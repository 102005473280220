import React, { useEffect } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default function LightBox(props) {

  useEffect(() => {
    
  }, []);

  return (
    <Lightbox
      mainSrc={props.lightboxImages[props.photoIndex]}
      nextSrc={props.lightboxImages[(props.photoIndex + 1) % props.lightboxImages.length]}
      prevSrc={props.lightboxImages[(props.photoIndex + props.lightboxImages.length - 1) % props.lightboxImages.length]}
      onCloseRequest={() => props.setIsOpen(false)}
      onMovePrevRequest={() =>
        props.setPhotoIndex((props.photoIndex + props.lightboxImages.length - 1) % props.lightboxImages.length)
      }
      onMoveNextRequest={() =>
        props.setPhotoIndex((props.photoIndex + 1) % props.lightboxImages.length)
      }
    />
  );
}

import React, { Component } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import UploadConfiguratorLandingDropzone from "../partials/UploadConfiguratorLandingDropzone";
import axios from 'axios';
import {baseUrl} from "../../Constants"
import uploadIcon from "../../assets/upload.svg";
import crossLightIcon from "../../assets/cross-light.svg";
import { StateContext } from "../../context/state";
import linkIcon from "../../assets/link.svg";
import crossSmallIcon from "../../assets/cross-small.svg";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/trash.svg"
import { Spinner } from 'reactstrap';
import Masonry from 'react-masonry-css';
import { withTranslation } from 'react-i18next';
import LightBox from "../lightBox/LightBox";

class BildOptionComponent extends Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.messagesRef=React.createRef(null);
    this.state = {
      bildInput: {},
      uploadModalOpen: false,
      tabIndex: 0,
      mediaLibrary: [],
      isMediaLibraryEmpty: true,
      searchValue:"",
      actualTotal:[],
      deleted:false,
      bildSelected:false,
      photo:"",
      clientid:"Z4rIu13ZLRWsXO5kL70Gox1nvGssGRFupjwtJ2vhNEA",
      unsplashImages:[],
      TotalUnsplashImages:[],
      page:1,
      totalPages:1,
      isOpen: false,
      lightboxImages: [],
      image: [],
      photoIndex: 0
    };
  }

  componentDidMount(){
    // console.log("venueid",this.context.venueId)
    // http://testapi.the-eventcloud.com/api/mediaGallery/GetMediaGalleryForVenue/%7BvenueId%7D/%7Btype%7D
    // console.log("index",this.props.index)
    axios.get(`${baseUrl}api/mediaGallery/mediaGalleryForVenue/${this.context.venueId}/image`)
    .then((response)=>{
      // console.log("media library",response.data)
      if (response.data.hasOwnProperty("mediaLibrary")||response.data.hasOwnProperty("dummy")) {
      this.setState({
        isMediaLibraryEmpty: false,
        mediaLibrary:response.data.mediaLibrary?response.data.mediaLibrary:response.data.dummy,
        actualTotal:response.data.mediaLibrary?response.data.mediaLibrary:response.data.dummy,
      })
    }

    })
    .catch((err)=>{
      console.log(err)
    })
  }
  static getDerivedStateFromProps = (nextProps, prevState) => {
    return {
      bildInput: nextProps.bildInput
    };
  };

  toggleUploadModal = index => {
    // console.log('clicked')
    const mediaLibrary = this.state.mediaLibrary;
    mediaLibrary.forEach(item => {
      item.checked = false;
    });
    this.setState(prevState => ({
      mediaLibrary,
      uploadModalOpen: !prevState.uploadModalOpen,
      tabIndex: 0
    }));
    this.props.setOptionSelected(this.props.index);
    this.props.setPopup();
  };

  openUploadModalMediaSection = index => {
    // const mediaLibrary = this.state.mediaLibrary;
    // mediaLibrary.forEach(item => {
    //   item.checked = false;
    // });
    // this.setState({
    //   mediaLibrary,
    //   uploadModalOpen: true,
    //   tabIndex: 1
    // });
    // this.props.setOptionSelected(this.props.index);

    // axios
    // .get(baseUrl + `api/mediaGallery/${this.context.loggedInUserId}/image`)
    axios.get(`${baseUrl}api/mediaGallery/mediaGalleryForVenue/${this.context.venueId}/image`)
    .then(res => {
      // console.log(res);
      if (res.data.hasOwnProperty("mediaLibrary")||res.data.hasOwnProperty("dummy")) {
        this.setState({ isMediaLibraryEmpty: false, tabIndex:1 });
        const mediaLibrary = res.data.mediaLibrary?res.data.mediaLibrary:res.data.dummy;
        mediaLibrary.forEach(item => {
          item.checked = false;
        });
        this.setState({ 
           mediaLibrary,
           actualTotal:res.data.mediaLibrary?res.data.mediaLibrary:res.data.dummy,
           tabIndex:1,
           uploadModalOpen: true,
           });
      }
      else{
       const mediaLibrary = this.state.mediaLibrary;
        mediaLibrary.forEach(item => {
          item.checked = false;
        });
        this.setState({mediaLibrary})
      }
    })
    .catch(error => {
      console.log(error);
    });

    this.setState({
      // mediaLibrary,
      uploadModalOpen: true,
      tabIndex: 1
    });
   this.props.setOptionSelected(this.props.index);

  };

  setMediaItemChecked = (index, e, itemUrl) => {
    const {mediaLibrary, actualTotal} = this.state;
    // console.log("actualTotal  top", actualTotal);
    mediaLibrary.forEach((item, _index) => {
      if(index === _index){
        e.target.checked === true ? (item.checked = true) : (item.checked = false)
      }
      
    });
    actualTotal.forEach((item, _index) => {
      if( item.url === itemUrl){
        e.target.checked === true ? (item.checked = true) : (item.checked = false);
      }
    });
    
    this.setState({ mediaLibrary, actualTotal });
    // console.log("actualTotal bottom", actualTotal);
    
  };

  insertSelectedMedia = () => {
    let imgs=this.state.bildInput?.image ? Object.assign([], this.state.bildInput?.image) :[]
    // let imgs=[]
    this.state.mediaLibrary.forEach(item => {
      if(item.checked){
        let x={url:item.url, title:"", caption:""}
        imgs.push(x)
      }
    });
    this.props.setBildImage(imgs);
    this.setState({ uploadModalOpen: false });
    this.props.setPopup();
  };

  insertSelectedUnsplash = () => {
    var promises = [];
    var setBildImage=(images)=>{
      this.props.setBildImage(images);
      this.setState({ uploadModalOpen: false });
      this.props.setPopup();
    }
    let imgs = this.state.bildInput?.image ? Object.assign([], this.state.bildInput?.image) : []
    this.state.TotalUnsplashImages.forEach((photo) => {
      promises.push(axios
        .post(baseUrl + `api/blob/unSplash`, { url: photo }))
    });
    Promise.all(promises).then(function (results) {
      results.forEach((response) => {
        let x = { url: response.data.url, title: "", caption: "" }
        imgs.push(x);
      });
      // console.log(imgs);
      setBildImage(imgs);    
    });
  };
// insertSelectedUnsplash = () => {
//    let imgs = this.state.bildInput?.image ? Object.assign([], this.state.bildInput?.image) : []
//     this.state.TotalUnsplashImages.forEach((photo) => {
//       let x = { url:photo, title: "", caption: "" }
//       imgs.push(x);
//     });
//     this.props.setBildImage(imgs);
//     this.setState({ uploadModalOpen: false });
//     this.props.setPopup();
//   };
  
  setUnsplashItemChecked=(e,url) => {
    var totalImages = this.state.TotalUnsplashImages;
    if(totalImages.indexOf(url)>=0)
    {
      totalImages.splice(totalImages.indexOf(url)-1,1);
    }
    else
    {
      totalImages.push(url);
    }
    this.setState({ TotalUnsplashImages: totalImages });
    console.log(this.state.TotalUnsplashImages);
  };

  acceptUploadToMediaLibrary = params => {
    if (this.state.isMediaLibraryEmpty) {
      // console.log("media library",this.state.isMediaLibraryEmpty)
      axios
        .post(baseUrl + "api/mediaGallery", {
          mediaLibrary: [
            {
              url: params.url,
              type: params.type,
              name: params.name,
              checked:false
            }
          ],
          venueId:this.context.venueId
          // userId: this.context.loggedInUserId
        })
        .then(res => {
          //Remove loader
          const mediaLibrary = this.state.mediaLibrary;
          mediaLibrary.push(params);
          this.setState({ 
            mediaLibrary,
            actualTotal:mediaLibrary,
            tabIndex: 1,
            isMediaLibraryEmpty: false
            // this.setState({ isMediaLibraryEmpty: false, tabIndex });
          });
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      // console.log("meadiaLibrary",this.state.mediaLibrary)
      const mediaLibrary = this.state.mediaLibrary;
      mediaLibrary.unshift(params);
      // mediaLibrary.push(params);
      // console.log('library', mediaLibrary)
      axios
        .put(baseUrl+ `api/mediaGallery/${this.context.venueId}`, {
          mediaLibrary,
          venueId: this.context.venueId
        })
        .then(res => {
          // console.log(res);
          this.setState({ 
            mediaLibrary, 
            actualTotal:mediaLibrary,
            tabIndex: 1 
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
  handleClicked=()=>{
    this.props.setOptionSelected(this.props.index, "bild");
   
  }
  deleteMediaImage=(item,e)=>{
    this.setState({delete:true})
  const array=Object.assign([],this.state.mediaLibrary)
   
   const mediaLibrary=array?.filter(media=>media.url!==item.url)
  //  console.log(mediaLibrary,"medialibrary")
    // this.setState({mediaLibrary:mediaLibrary})
  
    
             axios
            .put(baseUrl+ `api/mediaGallery/${this.context.venueId}`, {
              mediaLibrary,
              venueId: this.context.venueId
            })
            .then(res => {
              // console.log(res);
              this.setState({ 
                mediaLibrary:mediaLibrary, 
                actualTotal:mediaLibrary,
                tabIndex: 1 ,
                delete:false
              });
            })
            .catch(error => {
              console.log(error);
            });
    
      }
    

  showLinkComponent = (e) => {
    e.stopPropagation()
    this.props.toggleLinkOption(null, null, this.props.index);
    
  };
  handleTabShift=(tabIndex)=>{
    // console.log('tab shift', tabIndex)
    if(tabIndex==="1"){
      // axios
      // .get(baseUrl + `api/mediaGallery/${this.context.loggedInUserId}/image`)
      axios.get(`${baseUrl}api/mediaGallery/mediaGalleryForVenue/${this.context.venueId}/image`)
      .then(res => {
        // console.log(res);
        if (res.data.hasOwnProperty("mediaLibrary")) {
          this.setState({ isMediaLibraryEmpty: false, tabIndex });
          const mediaLibrary = res.data.mediaLibrary;
          // mediaLibrary.forEach(item => {
          //   item.checked = false;
          // });
          this.setState({ mediaLibrary, 
            actualTotal:res.data.mediaLibrary,
            tabIndex });
        }
        else{
          this.setState({ tabIndex })
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
    else{
      this.setState({ tabIndex })
    }
    if(tabIndex==="2"){
      this.setState({
        unsplashImages:[]  
      });
    }

  }
  

   handleChange = (event) => {
    this.setState({  
      photo:event.target.value
    }); 
  }

  handleSumbit = (page) => { 
    var totalpage=this.state.totalPages;
    if(page===1){
       totalpage=1
    }
    if(page<=totalpage)
    {
    const url = "https://api.unsplash.com/search/photos?page="+page+"&query=" + this.state.photo + "&client_id=" + this.state.clientid;
   
    axios.get(url)
      .then((response) => {    
      if (page<2)
       {
        this.setState(
          {
          unsplashImages:response.data.results ,
          totalPages :response.data.total_pages  
        });
       }
     else if(page<=response.data.total_pages){
        this.setState(
          {
            unsplashImages:[...this.state.unsplashImages,...response.data.results] ,     
        });
      //  console.log("total unsplash",this.state.TotalUnsplashImages);

      }  
      })
    }
  }

  handleKeypress = e => {
    //it triggers by pressing the enter key
  if (e.key === 'Enter' && this.state.tabIndex===2) 
   {
    this.handleSumbit(1);
   }
};

handleScroll = (e) => { 
  if(this.state.tabIndex===2) 
  { 
      
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) 
    { 
      this.setState(
      {
        page:this.state.page+1},()=>{
        this.handleSumbit(this.state.page);
      }
     )
    }
  } 
}


  handleSearch=(e)=>{
    const {mediaLibrary, actualTotal}=this.state
    if(this.state.searchValue.length> e.target.value.length){
      const x=actualTotal.filter(img=>img.name?.toLowerCase().includes(e.target.value.toLowerCase()))
      this.setState({
        mediaLibrary:x,
        searchValue:e.target.value
      })
    }
    else{
      const x=mediaLibrary.filter(img=>img.name?.toLowerCase().includes(e.target.value.toLowerCase()))
      this.setState({
        mediaLibrary:x,
        searchValue:e.target.value
      })
    }
  }
  removeImageItem = (index) => {
    const bildInput = this.state.bildInput;
    bildInput.image.splice(index, 1);
    // const selectedImageIndex =
    //   this.state.selectedImageIndex === null
    //     ? null
    //     : index === 0
    //     ? (index = 0)
    //     : index - 1;
    // // this.props.updateImageOptionInput(imageInput);
    // imageInput.inputs.length === 0
    //   ? this.setState({ imageInput, singleImageAdd: false, selectedImageIndex })
    //   : this.setState({ imageInput, selectedImageIndex });
  };
  editImageItem=(e, index)=>{
    e.stopPropagation()
    this.props.toggleImageCaption(this.state.bildInput, index)
  }
  handleDragOver=(e)=>{
    e.preventDefault();
  }
  onImageDrop=(e)=>{
    const index = e.dataTransfer.getData("index");
    const propsIndex = e.dataTransfer.getData(`${this.props.index}`);
    const target = e.target.id;
    
    // console.log('drag', index, target, propsIndex)
    if(propsIndex){
      const dummy=Object.assign({}, this.state.bildInput)
      const x=dummy.image[target]
      const y=dummy.image[index]
      if(x && y){
        // dummy.image[index]=x
        // dummy.image[target]=y
        dummy.image.splice(index, 1);
    dummy.image.splice(target, 0, y);
        // console.log('in if', dummy)
        this.setState({
          bildInput:dummy
        })
      }
      
    }
  }
  onDragStart = (e, index) => {
    e.dataTransfer.setData("index", index);
    e.dataTransfer.setData(`${this.props.index}`, this.props.index);
  };
  changeImageItem=()=>{
    const mediaLibrary = this.state.mediaLibrary;
    mediaLibrary.forEach(item => {
      item.checked = false;
    });
    this.setState({
      tabIndex:1,
      uploadModalOpen:true,
      mediaLibrary,
      TotalUnsplashImages:[]
    })
    this.props.setPopup();
    
  }
  display=(value)=>{
    this.setState({bildSelected:value})
  }
  display2=()=>{
    // console.log("optioncontainer",this.props.optionContainerClicked)
    this.setState({bildSelected:this.props.optionContainerClicked ? true:false})
  }

  openLightBox= (index) => {
    let lghtImges = this.state.mediaLibrary.map((obj) => {
      return obj.url;
    });
    this.setState({lightboxImages: lghtImges, isOpen : true, photoIndex: index});
  }

  render() {
    const breakpointColumnsObj = {
      default: 3,
      1100: 2,
      700: 1,
      500: 1
    };
    
    // console.log("in display",display)
    return (
      <div tabIndex="0" className="image-option-component" onClick={this.handleClicked} 
      style={{ 
        background:this.props.layoutObject[this.props.index[0]]?.row[this.props.index[1]]?.column[this.props.index[2]]?.selected===true ? "#aaacaf":""
      }}
      onFocus={()=>{this.display(true)}}
      onBlur={()=>{this.display(false)}} >
        <button className="template-link-btn" onClick={(e)=>{this.showLinkComponent(e)}} >
            <img src={linkIcon} alt="" />
          </button>
         
        <div className="upload-container"
         style={{padding: "30px 30px 10px 10px"}} 
          ref={this.messagesRef}>
          {this.state.bildInput.hasOwnProperty("image") 
          && this.state.bildInput?.image?.length &&  Array.isArray(this.state.bildInput?.image) ? (
           
           <div className="images-container" ref={this.messagesRef}
           onDragOver={(e)=>{this.handleDragOver(e)}}
           onDrop={(e) => this.onImageDrop(e)}
           >
           <div ref={this.messagesRef}
            style={{
              textAlign: "left"
              // width: this.state.bildInput.image.length * 315 +
              // (this.state.bildInput.image.length - 1) * 15
            }}
            >
               <button
                      className="change-image-item"
                      onClick={(e) => this.changeImageItem(e)}
                    >
                      <img src={editIcon} alt="" style={{width:"15px", height:"15px", top:'35px', verticalAlign:"initial"}}/>
                    </button>
            {this.state.bildInput?.image?.map((input, index)=>{
             return  <div ref={this.messagesRef}
              className={`image-item image-item-horizontal`}
              style={{width:"200px"}}
              key={index}
              >
                <div className="image" ref={this.messagesRef}
                id={index}
                style={{ backgroundImage: `url(${input?.url})`, 
                backgroundRepeat: "no-repeat" , 
                backgroundSize:"contain",
                height:"0"
              }}
                draggable
                onDragStart={(e)=>{this.onDragStart(e, index)}}
                >
            </div>
            <button
                      className="remove-image-item"
                      onClick={() => this.removeImageItem(index)}
                    >
                      <img src={crossSmallIcon} alt="" />
                    </button>
                    <button
                      className="edit-image-item"
                      onClick={(e) => this.editImageItem(e, index)}
                    >
                      <img src={editIcon} alt="" style={{width:"15px", height:"15px", verticalAlign:"initial"}}/>
                    </button>
                   
              </div>
            })}
            </div>
            </div>
          ) : (
            <div className="upload-section" ref={this.messagesRef}>
              <img src={uploadIcon} alt="" />
              <button className="choose-btn" onClick={this.toggleUploadModal} style={{fontSize:"11px",height:"30px",width:"100px"}}>
              {this.props.t("Datei wählen")} 
              </button>
              <button className="choose-btn" onClick={this.openUploadModalMediaSection} style={{fontSize:"11px",height:"30px",width:"100px"}}>
              {this.props.t("Bildersammlung")}  
              </button>
            </div>
          )}
        </div>
        <div className={`image-selector-modal ${this.state.uploadModalOpen && "open"}`} ref={this.messagesRef}>
          <div className="selector-container">
            <Tabs selectedIndex={this.state.tabIndex} 
              onSelect={tabIndex=>this.handleTabShift(tabIndex)}>
              <TabList>
                <Tab>{this.props.t("Bilder hochladen")}</Tab>
                <Tab>{this.props.t("Bildersammlung")}</Tab>
                <Tab>Unsplash</Tab>  
              </TabList>
              <TabPanel>
              <UploadConfiguratorLandingDropzone
                  acceptUploadToMediaLibrary={this.acceptUploadToMediaLibrary}
                />
              </TabPanel>
              <TabPanel>
                <div className="media-library-header" ref={this.messagesRef}>
                  <div className="row">
                    <div className="col-6 text-left">
                      {/* <select className="type-select">
                        <option value="all-media">All Media</option>
                        <option value="images">Images</option>
                      </select> */}
                         <input 
                        type="text" 
                        className="search-input" 
                        placeholder={this.props.t("Suchen")}
                        value={this.state.searchValue}
                        onChange={this.handleSearch}
                        autoComplete="off"
                        />
                    </div>
                    <div className="col-6 text-right">
                   
                    </div>
                  </div>
                </div>
                {this.state.delete===true ? 
                 <div style={{
                  color: "#888888",
                  fontWeight: "bold",
                  fontSize: "20px",
                  padding: "20px",
                  textAlign: "center",
                 }}><div><Spinner color="success" /></div></div>:
                 <></>
              }
                <div className="media-library-container" ref={this.messagesRef}>
                  {this.state.isOpen &&
                    <LightBox lightboxImages={this.state.lightboxImages} setIsOpen={(e) => this.setState({isOpen: e})} setPhotoIndex={(e) => this.setState({photoIndex: e})} photoIndex={this.state.photoIndex} />
                  }
                  {this.state.mediaLibrary.map((item, index) => (
                    <>
                    <label className={`media-item ${item.checked && "selected"}`} key={index}>
                    <img src={deleteIcon} alt={"Delete"}
                         style={{position:"absolute",top:"150px",borderRadius:"50%",background:"white",padding:"5px",left:"10px"}}
                          onClick={(e)=>this.deleteMediaImage(item,e)}/>
                      <input type="checkbox" checked={item.checked} onChange={e => this.setMediaItemChecked(index, e, item.url)} autoComplete="off" />
                      <span />
                      <div className="image" style={{ backgroundImage: `url(${item.url})`, height:"0" }} onClick={() => this.openLightBox(index)}></div>
                      <div className="file-name">{item.name}</div>
                    </label>
                    </>
                  ))}
                </div>
                <div className="media-library-footer text-right">
                  <button className="footer-btn" onClick={this.insertSelectedMedia}>
                  {this.props.t('Bild einfügen')}
                  </button>
                  <button className="footer-btn" onClick={this.toggleUploadModal}>
                  {this.props.t('Abbrechen')} 
                  </button>
                </div>
              </TabPanel>
              <TabPanel>
              <div>
                <div className="unsplash-library-header">
                  <div className="row">
                    <div className="col-6 text-left"> 
                        {/* <select className="type-select">
                        <option value="all-media">All Media</option>
                        <option value="images">Images</option>
                      </select> */}
                      <input  className="search-input"   
                     onChange={this.handleChange}
                      type="text" name="photo"
                       placeholder={this.props.t("Suchen")}
                       onKeyPress={this.handleKeypress}
                       autoComplete="off"/>
                       <button className="unsplash-sumbit" onClick={()=>this.handleSumbit(1)} type="sumbit"></button>
                    </div>
                    <div className="col-6 text-right">
                    </div>
                  </div>
                </div>   
                <div className="wrapper-unsplash" onScroll={this.handleScroll}>
                <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">  
                {this.state.unsplashImages.map((photo) => {
                 return (
                     <div className={`unsplash-item ${this.state.TotalUnsplashImages.indexOf(photo.urls.small)>=0 && "selected"}`}>
                     <input type="checkbox" className="checkbox" onChange={e => this.setUnsplashItemChecked(e, photo.urls.small)} autoComplete="off"/>
                     <img src={photo.urls.small} alt={"URL"} />
                     </div>); 
                })}
                </Masonry>
                </div>
                <div className="unsplash-footer text-right">
                  <button className="footer-btn" onClick={this.insertSelectedUnsplash}>
                  {this.props.t('Bild einfügen')}
                  </button>
                  <button className="footer-btn" onClick={this.toggleUploadModal}>
                  {this.props.t('Abbrechen')}
                  </button>
                </div>
                </div>
              </TabPanel>
            </Tabs>
            <button className="close-btn" onClick={this.toggleUploadModal}>
              <img src={crossLightIcon} alt="" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default  withTranslation() (BildOptionComponent);

import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import UploadConfiguratorLandingDropzone from "../partials/UploadConfiguratorLandingDropzone";
import axios from "axios";
import { baseUrl } from "../../Constants";
import crossLightIcon from "../../assets/cross-light.svg";
import uploadIcon from "../../assets/upload.svg";
import plusIcon from "../../assets/plus.svg";
import optionIcon from "../../assets/option.svg";
import optionFilledIcon from "../../assets/option-filled.svg";
import crossSmallIcon from "../../assets/cross-small.svg";
import { StateContext } from "../../context/state";
import linkIcon from "../../assets/link.svg";
import deleteIcon from "../../assets/trash.svg"
import { Spinner } from 'reactstrap';
import Masonry from 'react-masonry-css';
import { withTranslation } from 'react-i18next';

class ImageOptionComponent extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.messagesRef=React.createRef(null);
    this.state = {
      canBeEdited: false,
      imageInput: {
        question: "",
        multipleSelection:false,
        alignment: "horizontal",
        inputs: [],
      },
      mediaLibrary: [],
      tabIndex: 0,
      uploadModalOpen: false,
      selectedImageIndex: null,
      singleImageIndex: null,
      singleImageAdd: false,
      searchValue: "",
      actualTotal: [],
      deleted:false,
      imageSelected:false,
      photo:"",
      clientid:"Z4rIu13ZLRWsXO5kL70Gox1nvGssGRFupjwtJ2vhNEA",
      unsplashImages:[],
      TotalUnsplashImages:[],
      page:1,
      totalPages:1
    };
  }
  componentDidMount() {
    // axios
    //   .get(`${baseUrl}api/mediaGallery/${this.context.venueId}/image`)
    axios.get(`${baseUrl}api/mediaGallery/mediaGalleryForVenue/${this.context.venueId}/image`)
      .then((response) => {
        if (response.data.hasOwnProperty("mediaLibrary")) {
          this.setState({
            isMediaLibraryEmpty: false,
            mediaLibrary: response.data.mediaLibrary,
            actualTotal: response.data.mediaLibrary,
          });
        }
     })
      .catch((err) => {
        console.log(err);
      });
  }
  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.imageInput !== undefined) {
      if (nextProps?.canBeEdited) {
      }
      return {
        selectedImageIndex: nextProps.selectedImageIndex,
        imageInput: nextProps.imageInput,
        canBeEdited: nextProps.canBeEdited ? nextProps.canBeEdited : false,
      };
    } else {
      return {
        selectedImageIndex: nextProps.selectedImageIndex,
        canBeEdited: nextProps.canBeEdited ? nextProps.canBeEdited : false,
      };
    }
  };

  setQuestion = (e) => {
    const imageInput = this.state.imageInput;
    imageInput.question = e.target.value;
    this.setState({ imageInput });
  };

  toggleUploadModal = (index) => {
    const mediaLibrary = this.state.mediaLibrary;
    mediaLibrary.forEach((item) => {
      item.checked = false;
    });
    this.setState((prevState) => ({
      mediaLibrary,
      uploadModalOpen: !prevState.uploadModalOpen,
      tabIndex: 0,
      singleImageIndex: index,
    }));
    this.props.setPopup();
  };
  deleteMediaImage=(item,e)=>{
    this.setState({delete:true})
    const array=Object.assign([],this.state.mediaLibrary)
     
     const mediaLibrary=array?.filter(media=>media.url!==item.url)
    //  console.log(mediaLibrary,"medialibrary")
      // this.setState({mediaLibrary:mediaLibrary})
    
      
               axios
              .put(baseUrl+ `api/mediaGallery/${this.context.venueId}`, {
                mediaLibrary,
                venueId: this.context.venueId
              })
              .then(res => {
                // console.log(res);
                this.setState({ 
                  mediaLibrary:mediaLibrary, 
                  actualTotal:mediaLibrary,
                  tabIndex: 1 ,
                  delete:false
                });
              })
              .catch(error => {
                console.log(error);
              });
      
        }
  openUploadModalMediaSection = (index) => {
    // const mediaLibrary = this.state.mediaLibrary;
    // mediaLibrary.forEach(item => {
    //   item.checked = false;
    // });
    // this.setState({
    //   mediaLibrary,
    //   uploadModalOpen: true,
    //   tabIndex: 1,
    //   singleImageIndex: index
    // });
    // axios
    //   .get(baseUrl + `api/mediaGallery/${this.context.venueId}/image`)
    axios.get(`${baseUrl}api/mediaGallery/mediaGalleryForVenue/${this.context.venueId}/image`)
      .then((res) => {
        if (res.data.hasOwnProperty("mediaLibrary")) {
          this.setState({ isMediaLibraryEmpty: false, tabIndex: 1 });
          const mediaLibrary = res.data.mediaLibrary;
          mediaLibrary.forEach((item) => {
            item.checked = false;
          });
          this.setState({
            mediaLibrary,
            actualTotal: res.data.mediaLibrary,
            tabIndex: 1,
            uploadModalOpen: true,
          });
        } else {
          const mediaLibrary = this.state.mediaLibrary;
          mediaLibrary.forEach((item) => {
            item.checked = false;
          });
          this.setState({ mediaLibrary });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // const mediaLibrary = this.state.mediaLibrary;
    // mediaLibrary.forEach(item => {
    //   item.checked = false;
    // });
    this.setState({
      // mediaLibrary,
      uploadModalOpen: true,
      tabIndex: 1,
      singleImageIndex: index,
    });
  };

  setMediaItemChecked = (index, e) => {
    const mediaLibrary = this.state.mediaLibrary;
    if (this.state.singleImageAdd) {
      mediaLibrary.forEach((item, _index) => {
        index === _index && e.target.checked === true
          ? (item.checked = true)
          : (item.checked = false);
      });
    } else mediaLibrary[index].checked = !mediaLibrary[index].checked;
    this.setState({ mediaLibrary });
  };

  insertSelectedMedia = () => {
    const imageInput = this.state.imageInput;
    if (imageInput.inputs.length > 0) {
      this.state.mediaLibrary.forEach((item, index) => {
        if (item?.checked) {
          imageInput.inputs[this.state.singleImageIndex].image = item?.url;
        }
      })
      this.setState({ imageInput, uploadModalOpen: false, singleImageAdd: true });
      this.props.setPopup();
    }
    else {
    this.state.mediaLibrary.forEach((item, index) => {
      // console.log('item', item)
      if (item?.checked) {
        if (this.state?.singleImageAdd)
          imageInput.inputs[this.state.singleImageIndex].image = item?.url;
        else
          imageInput.inputs.push({
            id: index,
            image: item.url,
            value: item.name,
            price: "",
            priceType: "",
            priceTypeId: null,
            tags: [],
            numerically: {
              minimumNumber: "",
              minimumNumberCalculate: false,
              maximumNumber: "",
              maximumNumberCalculate: false,
              stage: "",
              relay1: "",
              relay1Alle: false,
              relay1Price: "",
              relay2: "",
              relay2Alle: false,
              relay2Price: "",
              relay3: "",
              relay3Alle: false,
              relay3Price: "",
              unit: "",
            },
          });
      }
    });
    // this.props.updateImageOptionInput(imageInput);
    this.setState({ imageInput, uploadModalOpen: false, singleImageAdd: true });
    this.props.setPopup();
  }
  };

  insertSelectedUnsplash = () => {
    const imageInput = this.state.imageInput;
    if(imageInput.inputs.length>0)
    {
      this.state.TotalUnsplashImages.forEach((photo, index)=>{
        imageInput.inputs[this.state.singleImageIndex].image = photo;
      })
      this.setState({ imageInput, uploadModalOpen: false, singleImageAdd: true });
    }
    else{
    this.state.TotalUnsplashImages.forEach((photo, index)=>{
      if (this.state?.singleImageAdd)
      imageInput.inputs[this.state.singleImageIndex].image = photo;
    else
      imageInput.inputs.push({
        id: index,
        image: photo,
        // value: photo.name,
        value: "",
        price: "",
        priceType: "",
        priceTypeId: null,
        tags: [],
        numerically: {
          minimumNumber: "",
          minimumNumberCalculate: false,
          maximumNumber: "",
          maximumNumberCalculate: false,
          stage: "",
          relay1: "",
          relay1Alle: false,
          relay1Price: "",
          relay2: "",
          relay2Alle: false,
          relay2Price: "",
          relay3: "",
          relay3Alle: false,
          relay3Price: "",
          unit: "",
        },
      });
    });
    this.setState({ imageInput, uploadModalOpen: false, singleImageAdd: true });
  }
  };

  setUnsplashItemChecked=(e,url) => {
    var totalImages = this.state.TotalUnsplashImages;
    if(totalImages.indexOf(url)>=0)
    {
      totalImages.splice(totalImages.indexOf(url)-1,1);
    }
    else
    {
      totalImages.push(url);
    }
    this.setState({ TotalUnsplashImages: totalImages });
  };

  showOptionComponent = (index, e) => {
    e.stopPropagation();
    this.props.toggleImageOptionConfiguration(
      index,
      this.state.imageInput,
      this.props.index
    );
    this.setState({
      selectedImageIndex: index,
    });
  };

  addImageItem = (index) => {
    const imageInput = this.state.imageInput;
    imageInput.inputs.splice(index + 1, 0, {
      id: imageInput.inputs.length,
      image: "",
      value: "",
      price: "",
      priceType: "",
      priceTypeId: null,
      tags: [],
      numerically: {
        minimumNumber: "",
        minimumNumberCalculate: false,
        maximumNumber: "",
        maximumNumberCalculate: false,
        stage: "",
        relay1: "",
        relay1Alle: false,
        relay1Price: "",
        relay2: "",
        relay2Alle: false,
        relay2Price: "",
        relay3: "",
        relay3Alle: false,
        relay3Price: "",
        unit: "",
      },
    });
    // this.props.updateImageOptionInput(imageInput);
   this.setState({ imageInput ,singleImageAdd:true});
  };

  removeImageItem = (index) => {
    const imageInput = this.state.imageInput;
    imageInput.inputs.splice(index, 1);
    const selectedImageIndex =
      this.state.selectedImageIndex === null
        ? null
        : index === 0
        ? (index = 0)
        : index - 1;
    // this.props.updateImageOptionInput(imageInput);
    imageInput.inputs.length === 0
      ? this.setState({ imageInput, singleImageAdd: false, selectedImageIndex })
      : this.setState({ imageInput, selectedImageIndex });
  };
  acceptUploadToMediaLibrary = (params) => {
    if (this.state.isMediaLibraryEmpty) {
      axios
        .post(baseUrl + "api/mediaGallery", {
          mediaLibrary: [
            {
              url: params.url,
              type: params.type,
              name: params.name,
              checked: false,
            },
          ],
          venueId: this.context.venueId,
        })
        .then((res) => {
          // console.log(res);
          const mediaLibrary = this.state.mediaLibrary;
          mediaLibrary.unshift(params);
          this.setState({
            mediaLibrary,
            actualTotal: mediaLibrary,
            tabIndex: 1,
            isMediaLibraryEmpty: false
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const mediaLibrary = this.state.mediaLibrary;
      mediaLibrary.unshift(params);
      // console.log('library', mediaLibrary)
      axios
        .put(baseUrl + `api/mediaGallery/${this.context.venueId}`, {
          mediaLibrary,
          venueId: this.context.venueId,
        })
        .then((res) => {
          // console.log(res);
          this.setState({
            mediaLibrary,
            actualTotal: mediaLibrary,
            tabIndex: 1,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  handleClicked = () => {
    // console.log('click', this.state.imageInput)
    const d = {
   
      max: this.state.imageInput.max
        ? this.state.imageInput.max
        : "",
      min: this.state.imageInput.min
        ? this.state.imageInput.min
        : "",
    };
    this.props.setOptionSelected(
      this.props.index,
      "image",
      null,
      this.state.imageInput,null,null,null,d
    );
  };
  handleTabShift = (tabIndex) => {
    // console.log('tab shift', tabIndex)
    if (tabIndex === "1") {
      // axios
      //   .get(baseUrl + `api/mediaGallery/${this.context.venueId}/image`)
      axios.get(`${baseUrl}api/mediaGallery/mediaGalleryForVenue/${this.context.venueId}/image`)
        .then((res) => {
          // console.log(res);
          if (res.data.hasOwnProperty("mediaLibrary")) {
            this.setState({ isMediaLibraryEmpty: false, tabIndex });
            const mediaLibrary = res.data.mediaLibrary;
            mediaLibrary.forEach((item) => {
              item.checked = false;
            });
            this.setState({
              mediaLibrary,
              tabIndex,
              actualTotal: res.data.mediaLibrary,
            });
          } else {
            this.setState({ tabIndex });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ tabIndex });
    }
    if(tabIndex==="2"){
      this.setState({
        result:[]  
      });
    }

  };

  handleChange = (event) => {
    // console.log("target",event.target.value);
   this.setState({  
     photo:event.target.value
   });  
 }

//  handleSumbit = (event) => {
//   // console.log("photo in sumbit", this.state.photo);
//   const url = "https://api.unsplash.com/search/photos?page=1&query=" + this.state.photo + "&client_id=" + this.state.clientid;
//   // console.log(url);
//   axios.get(url)
//     .then((response) => {
//       // setResult(response.data.results);
//       console.log(response);
//       this.setState({
//         result:response.data.results   
//       })
//     })
// }

handleSumbit = (page) => { 
  var totalpage=this.state.totalPages;
  if(page===1){
     totalpage=1
  }
  if(page<=totalpage)
  {
  const url = "https://api.unsplash.com/search/photos?page="+page+"&query=" + this.state.photo + "&client_id=" + this.state.clientid;
 
  axios.get(url)
    .then((response) => {    
    if (page<2)
     {
      this.setState(
        {
        unsplashImages:response.data.results ,
        totalPages :response.data.total_pages  
      });
     }
   else if(page<=response.data.total_pages){
      this.setState(
        {
          unsplashImages:[...this.state.unsplashImages,...response.data.results] ,  
      });
     console.log("total unsplash",this.state.TotalUnsplashImages);

    }  
    })
  }
}

handleKeypress = e => {
  //it triggers by pressing the enter key
  if (e.key === 'Enter' && this.state.tabIndex===2) 
  {
   this.handleSumbit(1);
  }
};

handleScroll = (e) => { 
  if(this.state.tabIndex===2) 
  { 
      
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) 
    { 
      this.setState(
      {
        page:this.state.page+1},()=>{
        this.handleSumbit(this.state.page);
      }
     )
    }
  } 
}


  handleSearch = (e) => {
    const { mediaLibrary, actualTotal } = this.state;
    if (this.state.searchValue.length > e.target.value.length) {
      const x = actualTotal.filter((img) =>
        img.name?.toLowerCase().includes(e.target.value.toLowerCase())
      );
      this.setState({
        mediaLibrary: x,
        searchValue: e.target.value,
      });
    } else {
      const x = mediaLibrary.filter((img) =>
        img.name?.toLowerCase().includes(e.target.value.toLowerCase())
      );
      this.setState({
        mediaLibrary: x,
        searchValue: e.target.value,
      });
    }
  };
  showLinkComponent = (index) => {
    // console.log('oi',this.state.radioInput )
    this.props.toggleLinkOption(index, this.state.radioInput, this.props.index);
    this.setState({
      selectedRadioIndex: index,
    });
  };
  functionValidation = (input) => {
    // console.log("input", input);
    const min = Number(input.numerically.minimumNumber);
    const max = Number(input.numerically.maximumNumber);
    const price = Number(input.price);

    if (min && max && min > max) {
      const msg = "Der Maximalwert sollte größer als der Minimalwert sein";
      return msg;
    }
    if (min && max && !price) {
      const msg = "Preis eingeben";
      return msg;
    } else if (min && !price) {
      const msg = "Preis eingeben";
      return msg;
    } else if (max && !price) {
      const msg = "Preis eingeben";
      return msg;
    } else {
      return null;
    }
  };
  display=(value)=>{
    this.setState({imageSelected:value})
  }
  setImageInputValue = (index, e) => {
    const imageInput = this.state.imageInput;
    imageInput.inputs[index].value = e.target.value;
    this.setState({ imageInput });
    //this.updateLayoutObject();
  };

  render() {
    const breakpointColumnsObj = {
      default: 3,
      1100: 2,
      700: 1,
      500: 1
    };
    return (
      <div
      tabIndex="0"
        className="image-option-component"  ref={this.messagesRef}
        style={{ border: "2px dashed #bcbec0", padding: "10px",
      
        background:this.props.layoutObject[this.props.index[0]]?.row[this.props.index[1]]?.column[this.props.index[2]]?.selected===true ? "#aaacaf":""
      }}
      
        onFocus={()=>{this.display(true)}}
        onBlur={()=>{ this.display(false)}}
      >
        <button className="template-link-img-btn" style={{marginBottom:"5px"}}>
          <img src={linkIcon} alt="" onClick={this.showLinkComponent} />
        </button>

        <input
          type="text"
          className="question"
          placeholder={this.props.t("Text einfügen")}
          onChange={this.setQuestion}
          onClick={this.handleClicked}
          value={this.state.imageInput.question}
          style={{ background: "#e6e7e8" }}
          autoComplete="off"
        />
        <div className="upload-container" onClick={this.handleClicked}>
          {this.state.imageInput.inputs.length === 0 ? (
            <div
              className="upload-section"
              // style={{ paddingRight: this.state.canBeEdited && "30px" }}
            >
              <img src={uploadIcon} alt="" />
              <button className="choose-btn" onClick={this.toggleUploadModal} style={{fontSize:"11px",height:"30px",width:"100px"}}>
              {this.props.t("Datei wählen")} 
              </button>
              <button
                className="choose-btn"
                style={{fontSize:"11px",height:"30px",width:"100px"}}
                onClick={this.openUploadModalMediaSection}
              >
                {this.props.t("Bildersammlung")}  
              </button>
            </div>
          ) : (
            <div className="images-container">
              <div
                // className="images-overflow"
                style={{
                  width:
                    this.state.imageInput.alignment === "horizontal"
                      ? this.state.imageInput.inputs.length * 315 +
                        (this.state.imageInput.inputs.length - 1) * 15
                      : "100%",
                }}
              >
                {this.state.imageInput.inputs.map((input, index) => (
                  <div
                    className={`image-item image-item-${this.state.imageInput.alignment}`}
                    key={index}
                  >
                    {input.image === "" ? (
                      <div className="image-upload">
                        <img src={uploadIcon} alt="" />
                        <button
                          className="choose-btn"
                          onClick={() => this.toggleUploadModal(index)}
                        >
                          {this.props.t("Datei wählen")} 
                        </button>
                        <button
                          className="choose-btn"
                          onClick={() =>
                            this.openUploadModalMediaSection(index)
                          }
                        >
                          {this.props.t("Bildersammlung")}  
                        </button>
                      </div>
                    ) : (
                      <div
                        className="image"
                        onClick={this.handleClicked}
                        style={{ backgroundImage: `url(${input.image})`, height:"0" }}
                      ></div>
                    )}
                    <button
                      className="add-image-item"
                      onClick={() => this.addImageItem(index)}
                    >
                      <img src={plusIcon} alt="" />
                    </button>
                    {/* <input
                      type="text"
                      className="radio-input1"
                      placeholder={this.props.t("Text einfügen")}
                      value={input.value}
                      onChange={(e) => this.setImageInputValue(index, e)}
                      // onFocus={() => this.showOptionComponent(index)}
                      onClick={this.handleClicked}
                      autoComplete="off"
                    /> */}
                   {
                   !this.state.imageInput.multipleSelection || this.state.imageInput.multipleSelection==="false"?
                   <span className="circle" />
                   :
                   <span className="square" />
                   }
                    <button
                      className="option"
                      onClick={(e) => this.showOptionComponent(index, e)}
                      style={{
                        border: this.functionValidation(input)
                          ? "2px solid red"
                          : null,
                        borderRadius: "50%",
                        height: "35px",
                        width: "34px",
                      }}
                    >
                      <img
                        src={
                          this.state.selectedImageIndex === index
                            ? optionFilledIcon
                            : optionIcon
                        }
                        alt=""
                      />
                    </button>
                    <span className="price-display">
                      {input.price !== undefined &&
                        input.price !== "" &&
                        `${this.context.currencyFormat} ${this.context.languageFormat==="en" ? Number(input.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") :Number(input.price).toFixed(2)
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".") }`}
                    </span>
                    {input.priceType === "number-guests" && (
                      <div className="x-guest">x {this.props.t('Gäste')}</div>
                    )}

                          
                    <button
                      className="remove-image-item"
                      onClick={() => this.removeImageItem(index)}
                    >
                      <img src={crossSmallIcon} alt="" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div
          className={`image-selector-modal ${
            this.state.uploadModalOpen && "open"
          }`}
        >
          <div className="selector-container">
            <Tabs
              selectedIndex={this.state.tabIndex}
              onSelect={(tabIndex) => this.handleTabShift(tabIndex)}
            >
              <TabList>
                <Tab>{this.props.t("Bilder hochladen")}</Tab>
                <Tab>{this.props.t("Bildersammlung")}</Tab>
                <Tab> Unsplash</Tab>
              </TabList>
              <TabPanel>
                <UploadConfiguratorLandingDropzone
                  acceptUploadToMediaLibrary={this.acceptUploadToMediaLibrary}
                />
              </TabPanel>
              <TabPanel>
                <div className="media-library-header">
                  <div className="row">
                    <div className="col-6 text-left">
                      {/* <select className="type-select">
                        <option value="all-media">All Media</option>
                        <option value="images">Images</option>
                      </select> */}
                      <input
                        type="text"
                        className="search-input"
                        placeholder={this.props.t("Suchen")}
                        value={this.state.searchValue}
                        onChange={this.handleSearch}
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-6 text-right">          
                    </div>
                  </div>
                </div>
                {this.state.delete===true ? 
                 <div style={{
                  color: "#888888",
                  fontWeight: "bold",
                  fontSize: "20px",
                  padding: "20px",
                  textAlign: "center",
                 }}><div><Spinner color="success" /></div></div>:
                 <></>
              }
                <div className="media-library-container">
                  {this.state.mediaLibrary.map((item, index) => (
                    <label
                      className={`media-item ${item.checked && "selected"}`}
                      key={index}
                    >
                       <img src={deleteIcon} alt={"Delete"}
                         style={{position:"absolute",top:"150px",left:"10px",borderRadius:"50%",background:"white",padding:"5px"}}
                          onClick={(e)=>this.deleteMediaImage(item,e)}/>
                      <input
                        type="checkbox"
                        checked={item.checked}
                        onChange={(e) => this.setMediaItemChecked(index, e)}
                        autoComplete="off"
                      />
                      <span />
                      <div
                        className="image"
                        style={{ backgroundImage: `url(${item.url})`, height:"0" }}
                      ></div>
                      <div className="file-name">{item.name}</div>
                    </label>
                  ))}
                </div>
                <div className="media-library-footer text-right">
                  <button
                    className="footer-btn"
                    onClick={this.insertSelectedMedia}
                  >
                   {this.props.t('Bild einfügen')}
                  </button>
                  <button
                    className="footer-btn"
                    onClick={this.toggleUploadModal}
                  >
                  {this.props.t('Abbrechen')} 
                  </button>
                </div>
              </TabPanel>
              <TabPanel>
              <div>
                <div className="unsplash-library-header">
                  <div className="row">
                    <div className="col-6 text-left">
                      {/* <select className="type-select">
                        <option value="all-media">All Media</option>
                        <option value="images">Images</option>
                      </select> */}
                       <input  className="search-input"   
                     onChange={this.handleChange}
                      type="text" name="photo"
                       placeholder={this.props.t("Suchen")}
                       onKeyPress={this.handleKeypress}
                       autoComplete="off" />
                       <button className="unsplash-sumbit" onClick={()=>this.handleSumbit(1)} type="sumbit"></button>
                    </div>
                    <div className="col-6 text-right">  
                    </div>
                  </div>
                </div>   
                <div className="wrapper-unsplash" onScroll={this.handleScroll}>
                <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">  
                {this.state.unsplashImages.map((photo) => {
                 return (
                     <div className={`unsplash-item ${this.state.TotalUnsplashImages.indexOf(photo.urls.small)>=0 && "selected"}`}>
                     <input type="checkbox" className="checkbox" onChange={e => this.setUnsplashItemChecked(e, photo.urls.small)} autoComplete="off" />
                     <img src={photo.urls.small} alt={"URL"} />
                     </div>); 
                })}
                </Masonry>
                </div>
                <div className="unsplash-footer text-right">
                  <button className="footer-btn" onClick={this.insertSelectedUnsplash}>
                  {this.props.t('Bild einfügen')}
                  </button>
                  <button className="footer-btn" onClick={this.toggleUploadModal}>
                 {this.props.t('Abbrechen')} 
                  </button>
                </div>
                </div>
              </TabPanel>
            </Tabs>
            <button className="close-btn"  onClick={this.toggleUploadModal}>
              <img src={crossLightIcon} alt="" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default  withTranslation() (ImageOptionComponent);

import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import trash from '../../assets/trash.svg';
import editIcon from '../../assets/edit.svg';
import axios from 'axios';
import { baseUrl } from '../../Constants';
import { withTranslation } from 'react-i18next';
import swal from 'sweetalert';
const imageMaxSize = 10240;
class ImageComposer extends React.Component {
	state = {
		croppedImageUrl: null,
		croppedImageBool: false,
		crop: {
			unit: '%',
			width: 30,
			// aspect: 16 / 9,
			aspect: 4 / 1,
			displayCropper: undefined,
		},
		blob: {},
		image: '',
		loader: false,
		array: [],
		collapsed: true,
		openSelectFile: false,
	};

	onSelectFile = (e) => {
		// console.log("in onSelect",e.target.files)
		this.setState({ displayCropper: true, openSelectFile: true });
		let img = new Image();
		if (e.target.files && e.target.files.length !== 0 && e.target != null) {
			// console.log("e",  e.target.files[0].size)
			e.persist();
			img.src = window.URL.createObjectURL(e.target?.files[0]);
			const currentFileSize = Math.round(e.target?.files[0].size / 1024);
			if (currentFileSize > imageMaxSize) {
				swal(`${this.props.t('Bilder bis 15 MB sind erlaubt')}`, '', 'info');
				return;
			}
			// console.log("img",img)
			img.onload = () => {
				// if (
				//   (img.width >= 960 && img.height >= 1280) ||
				//   (img.width >= 1920 && img.height >= 1200)
				// )
				// console.log("size",e.target.files[0]?.size)
				if (e.target.files[0]?.size >= 184676) {
					const reader = new FileReader();
					// console.log("reader",reader.result)
					reader.addEventListener('load', () =>
						this.setState({ src: reader.result, openSelectFile: false })
					);
					reader.readAsDataURL(e.target.files[0]);
				} else {
					// alert(
					//   ` Bitte laden Sie hier nur Bilder mit einer größeren Auflösung als 960x1280  Pixel hoch oder 1920 x 1200 Pixel hoch`
					// );
					swal(
						`${this.props.t('Bitte lade ein größeres Bild hoch')}`,
						'',
						'info'
					);
				}
			};
		}
	};

	componentDidMount() {
		// console.log("in componentDid")
		if (window.innerWidth > 1366) this.setState({ collapsed: true });
		window.addEventListener('resize', () => {
			if (window.innerWidth > 1366) this.setState({ collapsed: false });
			else this.setState({ collapsed: true });
		});

		this.setState({ loader: true });
		if (window.location.pathname === '/guests') {
			axios
				.get(baseUrl + `api/headerImage/${this.props.questionnaireId}`)
				.then((res) => {
					// console.log(res.data);
					if (res.data) {
						this.setState({
							croppedImageUrl: res.data.url
								? res.data.url
								: res.data.headerImage,
							loader: false,
						});
					}
				})
				.catch((error) => {
					// console.log(error);
				});
		} else {
			axios
				.get(
					baseUrl +
						`api/themes/${this.props.questionnaireId}/${this.props.selectedTemplateId}/${this.props.stepId}/0`
				)
				.then((res) => {
					// console.log(res.data);
					if (res.data) {
						this.setState({
							croppedImageUrl: res.data.base64String,
							loader: false,
						});
					}
				})
				.catch((error) => {
					// console.log(error);
				});
		}
	}
	deleteHeaderImage = () => {
		// this.setState({loader:true})
		this.props.setHeaderImage('');
		axios
			.post(
				baseUrl +
					`api/themes/${this.props.questionnaireId}/${this.props.selectedTemplateId}/${this.props.stepId}`,
				{
					base64String: '',
					// hideAdjustment: false,
				}
			)
			.then((_res) => {
				this.setState({ croppedImageUrl: '', loader: false });
			})
			.catch((error) => {
				console.log(error);
			});
	};

	onImageLoaded = (image) => {
		// console.log("onImageLoaded",image)
		this.imageRef = image;
	};

	handlePreview = (crop) => {
		// console.log("crop",crop)
		this.setState({ displayCropper: false, croppedImageBool: true });
		this.makeClientCrop(crop, 'frompreview');
		// const canvas = document.createElement("canvas");
		// this.blobApiCall(blob)
	};

	onCropComplete = (crop) => {
		// console.log("crop",crop)
		// let array=Object.assign([],this.state.array)
		// array.push(crop)
		// console.log("array",array[array.length-1])
		this.makeClientCrop(crop);
	};
	onCropChange = (crop, percentCrop) => {
		// You could also use percentCrop:
		// this.setState({ crop: percentCrop });
		this.setState({ crop });
	};

	async makeClientCrop(crop, val) {
		// console.log("val",val)
		// console.log("this.imageref",this.imageRef)
		if (this.imageRef && crop.width && crop.height) {
			const croppedImageUrl = await this.getCroppedImg(
				this.imageRef,
				crop,
				'newFile.jpeg',
				val
			);

			this.setState({ croppedImageUrl });
		}
	}

	getCroppedImg(image, crop, fileName, val) {
		const canvas = document.createElement('canvas');
		const pixelRatio = window.devicePixelRatio;
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext('2d');

		canvas.width = crop.width * pixelRatio * scaleX;
		canvas.height = crop.height * pixelRatio * scaleY;

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
		ctx.imageSmoothingQuality = 'high';

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width * scaleX,
			crop.height * scaleY
		);
		// let array=Object.assign({},image.src)

		this.setState({ image: image.src });
		// console.log("image",image.src)
		// console.log("croppedImageBool",this.state.croppedImageBool)
		// console.log("this.state.displayCropper",this.state.displayCropper)
		// console.log("src",this.state.src)
		// if(!this.state.src){
		//   this.b64toBlob(image.src, "image/png", 512)
		// }

		return new Promise((resolve, reject) => {
			canvas.toBlob(
				(blob) => {
					if (!blob) {
						//reject(new Error('Canvas is empty'));
						console.error('Canvas is empty');
						return;
					}
					blob.name = fileName;
					window.URL.revokeObjectURL(this.fileUrl);
					if (val) {
						this.setState({ loader: true });
						this.blobApiCall(blob);
					}
					// this.setState({blob})

					// console.log("blob",blob)

					// this.fileUrl = window.URL.createObjectURL(blob);
					// console.log(this.fileUrl)
					// resolve(this.fileUrl);
				},
				'image/png',
				1
			);
		});
	}
	b64toBlob = (b64Data, contentType, sliceSize) => {
		// // console.log("croppedImageBool",this.state.croppedImageBool)
		let data = b64Data.split('base64,')[1];
		const byteCharacters = atob(data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		// console.log("blob",blob)
		this.setState({ loader: true });
		const formData = new FormData();
		formData.append('blob', blob);
		axios
			.post(baseUrl + `api/blob/${2}`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then((res) => {
				// console.log("res",res)
				if (window.location.pathname === '/guests') {
					if (res.data.url) {
						axios
							.put(
								baseUrl +
									`api/headerImage/${
										Object.keys(this.props.obj).length
											? this.props.obj.questionnaireId
											: this.context.questionnaireId
									}`,
								{
									headerImage: res.data.url,
									questionnaireId: Object.keys(this.props.obj).length
										? this.props.obj.questionnaireId
										: this.context.questionnaireId,
								}
							)
							.then((_res) => {
								this.setState({
									croppedImageUrl: res.data.url,
									loader: false,
									hideAdjustment: false,
								});
							})
							.catch((error) => {
								console.log(error);
							});
					} else {
						axios
							.post(baseUrl + 'api/headerImage', {
								headerImage: res.data.url,
								hideAdjustment: false,
								// eventId: value.eventId,
								questionnaireId: Object.keys(this.props.obj).length
									? this.props.obj.questionnaireId
									: this.context.questionnaireId,
							})
							.then((_res) => {
								this.setState({ croppedImageUrl: res.data.url, loader: true });
							})
							.catch((error) => {
								console.log(error);
							});
					}
				} else {
					axios
						.post(
							baseUrl +
								`api/themes/${this.props.questionnaireId}/${this.props.selectedTemplateId}/${this.props.stepId}`,
							{
								base64String: res.data.url,
								// hideAdjustment: false,
							}
						)
						.then((_res) => {
							this.props.setHeaderImage(res.data.url);
							this.setState({
								croppedImageUrl: res.data.url,
								loader: false,
								hideAdjustment: false,
							});
						})
						.catch((error) => {
							console.log(error);
						});
				}

				this.setState({
					// preview: {
					//   url: res.data.url,
					//   loader:true
					// },
					croppedImageUrl: res.data.url,
					hideAdjustment: false,
				});
			});
		return blob;
	};
	blobApiCall = (blob) => {
		this.setState({ croppedImageUrl: '' });
		const formData = new FormData();
		formData.append('blob', blob);
		axios
			.post(baseUrl + `api/blob/${3}`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then((res) => {
				// console.log("res",res)

				if (window.location.pathname === '/guests') {
					if (res.data.url) {
						axios
							.put(baseUrl + `api/headerImage/${this.props.questionnaireId}`, {
								headerImage: res.data.url,
								questionnaireId: this.props.questionnaireId,
							})
							.then((_res) => {
								this.setState({
									croppedImageUrl: res.data.url,
									loader: false,
									hideAdjustment: false,
								});
							})
							.catch((error) => {
								console.log(error);
							});
					} else {
						axios
							.post(baseUrl + 'api/headerImage', {
								headerImage: res.data.url,
								hideAdjustment: false,
								// eventId: value.eventId,
								questionnaireId: this.props.questionnaireId,
							})
							.then((_res) => {
								this.setState({
									croppedImageUrl: res.data.url,
									loader: false,
								});
							})
							.catch((error) => {
								console.log(error);
							});
					}
				} else {
					axios
						.post(
							baseUrl +
								`api/themes/${this.props.questionnaireId}/${this.props.selectedTemplateId}/${this.props.stepId}`,
							{
								base64String: res.data.url,

								// hideAdjustment: false,
							}
						)
						.then((_res) => {
							this.props.setHeaderImage(res.data.url);
							this.setState({
								// croppedImageUrl: res.data.url,
								croppedImageUrl: '',
								loader: false,
								hideAdjustment: false,
							});
						})
						.catch((error) => {
							console.log(error);
						});
				}
			});
		return blob;
	};
	render() {
		const { crop, croppedImageUrl, src, collapsed } = this.state;

		return (
			<>
				{this.state.loader ? (
					<span style={{ marginTop: '50px' }}>{this.props.t('Loading')}</span>
				) : (
					<></>
				)}
				{
					<div
						style={{
							marginTop: window.location.pathname === '/guests' && '-5px',
							marginRight: window.location.pathname !== '/guests' && '-14.5px',
						}}>
						{(this.state.displayCropper === undefined &&
							!croppedImageUrl &&
							!this.props.headerImage) ||
						(this.state.displayCropper === false &&
							!croppedImageUrl &&
							!this.props.headerImage) ||
						(!src && !croppedImageUrl && !this.props.headerImage) ? (
							<div className="dropzone"></div>
						) : (
							<></>
						)}

						{src && this.state.displayCropper && (
							<div
								style={{
									background: '#d1d3d4',
									paddingLeft: collapsed === false ? '450px' : '280px',
								}}>
								<ReactCrop
									src={src}
									crop={crop}
									ruleOfThirds
									onImageLoaded={this.onImageLoaded}
									onComplete={this.onCropComplete}
									onChange={this.onCropChange}
									// onBlur={()=>{this.handleBlur(crop)}}
									// style={{marginTop:"0px",marginBottom:"0px"}}
									className="editor-canvas"
									// style={{width:"400px",height:"200px"}}
								/>
								<br />
								<input
									type="button"
									onClick={() => {
										this.handlePreview(crop);
									}}
									value="Einfügen"
								/>
							</div>
						)}
					</div>
				}

				<div className="image-upload">
					<label for="file-input" style={{ marginBottom: 0 }}>
						<img
							src={editIcon}
							alt={editIcon}
							style={{
								float: 'right',
								height: '2.5rem',
								width: '2.5rem',
								outline: 'none',
								background: '#d1d3d4',
								borderRadius: '0.5rem',
								padding: '0.5rem',
								position: 'absolute',
								right: '1rem',
								top: '1rem',
							}}
						/>
					</label>

					<input
						id="file-input"
						type="file"
						accept="image/*"
						onChange={(event) => {
							this.onSelectFile(event);
						}}
						onClick={(event) => {
							event.target.value = '';
						}}
					/>
				</div>
				{/* {console.log("in image display",window.location.pathname,"pathname",this.props.headerImage,
        "croppedImageUrl",croppedImageUrl,"src",src)} */}

				{console.log('displaycropper', this.state.displayCropper)}
				{window.location.pathname !== '/guests' &&
				this.props.headerImage &&
				this.state.displayCropper !== true ? (
					<>
						<div
							style={{
								backgroundImage: `url(${this.props.headerImage})`,
								backgroundRepeat: 'no-repeat',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								//backgroundSize: "100% 100%",
								width: '100%',
								height: '175px',
								marginBottom: '20px',
							}}></div>

						<div
							className="drop"
							style={{
								cursor: 'pointer',
								float: 'right',
								height: '2.5rem',
								width: '2.5rem',
								outline: 'none',
								background: '#d1d3d4',
								borderRadius: '0.5rem',
								padding: '0.45rem 0.7rem',
								position: 'absolute',
								right: '1rem',
								top: '4.5rem',
								justifyContent: 'center',
							}}>
							<img
								src={trash}
								onClick={this.deleteHeaderImage}
								style={{
									// margin: "0,5rem"
									height: 'auto',
									width: 'auto',
								}}
								alt=""
							/>
						</div>
					</>
				) : (
					<>
						{((croppedImageUrl && this.state.displayCropper !== true) ||
							(croppedImageUrl && !src) ||
							(croppedImageUrl &&
								(this.state.displayCropper !== true ||
									!this.state.displayCropper))) && (
							<>
								<div
									style={{
										backgroundImage: `url(${croppedImageUrl})`,
										backgroundRepeat: 'no-repeat',
										//backgroundSize: "100% 100%",
										backgroundSize: 'cover',
										backgroundPosition: 'center',
										width: '100%',
										height: '175px',
										marginBottom: '20px',
										marginTop: '-8px',
									}}></div>
							</>
						)}
					</>
				)}

				{/* {!this.state.displayCropper ? (
          <span>
            Bitte laden Sie hier nur Bilder mit einer größeren Auflösung als
            960x1280 Pixel hoch oder 1920 x 1200 Pixel hoch
          </span>
        ) : (
          <></>
        )} */}
			</>
		);
	}
}

export default withTranslation()(ImageComposer);

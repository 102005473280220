import React, { Component } from "react";
// import { Link } from "react-router-dom";
import LeftBar from "../partials/LeftBar";
import RightBar from "../partials/RightBar";
import { StateContext } from "../../context/state";
import axios from "axios";
import { baseUrl } from "../../Constants";
import Moment from "react-moment";
import "moment-timezone";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { withTranslation} from 'react-i18next';
class Landing extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      mainPrice: "7462.00",
      events: [],
      paragraphs: [],
      dateFormat:""
      // eventDateLinks: [
      //   "30.09.2019",
      //   "03.10.2019",
      //   "20.12.2019",
      //   "15.04.2020",
      //   "22.05.2020"
      // ]
    };
  }
  componentDidMount() {
  
    let body = document.getElementsByTagName("body")[0];
    body.style.backgroundImage = "";
    const data = this.context;
    localStorage.clear('state')
   
    axios
      .get(`${baseUrl}api/venue/${data.venueId}`)
      .then((resp) => {
        
        this.setState({
          paragraphs: resp.data.paragraphs? resp.data.paragraphs : null,
        dateFormat:resp.data?.dateFormat
        });
      })
      .catch((err) => {
        console.log(err);
      });

    var id;
    if (data.userId?.length) {
      id = data.userId;
    } else {
      id = data.loggedInUserId;
    }
    axios
      .get(`${baseUrl}api/user/EventByUser/${id}`)

      .then((resp) => {
        // console.log('data', resp.data)
        this.setState({
          events: resp.data ? resp.data : [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleEventClick = (item) => {
    // console.log('wtnnaireId', questionnaireId, this.state.events)
    // console.log("landing:","item._id",item._id,"item.childQuestionnaireId",item.childQuestionnaireId,
    // "item.eventDate",item.eventDate,"item.eventName",item.eventName,"item.parentQuestionnaireId",item.parentQuestionnaireId
    // ,"item.type",item.type,"item.deadlineForEntries",item.deadlineForEntries)
    this.context.updateState({
      eventId: item._id,
      questionnaireId: item.childQuestionnaireId,
      eventDate: item.eventDate,
      eventName: item.eventName,
      parentQuestionnaireId:item.parentQuestionnaireId,
      eventType:item.type,
      deadlineForEntries:item.deadlineForEntries,
    });
    this.props.history.push("/EventLanding", {
      from: "landing",
    });
  };
  // functionCapitalise = () => {
  //   let title = "";
  //   if (this.context?.cuDetails?.title1) {
  //     title = this.context?.cuDetails?.title1[0]
  //       .toUpperCase()
  //       .concat(
  //         this.context?.cuDetails?.title1.slice(
  //           1,
  //           this.context?.cuDetails?.title1.length 
  //         )
  //       );
  //   }
    
  //   return title;
  // };
  functionCapitalise = (initialTitle) => {
    let title = "";
    if (initialTitle) {
      title = initialTitle[0]
        .toUpperCase()
        .concat(
          initialTitle.slice(
            1,
            initialTitle.length 
          )
        );
    }
    
    return title;
  };
 
  render() {
 const {cuDetails}= this.context
    return (
      <div className="container login-landing">
        <div className="col-2 toDisplay" >
          <LeftBar />
        </div>
        <div className="col-10" style={{marginLeft: "50px"}}>
        {/* {console.log('title', this.context?.cuDetails?.title1)} */}
          <h5>
            {/* Sehr geehrter {this.functionCapitalise()}{" "}
            {this.context?.cuDetails?.surname1}, */}
             {cuDetails?.surname1 ? `${cuDetails?.title1==="frau"?`${this.props.t('Sehr geehrte')}`:`${this.props.t('Sehr geehrter')}`} ${this.props.t(this.functionCapitalise(cuDetails?.title1))} ${cuDetails?.surname1}${cuDetails?.surname2 ? `,`: ""} `: ""}
              
              {cuDetails?.surname2 ? `${cuDetails?.title2==="frau"?`${this.props.t('Sehr geehrte')}`:`${this.props.t('Sehr geehrter')}`} ${this.props.t(this.functionCapitalise(cuDetails?.title2))} ${cuDetails?.surname2},`
               :""}
          </h5>

          {this.state.paragraphs.length!==0 || Object.keys(this.state.paragraphs).length!==0? (
            <div
              style={{ marginTop: "20px" }}
              dangerouslySetInnerHTML={{
                __html: draftToHtml(
                  convertToRaw(
                    EditorState.createWithContent(
                      convertFromRaw(this.state.paragraphs)
                    ).getCurrentContent()
                  )
                ),
              }}
            ></div>
          ) :  <p
          style={{
            color: `${this.state.textColor}`,
            fontFamily: `${this.state.textFontFamily}`,
            fontSize: `${this.state.textFontSize}px`,
          }}
        >
        {this.props.t('No text available')}
        </p>}

          {/* {console.log("paragraphs",this.state.paragraphs)} */}
          {/* {this.state.paragraphs!=undefined && this.state.paragraphs.length ? (
            this.state.paragraphs?.map((para, i) => {
            
              return <p key={i}>{para?.text}</p>;
            })
          ) : (
            <p>No text available</p>
          )} */}


          {/* <p>
          Herzlich willkommen zu Ihre Konfigurator für Ihre Events bei uns.
          <br />
          Unten können Sie das Event auswählen den Sie konfigurieren möchte.
        </p>
        <p>
          Bei Fragen stehen wir jeder Zeit zur Verfügung. Kontaktinfo zu Ihre
          Kontaktperson bei uns finden Sie unten links.
          <br />
          <b>Viel Spaß bei Ihrer Planung!</b>
        </p> */}
          {/* <p className="signature">Ihr Hofgut Lilienhof Team</p> */}
          <div className="event-dates">
            {this.state.events.length ? this.state.events.map((item) => (
              <div
              style={{wordWrap:"anywhere"}}
                key={item._id}
                className="individual word"
                onClick={() => {
                  this.handleEventClick(item);
                }}
              >
                {item.eventName}
                <br />
                <Moment format={this.state.dateFormat?this.state.dateFormat:'DD/MM/YYYY'}>{item.eventDate}</Moment>
              </div>
            ))
          :null}
          </div>
        </div>
<div className="displayRightbar">
        <RightBar />
        {/* <PullbarPreview/> */}
        </div>
      </div>
    );
  }
}

export default withTranslation() (Landing);

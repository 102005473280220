import React, {Component} from "react";
import { StateContext } from "../../context/state";
import { withTranslation} from 'react-i18next';
 
class Text extends Component {
    static contextType = StateContext;
    render() {
        const headingFontSize = this.context.stylingData?.headingFontSize;
        const headingFontFamily = this.context.stylingData?.headingFontFamily;
        const headingColor = this.context.stylingData?.textColor ? this.context.stylingData?.textColor :"#939598";
     
        const {value, style,fontFamily,fontSize,color} = this.props;
        // console.log("value",value);
// console.log("fontfamily",fontFamily)
// console.log("fontsize",fontSize)
        let textStyle = {...styles.text, ...style};
        textStyle.fontSize=fontSize?fontSize:headingFontSize
        textStyle.fontFamily=fontFamily?fontFamily:headingFontFamily
       
         if(window.location.pathname==='/questionnaire-preview'){
            textStyle.color=color?color:headingColor
        }
        // console.log("textstyle",textStyle)
        return (
            <p style={textStyle}>{this.props.t(value)}</p>
        );
    }
}

const styles = {
    text: {
        border: "0",
        // fontSize: "24px",
        // fontFamily: "Crete Round",
        color: "#939598",
        padding: "10px",
        margin: "0",
        marginTop:"5px"
    }
};

export default withTranslation() (Text);
import React, { Component } from "react";
import { StateContext } from "../../context/state";
import { withRouter } from "react-router";
import axios from "axios";
import { baseUrl } from "../../Constants";
import collapseToggleIcon from "../../assets/collapse.svg";
import { withTranslation} from 'react-i18next';

class RightBar extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      cuDetails: [],
      obj: {}
      // addressTitle: "Bosch GmbH",
      // addressMain: "Gilbert Reichhausen, Fischartstraße 5, 80686 München",
      // addressTel: "Tel. 0157 3434 7248",
      // addressMail: "gilbert.reichhausen@bosch.com"
    };
  }
  collapseToggle = () => {
    this.setState((prevState) => ({ collapsed: !prevState.collapsed }));
  };
  componentDidMount = () => {
    if (window.innerWidth < 1306) this.setState({ collapsed: true });
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1306) this.setState({ collapsed: true });
      else this.setState({ collapsed: false });
    });
    let data = this.context;
    const obj = JSON.parse(sessionStorage.getItem("state2"));

    if (obj) {
      this.setState({ obj: obj });
    }
    // let data
    // // console.log("this.props",this.props)
    //    if(this.props.history.location.pathname=="/EventLanding"){
    //     const obj=JSON.parse(localStorage.getItem("state"))

    //     this.setState({obj:obj})
    // data=obj
    // // console.log("data",data)
    //    }
    //    else{
    //     data=this.context
    //    }

    // console.log("data",data)
    let eventId = obj ? obj?.eventId : data?.eventId;
    var id;
    if (eventId) {
      axios
        .get(`${baseUrl}api/user/ByEvent/${eventId}/CU`)
        .then((response) => {
          // console.log('cu resp',response.data)
          this.context.updateState({ cuDetails: response.data });
          this.setState({
            cuDetails: response.data,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      id = this.context.loggedInUserId;
      axios
        .get(`${baseUrl}api/user/${id}`)
        .then((response) => {
												 
          this.context.updateState({ cuDetails: response.data });
          // console.log("lang",response.data.languageFormat);
        //  i18next.changeLanguage(response.data.languageFormat);
       
          this.setState({
            cuDetails: response.data,  
          });
        })
        .catch((err) => {
          console.log(err);
        });
     
    }

  };
  toUserDetailsPage = () => {
    const { history } = this.props;
    const couple = this.context.cuDetails;
    // console.log('clicked', history, couple)
    if (!history) { return; }
    history.push("/userInfo", {
      user: { ...couple, userId: couple._id },
      disabled: false,
    });
  };
  functionCapitalise = () => {
    let title = "";
    if (this.context?.cuDetails?.title1) {
      title = this.context?.cuDetails?.title1[0]
        .toUpperCase()
        .concat(
          this.context?.cuDetails?.title1.slice(
            1,
            this.context?.cuDetails?.title1.length
          )
        );
    }
    // console.log('title', title)
   
    return title;
  };
  functionCapitaliseTitle2 = () => {
    let title = "";
    if (this.context?.cuDetails?.title2) {
      title = this.context?.cuDetails?.title2[0]
        .toUpperCase()
        .concat(
          this.context?.cuDetails?.title2.slice(
            1,
            this.context?.cuDetails?.title2.length
          )
        );
    }
    // console.log('title', title)
    return title;
  };
  render() {
    return (
      <StateContext.Consumer>
        {({ userRole }) => {
          const { cuDetails } = this.state;
          return (
            <div
              className={`right-bar ${this.state.collapsed ? "collapsed" : ""}`}
            >
              <button
                className={`right-bar-collapse ${
                  this.state.collapsed ? "collapsed" : ""
                } display`}
                onClick={this.collapseToggle.bind(this)}
              >
                <img src={collapseToggleIcon} alt="" />
              </button>
              {(userRole === "couple" || userRole === "event-manager") && (
                <div className="pricing-section">
                  <h6>Preis Ihrer Event</h6>
                  <h1>7.462,00 €</h1>
                  <p className="below-price">Event konfigurieren</p>
                  <div className="seperator"></div>
                  <div className="price-item">
                    120 stk. Erwachsene & 10 stk.Kinder
                  </div>
                  <div className="price-number">6.392,00 €</div>
                  <div className="price-item">
                    Servicepauschalefür die Nacht
                  </div>
                  <div className="price-number">190,00 €</div>
                  <div className="price-item">Bar Ort der Bar : Wiese</div>
                  <div className="price-number">790,00 €</div>
                  <div className="price-item">Technikpaket</div>
                  <div className="price-number">90,00 €</div>
                </div>
              )}
              <div
                className="bottom-section"
                // style={{padding: "30px 0px 30px 73px"}}
                style={{ right: "5px" }}
              >
                <p style={styles.title}>  {this.props.t('Billing_address')} </p>

                <p
                  style={{ ...styles.text, ...styles.pointer }}
                  onClick={() => this.toUserDetailsPage()}
                >
                  {this.props.t('Change')}
                </p>
                {/* {(cuDetails?.firstName1?.length)? */}
                <>
                  <br />
                  {/* <p style={styles.bold}>{cuDetails?.firstName1} {cuDetails?.surname1}</p> */}
                  {window.location.pathname === "/eventsWelcome" ? (
                    <>
                      <p style={styles.text}>Maxi Mustermann</p>
                      <p style={styles.text}>Musterstraße 10</p>
                      <p style={styles.text}>12345 Musterstetten</p>
                      <br />
                      <p style={styles.text}>Tel. 01234567891</p>
                      <p style={styles.text}>maxi@muster.de</p>
                    </>
                  ) : (
                    <>
                      {cuDetails?.companyName ? (
                        <p style={styles.text}>{cuDetails?.companyName}</p>
                      ) : (
                        <>
                          <p style={styles.bold}>
                            {cuDetails?.firstName1} {cuDetails?.surname1}
                          </p>
                          <p style={styles.bold}>
                            {cuDetails?.firstName2} {cuDetails?.surname2}
                          </p>
                        </>
                      )}
                      {/* <p style={styles.text}>{cuDetails?.companyName || "undefiniert"}</p> */}
                      <p style={styles.text}>
                        {cuDetails?.streetNumber || `${this.props.t('undefiniert')}` }
                      </p>
                      <p style={styles.text}>
                        {cuDetails.postCode || `${this.props.t('undefiniert')}`}{" "}
                        {cuDetails.state || `${this.props.t('undefiniert')}`}
                      </p>
                      <br />
                      <p style={styles.text}>
                        {cuDetails?.telNumber1 || `${this.props.t('undefiniert')}`}
                      </p>
                      <p style={styles.text}>
                        {cuDetails?.email1 || `${this.props.t('undefiniert')}`}
                      </p>
                    </>
                  )}
                </>
                {/* :null                } */}
              </div>
            </div>
          );
        }}
      </StateContext.Consumer>
    );
  }
}

export default withTranslation() (withRouter(RightBar));

const styles = {
  title: {
    fontFamily: "Crete Round",
    fontSize: "19px",
    color: "#000",
    margin: "0px",
  },
  bold: {
    fontSize: "16px",
    margin: "0px",
    color: "#000",
    fontWeight: "600",
  },
  text: {
    fontSize: "16px",
    fontWeight: "300",
    margin: "0px",
    color: "#000",
  },
  cost: {
    fontSize: "40px",
    fontWeight: "600",
    color: "#000",
  },
  separator: {
    display: "block",
    width: "100%",
    height: "2px",
    background: "#297825",
    marginBottom: "20px",
  },
  container: {
    textAlign: "right",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    padding: "60px 0 10px 20px",
  },
  pointer: {
    cursor: "pointer",
  },
};

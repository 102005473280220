import React, { useState, useEffect, useContext } from "react";
import { baseUrl } from "../../Constants";
import Dropzone from "react-dropzone";
import DataPopup from "./dataPopup";
import EventsParagraphs from "./EventsParagraphs";
import upload from "../../assets/upload.svg";
import { ChromePicker } from "react-color";
import "../../venueSettingStyles.css";
import axios from "axios";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { StateContext } from "../../context/state";
import swal from "sweetalert";
import NewPassword from "./NewPassword";
import ForgotPassword from "./ForgotPassword";

import i18next from 'i18next';
import { useTranslation } from "react-i18next";
const imageMaxSize = 10240;

export default function SettingsMain(props) {
  const { t } = useTranslation();
  const [newEntry, setNewEntry] = useState(false);
  const [time, setTime] = useState("24");
  const [date, setDate] = useState("DD/MM/YY");
  const [timeFormats, setTimeFormats] = useState([]);
  const [dateFormats, setDateFormats] = useState([]);
  const[currency,setCurrency]= useState("€");
  const [currencyFormats, setCurrencyFormats] = useState([]);
  const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false);
  const [timeDropDownOpen, setTimeDropdownOpen] = useState(false);
  const [fontDropDownOpen, toggleFontDropdown] = useState(false);
  const [textNoteDropDownOpen,toggleNoteDropdown]=useState(false);
 
//  const [invoiceReceipt,setInvoiceReceipt]=useState("");
  const [fonts, setFonts] = useState([]);
  const [langDropDownOpen, setLangDropdownOpen] = useState(false);
  const [language, setLanguage] = useState("en");
  const [languages, setLanguages]=useState([]);


  //----------------questionnaire--------------
  const [questionnaireDropDownOpen, setQuestionnaireDropDownOpen] = useState(
    false
  );
  const [textFontDropDownOpen, setTextFontDropDownOpen] = useState(false);
  const [headingFontDropDownOpen, setHeadingFontDropDownOpen] = useState(false);
  const [textFontSizeDropDownOpen, setTextFontSizeDropDownOpen] = useState(
    false
  );
  const [
    headingFontSizeDropDownOpen,
    setHeadingFontSizeDropDownOpen,
  ] = useState(false);
  const [eventLogoSrc, setEventLogoSrc] = useState(null);
  const [questionnaireOptions, setQuestionnaireOptions] = useState([]);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState("");

  const [textColor, setTextColor] = useState("#fff");
  const [showTextColorPicker, setShowTextColorPicker] = useState(false);
  const [, setHeadingColor] = useState("#fff");

  const [lineColor, setLineColor] = useState("#fff");
  const [showLineColorPicker, setShowLineColorPicker] = useState(false);
  const [displayFlag, setDisplayFlag] = useState(false);
  const [fontSizes, setFontSizes] = useState([]);

  const [selectedTextFontSize, setSelectedTextFontSize] = useState("");
  const [selectedTextFontFamily, setSelectedTextFontFamily] = useState("");
  const [selectedHeadingFontFamily, setSelectedHeadingFontFamily] = useState(
    ""
  );
  const [selectedNote,setSelectedNote]=useState({})
  const [selectedHeadingFontSize, setSelectedHeadingFontSize] = useState("");

  const [validateQuestionairre, setValidateQuestionairre] = useState(true);
  const [validateEventLogo, setValidateEventLogo] = useState(true);
  // const [validateTextFontSize, setValidateTextFontSize]=useState(true)
  const [validateTextFontFamily, setValidateTextFontFamily] = useState(true);
  const[validateNote,setValidateNote]=useState(true)
  const [validateHeadingFontFamily, setValidateHeadingFontFamily] = useState(
    true
  );
  // const [validateHeadingFontSize, setValidateHeadingFontSize]=useState(true)

  //---------------venue------------------
  const [validateWelcomeText, setValidateWelcomeText] = useState(true);
  // const [validateInvoice,setValidateInvoice]=useState(true)
  const [validateWelcomeTextFont, setValidateWelcomeTextFont] = useState(true);
  const [validateBackgroundImg, setValidateBackgroundImg] = useState(true);
  const [validateVenueLogo, setValidateVenueLogo] = useState(true);
  const [welcomeText, setWelcomeText] = useState("");
  // const [invoice, setInvoice] = useState("");
  // const [invoiceSaved,setInvoiceSave]=useState("")
  const [welcomeTextColor, setWelcomeTextColor] = useState("#fff");
  const [WelcomeTextColorPicker, setWelcomeTextColorPicker] = useState(false);
  const [selectedFont, setSelectedFont] = useState("");
  const [backgroundImgSrc, setBackgroundImgSrc] = useState(null);
  const [venueLogoSrc, setVenueLogoSrc] = useState(null);
  const [venueName, setVenueName] = useState("");
  const [venueURL, setVenueURL] = useState("");
  const [paragraphs, setParagraphs] = useState([]);
  const [Questionaireparagraphs, setQuestionaireParagraphs] = useState(null);
  const [link, setLink] = useState("");
  const [vvoAssignmentDate, setVvoAssignmentDate] = useState("0");
  const [, setValidateVvoDays] = useState(true);
  const [showPopup, handlePopup] = useState(false);
  const [showQuestionairePopup, handleQuestionairePopup] = useState(false);
  const [showNewPasswordPopup, handleNewPasswordPopup] = useState(false);
  const [showForgotPasswordPopup, handleForgotPasswordPopup] = useState(false);
  const [note,]=useState([{name:"Brutto",value:"*Alle Preise verstehen sich Brutto, inklusive der gesetzlichen Mwst."},
{name:"Netto",value:"*Alle Preise verstehen sich Netto, zuzüglich der gesetzlichen Mwst."}])
  let value = useContext(StateContext);

  // "5eb946868180cb00b08e06d0"
  const venueId = value.venueId ? value.venueId : null;
  const [questionnaireId, setQuestionnaireId] = useState(null);
  

  useEffect(() => {
    const para = props.location.state?.para;
    const id = props.location.state?.id;
    const name = props.location.state?.name;
    // console.log('para', para)
    if (para) {
      // setQuestionaireParagraphs(para);
      const q = { _id: id, name: name };
      handleSelectedQuest(q, "secondTime");
    }
  
    axios
    .get(`${baseUrl}api/quest/GetQuestionnaireByVenue/${venueId}`)
    .then((response) => {
      setQuestionnaireOptions(response.data);
    })
    .catch((err) => {
      console.log(err);
    });

  
    axios
      .get(baseUrl + "api/lookup/VenueSettingScreen")
      .then((response) => {
        // console.log("response- venuesettingScreen", response);
        for (let i in response.data) {
          if (i === "dateFormats") {
          setDateFormats(response.data[i]);
          }
          if (i === "timeFormats") {
            setTimeFormats(response.data[i]);
            // console.log("timeFormats",timeFormats);
            // console.log("response data-timeformats", response.data); 
          }
          if (i === "fonts") {
            let resp=[]
          
            resp.push("Arial","Georgia","Impact","Tahoma","Times New Roman","Verdana")
        
            setFonts(resp);

          }
          if (i === "fontSizes") {
            setFontSizes(response.data[i]);

          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${baseUrl}api/venue/currencies/`)
      .then((response) => {
        // console.log("response-currencies", response.data);
        setCurrencyFormats(response.data);
        // console.log("currencyFormats",currencyFormats);
        // setCurrency(response.data.symbol);
        // console.log("currency", currency);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${baseUrl}api/venue/languages/`)
      .then((response) => {
        // console.log("response-languages", response.data);
        setLanguages(response.data);
       
        // console.log("Languages",languages);
        // setCurrency(response.data.symbol);
        // console.log("currency", currency);
      })
      .catch((err) => {
        console.log(err);
      });

    if (venueId) {
      axios
        .get(`${baseUrl}api/venue/${venueId}`)
        .then((response) => {
          const data = response.data;
          setDate(data.dateFormat);
          setBackgroundImgSrc(data.backgroundImgSrc);
          setTime(data.timeFormat);
          setVenueName(data.venueName);
          setVenueURL(data.venueURL);
          setVenueLogoSrc(data.venueLogoSrc);
          setWelcomeText(data.welcomeText);
          // setInvoice(data.invoice)
          // setInvoiceSave(data.invoice)
          setWelcomeTextColor(data.welcomeTextColor);
          setSelectedFont(data.welcomeTextFont);
          setParagraphs(data.paragraphs ? data.paragraphs : []);
          setCurrency(data.currencyFormat);
          setLanguage(data.languageFormat);
          i18next.changeLanguage(data.languageFormat);
        })
        .catch((err) => {
          // console.log(err)
        });
      // console.log("venue", venueId);
      // axios
      //   .get(`${baseUrl}api/quest/GetQuestionnaireByVenue/${venueId}`)
      //   .then((response) => {
      //     setQuestionnaireOptions(response.data);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    }
  }, [props.location.state, venueId]);
  const handleSave = () => {
    // console.log("save function");
    // console.log("displayFlag",displayFlag);
    if (!displayFlag) {
      let venueData = {
        venueName: venueName,
        venueURL: venueURL,
        timeFormat: time,
        dateFormat: date,
        welcomeText: welcomeText,
        // invoice:invoice,
        // invoiceReceipt:invoice,
        welcomeTextColor: welcomeTextColor,
        welcomeTextFont: selectedFont,
        backgroundImgSrc: backgroundImgSrc,
        venueLogoSrc: venueLogoSrc,
        paragraphs: paragraphs,
        isDeleted: false,
        currencyFormat:currency,
        languageFormat:language,
      };
      let validate = true;
      for (let i in venueData) {
        if (i === "welcomeText" && !venueData[i].length) {
          validate = false;
          setValidateWelcomeText(false);
        }
      //   if(value.userRole !== "SA"){
      //   if (i === "invoice" && !venueData[i]?.length) {
      //     validate = false;
      //     setValidateInvoice(false);
      //   }
      // }
        if (i === "welcomeTextFont" && !venueData[i].length) {
          validate = false;
          setValidateWelcomeTextFont(false);
        }
        if (i === "backgroundImgSrc" && !venueData[i]) {
          validate = false;
          setValidateBackgroundImg(false);
        }
        if (i === "venueLogoSrc" && !venueData[i]) {
          validate = false;
          setValidateVenueLogo(false);
        }
      }
      if (validate) {
        axios
          .put(`${baseUrl}api/venue/${venueId}`, venueData)
          .then((response) => {
            value.updateState({
              currencyFormat: currency,
              languageFormat: language,
            });
            i18next.changeLanguage(venueData.languageFormat);
            swal(t('Gespeichert!'), "", "success");
            // props.history.push(`/admin/events`)
          })
          .catch((err) => {
            console.log(err);
          });
      }
     } else {
      let venueData = {
        venueName: venueName,
        venueURL: venueURL,
        timeFormat: time,
        dateFormat: date,
        welcomeText: welcomeText,
        // invoice:invoice,
        // invoiceReceipt:invoice,
        welcomeTextColor: welcomeTextColor,
        welcomeTextFont: selectedFont,
        backgroundImgSrc: backgroundImgSrc,
        venueLogoSrc: venueLogoSrc,
        paragraphs: paragraphs,
        isDeleted: false,
        currencyFormat:currency,
        languageFormat:language,
      };
 
      let validate = true;
      for (let i in venueData) {
        if (i === "welcomeText" && !venueData[i].length) {
          validate = false;
          setValidateWelcomeText(false);
        }
      //   if(value.userRole !== "SA"){
      //   if (i === "invoice" && !venueData[i]?.length) {
      //     validate = false;
      //     setValidateInvoice(false);
      //   }
      // }
        if (i === "welcomeTextFont" && !venueData[i].length) {
          validate = false;
          setValidateWelcomeTextFont(false);
        }
        if (i === "backgroundImgSrc" && !venueData[i]) {
          validate = false;
          setValidateBackgroundImg(false);
        }
        if (i === "venueLogoSrc" && !venueData[i]) {
          validate = false;
          setValidateVenueLogo(false);
        }
      }
      if (validate) {
        axios
          .put(`${baseUrl}api/venue/${venueId}`, venueData)
          .then((response) => {
            // swal("Venue is updated!","", "success");
            // console.log('put venue done', response.data)
          })
          .catch((err) => {
            console.log(err);
          });
      }
    
      let qtnnaireData = {
        questionnaireId: questionnaireId,
        questionnaireLogoSrc: eventLogoSrc,
        questionairre: selectedQuestionnaire,
        textColor: textColor,
        // headingColor: headingColor,
        lineColor: lineColor,
        textFontSize: selectedTextFontSize,
        textFontFamily: selectedTextFontFamily,
        note:selectedNote,
        headingFontFamily: selectedHeadingFontFamily,
        headingFontSize: selectedHeadingFontSize,
        vvoAssignmentDate: vvoAssignmentDate,
        Questionaireparagraphs: Questionaireparagraphs,
        link: link,
        dateFormat: date,
      
      };
      let validateqtnnaireData = true;
      for (let i in qtnnaireData) {
        if (i === "questionairre" && !qtnnaireData[i].length) {
          validateqtnnaireData = false;
          setValidateQuestionairre(false);
        }
        if (i === "questionnaireLogoSrc" && !qtnnaireData[i]) {
          validateqtnnaireData = false;
          setValidateEventLogo(false);
        }
        if (i === "textFontFamily" && !qtnnaireData[i].length) {
          validateqtnnaireData = false;
          setValidateTextFontFamily(false);
        }
        if (i === "note" && !Object.keys(qtnnaireData[i]).length) {
          validateqtnnaireData = false;
          setValidateNote(false);
        }
        if (i === "headingFontFamily" && !qtnnaireData[i].length) {
          validateqtnnaireData = false;
          setValidateHeadingFontFamily(false);
        }
        // if (i === "vvoAssignmentDate") {
        //   if (Number(qtnnaireData[i]) > 14) {
        //     validateqtnnaireData = true;
        //     setValidateVvoDays(true);
        //   } else {
        //     validateqtnnaireData = false;
        //     setValidateVvoDays(false);
        //   }
        // }
      }
    
      if (validateqtnnaireData) {
      
        if (newEntry) {
          axios
            .post(baseUrl + "api/questionnaireSetting", qtnnaireData)
            .then((response) => {
              // console.log('post questionnaire done', response.data)
              if (response.data.id) {
                // swal("Gespeichert!", "", "success");
                swal(t("Gespeichert!"), "", "success");
               
                // props.history.push(`/admin/events`)
              }
            })
            .catch((err) => {
              console.log(err);
              swal(t("Gönnen Sie sich einen Kaffee, hier hat sich ein Fehler eingeschlichen!!"), "", "error");
            });
        } else {
          axios
            .put(
              `${baseUrl}api/questionnaireSetting/${questionnaireId}`,
              qtnnaireData
            )
            .then((response) => {
              swal(t("Gespeichert!"), "", "success");
              // props.history.push(`/admin/events`)
              // console.log('put questionnaire done', response.data)
            })
            .catch((err) => {
              console.log(err);
              swal(t("Gönnen Sie sich einen Kaffee, hier hat sich ein Fehler eingeschlichen!!"), "", "error");
            });
        }
      }
    }
    // value.updateState({ dateFormat: date });
  };

  const handleShowTextColorPicker = () => {
    setShowTextColorPicker(!showTextColorPicker);
  };

  const handleShowLineColorPicker = () => {
    setShowLineColorPicker(!showLineColorPicker);
  };
  

  const verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileSize = Math.round(currentFile.size / 1024);
      if (currentFileSize > imageMaxSize) {

        // swal(`Diese Datei ist zu groß`, "", "info");
        swal(t('Bilder bis 15 MB sind erlaubt'), "", "info");

        return false;
      }
      return true;
    }
  };

  const handleOnDropEventLogo = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyImageFile(rejectedFiles);
    }

    if (acceptedFiles && acceptedFiles.length > 0) {
      const isVerified = verifyImageFile(acceptedFiles);
      if (isVerified) {
        // imgBase64Data
        const currentFile = acceptedFiles[0];
        // const image = URL.createObjectURL(currentFile);
        // console.log('img', currentFile)

        const data = new FormData();
        data.append("blob", currentFile);
        axios
          .post(baseUrl +`api/blob/${1}`, data, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            //  console.log(res.data)
            setEventLogoSrc(res.data.url);
            setValidateEventLogo(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  const handleOnDropBackgroundImg = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyImageFile(rejectedFiles);
    }

    if (acceptedFiles && acceptedFiles.length > 0) {
      const isVerified = verifyImageFile(acceptedFiles);
      if (isVerified) {
        const currentFile = acceptedFiles[0];
        const data = new FormData();
        data.append("blob", currentFile);
        axios
          .post(baseUrl + `api/blob/${1}`, data, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            setBackgroundImgSrc(res.data.url);
            setValidateBackgroundImg(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  const handleOnDropVenueLogo = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyImageFile(rejectedFiles);
    }

    if (acceptedFiles && acceptedFiles.length > 0) {
      const isVerified = verifyImageFile(acceptedFiles);
      if (isVerified) {
        const currentFile = acceptedFiles[0];
        const data = new FormData();
        data.append("blob", currentFile);
        axios
          .post(baseUrl + `api/blob/${3}`, data, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            setVenueLogoSrc(res.data.url);
            setValidateVenueLogo(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  const handleShowWelcomeTextColorPicker = () => {
    setWelcomeTextColorPicker(!WelcomeTextColorPicker);
  };

  // const handleCurrency=()=>{
  // setCurrency(d);
  //   axios
  //   .get(`${baseUrl}api/venue/currencies/`)
  //    .then((response) => {
  //           // console.log("response",response)
  //           const data = response.data;
  //   })
  //   .catch((err) => {
  //           setDisplayFlag(true);
  //         })
        
  //       }


  const handleSelectedQuest = (q, label) => {
    setQuestionnaireId(q._id);
    setSelectedQuestionnaire(q.name);
    axios
      .get(`${baseUrl}api/questionnaireSetting/${q._id}`)
      .then((response) => {
        // console.log("response",response)
        const data = response.data;
        // setQuestionnaireId(data.questionnaireId)
        if (Object.keys(data).length) {
          setEventLogoSrc(
            data.questionnaireLogoSrc ? data.questionnaireLogoSrc : ""
          );
          // setHeadingColor(data.headingColor ? data.headingColor : "#000");
          setSelectedHeadingFontFamily(
            data.headingFontFamily ? data.headingFontFamily : ""
          );
          setSelectedHeadingFontSize(
            data.headingFontSize ? data.headingFontSize : ""
          );
          setLineColor(data.lineColor ? data.lineColor : "#000");
          setTextColor(data.textColor ? data.textColor : "#000");
          setSelectedTextFontFamily(
            data.textFontFamily ? data.textFontFamily : ""
          );
          setSelectedNote(data.note ?data.note:{})
          setSelectedTextFontSize(data.textFontSize ? data.textFontSize : "");
          setVvoAssignmentDate(
            data.vvoAssignmentDate ? data.vvoAssignmentDate : "0"
          );
          setLink(data?.link ? data.link : "");
          // setQuestionaireParagraphs(data.Questionaireparagraphs ? data.Questionaireparagraphs : []);
          setDisplayFlag(true);
         
          // if (!label) {
            // const alternate = [
            //   "uns liegt es am Herzen, dass Sie und Ihre Gäste einen inspirierenden und unvergesslichen Aufenthalt bei uns auf dem Hofgut Lilienhof erleben,",
            //   ">ein Event genau nach Ihren Vorstellungen mit vielen persönlichen Highlights",

            //   ">ein Event, die Sie und Ihre Gäste genießen und in bleibender Erinnerung behalten können",

            //   ">ein Event, die Sie beide näher verbindet und ein Meilenstein für Ihre gemeinsame Zukunft wird.",
            //   "Um diesem Anspruch gerecht zu werden und um den viel besagten ,Event-Stress“ im Vorfeld und während der Feier für Sie auf ein Minimum zu reduzieren,sind wir auf Ihre Mithilfe angewiesen.",
            //   " Besonders hervorheben möchten wir an dieser Stelle das Einhalten von Fristen und Terminen. Bitte nehmen Sie sich kurz Zeit, um sich mit unserer Zeitschiene vertraut zu machen. Dieser Online Fragebogen vereinfacht die Planung und Abstimmung Ihrer Feier auf dem Hofgut Lilienhof und wird das zentrale Tool Ihrer und unserer Vorbereitungen werden. Bitte zögern Sie nicht, uns bei Fragen oder offenen Punkten zu kontaktieren – wir helfen gerne!",
            //   "Viel Spaß bei Ihrer Planung!",
            // ];
           
            setQuestionaireParagraphs(
             data.Questionaireparagraphs? data.Questionaireparagraphs: data.questionairre
               
            );
           
          // }
        } else {
          setEventLogoSrc(null);
          setHeadingColor("#000");
          setSelectedHeadingFontFamily("");
          setSelectedHeadingFontSize("");
          setLineColor("#000");
          setTextColor("#000");
          setSelectedTextFontFamily("");
          setSelectedNote({});
          setSelectedTextFontSize("");
          setVvoAssignmentDate("0");
          setLink("");
          setDisplayFlag(true);
          setNewEntry(true);
         

          // setQuestionaireParagraphs([
          //   "uns liegt es am Herzen, dass Sie und Ihre Gäste einen inspirierenden und unvergesslichen Aufenthalt bei uns auf dem Hofgut Lilienhof erleben,",
          //   ">ein Event genau nach Ihren Vorstellungen mit vielen persönlichen Highlights",

          //   ">ein Event, die Sie und Ihre Gäste genießen und in bleibender Erinnerung behalten können",

          //   ">ein Event, die Sie beide näher verbindet und ein Meilenstein für Ihre gemeinsame Zukunft wird.",
          //   "Um diesem Anspruch gerecht zu werden und um den viel besagten ,Event-Stress“ im Vorfeld und während der Feier für Sie auf ein Minimum zu reduzieren,sind wir auf Ihre Mithilfe angewiesen.",
          //   " Besonders hervorheben möchten wir an dieser Stelle das Einhalten von Fristen und Terminen. Bitte nehmen Sie sich kurz Zeit, um sich mit unserer Zeitschiene vertraut zu machen. Dieser Online Fragebogen vereinfacht die Planung und Abstimmung Ihrer Feier auf dem Hofgut Lilienhof und wird das zentrale Tool Ihrer und unserer Vorbereitungen werden. Bitte zögern Sie nicht, uns bei Fragen oder offenen Punkten zu kontaktieren – wir helfen gerne!",
          //   "Viel Spaß bei Ihrer Planung!",
          // ]);
        }
      })
      .catch((err) => {
        // console.log(err)
        setNewEntry(true);
        setDisplayFlag(true);
      });
  };
  const handleDateChange = (e) => {
    // console.log(date)
    setVvoAssignmentDate(e.target.value);
    if (e.target.value >= 14) {
      setValidateVvoDays(true);
    }
  };

  const handleBack = () => {
    props.history.push(`/admin/events`);
  };
  const closePopup = (paras) => {
    handlePopup(!showPopup);
    setParagraphs(paras);
    // console.log('close', paras)
  };
  const closeQuestionairePopup = (paras) => {
    handleQuestionairePopup(!showQuestionairePopup);
    // setQuestionaireParagraphs(paras);
    // console.log('close', paras)
  };
  const closeNewPasswordPopup = () => {
    handleNewPasswordPopup(!showNewPasswordPopup);
  };
  const closeForgotPasswordPopup = () => {
    handleForgotPasswordPopup(!showForgotPasswordPopup);
  };
  
  return (
		<div
			style={{
				fontFamily: 'Crete Round',
				color: '#302f2f',
				fontStyle: 'italic',
			}}>
			<div className={`${showPopup ? 'behindComponent' : ''}`}>
				<div
					style={{
						color: '#524f4f',
						fontSize: '32px',
						fontStyle: 'normal',
						margin: '20px 30px 10px',
					}}>
					{t('Settings')}{' '}
				</div>

				<br />
				<div style={{ marginLeft: '30px' }}>
					<button
						className="button"
						onClick={handleSave}
						style={{ minWidth: '150px' }}>
						{t('save')}
					</button>

					<button
						className="button"
						onClick={handleBack}
						style={{ minWidth: '150px' }}>
						{t('return')}
					</button>
				</div>
				<br />
				<div style={{ margin: '30px' }}>
					<div style={{ display: 'inline-flex', marginBottom: '30px' }}>
						<div style={{ marginRight: '100px' }}>
							<h4 className="heading-style"> {t('language')}</h4>
							<div style={{ display: 'flex' }}>
								<Dropdown
									direction="down"
									name="language"
									isOpen={langDropDownOpen}
									toggle={() => setLangDropdownOpen((prevState) => !prevState)}>
									<DropdownToggle caret className="dropdown11">
										{language ? `${language}` : `${t('language')}`}
									</DropdownToggle>
									<DropdownMenu className="dropdown1">
										{languages.map((d, country_code) => {
											return (
												<DropdownItem
													name="language"
													onClick={() => {
														setLanguage(d.code);
													}}
													key={country_code}
													value={d.code}>
													{/* {d.code} */}
													{d.name}
												</DropdownItem>
											);
										})}
									</DropdownMenu>
								</Dropdown>
							</div>
						</div>

						<div style={{ marginRight: '100px' }}>
							<h4 className="heading-style">{t('currency')}</h4>
							<div style={{ display: 'flex' }}>
								<Dropdown
									direction="down"
									name="currency"
									isOpen={currencyDropdownOpen}
									toggle={() =>
										setCurrencyDropdownOpen((prevState) => !prevState)
									}>
									<DropdownToggle caret className="dropdown11">
										{currency ? `${currency}` : `${t('currency')}`}
									</DropdownToggle>
									<DropdownMenu className="dropdown1">
										{currencyFormats.map((d, country) => {
											return (
												<DropdownItem
													name="currency"
													// onClick={() => setCurrency(d)}
													onClick={() => setCurrency(d.symbol)}
													key={country}
													value={d.symbol}>
													{d.country} {d.symbol}
												</DropdownItem>
											);
										})}
									</DropdownMenu>
								</Dropdown>
							</div>
						</div>

						<div style={{ marginRight: '100px' }}>
							<h4 className="heading-style">{t('Format_date')}</h4>
							<div style={{ display: 'flex' }}>
								<Dropdown
									direction="down"
									name="date"
									isOpen={dateDropdownOpen}
									toggle={() => setDateDropdownOpen((prevState) => !prevState)}>
									<DropdownToggle
										caret
										className="dropdown11"
										style={{ marginRight: '10px' }}>
										{date.length
											? date === 'DD/MM/YY'
												? 'TT-MM-JJ'
												: date === 'MM/DD/YY'
												? 'MM-TT-JJ'
												: date === 'YY/MM/DD'
												? 'JJ/MM/TT'
												: ''
											: `${t('Format_date')}`}
									</DropdownToggle>
									<DropdownMenu className="dropdown1">
										{dateFormats.map((d) => {
											return (
												<DropdownItem
													name="date"
													onClick={() => setDate(d)}
													key={d}
													value={
														d === 'DD/MM/YY'
															? 'TT-MM-JJ'
															: d === 'MM/DD/YY'
															? 'MM-TT-JJ'
															: d === 'YY/MM/DD'
															? 'JJ/MM/TT'
															: ''
													}>
													{d === 'DD/MM/YY'
														? 'TT-MM-JJ'
														: d === 'MM/DD/YY'
														? 'MM-TT-JJ'
														: d === 'YY/MM/DD'
														? 'JJ/MM/TT'
														: ''}
												</DropdownItem>
											);
										})}
									</DropdownMenu>
								</Dropdown>
								&nbsp;
								<Dropdown
									direction="down"
									name="time"
									isOpen={timeDropDownOpen}
									toggle={() => setTimeDropdownOpen((prevState) => !prevState)}>
									<DropdownToggle caret className="dropdown11">
										{time ? `${time}` : 'time'}
									</DropdownToggle>
									<DropdownMenu className="dropdown1">
										{timeFormats.map((d) => {
											return (
												<DropdownItem
													name="time"
													onClick={() => setTime(d)}
													key={d}
													value={d}>
													{d}
												</DropdownItem>
											);
										})}
									</DropdownMenu>
								</Dropdown>
								{'  '}
								&nbsp;
							</div>
						</div>
					</div>

					<div className={`row`}>
						<div className="col-md-6" style={{ padding: '30px 0 0 15px' }}>
							<div>
								<div>
									<div style={{ padding: '0 0 30px 0' }}>
										<h4 className="heading-style">{t('Home_page')}</h4>
										<h6>{t('Home_page_text')}</h6>
										<button
											className="button"
											onClick={() => handlePopup(!showPopup)}>
											{t('edit_homepage')}
										</button>
									</div>
									<div style={{ padding: '30px 0 30px 0' }}>
										<h4 className="heading-style">E-Mail</h4>
										<div style={{ display: 'flex' }}>
											<button
												className="button"
												onClick={() =>
													handleNewPasswordPopup(!showNewPasswordPopup)
												}>
												{t('new_user')}
											</button>
											<button
												className="button"
												onClick={() =>
													handleForgotPasswordPopup(!showForgotPasswordPopup)
												}>
												{t('forgot_Password')}
											</button>
										</div>
									</div>
									<br />
								</div>
								<div>
									<h4 className="heading-style">{t('Login_page')}</h4>
									<br />
									<form>
										<div style={{ display: 'flex' }}>
											<div className="col-4">
												<input
													type="text"
													name="welcomeText"
													className="welcomeText"
													value={welcomeText}
													onChange={(e) => {
														setWelcomeText(e.target.value);
														setValidateWelcomeText(true);
													}}
													placeholder="Bengrusungstext"
													autoComplete="off"
												/>
												<br />
												<small>{t('Welcome_login')}</small>
												<br />
												{!validateWelcomeText && (
													<small style={{ color: 'red' }}>
														* {t('Required_field')}
													</small>
												)}
											</div>
											&nbsp;
											<div className="col-4">
												<div className="underlined-div">
													<input
														type="text"
														className="colorSelection"
														placeholder={welcomeTextColor}
														onClick={handleShowWelcomeTextColorPicker}
														autoComplete="off"
													/>
													<div
														className="colorDisplay"
														style={{
															backgroundColor: welcomeTextColor,
														}}
														onClick={handleShowWelcomeTextColorPicker}></div>
													{'    '}
													&nbsp;
													{WelcomeTextColorPicker && (
														<ChromePicker
															className="color-picker"
															color={welcomeTextColor}
															onChange={(color) => {
																setWelcomeTextColor(color.hex);
															}}
														/>
													)}
												</div>
												<small>{t('Heading_color')}</small>
											</div>
											<div className="col-4">
												<Dropdown
													direction="down"
													name="selectedFont"
													isOpen={fontDropDownOpen}
													toggle={() => toggleFontDropdown(!fontDropDownOpen)}>
													<DropdownToggle
														caret
														className="dropdown11"
														style={{ width: '160px' }}>
														{selectedFont.length ? `${selectedFont}` : 'Font'}
													</DropdownToggle>
													<DropdownMenu className="dropdown1">
														{fonts.map((d) => {
															return (
																<DropdownItem
																	name="selectedFont"
																	onClick={() => {
																		setSelectedFont(d);
																		setValidateWelcomeTextFont(true);
																	}}
																	key={d}
																	value={d}>
																	{d}
																</DropdownItem>
															);
														})}
													</DropdownMenu>
												</Dropdown>
												{!validateWelcomeTextFont && (
													<small style={{ color: 'red' }}>
														*{t('Required_field')}
													</small>
												)}
											</div>
										</div>
										<br />
										<br />
										<div className="row">
											<div className="col-4">
												<Dropzone
													style={{ margin: '0' }}
													onDrop={handleOnDropBackgroundImg}
													accept="image/*"
													// maxSize={imageMaxSize}
												>
													{({ getRootProps, getInputProps }) => (
														<>
															<div
																{...getRootProps()}
																style={{
																	border: '2px dashed gray',
																	borderRadius: '3px',
																	width: 260,
																	height: 210,
																	margin: '0',
																}}>
																<input
																	{...getInputProps()}
																	autoComplete="off"
																/>
																{backgroundImgSrc !== null ? (
																	<img
																		src={backgroundImgSrc}
																		style={{
																			width: 250,
																			height: 200,
																		}}
																		alt="Background"
																	/>
																) : (
																	<div style={{ padding: 40 }}>
																		<img
																			src={upload}
																			style={{
																				margin: '10px 40px',
																				// width:200, height:200
																			}}
																			alt="Uploading"
																		/>
																		<small style={{ marginLeft: '17px' }}>
																			{`Drag & Drop
                                     oder 
                                     Datei auswählen(choose a file)`}
																		</small>
																		{/* <button className="dropzone_button" >select files</button> */}
																	</div>
																)}
															</div>
															<p>{t('Login_wallpaper')}</p>
															{!validateBackgroundImg && (
																<small style={{ color: 'red' }}>
																	* {t('Choose_picture')}
																</small>
															)}
														</>
													)}
												</Dropzone>
											</div>
											<div className="col-4">
												<Dropzone
													onDrop={handleOnDropVenueLogo}
													accept="image/*"
													// maxSize={imageMaxSize}
												>
													{({ getRootProps, getInputProps }) => (
														<>
															<div {...getRootProps()}>
																<input
																	{...getInputProps()}
																	autoComplete="off"
																/>
																<div
																	style={{
																		border: '2px dashed gray',
																		width: 260,
																		height: 210,
																		alignContent: 'center',
																		display: 'flex',
																	}}>
																	{venueLogoSrc !== null ? (
																		<img
																			src={venueLogoSrc}
																			style={{
																				maxWidth: '100%',
																				maxHeight: '100%',
																				alignSelf: 'center',
																				padding: '5px',
																				marginLeft: 'auto',
																				marginRight: 'auto',
																			}}
																			alt="Venue Logo"
																		/>
																	) : (
																		<div style={{ padding: 40 }}>
																			<img
																				src={upload}
																				style={{ margin: '10px 40px' }}
																				alt="Upload"
																			/>
																			<small style={{ marginLeft: '17px' }}>
																				{`Drag & Drop
                                     oder 
                                     Datei auswählen(choose a file)`}
																			</small>
																			{/* <button className="dropzone_button" >select files</button> */}
																		</div>
																	)}
																</div>
																<p> {t('Location Logo')}</p>
																{!validateVenueLogo && (
																	<small style={{ color: 'red' }}>
																		* {t('Choose_picture')}
																	</small>
																)}
															</div>
														</>
													)}
												</Dropzone>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-md-6" style={{ paddingRight: '0px' }}>
							<div
								style={{
									backgroundColor: '#fcfcfc',
									boxShadow: '0px 0px 5px #dadada',
									borderRadius: '5px',
									padding: '30px',
									minHeight: 'auto',
								}}>
								<h4
									className="heading-style2"
									style={{ display: 'block', whiteSpace: 'nowrap' }}>
									{t('Settings_questionnaire')}
								</h4>
								<br />
								<div className="row">
									<div>
										<div className="col-md-2">
											<Dropdown
												// style={{width:"500px"}}
												direction="down"
												name="selectedQuestionnaire"
												isOpen={questionnaireDropDownOpen}
												toggle={() =>
													setQuestionnaireDropDownOpen(
														(prevState) => !prevState
													)
												}>
												<DropdownToggle
													caret
													className="dropdown11"
													style={{ width: '200px', height: '38px' }}>
													{selectedQuestionnaire
														? `${selectedQuestionnaire}`
														: `${t('Selection_questionnaire')}`}
												</DropdownToggle>
												<DropdownMenu className="dropdown1">
													{questionnaireOptions.map((q) => {
														return (
															<DropdownItem
																name="selectedQuestionnaire"
																onClick={() => {
																	handleSelectedQuest(q);
																	// setSelectedQuestionnaire(q)
																	// setDisplayFlag(true)
																}}
																key={q._id}
																value={q.name}>
																{q.name}
															</DropdownItem>
														);
													})}
												</DropdownMenu>
											</Dropdown>
										</div>
										{!validateQuestionairre ? (
											<small style={{ color: 'red' }}>
												* {t('Required_field')}
											</small>
										) : (
											''
										)}
									</div>
									<div className="col-md-1">
										{displayFlag ? (
											<>
												<div style={{ display: 'block', whiteSpace: 'nowrap' }}>
													<input
														type="number"
														style={{ width: '180px' }}
														value={vvoAssignmentDate}
														onChange={(e) => {
															handleDateChange(e);
														}}
														autoComplete="off"
													/>
													<br />
													<div>
														<small
															style={{
																display: 'block',
																whiteSpace: 'nowrap',
																// marginLeft: "190px",
															}}>
															{t('days_before_event')}
															<br />
															{t('contact_details')}
															<br />
															{t('ev_mgr')}
														</small>
													</div>
													<br />
												</div>
											</>
										) : null}
									</div>
								</div>
								{displayFlag ? (
									<>
										<div className="row">
											<div className="col-md-4">
												<div className="underlined-div">
													<input
														type="text"
														className="colorSelection"
														placeholder={textColor}
														onClick={handleShowTextColorPicker}
														autoComplete="off"
													/>
													<div
														className="colorDisplay"
														style={{ backgroundColor: textColor }}
														onClick={handleShowTextColorPicker}></div>
													{showTextColorPicker && (
														<ChromePicker
															className="color-picker"
															color={textColor}
															onChange={(updatedColor) => {
																setTextColor(updatedColor.hex);
															}}
														/>
													)}
												</div>
												<small>{t('Text_color')}</small>
											</div>
											<div className="col-md-4">
												<div className="underlined-div">
													<input
														type="text"
														className="colorSelection"
														placeholder={lineColor}
														onClick={handleShowLineColorPicker}
														autoComplete="off"
													/>{' '}
													{'    '}
													<div
														style={{
															backgroundColor: lineColor,
														}}
														className="colorDisplay"
														onClick={handleShowLineColorPicker}></div>
													{'    '}
													{showLineColorPicker && (
														<ChromePicker
															className="color-picker"
															color={lineColor}
															disableAlpha={true}
															onChange={(updatedColor) => {
																setLineColor(updatedColor.hex);
															}}
														/>
													)}
												</div>

												{/* <small>Strichfarbe</small> */}
												<small>{t('Akzentfarbe')}</small>
											</div>
										</div>
										{/* end font styling */}
										<div className="row">
											<div className="col-md-6">
												<Dropdown
													direction="down"
													name="selectedNote"
													isOpen={textNoteDropDownOpen}
													toggle={() =>
														toggleNoteDropdown((prevState) => !prevState)
													}>
													<DropdownToggle
														caret
														className="dropdown11"
														style={{ width: '160px', fontSize: '13px' }}>
														{/* {console.log("selected",selectedNote)} */}
														{Object.keys(selectedNote).length
															? `${selectedNote.name}`
															: `${t('notes')}`}
													</DropdownToggle>
													<DropdownMenu className="dropdown1">
														{note.map((q) => {
															return (
																<DropdownItem
																	name="selectedNote"
																	onClick={() => {
																		setSelectedNote(q);
																		setValidateNote(true);
																	}}
																	key={q.name}
																	value={q.name}>
																	{t(q.name)}
																</DropdownItem>
															);
														})}
													</DropdownMenu>
												</Dropdown>
												<small
													style={{
														marginLeft: '10px',
														marginRight: '10px',
														display: 'block',
														whiteSpace: 'nowrap',
													}}>
													{t('VAT')}
												</small>

												{!validateNote ? (
													<small style={{ color: 'red' }}>
														* {t('Required_field')}
													</small>
												) : (
													''
												)}
											</div>
										</div>
										<br />
										<div className="row" style={{ width: 'auto' }}>
											<div className="col-6" style={{ width: 'auto' }}>
												<div className="row" style={{ width: 'auto' }}>
													<div className="col-6" style={{ width: 'auto' }}>
														<Dropdown
															direction="down"
															name="selectedTextFontFamily"
															isOpen={textFontDropDownOpen}
															toggle={() =>
																setTextFontDropDownOpen(
																	(prevState) => !prevState
																)
															}>
															<DropdownToggle
																caret
																className="dropdown11"
																style={{ width: '160px', fontSize: '13px' }}>
																{selectedTextFontFamily.length
																	? `${selectedTextFontFamily}`
																	: `${t('font_text')}`}
															</DropdownToggle>
															<DropdownMenu className="dropdown1">
																{fonts.map((q) => {
																	return (
																		<DropdownItem
																			name="selectedTextFontFamily"
																			onClick={() => {
																				setSelectedTextFontFamily(q);
																				setValidateTextFontFamily(true);
																			}}
																			key={q}
																			value={q}>
																			{q}
																		</DropdownItem>
																	);
																})}
															</DropdownMenu>
														</Dropdown>
														<small
															style={{
																marginLeft: '10px',
																marginRight: '10px',
																display: 'block',
																whiteSpace: 'nowrap',
															}}>
															{t('font_text')}
														</small>
														<br />
														{!validateTextFontFamily ? (
															<small style={{ color: 'red' }}>
																* {t('Required_field')}
															</small>
														) : (
															''
														)}
														&nbsp;
														<Dropdown
															direction="down"
															name="selectedHeadingFontFamily"
															isOpen={headingFontDropDownOpen}
															toggle={() =>
																setHeadingFontDropDownOpen(
																	(prevState) => !prevState
																)
															}>
															<DropdownToggle
																caret
																className="dropdown11"
																style={{ width: '160px', fontSize: '12px' }}>
																{selectedHeadingFontFamily.length
																	? `${selectedHeadingFontFamily}`
																	: `${t('Font_heading')}`}
															</DropdownToggle>
															<DropdownMenu className="dropdown1">
																{fonts.map((q) => {
																	return (
																		<DropdownItem
																			name="selectedHeadingFontFamily"
																			onClick={() => {
																				setSelectedHeadingFontFamily(q);
																				setValidateHeadingFontFamily(true);
																			}}
																			key={q}
																			value={q}>
																			{q}
																		</DropdownItem>
																	);
																})}
															</DropdownMenu>
														</Dropdown>
														<small
															style={{
																marginLeft: '10px',
																display: 'block',
																marginRight: '10px',
																whiteSpace: 'nowrap',
															}}>
															{' '}
															{t('Font_heading')}
														</small>
														{!validateHeadingFontFamily ? (
															<small style={{ color: 'red' }}>
																* {t('Required_field')}
															</small>
														) : (
															''
														)}
														&nbsp;
													</div>

													<div className="col-md-6" style={{ width: 'auto' }}>
														<Dropdown
															style={{ paddingLeft: '20px' }}
															direction="down"
															name="selectedTextFontSize"
															isOpen={textFontSizeDropDownOpen}
															toggle={() =>
																setTextFontSizeDropDownOpen(
																	(prevState) => !prevState
																)
															}>
															<DropdownToggle
																caret
																className="dropdown11"
																style={{
																	width: '120px',
																	fontSize: '13px',
																	// paddingLeft:"20px"
																}}>
																{selectedTextFontSize
																	? `${selectedTextFontSize}`
																	: `${t('Font_size_text')}`}
															</DropdownToggle>
															<DropdownMenu className="dropdown1">
																{fontSizes.map((q) => {
																	return (
																		<DropdownItem
																			name="selectedTextFontSize"
																			onClick={() => {
																				setSelectedTextFontSize(q);
																				// setValidateTextFontSize(true)
																			}}
																			key={q}
																			value={q}>
																			{q}
																		</DropdownItem>
																	);
																})}
															</DropdownMenu>
														</Dropdown>
														<small
															style={{
																marginLeft: '10px',
																marginRight: '10px',
																display: 'block',
																whiteSpace: 'nowrap',
																paddingLeft: '20px',
															}}>
															{t('Font_size_text')}
														</small>
														<br />
														&nbsp;
														<Dropdown
															style={{ paddingLeft: '20px' }}
															direction="down"
															name="selectedHeadingFontSize"
															isOpen={headingFontSizeDropDownOpen}
															toggle={() =>
																setHeadingFontSizeDropDownOpen(
																	(prevState) => !prevState
																)
															}>
															<DropdownToggle
																caret
																className="dropdown11"
																style={{ width: '120px', fontSize: '12px' }}>
																{selectedHeadingFontSize
																	? `${selectedHeadingFontSize}`
																	: `${t('Font_size_heading')}`}
															</DropdownToggle>
															<DropdownMenu className="dropdown1">
																{fontSizes.map((q) => {
																	return (
																		<DropdownItem
																			name="selectedHeadingFontSize"
																			onClick={() => {
																				setSelectedHeadingFontSize(q);
																				// setValidateHeadingFontSize(true)
																			}}
																			key={q}
																			value={q}>
																			{q}
																		</DropdownItem>
																	);
																})}
															</DropdownMenu>
														</Dropdown>
														<small
															style={{
																marginLeft: '10px',
																marginRight: '10px',
																display: 'block',
																whiteSpace: 'nowrap',
																paddingLeft: '20px',
															}}>
															{t('Font_size_heading')}
														</small>
													</div>
													<br />
													<br />
												</div>
											</div>
											<div className="col-4" style={{ width: 'auto' }}>
												<Dropzone onDrop={handleOnDropEventLogo}>
													{({ getRootProps, getInputProps }) => (
														<div {...getRootProps()}>
															<input {...getInputProps()} autoComplete="off" />
															<div
																style={{
																	border: '2px dashed gray',
																	width: 230,
																	height: 210,
																	alignContent: 'center',
																	display: 'flex',
																}}>
																{eventLogoSrc !== null ? (
																	<img
																		src={eventLogoSrc}
																		style={{
																			maxWidth: '100%',
																			maxHeight: '100%',
																			alignSelf: 'center',
																			padding: '5px',
																			marginLeft: 'auto',
																			marginRight: 'auto',
																		}}
																		alt="Event Logo"
																	/>
																) : (
																	<div style={{ padding: '40px' }}>
																		<img
																			src={upload}
																			style={{
																				margin: '10px 40px',
																				// width:250,
																				// height:200
																			}}
																			alt="Upload"
																		/>

																		<small>
																			{`Drag & Drop oder Datei auswählen`}
																		</small>
																	</div>
																)}
															</div>
															{/* <p>Logo dieses Fragebogens hochladen</p> */}
															<p style={{ position: 'relative', left: '15px' }}>
																{t('Logo_questionnaire')}
															</p>
														</div>
													)}
												</Dropzone>
												{!validateEventLogo && (
													<small style={{ color: 'red' }}>
														*{t('Choose_picture')}
													</small>
												)}
											</div>
										</div>
									</>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
			{showPopup ? (
				<DataPopup
					paragraphs={paragraphs}
					closePopup={closePopup}
					language={language}
				/>
			) : showQuestionairePopup ? (
				<EventsParagraphs
					paragraphs={Questionaireparagraphs}
					closePopup={closeQuestionairePopup}
				/>
			) : null}
			{showNewPasswordPopup ? (
				<NewPassword closePopup={closeNewPasswordPopup} />
			) : showForgotPasswordPopup ? (
				<ForgotPassword closePopup={closeForgotPasswordPopup} />
			) : null}
		</div>
	);
}

import React, { Component } from "react";
import { StateContext } from "../../context/state";

import axios from "axios";
import { baseUrl } from "../../Constants";
import Moment from "react-moment";
import moment from "moment";
import "moment-timezone";
import home from "../../assets/home.svg"
import mailIcon from "../../assets/mail.svg";
import phoneIcon from "../../assets/phone.svg";
import logoutIcon from "../../assets/logout.svg";
import collapseToggleIcon from "../../assets/collapse.svg";
import heartMask from "../../assets/heart-mask.png";
import { withRouter } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { withTranslation} from 'react-i18next';
class LeftBar extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      dateFormat: "",
      vvoDetails: [],
      // cuDetails: [],
      venueLogoSrc: "",
      companyLogoUrl: "",
      plannerImageUrl: "",
      plannerEmail: "nadine@hofgut-lilienhof.de",
      plannerPhone: "07668 9965280",
      eventDate: "",
      coupleImageUrl: "",
      coupleName: "Hanna und Gilbert Reichhausen",
      eventLogo: "",
      vvoAssignmentDate:"",
      email:"",
      telephone:"",
      obj:{},
      stylingData:{},
      textFontFamily:"",
      textFontSize:"",
      paragraphs:{ }
    
    };
  }
  collapseToggle = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };
  componentDidMount = () => {
    if (window.innerWidth > 1306) this.setState({ collapsed: false });
    window.addEventListener("resize", () => {
      if (window.innerWidth > 1306) this.setState({ collapsed: false });
      else this.setState({ collapsed: true });
    });
    const data = this.context;
   console.log("leftbarvenue");
    axios
      .get(`${baseUrl}api/venue/${data.venueId}`)
      .then((res) => {
        // console.log("leftbar", res.data);
        this.setState({
          venueLogoSrc: res.data.venueLogoSrc,
          dateFormat: res.data?.dateFormat,
          telephone:res.data?.telephoneNumber,
          email:res.data?.email,
          obj:this.props.obj
        });
       
        // console.log("res.data?.currencyFormat-leftbar", res.data?.currencyFormat);
        this.context.updateState({ currencyFormat: res.data?.currencyFormat,dateFormat: res.data?.dateFormat});
        // console.log("context", this.context);
      })
      .catch((error) => {
        console.log(error);
      });
      let eventId
      let parentQuestionnaireId
if(this.props.obj){
  eventId=this.props.obj?.eventId
  parentQuestionnaireId=this.props.obj?.parentQuestionnaireId
}
else{
  eventId=data?.eventId
  parentQuestionnaireId=data.parentQuestionnaireId
}
    if (eventId) {
      axios
        .get(`${baseUrl}api/user/ByEvent/${eventId}/VV`)
        .then((response) => {
          this.context.updateState({ vvoDetails: response.data });
          this.setState({
            vvoDetails: response.data,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    
    
    if (this.props.location.pathname === "/EventLanding") {
      axios
        .get(`${baseUrl}api/questionnaireSetting/${parentQuestionnaireId}`)
        .then((res) => {
          if (Object.keys(res.data).length) {
            this.props.setDateFormat(this.state?.dateFormat,res.data)

            this.setState({
              eventLogo: res?.data.questionnaireLogoSrc,
              vvoAssignmentDate:res?.data?.vvoAssignmentDate,
              stylingData: res?.data,
                  textColor: res.data?.textColor,
                  textFontFamily: res.data?.textFontFamily,
                  textFontSize: res.data?.textFontSize,
                  paragraphs: res.data.questionairre
                    ? res.data.questionairre
                    : 
                    
                    {}
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  handleLogout = () => {
    const urlName = this.context.urlName;
    this.context.updateState({
      authenticated: false,
      from:"logout"

    });
    localStorage.clear();
    sessionStorage.clear();
    if (urlName === "adminScreen") {
      this.props.history.push(`/login`);
    } else {
      this.props.history.push(`/login/${urlName}`);
    }
  };

  render() {
    const {stylingData } = this.state;
    console.log("data side bar", this.props.defaultSettingStyle)
 
    var today = new Date();
today.toISOString()

var finalDate
var eventType
var eventDate

if(this.props.obj){

  finalDate=  moment(this.props.obj?.eventDate).subtract(
    Number(this.state.vvoAssignmentDate),
    "days"
  )._d
  eventDate=this.props.obj?.eventDate
  eventType=this.props.obj?.eventType
}
else{
 
  finalDate= moment(this.context.eventDate).subtract(
    Number(this.state.vvoAssignmentDate),
    "days"
  )._d
  eventDate=this.context.eventDate
  eventType=this.context.eventType
}


    return (
      <StateContext.Consumer>
        {({ userRole }) => {
          const { vvoDetails } = this.state;
          const {cuDetails}=this.context
          return (
            <div
              className={`left-bar ${this.state.collapsed ? "collapsed" : ""}`}
              style={{borderRight: this.context.stylingData?.lineColor ? `1px solid ${this.context.stylingData?.lineColor}` : `1px solid ${this.props.defaultSettingStyle?.presAccentColor}`}}
            >
              <>
              
                <OverlayTrigger
                  key="bottom"
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id={`tooltip-bottom`}
                    >
                      <span>{this.props.t('logout')}</span>
                    </Tooltip>
                  }
                >
                  <button className="logout-btn" onClick={this.handleLogout}>
                    <img src={logoutIcon} alt="" />
                  </button>
                </OverlayTrigger>
            {/* {console.log("history",this.props.history.location.pathname)} */}
         {this.props.history.location.pathname!=="/login-landing" &&
         <OverlayTrigger
         key="bottom"
         placement="bottom"
         overlay={
           <Tooltip
             id={`tooltip-bottom`}
           >
             <span>{this.props.t('home')}</span>
           </Tooltip>
         }
       >
          <button className="home-btn" onClick={(e)=>{
           this.context.userRole!=="CU" ? 
           this.props.location.state?.from==="questionnairePage"?this.props.history.push("/admin/questionnaire"):
           this.props.history.push("/admin/events")
           
           :this.props.history.push("/login-landing")}
          }>
                 <img style={{width:"20px",height:"25px"}}src={home} alt="" />
               </button>
             </OverlayTrigger>
           
              
         }   
              </>
              <button
              
                className={`left-bar-toggle ${
                  this.state.collapsed ? "collapsed" : ""
                }`}
                onClick={this.collapseToggle.bind(this)}
              >
                <img src={collapseToggleIcon} alt="" />
              </button>
              <div className="main-company-logo">
                
                {/* {this.props.location.pathname === "/EventLanding" ? ( */}
                <img
                  src={`${this.state.eventLogo?this.state.eventLogo:""}`}
                  alt=""
                  style={{
                    // width: "125px",
                    // height: "100px",
                    // margin: "10px 70px"
                     width: "100%",
                    height: "auto",
                    maxHeight: "100px",
                    objectFit: "contain",
                    imageRendering: "high-quality",
                  }}
                />
              {/* ) : <></>} */}
                 </div>
                {/* <img
              
                  src={this.state.venueLogoSrc}
                  alt=""
                  style={{
                    // width: "100%",
                    // height: "auto",
                    // maxHeight: "100px",
                    objectFit: "contain",
                    imageRendering: "high-quality",
                  }}
                />
            */}

            

              {cuDetails?.shape === "heart" ? (
                <div
                  className="heart-container"
                  style={{
                    margin: window.innerHeight > 730 ? "50px auto 20px" : "5px auto",
                  }}
                >
                  <img className="heart-mask" src={heartMask} alt="" />
                  <img
                    className="couple-image"
                    src={cuDetails?.pictureURL}
                    style={{
                      height: "100%",
                      objectFit:"contain"
                    }}
                    alt=""
                  />
                </div>
              ) : (
                cuDetails?.pictureURL && (
                  <div
                    className="secondary-company-logo"
                    style={{
                      margin:
                        window.innerHeight > 730 ? "4px auto" : "5px auto",
                    }}
                  >
                    <img src={cuDetails?.pictureURL} alt="" />
                  </div>
                )
              )}

              {Object.keys(vvoDetails).length &&
              this.props.location.pathname !== "/login-landing" ? (
                <>
                  <div
                    className="event-details date-alignment"
                    style={{
                      // lineHeight: "25px",
                      fontSize: window.innerHeight > 730 ?"20px":"13px",
                      paddingTop: cuDetails?.pictureURL ? "" : "40px",
                      display:window.innerHeight >640 ? "":window.innerHeight >360?"flex":"none",
                      marginLeft:window.innerHeight >640? "":"30px"
                    }}
                  >
                  
                    {eventType === "wedding" ? (
                      <span>
                        <b>{`${this.props.t('Hochzeitsdatum')}`}{" "}</b>&nbsp;
                      </span>
                    ) : (
                      <span>
                        <b>{`${this.props.t('Eventdatum')}`}{" "}</b>&nbsp;
                      </span>
                    )}
                    {/* {this.context.eventName && (
                     this.context.eventName
                    )} */}
                    <br />
                    {eventDate && (
                      <b>
                        {" "}
                        <Moment
                          format={
                            this.context.dateFormat
                              ? this.context.dateFormat.toUpperCase()
                              : "DD-MM-YYYY"
                          }
                        >
                          {eventDate}
                        </Moment>
                      </b>
                    )}
                    <br />
                    {window.innerHeight > 790 ? (
                    eventType==="wedding" ? 

                      <div classname="display-von"style={{ fontSize: "16px", marginTop: "5px" }}>
                         {this.props.t('von')}
                        <br />
                      
                        {cuDetails?.surname1 && (
                              <b>{cuDetails?.firstName1}</b>
                            )}
                            {cuDetails?.surname2 && (
                              <b>{` ${this.props.t('und')} ${cuDetails?.firstName2}`}</b>
                            )}
                       
                      </div>:
                        <div classname="display-von"style={{ fontSize: "16px", marginTop: "5px" }}>
                        {cuDetails?.companyName!=="" ? 
                        <div>
                          {this.props.t('von')}
                        <br />
                      {<b>{cuDetails?.companyName}</b>}
                          </div>:
                          <></>
                      }
                        
                           
                            </div>
                    ) : null}
                  </div>

                  <div className="bottom-section" style={{ marginTop: "20px" }}>
                    <div className="planner-image">
                      {vvoDetails?.pictureURL?.length && (
                        <img
                          src={vvoDetails.pictureURL}
                          className="pictureUrl"
                          style={{
                            maxWidth: "100px",
                            width: "100%",
                            height: "100%",
                            maxHeight: "100px",
                            objectFit: " cover",
                          }}
                          alt=""
                        />
                      )}
                    </div>
                    <div
                      className="planner-details"
                      // style={{
                      //   padding:
                      //     window.innerHeight > 730 ? "60px 15px 20px" : "32px 15px 15px",
                      // }}
                      style={{background: stylingData.lineColor ? `${stylingData.lineColor}` : `${this.props.defaultSettingStyle?.presAccentColor}`}}
                    >
                      <h6>{this.props.t('any_questions?')}</h6>
                      <p>
                      {this.props.t('any_questions_text')}
															  
                      </p>
                      <div style={{display:"inline-grid",justifyItems:"left"}}>
                        <div style={{overflowWrap: "break-word",
  wordWrap: "break-word"}}>
                      <a
                        href={`mailto:${today>=finalDate? vvoDetails?.email1:this.state.email}`}
                        className="email-container"
                        style={{ marginTop: "10px" }}
                      >
                        <img src={mailIcon} alt="" />
                     
                        {today>=finalDate? vvoDetails?.email1:this.state.email}
                      </a>
                      </div>
                      <div style={{overflowWrap: "break-word",
  wordWrap: "break-word"  }} >
   
                      <a
                        href={`tel:${today>=finalDate?vvoDetails?.telNumber1:this.state.telephone}`}
                        className="phone-container"
                        style={{ marginTop: "10px" }}
                      >
                        <img src={phoneIcon} alt=""  style={{paddingRight:"9px"}}/>
                        <span>{today>=finalDate?vvoDetails?.telNumber1:this.state.telephone}</span>
                      </a>
                      </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          );
        }}
      </StateContext.Consumer>
    );
  }
}

export default withTranslation() (withRouter(LeftBar))  ; ;


import React, { Component } from 'react';
import { StateContext } from '../../context/state';
import axios from 'axios';
import { baseUrl } from '../../Constants.js';
import crossLight from '../../assets/cross-light.svg';
import { withTranslation } from 'react-i18next';

class ImageOption extends Component {
	static contextType = StateContext;
	constructor(props) {
		super(props);
		this.state = {
			newData: {},
			collapsed: true,
		};
	}
	componentDidMount() {
		if (window.innerWidth > 1800) this.setState({ collapsed: false });
		else this.setState({ collapsed: true });
		window.addEventListener('resize', () => {
			if (window.innerWidth > 1800) this.setState({ collapsed: false });
			else this.setState({ collapsed: true });
		});
		const option = this.props.option;
		// console.log('api', `${baseUrl}api/export/ServiceElement/${this.context.questionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`)
		axios
			.get(
				`${baseUrl}api/export/ServiceElement/${this.props.questionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`
			)
			.then((res) => {
				const d = res.data;
				d.imageInput.questionSelected = this.props.divData?.imageInput
					?.questionSelected
					? this.props.divData?.imageInput?.questionSelected
					: false;
				const dd = d.imageInput.inputs.map((input, index) => {
					input.alternate = input.alternate
						? input.alternate
						: this.props.divData?.imageInput?.inputs[index].alternate;
					input.marked = false;
					input.showMark = false;
					return { ...input };
				});
				d.imageInput.inputs = dd;

				d.imageInput.qtnAlternate = d.imageInput.qtnAlternate
					? d.imageInput.qtnAlternate
					: this.props.divData?.radioInput?.qtnAlternate
					? this.props.divData?.radioInput?.qtnAlternate
					: this.props.divData?.imageInput?.qtnAlternate
					? this.props.divData?.imageInput?.qtnAlternate
					: '';
				this.setState({
					data: d,
					newData: { ...this.state.newData, ...d },
				});
				// console.log('res', {...d})
				this.props.updateChange({ ...d }, this.props.divId);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	handleChangeQtnAlt = (e) => {
		const newData = Object.assign({}, this.state.newData);
		newData.imageInput.qtnAlternate = e.target.value;
		this.setState({
			newData,
		});
		this.props.updateChange(newData, this.props.divId);
	};
	showLinkOptions = () => {
		this.props.handleLinking(this.state.newData);
	};

	clickedOption = (option, i) => {
		const dummy = Object.assign({}, this.state.newData);

		let array = dummy.selectedOptions ? dummy.selectedOptions : [];
		const found = dummy?.selectedOptions?.find((op) => {
			return op.id === option.id;
		});
		if (!found) {
			array.push(option);
		}
		// array.push(option);

		dummy.selectedOptions = array;
		// dummy.radioInput.inputs[i].showMark=true
		// console.log("array",array)
		this.setState({
			newData: dummy,
			selectedOptions: array,
		});

		this.props.updateChange(dummy, this.props.divId);
	};

	markQuestion = (e, question) => {
		// console.log("e",e.target.value,"question",question)
		const dummy = Object.assign({}, this.state.newData);
		if (question === dummy.imageInput.question) {
			dummy.imageInput.questionSelected = true;
		}
		//  console.log("dummy",dummy)
		this.setState({ newData: dummy });
		// console.log("divid",this.props.divClicked)
		this.props.updateChange(dummy, this.props.divClicked);
	};
	removeMarkedQuestion = (question) => {
		// console.log("divid",this.props.divClicked)
		const dummy = Object.assign({}, this.state.newData);
		if (question === dummy.imageInput.question) {
			dummy.imageInput.questionSelected = false;
		}

		this.setState({ newData: dummy });
		this.props.updateChange(dummy, this.props.divClicked, this.props.themeId);
	};

	handleChangeOptionAlt = (id, e) => {
		const dummy = Object.assign({}, this.state.newData);
		dummy.imageInput.inputs.map((input) => {
			if (input.id === id) {
				return (input.alternate = e.target.value);
			} else {
				return input;
			}
		});
		this.setState({
			newData: dummy,
		});
		this.props.updateChange(dummy, this.props.divId);
	};

	render() {
		const inputs = this.state.newData.imageInput?.inputs || [];
		let collapse = (inputs.length < 4) === true ? true : false;
		const alignment = this.props.divData.imageInput?.alignment || '';
		const widthStyle = {
			width:
				alignment === 'horizontal'
					? inputs.length * 115 + (inputs.length - 1) * 15
					: '100%',
			height: '250px',
		};

		return (
			<div>
				<div style={{ overflowX: collapse === false ? 'auto' : 'hidden' }}>
					{this.state.newData.imageInput?.inputs && (
						<>
							<div className="qtn_optns">
								<div
									style={{
										background: this.state.newData.imageInput.questionSelected
											? '#939598'
											: '#e6e7e8',
									}}
									className="quest1"
									onClick={(e) => {
										this.markQuestion(
											e,
											this.state.newData.imageInput?.question
										);
									}}>
									<b>
										<i>{this.state.newData.imageInput?.question}</i>
									</b>
								</div>
								<img
									src={crossLight}
									alt=""
									style={{
										float: 'right',
										width: '10px',
										position: 'relative',
										right: '15px',
										//  paddingTop:"5px",
										bottom: '8px',
										display:
											!this.state.newData.imageInput.questionSelected && 'none',
									}}
									onClick={() =>
										this.removeMarkedQuestion(
											this.state.newData.imageInput.question
										)
									}
								/>
								<input
									style={{
										background: this.state.newData.imageInput.questionSelected
											? '#939598'
											: '#e6e7e8',
									}}
									type="text"
									className="inp1"
									placeholder={this.props.t('Ersatztext')}
									value={this.state.newData.imageInput.qtnAlternate}
									// disabled="true"
									onChange={(e) => {
										this.handleChangeQtnAlt(e);
									}}
								/>
							</div>
							<div className="secondHalf">
								{this.state.newData?.imageInput.inputs.map((input, i) => {
									return (
										<div key={i}>
											<div
												className={`image-item image-item-${alignment}`}
												style={{ paddingLeft: '10px' }}>
												{/* <div className="image" style={{backgroundImage: `url(${input.image})`}} /> */}
												{/* {(input.value!=="")? */}
												{/* <div
                                     className="optn-image"
                                     onClick={() => {
                                       this.clickedOption(input, i);
                                     }}
                                     style={{
                                       background:
                                         option.showMark
                                           ? "#939598"
                                           : "#e6e7e8",
                                           width:"200px"
                                     }}> 
                                         <img
                                     src={crossLight}
                                     alt=""
                                     style={{
                                      float:"right",
                                      width:"10px",   
                                      display:!option.showMark && "none"
                                     }}
                                     onClick={(e) => {
                                       this.handleRemoveSelectedOption(e, input, i);
                                     }}
                                   />
                                     {input.value}</div> */}
												<img
													src={input.image}
													style={
														(widthStyle, { width: '200px', height: '200px' })
													}
													alt=""
												/>
												<div className="optn-image">
													<input
														type="text"
														className="inp-image"
														placeholder={this.props.t('Ersatztext')}
														value={input.alternate}
														onChange={(e) => {
															this.handleChangeOptionAlt(input.id, e);
														}}
													/>
												</div>
											</div>
											{input.id === this.state.newData?.answer?.id ? (
												<span style={styles.filledCircle} />
											) : (
												<span style={styles.circle} />
											)}
										</div>
									);
								})}
							</div>
						</>
					)}
				</div>
			</div>
		);
	}
}

export default withTranslation()(ImageOption);

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		padding: '10px',
	},
	labelContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '10px',
	},
	typeContainer: {
		marginLeft: '54px',
	},
	circle: {
		marginTop: '7px',
		width: '16px',
		height: '16px',
		display: 'inline-block',
		textAlign: 'center',
		background: 'transparent',
		borderRadius: '50%',
		border: '1px solid #939598',
		outline: 'none',
	},
	filledCircle: {
		marginTop: '7px',
		width: '16px',
		height: '16px',
		display: 'inline-block',
		textAlign: 'center',
		background: 'transparent',
		borderRadius: '50%',
		backgroundColor: '#939598',
		border: '1px solid #939598',
		outline: 'none',
	},
	selected: {
		background: '#297825',
	},
	label: {
		margin: '0 0 0 20px',
		padding: '0',
		display: 'inline-block',
		color: '#939598',
		fontSize: '16px',
	},
	input: {
		border: 0,
		padding: 0,
		margin: 0,
		background: 'transparent',
		borderBottom: '1px solid #707070',
		width: '200px',
	},
	link: {
		background: '#e6e7e8',
		borderRadius: '50%',
		padding: '2px',
		marginLeft: '90%',
	},
};

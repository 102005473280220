import React, { Component } from "react";
import { StateContext } from "../../context/state";
import axios from "axios";
import { baseUrl } from "../../Constants.js";
import Loader from "./Loader";
import { Carousel } from "react-bootstrap";
class BildOption extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      newData: {},
      childData: {},
    };
  }
  componentDidMount() {
    if (this.props.data) {
      // console.log('hi',this.props.data )
      if (this.props.data?.bildInput?.image) {
        const image = {
          url: this.props.data.bildInput.image,
        };
        this.props.updateAttachments(image);
      }
      this.setState({
        newData: this.props.data,
      });
    }
    this.setState({loading:true})
    const option = this.props.option;
    axios
      .get(
        `${baseUrl}api/export/ServiceElement/${this.context.childQuestionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`
      )
      .then((res) => {
        const d = res.data;
        if (d.bildInput.image) {
          const image = {
            url: d.bildInput.image,
          };
          this.props.updateAttachments(image);
        }
        this.setState({
          childData: d,
          loading:false
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    // https://cors-anywhere.herokuapp.com/
    //` : ""
    let image = this.state.childData?.bildInput?.image
      // ? `https://secret-ocean-49799.herokuapp.com/${this.state.childData?.bildInput?.image}`
      // : "https://secret-ocean-49799.herokuapp.com/http://placehold.jp/300x300.png";
    return (
      // <img
      //   src={image}
      //   style={{ ...styles.image, backgroundImage: image }}
      //   alt=""
      // />
      // {this.state.data.radioInput?.link?.display &&
      //   this.state.data?.answer?.id ===
      //     this.state.data.radioInput.link.linkedOptionId &&
      //   this.renderOption(this.state.data.radioInput.link)}
      <div style={{marginBottom:"10px"}}>
          {this.state.loading?
        <Loader/>:<>
      
      <Carousel style={{display:this.props.showExportPopup && "none"}}>
        {Array.isArray(image) &&
          image.map((i) => {
            return (
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  // style={{...styles.image}}
                  src={i.url}
                  alt="First slide"
                />
                <Carousel.Caption>
                  {i.title.length ? (
                    <div
                      style={{
                        background: "white",
                        color: "black",
                        padding: "10px",
                      }}
                    >
                      <h3>{i.title}</h3>
                      <p>{i.caption}</p>
                    </div>
                  ) : null}
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
      </Carousel>
      </>}
      </div>
    );
  }
}

export default BildOption;

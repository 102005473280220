import React, {Component} from "react";
import { withTranslation} from 'react-i18next';
import AccordionSection from "./AccordionSection";

 class Accordion extends Component {
    render() {
        const {data, activeTheme, onThemeSelect, onServiceSelect} = this.props;

        return (
            <>
                {data !==undefined && data.map(theme => (
                    <AccordionSection
                        key={theme.id}
                        isOpen={activeTheme.id === theme.id}
                        theme={theme}
                        onThemeSelect={onThemeSelect}
                        onServiceSelect={onServiceSelect}
                    />
                ))}
            </>
        );
    }
}

export default withTranslation() (Accordion);
import React, { Component } from "react";
import { StateContext } from "../../context/state";

import optionIcon from "../../assets/option.svg";
import optionFilledIcon from "../../assets/option-filled.svg";
import deleteIcon from "../../assets/delete.svg";
import plusIcon from "../../assets/plus.svg";
import linkIcon from "../../assets/link.svg";
import { withTranslation } from 'react-i18next';

class RadioOptionComponent extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.messagesRef=React.createRef(null);
    this.state = {
      canBeEdited: false,
      radioInput: {
        question: "",
        multipleSelection:false,
        inputs: [
          {
            id: 1,
            value: "",
            price: "",
            priceType: "", //flat-rate, number-guests, numerically
            priceTypeId: null,
            tags: [],
            numerically: {
              minimumNumber: "",
              minimumNumberCalculate: false,
              maximumNumber: "",
              maximumNumberCalculate: false,
              stage: "",
              relay1: "",
              relay1Alle: false,
              relay1Price: "",
              relay2: "",
              relay2Alle: false,
              relay2Price: "",
              relay3: "",
              relay3Alle: false,
              relay3Price: "",
              unit: "",
            },
          },
          {
            id: 2,
            value: "",
            price: "",
            priceType: "",
            priceTypeId: null,
            tags: [],
            numerically: {
              minimumNumber: "",
              minimumNumberCalculate: false,
              maximumNumber: "",
              maximumNumberCalculate: false,
              stage: "",
              relay1: "",
              relay1Alle: false,
              relay1Price: "",
              relay2: "",
              relay2Alle: false,
              relay2Price: "",
              relay3: "",
              relay3Alle: false,
              relay3Price: "",
              unit: "",
            },
          },
        ],
      },
      selectedRadioIndex: null,
      radioSelected:false
    };
  }

  static contextType = StateContext;

  updateLayoutObject = () => {
    const { layoutObject, updateState } = this.context;
    layoutObject.forEach((item) => {
      item.row.forEach((_item) => {
        _item.column.forEach((__item) => {
          if (__item.selected) {
            __item.option.type = "radio";
            __item.option.radioInput = this.state.radioInput;
          }
        });
      });
    });
    updateState({
      layoutObject,
    });
  };

  static getDerivedStateFromProps = (nextProps, prevState) => {
    // console.log('hi', "context save", nextProps)
    if (nextProps.radioInput !== undefined) {
      if (nextProps?.canBeEdited) {
        // this.context.updateState({showLinkComponent:true})
      }
      return {
        selectedRadioIndex: nextProps.selectedRadioIndex,
        radioInput: nextProps.radioInput,
        canBeEdited: nextProps.canBeEdited ? nextProps.canBeEdited : false,
      };
    } else {
      return {
        selectedRadioIndex: nextProps.selectedRadioIndex,
        canBeEdited: nextProps.canBeEdited ? nextProps.canBeEdited : false,
      };
    }
  };

  setQuestion = (e) => {
    const radioInput = this.state.radioInput;
    radioInput.question = e.target.value;
    this.setState({ radioInput });
  };

  setRadioInputValue = (index, e) => {
    const radioInput = this.state.radioInput;
    radioInput.inputs[index].value = e.target.value;
    this.setState({ radioInput });
    //this.updateLayoutObject();
  };

  addRadio = () => {
    const radioInput = this.state.radioInput;
    radioInput.inputs.push({
      id: radioInput.inputs[radioInput.inputs.length - 1].id + 1,
      value: "",
      price: "",
      priceType: "",
      priceTypeId: null,
      tags: [],
      min:"",
      max:"",
      numerically: {
        minimumNumber: "",
        minimumNumberCalculate: false,
        maximumNumber: "",
        maximumNumberCalculate: false,
        stage: "",
        relay1: "",
        relay1Alle: false,
        relay1Price: "",
        relay2: "",
        relay2Alle: false,
        relay2Price: "",
        relay3: "",
        relay3Alle: false,
        relay3Price: "",
        unit: "",
      },
    });
    this.setState({ radioInput });
    // console.log(radioInput);
    //this.updateLayoutObject();
  };

  removeRadio = (index) => {
    const radioInput = this.state.radioInput;
    radioInput.inputs.splice(index, 1);
    const selectedRadioIndex =
      this.state.selectedRadioIndex === null ? null : index - 1;
    this.setState({ radioInput, selectedRadioIndex });
    //this.updateLayoutObject();
  };

  showOptionComponent = (index) => {
    this.props.toggleRadioOptionConfiguration(
      index,
      this.state.radioInput,
      this.props.index
    );
    this.setState({
      selectedRadioIndex: index,
    });
  };
  showLinkComponent = (index) => {
    // console.log('oi',this.state.radioInput )
    this.props.toggleLinkOption(index, this.state.radioInput, this.props.index);
    this.setState({
      selectedRadioIndex: index,
    });
  };
  handleClicked = () => {
    // console.log("radioInput", this.state.radioInput);
    const d = {
   
      max: this.state.radioInput.max
        ? this.state.radioInput.max
        : "",
      min: this.state.radioInput.min
        ? this.state.radioInput.min
        : "",
    };
    this.props.setOptionSelected(this.props.index, "radio", null, null, null, null,this.state.radioInput.multipleSelection,d );
  };
  handleLinkClicked = () => {
    this.props.setOptionSelected(this.props.index, "radio", "linkClicked");
  };
  functionValidation = (input) => {
    // console.log("input", input);
    const min=Number(input.numerically.minimumNumber)
    const max=Number(input.numerically.maximumNumber)
    const price=Number(input.price)

    if(min && max && min>max){
      const msg="Der Maximalwert sollte größer als der Minimalwert sein"
        return msg
    }
    if(min && max && !price && price!==0){
      const msg="Preis eingeben"
        return msg
    }
    else if(min && !price && price!==0){
      const msg="Preis eingeben"
        return msg
    }
    else if(max && !price && price!==0){
      const msg="Preis eingeben"
        return msg
    }
    else{
        return null
    }

  };
  display=(value)=>{
    this.setState({radioSelected:value})
  }
  display2=()=>{
    // console.log("optioncontainer",this.props.optionContainerClicked)
    this.setState({radioSelected:this.props.optionContainerClicked ? true:false})
  }
  componentDidMount() {
  
  }
  render() {
    // console.log("selected",this.state.selectedRadioIndex,"index",this.props.index)
    // console.log("optionContainer",this.props.optionContainerClicked,"radioSelected",this.state.radioSelected)
    return (
      <div className="radio-option-component" 
     style={{
        background:this.props.layoutObject[this.props.index[0]]?.row[this.props.index[1]]?.column[this.props.index[2]]?.selected===true? "#aaacaf":"",
        boxShadow: "0 0 10px #70707025",
       padding: "2px",
        marginBottom:"-5px"
      }}
      tabIndex="0"
      onFocus={()=>{this.display(true)}}
      onBlur={()=>{this.display(false)}}
      >
        <div className="radio-option-container">
          <input
            type="text"
            className="question"
            placeholder={this.props.t("Text einfügen")}
            value={this.state.radioInput.question}
            onChange={this.setQuestion}
            onClick={this.handleClicked}
            style={{ background: "#e6e7e8", borderRadius: "3px" }}
            autoComplete="off"
          />
          {this.state.radioInput.inputs.map((input, index) => {
            return (
              <div className="radio-container" key={index}>
                {!this.state.radioInput.multipleSelection || this.state.radioInput.multipleSelection==="false"?
                <span className="circle"></span>
                :
                <span className="square" ></span>}
                <input
                  type="text"
                  className="radio-input"
                  placeholder={this.props.t("Text einfügen")}
                  value={input.value}
                  onChange={(e) => this.setRadioInputValue(index, e)}
                  // onFocus={() => this.showOptionComponent(index)}
                  onClick={this.handleClicked}
                  autoComplete="off"
                />
                <span className="price-display">
                  {input.price !== undefined &&
                    input.price !== "" &&
                    `${this.context.currencyFormat}  ${this.context.languageFormat==="en" ? Number(input.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") :Number(input.price).toFixed(2)
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".") }`}
                </span>

                <button
                  className="option"
                  onClick={() => this.showOptionComponent(index)}
                  style={{
                    border: this.functionValidation(input)
                      ? "2px solid red"
                      : null,
                      borderRadius:"50%",
                      height: "34px",
                      width: "34px"
                  }}
                >
                  <img
                    src={
                      this.state.selectedRadioIndex === index
                        ? optionFilledIcon
                        : optionIcon
                    }
                    alt=""
                  />
                </button>
                {input.priceType === "number-guests" && (
                  <div className="x-guest">x {this.props.t("Gäste")}</div>
                )}
                {index > 1 && (
                  <button
                    className="remove-radio"
                    onClick={() => this.removeRadio(index)}
                  >
                    <img src={deleteIcon} alt="" />
                  </button>
                )}
                {index === this.state.radioInput.inputs.length - 1 && (
                  <button className="add-radio" onClick={this.addRadio}>
                    <img src={plusIcon} alt="" />
                  </button>
                )}
              </div>
            );
          })}
        </div>
        
          <button className="template-link-btn">
            <img src={linkIcon} alt="" onClick={this.showLinkComponent} />
          </button>
       
      </div>
    );
  }
}

export default withTranslation() (RadioOptionComponent);

import React, { Component } from "react";
import closeIcon from "../../assets/cross.svg";
import { StateContext } from "../../context/state";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, convertFromRaw } from "draft-js";
import { withTranslation} from 'react-i18next';

class DataPopup extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      para: "",
      editorState: { value: EditorState.createEmpty(), hideTextToolbar: true },
      paragraphs: this.props.paragraphs ? this.props.paragraphs : [],
      language:this.props.language
    };
  }
 

  componentDidMount() {
    // console.log('para', this.props.paragraphs)
    // const data=this.context
    // axios.get(`${baseUrl}api//${data.venueId}`)
    // .then((res)=>{
    //     console.log(res.data)
    //     this.setState({
    //         paragraphs:res.data.paragraphs
    //     })
    // })
    // .catch((err)=>{
    //     console.log(err)
    // })
    // console.log("para",this.props)
    let dummyPara = {
      value: EditorState.createEmpty(),
      hideTextToolbar: true,
    };
    // let string = `uns liegt es am Herzen, dass Sie und Ihre Gäste einen inspirierenden und unvergesslichen Aufenthalt bei uns auf dem Hofgut Lilienhof erleben
    //       >ein Event genau nach Ihren Vorstellungen mit vielen persönlichen Highlights
  
    //       >ein Event, die Sie und Ihre Gäste genießen und in bleibender Erinnerung behalten können
  
    //       >ein Event, die Sie beide näher verbindet und ein Meilenstein für Ihre gemeinsame Zukunft wird.
    //       Um diesem Anspruch gerecht zu werden und um den viel besagten ,Event-Stress“ im Vorfeld und während der Feier für Sie auf ein Minimum zu reduzieren,sind wir auf Ihre Mithilfe angewiesen.
    //        Besonders hervorheben möchten wir an dieser Stelle das Einhalten von Fristen und Terminen. Bitte nehmen Sie sich kurz Zeit, um sich mit unserer Zeitschiene vertraut zu machen. Dieser Online Fragebogen vereinfacht die Planung und Abstimmung Ihrer Feier auf dem Hofgut Lilienhof und wird das zentrale Tool Ihrer und unserer Vorbereitungen werden. Bitte zögern Sie nicht, uns bei Fragen oder offenen Punkten zu kontaktieren – wir helfen gerne!
    //     Viel Spaß bei Ihrer Planung!`;
        let string = ``;
    let string2 = "";
    // console.log(this.props.paragraphs,"para")

    if (this.props.paragraphs?.blocks?.length) {
      dummyPara.value = EditorState.createWithContent(
        convertFromRaw(this.props.paragraphs)
      );
    } else if (this.props.paragraphs.length) {
      this.props.paragraphs.forEach((para) => {
        //    console.log("para",para)
        string2 = string2 + para;
        //   return string2
        dummyPara.value = EditorState.createWithContent(
          ContentState.createFromText(string2)
        );
      });
    } else {
      dummyPara.value = EditorState.createWithContent(
        ContentState.createFromText(string)
      );
    }

    this.setState({
      editorState: dummyPara,
    });
  }
  onEditorStateChange = (e) => {
    // console.log(editorState)
    const editorState = Object.assign({}, this.state.editorState);
    editorState.value = e;
    this.setState({
      editorState,
    });
  };

  handlePara = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangingPara = (e, i) => {
    const paragraphs = Object.assign([], this.state.paragraphs);
    paragraphs[i] = e.target.value;
    this.setState({
      paragraphs,
    });
  };
  addPara = (e) => {
    e.preventDefault();
    let paragraphs = convertToRaw(
      this.state.editorState.value.getCurrentContent()
    );
    this.setState({
      // paragraphs:this.state.paragraphs.concat(this.state.para),
      paragraphs,
    });
    this.props.closePopup(paragraphs);
    // return this.props.closePopup()
  };
  handleLanguageForEditor=()=>{ 
    const lang= this.state.language
     switch (lang) {
       case 'de':
         return 'de';
       case 'en':
         return 'en';
       case 'se':
         return 'es';
       default:
         return lang;
     }
   }
  render() {
    const editorLabels = {
      "components.controls.link.linkTitle": "Anzuzeigender Text",
      "components.controls.link.linkTarget": "Link",
      "components.controls.link.linkTargetOption": "In neuem Fenster öffnen ",
    };
    return (
      <div className="popup-overlay " style={{ top: "20%" }}>
        <div
          className="popup-container"
          style={{
            background: "#d1d3d4",
            maxWidth: "1620px",
            width: "80%",
            height: "400px",
            display: "inline-block",
            padding: "10px",
            position: "relative",
            maxHeight: "100vh",
            overflow: "auto",
          }}
        >
          <button
            className="close-btn"
            style={{ float: "right", border: "none", borderRadius: "50%" }}
            onClick={() => this.props.closePopup(this.state.paragraphs)}
          >
            <img src={closeIcon} alt="" />
          </button>
          <button
            style={{ float: "right", border: "none", marginRight: "10px" }}
            onClick={this.addPara}
          >
           {this.props.t('Speichern')}
          </button>
          <div style={{ padding: "50px" }}>
            <h4>  {this.props.t('login_text')}</h4>
            <Editor
              localization={{ locale: this.handleLanguageForEditor(), translations: editorLabels }}
              editorState={this.state.editorState.value}
              onEditorStateChange={this.onEditorStateChange}
              onFocus={this.handleOnParagraphFocus}
              onBlur={this.handleOnParagraphBlur}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              toolbar={{fontFamily:{options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana','Roboto'],
            }}}
            />

            {/* {
                        this.state.paragraphs.map((para,i)=>{
                            return <div key={i}><textarea cols="80" style={{border:"none"}} value={para} onChange={(e)=>{this.handleChangingPara(e,i)}}>{para}</textarea><br/><br/></div>
                            })
                            
                   
                        } */}

            {/* <div style={{display:"flex", margin:"0% 20%"}}>
                        <textarea name="para" style={{border:"none"}} cols="80" value={this.state.para} onChange={this.handlePara}></textarea>&nbsp;
                        <button  style={{border:"none", borderRadius:"50%", marginTop:"10px", width:"30px", height:"30px"}} onClick={this.addPara}>
                            <img src={plusIcon} alt=""/>
                        </button>
                        </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation() (DataPopup);
import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import { StateContext } from "../../../context/state";
import LightBox from "../../../components/lightBox/LightBox";
export default class Bild extends Component {
  static contextType = StateContext;
  
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      photoIndex: 0,
    };
  }

  render() {
    let image =
      this.props.option?.bildInput?.image?.length ?
      this.props.option?.bildInput?.image :
       [{url:"http://placehold.jp/300x300.png", title:"", caption:""}];
       
       let lightboxImages = image.map((obj) => {
        return obj.url;
      });

    const { isOpen } = this.state;
    const headingFontFamily=this.context.stylingData?.headingFontFamily
    const headingFontSize=this.context.stylingData?.headingFontSize
    const textFontSize=this.context.stylingData?.textFontSize
    const textFontFamily=this.context.stylingData?.textFontFamily

    return (
			<div
				style={{
					fontSize: `${textFontSize}px`,
					objectFit: 'contain',
					marginTop: '20px',
				}}>
				{isOpen && (
					<LightBox
						lightboxImages={lightboxImages}
						setIsOpen={(e) => this.setState({ isOpen: e })}
						setPhotoIndex={(e) => this.setState({ photoIndex: e })}
						photoIndex={this.state.photoIndex}
					/>
				)}
				{/* <img src={image} style={{...styles.image, backgroundImage: image}} alt="" /> */}
				{Array.isArray(image) && image.length === 1 ? (
					<div>
						<img
							className="image"
							src={image[0].url}
							style={
								({ backgroundImage: image, objectFit: 'contain' },
								styles.pointer)
							}
							alt=""
							onClick={() => this.setState({ isOpen: true })}
						/>
						{image[0].title.length ? (
							<div
								style={{
									// top: "59%",
									background: 'rgba(255,255,255,0.8)',
									position: 'absolute',
									left: '15%',
									right: '15%',
									zIndex: '10',
									bottom: '10%',
									padding: '5px',
									fontFamily: `${headingFontFamily}`,
									fontSize: `${headingFontSize}px`,
									textAlign: 'center',
									width: '75%',
									color: 'black',
								}}>
								{image[0].title.length ? (
									<h3
										style={{
											fontFamily: `${headingFontFamily}`,
											fontSize: `${headingFontSize}px`,
											fontWeight: 'bold',
										}}>
										{image[0].title}
									</h3>
								) : null}
								{image[0].caption.length ? (
									<p
										style={{
											fontSize: `${textFontSize}px`,
											fontFamily: `${textFontFamily}`,
										}}>
										{image[0].caption}
									</p>
								) : null}
							</div>
						) : null}
					</div>
				) : (
					<div style={{ padding: '0px 20px' }}>
						{' '}
						<Carousel>
							{Array.isArray(image) &&
								image.map((i, k) => {
									return (
										<Carousel.Item>
											<div className="image-container" key={k}>
												<img
													className="d-block w-100 image"
													src={i.url}
													alt="First slide"
													onClick={() =>
														this.setState({ isOpen: true, photoIndex: k })
													}
													style={styles.pointer}
												/>
											</div>

											{i.title.length ? (
												<Carousel.Caption>
													<div
														style={{
															color: 'black',
														}}>
														<h3
															style={{
																fontFamily: `${headingFontFamily}`,
																fontSize: `${headingFontSize}px`,
																fontWeight: 'bold',
															}}>
															{i.title}
														</h3>
														<p
															style={{
																fontFamily: `${textFontFamily}`,
																fontSize: `${textFontSize}px`,
																margin: 0,
															}}>
															{i.caption}
														</p>
													</div>
												</Carousel.Caption>
											) : null}
										</Carousel.Item>
									);
								})}
						</Carousel>
					</div>
				)}
			</div>
		);
  }
}

const styles = {
  // image: {
  //   display: "block",
  //   width: "100%",
  //   height: "360px",
  //   // maxHeight: "360px",
  //   // objectFit: "contain",
  //   objectPosition: "0% 0%",
  //   overflow:"auto"
  // },

  pointer: {
    cursor: "pointer"
  }
};

import React, { Component } from "react";
import axios from "axios";
import { StateContext } from "../../context/state";
import { baseUrl } from "../../Constants.js";
import docIcon from "../../assets/doc.svg";
class DownloadOption extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      newData: {},
      childData: {},
    };
  }
  componentDidMount() {
    if (this.props.data) {
      if (this.props.data.images) {
       
        // this.props.updateAttachments(image);
      }
      this.setState({
        newData: this.props.data,
      });
    }
    // else{
    const option = this.props.option;
    axios
      .get(
        `${baseUrl}api/export/ServiceElement/${this.context.childQuestionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`
      )
      .then((res) => {
        const d = res.data;
       
        if( (d.images)||(d.uploadInput.inputs)) {
          const image = {
            url: d.images,
          };
        
          this.props.updateAttachments(image);
          if(this.state.newData.exportImgs.largeImg===true){
            this.props.downloadAttachment(d.uploadInput.inputs,res.data.isDisplay)
          }
          // if(this.props.attachments.length){
          //     const newDat=this.props.attachments.find((urls)=>{
          //         return urls.url===d.images
          //     })
          //     if(!newDat){
          //         const newD=this.props.attachments.concat(image)
          //         this.props.updateAttachments(newD)

          //     }
          // }
          // else{
          //     const newD=this.props.attachments.concat(image)
          //     this.props.updateAttachments(newD)                   }
        }
        this.setState({
          childData: d,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  }
  downloadFile = (file) => {
    let a = document.createElement("a");
    a.href = file.url;
    a.download = file.name;
    a.target = "_blank";
    a.click();
  };

  render() {
    return (
      <div>
        {(this.state.childData.isDisplay && this.state.childData.isDisplay==="true" && this.state.newData.uploadInput?.inputs.length!==0) ? 
        <>
        <div className="qtn_optns">
          {this.state.newData.uploadInput?.inputs && (
            <>
              <div style={{ background: "white" }} className="quest1">
                {this.state.newData.uploadInput?.qtnAlternate ? (
                  <b>
                    <i>{this.state.newData.uploadInput?.qtnAlternate}</i>
                  </b>
                ) : (
                  <b>
                    <i>{this.state.newData.uploadInput?.question}</i>
                  </b>
                )}
              </div>
              {/* <input 
                        type="text" 
                        className="inp1" 
                        placeholder="Ersatztext"
                        value= {this.state.newData.uploadInput.qtnAlternate}
                        onChange={(e)=>{this.handleChangeQtnAlt(e)}}
                        autoComplete="off"
                        /> */}
            </>
          )}
        </div>
      
        <div
          style={{ marginBottom: "10px", display: "flex", flexWrap: "wrap" }}
        >
          {this.state.childData.uploadInput?.inputs.map((input, i) => {
            return (
							<div key={i}>
								{
									<div
										style={{
											paddingBottom: '10px',
											paddingLeft: i !== 0 ? '20px' : '',
										}}>
										<div>
											{this.state.newData?.exportImgs?.thumbnail === true && (
												<a
													target="_blank"
													rel="noopener noreferrer"
													href={`${input.url}`}>
													{/* {console.log("input", input)} */}
													<img
														src={
															input.type === 'jpeg' ||
															input.type === 'jpg' ||
															input.type === 'png' ||
															input.type === 'image'
																? `${input.url}`
																: docIcon
														}
														alt=""
														style={{ width: '180px', height: '160px' }}
													/>
												</a>
											)}
										</div>

										{/*   
    <div style={{display:"flex",paddingLeft:"10px"}}>
    {this.state.newData?.exportImgs?.largeImg===true &&
    <img  src={(input.type==="pdf"||input.type==="doc")? docIcon:`${input.url}`}  
    style={(input.type==="pdf"||input.type==="doc")? styles.pdf:styles.image} alt=""/>
            }
 </div> */}
										<div>
											{this.state.newData?.exportImgs?.url === true && (
												<a
													style={{ height: '20px', outline: 'none' }}
													target="_blank"
													rel="noopener noreferrer"
													href={`${input.url}`}>
													{input.name}
												</a>
											)}
										</div>
									</div>
								}
							</div>
						);
          })}
        </div>
     
     </>:<></>}
        </div>
    );
  }
}

export default DownloadOption;

import React, {Component} from "react";
import arrowRight from "../../assets/arrow-small-right.svg";
import arrowDown from "../../assets/arrow-small-down.svg";
import {createRef} from "react/cjs/react.production.min";

export default class DropDown extends Component {

    ref = createRef();

    constructor(props) {
        super(props);

        this.state = {
            activeOption: -1,
            showOptions: false,
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = e => {
        if (!this.ref?.current?.contains?.(e.target)) {
            this.setState({showOptions: false})
        }
    };

    onKeyDown = (e) => {

        const {activeOption} = this.state;
        const {options, onSelect} = this.props;

        const state = {};

        if (e.keyCode === 13) {
            onSelect && onSelect(options[activeOption]?.code);
            state.showOptions = false;
            state.activeOption = -1;
        } else if (e.keyCode === 38) {
            state.activeOption = (activeOption - 1 + options.length) % options.length;
        } else if (e.keyCode === 40) {
            state.activeOption = (activeOption + 1) % options.length;
        }

        e.preventDefault();
        this.setState(state);
    };

    render() {
        const {activeOption, showOptions} = this.state;
        const {value, placeholder, style, options, disabled} = this.props;
        const dropDownStyle = {...styles.dropdown, ...style};

        const opt = options?.filter(option => option.code === value);

        return (
            <div style={styles.container} ref={this.ref}>
                <div style={dropDownStyle}>
                    <input
                        maxLength={30}
                        style={styles.input}
                        value={opt?.[0]?.name ?? placeholder ?? ""}
                        onKeyDown={this.onKeyDown}
                        onClick={() => !disabled && this.setState({showOptions: true})}
                        readOnly={true}
                        autoComplete="off"
                    />
                    <img
                        alt="dropdown"
                        style={styles.icon}
                        src={showOptions ? arrowDown : arrowRight} />
                </div>

                <div style={styles.relative}>
                    {showOptions && options?.length > 0 && <div style={styles.wrapper}>
                        {options?.map((option, index) =>
                            <div key={index}
                                 style={{...styles.option, background: index === activeOption && "#d0d3d4"}}
                                 onClick={() => this.onSelect(option)}
                            >
                                {option?.name}
                            </div>)
                        }
                    </div>}
                </div>
            </div>
        );
    }

    onSelect = (option) => {
        this.props.onSelect(option?.code);
        this.setState({
            showOptions: false,
            activeOption: -1
        });
    };
}

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        width: "fit-content"
    },
    relative: {
        position: "relative"
    },
    dropdown: {
        background: "#e7e7e8",
        color: "#939599",
        fontSize: "14px",
        width:"min-content",
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
    },
    wrapper: {
        position: "absolute",
        maxHeight: "200px",
        background: "#e7e7e8",
        border: "1px solid #939599",
        zIndex: "60",
        left: "0",
        right: "0",
        fontSize: "12px",
        overflow: "auto",
    },
    option: {
        padding: "5px 10px",
        cursor: "pointer",
        color: "#939599",
        backgroundColor: "transparent"
    },
    icon: {
        marginRight: "15px",
        height: "8px",
        width: "8px",
    },
    input: {
        border: 0,
        margin: 0,
        maxWidth: "150px",
        cursor: "pointer",
        background: "transparent",
        padding: "10px 15px",
    }
};
import React, { Component } from "react";
import linkIcon from "../../assets/link.svg";
import { withTranslation } from 'react-i18next';

class DatumOptionComponent extends Component {
  constructor(props) {
    super(props);
    this.messagesRef=React.createRef(null);
    this.state = {
      hasDatum: true,
      hasTime: true,
      // question:""
      dateInput:{
        hasDatum: true,
        hasTime: true,
        question:""
      },
      dateSelected:false
    };
  }
  componentDidMount() {
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    // console.log("nextProps", nextProps.datumOptionConfiguration)
    return {
      hasDatum: nextProps.datumOptionConfiguration !== undefined ? nextProps.datumOptionConfiguration.hasDatum : true,
      hasTime: nextProps.datumOptionConfiguration !== undefined ? nextProps.datumOptionConfiguration.hasTime : true,
      question: nextProps.datumOptionConfiguration !== undefined ? nextProps.datumOptionConfiguration.question : ""
    };
  };
  display=(value)=>{
    this.setState({dateSelected:value})
  }
  handleClicked=()=>{
    const info={
      hasDatum: this.state.hasDatum,
      hasTime: this.state.hasTime,
      question:this.state.question
    }
    this.props.setOptionSelected(this.props.index, "datum", null, null, info);
  }
  
  setQuestion = (e) => {
    // const radioInput = this.state.radioInput;
    let {dateInput}=this.state
    dateInput.question = e.target.value;
    this.setState({ dateInput });
    this.props.updateDate(dateInput.question);
    

  };
  showLinkComponent = (e) => {
    e.stopPropagation()
    this.props.toggleLinkOption(null, null, this.props.index);
  };
  render() {
  
    return (

      <div
      ref={this.messagesRef}
      id="date"
      tabIndex="0"
      className="datum-option-component" 
      style={{ border: "2px dashed #bcbec0",
       // paddingBottom: "10px",
        background:this.props.layoutObject[this.props.index[0]]?.row[this.props.index[1]]?.column[this.props.index[2]]?.selected===true ? "#aaacaf":"",
      }} 
      onFocus={()=>{this.display(true)}}
      onBlur={()=> {this.display(false)}}
        onClick={this.handleClicked}>
        {/* {console.log("option",this.props.optionType,"dateSelected",this.state.dateSelected)} */}
          <input
            type="text"
            className="question"
            placeholder={this.props.t("Text einfügen")}
            value={this.state.question}
            onChange={this.setQuestion}
           
            // onClick={this.handleClicked}
            style={{ background: "#e6e7e8",
             width:"90%",
             marginTop:"5px",
            border: "0",
            fontSize: "24px",
              marginRight: "60px",
            borderRadius: "3px",
           
            color:" #939598",
            padding: "6px 12px" }}
            autoComplete="off"
          />
        {this.state.hasDatum && (
          <div className="datum-container">
            
            <div className="date">TT.MM.JJJJ</div>
            <span>{this.props.t('Datum')}</span>
          </div>
        )}
        {this.state.hasTime && (
          <div className="time-container">
            <div className="time">00:00</div>
            <span>{this.props.t('Uhrzeit')}</span>
          </div>
        )}
          <button className="template-link-btn" onClick={(e)=>{this.showLinkComponent(e)}} >
            <img src={linkIcon} alt="" />
          </button>
      </div>
    );
  }
}

export default withTranslation() (DatumOptionComponent);

import React, { Component } from "react";
import { StateContext } from "../../context/state";
import editIcon from "../../assets/edit.svg";

export default class PriceBar extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);

    this.state = {
    showRoom:false
    };
  }

  static getDerivedStateFromProps(nextProps) {
   
  }
  onDragStart = (e, index) => {
    if(this.props?.rooms[index]?.roomType==="restricted"){
      e.preventDefault()
    }
    e.dataTransfer.setData("index", index);
    // const index2 = e.dataTransfer.getData("index");
    // console.log("index2",index2)
    this.props.dragData(e, index)

  };
  componentDidMount() {
   
  }
  
handleEditRoom=(index)=>{
const {rooms}=this.props
// console.log("rooms[index]",rooms[index])

this.props.displayRoom(true)
this.props.sendOneRoom(rooms[index])
}
  render() {
  
    return (
      <React.Fragment>
      
        
        <div style={styles.container}>
         <span>Sideplan</span>
         <button type="submit" 
                  className="outline-button speichern"
                  style={ styles.outline}
                  onClick={(e)=>{
                    this.props.displayRoom(!this.state.showRoom)
                    this.setState({showRoom:!this.state.showRoom})}}
                >
                  Add Room
                </button>
                {this.props.rooms.length && this.props.rooms.map((room,index)=>{
               return <div style={{display:"flex",marginTop:"20px",color:room.roomType==="restricted" && "gray"}}>
                  <span
                    id={index}
                    draggable
                    onDragStart={(e) => this.onDragStart(e, index)}
                  style={{...styles.circle,background:room.roomType==="restricted" ? "gray":"#297825"}}>{index}</span>
                   &nbsp;&nbsp;&nbsp; <span>{room?.name}</span>
                 <img alt={ "Edit" } src={editIcon} onClick={()=>this.handleEditRoom(index)}style={{width:"20px",cursor:"pointer",height:"20px",marginLeft:"60px"}}/>
                   </div>
                })}
        </div>
      </React.Fragment>
    );
  }

}

const styles = {
  title: {
    fontFamily: "Crete Round",
    fontSize: "19px",
    color: "#000",
    margin: "0px",
  },
  circle:{
    // background: "#297825",
borderRadius: "50%",
padding: "2px 12px",
color:"black"
  },
  bold: {
    fontSize: "16px",
    margin: "0px",
    color: "#000",
    fontWeight: "600",
  },
  text: {
    fontSize: "16px",
    fontWeight: "300",
    margin: "0px",
    color: "#000",
  },
  cost: {
    fontSize: "40px",
    fontWeight: "600",
    color: "#000",
  },
 
  container: {
    // textAlign: "right",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    // padding: "60px 10px 10px 20px",
    width:"400px",
    padding:"60px 10px 10px 10px",
    background:"rgb(208, 211, 212) none repeat scroll 0% 0%"
  },
  pointer: {
    cursor: "pointer",
  },
  loader: {
    color: "#888888",
    fontWeight: "bold",
    fontSize: "20px",
    padding: "20px",
    textAlign: "center",
  },
  outline: {
    color: "#297825",
    fontFamily: "Crete Round",
    fontSize: "13px",
    border: "1px solid #297825",
    // minWidth: "143px",
    padding: "9px 15px",
    textAlign: "center",
    display: "inline-block",
    marginRight: "15px",
    // float: "right",
  },
};

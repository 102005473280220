import React, { Component } from 'react';
// import home from '../../assets/home.svg'
// import back from '../../assets/collapse.svg'
// import print from '../../assets/print.svg'
// import pdf from '../../assets/pdf.svg'
// import link2 from '../../assets/link2.svg'
// import ExportSectionPopup from '../partials/ExportSectionPopup'
// import ReactToPrint from 'react-to-print';
// import ReactToPdf from 'react-to-pdf';
import TextOption from "./text";
import DatumOption from "./datum";
import RadioOption from "./radio";
import ImageOption from "./image";
import BildOption from "./bild";
import TableOption from "./table";
import UploadOption from "./upload";
import {StateContext} from "../../context/state";
// import axios from "axios";
// import {baseUrl} from '../../Constants.js'
import { Document, Page, Text } from '@react-pdf/renderer'


React.createRef();
const att=[]
class PlanChild extends Component {
  static contextType = StateContext;
    constructor(props){
        super(props)
        this.state={
            event:{},
            showExportPopup:false,
            themes:[],
            attachments:[]
        }
    }
    componentDidMount(){
      // this.context.updateState({attachments:[]})
      // axios.get(`${baseUrl}api/export/${this.context.questionnaireId}/${this.context.exportPlanId}`)
      // .then((res)=>{
      //   // console.log('res preview', res.data.themes)
      //     this.setState({
      //     event:data,
      //     themes:res.data?.themes
      //   })
      // }) 
      // .catch((err)=>{
      //   console.log(err)
      // }) 
     
    }

    handleMailPDF=(data)=>{
      console.log('attachments', data)

    }
    handleBackClick=()=>{
        this.setState({
            showExportPopup:!this.state.showExportPopup
        })
    }
    closeExportPopup = () => this.setState({ showExportPopup: false });
    // handleOnFocus=(themeId, divId)=>{
    //   const themes=this.state.themes
    //   for(let i of themes){
    //     if(i.themeId===themeId){
    //       for(let j of i.divs){
    //         if(j.divId===divId){
    //          j["hideToolbar"]=false
    //         }
    //       }
    //     }
    //   }
     
    //   this.setState({themes})
    // }
    // handleOnBlur=(themeId, divId)=>{
    //   const themes=this.state.themes
    //   for(let i of themes){
    //     if(i.themeId===themeId){
    //       for(let j of i.divs){
    //         if(j.divId===divId){
    //           j["hideToolbar"]=true
    //         }
    //       }
    //     }
    //   }
     
    //   this.setState({themes})
    // }

//     onEditorStateNoteChange=(themeId, divId, editorState)=>{
//   // console.log("editor",themeId, divId,editorState)
//   const copy=Object.assign([], this.state.themes)
//     for(let i of copy){
//       if(i.themeId===themeId){
//         for(let j of i.divs){
//           if(j.divId===divId){
//             j.editorStateNote=editorState
//           }
//         }
//       }
//     }
//   this.setState({
//     theme:copy
//   });
// };
setTextInputValue = (themeId ,id, e) => {
  const copy=Object.assign([], this.state.themes)
  for(let i of copy){
    if(i.themeId===themeId){
      for(let j of i.divs){
        if(j.divId===id){
          j[e.target.name]=e.target.value
        }
      }
    }
  }
  this.setState({ themes:copy });
};

    renderOption = (option, divId, divData) => {
        switch (option.optionType) {
          case "text":
            return (
              <TextOption 
                key={divId}
                data={divData}
                option={option}
                updateChange={this.updateChange}
              />
            );
          case "datum":
            return (
              <DatumOption
                key={divId}
                data={divData}
                option={option}
                updateChange={this.updateChange}
              />
            );
          case "bild":
            return (
              <BildOption
                key={divId}  
                data={divData}          
                option={option}
                attachments={this.state.attachments}
                updateChange={this.updateChange}
                updateAttachments={this.updateAttachments}

              />
            );
          case "radio":
            return (
              <RadioOption 
                key={divId}            
                data={divData}
                option={option}
                updateChange={this.updateChange}
              />
            );
          case "table":
            return (
              <TableOption
                key={divId}
                data={divData}
                option={option}
                updateChange={this.updateChange}
              />
            );
          case "upload":
            return (
              <UploadOption
                key={divId}
                data={divData}
                option={option}
                attachments={this.state.attachments}
                updateChange={this.updateChange}
                updateAttachments={this.updateAttachments}

              />
            );
          case "image":
            return (
              <ImageOption
                key={divId}
                data={divData}
                option={option}
                attachments={this.state.attachments}
                updateChange={this.updateChange}
                updateAttachments={this.updateAttachments}
              />
            );
          default:
            break;
        }
      };
      updateAttachments=(image)=>{
        if(att.length){
              const newDat=att.find((urls)=>{
                  return urls.url===image.url
              })
        if(!newDat){
            att.push(image)
          }
      }
      else{
        att.push(image)
      }
    }
    render() {
        return (
            <Document>
            <Page>
              <Text>hi</Text>
            {/* <div>              
               <div style={{width:"50%", marginLeft:"150px"}}  ref={ref}>
              
                {
                    this.state.themes.map((theme, j)=>{
                      // console.log('theme', theme)

                       return <div className="event_plan_details" key={j}>
                       { theme.divs.map((div,i)=>{
                            if(div.textClicked){
                            return <div key={i}>{div.text}</div>
                        }
                        else if(div.noteClicked){
                              return  <textarea
                              key={div.divId}
                              type="text"
                              name="note"
                              placeholder="Note" 
                              className="input_field"
                              value={div.note}
                              onChange={e=>this.setTextInputValue(theme.themeId, div.divId, e)}
                              />
                        }
                        else if(div.infoClicked){    
                          // console.log('div', div.selectedDropdown )                       
                            return this.renderOption(div.selectedDropdown, div.divId, div?.data )
                
                       }
                        else{
                            return null
                        }
                        })
                        }
                        </div>
                    })
                }
                <img src={home} alt=""/>
               
               </div>
               
               <div>
                </div>
            </div> */}
            </Page>
               </Document>
        );
    }
}

export default PlanChild;
import React, { Component } from 'react';
import PullBar from './PullBar';
import HttpService from '../../services/HttpService';
import { StateContext } from '../../context/state';
import { Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class PriceBar extends Component {
	static contextType = StateContext;
	constructor(props) {
		super(props);

		this.state = {
			total: 0,
			current: 0,
			priceModel: {},
			questionnaire: {},
			activeTheme: false,
			activeService: false,
			couple: props?.couple,
			latestPrice: [],
			guestInfo: {},
		};
	}

	static getDerivedStateFromProps(nextProps) {
    if (!nextProps.loading) {
			const { activeTheme, activeService, guestPriceObj, latestPrice } =
				nextProps;
			const { model, valid } = PriceBar.buildPriceModel(nextProps);
			if (nextProps.onValidate) nextProps.onValidate(valid);
			const total = Number(nextProps?.totalAmount) ?? 0.00;
			const current =
				PriceBar.calculateTotalPrice(model)
					?.toFixed(2)
					.replace('.', ',')
					.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
			return {
				model,
				total,
				current,
				activeTheme,
				activeService,
				guestPriceObj,
				latestPrice,
      }; 
		} else {
			const total =
				Number(nextProps?.totalAmount)
					?.toFixed(2)
					.replace('.', ',')
					.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
			return {
				total,
			};
		}
	}

	nanElimination = (priceAmount) => {
		if (isNaN(priceAmount)) {
			return 0.00;
		} else { return priceAmount}
	}


	priceRefesh = async () => {
		const { questionnaireId, loggedInUserId } = this.context;
		const { activeTheme, activeService } = this.props;
		const quest = Object.assign({}, this.props.questionnaire);
		delete quest._id;
		await HttpService.uploadAnswer(
			questionnaireId,
			activeTheme.id,
			activeService.id,
			loggedInUserId,
			quest
    );
    
		let questionnaire = await HttpService.getForms(
			questionnaireId,
			activeTheme.id,
			activeService.id
		);

		const themePrice = await HttpService.themePriceUpdate(
			this.context.questionnaireId
    );
    
		const uploadPrice = await HttpService.uploadPrice(
			this.context.questionnaireId
    );
    
		let themes;

		if (themePrice.status === 'success' && uploadPrice.status === 'success') {
			themes = (await HttpService.getThemes(this.context.questionnaireId))
				?.themeApiObject;

			themes = themes?.themes ?? [];
			for (let theme of themes)
				theme.services = theme?.services?.filter((service) => service?.display);
			themes = themes.filter((theme) => theme?.services?.length > 0);
			themes = [{ id: -1, name: 'Anzahl Gäste', services: [] }, ...themes];
			let latestPrice = await HttpService.getPriceBarLatestData(
				questionnaireId
			);
			if (this.props.updatePriceState) {
				this.props.updatePriceState(latestPrice, themes, questionnaire);
			}
		}
  };
  
	static calculateTotalPrice = (model) => {
		let sum = 0;
		Object.getOwnPropertyNames(model).forEach((key) => {
			if (Number(model[key])) sum += model[key];
		});
		return Number(sum);
	};

	static buildPriceModel = (props) => {
		const { activeService } = props;
		let valid = true;
		const model = {};

		if (!activeService) return { model, valid };

		const obj2 = [];
		const obj = Object.values(model);
		let bool = false;
		//  console.log("obj",obj)
		obj.forEach((o) => {
			if (typeof o == 'string' && o !== '') {
				bool = true;
				obj2.push(o);
			}
		});
		// console.log("bool",bool)
		// console.log("obj2",obj2)
		if (bool) {
			sessionStorage.setItem('error', JSON.stringify(true));
			sessionStorage.setItem('errorMessage', JSON.stringify(obj2));
		} else if (!bool) {
			sessionStorage.setItem('error', JSON.stringify(false));
			sessionStorage.setItem('errorMessage', JSON.stringify([]));
		}
		return { model, valid };
	};
	calculateGuestTotal = (model) => {
		const obj = Object.values(model);
		const obj2 = [];
		let bool = false;
		obj.forEach((o) => {
			if (typeof o == 'string' && o !== '') {
				obj2.push(o);
				bool = true;
			}
		});

		if (bool) {
			sessionStorage.setItem('guestErrors', JSON.stringify(true));
			sessionStorage.setItem('guestErrorMessage', JSON.stringify(obj2));
		} else if (!bool) {
			sessionStorage.setItem('guestErrors', JSON.stringify(false));
			sessionStorage.setItem('guestErrorMessage', JSON.stringify([]));
    }
    
    let sum = 0;
    
		Object.getOwnPropertyNames(model).forEach((key) => {
			if (Number(model[key])) sum += model[key];
		});

		return this.context.languageFormat === 'en'
			? Number(sum)
					.toFixed(2)
					.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
			: Number(sum)
					.toFixed(2)
					.replace('.', ',')
					.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };
  
	findLabel = (name) => {
		const { guestInfo } = this.state;

		if (name === 'adults') {
			return guestInfo.adults;
		} else if (name === 'U12') {
			return guestInfo.U12;
		} else if (name === 'U18') {
			return guestInfo.U18;
		} else if (name === 'U6') {
			return guestInfo.U6;
		} else {
			return null;
		}
  };
  
	renderGuestPriceContent = () => {
		const { guestPriceObj } = this.props;

		return Object.keys(guestPriceObj).forEach((key, index) => {
			if (guestPriceObj[key])
				return (
					<React.Fragment key={index}>
						{Number(guestPriceObj[key]) ? (

							<>
								<br />
								<div style={{ ...styles.text }}>
									{this.findLabel(key, index)}
								</div>
								<div>
									{`
                  ${
										this.context.languageFormat === 'en'
											? Number(guestPriceObj[key])
													.toFixed(2)
													.replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0.00
											: Number(guestPriceObj[key])
													.toFixed(2)
													.replace('.', ',')
													.replace(/\B(?=(\d{3})+(?!\d))/g, '.') ?? 0.00
									}
                   ${this.context.currencyFormat}`}
								</div>
              </>
              
						) : (
							<>
								<br />
								<div style={{ ...styles.text }}>
									{this.findLabel(key, index)}
								</div>
								<div
									style={{ ...styles.bold, color: 'red', marginBottom: '5px' }}>
									{guestPriceObj[key]}
								</div>
							</>
						)}
					</React.Fragment>
				);
		});
	};

	renderContent = () => {
		const { activeTheme, activeService, model } = this.state;

		if (!activeTheme || !activeService) return;
		return Object.keys(model).forEach((key, index) => {
			if (model[key])
				return (
					<React.Fragment key={index}>
						{Number(model[key]) ? null : (
							<>
								<div style={{ ...styles.text }}>{key}</div>
								<div
									style={{
										...styles.bold,
										color: 'red',
										marginBottom: '10px',
									}}>
									{model[key]}
								</div>
							</>
						)}
					</React.Fragment>
				);
		});
  };
  
	renderLatest = () => {
		const { activeTheme, activeService, latestPrice } = this.state;
		if (!activeTheme || !activeService) return;
		return latestPrice?.map((price, index) => {
			return (
				<div
					key={index}
					style={{
						margin: '5px 0px 15px 0px',
						fontSize: '18px',
						lineHeight: '25px',
						fontWeight: '300',
					}}>
					<div style={{ color: 'gray' }}>{`${price.serviceName}`} </div>
					<div style={styles.bold}>
						{`
          ${
						this.context.languageFormat === 'en'
							? Number(price.cost)
									.toFixed(2)
									.replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0.00
							: Number(price.cost)
									.toFixed(2)
									.replace('.', ',')
									.replace(/\B(?=(\d{3})+(?!\d))/g, '.') ?? 0.00
					}
           ${this.context.currencyFormat}`}
					</div>
				</div>
			);
		});
	};

	functionCapitalise = () => {
		let title = '';
		if (this.context?.cuDetails?.title1) {
			title = this.context?.cuDetails?.title1[0]
				.toUpperCase()
				.concat(
					this.context?.cuDetails?.title1.slice(
						1,
						this.context?.cuDetails?.title1.length
					)
				);
		}
		return title;
  };
  
	functionCapitaliseTitle2 = () => {
		let title = '';
		if (this.context?.cuDetails?.title2) {
			title = this.context?.cuDetails?.title2[0]
				.toUpperCase()
				.concat(
					this.context?.cuDetails?.title2.slice(
						1,
						this.context?.cuDetails?.title2.length
					)
				);
		}
		return title;
	};

	
	
	


	render() {
		const { couple, activeService, activeTheme } = this.props;

		return (
			<React.Fragment>
				<div style={{ display: 'flex' }}>
					{this.props.history.location.pathname !== '/invoice' && (
						<PullBar
							{...this.state}
							guestPriceObj={this.props.guestPriceObj}
							updatePriceState={this.props.updatePriceState}
						/>
					)}
				</div>
				<div style={styles.container}>
					<h6 style={styles.title}>{this.props.t('Estimated price')}</h6>
					<h1 style={styles.cost}>
						{(this.props.allowPricingApi === true ||
							this.props.uploadPriceStatus === true) && (
							<span style={styles.loader}>
								<span>
									<Spinner
										size="sm"
										style={
											this.context.stylingData?.['lineColor']
												? { color: this.context.stylingData?.lineColor }
												: { color: 'success' }
										}
									/>
								</span>
							</span>
						)}

						{this.context.languageFormat === 'en'
							? !isNaN(this.state.total)
								? Number(this.state.total)
										.toFixed(2)
										.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
								: ''
							: !isNaN(this.state.total)
							? Number(this.state.total)
									.toFixed(2)
									.replace('.', ',')
									.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
							: ''}
						{this.context.currencyFormat}
					</h1>

					<div
						className="separator"
						style={{
							background: this.context.stylingData?.lineColor
								? `${this.context.stylingData.lineColor}`
								: `${this.context.defaultSettingStyle.presAccentColor}`,
						}}
					/>
					{activeTheme?.id === -1 && (
						<>
							<h5>
								{this.props.t('Gästepauschale')}:{' '}
								{this.calculateGuestTotal(this.props.guestPriceObj)}
							</h5>
							{this.renderGuestPriceContent()}
						</>
					)}

					<div style={{ flex: '1 1 auto', overflowY: 'auto' }}>
						{activeTheme?.id !== -1 && activeService && this.renderLatest()}
					</div>
					<div style={{ flex: '1 1 auto', overflowY: 'auto' }}>
						{activeTheme?.id !== -1 && activeService && this.renderContent()}
					</div>
					<div>
						<p style={styles.title}>{this.props.t('Billing_address')}</p>
						<p
							style={{ ...styles.text, ...styles.pointer }}
							onClick={this.toUserDetailsPage}>
							{this.props.t('Change')}
						</p>
						<p />

						{couple?.companyName ? (
							<p style={styles.text}>{couple?.companyName}</p>
						) : (
							<>
								<p style={styles.bold}>
									{couple?.firstName1} {couple?.surname1}
								</p>
								<p style={styles.bold}>
									{couple?.firstName2} {couple?.surname2}
								</p>
							</>
						)}
						<p style={styles.text}>{`${
							couple?.streetNumber || 'undefiniert'
						}`}</p>
						<p style={styles.text}>{`${couple?.postCode || 'undefiniert'} ${
							couple?.state || 'undefiniert'
						}`}</p>
						<br />
						<p style={styles.text}>{`Tel. ${
							couple?.telNumber1 || 'undefiniert'
						}`}</p>
						<p style={styles.text}>{couple?.email1 || 'undefiniert'}</p>
					</div>
				</div>
			</React.Fragment>
		);
	}

	toUserDetailsPage = () => {
		const { history, couple } = this.props;
		if (!history) {
			return;
		}
		history.push('/userInfo', {
			user: { ...couple, userId: couple._id },
			disabled: false,
		});
	};
}

export default withTranslation()(PriceBar);
const styles = {
	title: {
		fontFamily: 'Crete Round',
		fontSize: '19px',
		color: '#000',
		margin: '0px',
	},
	bold: {
		fontSize: '16px',
		margin: '0px',
		color: '#000',
		fontWeight: '600',
	},
	text: {
		fontSize: '16px',
		fontWeight: '300',
		margin: '0px',
		color: '#000',
	},
	cost: {
		fontSize: '40px',
		fontWeight: '600',
		color: '#000',
	},

	container: {
		textAlign: 'right',
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'column',
		padding: '60px 10px 10px 20px',
	},
	pointer: {
		cursor: 'pointer',
	},
	loader: {
		color: '#888888',
		fontWeight: 'bold',
		fontSize: '20px',
		padding: '20px',
		textAlign: 'center',
	},
};

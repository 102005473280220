import React, { Component } from "react";
import { StateContext } from "../../context/state";
import axios from "axios";
import { baseUrl } from "../../Constants.js";
import checkIcon from "../../assets/check.png";
import TextOption from "./text";
import DatumOption from "./datum";
import ImageOption from "./image";
import BildOption from "./bild";
import TableOption from "./table";
import UploadOption from "./upload";
import DownloadOption from "./download";
import RadioOption2 from "./radio2";
class RadioMultipleOption extends Component {
  static contextType = StateContext;
  constructor() {
    super();
    this.state = {
     
      data: {},
      newData: {},
      newData2:{}
    };
  }
  
  componentDidMount() {
    // console.log("newData",this.props)
    if (this.props.data) {
      if (this.props.data?.imageInput?.inputs) {
        this.props.data.imageInput.inputs.forEach((item) => {
          if (item.image) {
            const image = {
              url: item.image,
            };
            this.props.updateAttachments(image);
          }
        });
      }
      this.setState({
        newData: this.props.data,
        newData2:this.props.data2
      });
    }
    const option = this.props.option;
    
    // console.log("option",option)
    axios
      .get(
        `${baseUrl}api/export/ServiceElement/${this.context.childQuestionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`
      )
      .then((res) => {

        // console.log("res",res.data)
        const d = res.data;
        //converting obj to array if question chnged from multiplechoice to radio
        if(d?.answer){
        if (!Array.isArray(d?.answer)) {
          // console.log("true");
         const ansArray = [];
         ansArray.push(d?.answer); 
         d.answer=ansArray;
        }
      }
        
        this.setState({
          data: { ...d },
         
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  renderOption = (option) => {
    switch (option.optionType) {
      case "text":
        return <TextOption option={option} />;
      case "datum":
        return <DatumOption option={option} />;
      case "bild":
        return <BildOption option={option} />;
      case "radio":
        return <RadioOption2 option={option} />;
      case "table":
        return <TableOption option={option} />;
      case "upload":
        return <UploadOption option={option} />;
      case "download":
        return <DownloadOption option={option} />;
      case "image":
        return <ImageOption option={option} />;

      default:
        break;
    }
  };

  display = (option) => {
    //  console.log("opotions",option)
    //  console.log("newdata",this.state.newData)
    //  console.log("data",this.state.data)
    let dummyData;
    if (this.state.data) {
      dummyData = Object.assign({}, this.state.data);
    }

  
    let bool;

    if (dummyData?.answer) {
      for (let ans of dummyData.answer) {
        if (ans.id === option.id) {
          if (option.showMark === true) {
            // console.log("in ids same")
            // console.log("bool inside loop",bool)
            bool = true;
            // console.log("bool inside loop",bool)
            break;
          }
        }
      }
    }
    // console.log("bool outside loop",bool)
    if (bool === true) {
      return true;
    } else {
      return false;
    }
  };


  handleDisplayAnswers = (id) => {  
    if (this.state.data?.answer?.length) {
      const dummy = Object.assign([], this.state.data?.answer);

      const findingElement = dummy.find((ans) => {
        return ans.id === id;
      });
      if (findingElement) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  
  render() {
   
    if (this.state.data) {
      Object.assign({}, this.state.data);
    }
    let ans;
    if (this.state.data?.answer) {
      ans = this.state.data?.answer;   
    }
    
  
    let show = false;
    if (this.props.data2?.radioInput?.checkboxQuestionSelected) {
      show = true;
    } else if (this.state.data?.radioInput?.checkboxQuestionSelected) {
      show = true;
    }
   
 
  //  console.log("data-checkbox",this.state.data);
  //  console.log("data2-checkbox",this.props.data2);
    return (
      <>
      {this.state.data.isDisplay!=="false" ? 
        <div>
     
          <div className="qtn_optns2" style={{ background: "white" }}>
            
            {(ans !== undefined &&
              ans?.length !== 0 )&&
              !show &&
              this.state.data?.radioInput &&
              (this.props.data2!==undefined &&
              Object.keys(this.props.data2)?.length) && (
                <>
                 

                  {/* {bool2 == false && ( */}
                    <div style={{ marginTop: "10px" }}>
                      {/* {console.log("in div",this.state.newData)} */}
                      {this.props.data2?.radioInput?.qtnAlternate ? (
                        <div>
                          <b>{this.props.data2?.radioInput?.qtnAlternate}</b>
                        </div>
                      ) : (
                        <div><b>{this.state.data?.radioInput?.question}</b></div>
                      )}
                    </div>
                  {/* )} */}
                </>
              )}
          </div>

          <div>
            {/* {console.log("newdata",this.props.data2)}
            {console.log("data",this.state.data)} */}
            {this.state.data?.radioInput?.inputs?.length &&
              (this.props.data2!==undefined && Object.keys(this.props.data2)?.length) &&
              this.props.data2?.radioInput?.inputs?.map((option, i) => {
                return (
                  <div
                    // className={
                    //   this.handleDisplayAnswers(option.id) !== false
                    //     ? "qtn_optns"
                    //     : "qtn_optns2"
                    // }
                    key={i}
                    className="qtn_optns2"

                    // style={{
                    //   marginBottom: bool == true ? "10px" : "",
                    // }}
                  >
                 
                    {ans === undefined || ans.length === 0 ? (
                      //  <span style={styles.circle} />
                      <></>
                    ) : 
                    // <div>
                    //   {console.log("opt", option.id, option.showMark)}
                    //   hi
                    //   </div>
                    option.showMark === false ? (
                      this.handleDisplayAnswers(option.id) !== false && (
                        <div style={{ marginBottom: "10px" }}>
                          <span style={styles.filledCircle} />
                        </div>
                      )
                      
                    ) : (
                      <></>
                    )
                    }
                    <>
                      {ans === undefined || ans.length === 0 ? (
                        <></>
                      ) :   option.showMark ===false ? (
                        (this.handleDisplayAnswers(option.id) !== false &&
                        ans.length )&&
                        // eslint-disable-next-line array-callback-return
                        ans.map((ans) => {
                          if (ans.id === option.id) {
                            return (
                              <>
                                {/* {console.log("alt",this.state.newData?.radioInput?.inputs[i])} */}
                                {(
                                  Object.keys(this.state.data).length !== 0 &&
                                  this.props.data2?.radioInput?.inputs[i]
                                ).alternate ? (
                                  <div
                                    // className="optn"
                                    style={{
                                      padding: "2px 4px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    {
                                      this.props.data2?.radioInput?.inputs[i]
                                        .alternate
                                    }{" "}
                                    &nbsp;&nbsp;
                                    {ans?.quantity !== "0" && ans?.quantity}
                                    {ans?.quantity2 !== "0" && ans.quantity2}
                                  </div>
                                ) : (
                                    Object.keys(this.state.data).length !== 0 &&
                                    this.props.data2?.radioInput?.inputs[i]
                                  ).alternate === "" ? (
                                  <div
                                    // className="optn"
                                    style={{
                                      padding: "2px 4px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    {/* {console.log("va;ue",option.value)}  */}
                                    {option.value}&nbsp;&nbsp;
                                    {ans?.quantity !== "0" && ans?.quantity}
                                    {ans?.quantity2 !== "0" && ans.quantity2}
                                  </div>
                                ) : (
                                  <div
                                    // className="optn"
                                    style={{
                                      padding: "2px 4px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    {/* {console.log("va;ue",option.value)}  */}
                                    {option.value}&nbsp;&nbsp;
                                    {ans?.quantity !== "0" && ans?.quantity}
                                    {ans?.quantity2 !== "0" && ans.quantity2}
                                  </div>
                                )}
                              </>
                            );
                          }
                        })
                      ) : (
                        <></>
                      )}
                    </>
                    {this.handleDisplayAnswers(option.id)}
                  </div>
                );
              })}
          </div>

          {/* {this.state.data.radioInput?.link?.display &&
          this.state.data?.answer?.id ===
            this.state.data.radioInput.link.linkedOptionId &&
          this.renderOption(this.state.data.radioInput.link)} */}
        </div>:
        <></>}
      </>
    );
  }
}

export default RadioMultipleOption;
const styles = {
  circle: {
    marginTop: "7px",
    width: "16px",
    height: "16px",
    display: "inline-block",
    textAlign: "center",
    background: "transparent",
    // borderRadius: "12px",
    border: "1px solid #297825",
    outline: "none",
    cursor: "pointer",
  },
  filledCircle: {
    marginTop: "7px",
    width: "16px",
    height: "16px",
    display: "inline-block",
    textAlign: "center",
    background: "transparent",
    content: `url(${checkIcon})`,
    // backgroundColor:"#939598",
    border: "1px solid #939598",
    outline: "none",
    cursor: "pointer",
  },
};

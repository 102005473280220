import React, { Component } from "react";
import Text from "../../Text";
import ImageOption from "./ImageOption";
import ImageMultipleOption from "./ImageMultipleOption";
import { StateContext } from "../../../../context/state";
import ArrowIcon from "../../../../assets/arrow-small-right.svg";

export default class ImageButton extends Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.messagesRef=React.createRef(null);
    this.totalImages = props.option.imageInput.inputs.length;
// console.log("totalimages",this.totalImages)
    this.state = {
      overflowPosition: 0,
      disablePreviousButton:  props.option.imageInput?.alignment==="verticle" ? true :false,
      disableNextButton: props.option.imageInput?.alignment==="verticle" ? false : false,
    }
  }

  onUpdate = (answer) => {
    const { option, onUpdate } = this.props;
    option.answer = answer;

    onUpdate(option);
  };
  onUpdateWithoutAPICall = (answer) => {
    const { option, onUpdateWithoutAPICall } = this.props;
    option.answer = answer;

    onUpdateWithoutAPICall(option);
  };
  previousImage = () => {
    // console.log('here2', this.state.overflowPosition )
    if(this.totalImages!==2){
      if (!(this.state.overflowPosition <= -((this.totalImages - 2) * 315))) {
        this.setState({ overflowPosition: this.state.overflowPosition - 300 })
      }
    }
   else{
    if (!(this.state.overflowPosition <= -((this.totalImages) * 200))) {
      this.setState({ overflowPosition: this.state.overflowPosition - 185 })
    }
   }

    this.setNavButtonState();
  }
  previousImageVertical = () => {
    // console.log('here2', this.state.overflowPosition )
    if(this.totalImages!==2){
      if (!(this.state.overflowPosition <= -((this.totalImages - 2) * 270))) {
        this.setState({ overflowPosition: this.state.overflowPosition - 255 })
      }
    }
   else{
    if (!(this.state.overflowPosition <= -((this.totalImages) * 45))) {
      this.setState({ overflowPosition: this.state.overflowPosition - 30 })
    }
   }

    this.setNavButtonStateVertical();
  }

  nextImage = () => {
    // console.log('here', this.state.overflowPosition )
    if(this.totalImages!==2){
      if (!(this.state.overflowPosition >= 0)) {
        this.setState({ overflowPosition: this.state.overflowPosition + 300 })
      } 
    }
    else{
      if (!(this.state.overflowPosition >= 0)) {
        this.setState({ overflowPosition: this.state.overflowPosition + 30 })
      }
    }
   

    this.setNavButtonState();
  }
  nextImageVertical = () => {
    // console.log('here', this.state.overflowPosition )
    if(this.totalImages!==2){
      if (!(this.state.overflowPosition >= 0)) {
        this.setState({ overflowPosition: this.state.overflowPosition + 255 })
      }
    }
    else{
      if (!(this.state.overflowPosition >= 0)) {
        this.setState({ overflowPosition: this.state.overflowPosition + 185 })
      }
    }
     
  
    
   
    this.setNavButtonStateVertical();
  }

  setNavButtonState = () => {
    if (this.state.overflowPosition > 0) {
      this.setState({ disableNextButton: true })
    } else {
      this.setState({ disableNextButton: false })
    }
    if(this.totalImages!==2){
      if (this.state.overflowPosition <= -((this.totalImages - 2) * 315)) {
        this.setState({ disablePreviousButton: true })
      } 
      else {
        this.setState({ disablePreviousButton: false })
      }
    }
    else{
      if (this.state.overflowPosition <= -((this.totalImages) * 45)) {
        this.setState({ disablePreviousButton: true })
      }
      else {
        this.setState({ disablePreviousButton: false })
      }
    }
   
  }
  setNavButtonStateVertical = () => {
    if (this.state.overflowPosition > 0) {
      this.setState({ disableNextButton: true })
    } else {
      this.setState({ disableNextButton: false })
    }
    if(this.totalImages!==2){
      if (this.state.overflowPosition <= -((this.totalImages - 2) * 270)) {
        this.setState({ disablePreviousButton: true })
      } 
      else {
        this.setState({ disablePreviousButton: false })
      }
    }
    else{
      if (this.state.overflowPosition <= -((this.totalImages) * 200)) {
        this.setState({ disablePreviousButton: true })
      }
      else {
        this.setState({ disablePreviousButton: false })
      }
    }
   
  }
  componentDidMount() {
    if (this.messagesRef.current) {
        this.messagesRef.current.scrollIntoView({
            behavior: 'smooth',
           block: 'end',
           inline: 'nearest'
         });
    }
  }
 
  render() {
    const headingFontFamily = this.context.stylingData?.headingFontFamily
    const headingFontSize = this.context.stylingData?.headingFontSize;
    const textColor = this.context.stylingData?.textColor? this.context.stylingData?.textColor :"#939598";
    let {
      option: {
        imageInput: { question, multipleSelection, alignment, inputs },
        answer,
      },
    } = this.props;
    const params = { alignment, answer, onUpdate: this.onUpdate, onUpdateWithoutAPICall: this.onUpdateWithoutAPICall };

    inputs = inputs || [];

    const widthStyle = {
      transform: alignment === "horizontal"? 'translateX(' + this.state.overflowPosition + 'px)':'translateY(' + this.state.overflowPosition + 'px)' ,
      width:
        alignment === "horizontal"
          ? inputs.length * 315 + (inputs.length - 1) * 15
          : "100%",
    };
    
    return (
			<div
				className="image-option-component image-height"
				ref={this.messagesRef}>
				<Text
					value={question}
					style={{
						fontFamily: `${headingFontFamily}`,
						fontSize: `${headingFontSize}px`,
						color: `${textColor}`,
					}}
				/>

				<div className="upload-container" style={styles.container}>
					<div className="images-container">
						{inputs.length && alignment === 'horizontal' ? (
							<>
								<button
									className="nav-button nav-button-left"
									onClick={this.nextImage}
									disabled={this.state.disableNextButton}>
									<img src={ArrowIcon} alt={'Arrow'} />
								</button>
								<button
									className="nav-button nav-button-right"
									onClick={this.previousImage}
									disabled={this.state.disablePreviousButton}>
									<img src={ArrowIcon} alt={'Arrow'} />
								</button>
							</>
						) : inputs.length && alignment === 'vertical' ? (
							<>
								<button
									className="nav-button nav-button-top mbl-btn"
									onClick={this.nextImageVertical}
									disabled={this.state.disableNextButton}>
									<img src={ArrowIcon} alt={'Arrow'} />
								</button>
								<button
									className="nav-button nav-button-bottom"
									onClick={this.previousImageVertical}
									disabled={this.state.disablePreviousButton}>
									<img src={ArrowIcon} alt={'Arrow'} />
								</button>
							</>
						) : (
							<></>
						)}

						<div className="images-overflow" style={widthStyle}>
							{inputs.map((input, index) =>
								multipleSelection === 'true' ? (
									<ImageMultipleOption
										key={index}
										{...input}
										{...params}
										guestCount={this.props.guestCount}
										min={this.props.option.imageInput.min}
										max={this.props.option.imageInput.max}
									/>
								) : (
									<ImageOption
										key={index}
										{...input}
										{...params}
										guestCount={this.props.guestCount}
									/>
								)
							)}
						</div>
					</div>
				</div>
			</div>
		);
  }
}

const styles = {
  container: {
    border: 0,
  },
 
};


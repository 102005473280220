import React, { useState, useEffect, useContext } from "react"
import moment from "moment"
import styled from "styled-components"
import { Image } from 'react-bootstrap'

import { StateContext } from "../../context/state"
import HttpService from "../../services/HttpService";

const WeatherDiv = styled.div`
    background: #deedf1;
    border-radius: 5px;
    margin-top: 3px;
    margin-right: 3px;
    margin-bottom: 3px;
    border: 2px solid #ddd;
`
const WeatherP = styled.p`
    line-height: 11px;
    color: #949598;
`

const WeatherIcon = styled(Image)`
    width: 30px;
    height: 30px;
`

const styles = {
    rightweatherbox: {
        padding: '15px',
        textAlign: 'center',
        borderRight: '1px solid #ccc',
    },
    noRightBorder: {
        borderRight: '0px',
    }
}

const WeatherWidget = (props) => {
    let contextValue = useContext(StateContext);
    const { languageFormat } = contextValue;

    const [weatherInfo, setWeatherInfo] = useState([]);

    useEffect(() => {
        HttpService.getWeather('51.1657', '10.4515').then(
            (res) => {
                if(res.daily && res.daily.length > 0) {
                    setWeatherInfo(res)
                } else {
                    HttpService.getWeatherLive('51.1657', '10.4515').then(
                        (response) => {
                            setWeatherInfo(response)
                            HttpService.updateWeather(response)
                        }
                    )
                }
            }
        );
    }, [])

    return (
        <React.Fragment>
            <WeatherDiv className="d-flex">
                {weatherInfo.daily && weatherInfo.daily.length > 0 &&
                    weatherInfo.daily.map((value, i, { length }) =>
                        <div style={(i + 1 === length) ? { ...styles.rightweatherbox, ...styles.noRightBorder } : styles.rightweatherbox} className="weather-box" key={i}>
                            <WeatherP className="m-0"><small>{moment.unix(value.dt).locale(languageFormat).format("ddd")}</small></WeatherP>
                            <WeatherIcon src={`https://openweathermap.org/img/wn/${value.weather[0].icon}@2x.png`}></WeatherIcon>
                            <WeatherP className="m-0"><small>{`${(value.temp.min).toFixed()}`}<span>&#176;</span>{`-${(value.temp.max).toFixed()}`}<span>&#176;</span></small></WeatherP>
                        </div>
                    )
                }
            </WeatherDiv>
        </React.Fragment >
    )
}

export default WeatherWidget
import React, { Component } from "react";
import calendar from "../../assets/calendar.svg";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { StateContext } from "../../context/state";
import axios from "axios";
import { baseUrl } from "../../Constants.js";
import "moment-timezone";
import Moment from "react-moment";
import { withTranslation} from 'react-i18next';
import Loader from "./Loader";
class DatumOption extends Component {
  static contextType = StateContext;
  showDate = true;
  showTime = true;
  currentDate = new Date().toLocaleDateString();

  constructor(props) {
    super(props);
    // const {option} = this.props;

    // this.showDate = option.datumInput?.hasDatum ?? true;
    // this.showTime = option.datumInput?.hasTime ?? true;
    this.state = {
      loading:false,
      option: [],
      newData: {},
      childData:{}
    };
  }
componentDidMount() {
  this.setState({loading:true})
    const option = this.props.option;
    
    axios
      .get(
        `${baseUrl}api/export/ServiceElement/${this.context.childQuestionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`
      )
      .then((res) => {
        // console.log("res",res)
        const d = res.data;
        this.setState({
          childData: d,
          loading:false
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onDateSelect = (date) => {
    // const {option, onUpdate} = this.props;
    // const time = option.answer?.time;
    // date = date?.toDateString();
    // option.answer = {time, date};
    // onUpdate(option);
  };

  onTimeSelect = (time) => {
    // const {option, onUpdate} = this.props;
    // const date = option.answer?.date;
    // time = time?.toTimeString();
    // option.answer = {time, date};
    // onUpdate(option);
  };
  render() {
    const {
      childData: { answer },
    } = this.state;

    const date = answer?.date ? new Date(answer?.date) : undefined;
    const time = answer?.time
      ? new Date(`${this.currentDate} ${answer?.time}`)
      : undefined;
   
    return (
      <React.Fragment>
          {this.state.loading?
        <Loader/>:<>
        {this.state.childData.isDisplay!=="false" ?
        <>
        {/* {console.log("childData",this.state.childData)} */}
        {this.showDate && (
          <div style={styles.container}>
            <div style={{ display: "flex" }}>
              <div style={{...styles.value, borderBottom: "1px solid rgb(112, 112, 112)", width:"140px"}}>
                <Moment format="DD-MM-YYYY">
                  {date}
                </Moment>
              </div>
              <div
                style={{
                  background: `url("${calendar}") no-repeat right 0 top 3px`,
                  height: "20px",
                  width: "20px",
                }}
              />
            </div>
            <span style={styles.label}>{this.props.t("Datum")}</span>
          </div>
        )}

        {this.showTime && (
          <div style={styles.container}>
            <div style={{ ...styles.value, ...styles.time }}>
              <Moment format="hh:mm" className="date-time-input">
                {time}
              </Moment>
            </div>
            <span style={styles.label}>{this.props.t("Uhrzeit")}</span>
          </div>
        )}
        </>:<></>
  }
        </>}
      </React.Fragment>
    );
  }
}

export default withTranslation() (DatumOption);

const styles = {
  container: {
    textAlign: "left",
    marginBottom:"10px" ,
    marginLeft: "15px",
  },
  value: {
    // color: "#e6e7e8",
    width: "150px",
    borderBottom: "1px solid #707070",
    fontSize: "15px",
    // background: `url("${calendar}") no-repeat right 0 top 3px`,
  },
  input: {
    border: 0,
    padding: 0,
    margin: 0,
    background: "transparent",
  },
  time: {
    background: "none",
  },
  label: {
    fontFamily: "Crete Round",
    color: "#939598",
    fontStyle: "italic",
    fontSize: "15px",
  },
};

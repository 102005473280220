import React, {Component} from 'react';
import {StateContext} from "../../context/state";
import crossIcon from "../../assets/cross.svg";
import Slider from "../partials/Slider";
import swal from "sweetalert";
import Tag from "./Tag";
import { withTranslation} from 'react-i18next';

 class TagHeader extends Component {
    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {
            currAggregator: {},
            aggregateId: undefined,
            selectedTagsAggr:[],
            showAggregator: false,
            showAddMore: false,
            count: 0,
            currentOperationType: {}
        };
        this.resetSidebar = this.resetSidebar.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.collapsed !== this.props.collapsed) {
            this.resetSidebar();
        }
    }

    resetSidebar = () => {
        this.setState({selectedTagsAggr: [], showAddMore: false, aggregateId: undefined, currAggregator: {}, showAggregator: false, currentOperationType: {}});
    }

    render() {
        const {collapsed, onChange, options, aggregates} = this.props;
        const {aggregateId, showAggregator, showAddMore, currAggregator, selectedTagsAggr, currentOperationType} = this.state;
        return <Slider collapsed={collapsed} onChange={(collapsed) => onChange(collapsed)}>
            <div style={styles.wrapper}>
                <div style={styles.content}>
                    <Tag isDisabled={true}
                        name={currAggregator.value ?? ''}
                        options={options}
                        onChange={(name)=> {
                            this.setState({currAggregator: {value: name}});
                        }}
                        onSelect={(value) => {
                            if(value) {
                                this.setState({currAggregator: value, showAggregator: true});
                                if(selectedTagsAggr.length > 0) {
                                    this.handleTagAggr(currentOperationType, value);
                                }
                            }
                        }}
                    />
                    {showAggregator && selectedTagsAggr.length === 0 &&
                        <div style={styles.aggregateWrapper}>
                            {aggregates?.map((aggregate, index) => {
                                return <div key={index} style={{
                                    ...styles.aggregate,
                                    backgroundColor: aggregateId === aggregate._id ? "#93959944" : "#e7e7e8"
                                }}
                                onClick={() => this.handleTagAggr(aggregate, currAggregator)}>{this.props.t(aggregate.name)}</div>
                            })}
                        </div>
                    }
                    {showAddMore &&
                        <>
                        <button style={styles.buttonAddMore} onClick={this.onTagAdd}>{this.props.t('add_more')}</button>
                        {/* <img src={plusIcon} style={{margin: '24px 0', cursor: 'pointer'}} onClick={this.onTagAdd} alt='' /> */}
                        </>
                    }
                    {selectedTagsAggr.length > 0 && selectedTagsAggr.map((object, _index) =>
                        <div style={styles.value} key={_index} className='buttons dummy_button'>
                            {`${this.props.t(object.name)} - ${object.aggregateName}`}
                            <img src={crossIcon} style={styles.closeBtn} alt=""
                                onClick={() => {
                                    this.setState({selectedTagsAggr: selectedTagsAggr.filter((obj, i) => (obj.id !== object.id))})
                                }}
                            />
                        </div>
                    )}
                </div>
                <button style={styles.button} onClick={this.onDone}>{this.props.t('Done')}</button>
            </div>
        </Slider>;
    }

    onTagAdd = () => {
        this.setState({currAggregator: {}, aggregateId: undefined});    
    };

    handleTagAggr = (aggregate, currAggreg) => {
        const {selectedTagsAggr, count} = this.state;
        if((Object.keys(currAggreg).length === 0) || (currAggreg && currAggreg.value === '')) {
            return false;
        }
        let isValidAgg = true;
        selectedTagsAggr.length && selectedTagsAggr.forEach((agg) => {
            if(agg.name === currAggreg.name) {
                isValidAgg = false;
            }
        });
        if(isValidAgg) {
            selectedTagsAggr.push({id: currAggreg.name + '_' + aggregate._id + '_' + count, name:currAggreg.name, value: currAggreg.value, _id:aggregate._id, aggregateName:aggregate.name})
            this.setState({selectedTagsAggr, showAddMore: false, aggregateId: aggregate._id, currAggregator: {}, count: count+1, currentOperationType: aggregate})
        } else {
            this.setState({currAggregator: {}})
        }
    };

    onDone = () => {
        const {headers, onSelect} = this.props;
        const {aggregateId, selectedTagsAggr} = this.state;

        const invalid = selectedTagsAggr.length === 0;
        if (invalid)
            swal("Bitte wählen Sie ein gültiges Tag / Aggregat aus");
        else {
            const _ids = headers.map(({_id}) => _id);
            const id = selectedTagsAggr.map(tag => `${tag.value},${tag._id ?? aggregateId}`).join(':');
            if (_ids.includes(id)) {
                swal("Tag-Kombination existiert bereits. Bitte Kombination auswählen");
                if(this.props.isClickedPlus === false) {
                    this.props.showPlus(true);
                }
            }
            else {
                this.resetSidebar();
                this.setState({
                    aggregateId: undefined
                });
                onSelect(id)
            }
        }
    };
}

export default withTranslation() (TagHeader);
const styles = {
    wrapper: {
        background: "#d0d3d4",
        padding: "40px",
        height: "auto",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexGrow: 1,
    },
    aggregateWrapper: {
        display: "grid",
        gridTemplateColumns: "150px 150px",
        gridGap: "10px",
        marginTop: "20px",
        padding: "16px",
        border: "2px dashed #bcbec0",
        cursor: "pointer"
    },
    aggregate: {
        background: "#e7e7e8",
        padding: "10px",
        color: "#939599",
        fontSize: "12px"
    },
    button: {
        border: "none",
        boxShadow: "none",
        padding: "7px",
        margin: "5px",
        color: "#e7e7e8",
        backgroundColor: "#939599",
        minWidth: "90%"
    },
    buttonAddMore: {
        border: "none",
        boxShadow: "none",
        padding: "7px",
        margin: "10px",
        color: "#e7e7e8",
        backgroundColor: "#939599",
        minWidth: "70%"
    },
    value: {
        margin: "10px 10px 0 5px",
        padding: "0 10px",
        background: "#e7e7e8",
        display: "flex",
        alignItems: "center",
        minHeight: "40px",
    },
    closeBtn: {
        margin: "5px -5px 28px 15px",
        cursor: "pointer",
        width: "10px",
        height: "10px",
    },
};


import React, { Component } from "react";

import templateIcon1 from "../../assets/template-1.svg";
import templateIcon2 from "../../assets/template-2.svg";
import templateIcon3 from "../../assets/template-3.svg";
import templateIcon4 from "../../assets/template-4.svg";
import templateIcon5 from "../../assets/template-5.svg";
import templateIcon6 from "../../assets/template-6.svg";
import templateIcon7 from "../../assets/template-7.svg";
import templateIcon8 from "../../assets/template-8.svg";
import templateIcon9 from "../../assets/template-9.svg";
import templateIcon10 from "../../assets/template-10.svg";
import templateIcon11 from "../../assets/template-11.svg";
import templateIcon12 from "../../assets/template-12.svg";
import { withTranslation} from 'react-i18next';
import styled from "styled-components"
const HeadingDiv = styled.div`
	background: #d1d3d400;
	position: sticky;
	top: 2px;
	z-index: 100;
	height: 61px;
	margin-bottom: 25px;
    margin-top:-32px;
`;
const HeadingStyle=styled.h3` 
position:relative;
top:-45px;
	font-size: 27px;
	margin-bottom: 30px;
	/* color: #d1d3d4; */
`;
class ConfiguratorTemplateSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount(){
    window.addEventListener("scroll", () => {
      this.setState({scroll:window.scrollY > 0});
    });
 }
  render() {
    return (
     
      <div className="outline">
         <HeadingDiv  className={this.scroll ? "scroll-div" : "no-scroll"}>
           <HeadingStyle>
        {/* <p className="select-text"> */}
        {this.props.t('Bitte wählen Sie Ihr gewünschtes Layout aus')}
        {/* </p> */}
        </HeadingStyle>
        </HeadingDiv>
        <button
          className="template-selection"
          onClick={() => this.props.setSelectedTemplate(1)}
        >
          <img src={templateIcon1} alt="" />
        </button>
        <button
          className="template-selection"
          onClick={() => this.props.setSelectedTemplate(2)}
        >
          <img src={templateIcon2} alt="" />
        </button>
        <button
          className="template-selection"
          onClick={() => this.props.setSelectedTemplate(3)}
        >
          <img src={templateIcon3} alt="" />
        </button>
        <button
          className="template-selection"
          onClick={() => this.props.setSelectedTemplate(4)}
        >
          <img src={templateIcon4} alt="" />
        </button>
        <button
          className="template-selection"
          onClick={() => this.props.setSelectedTemplate(5)}
        >
          <img src={templateIcon5} alt="" />
        </button>
        <button
          className="template-selection"
          onClick={() => this.props.setSelectedTemplate(6)}
        >
          <img src={templateIcon6} alt="" />
        </button>

        <button
          className="template-selection"
          onClick={() => this.props.setSelectedTemplate(7)}
        >
          <img src={templateIcon7} alt="" />
        </button>
        <button
          className="template-selection"
          onClick={() => this.props.setSelectedTemplate(8)}
        >
          <img src={templateIcon8} alt="" />
        </button>
        <button
          className="template-selection"
          onClick={() => this.props.setSelectedTemplate(9)}
        >
          <img src={templateIcon9} alt="" />
        </button>
        <button
          className="template-selection"
          onClick={() => this.props.setSelectedTemplate(10)}
        >
          <img src={templateIcon10} alt="" />
        </button>
        <button
          className="template-selection"
          onClick={() => this.props.setSelectedTemplate(11)}
        >
          <img src={templateIcon11} alt="" />
        </button>
        <button
          className="template-selection"
          onClick={() => this.props.setSelectedTemplate(12)}
        >
          <img src={templateIcon12} alt="" />
        </button>
      </div>
    );
  }
}

export default withTranslation() (ConfiguratorTemplateSelection);

import React, {Component} from "react";
import TextOption from "./TextOption";

export default class TextComponent extends Component {

    onUpdate = (answer, index) => {

        const {option, onUpdate} = this.props;
        option.textInput.inputs[index].answer = answer;

        onUpdate(option);
    };
    onUpdateWithoutAPICall = (answer, index) => {

        const {option, onUpdateWithoutAPICall} = this.props;
        option.textInput.inputs[index].answer = answer;

        onUpdateWithoutAPICall(option);
    };
    render() {
        let {option: {textInput: { inputs, boxColor}}} = this.props;

        inputs = inputs || [];
        return (
            <div style={{background:boxColor?boxColor:"none"}}>
                {inputs.map((input, index) => (
                    <TextOption key={index} {...input} 
                    selectedGuestCount={input.selectedGuestCount}
                        onUpdate={(answer) => this.onUpdate(answer, index)}
                        onUpdateWithoutAPICall={(answer) => this.onUpdateWithoutAPICall(answer, index)}
                        guestCount={this.props.guestCount} 
                    />
                ))}
            </div>
        );
    }
}
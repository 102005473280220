import React from "react";
import KeyFigures from "../partials/KeyFigures";
import Graph1 from "./graph1";
import Graph2 from "./graph2";
import BillingTable from "./billingTable";
import "../../venueSettingStyles.css";
import {StateContext} from "../../context/state";
import StatisticsTable from "./StatisticTable";
import { withTranslation} from 'react-i18next';
 class StatisticsMain extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();
    this.state = {
      keyFigures: [],
      selectedFig: {},
      dateRange: "",
    };
  }

  // componentDidMount  ()  {
  //   const data = this.context;
  //   axios
  //   .get(`${baseUrl}api/venue/${data.venueId}`)
  //   .then((res) => {
  //     this.context.updateState({
  //       languageFormat: res.data?.languageFormat
  //     });  
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });
  // }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleLogout = () => {
    const urlName = this.context.urlName;
    this.context.updateState({
      authenticated: false,
      from:"logout"

    });
    localStorage.clear();
    sessionStorage.clear();
    if (urlName === "adminScreen") {
      this.props.history.push(`/login`);
    } else {
      this.props.history.push(`/login/${urlName}`);
    }
  };
  render() {

    return (
      <div>
        {/* <OverlayTrigger
          key="bottom"
          placement="bottom"
          overlay={
            <Tooltip id={`tooltip-disabled`}>
              <span>{this.props.t('logout')}</span>
            </Tooltip>
          }
        >
          <button
            className="logout-btn"
            style={{
              border: "none",
              marginLeft: "15px",
            }}
            onClick={this.handleLogout}
          >
            <img src={logoutIcon} alt="" />
          </button>
        </OverlayTrigger> */}
        <div className={"row ml-0 mr-0"}>
          <div className={"col-10 offset-1"}>
            <KeyFigures />
            <div style={{color: "#524f4f", fontSize: "24px", marginTop: "20px"}}>{this.props.t('Controlling')}</div>
            <div style={styles.divider} />
           
           {this.context.userRole === "SA" &&  <BillingTable/>}
            <StatisticsTable/>
            <Graph1 />
            <Graph2 />       
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation() (StatisticsMain);

const styles={
  divider: {
    borderTop: "1px solid #d0d3d4",
    margin: "30px 0 0px 0px",
    paddingTop: "10px",
  },
};

import {useLocation} from "react-router-dom";
import React from "react";

const withRoute = (Component) => {

    return (props) => {
        const route = useLocation();
        return <Component activeRoute={route} {...props} />
    };
};

export default withRoute
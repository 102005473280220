import React, { Component } from "react";
import closeIcon from "../../assets/cross.svg";
import arrowDown from "../../assets/arrow-small-down.svg";
import deleteIcon from "../../assets/delete.svg";
import crossLight from "../../assets/cross-light.svg";
import plusIcon from "../../assets/plus.svg";
import plusLight from "../../assets/plus-light.svg";
import axios from "axios";
import swal from "sweetalert";
import { baseUrl } from "../../Constants.js";
import "../../venueSettingStyles.css";
import { StateContext } from "../../context/state";
import { withRouter } from "react-router-dom";
import TextOption from "../exportComponents/text";
import DatumOption from "../exportComponents/datum";
import RadioOption from "../exportComponents/radio";
import ImageOption from "../exportComponents/image";
import BildOption from "../exportComponents/bild";
import TableOption from "../exportComponents/table";
import UploadOption from "../exportComponents/upload";
import DownloadOption from "../exportComponents/download";
import RadioMultipleOption from "../exportComponents/radioMultipleOption";
import HttpService from "../../services/HttpService";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import linkIcon from "../../assets/link.svg";
import ThemeLinking from "../exportComponents/themeLink";
import ImageMultipleOption from "../exportComponents/imageMultipleOption";
import { v4 as uuidv4 } from "uuid";
import optionIcon from "../../assets/option.svg";
import optionFilledIcon from "../../assets/option-filled.svg";
import { withTranslation} from 'react-i18next';
class ExportSectionPopup extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      newEntry: false,
      exportPlanId: "",
      linkData: {},
      secondLinkData: {},
      themeIdCount: 1,
      themeIds: [1],
      link: false,
      guestPriceObject: [
        { name: "adults", price: "", id: uuidv4() },
        { name: "U18", price: "", id: uuidv4() },
        { name: "U12", price: "", id: uuidv4() },
        { name: "U6", price: "", id: uuidv4() },
      ],
      guestPriceObject2: [
        { name: "adults", price: "", id: uuidv4() },
        { name: "U18", price: "", id: uuidv4() },
        { name: "U12", price: "", id: uuidv4() },
        { name: "U6", price: "", id: uuidv4() },
      ],
      displayTextField: false,
      clickedTheme: 1,
      abc: [],
      themes: this.props.data
        ? this.props.data
        : [
            {
              themeId: 1,
              priceObject: [],
              // linkQtn:'',
              display: "",
              divs: [
                {
                  divId: 1,
                  // text:'',
                  editorStateText: EditorState.createEmpty(),
                  hideTextToolbar: false,
                  editorStateNote: EditorState.createEmpty(),
                  hideNoteToolbar: false,
                  note: "",
                  // selectedOption:'',
                  textClicked: false,
                  noteClicked: false,
                  infoClicked: false,
                  data: {},
                  selected: false,
                  // question:{}
                },
              ],
            },
          ],
      pricing: [
        {
          id: 1,
          value: "adults",
          price: "",
          priceType: "", //flat-rate, number-guests, numerically
          priceTypeId: null,
          tags: [],
          numerically: {
            minimumNumber: "",
            minimumNumberCalculate: false,
            maximumNumber: "",
            maximumNumberCalculate: false,
            stage: "",
            relay1: "",
            relay1Alle: false,
            relay1Price: "",
            relay2: "",
            relay2Alle: false,
            relay2Price: "",
            relay3: "",
            relay3Alle: false,
            relay3Price: "",
            unit: "",
          },
        },
        {
          id: 2,
          value: "U12",
          price: "",
          priceType: "", //flat-rate, number-guests, numerically
          priceTypeId: null,
          tags: [],
          numerically: {
            minimumNumber: "",
            minimumNumberCalculate: false,
            maximumNumber: "",
            maximumNumberCalculate: false,
            stage: "",
            relay1: "",
            relay1Alle: false,
            relay1Price: "",
            relay2: "",
            relay2Alle: false,
            relay2Price: "",
            relay3: "",
            relay3Alle: false,
            relay3Price: "",
            unit: "",
          },
        },
        {
          id: 3,
          value: "U18",
          price: "",
          priceType: "", //flat-rate, number-guests, numerically
          priceTypeId: null,
          tags: [],
          numerically: {
            minimumNumber: "",
            minimumNumberCalculate: false,
            maximumNumber: "",
            maximumNumberCalculate: false,
            stage: "",
            relay1: "",
            relay1Alle: false,
            relay1Price: "",
            relay2: "",
            relay2Alle: false,
            relay2Price: "",
            relay3: "",
            relay3Alle: false,
            relay3Price: "",
            unit: "",
          },
        },
        {
          id: 4,
          value: "U6",
          price: "",
          priceType: "", //flat-rate, number-guests, numerically
          priceTypeId: null,
          tags: [],
          numerically: {
            minimumNumber: "",
            minimumNumberCalculate: false,
            maximumNumber: "",
            maximumNumberCalculate: false,
            stage: "",
            relay1: "",
            relay1Alle: false,
            relay1Price: "",
            relay2: "",
            relay2Alle: false,
            relay2Price: "",
            relay3: "",
            relay3Alle: false,
            relay3Price: "",
            unit: "",
          },
        },
      ],
      divIds: [1],
      selectedTextIndex: null,
      textRadio: false,
      options: [],
      clickedDiv: 1,
      //linkSub:"",
      selectedDropdown: this.props.t('Info von Antwort'),
      selectedLinkData: {},
      selectedThemeIndex: "",
      bool: true,
      guestOptions: [
        { name: "Eventname", id: uuidv4() },
        { name: "Eventdatum", id: uuidv4() },
        { name: "Kontaktperson", id: uuidv4() },
        {
          name: "Anzahl Gäste",
          id: uuidv4(),
          value: [
            { label: "adults", name: "Anzahl Erwachsene", id: uuidv4() },
            { label: "U6", name: "Anzahl Kleinkinder", id: uuidv4() },
            { label: "U12", name: "Anzahl Kinder", id: uuidv4() },
            { label: "U18", name: "Anzahl Jugendliche", id: uuidv4() },
          ],
        },
        { name: "Eventmanager", id: uuidv4() },
      ],
      display1: false,
      display2: false,
      display3: false,
      previewHeader: {},
      activeTheme: { id: -1, name: "Anzahl Gäste", services: [] },
      guestLabel: {
        adults: "Anzahl Erwachsene",
        U6: "Anzahl Kleinkinder",
        U12: " Anzahl Kinder",
        U18: "Anzahl Jugendliche",
      },
      content: {},
      guestInfo: {
        adults: true,
        U6: true,
        U12: true,
        U18: true,
      },
      showOptions: null,
      selectedPricing: {},
      selectedOption: "",
      selectedOptionIndex: null,
      priceObject: [],
      image: "",
      array: [],
      singleDivClicked: false,
    };
  }
  componentDidMount = async () => {
    const obj=JSON.parse(sessionStorage.getItem("state1"))
    // this._isMounted = true;'
    let guestInfo;
    var guestInfoFromParentQuestionnaire = await HttpService.getGuests(
      this.props.questionnaireId
    );

    let guestInfoFromchildQuestionnaireId = await HttpService.getGuests(
      this.context.childQuestionnaireId
    );

    if (
      !guestInfoFromchildQuestionnaireId.isSavedFrom &&
      this.props.location.pathname === "/planPreview"
    ) {
      if (!guestInfoFromchildQuestionnaireId.guestOptions) {
        guestInfo = guestInfoFromParentQuestionnaire;
      } else {
        guestInfo = guestInfoFromchildQuestionnaireId;
      }
    } else {
      guestInfo = guestInfoFromParentQuestionnaire;
    }
    // console.log("guestinfo",guestInfo)
    var dummyfields = Object.assign({}, this.state.guestInfo);
    let dummyPricing = Object.assign([], this.state.pricing);
    let dummyLabels = Object.assign({}, this.state.guestLabel);

    let guestPriceObject = [];
    //     Object.values(guestInfo.guestLabel).map((values)=>{
    // // console.log("values",values)
    // guestPriceObject=[{name:values,price:""}]
    //     })
    if (guestInfo.guestLabel) {
      for (let values of Object.values(guestInfo.guestLabel)) {
        guestPriceObject.push({ name: values, price: "", id: uuidv4() });
        // console.log("guestPriceObject",guestPriceObject)
      }
    }

    if (guestInfo.guestLabel !== undefined) {
      Object.keys(guestInfo.guestLabel).forEach((guest) => {
        if (!this.state.guestOptions[3].value) { return; } else {
          this.state.guestOptions[3].value.forEach((label) => {
            if (label.label === guest) {
              (label.name = guestInfo.guestLabel[guest]);
            }
          })
        };
      });
    }

    if (guestInfo?.guestOptions) {
      guestInfo.guestOptions.length &&
        guestInfo.guestOptions.forEach((guest) => {
          if (guest.name === "Event Name") {
             (guest.name = "Eventname");
          }
          if (guest.name === "Date") {
             (guest.name = "Eventdatum");
          }
          if (guest.name === "Venue") {
             (guest.name = "Kontaktperson");
          }
          if (guest.name === "Number Of Guest") {
             (guest.name = "Anzahl Gäste");
          }
          if (guest.name === "VVO") {
             (guest.name = "Eventmanager");
          }
        });
    }

    if (Object.keys(guestInfo).length) {
      this.setState({
        guests: guestInfo?.guests || {},
        guestInfo:
          guestInfo?.fields && Object.keys(guestInfo?.fields).includes("adults")
            ? guestInfo?.fields
            : dummyfields,
        // guestOptions:(guestInfo?.guestOptions!=undefined && guestInfo?.guestOptions.length!=0)
        //   ? guestInfo?.guestOptions
        //   : this.state.guestOptions,
        image: guestInfo?.image || "",
        // cuDetails: this.context.cuDetails,
        pricing: guestInfo.pricing ? guestInfo.pricing : dummyPricing,
        guestLabel: guestInfo.guestLabel ? guestInfo.guestLabel : dummyLabels,
        guestPriceObject: guestPriceObject,
        content: guestInfo.content,
      });
    }
   
    
    //  console.log('planinfo',planInfo )
    let res = await HttpService.getAllExportElements(
      this.props.questionnaireId
    );
    let planInfo = await HttpService.getPlanDetails(this.props.planId);
    if (planInfo.exportPlanId) {
      let themeInfo;

      if (this.props.location.pathname === "/planPreview") {
        themeInfo = await HttpService.getExportPlanWithChildQuestionnaire(
          this.props.questionnaireId,
          this.context.childQuestionnaireId,
          planInfo.exportPlanId,
          obj?.eventId
        );
      } else {
        themeInfo = await HttpService.getExportPlan(
          this.props.questionnaireId,
          planInfo.exportPlanId
        );
      }

      // console.log("themeinfo",themeInfo)
      if (themeInfo?.themes) {
        themeInfo.themes.forEach((theme) => {
          theme.divs.forEach((div) => {
            if (div?.editorStateText) {
              if (Object.keys(div.editorStateText).includes("blocks")) {
                div.editorStateText = EditorState.createWithContent(
                  convertFromRaw(div?.editorStateText)
                );
              }
              if (Object.keys(div.editorStateNote).includes("blocks")) {
                div.editorStateNote = EditorState.createWithContent(
                  convertFromRaw(div?.editorStateNote)
                );
              }
            }
          });
        });
        // console.log(themeInfo,"themeinfo")
        // console.log("guest",this.state.guestLabel)
        this.setState({
          options: res?.themes,
          priceObject: themeInfo.priceObject
            ? themeInfo.priceObject
            : guestPriceObject,
          themes: themeInfo.themes,
          newEntry: false,
          exportPlanId: planInfo.exportPlanId,
          guestOptions:
            themeInfo?.guestOptions !== undefined &&
            themeInfo?.guestOptions.length !== 0
              ? themeInfo?.guestOptions
              : this.state.guestOptions,
          // clickedDiv:"",
          // clickedTheme:""
        });
      } else {
        this.setState({
          options: res?.themes,
          exportPlanId: planInfo?.exportPlanId,
        });
      }
    } else {
      this.setState({
        options: res?.themes,
        newEntry: true,
      });
    }

  };
  onGuestChange = (e) => {
    const guestInfo = Object.assign({}, this.state.guestInfo);
    for (let i in guestInfo) {
      if (i === e.target.name) {
        guestInfo[i] = !guestInfo[i];
      }
    }
    this.setState({
      guestInfo,
    });
  };
  handleClose = () => {
    return this.props.closeExportPopup;
  };
  handleClosePrice = () => {
    this.setState({
      showOptions: false,
      selectedOptionIndex: null,
      selectedOption: "",
    });
  };
  handleSave = async () => {
    // console.log("in handleSave")
    const {
      pricing,
      guestLabel,
    } = this.state;
    const obj=JSON.parse(sessionStorage.getItem("state1"))
    // console.log("obj",obj)
    const copy = Object.assign([], this.state.themes);
    // console.log("themes",this.state.themes)
    copy.forEach((theme) => {
      theme.divs.forEach((div) => {
        if (!Object.keys(div.editorStateText).includes("blocks")) {
          div.editorStateText = convertToRaw(
            div.editorStateText.getCurrentContent()
          );
        }
        if (!Object.keys(div.editorStateNote).includes("blocks")) {
          div.editorStateNote = convertToRaw(
            div.editorStateNote.getCurrentContent()
          );
        }
      });
    });
    // console.log("copy in save",copy)
    Object.keys(this.state.guestLabel).forEach((guest) => {
      if (!this.state.guestOptions[3].value)
        this.state.guestOptions[3].value.forEach((label) => {
          if (label.label === guest) {
             (label.name = this.state.guestLabel[guest]);
          }
        })
    });
    // console.log("quest",this.context.childQuestionnaireId)
    const data = {
      questionnaireId: this.props.questionnaireId,
      themes: copy,
      planId: this.props.planId,
      guestOptions: this.state.guestOptions,
      priceObject: this.state.priceObject,
    };
    // console.log("data",data)
    if (this.state.newEntry) {
      var res = await HttpService.saveExportPlan(data);

      // this.context.updateState({
      //   exportPlanId: res.id,
      //   questionnaireId: this.props.questionnaireId,
      //   planId: this.props.planId,
      //   // saved:this.props.location.pathname === "/planPreview"? "saved":""
      // });

      let planInfo = await HttpService.getPlanDetails(this.props.planId);
      const newPlanData = { ...planInfo, exportPlanId: res.id };
      await HttpService.updatePlan(newPlanData);

    } else {
      
      if(this.props.location.pathname === "/planPreview"){
        res = await HttpService.updateExportPlanWithEvent(this.state.exportPlanId, obj?.eventId,data);

        this.context.updateState({
          exportPlanId: this.state.exportPlanId,
          questionnaireId: this.props.questionnaireId,
          planId: this.props.planId,
          eventId:obj?.eventId
          // saved:this.props.location.pathname === "/planPreview" ? "saved":""
        });
      }
      else{
        res = await HttpService.updateExportPlan(this.state.exportPlanId, data);

        // this.context.updateState({
        //   exportPlanId: this.state.exportPlanId,
        //   questionnaireId: this.props.questionnaireId,
        //   planId: this.props.planId,
        //   // saved:this.props.location.pathname === "/planPreview" ? "saved":""
        // });
      }
      
    }
    // console.log(this.context.childQuestionnaireId,"this.context.childQuestionnaireId")

    if (this.props.location.pathname === "/planPreview") {
      // console.log("in child")
      var guestsData = {
        questionnaireId: this.context.childQuestionnaireId,
        fields: this.state.guestInfo,
        pricing,
        guestLabel,
        guests: this.state.guests,
        guestOptions: this.state.guestOptions,
        savedFrom: "planPreview",
        content: this.state.content,
        image: this.state.image,
      };

      await HttpService.putGuests(
        this.context.childQuestionnaireId,
        guestsData
      );
    } else {
      // console.log("in else ",this.props.questionnaireId)
      guestsData = {
        questionnaireId: this.props.questionnaireId,
        fields: this.state.guestInfo,
        pricing,
        guestLabel,
        guestOptions: this.state.guestOptions,
        content: this.state.content,
        image: this.state.image,
      };
      await HttpService.putGuests(this.props.questionnaireId, guestsData);
      // if (activeTheme.id === -1 && newEntry) {
      //   // console.log('post', guestsData)
      //   await HttpService.postGuests(guestsData);
      //   // return;
      // }
      // if (activeTheme.id === -1 && !newEntry) {
      //   // console.log('put', guestsData)

      //   await HttpService.putGuests(this.props.questionnaireId, guestsData);
      //   // return;
      // }
    }
    if (this.props.location.pathname === "/planPreview") {
      window.location.reload();
    }
    swal(`${this.props.t('Gespeichert!')}`, "", "success");
    // else {
    //   return this.props.history.push("/planPreview");
    // }
    // this.props.closeExportPopup("saveCalled");
  };

  // handleLinkOption=(option, type, sub)=>{
  //   const copy=Object.assign([], this.state.themes)
  //   for(let i of copy){
  //     if(i.themeId===this.state.clickedTheme){
  //       i.linkQtn=sub
  //     }
  //   }
  //   this.setState({
  //    theme:copy
  //   })
  // }
  //   HeaderObject=(e)=>{

  //    let previewHeader=this.state.previewHeader

  // // console.log("previewHeader",previewHeader)
  // // console.log("op",e.target.value)
  //   }
  selectedHeader = (e) => {
    let guestOptions = this.state.guestOptions;
    if (!guestOptions) { return; }
    guestOptions.forEach((op) => {
      if (op.name === e.target.value) {
        e.target.checked === true ? (op.checked = true) : (op.checked = false);
      }
    });

    this.setState({ guestOptions });
  };
  selectedPrice = (e) => {
    const themes = this.state.themes;

    themes.forEach((theme) => {
      if (theme.themeId === this.state.clickedTheme) {
        theme.priceObject.forEach((price) => {
          if (price.name === e.target.value) {
            e.target.checked === true
              ? (price.checked = true)
              : (price.checked = false);
          }
        });
      }
    });

    this.setState({ themes });
  };
  handlePriceChange = (e, p) => {
    const themes = this.state.themes;

    themes.forEach((theme) => {
      if (theme.themeId === this.state.clickedTheme) {
        theme.priceObject.forEach((price) => {
          // console.log("price",price)
          if (p.name === price.name) {
            price.price = e.target.value;
          }
        });
      }
    });
    this.setState({ themes });
  };

  onThemeClicked = (themeId) => {
    // console.log("on theme clicked",themeId)
    this.setState({ clickedTheme: themeId, display3: false });
  };
  handleGuestRemove = (p) => {
    // console.log("price",price)
    const themes = this.state.themes;

    themes.forEach((theme) => {
      if (theme.themeId === this.state.clickedTheme) {
        theme.priceObject.forEach((price) => {
          // console.log("price",price)
          if (p.name === price.name) {
            // price.price = e.target.value;
            price.checked = false;
            price.price = "";
          }
        });
      }
    });
    this.setState({ themes });
  };
  updatePriceOptionConfiguration = (options) => {
    this.setState({
      pricing: options,
    });
  };
  selectedGuest = (e, o, index) => {
    // console.log("e",e.target.value)
    let guestOptions = this.state.guestOptions;
    let pricing = this.state.pricing;
    if (!guestOptions[3] || !guestOptions[3].value) { return; }
			guestOptions[3].value.forEach((op) => {
				// console.log("op.name",op.name,"e.target.value",e.target.value)
				if (op.id === o.id) {
					e.target.checked === true
						? (op.checked = true)
						: (op.checked = false);
				}
			});

    pricing.forEach((price) => {
      if (price.value === o.label) {
        o.checked === true ? (price.checked = true) : (price.checked = false);
      }
    });
    // console.log("pricing",pricing)
    this.setState({ guestOptions, pricing });
    // console.log("obj",obj)
    // console.log(guestOptions,"guestOptions")
  };
  handleNumberOfGuest = () => {
    this.setState({ display2: !this.state.display2 });
  };
  guestDivClicked = () => {
    this.setState({ display1: !this.state.display1 });
  };
  handleLink = (themeId, index) => {
    const themes = Object.assign({}, this.state.themes);
    const dummy = [
      {
        columnIndex: "",
        linkedOptionId: "",
        linkedOptionValue: "",
        optionType: "",
        questionIndex: "",
        rowIndex: "",
        serviceId: "",
        themeId: "",
        display: "",
      },
    ];
    const selectedLinkData = themes[index]?.themeLink
      ? themes[index].themeLink
      : dummy;
    this.setState({
      link: !this.state.link,
      selectedLinkData,
      selectedThemeIndex: index,
      clickedTheme: themeId,
    });
  };
  onEditorStateTextChange = (themeId, divId, editorState) => {
    // console.log("editor",themeId, divId,editorState)
    const copy = Object.assign([], this.state.themes);
    for (let i of copy) {
      if (i.themeId === themeId) {
        for (let j of i.divs) {
          if (j.divId === divId) {
            j.editorStateText = editorState;
          }
        }
      }
    }
    this.setState({
      theme: copy,
    });
  };
  functionValidation = (input) => {
    const min = Number(input.numerically.minimumNumber);
    const max = Number(input.numerically.maximumNumber);
    const price = Number(input.price);

    if (min && max && min > max) {
      const msg = "Der Maximalwert sollte größer als der Minimalwert sein";
      return msg;
    }
    if (min && max && !price) {
      const msg = "Preis eingeben";
      return msg;
    } else if (min && !price) {
      const msg = "Enter Price";
      return msg;
    } else if (max && !price) {
      const msg = "Enter Price";
      return msg;
    } else {
      return null;
    }
  };

  handleOnFocus = (themeId, divId) => {
    const themes = this.state.themes;
    for (let i of themes) {
      if (i.themeId === themeId) {
        for (let j of i.divs) {
          if (j.divId === divId) {
            j["hideToolbar"] = false;
          }
        }
      }
    }

    this.setState({ themes });
  };
  handleLabelChange = (e, labelFor) => {
    const { guestLabel } = this.state;
    guestLabel[labelFor] = e.target.value;
    this.setState({
      guestLabel,
    });
  };
  handleOnBlur = (themeId, divId) => {
    const themes = this.state.themes;
    for (let i of themes) {
      if (i.themeId === themeId) {
        for (let j of i.divs) {
          if (j.divId === divId) {
            j["hideToolbar"] = true;
          }
        }
      }
    }

    this.setState({ themes });
  };

  setTextInputValue = (themeId, id, e) => {
    const copy = Object.assign([], this.state.themes);
    for (let i of copy) {
      if (i.themeId === themeId) {
        for (let j of i.divs) {
          if (j.divId === id) {
            j[e.target.name] = e.target.value;
          }
        }
      }
    }
    this.setState({ themes: copy });
  };
  showOptionComponent = (category, index) => {
    this.setState({
      showOptions: true,
      selectedOption: category,
      selectedOptionIndex: index,
    });
  };
  priceOptionsDisplay = () => {
    const themes = this.state.themes;

    let abc = [];
    themes.forEach((theme) => {
      if (theme.themeId === this.state.clickedTheme) {
        // console.log("themeobject", theme?.priceObject);
        if (!theme?.priceObject) {
          // console.log("in first if", theme?.priceObject);
          let guestObject2 = [];
          if (this.state.guestLabel) {
            for (let values of Object.values(this.state.guestLabel)) {
              // console.log("this.state.guestLabel[values]",values)
              guestObject2.push({ name: values, price: "", id: uuidv4() });
              // console.log("gguestPriceObject2uest",guestPriceObject2)
            }
          }
          // console.log("in main if",this.state.guestPriceObject)
          theme.priceObject = guestObject2;
          abc = this.state.guestObject2;
          return theme;
        } else if (theme.priceObject.length === 0) {
          // console.log("in if", theme.priceObject);
          let guestPriceObject2 = [];
          if (this.state.guestLabel) {
            for (let values of Object.values(this.state.guestLabel)) {
              // console.log("this.state.guestLabel[values]",values)
              guestPriceObject2.push({ name: values, price: "", id: uuidv4() });
              // console.log("gguestPriceObject2uest",guestPriceObject2)
              theme.priceObject = guestPriceObject2;
              abc = theme.priceObject;
            }
          }
        } else {
          let guestPriceObject3 = [];
          if (this.state.guestLabel) {
            for (let values of Object.values(this.state.guestLabel)) {
              guestPriceObject3.push({ name: values, price: "", id: uuidv4() });
            }
          }

          theme.priceObject[0].name = guestPriceObject3[0].name;
          theme.priceObject[1].name = guestPriceObject3[1].name;
          theme.priceObject[2].name = guestPriceObject3[2].name;
          theme.priceObject[3].name = guestPriceObject3[3].name;
          // console.log(theme.priceObject)
          abc = theme.priceObject;
          // console.log("guestPriceObject3",guestPriceObject3)
          // console.log("in else",abc)
        }
      }
    });

    this.setState({ display3: !this.state.display3, themes, abc });
  };
  handleChange = (e, id) => {
    const themes = this.state.themes;

    themes.forEach((theme) => {
      if (theme.themeId === this.state.clickedTheme) {
        theme.priceObject.forEach((price) => {
          if (price.id === id) {
            price.price = e.target.value;
            return price;
          }
        });
        // theme.priceObject=priceObject
        return theme;
      }
    });
    this.setState({ themes });
  };
  renderContent = () => {
    const { activeTheme } = this.state;
    if (activeTheme.id === -1) {
      const { guestInfo, guestLabel } = this.state;
      return (
        <React.Fragment>
          <form>
            <br />
            <div style={styles.flexRow}>
              <div style={styles.inputWrapper}>
                <div style={{ display: "flex" }}>
                </div>
                <div style={{ display: "flex", margin: "10px 0px" }}>
                  <input
                    type="text"
                    style={styles.inputLabel}
                    value={guestLabel.adults}
                    onChange={(e) => {
                      this.handleLabelChange(e, "adults");
                    }}
                    autoComplete="off"
                  />
                  {/* &nbsp;&nbsp; */}

                  <div
                    className="option"
                    onClick={() => this.showOptionComponent("adults", 0)}
                    style={{
                      border: this.functionValidation(this.state.pricing[0])
                        ? "2px solid red"
                        : "none",
                      borderRadius: "50%",
                      height: "34px",
                      width: "34px",
                      margin: "0px 5px",
                    }}
                  >
                    <img
                      src={
                        this.state.selectedOption === "adults"
                          ? optionFilledIcon
                          : optionIcon
                      }
                      alt=""
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <input
                      type="checkbox"
                      style={{ margin: "7px" }}
                      name="adults"
                      value="adults"
                      checked
                      disabled
                      autoComplete="off"
                    />
                  </div>
                </div>
                {this.state.pricing[0]?.price ? (
                  <span
                    style={{
                      fontFamily: "Crete Round",
                      fontStyle: "italic",
                      color: "rgb(149, 150, 155)",
                      fontSize: "15px",
                      marginTop: "2px",
                      border: "medium none",
                    }}
                  >
                    {`(${Number(this.state.pricing[0]?.price)
                      .toFixed(2)
                      .replace(".", ",")
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}${this.context.currencyFormat})
                   
                   `}
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div style={styles.inputWrapper}>
                <div style={{ display: "flex" }}>
                </div>
                <div style={{ display: "flex", margin: "10px 0px" }}>
                  <input
                    type="text"
                    style={styles.inputLabel}
                    value={guestLabel.U12}
                    onChange={(e) => {
                      this.handleLabelChange(e, "U12");
                    }}
                    autoComplete="off"
                  />

                  <div
                    className="option"
                    onClick={() => this.showOptionComponent("U12", 1)}
                    style={{
                      border: this.functionValidation(this.state.pricing[1])
                        ? "2px solid red"
                        : "none",
                      borderRadius: "50%",
                      height: "34px",
                      width: "34px",
                      margin: "0px 5px",
                    }}
                  >
                    <img
                      src={
                        this.state.selectedOption === "U12"
                          ? optionFilledIcon
                          : optionIcon
                      }
                      alt=""
                    />
                  </div>
                  <input
                    type="checkbox"
                    style={{ margin: "7px" }}
                    name="U12"
                    value="U12"
                    checked={guestInfo.U12}
                    onChange={this.onGuestChange}
                    autoComplete="off"
                  />
                </div>
                {this.state.pricing[1]?.price ? (
                  <span
                    style={{
                      fontFamily: "Crete Round",
                      fontStyle: "italic",
                      color: "rgb(149, 150, 155)",
                      fontSize: "15px",
                      marginTop: "2px",
                      border: "medium none",
                    }}
                  >{`(${Number(this.state.pricing[1]?.price)
                    .toFixed(2)
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}${this.context.currencyFormat})`}</span>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div style={styles.flexRow}>
              <div style={styles.inputWrapper}>
                <div style={{ display: "flex" }}>
                </div>
                <div style={{ display: "flex", margin: "10px 0px" }}>
                  <input
                    type="text"
                    style={styles.inputLabel}
                    value={guestLabel.U18}
                    onChange={(e) => {
                      this.handleLabelChange(e, "U18");
                    }}
                    autoComplete="off"
                  />

                  <div
                    className="option"
                    onClick={() => this.showOptionComponent("U18", 2)}
                    style={{
                      border: this.functionValidation(this.state.pricing[2])
                        ? "2px solid red"
                        : "none",
                      borderRadius: "50%",
                      height: "34px",
                      width: "34px",
                      margin: "0px 5px",
                    }}
                  >
                    <img
                      src={
                        this.state.selectedOption === "U18"
                          ? optionFilledIcon
                          : optionIcon
                      }
                      alt=""
                    />
                  </div>
                  <input
                    type="checkbox"
                    style={{ margin: "7px" }}
                    name="U18"
                    checked={guestInfo.U18}
                    onChange={this.onGuestChange}
                    autoComplete="off"
                  />
                </div>
                {this.state.pricing[2]?.price ? (
                  <span
                    style={{
                      fontFamily: "Crete Round",
                      fontStyle: "italic",
                      color: "rgb(149, 150, 155)",
                      fontSize: "15px",
                      marginTop: "2px",
                      border: "medium none",
                    }}
                  >{`(${Number(this.state.pricing[2]?.price)
                    .toFixed(2)
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}${this.context.currencyFormat})`}</span>
                ) : (
                  ""
                )}
              </div>

              <div style={styles.inputWrapper}>
                <div style={{ display: "flex" }}>
                </div>
                <div style={{ display: "flex", margin: "10px 0px" }}>
                  <input
                    type="text"
                    style={styles.inputLabel}
                    value={guestLabel.U6}
                    onChange={(e) => {
                      this.handleLabelChange(e, "U6");
                    }}
                    autoComplete="off"
                  />

                  <div
                    className="option"
                    onClick={() => this.showOptionComponent("U6", 3)}
                    style={{
                      border: this.functionValidation(this.state.pricing[3])
                        ? "2px solid red"
                        : "none",
                      borderRadius: "50%",
                      height: "34px",
                      width: "34px",
                      margin: "0px 5px",
                    }}
                  >
                    <img
                      src={
                        this.state.selectedOption === "U6"
                          ? optionFilledIcon
                          : optionIcon
                      }
                      alt=""
                    />
                  </div>
                  <input
                    type="checkbox"
                    name="U6"
                    style={{ margin: "7px" }}
                    checked={guestInfo.U6}
                    onChange={this.onGuestChange}
                    autoComplete="off"
                  />
                </div>
                {this.state.pricing[3]?.price ? (
                  <span
                    style={{
                      fontFamily: "Crete Round",
                      fontStyle: "italic",
                      color: "rgb(149, 150, 155)",
                      fontSize: "15px",
                      marginTop: "2px",
                      border: "medium none",
                    }}
                  >{`(${Number(this.state.pricing[3]?.price)
                    .toFixed(2)
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}${this.context.currencyFormat})`}</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        </React.Fragment>
      );
    }
  };

  handleTextRadio = () => {
    const copyDivs = Object.assign([], this.state.themes);
    for (let i of copyDivs) {
      if (i.themeId === this.state.clickedTheme) {
        for (let j of i.divs) {
          if (j.divId === this.state.clickedDiv) {
            j["textClicked"] = !j["textClicked"];
            j["noteClicked"] = false;
            j["infoClicked"] = false;
            j["data"] = {};
          }
        }
      }
    }
    this.setState({
      themes: copyDivs,
    });
  };

  getElementLocalName = (term) => {

    if(term!==undefined){
    term = term.toLowerCase();
    }
    let local = {
      textfield: this.props.t('textfield'),
      notes: this.props.t('notes'),
      table: this.props.t('table'),
      radio:this.props.t('radio'),
      checkbox: this.props.t('checkbox'),
      text: this.props.t('text'),
      upload: this.props.t('upload'),
      download:this.props.t('download'),
    };

    if (local[term] !== undefined) {
      return local[term];
    }

    return term;
  };

  handleClickedDiv = (themeId, divId) => {
    // console.log("divId",divId)
    // console.log("handleClickedDiv",themeId)
    this.setState({
      clickedTheme: themeId,
      clickedDiv: divId,
    });
  };

  // handleTotextBox = (themeId, id) => {
  //   const themes = this.state.themes;
  //   for (let i of themes) {
  //     if (i.themeId === themeId) {
  //       for (let j of i.divs) {
  //         if (j.divId === id) {
  //           j["textClicked"] = !j["textClicked"];
  //         }
  //       }
  //     }
  //   }

  //   this.setState({ themes });
  // };
  handleToSelect = (themeId, id) => {
    const themes = this.state.themes;
    for (let i of themes) {
      if (i.themeId === themeId) {
        for (let j of i.divs) {
          if (j.divId === id) {
            j["selected"] = !j["selected"];
          }
        }
      }
    }

    this.setState({ themes });
  };
  addTheme = () => {
    var r1 = uuidv4();
    // var r1 = Math.round(Math.random() * 1000);
    // while (this.state.themeIds.includes(r1)) {
    //   r1 = Math.round(Math.random() * 1000);
    // }
    // var r2=Math.round(Math.random()*1000)
    // while(this.state.divIds.includes(r2)){
    //   r2=Math.round(Math.random()*1000)
    // }
    // var r1=this.state.clickedTheme+1
    var r2 = uuidv4();
    let theme = [
      {
        themeId: r1,
        // linkQtn:"",
        divs: [
          {
            divId: r2,
            // text:'',
            editorStateText: EditorState.createEmpty(),
            hideTextToolbar: false,
            editorStateNote: EditorState.createEmpty(),
            hideNoteToolbar: false,
            note: "",
            // selectedOption:"",
            textClicked: false,
            noteClicked: false,
            infoClicked: false,
            // question:{},
            data: {},
            selected: false,
          },
        ],
      },
    ];
    this.setState({
      themes: this.state.themes.concat(theme),
      link: false,
      selectedDropdown: "Info von Antwort",
    });
  };
  addDiv = (themeId) => {
    // var r=Math.round(Math.random()*1000)
    // while(this.state.divIds.includes(r)){
    //   r=Math.round(Math.random()*1000)
    // }
    const dummy = Object.assign([], this.state.themes);
    dummy.find((theme) => {
      return theme.themeId === themeId;
    });
    // console.log("x", x.divs.length)
    var r = uuidv4();
    // var r = x.divs.length + 1;
    const d = {
      divId: r,
      // text:'',
      editorStateText: EditorState.createEmpty(),
      hideTextToolbar: false,
      editorStateNote: EditorState.createEmpty(),
      hideNoteToolbar: false,
      note: "",
      // selectedOption:"",
      textClicked: false,
      noteClicked: false,
      infoClicked: false,
      // question:{},
      data: {},
      selected: false,
    };

    let copyDivs = Object.assign([], this.state.themes);
    for (let i of copyDivs) {
      if (i.themeId === themeId) {
        i.divs.push(d);
        this.setState({ array: i.divs });
      }
    }
    this.setState({
      themes: copyDivs,
      // divIds: this.state.divIds.concat(r),
      clickedDiv: r,
      clickedTheme: themeId,
      link: false,
      selectedDropdown: "Info von Antwort",
      singleDivClicked: true,
    });
  };
  handleRemove = (themeId, id) => {
    const copyDiv = Object.assign([], this.state.themes);
    for (let i of copyDiv) {
      if (i.themeId === themeId) {
        var filtered = i.divs.filter((div) => {
          return div.divId !== id;
        });
        i.divs = filtered;
      }
    }

    if (!filtered?.length) {
      var dum = copyDiv.filter((i) => {
        return i.themeId !== themeId;
      });
      this.setState({
        themes: dum,
      });
    } else {
      this.setState({
        themes: copyDiv,
      });
    }
  };

  find = () => {
    for (let i of this.state.themes) {
      if (i.themeId === this.state.clickedTheme) {
        for (let j of i.divs) {
          if (j.divId === this.state.clickedDiv) {
            return j.textClicked;
          }
        }
      }
    }
  };

  findNoteClicked = () => {
    for (let i of this.state.themes) {
      if (i.themeId === this.state.clickedTheme) {
        for (let j of i.divs) {
          if (j.divId === this.state.clickedDiv) {
            return j.noteClicked;
          }
        }
      }
    }
  };

  dropdownClicked = () => {
    var divClicked = document.getElementById("myDiv");
    // divClicked.style.height="60px"
    divClicked.style.height = `${250}px`;
    // if(offsetHeight>=409){
    // divClicked.style.height=`${offsetHeight}px`
    // divClicked.style.overflow="auto"
    // }
    // else{
    //   divClicked.style.height="60px"

    // }
  };

  handleNotesRadio = () => {
    const copyDivs = Object.assign([], this.state.themes);
    for (let i of copyDivs) {
      if (i.themeId === this.state.clickedTheme) {
        for (let j of i.divs) {
          if (j.divId === this.state.clickedDiv) {
            j["noteClicked"] = !j["noteClicked"];
            j["textClicked"] = false;
            j["infoClicked"] = false;
            j["data"] = {};
          }
        }
      }
    }
    this.setState({
      themes: copyDivs,
    });
  };

  // findInfoClicked=()=>{
  //   const copyDiv=Object.assign([], this.state.themes)
  //   for(let i of copyDiv){
  //     for(let k of i){
  //       for(let j in k){
  //         if(j==="id" && k[j]===this.state.clickedDiv){
  //           k["infoClicked"]=!k["infoClicked"]
  //         }
  //       }
  //     }
  //   }
  //     this.setState({
  //       themes:copyDiv
  //     })
  // }

  // handleInfoRadio=()=>{
  //   const copyDiv=Object.assign([], this.state.themes)
  //   for(let i of copyDiv){
  //     for(let k of i){
  //       for(let j in k){
  //         if(j==="id" && k[j]===this.state.clickedDiv){
  //           k["infoClicked"]=!k["infoClicked"]
  //         }
  //       }
  //     }
  //   }
  //     this.setState({
  //       themes:copyDiv
  //     })
  // }

  // handleSelectedOption=(option, type, sub)=>{
  //   const copyDiv=Object.assign([], this.state.themes)
  //   for(let i of copyDiv){
  //     if(i.themeId===this.state.clickedTheme){
  //       for(let j of i.divs){
  //         if(j.divId===this.state.clickedDiv){
  //           j["selectedDropdown"]=sub
  //           j["infoClicked"]=!j["infoClicked"]
  //           j["question"]=question
  //         }
  //       }
  //     }
  //   }
  //     this.setState({
  //       themes:copyDiv,
  //       selectedDropdown:sub
  //     })
  // }

  addThemeInBetween = (index, themeId) => {
    const copy = Object.assign([], this.state.themes);
    var r1 = uuidv4();
    // var r1 = Math.round(Math.random() * 1000);
    // while (this.state.themeIds.includes(r1)) {
    //   r1 = Math.round(Math.random() * 1000);
    // }
    // var r2=Math.round(Math.random()*1000)
    // while(this.state.divIds.includes(r2)){
    //   r2=Math.round(Math.random()*1000)
    // }

    var r2 = uuidv4();
    let theme = {
      themeId: r1,
      // linkQtn:"",
      divs: [
        {
          divId: r2,
          // text:'',
          editorStateText: EditorState.createEmpty(),
          hideTextToolbar: false,
          editorStateNote: EditorState.createEmpty(),
          hideNoteToolbar: false,
          note: "",
          // selectedOption:"",
          textClicked: false,
          noteClicked: false,
          infoClicked: false,
          // question:{},
          data: {},
          selected: false,
        },
      ],
    };

    copy.splice(index + 1, 0, theme);
    this.setState({
      themes: copy,
      link: false,
      selectedDropdown: "Info von Antwort",
    });
  };

  updateChange = (data, divId,themeId) => {
    // const divId = divClicked ? divClicked : this.state.clickedDiv;
    // console.log("data",data)
    // console.log("clickedtheme",this.state.clickedTheme)
    const copyDiv = Object.assign([], this.state.themes);
    let clickedTheme=themeId? themeId: this.state.clickedTheme
    // console.log("copyDiv in updateChage",copyDiv)
    for (let i of copyDiv) {
      // console.log(i,"i")
      // console.log("clickedtheme",this.state.clickedTheme)
      if (i.themeId ===clickedTheme) {
        // console.log("array",this.state.array)
        // console.log("j",i)
        for (let j of i.divs) {
        //  console.log("j.divid",j.divId,"divId",divId)
          if (j.divId === divId) {
            j["data"] = data;
            // console.log("j",j)
          }
          
        }
      }
    }
    // console.log("copydv", copyDiv);
    this.setState({
      themes: copyDiv,
      // selectedDropdown:element
    });
  };

  showServiceElements = (theme, service) => {
    //console.log("Clicked service", service);
    //console.log("Themee", theme);
    if (service.elements.length === 1) {
      service.elements.forEach((element, index) => {
        // console.log("index",index);
        this.handleLinkOption(
          theme._id,
          service._id,
          element,
          theme.name,
          index
        );
      });
    } else if (service.elements.length > 1) {
      service.elements.forEach((element, index) => {
        // console.log("index",index);
        this.addMultipleDivs(
          this.state.clickedTheme,
          theme._id,
          service._id,
          element,
          theme.name,
          index
        );
      });
    }
  };
  addMultipleDivs = (
    themeId,
    theme_id,
    service_id,
    element,
    theme_name,
    index
  ) => {
    const dummy = Object.assign([], this.state.themes);
    dummy.find((theme) => {
      return theme.themeId === themeId;
    });
    // console.log("x", x, element)
    var r = uuidv4();
    // var r = x? x.divs.length + 1 :1;
    const d = {
      divId: r,
      // text:'',
      editorStateText: EditorState.createEmpty(),
      hideTextToolbar: false,
      editorStateNote: EditorState.createEmpty(),
      hideNoteToolbar: false,
      note: "",
      // selectedOption:"",
      textClicked: false,
      noteClicked: false,
      infoClicked: true,
      selectedDropdown: {
        ...element,
        themeId: theme_id,
        serviceId: service_id,
      },
      // question:{},
      data: {},
      selected: false,
    };

    let copyDivs = Object.assign([], this.state.themes);

    for (let i of copyDivs) {
      if (i.themeId === themeId) {
        var filtered = i.divs.filter((div) => {
          return div.divId !== this.state.clickedDiv;
        });
        i.divs = filtered;
        i.divs.push(d);
        this.setState({ array: i.divs }, () => {
          // console.log("array", this.state.array);
        });
        // array.push(d.divId)
      }
    }

    this.setState(
      {
        themes: copyDivs,
        divIds: this.state.divIds.concat(r),
        clickedDiv: r,
        clickedTheme: themeId,
        link: false,
        selectedDropdown: "Info von Antwort",
        singleDivClicked: false,
      },
      () => {
        // console.log("copyDivs",copyDivs)
        // console.log("divIds",this.state.divIds)
      }
    );
  };
  toggleService = (element) => {
    document.getElementById(element).checked = false;
  };

  handleLinkOption = (
    themeId,
    serviceId,
    element,
    opt,
    index,
    sIndex = null
  ) => {
    // console.log("themeid",themeId, serviceId, element)
    // console.log("options",opt,"index",index)
    // console.log(document.getElementById(`${opt}${index}`))

    //console.log(`${sIndex}${opt}${index}`)

    // if(sIndex){
    //   document.getElementById(`${opt}${index}`).checked = false;
    // }

    const copyDiv = Object.assign([], this.state.themes);
    for (let i of copyDiv) {
      if (i.themeId === this.state.clickedTheme) {
        for (let j of i.divs) {
          if (j.divId === this.state.clickedDiv) {
            j["selectedDropdown"] = { ...element, themeId, serviceId };
            j["infoClicked"] = true;
            j["textClicked"] = false;
            j["noteClicked"] = false;
            // j["question"]=question
          }
        }
      }
    }
    // console.log('elem', element)
    if (element.optionType === "radio" || element.optionType === "checkbox") {
      axios
        .get(
          `${baseUrl}api/export/ServiceElement/${this.props.questionnaireId}/${element.questionIndex}/${element.rowIndex}/${element.columnIndex}/${themeId}/${serviceId}`
        )
        .then((res) => {
          // console.log('here', element, d)
          this.setState({
            themes: copyDiv,
            // link: !this.state.link,
            linkData: { ...element, themeId, serviceId },
            selectedDropdown: element.optionType,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (element.optionType === "image") {
      axios
        .get(
          `${baseUrl}api/export/ServiceElement/${this.props.questionnaireId}/${element.questionIndex}/${element.rowIndex}/${element.columnIndex}/${themeId}/${serviceId}`
        )
        .then((res) => {
          this.setState({
            themes: copyDiv,
            // link: !this.state.link,
            linkData: { ...element, themeId, serviceId },
            selectedDropdown: element.optionType,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState({
        themes: copyDiv,
        selectedDropdown: element.optionType,
      });
    }
  };

  renderOption = (option, divId, divData,themeId) => {
    //     console.log("option",option)
    // console.log("divData in renderoption",divData)
    // console.log("optionType",option.optionType)
    // console.log("divId",divId)

    switch (option.optionType) {
      case "text":
        return (
          <TextOption
            key={divId}
            divId={divId}
            option={option}
            divData={divData}
            updateChange={this.updateChange}
            questionnaireId={this.props.questionnaireId}
            planId={this.props.planId}
          />
        );
      case "datum":
        return (
          <DatumOption
            key={divId}
            divId={divId}
            option={option}
            updateChange={this.updateChange}
            questionnaireId={this.props.questionnaireId}
            planId={this.props.planId}
          />
        );
      case "bild":
        return (
          <BildOption
            key={divId}
            divId={divId}
            option={option}
            handleLinking={this.handleLinking}
            updateChange={this.updateChange}
            questionnaireId={this.props.questionnaireId}
            planId={this.props.planId}
          />
        );
      case "radio":
        return (
          <RadioOption
            key={divId}
            themeId={themeId}
            divId={divId}
            option={option}
            divData={divData}
            divClicked={divId}
            handleLinking={this.handleLinking}
            updateChange={this.updateChange}
            questionnaireId={this.props.questionnaireId}
            planId={this.props.planId}
          />
        );
      case "checkbox":
        return (
          <RadioMultipleOption
            key={divId}
            divId={divId}
            option={option}
            divData={divData}
            divClicked={divId}
            handleLinking={this.handleLinking}
            updateChange={this.updateChange}
            questionnaireId={this.props.questionnaireId}
            planId={this.props.planId}
          />
        );

      case "table":
        return (
          <TableOption
            key={divId}
            divId={divId}
            option={option}
            updateChange={this.updateChange}
            questionnaireId={this.props.questionnaireId}
            planId={this.props.planId}
          />
        );
      case "upload":
        return (
          <>
            {/* {  console.log("divdata",divData)} */}
            <UploadOption
              key={divId}
              divId={divId}
              option={option}
              divData={divData}
              updateChange={this.updateChange}
              questionnaireId={this.props.questionnaireId}
              planId={this.props.planId}
            />
          </>
        );
      case "image":
        if ((divData?.imageInput?.multipleSelection === false)&&(divData?.radioInput?.multipleSelection === false)) {
          return (
            <ImageOption
              key={divId}
              divData={divData}
              divId={divId}
              option={option}
              updateChange={this.updateChange}
              handleLinking={this.handleLinking}
              questionnaireId={this.props.questionnaireId}
              planId={this.props.planId}
            />
          );
        } else {
          return (
            <ImageMultipleOption
              key={divId}
              divId={divId}
              divData={divData}
              option={option}
              updateChange={this.updateChange}
              handleLinking={this.handleLinking}
              questionnaireId={this.props.questionnaireId}
              planId={this.props.planId}
            />
          );
        }

      case "download":
        return (
          <>
            <DownloadOption
              key={divId}
              divId={divId}
              option={option}
              divData={divData}
              updateChange={this.updateChange}
              handleLinking={this.handleLinking}
              questionnaireId={this.props.questionnaireId}
              planId={this.props.planId}
            />
          </>
        );
      default:
        break;
    }
  };

  updateRadioLink = async (radioInput) => {
    // console.log('here', radioInput)
    const copyDiv = Object.assign([], this.state.themes);
    for (let i of copyDiv) {
      if (i.themeId === this.state.clickedTheme) {
        for (let j of i.divs) {
          if (j.divId === this.state.clickedDiv) {
            if (j.data?.radioInput?.link) {
              j.data.radioInput.link = radioInput?.link;
            } else if (j.data?.imageInput?.link) {
              j.data.imageInput.link = radioInput?.link;
            }

            // console.log('j', j)
          }
        }
      }
    }
    // console.log('updated', radioInput)

    this.setState({
      themes: copyDiv,
    });
  };
  updateThemeLink = (data) => {
    // console.log("update theme link data",data)
    const themes = Object.assign({}, this.state.themes);
    themes[this.state.selectedThemeIndex].themeLink = data;
    this.setState({
      selectedLinkData: data,
    });
    // console.log("selectedLinkData",this.state.selectedLinkData)
  };
  handleLinking = (linkingData) => {
    //  console.log('linking', linkingData)
    this.setState({
      link: !this.state.link,
      secondLinkData: linkingData,
      linkData: {},
    });
  };
  handleClickedDiv = (theme, div) => {
    // const themes=this.state.themes
    //   for(let i of themes){
    //     if(i.themeId===theme){
    //       for(let j of i.divs){
    //         if(j.divId===div){
    //           j["selected"]=!j["selected"]
    //         }
    //       }
    //     }
    //   }
    // console.log("divId",this.state.clickedDiv)
    this.setState({
      clickedDiv: div,
      clickedTheme: theme,
      // themes
    });
  };
  render() {
    return (
      <div className="popup-overlay" style={{ position: "fixed" }}>
        <div className="popup template-selection-popup export-section-popup" style={{ overflowY: "scroll"}}>
          <div
            className="popup-container"
            style={{ height: "100%", maxWidth: "unset", minHeight: "100vh" }}
          >
            <label
              style={{
                marginBottom: "50px",
                color: "#939598",
                fontSize: "20px",
              }}
            >
              {this.props.t('Header')}
            </label>
            <div
              style={{
                // background: "#e6e7e8",
                color: "#939598",
                // padding: "6px 100px 1px 200px",
                display: "inline-table",
                // marginTop: "4px",
                width: "20px",
                // height: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginRight: "-20px",
                  marginLeft: "100px",
                  marginTop: "-40px",
                  height: "34px",
                }}
              >
                {this.state.guestOptions?.map((op, i) => {
                  // console.log(this.state.guestOptions,"this.state.guestOptions")
                  return (
                    <div
                      key={i}
                      // onClick={(e)=>this.HeaderObject(e)}
                      style={{
                        paddingBottom: "20px",
                        marginTop: "-10px",
                        color: "#939598",
                        // background: "#e6e7e8",
                        display: "flex",
                        // marginLeft: "10px",
                        paddingLeft: "10px",

                        // paddingRight: "140px",
                      }}
                    >
                      <input
                        onChange={(e) => {
                          this.selectedHeader(e, op, i);
                        }}
                        className="HeaderObject"
                        checked={op.checked}
                        type="checkbox"
                        id={op.id}
                        name={op.id}
                        value={op?.name}
                        autoComplete="off"
                      />
                      <div
                        onClick={
                          op.name === "Anzahl Gäste" ||
                          op.name === "Number Of Guest"
                            ? this.handleNumberOfGuest
                            : undefined
                        }
                      >
                        <label
                          style={{
                            clear: "both",
                            fontSize: "16px",
                            display: "inline-block",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                          htmlFor={op.id}
                        >
                          {this.props.t(op.name)}
                        </label>
                      </div>
                      {(op.name === "Anzahl Gäste" ||
                        op.name === "Number Of Guest") &&
                        op?.checked === true && (
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            {op?.value?.map((o, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    // paddingBottom: "20px",
                                    // marginTop: "-10px",
                                    color: "#939598",
                                    // background: "#e6e7e8",
                                    display: "flex",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  <input
                                    checked={o.checked}
                                    type="checkbox"
                                    id={o?.id}
                                    name={o?.id}
                                    value={o?.name}
                                    onChange={(e) => {
                                      this.selectedGuest(e, o, index);
                                    }}
                                    autoComplete="off"
                                  />
                                  <label
                                    style={{ display: "block-ruby" }}
                                    htmlFor={o?.id}
                                  >
                                    {/* {Object.keys(this.state.guestLabel).map((label)=>{
                                      return o?.label==label && this.state.guestLabel[label]
                                    })} */}
                                    {this.props.t(o?.name)}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        )}
                    </div>
                  );
                })}
              </div>
            </div>
            <button className="close-btn" style={{position:"fixed"}} onClick={this.handleClose()}>
              <img src={closeIcon} alt="" />
            </button>
            <button className="save-btn" style={{position:"fixed"}} onClick={this.handleSave}>
            {this.props.t('save')}
            </button>
            <div
              className="row"
              style={{
                fontFamily: "Crete Round",
                // fontStyle: "italic",
                height: "100%",
                marginTop: "50px"
              }}
            >
              <div style={{ position: "absolute" }} className="col-7 first_box">
                <div>
                  {this.state.themes.map((theme, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          this.onThemeClicked(theme.themeId);
                        }}
                      >
                        <button className="link_btn">
                          <img
                            src={linkIcon}
                            alt=""
                            onClick={() => {
                              this.handleLink(theme.themeId, index);
                            }}
                          />
                        </button>
                        <div className="layout-container">
                          {theme.priceObject ? (
                            <div style={{ color: "rgb(147, 149, 152)" }}>
                              {theme.priceObject.map((price) => {
                                return (
                                  <div>
                                    {price.checked ? (
                                      <div style={{ display: "flex" }}>
                                        {/* <input
                                         onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                            className="inp1"
                                          onChange={(e) => {
                                            this.handlePriceChange(e, price);
                                          }}
                                          value={price.price}
                                        /> */}
                                        <div
                                          className="quest1"
                                          style={{ width: "100%" }}
                                        >
                                          {price.name}
                                          <img
                                            src={crossLight}
                                            alt=""
                                            style={{
                                              // marginRight:"5px",
                                              borderRadius: "50%",
                                              float: "right",
                                              padding: "2px",
                                              // position: "relative",
                                              backgroundColor: "#939598",
                                              // marginTop:"-220px",
                                              position: "absolute",
                                              left: "630px",
                                            }}
                                            className="delete_icon"
                                            onClick={() => {
                                              this.handleGuestRemove(
                                                price
                                                // theme.themeId,
                                                // div.divId
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <></>
                          )}
                          {theme.divs.map((div) => {
                            if (div.textClicked) {
                              if (
                                Object.keys(div.editorStateText).includes(
                                  "blocks"
                                )
                              ) {
                                return (
                                  <div style={{ paddingTop: "20px" }}>
                                    {/* <button
                                      //  style={{marginTop: "10px"}}
                                      className="delete_icon3"
                                      onClick={() => {
                                        this.handleRemove(
                                          theme.themeId,
                                          div.divId
                                        );
                                      }}
                                    >
                                      <img src={crossLight} alt="" />
                                    </button> */}
                                    <img
                                      src={crossLight}
                                      alt=""
                                      style={{
                                        marginRight: "7px",
                                        // marginRight:"5px",
                                        borderRadius: "50%",
                                        float: "right",
                                        padding: "2px",
                                        // position: "relative",
                                        backgroundColor: "#939598",
                                        // marginTop:"-220px",
                                        marginBottom: "-45px",
                                        width: "17px",
                                      }}
                                      className="delete_icon"
                                      onClick={() => {
                                        this.handleRemove(
                                          theme.themeId,
                                          div.divId
                                        );
                                      }}
                                    />
                                    <Editor
                                      key={div.divId}
                                      toolbarOnFocus={!div.hideToolbar}
                                      toolbarHidden={div.hideToolbar}
                                      editorState={EditorState.createWithContent(
                                        convertFromRaw(div.editorStateText)
                                      )}
                                      toolbarClassName="toolbarClassName"
                                      wrapperClassName="wrapperClassName"
                                      editorClassName="editorClassName"
                                      onFocus={() => {
                                        this.handleOnFocus(
                                          theme.themeId,
                                          div.divId
                                        );
                                      }}
                                      onBlur={() => {
                                        this.handleOnBlur(
                                          theme.themeId,
                                          div.divId
                                        );
                                      }}
                                      onEditorStateChange={(e) => {
                                        this.onEditorStateTextChange(
                                          theme.themeId,
                                          div.divId,
                                          e
                                        );
                                      }}
                                      toolbar={{fontFamily:{options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana','Roboto'],
                                    }}}
                                    />
                                  </div>
                                );
                              } else {
                                return (
                                  <div style={{ paddingTop: "20px" }}>
                                    <img
                                      src={crossLight}
                                      alt=""
                                      style={{
                                        marginRight: "7px",
                                        // marginRight:"5px",
                                        borderRadius: "50%",
                                        float: "right",
                                        padding: "2px",
                                        // position: "relative",
                                        backgroundColor: "#939598",
                                        // marginTop:"40px",
                                        marginBottom: "-45px",
                                        width: "17px",
                                      }}
                                      className="delete_icon"
                                      onClick={() => {
                                        this.handleRemove(
                                          theme.themeId,
                                          div.divId
                                        );
                                      }}
                                    />
                                    {/* <button
                                      style={{padding:"2px" }}
                                      className="delete_icon"
                                      onClick={() => {
                                        this.handleRemove(
                                          theme.themeId,
                                          div.divId
                                        );
                                      }}
                                    >
                                      <img src={crossLight} alt="" />
                                    </button> */}
                                    <Editor
                                      key={div.divId}
                                      toolbarOnFocus={!div.hideToolbar}
                                      toolbarHidden={div.hideToolbar}
                                      editorState={div.editorStateText}
                                      toolbarClassName="toolbarClassName"
                                      wrapperClassName="wrapperClassName"
                                      editorClassName="editorClassName"
                                      onFocus={() => {
                                        this.handleOnFocus(
                                          theme.themeId,
                                          div.divId
                                        );
                                      }}
                                      onBlur={() => {
                                        this.handleOnBlur(
                                          theme.themeId,
                                          div.divId
                                        );
                                      }}
                                      onEditorStateChange={(e) => {
                                        this.onEditorStateTextChange(
                                          theme.themeId,
                                          div.divId,
                                          e
                                        );
                                      }}
                                      toolbar={{fontFamily:{options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana','Roboto'],
                                    }}}
                                    />
                                  </div>
                                );
                              }

                              // <input
                              //          key={div.divId}
                              //          name="text"
                              //          type="text"
                              //          className="input_field"
                              //          value={div.text}
                              //          onChange={e=>this.setTextInputValue(theme.themeId, div.divId, e)}
                              //          />
                            } else if (div.noteClicked) {
                              return (
                                <>
                                  <div
                                    style={{
                                      marginTop: "30px",
                                      marginBottom: "90px",
                                    }}
                                  >
                                    {/* <button
                                // style={{marginRight:"-2px",float:"right"}}
                                    className="delete_icon"
                                    onClick={() => {
                                      this.handleRemove(
                                        theme.themeId,
                                        div.divId
                                      );
                                    }}
                                  >
                                    <img src={deleteIcon} alt="" />
                                  </button> */}

                                    <input
                                      key={div.divId}
                                      type="text"
                                      name="note"
                                      placeholder={this.props.t("Notizen")}
                                      className="input_field"
                                      style={{
                                        backgroundColor: "#e6e7e8",

                                        height: "40px",
                                        //  marginBottom:"-20px",
                                        width: "82.4%",
                                        boxShadow: "none",
                                        border: "none",
                                        position: "absolute",
                                      }}
                                      disabled
                                      value={div.note}
                                      onChange={(e) =>
                                        this.setTextInputValue(
                                          theme.themeId,
                                          div.divId,
                                          e
                                        )
                                      }
                                      autoComplete="off"
                                    />
                                    <img
                                      src={crossLight}
                                      alt=""
                                      style={{
                                        // marginLeft:"210px",
                                        marginRight: "7px",
                                        borderRadius: "50%",
                                        float: "right",
                                        padding: "2px",
                                        position: "relative",
                                        backgroundColor: "#939598",
                                        marginTop: "3px",
                                        width: "17px",
                                      }}
                                      className="delete_icon"
                                      onClick={() => {
                                        this.handleRemove(
                                          theme.themeId,
                                          div.divId
                                        );
                                      }}
                                    />
                                  </div>
                                </>
                              );
                            } else if (div.infoClicked) {
                              // console.log("div.infoClicked", div)
                              return (
                                <div
                                  style={{ marginTop: "10px" }}
                                  key={div.divId}
                                  onClick={() => {
                                    this.handleClickedDiv(
                                      theme.themeId,
                                      div.divId
                                    );
                                  }}
                                >
                                  <img
                                    src={crossLight}
                                    alt=""
                                    style={{
                                      // marginLeft:"210px",
                                      borderRadius: "50%",
                                      float: "right",
                                      padding: "2px",
                                      position: "relative",
                                      backgroundColor: "#939598",
                                      marginTop: "2px",
                                      marginRight: "7px",
                                      marginBottom: "-50px",
                                      width: "17px",
                                    }}
                                    className="delete_icon3"
                                    onClick={() => {
                                      this.handleRemove(
                                        theme.themeId,
                                        div.divId
                                      );
                                    }}
                                  />
                                  {/* {console.log("selceted dropdown",div.selectedDropdown)}
{console.log("divID",div.divId)} */}
                                  {/* {console.log("div?.data before render option",div?.data)} */}
                                  {this.renderOption(
                                    div.selectedDropdown,
                                    div.divId,
                                    div?.data,
                                    theme.themeId
                                  )}
                                </div>
                              );
                              // return <Information
                              //           key={div.divId}
                              //           handleInfoChanges={this.handleInfoChanges}
                              //           qtn={div.question}
                              //           childHandleChange={this.childHandleChange}
                              //           childQtnAltChange={this.childQtnAltChange}
                              //           childHandleImageDrop={this.childHandleImageDrop}
                              //           childHandleThumbnailDrop={this.childHandleThumbnailDrop}
                              //           childHandleLargeImgDrop={this.childHandleLargeImgDrop}
                              //           childHandleURLDrop={this.childHandleURLDrop}
                              //          />
                            } else {
                              return (
                                <div
                                  key={div.divId}
                                  className={
                                    div.selected
                                      ? `expo_button_notSelected`
                                      : `expo_button_selected`
                                  }
                                  onClick={() => {
                                    this.handleClickedDiv(
                                      theme.themeId,
                                      div.divId
                                    );
                                  }}
                                >
                                  <img
                                    src={deleteIcon}
                                    alt=""
                                    style={{
                                      // marginLeft:"210px",
                                      borderRadius: "50%",
                                      float: "right",
                                      padding: "2px",
                                      position: "relative",
                                      backgroundColor: "#e6e7e8",
                                      marginTop: "2px",
                                    }}
                                    className="delete_icon3"
                                    onClick={() => {
                                      this.handleRemove(
                                        theme.themeId,
                                        div.divId
                                      );
                                    }}
                                  />
                                  {/* <button
                                    className="delete_icon"
                                    onClick={() => {
                                      this.handleRemove(
                                        theme.themeId,
                                        div.divId
                                      );
                                    }}
                                  >
                                    <img src={deleteIcon} alt="" />
                                  </button> */}
                                  <button
                                    className="plus_light"
                                    onClick={() => {
                                      this.handleToSelect(
                                        theme.themeId,
                                        div.divId
                                      );
                                    }}
                                  >
                                    <img src={plusLight} alt="" />
                                  </button>
                                </div>
                              );
                            }
                          })}

                          <button
                            className="add-text"
                            onClick={() => {
                              this.addDiv(theme.themeId);
                            }}
                          >
                            <img src={plusIcon} alt="" />
                          </button>
                        </div>
                        {/* {console.log('hi', this.state.themes.length, index)} */}
                        {index !== this.state.themes.length - 1 && (
                          <button
                            className="add-text"
                            onClick={() => this.addThemeInBetween(index, theme)}
                          >
                            <img src={plusIcon} alt="" />
                          </button>
                        )}
                      </div>
                    );
                  })}
                  <button className="add-text" onClick={this.addTheme}>
                    <img src={plusIcon} alt="" />
                  </button>
                </div>
              </div>
              {this.state.link ? (
                <div style={{ left: "63%" }} className="col-4 second_box">
                  <ThemeLinking
                    data={this.state.selectedLinkData}
                    updateThemeLink={this.updateThemeLink}
                    questionnaireId={this.props.questionnaireId}
                  />
                </div>
              ) : (
                <div style={{ left: "63%" }} className="col-4 second_box">
                  <div>
                    {/* <div style={{ position: "fixed" }}> */}
                    {/* <button
          className={`component-selector ${
            this.state.selectedInput === "datum" ? "selected" : ""
          }`}
          onClick={() => this.setComponentSelected("datum")}
        >
          Datum
          <div className="right-dot">
            <span></span>
          </div>
          <img
            src={
              this.state.selectedInput === "datum"
                ? arrowSmallSelected
                : arrowSmall
            }
            alt=""
          />
        </button> */}
                    <div style={{ display: "flex" }}>
                      <div
                        className="text_button"
                        onClick={this.handleTextRadio}
                      >
                        {this.props.t('Textfeld')}
                      </div>
                      {this.find() ? (
                        <div className="button_divs">
                          <div
                            className="radio_clicked"
                            onClick={this.handleTextRadio}
                          ></div>
                        </div>
                      ) : (
                        <div className="button_divs">
                          <div
                            className="radio_unclicked"
                            onClick={this.handleTextRadio}
                          ></div>
                        </div>
                      )}
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        className="text_button"
                        onClick={this.handleNotesRadio}
                      >
                        {this.props.t('notes')}  
                      </div>
                      {this.findNoteClicked() ? (
                        <div className="button_divs">
                          {" "}
                          <div
                            className="radio_clicked"
                            onClick={this.handleNotesRadio}
                          ></div>
                        </div>
                      ) : (
                        <div className="button_divs">
                          <div
                            className="radio_unclicked"
                            onClick={this.handleNotesRadio}
                          ></div>
                        </div>
                      )}
                    </div>

                    <div
                      className="navi"
                      // onClick={this.dropdownClicked}
                      style={{
                        width: "100%",
                        margin: "0px",
                        overflow: "auto",
                        position: "relative",
                        // height:this.state.bool?"400px":"60px"}}
                      }}
                    >
                      <input type="checkbox" id="menu" autoComplete="off" />
                      <label
                        className="checkboxLable"
                        htmlFor="menu"
                        id="navi-icon2"
                      >
                        {this.state.selectedDropdown}
                      </label>
                      <img
                        src={arrowDown}
                        alt=""
                        style={{
                          height: 10,
                          width: 10,
                          top: 15,
                          marginLeft: 294,
                          display: "block",
                          float: "right",
                          marginRight: 15,
                          position: "absolute",
                          right: 0,
                          scale: "1.5",
                        }}
                      />
                      <div
                        className="multi-level"
                        id="myDiv"
                        onClick={this.dropdownClicked}
                        style={{ overflow: "auto", height: "200px" }}
                      >
                        <div
                          style={{
                            background: "#e6e7e8",
                            color: "#939598",
                            // padding: "1px 250px 1px 1px",
                            marginTop: "4px",
                            clear: "both",

                            whiteSpace: "nowrap",
                          }}
                        >
                          <img
                            src={arrowDown}
                            className="dropdown_arrow"
                            alt=""
                          />
                          <label
                            onClick={this.priceOptionsDisplay}
                            className="checkboxLable"
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                            }}
                          >
                          {this.props.t('Anzahl')}  
                          </label>
                        </div>

                        {this.state.display3 === true ? (
                          <div style={{ display: "flex", marginTop: "20px" }}>
                            <div
                              style={{
                                width: " 4000px",
                              }}
                            >
                              {this.state.abc?.map((price, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      paddingBottom: "20px",
                                      marginTop: "-10px",
                                      color: "#939598",
                                      background: "#e6e7e8",
                                      display: "flex",
                                      fontSize: "14px",
                                    }}
                                  >
                                    <input
                                      checked={price.checked}
                                      type="checkbox"
                                      id={price?.id}
                                      name={price?.id}
                                      value={price?.name}
                                      onChange={(e) => {
                                        this.selectedPrice(e, price, index);
                                      }}
                                      autoComplete="off"
                                    />
                                    <label htmlFor={price?.id}>
                                      - {this.props.t(price?.name)}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {this.state?.options?.length
                          ? this.state.options.map((theme, _i) => {
                             
                              return (
                                <div key={theme._id} className="item">
                                  <input
                                    type="checkbox"
                                    id={`${theme.name}${_i}`}
                                    autoComplete="off"
                                  />
                                  <img
                                    src={arrowDown}
                                    className="dropdown_arrow"
                                    alt=""
                                  />
                                  <label
                                    className="checkboxLable"
                                    style={{
                                      fontSize: "14px",
                                      textAlign: "left",
                                    }}
                                    htmlFor={`${theme.name}${_i}`}
                                  >
                                    {theme.name}
                                  </label>
                                  <ul style={{ fontSize: "14px" }}>
                                    {theme?.services.map((service, _s) => {
                                      // console.log('service', servicez)
                                      if (service?.elements) {
                                        // console.log(
                                        //   "ids",
                                        //   service,
                                        //   service._id,
                                        //   this.context.serviceId,
                                        //   theme._id,
                                        //   this.context.themeId
                                        // );

                                        return (
                                          <li
                                            style={{ fontSize: "14px" }}
                                            key={service._id}
                                          >
                                            {/* {console.log('serv', service)} */}
                                            <div
                                              className="sub-item"
                                              style={{
                                                marginLeft: "70px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <input
                                                type="checkbox"
                                                id={`${_i}${service.name}${_s}`}
                                                autoComplete="off"
                                              />
                                              <img
                                                src={arrowDown}
                                                className="dropdown_arrow"
                                                alt=""
                                              />
                                              <label
                                                className="checkboxLable"
                                                style={{
                                                  fontSize: "14px",
                                                  textAlign: "left",
                                                }}
                                                htmlFor={`${_i}${service.name}${_s}`}
                                              >
                                                {/* {console.log("service", service)} */}
                                                <span
                                                  onClick={() => {
                                                    this.showServiceElements(
                                                      theme,
                                                      service
                                                    );
                                                  }}
                                                >
                                                  {" "}
                                                  {service?.name}
                                                </span>
                                              </label>
                                              <ul style={{ fontSize: "14px" }}>
                                                {/* {service?.elements?.map((element, i) => {
                                      return (
                                        <li
                                          key={i}
                                          style={{ fontSize: "14px" }}
                                          onClick={() => {
                                            this.handleLinkOption(
                                              theme._id,
                                              service._id,
                                              element
                                            );
                                          }}
                                        >
                                          {element.optionType}
                                        </li>
                                      );
                                    })} */}

                                                {service?.elements?.map(
                                                  (element, i) => {
                                                    return (
                                                      <li
                                                        key={i}
                                                        style={{
                                                          fontSize: "14px",
                                                        }}
                                                        // onClick={() => {
                                                        //   this.handleLinkOption(
                                                        //     theme._id,
                                                        //     service._id,
                                                        //     element
                                                        //   );
                                                        // }}
                                                      >
                                                        <div
                                                          className="sub-item"
                                                          onClick={() => {
                                                            this.handleLinkOption(
                                                              theme._id,
                                                              service._id,
                                                              element,
                                                              theme.name,
                                                              _i
                                                            );
                                                          }}
                                                        >
                                                          {/* <input
                                                          type="checkbox"
                                                          id={`${_i}${_s}${i}`}
                                                        />
                                                        <img
                                                          src={arrowDown}
                                                          className="dropdown_arrow"
                                                          alt=""
                                                        />
                                                        <label
                                                          className="checkboxLable"
                                                          style={{
                                                            fontSize: "14px",
                                                            textAlign: "left",
                                                          }}
                                                          htmlFor={`${_i}${_s}${i}`}
                                                        > */}
                                                          <span
                                                            onClick={() => {
                                                              this.toggleService(
                                                                `${_i}${service.name}${_s}`
                                                              );
                                                            }}
                                                          >
                                                            -
                                                          </span>{" "}
                                                          {this.getElementLocalName(
                                                            element?.optionType
                                                          )}
                                                          {/* </label> */}
                                                          {/*<ul
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                        >
                                                          {element?.options.map(
                                                            (option, o) => {
                                                              return (
                                                                <li
                                                                  key={o}
                                                                  onClick={() => {
                                                                    this.handleLinkOption(
                                                                      theme._id,
                                                                      service._id,
                                                                      element,
                                                                      option,
                                                                      // index
                                                                    );
                                                                  }}
                                                                >
                                                                  -{" "}
                                                                  {
                                                                    option.optionValue
                                                                  }
                                                                </li>
                                                              );
                                                            }
                                                          )} 
                                                        </ul>*/}
                                                        </div>
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          </li>
                                        );
                                      } else {
                                        return null;
                                      }
                                    })}
                                  </ul>
                                </div>
                              );
                            })
                          : null}
                      </div>

                      {/* <div className="multi-level">
                      {this.state?.options?.length
                        ? this.state.options.map((theme, index) => {
                            return (
                              <div key={theme._id} className="item">
                               
                                <input type="checkbox" id={theme.name} />
                                <img src={arrowDown} className="dropdown_arrow" alt="" />
                                <label
                                  className="checkboxLable"
                                  style={{
                                    fontSize: "14px",
                                    textAlign: "left",
                                  }}
                                  htmlFor={theme.name}
                                >
                                  {theme.name}
                                </label>
                                <ul style={{ fontSize: "14px" }}>
                                  {theme.services.map((service) => {
                                  if(service.elements){
                                    return (
                                      <li
                                        style={{ fontSize: "14px" }}
                                        key={service._id}
                                      >
                                        <div className="sub-item">
                                          <input
                                            type="checkbox"
                                            id={service.name}
                                          />
                                          <img
                                            src={arrowDown}
                                            className="dropdown_arrow"
                                            alt=""
                                          />
                                          <label
                                            className="checkboxLable"
                                            style={{
                                              fontSize: "14px",
                                              textAlign: "left",
                                            }}
                                            htmlFor={service.name}
                                          >
                                            {service.name}
                                          </label>
                                          <ul style={{ fontSize: "14px" }}>
                                            {service.elements.map(
                                              (element, i) => {
                                                return (
                                                  <li
                                                  key={i}
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  <div className="sub-item">
                                                    <input
                                                      type="checkbox"
                                                      id={`${i}`}
                                                    />
                                                    <img
                                                      src={arrowDown}
                                                      className="dropdown_arrow"
                                                      alt=""
                                                    />
                                                    <label
                                                      className="checkboxLable"
                                                      style={{
                                                        fontSize: "14px",
                                                        textAlign: "left",
                                                      }}
                                                      htmlFor={`${i}`}
                                                    >
                                                      {element.optionType}
                                                    </label>
                                                    <ul
                                                      style={{
                                                        fontSize: "14px",
                                                      }}
                                                    >
                                                      {element.options.map(
                                                        (option, o) => {
                                                          return (
                                                            <li
                                                              key={o}
                                                              onClick={() => {
                                                                this.handleLinkOption(
                                                                  theme._id,
                                                                  service._id,
                                                                  element,
                                                                  option,
                                                                  index
                                                                );
                                                              }}
                                                            >
                                                              - {
                                                                option.optionValue
                                                              }
                                                            </li>
                                                          );
                                                        }
                                                      )}
                                                    </ul>
                                                  </div>
                                                </li>
                                                  // <li
                                                  //   key={i}
                                                  //   style={{ fontSize: "14px" }}
                                                  //   onClick={() => {
                                                  //     this.handleLinkOption(
                                                  //       theme._id,
                                                  //       service._id,
                                                  //       element
                                                  //     );
                                                  //   }}
                                                  // >
                                                  //   {element.optionType}
                                                  // </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      </li>
                                    );}
                                    else{
                                      return null
                                    }
                                  })}
                                </ul>
                              </div>
                            );
                          })
                        : null}
                    </div> */}
                    </div>
                  </div>

                  {/* <div
                    onClick={this.guestDivClicked}
                    style={{
                      background: "#e6e7e8",
                      color: "#939598",
                      padding: "1px 180px 1px 1px",
                      marginTop: "4px",
                    }}
                  >
                    <label>Preview Header Options</label>
                  </div>

                  {this.state.display1 == true ? (
                    <div>
                      {this.state.guestOptions?.map((op, i) => {
                       
                        return (
                          <div
                            key={i}
                          
                            style={{
                              paddingBottom: "20px",
                              marginTop: "-10px",
                              color: "#939598",
                              background: "#e6e7e8",
                              display: "flex",
                              paddingLeft: "50px",
                              paddingRight: "140px",
                            }}
                          >
                            <input
                              onChange={(e) => {
                                this.selectedHeader(e, op, i);
                              }}
                              style={{
                                marginTop:
                                  op.name == "Number Of Guest" &&
                                  this.state.display2 == true
                                    ? "-191px"
                                    : "",
                              }}
                              className="HeaderObject"
                              checked={op.checked}
                              type="checkbox"
                              id={op.id}
                              name={op.id}
                              value={op?.name}
                              autoComplete="off"
                            />
                            <div
                              onClick={
                                op.name == "Number Of Guest"
                                  ? this.handleNumberOfGuest
                                  : undefined
                              }
                            >
                              <label htmlFor={op.id}>{op.name}</label>
                            </div>
                            {op?.name == "Number Of Guest" &&
                              this.state.display2 == true && (
                                <div
                                  style={{
                                    marginTop: "50px",

                                    marginLeft: "-120px",
                                  }}
                                >
                                  {op?.value?.map((o, index) => {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          paddingBottom: "20px",
                                          marginTop: "-10px",
                                          color: "#939598",
                                          background: "#e6e7e8",
                                          display: "flex",
                                        }}
                                      >
                                        <input
                                          checked={o.checked}
                                          type="checkbox"
                                          id={o?.id}
                                          name={o?.id}
                                          value={o?.name}
                                          onChange={(e) => {
                                            this.selectedGuest(e, o, index);
                                          }}
                                          autoComplete="off"
                                        />
                                        <label htmlFor={o?.id}>{o?.name}</label>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <></>
                  )} */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation() (withRouter(ExportSectionPopup));

const styles = {
  app: {
    backgroundColor: "white",
    justifyItems: "center",
    alignItems: "center",
    display: "grid",
    height: "100vh",
    color: "black",
    gridTemplateColumns: "1fr",
  },
  select: {
    width: "100%",
    maxWidth: "600",
  },
  outline: {
    color: "#297825",
    fontFamily: "Crete Round",
    fontSize: "13px",
    border: "1px solid #297825",
    minWidth: "143px",
    padding: "9px 15px",
    textAlign: "center",
    display: "inline-block",
    marginRight: "15px",
  },
  solid: {
    background: "#297825",
    fontFamily: "Crete Round",
    border: "1px solid #297825",
    color: "#fff",
    fontSize: "13px",
    minWidth: "143px",
    padding: "9px 15px",
    textAlign: "center",
    display: "inline-block",
    marginRight: "15px",
  },
  stepsContainer: {
    textAlign: "center",
  },
  headerImage: {
    width: "100%",
    height: "140px",
    objectFit: "cover",
  },
  input: {
    border: 0,
    padding: 0,
    margin: 0,
    background: "transparent",
    borderBottom: "1px solid #707070",
    width: "200px",
  },
  inputLabel: {
    fontFamily: "Crete Round",
    fontStyle: "Italic",
    color: "#95969b",
    fontSize: "15px",
    border: "none",
    // marginRight:"-10px"
  },
  inputWrapper: {
    // display: "inline-block",
    marginRight: "40px",
  },
  flexRow: {
    flexDirection: "column",
  },
  priceWrapper: {
    background: "#e6e7e8",
    right: "0",
    top: "0",
    display: "block",
    width: "360px",
    height: "100vh",
    transition: "0.3s",
    zIndex: "30",
  },
  themesWrapper: {
    background: "#f0eff0",
    height: "100vh",
  },
  // contentWrapper: {
  //     padding: "32px 0px"
  // },
  loader: {
    color: "#888888",
    fontWeight: "bold",
    fontSize: "20px",
    padding: "20px",
    textAlign: "center",
  },

  title: {
    fontFamily: "Crete Round",
    fontSize: "19px",
    color: "#000",
    margin: "0px",
  },
  bold: {
    fontSize: "16px",
    margin: "0px",
    color: "#000",
    fontWeight: "600",
  },
  text: {
    fontSize: "16px",
    fontWeight: "300",
    margin: "0px",
    color: "#000",
  },

  pointer: {
    cursor: "pointer",
  },
};

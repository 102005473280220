import React, { Component } from "react";
import { PriceType, PricingFunction } from "../../../../Constants";
import Text from "../../Text";
import checkIcon from "../../../../assets/check.png";

import { StateContext } from "../../../../context/state";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation} from 'react-i18next';
import Style from 'style-it';
import i18next from 'i18next';
 class RadioMultipleOption extends Component {
  static contextType = StateContext;
  pricingFunction;

  constructor(props) {
    super(props);
    this.state = {
      error: "",
      quantity: "",
      quantity2: "",
      textDisplay: true,
      displayError: false,
      displayErrorWhileRemoving: false,
    };
    this.pricingFunction = PricingFunction[props.priceType || PriceType.NONE];
  }

  onQuantityChange = (e) => {
    let key = Number(e.target.value);
    if (isNaN(key) || e.key === null || e.key === " ") return;
    const {
      id,
      price,
      numerically,
      stages,
      maximum,
      minimum,
      onUpdateWithoutAPICall,
      answer,
    } = this.props;
    const quantity = e.target.value;
    const { amount, error } = this.pricingFunction(
      Number(price) || 0,
      Number(quantity) || 0,
      Number(stages) || 0,
      Number(maximum) || 0,
      Number(minimum) || 0,
      numerically
    );
    let deadLineDate = new Date(this.context.deadlineForEntries);

    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) {
      return false;
    } else {
      this.setState({ error: error });
      const item = { id, quantity, amount, error };
      if (answer?.length) {
        const dummy = Object.assign([], answer);
        const findingElement = dummy.find((ans) => {
          return ans.id === id;
        });

        if (findingElement) {
          const dummy2 = dummy.filter((ans) => {
            return ans.id !== id;
          });
          dummy2.push(item);
          onUpdateWithoutAPICall(dummy2);
        } else {
          dummy.push(item);
          onUpdateWithoutAPICall(dummy);
        }
      } else {
        onUpdateWithoutAPICall([{ id, quantity, amount, error }]);
      }
    }
  };
  QuantityZero = (e) => {
   
    let deadLineDate = new Date(this.context.deadlineForEntries);

    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) {
      return false;
    } else {
      let key = Number(e.target.value);
      if (isNaN(key) || e.key === null || e.key === " ") return;
      const {
        id,
        onUpdateWithoutAPICall,
        answer,
      } = this.props;
      const quantity2 = e.target.value;
      this.setState({ quantity2: quantity2 });
      const { quantity } = answer ? answer : 0;

      const item = { id, quantity2, quantity };
      if (answer?.length) {
        const dummy = Object.assign([], answer);
        const findingElement = dummy.find((ans) => {
          return ans.id === id;
        });

        if (findingElement) {
          const dummy2 = dummy.filter((ans) => {
            return ans.id !== id;
          });
          dummy2.push(item);
          onUpdateWithoutAPICall(dummy2);
        } else {
          dummy.push(item);
          onUpdateWithoutAPICall(dummy);
        }
      } else {
        onUpdateWithoutAPICall([{ id, quantity, quantity2 }]);
      }
    }
  };
  onSelect = (e) => {
   // e.preventDefault();
    e.stopPropagation();
    const {
      id,
      price,
      priceType,
      answer,
      onUpdate,
      min,
      maxLimit
    } = this.props;
   
    const quantity = priceType === "number-guests" ? this.props.selectedGuestCount : 0;
    const quantity2 = answer?.quantity2;
    let amount = undefined;
    let deadLineDate = new Date(this.context.deadlineForEntries);

    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) {
      return false;
    }
    
    else {
      if (priceType === PriceType.FLAT_RATE) amount = Number(price);
      const item = { id, quantity, amount };
      if (answer?.length) {
        const dummy = Object.assign([], answer);
      
        const findingElement = dummy.find((ans) => {
          return ans.id === id;
        });
       
        if (findingElement) {
          const dummy2 = dummy.filter((ans) => {
            return ans.id !== id;
          });
          
           if(maxLimit && dummy2.length>=maxLimit)
           {
            
              toast.error(i18next.t(`max_selection`,{maxUnit:maxLimit}), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
             return;
           }
           else{
            onUpdate(dummy2);
          }
         
          if (dummy2.length < min) {
            toast.error(`${this.props.t('Bitte wählen Sie mindestens')} ${min} ${this.props.t('Möglichkeiten')}.`, {
              position: "top-center",
              // autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // dummy2.push(opt)
            // onUpdate(dummy2);
            this.setState({
              displayErrorWhileRemoving: true,
              displayError: false,
              error: `${this.props.t('Bitte wählen Sie mindestens')} ${min} ${this.props.t('Möglichkeiten')}.`,
            });
          }
          // console.log("displayErrorWhileRemoving",this.state.displayErrorWhileRemoving)
          // if (this.state.displayErrorWhileRemoving == false) {
          //   // const dummy2 = dummy.filter((ans) => {
          //   //   return ans.id !== id;
          //   // });
          //   onUpdate(dummy2);
          // }
        } else {
         
          if(maxLimit && dummy.length>=maxLimit)
          {
           
            toast.error(i18next.t(`max_selection`,{maxUnit:maxLimit}) , {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return;
          }
          else{
           dummy.push(item);
           onUpdate(dummy);
          }    
          // console.log("in else",dummy)
          // if (dummy.length > max) {
          //   toast.error(`Bitte wählen Sie maximal ${max} Möglichkeiten`, {
          //     position: "top-center",
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //   });
          //   dummy.pop();
          //   this.setState({
          //     displayError: true,
          //     displayErrorWhileRemoving: false,
          //     error: `Bitte wählen Sie maximal ${max} Möglichkeiten`,
          //   });
          // }
          // if (this.state.displayError == false) {
          //   onUpdate(dummy);
          // }
        }
      } else {
        onUpdate([{ id, quantity, amount, quantity2 }]);
      }
    }
    
  };

  renderPriceTypeComponent = () => {
    const { priceType, price } = this.props;
    const textColor =this.context.stylingData?.textColor? this.context.stylingData?.textColor : "#black";
    const val = this.findingFunction();
    // const quantity = val
    //   ? val.quantity
    //   : priceType === "number-guests"
    //   ? this.props.selectedGuestCount
    //   : "";
    let quantity
      if(val){
        if( priceType !== "number-guests"){
          quantity=val.quantity
        }
        else{
          if(this.props.selectedGuestCount){
            quantity="";
            // quantity=this.props.selectedGuestCount
          }
          else{
            quantity=""
          }
        }
      }
      let quantity2
      if(val){
        if( priceType !== "number-guests"){
          quantity2=val.quantity2
        }
        else{
          if(this.props.selectedGuestCount){
             quantity2 = "";
            // quantity=this.props.selectedGuestCount
          }
          else{
            quantity2=""
          }
        }
      }
    // const quantity2 = val ? val.quantity2 : "";
    // console.log('quantity', val, quantity)
    let placeholder;
    switch (priceType) {
      case PriceType.NUMBER_OF_GUESTS:
        placeholder = `€ ${price} x Gäste`;
        break;
      case PriceType.NUMERICALLY:
        placeholder = this.props.t("Geben Sie den Wert ein");
        break;
      case PriceType.FLAT_RATE:
      default:
        placeholder = undefined;
        break;
    }

    if (placeholder) {
      return (
        <>
          {price === 0 ? (
            <input
            style={this.props.selectedGuestCount ? styles.inputHidden : styles.input}
              value={quantity2}
              // placeholder={placeholder}
              placeholder={this.props.t('bitte_eintragen')}
              onKeyDown={(e) => {
                if (e.key === "Enter") this.onEnterHitWhenZero(quantity2);
              }}
              maxLength={10}
              onChange={this.QuantityZero}
              autoComplete="off"
            />
          ) : (
            priceType !== "number-guests" && <Style>
            {`
      #placeHolder::placeholder {
      color: ${textColor};    
     }`}
     <div>
            <input
            style={this.props.selectedGuestCount ? styles.inputHidden :  {...styles.input,color:textColor}}
              // placeholder={placeholder}
              id="placeHolder"
              placeholder={this.props.t('bitte_eintragen')}
              // value={
              //   this.props.selectedGuestCount
              //     ? this.props.selectedGuestCount
              //     : quantity
              // }
              value={
                this.props.selectedGuestCount
                  ? quantity
                  : quantity
              }
              maxLength={10}
              onChange={this.onQuantityChange}
              // disabled={this.props.selectedGuestCount ? true : false}
              onKeyDown={(e) => {
                let deadLineDate = new Date(this.context.deadlineForEntries);

                if (
                  deadLineDate.toDateString() !== new Date().toDateString() &&
                  moment(this.context.deadlineForEntries).isBefore(
                    new Date()
                  ) &&
                  this.context.userRole === "CU"
                ) {
                  e.preventDefault();
                } else {
                  if (e.key === "Enter") this.onEnterHit(quantity);
                  // if (e.key === "Backspace") return;
                  // let key = Number(e.key);
                  // if (isNaN(key) || e.key === null || e.key === " ")
                  //   e.preventDefault();
                }
              }}
              autoComplete="off"
            />
             </div>
            </Style>
          )}
        </>
      );
    } else return null;
  };
  onEnterHit = (quantity) => {
    console.log("quantity",quantity)
    const {
      id,
      price,
      numerically,
      stages,
      maximum,
      minimum,
      onUpdate,
      onUpdateWithoutAPICall,
      answer,
    } = this.props;

    const { amount, error } = this.pricingFunction(
      Number(price) || 0,
      Number(quantity) || 0,
      Number(stages) || 0,
      Number(maximum) || 0,
      Number(minimum) || 0,
      numerically
    );
    // console.log('hi', quantity, answer)
    const item = { id, quantity, amount, error };
    if (answer?.length) {
      const dummy = Object.assign([], answer);
      const findingElement = dummy.find((ans) => {
        return ans.id === id;
      });

      if (findingElement) {
        const dummy2 = dummy.filter((ans) => {
          return ans.id !== id;
        });
        dummy2.push(item);
        !error ? onUpdate(dummy2) : onUpdateWithoutAPICall(dummy2);
      } else {
        dummy.push(item);
        !error ? onUpdate(dummy) : onUpdateWithoutAPICall(dummy);
      }
    } else {
      !error
        ? onUpdate([{ id, quantity, amount, error }])
        : onUpdateWithoutAPICall([{ id, quantity, amount, error }]);
    }
    // console.log("error",this.state.error)
    if(this.state.error){
      toast.error(this.state.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
   
  };
  componentDidMount() {
    if (window.innerWidth > 1094) this.setState({ textDisplay: false });
    window.addEventListener("resize", () => {
      if (window.innerWidth > 1094) this.setState({ textDisplay: false });
      else this.setState({ textDisplay: true });
    });
  }
  onEnterHitWhenZero = (quantity2) => {
    const {
      id,
      onUpdate,
      answer,
    } = this.props;
    const { quantity } = answer;

    let deadLineDate = new Date(this.context.deadlineForEntries);

    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) {
      return false;
    } else {
      const item = { id, quantity2, quantity };
      if (answer?.length) {
        const dummy = Object.assign([], answer);

        const findingElement = dummy.find((ans) => {
          return ans.id === id;
        });
        if (findingElement) {
          const dummy2 = dummy.filter((ans) => {
            return ans.id !== id;
          });
          dummy2.push(item);
          onUpdate(dummy2);
        } else {
          dummy.push(item);
          onUpdate(dummy);
        }
      } else {
        onUpdate([{ id, quantity2, quantity }]);
      }
    }
  };
  findingFunction = () => {
    const { id, answer } = this.props;
    if (answer?.length) {
      const dummy = Object.assign([], answer);
      const findingElement = dummy.find((ans) => {
        return ans.id === id;
      });
      if (findingElement) {
        return findingElement;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  findMatching = (guestInfo) => {  
    const {GuestInfoLabel} = this.props; 
    const result = [];
    for (var i = 0; i < guestInfo.length; i++) {
      var info = guestInfo[i];
      switch (info) {
        case "adults":
          var priceAdult;
          if(GuestInfoLabel.pricing?.[0]?.price ){
            priceAdult= ` ${Number(GuestInfoLabel.pricing?.[0]?.price).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} ${this.context.currencyFormat}`;
          }
          else
          {
            priceAdult="";
          } 
          result.push(` ${this.props.t('Per')} ` + `${this.props.t('Gast')}`+ priceAdult);
          break;
        case "U6":
          var priceU6;
          if(GuestInfoLabel.pricing?.[3]?.price ){
            priceU6= ` ${Number(GuestInfoLabel.pricing?.[3]?.price).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} ${this.context.currencyFormat}`;
          }
          else
          {
            priceU6="";
          }
          result.push(` ${this.props.t('Per')} `+ `${this.props.t('Gast')}`+ priceU6);
          break;
        case "U12":
          var priceU12;
          if(GuestInfoLabel.pricing?.[1]?.price ){
            priceU12= ` ${Number(GuestInfoLabel.pricing?.[1]?.price).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} ${this.context.currencyFormat}`;
          }
          else
          {
            priceU12="";
          }
          result.push(` ${this.props.t('Per')} `+ `${this.props.t('Gast')}`+ priceU12);
          break;
        case "U18":
          var priceU18;
          if(GuestInfoLabel.pricing?.[2]?.price ){
            priceU18= ` ${Number(GuestInfoLabel.pricing?.[2]?.price).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} ${this.context.currencyFormat}`;
          }
          else
          {
            priceU18="";
          }
          result.push(` ${this.props.t('Per')} `+ `${this.props.t('Gast')}`+ priceU18);
          break;
        default:
          break;
      }        
    } 
    return result;
  }
  
  render() {
    // const notify = () => toast(this.state.error);
    let { value, priceType, numerically, price, answer, id } = this.props;
    const textFontFamily = this.context.stylingData?.textFontFamily;
    const val = this.findingFunction();
    const quantity2 = val ? val.quantity2 : "";
    const textFontSize = this.context.stylingData?.textFontSize;
    const headingColor = this.context.stylingData?.textColor ? this.context.stylingData?.textColor :"#939598";
    const lineColor= this.context.stylingData?.lineColor?this.context.stylingData?.lineColor:"rgb(41, 120, 37)";
    // const circleStyle = { ...styles.circle, ...selectedStyle };
    const svgDiv= this.findingFunction() ? `<svg width="24" height="24"  style="fill: ${lineColor};" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"/></svg>` :"";
    const circleStyle = { ...styles.circle, svgDiv };
    
    const dummy = Object.assign([], answer);
    const findingElement = dummy.find((ans) => {
      return ans.id === id;
    });
    return (
      <div
        style={{
          fontSize: `${textFontSize}px`,
          // paddingLeft:"10px",
          //  display: "flex",
          // flexDirection: "column",
          // padding: "0px",
          // paddingTop:"0px"
        }}
      >
        {(findingElement && this.state.textDisplay) ||
        this.state.displayError ||
        this.state.displayErrorWhileRemoving ? (
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        ) : (
          <></>
        )}
        <div style={styles.labelContainer}>
          <div  dangerouslySetInnerHTML={{ __html: svgDiv }} style={{...circleStyle,border: `1px solid ${lineColor}`}} onClick={this.onSelect}/>
          <div style={ {padding: "0", color:`${headingColor}`, margin: "0 0 0 20px"}}>{value}</div>
          {priceType === "flat-rate" ? (
            <>
              {priceType === PriceType.NUMERICALLY ? (
                price === 0 ? (
                  <input
                    style={styles.input2}
                    value={quantity2}
                    // placeholder={placeholder}
                    placeholder={this.props.t('bitte_eintragen')}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") this.onEnterHitWhenZero(quantity2);
                    }}
                    maxLength={10}
                    onChange={this.QuantityZero}
                    autoComplete="off"
                  />
                ) : (
                  <Text
                    fontSize={textFontSize}
                    fontFamily={textFontFamily}
                    // style={styles.unit}
                    style={{
                      fontSize: `${textFontSize}`,
                      fontFamily: `${textFontFamily}`,
                      // padding: "10",
                      margin: "0 0 0 10px",
                      padding: "0",

                      // paddingTop: "5px",
                      color: "#939598",
                    }}
                    value={`(${this.props.t('flat_rate')} 
                    ${this.context.languageFormat==="en"? `${Number(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :  `${Number(price)
                      .toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`} ${this.context.currencyFormat}${
                      numerically?.unit ? ` ${numerically.unit}` : ""
                    })`}
                  />
                )
              ) : price === 0 ? (
                <input
                  style={styles.input2}
                  value={quantity2}
                  // placeholder={placeholder}
                  placeholder={this.props.t('bitte_eintragen')}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") this.onEnterHitWhenZero(quantity2);
                  }}
                  maxLength={10}
                  onChange={this.QuantityZero}
                  autoComplete="off"
                />
              ) : (
                price !== 0 && (
                  <Text
                    fontSize={textFontSize}
                    fontFamily={textFontFamily}
                    style={{
                      fontSize: `${textFontSize}`,
                      fontFamily: `${textFontFamily}`,
                      margin: "0 0 0 10px",
                      padding: "0",

                      // margin: "0 0 0 0px",
                      // padding: "10",
                      //  paddingTop: "5px",
                      color: "#939598",
                    }}
                    value={
                      price &&
                      `( ${
                        numerically?.unit? ` ${numerically.unit}` : 
                        `${this.props.t('flat_rate')}`
                       } 
                       ${this.context.languageFormat==="en"? `${Number(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :  `${Number(price)
                        .toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`} ${this.context.currencyFormat})`
                    }
                  />
                )
              )}
            </>
          ) : (
            <>
              {priceType === PriceType.NUMERICALLY
                ? price !== 0 && (
                    <Text
                      fontSize={textFontSize}
                      fontFamily={textFontFamily}
                      //  style={styles.unit}
                      style={{
                        fontSize: `${textFontSize}`,
                        fontFamily: `${textFontFamily}`,
                        margin: "0 0 0 10px",
                        padding: "0",

                        // margin: "0 0 0 0px",
                        // padding: "10px",
                        // paddingTop: "5px",
                        color: "#939598",
                      }}
                      value={`(
                        ${this.context.languageFormat==="en"? `${Number(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :  `${Number(price)
                          .toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`} ${this.context.currencyFormat} ${
                        numerically?.unit ? ` ${numerically.unit}` : ""
                      })`}
                    />
                  )
                : price !== 0 && (
                    <Text
                      fontSize={textFontSize}
                      fontFamily={textFontFamily}
                      style={{
                        fontSize: `${textFontSize}`,
                        fontFamily: `${textFontFamily}`,
                        margin: "0 0 0 10px",
                        padding: "0",

                        //   margin: "0 0 0 0px",
                        //  padding: "10px",
                        //  paddingTop: "5px",
                        color: "#939598",
                      }}
                      value={
                        price &&
                        `(${this.context.languageFormat==="en"? `${Number(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :  `${Number(price)
                          .toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`} ${this.context.currencyFormat}  ${
                            numerically?.unit? ` ${numerically.unit}` : 
                            `${this.props.t('Per')} ${this.props.t('Gast')}`
                           })`
                      }
                    />
                  )}
            </>
          )}
        </div>
        {this.renderPriceTypeComponent()}
        <br />
        {/* {(findingElement && this.state.textDisplay )?
      <span  style={{color:"red",fontSize: "16px", margin: "0px 0px 10px", fontWeight: "600"}}>
        {this.state.error?this.state.error:""}
        </span>
        :
      <></>
    }   */}
      </div>
    );
  }
}

export default withTranslation() (RadioMultipleOption);
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    // padding: "10px",
  },
  labelContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // top: "-3px",
    position: "relative",
    // padding: "10px 0",
  },
  circle: {
    minWidth: "24px",
    minHeight: "24px",
    display: "inline-block",
    textAlign: "center",
    background: "transparent",
    // borderRadius: "12px",
    border: "1px solid #297825",
    outline: "none",
    cursor: "pointer",
  },
  selected: {
    background: `url(${checkIcon})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    // width: "24px",
    // height: "24px",
  },
  label: {
    padding: "0",
    color: "#939598",
    margin: "0 0 0 20px",
    // fontSize: "16px",
    // fontFamily: "Crete Round",
  },
  unit: {
    margin: "0 0 0 10px",
    padding: "0",
    color: "#939598",
    fontSize: "16px",
    // fontFamily: "Crete Round",
  },
  input: {
    border: 0,
    padding: 0,
    // margin: "0 0 20px 0",
    margin: "0 0px 20px 44px",
    background: "transparent",
    borderBottom: "1px solid #707070",
    width: "200px",
  },
  inputHidden : {
    display:"none",
  },

  input2: {
    border: 0,
    padding: 0,
    // margin: "0 0 20px 0",
    margin: "0 0px 20px 44px",
    background: "transparent",
    borderBottom: "1px solid #707070",
    width: "200px",
  },
};

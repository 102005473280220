import React, { Component } from 'react';
import home from '../../assets/home.svg'
import back from '../../assets/collapse.svg'
import print from '../../assets/print.svg'
import pdf from '../../assets/pdf.svg'
import link2 from '../../assets/link2.svg'
import ExportSectionPopup from '../partials/x'
import ReactToPrint from 'react-to-print';
import ReactToPdf from 'react-to-pdf';
import {StateContext} from "../../context/state";
import axios from "axios";
import {baseUrl} from '../../Constants.js'
import { BlobProvider
  // ,
  //  PDFDownloadLink, Document, Page 
  } from '@react-pdf/renderer'
import PlanChild from './planChild';

const data={
    "eventDate":"24-dec-2020",
    "eventCustomer":"dummy",
    "guests":128,
    "VVO":"nadine"
}

const ref = React.createRef();
const options = {
  orientation: 'landscape',
  unit: 'in',
  format: [4,2]
};
const att=[]
class PlanPreview extends Component {
  static contextType = StateContext;
    constructor(){
        super()
        this.state={
            event:{},
            showExportPopup:false,
            themes:[],
            attachments:[]
        }
    }
    componentDidMount(){
      this.context.updateState({attachments:[]})
      axios.get(`${baseUrl}api/export/${this.context.questionnaireId}/${this.context.exportPlanId}`)
      .then((res)=>{
        // console.log('res preview', res.data.themes)
          this.setState({
          event:data,
          themes:res.data?.themes
        })
      }) 
      .catch((err)=>{
        console.log(err)
      }) 
     
    }

    handleMailPDF=(data)=>{
      console.log('attachments', data)

    }
    handleBackClick=()=>{
        this.setState({
            showExportPopup:!this.state.showExportPopup
        })
    }
    closeExportPopup = () => this.setState({ showExportPopup: false });
    // handleOnFocus=(themeId, divId)=>{
    //   const themes=this.state.themes
    //   for(let i of themes){
    //     if(i.themeId===themeId){
    //       for(let j of i.divs){
    //         if(j.divId===divId){
    //          j["hideToolbar"]=false
    //         }
    //       }
    //     }
    //   }
     
    //   this.setState({themes})
    // }
    // handleOnBlur=(themeId, divId)=>{
    //   const themes=this.state.themes
    //   for(let i of themes){
    //     if(i.themeId===themeId){
    //       for(let j of i.divs){
    //         if(j.divId===divId){
    //           j["hideToolbar"]=true
    //         }
    //       }
    //     }
    //   }
     
    //   this.setState({themes})
    // }

//     onEditorStateNoteChange=(themeId, divId, editorState)=>{
//   // console.log("editor",themeId, divId,editorState)
//   const copy=Object.assign([], this.state.themes)
//     for(let i of copy){
//       if(i.themeId===themeId){
//         for(let j of i.divs){
//           if(j.divId===divId){
//             j.editorStateNote=editorState
//           }
//         }
//       }
//     }
//   this.setState({
//     theme:copy
//   });
// };
setTextInputValue = (themeId ,id, e) => {
  const copy=Object.assign([], this.state.themes)
  for(let i of copy){
    if(i.themeId===themeId){
      for(let j of i.divs){
        if(j.divId===id){
          j[e.target.name]=e.target.value
        }
      }
    }
  }
  this.setState({ themes:copy });
};


      updateAttachments=(image)=>{
        if(att.length){
              const newDat=att.find((urls)=>{
                  return urls.url===image.url
              })
        if(!newDat){
            att.push(image)
          }
      }
      else{
        att.push(image)
      }
    }
    handleCall=(b,u,l,e)=>{
        console.log('blob,', b,u,l, e)
    }
    render() {
        return (
            <div>
               <div className="plan_preview_nav">

                   <div className="row preview_nav">
                   <div className="col-2" >
                        <img src={home} className="home_img" alt="back" onClick={()=>{this.props.history.push("/admin/plans")}}/>
                   </div>
                   <div className="col-6">
                        <div className="plan_preview_info">
                            <span>{`${this.state.event.eventDate} |  
                                ${this.state.event.eventCustomer}  |  
                                ${this.state.event.guests}  |  
                                ${this.state.event.VVO}`}
                                </span>
                        </div>
                   </div>
                   <div className="col-4">
                   <ReactToPdf 
                      targetRef={ref} 
                      onComplete={this.handleMailPDF} 
                      filename="plans.pdf"
                      options={options}
                      >
                      {({toPdf}) => (
                          <button className="download" onClick={toPdf}>
                            {/* {console.log("pdf", pdf)} */}
                          
                            <img src={link2} alt=""  />
                          </button>
                      )}
                    </ReactToPdf>
                   {/* <button className="download" onClick={this.handleMailPDF}>
                        <img src={link2} alt=""  />
                   </button> */}
                  
                   
                   <ReactToPdf targetRef={ref} filename="plan.pdf">
                    {({toPdf}) => (
                        <button className="download" onClick={toPdf}>
                          <img src={pdf} alt="" />
                        </button>
                    )}
                    </ReactToPdf>

                    <ReactToPrint
                    trigger={() => <button className="download"><img src={print}  alt="" /></button>}
                    content={() => ref.current}
                    />
                   
                   </div>
                   </div>
               </div>
               <img src={back} className="back_to_plan" alt="" onClick={this.handleBackClick}/>
               {this.state.showExportPopup ? (
                    <ExportSectionPopup
                        closeExportPopup={this.closeExportPopup.bind(this)
                       
                        }
                        data={this.state.themes}
                    />
                ) : null}
              
               <div style={{width:"50%", marginLeft:"150px"}}  ref={ref}>
                        {/* <PlanChild/> */}
               </div>
               
               <div>
    {/* <PDFDownloadLink document={<PlanChild/>} fileName="somename.pdf">
        
      {({ blob, url, loading, error }) => 
        this.handleCall(blob, url, loading, error)
        // (loading ? 'Loading document...' : `${url}`)
        }
    </PDFDownloadLink> */}
     <BlobProvider document={<PlanChild/>}>
      {({ blob, url, loading, error }) => {
        console.log('blob provider', blob, url,loading,error)
        return <div>There's something going on on the fly</div>
      }}
    </BlobProvider>
  </div>
            </div>
        );
    }
}

export default PlanPreview;
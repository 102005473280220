import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import optionIcon from "../../assets/option.svg";
import optionFilledIcon from "../../assets/option-filled.svg";
import deleteIcon from "../../assets/delete.svg";
import Undo from "../../assets/undo.png";
import closeIcon from "../../assets/cross-small.svg";
import linkIcon from "../../assets/link.svg";
import { convertToRaw, convertFromRaw } from "draft-js";
import { ChromePicker } from "react-color";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { StateContext } from "../../context/state";
import { withTranslation } from 'react-i18next';
import axios from "axios";
import { baseUrl } from "../../Constants";

class TextOptionComponent extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.messagesRef=React.createRef(null);
    this.state = {
      textInput: {
        inputs: [
          {
            id: 1,
            interactive: false,
            numeric: false,
            stages: "",
            maximum: "",
            minimum: "",
            value: EditorState.createEmpty(),
            interactiveText: "",
            hideTextToolbar: true,
            price: "",
            priceType: "",
            priceTypeId: null,
            tags: [],
            numerically: {
              minimumNumber: "",
              minimumNumberCalculate: false,
              maximumNumber: "",
              maximumNumberCalculate: false,
              stage: "",
              relay1: "",
              relay1Alle: false,
              relay1Price: "",
              relay2: "",
              relay2Alle: false,
              relay2Price: "",
              relay3: "",
              relay3Alle: false,
              relay3Price: "",
              unit: "",
            },
          },
        ],
        boxColor: "",
      },
      selectedTextIndex: null,
      showColorPicker: false,
      ripStyles: false,
      textInputSelected:false,
      languageFormat:""
    };
  }

  componentDidMount = () => {
    // console.log('text', this.props)
    const data = this.context;
    axios
    .get(`${baseUrl}api/venue/${data.venueId}`)
    .then((res) => {
      this.setState({languageFormat: res.data?.languageFormat})
    })
    .catch((error) => {
      console.log(error);
    });
 
    if (this.props.textInput) {
      // console.log("textinput",this.props.textInput)
      // this.props.updateSelectedOptionForText({ textInput: this.state.textInput });
    }
    // document.addEventListener("onBlur", this.handleOnBlur);
  };

  static getDerivedStateFromProps = (nextProps, prevState) => {
    // console.log('nexInput', nextProps.selectedTextIndex)
    // console.log("textinput",nextProps.textInput)
    if (nextProps.textInput !== undefined) {
      return {
        selectedTextIndex: nextProps.selectedTextIndex,
        textInput: nextProps.textInput,
      };
    } else {
      return {
        selectedTextIndex: nextProps.selectedTextIndex,
      };
    }
  };

  handleOnFocus = (e, id, index) => {
    // e.preventDefault()
    // e.stopImmediatePropagation()
    // console.log('focus', id)
    const textInput = Object.assign({}, this.state.textInput);
    for (let i of this.state.textInput.inputs) {
      if (i.id === id) {
        if (i.numeric === false && !i.interactive) {
          i["hideTextToolbar"] = false;
        }
      }
    }
    this.setState({ textInput ,textInputSelected:true});
    // console.log('selected index', index)
    // this.props.updateSelectedOptionForText({ textInput: this.state.textInput });
    this.props.updateSeletedTextIndex(index);
    this.props.setOptionSelected(
      this.props.index,
      "text",
      null,
      { textInput: textInput },
      null,
      index
    );
  };
  handleOnBlur = (e, id, index) => {
    e.preventDefault();
    // console.log('blur', id)
    const textInput = Object.assign({}, this.state.textInput);
    for (let i of this.state.textInput.inputs) {
      if (i.id === id) {
        i["hideTextToolbar"] = true;
      }
    }
  
    this.setState({ textInput ,textInputSelected: false});
    // this.props.updateSeletedTextIndex(index)
    // this.props.setOptionSelected(this.props.index, "text", null, {textInput:textInput},null, index);
  };

  addText = () => {
    const textInput = this.state.textInput;
    textInput.inputs.push({
      id: textInput.inputs.length + 1,
      interactive: false,
      numeric: false,
      stages: "",
      maximum: "",
      minimum: "",
      value: EditorState.createEmpty(),
      interactiveText: "",
      hideTextToolbar: true,
      price: "",
      priceType: "",
      priceTypeId: null,
      tags: [],
      numerically: {
        minimumNumber: "",
        minimumNumberCalculate: false,
        maximumNumber: "",
        maximumNumberCalculate: false,
        stage: "",
        relay1: "",
        relay1Alle: false,
        relay1Price: "",
        relay2: "",
        relay2Alle: false,
        relay2Price: "",
        relay3: "",
        relay3Alle: false,
        relay3Price: "",
        unit: "",
      },
    });
    this.setState({ textInput });
    // this.props.updateSelectedOptionForText({ textInput: this.state.textInput });
  };

  removeText = (index) => {
    // console.log('index', index)
    const textInput = this.state.textInput;
    textInput.inputs.splice(index, 1);
    const selectedTextIndex =
      this.state.selectedTextIndex === null ? null : index - 1;
    this.setState({ textInput, selectedTextIndex });
    this.props.updateSeletedTextIndex(index === 0 ? 0 : index - 1);
    // this.props.updateSelectedOptionForText({ textInput: this.state.textInput });
  };

  setQuestion = (e) => {
    const textInput = this.state.textInput;
    textInput.question = e.target.value;
    this.setState({ textInput });
    this.props.toggleTextOptionConfiguration(
      0,
      this.state.textInput,
      this.props.index
    );
  };

  handleRipStyles = (index) => {
    const textInput = this.state.textInput;
    let obj = Object.assign({}, textInput.inputs[index]);
    // console.log("obj", obj);
    if (!obj.value.blocks) {
      let dummyText = convertToRaw(obj.value.getCurrentContent());
      // console.log("dummyText", dummyText);
      let text = "";
      dummyText.blocks.map((block)=>{
       return text=`${text} ${block.text} \n`
       
      })
      // console.log("text",text)
      text=text.trim()
      let dummy = EditorState.createWithContent(
        ContentState.createFromText(text)
      );
      let dummy2 = convertToRaw(dummy.getCurrentContent());
      // console.log("dummy",dummy2)
      textInput.inputs[index].value = dummy2;
      //  console.log("textinput",textInput)

      this.setState({ textInput, ripStyles: true });
    }
  };
  setTextInputValue = (index, e) => {
    const textInput = this.state.textInput;
    textInput.inputs[index].value = e;

    this.setState({ textInput });
  };

  showOptionComponent = (index) => {
    this.props.toggleTextOptionConfiguration(
      index,
      this.state.textInput,
      this.props.index
    );
    this.setState({
      selectedTextIndex: index,
    });
    // this.props.updateSelectedOptionForText({ textInput: this.state.textInput });
  };
  // handleTextClicked=(index)=>{
  //   console.log('text clicked', this.props.index)
  //   this.props.updateSeletedTextIndex(index)
  //   this.props.setOptionSelected(this.props.index, "text", null, {textInput:this.state.textInput}, null, index);
  // }
  handleTextAltChange = (e, index) => {
    const textInput = this.state.textInput;
   // console.log('change', typeof(textInput.inputs[index].interactive))
    textInput.inputs[index].interactiveText = e.target.value;
    this.setState({ textInput });
  };
  functionValidation = (input) => {
    // console.log("input", input);
    const min = Number(input.numerically.minimumNumber);
    const max = Number(input.numerically.maximumNumber);
    const price = Number(input.price);

    if (min && max && min > max) {
      const msg = "Der Maximalwert sollte größer als der Minimalwert sein";
      return msg;
    }
    if (min && max && !price) {
      const msg = "Preis eingeben";
      return msg;
    } else if (min && !price) {
      const msg = "Preis eingeben";
      return msg;
    } else if (max && !price) {
      const msg = "Preis eingeben";
      return msg;
    } else {
      return null;
    }
  };
  showLinkComponent = (e) => {
    e.stopPropagation();
    this.props.toggleLinkOption(null, null, this.props.index);
  };
  handleShowTextColorPicker = () => {
    this.setState({
      showColorPicker: !this.state.showColorPicker,
    });
  };
  closeColourPicker = () => {
    this.setState({
      showColorPicker: false,
    });
  };
  handleUpdateColor = (color) => {
    const dummy = this.state.textInput;
    dummy.boxColor = color;
    this.setState({ textInput: dummy });
  };
  removeColor = () => {
    const dummy = this.state.textInput;
    dummy.boxColor = "";
    this.setState({ textInput: dummy });
  };
  handleLanguageForEditor=()=>{ 
   const lang= this.state.languageFormat
    switch (lang) {
      case 'de':
        return 'de';
      case 'en':
        return 'en';
      case 'se':
        return 'es';
      default:
        return lang;
    }
  }
  render() {
  
    const editorLabels = {
      "components.controls.link.linkTitle": "Link Text",
      "components.controls.link.linkTarget": "Link",
      "components.controls.link.linkTargetOption": "In neuem Fenster öffnen ",
    };
 
    return (
      <div
        className="text-option-component"
        style={{
          border: "2px dashed #bcbec0",
          padding: "15px 37px 10px 10px",
          textAlign: "left",
          background:this.props.layoutObject[this.props.index[0]]?.row[this.props.index[1]]?.column[this.props.index[2]]?.selected===true ? "#aaacaf":""
        }}
      >
        <button
          className="template-link-btn"
          onClick={(e) => {
            this.showLinkComponent(e);
          }}
        >
          <img src={linkIcon} alt="" />
        </button>
        {/* {console.log('text render', this.state.textInput)} */}
        {/*<input
          type="text"
          className="question"
          placeholder="Wie viele möchten Sie haben?"
          value={this.state.textInput.question}
          onChange={this.setQuestion}
        />*/}
        {this.state.textInput.inputs.map((input, index) => {
          return (
						<div className="text-container" key={input.id}>
							{/* <input
              onClick={this.handleTextClicked}
                type="text"
                placeholder="Text einfügen"
                className="text-input"
                onFocus={() => this.props.updateSeletedTextIndex(index)}
                value={input.value}
                onChange={e => this.setTextInputValue(index, e)}
              /> */}
							<div
								className="text-input"
								style={{
									background: this.state.textInput.boxColor
										? this.state.textInput.boxColor
										: '#e6e7e8',
								}}>
								{/* {console.log(convertToRaw(input.value.getCurrentContent()))} */}
								{/* <Editor
                editorState={input.value}
                style={{marginRight:"15px"}}
                toolbarOnFocus={!input.hideTextToolbar}
                toolbarHidden={input.hideTextToolbar}
                // customStyleMap={styleMap}
                // onClick={this.handleTextClicked}
                // toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                // editorClassName="editorClassName"
                onFocus={(e) => {
                  this.handleOnFocus(e, input.id, index)
                }}
                onBlur={(e) => {
                  this.handleOnBlur(e,input.id, index)
                }}
                // onFocus={() =>this.handleTextClicked(index) }
                onEditorStateChange={e => this.setTextInputValue(index, e)}
              /> */}

								{Object.keys(input.value).includes('blocks') ? (
									<Editor
										localization={{
											locale: this.handleLanguageForEditor(),
											translations: editorLabels,
										}}
										editorState={EditorState.createWithContent(
											convertFromRaw(input.value)
										)}
										toolbarOnFocus={!input.hideTextToolbar}
										toolbarHidden={input.hideTextToolbar}
										wrapperClassName={{ backgroundColor: 'red' }}
										onFocus={(e) => {
											this.handleOnFocus(e, input.id, index);
										}}
										onBlur={(e) => {
											this.handleOnBlur(e, input.id, index);
										}}
										onEditorStateChange={(e) =>
											this.setTextInputValue(index, e)
										}
										placeholder={
											input.interactive === true &&
											this.props.t('Platzhaltertext einfügen')
										}
										toolbar={{
											fontFamily: {
												options: [
													'Arial',
													'Georgia',
													'Impact',
													'Tahoma',
													'Times New Roman',
													'Verdana',
													'Roboto',
												],
												className: undefined,
												component: undefined,
												dropdownClassName: undefined,
											},
										}}
									/>
								) : (
									<Editor
										localization={{
											locale: this.handleLanguageForEditor(),
											translations: editorLabels,
										}}
										// localization={{ locale:"de", translations: editorLabels }}
										editorState={input.value}
										style={{ marginRight: '15px' }}
										toolbarOnFocus={!input.hideTextToolbar}
										// toolbarHidden={input.hideTextToolbar}
										wrapperClassName="wrapperClassName11"
										onFocus={(e) => {
											this.handleOnFocus(e, input.id, index);
										}}
										onBlur={(e) => {
											this.handleOnBlur(e, input.id, index);
										}}
										onEditorStateChange={(e) =>
											this.setTextInputValue(index, e)
										}
										placeholder={
											input.interactive === true &&
											this.props.t('Platzhaltertext einfügen')
										}
										toolbar={{
											fontFamily: {
												options: [
													'Arial',
													'Georgia',
													'Impact',
													'Tahoma',
													'Times New Roman',
													'Verdana',
													'Roboto',
												],
												className: undefined,
												component: undefined,
												dropdownClassName: undefined,
											},
										}}
									/>
								)}
							</div>

							<div style={{ position: 'absolute', right: '44px', top: '5px' }}>
								{input.interactive === false ? (
									<OverlayTrigger
										key="bottom"
										placement="bottom"
										overlay={
											<Tooltip id={`tooltip-disabled`}>
												<span>{this.props.t('Formatierung zurücksetzen')}</span>
											</Tooltip>
										}>
                    <img
                      alt={"Undo"}
											src={Undo}
											style={{
												position: 'absolute',
												top: '25px',
												width: '20px',
												cursor: 'pointer',
											}}
											onClick={(e) => this.handleRipStyles(index)}
										/>
									</OverlayTrigger>
								) : (
									<></>
								)}

								<div
									className="colorDisplay"
									style={{ backgroundColor: this.state.textInput.boxColor }}
									onClick={this.handleShowTextColorPicker}></div>

								{this.state.showColorPicker && (
									<>
										<img
											alt={'Close'}
											src={closeIcon}
											onClick={this.closeColourPicker}
											style={{
												borderRadius: '50%',
												background: '#efefef',
												padding: '5px',
											}}
										/>
										<ChromePicker
											className="color-picker2"
											style={{
												margin: '0px',
												zIndex: '1',
												position: 'absolute',
												content: { closeIcon },
											}}
											color={this.state.textInput.boxColor}
											onChange={(updatedColor) => {
												this.handleUpdateColor(updatedColor.hex);
											}}
										/>
									</>
								)}
							</div>
							{this.state.textInput.boxColor && (
								// <div style={{ marginTop: "5px" }}>
								<img
									src={require('../../assets/eraser.png')}
									alt={'eraser'}
									onClick={this.removeColor}
									style={{
										cursor: 'pointer',
										width: '1.5rem',
										height: '1.5rem',
										top: '0.5rem',
										right: '0.5rem',
										float: 'right',
										position: 'absolute',
									}}
								/>
								// </div>
							)}
							<span className="price-display" style={{ top: '25px' }}>
								{input.price !== undefined &&
									input.price !== '' &&
									`${this.context.currencyFormat}
                  ${
										this.context.languageFormat === 'en'
											? Number(input.price)
													.toFixed(2)
													.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											: Number(input.price)
													.toFixed(2)
													.replace('.', ',')
													.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
									}
                   `}
							</span>

							{input.interactive && (
								<button
									className="option"
				
									onClick={() => this.showOptionComponent(index)}
									style={{
										border: this.functionValidation(input)
											? '2px solid red'
											: null,
										borderRadius: '50%',
										height: '34px',
										width: '34px',
										top: '25px',
										marginTop: '15px',
										zIndex: '1',
									}}>
									<img
										src={
											this.state.selectedTextIndex === index
												? optionFilledIcon
												: optionIcon
										}
										alt=""
									/>
								</button>
							)}
							{input.priceType === 'number-guests' && (
								<div className="x-guest">x {this.props.t('Gäste')}</div>
							)}
							{index > 0 && (
								<button
									className="remove-text"
									onClick={() => this.removeText(index)}>
									<img src={deleteIcon} alt="" />
								</button>
							)}
							{input.interactive && (
								<input
									type="text"
									value={input?.interactiveText}
									onChange={(e) => {
										this.handleTextAltChange(e, index);
									}}
									placeholder={this.props.t('Text einfügen')}
									style={{
										marginTop: '4px',
										width: '100%',
										background: '#e6e7e8',
										fontSize: '17px',
										border: 0,
										padding: '8px 8px 0px 0px',
										verticalAign: 'top',
										color: '#939598',
									}}
									autoComplete="off"
								/>
							)}
						</div>
					);
        })}

        {/* <button className="add-text" onClick={this.addText}>
          <img src={plusIcon} alt="" />
        </button> */}
      </div>
    );
  }
}

export default withTranslation() (TextOptionComponent);

// const styleMap = {
//   rightSide:{
//     paddingRight:"15px"
//   }
// };

import React, { Component } from "react";
import closeIcon from "../../assets/cross.svg";
import { StateContext } from "../../context/state";
import axios from "axios";
import { baseUrl } from "../../Constants";
import {
  EditorState,
  convertToRaw,
  ContentState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import HttpService from "../../services/HttpService";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withTranslation} from 'react-i18next';

class ForgotPassword extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      data: {
        subject: { value: EditorState.createEmpty(), hideTextToolbar: true },
        header: { value: EditorState.createEmpty(), hideTextToolbar: true },
        link: { value: EditorState.createEmpty(), hideTextToolbar: true },
        footer: { value: EditorState.createEmpty(), hideTextToolbar: true },
      },
      newEntry: true,
      fontDropDownOpen: false,
      fonts: [
        "Arial",
        "Georgia",
        "Tahoma",
        "Times New Roman",
        "Verdana",
      ],
      selectedFont: "Arial",
    };
  }

  componentDidMount() {
    axios
      .get(`${baseUrl}api/email/resetPasswordEmail/${this.context.venueId}`)
      .then((res) => {
        if (Object.keys(res.data).length) {
            //subject conversion
          const blocksFromHtml1 = htmlToDraft(res.data.subject);
          const contentState1 = ContentState.createFromBlockArray(
            blocksFromHtml1.contentBlocks,
            blocksFromHtml1.entityMap
          );
          const editorState1 = EditorState.createWithContent(contentState1);
          
          //header conversion
          const blocksFromHtml2 = htmlToDraft(res.data.header);
          const contentState2 = ContentState.createFromBlockArray(
            blocksFromHtml2.contentBlocks,
            blocksFromHtml2.entityMap
          );
          const editorState2 = EditorState.createWithContent(contentState2);

          //footer conversion
          const blocksFromHtml3 = htmlToDraft(res.data.footer);
          const contentState3 = ContentState.createFromBlockArray(
            blocksFromHtml3.contentBlocks,
            blocksFromHtml3.entityMap
          );
          const editorState3 = EditorState.createWithContent(contentState3);
          // console.log(editorState, 'editor',
          // draftToHtml(convertToRaw(editorState.getCurrentContent())))
          const data = {
            subject: {
              value: res.data.subject ? editorState1 : EditorState.createEmpty(),
              hideTextToolbar: true,
            },
            header: {
              value: res.data.header
                ? editorState2
                : EditorState.createEmpty(),
              hideTextToolbar: true,
            },
            footer: {
              value: res.data.footer
                ? editorState3
                : EditorState.createEmpty(),
              hideTextToolbar: true,
            },
          };
          this.setState({
            newEntry: false,
            data,
            selectedFont:res.data?.fontForLink || 'Arial'
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleClosePopup = () => {
    this.props.closePopup();
  };
  handleSave = async (data, family) => {
    const link = `<div style="text-align:left; font-family:${family};">
    Link:<a href=${baseUrl}set-password/${this.context.urlName}> ${baseUrl}set-password/${this.context.urlName}</a>
    <br/>
    Zu Ihrem Login Bereich: <a href=${baseUrl}login/${this.context.urlName} >${baseUrl}login/${this.context.urlName}</a>
    </div>`;
    const payloadData = {
      venueId: this.context.venueId,
      subject: draftToHtml(
        convertToRaw(data.subject.value.getCurrentContent())
      ),
      header: draftToHtml(convertToRaw(data.header.value.getCurrentContent())),
      link,
      footer: draftToHtml(convertToRaw(data.footer.value.getCurrentContent())),
      fontForLink: this.state.selectedFont
    };
    if (this.state.newEntry) {
      await HttpService.postResetPassword(payloadData);
    } else {
      await HttpService.putResetPassword(this.context.venueId, payloadData);
    }
    // console.log("save", payloadData, this.state.newEntry);
    return this.props.closePopup();
  };
  onEditorStateChange = (e, label) => {
    const data = Object.assign({}, this.state.data);
    data[label].value = e;
    this.setState({
      data,
    });
  };
  handleOnFocus = (label) => {
    const data = Object.assign({}, this.state.data);
    data[label]["hideTextToolbar"] = false;
    this.setState({ data });
  };
  handleOnBlur = (label) => {
    const data = Object.assign({}, this.state.data);
    data[label]["hideTextToolbar"] = true;
    this.setState({ data });
  };
  toggleFontDropdown = () => {
    this.setState({
      fontDropDownOpen: !this.state.fontDropDownOpen,
    });
  };
  setSelectedFont = (d) => {
    this.setState({
      selectedFont: d,
    });
  };
  uploadImageCallBack=(file)=> {
    return new Promise(
      (resolve, reject) => {
        const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
        xhr.open('POST', 'https://api.imgur.com/3/image');
        xhr.setRequestHeader('Authorization', 'Client-ID 8d26ccd12712fca');
        const data = new FormData(); // eslint-disable-line no-undef
        data.append('image', file);
        xhr.send(data);
        xhr.addEventListener('load', () => {
          const response = JSON.parse(xhr.responseText);
          resolve(response);
        });
        xhr.addEventListener('error', () => {
          const error = JSON.parse(xhr.responseText);
          reject(error);
        });
      },
    );
  }
  render() {
    const editorLabels={
      'components.controls.link.linkTitle': 'Link Text',
      'components.controls.link.linkTarget': 'Link',
      'components.controls.link.linkTargetOption': 'In neuem Fenster öffnen ',
      
    }
    const {
      data: { subject, header, footer },
      selectedFont,
    } = this.state;
    return (
      <div className="popup-overlay " style={{ top: "5%", zIndex: "999" }}>
        <div
          className="popup-container"
          style={{
            background: "#d1d3d4",
            maxWidth: "1620px",
            width: "80%",
            height: "700px",
            display: "inline-block",
            padding: "20px",
            position: "relative",
            maxHeight: "100vh",
            overflow: "auto",
            border: "1px solid #95969b",
            borderRadius: "3px"
          }}
        >
          <>
            <button
              className="close-btn"
              style={{ float: "right", border: "none", borderRadius: "3px" }}
              onClick={this.handleClosePopup}
            >
              <img src={closeIcon} alt="" />
            </button>
            <button
              style={{ float: "right", border: "none", marginRight: "10px", borderRadius: "3px" }}
              onClick={() =>
                this.handleSave(this.state.data, this.state.selectedFont)
              }
            >
             {this.props.t('Speichern')}
            </button>
            <div style={{ padding: "30px" }}>
              <h4>
              {this.props.t('E-Mail Passwort vergessen')} 
              </h4>
              <hr/>
              <div>
                <div style={{textAlign:"left"}}>
                  {/* {console.log('val', draftToHtml(convertToRaw(subject.value.getCurrentContent())))} */}
                  <p>{this.props.t('Betreff')}:</p>
                  <Editor
                        localization={{ locale: 'de', translations: editorLabels }}
                    editorState={subject.value}
                    // toolbarOnFocus={!subject.hideTextToolbar}
                    // toolbarHidden={subject.hideTextToolbar}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    // onFocus={() => this.handleOnFocus("subject")}
                    // onBlur={(e) => this.handleOnBlur(e, "subject")}
                    onEditorStateChange={(e) =>
                      this.onEditorStateChange(e, "subject")
                    }
                    toolbar={{
                      image:{uploadCallback: this.uploadImageCallBack,alt: { present: true, mandatory: false }, previewImage:true}
                    }}
                  />
                </div>
                <br />
                <div style={{textAlign:"left"}}>
                <p>{this.props.t('Mailtext')}:</p>
                  <Editor
                        localization={{ locale: 'de', translations: editorLabels }}
                    editorState={header.value}
                    // toolbarOnFocus={!header.hideTextToolbar}
                    // toolbarHidden={header.hideTextToolbar}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    // onFocus={() => this.handleOnFocus("header")}
                    // onBlur={() => this.handleOnBlur("header")}
                    onEditorStateChange={(e) =>
                      this.onEditorStateChange(e, "header")
                    }
                    toolbar={{
                      image:{uploadCallback: this.uploadImageCallBack,alt: { present: true, mandatory: false }, previewImage:true}
                    }}
                  />
                </div>
                <br />
                <div style={{ textAlign: "left", fontFamily:selectedFont}}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "80%", textAlign: "left", paddingTop: "15px" }}>
                  {this.props.t('Link')}: {`${baseUrl}set-password/${this.context.urlName}`}
                  </div>
                  <div>
                    <Dropdown
                      direction="down"
                      name="selectedFont"
                      isOpen={this.state.fontDropDownOpen}
                      toggle={this.toggleFontDropdown}
                    >
                      <DropdownToggle
                        caret
                        className="dropdown1"
                        style={{
                          width: "160px",
                          background: "rgb(240, 239, 240)",
                        }}
                      >
                        {selectedFont.length ? `${selectedFont}` : "Font"}
                      </DropdownToggle>
                      <DropdownMenu className="dropdown1">
                        {this.state.fonts.map((d) => {
                          return (
                            <DropdownItem
                              name="selectedFont"
                              onClick={() => {
                                this.setSelectedFont(d);
                              }}
                              key={d}
                              value={d}
                            >
                              {d}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                {this.props.t('Zu Ihrem Login Bereich')}: {`${baseUrl}login/${this.context.urlName}`}
                <br/>
                </div>
                <br />
                <div style={{textAlign:"left"}}>
                <p>{this.props.t('Abschlußtext')}:</p>
                  <Editor
                        localization={{ locale: 'de', translations: editorLabels }}
                    editorState={footer.value}
                    // toolbarOnFocus={!footer.hideTextToolbar}
                    // toolbarHidden={footer.hideTextToolbar}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    // onFocus={() => this.handleOnFocus("footer")}
                    // onBlur={() => this.handleOnBlur("footer")}
                    onEditorStateChange={(e) =>
                      this.onEditorStateChange(e, "footer")
                    }
                    toolbar={{
                      image:{uploadCallback: this.uploadImageCallBack,alt: { present: true, mandatory: false }, previewImage:true}
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    );
  }
}

export default withTranslation() (ForgotPassword);

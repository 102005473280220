import React from "react";
import TableSection, { Header } from "../partials/TableSection";
import { StateContext } from "../../context/state";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import Moment from "react-moment";
import moment from "moment";
import * as rdrLocales from "react-date-range/dist/locale";
import { dateRangeService } from "../../services/DateRangeService";
import axios from 'axios'
import {baseUrl} from '../../Constants'
import "moment-timezone";
import { withTranslation} from 'react-i18next';
const headers = [
  {
    name: "Venues",
    value: "VenueName",
    type: Header.TEXT,
  },
  {
    name: "No. Of Events",
    value: "NumberOfEvents",
    type: Header.TEXT,
  },
  {
    name: "No. Of Adults",
    value: "NumberOfAdults",
    type: Header.TEXT,
  },
  {
    name: "No. Of Teens",
    value: "NumberOfTeens",
    type: Header.TEXT,
    //   field: "parentQuestionnaireId",
  },
  {
    name: "No. Of Kids",
    value: "NumberOfKids",
    type: Header.TEXT,
  },
  {
    name: "No. Of Small child",
    value: "NumberOfChild",
    type: Header.TEXT,
  },
  {
    name: "Total No. of Guest",
    value: "TotalGuests",
    type: Header.TEXT,
  },
];

 class BillingTable extends React.Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      dates: [
        {
          startDate: null,
          endDate: null,
          key: "selection",
        },
      ],
      displayDateRange: false,
      data: [
        {
          VenueName: "sydney",
          NumberOfEvents: "4",
          NumberOfAdults: "3",
          NumberOfTeens: "4",
          NumberOfKids: "2",
          NumberOfChild: "7",
          TotalGuests: "100",
        },
        {
          VenueName: "oslo",
          NumberOfEvents: "4",
          NumberOfAdults: "3",
          NumberOfTeens: "4",
          NumberOfKids: "2",
          NumberOfChild: "7",
          TotalGuests: "100",
        },
        {
          VenueName: "test",
          NumberOfEvents: "4",
          NumberOfAdults: "3",
          NumberOfTeens: "4",
          NumberOfKids: "2",
          NumberOfChild: "7",
          TotalGuests: "100",
        },
        {
          VenueName: "test2",
          NumberOfEvents: "4",
          NumberOfAdults: "3",
          NumberOfTeens: "4",
          NumberOfKids: "2",
          NumberOfChild: "7",
          TotalGuests: "100",
        },
        {
          VenueName: "graph",
          NumberOfEvents: "4",
          NumberOfAdults: "3",
          NumberOfTeens: "4",
          NumberOfKids: "2",
          NumberOfChild: "7",
          TotalGuests: "100",
        },
      ],
    };
  }
  componentDidMount(){
    const date1=JSON.parse(localStorage.getItem("startdate"))
    const date2=JSON.parse(localStorage.getItem("endDate"))
    // console.log("date",date1,"date2",date2)
    
    //   const startDate=date1
    //   const endDate=date2
      
    const startDate=date1 ? date1:new Date().toISOString()
    const endDate=date2?date2:new Date().toISOString()
    axios.get(`${baseUrl}api/report/totalGuests/${startDate}/${endDate}`)
    .then((res)=>{
        // console.log(res,"res")
        this.setState({
           data:res?.data,
           startDate:date1? date1:new Date(),
           endDate:date2? date2:new Date()
        })
    })
    .catch((err)=>{
        console.log(err)
    })
  }
  showDateRange = () => {
    this.setState({
      displayDateRange: !this.state.displayDateRange,
    });
  };

  setRange = (item) => {
    // console.log("item",item)
    const date1 = item.selection.startDate;
    const date2 = item.selection.endDate;
    const startDate=date1.toISOString()
    const endDate=date2.toISOString()
    localStorage.setItem("startdate", JSON.stringify(date1));
    localStorage.setItem("endDate", JSON.stringify(date2));

    //   console.log("startdate",startDate)
    // console.log("endDate",endDate)
    if (item.selection.startDate !== item.selection.endDate) {
      this.setState({
        displayDateRange: !this.state.displayDateRange,
        dates: [item.selection],
        startDate,
        endDate,
      });

      dateRangeService.setDateRange({
        fromDate: moment(startDate).format("YYYY/MM/DD"),
        toDate: moment(endDate).format("YYYY/MM/DD"),
      });
    } else {
      this.setState({
        dates: [item.selection],
      });
    }
  
    axios.get(`${baseUrl}api/report/totalGuests/${startDate}/${endDate}`)
    .then((res)=>{
        // console.log(res,"res")
        this.setState({
           data:res?.data
        })
    })
    .catch((err)=>{
        console.log(err)
    })
  
  };
  render() {
    return (
      <div>
        <div
          style={{
            color: "rgb(82, 79, 79)",
            fontSize: "20px",
            marginTop: "20px",
          }}
        >
         {this.props.t("Filter Time Periods")}
        </div>
        <button className="buttons dummy_button" onClick={this.showDateRange}>
          {/* <Moment format='DD-MM-YYYY'>{this.state.startDate}</Moment> */}
          <Moment format="DD-MM-YYYY">{this.state.startDate}</Moment>
        </button>

        {this.state.displayDateRange && (
          <DateRange
            locale={rdrLocales.de}
            startDatePlaceholder={this.props.t("Früher")}
            endDatePlaceholder={this.props.t("Später")}
            className="date-range-picker"
            onChange={this.setRange}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={this.state.dates}
          />
        )}
        <button className="buttons dummy_button" onClick={this.showDateRange}>
          <Moment format="DD-MM-YYYY">{this.state.endDate}</Moment>
        </button>
        <div style={{marginTop:"20px"}}>
     {this.state.data.length ?
     
       <TableSection headers={headers} data={this.state.data} buttons={[]} />:
       <></>
     }
        </div>
        {/* <div style={{
              color: "rgb(82, 79, 79)",
              fontSize: "20px",
              marginTop: "20px"
          }}>Filter Time Periods</div>
        <div className="row">
          
          <div className="col" style={{ border: "1px solid black" }}>
            Venues
          </div>
          <div className="col" style={{ border: "1px solid black" }}>
            Number Of Events
          </div>
          <div className="col" style={{ border: "1px solid black" }}>
            Number Of Adults
          </div>
          <div className="col" style={{ border: "1px solid black" }}>
            Number Of Teens
          </div>
          <div className="col" style={{ border: "1px solid black" }}>
            Number Of Kids
          </div>
          <div className="col" style={{ border: "1px solid black" }}>
            Number Of Small child
          </div>
          <div className="col" style={{ border: "1px solid black" }}>
            Total Number of Guest
          </div>
        </div>
        
            {this.state.data.map((d,index)=>{
                return <div className="row" key={index}>
                 <div className="col" style={{ border: "1px solid black" }}>
                {d.venueName}
               </div>
                <div className="col" style={{ border: "1px solid black" }}>
                {d.numberOfEvents}
               </div>
               <div className="col" style={{ border: "1px solid black" }}>
                {d.numberOfAdults}
               </div>
               <div className="col" style={{ border: "1px solid black" }}>
                {d.numberOfTeens}
               </div>
               <div className="col" style={{ border: "1px solid black" }}>
                {d.numberOfKids}
               </div>
               <div className="col" style={{ border: "1px solid black" }}>
                {d.numberOfChild}
               </div>
               <div className="col" style={{ border: "1px solid black" }}>
                {d.totalNumberOfGuest}
               </div>
               </div>
            })}
        */}
      </div>
    );
  }
}

export default withTranslation() (BillingTable); 
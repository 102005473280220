import React, { Component } from "react";
import axios from "axios";
import { StateContext } from "../../context/state";
import { baseUrl } from "../../Constants.js";
import docIcon from "../../assets/doc.svg";
class UploadOption extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      newData: {},
      childData: {},
    };
  }
  componentDidMount() {
    if (this.props.data) {
      this.setState({
        newData: this.props.data,
      });
    }

    const option = this.props.option;
    axios
      .get(
        `${baseUrl}api/export/ServiceElement/${this.context.childQuestionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`
      )
      .then((res) => {
        const d = res.data;
        // console.log("res",res.data)

        if( (d.images)||(d.files) ){
          const image = {
            url: d.images,
          };
        // console.log("newData",this.state.newData)
          this.props.updateAttachments(image);
          // console.log("img",res.data)
          if(this.state.newData.exportImgs.largeImg===true){
            this.props.uploadAttachment(res.data.files,res.data.isDisplay)
          }
         
          // if(this.props.attachments.length){
          //     const newDat=this.props.attachments.find((urls)=>{
          //         return urls.url===d.images
          //     })
          //     if(!newDat){
          //         const newD=this.props.attachments.concat(image)
          //         this.props.updateAttachments(newD)

          //     }
          // }
          // else{
          //     const newD=this.props.attachments.concat(image)
          //     this.props.updateAttachments(newD)                   }
        }
        this.setState({
          childData: d,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
//  console.log("upload data",this.state.newData);
//  console.log("upload cdata",this.state.childData);
    return (
      <div >
        { (this.state.childData.isDisplay && this.state.childData.isDisplay==="true" && this.state.childData.files!==undefined && this.state.childData.files?.length!==0 )  ? <>
        <div className="qtn_optns" >
          {this.state.newData.uploadInput?.inputs ? (
            <>
              <div className="quest1" style={{background:"white"}}>
                {this.state.newData.uploadInput?.qtnAlternate ? (
                  <b>
                    <i>{this.state.newData.uploadInput?.qtnAlternate}</i>
                  </b>
                ) : (
                 <b>
                    <i>{this.state.childData.uploadInput?.question}</i>
                  </b>
                )}
              </div>

              {/* <input 
                        type="text" 
                        className="inp1" 
                        placeholder="Ersatztext"
                        value= {this.state.newData.uploadInput.qtnAlternate}
                        onChange={(e)=>{this.handleChangeQtnAlt(e)}}
                        /> */}
            </>
          )
        :
        this.state.childData.files?.length!==0  &&  <b>
        <i>{this.state.childData.uploadInput?.question}</i>
        </b>
      }
        </div>
        <br/>
        <div style={{marginBottom:"10px",display:"flex",flexWrap:"wrap"}}>
        {this.state.childData.files?.map((input, i) => {
          return (
            <div key={i}>
              {
                <div style={{paddingBottom:"10px" ,
                paddingLeft:i!==0 ? "20px":""}}>
                  <div>
                    {/* {console.log("this.state.newData?.exportImgs?",this.state.newData?.exportImgs)} */}
                    {this.state.newData?.exportImgs?.thumbnail === true && (
                      <a target="_blank" rel="noopener noreferrer" href={`${input.url}`}>
                        {/* {console.log("input",input)} */}
                        <img
                          src={  (input.type === "jpeg" ||
                          input.type === "jpg" ||
                          input.type === "png"||input.type==="image")? `${input.url}` :docIcon}
                          alt=""
                          style={{ width: "180px",height:"160px" }}
                        />
                      </a>
                    )}
                  </div>
                 

                  {/* <div>
                    {this.state.newData?.exportImgs?.largeImg === true && (
                      <img  src={input.type==="pdf"? docIcon:`${input.url}`}
                      style={input.type==="pdf"? styles.pdf:styles.image} 
                       alt="" />
                    )}
                  </div> */}
                  <div>
                    {/* {console.log("exportimg url",this.state.newData?.exportImgs?.url)} */}
                    {this.state.newData?.exportImgs?.url === true && (
                      <a  style={{height:"20px",outline: "none"}}target="_blank" rel="noopener noreferrer" href={`${input.url}`}>
                        {input.name}
                      </a>
                    )}
                  </div>
                </div>
              }
            </div>
          );
        })}
        </div>
       </>:<></>}
      </div>
    );
  }
}

export default UploadOption;
import React, { Component } from "react";
import axios from "axios";
import { StateContext } from "../../context/state";
import { baseUrl } from "../../Constants.js";
import transAntwortIcon from "../../assets/transperent-antwort-btn.svg";
import transLinkBtnIcon from "../../assets/transperent-link-btn.svg";
import transAttachBtnIcon from "../../assets/transperent-attachment-btn.svg";
import antwortBtn from "../../assets/antwort-btn.svg";
import linkBtn from "../../assets/link-btn.svg";
import attachmentBtn from "../../assets/attachment-btn.svg";
import { withTranslation} from 'react-i18next';
// const d={
//     type:"upload",
//     uploadInput:{
//         question:"qtn",
//         inputs:[
//            {
//                 id:"1",
//                 value:"a"
//             },
//             {
//                 id:"2",
//                 value:"b"
//             }
//         ],
//         maximum: "",
//         minimum: "1"
//     }
//   }
class UploadOption extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      newData: {},
      // data:{}
    };
  }
  componentDidMount() {
    const option = this.props.option;
    // console.log('api', `${baseUrl}api/export/ServiceElement/${this.context.questionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`)

    if(Object.keys(this.props.divData).length){
      // console.log("in if")
      this.setState({
          data:this.props.divData,
          // newData:this.props.divData
      },()=>{
        // console.log("data",this.state.data)
      })
    }
    // else{
      // console.log("in else")
      const divData=this.props.divData
    axios
      .get(
        `${baseUrl}api/export/ServiceElement/${this.props.questionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`
      )
      .then((res) => {
        const d=Object.assign({},res.data)

        // console.log('result',d)
        // d.uploadInput.qtnAlternate = divData.uploadInput.qtnAlternate? divData.uploadInput.qtnAlternate:"";
        if(this.state.data){
          d.uploadInput.qtnAlternate=this.state.data.uploadInput?.qtnAlternate ? this.state.data.uploadInput?.qtnAlternate:""
        }
      else{
        d.uploadInput.qtnAlternate=""
      }
        const exportImgs = { thumbnail: "", url: "", largeImg: "" };
        d.exportImgs = divData.exportImgs?divData.exportImgs:exportImgs;
        // console.log('hi', d)

        this.setState({
          // data: d,
      newData: { ...this.state.newData, ...d },
        },()=>{
          // console.log("newData",this.state.newData)
        });
        this.props.updateChange({...d },this.props.divId);
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  }
  handleThmbnail = () => {
    const newData = Object.assign({}, this.state.newData);
    if(newData.exportImgs){

    newData.exportImgs.thumbnail = !newData?.exportImgs?.thumbnail;
    }
    // newData.exportImgs.url=false
    // newData.exportImgs.largeImg=false
    this.setState({
      newData,
    });
    this.props.updateChange(newData,this.props.divId);
  };
  handleUrl = () => {
    const newData = Object.assign({}, this.state.newData);
    if(newData.exportImgs){
      newData.exportImgs.url = !newData?.exportImgs?.url;
    }
    
    // newData.exportImgs.thumbnail=false
    // newData.exportImgs.largeImg=false
    this.setState({
      newData,
    });
    this.props.updateChange(newData,this.props.divId);
  };
  handleLargeImgs = () => {
    const newData = Object.assign({}, this.state.newData);
    if(newData.exportImgs){

    newData.exportImgs.largeImg = !newData?.exportImgs?.largeImg;
    }
   

    this.setState({
      newData,
    });
    this.props.updateChange(newData,this.props.divId);
  };
  // handleThumbnail=(acceptedFiles, rejectedFiles)=>{
  //     if (acceptedFiles && acceptedFiles.length > 0) {
  //         const currentFile = acceptedFiles[0];
  //         // const image = URL.createObjectURL(currentFile);
  //         const data = new FormData();
  //         data.append("blob", currentFile);
  //         axios.post(
  //           'http://api.the-eventcloud.com/api/blob',
  //           data,
  //           {headers: {'Content-Type': 'multipart/form-data'}})
  //           .then(res => {
  //             //  console.log(res.data)
  //              const {newData}=this.state
  //              this.state.newData.exportImgs.thumbnail=res.data.url
  //              this.setState({
  //                  newData
  //              }, ()=>{
  //                 this.props.updateChange(this.state.newData)
  //              })

  //           }).catch(error => {
  //               console.log(error)
  //           });

  //     }
  //    }
  //    handleLargeImg=(acceptedFiles, rejectedFiles)=>{
  //     if (acceptedFiles && acceptedFiles.length > 0) {
  //         const currentFile = acceptedFiles[0];
  //         // const image = URL.createObjectURL(currentFile);
  //         const data = new FormData();
  //         data.append("blob", currentFile);
  //         axios.post(
  //           'http://api.the-eventcloud.com/api/blob',
  //           data,
  //           {headers: {'Content-Type': 'multipart/form-data'}})
  //           .then(res => {
  //             //  console.log(res.data)
  //              const {newData}=this.state
  //              this.state.newData.exportImgs.largeImg=res.data.url

  //              this.setState({
  //                 newData
  //             }, ()=>{
  //                 this.props.updateChange(this.state.newData)
  //             })

  //           }).catch(error => {
  //               console.log(error)
  //           });
  //         //   this.props.childHandleLargeImgDrop('large img url')
  //     }
  //    }
  //    handleURL=(acceptedFiles, rejectedFiles)=>{
  //     if (acceptedFiles && acceptedFiles.length > 0) {
  //         const currentFile = acceptedFiles[0];
  //         // const image = URL.createObjectURL(currentFile);
  //         const data = new FormData();
  //         data.append("blob", currentFile);
  //         axios.post(
  //           'http://api.the-eventcloud.com/api/blob',
  //           data,
  //           {headers: {'Content-Type': 'multipart/form-data'}})
  //           .then(res => {
  //             //  console.log(res.data)
  //              const {newData}=this.state
  //              this.state.newData.exportImgs.url=res.data.url
  //              this.setState({
  //                newData
  //             },()=>{
  //                 this.props.updateChange(this.state.newData)
  //             })

  //           }).catch(error => {
  //               console.log(error)
  //           });
  //     }
  //    }
  handleChange = (e) => {
    const newData = Object.assign({}, this.state.newData);
    if (newData.uploadInput) {
      newData.uploadInput.qtnAlternate = e.target?.value;
      this.setState({
        newData,
      });
      this.props.updateChange(newData,this.props.divId);
    }
  };
  render() {
    // const {thumbnail,url,largeImg}=this.state.newData?.exportImgs
    const thumbnail = this.state.newData?.exportImgs?.thumbnail;
    const url = this.state.newData?.exportImgs?.url;
    const largeImg = this.state.newData?.exportImgs?.largeImg;
    return (
      <div>
        {/* <div className="qtn_optns">
                  { this.state.newData.uploadInput?.inputs && 
                  <div className="quest1">                     
                    <b><i>{this.state.newData.uploadInput?.question}</i></b>
                    </div>
                    <input 
                        type="text" 
                        className="inp1" 
                        placeholder="Ersatztext"
                        value= {this.state.newData.images}
                        onChange={(e)=>{this.handleChangeQtnAlt(e)}}
                        /> */}

        <div className="qtn_optns" style={{paddingTop:"20px"}}>
          <div className="quest1">
            <b>
              <i>{this.state.newData.uploadInput?.question}</i>
            </b>
          </div>
          <input
            type="text"
            className="inp1"
            placeholder={this.props.t("Ersatztext")}
            value={this.state.newData.uploadInput?.qtnAlternate}
            onChange={this.handleChange}
            autoComplete="off"
          />

          {/* <img src={this.state.newData.images} style={styles.image} alt="" /> */}
          {/* <div className="secondHalf"> */}
          <div onClick={this.handleThmbnail} style={{ cursor: "pointer" }}>
            {thumbnail === true ? (
              <img src={antwortBtn} className="_icon" alt="" />
            ) : (
              <img src={transAntwortIcon} className="_icon" alt="" />
            )}
          </div>
          <div onClick={this.handleLargeImgs} style={{ cursor: "pointer" }}>
            {largeImg === true  ? (
              <img src={attachmentBtn} className="_icon" alt="" />
            ) : (
              <img src={transAttachBtnIcon} className="_icon" alt="" />
            )}
          </div>
          <div  onClick={this.handleUrl} style={{ cursor: "pointer" }}>
            { url === true? (
              <img src={linkBtn} className="_icon" alt="" />
            ) : (
              <img src={transLinkBtnIcon} className="_icon" alt="" />
            )}
          </div>
          {/* <input type="text" style={{border:'none', background:'#e6e7e8'}} placeholder="Ersatztext"/> */}
          {/* <Dropzone onDrop={this.handleThumbnail}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                            <div>
                                                <img src={transAntwortIcon} className="_icon" alt="" />
                                            </div>
                            </div>
                          )}
                        </Dropzone> */}
          {/* 
                        <Dropzone onDrop={this.handleLargeImg}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div>
                                        <img src={transAttachBtnIcon} className="_icon" alt=""/>
                                    </div>
                            </div>
                          )}
                        </Dropzone> */}

          {/* <Dropzone onDrop={this.handleURL}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                <div>
                                    <img src={transLinkBtnIcon} className="_icon" alt=""/>
                                </div>
                            </div>
                          )}
                        </Dropzone> */}
          {/* </div> */}
        </div>
      </div>
    );
  }
}

export default withTranslation() (UploadOption);
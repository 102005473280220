import React, { Component } from "react";
import { StateContext } from "../../context/state";
import axios from "axios";
import { baseUrl } from "../../Constants.js";
import TextOption from "./text";
import DatumOption from "./datum";
import RadioOption2 from "./radio2";
import ImageOption2 from "./image";
import BildOption from "./bild";
import TableOption from "./table";
import UploadOption from "./upload";
import DownloadOption from "./download";
import checkIcon from "../../assets/check.png";
import { withTranslation} from 'react-i18next';

class ImageMultipleOption extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      newData: {},
      childData: {},
      bool: false,
    };
  }
  componentDidMount() {
    if (this.props.data) {
      if (this.props.data?.imageInput?.inputs) {
        this.props.data.imageInput.inputs.forEach((item) => {
          if (item.image) {
            const image = {
              url: item.image,
            };
            this.props.updateAttachments(image);
          }
        });
      }
      this.setState({
        newData: this.props.data,
      });
    }

    const option = this.props.option;
    axios
      .get(
        `${baseUrl}api/export/ServiceElement/${this.context.childQuestionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`
      )
      .then((res) => {
        const d = res.data;
        const dd = d?.imageInput?.inputs?.map((input,index) => {
          // input.alternate = "";
          input.alternate = input.alternate? input.alternate:this.props.data?.imageInput?.inputs[index].alternate
          return { ...input };
        });
        if (d?.imageInput?.inputs) {
          d.imageInput.inputs.forEach((item) => {
            if (item.image) {
              const image = {
                url: item.image,
              };
              this.props.updateAttachments(image);
            }
          });
        }
        if(d.imageInput){
          d.imageInput.inputs = dd;
        }
        // this.setState({
        //   childData: { ...d },
        // });
        this.setState({
          data: d,
          childData: { ...this.state.newData, ...d },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleChangeQtnAlt = (e) => {};
  renderOption = (option) => {
    if (option?.display === false) {
      return null;
    }
    switch (option?.optionType) {
      case "text":
        return <TextOption option={option} />;
      case "datum":
        return <DatumOption option={option} />;
      case "bild":
        return <BildOption option={option} />;
      case "radio":
        return <RadioOption2 option={option} />;
      case "table":
        return <TableOption option={option} />;
      case "upload":
        return <UploadOption option={option} />;
      case "download":
        return <DownloadOption option={option} />;
      case "image":
        return <ImageOption2 option={option} />;
      default:
        break;
    }
  };
  handleDisplayAnswers = (id) => {
    if (this.state.childData?.answer?.length) {
      const dummy = Object.assign([], this.state.childData?.answer);

      const findingElement = dummy.find((ans) => {
        return ans.id === id;
      });
      if (findingElement) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  render() {
    //   let obj={}

    //   let obj2=[]
    // obj= this.state.newData?.radioInput?.inputs.map((option,i)=>{
    //      return this.state.childData?.answer?.filter((d)=>{
    //        if(d?.id===option?.id){
    //          obj2.push(d)
    //        }
    //      })

    //         })
    
    let ans;
    if (this.state.childData?.answer) {
      ans = this.state.childData?.answer;   
    }
  

    if(this.state.childData.imageInput){
      this.setState.childData.imageInput.qtnAlternate=this.state.childData?.imageInput?.qtnAlternate ? 
      this.state.childData?.imageInput?.qtnAlternate:
       this.state.newData?.imageInput?.qtnAlternate
    }
    const alignment = this.props.data.imageInput?.alignment || "";

    
  //console.log("data2", this.props.data2);
    let show = false;
    if (this.props.data2?.imageInput?.checkboxQuestionSelected ||this.props.data2?.imageInput?.questionSelected) {
      show = true;
    } else if (this.state.data?.imageInput?.checkboxQuestionSelected ||this.state.data?.imageInput?.questionSelected) {
      show = true;
    }

    return (
      <div style={{ marginBottom:"10px" }}>
        { (ans !== undefined &&
              ans?.length !== 0 ) && this.state.childData.isDisplay!=="false" ?<>
        <div style={{ overflow: "auto" }}>
          {this.state.newData.imageInput?.inputs && (
            <>
              <div className="qtn_optns">
              {(Object.keys(this.state.childData).length!==0 && this.state.childData!==undefined) && (!show) && <>
                {!this.state.childData?.imageInput?.qtnAlternate ? (
                  <div className="quest1" style={{background:"white",paddingLeft:"0px"}}>
                    <b>
                      <i>{this.state.childData?.imageInput?.question}</i>
                    </b>
                  </div>
                ) : (
                  // <input
                  //   type="text"
                  //   className="inp1"
                  //   placeholder={this.props.t('Ersatztext')}
                  //   value={this.state.childData.imageInput.qtnAlternate}
                  //   onChange={(e) => {
                  //     this.handleChangeQtnAlt(e);
                  //   }}
                  //   autoComplete="off"
                  // />

                  <div className="quest1" style={{background:"white",paddingLeft:"0px"}}>
                    <b>
                      <i>{this.state.childData?.imageInput?.qtnAlternate}</i>
                    </b>
                  </div>
                )}
                </>
              }
              </div>
              <div className="secondHalfPreview" style={{width:"0px"}}>
                {this.state.childData?.imageInput?.inputs.map((input, i) => {
                  return (
                    <div key={i}>
                      <div className={`image-item image-item-${alignment}`}>
                        {/* <div className="image" style={{backgroundImage: `url(${input.image})`}} /> */}
                        {this.state.childData?.answer !== undefined && 
                        this.state.childData?.answer.length ? (
                          <>
                            {this.state.childData.answer?.map((data) => {
                              return (
                                <>
                                  
                                  {data?.id === input?.id && (
                                    <>
                                      {input?.alternate ?
                                        <div>
                                            <div style={{ width: "200px" }} >
                                              {input?.alternate}
                                            </div>
                                        </div>
                                        :
                                        <img
                                          src={`${input?.image}`}
                                          // style={widthStyle}
                                          style={{ width: "300px", height: "250px" }}
                                          alt=""
                                        />
                                      }
                                      {data?.id === input?.id ? (
                                        <>
                                          <span style={styles.filledCircle} />
                                          {data?.quantity !== "0" &&
                                            data?.quantity}
                                          {data?.quantity2 !== "0" &&
                                            data?.quantity2}
                                        </>
                                      ) : (
                                        <span style={styles.circle} />
                                      )}
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                             {input?.alternate ?
                              <div>                          
                                  <div style={{width:"200px"}} >
                                    {input?.alternate}
                                  </div>                          
                              </div>
                              :
                            <img
                              src={`${input?.image}`}
                              style={{width:"300px",height:"250px"}}
                              // style={widthStyle}
                              alt=""
                            />}
                            {this.handleDisplayAnswers(input.id) !== false ? (
                              <span style={styles.filledCircle} />
                            ) : (
                              <span style={styles.circle} />
                            )}
                          </>
                        )}

                        {/* {this.state.childData?.answer?.map((ans,i)=>{
                                      return (
                                          
                                        (input?.id===ans?.id)?
                                        <span key={i} style={styles.filledCircle} />
                                        :
                                        <span key={i} style={styles.circle} />
                                      )
                                    })
                                      

                                    } */}
                      </div>
                      {this.handleDisplayAnswers(input.id)}
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>

        {/* {this.state.newData.imageInput?.link?.display &&
          this.state.childData?.answer?.id ===
            this.state.newData.imageInput.link.linkedOptionId &&
          this.renderOption(this.state.childData.imageInput.link[0])} */}
    </>:<></>}
      </div>
    );
  }
}

export default withTranslation() (ImageMultipleOption);

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    marginBottom:"10px" 
  },
  labelContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px",
  },
  typeContainer: {
    marginLeft: "54px",
  },
  circle: {
    marginTop: "7px",
    width: "16px",
    height: "16px",
    display: "inline-block",
    textAlign: "center",
    background: "transparent",
    // borderRadius: "12px",
    border: "1px solid #297825",
    outline: "none",
    cursor: "pointer",
  },
  filledCircle: {
    marginTop: "7px",
    width: "16px",
    height: "16px",
    display: "inline-block",
    textAlign: "center",
    background: "transparent",
    content: `url(${checkIcon})`,
    // backgroundColor:"#939598",
    border: "1px solid #939598",
    outline: "none",
    cursor: "pointer",
  },
  selected: {
    background: "#297825",
  },
  label: {
    margin: "0 0 0 20px",
    padding: "0",
    display: "inline-block",
    color: "#939598",
    fontSize: "16px",
  },
  input: {
    border: 0,
    padding: 0,
    margin: 0,
    background: "transparent",
    borderBottom: "1px solid #707070",
    width: "200px",
  },
  selectedIcon: {
    background: `url(${checkIcon})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
};

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import LeftBar from '../partials/LeftBar';
import RightBar from '../partials/RightBar';
import PullBar from '../partials/PullBar';
import { StateContext } from '../../context/state';
import axios from 'axios';
import { baseUrl } from '../../Constants';
import 'moment-timezone';
import Moment from 'react-moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import HttpService from '../../services/HttpService';
import { PriceType, PricingFunction } from '../../Constants';

class EventLanding extends Component {
	static contextType = StateContext;
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			events: [],
			paragraphs: {},
			loaded: false,
			textColor: '',
			textFontFamily: '',
			textFontSize: 15,
			stylingData: {},
			defaultSettingStyle: '',
			dateFormat: '',
			collpsed: true,
			textDisplay: true,
			obj: {},
			guestInfo: {},
			themes: [],
		};
	}
	componentDidMount() {
		// console.log("localstaora",localStorage.getItem("state"))
		// console.log("context",this.context)
		// console.log("this.props.location?.state?.from",this.props.location?.state?.from)
		const venue = JSON.parse(sessionStorage.getItem('USER'))?.urlName;

		if (this.context.venueId) {
			HttpService.getVenueSettingDetails(this.context.venueId).then((res) => {
				this.setState({ defaultSettingStyle: res });
				this.context.updateState({ defaultSettingStyle: res });
			});
		}

		if (venue) {
			document.title = venue;
		}
		const obj = JSON.parse(sessionStorage.getItem('state2'));
		//   if(Object.keys(obj).length!=0){
		//     this.context.updateState({
		//        authenticated: true,
		//      });
		//  }
		this.setState({ obj: obj });
		//   if(obj){
		//   window.onload = function () {
		//     //considering there aren't any hashes in the urls already
		//     if (!window.location.hash) {
		//       //setting window location
		//       window.location = window.location + "#loaded";
		//       //using reload() method to reload web page
		//       window.location.reload();
		//     }
		//   };
		// }

		// // console.log("state",this.props.location)
		if (obj) {
			this.context.updateState({
				eventId: obj?.eventId,
				eventName: obj?.eventName,
				eventDate: obj?.eventDate,
				eventType: obj?.eventType,
				deadlineForEntries: obj?.deadlineForEntries,
				newDate: obj?.newDate,
				questionnaireId: obj?.questionnaireId,
				parentQuestionnaireId: obj?.parentQuestionnaireId,
			});
		}

		//     console.log("eventName",this.context.eventName)
		if (window.innerWidth > 1306) this.setState({ collapsed: false });
		window.addEventListener('resize', () => {
			if (window.innerWidth > 1306) this.setState({ collapsed: false });
			else this.setState({ collapsed: true });
		});
		if (window.innerWidth > 1094) this.setState({ textDisplay: false });
		window.addEventListener('resize', () => {
			if (window.innerWidth > 1094) this.setState({ textDisplay: false });
			else this.setState({ textDisplay: true });
		});
		this._isMounted = true;
		const data = this.context;
		let parentQuestionnaireId, questionnaireId;
		if (obj) {
			parentQuestionnaireId = obj?.parentQuestionnaireId;
			questionnaireId = obj?.questionnaireId;
		} else {
			parentQuestionnaireId = data?.parentQuestionnaireId;
			questionnaireId = data?.questionnaireId;
		}

		axios
			.get(`${baseUrl}api/event/Welcome/${parentQuestionnaireId}`)
			.then((res) => {
				// console.log(ev,"ev")
				this.setState({
					events: res.data.events ? res.data.events : [],

					// paragraphs:res.data.paragraphs?res.data.paragraphs:[],
					newEntry: false,
					loaded: true,
				});
			})
			.catch((err) => {
				console.log('err', err);
			});

		if (obj) {
			this.context.updateState({
				eventId: obj?.eventId,
				eventName: obj?.eventName,
				eventDate: obj?.eventDate,
				eventType: obj?.eventType,
				deadlineForEntries: obj?.deadlineForEntries,
				newDate: obj?.newDate,
				questionnaireId: obj?.questionnaireId,
				// parentQuestionnaireId: obj?.parentQuestionnaireId,
			});
		}

		if (this.context.userRole === 'CU') {
			if (this.context.eventId?.length) {
				axios
					.get(`${baseUrl}api/user/ByEvent/${this.context.eventId}/CU`)
					.then((response) => {
						console.log('cu resp', response.data);
						this.context.updateState({
							languageFormat: response.languageFormat,
						});
					})
					.catch((err) => {
						console.log(err);
					});
			}
		} else {
			axios
				.get(`${baseUrl}api/venue/${data.venueId}`)
				.then((resp) => {
					this.context.updateState({
						currencyFormat: resp.data?.currencyFormat,
						languageFormat: resp.data?.languageFormat,
					});
				})
				.catch((err) => {
					console.log(err);
				});
		}

		let guestInfo;

		const guestsPromise = HttpService.getGuests(questionnaireId).then(
			(response) => {
				guestInfo = response;
			}
		);
		Promise.all([guestsPromise])
			.then(() => {
				let obj = {};
				if (guestInfo?.pricing && guestInfo?.guests) {
					guestInfo.pricing.forEach((singlePricing) => {
						if (
							Object.keys(guestInfo?.guests).includes(singlePricing.value) &&
							singlePricing.price
						) {
							let x =
								PricingFunction[singlePricing.priceType || PriceType.NONE];
							const { amount, error } = x(
								Number(singlePricing.price) || 0,
								Number(guestInfo?.guests[singlePricing.value]) || 0,
								Number(singlePricing.numerically.stage) || 0,
								Number(singlePricing.numerically.maximumNumber) || 0,
								Number(singlePricing.numerically.minimumNumber) || 0,
								singlePricing.numerically
							);
							if (amount) {
								obj[`${singlePricing.value}`] = amount;
							} else {
								obj[`${singlePricing.value}`] = error;
							}
						}
					});
				}
				let count = 0;
				if (guestInfo?.guests) {
					Object.keys(guestInfo?.guests).forEach((key, index) => {
						if (guestInfo.fields[key] === true) {
							count += Number(guestInfo?.guests[key]);
						}
					});
				}

				this.setState({
					guestInfo,
					guestPriceObj: obj,
					guestCount: count,
				});
			})
			.catch((error) => alert(error));
		// axios
		//   .get(`${baseUrl}api/questionnaireSetting/${parentQuestionnaireId}`)
		//   .then((res) => {
		//     if (Object.keys(res.data).length) {
		//       // console.log(res.data.questionairre,"res")
		//       this.setState({
		//         stylingData: res?.data,
		//         textColor: res.data?.textColor,
		//         textFontFamily: res.data?.textFontFamily,
		//         textFontSize: res.data?.textFontSize,
		//         paragraphs: res.data.questionairre
		//           ? res.data.questionairre
		//           :

		//           {},

		//           paragraphs: res.data.Questionaireparagraphs?
		//           res.data.Questionaireparagraphs : res.data.questionairre?res.data.questionairre:{}
		//       });
		//     }
		//     if(obj){
		//     this.context.updateState({ stylingData: res?.data,
		//       eventId:obj?.eventId ,
		//       eventName: obj?.eventName,
		//       eventDate: obj?.eventDate,
		//       eventType: obj?.eventType,
		//       deadlineForEntries: obj?.deadlineForEntries,
		//       newDate: obj?.newDate,
		//       questionnaireId: obj?.questionnaireId,
		//       // parentQuestionnaireId: obj?.parentQuestionnaireId,
		//     });
		//   }
		//   else{
		//     this.context.updateState({ stylingData: res?.data,

		//     });
		//   }
		//   })
		//   .catch((err) => {
		//     console.log("err", err);
		//   });
		// console.log("context",this.context.eventId,this.context.eventName,this.context.eventDate,this.context.eventType,this.context.deadlineForEntries,

		// this.context.newDate,this.context.questionnaireId,this.context.parentQuestionnaireId)
	}

	setDateFormat = (value, res) => {
		if (this.context.venueId) {
			HttpService.getVenueSettingDetails(this.context.venueId).then(
				(resData) => {
					this.setState({ defaultSettingStyle: resData });
				}
			);
		}

		this.context.updateState({ stylingData: res });

		this.setState({
			dateFormat: value,
			stylingData: res,
			textColor: res?.textColor,
			textFontFamily: res?.textFontFamily,
			textFontSize: res?.textFontSize,
			paragraphs: res.questionairre ? res.questionairre : {},
		});
	};
	componentWillUnmount() {
		this._isMounted = false;
	}
	handleBack = () => {
		this.props.history.goBack();
	};
	findIfDatePassed = (name) => {
		const dummy = Object.assign([], this.state.events);
		const dummy2 = dummy.find((d) => {
			return d.name === name;
		});
		return moment(dummy2.date).isAfter(new Date());
	};

	functionCapitalise = (initialTitle) => {
		let title = '';
		if (initialTitle) {
			title = initialTitle[0]
				.toUpperCase()
				.concat(initialTitle.slice(1, initialTitle.length));
		}
		return title;
	};

	// handleLink=()=>{
	//   console.log('context', this.state.stylingData)
	//   const {stylingData}= this.state
	//   window.open(`${stylingData?.link}`, '_blank');

	// }
	findDaysLeft = (endDate) => {
		const days = moment(endDate).diff(moment(), 'days');
		// moment.duration(today.diff(endDate)).asDays()
		// console.log('day', days)

		return days;
	};
	findWeeksLeft = (endDate) => {
		const days = moment(endDate).diff(moment(), 'weeks');
		return days;
	};
	render() {
		const { events, stylingData, defaultSettingStyle } = this.state;
		const { cuDetails } = this.context;
		const obj2 = JSON.parse(sessionStorage.getItem('state2'));

		let date = new Date().toISOString();
		let eventDate;
		let deadlineForEntries;
		if (obj2) {
			eventDate = obj2?.eventDate;
			deadlineForEntries = obj2?.deadlineForEntries;
		} else {
			eventDate = this.context.eventDate;
			deadlineForEntries = this.context.deadlineForEntries;
		}
		return (
			<div className="login-event-landing alignment">
				<div className={this.state.collapsed === true ? '' : 'col-2'}>
					<LeftBar
						obj={obj2}
						setDateFormat={this.setDateFormat}
						defaultSettingStyle={this.state.defaultSettingStyle}
					/>
				</div>
				<div className={this.state.collapsed === true ? 'col-11' : 'col-10'}>
					{(this.state.loaded && events.length) ||
					Object.keys(events).length ? (
						<div
							className="steps-indicator"
							style={{ marginLeft: window.innerWidth > 2000 && '25%' }}>
							<div className="disp">
								<div className="second-step">
									{events.map((event, i) => {
										if (event.name === 'secondStep') {
											return (
												<div
													style={{
														width: '150px',
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
													key={i}>
													{/* <span style={{width: "150px"}} >Event Creation Date</span> */}

													{/* {events.length &&
                        this.findIfDatePassed("secondStep") ? (
                          <div
                            className="empty-circle"
                            style={{ marginTop: "50px" }}
                          ></div>
                        ) : (
                          <div
                            className="circle"
                            style={{ marginTop: "50px" }}
                          ></div>
                        )} */}

													{this.state.textDisplay === true &&
													event.topText.length > 8 ? (
														<OverlayTrigger
															key="bottom"
															placement="bottom"
															overlay={
																<Tooltip id={`tooltip-disabled`}>
																	<span>{event?.topText}</span>
																</Tooltip>
															}>
															<span>{event?.topText.slice(0, 7)}</span>
														</OverlayTrigger>
													) : (
														<OverlayTrigger
															key="bottom"
															placement="bottom"
															overlay={
																<Tooltip id={`tooltip-disabled`}>
																	<span>{event?.topText}</span>
																</Tooltip>
															}>
															<span>{event?.topText}</span>
														</OverlayTrigger>
													)}

													{events.length && (
														<div
															className="empty-circle"
															style={{
																marginTop: !event?.topText && '50px',
																background: stylingData.lineColor
																	? `${stylingData.lineColor}`
																	: `${defaultSettingStyle.presAccentColor}`,
																border: stylingData.lineColor
																	? `1px solid ${stylingData.lineColor}`
																	: `1px solid ${defaultSettingStyle.presAccentColor}`,
															}}></div>
													)}
													{this.state.textDisplay === true &&
													event?.text?.length > 8 ? (
														<OverlayTrigger
															key="bottom"
															placement="bottom"
															overlay={
																<Tooltip id={`tooltip-disabled`}>
																	<span>{event?.text}</span>
																</Tooltip>
															}>
															<span>{event?.text.slice(0, 7)}</span>
														</OverlayTrigger>
													) : (
														<OverlayTrigger
															key="bottom"
															placement="bottom"
															overlay={
																<Tooltip id={`tooltip-disabled`}>
																	<span>{event.text}</span>
																</Tooltip>
															}>
															<span>{event.text}</span>
														</OverlayTrigger>
													)}

													{/* <span>{event.text}</span> */}
													{/* <Moment format={this.context.dateFormat?this.context.dateFormat:'DD-MM-YYYY'}>{event.date}</Moment> */}
												</div>
											);
										} else {
											return null;
										}
									})}
									<span
										className="line second"
										style={{
											top: '62px',
											background: stylingData.lineColor
												? `${stylingData.lineColor}`
												: `${defaultSettingStyle.presAccentColor}`,
										}}></span>
									<div></div>
								</div>
								<div className="third-step">
									<span
										className="line"
										style={{
											top: '62px',
											background: stylingData.lineColor
												? `${stylingData.lineColor}`
												: `${defaultSettingStyle.presAccentColor}`,
										}}></span>
									{events.map((event, i) => {
										if (event.name === 'thirdStep') {
											return (
												<div
													style={{
														width: '150px',
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
													key={i}>
													<span style={{ width: '154px' }}>
														{/* {this.findWeeksLeft(event.date)} wochen vorher */}
													</span>

													{/* {events.length && this.findIfDatePassed("thirdStep") ? (
                          <div
                            className="empty-circle"
                            style={{ marginTop: "50px" }}
                          ></div>
                        ) : (
                          <div
                            className="circle"
                            style={{ marginTop: "50px" }}
                          ></div>
                        )} */}
													{/* <span>{event?.topText}</span> */}
													{this.state.textDisplay === true &&
													event.topText.length > 8 ? (
														<OverlayTrigger
															key="bottom"
															placement="bottom"
															overlay={
																<Tooltip id={`tooltip-disabled`}>
																	<span>{event?.topText}</span>
																</Tooltip>
															}>
															<span>{event?.topText.slice(0, 7)}</span>
														</OverlayTrigger>
													) : (
														<OverlayTrigger
															key="bottom"
															placement="bottom"
															overlay={
																<Tooltip id={`tooltip-disabled`}>
																	<span>{event?.topText}</span>
																</Tooltip>
															}>
															<span>{event?.topText}</span>
														</OverlayTrigger>
													)}
													{events.length && (
														<div
															className="empty-circle"
															style={{
																marginTop: !event?.topText && '50px',
																background: stylingData.lineColor
																	? `${stylingData.lineColor}`
																	: `${defaultSettingStyle.presAccentColor}`,
																border: stylingData.lineColor
																	? `1px solid ${stylingData.lineColor}`
																	: `1px solid ${defaultSettingStyle.presAccentColor}`,
															}}></div>
													)}
													{this.state.textDisplay === true &&
													event?.text?.length > 8 ? (
														<OverlayTrigger
															key="bottom"
															placement="bottom"
															overlay={
																<Tooltip id={`tooltip-disabled`}>
																	<span>{event?.text}</span>
																</Tooltip>
															}>
															<span>{event?.text.slice(0, 7)}</span>
														</OverlayTrigger>
													) : (
														<OverlayTrigger
															key="bottom"
															placement="bottom"
															overlay={
																<Tooltip id={`tooltip-disabled`}>
																	<span>{event?.text}</span>
																</Tooltip>
															}>
															<span>{event?.text}</span>
														</OverlayTrigger>
													)}
													{/* <Moment format={this.context.dateFormat?this.context.dateFormat:'DD-MM-YYYY'}>{event.date}</Moment> */}
													{/* <span>{event.text}</span> */}
												</div>
											);
										} else {
											return null;
										}
									})}
									<span
										className="line second"
										style={{
											top: '62px',
											background: stylingData.lineColor
												? `${stylingData.lineColor}`
												: `${defaultSettingStyle.presAccentColor}`,
										}}></span>
								</div>
								<div
									id="firstDiv"
									className="sixth-step"
									style={{ display: 'none' }}>
									<span
										className="line"
										style={{
											top: '62px',
											background: stylingData.lineColor
												? `${stylingData.lineColor}`
												: `${defaultSettingStyle.presAccentColor}`,
										}}></span>
									<div>
										<span style={{ width: '150px' }}>
											{/* {this.findWeeksLeft(event.date)} wochen vorher */}
											<Moment
												format={
													this.context.dateFormat
														? this.context.dateFormat.toUpperCase()
														: 'DD-MM-YYYY'
												}>
												{date}
											</Moment>
										</span>
										{events.length && (
											<div
												className="circle"
												style={{
													background: stylingData.lineColor
														? `${stylingData.lineColor}`
														: `${defaultSettingStyle.presAccentColor}`,
												}}></div>
										)}

										<span>{this.props.t('Present_day')}</span>
									</div>
									{/* {events.map((event, i) => {
                  if (event.name === "sixthStep") {
                    return (
                      <div key={i}>
                        <span style={{ width: "150px" }}>
                         
                          <Moment format={this.context.dateFormat?this.context.dateFormat.toUpperCase():'DD-MM-YYYY'}>{this.context?.newDate}</Moment>
                        </span>
                        {events.length && 
                          <div className="circle"></div>
                  }
                      
                      <span>Heutige Tag</span>
                        
                      </div>
                    );
                  } else {
                    return null;
                  }
                })} */}

									<span
										className="line second"
										style={{
											top: '62px',
											background: stylingData.lineColor
												? `${stylingData.lineColor}`
												: `${defaultSettingStyle.presAccentColor}`,
										}}></span>
								</div>
								<div className="fourth-step">
									<span
										className="line"
										style={{
											top: '62px',
											background: stylingData.lineColor
												? `${stylingData.lineColor}`
												: `${defaultSettingStyle.presAccentColor}`,
										}}></span>
									{events.map((event, i) => {
										if (event.name === 'fourthStep') {
											return (
												<div
													style={{
														width: '150px',
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
													key={i}>
													<span style={{ width: '150px' }}>
														{/* {this.findWeeksLeft(event.date)} wochen vorher */}

														{this.state.textDisplay === true &&
														deadlineForEntries.length > 8 ? (
															<OverlayTrigger
																key="bottom"
																placement="bottom"
																overlay={
																	<Tooltip id={`tooltip-disabled`}>
																		<Moment
																			format={
																				this.context.dateFormat
																					? this.context.dateFormat.toUpperCase()
																					: 'DD-MM-YYYY'
																			}>
																			{deadlineForEntries}
																		</Moment>
																	</Tooltip>
																}>
																<Moment
																	format={
																		this.context.dateFormat
																			? this.context.dateFormat.toUpperCase()
																			: 'DD-MM-YYYY'
																	}>
																	{deadlineForEntries.slice(0, 7)}
																</Moment>
															</OverlayTrigger>
														) : (
															<Moment
																format={
																	this.context.dateFormat
																		? this.context.dateFormat.toUpperCase()
																		: 'DD-MM-YYYY'
																}>
																{deadlineForEntries}
															</Moment>
														)}
													</span>
													{events.length &&
													this.findIfDatePassed('fourthStep') ? (
														<div
															className="empty-circle"
															style={{
																background: stylingData.lineColor
																	? `${stylingData.lineColor}`
																	: `${defaultSettingStyle.presAccentColor}`,
																border: stylingData.lineColor
																	? `1px solid ${stylingData.lineColor}`
																	: `1px solid ${defaultSettingStyle.presAccentColor}`,
															}}></div>
													) : (
														<div
															className="circle"
															style={{
																background: stylingData.lineColor
																	? `${stylingData.lineColor}`
																	: `${defaultSettingStyle.presAccentColor}`,
															}}></div>
													)}
													{this.state.textDisplay === true &&
													event?.text.length > 8 ? (
														<OverlayTrigger
															key="bottom"
															placement="bottom"
															overlay={
																<Tooltip id={`tooltip-disabled`}>
																	<span>{event?.text}</span>
																</Tooltip>
															}>
															<span>{event?.text.slice(0, 7)}</span>
														</OverlayTrigger>
													) : (
														<OverlayTrigger
															key="bottom"
															placement="bottom"
															overlay={
																<Tooltip id={`tooltip-disabled`}>
																	<span>{event?.text}</span>
																</Tooltip>
															}>
															<span>{event?.text}</span>
														</OverlayTrigger>
													)}
													{/* <span>{event.text}</span> */}
													{/* <Moment format={this.context.dateFormat?this.context.dateFormat:'DD-MM-YYYY'}>{event.date}</Moment> */}
												</div>
											);
										} else {
											return null;
										}
									})}

									<span
										className="line second"
										style={{
											top: '62px',
											background: stylingData.lineColor
												? `${stylingData.lineColor}`
												: `${defaultSettingStyle.presAccentColor}`,
										}}></span>
								</div>

								<div
									id="secondDiv"
									className="sixth-step"
									style={{ display: 'none' }}>
									<span
										className="line"
										style={{
											top: '62px',
											background: stylingData.lineColor
												? `${stylingData.lineColor}`
												: `${defaultSettingStyle.presAccentColor}`,
										}}></span>
									<div>
										<span style={{ width: '150px' }}>
											{/* {this.findWeeksLeft(event.date)} wochen vorher */}
											<Moment
												format={
													this.context.dateFormat
														? this.context.dateFormat.toUpperCase()
														: 'DD-MM-YYYY'
												}>
												{date}
											</Moment>
										</span>
										{events.length && (
											<div
												className="circle"
												style={{
													background: stylingData.lineColor
														? `${stylingData.lineColor}`
														: `${defaultSettingStyle.presAccentColor}`,
												}}></div>
										)}

										<span>{this.props.t('Present_day')}</span>
									</div>
									{/* {events.map((event, i) => {
                  if (event.name === "sixthStep") {
                    return (
                      <div key={i}>
                        <span style={{ width: "150px" }}>
                         
                          <Moment format={this.context.dateFormat?this.context.dateFormat.toUpperCase():'DD-MM-YYYY'}>{this.context?.newDate}</Moment>
                        </span>
                        {events.length && 
                          <div className="circle"></div>
                  }
                      
                      <span>Heutige Tag</span>
                        
                      </div>
                    );
                  } else {
                    return null;
                  }
                })} */}

									<span
										className="line second"
										style={{
											top: '62px',
											background: stylingData.lineColor
												? `${stylingData.lineColor}`
												: `${defaultSettingStyle.presAccentColor}`,
										}}></span>
								</div>
								<div className="fifth-step">
									<span
										className="line"
										style={{
											top: '62px',
											background: stylingData.lineColor
												? `${stylingData.lineColor}`
												: `${defaultSettingStyle.presAccentColor}`,
										}}></span>
									{events.map((event, i) => {
										if (event.name === 'fifthStep') {
											return (
												<div key={i}>
													<span className="noch">
														{this.props.t('Noch')}{' '}
														{this.findDaysLeft(eventDate)} {this.props.t('Tag')}
													</span>

													{events.length &&
													this.findIfDatePassed('fifthStep') ? (
														<div
															className="empty-circle"
															style={{
																background: stylingData.lineColor
																	? `${stylingData.lineColor}`
																	: `${defaultSettingStyle.presAccentColor}`,
																border: stylingData.lineColor
																	? `1px solid ${stylingData.lineColor}`
																	: `1px solid ${defaultSettingStyle.presAccentColor}`,
															}}></div>
													) : (
														<div
															className="circle"
															style={{
																background: stylingData.lineColor
																	? `${stylingData.lineColor}`
																	: `${defaultSettingStyle.presAccentColor}`,
															}}></div>
													)}
													{this.state.textDisplay === true &&
													event?.text.length > 8 ? (
														<OverlayTrigger
															key="bottom"
															placement="bottom"
															overlay={
																<Tooltip id={`tooltip-disabled`}>
																	<span>{event?.text}</span>
																</Tooltip>
															}>
															<span>{event?.text.slice(0, 7)}</span>
														</OverlayTrigger>
													) : (
														<OverlayTrigger
															key="bottom"
															placement="bottom"
															overlay={
																<Tooltip id={`tooltip-disabled`}>
																	<span>{event?.text}</span>
																</Tooltip>
															}>
															<span>{event?.text}</span>
														</OverlayTrigger>
													)}
													{/* <span>{event.text}</span> */}
													{/* <Moment format={this.context.dateFormat?this.context.dateFormat:'DD-MM-YYYY'}>{event.date}</Moment> */}
												</div>
											);
										} else {
											return null;
										}
									})}
									{/* <span className="line second" style={{ top: "62px" ,right:date >= this.context.eventDate && 0 }}></span> */}
								</div>
								<div
									id="thirdDiv"
									className="sixth-step"
									style={{ display: 'none' }}>
									<span
										className="line"
										style={{
											top: '62px',
											background: stylingData.lineColor
												? `${stylingData.lineColor}`
												: `${defaultSettingStyle.presAccentColor}`,
										}}></span>
									<div>
										<span style={{ width: '150px' }}>
											{/* {this.findWeeksLeft(event.date)} wochen vorher */}
											<Moment
												format={
													this.context.dateFormat
														? this.context.dateFormat.toUpperCase()
														: 'DD-MM-YYYY'
												}>
												{date}
											</Moment>
										</span>
										{events.length && (
											<div
												className="circle"
												style={{
													background: stylingData.lineColor
														? `${stylingData.lineColor}`
														: `${defaultSettingStyle.presAccentColor}`,
												}}></div>
										)}

										<span>{this.props.t('Present_day')}</span>
									</div>
									{/* {events.map((event, i) => {
                  if (event.name === "sixthStep") {
                    return (
                      <div key={i}>
                        <span style={{ width: "150px" }}>
                         
                          <Moment format={this.context.dateFormat?this.context.dateFormat.toUpperCase():'DD-MM-YYYY'}>{this.context?.newDate}</Moment>
                        </span>
                        {events.length && 
                          <div className="empty-circle"></div>
                  }
                       
                      <span>Heutige Tag</span>
                        
                      </div>
                    );
                  } else {
                    return null;
                  }
                })} */}

									{/* <span className="line second" style={{ top: "62px" }}></span> */}
								</div>
							</div>
						</div>
					) : null}
					<br />

					<div className="text-alignment">
						<p
							style={{
								// color: `${stylingData?.headingColor}`,
								color: stylingData.textColor
									? `${stylingData.textColor}`
									: `${defaultSettingStyle.presTextColor}`,
								fontFamily: stylingData.headingFontFamily
									? `${stylingData.headingFontFamily}`
									: `${defaultSettingStyle.headlineFont}`,
								fontSize: stylingData.headingFontSize
									? `${stylingData.headingFontSize}px`
									: `${defaultSettingStyle.headlineSize}px`,
							}}>
							{cuDetails?.surname1
								? `${
										cuDetails?.title1 === 'frau'
											? `${this.props.t('Sehr geehrte')}`
											: `${this.props.t('Sehr geehrter')}`
								  } ${this.props.t(
										this.functionCapitalise(cuDetails?.title1)
								  )} ${cuDetails?.surname1}${cuDetails?.surname2 ? `,` : ','} `
								: ''}

							{cuDetails?.surname2
								? `${
										cuDetails?.title2 === 'frau'
											? `${this.props.t('Sehr geehrte')}`
											: `${this.props.t('Sehr geehrter')}`
								  } ${this.props.t(
										this.functionCapitalise(cuDetails?.title2)
								  )} ${cuDetails?.surname2},`
								: ''}
						</p>
						<hr
							style={{
								height: '2px',
								color: stylingData.lineColor
									? `${stylingData.lineColor}`
									: `${defaultSettingStyle.presAccentColor}`,
								borderTop: stylingData.lineColor
									? `2px solid ${stylingData.lineColor}`
									: `2px solid ${defaultSettingStyle.presAccentColor}`,
							}}
						/>
						<div style={{ overflow: 'auto' }}>
							{/* {console.log("para",typeof this.state.paragraphs)} */}
							{typeof this.state.paragraphs == 'string' ? (
								<p
									style={{
										whiteSpace: 'pre-wrap',
										color: stylingData.textColor
											? `${stylingData.textColor}`
											: `${defaultSettingStyle.presTextColor}`,
										fontFamily: stylingData.textFontFamily
											? `${stylingData.textFontFamily}`
											: `${defaultSettingStyle.presTextFont}`,
										fontSize: stylingData.textFontSize
											? `${stylingData.textFontSize}px`
											: `${defaultSettingStyle.presTextSize}px`,
									}}>
									{this.state.paragraphs}
								</p>
							) : Object.keys(this.state.paragraphs)?.length !== 0 &&
							  !this.state.paragraphs?.blocks ? (
								this.state.paragraphs?.map((p, i) => {
									return (
										<p
											key={i}
											style={{
												whiteSpace: 'pre-wrap',
												color: stylingData.textColor
													? `${stylingData.textColor}`
													: `${defaultSettingStyle.presTextColor}`,
												fontFamily: stylingData.textFontFamily
													? `${stylingData.textFontFamily}`
													: `${defaultSettingStyle.presTextFont}`,
												fontSize: stylingData.textFontSize
													? `${stylingData.textFontSize}px`
													: `${defaultSettingStyle.presTextSize}px`,
											}}>
											{p}
										</p>
									);
								})
							) : Object.keys(this.state.paragraphs).length !== 0 &&
							  this.state.paragraphs?.blocks?.length !== 0 &&
							  this.state.paragraphs?.blocks[0]?.text !== '' ? (
								<>
									{/* {console.log("in this div")} */}
									<div
										style={{
											// color: `${stylingData?.headingColor}`,
											color: stylingData.textColor
												? `${stylingData.textColor}`
												: `${defaultSettingStyle.presTextColor}`,
											// fontFamily: `${stylingData?.headingFontFamily}`,
											// fontSize: `${stylingData?.headingFontSize}px`,
										}}
										dangerouslySetInnerHTML={{
											__html: draftToHtml(
												convertToRaw(
													EditorState.createWithContent(
														convertFromRaw(this.state.paragraphs)
													).getCurrentContent()
												)
											),
										}}></div>
								</>
							) : (
								<p
									style={{
										color: `${this.state.textColor}`,
										fontFamily: `${this.state.textFontFamily}`,
										fontSize: `${this.state.textFontSize}px`,
									}}>
									{this.props.t('No text available')}
								</p>
							)}
							{/* {this.state.paragraphs?.length ? (
                this.state.paragraphs?.map((p, i) => {
                  return (
                    <p
                      key={i}
                      style={{
                        whiteSpace: "pre-wrap",
                        color: `${stylingData.textColor}`,
                        fontFamily: `${stylingData.textFontFamily}`,
                        fontSize: `${stylingData.textFontSize}px`,
                      }}
                    >
                      {p?.text}
                    </p>
                  );
                })
              ) : (
                <p
                  style={{
                    color: `${this.state.textColor}`,
                    fontFamily: `${this.state.textFontFamily}`,
                    fontSize: `${this.state.textFontSize}px`,
                  }}
                >
                  No text available
                </p>
              )} */}
						</div>
						{/* <p className="signature">Ihr Hofgut Lilienhof Team</p> */}

						<div className="links-container">
							{
								this.context.userRole !== 'CU' ? (
									<Link
										style={{
											marginTop: '10px',
											border: stylingData.lineColor
												? `1px solid ${stylingData.lineColor}`
												: `1px solid ${defaultSettingStyle.presAccentColor}`,
											color: stylingData.lineColor
												? `${stylingData.lineColor}`
												: `${defaultSettingStyle.presAccentColor}`,
										}}
										className="green-border-btn"
										to="/admin/events">
										{this.props.t('return')}
									</Link>
								) : (
									<Link
										style={{
											marginTop: '10px',
											background: stylingData.lineColor
												? `${stylingData.lineColor}`
												: `${defaultSettingStyle.presAccentColor}`,
											border: stylingData.lineColor
												? `1px solid ${stylingData.lineColor}`
												: `1px solid ${defaultSettingStyle.presAccentColor}`,
										}}
										className="green-border-btn"
										to="/login-landing"
										//  onClick={this.handleBack}
									>
										{this.props.t('return')}
									</Link>
								)
								//    <Link className="green-border-btn" to="/login-landing">
								// Zurück
								//   </Link>
							}

							{/* <a
                className="green-filled-btn"
                rel="noopener noreferrer"
                href={`http://${stylingData?.link}`}
                target="_blank"
              >
                Nütlich zu Wissen
              </a> */}
							{/* <button className="green-filled-btn" onClick={this.handleLink} >
            Nütlich zu Wissen
          </button> */}
							<Link
								style={{
									marginTop: '10px',
									background:
										//    stylingData?.lineColor != '#000' ?
										stylingData.lineColor
											? `${stylingData.lineColor}`
											: `${defaultSettingStyle.presAccentColor}`,
									//:
									// `${this.state.defaultSettingStyle.presAccentColor}`,
									border: stylingData.lineColor
										? `1px solid ${stylingData.lineColor}`
										: `1px solid ${defaultSettingStyle.presAccentColor}`,
								}}
								className="green-filled-btn"
								// to="/questionnaire-preview"
								to={{
									pathname: '/questionnaire-preview',
									state: this.state.defaultSettingStyle,
								}}
								//         onClick={()=>{
								//           this.context.updateState({
								//   eventId:obj?.eventId ,
								//   eventName: obj?.eventName,
								//   eventDate: obj?.eventDate,
								//   eventType: obj?.eventType,
								//   deadlineForEntries: obj?.deadlineForEntries,
								//   newDate: obj?.newDate,
								//   questionnaireId: obj?.childQuestionnaireId,
								//   parentQuestionnaireId: obj?.parentQuestionnaireId,
								// });
								//         }}
							>
								{this.props.t('for_planning')}
							</Link>
							<br></br>
							<br></br>
							<br></br>
						</div>
					</div>
				</div>

				<RightBar obj={obj2} />
				<PullBar guestPriceObj={this.state.guestPriceObj} />
			</div>
		);
	}
}

export default withTranslation()(withRouter(EventLanding));

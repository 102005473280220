import React, {Component} from 'react';
import {StateContext} from "../../context/state";
import magnifyingGlass from "../../assets/magnifying-glass.svg";
import AutoSpinner from "../partials/AutoSpinner";
import crossIcon from "../../assets/cross.svg";
import { withTranslation} from 'react-i18next';

 class Tag extends Component {
    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {
            hovered: false,
        }
    }

    render() {
        const {isDisabled, options, onSelect, onRemove, onChange, index, name} = this.props;
        const {hovered} = this.state;

        return (
            <div key={index} style={styles.tag}>
                <div
                    style={styles.search}
                    onMouseEnter={() => this.setState({hovered: true})}
                    onMouseLeave={() => this.setState({hovered: false})}
                >
                    {hovered && !isDisabled &&
                    <img style={styles.closeIcon} src={crossIcon} onClick={onRemove} alt="" />}
                    <AutoSpinner
                        placeholder={this.props.t("Tag einfügen")}
                        style={styles.input}
                        options={options}
                        value={name}
                        onSelect={(value) => onSelect(value)}
                        onChange={(name) => onChange(name)}
                    />
                    <img
                        src={magnifyingGlass}
                        style={styles.icon}
                        alt=""
                    />
                </div>
            </div>);
    }
}

export default withTranslation() (Tag);
const styles = {
    tag: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: '24px'
    },
    search: {
        width: '90%',
        background: "#e7e7e8",
        padding: "10px 5px",
        alignItems: "center",
        display: "flex",
        color: "#939599",
        position: "relative"
    },
    input: {
        border: 0,
        padding: "0 5px",
        margin: 0,
        background: "transparent",
        width: "100%",
        flexGrow: 1,
        fontSize: "14px",
    },
    icon: {
        width: "18px",
        height: "18px",
        opacity: 0.9,
        margin: "2px",
        cursor: "pointer",
    },
    closeIcon: {
        position: 'absolute',
        top: 2,
        right: 2,
        width: '18px',
        height: '18px',
        cursor: 'pointer',
    }
};


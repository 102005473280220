import React, { useState, useEffect, useContext } from "react";
import { baseUrl } from "../../Constants";
import Dropzone from "react-dropzone";
import upload from "../../assets/upload.svg";
import { ChromePicker } from "react-color";
import "../../venueSettingStyles.css";
import axios from "axios";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { StateContext } from "../../context/state";
import logoutIcon from "../../assets/logout.svg";
import swal from "sweetalert";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const imageMaxSize = 10240;

export default function VenueInfoPage(props) {
  const { t } = useTranslation();
  const [time, setTime] = useState("24");
  const [date, setDate] = useState("DD/MM/YY");
  const [timeFormats, setTimeFormats] = useState([]);
  const [dateFormats, setDateFormats] = useState([]);
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false);
  const [timeDropDownOpen, toggleTimeDropdown] = useState(false);
  const [fontDropDownOpen, toggleFontDropdown] = useState(false);
  const [fonts, setFonts] = useState([]);
  const [venueName, setVenueName] = useState("");
  const [venueURL, setvenueURL] = useState("");
  const [venueText, setVenueText] = useState("");
  const [validateVenueName, setValidateVenueName] = useState(true);
  const [validateWelcomeText, setValidateWelcomeText] = useState(true);
  const [validateWelcomeTextFont, setValidateWelcomeTextFont] = useState(true);
  const [validateBackgroundImg, setValidateBackgroundImg] = useState(true);
  const [validateVenueLogo, setValidateVenueLogo] = useState(true);
  const [welcomeText, setWelcomeText] = useState("");
  const [welcomeTextColor, setWelcomeTextColor] = useState("#fff");
  const [WelcomeTextColorPicker, setWelcomeTextColorPicker] = useState(false);
  const [selectedFont, setselectedFont] = useState("");
  const [backgroundImgSrc, setBackgroundImgSrc] = useState(null);
  const [venueLogoSrc, setVenueLogoSrc] = useState(null);
  const [paragraphs, setParagraphs] = useState([]);
  const[currency,setCurrency]= useState("€");
  const [language, setLanguage] = useState("en");
  const [invoice, setInvoice] = useState("");




  const value = useContext(StateContext);
  // const venueId=value.venueId?value.venueId:null

  useEffect(() => {
    axios
      .get(baseUrl + "api/lookup/VenueSettingScreen")
      .then((response) => {
        for (let i in response.data) {
          if (i === "dateFormats") {
            setDateFormats(response.data[i]);
          }
          if (i === "timeFormats") {
            setTimeFormats(response.data[i]);
          }
          if (i === "fonts") {
            setFonts(response.data[i]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    if (props?.location?.state?.venue) {
      axios
        .get(`${baseUrl}api/venue/${props.location.state.venue._id}`)
        .then((response) => {
          const data = response.data;
          // console.log('response', data)
          setDate(data.dateFormat);
          setBackgroundImgSrc(data.backgroundImgSrc);
          setTime(data.timeFormat);
          setvenueURL(data.venueURL);
          setVenueName(data.venueName);
          setVenueLogoSrc(data.venueLogoSrc);
          setWelcomeText(data.welcomeText);
          setWelcomeTextColor(data.welcomeTextColor);
          setselectedFont(data.welcomeTextFont);
          setParagraphs(data.paragraphs ? data.paragraphs : []);
          setCurrency(data.currencyFormat);
          setLanguage(data.languageFormat);
          setInvoice(data.invoice)

        })
        .catch((err) => {
          // console.log(err)
        });
    }
  }, [props.location.state]);

  const handleSave = () => {
    const formData = {
      venueURL: venueURL,
      venueName: venueName,
      timeFormat: time,
      dateFormat: date,
      welcomeText: welcomeText,
      welcomeTextColor: welcomeTextColor,
      welcomeTextFont: selectedFont,
      backgroundImgSrc: backgroundImgSrc,
      venueLogoSrc: venueLogoSrc,
      paragraphs: paragraphs,
      currencyFormat:currency,
      languageFormat:language,
      invoice:invoice,
    };
    let validate = true;
    for (let i in formData) {
      if (i === "venueURL" && !formData[i]) {
        validate = false;
        setValidateVenueName(false);
      }
      if (i === "venueName" && !formData[i]) {
        validate = false;
        setValidateVenueName(false);
      }
      if (i === "welcomeText" && !formData[i].length) {
        validate = false;
        setValidateWelcomeText(false);
      }
      if (i === "welcomeTextFont" && !formData[i].length) {
        validate = false;
        setValidateWelcomeTextFont(false);
      }
      if (i === "backgroundImgSrc" && !formData[i]) {
        validate = false;
        setValidateBackgroundImg(false);
      }
      if (i === "venueLogoSrc" && !formData[i]) {
        validate = false;
        setValidateVenueLogo(false);
      }
    }

    if (validate) {
      const nameWidoutSpace = venueURL.trim().split(" ").join("");
      let venueData = {
        venueURL: nameWidoutSpace,
        venueName: venueName,
        timeFormat: time,
        dateFormat: date,
        welcomeText: welcomeText,
        welcomeTextColor: welcomeTextColor,
        welcomeTextFont: selectedFont,
        backgroundImgSrc: backgroundImgSrc,
        venueLogoSrc: venueLogoSrc,
        paragraphs: paragraphs,
        isDeleted: false,
        currencyFormat:currency,
        languageFormat:language,
        invoice:invoice,
      };

      if (props?.location?.state?.venue) {
        // console.log('put data', venueData)
        axios
          .put(
            baseUrl + `api/venue/${props.location.state.venue._id}`,
            venueData
          )
          .then((response) => {
            // console.log('post venue done', response.data)
            if (response.data.error) {
              setValidateVenueName(false);
              setVenueText(response.data.error);
              swal(`${response.data.error}!!`, "", "warning");
            } else {
              swal("Gespeichert!!", "", "success");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        //  console.log('post data', venueData)
        axios
          .post(baseUrl + "api/venue", venueData)
          .then((response) => {
            // console.log('post venue done', response.data)
            if (response.data.error) {
              setValidateVenueName(false);
              setVenueText(response.data.error);
              swal(`${response.data.error}!!`, "", "warning");
            } else {
              swal("Gespeichert!!", "", "success");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileSize = Math.round(currentFile.size / 1024);
      if (currentFileSize > imageMaxSize) {
       // swal(`Diese Datei ist zu groß`, "", "info");
        swal(t('Bilder bis 15 MB sind erlaubt'), "", "info");
        return false;
      }
      return true;
    }
  };

  const handleOnDropBackgroundImg = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyImageFile(rejectedFiles);
    }

    if (acceptedFiles && acceptedFiles.length > 0) {
      const isVerified = verifyImageFile(acceptedFiles);
      if (isVerified) {
        const currentFile = acceptedFiles[0];
      
        let img = new Image()
        img.src = window.URL.createObjectURL(acceptedFiles[0])
       
  img.onload = () => {
     if(img.width >= 1280 && img.height >= 720){
      const data = new FormData();
      data.append("blob", currentFile);
      axios
        .post(baseUrl + `api/blob/${3}`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setBackgroundImgSrc(res.data.url);
          setValidateBackgroundImg(true);
        })
        .catch((error) => {
          console.log(error);
        });
     }
     else{
      alert(t(`Bitte laden Sie hier nur Bilder mit einer größeren Auflösung als 1280x720 Pixel hoch`));
     }
  }
      
      }
    }
  };
  const handleOnDropVenueLogo = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyImageFile(rejectedFiles);
    }

    if (acceptedFiles && acceptedFiles.length > 0) {
      const isVerified = verifyImageFile(acceptedFiles);
      if (isVerified) {
        const currentFile = acceptedFiles[0];
        const data = new FormData();
        data.append("blob", currentFile);
        axios
          .post(baseUrl + `api/blob/${3}`, data, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            setVenueLogoSrc(res.data.url);
            setValidateVenueLogo(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  const handleShowWelcomeTextColorPicker = () => {
    setWelcomeTextColorPicker(!WelcomeTextColorPicker);
  };

  // const handleWelcomeTextColorPicker = updatedColor => {
  //   setWelcomeTextColor(welcomeTextColor)
  // };

  const handleBack = () => {
    props.history.push(`/admin/venues`);
  };
  const handleLogout = () => {
    const urlName = value.urlName;
    value.updateState({
      authenticated: false,
      from:"logout"

    });
    localStorage.clear();
    sessionStorage.clear();
    if (urlName === "adminScreen") {
      props.history.push(`/login`);
    } else {
      props.history.push(`/login/${urlName}`);
    }
  };
  return (
    <div
      style={{
        fontFamily: "Crete Round",
        color: "#302f2f",
        fontStyle: "italic",
        marginLeft: "30px",
      }}
    >
      <OverlayTrigger
        key="bottom"
        placement="bottom"
        overlay={
          <Tooltip id={`tooltip-disabled`}>
            <span> {t('logout')}</span>
          </Tooltip>
        }
      >
        <button
          className="logout-btn"
          style={{
            border: "none",
            marginTop: "10px",
          }}
          onClick={handleLogout}
        >
          <img src={logoutIcon} alt="" />
        </button>
      </OverlayTrigger>
      <br />
      <br />
      <button className="button" onClick={handleSave}>
      {t('save')}
      </button>

      <button className="button" onClick={handleBack}>
      {t('return')}
      </button>
      <br />
      <div style={{ margin: 30 }}>
        <h4 className="heading-style">{t('Übergreifend')}</h4>
        <div style={{ display: "flex" }}>
          <Dropdown
            direction="down"
            name="date"
            isOpen={dateDropdownOpen}
            toggle={() => setDateDropdownOpen((prevState) => !prevState)}
          >
            <DropdownToggle caret className="dropdown1">
              {date.length ? `${date}` : " Date Format"}
            </DropdownToggle>
            <DropdownMenu className="dropdown1">
              {dateFormats.map((d) => {
                return (
                  <DropdownItem
                    name="date"
                    onClick={() => setDate(d)}
                    key={d}
                    value={d}
                  >
                    {d}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
          &nbsp;
          <Dropdown
            direction="down"
            name="time"
            isOpen={timeDropDownOpen}
            toggle={() => toggleTimeDropdown((prevState) => !prevState)}
          >
            <DropdownToggle caret className="dropdown1">
              {time ? `${time} h` : "time"}
            </DropdownToggle>
            <DropdownMenu className="dropdown1">
              {timeFormats.map((d) => {
                return (
                  <DropdownItem
                    name="time"
                    onClick={() => setTime(d)}
                    key={d}
                    value={d}
                  >
                    {d}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
          {"  "}
          &nbsp;
        </div>
        <br />
        <div className="row">
          <div style={{ margin: "20px" }}>
            <h4 className="heading-style">{t('Allgemein')}</h4>
            <h6>{t('Begrüßungsseite für mehrfachen Events in der Zukunft')}</h6>
            {/* <button className="button">Begrüßungsseite bearbelten</button> */}
            <br />
            <br />
            <h6 className="heading-style">{t('Loginfenster allgemein')}</h6>
            <br />
            <form>
              <div style={{ margin: "10px" }}>
                <input
                  type="text"
                  name="venueURL"
                  className="welcomeText"
                  value={venueURL}
                  onChange={(e) => {
                    setvenueURL(e.target.value.toLowerCase());
                    setValidateVenueName(true);
                  }}
                  placeholder="Url Name"
                  autoComplete="off"
                />
                <br />
                <small>{t('Url Name')}</small>
                <br />
                {!validateVenueName && (
                  <small style={{ color: "red" }}>
                    {venueText ? venueText : "* Required field"}
                  </small>
                )}
                <br />
              </div>
              <div style={{ margin: "10px" }}>
                <input
                  type="text"
                  name="venueName"
                  className="welcomeText"
                  value={venueName}
                  onChange={(e) => {
                    setVenueName(e.target.value.replace(/[^A-Z@~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-]*$/i, ''));
                    setValidateVenueName(true);
                  }}
                  placeholder="Venue Name"
                  autoComplete="off"
                />
                <br />
                <small>{t('Venue Name')}</small>
                <br />
                {!validateVenueName && (
                  <small style={{ color: "red" }}>
                    {venueText ? venueText : "* Required field"}
                  </small>
                )}
                <br />
              </div>
              <div style={{ display: "flex" }}>
                <div className="col-4">
                  <input
                    type="text"
                    name="welcomeText"
                    className="welcomeText"
                    value={welcomeText}
                    onChange={(e) => {
                      setWelcomeText(e.target.value);
                      setValidateWelcomeText(true);
                    }}
                    placeholder={t("Greeting")}
                    autoComplete="off"
                  />
                  <br />
                  <small style={{ marginLeft: "-15px" }}>
                  {t('Überschitt Login')}  
                  </small>
                  <br />
                  {!validateWelcomeText && (
                    <small style={{ color: "red" }}> * {t('Required_field')}</small>
                  )}
                </div>
                &nbsp;
                <div className="col-4">
                  <div className="underlined-div">
                    <input
                      type="text"
                      className="colorSelection"
                      placeholder={welcomeTextColor}
                      onClick={handleShowWelcomeTextColorPicker}
                      // onBlur={handleShowWelcomeTextColorPicker}
                      autoComplete="off"
                    />
                    <div
                      className="colorDisplay"
                      style={{
                        backgroundColor: welcomeTextColor,
                      }}
                      onClick={handleShowWelcomeTextColorPicker}
                    ></div>
                    {"    "}
                    &nbsp;
                    {WelcomeTextColorPicker && (
                      <ChromePicker
                        className="color-picker"
                        color={welcomeTextColor}
                        onChange={(color) => {
                          setWelcomeTextColor(color.hex);
                        }}
                        onBlur={handleShowWelcomeTextColorPicker}
                      />
                    )}
                  </div>
                  <small>{t('Heading_color')}</small>
                </div>
                <div className="col-4">
                  <Dropdown
                    direction="down"
                    name="selectedFont"
                    isOpen={fontDropDownOpen}
                    toggle={() => toggleFontDropdown(!fontDropDownOpen)}
                  >
                    <DropdownToggle
                      caret
                      className="dropdown1"
                      style={{ width: "160px" }}
                    >
                      {selectedFont.length ? `${selectedFont}` : "Font"}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown1">
                      {fonts.map((d) => {
                        return (
                          <DropdownItem
                            name="selectedFont"
                            onClick={() => {
                              setselectedFont(d);
                              setValidateWelcomeTextFont(true);
                            }}
                            key={d}
                            value={d}
                          >
                            {d}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                  {!validateWelcomeTextFont && (
                    <small style={{ color: "red" }}> * {t('Required_field')}</small>
                  )}
                </div>
              </div>
              <br />
              <br />
              <div className="row">
                <div className="col-6">
                  <Dropzone
                    onDrop={handleOnDropBackgroundImg}
                    accept="image/*"
                    // maxSize={imageMaxSize}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <div
                          {...getRootProps()}
                          style={{
                            border: "2px dashed gray",
                            width: 260,
                            height: 210,
                          }}
                        >
                          <input {...getInputProps()} autoComplete="off" />
                          {backgroundImgSrc !== null ? (
                            <img
                              src={backgroundImgSrc}
                              style={{
                                width: 250,
                                height: 200,
                              }}
                              alt="Background"
                            />
                          ) : (
                            <div style={{ padding: 40 }}>
                              <img
                                src={upload}
                                style={{
                                  margin: "10px 40px",
                                  // width:200, height:200
                                }}
                                alt="Uploading"
                              />
                              <small style={{ marginLeft: "17px" }}>
                               {t(`Ziehen Sie hier Dateien per Drag & Drop`)}
                              </small>
                              {/* <button className="dropzone_button" >select files</button> */}
                            </div>
                          )}
                        </div>
                        <p>{t("Hintergrundbild furs Einloggen")}</p>
                        {!validateBackgroundImg && (
                          <small style={{ color: "red" }}>
                            * {t("Bild auswählen")}
                          </small>
                        )}
                         <span style={{fontSize:"13px"}}>*{t("Bitte laden Sie hier nur Bilder mit einer größeren Auflösung als 1280x720 Pixel hoch")}</span>
           <br/>
                      </>
                    )}
                  </Dropzone>
                </div>
                <div className="col-6">
                  <Dropzone
                    onDrop={handleOnDropVenueLogo}
                    accept="image/*"
                    // maxSize={imageMaxSize}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} autoComplete="off" />
                          <div
                            style={{
                              border: "2px dashed gray",
                              width: 260,
                              height: 210,
                            }}
                          >
                            {venueLogoSrc !== null ? (
                              <img
                                src={venueLogoSrc}
                                style={{ width: 250, height: 200 }}
                                alt="Venue Logo"
                              />
                            ) : (
                              <div style={{ padding: 40 }}>
                                <img
                                  src={upload}
                                  style={{ margin: "10px 40px" }}
                                  alt="Upload"
                                />
                                <small style={{ marginLeft: "17px" }}>
                                {t(`Ziehen Sie hier Dateien per Drag & Drop`)}
                                </small>
                                {/* <button className="dropzone_button" >select files</button> */}
                              </div>
                            )}
                          </div>
                          <p>{t("Logo furs Login hochladen")}</p>
                          {!validateVenueLogo && (
                            <small style={{ color: "red" }}>
                              * {t("Bild auswählen")}
                            </small>
                          )}
                        </div>
                      </>
                    )}
                  </Dropzone>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { Component } from "react";
import { StateContext } from "../../context/state";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "../../venueSettingStyles.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { CSVLink } from "react-csv";
import { DateRange } from "react-date-range";
// import plusIcon from "../../assets/plus.svg";
import cross from "../../assets/cross.svg";
import exp from "../../assets/sm-export.svg";
import Moment from "react-moment";
import moment from "moment";
import "moment-timezone";
import axios from "axios";
import { baseUrl } from "../../Constants";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import HttpService from "../../services/HttpService";
import * as rdrLocales from 'react-date-range/dist/locale';
import {monthsDisplay} from "../../Constants";
import { withTranslation} from 'react-i18next';

 class Graph2 extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      showParameters: false,
      units: ["year", "month", "day", "week"],
      selectedUnit: "year",
      showUnit: false,
      selectedPara: {},
      initialData: {},
      parameters: [],
      listOfQuest: [],
      list: [],
      listOfEvents: [],
      startDate: new Date(),
      endDate: new Date(),
      data: [
        {
          startDate: null,
          endDate: null,
          key: "selection",
        },
      ],
      // dateRange:'11-02-2020',
      displayDateRange: false,
      graphFig: [],
      showDropDown: false,
      optionsForGraph1: {},
      dummyXaxis: [],
      options: {
        colors: ["#bec896", " #c797a7", "#94c7c1"],
        chart: {
          type: "column",
        },
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          crosshair: true,
        },
        yAxis: {
          allowDecimals: false,
          min: 0,
          title: {
            text: "",
          },
        },

        // tooltip: {
        //     headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        //     pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        //         '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
        //     footerFormat: '</table>',
        //     shared: true,
        //     useHTML: true
        // },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "bottom",
          // x: 5,
          // y:270,
          floating: false,
        },
        series: [],
      },
    };
  }
  componentDidMount=async()=> {
    const data = this.context;
    //   console.log('id', data)
    var listOfQuest=[]
    axios
      .get(`${baseUrl}api/report`)
      .then((res) => {
        this.setState({
          parameters: res.data.reportParam,
        });
      })
      .catch((err) => {
        console.log(err);
      });
      listOfQuest= await HttpService.getqtnnqireListing(data.venueId)
    axios
      .get(`${baseUrl}api/Chartdata/${this.context.loggedInUserId}/${2}`)
      .then((res) => {
        Object.assign([], this.state.listOfQuest);
        if (!res.data) { return; }
        res.data.questionnaireIds.split(",");

        const dummy = Object.assign([], this.state.parameters);

        dummy.filter(
          (dum) => dum?.id === res.data?.keyFigId
        );

        // console.log("this.state.listOfQuest",this.state.listOfQuest,"dummmy2",dummy2)
        this.setState({
          initialData: res.data,
          startDate: res.data?.fromDate,
          endDate: res.data?.toDate,
          selectedUnit: res.data?.timeUnit,
        //   selectedPara: { id: initialPara[0]?.id, name: initialPara[0]?.name },
          selectedPara: res.data?.keyFig ?? {},
          // listOfQuest: dummy2,
        });
        const dummyarray= res.data?.questionnaire?.map((q)=>{
          return q._id
      })
      // console.log('listOfQuest', listOfQuest)
      // this.addFiguresInitial(dummyarray, res.data.questionnaire, listOfQuest)
      if(res.data?.questionnaire){
        this.addFiguresInitial(dummyarray, res.data.questionnaire, listOfQuest)
    }
    else{
        this.setState({
            listOfQuest
        })
    }
        // for(let quest of res.data.questionnaire){
        //     this.addFigures(quest)
        // }
        // for (let quest of question) {
        //   array = dummy2.filter((dum) => dum._id == quest);
        //   let value;

        //   if (array.length) {
        //     array.map((arr) => {
        //       //   console.log("ara",arr)

        //       value = { _id: arr._id, name: arr.name };

        //       if (Object.keys(value).length !== 0) {
        //         this.addFigures(value);
        //       }
        //     });
        //   }
        // }
      })
      .catch((err) => {
        console.log(err);
        this.setState({listOfQuest})
      });
  }
  addFiguresInitial=(value, qtnnairs, dummy2)=>{
    //  console.log('id', value)//{name:"", _id:""}
const {startDate, endDate, selectedUnit,selectedPara,graphFig}=this.state
const from=moment(startDate).format("YYYY-MM-DD")+'T01:11:11Z'
const to=moment(endDate).format("YYYY-MM-DD")+'T01:11:11Z'
let questionnaireIds=graphFig.map((g)=>{
        return g._id
})
questionnaireIds=value
// console.log('here', questionnaireIds) //["id1","id2","id3"]
const data=this.context
if(startDate && endDate && selectedUnit && Object.keys(selectedPara)?.length && questionnaireIds.length){
  
const postData={
    "venueId":data.venueId,
    "questionnaireIds":questionnaireIds.join(","),
    "fromDate":from,
    "toDate":to,
    "timeUnit":selectedUnit,
    "keyFigId":selectedPara.id,
    "userRole":data.userRole
}


// console.log('postData', postData)
axios.post(`${baseUrl}api/report/QuestionnaireReport`, postData)
    .then((res)=>{
        //  console.log(res.data)
        const options=Object.assign([],this.state.options)
        // console.log('optns', options)

        options.series=res.data.report
        this.setState({
                options,
                dummyXaxis:res.data["x-axis"]
        },()=>{
            if(selectedUnit==="year"){
                this.calculateYears()
            }
            else if(selectedUnit==="month"){
                this.calculateMonths()
            }
            else if(selectedUnit==="week"){
                this.calculateWeeks()
            }
            else if(selectedUnit==="day"){
                this.calculateDays()
            }
        })
    })
    .catch((err)=>{
        console.log(err)
    })

  //  if(Object.keys(this.state.initialData).length===0){
  //   axios.post(`${baseUrl}api/Chartdata`, postData1)
  //   .then((res)=>{
  //       //  console.log(res.data)
  //       this.setState({
  //           initialData:postData1
  //       })
  //   })
  //   .catch((err)=>{
  //       console.log(err)
  //   })
  //   }
  //   else{
  //       axios.put(`${baseUrl}api/Chartdata/${this.context.loggedInUserId}/${1}`, postData1)
  //       .then((res)=>{
  //           //  console.log(res.data)
            
  //       })
  //       .catch((err)=>{
  //           console.log(err)
  //       })
        
  //      }
   }
  
 
//  console.log('quest1',dummy2, qtnnairs)
  for(let quest of qtnnairs){
    dummy2=dummy2.filter((q)=>{
            return q._id!==quest._id
        })
    }
    // console.log('quest111', listOfQuest1, dummy2)
this.setState({
            graphFig:qtnnairs,
            listOfQuest:dummy2
    })

}
  showDateRange = () => {
    this.setState({
      displayDateRange: !this.state.displayDateRange,
    });
  };
  calculateYears = () => {
    //    console.log('oi', Number(moment(this.state.startDate).format("YYYY")))
    //    const start=Number(moment(this.state.startDate).format("YYYY"))
    //    const end=Number(moment(this.state.endDate).format("YYYY"))
    //    let range=[]
    //    for(let i=start; i<=end; i++){
    //     range.push(i)
    //    }
    //    console.log('range', range)
    const dummyXaxis = Object.assign([], this.state.dummyXaxis);
    const options = Object.assign([], this.state.options);

    // console.log("dummy", dummyXaxis)
    options["xAxis"]["categories"] = dummyXaxis;
    this.setState({
      options,
    });
  };
  calculateMonths = () => {
    const dummyXaxis = Object.assign([], this.state.dummyXaxis);
    const options = Object.assign([], this.state.options);
    const dummy = dummyXaxis[0].map((d) => {
      return monthsDisplay(d.Month);
    });

    // console.log("dummy",dummyXaxis, dummy)
    options["xAxis"]["categories"] = dummy;
    this.setState({
      options,
    });
  };
  calculateWeeks = () => {
    //     const dummyXaxis=Object.assign([], this.state.dummyXaxis)
    //     const options=Object.assign([], this.state.options)
    //    const x=options.series[0].data.length
    //    for(let i=0; i<=x; i++){
    //    }
  };
  calculateDays = () => {};
  addFigures = (value) => {
    const {
      startDate,
      endDate,
      selectedUnit,
      selectedPara,
      graphFig,
    } = this.state;
    const from = moment(startDate).format("YYYY-MM-DD") + "T01:11:11Z";
    const to = moment(endDate).format("YYYY-MM-DD") + "T01:11:11Z";
    let questionnaireIds = graphFig.map((g) => {
      return g._id;
    });
    questionnaireIds = questionnaireIds.concat(value._id);
    // console.log('here', questionnaireIds)
    const data = this.context;
    if (
      startDate &&
      endDate &&
      selectedUnit &&
      Object.keys(selectedPara)?.length &&
      questionnaireIds.length
    ) {
      const postData = {
        venueId: data.venueId,
        questionnaireIds: questionnaireIds.join(","),
        fromDate: from,
        toDate: to,
        timeUnit: selectedUnit,
        keyFigId: selectedPara.id,
        userRole: data.userRole,
      };
      const postData1 = {
        venueId: data.venueId,
        userId: data.loggedInUserId,
        questionnaireIds: questionnaireIds.join(","),
        fromDate: from,
        toDate: to,
        timeUnit: selectedUnit,
        keyFigId: selectedPara.id,
        userRole: data.userRole,
        graphType: "2",
      };
      // console.log('postData', postData)
      axios
        .post(`${baseUrl}api/report/QuestionnaireReport`, postData)
        .then((res) => {
          // console.log(res.data)
          const options = Object.assign([], this.state.options);
          // console.log('optns', options)
          options.series = res.data.report;
          this.setState(
            {
              options,
              dummyXaxis: res.data["x-axis"],
            },
            () => {
              if (selectedUnit === "year") {
                this.calculateYears();
              } else if (selectedUnit === "month") {
                this.calculateMonths();
              } else if (selectedUnit === "week") {
                this.calculateWeeks();
              } else if (selectedUnit === "day") {
                this.calculateDays();
              }
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
      if (Object.keys(this.state.initialData).length === 0) {
        axios
          .post(`${baseUrl}api/Chartdata`, postData1)
          .then((res) => {
            //  console.log(res.data)
            this.setState({
              initialData:postData1
          })
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .put(
            `${baseUrl}api/Chartdata/${this.context.loggedInUserId}/${2}`,
            postData1
          )
          .then((res) => {
            //  console.log(res.data)
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    this.setState((prevState) => {
      return {
        graphFig: prevState.graphFig.concat(value),
        listOfQuest: prevState.listOfQuest.filter((q) => {
          return q !== value;
        }),
      };
    });
  };
  handleShowDropdown = () => {
    this.setState({
      showDropDown: !this.state.showDropDown,
    });
  };
  handleShowParameters = () => {
    this.setState({
      showParameters: !this.state.showParameters,
    });
  };
  setRange = (item) => {
    const startDate = item.selection.startDate;
    const endDate = item.selection.endDate;
    if (item.selection.startDate !== item.selection.endDate) {
      this.setState({
        displayDateRange: !this.state.displayDateRange,
        data: [item.selection],
        startDate,
        endDate,
      });
    } else {
      this.setState({
        data: [item.selection],
      });
    }
    const { selectedUnit, selectedPara, graphFig } = this.state;
    let questionnaireIds = graphFig.map((g) => {
      return g._id;
    });
    if (
      startDate &&
      endDate &&
      selectedUnit &&
      Object.keys(selectedPara)?.length && 
      questionnaireIds.length
    ) {
      const from = moment(startDate).format("YYYY-MM-DD") + "T01:11:11Z";
      const to = moment(endDate).format("YYYY-MM-DD") + "T01:11:11Z";
      
      // questionnaireIds=questionnaireIds.concat(value._id)
      // console.log('here', questionnaireIds)
      const data = this.context;

      // console.log(data.venueId, questionnaireIds,from, to, selectedUnit, selectedPara.id)
      const postData = {
        venueId: data.venueId,
        questionnaireIds: questionnaireIds.join(","),
        fromDate: from,
        toDate: to,
        timeUnit: selectedUnit,
        keyFigId: selectedPara.id,
        userRole: data.userRole,
      };
      const postData1 = {
        venueId: data.venueId,
        userId: data.loggedInUserId,
        questionnaireIds: questionnaireIds.join(","),
        fromDate: from,
        toDate: to,
        timeUnit: selectedUnit,
        keyFigId: selectedPara.id,
        userRole: data.userRole,
        graphType: "2",
      };
      // console.log('postData', postData)
      axios
        .post(`${baseUrl}api/report/QuestionnaireReport`, postData)
        .then((res) => {
          // console.log(res.data)
          const options = Object.assign([], this.state.options);
          // console.log('optns', options)
          options.series = res.data.report;
          this.setState(
            (prevState) => {
              return {
                options,
                dummyXaxis: res.data["x-axis"],
              };
            },
            () => {
              if (selectedUnit === "year") {
                this.calculateYears();
              } else if (selectedUnit === "month") {
                this.calculateMonths();
              } else if (selectedUnit === "week") {
                this.calculateWeeks();
              } else if (selectedUnit === "day") {
                this.calculateDays();
              }
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
      if (Object.keys(this.state.initialData).length === 0) {
        axios
          .post(`${baseUrl}api/Chartdata`, postData1)
          .then((res) => {
            //  console.log(res.data)
            this.setState({
              initialData:postData1
          })
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .put(
            `${baseUrl}api/Chartdata/${this.context.loggedInUserId}/${2}`,
            postData1
          )
          .then((res) => {
            //  console.log(res.data)
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  handleUnits = () => {
    this.setState({ showUnits: !this.state.showUnits });
  };
  selectUnit = (u) => {
    this.setState({
      selectedUnit: u,
    });
    const {
      startDate,
      endDate,
      selectedUnit,
      selectedPara,
      graphFig,
    } = this.state;
    let questionnaireIds = graphFig.map((g) => {
      return g._id;
    });
    if (
      startDate &&
      endDate &&
      selectedUnit &&
      Object.keys(selectedPara)?.length && 
      questionnaireIds.length
    ) {
      const from = moment(startDate).format("YYYY-MM-DD") + "T01:11:11Z";
      const to = moment(endDate).format("YYYY-MM-DD") + "T01:11:11Z";
     
      // questionnaireIds=questionnaireIds.concat(value._id)
      // console.log('here', questionnaireIds)
      const data = this.context;

      // console.log(data.venueId, questionnaireIds,from, to, selectedUnit, selectedPara.id)
      const postData = {
        venueId: data.venueId,
        questionnaireIds: questionnaireIds.join(","),
        fromDate: from,
        toDate: to,
        timeUnit: u,
        keyFigId: selectedPara.id,
        userRole: data.userRole,
      };
      const postData1 = {
        venueId: data.venueId,
        userId: data.loggedInUserId,
        questionnaireIds: questionnaireIds.join(","),
        fromDate: from,
        toDate: to,
        timeUnit: selectedUnit,
        keyFigId: selectedPara.id,
        userRole: data.userRole,
        graphType: "2",
      };
      // console.log('postData', postData)
      axios
        .post(`${baseUrl}api/report/QuestionnaireReport`, postData)
        .then((res) => {
          // console.log(res.data)
          const options = Object.assign([], this.state.options);
          // console.log('optns', options)
          options.series = res.data.report;
          this.setState(
            (prevState) => {
              return {
                options,
                dummyXaxis: res.data["x-axis"],
              };
            },
            () => {
              if (u === "year") {
                this.calculateYears();
              } else if (u === "month") {
                this.calculateMonths();
              } else if (u === "week") {
                this.calculateWeeks();
              } else if (u === "day") {
                this.calculateDays();
              }
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
      if (Object.keys(this.state.initialData).length === 0) {
        axios
          .post(`${baseUrl}api/Chartdata`, postData1)
          .then((res) => {
            //  console.log(res.data)
            this.setState({
              initialData:postData1
          })
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .put(
            `${baseUrl}api/Chartdata/${this.context.loggedInUserId}/${2}`,
            postData1
          )
          .then((res) => {
            //  console.log(res.data)
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  selectParameter = (p) => {
    this.setState({
      selectedPara: p,
    });
    const {
      startDate,
      endDate,
      selectedUnit,
      selectedPara,
      graphFig,
    } = this.state;
    let questionnaireIds = graphFig.map((g) => {
      return g._id;
    });
    if (startDate && endDate && selectedUnit && Object.keys(p).length && questionnaireIds.length) {
      const from = moment(startDate).format("YYYY-MM-DD") + "T01:11:11Z";
      const to = moment(endDate).format("YYYY-MM-DD") + "T01:11:11Z";
     
      // questionnaireIds=questionnaireIds.concat(value._id)
      // console.log('here', questionnaireIds)
      const data = this.context;

      // console.log(data.venueId, questionnaireIds,from, to, selectedUnit, selectedPara.id)
      const postData = {
        venueId: data.venueId,
        questionnaireIds: questionnaireIds.join(","),
        fromDate: from,
        toDate: to,
        timeUnit: selectedUnit,
        keyFigId: p.id,
        userRole: data.userRole,
      };
      const postData1 = {
        venueId: data.venueId,
        userId: data.loggedInUserId,
        questionnaireIds: questionnaireIds.join(","),
        fromDate: from,
        toDate: to,
        timeUnit: selectedUnit,
        keyFigId: selectedPara.id,
        userRole: data.userRole,
        graphType: "2",
      };
      // console.log('postData', postData)
      axios
        .post(`${baseUrl}api/report/QuestionnaireReport`, postData)
        .then((res) => {
          // console.log(res.data)
          const options = Object.assign([], this.state.options);
          // console.log('optns', options)
          options.series = res.data.report;
          this.setState(
            (prevState) => {
              return {
                options,
                dummyXaxis: res.data["x-axis"],
              };
            },
            () => {
              if (selectedUnit === "year") {
                this.calculateYears();
              } else if (selectedUnit === "month") {
                this.calculateMonths();
              } else if (selectedUnit === "week") {
                this.calculateWeeks();
              } else if (selectedUnit === "day") {
                this.calculateDays();
              }
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
      if (Object.keys(this.state.initialData).length === 0) {
        axios
          .post(`${baseUrl}api/Chartdata`, postData1)
          .then((res) => {
            //  console.log(res.data)
            this.setState({
              initialData:postData1
          })
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .put(
            `${baseUrl}api/Chartdata/${this.context.loggedInUserId}/${2}`,
            postData1
          )
          .then((res) => {
            //  console.log(res.data)
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  handleDelete = (g) => {
    let graphFig = Object.assign([], this.state.graphFig);
    let listOfQuest = Object.assign([], this.state.listOfQuest);
    graphFig = graphFig.filter((f) => {
      return f._id !== g._id;
    });
    listOfQuest = listOfQuest.concat(g);
    this.setState({
      graphFig,
      listOfQuest,
    });

    const { startDate, endDate, selectedUnit, selectedPara } = this.state;
    const from = moment(startDate).format("YYYY-MM-DD") + "T01:11:11Z";
    const to = moment(endDate).format("YYYY-MM-DD") + "T01:11:11Z";
    let questionnaireIds = graphFig.map((g) => {
      return g._id;
    });
    // questionnaireIds=questionnaireIds.concat(value._id)
    // console.log('here', questionnaireIds)
    const data = this.context;

    // console.log(data.venueId, questionnaireIds,from, to, selectedUnit, selectedPara.id)
    const postData = {
      venueId: data.venueId,
      questionnaireIds: questionnaireIds.join(","),
      fromDate: from,
      toDate: to,
      timeUnit: selectedUnit,
      keyFigId: selectedPara.id,
      userRole: data.userRole,
    };
    const postData1 = {
      venueId: data.venueId,
      userId: data.loggedInUserId,
      questionnaireIds: questionnaireIds.join(","),
      fromDate: from,
      toDate: to,
      timeUnit: selectedUnit,
      keyFigId: selectedPara.id,
      userRole: data.userRole,
      graphType: "2",
    };
    // console.log('postData', postData)
    axios
      .put(
        `${baseUrl}api/Chartdata/${this.context.loggedInUserId}/${2}`,
        postData1
      )
      .then((res) => {
        //  console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .post(`${baseUrl}api/report/QuestionnaireReport`, postData)
      .then((res) => {
        // console.log(res.data)
        const options = Object.assign([], this.state.options);
        // console.log('optns', options)
        options.series = res.data.report;
        this.setState(
          (prevState) => {
            return {
              options,
              dummyXaxis: res.data["x-axis"],
            };
          },
          () => {
            if (selectedUnit === "year") {
              this.calculateYears();
            } else if (selectedUnit === "month") {
              this.calculateMonths();
            } else if (selectedUnit === "week") {
              this.calculateWeeks();
            } else if (selectedUnit === "day") {
              this.calculateDays();
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
    if (Object.keys(this.state.initialData).length === 0) {
      axios
        .post(`${baseUrl}api/Chartdata`, postData1)
        .then((res) => {
          //  console.log(res.data)
          this.setState({
            initialData:postData1
        })
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .put(
          `${baseUrl}api/Chartdata/${this.context.loggedInUserId}/${2}`,
          postData1
        )
        .then((res) => {
          //  console.log(res.data)
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // this.setState((prevState)=>{
    //         return {
    //             graphFig:prevState.graphFig.concat(value),
    //             listOfQuest:prevState.listOfQuest.filter((q)=>{
    //                 return q!==value
    //             })
    //         }
    //     })
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleLanguageForEditor=()=>{ 
    const lang= this.context.languageFormat;
     switch (lang) {
       case 'de':
         return 'de';
       case 'en':
         return 'enUS';
       case 'se':
         return 'es';
         case 'no':
         return 'nb';
       default:
         return lang;
     }
   }
  render() {
    const lang= this.handleLanguageForEditor();
    console.log("language",lang);
    return (
      <div>
        <br />
        <div style={{ display: "block", width: "990px" }}>
          <button className="buttons dummy_button" onClick={this.showDateRange}>
            <Moment format="DD-MM-YYYY">{this.state.startDate}</Moment>
          </button>

          {this.state.displayDateRange && (
            <DateRange
              locale={rdrLocales[lang]}
              startDatePlaceholder={this.props.t("Früher")}
              endDatePlaceholder={this.props.t("Später")}
              className="date-range-picker"
              onChange={this.setRange}
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              ranges={this.state.data}
            />
          )}
          <button className="buttons dummy_button" onClick={this.showDateRange}>
            <Moment format="DD-MM-YYYY">{this.state.endDate}</Moment>
          </button>
          <Dropdown
            className="dummy_button"
            isOpen={this.state.showUnits}
            toggle={this.handleUnits}
          >
            <DropdownToggle className="buttons dummy_button">
              {this.state.selectedUnit}
            </DropdownToggle>
            <DropdownMenu className="buttons">
              {this.state.units.map((u, i) => {
                return (
                  <DropdownItem
                    key={i}
                    value={u}
                    onClick={() => {
                      this.selectUnit(u);
                    }}
                  >
                    {u}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>

          <Dropdown
            className="dummy_button"
            isOpen={this.state.showParameters}
            toggle={this.handleShowParameters}
          >
            <DropdownToggle className="buttons dummy_button">
              {this.state.selectedPara?.name
                ? this.state.selectedPara?.name
                : this.props.t("Select parameter")}
            </DropdownToggle>
            <DropdownMenu className="buttons">
              {this.state.parameters.map((p, i) => {
                return (
                  <DropdownItem
                    key={i}
                    value={p.name}
                    onClick={() => {
                      this.selectParameter(p);
                    }}
                  >
                    {p.name}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
          {this.state.graphFig.length
            ? this.state.graphFig.map((g, i) => {
                return (
                  <div key={i} className="buttons dummy_button">
                    {g.name}
                    <img
                      src={cross}
                      onClick={() => {
                        this.handleDelete(g);
                      }}
                      style={{ width: "10px", height: "10px", float: "right" }}
                      alt=""
                    />
                  </div>
                );
              })
            : null}

          <Dropdown
            className="dummy_button"
            direction="right"
            isOpen={this.state.showDropDown}
            toggle={this.handleShowDropdown}
          >
            <DropdownToggle className="buttons dummy_button">
              {this.props.t('Select')}
            </DropdownToggle>
            <DropdownMenu className="buttons">
              {this.state.listOfQuest.map((k, i) => {
                return (
                  <DropdownItem
                    key={k._id}
                    className="dd"
                    value={k}
                    onClick={() => {
                      this.addFigures(k);
                    }}
                  >
                    {k.name}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>

          <CSVLink data={this.state.options.series} filename={"statistics.csv"}>
            <img src={exp} alt="export" />
          </CSVLink>
        </div>
        <br />
        <br />
        {this.state.graphFig.length && this.state.selectedPara.id ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={this.state.options}
          />
        ) : null}
      </div>
    );
  }
}

export default withTranslation() (Graph2);
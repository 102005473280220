import React, { Component } from "react";
import uploadIcon from "../../assets/upload.svg";
import DropDown from "../partials/DropDown";
import HttpService from "../../services/HttpService";
import Text from "../preview/Text";
import Dropzone from "react-dropzone";
import { StateContext } from "../../context/state";
import WithNavStateAsProps from "../partials/WithNavStateAsProps";
import { heartMask } from "../../assets";
import { circleMask } from "../../assets";
import swal from 'sweetalert';
import { withTranslation } from 'react-i18next';
const imageMaxSize = 10240;

					  
export const Roles = {
    SuperAdmin: "SA",
    VenueAdmin: "VA",
    VenueManager: "VM",
    VVO: "VV",
    Customer: "CU"
};

const RolesMap = {
    [Roles.SuperAdmin]: [Roles.VenueAdmin],
    [Roles.VenueAdmin]: [Roles.VenueManager, Roles.VVO, Roles.Customer],
    [Roles.VenueManager]: [Roles.VenueManager, Roles.VVO, Roles.Customer],
    [Roles.Customer]: [Roles.Customer]
};

class UserInfoPage extends Component {

    static contextType = StateContext;

    inputProps;
    userPicture;

    constructor(props,context) {
        super(props,context);

        this.selectorRef = React.createRef(null);

        this.state = {
            loading: "Loading...",
            loggedInUser: undefined,
            user: {},
            roles: [],
            venues: [],
            collapsed: false,
            collapsed1: false,
            userLanguages:[],
            userLangDropDownOpen:false,    
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 2 / 2,
            croppedAreaPixels: {},
            imgSrc: 'https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1100_500'	  					
        };

        this.inputProps = {
            type: "text",
            maxLength: 50,
            style: styles.input,
            disabled: this.props.disabled
        };

        this.shapes = [{name: this.props.t('Herzform'), code: "heart"}, {name: this.props.t('Quadratisch'), code: "square"}];
        this.titles = [{name: this.props.t('Herr'), code: "Herr"}, {name: this.props.t('Frau'), code: "frau"}];
    }

    componentDidMount() {
        console.log("roles map",RolesMap);
        if (window.innerWidth > 1316) this.setState({ collapsed: false });
        window.addEventListener("resize", () => {
            if (window.innerWidth > 1316) this.setState({ collapsed: false });
            else this.setState({ collapsed: true });
        });
        if (window.innerWidth > 1190) this.setState({ collapsed1: false });
        window.addEventListener("resize", () => {
            if (window.innerWidth > 1190) this.setState({ collapsed1: false });
            else this.setState({ collapsed1: true });
        });
        let roles, venues, userLanguages, venueLanguage;
        const loggedInUser = this.context;
        let user = this.props.user ?? {
            "venueId": this.context?.venueId,
            "role": undefined,
            "title1": undefined,
            "surname1": undefined,
            "firstName1": undefined,
            "email1": undefined,
            "telNumber1": undefined,
            "title2": undefined,
            "surname2": undefined,
            "firstName2": undefined,
            "email2": undefined,
            "telNumber2": undefined,
            "streetNumber": undefined,
            "postCode": undefined,
            "state": undefined,
            "country": undefined,
            "companyName": undefined,
            "taxID": undefined,
            "pictureURL": undefined,
            "shape": undefined,
            "isDeleted": "false",
			"languageFormat":undefined,

        };
  if(!this.props.user)
        { const {venueId}=this.context;
           HttpService.getVenue(venueId)
            .then(response => {
               console.log("venue",response);
               venueLanguage= response.languageFormat;
               user.languageFormat=venueLanguage;
            });
        }
        const {venueId}=this.context;
        const rolesPromise = HttpService.getUserRoles(venueId)
            .then(response => {
                roles = response?.roles;
                const allowedRoles = new Set(RolesMap[loggedInUser?.userRole]);
                roles = roles.filter(role => allowedRoles.has(role?.code));
            });

		   const languagePromise= HttpService.getUserLanguages()
          .then(response => {   
              userLanguages = response;
              console.log(userLanguages);
            //   this.context.updateState({ languageFormat: venueLanguage });
          });														  

        const userPromise = user.userId
            ? HttpService.getUser(user.userId)
                .then(response => {
                    user = response;

                })
            : {};

        const venuesPromise = loggedInUser.userRole === Roles.SuperAdmin
            ? HttpService.getVenues()
                .then(response => {
                    venues = response?.map(venue => {
                        return {
                            code: venue._id,
                            name: venue.venueName
                        }
                    });
                })
            : {};



	
	  Promise.all([rolesPromise, userPromise, venuesPromise,languagePromise]).then(() => {																				   
      this.setState({
                loading: undefined,
                roles,
                user,
                venues,
                loggedInUser,
                userLanguages,
            });
     //this.context.updateState({ user.languageFormat:venueLanguage });

        }).catch(error => {
            swal(JSON.stringify(error), "", "error");
        });


    }

    setValue = (key, value) => {
        const { user } = this.state;
        user[key] = value;
        this.setState({ user })
    };

    renderPicker() {
        const { pictureURL, shape } = this.state.user;

        if (pictureURL) {
            return <>
                <div style={styles.imageWrapper}>
                    {this.state.user?.role === "CU" ?
                        <>
                            <img style={styles.image} src={pictureURL} alt="" />
                            <img style={styles.imageMask} src={shape === "heart" ? heartMask : null} alt=""  draggable="false" />
                        </>
                        :
                        <>
                            <img style={styles.image} src={pictureURL} alt="" />
                            <img style={styles.imageMask} src={circleMask} alt="" draggable="false" />
                        </>
                    }
                </div>
            </>
        }

        return !this.props.disabled &&
            <div style={{ display: "flex", flexDirection: "column", padding: "20px", alignItems: "center", marginTop: "20px" }}>
                <img style={styles.icon} src={uploadIcon} alt="" />
                {/* <p style={styles.text}>{`Drag & Drop oder Datei auswählen(choose a file)`}  </p> */}
                <button style={styles.button}>  {this.props.t('Choose_file')}</button>
            </div>;
    }

    onFileSelect = (selectedFiles) => {

        selectedFiles = selectedFiles.filter(file => file && file['type'].split('/')[0] === 'image');

        if (selectedFiles?.length === 0) {
            swal(`${this.props.t('Bild auswählen')}`, "", "info");;
				       return;
        }
        const currentFile = selectedFiles[0];
        const currentFileSize = Math.round(currentFile.size / 1024);
        if (currentFileSize > imageMaxSize) {
            swal(`${this.props.t('Bilder bis 15 MB sind erlaubt')}`, "", "info");						 
															  
            return;
        }

        const { user } = this.state;
        selectedFiles.forEach(file => {
            user.pictureURL = URL.createObjectURL(file);
            this.userPicture = file;
        });

        this.setState({ user });
    };

    onSelectFile = (e) => {
        // if (e.target.files && e.target.files.length > 0) {
        //   const reader = new FileReader();
        //   reader.addEventListener('load', () =>
        //     this.setState({ imgSrc: reader.result })
        //   );
        //   reader.readAsDataURL(e.target.files[0]);
        // }
    };
    
    // If you setState the crop in here you should return false.
    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({
            croppedAreaPixels
        }, () =>{
            this.drawImage();
        })
        this.makeClientCrop(croppedArea);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };
    
    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        // this.setState({ image: image.src });
        
        return new Promise((resolve, reject) => {
        canvas.toBlob(
            (blob) => {
            if (!blob) {
                //reject(new Error('Canvas is empty'));
                console.error('Canvas is empty');
                return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
            },
            'image/jpeg',
            1
        );
        });
    }

    onCropChange = (crop) => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        console.log(croppedArea, croppedAreaPixels)
    }

    onZoomChange = (zoom) => {
        this.setState({ zoom })
    }

    render() {

        if (this.state.loading) {
            return <div style={{ display: "flex" }}>
                <Text value={this.state.loading} />
            </div>
        }

        const { user, roles, venues, loggedInUser,userLanguages } = this.state;

        const isCustomer = loggedInUser?.userRole === Roles.Customer;
        const { disabled } = this.props;
        const { inputProps } = this;

        return <div style={styles.container}>
            <div style={styles.formWrapper}>
                <div style={{ display: "flex", marginBottom: "30px" }}>
                    <DropDown
                        placeholder={this.props.t('Anrede')}
                        options={this.titles}
                        value={user?.title1}
                        onSelect={value => this.setValue("title1", value)}
                        disabled={disabled || isCustomer}
                    />
                    <div style={{ display: this.state.collapsed === true ? "block" : "flex", textAlign: this.state.collapsed === true ? "center" : "" }}>
                        <div style={styles.inputWrapper}>
                            <input
                                {...inputProps}
                                placeholder={this.props.t('Nachname')}
                                value={user?.surname1 ?? ""}
                                onChange={(e) => this.setValue("surname1", e.target.value)}
                            // disabled={isCustomer}
								 autoComplete="off"			  
                            />
                            <p style={styles.inputLabel}>{`1.${this.props.t('Kontaktperson')}`}</p>
                        </div>
                        <div style={styles.inputWrapper}>
                            <input
                                {...inputProps}
                                placeholder={this.props.t('Vorname')}
                                value={user?.firstName1 ?? ""}
                                onChange={(e) => this.setValue("firstName1", e.target.value)}
                            // disabled={isCustomer}
								 autoComplete="off"			  
                            />
                            <p style={styles.inputLabel} />
                        </div>
                        <div style={styles.inputWrapper}>

                            <input
                                {...inputProps}
                                placeholder="E-Mail"
                                value={user?.email1 ?? ""}
                                onChange={(e) => this.setValue("email1", e.target.value)}
                            // disabled={isCustomer}
									 autoComplete="off"		  
                            />
                            <p style={styles.inputLabel} />
                        </div>
                        <div style={styles.inputWrapper}>
                            <input
                                {...inputProps}
                                maxLength={15}
                                placeholder={this.props.t('Telefonnummer')}
                                value={user?.telNumber1 ?? ""}
                                onKeyDown={e => {
                                    if (e.key === "Backspace") return;
                                    let key = Number(e.key);
                                    if (isNaN(key) || e.key === null || e.key === ' ') e.preventDefault();
                                }}
                                onChange={(e) => this.setValue("telNumber1", e.target.value)}
								 autoComplete="off"			  
                            />
                            <p style={styles.inputLabel} />
                        </div>
                    </div>    

                     {user?.role === Roles.VenueAdmin   ?  
                    <DropDown
                        placeholder={this.props.t('Select_role')}
                        options={roles}
                        value={user?.role}
                        onSelect={value => this.setValue("role", value)}
                        disabled={true} 
                    />:
                    <DropDown
                    placeholder={this.props.t('Select_role')}
                    options={roles}
                    value={user?.role}
                    onSelect={value => this.setValue("role", value)}
                    disabled={disabled || isCustomer} 
                />
                     }  
                </div>

                {user?.role === Roles.Customer && <div style={{ display: "flex", marginBottom: "30px" }}>
                    <DropDown
                        placeholder={this.props.t('Anrede')}
                        options={this.titles}
                        value={user?.title2}
                        onSelect={value => this.setValue("title2", value)}
                        disabled={disabled || isCustomer}
                    />
                    <div style={{ display: this.state.collapsed === true ? "block" : "flex", textAlign: this.state.collapsed === true ? "center" : "" }}>
                        <div style={styles.inputWrapper}>
                            <input
                                {...inputProps}
                                placeholder={this.props.t('Nachname')}
                                value={user?.surname2 ?? ""}
                                onChange={(e) => this.setValue("surname2", e.target.value)}
                            // disabled={isCustomer}
									 autoComplete="off"		  
                            />
                            <p style={styles.inputLabel}>{`2.${this.props.t('Kontaktperson')}`}</p>
                        </div>
                        <div style={styles.inputWrapper}>
                            <input
                                {...inputProps}
                                placeholder={this.props.t('Vorname')}
                                value={user?.firstName2 ?? ""}
                                onChange={(e) => this.setValue("firstName2", e.target.value)}
                            // disabled={isCustomer}
									 autoComplete="off"		  
                            />
                            <p style={styles.inputLabel} />
                        </div>
                        <div style={styles.inputWrapper}>
                            <input
                                {...inputProps}
                                placeholder="E-Mail"
                                value={user?.email2 ?? ""}
                                onChange={(e) => this.setValue("email2", e.target.value)}
                            // disabled={isCustomer}
									 autoComplete="off"		  
                            />
                            <p style={styles.inputLabel} />
                        </div>
                        <div style={styles.inputWrapper}>
                            <input
                                {...inputProps}
                                maxLength={15}
                                placeholder={this.props.t('Telefonnummer')}
                                value={user?.telNumber2 ?? ""}
                                onKeyDown={e => {
                                    if (e.key === "Backspace") return;
                                    let key = Number(e.key);
                                    if (isNaN(key) || e.key === null || e.key === ' ') e.preventDefault();
                                }}
                                onChange={(e) => this.setValue("telNumber2", e.target.value)}
										 autoComplete="off"	  
                            />
                            <p style={styles.inputLabel} />
                        </div>
                    </div>
		 
                    <DropDown
                        placeholder={this.props.t('Select_role')}
                        options={roles}
                        value={user?.role}
                        disabled={true}
                        onSelect={value => this.setValue("role", value)}
                    />

					    <DropDown
                        //    placeholder={venueLanguage}
                              options={userLanguages}
                              value={user?.languageFormat}
                              onSelect={value => this.setValue("languageFormat", value)}
                            //   disabled={disabled || isCustomer}
                              style={{display: "flex", marginLeft: "30px"}}
                          /> 

                </div>}

                <div style={{ display: "flex", marginBottom: "30px" }}>
                    <DropDown placeholder={this.props.t('Anrede')} style={styles.hidden} />
                    <div style={{
                        display: this.state.collapsed === true ? "block" : "flex",
                        textAlign: this.state.collapsed === true ? "center" : ""
                    }}>
                        <div style={styles.inputWrapper}>
                            <input
                                placeholder={this.props.t('Street_no')}
                                {...inputProps}
                                value={user?.streetNumber ?? ""}
                                onChange={(e) => this.setValue("streetNumber", e.target.value)}
											  
                            />
                            <p style={styles.inputLabel}>{this.props.t('Adresse')}</p>
                        </div>
                        <div style={styles.inputWrapper}>
                            <input
                                {...inputProps}
                                placeholder={this.props.t('PLZ')}
                                value={user?.postCode ?? ""}
                                onChange={(e) => this.setValue("postCode", e.target.value)}
								 autoComplete="off"			  
                            />
                            <p style={styles.inputLabel} />
                        </div>
                        <div style={styles.inputWrapper}>
                            <input
                                {...inputProps}
                                placeholder={this.props.t('Ort')}
                                value={user?.state ?? ""}
                                onChange={(e) => this.setValue("state", e.target.value)}
									 autoComplete="off"		  
                            />
                            <p style={styles.inputLabel} />
                        </div>
                        <div style={styles.inputWrapper}>
                            <input
                                {...inputProps}
                                placeholder={this.props.t('Land')}
                                value={user?.country ?? ""}
                                onChange={(e) => this.setValue("country", e.target.value)}
								 autoComplete="off"			  
                            />
                            <p style={styles.inputLabel} />
                        </div>
                    </div>
                    <DropDown
                        style={loggedInUser?.userRole !== Roles.SuperAdmin && styles.hidden}
                        placeholder={this.props.t('Select_venue')}
                        options={venues}
                        value={user?.venueId}
                        onSelect={value => this.setValue("venueId", value)}
                        disabled={disabled}
                    />
                </div>

                <div style={{ display: "flex", marginBottom: "30px" }}>
                    <DropDown placeholder={this.props.t('Anrede')} style={styles.hidden} />
                    <div style={{ display: this.state.collapsed1 === true ? "block" : "flex", textAlign: this.state.collapsed1 === true ? "center" : "" }}>
                        <div style={styles.inputWrapper}>
                            <input
                                {...inputProps}
                                placeholder={this.props.t('Firmenname')}
                                value={user?.companyName ?? ""}
                                onChange={(e) => this.setValue("companyName", e.target.value)}
                            />
                            <p style={styles.inputLabel}>{this.props.t('Firmeninfo')}</p>
                        </div>
                        <div style={styles.inputWrapper}>
                            <input
                                {...inputProps}
                                placeholder={this.props.t('st_id_number')}
                                value={user?.taxID ?? ""}
                                onChange={(e) => this.setValue("taxID", e.target.value)}
									 autoComplete="off"		  
                            />
                            <p style={styles.inputLabel} />
                        </div>
                    </div>
                    <div style={{ ...styles.inputWrapper, visibility: "hidden" }} />
                    <div style={{ ...styles.inputWrapper, visibility: "hidden" }} />
                    <DropDown placeholder={this.props.t('Select_role')} style={styles.hidden} />
                </div>

                {!disabled && <div style={{ display: "flex", marginBottom: "30px", alignItems: "center" }}>
                    <DropDown placeholder={this.props.t('Anrede')} style={styles.hidden} />
                    <div style={styles.buttonLight} onClick={this.saveDetails}>
                        <p className={"m-0"} style={{ color: "#212529" }}>{this.props.t('save')}</p>
                    </div>
                    <div style={styles.buttonLight} onClick={() => this.props.history.goBack()}>
                        <p className={"m-0"} style={{ color: "#212529" }}>{this.props.t('return')}</p>
                    </div>
                </div>}
            </div>

            <div style={styles.pickerWrapper}>
                <Dropzone onDrop={this.onFileSelect}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} style={styles.picker}>
                            <input {...{ ...getInputProps(), multiple: false, disabled: disabled }} />
                            {this.renderPicker()}
                        </div>
                    )}
                </Dropzone>
                {/* <div style={styles.imgpicker} onClick={() => this.selectorRef.current.click()}>
                    <input className="input-file-hidden" type="file" accept="image/*" onChange={this.onFileSelect} ref={this.selectorRef} />
                </div> */}
                <p style={styles.inputLabel}>{this.props.t('Upload_picture_logo')}</p>
               {user?.role === "CU" &&
                <DropDown
                    placeholder={`${this.props.t('Herzform')} ${this.props.t('Quadratisch')}`}
                    style={{ marginTop: "16px" }}
                    options={this.shapes}
                    value={user?.shape}
                    onSelect={value => this.setValue("shape", value)}
                    disabled={disabled}
                />}
            </div>
        </div>
    }

    validateEmail = (mail) => /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w+)+$/.test(mail);

    saveDetails = async () => {

        const { userPicture, validateEmail, state: { user, loggedInUser }, props: { history } } = this;
        const { _id, venueId, title1, surname1, firstName1, email1, email2, telNumber1, role, streetNumber, postCode, state, country } = user;
        const isCustomer = loggedInUser.userRole === Roles.Customer;

        if (!isCustomer && !title1) return swal(`${this.props.t('Wählen Sie einen Titel')}`, "", "info");
        if (!isCustomer && !surname1?.trim()) return swal(`${this.props.t('Geben Sie einen Nachnamen ein')}`, "", "info");
        if (!isCustomer && !firstName1?.trim()) return swal(`${this.props.t('Geben Sie einen Vornamen ein')}`, "", "info");
        if (!email1?.trim() || !validateEmail(email1)) return swal(`${this.props.t('Geben Sie eine gültige E-Mail Adresse 1 ein')}`, "", "info");
        if (email2 && !validateEmail(email2)) return swal(`${this.props.t('Geben Sie eine gültige E-Mail Adresse 2 ein')}`, "", "info");
        if (!telNumber1?.trim()) return swal(`${this.props.t('Geben Sie eine Telefonnummer ein')}`, "", "info");
        if (!role) return swal(`${this.props.t('Wählen Sie eine Rolle aus')}`, "", "info");
        if (!streetNumber?.trim()) return swal(`${this.props.t('Geben Sie eine Hausnummer ein')}`, "", "info");
        if (!postCode?.trim()) return swal(`${this.props.t('Geben Sie eine Postleitzahl ein')}`, "", "info");
        if (!state?.trim()) return swal(`${this.props.t('Geben Sie einen Staat ein')}`, "", "info");
        if (!country?.trim()) return swal(`${this.props.t('Geben Sie ein Land ein')}`, "", "info");
        if (loggedInUser?.userRole === Roles.SuperAdmin && !venueId) return swal(`${this.props.t('Bitte wählen Sie einen Veranstaltungsort')}`, "", "info");

        if (userPicture) {
            const response = await HttpService.uploadFile(userPicture);
              console.log("resp",response);
              if (response.message) {
                swal(response.message, "", "success");
                return;
                }
                user.pictureURL = response.url;
            }
										   
		 

        const response = _id
            ? await HttpService.updateUser(user)
            : await HttpService.createUser(user);

        if (response?.error && response?.error !== "") {
            swal(response?.error, "", "error");
            return;
        }
        if (loggedInUser.loggedInUserId === _id) {
            this.context.updateState({ surname: firstName1, userdata: user.pictureURL});
        }
        history.goBack();
    }
}

export default withTranslation()(WithNavStateAsProps(UserInfoPage));

const styles = {
    container: {
        display: "flex",
        minHeight: "100vh",
        alignItems: "center",
        flexWrap: true
    },
    formWrapper: {
        flexGrow: 1,
        padding: "0 30px"
    },
    pickerWrapper: {
        borderLeft: "1px solid #939599",
        padding: "0 30px",
        display: "flex",
        flexDirection: "column",
    },
    picker: {
        width: "300px",
        cursor: "pointer",
        textAlign: "center",
        minHeight: "300px",
        backgroundSize: "cover",
        border: "1px dashed #d1d3d4",
        backgroundPosition: "center center",
    },
    imgpicker: {
        width: "348px",
        cursor: "pointer",
        textAlign: "center",
        minHeight: "300px",
        backgroundSize: "cover",
        border: "1px dashed #d1d3d4",
        backgroundPosition: "center center",
    },
    icon: {
        marginBottom: "15px"
    },
    text: {
        fontSize: "10px",
        fontFamily: "Crete Round",
        fontStyle: "italic",
        textAlign: "center",
        color: "#939599",
        margin: 0,
    },
    button: {
        display: "block",
        background: "#939599",
        fontSize: "10px",
        color: "#e6e7e8",
        padding: "3px 6px",
        border: "0",
    },
    buttonLight: {
        display: "inline-block",
        margin: "0px 10px",
        background: "#e6e7e8",
        fontSize: "14px",
        minWidth: "100px",
        textAlign: "center",
        color: "#939599",
        padding: "6px",
        border: "0",
        cursor: "pointer"
    },
    input: {
        border: 0,
        padding: 0,
        margin: 0,
        width: "100%",
        background: "transparent",
        borderBottom: "1px solid #707070",
    },
    inputLabel: {
        fontFamily: "Crete Round",
        fontStyle: "Italic",
        // color: "#95969b",
        fontSize: "14px",
        margin: 0,
        textAlign: "justify"
    },
    inputWrapper: {
        display: "inline-block",
        margin: "0px 10px",
        flexGrow: 1,
        flexBasis: 1,
        marginBottom: "10px"
    },
    hidden: {
        visibility: "hidden"
    },
    image: {
        width: "inherit",
        height: "inherit",
        objectFit: "contain"
    },
    imageMask: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "inherit",
        height: "inherit",
    },
    imageWrapper: {
        width: "300px",
        height: "300px",
        position: "relative"
    },
    cropperContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: '80px',
    },
    cropperControls: {
        position: 'absolute',
        bottom: 0,
        left: '50%',
        width: '50%',
        transform: 'translateX(-50%)',
        height: '80px',
        display: 'flex',
        alignItems: 'center',
    }
};

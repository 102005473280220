import React, {Component} from "react";
import plusIcon from "../../assets/plus.svg";
import TableSection, {Header} from "../partials/TableSection";
import editIcon from "../../assets/edit.svg";
import {StateContext} from "../../context/state";
import HttpService from "../../services/HttpService";
import Text from "../preview/Text";
import deleteIcon from "../../assets/trash.svg";
import {withTranslation} from 'react-i18next';

const headers = [
    {
        name: "Url",
        value: "venueURL",
        type: Header.TEXT,
    },
    {
        name: "Venue",
        value: "venueName",
        type: Header.TEXT,
    },
    {
        name: "Number of open Events",
        value: "openEvents",
        type: Header.TEXT,
    },
    {
        name: "Customer Count",
        value: "customerCount",
        type: Header.TEXT,
    },
    {
        name: "Number of all Event",
        value: "allEvents",
        type: Header.TEXT,
    }
];

 class VenuesPage extends Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: "Loading...",
            venues: []
        }
    }

    componentDidMount() {
        HttpService.fetchVenues()
            .then(venues => {
                this.setState({
                    loading: undefined,
                    venues
                });
            })
            .catch(error => {
                this.setState({
                    loading: JSON.stringify(error)
                });
            });
    }

    render() {

        const {loading, venues} = this.state;

        if (loading) {
            return <div style={styles.row}>
                <div className="col-1" />
                <div className="col-10">
                    <Text value={this.props.t("Loading")} />
                </div>
            </div>
        }

        return (
            <React.Fragment>
                 <div style={styles.row}>
                <div className="col-11">
                <div style={{color: "rgb(147, 149, 153)", fontSize:"24px", margin:"20px 150px 0px"}}>{this.props.t('Veranstaltungsort')}</div>
                  <div style={styles.divider} />
                </div>
                </div>
                <div style={styles.row}>
                    <div className="col-11" style={styles.addWrapper}>
                        <img style={styles.pointer} src={plusIcon} alt="" onClick={() => {
                            this.props.history.push("/admin/venueInfo");
                        }} />
                    </div>
                </div>

                <div style={styles.row}>
                    <div className={"col-1"} />
                    <div className={"col-10"}>
                        <TableSection headers={headers} data={venues} buttons={this.buttons} buttonCount={2} />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    toVenueDetailsPage = (state) => {
        this.props.history.push("/admin/venueInfo", state);
    };

    deleteVenue = (venue) => {
        HttpService.deleteVenue(venue)
            .then(() => {
                let {venues} = this.state;
                venues = venues.filter(v => v._id !== venue._id);
                this.setState({venues})
            })
            .catch(err => JSON.stringify(err))
    };

    buttons = [
        {
            id: 1,
            name: "edit",
            icon: editIcon,
            tooltipText:"Bearbeiten",
            onPress: (venue) => this.toVenueDetailsPage({venue, disabled: false}),
        },
        {
            id: 2,
            name: "delete",
            icon: deleteIcon,
            tooltipText:"Löschen",
            onPress: this.deleteVenue
        }
    ];
}

export default withTranslation() (VenuesPage);
const styles = {
    row: {
        display: "flex",
        flexWrap: "wrap",
    },
    addWrapper: {
        marginLeft: "40px"
    },
    pointer: {
        cursor: "pointer"
    }, 
    divider: {
        borderTop: "1px solid #d0d3d4",
        margin: "30px 0 0px 40px",
        paddingTop: "10px",
      },
};

import React, {Component} from "react";
import Text from "../Text";
import docIcon from "../../../assets/doc.svg";
import jpgIcon from "../../../assets/jpg-file.svg";
import pdfIcon from "../../../assets/pdf.svg";
import {StateContext} from "../../../context/state";
import moment from 'moment-timezone'
export default class DownloadComponent extends Component {

    iconMap = {
        jpg: jpgIcon,
        png: jpgIcon,
        jpeg: jpgIcon,
        pdf: pdfIcon
    };
    static contextType = StateContext;

    constructor(props) {
        super(props);

        let inputs = props.inputs.map(input => {
            let name = (input.url?.split("/") ?? [""])?.slice(-1)?.toString() ?? "";
            return {
                ...input,
                type: (name?.split(".") ?? [""])?.slice(-1)?.toString() ?? ""
            };
        });

        this.state = {
            inputs
        }
    }
    static getDerivedStateFromProps = (nextProps, prevState) => {
        // console.log('props', nextProps)
        return {
            inputs:nextProps.inputs
        };
      };
    downloadFile = (file) => {
   
        let deadLineDate = new Date(this.context.deadlineForEntries);
   
        if (
          deadLineDate.toDateString() !== new Date().toDateString() &&
          moment(this.context.deadlineForEntries).isBefore(new Date()) &&
          this.context.userRole === "CU"
        ){
         return false
        }
        else{
            let a = document.createElement('a');
            a.href = file.url;
            a.download = file.name;
            a.target= "_blank";
            a.click();  
        }
        
    };

    render() {
        const textFontSize = this.context.stylingData?.textFontSize;
        const textFontFamily = this.context.stylingData?.textFontFamily;
        let {inputs} = this.state;
        inputs = inputs ?? [];

        return <div style={styles.container}>
            <div style={{display: "flex"}}>
               
                {inputs.map((item, index) => {
                   
                    return <div style={styles.imageWrapper} key={index} onClick={() => this.downloadFile(item)}>
                        {/* <img src={this.iconMap[item.type] ?? docIcon} alt="" style={styles.image} /> */}
                        <img 
                        // src={item.url?item.url:docIcon} 
                        src={
                            item.type === "jpeg" ||
                            item.type === "jpg" ||
                            item.type === "png" ||
                            item.type === "image"
                              ? item?.url
                              : this.iconMap[item.type] ?? docIcon
                          }
                        alt={item.type} style={styles.image} />
                        <Text   fontSize={textFontSize}
                fontFamily={textFontFamily} style={styles.label} value={item.name || ""} />
                    </div>
                })}
            </div>
        </div>;
    }
}

const styles = {
    container: {
        overflow: "auto",
        maxHeight: "390px",
        marginTop:"20px"
    },
    imageWrapper: {
        marginRight: "15px",
        width: "200px",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center"
    },
    image: {
        display: "block",
        width: "100px",
        height: "100px"
    },
    label: {
        // fontSize: "14px",
        // textAlign: "center"
    }
};

import React, { Component } from "react";
import ConfiguratorTemplateSelectionPopup from "../partials/ConfiguratorTemplateSelectionPopup";
import ConfiguratorTemplateEditPopup from "../partials/ConfiguratorTemplateEditPopup";
import axios from "axios";
import { StateContext } from "../../context/state";
import { baseUrl } from "../../Constants";
import plusIcon from "../../assets/plus.svg";
import editIcon from "../../assets/edit.svg";
import logoutIcon from "../../assets/logout.svg";
import home from "../../assets/home.svg";
import HttpService from "../../services/HttpService";
import UploadDownload from "../preview/upload/UploadDownload";
import RadioButton from "../preview/radio/text/RadioButton";
import Bild from "../preview/bild/Bild";
import Table from "../preview/table/Table";
import TextComponent from "../preview/text/TextComponent";
import DateTime from "../preview/dateTime/DateTime";
import ImageButton from "../preview/radio/image/ImageButton";
import { v4 as uuidv4 } from "uuid";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import swal from "sweetalert";
import TextareaAutosize from "react-textarea-autosize";
import ImageComposer from "./Preview";
import { withTranslation} from 'react-i18next';
class ConfiguratorLanding extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.messagesRef=React.createRef(null);
    this.state = {
      templateObject: [],
      services: [],
      allowZoomOut: false,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      borderRadius: 0,
      preview: null,
      width: 850,
      height: 320,
      loader:false,
      // eventManagerDetails: {
      //   addressTitle: "Max und Franzi Mustermann",
      //   addressMain: "Musterstraße 5, 12345 Musterhausen",
      //   addressTel: "Tel. 0123 4567 8900",
      //   addressMail: "max.mustermann@muster.web",
      // },
      guestInfo: {},
      themeData: {},
      bool: false,
      price: 0,
      showTemplatePopup: false,
      selectedTemplate: 0,
      selectedQuestion: 0,
      selectedTemplateId: 1,
      selectedQuestionId: 1,
      addTemplateInput: "",
      editable: true,
      cuDetails: {},
      questFetchStatus: undefined,
      questionnaire: {},
      headerImage: "",
      newEntry: true,
      loading: false,
      header:"",
      oldHeaderImage:"",
      eventPageRoute:null
    };
  }

  componentDidMount() {
    const data = this.context;
    const obj=JSON.parse(sessionStorage.getItem("state2"));
    this.setState({ eventPageRoute: obj?.eventsRoute });
    // console.log("eventsPage",obj.eventsRoute)
    // if (window.innerWidth > 1525){
    //   bool=true
    //       }
       
    //       window.addEventListener("resize", () => {
    //         if (window.innerWidth > 1525){
    //           bool=true
    //         }
    //         else {
    //           bool=false
    //         }
    //       });
    //       // console.log("bool",bool)
    //       if(bool==true){
           
    //         this.setState({height:450})
    //       }
    //       else{
    //         this.setState({height:320})
    //       }
    //       const canvas = document.getElementById('canvas');

    //       const ctx = canvas?.getContext('2d');
          
    //       let image = new Image();
    //       image.src =this.state.headerImage;
    //       image.onload = function() {
    //         ctx.imageSmoothingQuality = 'High';
    //         ctx.drawImage(image, 0, 0, 300, 150);
    //       };
    
    
     HttpService.getGuests(data.questionnaireId).then(
      (response) => {
        this.setState({
          guestInfo: response
        })   
      }
    );

    axios
      .get(`${baseUrl}api/Themes/${data.questionnaireId}`)
      .then((res) => {
        const themes = res.data?.themeApiObject?.themes;
        var x = themes[0].services[0];
        const eventPageRoute = obj?.eventsRoute === "eventPage" ? 1 : 0 
        axios
        .get(
          baseUrl +
            `api/themes/${this.context.questionnaireId}/${themes[0].id}/${themes[0].services[0].id}/${eventPageRoute}`
        )
        .then((resp) => {
        
          if (resp.data) {
            this.setState({ headerImage: resp.data.base64String });
          }
        })
        .catch((error) => {
          // console.log(error);
        });


        const flag = x?.isSaved || false;
        this.setState({
          templateObject: themes,
          editable: flag,
          oldHeaderImage: x.headerImage ? x.headerImage : "",
          newEntry: false,
          selectedTemplateId: themes[0].id,
          selectedQuestionId: themes[0].services[0].id,
        });
        this.handleDisplay(themes[0].services[0].id, 0);
      })
      .catch((err) => {
        console.log(err);
      });
    
    axios
      .get(`${baseUrl}api/pricing/totalAmount/${data.questionnaireId}`)
      .then((res) => {
        const price = res.data;
        this.setState({
          price,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    if (this.context.eventId?.length) {
      axios
        .get(`${baseUrl}api/user/ByEvent/${this.context.eventId}/CU`)
        .then((response) => {
          this.context.updateState({ cuDetails: response.data });
          this.setState({
            cuDetails: response.data,
           
          });
           console.log("cudetails",response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

   console.log("configurationvenue");
    axios
      .get(`${baseUrl}api/venue/${this.context.venueId}`)
      .then((response) => {
        this.context.updateState({
          dateFormat: response.data?.dateFormat
            ?.toLowerCase()
            .replace(/m/g, "M"),
          timeFormat: response.data?.timeFormat,
          currencyFormat: response.data?.currencyFormat

        });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${baseUrl}api/questionnaireSetting/${data.parentQuestionnaireId}`)
      .then((res) => {
        if (Object.keys(res.data).length) {
          this.setState({
            stylingData: res?.data,
            textColor: res.data?.textColor,
            textFontFamily: res.data?.textFontFamily,
            textFontSize: res.data?.textFontSize,
            headingFontSize: res.data?.headingFontSize,
            headingFontFamily: res.data?.headingFontFamily,
            paragraphs: res.data.Questionaireparagraphs
              ? res.data.Questionaireparagraphs
              : [],
          });
        }
      
        this.context.updateState({ stylingData: res?.data });
     
      })
    
      .catch((err) => {
        console.log("err", err);
      });
  
  }
  closeTemplatePopup = (d, themes) => {
    if (this.messagesRef.current) {
    
  }
    if (d === "yes") {
      this.setState({
        showTemplatePopup: false,
        templateObject: themes,
        editable: true,
        questionnaire: {},
      });
     
    } else {
      this.handleDisplay(this.state.selectedQuestionId,this.state.selectedTemplateId);
      this.setState({
        showTemplatePopup: false,
        editable: true,
        questionnaire: {},
      });
    }
  };

  openTemplatePopup = () => this.setState({ showTemplatePopup: true });

  openEditPopup = (index, id) => {
    const themes = Object.assign([], this.state.templateObject);
    this.context.updateState({
      serviceId: id,
      themeId: themes[this.state.selectedTemplate].id,
    });
   
    this.setState({
      selectedQuestion: index,
      selectedQuestionId: id,
      // selectedTemplateId: this.state.selectedTemplate + 1,
      showEditPopup: true,
    });
  };

  closeEditPopup = (str, themes) => {

    if (str === "yes") {
      this.setState({
        templateObject: themes,
        showEditPopup: false,
        editable: true,
        questionnaire: {},
      });
    } else {
      this.handleDisplay(this.state.selectedQuestionId,this.state.selectedTemplateId);
      this.setState({
        showEditPopup: false,
        editable: true,
        questionnaire: {},
      });
    }
  };

  setTemplateInput = (e) => this.setState({ addTemplateInput: e.target.value });

  handleBlur = (e) => {
    if (this.state.addTemplateInput !== "") {
      const templateObject = this.state.templateObject;
      let id = uuidv4();
      templateObject.push({
        // id: templateObject.length + 1,
        id,
        name: this.state.addTemplateInput,
        services: [{ name: "", id: 1 }],
      });
      this.setState({ templateObject, addTemplateInput: ""});
      this.setSelectedTemplate(templateObject.length-1, id)
      this.handleSaveThemes();
     
    }
  };
  handleKey = (e) => {
    if (e.key === "Enter") {
      return this.handleBlur();
    }
  };
  // addTemplateObject = () => {
  //   if (this.state.addTemplateInput !== "") {
  //     const templateObject = this.state.templateObject;
  //     let id= uuidv4()
  //     templateObject.push({
  //       // id: templateObject.length + 1,
  //       id,
  //       name: this.state.addTemplateInput,
  //       services: [{ name: "", id: 1 }],
  //     });
  //     this.setState({ templateObject, addTemplateInput: "" });

  //   }
  // };

  setSelectedTemplate = (index, id) => {
    const { templateObject } = this.state;
    this.context.updateState({
      themeId: id,
      serviceId: templateObject[index]?.services[0]?.id,
    });

    this.setState(
      {
        selectedTemplate: index,
        selectedTemplateId: id,
        selectedQuestionId: templateObject[index]?.services[0]?.id,
        selectedQuestion: 0,
        questionnaire: {},
        oldHeaderImage: templateObject[index]?.services[0]?.headerImage,
      },
      () => {
        if (templateObject[index]?.services[0]?.isSaved) {
          // console.log('theme',templateObject[index]?.services[0]?.id)
          this.handleDisplay(templateObject[index]?.services[0]?.id, 0);    
        }
         //selecting question
      const themes = Object.assign([], templateObject);
      var x = themes[templateObject.length-1].services[0].id;
      console.log("template last index", templateObject.length-1);
       this.selectQuestion(0, x);  
      }
    );
  };
  deleteHeaderImage = () => {
    this.state.templateObject.forEach((template) => {
      if (template.id === this.state.selectedTemplateId) {
        template.services.forEach((o) => {
          if (o.id === this.state.selectedQuestionId) {
            return delete o["headerImage"];
          }
        });
      }
    });

    this.setState({
      headerImage: "",
    });
  };
  addQuestion = () => {
    const templateObject = this.state.templateObject;
    const l = templateObject[this.state.selectedTemplate].services.length;
    const lastItem =
      templateObject[this.state.selectedTemplate].services[l - 1];
    const uniqueId = uuidv4();
    templateObject[this.state.selectedTemplate].services.push({
      name: "",
      // id: l + 1,
      id: uniqueId,

      oldHeaderImage: lastItem?.headerImage ? lastItem?.headerImage : "",
    }); 
    this.setState({ templateObject, selectedQuestionId: "", headerImage:""}, ()=>{
     const themes = Object.assign([], this.state.templateObject);
     var x = themes[this.state.selectedTemplate].services.length-1;
      this.selectQuestion(x, uniqueId);
    });   
  };

  selectQuestion = (index, id) => {
    const themes = Object.assign([], this.state.templateObject);
    var x = themes[this.state.selectedTemplate].services[index];
    const flag = x?.isSaved || false;
    this.context.updateState({
      serviceId: id,
      themeId: themes[this.state.selectedTemplate].id,
    });
    // console.log("theme", this.state.selectedTemplateId, "serv", id);
    this.setState({
      selectedQuestion: index,
      selectedQuestionId: id,
      // selectedTemplateId:this.state.selectedTemplateId,
      editable: flag,
      questionnaire: {},
      oldHeaderImage: x.headerImage ? x.headerImage : "",
      headerImage:x.headerImage ? x.headerImage : "",
    });
  };

  setStepName = (index, e) => {
    const templateObject = this.state.templateObject;
    templateObject[this.state.selectedTemplate].services[index].name =
      e.target.value;
    this.setState({ templateObject });
  };
  deleteQuestionAndTheme = async () => {
    const ifDelete = await swal({
      title: `${this.props.t('Bist du sicher')}`,
      text:
      `${this.props.t('Nach dem Löschen können Sie diese Seite nicht mehr wiederherstellen!')}`,
      buttons: [`${this.props.t('Abbrechen')}`, "OK"],
      dangerMode: true,
    });
    if (ifDelete) {
      let { templateObject, selectedQuestion, selectedTemplate } = this.state;
      // if (deleteServices.status === true) {
      const getThemes = await HttpService.getBuilderThemes(
        this.context.questionnaireId
      );


      if (getThemes.themeApiObject) {
        // let putThemeData = { themeApiObject };

        let y;
        let copyObj = Object.assign([], this.state.templateObject);
        let dummy = copyObj.map((t) => {
          if (
            t?.services?.length > 1 &&
            t?.id === this.state.selectedTemplateId
          ) {
            const x = t.services.filter(
              (s) => s.id !== this.state.selectedQuestionId
            );

            t.services = x;
            this.setState({ questionnaire: {} });

            return t;
          } else if (
            t.services.length === 1 &&
            t.id === this.state.selectedTemplateId
          ) {
            y = this.state.templateObject.filter(
              (t) => t.id !== this.state.selectedTemplateId
            );

            return t;
          } else {
            return t;
          }
        });

        if (y) {
          // const tempId = templateObject[selectedTemplate]?.id;

          const lastTemplate = templateObject[templateObject.length - 2];
          const lastServiceId =
            lastTemplate?.services[lastTemplate?.services?.length - 1]?.id;
          const index = lastTemplate?.services.indexOf(
            lastTemplate?.services[lastTemplate?.services?.length - 1]
          );
          if (selectedTemplate !== 0) {
            const tempId = templateObject[selectedTemplate - 1].id;
            this.setSelectedTemplate(selectedTemplate - 1, tempId);

            this.handleDisplay(lastServiceId, index);
          }
          if (selectedTemplate === 0) {
            this.props.history.goBack();
          }

          getThemes.themeApiObject.themes = y;
          this.setState({ templateObject: y });
        } else {
          if (selectedQuestion !== 0) {
            const servId =
              templateObject[selectedTemplate]?.services[selectedQuestion - 1]
                ?.id;
            this.handleDisplay(servId, selectedQuestion - 1);
          } else if (
            selectedQuestion === 0 &&
            templateObject[selectedTemplate]?.services.length === 0
          ) {
            
            
            this.setState({
              selectedTemplate: selectedTemplate - 1,
              selectedTemplateId: templateObject[selectedTemplate - 1]?.id,
            });
          } else if (
            selectedQuestion === 0 &&
            templateObject[selectedTemplate]?.services.length
          ) {
            const servId =
              templateObject[selectedTemplate]?.services[selectedQuestion]?.id;
            this.handleDisplay(servId, selectedQuestion);
          }

          getThemes.themeApiObject.themes = dummy;
          getThemes.questionnaireName = this.context.questionnaireName;
          this.setState({ templateObject: dummy });
        }
        delete getThemes._id
        return HttpService.putThemes(getThemes.questionnaireId, getThemes);
      }

      // }
    }
  };

  guests = () => {
    this.props.history.push("/guests", this.props.location.state);
  };
  handleLogout = () => {
    const urlName = this.context.urlName;
    this.context.updateState({
      authenticated: false,
      from:"logout"

    });
    localStorage.clear();
    sessionStorage.clear();
    if (urlName === "adminScreen") {
      this.props.history.push(`/login`);
    } else {
      this.props.history.push(`/login/${urlName}`);
    }
  };

  handleBack = () => {
    // themes[0].services[0].id

    const { selectedTemplate, selectedQuestion, templateObject } = this.state;

    if (selectedTemplate === 0 && selectedQuestion === 0) {
      this.props.history.push("/guests", this.props.location.state);
    } else if (selectedQuestion !== 0) {
      const servId =
        templateObject[selectedTemplate].services[selectedQuestion - 1].id;
      this.handleDisplay(servId, selectedQuestion - 1);
    } else if (selectedQuestion === 0) {
      let leng = templateObject[selectedTemplate - 1].services.length - 1;
      const servId = templateObject[selectedTemplate - 1].services[leng].id;
      this.setState(
        {
          selectedTemplate: selectedTemplate - 1,
          selectedTemplateId: templateObject[selectedTemplate - 1].id,
        },
        () => {
          this.handleDisplay(servId, leng);
        }
      );
    }
    // let object = {};
    // this.state.templateObject.map((theme) => {
    //   if (theme.id == this.state.selectedTemplateId) {
    //     object = ({}, theme.services);
    //   }
    // });

    // if (
    //   this.state?.templateObject[0]?.id != this.state.selectedTemplateId &&
    //   object[0]?.id == this.state.selectedQuestionId
    // ) {
    //   this.setSelectedTemplate(
    //     this.state.selectedTemplate - 1,
    //     this.state.selectedTemplateId - 1
    //   );
    // } else if (
    //   (this.state.templateObject[0]?.id == this.state.selectedTemplateId &&
    //     object[0]?.id == this.state.selectedQuestionId) ||
    //   this.state.templateObject.length === 0
    // ) {
    //   this.props.history.goBack();
    // } else {
    //   this.handleDisplay(
    //     this.state.selectedQuestion,
    //     this.state.selectedQuestionId - 1
    //   );
    // }
  };
  handleSaveThemes = async () => {
    const getThemes = await HttpService.getBuilderThemes(
      this.context.questionnaireId
    );
    const themeApiObject = { themes: this.state.templateObject };
    if (getThemes.themeApiObject) {
      // const putThemeData = {
      //   themeApiObject,
      //   // ...qt
      // };
      getThemes.themeApiObject = themeApiObject;
      getThemes.questionnaireName = this.context.questionnaireName;
      delete getThemes._id
      await HttpService.putThemes(this.context.questionnaireId, getThemes);
    } else {
      const qt = { questionnaireId: this.context.questionnaireId };
      const postThemeData = {
        themeApiObject,
        ...qt,
      };
      await HttpService.postThemes(postThemeData);
    }

    
  };
  handleSave = async () => {
    // console.log("postion",this.state.position)
    const getThemes = await HttpService.getBuilderThemes(
      this.context.questionnaireId
    );
    const themeApiObject = { themes: this.state.templateObject };
    if (getThemes.themeApiObject) {
      // const putThemeData = {
      //   themeApiObject,
      //   // ...qt
      // };
      // const dummyLay = Object.assign([], this.state.templateObject);
      themeApiObject.themes.forEach((theme) => {
        if (theme.id === this.state.selectedTemplateId) {
          theme.services.forEach((serv) => {
            if (serv.id === this.state.selectedQuestionId) {
              // console.log("serv",serv)
              serv.position = this.state.position;
              serv.scale = this.state.scale;
              // serv.headerImage=this.state.oldHeaderImage?
              // this.state.oldHeaderImage:this.state.headerImage?this.state.headerImage:""
              serv.headerImage=this.state.headerImage?
              this.state.headerImage:this.state.oldHeaderImage?this.state.oldHeaderImage:""
            }
          });
        }
      });
      // this.setState({ position ,templateObject: dummyLay})
      getThemes.themeApiObject = themeApiObject;
      getThemes.questionnaireName = this.context.questionnaireName;
      delete getThemes._id
      await HttpService.putThemes(this.context.questionnaireId, getThemes);
    } else {
      const qt = { questionnaireId: this.context.questionnaireId };
      const postThemeData = {
        themeApiObject,
        ...qt,
      };
      await HttpService.postThemes(postThemeData);
    }

    const { selectedTemplate, selectedQuestion } = this.state;
    const length =
      this.state.templateObject[selectedTemplate].services.length - 1;
    if (selectedQuestion === length) {
      this.addQuestion();
    } else {
      const servId = this.state.templateObject[selectedTemplate].services[
        selectedQuestion + 1
      ].id;
      this.handleDisplay(servId, selectedQuestion + 1);
    }
    // let object = {};
    // this.state.templateObject.map((theme) => {
    //   if (theme.id == this.state.selectedTemplateId) {
    //     object = ({}, theme.services);
    //   }
    // });

    // if (
    //   Object.keys(this.state.questionnaire).length === 0 &&
    //   this.state.selectedQuestion == 0
    // ) {
    //   this.handleDisplay(1, this.state.selectedQuestion);
    //   this.setState({ bool: true });
    // } else if (
    //   Object.keys(this.state.questionnaire).length !== 0 &&
    //   this.state.selectedQuestion == 0
    // ) {
    //   this.handleDisplay(
    //     this.state.selectedQuestionId + 1,
    //     this.state.selectedQuestion
    //   );
    // }
    // if (
    //   (object[object.length - 1].id == this.state.questionnaire.serviceId) ==
    //   true
    // ) {
    //   this.addQuestion();
    // }
  };
  handleDisplay = async (id, _i) => {
    console.log("handle display called",id,"imdex",_i)
this.setState({headerImage:"",loader:true})
    const themes = Object.assign([], this.state.templateObject);

    this.context.updateState({
      serviceId: id,
      themeId: themes[this.state.selectedTemplate]?.id,
    });

    var x = themes[this.state.selectedTemplate]?.services[_i];
    console.log("x",x);

    const getQtn = await HttpService.ifIsSaved(
      this.context.questionnaireId,
      this.state.selectedTemplateId,
      id
    );
    // console.log("scale",x)
    // console.log("x",x)
    const eventPageRoute = this.state.eventPageRoute === "eventPage" ? 1 : 0
    axios
    .get(
      baseUrl +
        `api/themes/${this.context.questionnaireId}/${this.state.selectedTemplateId}/${id}/${eventPageRoute}`
    )
    .then((res) => {
    
      if (res.data) {
        this.setState({ headerImage: res.data.base64String ,loader:false});
      }
    })
    .catch((error) => {
      // console.log(error);
    });
    this.setState({
      oldHeaderImage: x?.headerImage ? x?.headerImage : "",
      selectedQuestion: _i,
      selectedQuestionId: id,
      questionnaire: getQtn ? getQtn : {},
      // scale:x.scale ? x.scale:1,
      // position:x.position ? x.position : { x: 0.5, y: 0.5 },
    });
    // console.log("selectedtemplate",this.state.selectedTemplateId)
  };
setHeaderImage=(header,templateObject)=>{
  // console.log("template object",templateObject)
this.setState({headerImage:header,oldHeaderImage:header})
}
  onUpdate = (updatedOption, count) => {
    const { questionnaire } = this.state;
    const { row, column, ele } = count;

    questionnaire.question[row].row[column].column[ele].option = updatedOption;
    this.setState({ questionnaire });
  };

  onImageSelect = (imageFiles, count) => {
    // this.images[`${row}:${column}:${ele}`] = imageFiles;
  };
  onDrop = async (acceptedFiles) => {
    this.setState({ loading: true });
    const getThemes = await HttpService.getBuilderThemes(
      this.context.questionnaireId
    );
   
    acceptedFiles.forEach((file) => {
      let img = new Image()
      img.src = window.URL.createObjectURL(file)
     
img.onload = () => {
   if(img.width >= 1920 && img.height >= 1080){
    const data = new FormData();
    data.append("blob", file);
    axios
      .post(baseUrl +`api/blob/${2}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        // console.log("res",res.data)
        if (!res) {
          this.setState({ loading: true });
        } else {
          this.setState({ loading: false });
          const dummyLay = Object.assign([], this.state.templateObject);
          dummyLay.forEach((theme) => {
            if (theme.id === this.state.selectedTemplateId) {
              theme.services.forEach((serv) => {
                if (serv.id === this.state.selectedQuestionId) {
                  // serv.headerImage = res.data.url;
                }
              });
            }
          });

          getThemes.themeApiObject.themes = dummyLay;

          axios
            .put(
              `${baseUrl}api/themes/${this.context.questionnaireId}`,
              getThemes
            )
            .then((res) => {
              return Promise.resolve(res?.data);
            })
            .catch((error) => {
              return Promise.resolve({ message: error });
            });

          this.setState({
            templateObject: dummyLay,
            // headerImage: res.data.url,
          });
        }
      })

      .catch((error) => {
        console.log(error);
      });
   }
  else{
    alert(` Bitte laden Sie hier nur Bilder mit einer größeren Auflösung als 1920x1080 Pixel hoch`);
  }
  }

     
    });
  };
  renderOption = (option, count) => {
    // console.log('here', option)
    const params = {
      option,
      onUpdate: (updatedOption) => this.onUpdate(updatedOption, count),
      onUpdateWithoutAPICall: (updatedOption) =>
        this.onUpdate(updatedOption, count),
        
    };
    //commented the hide on linking functionality in questionaire configuration
    // if (option?.display === false) {
    //   return null;
    // }
    const radioParams = {
      guestCount: this.state.guestCount,
      option,
      onUpdateWithoutAPICall: (updatedOption) =>
        this.onUpdate(updatedOption, count),
      onUpdate: (updatedOption) => this.onUpdate(updatedOption, count),
      guestInfo:this.state.guestInfo
    
    };
    switch (option.type) {
      case "text":
        return <TextComponent {...params} />;
      case "datum":
        return <DateTime {...params} />;
      case "bild":
        return <Bild {...params} />;
      case "radio":
        return <RadioButton {...radioParams} />;
      case "table":
        return <Table {...params} />;
      case "upload":
        return (
          <UploadDownload
            {...params}
            onSelect={(imageFiles) => this.onImageSelect(imageFiles, count)}
          />
        );
      case "image":
        return <ImageButton {...params} />;
      default:
        break;
    }
  };
  onDragOver = (e) => {
    e.preventDefault();
  };
  onServiceDrop = (e) => {
    // e.preventDefault();
    const index = e.dataTransfer.getData("index");
    const target = e.target.id;
    if (target?.length && index?.length) {
      this.swapServicesFunction(target, index);
    }
    this.handleSaveThemes();
  };
  swapServicesFunction = (target, startIndex) => {
    const themes = Object.assign([], this.state.templateObject);
    const x = themes[this.state.selectedTemplate].services[startIndex];
    themes[this.state.selectedTemplate].services.splice(startIndex, 1);
    themes[this.state.selectedTemplate].services.splice(target, 0, x);
    this.setState({
      templateObject: themes,
    });
  };

  onDragStart = (e, index) => {
    e.dataTransfer.setData("index", index);
  };

  onThemeDragStart = (e, index) => {
    e.dataTransfer.setData("themeIndex", index);
  };

  onDragOverThemes = (e) => {
    e.preventDefault();
  };

  onThemeDrop = (e) => {
    // e.preventDefault();
    const index = e.dataTransfer.getData("themeIndex");
    const target = e.target.id;
    if (target?.length && index?.length) {
      this.swapThemesFunction(target, index);
    }
    this.handleSaveThemes();
  };
  swapThemesFunction = (target, index) => {
    const themes = Object.assign([], this.state.templateObject);
    const x = themes[index];
    themes.splice(index, 1);
    themes.splice(target, 0, x);
    this.setState({
      templateObject: themes,
      editable: true,
      questionnaire: {},
      headerImage: "",
      selectedQuestionId: 1,
      selectedTemplateId: themes[this.state.selectedTemplate].id,
    });
  };
  handleThemeRename = (e, index) => {
    // console.log(this.state.templateObject[index].name, e.target.value);
    const templateObject = Object.assign([], this.state.templateObject);
    this.setState().templateObject[index].name = e.target.value;
    this.setState({
      templateObject,
    });
  };
  functionCapitalise = (initialTitle) => {
    let title = "";
    if (initialTitle) {
      title = initialTitle[0]
        .toUpperCase()
        .concat(initialTitle.slice(1, initialTitle.length));
    }
    return title;
  };

  // handleNewImage = (e) => {
  //   this.setState({ headerImage: e.target.files[0] });
  // };

  handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    // console.log("scale",scale)
    const dummyLay = Object.assign([], this.state.templateObject);
    dummyLay.forEach((theme) => {
      if (theme.id === this.state.selectedTemplateId) {
        theme.services.forEach((serv) => {
          if (serv.id === this.state.selectedQuestionId) {
            serv.scale = scale;
          }
        });
      }
    });
    this.setState({ scale, templateObject: dummyLay });
  };

  handlePositionChange = (position) => {
    const dummyLay = Object.assign([], this.state.templateObject);
    dummyLay.forEach((theme) => {
      if (theme.id === this.state.selectedTemplateId) {
        theme.services.forEach((serv) => {
          if (serv.id === this.state.selectedQuestionId) {
            serv.position = position;
          }
        });
      }
    });
    this.setState({ position, templateObject: dummyLay });
  };

  onClickSave = () => {};

  setEditorRef = (editor) => (this.editor = editor);
  render() {
    const textFontSize = this.context.stylingData?.textFontSize;
    const textFontFamily = this.context.stylingData?.textFontFamily;

    return (
			<div className="container-fluid configurator-landing">
				<div
					className={`row ${
						this.state.showTemplatePopup || this.state.showEditPopup
							? 'blur'
							: ''
					}`}>
					{/* {console.log(
            // "tempid, qtnid",
            // this.state.selectedTemplate,
            // this.state.selectedQuestion,
            "thmeid, servId",
            this.state.selectedTemplateId,
            this.state.selectedQuestionId
          )} */}
					<div
						className="col-lg-2 config-left-bar"
						style={{ paddingRight: '0px' }}>
						<OverlayTrigger
							// key="bottom"
							placement="bottom"
							overlay={
								<Tooltip id={`tooltip-disabled`}>
									<span>{this.props.t('logout')}</span>
								</Tooltip>
							}>
							<button
								className="logout-btn"
								style={{
									border: 'none',
									marginTop: '10px',
									background: 'white',
								}}
								onClick={this.handleLogout}>
								<img src={logoutIcon} alt="" />
							</button>
						</OverlayTrigger>
						<OverlayTrigger
							key="bottom"
							placement="bottom"
							overlay={
								<Tooltip id={`tooltip-bottom`}>
									<span>{this.props.t('home')}</span>
								</Tooltip>
							}>
							<button
								className="home-btn border-0 m-2"
								style={{ border: 'none', background: 'white' }}
								onClick={(e) => {
									this.props.location.state?.from === 'questionnairePage'
										? this.props.history.push('/admin/questionnaire')
										: this.props.history.push('/admin/events');
								}}>
								<img
									style={{ width: '20px', height: '25px' }}
									src={home}
									alt=""
								/>
							</button>
						</OverlayTrigger>
						<img
							className="main-logo"
							// style={{ width: "150px", height: "150px" }}
							style={{
								width: '100%',
								height: 'auto',
								maxHeight: '80px',
								objectFit: 'contain',
								imageRendering: 'high-quality',
								marginTop: '15px',
							}}
							src={this.context.venueLogo}
							alt=""
						/>
						<div
							// ref={this.messagesRef}
							className="template-container"
							onDragOver={(e) => this.onDragOverThemes(e)}
							onDrop={(e) => this.onThemeDrop(e)}>
							<div className="template-item" onClick={this.guests}>
								{this.props.t('Anzahl')}
							</div>
							{this.state.templateObject.map((item, index) => (
								<div
									className="template-item"
									key={index}
									id={index}
									draggable
									onDragStart={(e) => this.onThemeDragStart(e, index)}
									onClick={() => this.setSelectedTemplate(index, item.id)}>
									<TextareaAutosize
										id={index}
										type="text"
										style={{
											overflow: 'hidden',
											cursor: 'pointer',
											resize: 'none',
											outline: 'none',
											width: '150px',
											fontWeight:
												this.state.selectedTemplateId === item.id ? 'bold' : '',
											color:
												this.state.selectedTemplateId === item.id
													? 'black'
													: '#a2a4a5',
											border: 'none',
										}}
										value={item?.name}
										onChange={(e) => {
											this.handleThemeRename(e, index);
										}}
										onBlur={this.handleSaveThemes}
									/>

									{/* <input
                    id={index}
                    type="text"
                    style={{
                      fontWeight:
                        this.state.selectedTemplateId == item.id ? "bold" : "",
                      color:
                        this.state.selectedTemplateId == item.id
                          ? "black"
                          : "#a2a4a5",
                      border: "none",
                    }}
                    value={item?.name}
                    onChange={(e) => {
                      this.handleThemeRename(e, index);
                    }}
                  /> */}
								</div>
							))}

							{/* <button
                className="add-template-btn"
                onClick={this.addTemplateObject}
              >
                <img
                  src={upload}
                  alt=""
                  style={{ width: "15px", height: "15px" }}
                />
              </button> */}

							<div style={{ marginTop: '10px' }}>
								<input
									type="text"
									className="add-template-input"
									value={this.state.addTemplateInput}
									onChange={this.setTemplateInput}
									onBlur={this.handleBlur}
									onKeyDown={this.handleKey}
									placeholder={this.props.t('Thema einfügen')}
								/>
							</div>
						</div>
					</div>
					<div
						className="col-lg-8"
						style={{
							paddingLeft: '0px',
							paddingRight: '0px',
							marginTop: '0px',
						}}>
						{this.state.loading === true && (
							<span style={{ color: 'grey' }}>{this.props.t('Loading')}</span>
						)}
						<div
						// className={!this.state.headerImage && "dropzone" }
						// style={{ backgroundImage: `url(${this.state.headerImage})` }}
						>
							{/* {console.log("questionnaire",this.context.questionnaireId)} */}
							<ImageComposer
								setHeaderImage={this.setHeaderImage}
								questionnaireId={this.context.questionnaireId}
								templateObject={this.state.templateObject}
								selectedTemplateId={this.state.selectedTemplateId}
								stepId={this.state.selectedQuestionId}
								headerImage={
									this.state.headerImage
										? this.state.headerImage
										: this.state.oldHeaderImage
								}
								loader={this.state.loader}
							/>
						</div>
						<div
							style={{
								borderLeft: '1px solid #d1d3d4',
								fontFamily: `${textFontFamily}`,
								fontSize: `${textFontSize}`,
							}}>
							<div
								className="steps-indicator"
								onDragOver={(e) => this.onDragOver(e)}
								onDrop={(e) => this.onServiceDrop(e)}>
								{this.state?.templateObject?.length
									? this.state.templateObject[
											this.state.selectedTemplate
									  ]?.services.map((item, index) => (
											<div
												className="step-item"
												key={index}
												id={index}
												draggable
												onDragStart={(e) => this.onDragStart(e, index)}>
												{item?.isSaved ? (
													<div className="circle">
														<div
															id={index}
															style={{
																background:
																	item.id === this.state.selectedQuestionId
																		? '#297825'
																		: '',
															}}
															className="circle green"
															onClick={() => {
																this.handleDisplay(item.id, index);
															}}></div>
														<div
															className="editCircle"
															id={index}
															onClick={() => {
																this.openEditPopup(index, item.id);
															}}>
															<div
																id={index}
																style={{
																	background: `url(${editIcon})`,
																	height: '28px',
																	backgroundRepeat: 'no-repeat',
																	backgroundPosition: 'center',
																	backgroundSize: '15px auto',
																}}
															/>
														</div>
													</div>
												) : (
													<div
														className="circle"
														id={index}
														onClick={() => this.selectQuestion(index, item.id)}
													/>
												)}

												<input
													type="text"
													className="step-name"
													value={item.name}
													onChange={(e) => this.setStepName(index, e)}
													placeholder={this.props.t('Überschrift einfügen')}
												/>
												<span className="line"></span>
												<span className="line second"></span>
											</div>
									  ))
									: null}
								<div className="step-add">
									{this.state.templateObject.length ? (
										<button className="circle" onClick={this.addQuestion}>
											<img src={plusIcon} alt="" />
										</button>
									) : (
										<button className="circle" disabled>
											<img src={plusIcon} alt="" />
										</button>
									)}
									<span className="line"></span>
								</div>
							</div>
							<div
								className="single-template"
								style={{ margin: '50px 80px 5px 80px' }}>
								{
									<>
										{this.state.questionnaire?.question ? (
											<div
												className="layout-row-container"
												style={{ margin: '0px' }}>
												{/* {console.log("div", this.state.questionnaire?.question)} */}
												{this.state.questionnaire?.question?.map(
													(item, index) => (
														<div
															// ref={this.messagesRef}
															key={index}
															className={`layout-box-container ${
																item.orientation === 'bottom' && 'space-top '
															} ${
																item.orientation === 'top'
																	? 'space-bottom'
																	: 'space-bottom'
															}`}>
															{item.row.map((_item, _index) => (
																<div
																	ref={this.messagesRef}
																	key={_index}
																	className="layout-box-vertical"
																	style={{
																		width: `calc(${
																			(100 / item.quantity) * _item.span
																		}% - ${
																			((item.quantity - _item.span) * 30) /
																			item.quantity
																		}px)`,
																	}}>
																	{_item.column.map((__item, __index) => (
																		<div
																			key={__index}
																			className="layout-box"
																			style={{
																				height: `calc(${
																					(100 / _item.quantity) * __item.span
																				}% - ${
																					((_item.quantity - __item.span) *
																						30) /
																					_item.quantity
																				}px)`,
																			}}>
																			<div className="option-container">
																				{this.renderOption(__item.option, {
																					row: index,
																					column: _index,
																					ele: __index,
																				})}
																			</div>
																		</div>
																	))}
																</div>
															))}
														</div>
													)
												)}
											</div>
										) : (
											<div className="pick-template">
												{this.state.editable ? null : (
													<img
														src={plusIcon}
														alt=""
														style={{ padding: '20% 50%', cursor: 'pointer', size: "100%" }}
														onClick={this.openTemplatePopup.bind(this)}
													/>
												)}
											</div>
										)}
									</>
								}
							</div>
						</div>
						<div className="links-container">
							<button
								className="green-border-btn"
								style={{ background: 'white' }}
								onClick={this.handleBack}>
								{this.props.t('return')}
							</button>
							<button
								className="green-border-btn"
								style={{ background: 'white' }}
								onClick={this.handleSave}>
								{this.props.t('weiter')}
							</button>

							{}
							<br />
							<br />
							<button
								className="green-border-btn"
								style={{ background: 'white' }}
								onClick={this.deleteQuestionAndTheme}>
								{this.props.t('Löschen')}
							</button>
						</div>
					</div>
					<div className="col-lg-2 config-right-bar">
						<div className="pricing-section">
							<h6 style={{ fontSize: '19px' }}>
								{this.props.t('event_price')}
							</h6>
							<h1>
								{' '}
								{this.context.languageFormat === 'en'
									? `${this.state.price
											.toFixed(2)
											.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${
											this.context.currencyFormat
									  }`
									: `${this.state.price
											.toFixed(2)
											.replace('.', ',')
											.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  ${this.context.currencyFormat}`}
							</h1>
							<div className="seperator"></div>
						</div>
						<div className="bottom-section">
							{this.context.eventId?.length ? (
								<>
									<p style={styles.title}>{this.props.t('Billing_address')}</p>
									<p
										style={{ ...styles.text, ...styles.pointer }}
										onClick={() => this.toUserDetailsPage()}>
										{this.props.t('Change')}
									</p>
									<br />
									<>
										{/* <p style={styles.bold}>
                      {cuDetails?.firstName1} {cuDetails?.surname1}
                    </p> */}
										<p style={styles.text}>Maxi Mustermann</p>
										<p style={styles.text}>Musterstraße 10</p>
										<p style={styles.text}>12345 Musterstetten</p>
										<br />
										<p style={styles.text}>Tel. 01234567891</p>
										<p style={styles.text}>maxi@muster.de</p>
									</>
								</>
							) : null}
						</div>
					</div>
				</div>
				{this.state.showTemplatePopup ? (
					<ConfiguratorTemplateSelectionPopup
						closeTemplatePopup={this.closeTemplatePopup.bind(this)}
						templateId={this.state.selectedTemplateId}
						stepId={this.state.selectedQuestionId}
						templateObject={this.state.templateObject}
					/>
				) : null}

				{this.state.showEditPopup && (
					<ConfiguratorTemplateEditPopup
						closeEditPopup={this.closeEditPopup}
						templateId={this.state.selectedTemplateId}
						stepId={this.state.selectedQuestionId}
						templateObject={this.state.templateObject}
					/>
				)}
			</div>
		);
  }
}

export default withTranslation() (ConfiguratorLanding);

const styles = {
  outline: {
    color: "#297825",
    fontFamily: "Crete Round",
    fontSize: "13px",
    border: "1px solid #297825",
    minWidth: "143px",
    padding: "9px 15px",
    textAlign: "center",
    display: "inline-block",
    marginRight: "15px",
  },
  solid: {
    background: "#297825",
    fontFamily: "Crete Round",
    border: "1px solid #297825",
    color: "#fff",
    fontSize: "13px",
    minWidth: "143px",
    padding: "9px 15px",
    textAlign: "center",
    display: "inline-block",
    marginRight: "15px",
  },
  stepsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  headerImage: {
    width: "100%",
    height: "140px",
    objectFit: "cover",
  },
  input: {
    border: 0,
    padding: 0,
    margin: 0,
    background: "transparent",
    borderBottom: "1px solid #707070",
    width: "200px",
  },
  inputLabel: {
    fontFamily: "Crete Round",
    fontStyle: "Italic",
    color: "#95969b",
    fontSize: "14px",
  },
  inputWrapper: {
    display: "inline-block",
    marginRight: "40px",
  },
  flexRow: {
    flexDirection: "row",
  },
  priceWrapper: {
    background: "#e6e7e8",
    right: "0",
    top: "0",
    display: "block",
    width: "360px",
    height: "100vh",
    transition: "0.3s",
    zIndex: "30",
  },
  themesWrapper: {
    background: "#f0eff0",
  },
  contentWrapper: {
    padding: "32px 16px",
  },
  loader: {
    color: "#888888",
    fontWeight: "bold",
    fontSize: "20px",
    padding: "20px",
    textAlign: "center",
  },
  logo: { width: "100px", height: "100px", objectFit: "contain" },
  pointer: {
    cursor: "pointer",
  },
  bold: {
    fontSize: "16px",
    margin: "0px",
    color: "#000",
    fontWeight: "600",
  },
  text: {
    fontSize: "16px",
    fontWeight: "300",
    margin: "0px",
    color: "#000",
  },
  title: {
    fontFamily: "Crete Round",
    fontSize: "19px",
    color: "#000",
    margin: "0px",
  },
};

import React, { Component } from "react";
import CreatableSelect from "react-select/creatable";
import { StateContext } from "../../context/state";
import minusIcon from "../../assets/minus.svg";
import plusIcon from "../../assets/plus.svg";
import tagIcon from "../../assets/tag.svg";
import tagInverseIcon from "../../assets/tag-inverse.svg";
// import deleteIcon from "../../assets/delete.svg";
import crossSmallIcon from "../../assets/cross-small.svg";
import axios from "axios";
import { baseUrl } from "../../Constants";
import { v4 as uuidv4 } from "uuid";
import { withTranslation} from 'react-i18next';
import addIcon from "../../assets/add.svg";


// let valid=true
class RadioOptionConfiguration extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      priceOptionSelected: false,
      tagOptions: [],
      tagOptionSelected: false,
      radioInput: {},
      selectedRadioIndex: null,
      tags: [],
      bruttoChange:undefined,
      newEntry: true,
      validationMessage: "validation msg",
      guestInfo: {
        adults: false,
        U6: false,
        U12: false,
        U18: false,
      },
      type: "",

      guestOptions: [
        { value: "adults", checked: false },
        { value: "U6", checked: false },
        { value: "U12", checked: false },
        { value: "U18", checked: false },
      ],

      initialGuestOptions: [
        { value: "adults", checked: false },
        { value: "U6", checked: false },
        { value: "U12", checked: false },
        { value: "U18", checked: false },
      ],
      display: false,
      VATPercent: [
        { id: uuidv4(), checked: false, value: "19" },
        { id: uuidv4(), checked: false, value: "7" },
      ],
    };
  }

  componentDidMount() {
    // console.log("priceType",this.context.priceType)
    axios
      .get(`${baseUrl}api/Questions/Tag/${this.context.venueId}`)
      .then((res) => {
        // console.log('res', res.data)
        if (res.data.hasOwnProperty("tags")) {
          this.setState({
            tagOptions: res.data.tags,
            tags: res.data.tags,
            newEntry: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // axios
    //   .get(
    //     `${baseUrl}api/questionnaireSetting/${this.context.parentQuestionnaireId}`
    //   )
    //   .then((res) => {
    //     if (Object.keys(res.data).length) {
    //       // console.log(res.data,"res")
    //       this.setState({ type: res?.data?.note?.name });
    //     }
    //   });
    // let guestOptions;
    // let initialGuestOptions;
    // Object.keys(this.context.guestLabel).map((guest) => {
    //   guestOptions = this.state.guestOptions.map((label) => {
    //     if (label.value == guest) {
    //       return (label.value = this.context.guestLabel[guest]);
    //     }
    //   });
    // });
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    // console.log("nextProps.option.radioInput", nextProps.option.radioInput);
    return {
      radioInput: nextProps.option.radioInput,
      selectedRadioIndex: nextProps.selectedRadioIndex,
    };
  };

  functionValidation = () => {
    const { radioInput } = this.state;
    const min = Number(
      radioInput.inputs[this.state.selectedRadioIndex].numerically.minimumNumber
    );
    const max = Number(
      radioInput.inputs[this.state.selectedRadioIndex].numerically.maximumNumber
    );
    //  console.log('min', min, max)
    if (min && max && min > max) {
      const msg = "Der Maximalwert sollte größer als der Minimalwert sein";
      // valid=false
      return msg;
    } else {
      // valid=true
      return null;
    }
  };

  functionPriceValidation = () => {
    const { radioInput } = this.state;
    const min = Number(
      radioInput.inputs[this.state.selectedRadioIndex].numerically.minimumNumber
    );
    const max = Number(
      radioInput.inputs[this.state.selectedRadioIndex].numerically.maximumNumber
    );
    const price = Number(
      radioInput.inputs[this.state.selectedRadioIndex].price
    );
    const brutto = Number(
      radioInput.inputs[this.state.selectedRadioIndex].brutto
    );
    const netto = Number(
      radioInput.inputs[this.state.selectedRadioIndex].netto
    );
    const grossNetPrice = this.context.priceType === "Netto" ? netto : brutto;
    //  console.log('min', min, max)
    // console.log("netto",netto,"brutto",brutto,"grossNetPrice",grossNetPrice)
    if (min && max && !price && price !== 0 && grossNetPrice !== 0) {
      const msg = "Preis eingeben";
      // valid=false
      return msg;
    } else if (min && !price && price !== 0 && grossNetPrice !== 0) {
      const msg = "Preis eingeben";
      // valid=false
      return msg;
    } else if (max && !price && price !== 0 && grossNetPrice !== 0) {
      const msg = "Preis eingeben";
      // valid=false
      return msg;
    } else {
      // valid=true
      return null;
    }
  };
  setPriceOptionSelected = () =>
    this.setState({ priceOptionSelected: true, tagOptionSelected: false });

  setTagOptionSelected = () =>
    this.setState({ priceOptionSelected: false, tagOptionSelected: true });

  renderPriceOption = (selectedPriceOption) => {
    // console.log("initialGuestOptions",this.state.initialGuestOptions)
    
    Object.keys(this.context.guestLabel).forEach((guest) => {
      this.state.initialGuestOptions.forEach((label) => {
        if (label.value === guest) {
          label.value = this.context.guestLabel[guest];
        }
      });
    });
    //  console.log("initialguest",this.state.initialGuestOptions)
    const array = this.state.radioInput.inputs[this.state.selectedRadioIndex]
      .guestOptions
      ? this.state.radioInput.inputs[this.state.selectedRadioIndex].guestOptions
      : this.state.initialGuestOptions;

    switch (selectedPriceOption) {
      case "flat-rate":
        return (
          <div className="price-option-btn-container">
            <div className="price-option-btn">{this.props.t('flat_rate')}</div>
            <button className="clear-option" onClick={this.resetPriceOption}>
              <img src={minusIcon} alt="" />
            </button>
            <div className="row" style={{ display: "block", margin: "auto" }}>
              <div style={{}}>
                <h6 style={{paddingLeft:"66px",textAlign:"left"}}>{this.props.t('Unit')}</h6>
                <input
                  maxLength="15"
                  className="invoice-text"
                  type="text"
                  placeholder={`${this.props.t('flat_rate')}`}
                  value={
                    this.state.radioInput.inputs[this.state.selectedRadioIndex]
                      .numerically.unit
                  } 
                  onChange={this.setFlatRate}        
                />
              </div>
            </div>
          </div>
        );
      case "number-guests":
        return (
					<div className="price-option-btn-container">
						<div className="price-option-btn">{this.props.t('Anzahl')}</div>
						<button className="clear-option" onClick={this.resetPriceOption}>
							<img src={minusIcon} alt="" />
						</button>

						<div
							style={{
								marginBottom: "20px",
								marginLeft: '75px',
								width: '250px',
								textAlign: 'left',
							}}>
							{array.map((guest) => {
								return (
									<div
										style={{
											display: 'inline-flex',
											flexDirection: 'row'
										}}>
										<div>
											<input
												onChange={(e) => {
													this.selectedHeader(e, guest.value);
												}}
												style={{
                          marginRight: '1rem',
                          height: "20px",
													// width: "100%",
													// position: "absolute",
													// right: "250px",
													// left: "90px",
													// top:"40px"
												}}
												checked={guest.checked}
												type="checkbox"
												id={guest.value}
												name={guest.value}
												value={guest.value}
											/>
										</div>
										<div>
											<label
												htmlFor={guest.value}
												>
												{this.props.t(guest.value)}
											</label>
										</div>
									</div>
								);
							})}
						</div>
						<div className="row" style={{ display: 'block', margin: 'auto' }}>
							<div style={{}}>
								<h6 style={{ paddingLeft: '66px', textAlign: 'left' }}>
									{this.props.t('Unit')}
								</h6>
								<input
									maxLength="15"
									className="invoice-text"
									type="text"
									placeholder={`${this.props.t('Per')} ${this.props.t('Gast')}`}
									value={
										this.state.radioInput.inputs[this.state.selectedRadioIndex]
											.numerically.unit
									}
									onChange={this.setGuest}
								/>
							</div>
						</div>
					</div>
				);
      case "numerically":
        return (
          <>
            <div className="price-option-btn-container">
              <div className="price-option-btn">{this.props.t('Numerically')}</div>
              <button className="clear-option" onClick={this.resetPriceOption}>
                <img src={minusIcon} alt="" />
              </button>
            </div>
            {this.functionPriceValidation() ? (
              <div className="validationMsg">
                {this.functionPriceValidation()}
              </div>
            ) : null}
            {this.functionValidation() ? (
              <div className="validationMsg">{this.functionValidation()}</div>
            ) : null}

            <div className="numerically-options">
              <div className="left-text">
              {this.props.t('Minimum_number')}
                <br />
                {this.props.t('Max_number')} 
                <br />
                {this.props.t('stages')}
                <br />
                {`${this.props.t('Season')} 1:`}
                <br />
                {`${this.props.t('Season')} 2:`}
                <br />
                {`${this.props.t('Season')} 3:`}
                <br />
                {/* {`${this.props.t('Unit')}`} */}
              </div>
              <div className="right-options">
                <div
                  style={{ display: "flex", height: "30px", marginTop: "5px" }}
                >
                  <input
                    type="number"
                    className="small-input"
                    placeholder="000"
                    style={
                      this.functionValidation() && { border: "1px solid red" }
                    }
                    value={
                      this.state.radioInput.inputs[
                        this.state.selectedRadioIndex
                      ].numerically.minimumNumber
                    }
                    onChange={this.setMinimumNumber}
                  />
                  <label className="calculate">
                    <input
                      type="checkbox"
                      checked={
                        this.state.radioInput.inputs[
                          this.state.selectedRadioIndex
                        ].numerically.minimumNumberCalculate
                      }
                      onChange={this.setMinimumNumberCalculate}
                    />
                    <span>{this.props.t('Just_calculate')}</span>
                  </label>
                </div>
                {/* <br /> */}
                {/* {
                  console.log('valid', valid)
                } */}
                <div
                  style={{ display: "flex", height: "30px", marginTop: "2px" }}
                >
                  <input
                    type="number"
                    className="small-input"
                    placeholder="000"
                    style={
                      this.functionValidation() && { border: "1px solid red" }
                    }
                    value={
                      this.state.radioInput.inputs[
                        this.state.selectedRadioIndex
                      ].numerically.maximumNumber
                    }
                    onChange={this.setMaximumNumber}
                  />
                  <label className="calculate">
                    <input
                      type="checkbox"
                      checked={
                        this.state.radioInput.inputs[
                          this.state.selectedRadioIndex
                        ].numerically.maximumNumberCalculate
                      }
                      onChange={this.setMaximumNumberCalculate}
                    />
                    <span>{this.props.t('Just_calculate')}</span>
                  </label>
                </div>
                {/* <br /> */}
                <div style={{ height: "30px", marginTop: "2px" }}>
                  <input
                    type="number"
                    className="small-input"
                    placeholder="000"
                    // min="1"
                    style={{ height: "20px" }}
                    value={
                      this.state.radioInput.inputs[
                        this.state.selectedRadioIndex
                      ].numerically.stage
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Backspace") return;
                      let key = Number(e.key);
                      if (isNaN(key) || e.key === null || e.key === " ")
                        e.preventDefault();
                    }}
                    onChange={this.setStage}
                  />
                </div>
                {/* <br /> */}
                <div
                  style={{ display: "flex", height: "30px", marginTop: "2px" }}
                >
                  <input
                    type="number"
                    className="small-input"
                    placeholder="000"
                    value={
                      this.state.radioInput.inputs[
                        this.state.selectedRadioIndex
                      ].numerically.relay1
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Backspace") return;
                      let key = Number(e.key);
                      if (isNaN(key) || e.key === null || e.key === " ")
                        e.preventDefault();
                    }}
                    onChange={this.setRelay1}
                  />
                  <div>
                    <label className="more">
                      <input
                        type="radio"
                        name="staffel1"
                        checked={
                          this.state.radioInput.inputs[
                            this.state.selectedRadioIndex
                          ].numerically.relay1Alle === false
                        }
                        onChange={this.handleRelay1}
                        value="false"
                      />
                      <span>{this.props.t('Each_further')}</span>
                    </label>
                  </div>
                  <div>
                    <label className="alle">
                      <input
                        type="radio"
                        name="staffel1"
                        checked={
                          this.state.radioInput.inputs[
                            this.state.selectedRadioIndex
                          ].numerically.relay1Alle === true
                        }
                        onChange={this.handleRelay1}
                        value="true"
                      />
                      <span>{this.props.t('all')}</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="last-input"
                      value={
                        // this.state.radioInput.inputs[
                        //   this.state.selectedRadioIndex
                        // ].numerically.relay1Price
                        this.convertedRelay1PriceValues()
                      }
                      onChange={this.setRelay1Price}
                      onKeyPress={(evt) => {
                        var charCode = evt.which ? evt.which : evt.keyCode;
    
                        let inputFormat= "";
                        inputFormat = (this.context.languageFormat==="en") ? charCode !== 46 : charCode !== 44 
                        if (inputFormat &&
                          charCode > 31 &&
                          (charCode < 48 || charCode > 57)  
                        ) {
                          //console.log("in alse")
                          // return false;
                          evt.preventDefault();
                        }
                        
                        return true;
                      }}
                    />
                  </div>
                </div>
                {/* <br /> */}
                <div
                  style={{ display: "flex", height: "30px", marginTop: "2px" }}
                >
                  <input
                    type="number"
                    className="small-input"
                    placeholder="000"
                    value={
                      this.state.radioInput.inputs[
                        this.state.selectedRadioIndex
                      ].numerically.relay2
                    }
                    onChange={this.setRelay2}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace") return;
                      let key = Number(e.key);
                      if (isNaN(key) || e.key === null || e.key === " ")
                        e.preventDefault();
                    }}
                  />
                  <div>
                    <label className="more">
                      <input
                        type="radio"
                        name="staffel2"
                        value="false"
                        checked={
                          this.state.radioInput.inputs[
                            this.state.selectedRadioIndex
                          ].numerically.relay2Alle === false
                        }
                        onChange={this.handleRelay1}
                      />
                      <span>{this.props.t('Each_further')}</span>
                    </label>
                  </div>
                  <div>
                    <label className="alle">
                      <input
                        type="radio"
                        name="staffel2"
                        value="true"
                        checked={
                          this.state.radioInput.inputs[
                            this.state.selectedRadioIndex
                          ].numerically.relay2Alle === true
                        }
                        onChange={this.handleRelay1}
                      />
                      <span>{this.props.t('all')}</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      min="0"
                      className="last-input"
                      value={
                        // this.state.radioInput.inputs[
                        //   this.state.selectedRadioIndex
                        // ].numerically.relay2Price
                        this.convertedRelay2PriceValues()
                      }
                      onChange={this.setRelay2Price}
                      onKeyPress={(evt) => {
                        var charCode = evt.which ? evt.which : evt.keyCode;
    
                        let inputFormat= "";
                        inputFormat = (this.context.languageFormat==="en") ? charCode !== 46 : charCode !== 44 
                        if (inputFormat &&
                          charCode > 31 &&
                          (charCode < 48 || charCode > 57)  
                        ) {
                          //console.log("in alse")
                          // return false;
                          evt.preventDefault();
                        }
                        
                        return true;
                      }}
                    />
                  </div>
                </div>
                {/* <br /> */}
                <div
                  style={{ display: "flex", height: "30px", marginTop: "2px" }}
                >
                  <input
                    type="number"
                    className="small-input"
                    placeholder="000"
                    // min="1"
                    value={
                      this.state.radioInput.inputs[
                        this.state.selectedRadioIndex
                      ].numerically.relay3
                    }
                    onChange={this.setRelay3}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace") return;
                      let key = Number(e.key);
                      if (isNaN(key) || e.key === null || e.key === " ")
                        e.preventDefault();
                    }}
                  />
                  <div>
                    <label className="more">
                      <input
                        type="radio"
                        name="staffel3"
                        value="false"
                        checked={
                          this.state.radioInput.inputs[
                            this.state.selectedRadioIndex
                          ].numerically.relay3Alle === false
                        }
                        onChange={this.handleRelay1}
                      />
                      <span>{this.props.t('Each_further')}</span>
                    </label>
                  </div>
                  <div>
                    <label className="alle">
                      <input
                        type="radio"
                        name="staffel3"
                        value="true"
                        checked={
                          this.state.radioInput.inputs[
                            this.state.selectedRadioIndex
                          ].numerically.relay3Alle === true
                        }
                        onChange={this.handleRelay1}
                      />
                      <span>{this.props.t('all')}</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="last-input"
                      value={
                        // this.state.radioInput.inputs[
                        //   this.state.selectedRadioIndex
                        // ].numerically.relay3Price
                        this.convertedRelay3PriceValues()
                      }
                      onChange={this.setRelay3Price}
                      onKeyPress={(evt) => {
                        var charCode = evt.which ? evt.which : evt.keyCode;
    
                        let inputFormat= "";
                        inputFormat = (this.context.languageFormat==="en") ? charCode !== 46 : charCode !== 44 
                        if (inputFormat &&
                          charCode > 31 &&
                          (charCode < 48 || charCode > 57)  
                        ) {
                          //console.log("in alse")
                          // return false;
                          evt.preventDefault();
                        }
                        
                        return true;
                      }}
                    />
                  </div>
                </div>
                <br />
                {/* <input
                  className="unit"
                  type="text"
                  placeholder={this.props.t('Add your Unit here..')}
                  value={
                    this.state.radioInput.inputs[this.state.selectedRadioIndex]
                      .numerically.unit
                  }
                  onChange={this.setUnit}
                /> */}
              </div>
            </div>
            <div className="row" style={{ display: "block", margin: "auto" }}> 
                <h6 style={{paddingLeft:"66px",textAlign:"left"}}>{this.props.t('Unit')}</h6>
                <input
                   maxLength="15"
                  className="unit1"
                  type="text"
                  placeholder={this.props.t('Add your Unit here..')}
                  value={
                    this.state.radioInput.inputs[this.state.selectedRadioIndex]
                      .numerically.unit
                  }
                  onChange={this.setUnit}
                />
             
            </div>
          </>
        );
      default:
        return null;
    }
  };

  str2bool = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  selectPriceOption = (priceType) => {
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].priceType = priceType;
    if (priceType === "flat-rate")
      radioInput.inputs[this.state.selectedRadioIndex].priceTypeId = 1;
    else if (priceType === "number-guests")
      radioInput.inputs[this.state.selectedRadioIndex].priceTypeId = 2;
    else if (priceType === "numerically")
      radioInput.inputs[this.state.selectedRadioIndex].priceTypeId = 3;
    else radioInput.inputs[this.state.selectedRadioIndex].priceTypeId = null;
    this.setState({ radioInput });
    this.props.updateRadioOptionConfiguration(this.state.radioInput);
  };

  resetPriceOption = () => {
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].priceType = "";
    this.setState({ radioInput });
    this.props.updateRadioOptionConfiguration(this.state.radioInput);
  };
  selectedHeader = (e, label) => {
    // const radioInput = this.state.radioInput;
    const radioInput = Object.assign({}, this.state.radioInput);
   
    let guestOptions;
    if (radioInput.inputs[this.state.selectedRadioIndex].guestOptions) {
      // console.log("in if", radioInput.inputs[this.state.selectedRadioIndex].guestOptions)
      guestOptions =
        radioInput.inputs[this.state.selectedRadioIndex].guestOptions;
    } else {
      // console.log("in else")

      let dummy = [
        { value: "adults", checked: false },
        { value: "U6", checked: false },
        { value: "U12", checked: false },
        { value: "U18", checked: false },
      ];
      
      Object.keys(this.context.guestLabel).forEach((guest) => {
        dummy.forEach((label) => {
          if (label.value === guest) {
            (label.value = this.context.guestLabel[guest]);
          }
        });
      });
      // console.log("dummy",dummy,"dummy2",dummy2)
      guestOptions = dummy;
    }
    // console.log(guestOptions,"guestOptions")
    guestOptions.forEach((guest) => {
      if (guest.value === label) {
        // e.target.checked === true
        guest.checked = e.target.checked;
        // ? (guest.checked = true)
        // : (guest.checked = false);
      }
    });
    let array = [];
    guestOptions.forEach((guest) => {
      if (guest.checked === true) {
        Object.keys(this.context.guestLabel).forEach((label) => {
          // console.log(this.context.guestLabel[label])
          if (this.context.guestLabel[label] === guest.value) {
            array.push(label);
          }
        });
      }
    });

    radioInput.inputs[
      this.state.selectedRadioIndex
    ].guestOptions = guestOptions;
    radioInput.inputs[this.state.selectedRadioIndex].guestInfo = array;
    this.setState({ radioInput });
    this.props.updateRadioOptionConfiguration(this.state.radioInput);
  };
  setRadioInputPrice = (e) => {
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].price = e.target.value;
    this.setState({ radioInput });
    this.props.updateRadioOptionConfiguration(this.state.radioInput);
  };
  setVAT = (e, per) => {
    const radioInput = this.state.radioInput;
    // console.log("per",per)
    radioInput.inputs[this.state.selectedRadioIndex].VATPercent.forEach((perc) => {
      if (perc.id === per.id) {
        perc.value = e.target.value;
        // console.log("perc",perc)
      }
    });

    this.setState({ radioInput });
    this.props.updateRadioOptionConfiguration(this.state.radioInput);
  };
  onNettoChange = (e) => {
    // console.log("e.key",e.key)

    const radioInput = this.state.radioInput;
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    radioInput.inputs[this.state.selectedRadioIndex].netto = textValue;
    radioInput.inputs[this.state.selectedRadioIndex].VATPercent.forEach(
      (vat) => {
        if (vat.checked === true) {
          let percent = `${vat.value}%`;
          // console.log("percent",percent)
          var result = parseFloat(percent) / 100.0;
          // var value=e.target.value *( result+1)
          var value = Number(textValue * (result + 1)).toFixed(2);
          // console.log("result",value)
          radioInput.inputs[this.state.selectedRadioIndex].brutto = value;
          if (this.context.priceType === "Netto") {
            radioInput.inputs[this.state.selectedRadioIndex].price= textValue;
          }
          else
          {
          radioInput.inputs[this.state.selectedRadioIndex].price = value;
          }
          this.setState({ radioInput });
          this.props.updateRadioOptionConfiguration(this.state.radioInput);
        } else {
          this.setState({ radioInput });
          this.props.updateRadioOptionConfiguration(this.state.radioInput);
        }
      }
    );

    // this.setState({ radioInput });
    // this.props.updateRadioOptionConfiguration(this.state.radioInput);
  };
  convertedNettoValues = () => {
    const radioInput = this.state.radioInput;
    if(this.context.languageFormat!=="en" && radioInput.inputs[this.state.selectedRadioIndex].netto)
    {
      let inputValue = radioInput.inputs[this.state.selectedRadioIndex].netto
    return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && radioInput.inputs[this.state.selectedRadioIndex].netto)
    {
      return radioInput.inputs[this.state.selectedRadioIndex].netto
    }
    else{
      return ""
    }

  }
  convertedBruttoValues = () => {
    const radioInput = this.state.radioInput;
    if(this.context.languageFormat!=="en" && radioInput.inputs[this.state.selectedRadioIndex].brutto)
    {
      let inputValue = radioInput.inputs[this.state.selectedRadioIndex].brutto
 
      return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && radioInput.inputs[this.state.selectedRadioIndex].brutto)
    {
      return radioInput.inputs[this.state.selectedRadioIndex].brutto
    }
    else{
      return ""
    }

  }
  onBruttoChange = (e) => {
    const radioInput = this.state.radioInput;
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    radioInput.inputs[this.state.selectedRadioIndex].brutto = textValue;
    radioInput.inputs[this.state.selectedRadioIndex].VATPercent.forEach(
      (vat) => {
        if (vat.checked === true) {
          let percent = `${vat.value}%`;
          // console.log("percent",percent)
          var result = parseFloat(percent) / 100.0;
          var value = Number(textValue / (result + 1)).toFixed(2);
          // console.log("result",value)

          radioInput.inputs[this.state.selectedRadioIndex].netto = value;
          if (this.context.priceType === "Brutto") {
            radioInput.inputs[this.state.selectedRadioIndex].price= textValue;
          }
          else
          {
          radioInput.inputs[this.state.selectedRadioIndex].price = value;
          }
          this.setState({ radioInput });
          this.props.updateRadioOptionConfiguration(this.state.radioInput);
        } else {
          this.setState({ radioInput });
          this.props.updateRadioOptionConfiguration(this.state.radioInput);
        }
      }
    );
  };
  onEnterHitAtNetto = (e) => {
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].VATPercent.forEach(
      (vat) => {
        if (vat.checked === true) {
          let percent = `${vat.value}%`;
          // console.log("percent",percent)
          var result = parseFloat(percent) / 100.0;
          // var value=e.target.value *( result+1)
          var value = Number(e.target.value * (result + 1)).toFixed(2);
          // console.log("result",value)
          radioInput.inputs[this.state.selectedRadioIndex].brutto = value;
          if (this.context.priceType === "Netto") {
            radioInput.inputs[this.state.selectedRadioIndex].price= e.target.value;
          }
          else
          {
          radioInput.inputs[this.state.selectedRadioIndex].price = value;
          }
          this.setState({ radioInput });
          this.props.updateRadioOptionConfiguration(this.state.radioInput);
        }
      }
    );
  };
  onEnterHitAtBrutto = (e) => {
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].VATPercent.forEach(
      (vat) => {
        if (vat.checked === true) {
          let percent = `${vat.value}%`;
          // console.log("percent",percent)
          var result = parseFloat(percent) / 100.0;
          var value = Number(e.target.value / (result + 1)).toFixed(2);
          // console.log("result",value)
          radioInput.inputs[this.state.selectedRadioIndex].netto = value;
          if (this.context.priceType === "Brutto") {
            radioInput.inputs[this.state.selectedRadioIndex].price= e.target.value;
          }
          else
          {
          radioInput.inputs[this.state.selectedRadioIndex].price = value;
          }
          this.setState({ radioInput });
          this.props.updateRadioOptionConfiguration(this.state.radioInput);
        }
      }
    );
  };
  addVATPercentage = (e) => {
    const radioInput = this.state.radioInput;
    let dummy;
    if (radioInput.inputs[this.state.selectedRadioIndex].VATPercent) {
      dummy = Object.assign(
        [],
        radioInput.inputs[this.state.selectedRadioIndex].VATPercent
      );
    } else {
      dummy = Object.assign([], this.state.VATPercent);
    }
    // console.log("VA",this.state.VATPercent)
    dummy.push({ id: uuidv4(), checked: false, value:'0' });
    // console.log("dummy",dummy)
    radioInput.inputs[this.state.selectedRadioIndex].VATPercent = dummy;
    this.setState({ radioInput });
    this.props.updateRadioOptionConfiguration(this.state.radioInput);
  };

  removeVATPercentage=(index)=>{
    const radioInput = this.state.radioInput;
    if (radioInput.inputs[this.state.selectedRadioIndex].VATPercent[index].checked) {
      if(index>1){
        radioInput.inputs[this.state.selectedRadioIndex].VATPercent.splice(index,1);
        this.setCheckedPercentage(radioInput.inputs[this.state.selectedRadioIndex].VATPercent[0]);
        this.setState({ radioInput});
      }
    }
    else {
      radioInput.inputs[this.state.selectedRadioIndex].VATPercent.splice(index,1);
      this.setState({radioInput});
    }
    }
  clearPrice = () => {
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].price = "";
    radioInput.inputs[this.state.selectedRadioIndex].netto = "";
    radioInput.inputs[this.state.selectedRadioIndex].brutto = "";
    radioInput.inputs[this.state.selectedRadioIndex].VATPercent.forEach((v) => {
      // console.log("v",v)
      v.checked = false;
    });
    this.setState({ radioInput });
    this.props.updateRadioOptionConfiguration(this.state.radioInput);
  };

  setMinimumNumber = (e) => {
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].numerically.minimumNumber =
      e.target.value;
    this.setState({ radioInput });
    this.props.updateRadioOptionConfiguration(this.state.radioInput);
  };
  setInvoiceText = (e) => {
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].description =
      e.target.value;
    this.setState({ radioInput });
    this.props.updateRadioOptionConfiguration(this.state.radioInput);
  };
  setCheckedPercentage = (per) => {
    const radioInput = this.state.radioInput;
    // console.log("per",per)
    radioInput.inputs[this.state.selectedRadioIndex].VATPercent.forEach((perc) => {
      if (perc.id === per.id) {
        perc.checked = true;

        if (perc.checked === true) {
          if (this.context.priceType === "Netto") {
            // console.log("brutto selected",this.state.bruttoChange)
if(this.state.bruttoChange===true){
  let percent = `${perc.value}%`;

              var result = parseFloat(percent) / 100.0;

              var value = Number(
                radioInput.inputs[this.state.selectedRadioIndex].brutto /
                  (result + 1)
              ).toFixed(2);
              // console.log("value",value)
              radioInput.inputs[this.state.selectedRadioIndex].netto = value;
              radioInput.inputs[this.state.selectedRadioIndex].price = value;
}
else{
            if (radioInput.inputs[this.state.selectedRadioIndex].netto) {
              // console.log(perc.value)
              let percent = `${perc.value}%`;

              result = parseFloat(percent) / 100.0;

              value = Number(
                radioInput.inputs[this.state.selectedRadioIndex].netto *
                  (result + 1)
              ).toFixed(2);
              // console.log("value",value)
              radioInput.inputs[this.state.selectedRadioIndex].brutto = value;
              radioInput.inputs[this.state.selectedRadioIndex].price = radioInput.inputs[this.state.selectedRadioIndex].netto;
            }
          }
            //  else {
            //   radioInput.inputs[this.state.selectedRadioIndex].brutto = 0;
            //   radioInput.inputs[this.state.selectedRadioIndex].price = 0;
            // }
          } else if (this.context.priceType === "Brutto") {
            if(this.state.bruttoChange===false){
              let percent = `${perc.value}%`;
            
                          result = parseFloat(percent) / 100.0;
            
                          value = Number(
                            radioInput.inputs[this.state.selectedRadioIndex].netto *
                              (result + 1)
                          ).toFixed(2);
                          // console.log("value",value)
                          radioInput.inputs[this.state.selectedRadioIndex].brutto = value;
                          radioInput.inputs[this.state.selectedRadioIndex].price = value;
            }
            // console.log("in if brutto")
          else{
            if (radioInput.inputs[this.state.selectedRadioIndex].brutto) {
              let percent = `${perc.value}%`;

              result = parseFloat(percent) / 100.0;

              value = Number(
                radioInput.inputs[this.state.selectedRadioIndex].brutto /
                  (result + 1)
              ).toFixed(2);
              radioInput.inputs[this.state.selectedRadioIndex].netto = value;
              radioInput.inputs[this.state.selectedRadioIndex].price = radioInput.inputs[this.state.selectedRadioIndex].brutto;
            } 
            // else {
            //   radioInput.inputs[this.state.selectedRadioIndex].netto = 0;
            //   radioInput.inputs[this.state.selectedRadioIndex].price = 0;
            // }
          }
        }
        } else {
          radioInput.inputs[this.state.selectedRadioIndex].price = 0;
        }
        // console.log("perc",perc)
      }
      // else{
      //   radioInput.inputs[this.state.selectedRadioIndex].price=0

      // }
      if (perc.id !== per.id) {
        perc.checked = false;
      }
    });

    this.setState({ radioInput });
    this.props.updateRadioOptionConfiguration(this.state.radioInput);
  };
  setMinimumNumberCalculate = (e) => {
    const radioInput = this.state.radioInput;
    radioInput.inputs[
      this.state.selectedRadioIndex
    ].numerically.minimumNumberCalculate = !radioInput.inputs[
      this.state.selectedRadioIndex
    ].numerically.minimumNumberCalculate;
    this.setState({ radioInput });
  };

  setMaximumNumber = (e) => {
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].numerically.maximumNumber =
      e.target.value;
    this.setState({ radioInput });
    this.props.updateRadioOptionConfiguration(this.state.radioInput);
  };

  setMaximumNumberCalculate = () => {
    const radioInput = this.state.radioInput;
    radioInput.inputs[
      this.state.selectedRadioIndex
    ].numerically.maximumNumberCalculate = !radioInput.inputs[
      this.state.selectedRadioIndex
    ].numerically.maximumNumberCalculate;
    this.setState({ radioInput });
  };

  setStage = (e) => {
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].numerically.stage =
      e.target.value;
    this.setState({ radioInput });
  };

  setRelay1 = (e) => {
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].numerically.relay1 =
      e.target.value;
    this.setState({ radioInput });
  };

  handleRelay1 = (e) => {
    // console.log('hi handleRelay', e.target.value)
    const radioInput = this.state.radioInput;
    radioInput.inputs[
      this.state.selectedRadioIndex
    ].numerically.relay1Alle = this.str2bool(e.target.value);
    radioInput.inputs[
      this.state.selectedRadioIndex
    ].numerically.relay2Alle = this.str2bool(e.target.value);
    radioInput.inputs[
      this.state.selectedRadioIndex
    ].numerically.relay3Alle = this.str2bool(e.target.value);
    this.setState({ radioInput });
  };

  setRelay1Price = (e) => {
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].numerically.relay1Price =
      textValue;
    this.setState({ radioInput });
  };
  convertedRelay1PriceValues = () => {

    if(this.context.languageFormat!=="en" && this.state.radioInput.inputs[this.state.selectedRadioIndex].numerically.relay1Price)
    {
      let inputValue = this.state.radioInput.inputs[this.state.selectedRadioIndex].numerically.relay1Price

      //inputValue.replace('.',",")
    return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && this.state.radioInput.inputs[this.state.selectedRadioIndex].numerically.relay1Price)
    {
      return this.state.radioInput.inputs[this.state.selectedRadioIndex].numerically.relay1Price
    }
    else{
      return ""
    }

  }

  setRelay2 = (e) => {
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].numerically.relay2 =
      e.target.value;
    this.setState({ radioInput });
  };

  // handleRelay2 = e => {
  //   const radioInput = this.state.radioInput;
  //   radioInput.inputs[
  //     this.state.selectedRadioIndex
  //   ].numerically.relay2Alle = this.str2bool(e.target.value);
  //   this.setState({ radioInput });
  // };

  setRelay2Price = (e) => {
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].numerically.relay2Price =
      textValue;
    this.setState({ radioInput });
  };
  convertedRelay2PriceValues = () => {

    if(this.context.languageFormat!=="en" && this.state.radioInput.inputs[this.state.selectedRadioIndex].numerically.relay2Price)
    {
      let inputValue = this.state.radioInput.inputs[this.state.selectedRadioIndex].numerically.relay2Price

      //inputValue.replace('.',",")
    return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && this.state.radioInput.inputs[this.state.selectedRadioIndex].numerically.relay2Price)
    {
      return this.state.radioInput.inputs[this.state.selectedRadioIndex].numerically.relay2Price
    }
    else{
      return ""
    }

  }

  setRelay3 = (e) => {
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].numerically.relay3 =
      e.target.value;
    this.setState({ radioInput });
  };

  // handleRelay3 = e => {
  //   const radioInput = this.state.radioInput;
  //   radioInput.inputs[
  //     this.state.selectedRadioIndex
  //   ].numerically.relay3Alle = this.str2bool(e.target.value);
  //   this.setState({ radioInput });
  // };

  setRelay3Price = (e) => {
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].numerically.relay3Price =
      textValue;
    this.setState({ radioInput });
  };
  convertedRelay3PriceValues = () => {

    if(this.context.languageFormat!=="en" && this.state.radioInput.inputs[this.state.selectedRadioIndex].numerically.relay3Price)
    {
      let inputValue = this.state.radioInput.inputs[this.state.selectedRadioIndex].numerically.relay3Price

      //inputValue.replace('.',",")
    return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && this.state.radioInput.inputs[this.state.selectedRadioIndex].numerically.relay3Price)
    {
      return this.state.radioInput.inputs[this.state.selectedRadioIndex].numerically.relay3Price
    }
    else{
      return ""
    }

  }

  setGuest=(e)=>{
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].numerically.unit =
    e.target.value;
  this.setState({ radioInput });
  }

  setUnit = (e) => {
    const radioInput = this.state.radioInput;
   radioInput.inputs[this.state.selectedRadioIndex].numerically.unit =
      e.target.value;
    this.setState({ radioInput });
  };
  setFlatRate=(e)=>{
    const radioInput = this.state.radioInput;
    console.log(radioInput);
    radioInput.inputs[this.state.selectedRadioIndex].numerically.unit =
    e.target.value;
  this.setState({ radioInput });
  }

  handleTagChange = (newValue, x) => {
    const radioInput = this.state.radioInput;
    radioInput.inputs[this.state.selectedRadioIndex].tags = newValue;
    this.setState({ radioInput });
    if (x.action === "create-option") {
      let tags = Object.assign([], this.state.tags);
      const tag = {
        value: newValue[newValue.length - 1].value,
        label: newValue[newValue.length - 1].label,
      };
      tags.push(tag);
      const tagOptions = Object.assign([], this.state.tagOptions);
      tagOptions.push(tag);
      this.setState({ tags, tagOptions });
      const postData = {
        tags,
        venueId: this.context.venueId,
      };
      if (this.state.newEntry && !this.state.tagOptions.length) {
        axios
          .post(`${baseUrl}api/Questions/Tag`, postData)
          .then((res) => {
            // console.log(res.data)
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .put(`${baseUrl}api/Questions/Tag/${this.context.venueId}`, postData)
          .then((res) => {
            // console.log(res.data)
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  handleClose = () => {
    const ans = this.functionValidation();
    if (!ans) {
      this.props.handleClose();
    }
  };
  guestDivClicked = () => {
    this.setState({
      display: !this.state.display,
      selectedRadioIndex: this.state.selectedRadioIndex,
    });
  };
  render() {
    const { radioInput } = this.state;
    const VATPercent = [
      { id: uuidv4(), checked: true, value: "19" },
      { id: uuidv4(), checked: false, value: "7" },
    ];
    radioInput.inputs[this.state.selectedRadioIndex].description = radioInput
      .inputs[this.state.selectedRadioIndex].description
      ? radioInput.inputs[this.state.selectedRadioIndex].description
      : "";
    if (!radioInput.inputs[this.state.selectedRadioIndex].VATPercent) {
      radioInput.inputs[this.state.selectedRadioIndex].VATPercent = VATPercent;
    }
    return (
			<div
				className="component-container option-component"
				style={{ top: '65px' }}>
				{/* {console.log('radio configuration', this.state.radioInput)} */}
				<button
					className={`price-selection ${
						this.state.priceOptionSelected ? 'selected' : ''
					}`}
					style={{ position: 'relative', top: '-14px' }}
					onClick={this.setPriceOptionSelected}>
					{this.context.currencyFormat}
				</button>
				<button
					className={`tag-selection ${
						this.state.tagOptionSelected ? 'selected' : ''
					}`}
					style={{ position: 'relative', top: '-14px' }}
					onClick={this.setTagOptionSelected}>
					<img
						src={this.state.tagOptionSelected ? tagInverseIcon : tagIcon}
						alt=""
					/>
				</button>

				<img
					src={crossSmallIcon}
					onClick={this.handleClose}
					alt=""
					style={{
						float: 'right',
						cursor: 'pointer',
						marginRight: '1rem',
						marginTop: '-1.5rem',
						width: '1rem',
						height: '1rem',
					}}
				/>

				{this.state.priceOptionSelected && (
					<div className="price-options">
						{this.state.radioInput.inputs[this.state.selectedRadioIndex]
							.price !== '' && (
							<button
								className="clear-price"
								style={{ marginBottom: '20px' }}
								onClick={this.clearPrice}>
								<img src={minusIcon} alt="" />
							</button>
						)}
						<div className="price-input">
							<div style={{ justifyContent: 'center', display: 'flex' }}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										width: 'min-content',
										height: 'min-content',
										marginLeft: '103px',
										color: 'rgb(149, 150, 155)',
										fontSize: '15px',
										fontWeight: '300',
									}}>
									<h6>{this.props.t('Netto')}</h6>
								</div>
								<div
									style={{
										background:
											this.context.priceType === 'Netto'
												? 'rgb(170, 172, 175)'
												: '#e6e7e8',
										// marginRight: "20px",
										marginLeft: '-40px',
										marginTop: '20px',
										borderRadius: '3px',
										content: this.context.currencyFormat,
									}}
									className="netto-brutto">
									<div style={{ display: 'flex' }}>
										<span
											style={{
												marginTop: '5px',
												fontSize: '20px',
												color: '#939598',
											}}>
											{this.context.currencyFormat}
										</span>
										<input
											autoComplete="off"
											id="euro"
											type="text"
											onChange={this.onNettoChange}
											onFocus={() => {
												this.setState({ bruttoChange: false });
											}}
											value={this.convertedNettoValues()}
											// value={
											//   this.state.radioInput.inputs[
											//     this.state.selectedRadioIndex
											//   ].netto
											//     ? this.state.radioInput.inputs[
											//         this.state.selectedRadioIndex
											//       ].netto
											//     : ""
											// }
											// placeholder="00.00"
											placeholder={
												this.context.languageFormat === 'en' ? '00.00' : '00,00'
											}
											style={
												this.functionPriceValidation()
													? {
															border: '1px solid red',
															background:
																this.context.priceType === 'Netto'
																	? 'rgb(170, 172, 175)'
																	: '#e6e7e8',
															color:
																this.context.priceType === 'Brutto'
																	? '#939598'
																	: 'black',
															fontSize: '25px',
													  }
													: {
															background:
																this.context.priceType === 'Netto'
																	? 'rgb(170, 172, 175)'
																	: '#e6e7e8',
															color:
																this.context.priceType === 'Brutto'
																	? '#939598'
																	: 'black',
															fontSize: '25px',
													  }
											}
											onKeyPress={(evt) => {
												var charCode = evt.which ? evt.which : evt.keyCode;

												let inputFormat = '';
												inputFormat =
													this.context.languageFormat === 'en'
														? charCode !== 46
														: charCode !== 44;
												if (
													inputFormat &&
													charCode > 31 &&
													(charCode < 48 || charCode > 57)
												) {
													console.log('in alse');
													// return false;
													evt.preventDefault();
												}

												return true;
											}}
											onKeyDown={(e) => {
												if (e.key === 'Enter') this.onEnterHitAtNetto(e);
											}}
											// disabled={this.context.priceType == "Brutto" && true}
										/>
									</div>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										width: 'min-content',
										height: 'min-content',
										marginLeft: '25px',
										color: '#939598',
										fontSize: '15px',
										fontWeight: '300',
									}}>
									<h6>{this.props.t('Brutto')} </h6>
								</div>
								<div
									style={{
										background:
											this.context.priceType === 'Brutto'
												? 'rgb(170, 172, 175)'
												: '#e6e7e8',
										content: this.context.currencyFormat,
										marginRight: '88px',
										marginLeft: '-40px',
										marginTop: '20px',
										borderRadius: '3px',
									}}
									className="netto-brutto">
									<div style={{ display: 'flex' }}>
										<span
											style={{
												marginTop: '5px',
												fontSize: '20px',
												color: '#939598',
											}}>
											{this.context.currencyFormat}
										</span>
										<input
											className="euro"
											type="text"
											value={this.convertedBruttoValues()}
											// value={
											//   radioInput.inputs[this.state.selectedRadioIndex].brutto
											//     ? radioInput.inputs[this.state.selectedRadioIndex]
											//         .brutto
											//     : ""
											// }
											onChange={this.onBruttoChange}
											onFocus={() => {
												this.setState({ bruttoChange: true });
											}}
											placeholder={
												this.context.languageFormat === 'en' ? '00.00' : '00,00'
											}
											style={
												this.functionPriceValidation()
													? {
															border: '1px solid red',
															background:
																this.context.priceType === 'Brutto'
																	? 'rgb(170, 172, 175)'
																	: '#e6e7e8',
															color:
																this.context.priceType === 'Netto'
																	? '#939598'
																	: 'black',
															fontSize: '25px',
													  }
													: {
															background:
																this.context.priceType === 'Brutto'
																	? 'rgb(170, 172, 175)'
																	: '#e6e7e8',
															color:
																this.context.priceType === 'Netto'
																	? '#939598'
																	: 'black',
															fontSize: '25px',
													  }
											}
											onKeyPress={(evt) => {
												var charCode = evt.which ? evt.which : evt.keyCode;
												let inputFormat = '';
												inputFormat =
													this.context.languageFormat === 'en'
														? charCode !== 46
														: charCode !== 44;
												if (
													inputFormat &&
													charCode > 31 &&
													(charCode < 48 || charCode > 57)
												) {
													//console.log("in alse")
													// return false;
													evt.preventDefault();
												}

												return true;
											}}
											onKeyDown={(e) => {
												if (e.key === 'Enter') this.onEnterHitAtBrutto(e);
											}}
											// disabled={this.context.priceType == "Netto" && true}
										/>
									</div>
								</div>
							</div>
							<br />

							<div
								className="row"
								style={{ marginLeft: '0px', marginRight: '66px' }}>
								{this.state.radioInput.inputs[
									this.state.selectedRadioIndex
								].VATPercent?.map((per, index) => {
									return (
										<div
											className="col"
											style={{ marginLeft: '56px', marginRight: '-34px' }}>
											<label style={{ display: 'flex' }} className="calculate">
												<div
													style={per.checked ? styles.selected : styles.circle}
													onClick={() => this.setCheckedPercentage(per)}
												/>
												<div style={{ display: 'flex' }}>
													<input
														style={{
															background: '#d0d2d4',
															width: '36px',
															color: '#939598',
                              paddingLeft: '10px',
                              
														}}
														type="number"
														value={per.value}
														onChange={(e) => {
															this.setVAT(e, per);
														}}
														placeholder={
															this.context.languageFormat === 'en'
																? '00.00'
																: '00,00'
														}
														onKeyPress={(evt) => {
															var charCode = evt.which
																? evt.which
																: evt.keyCode;
															// console.log("charcode",charCode)
															if (
																charCode !== 46 &&
																charCode > 31 &&
																(charCode < 48 || charCode > 57)
															) {
																// console.log("in alse")
																// return false;
																evt.preventDefault();
															}

															return true;
														}}
													/>
													<span style={{ fontSize: '20px', color: '#939598' }}>
														%
													</span>
													{index > 1 && (
														<img
															src={crossSmallIcon}
															onClick={() => this.removeVATPercentage(index)}
															alt=""
															style={{
																float: 'right',
																width: '10px',
																height: '10px',
															}}
														/>
													)}
												</div>
											</label>
										</div>
									);
								})}
								<div className="col">
									<img src={addIcon} alt="" onClick={this.addVATPercentage} />
								</div>
							</div>
						</div>
						<div className="row" style={{ display: 'block', margin: 'auto' }}>
							<div style={{}}>
								<h6 style={{ color: '#939598' }}>
									{this.props.t('Invoice Text')}
								</h6>
								<input
									maxLength="50"
									className="invoice-text"
									type="text"
									placeholder={this.props.t('Invoice Text')}
									value={
										radioInput.inputs[this.state.selectedRadioIndex].description
									}
									onChange={this.setInvoiceText}
								/>
							</div>
						</div>
						<div className="plus-icon">
							<img src={plusIcon} alt="" />
						</div>
						{this.renderPriceOption(
							this.state.radioInput.inputs[this.state.selectedRadioIndex]
								.priceType
						)}
						{this.state.radioInput.inputs[this.state.selectedRadioIndex]
							.priceType === '' && (
							<>
								<button
									className="price-option-btn"
									onClick={() => this.selectPriceOption('flat-rate')}>
									{this.props.t('flat_rate')}
								</button>
								<button
									className="price-option-btn"
									onClick={() => this.selectPriceOption('number-guests')}>
									{this.props.t('Anzahl')}
								</button>
								<button
									className="price-option-btn"
									onClick={() => this.selectPriceOption('numerically')}>
									{this.props.t('Numerically')}
								</button>
							</>
						)}
					</div>
				)}
				{this.state.tagOptionSelected && (
					<div className="tag-options">
						<div className="select-container">
							<CreatableSelect
								isMulti
								className="tag-select"
								classNamePrefix="tag"
								// isClearable
								value={
									this.state.radioInput.inputs[this.state.selectedRadioIndex]
										.tags
								}
								onChange={this.handleTagChange}
								options={this.state.tagOptions}
								components={{
									DropdownIndicator: () => null,
									IndicatorSeperator: () => null,
								}}
								// value={{ label: tag }}
							/>
						</div>
					</div>
				)}
			</div>
		);
  }
}

export default withTranslation() (RadioOptionConfiguration);
const styles = {
  selected: {
    background: `url(${crossSmallIcon}) ,rgb(230, 231, 232) none repeat scroll 0% 0%`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    // width: "24px",
    // height: "24px",
    minWidth: "20px",
    minHeight: "20px",
    borderRadius: "4px",
    height: "20px",
    display: "inline-block",
    textAlign: "center",
    // background: "transparent",
    // borderRadius: "12px",
    // border: "1px solid black",
    outline: "none",
    cursor: "pointer",
  },
  circle: {
    minWidth: "20px",
    minHeight: "20px",
    height: "20px",
    display: "inline-block",
    textAlign: "center",
    background: "rgb(230, 231, 232) none repeat scroll 0% 0%",
    // borderRadius: "12px",
    // border: "1px solid black",
    outline: "none",
    cursor: "pointer",
  },
};

import React, { Component } from "react";
import { EditorState } from "draft-js";
import arrowSmall from "../../assets/arrow-small.svg";
import arrowSmallSelected from "../../assets/arrow-small-selected.svg";
import { withTranslation} from 'react-i18next';

class OptionsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedInput: null,
      selectedOption: {
        textInput: {
          inputs: [
            {
              id: 1,
              interactive: false,
              numeric: false,
              stages: "",
              maximum: "",
              minimum: "",
              value: EditorState.createEmpty(),
              interactiveText: "",
              hideTextToolbar: true,
              price: "",
              priceType: "",
              priceTypeId: null,
              tags: [],
              numerically: {
                minimumNumber: "",
                minimumNumberCalculate: false,
                maximumNumber: "",
                maximumNumberCalculate: false,
                stage: "",
                relay1: "",
                relay1Alle: false,
                relay1Price: "",
                relay2: "",
                relay2Alle: false,
                relay2Price: "",
                relay3: "",
                relay3Alle: false,
                relay3Price: "",
                unit: "",
              },
            },
          ],
          boxColor: "",
        },
      },
      radioChoice: "radio",
      multipleSelect: false,
      hasDatum: true,
      hasTime: true,
      isTextInteractive: "false",
      isTextNumeric: "false",
      textStage: "",
      textMaximum: "",
      textMinimum: "",
      imageAlignment: "",
      uploadSelect: "upload",
      tableOptionTypeVisible: false,
      uploadMinimum: "",
      uploadMaximum: "",
      selectedTextIndex2: 0,
      tableRows: "5",
      tableColumns: "6",
      isTableInteractive: true,
      tableOptionType: "text",
      min: "",
      max: "",
    };
  }
  componentDidMount() {
    // console.log('props', this.props.selectedOption)
    if (this.props.selectedOption === "text") {
      const selectedOption = {
        textInput: {
          inputs: [
            {
              id: 1,
              interactive: false,
              numeric: false,
              stages: "",
              maximum: "",
              minimum: "",
              value: EditorState.createEmpty(),
              interactiveText: "",
              hideTextToolbar: true,
              price: "",
              priceType: "",
              priceTypeId: null,
              tags: [],
              numerically: {
                minimumNumber: "",
                minimumNumberCalculate: false,
                maximumNumber: "",
                maximumNumberCalculate: false,
                stage: "",
                relay1: "",
                relay1Alle: false,
                relay1Price: "",
                relay2: "",
                relay2Alle: false,
                relay2Price: "",
                relay3: "",
                relay3Alle: false,
                relay3Price: "",
                unit: "",
              },
            },
          ],
          boxColor: "",
          optionContainerClicked:false
        },
      };
      this.setState({
        selectedOption,
      });
    }
  }
  str2bool = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  handleHasDatum = (e) => {
    // console.log('hasDatum', e.target.value)
    this.setState({ hasDatum: this.str2bool(e.target.value) });
    this.props.updateDatumOptionConfiguration({
      hasDatum: this.str2bool(e.target.value),
      hasTime: this.state.hasTime,
    });
  };

  handleHasTime = (e) => {
    // console.log('hasTime', e.target.value)
    this.setState({ hasTime: this.str2bool(e.target.value) });
    this.props.updateDatumOptionConfiguration({
      hasDatum: this.state.hasDatum,
      hasTime: this.str2bool(e.target.value),
    });
  };

  handleIsTextInteractive = (e) => {
    // console.log('interactive', e.target.value)
    const v = e.target.value;
    const selectedOption = this.state.selectedOption;
    selectedOption.textInput.inputs[
      this.state.selectedTextIndex2
    ].interactive = this.str2bool(e.target.value);
    if (v === "true") {
      selectedOption.textInput.inputs[
        this.state.selectedTextIndex2
      ].value = EditorState.createEmpty();
    }
    if (v === "false") {
      // console.log("in this")
      selectedOption.textInput.inputs[
        this.state.selectedTextIndex2
      ].price=""
      selectedOption.textInput.inputs[
        this.state.selectedTextIndex2
      ].numeric = false;
    }
    this.setState({ selectedOption, isTextInteractive: e.target.value });
    // console.log('optn1',selectedOption )
    this.props.updateTextOptionParams(this.state.selectedOption);
    // console.log('optn2',this.state.selectedOption )
  };

  handleIsTextNumeric = (e) => {
    let selectedOption = this.state.selectedOption;
    // console.log("selectedOption", selectedOption.textInput.inputs[
    //   this.state.selectedTextIndex2
    // ])
    // console.log(this.state.isTextInteractive,"this.state.isTextInteractive")
    if (this.state.isTextInteractive === "false" && e.target.value === "true" && selectedOption.textInput.inputs[
      this.state.selectedTextIndex2
    ].interactive!==true) {
  
      // const selectedOption = this.state.selectedOption;
      selectedOption.textInput.inputs[
        this.state.selectedTextIndex2
      ].numeric = false;
      this.setState({ selectedOption, isTextNumeric: "false" });
      this.props.updateTextOptionParams(this.state.selectedOption);
    } else {
     
      // const selectedOption = this.state.selectedOption;
      selectedOption.textInput.inputs[
        this.state.selectedTextIndex2
      ].numeric = this.str2bool(e.target.value);
      this.setState({ selectedOption, isTextNumeric: e.target.value });
      this.props.updateTextOptionParams(this.state.selectedOption);
    }
  };

  setTextStage = (e) => {
    const selectedOption = this.state.selectedOption;
    selectedOption.textInput.inputs[this.state.selectedTextIndex2].stages =
      e.target.value;
    this.setState({ selectedOption });
    this.props.updateTextOptionParams(this.state.selectedOption);
  };

  setTextMaximum = (e) => {
    const selectedOption = this.state.selectedOption;
    selectedOption.textInput.inputs[this.state.selectedTextIndex2].maximum =
      e.target.value;
    this.setState({ selectedOption });
    this.props.updateTextOptionParams(this.state.selectedOption);
  };

  setTextMinimum = (e) => {
    const selectedOption = this.state.selectedOption;
    selectedOption.textInput.inputs[this.state.selectedTextIndex2].minimum =
      e.target.value;
    this.setState({ selectedOption });
    this.props.updateTextOptionParams(this.state.selectedOption);
  };

  setComponentSelected = (selectedInput) => {
    this.props.populateOption(selectedInput);
    this.setState({
      selectedInput,
      tableColumns: "6",
      tableRows: "5",
      isTableInteractive: true,
      tableOptionTypeVisible: false,
    });
    if (selectedInput === "upload") {
      this.props.updateUploadInputMaxMin({ minimum: "", maximum: "" });
    }
    (selectedInput === "radio" || selectedInput === "image") &&
      this.setState({
        radioChoice: selectedInput,
        imageAlignment: "horizontal",
        multipleSelect: false,
        min: this.state.min,
        max: this.state.max,
      });
      this.props.clickOption(true)
  };

  handleRadioChoice = (e) => {
    // console.log('choice', e.target.value)
    this.setComponentSelected(e.target.value);
    this.setState({
      radioChoice: e.target.value,
    });
  };
  handleSelectMin = (e) => {
    // const selectedOption = this.state.selectedOption;
    // selectedOption.textInput.inputs[this.state.selectedTextIndex2].minimum =
    //   e.target.value;
    // console.log("radiochoice",this.state.radioChoice)
    this.setState({
      min: e.target.value,
    });
    this.props.setMinMaxValue({
      min: e.target.value,
      max: this.state.max,
    });
    // console.log("selectedoption",selectedOption)
    // this.setState({ selectedOption });
    // this.props.updateTextOptionParams(this.state.selectedOption);
  };
  handleSelectMax = (e) => {
    // const selectedOption = this.state.selectedOption;
    // selectedOption.textInput.inputs[this.state.selectedTextIndex2].maximum =
    //   e.target.value;
    this.setState(
      {
        max: e.target.value,
      },
      () => {}
    );
    this.props.setMinMaxValue({
      min: this.state.min,
      max: e.target.value,
    });
    // console.log("selectedoption",selectedOption)
    // this.setState({ selectedOption });
    // this.props.updateTextOptionParams(this.state.selectedOption);
  };
  handleRadioType = (e) => {
    if (this.state.radioChoice === "radio") {
      this.props.toggleRadioOptionType(e.target.value);
    } else if (this.state.radioChoice === "image") {
      this.props.toggleImageOptionType(e.target.value);
    }

    this.setState({
      multipleSelect: e.target.value,
    });
  };
  handleImageAlignment = (e) => {
    this.props.toggleImageOptionAlignment(e.target.value);
    // console.log('e.target.value', e.target.value)
    this.setState({
      imageAlignment: e.target.value,
    });
  };

  handleUploadSelect = (e) => {
    this.props.toggleUploadDownload(e.target.value);
    this.setState({
      uploadSelect: e.target.value,
    });
  };

  handleTableRows = (e) => {
    const selectedOption = this.state.selectedOption;
    const rows = parseInt(e.target.value);
    const oldLength = selectedOption.tableInput.rows.length;
    if (selectedOption.tableInput.rows.length < rows) {
      for (let i = 0; i < rows - oldLength; i++) {
        const lastRow = { columns: [] };
        for (
          let y = 1;
          y <= selectedOption.tableInput.rows[0].columns.length;
          y++
        ) {
          lastRow.columns.push({ value: "", interactive: true, type: "text" });
        }
        selectedOption.tableInput.rows.push(lastRow);
      }
      for (let i = 0; i < rows - oldLength; i++) {
        selectedOption.tableInput.checkboxes.rows.push(0);
      }
    } else {
      for (let i = 0; i < oldLength - rows; i++) {
        selectedOption.tableInput.rows.pop();
      }
      for (let i = 0; i < oldLength - rows; i++) {
        selectedOption.tableInput.checkboxes.rows.pop();
      }
    }
    // console.log("e", e.target.value, selectedOption.tableInput);
    this.props.updateTableInput(selectedOption.tableInput);
    this.setState({ selectedOption, tableRows: e.target.value });
  };

  handleTableColumns = (e) => {
    const selectedOption = this.state.selectedOption;
    const columns = parseInt(e.target.value);
    const oldLength = selectedOption.tableInput.rows[0].columns.length;
    if (oldLength < columns) {
      selectedOption.tableInput.rows.forEach((item) => {
        for (let y = 0; y < columns - oldLength; y++) {
          item.columns.push({ value: "", interactive: true, type: "text" });
        }
      });
      for (let i = 0; i < columns - oldLength; i++) {
        selectedOption.tableInput.checkboxes.columns.push(0);
      }
    } else {
      selectedOption.tableInput.rows.forEach((item) => {
        for (let y = 0; y < oldLength - columns; y++) {
          item.columns.pop();
        }
      });
      for (let i = 0; i < oldLength - columns; i++) {
        selectedOption.tableInput.checkboxes.columns.pop();
      }
    }
    this.props.updateTableInput(selectedOption.tableInput);
    this.setState({ selectedOption, tableColumns: e.target.value });
  };

  handleTableOptionType = (value) => {
    // const selectedOption = this.state.selectedOption;
    // selectedOption.tableInput.rows.forEach(item => {
    //   item.columns.forEach(_item => {
    //     _item.selected && (_item.type = e.target.value);
    //   });
    // });
    // this.props.updateTableInput(selectedOption.tableInput, {type:e.target.value});
    // this.setState({ selectedOption, tableOptionType:e.target.value });
    // console.log('hi', e.target.value)
    const selectedOption = this.state.selectedOption;
    selectedOption.tableInput.rows.forEach((item) => {
      item.columns.forEach((_item) => {
        if (_item.selected) {
          // _item.selected &&
          _item.type = value;
          _item.interactive = this.str2bool(this.state.isTableInteractive);
        }
      });
    });
    this.props.updateTableInput(selectedOption.tableInput, { type: value });
    this.setState({ selectedOption, tableOptionType: value });
    document.getElementById("show").checked = false;
  };

  handleTableInteractive = (e) => {
    // console.log('hey', this.state.tableOptionType, e.target.value)
    const selectedOption = this.state.selectedOption;
    selectedOption.tableInput.rows.forEach((item) => {
      item.columns.forEach((_item) => {
        if (_item.selected) {
          _item.interactive = this.str2bool(e.target.value);
          _item.type = this.state.tableOptionType;
          _item.value = "";
        }
        // _item.selected && (_item.interactive = this.str2bool(e.target.value));
      });
    });
    this.props.updateTableInput(selectedOption.tableInput, {
      isTableInteractive: this.str2bool(e.target.value),
    });
    this.setState({
      selectedOption,
      isTableInteractive: this.str2bool(e.target.value),
    });
  };

  handleUploadMinimum = (e) => {
    // console.log('e', e.target.value)
    // this.setState({ uploadMinimum: e.target.value }, () =>
    //   this.props.updateUploadInputMaxMin({
    //     maximum: this.state.uploadMaximum,
    //     minimum: this.state.uploadMinimum
    //   })
    // );
    this.setState({
      uploadMinimum: e.target.value,
    });
    this.props.updateUploadInputMaxMin({
      maximum: this.state.uploadMaximum,
      minimum: e.target.value,
    });
  };

  handleUploadMaximum = (e) => {
    // console.log('e', e.target.value)
    // this.setState({ uploadMaximum: e.target.value }, () =>
    //   this.props.updateUploadInputMaxMin({
    //     maximum: this.state.uploadMaximum,
    //     minimum: this.state.uploadMinimum
    //   })
    // );
    this.setState({
      uploadMaximum: e.target.value,
    });
    this.props.updateUploadInputMaxMin({
      maximum: e.target.value,
      minimum: this.state.uploadMinimum,
    });
  };

  static getDerivedStateFromProps = (nextProps, prevState) => {
    // console.log("nextProps optn component", nextProps.radioInfo.radioChoice,"prevstate",prevState);
    // console.log("nextprops",nextProps.radioInfo,"prevstate",prevState)
    if (nextProps.selectedOption !== null) {
      return {
        selectedInput: nextProps.type,
        selectedOption: nextProps.selectedOption,
        // tableOptionTypeVisible: nextProps.tableOptionTypeVisible,
        selectedTextIndex2: nextProps.selectedTextIndex2,
        hasDatum: nextProps.dateInfo
          ? nextProps.dateInfo.hasDatum
          : prevState.hasDatum,
        hasTime: nextProps.dateInfo
          ? nextProps.dateInfo.hasTime
          : prevState.hasTime,
        uploadSelect: nextProps.uploadInfo
          ? nextProps.uploadInfo.uploadSelect
          : prevState.uploadSelect,
        uploadMaximum: nextProps.uploadInfo
          ? nextProps.uploadInfo.uploadMaximum
          : prevState.uploadMaximum,
        uploadMinimum: nextProps.uploadInfo
          ? nextProps.uploadInfo.uploadMinimum
          : prevState.uploadMinimum,

        min: nextProps.radioInfo ? nextProps.radioInfo.min : prevState.min,
        max: nextProps.radioInfo ? nextProps.radioInfo.max : prevState.max,

        tableRows: nextProps.tableInfo
          ? nextProps.tableInfo.rows
          : prevState.tableRows,
        tableColumns: nextProps.tableInfo
          ? nextProps.tableInfo.columns
          : prevState.tableColumns,
        isTableInteractive: nextProps.tableInfo
          ? nextProps.tableInfo.isTableInteractive
          : prevState.isTableInteractive,
        tableOptionType: nextProps.tableInfo
          ? nextProps.tableInfo.type
          : prevState.tableOptionType,
        tableOptionTypeVisible: nextProps.tableInfo
          ? nextProps.tableInfo.tableOptionTypeVisible
          : prevState.tableOptionTypeVisible,
        radioChoice: nextProps.radioInfo.radioChoice,
        imageAlignment: nextProps.radioInfo.imageAlignment,
        multipleSelect: nextProps.radioInfo.multipleSelection,
      };
    } else {
      var selectedOption = {
        textInput: {
          inputs: [
            {
              id: 1,
              interactive: false,
              numeric: false,
              stages: "",
              maximum: "",
              minimum: "",
              value: EditorState.createEmpty(),
              interactiveText: "",
              hideTextToolbar: true,
              price: "",
              priceType: "",
              priceTypeId: null,
              tags: [],
              numerically: {
                minimumNumber: "",
                minimumNumberCalculate: false,
                maximumNumber: "",
                maximumNumberCalculate: false,
                stage: "",
                relay1: "",
                relay1Alle: false,
                relay1Price: "",
                relay2: "",
                relay2Alle: false,
                relay2Price: "",
                relay3: "",
                relay3Alle: false,
                relay3Price: "",
                unit: "",
              },
            },
          ],
          boxColor: "",
        },
      };
      return {
        selectedInput: nextProps.type,
        selectedOption,
        // tableOptionTypeVisible: nextProps.tableOptionTypeVisible,
        selectedTextIndex2: nextProps.selectedTextIndex2,
        hasDatum: nextProps.dateInfo
          ? nextProps.dateInfo.hasDatum
          : prevState.hasDatum,
        hasTime: nextProps.dateInfo
          ? nextProps.dateInfo.hasTime
          : prevState.hasTime,
        uploadSelect: nextProps.uploadInfo
          ? nextProps.uploadInfo.uploadSelect
          : prevState.uploadSelect,
        uploadMaximum: nextProps.uploadInfo
          ? nextProps.uploadInfo.uploadMaximum
          : prevState.uploadMaximum,
        uploadMinimum: nextProps.uploadInfo
          ? nextProps.uploadInfo.uploadMinimum
          : prevState.uploadMinimum,
        min: nextProps.radioInfo ? nextProps.radioInfo.min : prevState.min,
        max: nextProps.radioInfo ? nextProps.radioInfo.max : prevState.max,
        tableRows: nextProps.tableInfo
          ? nextProps.tableInfo.rows
          : prevState.tableRows,
        tableColumns: nextProps.tableInfo
          ? nextProps.tableInfo.columns
          : prevState.tableColumns,
        tableOptionType: nextProps.tableInfo
          ? nextProps.tableInfo.type
          : prevState.tableOptionType,
        isTableInteractive: nextProps.tableInfo
          ? nextProps.tableInfo.isTableInteractive
          : prevState.isTableInteractive,
        tableOptionTypeVisible: nextProps.tableInfo
          ? nextProps.tableInfo.tableOptionTypeVisible
          : prevState.tableOptionTypeVisible,
        radioChoice: nextProps.radioInfo.radioChoice,
        imageAlignment: nextProps.radioInfo.imageAlignment,
        multipleSelect: nextProps.radioInfo.multipleSelection,
        // min: nextProps.radioInfo.min,
        // max: nextProps.radioInfo.max,
      };
    }
  };
  handleClick=()=>{
    this.setState({optionContainerClicked:true})
    this.props.clickOption(true)
  }
  onBlurClick=()=>{
    this.props.clickOption(false)
  }
  render() {
    return (
      <div
      tabIndex="0"
      // onBlur={this.onBlurClick}
        className="component-container"
        style={{ position: "sticky", top: "62px" }}
        // onClick={this.handleClick}
      >
        {/* { console.log('selected', this.state.selectedOption)} */}
        {/* {console.log('index in options comp', this.state.selectedTextIndex2)} */}
        <button
          className={`component-selector ${
            this.state.selectedInput === "text" ? "selected" : ""
          }`}
          onBlur={this.onBlurClick}
          onClick={() => this.setComponentSelected("text")}
        >
          {this.props.t('Text')}
          <div className="right-dot">
            <span></span>
          </div>
          <img
            src={
              this.state.selectedInput === "text"
                ? arrowSmallSelected
                : arrowSmall
            }
            alt=""
          />
        </button>
        {
          // this.state.selectedInput === "text"
          this.state.selectedOption?.textInput &&
            this.state.selectedInput === "text" && (
              <div className="component-text-expand">
                <div className="row">
                  <div className="col-6">
                    {this.state.selectedOption.textInput && (
                      <>
                        {" "}
                        <h6>{this.props.t('Interaktiv')}</h6>
                        {/* {console.log("interactives",this.state.selectedTextIndex2, this.state.selectedOption )} */}
                        <select
                          onChange={this.handleIsTextInteractive}
                          value={
                            this.state.selectedOption.textInput.inputs[
                              this.state.selectedTextIndex2
                            ].interactive
                          }
                        >
                          <option value="true">{this.props.t('Ja')}</option>
                          <option value="false">{this.props.t('Nein')}</option>
                        </select>
                      </>
                    )}
                  </div>
                  <div className="col-6">
                    <h6>{this.props.t('Numerically')}</h6>
                    <select
                      onChange={this.handleIsTextNumeric}
                      value={
                        this.state.selectedOption.textInput.inputs[
                          this.state.selectedTextIndex2
                        ].numeric
                      }
                    >
                      <option value="true">{this.props.t('Ja')}</option>
                      <option value="false">{this.props.t('Nein')}</option>
                    </select>
                  </div>
                </div>
                {this.state.selectedOption.textInput.inputs[
                  this.state.selectedTextIndex2
                ].numeric && (
                  <div className="row">
                    <div className="col-4">
                      <h6>{this.props.t('stages')}</h6>
                      <input
                        type="number"
                        value={
                          this.state.selectedOption.textInput.inputs[
                            this.state.selectedTextIndex2
                          ].stages
                        }
                        onChange={this.setTextStage}
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-4">
                      <h6>{this.props.t('Mindestens')}</h6>
                      <input
                        type="number"
                        value={
                          this.state.selectedOption.textInput.inputs[
                            this.state.selectedTextIndex2
                          ].minimum
                        }
                        onChange={this.setTextMinimum}
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-4">
                      <h6>{this.props.t('Maxsimal')}</h6>
                      <input
                        type="number"
                        value={
                          this.state.selectedOption.textInput.inputs[
                            this.state.selectedTextIndex2
                          ].maximum
                        }
                        onChange={this.setTextMaximum}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                )}
              </div>
            )
        }
        <button
          className={`component-selector ${
            this.state.selectedInput === "datum" ? "selected" : ""
          }`}
          onClick={() => this.setComponentSelected("datum")}
        >
      {this.props.t('Datum')}    
          <div className="right-dot">
            <span></span>
          </div>
          <img
            src={
              this.state.selectedInput === "datum"
                ? arrowSmallSelected
                : arrowSmall
            }
            alt=""
          />
        </button>
        {this.state.selectedInput === "datum" && (
          <div className="component-datum-expand">
            <div>
              <h6>{this.props.t('Datum')}</h6>
              <select
                onChange={this.handleHasDatum}
                value={this.state.hasDatum}
              >
                <option value="true">{this.props.t('Ja')}</option>
                <option value="false">{this.props.t('Nein')}</option>
              </select>
            </div>
            <div>
              <h6>{this.props.t('Uhrzeit')}</h6>
              <select onChange={this.handleHasTime} value={this.state.hasTime}>
                <option value="true">{this.props.t('Ja')}</option>
                <option value="false">{this.props.t('Nein')}</option>
              </select>
            </div>
          </div>
        )}
        <button
          className={`component-selector ${
            this.state.selectedInput === "bild" ? "selected" : ""
          }`}
          onClick={() => this.setComponentSelected("bild")}
        >
          {this.props.t('Bild')}
          <div className="right-dot">
            <span></span>
          </div>
          <img
            src={
              this.state.selectedInput === "bild"
                ? arrowSmallSelected
                : arrowSmall
            }
            alt=""
          />
        </button>
        {/* Radio Button */}
        <button
          className={`component-selector ${
            this.state.selectedInput === "radio" ||
            this.state.selectedInput === "image"
              ? "selected"
              : ""
          }`}
          onClick={() => this.setComponentSelected("radio")}
        >
           {this.props.t('Radiobutton')} 
          <div className="right-dot">
            <span></span>
          </div>
          <img
            src={
              this.state.selectedInput === "radio" ||
              this.state.selectedInput === "image"
                ? arrowSmallSelected
                : arrowSmall
            }
            alt=""
          />
        </button>
        {(this.state.selectedInput === "radio" ||
          this.state.selectedInput === "image") && (
          <div className="component-radio-expand">
            <div className="row">
              <div className="col-6">
                {
                  // console.log('type', this.state.radioChoice)
                }
                <h6>{this.props.t('Auswahlmöglichkeit')}</h6>

                <select
                  onChange={this.handleRadioChoice}
                  value={this.state.selectedInput}
                >
                  <option value="radio">{this.props.t('Text')}</option>
                  <option value="image">{this.props.t('Bild')}</option>
                </select>
              </div>
              <div className="col-6">
                {
                  // console.log('type', this.state.radioChoice)
                }
                <h6>{this.props.t('Mehrouswahl')}</h6>
                <select
                  onChange={this.handleRadioType}
                  value={`${this.state.multipleSelect}`}
                >
                  <option value="false">{this.props.t('Nein')}</option>
                  <option value="true">{this.props.t('Ja')}</option>
                </select>
              </div>
              {this.state.selectedInput === "image" && (
                <div className="col-6">
                  <h6>{this.props.t('Ausrichtung')}</h6>
                  <select
                    onChange={this.handleImageAlignment}
                    value={this.state.imageAlignment}
                  >
                    <option value="horizontal">{this.props.t('Horizontal')}</option>
                    <option value="vertical">{this.props.t('Vertikal')}</option>
                  </select>
                </div>
              )}
            </div>

            {this.state.multipleSelect === "true" && (
              <>
                {/* {console.log("max", this.state.max, "min", this.state.min)} */}
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <span style={{ color: "#939598" }}>
                  {this.props.t('Mindest')}
                    <input
                      className="input-min-max"
                      value={this.state.min}
                      onChange={this.handleSelectMin}
                      style={{ width: "60px" }}
                      autoComplete="off"
                    />
                  </span>
                  <span style={{ color: "#939598" }}>
                    {this.props.t('Maximal')}
                    <input
                      className="input-min-max"
                      value={this.state.max}
                      onChange={this.handleSelectMax}
                      style={{ width: "60px" }}
                      autoComplete="off"
                    />
                  </span>
                </div>
              </>
            )}
          </div>
        )}
        <button
          className={`component-selector ${
            this.state.selectedInput === "table" ? "selected" : ""
          }`}
          onClick={() => this.setComponentSelected("table")}
        >
          {this.props.t('Tabelle')}
          <div className="right-dot">
            <span></span>
          </div>
          <img
            src={
              this.state.selectedInput === "table"
                ? arrowSmallSelected
                : arrowSmall
            }
            alt=""
          />
        </button>
        {this.state.selectedInput === "table" && (
          <div className="component-table-expand">
            <div className="row">
              <div className="col-3">
                <h6>{this.props.t('Reihen')}</h6>
                <select
                  onChange={this.handleTableRows}
                  value={this.state.tableRows}
                  // defaultValue={this.state.tableRows}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  {/* <option value="7">7</option>
                  <option value="8">8</option> */}
                </select>
              </div>
              <div className="col-3">
                <h6>{this.props.t('Spalten')}</h6>
                <select
                  onChange={this.handleTableColumns}
                  value={this.state.tableColumns}
                  // defaultValue={this.state.tableColumns}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  {/* <option value="7">7</option>
                  <option value="8">8</option> */}
                </select>
              </div>
              <div className="col-6">
                {/* {console.log('int', this.state.isTableInteractive)} */}
                {this.state.tableOptionTypeVisible && (
                  <>
                    <h6>{this.props.t('Interaktiv')}</h6>
                    <select
                      onChange={this.handleTableInteractive}
                      value={this.state.isTableInteractive}
                    >
                      <option value="true">{this.props.t('Ja')}</option>
                      <option value="false">{this.props.t('Nein')}</option>
                    </select>
                  </>
                )}
              </div>
            </div>
            {this.state.tableOptionTypeVisible &&
              this.state.isTableInteractive && (
                <>
                  <h6>{this.props.t('Auswahlmöglichkeit')}</h6>
                  {/* <select
                  onChange={this.handleTableOptionType}
                  value={this.state.tableOptionType}
                  // onFocus={()=>this.selectedIndex = -1}
                >
                  <option value="text">Text</option>
                  <option value="numeric">Numeric</option>
                  <option value="date">Date</option>
                </select> */}
                  <div
                    className="navi"
                    style={{
                      width: "100%",
                      margin: "0px 0px",
                      padding: "5px 5px 5px 0px",
                    }}
                  >
                    <input type="checkbox" id="show" autoComplete="off" />
                    <label
                      className="checkboxLable"
                      name="showHide"
                      htmlFor="show"
                      id="navi-icon2"
                    >
                      {this.state.tableOptionType === "numeric"
                        ? "Nummer"
                        : this.state.tableOptionType === "date"
                        ? "Datum"
                        : this.state.tableOptionType === "time"
                        ? "Zeit"
                        : this.state.tableOptionType === "text"
                        ? "Text"
                        : this.state.tableOptionType}
                    </label>
                    <div
                      className="multi-level"
                      style={{
                        position: "absolute",
                        width: "50%",
                        backgroundColor: "#e6e7e8",
                        zIndex: "1",
                      }}
                    >
                      <ul style={{ paddingLeft: "15px" }}>
                        <li
                          style={{ fontSize: "14px", textAlign: "left" }}
                          onClick={() => {
                            this.handleTableOptionType("text");
                          }}
                        >
                          {" "}
                          {this.props.t('Text')}
                        </li>
                        <li
                          style={{ fontSize: "14px", textAlign: "left" }}
                          onClick={() => {
                            this.handleTableOptionType("numeric");
                          }}
                        >
                        {this.props.t('Nummer')} 
                        </li>
                        <li
                          style={{ fontSize: "14px", textAlign: "left" }}
                          onClick={() => {
                            this.handleTableOptionType("date");
                          }}
                        >
                          {this.props.t('Datum')}
                        </li>
                        <li
                          style={{ fontSize: "14px", textAlign: "left" }}
                          onClick={() => {
                            this.handleTableOptionType("time");
                          }}
                        >
                          {this.props.t('Zeit')}   
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
          </div>
        )}
        <br />
        <button
          className={`component-selector ${
            this.state.selectedInput === "upload" ? "selected" : ""
          }`}
          onClick={() => this.setComponentSelected("upload")}
        >
          {this.props.t('Upload_Download')} 
          <div className="right-dot">
            <span></span>
          </div>
          <img
            src={
              this.state.selectedInput === "upload"
                ? arrowSmallSelected
                : arrowSmall
            }
            alt=""
          />
        </button>
        {this.state.selectedInput === "upload" && (
          <div className="component-upload-expand">
            <div className="row">
              <div className="col-6">
                <select
                  className="upload-select"
                  onChange={this.handleUploadSelect}
                  value={this.state.uploadSelect}
                >
                  <option value="upload">  {this.props.t('Upload')} </option>
                  <option value="download"> {this.props.t('Download')}</option>
                </select>
              </div>
              <div className="col-6">
                {this.state.uploadSelect === "upload" && (
                  <div className="upload-select-options">
                    <div>
                    {this.props.t('Mindest')}  :&nbsp;
                      <input
                        type="number"
                        onChange={this.handleUploadMinimum}
                        value={this.state.uploadMinimum}
                        autoComplete="off"
                      />
                    </div>
                    <div>
                    {this.props.t('Maximal')} :
                      <input
                        type="number"
                        onChange={this.handleUploadMaximum}
                        value={this.state.uploadMaximum}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {this.state.uploadSelect === "download" && (
              <div className="download-select-options">
                {this.state.selectedOption.hasOwnProperty("uploadInput") && (
                  <>
                    {this.state.selectedOption.uploadInput.inputs.map(
                      (item, index) => (
                        <input
                          type="text"
                          defaultValue={item.url}
                          key={index}
                          autoComplete="off"
                        />
                      )
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation() (OptionsComponent);

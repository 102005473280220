import React, { Component } from "react";
import { StateContext } from "../../context/state";
import axios from "axios";
import { baseUrl } from "../../Constants";
import arrowDown from "../../assets/arrow-small-down.svg";
import crossSmallIcon from "../../assets/cross-small.svg";
import plusIcon from "../../assets/plus.svg";
import HttpService from "../../services/HttpService";
import { withTranslation } from 'react-i18next';

class RadioLinkOption extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      themes: [],
      // selectedDropdown: "Info",
      radioInput: {},
      unlinked:""
    };
  }
  static getDerivedStateFromProps = (nextProps, prevState) => {
    // console.log("static", nextProps.option);
    switch (nextProps.option.type) {
      case "radio":
        if (nextProps.option.radioInput.link) {
          return {
            radioInput: nextProps.option?.radioInput,
          };
        } else {
          let dummy = nextProps.option?.radioInput;
          dummy.link = [
            {optionId:"",
              columnIndex: "",
              linkedOptionId: "",
              linkedOptionValue: "",
              optionType: "",
              questionIndex: "",
              rowIndex: "",
              serviceId: "",
              themeId: "",
              display: "",
            },
          ];
          return {
            radioInput: dummy,
          };
        }

      case "text":
        if (nextProps.option?.textInput.link) {
          return {
            radioInput: nextProps.option?.textInput,
          };
        } else {
          let dummy = nextProps.option?.textInput;
          dummy.link = [
            {optionId:"",
              columnIndex: "",
              linkedOptionId: "",
              linkedOptionValue: "",
              optionType: "",
              questionIndex: "",
              rowIndex: "",
              serviceId: "",
              themeId: "",
              display: "",
            },
          ];
          return {
            radioInput: dummy,
          };
        }
      case "image":
        if (nextProps.option?.imageInput.link) {
          return {
            radioInput: nextProps.option?.imageInput,
          };
        } else {
          let dummy = nextProps.option?.imageInput;
          dummy.link = [
            {optionId:"",
              columnIndex: "",
              linkedOptionId: "",
              linkedOptionValue: "",
              optionType: "",
              questionIndex: "",
              rowIndex: "",
              serviceId: "",
              themeId: "",
              display: "",
            },
          ];
          return {
            radioInput: dummy,
          };
        }

      case "datum":
        if (nextProps.option?.datumInput.link) {
          return {
            radioInput: nextProps.option?.datumInput,
          };
        } else {
          let dummy = nextProps.option?.datumInput;
          dummy.link = [
            {optionId:"",
              columnIndex: "",
              linkedOptionId: "",
              linkedOptionValue: "",
              optionType: "",
              questionIndex: "",
              rowIndex: "",
              serviceId: "",
              themeId: "",
              display: "",
            },
          ];
          return {
            radioInput: dummy,
          };
        }

      case "bild":
        if (nextProps.option?.bildInput?.link) {
          return {
            radioInput: nextProps.option?.bildInput,
          };
        } else {
          if (nextProps.option?.bildInput) {
            let dummy = nextProps.option?.bildInput;
            dummy.link = [
              {optionId:"",
                columnIndex: "",
                linkedOptionId: "",
                linkedOptionValue: "",
                optionType: "",
                questionIndex: "",
                rowIndex: "",
                serviceId: "",
                themeId: "",
                display: "",
              },
            ];
            return {
              radioInput: dummy,
            };
          } else {
            const bildDummy = nextProps.option;
            bildDummy.bildInput = {};
            let dummy = bildDummy.bildInput;
            dummy.link = [
              {optionId:"",
                columnIndex: "",
                linkedOptionId: "",
                linkedOptionValue: "",
                optionType: "",
                questionIndex: "",
                rowIndex: "",
                serviceId: "",
                themeId: "",
                display: "",
              },
            ];
            return {
              radioInput: dummy,
            };
          }
        }

      case "table":
        if (nextProps.option?.tableInput.link) {
          return {
            radioInput: nextProps.option?.tableInput,
          };
        } else {
          let dummy = nextProps.option?.tableInput;
          dummy.link = [
            {optionId:"",
              columnIndex: "",
              linkedOptionId: "",
              linkedOptionValue: "",
              optionType: "",
              questionIndex: "",
              rowIndex: "",
              serviceId: "",
              themeId: "",
              display: "",
            },
          ];
          return {
            radioInput: dummy,
          };
        }

      case "upload":
        if (nextProps.option?.uploadInput.link) {
          return {
            radioInput: nextProps.option?.uploadInput,
          };
        } else {
          let dummy = nextProps.option?.uploadInput;
          dummy.link = [
            {optionId:"",
              columnIndex: "",
              linkedOptionId: "",
              linkedOptionValue: "",
              optionType: "",
              questionIndex: "",
              rowIndex: "",
              serviceId: "",
              themeId: "",
              display: "",
            },
          ];
          return {
            radioInput: dummy,
          };
        }
      case "service":
        return {
          radioInput: nextProps.option?.serviceInput,
        };
      default:
        return {
          radioInput: {},
        };
    }
  };
  componentDidMount() {
    axios
      .get(`${baseUrl}api/export/elements/${this.context.questionnaireId}`)
      .then((res) => {
        // console.log( "hi",res.data)
        this.setState({
          themes: res.data.themes ? res.data.themes : [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleLinkOption =async (themeId, serviceId, element, opt, index,from) => {
    // console.log("from",from)
    // console.log("op",opt)
    document.getElementById(`menu${index}`).checked = false;

    let dummyRadioInput = Object.assign({}, this.state.radioInput);
    // console.log("ele", element);
    dummyRadioInput.link[index] = {
      ...dummyRadioInput.link[index],
      columnIndex: Number(element.columnIndex),
    optionId:element.optionId,
      linkedOptionId: opt.optionId,
      linkedOptionValue: opt.optionValue,
      optionType: element.optionType,
      questionIndex: Number(element.questionIndex),
      rowIndex: Number(element.rowIndex),
      serviceId: serviceId,
      themeId: themeId,
    };
    if(from!=="ThemeLink"){
      const getQtn = await HttpService.ifIsSaved(
        this.context.questionnaireId,
      themeId,
      serviceId
      );
      let linkedOptionArray= getQtn.question[element.questionIndex].row[element.rowIndex].column[element.columnIndex].option.linkedOptionArray?
   getQtn.question[element.questionIndex].row[element.rowIndex].column[element.columnIndex].option.linkedOptionArray
   :[]
    // console.log("linkedOptionArray",linkedOptionArray)
    this.props.layoutObject.forEach((item, index) => {
      // console.log("item",item)
      item.row.forEach((_item, _index) => {
        // console.log("_item",_item)
        _item.column.forEach((__item, __index) => {
        if(__item.selected===true){
          // console.log("__item",__item)
          // console.log(dummyRadioInput,"dummy")
          dummyRadioInput.link.forEach((d)=>{
            // console.log("d",d)
            // if(d.optionId==
            //   getQtn.question[element.questionIndex].row[element.rowIndex].column
            //   [element.columnIndex].option._id){
                // console.log("linkedOptionArray",linkedOptionArray)
              linkedOptionArray.push({
                columnIndex:Number(__index),
                display:d.display,
                linkedOptionId:d.linkedOptionId,
                optionId:__item.option._id,
                optionType:__item.option.type,
                questionIndex:Number(index),
                rowIndex:Number(_index),
                serviceId:this.context.serviceId,
                themeId:this.context.themeId,
              })
         //   }
          })
         
        }
        });
      });
    });
  //  console.log("linkedOptionArray",linkedOptionArray)
  var resultArray = linkedOptionArray.filter((op, index, self) =>
  index === self.findIndex((o) => (
    o.themeId===op.themeId &&
    o.serviceId===op.serviceId &&
  o.questionIndex===op.questionIndex 
  && o.rowIndex===op.rowIndex 
  && o.columnIndex===op.columnIndex &&
  o.display===op.display &&
  o.linkedOptionId===op.linkedOptionId
  ))
)
// console.log("resultArray",resultArray)
  getQtn.question[element.questionIndex].row[element.rowIndex].column[element.columnIndex].option.linkedOptionArray=resultArray
  //  console.log("linked",getQtn)
  delete getQtn._id
  
    }
    else{
      const getQtn = await HttpService.ifIsSaved(
        this.context.questionnaireId,
      themeId,
      serviceId
      );
      var serviceLinkOptionArray= getQtn.question[element.questionIndex].row[element.rowIndex].column[element.columnIndex].option.serviceLinkOptionArray?
   getQtn.question[element.questionIndex].row[element.rowIndex].column[element.columnIndex].option.serviceLinkOptionArray
   :[]
    
    this.props.layoutObject.forEach((item, index) => {
      // console.log("item",item)
      item.row.forEach((_item, _index) => {
        // console.log("_item",_item)
        _item.column.forEach((__item, __index) => {
        if(__item.selected===true){
          // console.log("__item",__item)
          // console.log(dummyRadioInput,"dummy")
          dummyRadioInput.link.forEach((d)=>{
            // if(d.optionId==getQtn.question[element.questionIndex].row[element.rowIndex].column[element.columnIndex].option._id){
              serviceLinkOptionArray.push({
                serviceId:this.context.serviceId,
                themeId:this.context.themeId,
              })
            //}
          })
         
        }
        });
      });
    });
    // console.log("ser",serviceLinkOptionArray)
    let resultArray = serviceLinkOptionArray.filter((op, index, self) =>
    index === self.findIndex((o) => (
      o.serviceId === op.serviceId && o.themeId === op.themeId
    ))
  )
  // console.log("resultArray",resultArray)
  getQtn.question[element.questionIndex].row[element.rowIndex].column[element.columnIndex].option.serviceLinkOptionArray=resultArray
  //  console.log("linked",getQtn)
  delete getQtn._id
  
    }
    // console.log("getQtn",getQtn.question[element.questionIndex].row[element.rowIndex].column[element.columnIndex])
  //  console.log("dummyradio",dummyRadioInput)
    this.setState({
      radioInput: dummyRadioInput,
      // selectedDropdown: opt.optionValue,
    });
    // console.log('link', dummyRadioInput)
    const dummyEle = {
      ...element,
      serviceId,
      themeId,
    };
    if(themeId===this.context.themeId && serviceId ===this.context.serviceId){
      this.props.updateRadioLinkConfiguration(dummyRadioInput, dummyEle,resultArray,serviceLinkOptionArray);

    }
    else{
      this.props.updateRadioLinkConfiguration(dummyRadioInput, dummyEle);

    }
  };

  selectShowHide = (ans, index) => {
    document.getElementById(`show${index}`).checked = false;
    let dummyRadioInput = Object.assign({}, this.state.radioInput);
    if (dummyRadioInput.link) {
      dummyRadioInput.link[index].display = ans;
    } else {
      var link = {
        display: ans,
      };
      dummyRadioInput.link = [];
      dummyRadioInput.link.push(link);
      // dummyRadioInput = { ...dummyRadioInput, link };
    }
    // console.log("dummy", dummyRadioInput);
    this.setState({
      radioInput: dummyRadioInput,
    });
    this.props.updateRadioLinkConfiguration(dummyRadioInput);
  };

  handleClose = () => {
    this.props.handleClose();
  };
  addingLink = () => {
    let dummyRadioInput = Object.assign({}, this.state.radioInput);
    dummyRadioInput.link.push({
      columnIndex: "",
      linkedOptionId: "",
      linkedOptionValue: "",
      optionId:"",
      optionType: "",
      questionIndex: "",
      rowIndex: "",
      serviceId: "",
      themeId: "",
      display: "",
    });

    this.setState({
      radioInput: dummyRadioInput,
    });
    this.props.updateRadioLinkConfiguration(dummyRadioInput);
  };
  removeLink = async(itemIndex,l) => {
    // console.log('itemIndex', itemIndex)
    // console.log("selectedIndex",this.props.selectedIndex)
    const {loggedInUserId } = this.context;

    let dummyRadioInput = Object.assign({}, this.state.radioInput);
    // console.log("l",l)
    // console.log("dummyRadioinput",dummyRadioInput);
    if(!dummyRadioInput.link.linkedOptionValue){
      dummyRadioInput.link.splice(itemIndex, 1);
      this.setState({
        radioInput: dummyRadioInput,
      });
    }
    const getQtn = await HttpService.ifIsSaved(
      this.context.questionnaireId,
    l.themeId,
    l.serviceId
    );

var dummy=Object.assign([], getQtn.question[l.questionIndex].row[l.rowIndex].column[l.columnIndex].option.linkedOptionArray)
//console.log("dummy",dummy);

// console.log("dummy",dummy)

if(this.props.showLink==="element"){

getQtn.question[l.questionIndex].row[l.rowIndex].column[l.columnIndex].option.linkedOptionArray=dummy
delete getQtn._id
const result1= await HttpService.putBuilderQtn(
  this.context.questionnaireId,
  l.themeId,
  l.serviceId,
  loggedInUserId,
  getQtn
);
// console.log("result",result1)
if(result1.status===true){
  setTimeout(()=>{
    this.setState({unlinked:this.props.t("Unlinked")})
  },1000)
}
}
else{
 let res2=Object.assign([], getQtn.question[l.questionIndex].row[l.rowIndex].column[l.columnIndex].option.serviceLinkOptionArray
  )
var result2=res2.filter(res=>(res.serviceId!==this.context.serviceId && res.themeId!==this.context.themeId)
  )
//  console.log("in else",result2)
 getQtn.question[l.questionIndex].row[l.rowIndex].column[l.columnIndex].option.serviceLinkOptionArray=result2
delete getQtn._id
const result1= await HttpService.putBuilderQtn(
  this.context.questionnaireId,
  l.themeId,
  l.serviceId,
  loggedInUserId,
  getQtn
);
// console.log("result",result1)
if(result1.status===true){
  setTimeout(()=>{
    this.setState({unlinked:this.props.t("Unlinked")})
  },1000)
}

}

    // console.log("getQtn", getQtn)
// console.log("dummy",res)

// dummyRadioInput.link.splice(itemIndex, 1);
//     this.setState({
//       radioInput: dummyRadioInput,
//     });
    this.addingLink()
    if(l.themeId===this.context.themeId && l.serviceId ===this.context.serviceId){
      this.props.updateRadioLinkConfiguration(dummyRadioInput,result2,dummy);

    }
    else{
      this.props.updateRadioLinkConfiguration(dummyRadioInput);
    }
  
  };
  render() {
    // console.log("props", this.props.option);
    return (
      <div className="component-container option-component" style={{top:"62px"}}>
        <img
          src={crossSmallIcon}
          onClick={this.handleClose}
          alt=""
          style={{
            cursor: "pointer",
            float: "right",
            marginRight: "1rem",
            marginTop: "-1.5rem",
            width: "1rem",
            height: "1rem",
          }}
        />
          <span>{this.state.unlinked}</span>
        {this.state.radioInput?.link?.length
          ? this.state.radioInput?.link.map((l, index) => {
              return (
                <div
                  className="row"
                  key={index}
                  style={{ marginTop: "20px" }}
                >
                  <div className="col-6">
                    <div className="navi">
                      <input type="checkbox" id={`show${index}`} autoComplete="off" />
                      {/* <label className="checkboxLable" htmlFor="show" id="navi-icon2">Aktion auswahlen</label> */}
                      <label
                        className="checkboxLable"
                        name="showHide"
                        htmlFor={`show${index}`}
                        id="navi-icon2"
                      >
                        {/* {console.log("l", l.display)} */}
                        {l.display !== ""
                          ? l.display
                            ? this.props.t('Feld zeigen')
                            : this.props.t('Feld nicht zeigen')
                          : this.props.t('Aktion')}
                      </label>
                      <div className="multi-level">
                        <ul style={{ padding: "5px" }}>
                          <li
                            style={{ fontSize: "14px", textAlign: "left" }}
                            onClick={() => {
                              this.selectShowHide(true, index);
                            }}
                          >
                            {" "}
                            - {this.props.t('Feld zeigen')}
                          </li>
                          <li
                            style={{ fontSize: "14px", textAlign: "left" }}
                            onClick={() => {
                              this.selectShowHide(false, index);
                            }}
                          >
                            -  {this.props.t('Feld nicht zeigen')}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="navi">
                      <input type="checkbox" id={`menu${index}`} autoComplete="off" />
                        
                      <button
                    style={{
                    border: "none",
                    borderRadius: "50%",
                    margin: "-25px 20px",
                    height: "23px",
                    position:"absolute"
                  }}
                  onClick={()=>{this.removeLink(index,l)}}
                >
                  <img src={crossSmallIcon} alt="" />
                </button>
                      <label
                        className="checkboxLable"
                        htmlFor={`menu${index}`}
                        id="navi-icon2"
                      >
                        {l.linkedOptionValue?.length
                          ? l.linkedOptionValue
                          : this.props.t("Info von Antwort")}
                      </label>
                      <div className="multi-level">
                        {this.state.themes.map((theme, _i) => {
                          return (
                            <div key={theme._id} className="item">
                              <input
                                type="checkbox"
                                id={`${index}${theme.name}${_i}`}
                                autoComplete="off"
                              />
                              <img src={arrowDown} className="dropdown_arrow" alt="" />
                              <label
                                className="checkboxLable"
                                style={{ fontSize: "14px", textAlign: "left" }}
                                htmlFor={`${index}${theme.name}${_i}`}
                              >
                                {theme.name}
                              </label>
                              <ul style={{ fontSize: "14px" }}>
                                {theme.services.map((service, _s) => {
                                  // console.log('service', servicez)
                                  if (service.elements) {
                                    // console.log(
                                    //   "ids",
                                    //   service,
                                    //   service._id,
                                    //   this.context.serviceId,
                                    //   theme._id,
                                    //   this.context.themeId
                                    // );
                                    if (this.props.option.type === "service") {
                                      // console.log('in if service', this.context.themeId, this.context.serviceId, theme._id, service._id )
                                      if (
                                        service._id === this.context.serviceId &&
                                        theme._id === this.context.themeId
                                      ) {
                                        return null
                                      } else {
                                        return (
                                          <li
                                            style={{ fontSize: "14px" }}
                                            key={service._id}
                                          >
                                            <div className="sub-item">
                                              <input
                                                type="checkbox"
                                                id={`${index}${_i}${service.name}${_s}`}
                                                autoComplete="off"
                                              />
                                              <img
                                                src={arrowDown}
                                                className="dropdown_arrow"
                                                alt=""
                                              />
                                              <label
                                                className="checkboxLable"
                                                style={{
                                                  fontSize: "14px",
                                                  textAlign: "left",
                                                }}
                                                htmlFor={`${index}${_i}${service.name}${_s}`}
                                              >
                                                {/* {console.log("service", service)} */}
                                                {service.name}
                                              </label>
                                              <ul style={{ fontSize: "14px" }}>
                                                {service.elements.map(
                                                  (element, i) => {
                                                    return (
                                                      <li
                                                        key={i}
                                                        style={{
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        <div className="sub-item">
                                                          <input
                                                            type="checkbox"
                                                            id={`${index}${_i}${_s}${i}`}
                                                            autoComplete="off"
                                                          />
                                                          <img
                                                            src={arrowDown}
                                                            className="dropdown_arrow"
                                                            alt=""
                                                          />
                                                          <label
                                                            className="checkboxLable"
                                                            style={{
                                                              fontSize: "14px",
                                                              textAlign: "left",
                                                            }}
                                                            htmlFor={`${index}${_i}${_s}${i}`}
                                                          >
                                                            {element.optionType}
                                                          </label>
                                                          <ul
                                                            style={{
                                                              fontSize: "14px",
                                                            }}
                                                          >
                                                            {/* {console.log("elemn",element)} */}
                                                            {element.options.map(
                                                              (option, o) => {
                                                                return (
                                                                  <li
                                                                    key={o}
                                                                    onClick={() => {
                                                                      this.handleLinkOption(
                                                                        theme._id,
                                                                        service._id,
                                                                        element,
                                                                        option,
                                                                        index,
                                                                   "ThemeLink"
                                                                      );
                                                                    }}
                                                                  >
                                                                    -{" "}
                                                                    {
                                                                      option.optionValue
                                                                    }
                                                                  </li>
                                                                );
                                                              }
                                                            )}
                                                          </ul>
                                                        </div>
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          </li>
                                        );
                                      }
                                    } else {
                                      return (
                                        <li
                                          style={{ fontSize: "14px" }}
                                          key={service._id}
                                        >
                                          <div className="sub-item">
                                            <input
                                              type="checkbox"
                                              id={`${index}${_i}${service.name}${_s}`}
                                              autoComplete="off"
                                            />
                                            <img
                                              src={arrowDown}
                                              className="dropdown_arrow"
                                              alt=""
                                            />
                                            <label
                                              className="checkboxLable"
                                              style={{
                                                fontSize: "14px",
                                                textAlign: "left",
                                              }}
                                              htmlFor={`${index}${_i}${service.name}${_s}`}
                                            >
                                              {/* {console.log("service", service)} */}
                                              {service.name}
                                            </label>
                                            <ul style={{ fontSize: "14px" }}>
                                              {service.elements.map(
                                                (element, i) => {
                                                  return (
                                                    <li
                                                      key={i}
                                                      style={{
                                                        fontSize: "14px",
                                                      }}
                                                    >
                                                      <div className="sub-item">
                                                        <input
                                                          type="checkbox"
                                                          id={`${index}${_i}${_s}${i}`}
                                                          autoComplete="off"
                                                        />
                                                        <img
                                                          src={arrowDown}
                                                          className="dropdown_arrow"
                                                          alt=""
                                                        />
                                                        <label
                                                          className="checkboxLable"
                                                          style={{
                                                            fontSize: "14px",
                                                            textAlign: "left",
                                                          }}
                                                          htmlFor={`${index}${_i}${_s}${i}`}
                                                        >
                                                          {element.optionType}
                                                        </label>
                                                        <ul
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                        >
                                                          {element.options.map(
                                                            (option, o) => {
                                                              return (
                                                                <li
                                                                  key={o}
                                                                  onClick={() => {
                                                                    this.handleLinkOption(
                                                                      theme._id,
                                                                      service._id,
                                                                      element,
                                                                      option,
                                                                      index,
                                                                     "elementLink"
                                                                    );
                                                                  }}
                                                                >
                                                                  -{" "}
                                                                  {
                                                                    option.optionValue
                                                                  }
                                                                </li>
                                                              );
                                                            }
                                                          )}
                                                        </ul>
                                                      </div>
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </li>
                                      );
                                    }
                                  } else {
                                    return null;
                                  }
                                })}
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {/* <button
                  style={{
                    border: "none",
                    borderRadius: "50%",
                    margin: "-25px 20px",
                    height: "23px",
                    position:"absolute"
                  }}
                  onClick={()=>{this.removeLink(index)}}
                >
                  <img src={crossSmallIcon} alt="" />
                </button> */}
                </div>
              );
            })
          : null}
        <div>
          {this.state.radioInput?.link?.length <= 4 ? (
            <button
              onClick={this.addingLink}
              style={{ borderRadius: "50%", border: "none" }}
            >
              <img src={plusIcon} alt="" />
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withTranslation() (RadioLinkOption);

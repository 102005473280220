import React, { Component } from 'react';
import { PriceType, PricingFunction } from '../../../../Constants';
import { StateContext } from '../../../../context/state';
import moment from 'moment-timezone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withTranslation } from 'react-i18next';
import Style from 'style-it';

class ImageOption extends Component {
	static contextType = StateContext;
	pricingFunction;

	constructor(props) {
		super(props);
		this.state = {
			error: '',
			selectedId: '',
		};
		this.pricingFunction = PricingFunction[props.priceType || PriceType.NONE];
	}

	componentDidMount() {
		if (window.innerWidth > 1094) this.setState({ textDisplay: false });
		window.addEventListener('resize', () => {
			if (window.innerWidth > 1094) this.setState({ textDisplay: false });
			else this.setState({ textDisplay: true });
		});
	}
	onQuantityChange = (e) => {
		let key = Number(e.target.value);
		if (isNaN(key) || e.key === null || e.key === ' ') return;
		const {
			id,
			price,
			numerically,
			stages,
			maximum,
			minimum,
			onUpdateWithoutAPICall,
			answer,
		} = this.props;
		const quantity = e.target.value;
		const { amount, error } = this.pricingFunction(
			Number(price) || 0,
			Number(quantity) || 0,
			Number(stages) || 0,
			Number(maximum) || 0,
			Number(minimum) || 0,
			numerically
		);
		const item = { id, quantity, amount, error };
		let deadLineDate = new Date(this.context.deadlineForEntries);
		this.setState({ error: error, selectedId: id });
		if (
			deadLineDate.toDateString() !== new Date().toDateString() &&
			moment(this.context.deadlineForEntries).isBefore(new Date()) &&
			this.context.userRole === 'CU'
		) {
			return false;
		}
		// if (Object.keys(answer)?.length) {
		// const dummy = Object.assign([], answer);
		else {
			const findingElement = answer?.id === id;
			if (findingElement) {
				let dummy2 = {};
				onUpdateWithoutAPICall(dummy2);
			} else {
				onUpdateWithoutAPICall(item);
				// }
			}

			onUpdateWithoutAPICall({ id, quantity, amount, error });

			// onUpdate({id, quantity, amount, error});
		}
	};
	QuantityZero = (e) => {
		let deadLineDate = new Date(this.context.deadlineForEntries);

		if (
			deadLineDate.toDateString() !== new Date().toDateString() &&
			moment(this.context.deadlineForEntries).isBefore(new Date()) &&
			this.context.userRole === 'CU'
		) {
			return false;
		} else {
			let key = Number(e.target.value);
			if (isNaN(key) || e.key === null || e.key === ' ') return;
			const { id, onUpdateWithoutAPICall, answer } = this.props;
			const quantity2 = e.target.value;
			this.setState({ quantity2: quantity2 });

			const { quantity } = answer ? answer : 0;
			// const { amount, error } = this.pricingFunction(
			//   Number(price) || 0,
			//   Number(quantity) || 0,
			//   Number(stages) || 0,
			//   Number(maximum) || 0,
			//   Number(minimum) || 0,
			//   numerically
			// );
			const item = { id, quantity2, quantity };
			// console.log('props', this.props)
			// if (Object.keys(answer)?.length) {

			const findingElement = answer?.id === id;
			if (findingElement) {
				let dummy2 = {};
				onUpdateWithoutAPICall(dummy2);
			} else {
				onUpdateWithoutAPICall(item);
			}

			onUpdateWithoutAPICall({ id, quantity2, quantity });
		}
	};
	onSelect = () => {
		// console.log("in on select")
		const { id, onUpdate, answer } = this.props;
		// const quantity = priceType === "number-guests" ? this.props.guestCount : 0;


		const findingElement = answer?.id === id;
		let deadLineDate = new Date(this.context.deadlineForEntries);

		if (
			deadLineDate.toDateString() !== new Date().toDateString() &&
			moment(this.context.deadlineForEntries).isBefore(new Date()) &&
			this.context.userRole === 'CU'
		) {
			return false;
		} else {
			if (findingElement) {
				let dummy2 = {};
				onUpdate(dummy2);
			} 
		}
	};

	renderPriceTypeComponent = () => {
		const textFontSize = this.context.tetFontSize;
		const { id, answer, priceType, numerically, price, alignment } = this.props;
		const quantity = answer?.id === id ? answer?.quantity : '';
		const textColor = this.context.stylingData?.textColor
			? this.context.stylingData?.textColor
			: '#939598';
		
		const quantity2 = answer?.id === id ? answer?.quantity2 : '';
		// const val = this.findingFunction();
		// const quantity = val ? val.quantity : "";
		let placeholder;
		switch (priceType) {
			case PriceType.NUMBER_OF_GUESTS:
				placeholder = 'x Gäste';
				break;
			case PriceType.NUMERICALLY:
				placeholder = `${
					numerically?.unit
						? numerically?.unit
						: this.props.t('Geben Sie den Wert ein')
				}`;
				break;
			case PriceType.FLAT_RATE:
			default:
				placeholder = undefined;
				break;
		}

		if (placeholder) {
			return (
				<>
					{price === 0 ? (
						<input
							style={{
								...styles.input,
								margin:
									alignment === 'vertical'
										? '7px 0px 36px 30px'
										: '7px 0px 36px -14px',
							}}
							value={quantity2}
							// placeholder={placeholder}
							placeholder={this.props.t('bitte_eintragen')}
							onKeyDown={(e) => {
								if (e.key === 'Enter') this.onEnterHitWhenZero(quantity2);
							}}
							maxLength={10}
							onChange={this.QuantityZero}
							autoComplete="off"
						/>
					) : (
						priceType !== 'number-guests' && (
							<Style>
								{`
       #placeHolder::placeholder {
       color: ${textColor};   
      }`}
								<div style={{ marginTop: '-32px' }}>
									<input
										// style={styles.input}
										// placeholder={placeholder}
										id="placeHolder"
										style={{
											border: 0,
											padding: 0,
											margin:
												alignment === 'vertical'
													? '7px 0px 36px 30px'
													: '7px 0px 36px 0px',
											background: 'transparent',
											borderBottom: '1px solid #707070',
											width: '200px',
											textFontSize: `${textFontSize}`,
											color: `${textColor}`,
										}}
										placeholder={this.props.t('bitte_eintragen')}
										value={quantity}
										maxLength={10}
										// disabled={this.props.selectedGuestCount ? true :false}
										onKeyDown={(e) => {
											let deadLineDate = new Date(
												this.context.deadlineForEntries
											);

											if (
												deadLineDate.toDateString() !==
													new Date().toDateString() &&
												moment(this.context.deadlineForEntries).isBefore(
													new Date()
												) &&
												this.context.userRole === 'CU'
											) {
												e.preventDefault();
											} else {
												if (e.key === 'Enter') this.onEnterHit(quantity);
												// if (e.key === "Backspace") return;
												// let key = Number(e.key);
												// if (isNaN(key) || e.key === null || e.key === " ")
												//   e.preventDefault();
											}
										}}
										onChange={this.onQuantityChange}
										// onKeyDown={this.handleKeyDown}
										autoComplete="off"
									/>
								</div>
							</Style>
						)
					)}
				</>
			);
		} else return null;
	};
	onEnterHitWhenZero = (quantity2) => {
		const {
			id,
			onUpdate,
			onUpdateWithoutAPICall,
			answer,
		} = this.props;
		const { quantity } = answer;

		let deadLineDate = new Date(this.context.deadlineForEntries);

		if (
			deadLineDate.toDateString() !== new Date().toDateString() &&
			moment(this.context.deadlineForEntries).isBefore(new Date()) &&
			this.context.userRole === 'CU'
		) {
			return false;
		} else {
			const item = { id, quantity2, quantity };
			const findingElement = answer?.id === id;
			if (findingElement) {
				let dummy2 = {};
				//  onUpdate({ id, quantity,quantity2 });
				onUpdateWithoutAPICall(dummy2);
			} else {
				onUpdateWithoutAPICall(item);
			}
			onUpdate({ id, quantity2, quantity });
		}
	};

	onEnterHit = (quantity) => {
		const {
			id,
			price,
			numerically,
			stages,
			maximum,
			minimum,
			onUpdate,
			onUpdateWithoutAPICall,
		} = this.props;

		const { amount, error } = this.pricingFunction(
			Number(price) || 0,
			Number(quantity) || 0,
			Number(stages) || 0,
			Number(maximum) || 0,
			Number(minimum) || 0,
			numerically
		);
		const item = { id, quantity, amount, error };
		// const findingElement = answer?.id === id;
		// if (findingElement) {
		//   let dummy2 = {};
		//   onUpdateWithoutAPICall(dummy2);
		// } else {
		//   onUpdateWithoutAPICall(item);
		// }
		this.setState({ error: error, selectedId: id });
		// console.log('err', error)
		if (!error) {
			onUpdate(item);
		} else {
			onUpdateWithoutAPICall(item);
		}

		toast.error(this.state.error, {
			position: 'top-center',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};
	findingFunction = () => {
		const { id, answer } = this.props;
		const findingElement = answer?.id === id;
		if (findingElement) {
			return findingElement;
		} else {
			return false;
		}
		// }
		//  else {
		//   return false;
		// }
	};
	render() {
		let { id, image, answer, alignment, price, priceType, numerically } =
			this.props;
		const textFontSize = this.context.stylingData?.textFontSize;
		const lineColor = this.context.stylingData?.lineColor
			? this.context.stylingData?.lineColor
			: 'rgb(41, 120, 37)';
		styles.selected.background = lineColor;
		const selectedStyle = this.findingFunction() ? styles.selected : {};
		const circleStyle = { ...styles.circle, ...selectedStyle };
		const quantity2 = answer?.id === id ? answer?.quantity2 : '';
		return (
			<div
				className={`image-item image-item-${alignment}`}
				style={{ fontSize: `${textFontSize}px` }}>
				{this.state.selectedId === answer?.id && this.state.textDisplay ? (
					<ToastContainer
						position="bottom-center"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
				) : (
					<></>
				)}
				{/* {(this.state.selectedId===answer?.id && this.state.textDisplay) ?
             <span  style={{color:"red",margin: "0px 0px 10px", fontWeight: "600"}}>{answer?.error}</span>:
             <></>
        }      */}
				<div className="image" style={{ backgroundImage: `url(${image})` }} />
				<div
					className={'circle'}
					style={{
						...circleStyle,
						border: `1px solid ${lineColor}`,
						marginBottom: '35px',
					}}
					onClick={this.onSelect}
				/>
				{/* <div className="radioLabel" style={{color:`${headingColor}`}}>
            {value}</div> */}
				{price && (
					<span
						className="price-display-image"
						// style={{bottom:textFontSize>12 ?"33px":"29px"}}
						style={{
							bottom: alignment === 'vertical' ? '-6px' : '8px',
							left: alignment === 'vertical' ? '30px' : '0px',
						}}>
						{priceType === 'flat-rate' ? (
							price === 0 ? (
								<input
									style={{ ...styles.input, margin: '7px 0px 0px 0px' }}
									value={quantity2}
									// placeholder={placeholder}
									placeholder={this.props.t('bitte_eintragen')}
									onKeyDown={(e) => {
										if (e.key === 'Enter') this.onEnterHitWhenZero(quantity2);
									}}
									maxLength={10}
									onChange={this.QuantityZero}
									autoComplete="off"
								/>
							) : (
								`(${
									numerically?.unit
										? ` ${numerically.unit}`
										: `${this.props.t('flat_rate')}`
								} ${
									this.context.languageFormat === 'en'
										? `${Number(price)
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
										: `${Number(price)
												.toFixed(2)
												.replace('.', ',')
												.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
								} ${this.context.currencyFormat})`
							)
						) : priceType === PriceType.NUMERICALLY ? (
							price !== 0 &&
							`(
                ${
									this.context.languageFormat === 'en'
										? `${Number(price)
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
										: `${Number(price)
												.toFixed(2)
												.replace('.', ',')
												.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
								} ${this.context.currencyFormat}
                  ${numerically?.unit ? ` ${numerically.unit}` : ''}
                  )`
						) : (
							price !== 0 &&
							`(
                    ${
											this.context.languageFormat === 'en'
												? `${Number(price)
														.toFixed(2)
														.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
												: `${Number(price)
														.toFixed(2)
														.replace('.', ',')
														.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
										} ${this.context.currencyFormat}
                    ${
											numerically?.unit
												? ` ${numerically.unit}`
												: `${this.props.t('Per')} ${this.props.t('Gast')}`
										}
                    )`
						)}
					</span>
				)}
				{this.renderPriceTypeComponent()}
			</div>
		);
	}
}

export default withTranslation()(ImageOption);
const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		padding: '10px',
	},
	labelContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '10px',
	},
	typeContainer: {
		marginLeft: '54px',
	},
	circle: {
		minWidth: '16px',
		minHeight: '16px',
		display: 'inline-block',
		textAlign: 'center',
		background: 'transparent',
		borderRadius: '8px',
		border: '1px solid #297825',
		outline: 'none',
	},
	selected: {
		background: '#297825',
	},
	label: {
		margin: '0 0 0 20px',
		padding: '0',
		display: 'inline-block',
		color: '#939598',
		fontSize: '16px',
		width: '50px',
		flexGrow: 1,
	},
	input: {
		border: 0,
		padding: 0,
		margin: '7px 0px 36px 30px',
		background: 'transparent',
		borderBottom: '1px solid #707070',
		width: '200px',
	},
};

import React, {Component} from "react";
import editIcon from "../../assets/edit.svg";
import { withTranslation} from 'react-i18next';

 class KeyFigure extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editable: false
        }
    }

    render() {

        const {style, onEditSelect, value} = this.props;
        const {editable} = this.state;

        let containerStyle = {...styles.container, ...style};

        return (
            <div
                style={containerStyle}
                onMouseEnter={() => this.isEditable(true)}
                onMouseLeave={() => this.isEditable(false)}
            >
                <div><p style={styles.indicator}>{value.value}</p></div>
                <p style={styles.name}>{this.props.t(value.name)}</p>
                <img
                    hidden={!editable}
                    onClick={onEditSelect}
                    style={styles.icon}
                    src={editIcon}
                    alt="edit"
                />
            </div>
        );
    }

    isEditable(show) {
        this.setState({
            editable: show,
        })
    }
}
export default  withTranslation() (KeyFigure);
const styles = {
	container: {
		background: '#d0d3d4',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		color: '#939599',
		borderRadius: '3px',
        boxShadow: '0px 0px 10px #dadada',
        border: "0.1px solid #70707030"
	},
	indicator: {
		textAlign: 'center',
		fontSize: '64px',
		lineHeight: '56px',
		fontWeight: '300',
		margin: '0px',
	},
	name: {
		textAlign: 'center',
		fontSize: '24px',
		// fontStyle: "italic",
		margin: '0px',
		marginBottom: '15px',
	},
	icon: {
		width: '24px',
		height: '24px',
		bottom: 8,
		right: 8,
		position: 'absolute',
		cursor: 'pointer',
	},
};

import React, { Component } from 'react';
import Accordion from '../Accordion';
import HttpService from '../../services/HttpService';
import Step from '../partials/Step';
import UploadDownload from '../preview/upload/UploadDownload';
import RadioButton from '../preview/radio/text/RadioButton';
import Bild from '../preview/bild/Bild';
import Table from '../preview/table/Table';
import TextComponent from '../preview/text/TextComponent';
import DateTime from '../preview/dateTime/DateTime';
import ImageButton from '../preview/radio/image/ImageButton';
import PriceBar from '../partials/PriceBar';
import { StateContext } from '../../context/state';
import Text from '../preview/Text';
import logoutIcon from '../../assets/logout.svg';
import home from '../../assets/home.svg';
import RightBarPreview from '../partials/RightBarPreview';
// import i18next from 'i18next';
// import swal from 'sweetalert';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { PriceType, PricingFunction } from '../../Constants';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PullPriceBar from '../partials/pullbarPreview';
import axios from 'axios';
import { baseUrl } from '../../Constants';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'reactstrap';
import swal from 'sweetalert';
import { withTranslation } from 'react-i18next';

class QuestionPreview extends Component {
	static contextType = StateContext;
	files = {};
	valid = true;
	deletedFiles = {};
	constructor(props) {
		super(props);
		this.state = {
			loading: 'Loading...',
			activeTheme: { id: -1, name: 'Anzahl Gäste', services: [] },
			activeService: undefined,
			questionnaire: {},
			questFetchStatus: undefined,
			defaultSettingStyle: {},
			guestInfo: {},
			themes: [],
			headerImage: '',
			responseFromPut: [],
			couple: {},
			vvo: {},
			guestPrice: 0,
			guestPriceObj: {},
			latestPrice: [],
			guestsCount: 0,
			bool: true,
			bool2: true,
			bool3: true,
			urls: [],
			weiterPressed: false,
			backPressed: false,
			lengthExceed: false,
			lengthExceed2: false,
			array: [],
			saveDisabled: false,
			errorInPricebar: false,
			isSaved: false,
			fromSaveQuestionnaire: false,
			message: '',
			timesPutCalled: 0,
			position: { x: 0.5, y: 0.5 },
			scale: 1,
			width: 850,
			height: 320,
			loader: false,
			oldHeaderImage: '',
			allowPricingApi: false,
			uploadPriceStatus: false,
			guestInitialState: {},
			guestChangeTriggered: false,
			updateCalled: false,
			eventPageRoute: null,
			disableBackground: false,
			guestAPIExecuted: false,
		};
	}

	componentDidMount = async () => {
		const { eventId, questionnaireId, venueId } = this.context;
		if (this.props.location.state)
			this.setState({ defaultSettingStyle: this.props.location.state });
		if (window.innerWidth > 1024) this.setState({ bool: false });
		window.addEventListener('resize', () => {
			if (window.innerWidth > 1024) this.setState({ bool: false });
			else this.setState({ bool: true });
		});
		if (window.innerWidth > 511) this.setState({ bool2: false });
		window.addEventListener('resize', () => {
			if (window.innerWidth > 511) this.setState({ bool2: false });
			else this.setState({ bool2: true });
		});
		const obj = JSON.parse(sessionStorage.getItem('state2'));
		this.setState({ eventPageRoute: obj?.eventsRoute });

		let themes = (await HttpService.getThemes(questionnaireId))?.themeApiObject;
		themes = themes?.themes ?? [];
		for (const theme of themes)
			theme.services = theme?.services?.filter((service) => service?.display);
		themes = themes.filter((theme) => theme?.services?.length > 0);
		themes = [this.state.activeTheme, ...themes];

		let guestInfo,
			couple,
			vvo,
			headerImage,
			totalAmount,
			latestPrice,
			guestInitialState;

		const guestsPromise = HttpService.getGuests(questionnaireId).then(
			(response) => {
				guestInfo = response;
				console.log('guestinfo', guestInfo);
			}
		);
		const coupleDetailsPromise = HttpService.fetchEventDetails(
			eventId,
			'CU'
		).then((response) => (couple = response));
		const vvDetailsPromise = HttpService.fetchEventDetails(eventId, 'VV').then(
			(response) => (vvo = response)
		);
		const headerImagePromise = HttpService.getHeaderImage(questionnaireId).then(
			(response) => {
				headerImage = response?.url ? response?.url : response?.headerImage;
			}
		);
		const totalPromise = HttpService.fetchTotalAmount(questionnaireId).then(
			(response) => (totalAmount = response)
		);
		const venuePromise = HttpService.fetchVenue(venueId).then((response) =>
			this.context.updateState({
				dateFormat: response?.dateFormat?.toLowerCase().replace(/m/g, 'M'),
				timeFormat: response?.timeFormat,
			})
		);
		let latestPricePromise;
		if (this.state.weiterPressed) {
			latestPricePromise = HttpService.getPriceBarLatestData(
				questionnaireId
			).then((response) => (latestPrice = response));
		}

		Promise.all([
			guestsPromise,
			coupleDetailsPromise,
			vvDetailsPromise,
			headerImagePromise,
			totalPromise,
			venuePromise,
			latestPricePromise,
		])
			.then(() => {
				let obj = {};
				if (guestInfo?.pricing && guestInfo?.guests) {
					guestInfo.pricing.forEach((singlePricing) => {
						if (
							Object.keys(guestInfo?.guests).includes(singlePricing.value) &&
							singlePricing.price
						) {
							let x =
								PricingFunction[singlePricing.priceType || PriceType.NONE];
							const { amount, error } = x(
								Number(singlePricing.price) || 0,
								Number(guestInfo?.guests[singlePricing.value]) || 0,
								Number(singlePricing.numerically.stage) || 0,
								Number(singlePricing.numerically.maximumNumber) || 0,
								Number(singlePricing.numerically.minimumNumber) || 0,
								singlePricing.numerically
							);
							// console.log("amount", amount)
							if (amount) {
								obj[`${singlePricing.value}`] = amount;
							} else {
								obj[`${singlePricing.value}`] = error;
							}
						}
					});
				}
				// console.log('hi', guestInfo)
				let count = 0;
				if (guestInfo?.guests) {
					Object.keys(guestInfo?.guests).forEach((key) => {
						if (guestInfo.fields[key]) {
							count += Number(guestInfo?.guests[key]);
						}
					});
				}

				this.setState({
					loading: undefined,
					guestInfo,
					guestInitialState,
					themes,
					couple,
					vvo,
					headerImage,
					totalAmount,
					guestPriceObj: obj,
					latestPrice,
					guestCount: count,
					scale: guestInfo.scale ? guestInfo.scale : this.state.scale,
					position: guestInfo.position
						? guestInfo.position
						: this.state.position,
				});
			})
			.catch((error) => alert(error));
	};
	setErrorInPricebar = (value) => {
		this.setState({ errorInPricebar: value });
	};
	updatePriceState = (price, themes, questionnaire) => {
		// console.log("proce",price,themes,questionnaire)
		// this.setState({latestPrice:price})
		this.setState((prevState) => ({
			themes,
			questionnaire,
			totalAmount: questionnaire?.totalAmount ?? prevState.totalAmount,
			latestPrice: price,
			questFetchStatus: undefined,
			weiterPressed: false,
			backPressed: false,
			disableBackground: false,
		}));
	};
	refresh = async (updatedOption, count) => {
		// console.log(this.state.disableBackground,"disable")
		this.setState({
			questFetchStatus: 'loading',
			uploadPriceStatus: true,
			disableBackground: false,
		});
		this.onUpdate(updatedOption, count, undefined);

		let { activeTheme, activeService, questionnaire } = this.state;
		// const { userId, eventId, theme,service,contents } = body;
		const { questionnaireId, loggedInUserId, eventId } = this.context;

		delete questionnaire._id;

		const upload = await HttpService.uploadAnswer(
			questionnaireId,
			activeTheme.id,
			activeService.id,
			loggedInUserId,
			questionnaire
		);

		Promise.all([upload]).then(() => {
			axios
				.get(
					baseUrl +
						`api/questions/${questionnaireId}/${activeTheme.id}/${activeService.id}`
				)
				.then((res) => {
					// console.log("res",res)
					if (res.data) {
						this.setState({
							questFetchStatus: undefined,
							questionnaire: res.data,
							// updateCalled:false
						});
					}
				});
		});
		console.log('questionnaire', questionnaire);
		console.log('updatedOption', updatedOption);
		console.log(
			questionnaire?.question[0]?.row[0]?.column[0]?.option?.textInput
				?.inputs[0]?.interactiveText
		);
		// var contentsLabel =
		var changeLog = {
			userId: loggedInUserId,
			eventId: eventId,
			questionnaireId: questionnaireId,
			theme: activeTheme.name,
			service: activeService.name,
			contents: [
				updatedOption?.radioInput?.question,
				updatedOption?.radioInput?.multipleSelection === true
					? updatedOption?.radioInput?.question
					: '',
				questionnaire?.question[0]?.row[0]?.column[0]?.option?.textInput
					?.inputs[0]?.interactiveText,
				updatedOption?.imageInput?.question,
				updatedOption?.imageInput?.multipleSelection === true
					? updatedOption?.radioInput?.question
					: '',
			],
		};
		await HttpService.uploadChangeLog(changeLog);

		console.log('in refresh');

		// console.log("uploadPrice",uploadPrice)

		const themePrice = await HttpService.themePriceUpdate(
			this.context.questionnaireId
		);
		
		if (themePrice.status === 'success') {
			let themes = (await HttpService.getThemes(this.context.questionnaireId))
				?.themeApiObject;

			themes = themes?.themes ?? [];
			for (const theme of themes)
				theme.services = theme?.services?.filter((service) => service?.display);
			themes = themes.filter((theme) => theme?.services?.length > 0);
			themes = [{ id: -1, name: 'Anzahl Gäste', services: [] }, ...themes];
			let totalAmount = await HttpService.fetchTotalAmount(questionnaireId);
			let latestPrice = await HttpService.getPriceBarLatestData(
				questionnaireId
			);
			console.log('latestPrice', latestPrice);
			const uploadPrice = await HttpService.uploadPrice(
				this.context.questionnaireId
			);
			if (uploadPrice.status === 'success' && latestPrice) {
				this.setState({ uploadPriceStatus: false });
			}

			// console.log('latest price', totalAmountApi)
			this.setState(() => ({
				themes,
				totalAmount,
				latestPrice,
				questFetchStatus: undefined,
				weiterPressed: false,
				backPressed: false,
			}));
		}
	};

	onUpdate = (updatedOption, count) => {
		// console.log('inside update', flag)
		this.setState({
			updateCalled: true,
			timesPutCalled: this.state.timesPutCalled + 1,
		});
		const { questionnaire } = this.state;
		const { row, column, ele } = count;
		questionnaire.question[row].row[column].column[ele].option = updatedOption;
		// console.log("latestprice",this.state.latestPrice)
		//  console.log("option",  questionnaire.question[row].row[column].column[ele].option.radioInput)
		if (
			questionnaire.question[row].row[column].column[ele].option.radioInput
				?.multipleSelection === 'true'
		) {
			// console.log("in")
			let min, max;
			if (
				questionnaire.question[row].row[column].column[ele].option.radioInput
			) {
				min =
					questionnaire.question[row].row[column].column[ele].option.radioInput
						.min;
				max =
					questionnaire.question[row].row[column].column[ele].option.radioInput
						.max;
			}
			if (max) {
				if (
					questionnaire.question[row].row[column].column[ele].option?.answer
						?.length > max
				) {
					toast.error(`Bitte wählen Sie maximal ${max} Möglichkeiten`, {
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
					// questionnaire.question[row].row[column].column[ele].option.answer.pop()
					this.setState({
						lengthExceed2: true,
						questionnaire,
						// questFetchStatus: flag ? "Loading" : undefined,
					});
				}
			} else {
				this.setState({
					questionnaire,
					// questFetchStatus: flag ? "Loading" : undefined,
				});
			}
		} else {
			this.setState({
				questionnaire,
				// questFetchStatus: flag ? "Loading" : undefined,
			});
		}
	};

	onFileSelect = (files, count) => {
		// console.log("files in file select",files)
		const { row, column, ele } = count;
		// this.files[`${row}:${column}:${ele}`] = files;
		const { questionnaire } = this.state;
		questionnaire.question[row].row[column].column[ele].option.files = files;
		// console.log('bla',questionnaire, count, row, column, ele,  questionnaire.question[row].row[column].column[ele].option.files )
		this.setState({
			questionnaire,
			updateCalled: true,
		});
	};
	onFileDelete = (files, count) => {
		const { questionnaire } = this.state;

		const { row, column, ele } = count;
		this.deletedFiles[`${row}:${column}:${ele}`] = files;
		let deletedKeys = Object.keys(this.deletedFiles);
		if (deletedKeys.length > 0) {
			for (let key of deletedKeys) {
				let count = key.split(':');
				questionnaire.question[count[0]].row[count[1]].column[
					count[2]
				].option.files = files;

			}
			this.setState({
				questionnaire,
				updateCalled: true,
			});
		}
	};
	renderOption = (option, count) => {
		const params = {
			guestCount: this.state.guestCount,
			option,
			onUpdate: (updatedOption) => this.onUpdate(updatedOption, count),
		};

		const radioParams = {
			// guestCount: this.state.guestCount,
			option,
			onUpdateWithoutAPICall: (updatedOption) =>
				this.onUpdate(updatedOption, count),
			onUpdate: (updatedOption) => this.refresh(updatedOption, count),
			guestInfo: this.state.guestInfo,
		};

		const textParams = {
			guestCount: this.state.guestCount,
			option,
			onUpdate: (updatedOption) => this.refresh(updatedOption, count),
			onUpdateWithoutAPICall: (updatedOption) =>
				this.onUpdate(updatedOption, count),
		};
		// console.log("option.type",option.type)
		if (option?.display === false) {
			return null;
		} else if (option?.isInitialDisplay === false) {
			return null;
		}

		switch (option?.type) {
			case 'text':
				return <TextComponent {...textParams} />;
			case 'datum':
				return <DateTime {...params} />;
			case 'bild':
				return <Bild {...params} />;
			case 'radio':
				return <RadioButton {...radioParams} />;
			case 'table':
				return <Table {...params} />;
			case 'upload':
				return (
					<UploadDownload
						{...params}
						onSelect={(files) => this.onFileSelect(files, count)}
						onDelete={(files) => this.onFileDelete(files, count)}
					/>
				);
			case 'image':
				return <ImageButton {...radioParams} />;
			default:
				break;
		}
	};
	handleKeyDown = (e) => {
		if (e.key === 'Backspace') return;
		let key = Number(e.key);
		if (isNaN(key) || e.key === null || e.key === ' ') e.preventDefault();
	};

	onBlur = () => {
		const { guestPriceObj } = this.state;
		toast.dismiss();

		for (const obj in guestPriceObj) {
			if (guestPriceObj[obj] && !Number(guestPriceObj[obj])) {
				if (window.innerWidth < 1024) {
					toast.error(
						`${this.state.guestInfo.guestLabel[obj]}: ${guestPriceObj[obj]}`,
						{
							position: 'top-center',
							autoClose: 6000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: false,
							progress: undefined,
						}
					);
				}
			}
		}
	};

	onGuestChange = (guestCount) => {
		const { guestInfo } = this.state;
		let { guests, pricing } = guestInfo;
		// console.log("guests",guests)
		guests = {
			...guests,
			...guestCount,
		};

		guestInfo.guests = guests;
		let obj = {};
		pricing.forEach((singlePricing) => {
			if (Object.keys(guests).includes(singlePricing.value)) {
				let x = PricingFunction[singlePricing.priceType || PriceType.NONE];
				const { amount, error } = x(
					Number(singlePricing.price) || 0,
					Number(guests[singlePricing.value]) || 0,
					Number(singlePricing.numerically.stage) || 0,
					Number(singlePricing.numerically.maximumNumber) || 0,
					Number(singlePricing.numerically.minimumNumber) || 0,
					singlePricing.numerically
				);

				if (amount) {
					// console.log("in if")
					obj[`${singlePricing.value}`] = amount;
				} else {
					obj[`${singlePricing.value}`] = error;
				}
			}
		});

		this.setState({
			guestInfo,
			guestPriceObj: obj,
			guestChangeTriggered: true,
			updateCalled: true,
		});
	};
	onEnterHit = () => {
		let totalAmount,
			isValid = true;
		const { questionnaireId } = this.context;
		const { guestPriceObj } = this.state;

		for (const obj in guestPriceObj) {
			//console.log(guestPriceObj, guestPriceObj[obj], obj)

			if (guestPriceObj[obj] && !Number(guestPriceObj[obj])) {
				if (window.innerWidth < 1024) {
					toast.error(
						`${this.state.guestInfo.guestLabel[obj]}: ${guestPriceObj[obj]}`,
						{
							position: 'top-center',
							autoClose: 6000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: false,
							progress: undefined,
						}
					);
				}

				return (isValid = false);
			}
		}
		if (isValid) {
			this.saveQuestionnaireWithoutSavePopUp().then(
				function () {
					const totalPromise = HttpService.fetchTotalAmount(
						questionnaireId
					).then((response) => (totalAmount = response));

					Promise.all([totalPromise]).then(() => {
						//console.log(res)

						this.setState(() => ({
							totalAmount: totalAmount,
						}));
					});
				}.bind(this)
			);
		}
	};
	renderContent = () => {
		const { activeTheme, activeService, questFetchStatus, themes } = this.state;
		const headingColor = this.context.stylingData?.textColor
			? this.context.stylingData.textColor
			: this.props.location.state.presTextColor;

		if (activeTheme.id === -1) {
			const {
				guestInfo: { content, fields, guests, pricing, guestLabel },
			} = this.state;

			return (
				<React.Fragment>
					{content?.title?.value ? (
						<div
							style={{ marginTop: '20px', color: `${headingColor}` }}
							dangerouslySetInnerHTML={{
								__html: draftToHtml(
									convertToRaw(
										EditorState.createWithContent(
											convertFromRaw(content.title.value)
										).getCurrentContent()
									)
								),
							}}></div>
					) : null}
					<div style={{ overflow: 'auto' }}>
						{(content?.paragraphs ?? []).map((p, i) => (
							<p
								key={i}
								dangerouslySetInnerHTML={{
									__html: draftToHtml(
										convertToRaw(
											EditorState.createWithContent(
												convertFromRaw(p.value)
											).getCurrentContent()
										)
									),
								}}></p>
						))}
					</div>

					<form className="preview" noValidate autoComplete="off">
						<div
							className="inputpadding"
							style={(styles.flexRow, { width: '100%' })}>
							<ToastContainer
								position="bottom-center"
								autoClose={5000}
								hideProgressBar={false}
								newestOnTop={false}
								closeOnClick
								rtl={false}
								pauseOnFocusLoss
								draggable
								pauseOnHover
							/>

							{questFetchStatus && (
								<div
									style={{
										...styles.loader,
										marginTop: '-70px',
										position:
											this.state.disableBackground === false ? 'absolute' : '',
										marginLeft:
											this.state.disableBackground === false &&
											(this.state.bool === false ? '390px' : '600px'),
									}}
									className={
										this.state.disableBackground === true ? 'parentDisable' : ''
									}>
									<div>
										<Spinner
											style={
												this.context.stylingData?.['lineColor']
													? {
															color: this.context.stylingData?.lineColor
																? this.context.stylingData.lineColor
																: this.props.location.state.presAccentColor,
													  }
													: { color: 'success' }
											}
										/>
									</div>
								</div>
							)}

							<div className="displayInput">
								{fields?.adults && (
									<div className="inputWrapper">
										<div className="displayInput">
											<input
												maxLength={5}
												type="text"
												placeholder={this.props.t('bitte_eintragen')}
												style={styles.input}
												className="input-width"
												value={guests?.adults}
												onKeyDown={(e) => {
													let deadLineDate = new Date(
														this.context.deadlineForEntries
													);

													if (
														deadLineDate.toDateString() !==
															new Date().toDateString() &&
														moment(this.context.deadlineForEntries).isBefore(
															new Date()
														) &&
														this.context.userRole === 'CU'
													) {
														e.preventDefault();
													} else {
														if (e.key === 'Enter')
															this.onEnterHit(guests?.adults);
														// if (e.key === "Backspace") return;
														// let key = Number(e.key);
														// if (isNaN(key) || e.key === null || e.key === " ")
														//   e.preventDefault();
													}
												}}
												onChange={(e) => {
													let deadLineDate = new Date(
														this.context.deadlineForEntries
													);

													if (
														deadLineDate.toDateString() !==
															new Date().toDateString() &&
														moment(this.context.deadlineForEntries).isBefore(
															new Date()
														) &&
														this.context.userRole === 'CU'
													) {
														e.preventDefault();
													} else {
														let key = Number(e.target.value);
														if (isNaN(key) || e.key === null || e.key === ' ')
															return;
														this.onGuestChange({ adults: e.target.value });
													}
												}}
												autoComplete="off"
											/>
											{/* <p style={{color:"rgb(149, 150, 155)"}}>{pricing?.[0]?.price? `€ ${pricing?.[0]?.price}` :null }{pricing?.[0]?.numerically.unit? `/ ${pricing?.[0]?.numerically.unit}` :null }</p> */}
										</div>
										<p
											//  style={styles.inputLabel}
											style={{
												color: '#95969b',
												fontSize: '14px',
												width: this.state.bool === false ? '200px' : '',
											}}>
											{guestLabel
												? this.props.t(guestLabel.adults)
												: 'Anzahl Erwachsene'}
											{/* {guestLabel ? guestLabel.adults : "Anzahl Erwachsene"} */}
											{/* {translate(guestLabel ? [guestLabel.adults] : "Anzahl Erwachsene")} */}
											{/* {(guestLabel ? guestLabel.adults : "Anzahl Erwachsene").replace(' ','_')} */}
											{pricing?.[0]?.price ? ' (' : null}
											{pricing?.[0]?.price
												? this.context.languageFormat === 'en'
													? Number(pricing?.[0]?.price)
															.toFixed(2)
															.replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0.00
													: Number(pricing?.[0]?.price)
															.toFixed(2)
															.replace('.', ',')
															.replace(/\B(?=(\d{3})+(?!\d))/g, '.') ?? 0.00
												: null}
											{/* {pricing?.[0]?.numerically.unit
                        ? ` ${this.context.currencyFormat} ${pricing?.[0]?.numerically.unit}`
                        : null} */}{' '}
											{this.context.currencyFormat}
											{pricing?.[0]?.price ? ')' : null}
										</p>
									</div>
								)}

								{fields?.U12 && (
									<div className="inputWrapper">
										<div className="displayInput">
											<input
												maxLength={5}
												type="text"
												style={styles.input}
												className="input-width"
												placeholder={this.props.t('bitte_eintragen')}
												value={guests?.U12}
												onKeyDown={(e) => {
													let deadLineDate = new Date(
														this.context.deadlineForEntries
													);

													if (
														deadLineDate.toDateString() !==
															new Date().toDateString() &&
														moment(this.context.deadlineForEntries).isBefore(
															new Date()
														) &&
														this.context.userRole === 'CU'
													) {
														e.preventDefault();
													} else {
														if (e.key === 'Enter') this.onEnterHit(guests?.U12);
													}
													// if (e.key === "Backspace") return;
													// let key = Number(e.key);
													// if (isNaN(key) || e.key === null || e.key === " ")
													//   e.preventDefault();
												}}
												onChange={(e) => {
													let deadLineDate = new Date(
														this.context.deadlineForEntries
													);

													if (
														deadLineDate.toDateString() !==
															new Date().toDateString() &&
														moment(this.context.deadlineForEntries).isBefore(
															new Date()
														) &&
														this.context.userRole === 'CU'
													) {
														e.preventDefault();
													} else {
														let deadLineDate = new Date(
															this.context.deadlineForEntries
														);

														if (
															deadLineDate.toDateString() !==
																new Date().toDateString() &&
															moment(this.context.deadlineForEntries).isBefore(
																new Date()
															) &&
															this.context.userRole === 'CU'
														) {
															e.preventDefault();
														} else {
															let key = Number(e.target.value);
															if (isNaN(key) || e.key === null || e.key === ' ')
																return;
															this.onGuestChange({ U12: e.target.value });
														}
													}
												}}
												autoComplete="off"
											/>
											{/* <p style={{color:"rgb(149, 150, 155)"}}>{pricing?.[1]?.price ? `€ ${pricing?.[1]?.price}`: null}{pricing?.[1]?.numerically.unit? `/ ${pricing?.[1]?.numerically.unit}` :null }</p> */}
										</div>
										<p
											// style={styles.inputLabel}
											style={{
												color: '#95969b',
												fontSize: '14px',
												width: this.state.bool === false ? '200px' : '',
											}}>
											{guestLabel
												? this.props.t(guestLabel.U12)
												: 'Anzahl Kinder'}
											{/* {guestLabel ? guestLabel.U12 : "Anzahl Kinder"} */}
											{pricing?.[1]?.price ? ' (' : null}
											{pricing?.[1]?.price
												? // `${Number(pricing?.[1]?.price)
												  //     .toFixed(2)
												  //     .replace(".", ",")
												  //     .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
												  this.context.languageFormat === 'en'
													? Number(pricing?.[1]?.price)
															.toFixed(2)
															.replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0.00
													: Number(pricing?.[1]?.price)
															.toFixed(2)
															.replace('.', ',')
															.replace(/\B(?=(\d{3})+(?!\d))/g, '.') ?? 0.00
												: null}
											{/* {pricing?.[1]?.numerically.unit
                        ? `${this.context.currencyFormat} ${pricing?.[1]?.numerically.unit}`
                        : null} */}{' '}
											{this.context.currencyFormat}
											{pricing?.[1]?.price ? ')' : null}
										</p>
									</div>
								)}
							</div>
							<div className="displayInput">
								{fields?.U18 && (
									<div className="inputWrapper">
										<div className="displayInput">
											<input
												maxLength={5}
												type="text"
												placeholder={this.props.t('bitte_eintragen')}
												style={styles.input}
												className="input-width"
												value={guests?.U18}
												onKeyDown={(e) => {
													let deadLineDate = new Date(
														this.context.deadlineForEntries
													);

													if (
														deadLineDate.toDateString() !==
															new Date().toDateString() &&
														moment(this.context.deadlineForEntries).isBefore(
															new Date()
														) &&
														this.context.userRole === 'CU'
													) {
														e.preventDefault();
													} else {
														if (e.key === 'Enter') this.onEnterHit(guests?.U18);
													}
													// if (e.key === "Backspace") return;
													// let key = Number(e.key);
													// if (isNaN(key) || e.key === null || e.key === " ")
													//   e.preventDefault();
												}}
												onBlur={this.onBlur}
												onChange={(e) => {
													let deadLineDate = new Date(
														this.context.deadlineForEntries
													);

													if (
														deadLineDate.toDateString() !==
															new Date().toDateString() &&
														moment(this.context.deadlineForEntries).isBefore(
															new Date()
														) &&
														this.context.userRole === 'CU'
													) {
														e.preventDefault();
													} else {
														let key = Number(e.target.value);
														if (isNaN(key) || e.key === null || e.key === ' ')
															return;
														this.onGuestChange({ U18: e.target.value });
													}
												}}
												autoComplete="off"
											/>
											{/* <p style={{color:"rgb(149, 150, 155)"}}>{pricing?.[2]?.price ? `€ ${pricing?.[2]?.price}`:null}{pricing?.[2]?.numerically.unit? `/ ${pricing?.[2]?.numerically.unit}` :null }</p> */}
										</div>
										<p
											style={{
												color: '#95969b',
												fontSize: '14px',
												width: this.state.bool === false ? '200px' : '',
											}}
											// style={styles.inputLabel}
										>
											{guestLabel
												? this.props.t(guestLabel.U18)
												: 'Anzahl Jugendliche'}
											{/* {guestLabel ? guestLabel.U18 :"Anzahl Jugendliche"} */}
											{/* {translate(guestLabel ? [guestLabel.U18] :"Anzahl Jugendliche")} */}
											{pricing?.[2]?.price ? ' (' : null}
											{pricing?.[2]?.price
												? this.context.languageFormat === 'en'
													? Number(pricing?.[2]?.price)
															.toFixed(2)
															.replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0.0
													: Number(pricing?.[2]?.price)
															.toFixed(2)
															.replace('.', ',')
															.replace(/\B(?=(\d{3})+(?!\d))/g, '.') ?? 0.0
												: null}
											{/* {pricing?.[2]?.numerically.unit
                        ? ` ${this.context.currencyFormat} ${pricing?.[2]?.numerically.unit}`
                        : null} */}{' '}
											{this.context.currencyFormat}
											{pricing?.[2]?.price ? ')' : null}
										</p>
									</div>
								)}

								{fields?.U6 && (
									<div className="inputWrapper">
										<div className="displayInput">
											<input
												maxLength={5}
												type="text"
												placeholder={this.props.t('bitte_eintragen')}
												style={styles.input}
												className="input-width"
												value={guests?.U6}
												onKeyDown={(e) => {
													let deadLineDate = new Date(
														this.context.deadlineForEntries
													);

													if (
														deadLineDate.toDateString() !==
															new Date().toDateString() &&
														moment(this.context.deadlineForEntries).isBefore(
															new Date()
														) &&
														this.context.userRole === 'CU'
													) {
														e.preventDefault();
													} else {
														if (e.key === 'Enter') this.onEnterHit(guests?.U6);
													}
													// if (e.key === "Backspace") return;
													// let key = Number(e.key);
													// if (isNaN(key) || e.key === null || e.key === " ")
													//   e.preventDefault();
												}}
												onBlur={this.onBlur}
												onChange={(e) => {
													let deadLineDate = new Date(
														this.context.deadlineForEntries
													);

													if (
														deadLineDate.toDateString() !==
															new Date().toDateString() &&
														moment(this.context.deadlineForEntries).isBefore(
															new Date()
														) &&
														this.context.userRole === 'CU'
													) {
														e.preventDefault();
													} else {
														let key = Number(e.target.value);
														if (isNaN(key) || e.key === null || e.key === ' ')
															return;
														this.onGuestChange({ U6: e.target.value });
													}
												}}
												autoComplete="off"
											/>
											{/* <p style={{color:"rgb(149, 150, 155)"}}>{pricing?.[3]?.price ? `€ ${pricing?.[3]?.price}` :null}{pricing?.[3]?.numerically.unit? `/ ${pricing?.[3]?.numerically.unit}` :null }</p> */}
										</div>
										<p
											//  style={styles.inputLabel}
											style={{
												color: '#95969b',
												fontSize: '14px',
												width: this.state.bool === false ? '200px' : '',
											}}>
											{guestLabel
												? this.props.t(guestLabel.U6)
												: 'Anzahl Kleinkinder'}
											{/* {guestLabel? guestLabel.U6 : "Anzahl Kleinkinder"} */}
											{/* {translate(guestLabel  ? [guestLabel.U6]: "Anzahl Kleinkinder")} */}
											{pricing?.[3]?.price ? ' (' : null}
											{pricing?.[3]?.price
												? this.context.languageFormat === 'en'
													? Number(pricing?.[3]?.price)
															.toFixed(2)
															.replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0.0
													: Number(pricing?.[3]?.price)
															.toFixed(2)
															.replace('.', ',')
															.replace(/\B(?=(\d{3})+(?!\d))/g, '.') ?? 0.0
												: null}
											{/* {pricing?.[3]?.numerically.unit
                        ? ` ${this.context.currencyFormat} ${pricing?.[3]?.numerically.unit}`
                        : null} */}{' '}
											{this.context.currencyFormat}
											{pricing?.[3]?.price ? ')' : null}
										</p>
									</div>
								)}
							</div>
						</div>
					</form>
				</React.Fragment>
			);
		}

		let theme = themes?.find((theme) => theme?.id === activeTheme?.id);
		const { services } =
			theme && Object.keys(theme)?.length && theme;
		const textFontSize = this.context.stylingData?.textFontSize
			? this.context.stylingData?.textFontSize
			: this.props.location.state.presTextSize;
		return (
			<React.Fragment>
				<div className="stepsContainer">
					{services?.length ? (
						services.map((service, index) => {
							return (
								<Step
									key={index}
									selected={service.id === activeService.id}
									name={service.name}
									type={
										services.length === 1
											? undefined
											: index === 0
											? 'start'
											: index === services.length - 1
											? 'end'
											: 'intermediate'
									}
									handleDisplay={() => {
										this.selectService(service, 'circleClick');
									}}
								/>
							);
						})
					) : (
						<></>
					)}
				</div>
				{
					<>
						<ToastContainer
							position="bottom-center"
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
						{questFetchStatus && (
							<div
								style={{
									...styles.loaderCheckbox,
									position:
										this.state.disableBackground === false ? 'absolute' : '',
									// marginLeft:
									//   this.state.disableBackground == false &&
									//   (this.state.bool == false ? "390px" : "600px"),
								}}
								className={
									this.state.disableBackground === true ? 'parentDisable' : ''
								}>
								<Spinner
									style={
										this.context.stylingData?.['lineColor']
											? {
													color: this.context.stylingData?.lineColor
														? this.context.stylingData?.lineColor
														: this.props.location.state.presAccentColor,
											  }
											: { color: 'success' }
									}
								/>
							</div>
						)}
						<div
							className="layout-row-container"
							style={{ fontSize: `${textFontSize}px`, marginTop: '50px' }}>
							{this.state.questionnaire?.question?.map((item, index) => (
								<div
									key={index}
									className={`layout-box-container ${
										item.orientation === 'bottom' && 'space-top'
									} ${
										item.orientation === 'top' ? 'space-bottom' : 'space-bottom'
									}`}>
									{item.row.map((_item, _index) => (
										<div
											key={_index}
											className="layout-box-vertical-preview"
											style={{
												width:
													this.state.bool === false
														? `calc(${(100 / item.quantity) * _item.span}% - ${
																((item.quantity - _item.span) * 30) /
																item.quantity
														  }px)`
														: item.quantity === 1 && this.state.bool2 === false
														? '500px'
														: '100%',
											}}>
											{_item?.column.map((__item, __index) => (
												<div
													key={__index}
													className="layout-box"
													style={{
														height: `calc(${
															(100 / _item.quantity) * __item.span
														}% - ${
															((_item.quantity - __item.span) * 30) /
															_item.quantity
														}px)`,
													}}>
													<div
														className="option-container"
														style={{
															marginRight: '20px',
															marginLeft: '20px',
															color: `${headingColor}`,
														}}>
														{this.renderOption(__item.option, {
															row: index,
															column: _index,
															ele: __index,
														})}
													</div>
												</div>
											))}
										</div>
									))}
								</div>
							))}
						</div>
					</>
				}
			</React.Fragment>
		);
	};

	onBackPress = async () => {
		this.setState({ backPressed: true, questFetchStatus: 'Loading' });
		const { activeTheme, activeService, themes } = this.state;
		// const sIndex = activeTheme.services.findIndex(
		//   (service) => service.id === activeService.id
		// );
		const tIndex = themes.findIndex((theme) => theme.id === activeTheme.id);
		const sIndex = themes[tIndex].services.findIndex(
			(service) => service.id === activeService.id
		);
		if (tIndex === 0) {
			this.props.history.goBack();
			return;
		}

		if (sIndex > 0) {
			await this.selectService(themes[tIndex].services[sIndex - 1]);
			return;
		}

		if (sIndex === 0) {
			await this.selectTheme(themes[tIndex - 1]);
		}
	};

	saveQuestionnaire = async () => {
		const {
			activeTheme,
			activeService,
			guestInfo,
			questionnaire,
			guestPriceObj,
		} = this.state;

		const { loggedInUserId, questionnaireId } = this.context;
		this.setState({ questFetchStatus: 'Loading', disableBackground: false });

		const obj = Object.values(guestPriceObj);
		// console.log("obj",obj)

		const error = JSON.parse(sessionStorage.getItem('error'));
		const errorMessage = JSON.parse(sessionStorage.getItem('errorMessage'));
		const guestError = JSON.parse(sessionStorage.getItem('guestErrors'));
		const guestErrorMessage = JSON.parse(
			sessionStorage.getItem('guestErrorMessage')
		);

		errorMessage &&
			errorMessage.forEach((error) => {
				// console.log("error",typeof error)
				if (typeof error == 'string' && error !== '') {
					toast.error(`${error}`, {
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}
			});
		guestErrorMessage &&
			guestErrorMessage.forEach((error) => {
				if (typeof error == 'string' && error !== '') {
					toast.error(`${error}`, {
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}
			});
		obj.map((obj) => {
			if (typeof obj == 'string') {
				return true;
			} else {
				return false;
			}
		});

		if (activeTheme.id === -1) {
			let result;
			if (!guestError) {
				result = await HttpService.putGuests(questionnaireId, guestInfo);
				if (result) {
					this.setState({ guestAPIExecuted: true }, () => {
					});
				}
			}
			if (result?.status === true) {
				this.setState({ questFetchStatus: undefined, allowPricingApi: true });

				this.setState({ uploadPriceStatus: true });

				const uploadPrice = await HttpService.uploadPrice(
					this.context.questionnaireId
				);
				// console.log("uploadPrice",uploadPrice)
				if (uploadPrice.status === 'success') {
					this.setState({ uploadPriceStatus: false });
				}
				const themePrice = await HttpService.themePriceUpdate(
					this.context.questionnaireId
				);
				const { eventId, questionnaireId } = this.context;

				if (themePrice.status === 'success') {
					let response = await HttpService.getSummary(eventId, questionnaireId);

					this.setState((prevState) => ({
						totalAmount: response?.totalAmount ?? prevState.totalAmount,
					}));
					this.setState({ allowPricingApi: false });
					swal(`${this.props.t('Gespeichert!')}`, '', 'success');
				}
				// }
			}
			let count = 0;
			if (guestInfo?.guests) {
				Object.keys(guestInfo?.guests).forEach((key) => {
					if (guestInfo.fields[key] === true) {
						count += Number(guestInfo?.guests[key]);
					}
				});
			}
			// console.log('hi', count)
			this.setState({
				guestCount: count,
			});
			return;
		}

		// if (!this.valid) return swal(`Please complete the questionnaire`, "", "info");
		let keys = Object.keys(this.files);
		if (keys.length > 0) {
			for (let {} of keys) {
			}
		}

		let response;

		delete questionnaire._id;
		// console.log("questionnaire",questionnaire)
		if (error === false || !error) {
			response = await HttpService.uploadAnswer(
				questionnaireId,
				activeTheme.id,
				activeService.id,
				loggedInUserId,
				questionnaire
			);
			if (response) {
				let questionnaire = await HttpService.getForms(
					questionnaireId,
					activeTheme.id,
					activeService.id
				);
				if (questionnaire) {
					this.setState({
						questFetchStatus: undefined,
					});

					console.log('m here', questionnaire);
				}
			}
		}

		this.setState({ uploadPriceStatus: true });

		const themePrice = await HttpService.themePriceUpdate(
			this.context.questionnaireId
		);
		if (response?.status === true && themePrice.status === 'success') {
			let themes = (await HttpService.getThemes(this.context.questionnaireId))
				?.themeApiObject;

			let latestPrice = await HttpService.getPriceBarLatestData(
				questionnaireId
			);
			const uploadPrice = await HttpService.uploadPrice(
				this.context.questionnaireId
			);

			if (uploadPrice.status === 'success') {
				this.setState({ uploadPriceStatus: false });
			}
			let totalAmount = await HttpService.fetchTotalAmount(questionnaireId);
			themes = themes?.themes ?? [];
			for (const theme of themes)
				theme.services = theme?.services?.filter((service) => service?.display);
			themes = themes.filter((theme) => theme?.services?.length > 0);
			themes = [{ id: -1, name: 'Anzahl Gäste', services: [] }, ...themes];
			this.setState({ themes, latestPrice });
			if (Object.keys(activeService).length !== 0) {
				this.setState({ isSaved: true });
			}

			this.setState({
				themes,
				latestPrice,
				totalAmount,
			});
			swal(`${this.props.t('Gespeichert!')}`, '', 'success');
		}
	};

	saveQuestionnaireWithoutSavePopUp = async () => {
		this.setState({
			questFetchStatus: 'Loading',
			uploadPriceStatus: true,
			disableBackground: false,
		});

		const {
			activeTheme,
			activeService,
			guestInfo,
			questionnaire,
			guestPriceObj,
		} = this.state;
		const { questionnaireId, loggedInUserId } = this.context;
		const obj = Object.values(guestPriceObj);

		const error = JSON.parse(sessionStorage.getItem('error'));
		const errorMessage = JSON.parse(sessionStorage.getItem('errorMessage'));
		const guestError = JSON.parse(sessionStorage.getItem('guestErrors'));
		const guestErrorMessage = JSON.parse(
			sessionStorage.getItem('guestErrorMessage')
		);

		errorMessage &&
			errorMessage.forEach((error) => {
				if (error !== '') {
					toast.error(`${error}`, {
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}
			});
		guestErrorMessage &&
			guestErrorMessage.forEach((error) => {
				if (error !== '') {
					toast.error(`${error}`, {
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}
			});
		obj.map((obj) => {
			if (typeof obj == 'string') {
				return true;
			} else {
				return false;
			}
		});
		if (activeTheme.id === -1) {
			let result;

			if (guestError === false || !guestError) {
				result = await HttpService.putGuests(questionnaireId, guestInfo);
				if (result) {
					let uploadPrice = await HttpService.uploadPrice(
						this.context.questionnaireId
					);
					if (uploadPrice.status === 'success') {
						this.setState({ uploadPriceStatus: false });
					}
				}
			}

			let count = 0;
			if (guestInfo?.guests) {
				Object.keys(guestInfo?.guests).forEach((key) => {
					if (guestInfo.fields[key] === true) {
						count += Number(guestInfo?.guests[key]);
					}
				});
			}

			this.setState({
				guestCount: count,
				uploadPriceStatus: false,
			});
			return;
		}

		let keys = Object.keys(this.files);
		if (keys.length > 0) {
			for (let {} of keys) {
			}
		}

		delete questionnaire._id;

		let latestPrice;
		if (error === false || !error) {
			await HttpService.uploadAnswer(
				questionnaireId,
				activeTheme.id,
				activeService.id,
				loggedInUserId,
				questionnaire
			);
		}

		if (Object.keys(activeService).length !== 0) {
			this.setState({
				isSaved: true,
				fromSaveQuestionnaire: true,
				latestPrice: latestPrice ? latestPrice : this.state.latestPrice,
				uploadPriceStatus: false,
			});
		}
	};
	onBlurButton = () => {
		this.setState({ weiterPressed: false });
	};

	saveQuestionnaireAndProceed = async () => {
		const error = JSON.parse(sessionStorage.getItem('error'));
		const guestError = JSON.parse(sessionStorage.getItem('guestErrors'));
		this.setState({
			weiterPressed: true,
			disableBackground: false,
			questFetchStatus: 'Loading',
			allowMultipleOpen: true,
			allowPricingApi: this.state.guestChangeTriggered ? true : false,
		});
		const { activeTheme, activeService, guestPriceObj } = this.state;
		const obj = Object.values(guestPriceObj);

		obj.map((obj) => {
			if (typeof obj == 'string') {
				return true;
			} else {
				return false;
			}
		});

		this.setState({ uploadPriceStatus: true });
		let themes;

		themes = (await HttpService.getThemes(this.context.questionnaireId))
			?.themeApiObject;
		themes = themes?.themes ?? [];
		for (const theme of themes)
			theme.services = theme?.services?.filter((service) => service?.display);
		themes = themes.filter((theme) => theme?.services?.length > 0);
		themes = [{ id: -1, name: 'Anzahl Gäste', services: [] }, ...themes];

		this.setState({ themes });

		await this.saveQuestionnaireWithoutSavePopUp();
		const tIndex = themes?.findIndex((theme) => theme.id === activeTheme.id);
		const sIndex = themes[tIndex].services.findIndex(
			(service) => service.id === activeService.id
		);

		if (activeTheme.id === -1) {
			if (this.state.guestChangeTriggered && this.state.updateCalled) {
				axios
					.post(`${baseUrl}api/themes/${this.context.questionnaireId}`)
					.then(() => {
						this.setState({ allowPricingApi: false });
					})
					.catch((err) => {
						console.log(err);
					});
			}

			if (guestError === false || !guestError) {
				if (tIndex === 0 || sIndex === activeTheme.services.length - 1) {
					if (tIndex === themes.length - 1) {
						this.props.history.push('/EventLanding');
						return;
					}

					let theme = themes[tIndex + 1];
					await this.selectTheme(theme);
					return;
				}
				if (themes[tIndex]) {
					if (sIndex < themes[tIndex].services.length - 1) {
						await this.selectService(
							themes[tIndex].services[sIndex + 1],
							'from save'
						);
					}
				}
			}
			if (this.state.updateCalled === true) {
			}
		} else {
			if (error === false || !error) {
				if (tIndex === 0 || sIndex === themes[tIndex]?.services?.length - 1) {
					if (tIndex === themes?.length - 1) {
						this.props.history.push('/EventLanding');
						return;
					}
					let theme = themes[tIndex + 1];
					await this.selectTheme(theme, 'from save');
					return;
				}

				if (themes[tIndex]) {
					if (sIndex < themes[tIndex].services.length - 1) {
						if (
							Math.sign(Number(sIndex === -1)) &&
							tIndex === themes.length - 1
						) {
							this.props.history.push('/EventLanding');
						} else {
							await this.selectService(
								themes[tIndex].services[sIndex + 1],
								'from save'
							);
						}
					}
				}
			}
		}
	};
	selectTheme = async (theme, circleClick) => {
		if (circleClick === 'circleClick') {
			this.setState({ disableBackground: true });
		}
		const { questionnaireId } = this.context;

		this.setState({
			questFetchStatus: 'Loading',
			uploadPriceStatus: true,
			activeTheme: theme,
			activeService: theme.services?.[0],
			headerImage: theme.services?.[0]?.headerImage
				? theme.services?.[0]?.headerImage
				: '',
		});

		if (theme.id === -1) {
			await HttpService.getGuests(questionnaireId);
			let headerImage;
			const headerImagePromise = HttpService.getHeaderImage(
				questionnaireId
			).then((response) => {
				headerImage = response?.url ? response?.url : response?.headerImage;
			});
			Promise.all([headerImagePromise])
				.then(() => {
					this.setState({
						headerImage,
						backPressed: false,
						questFetchStatus: undefined,
						disableBackground: false,
					});
				})
				.catch((error) => alert(error));
			return;
		} else {
			this.setState({ loader: true });
			const eventPageRoute = this.state.eventPageRoute === 'eventPage' ? 1 : 0;
			axios
				.get(
					baseUrl +
						`api/themes/${questionnaireId}/${theme.id}/${theme?.services?.[0].id}/${eventPageRoute}`
				)
				.then((res) => {
					if (res.data) {
						this.setState({
							headerImage: res.data.base64String
								? res.data.base64String
								: theme.services?.[0]?.headerImage,
							loader: false,
						});
					}
				})
				.catch(() => {});
		}

		let questionnaire = await HttpService.getForms(
			questionnaireId,
			theme.id,
			theme.services?.[0].id
		);
		if (questionnaire) {
			this.setState(
				{
					questionnaire,
				},
				() => {
					this.setState({
						questFetchStatus: undefined,
						disableBackground: false,
					});
				}
			);
		}

		let latestPrice;

		if (latestPrice) {
			this.setState({ uploadPriceStatus: false, latestPrice });
		}
		let totalAmount = await HttpService.fetchTotalAmount(questionnaireId);

		this.setState(() => ({
			totalAmount: totalAmount,
			weiterPressed: false,
			backPressed: false,
		}));
	};

	selectService = async (service, display, bool) => {
		if (display === 'circleClick') {
			this.setState({ disableBackground: true });
		}
		const { questionnaireId } = this.context;
		this.setState({ loader: true });
		const { activeTheme } = this.state;

		this.setState({
			questFetchStatus: 'Loading',
			activeService: service,
			position: service.position ? service.position : { x: 0.5, y: 0.5 },
			scale: service.scale ? service?.scale : 1,
			bool3: false,
		});
		const eventPageRoute = this.state.eventPageRoute === 'eventPage' ? 1 : 0;
		axios
			.get(
				baseUrl +
					`api/themes/${questionnaireId}/${activeTheme.id}/${service.id}/${eventPageRoute}`
			)
			.then((res) => {
				if (res.data) {
					this.setState({
						headerImage: res.data.base64String
							? res.data.base64String
							: service.headerImage,
						loader: false,
					});
				}
			})
			.catch(() => {});

		let questionnaire = await HttpService.getForms(
			questionnaireId,
			activeTheme.id,
			service.id
		);

		if (questionnaire) {
			this.setState(
				{
					questionnaire,
				},
				() => {
					this.setState({
						questFetchStatus: false,
						disableBackground: false,
					});
				}
			);
		}

		let themePrice;

		if (!bool) {
			let themes;
			if (themePrice?.status === 'success' || !bool) {
				themes = (await HttpService.getThemes(this.context.questionnaireId))
					?.themeApiObject;

				themes = themes?.themes ?? [];
				for (let theme of themes)
					theme.services = theme?.services?.filter(
						(service) => service?.display
					);
				themes = themes.filter((theme) => theme?.services?.length > 0);
				themes = [{ id: -1, name: 'Anzahl Gäste', services: [] }, ...themes];
			}
			this.setState({ themes });
		}
		this.setState({ uploadPriceStatus: true });

		if (bool) {
			const uploadPrice = await HttpService.uploadPrice(
				this.context.questionnaireId
			);
			// console.log("uploadPrice",uploadPrice)
			if (uploadPrice.status === 'success') {
				this.setState({ uploadPriceStatus: false });
			}
			themePrice = await HttpService.themePriceUpdate(
				this.context.questionnaireId
			);
		} else {
			this.setState({ uploadPriceStatus: false });
		}

		this.setState(() => ({
			position: service?.position ? service.position : { x: 0.5, y: 0.5 },
			scale: service?.scale ? service.scale : 1,
			weiterPressed: false,
			backPressed: false,
		}));
	};

	logout = () => {
		const { urlName } = this.context;
		this.context.updateState({
			authenticated: false,
		});
		localStorage.clear();
		sessionStorage.clear();
		this.props.history.push(
			`/login/${urlName !== 'adminScreen' ? urlName : ''}`
		);
	};

	render() {
		const {
			activeTheme,
			activeService,
			loading,
			questionnaire,
			themes,
			couple,
			vvo,
			totalAmount,
			questFetchStatus,
		} = this.state;

		const textFontFamily = this.context.stylingData?.textFontFamily
			? this.context.stylingData?.textFontFamily
			: this.props.location.state.presTextFont;
		const textFontSize = this.context.stylingData?.textFontSize
			? this.context.stylingData?.textFontSize
			: this.props.location.state.presTextSize;

		if (loading) {
			return <Text value={this.props.t('Loading')} />;
		}

		return (
			<div className="d-flex" style={{ minHeight: '100vh' }}>
				<div
					className="col-2 pl-0 pr-0 themesWrapper"
					style={{ height: '100vh', position: 'sticky', top: '0' }}>
					<OverlayTrigger
						// key="bottom"
						placement="bottom"
						overlay={
							<Tooltip id={`tooltip-disabled`}>
								<span>{this.props.t('logout')}</span>
							</Tooltip>
						}>
						<button className="logout-btn border-0 m-2" onClick={this.logout}>
							<img src={logoutIcon} alt="" />
						</button>
					</OverlayTrigger>

					<OverlayTrigger
						// key="bottom"
						placement="bottom"
						overlay={
							<Tooltip id={`tooltip-bottom`}>
								<span>{this.props.t('home')}</span>
							</Tooltip>
						}>
						<button
							className="home-btn"
							onClick={() => {
								this.context.userRole !== 'CU'
									? this.props.history.push('/admin/events')
									: this.props.history.push('/login-landing');
							}}
							style={{ border: 'none' }}>
							<img
								style={{ width: '20px', height: '25px' }}
								src={home}
								alt=""
							/>
						</button>
					</OverlayTrigger>

					<div className="main-company-logo d-flex align-items-center justify-content-center">
						<img
							src={`${
								this.context.stylingData?.questionnaireLogoSrc
									? this.context.stylingData?.questionnaireLogoSrc
									: ''
							}`}
							alt=""
							style={{
								width: '100%',
								height: 'auto',
								maxHeight: '100px',
								objectFit: 'contain',
								imageRendering: 'high-quality',
							}}
						/>
						{/* <img style={styles.logo} src={this.context.venueLogo} alt="" /> */}
					</div>
					<div className="pl-2 pt-5">
						<div
							className="scrollbar"
							style={{
								maxHeight: '80vh',
								overflowY: 'auto',
							}}>
								<Accordion
									allowMultipleOpen={false}
									data={themes}
									activeTheme={activeTheme}
									onThemeSelect={this.selectTheme}
									onServiceSelect={this.selectService}
									bool3={this.state.bool3}
									defaultSettingStyle={this.state.defaultSettingStyle}
								/>
						</div>
					</div>
				</div>
				<div className="rightbarpreview">
					<RightBarPreview
						bool3={this.state.bool3}
						activeService={activeService}
						questionnaire={questionnaire}
						logout={this.logout}
						//  couple={couple}
						//  vvo={vvo}
						onValidate={(valid) => (this.valid = valid)}
						history={this.props.history}
						loading={questFetchStatus}
						open={false}
						themes={themes}
						activeTheme={activeTheme}
						themeSelect={this.selectTheme}
						serviceSelect={this.selectService}
					/>
				</div>
				<div
					className="content pl-0 pr-0"
					style={{
						background: '#f0eff0',
						position: 'relative',
						fontFamily: `${textFontFamily}`,
						fontSize: `${textFontSize}px`,
					}}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							minHeight: '100vh',
						}}>
						<div>
							{this.state.loader === true && (
								<span>{this.props.t('Loading')}</span>
							)}
							{this.state.headerImage ? (
								<div
									style={{
										backgroundImage: `url(${this.state.headerImage})`,
										backgroundRepeat: 'no-repeat',
										//  backgroundSize: "100% 100%",
										backgroundSize: 'cover',
										backgroundPosition: 'center center',
										//  width: "100%",
										width: '100%',
										height: '175px',
									}}></div>
							) : null}
							<div className="contentWrapper">{this.renderContent()}</div>
						</div>
						<div
							className=" justify-content-center mb-3 button-alignment"
							style={{ paddingBottom: '30px' }}>
							<button
								className={[
									`outline-button ${
										this.state.backPressed ? 'solidButton' : 'outlineButton'
									}`,
								]}
								style={
									this.state.backPressed
										? {
												background: this.context.stylingData?.lineColor
													? `${this.context.stylingData.lineColor}`
													: `${this.props.location.state.presAccentColor}`,
										  }
										: {
												border: this.context.stylingData?.lineColor
													? `1px solid ${this.context.stylingData.lineColor}`
													: `1px solid ${this.props.location.state.presAccentColor}`,
												color: this.context.stylingData?.lineColor
													? `${this.context.stylingData.lineColor}`
													: `${this.props.location.state.presAccentColor}`,
										  }
								}
								// style={this.state.backPressed ? styles.solid : styles.outline}
								onClick={this.onBackPress}>
								{this.props.t('return')}
							</button>
							<button
								className="outline-button speichern outlineButton"
								style={{
									border: this.context.stylingData?.lineColor
										? `1px solid ${this.context.stylingData.lineColor}`
										: `1px solid ${this.props.location.state.presAccentColor}`,
									color: this.context.stylingData?.lineColor
										? `${this.context.stylingData.lineColor}`
										: `${this.props.location.state.presAccentColor}`,
								}}
								onClick={this.saveQuestionnaire}>
								{this.props.t('save')}
							</button>
							<button
								onBlur={this.onBlurButton}
								className={[
									`outline-button speichern ${
										this.state.weiterPressed ? 'solidButton' : 'outlineButton'
									}`,
								]}
								style={
									this.state.weiterPressed
										? {
												background: this.context.stylingData?.lineColor
													? `${this.context.stylingData.lineColor}`
													: `${this.props.location.state.presAccentColor}`,
										  }
										: {
												border: this.context.stylingData?.lineColor
													? `1px solid ${this.context.stylingData.lineColor}`
													: `1px solid ${this.props.location.state.presAccentColor}`,
												color: this.context.stylingData?.lineColor
													? `${this.context.stylingData.lineColor}`
													: `${this.props.location.state.presAccentColor}`,
										  }
								}
								// style={this.state.weiterPressed ? styles.solid : styles.outline}
								onClick={this.saveQuestionnaireAndProceed}>
								{this.props.t('weiter')}
								{/* speichern und weiter */}
							</button>
						</div>
					</div>
				</div>
				<div className="pullPriceBar">
					<PullPriceBar
						uploadPriceStatus={this.state.uploadPriceStatus}
						guestChangeTriggered={this.state.guestChangeTriggered}
						allowPricingApi={this.state.allowPricingApi}
						updatePriceState={this.updatePriceState}
						activeTheme={activeTheme}
						activeService={activeService}
						questionnaire={questionnaire}
						couple={couple}
						vvo={vvo}
						onValidate={(valid) => (this.valid = valid)}
						history={this.props.history}
						loading={questFetchStatus}
						totalAmount={totalAmount}
						guestPriceObj={this.state.guestPriceObj}
						latestPrice={this.state.latestPrice}
					/>
				</div>
				<div
					className="col-2 pl-0 priceWrapperPricebar"
					style={styles.priceWrapper}>
					{/* {console.log("latestPrice",this.state.latestPrice)} */}
					<PriceBar
						allowPricingApi={this.state.allowPricingApi}
						guestChangeTriggered={this.state.guestChangeTriggered}
						uploadPriceStatus={this.state.uploadPriceStatus}
						themes={this.state.themes}
						activeTheme={activeTheme}
						activeService={activeService}
						questionnaire={questionnaire}
						updatePriceState={this.updatePriceState}
						couple={couple}
						vvo={vvo}
						onValidate={(valid) => (this.valid = valid)}
						history={this.props.history}
						loading={questFetchStatus}
						totalAmount={totalAmount}
						guestPriceObj={this.state.guestPriceObj}
						latestPrice={this.state.latestPrice}
						setErrorInPricebar={this.state.setErrorInPricebar}
						defaultSettingStyle={this.props.location.state}
					/>
				</div>
			</div>
		);
	}
}

export default withTranslation()(QuestionPreview);
const styles = {
	outline: {
		color: '#297825',
		fontFamily: 'Crete Round',
		fontSize: '13px',
		border: '1px solid #297825',
		// minWidth: "143px",
		padding: '9px 15px',
		textAlign: 'center',
		display: 'inline-block',
		marginRight: '15px',
	},
	solid: {
		background: '#297825',
		fontFamily: 'Crete Round',
		border: '1px solid #297825',
		color: '#fff',
		fontSize: '13px',
		// minWidth: "143px",
		padding: '9px 15px',
		textAlign: 'center',
		display: 'inline-block',
		marginRight: '15px',
	},
	stepsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	headerImage: {
		width: '100%',
		height: '175px',
		objectFit: 'fill',
	},
	input: {
		border: 0,
		padding: 0,
		margin: 0,
		background: 'transparent',
		borderBottom: '1px solid #707070',
		// width: "200px",
	},
	inputLabel: {
		// fontFamily: "Crete Round",
		// fontStyle: "Italic",
		color: '#95969b',
		fontSize: '14px',
	},
	// inputWrapper: {
	//   display: "inline-block",
	//   marginRight: "50px",
	//   width: "40%",
	// },
	flexRow: {
		flexDirection: 'row',
		width: '70%',
		// marginLeft:"auto",
		// marginRight:"auto",
		// display:"block"
	},
	priceWrapper: {
		position: 'fixed',
		right: '0',
		top: '0',
		// display: "block",
		background: '#e6e7e8',
		minHeight: '100vh',
		transition: '0.3s',
		zIndex: '30',
	},

	loader: {
		color: '#888888',
		fontWeight: 'bold',
		fontSize: '20px',
		padding: '20px',
		textAlign: 'center',
		position: 'fixed',
	},
	loaderCheckbox: {
		color: '#888888',
		fontWeight: 'bold',
		fontSize: '20px',
		padding: '20px',
		textAlign: 'center',
		position: 'fixed',
		pointerEvents: 'none !important',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		top: '0',
		left: '0',
		opacity: '0.7',
		backgroundColor: '#ccc',
		zIndex: '99',
		marginTop: '0 !important',
		marginLeft: '0 !important',
	},
	logo: { width: '150px', height: '150px', objectFit: 'contain' },
};

import React, {Component} from "react";
import calendar from "../../../assets/calendar.svg";
import DatePicker from "react-datepicker";
import { Portal } from 'react-overlays'
import "react-datepicker/dist/react-datepicker.css";
import {StateContext} from "../../../context/state";
import moment from "moment-timezone"
import { registerLocale } from  "react-datepicker";
import {localeGerman} from '../../../Constants'
import Text from "../Text";
import { withTranslation} from 'react-i18next';

registerLocale('de', localeGerman)

class DateTime extends Component {

    static contextType = StateContext;
    showDate = true;
    showTime = true;
    currentDate = new Date().toDateString();

    constructor(props) {
        super(props);

        const {option} = this.props;

        this.showDate = option.datumInput?.hasDatum ?? true;
        this.showTime = option.datumInput?.hasTime ?? true;
    }
    CalendarContainer = ({children}) => {
        const el = document.getElementById('calendar-portal')
      
        return (
          <Portal container={el}>
            {children}
          </Portal>
        )
      }

    onDateSelect = (date) => {

   
        let deadLineDate = new Date(this.context.deadlineForEntries);
   
        if (
          deadLineDate.toDateString() !== new Date().toDateString() &&
          moment(this.context.deadlineForEntries).isBefore(new Date()) &&
          this.context.userRole === "CU"
        ){         
         return false
        }
        else{
            const {option, onUpdate} = this.props;

            const time = option.answer?.time;
            date = date?.toDateString();
            option.answer = {time, date};
    
            onUpdate(option);
        }
       
    };

    onTimeSelect = (time) => {
   
        let deadLineDate = new Date(this.context.deadlineForEntries);
   
        if (
          deadLineDate.toDateString() !== new Date().toDateString() &&
          moment(this.context.deadlineForEntries).isBefore(new Date()) &&
          this.context.userRole === "CU"
        ){
         
         return false
        }
        else{
             const {option, onUpdate} = this.props;

        const date = option.answer?.date;
        time = time?.toLocaleTimeString();
        option.answer = {time, date};

        onUpdate(option); 
        }
      
    };

    render() {

        const {option: {answer}} = this.props;
        const headingFontFamily=this.context.stylingData?.headingFontFamily
       const textFontSize=this.context.stylingData?.textFontSize
       const headingFontSize = this.context.stylingData?.headingFontSize;
        const date = answer?.date ? new Date(answer?.date) : undefined;
        const time = answer?.time ? new Date(`${this.currentDate} ${answer?.time}`) : undefined;
        const question=this.props.option.datumInput.question ? this.props.option.datumInput.question:"";
        // const headingColor =this.context.stylingData?.["headingColor"] ? this.context.stylingData?.headingColor : "#939598";
        const headingColor = this.context.stylingData?.textColor ? this.context.stylingData?.textColor :"#939598";

        const dummyPlaceholder=this.context?.dateFormat ? this.context?.dateFormat.replace("dd","TT").replace("yy","JJ"):"JJJJ-MM-TT"
// console.log("answer",this.props.option)
        return (
            <React.Fragment>
                 <Text
          value={question}
          style={{
            fontFamily: `${headingFontFamily}`,
            fontSize: `${headingFontSize}px`,
            color:`${headingColor}`,
            padding:"0",
            paddingTop:"10px",
            paddingBottom:"10px"
          }}
        />
                {this.showDate && <div style={styles.container}>
               
                    <div style={{fontSize:`${textFontSize}px`,  color: "#e6e7e8",
        width: "150px",
        borderBottom: "1px solid #707070",
        // fontSize: "15px",
        background: `url("${calendar}") no-repeat right 0 top 3px`,}}>
                        <DatePicker
                        locale="de"
                        popperContainer={this.CalendarContainer}
                            selected={date}
                            onChange={this.onDateSelect}
                            dateFormat={this.context?.dateFormat ?? "yyyy-MM-dd"}
                            placeholderText={dummyPlaceholder}
                            className="date-time-input"
                            onKeyDown={e => {
                                const key = e.key;
                                if (key !== "Backspace") {
                                    e.preventDefault()
                                }
                            }}
                        />
                    </div>
                    
                    <span style={{  color: "#939598", fontSize:`${textFontSize}px`
      }}>{this.props.t("Datum")}</span>
                </div>}

                {this.showTime && <div style={styles.container}>
                    
                    <div style={{...styles.value, ...styles.time ,fontSize:`${textFontSize}`}}>
                        <DatePicker
                       popperContainer={this.CalendarContainer}
                            selected={time}
                            onChange={this.onTimeSelect}
                            showTimeSelect
                            showTimeSelectOnly
                            placeholderText="00:00"
                            className="date-time-input"
                            timeIntervals={15}
                            timeCaption="Zeit"
                            timeFormat={this.context?.timeFormat === 24 ? "HH:mm" : "hh:mm aa" ?? "hh:mm aa"}
                            dateFormat={this.context?.timeFormat === 24 ? "HH:mm" : "hh:mm aa" ?? "hh:mm aa"}
                        />
                    </div>
                    <span style={{  color: "#939598",fontSize:`${textFontSize}px`
       }}>{this.props.t("Uhrzeit")}</span>
                </div>}
            </React.Fragment>
        );
    }
}

export default withTranslation() (DateTime);
const styles = {
    container: {
        textAlign: "left",
        marginTop: "15px",
        // marginLeft: "15px",
    },
    value: {
        color: "#e6e7e8",
        width: "150px",
        borderBottom: "1px solid #707070",
        // fontSize: "15px",
        background: `url("${calendar}") no-repeat right 0 top 3px`,
    },
    input: {
        border: 0,
        padding: 0,
        margin: 0,
        background: "transparent"
    },
    time: {
        background: "none",
    },
    label: {
        // fontFamily: "Crete Round",
        color: "#939598",
        // fontStyle: "italic",
        // fontSize: "15px",
    },
   

};

import React, { Component } from "react";
import { StateContext } from "../../context/state";
import axios from "axios";
import { baseUrl } from "../../Constants.js";
import TextOption from "./text";
import DatumOption from "./datum";
import RadioOption2 from "./radio2";
import ImageOption from "./image";
import BildOption from "./bild";
import TableOption from "./table";
import UploadOption from "./upload";
import DownloadOption from "./download";
import RadioMultipleOption from "./radioMultipleOption";

class RadioOption extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      newData: {},
      newData2:{},
      data: {},
      bool: false,
    };
  }
  componentDidMount() {
    this.setState({
      newData: this.props.data,
      newData2:this.props.data2
    });
    const option = this.props.option;
    axios
      .get(
        `${baseUrl}api/export/ServiceElement/${this.context.childQuestionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`
      )
      .then((res) => {
        const d = res.data;
        if (d?.imageInput?.inputs) {
          d.imageInput.inputs.forEach((item) => {
            if (item.image) {
              const image = {
                url: item.image,
              };
              this.props.updateAttachments(image);
            }
          });
        }
        this.setState({
          data: { ...d },
        });
      })
      .catch((err) => {
        console.log(err);
      });
      
  }
  renderOption = (option) => {
    switch (option.optionType) {
      case "text":
        return <TextOption option={option} />;
      case "datum":
        return <DatumOption option={option} />;
      case "bild":
        return <BildOption option={option} />;
      case "radio":
        return <RadioOption2 option={option} />;
      case "table":
        return <TableOption option={option} />;
      case "upload":
        return <UploadOption option={option} />;
      case "download":
        return <DownloadOption option={option} />;
      case "image":
        return <ImageOption option={option} />;
      case "checkbox":
        return <RadioMultipleOption option={option} />;
      default:
        break;
    }
  };
  display = (option) => {
    let boolean;
    let boolean2;
    let ans;
    if (this.state.data?.answer) {
      ans = this.state.data?.answer;
    }

    if (this.props.data2?.selectedOptions?.length) {
      boolean = this.props.data2?.selectedOptions?.filter(
        (data) => data?.id === option?.id
      );

      boolean2 = this.props.data2?.selectedOptions?.filter(
        (data) => data?.id === ans?.id
      );

      if (boolean && boolean2.length !== 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  render() {
    let bool;
    let ans;
// console.log("data",this.state.data);
// console.log("data2",this.props.data2);
    if (this.state.data?.answer) {
      ans = this.state.data?.answer;
    }
    let show=false
    if(this.props.data2?.radioInput?.questionSelected){
      show=true
    }
    else if(this.state.data?.radioInput?.questionSelected){
      show=true
    }
    // console.log("newdata",this.state.newData)
    //!this.state.newData.questionSelected  &&
    return (
      <div>
        {this.state.data.isDisplay!=="false" ?
        
      <>
        <div className="qtn_optns2">
         
          {ans !== undefined &&
            Object.keys(ans).length !== 0 &&
            (!show) &&
            this.state.data?.radioInput?.inputs && (
              <>
             
                {this.state.data?.radioInput.inputs.map((option, i) => {
                  return (bool = this.display(option) === false ? false : true);
                })}
                {/* {console.log("alternate ",this.state.newData )} */}
                {bool === false ? (
                  <div
                  // className="quest1"
                  // style={{marginTop: this.state.newData?.radioInput?.questionSelected ? "":"10px"}}
                  style={{marginTop:"10px"}}
                  >
                    {this.props.data2?.radioInput?.qtnAlternate ? (
                      <div><b>{this.props.data2?.radioInput?.qtnAlternate}</b></div>
                    ) : (
                      <div><b>{this.state.data?.radioInput?.question}</b></div>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
        </div>

        <div
         
        >
          {this.state.data?.radioInput?.inputs &&
            this.state.data?.radioInput.inputs.map((option, i) => {
              return (
                <div 
                // className={bool==false ? "qtn_optns":"qtn_optns2" }
                // style={{
                //   marginBottom:
                //   bool==false
                //       ? "10"
                //       : "",
                // }}
                className="qtn_optns2"
                //   style={{
                //   marginBottom:"10px"
                 
                // }}
                key={i}>
                 
                  {this.display(option)}
                  {this.display(option) === false ? (
                    <>
                      {ans === undefined || Object.keys(ans).length === 0 ? (
                        <></>
                      ) : // <span style={styles.circle} />
                      option?.id === ans?.id ? (
                        <div  
                           style={{
                            marginBottom:"10px"
                           
                          }}>
                        <span style={styles.filledCircle} />
                        </div>
                      ) : (
                        <></>
                        // <span style={styles.circle} />
                      )}

                      {ans === undefined || Object.keys(ans).length === 0 ? (
                     
                        <></>
                      ) : option?.id === ans?.id ? (
                        (
                          (Object.keys(this.state.data).length!==0 &&
                       (this.props.data2?.radioInput?.inputs[i]!==undefined &&
                        this.props.data2?.radioInput?.inputs[i].alternate)
                        )
                        )?  (
                          <div
                            // className="optn"
                            style={{ padding: "2px 4px" , marginBottom:"10px"}}
                          >
                            
                            {this.props.data2?.radioInput?.inputs[i]?.alternate} &nbsp; &nbsp;{" "}
                            {ans?.quantity !== "0" && ans?.quantity} &nbsp;
                            &nbsp; {ans?.quantity2 !== "0" && ans?.quantity2}
                          </div>
                        ) :  ((Object.keys(this.state.data)).length!==0 &&
                        this.props.data2?.radioInput?.inputs[i]?.alternate==="")?
                        
                         ( <div
                            // className="optn"
                            style={{ padding: "2px 4px",  marginBottom:"10px" }}
                          >

                           
                            {option?.value} &nbsp; &nbsp;
                            {ans?.quantity !== "0" && ans?.quantity} &nbsp;
                            &nbsp; {ans?.quantity2 !== "0" && ans?.quantity2}
                          </div>):
                        ( <div
                          // className="optn"
                          style={{ padding: "2px 4px",  marginBottom:"10px" }}
                        >
                      
                          {option?.value} &nbsp; &nbsp;
                          {ans?.quantity !== "0" && ans?.quantity} &nbsp;
                          &nbsp; {ans?.quantity2 !== "0" && ans?.quantity2}
                        </div>
                        ))
                      : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
        </div>
      </>:<></>}
      </div>
    );
  }
}

export default RadioOption;
const styles = {
  circle: {
    marginTop: "7px",
    width: "16px",
    height: "16px",
    display: "inline-block",
    textAlign: "center",
    background: "transparent",
    borderRadius: "50%",
    border: "1px solid #939598",
    outline: "none",
  },
  filledCircle: {
    marginTop: "7px",
    width: "16px",
    height: "16px",
    display: "inline-block",
    textAlign: "center",
    background: "transparent",
    borderRadius: "50%",
    backgroundColor: "#939598",
    border: "1px solid #939598",
    outline: "none",
  },
};

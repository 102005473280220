import React, { Component } from "react";
import ConfiguratorTemplateSelection from "../partials/ConfiguratorTemplateSelection";
import ConfiguratorTemplate12 from "../templates/ConfiguratorTemplate12";
import { StateContext } from "../../context/state";
import { convertToRaw } from "draft-js";
import closeIcon from "../../assets/cross.svg";
import HttpService from "../../services/HttpService";
import styled from "styled-components";
import swal from "sweetalert";
// import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from "draft-js";
import { v4 as uuidv4 } from "uuid";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation} from 'react-i18next';
const MainContainer = styled.div`
  height: auto;
  width: 100%;
  .scroll-div {
    position: sticky;
    z-index: 500;
    top: -10px;
    width:10%;
    height: auto;
    padding-bottom: 10px;
   }
  `;
const StickyDiv = styled.div`
  position:fixed;
  width:10%;
  height:36px;
  left:1325px;
  z-index: 1000;
  @media only screen and (max-width: 728px) and ( max-width:1024px){
    left:525px;
  }
`;
class ConfiguratorTemplateSelectionPopup extends Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.state = {
      scroll:false,
      newEntry: true,
      selectedTemplate: null,
      layoutObject: [
        {
          row: [
            {
              column: [
                {
                  option: {},
                  span: 1,
                  selected: false,
                },
              ],
              quantity: 1,
              span: 1,
              default: true,
            },
          ],
          default: true,
          quantity: 1,
        },
      ],
      serviceLink: {
        type: "service",
        serviceInput: {
          link: [
            {
              optionId:"",
              columnIndex: "",
              linkedOptionId: "",
              linkedOptionValue: "",
              optionType: "",
              questionIndex: "",
              rowIndex: "",
              serviceId: "",
              themeId: "",
              display: "",
            },
          ],
        },
      },
    };
  }

  renderTemplate = (sectionNumber) => {
    switch (sectionNumber) {
      case 1:
        return (
          <ConfiguratorTemplate12
            updateLayoutObject={this.updateLayoutObject}
            layoutObject={[
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "bild",
                          _id:uuidv4()
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    quantity: 1,
                    span: 1,
                    default: true,
                  },
                  {
                    column: [
                      {
                        option: {
                          type: "radio",
                          _id:uuidv4(),
                          radioInput: {
                            multipleSelection:false,
                            question: "",
                            inputs: [
                              {
                                id: 1,
                                value: "",
                                price: "",
                                priceType: "", //flat-rate, number-guests, numerically
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                              {
                                id: 2,
                                value: "",
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 2,
                    quantity: 1,
                    default: true,
                  },
                ],
                default: true,
                quantity: 3,
              },
              {
                row: [
                  {
                    column: [
                      {
                        option: {},
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                ],
                orientation: "bottom",
                quantity: 1,
              },
            ]}
          />
        );
      case 2:
        return (
          <ConfiguratorTemplate12
            updateLayoutObject={this.updateLayoutObject}
            layoutObject={[
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "radio",
                          _id:uuidv4(),
                          radioInput: {
                            question: "",
                            multipleSelection:false,
                            inputs: [
                              {
                                id: 1,
                                value: "",
                                price: "",
                                priceType: "", //flat-rate, number-guests, numerically
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                              {
                                id: 2,
                                value: "",
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    quantity: 1,
                    span: 2,
                    default: true,
                  },
                  {
                    column: [
                      {
                        option: {
                          type: "bild",
                          _id:uuidv4(),
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                    default: true,
                  },
                ],
                default: true,
                quantity: 3,
              },
              {
                row: [
                  {
                    column: [
                      {
                        option: {},
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                ],
                orientation: "bottom",
                quantity: 1,
              },
            ]}
          />
        );
      case 3:
        return (
          <ConfiguratorTemplate12
            updateLayoutObject={this.updateLayoutObject}
            layoutObject={[
              {
                row: [
                  {
                    column: [
                      {
                        option: {},
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                  {
                    column: [
                      {
                        option: {
                          type: "text",
                          _id:uuidv4(),
                          textInput: {
                            inputs: [
                              {
                                id: 1,
                                interactive: false,
                                numeric: false,
                                stages: "",
                                maximum: "",
                                minimum: "",
                                value: EditorState.createEmpty(),
                                interactiveText: "",
                                hideTextToolbar: true,
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                  {
                    column: [
                      {
                        option: {},
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                ],
                orientation: "top",
                quantity: 3,
              },
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "radio",
                          _id:uuidv4(),
                          radioInput: {
                            question: "",
                            multipleSelection:false,
                            inputs: [
                              {
                                id: 1,
                                value: "",
                                price: "",
                                priceType: "", //flat-rate, number-guests, numerically
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                              {
                                id: 2,
                                value: "",
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    quantity: 1,
                    span: 1,
                    default: true,
                  },
                ],
                default: true,
                quantity: 1,
              },
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "text",
                          _id:uuidv4(),
                          textInput: {
                            inputs: [
                              {
                                id: 1,
                                interactive: false,
                                numeric: false,
                                stages: "",
                                maximum: "",
                                minimum: "",
                                value: EditorState.createEmpty(),
                                interactiveText: "",
                                hideTextToolbar: true,
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                ],
                orientation: "bottom",
                quantity: 1,
              },
            ]}
          />
        );
      case 4:
        return (
          <ConfiguratorTemplate12
            updateLayoutObject={this.updateLayoutObject}
            layoutObject={[
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "text",
                          _id:uuidv4(),
                          textInput: {
                            inputs: [
                              {
                                id: 1,
                                interactive: false,
                                numeric: false,
                                stages: "",
                                maximum: "",
                                minimum: "",
                                value: EditorState.createEmpty(),
                                interactiveText: "",
                                hideTextToolbar: true,
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                            boxColor:""
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                  {
                    column: [
                      {
                        option: {},
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                  {
                    column: [
                      {
                        option: {},
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                ],
                orientation: "top",
                quantity: 3,
              },
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "radio",
                          _id:uuidv4(),
                          radioInput: {
                            question: "",
                            multipleSelection:false,
                            inputs: [
                              {
                                id: 1,
                                value: "",
                                price: "",
                                priceType: "", //flat-rate, number-guests, numerically
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                              {
                                id: 2,
                                value: "",
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    quantity: 1,
                    span: 1,
                    default: true,
                  },
                ],
                default: true,
                quantity: 1,
              },
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "text",
                          _id:uuidv4(),
                          textInput: {
                            inputs: [
                              {
                                id: 1,
                                interactive: false,
                                numeric: false,
                                stages: "",
                                maximum: "",
                                minimum: "",
                                value: EditorState.createEmpty(),
                                interactiveText: "",
                                hideTextToolbar: true,
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                            boxColor:""
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                ],
                orientation: "bottom",
                quantity: 1,
              },
            ]}
          />
        );
      case 5:
        return (
          <ConfiguratorTemplate12
            updateLayoutObject={this.updateLayoutObject}
            layoutObject={[
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "image",
                          _id:uuidv4(),
                          imageInput: {
                            question: "",
                            multipleSelection:false,
                            alignment: "horizontal",
                            inputs: [],
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    quantity: 1,
                    span: 1,
                    default: false,
                  },
                  {
                    column: [
                      {
                        option: {
                          type: "image",
                          _id:uuidv4(),
                          imageInput: {
                            question: "",
                            multipleSelection:false,
                            alignment: "horizontal",
                            inputs: [],
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    quantity: 1,
                    span: 1,
                    default: false,
                  },
                  {
                    column: [
                      {
                        option: {
                          type: "image",
                          _id:uuidv4(),
                          imageInput: {
                            question: "",
                            multipleSelection:false,
                            alignment: "horizontal",
                            inputs: [],
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    quantity: 1,
                    span: 1,
                    default: false,
                  },
                ],
                default: true,
                quantity: 3,
              },
            ]}
          />
        );
      case 6:
        return (
          <ConfiguratorTemplate12
            updateLayoutObject={this.updateLayoutObject}
            layoutObject={[
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "bild",
                          _id:uuidv4(),
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    quantity: 1,
                    span: 1,
                    default: false,
                  },
                  {
                    column: [
                      {
                        option: {
                          type: "bild",
                          _id:uuidv4(),
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    quantity: 1,
                    span: 1,
                    default: false,
                  },
                  {
                    column: [
                      {
                        option: {
                          type: "bild",
                          _id:uuidv4(),
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    quantity: 1,
                    span: 1,
                    default: false,
                  },
                ],
                default: true,
                quantity: 3,
              },
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "text",
                          _id:uuidv4(),
                          textInput: {
                            inputs: [
                              {
                                id: 1,
                                interactive: false,
                                numeric: false,
                                stages: "",
                                maximum: "",
                                minimum: "",
                                value: EditorState.createEmpty(),
                                interactiveText: "",
                                hideTextToolbar: true,
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                            boxColor:""
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                ],
                orientation: "bottom",
                quantity: 1,
              },
            ]}
          />
        );
      case 7:
        return (
          <ConfiguratorTemplate12
            updateLayoutObject={this.updateLayoutObject}
            layoutObject={[
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "text",
                          _id:uuidv4(),
                          textInput: {
                            inputs: [
                              {
                                id: 1,
                                interactive: false,
                                numeric: false,
                                stages: "",
                                maximum: "",
                                minimum: "",
                                value: EditorState.createEmpty(),
                                interactiveText: "",
                                hideTextToolbar: true,
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                            boxColor:""
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                ],
                orientation: "top",
                quantity: 1,
              },
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "image",
                          _id:uuidv4(),
                          imageInput: {
                            question: "",
                            multipleSelection:false,
                            alignment: "horizontal",
                            inputs: [],
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    quantity: 1,
                    span: 1,
                    default: false,
                  },
                  {
                    column: [
                      {
                        option: {
                          type: "image",
                          _id:uuidv4(),
                          imageInput: {
                            question: "",
                            multipleSelection:false,
                            alignment: "horizontal",
                            inputs: [],
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    quantity: 1,
                    span: 1,
                    default: false,
                  },
                  {
                    column: [
                      {
                        option: {
                          type: "image",
                          _id:uuidv4(),
                          imageInput: {
                            question: "",
                            multipleSelection:false,
                            alignment: "horizontal",
                            inputs: [],
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    quantity: 1,
                    span: 1,
                    default: false,
                  },
                ],
                default: true,
                quantity: 3,
              },
            ]}
          />
        );
      case 8:
        return (
          <ConfiguratorTemplate12
            updateLayoutObject={this.updateLayoutObject}
            layoutObject={[
              {
                row: [
                  {
                    column: [
                      {
                        option: {},
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                  {
                    column: [
                      {
                        option: {},
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                  {
                    column: [
                      {
                        option: {
                          type: "text",
                          _id:uuidv4(),
                          textInput: {
                            inputs: [
                              {
                                id: 1,
                                interactive: false,
                                numeric: false,
                                stages: "",
                                maximum: "",
                                minimum: "",
                                value: EditorState.createEmpty(),
                                interactiveText: "",
                                hideTextToolbar: true,
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                            boxColor:""
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                ],
                orientation: "top",
                quantity: 3,
              },
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "radio",
                          _id:uuidv4(),
                          radioInput: {
                            question: "",
                            multipleSelection:false,
                            inputs: [
                              {
                                id: 1,
                                value: "",
                                price: "",
                                priceType: "", //flat-rate, number-guests, numerically
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                              {
                                id: 2,
                                value: "",
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    quantity: 1,
                    span: 1,
                    default: true,
                  },
                ],
                default: true,
                quantity: 1,
              },
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "text",
                          _id:uuidv4(),
                          textInput: {
                            inputs: [
                              {
                                id: 1,
                                interactive: false,
                                numeric: false,
                                stages: "",
                                maximum: "",
                                minimum: "",
                                value: EditorState.createEmpty(),
                                interactiveText: "",
                                hideTextToolbar: true,
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                            boxColor:""
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                ],
                orientation: "bottom",
                quantity: 1,
              },
            ]}
          />
        );
      case 9:
        return (
          <ConfiguratorTemplate12
            updateLayoutObject={this.updateLayoutObject}
            layoutObject={[
              {
                row: [
                  {
                    column: [
                      {
                        option: {},
                        span: 1,
                        selected: false,
                      },
                    ],
                    quantity: 1,
                    span: 1,
                    default: true,
                  },
                ],
                default: true,
                quantity: 1,
              },
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "text",
                          _id:uuidv4(),
                          textInput: {
                            inputs: [
                              {
                                id: 1,
                                interactive: false,
                                numeric: false,
                                stages: "",
                                maximum: "",
                                minimum: "",
                                value: EditorState.createEmpty(),
                                interactiveText: "",
                                hideTextToolbar: true,
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                            boxColor:""
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                ],
                orientation: "bottom",
                quantity: 1,
              },
            ]}
          />
        );
      case 10:
        return (
          <ConfiguratorTemplate12
            updateLayoutObject={this.updateLayoutObject}
            layoutObject={[
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "text",
                          _id:uuidv4(),
                          textInput: {
                            inputs: [
                              {
                                id: 1,
                                interactive: false,
                                numeric: false,
                                stages: "",
                                maximum: "",
                                minimum: "",
                                value: EditorState.createEmpty(),
                                interactiveText: "",
                                hideTextToolbar: true,
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                            boxColor:""
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                ],
                orientation: "top",
                quantity: 1,
              },
              {
                row: [
                  {
                    column: [
                      {
                        option: {},
                        span: 1,
                        selected: false,
                      },
                    ],
                    quantity: 1,
                    span: 1,
                    default: true,
                  },
                ],
                default: true,
                quantity: 1,
              },
            ]}
          />
        );
      case 11:
        return (
          <ConfiguratorTemplate12
            updateLayoutObject={this.updateLayoutObject}
            layoutObject={[
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "text",
                          _id:uuidv4(),
                          textInput: {
                            inputs: [
                              {
                                id: 1,
                                interactive: false,
                                numeric: false,
                                stages: "",
                                maximum: "",
                                minimum: "",
                                value: EditorState.createEmpty(),
                                interactiveText: "",
                                hideTextToolbar: true,
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                            boxColor:""
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                ],
                // orientation: "top",
                default: false,
                quantity: 1,
              },
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "text",
                          _id:uuidv4(),
                          textInput: {
                            inputs: [
                              {
                                id: 1,
                                interactive: false,
                                numeric: false,
                                stages: "",
                                maximum: "",
                                minimum: "",
                                value: EditorState.createEmpty(),
                                interactiveText: "",
                                hideTextToolbar: true,
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                            boxColor:""
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                ],
                // orientation: "top",
                default: false,
                quantity: 1,
              },
              {
                row: [
                  {
                    column: [
                      {
                        option: {
                          type: "text",
                          _id:uuidv4(),
                          textInput: {
                            inputs: [
                              {
                                id: 1,
                                interactive: false,
                                numeric: false,
                                stages: "",
                                maximum: "",
                                minimum: "",
                                value: EditorState.createEmpty(),
                                interactiveText: "",
                                hideTextToolbar: true,
                                price: "",
                                priceType: "",
                                priceTypeId: null,
                                tags: [],
                                numerically: {
                                  minimumNumber: "",
                                  minimumNumberCalculate: false,
                                  maximumNumber: "",
                                  maximumNumberCalculate: false,
                                  stage: "",
                                  relay1: "",
                                  relay1Alle: false,
                                  relay1Price: "",
                                  relay2: "",
                                  relay2Alle: false,
                                  relay2Price: "",
                                  relay3: "",
                                  relay3Alle: false,
                                  relay3Price: "",
                                  unit: "",
                                },
                              },
                            ],
                            boxColor:""
                          },
                        },
                        span: 1,
                        selected: false,
                      },
                    ],
                    span: 1,
                    quantity: 1,
                  },
                ],
                // orientation: "top",
                default: false,
                quantity: 1,
              },
              {
                row: [
                  {
                    column: [
                      {
                        option: {},
                        span: 1,
                        selected: false,
                      },
                    ],
                    quantity: 1,
                    span: 1,
                    default: true,
                  },
                ],
                default: true,
                quantity: 1,
              },
            ]}
          />
        );
      case 12:
        return (
          <ConfiguratorTemplate12
            updateLayoutObject={this.updateLayoutObject}
            layoutObject={this.state.layoutObject}
          />
        );
      default:
        return (
          <ConfiguratorTemplateSelection
            setSelectedTemplate={this.setSelectedTemplate}
          />
        );
    }
  };

  updateLayoutObject = (layoutObject, serviceLink) => {
    if (serviceLink) {
      this.setState({ layoutObject, serviceLink });
    } else {
      this.setState({ layoutObject });
    }
  };

  setSelectedTemplate = (selectedTemplate) =>
    this.setState({ selectedTemplate });

  sendQuestionAndClosePopup = async () => {
    // console.log("save", this.state.layoutObject);
    var validated = true;
    const dummy = Object.assign([], this.state.layoutObject);
    dummy.forEach((layout) => {
      layout.row.forEach((row) => {
        row.column.forEach((column) => {
          if (column.option.type === "text") {
            if (column.option?.textInput) {
              column.option.textInput.inputs.forEach((input) => {
                const min = Number(input.numerically.minimumNumber);
                const max = Number(input.numerically.maximumNumber);
                const price = Number(input.price);

                if (min && max && min > max) {
                  validated = false;
                  return;
                } else if (min && max && !price) {
                  validated = false;
                  return;
                } else if (min && !price) {
                  validated = false;
                  return;
                } else if (max && !price) {
                  validated = false;
                  return;
                }
                if (!Object.keys(input.value).includes("blocks")) {
                  const c = convertToRaw(input.value.getCurrentContent());
                  input.value = c;
                }
              });
            }
          }
          if (column.option.type === "radio") {
            if (column.option?.radioInput) {
              column.option.radioInput.inputs.forEach((input) => {
                const min = Number(input.numerically.minimumNumber);
                const max = Number(input.numerically.maximumNumber);
                const price = Number(input.price);

                if (min && max && min > max) {
                  validated = false;
                  return;
                } else if (min && max && !price) {
                  validated = false;
                  return;
                } else if (min && !price) {
                  validated = false;
                  return;
                } else if (max && !price) {
                  validated = false;
                  return;
                }
              });
            }
          }
          if (column.option.type === "image") {
            if (column.option?.imageInput) {
              column.option.imageInput.inputs.forEach((input) => {
                const min = Number(input.numerically.minimumNumber);
                const max = Number(input.numerically.maximumNumber);
                const price = Number(input.price);

                if (min && max && min > max) {
                  validated = false;
                  return;
                } else if (min && max && !price) {
                  validated = false;
                  return;
                } else if (min && !price) {
                  validated = false;
                  return;
                } else if (max && !price) {
                  validated = false;
                  return;
                }
              });
            }
          }
        });
      });
    });
    if (validated) {
      const postQtnData = {
        questionnaireId: this.context.questionnaireId,
        themeId: this.props.templateId,
        serviceId: this.props.stepId,
        question: dummy,
        totalAmount: 0,
        isDeleted :false
      };
      
   let postData=   await HttpService.postBuilderQtn(postQtnData);
  //  console.log("postdata",postData)
      const themeApiObject = {};
      themeApiObject.themes = [];
      const tempDummy=Object.assign([],this.props.templateObject)
      tempDummy.forEach((item, index) => {
        themeApiObject.themes[index] = {
          id: item.id,
          name: item.name,
          services: item?.services || [],
        };
        item.services.forEach((_item, _index) => {
          themeApiObject.themes[index].services[_index] = {
            id: _item.id,
            name: _item.name,
            isSaved: _item.isSaved?_item.isSaved:false,
            serviceLink:_item.serviceLink?_item.serviceLink:{},
            headerImage:_item.headerImage?_item.headerImage:""
          };
          if (
            _item.id === this.props.stepId &&
            item.id === this.props.templateId
          ) {
            themeApiObject.themes[index].services[_index]["isSaved"] = true;
            themeApiObject.themes[index].services[_index][
              "serviceLink"
            ] = this.state.serviceLink;
          }
        });

      });
      let getThemes = await HttpService.getBuilderThemes(
        this.context.questionnaireId
      );
      if (Object.keys(getThemes).length) {
        //
        // const qt={questionnaireId:getThemes.questionnaireId}
        // const putThemeData = {
        //   themeApiObject,
        //   // ...qt
        // };
        getThemes.themeApiObject=themeApiObject
        getThemes.questionnaireName=this.context.questionnaireName 
        delete getThemes._id
       var putThemeData= await HttpService.putThemes(getThemes.questionnaireId, getThemes);
      } else {
        const qt = { questionnaireId: this.context.questionnaireId,
                     questionnaireName:this.context.questionnaireName          
        };
        const postThemeData = {
          ...qt,
          themeApiObject,
         
        };
    await HttpService.postThemes(postThemeData);
      }
// console.log("postdata",postData,"getthemes",getThemes,"putThemedata",putThemeData,"postthemedata",postTheme)
if(postData.status==="Success" && putThemeData.status===true){
  swal(`${this.props.t("Gespeichert!")}`, "", "success");
}
    }
  };
  componentDidMount(){
    window.addEventListener("scroll", () => {
      this.setState({scroll:window.scrollY > 0});
    });
    
  };

  render() {
    return (
			<>
				<MainContainer>
					<div className="popup-overlay">
						<div className="popup template-selection-popup">
							<div
								className="popup-container">
								<StickyDiv className={this.scroll ? 'scroll-div' : 'no-scroll'}>
									<button
										className="saveTemplate"
										onClick={this.sendQuestionAndClosePopup}
										// style={{position:"sticky" ,top: "0px" }}
										>
										{this.props.t('Speichern')}
									</button>
									<button
										className="closeTemplate"
										onClick={this.props.closeTemplatePopup}
										// style={{position:"sticky" , top: "0px" , marginRight: "-90%",marginBottom: "25px"}}
										// style={{position:"fixed",top:"-48px",marginRight: "-90%"}}
										>
										<img src={closeIcon} alt="" />
									</button>
								</StickyDiv>
								{/* {console.log('servlink', this.state.serviceLink)} */}
								{this.renderTemplate(this.state.selectedTemplate)}
							</div>
						</div>
					</div>
				</MainContainer>
			</>
		);
  }
}

export default  withTranslation() (ConfiguratorTemplateSelectionPopup);

import React, { Component } from "react";
import LeftBar from "../partials/LeftBar";
import RightBar from "../partials/RightBar";
import PullBar from "../partials/PullBar";
import { StateContext } from "../../context/state";
// import closeIcon from "../../assets/cross.svg";
import plusIcon from "../../assets/plus.svg";
import axios from "axios";
import { baseUrl } from "../../Constants";
import moment from "moment";
import "moment-timezone";
import Moment from "react-moment";
import HttpService from "../../services/HttpService";
export default class EventsParagraphs extends Component {
  static contextType = StateContext;
  _isMounted = false;
  constructor(props, context) {
    super(props, context);
    this.state = {
      para: "",
      paragraphs: [],
      questionnaireData: {},
      paragraphsNewEntry: true,
      id:"",
      name:"",
      newTimeline:true,
      events: [
        // {
        //   name:"firstStep",
        //   text:"",
        //   date: new Date()

        // },
        { name: "secondStep",
         text: "", 
        date: new Date(),
        topText:""
      },
        {
          name: "thirdStep",
          text: "",
          date: new Date(),
          topText:""
        },
        {
          name: "fourthStep",
          text: "",
          date: new Date(),
        },
        {
          name: "fifthStep",
          text: "",
          date: new Date(),
        },
      ],
    };
  }
  componentDidMount=()=>{
    
    const para= this.props.location.state.paras
    const id=this.props.location.state._id
    const name=this.props.location.state.name
    this.setState({
      paragraphs:para,
      id,
      name
    })
    const dummy = Object.assign([], this.state.events);
    axios
      .get(`${baseUrl}api/event/Welcome/${id}`)
      .then((res) => {
        if (res.data?.events) {
          this.setState({
            events: res.data.events.length ? res.data.events : dummy,
            newTimeline: res.data.events.length ? false : true,
          });
        }
      })
  }
  handlePara = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangingPara = (e, i) => {
    const paragraphs = Object.assign([], this.state.paragraphs);
    paragraphs[i] = e.target.value;
    this.setState({
      paragraphs
    });
  };
  addPara = (e) => {
    // e.preventDefault();
    this.setState({
      paragraphs: this.state.paragraphs.concat(this.state.para),
      para: "",
    });
  };

  // findIfDatePassed = (name) => {
  //   const dummy = Object.assign([], this.state.events);
  //   const dummy2 = dummy.find((d) => {
  //     return d.name === name;
  //   });
  //   // console.log('dummy',moment(dummy2.date).isAfter(new Date()))
  //   return moment(dummy2.date).isAfter(new Date());
  // };
  handleBack = async() => {
      const postData = {
        questionnaireId: this.state.id,
        events: this.state.events,
      };
     
      if (this.state.newTimeline) {
        await HttpService.postEventsPageData(postData);
      } else {
        await HttpService.putEventsPageData(postData, this.state.id);
      }
      this.props.history.push(`/admin/settings`, {para:this.state.paragraphs, id:this.state.id, name:this.state.name});
    
  };
  functionCapitalise = () => {
    let title = "";
    if (this.context?.cuDetails?.title1) {
      title = this.context?.cuDetails?.title1[0]
        .toUpperCase()
        .concat(
          this.context?.cuDetails?.title1.slice(
            1,
            this.context?.cuDetails?.title1.length
          )
        );
    }
    // console.log('title', title)
    return title;
  };

  handleTimelineText = (e, index) => {
    const events = Object.assign([], this.state.events);
    events[index].text = e.target.value;
    this.setState({
      events,
    });
  };
  handleTopText = (e, index) => {
    const events = Object.assign([], this.state.events);
    events[index].topText = e.target.value;
    this.setState({
      events,
    });
  };
  
  findIfDatePassed = (name) => {
    const dummy = Object.assign([], this.state.events);
    const dummy2 = dummy.find((d) => {
      return d.name === name;
    });
    // console.log('dummy',moment(dummy2.date).isAfter(new Date()))
    return moment(dummy2.date).isAfter(new Date());
  };
  render() {
    const { events } = this.state;
    return (
      <div className="login-event-landing" style={{ marginLeft: "200px" }}>
        <div className="col-2">
          <LeftBar />
        </div>
        <div className="col-10">
        <div className="steps-indicator">
            <div className="second-step">
              &nbsp;&nbsp;
              {/* &nbsp;&nbsp; */}
              {events.map((event, i) => {
                if (event.name === "secondStep") {
                  return (
                    <div key={i}>
                      {/* {events.length && this.findIfDatePassed("secondStep") ? (
                        <div
                          className="empty-circle"
                          style={{ marginTop: "45px" }}
                        ></div>
                      ) : (
                        <div
                          className="circle"
                          style={{ marginTop: "45px" }}
                        ></div>
                      )} */}
                      {events.length && (
                        <>
                         <input
                        type="text"
                        style={{ width: "150px" }}
                        value={events[0].topText}
                        onChange={(e) => {
                          this.handleTopText(e, 0);
                        }}
                        autoComplete="off"
                      />
                        <div
                          className="empty-circle"
                          style={{ marginTop: "20px" }}
                        ></div></>
                      )}
                      <br />
                      <input
                        type="text"
                        style={{ width: "150px", marginTop: "-1px" }}
                        value={events[0].text}
                        onChange={(e) => {
                          this.handleTimelineText(e, 0);
                        }}
                        autoComplete="off"
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
              <span className="line second"></span>
              <div></div>
            </div>
            <div className="third-step">
              <span className="line"></span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              {events.map((event, i) => {
                if (event.name === "thirdStep") {
                  return (
                    <div key={i}>
                      {/* {events.length && this.findIfDatePassed("thirdStep") ? (
                        <div
                          className="empty-circle"
                          style={{ marginTop: "45px" }}
                        ></div>
                      ) : (
                        <div
                          className="circle"
                          style={{ marginTop: "45px" }}
                        ></div>
                      )} */}
                      {events.length && (
                        <> <input
                        type="text"
                        style={{ width: "150px"}}
                        value={events[1].topText}
                        onChange={(e) => {
                          this.handleTopText(e, 1);
                        }}
                        autoComplete="off"
                      />
                        <div
                          className="empty-circle"
                          style={{ marginTop: "20px" }}
                        ></div></>
                      )}
                      <br />
                      <input
                        type="text"
                        style={{ width: "150px", marginTop: "-1px" }}
                        value={events[1].text}
                        onChange={(e) => {
                          this.handleTimelineText(e, 1);
                        }}
                        autoComplete="off"
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
              <span className="line second"></span>
            </div>
            <div className="fourth-step">
              <span className="line"></span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              {events.map((event, i) => {
                if (event.name === "fourthStep") {
                  return (
                    <div key={i}>
                      <span style={{ width: "150px" }}>
                       {this.props.location.state?.from==="eventsPage" ? <Moment format="DD-MM-YYYY">{this.context.deadlineForEntries}</Moment>: '--'}
                      </span>

                      <br />
                      {events.length && this.findIfDatePassed("fourthStep") ? (
                        <div className="empty-circle"></div>
                      ) : (
                        <div className="circle"></div>
                      )}
                      <br />

                      <input
                        type="text"
                        style={{ width: "150px" }}
                        value={events[2].text}
                        onChange={(e) => {
                          this.handleTimelineText(e, 2);
                        }}
                        autoComplete="off"
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
              <span className="line second"></span>
            </div>
            <div className="fifth-step">
              <span className="line"></span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              {events.map((event, i) => {
                if (event.name === "fifthStep") {
                  return (
                    <div key={i}>
                      <span style={{ width: "150px" }}>
                      {this.props.location.state?.from==="eventsPage" ? <Moment format="DD-MM-YYYY">{this.context.eventDate}</Moment>: '--'}
                      </span>

                      {events.length && this.findIfDatePassed("fifthStep") ? (
                        <div className="empty-circle"></div>
                      ) : (
                        <div className="circle"></div>
                      )}
                      <br />
                      <input
                        type="text"
                        style={{ width: "150px" }}
                        value={events[3].text}
                        onChange={(e) => {
                          this.handleTimelineText(e, 3);
                        }}
                        autoComplete="off"
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
          <div style={{ marginRight: "200px", marginLeft: "100px" }}>
            <br />
            <h5>
              Sehr geehrter {this.functionCapitalise()}{" "}
              {this.context?.cuDetails?.surname1},
            </h5>
            
            <div style={{ padding: "10px 50px" }}>
              {this.state.paragraphs?.map(
                (para, i) => {
                  return (
                    <div key={i}>
                      <textarea
                        cols="60"
                        style={{}}
                        value={para}
                        onChange={(e) => {
                          this.handleChangingPara(e, i);
                        }}
                      >
                        {para}
                      </textarea>
                      <br />
                    </div>
                  );
                }
              )}

              <div style={{ display: "flex" }}>
                <textarea
                  name="para"
                  style={{}}
                  cols="60"
                  value={this.state.para}
                  onChange={this.handlePara}
                ></textarea>
                &nbsp;
                <button
                  style={{
                    border: "none",
                    borderRadius: "50%",
                    marginTop: "10px",
                    width: "30px",
                    height: "30px",
                  }}
                  onClick={this.addPara}
                >
                  <img src={plusIcon} alt="" />
                </button>
              </div>
            </div>
            <p className="signature">Ihr Hofgut Lilienhof Team</p>

            <div className="links-container">
              <button className="green-border-btn" onClick={this.handleBack}>
                Zurück
              </button>
              
            </div>
            <br />
            <br />
          </div>
        </div>

        <RightBar />
        <PullBar />
      </div>
    );
  }
}

import React, { Component } from "react";
import TabBar from "../partials/TabBar";
import { Route, Switch } from "react-router-dom";
import EventsPage from "./EventsPage";
import Site from "./Site"
import QuestionnairesPage from "./QuestionnairesPage";
import PlansPage from "./PlansPage";
import UsersPage from "./UsersPage";
import SettingsMainPage from "../settings/settingsMainPage";
import Settings from "../settings/settings";
import StatisticsMain from "../statistics/statisticsMainPage";
import UserInfoPage from "./UserInfoPage";
import VenueInfoPage from "./VenueInfoPage";
import KeyFigures from "../partials/KeyFigures";
import withRoute from "../hocs/withRoute";
import Authorization from "../../authorization";
import { StateContext } from "../../context/state";
import VenuesPage from "./VenuesPage";
import { withTranslation} from 'react-i18next';
import DashboardPage from "./DashboardPage";
import ComingSoonPage from "./ComingSoonPage";

const KeyFiguresPaths = [
  "/admin/",
  "/admin/events",
  "/admin/questionnaire",
  "/admin/users",
  "/admin/plans",
  "/admin/invoices",
  "/admin/venues",
  // "/admin/siteplan"
];
export const allowedRoles = {
  events: ["VA", "SA", "VM", "VV"],
  Site: ["VA", "SA", "VM", "VV"],
  questionnaires: ["VA", "VM","SA"],
  users: ["VA", "VM", "SA"],
  settings: ["VA", "VM", "SA"],
  plans: ["VA", "VM", "VV"],
  statistics: ["VA", "SA","VM"],
  invoices: ["VA", "SA", "VM", "VV"],
  venues: ["SA"],
};

class AdminDashboard extends Component {
  static contextType = StateContext;
  authComingSoon = Authorization(ComingSoonPage, allowedRoles.events);
  authDashboard = Authorization(DashboardPage, allowedRoles.events);
  authEventListing = Authorization(EventsPage, allowedRoles.events);
  authSiteListing = Authorization(Site, allowedRoles.events);

  authQuestionnaire = Authorization(
    QuestionnairesPage,
    allowedRoles.questionnaires
  );
  authUser = Authorization(UsersPage, allowedRoles.users);
  authUserInfo = Authorization(UserInfoPage, allowedRoles.users);
  authSettings = Authorization(SettingsMainPage, allowedRoles.settings);
  authSettingsV1 = Authorization(Settings, allowedRoles.settings);
  authPlan = Authorization(PlansPage, allowedRoles.plans);
  authStatistics = Authorization(StatisticsMain, allowedRoles.statistics);
  authVenues = Authorization(VenuesPage, allowedRoles.venues);
  authVenueInfo = Authorization(VenueInfoPage, allowedRoles.venues);
  handleLogout = () => {
    const urlName = this.context.urlName;
    this.context.updateState({
      authenticated: false,
      from:"logout"
    });
    localStorage.clear();
    sessionStorage.clear();
    if (urlName === "adminScreen") {
      this.props.history.push(`/login`);
    } else {
      this.props.history.push(`/login/${urlName}`);
    }
  };

  render() {
    const { activeRoute } = this.props;
    const keyFiguresVisible = KeyFiguresPaths.includes(activeRoute?.pathname);
    return (
      <div style={styles.container}>
        { this.props.location.pathname !== '/admin/dashboard' && this.props.location.pathname !== '/admin' &&
          <TabBar {...this.props} /> 
        }

        <div style={window.location.pathname==="/admin/dashboard" ?{...styles.wrapper,marginLeft:0}:styles.wrapper} className="wrapper">
          {keyFiguresVisible && (
            <React.Fragment>
              <div style={styles.row}>
                <div className="col-1">
                  {/* <OverlayTrigger
                    key="bottom"
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-disabled`}>
                        <span>{this.props.t('logout')}</span>
                      </Tooltip>
                    }
                  >
                    <button
                      className="logout-btn"
                      style={{
                        border: "none",
                      }}
                      onClick={this.handleLogout}
                    >
                      <img src={logoutIcon} alt="" />
                    </button>
                  </OverlayTrigger> */}
                </div>
                <div className="col-10">
                  <KeyFigures />
                </div>
              </div>

              {/* <div style={styles.row}>
                <div className="col-11">
                  <div style={styles.divider} />
                </div>
              </div> */}
            </React.Fragment>
          )}

          <Switch>
            <Route path="/" exact component={this.authDashboard} />
            <Route path="/admin/coming-soon" exact component={this.authComingSoon} />
            <Route path="/admin/dashboard" exact component={this.authDashboard} />
            <Route path="/admin/events" component={this.authEventListing} />
            <Route path="/admin/siteplan" component={this.authSiteListing} />
            <Route
              path="/admin/questionnaire"
              component={this.authQuestionnaire}
            />
            <Route path="/admin/users" component={this.authUser} />
            <Route path="/admin/userInfo" component={this.authUserInfo} />
            <Route path="/admin/settings" component={this.authSettings} />
            <Route path="/admin/settings-new" component={this.authSettingsV1} />
            <Route path="/admin/plans" component={this.authPlan} />
            <Route path="/admin/invoices" component={this.authInvoices} />
            <Route path="/admin/stats" component={this.authStatistics} />
            <Route path="/admin/venues" component={this.authVenues} />
            <Route path="/admin/venueInfo" component={this.authVenueInfo} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withTranslation() (withRoute(AdminDashboard));

const styles = {
  container: {
    display: "flex",
    fontFamily: "Crete Round",
    background: "#f0eff0"
  },
  wrapper: {
    minHeight: "100vh",
    flexGrow: "1",
    padding: "10px 0",
    marginLeft:"72px"
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
  },
  divider: {
    borderTop: "1px solid #d0d3d4",
    margin: "30px 0 0px 40px",
    paddingTop: "10px",
  },
};

import React, { Component } from "react";
import { StateContext } from "../../context/state";
import crossSmallIcon from "../../assets/cross-small.svg";

class ImageCaption extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      themes: [],
      // selectedDropdown: "Info",
      bildInput: {},
      selectedBildImgIndex:0
    };
  }
  static getDerivedStateFromProps = (nextProps, prevState) => {
   return {
       bildInput:nextProps.bildCaptionData,
       selectedBildImgIndex:nextProps.selectedBildImgIndex
    }
  };

  handleOnChange=(e, name)=>{
    const {bildInput}= this.state
    bildInput.image[this.state.selectedBildImgIndex][name]=e.target.value
    this.props.updateImageCaptions(bildInput)
    this.setState({
        bildInput
    })
  }
  handleClose = () => {
    this.props.handleClose();
  };
 
  render() {
    // console.log("props", this.props.option);
    return (
      <div className="component-container option-component" style={{paddingBottom:"150px"}}>
        <img
          src={crossSmallIcon}
          onClick={this.handleClose}
          alt=""
          style={{
            float: "right",
            marginRight: "2px",
            marginTop: "-28px",
            width: "13px",
            height: "13px",
          }}
        />
      
        <div>
          {this.state.bildInput?.image  ? (
            <>
            {/* {console.log('hi',this.state.bildInput.image[this.state.selectedBildImgIndex] )} */}
            <textarea type="text" placeholder="Titel"
                style={{
                    backgroundColor: "#bcbec0",
                    border: "none",
                    height: "40px",
                    padding:"7px"
                }}
                value={this.state.bildInput.image[this.state.selectedBildImgIndex].title}
                onChange={(e)=>this.handleOnChange(e, "title")}
            />
            <br/>
            <textarea
                type="text" 
                placeholder="Text"
                style={{
                    backgroundColor: "#bcbec0",
                    border: "none",
                    marginTop:"10px",
                    height: "90px",
                    padding:"7px"
                }}
                value={this.state.bildInput.image[this.state.selectedBildImgIndex].caption}
                onChange={(e)=>this.handleOnChange(e, "caption")}
            />
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ImageCaption;

import React, { Component } from "react";
import home from "../../assets/home.svg";
import print from "../../assets/print.svg";
import docIcon from "../../assets/doc.svg";
import link2 from "../../assets/link2.svg";
import ExportSectionPopup from "../partials/x";
import ReactToPrint from "react-to-print";
import TextOption from "./text";
import DatumOption from "./datum";
import Loader from "./LoaderPlanPreview";
import RadioOption from "./radio";
import ImageOption from "./image";
import RadioMultipleOption from "./radioMultipleOption";
import BildOption from "./bild";
import TableOption from "./table";
import UploadOption from "./upload";
import DownloadOption from "./download";
import ImageMultipleOption from "./imageMultipleOption";
import { StateContext } from "../../context/state";
import axios from "axios";
import { baseUrl } from "../../Constants.js";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import HttpService from "../../services/HttpService";
import { withTranslation } from 'react-i18next';
import Moment from "react-moment";
// import Loader from "./Loader";

import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
// import { Editor } from 'react-draft-wysiwyg';
import TextareaAutosize from "react-textarea-autosize";

const ref = React.createRef();

const att = [];
class PlanPreview extends Component {
  static contextType = StateContext;
  constructor() {
    super();
    // this.onLaunchClicked = this.onLaunchClicked.bind(this);
    this.state = {
      loading: false,
      event: {},
      isDisplay: "",
      isDisplayDownload: "",
      closeCalled: false,
      showExportPopup: false,
      themes: [],
      guestOptions: [],
      attachments: [],
      popoverOpen: false,
      mails: [],
      showPlanPreviewInfoInPDF: false,
      pricing: [],
      attachedFiles: [],
      attachedFiles1: [],
      height: 7000,
      display: "",
      copySuccess: "",
      guestLabelWithPrice: {},
      saveCalled: false,
      uploadAttachment: [],
      downloadAttachment: [],
      obj: {},
      exportThemes:[],
      array:[],
      show:true
    };
  }
  componentDidMount() {
    // transfers sessionStorage from one tab to another
    //   if(Object.keys(obj).length!=0){
    //     this.context.updateState({
    //        authenticated: true,
    //      });
    //  }
    var sessionStorage_transfer = function (event) {
      if (!event) { event = window.event; } // ie suq
      if (!event.newValue) return;          // do nothing if no value to work with
      if (event.key === 'getSessionStorage') {
        // another tab asked for the sessionStorage -> send it
        localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
        // the other tab should now have it, so we're done with it.
        localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
      } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
        // another tab sent data <- get it
        var data = JSON.parse(event.newValue);
        for (var key in data) {
          sessionStorage.setItem(key, data[key]);
        }
      }
    };

    // listen for changes to localStorage
    if (window.addEventListener) {
      window.addEventListener("storage", sessionStorage_transfer, false);
    } else {
      window.attachEvent("onstorage", sessionStorage_transfer);
    };


    // Ask other tabs for session storage (this is ONLY to trigger event)
    if (!sessionStorage.length) {
      localStorage.setItem('getSessionStorage', 'foobar');
      localStorage.removeItem('getSessionStorage', 'foobar');
    };
    // console.log("session",JSON.parse(sessionStorage.getItem("state1")))

    const self = this;
    const obj = JSON.parse(sessionStorage.getItem("state1"));
    // console.log("context",this.context,obj,"obj")

    this.setState({ closeCalled: false, obj });
    window.addEventListener("click", function (e) {
      document.getElementById("pdf");
      if (document.getElementById("pdf")?.contains(e.target)) {
        self.setState({ display: "textareaDiv" });
      }
    });
    // console.log("location",window.location.href)
    if (!this.props.allowed) {
      this.context.updateState({
        attachments: [],
        eventName: obj?.eventName,
        exportPlanId: obj?.exportPlanId,
        parentQuestionnaireId: obj?.parentQuestionnaireId,
        childQuestionnaireId: obj?.childQuestionnaireId,
        planId: obj?.planId,
        eventId: obj?.eventId,

      });
    }

    // if (window.location.pathname !== "/planPreview") {
    //   // console.log("location onload",this.props.location?.state?.from)
    //   window.onload = function () {
    //     //considering there aren't any hashes in the urls already
    //     if (!window.location.hash) {
    //       //setting window location
    //       window.location = window.location + "#";
    //       //using reload() method to reload web page
    //       window.location.reload();
    //     }
    //   };
    // }
    let childQuestionnaireId;
    let parentQuestionnaireId;
    let exportPlanId;
    let eventId
    if (this.props.allowed) {
      childQuestionnaireId = this.props.childQuestionnaireId;
      parentQuestionnaireId = this.props.parentQuestionnaireId;
      exportPlanId = this.props.exportPlanId;
      eventId = this.props.eventId
    } else {
      childQuestionnaireId = obj?.childQuestionnaireId;
      parentQuestionnaireId = obj?.parentQuestionnaireId;
      exportPlanId = obj?.exportPlanId;
      eventId = obj?.eventId
    }
    this.setState({loading:true})
    axios
      .get(`${baseUrl}api/guest/${childQuestionnaireId}`)
      .then((res) => {
        console.log("res", res)
        this.setState({ guestLabelWithPrice: res.data.guests,loading:false});
         })
      .catch((err) => {  this.setState({loading:false})});
    // console.log("exportplanid",this.context.exportPlanId)
    if (exportPlanId) {
      axios
        .get(
          `${baseUrl}api/export/${parentQuestionnaireId}/${childQuestionnaireId}/${exportPlanId}/all`
        )
        .then((res) => {

          // console.log("themes",res.data?.themes)
          this.setState({exportThemes:res.data?.themes ,
            array:res.data?.guestOptions, loading:false})
         
        })
        .catch((err) => {
          // console.log(err);
        });
    }
    if (eventId) {
      axios
        .get(
          `${baseUrl}api/export/${parentQuestionnaireId}/${childQuestionnaireId}/${exportPlanId}/${eventId}`
        )
        .then((res) => {
          // console.log("res.data",res.data)

          this.setState({
            // event:data,
            pricing: res.data?.priceObject,
            themes: res.data?.themes,
            guestOptions: res.data?.guestOptions
              ? res.data.guestOptions
              : this.state.array,
                loading:false
          });


        })
        .catch((err) => {
          // console.log(err);
        });
    }
    if (childQuestionnaireId) {
      axios
        .get(`${baseUrl}api/export/Details/${childQuestionnaireId}`)
        .then((res) => {
          //  console.log('res', res.data)
          this.setState({
            event: res?.data,
            loading:false
          });

        })
        .catch((err) => {
          // console.log(err);
        });

    }
    const interval = setInterval(() => this.setState({ show: false }), 10000);
    return () => {
      clearInterval(interval);
    };
  }

  uploadAttachment = (attachment,isDisplay) => {
    this.setState((prevstate) => ({
      uploadAttachment: [...prevstate.uploadAttachment, ...attachment],
      isDisplay
    }));
  };
  downloadAttachment = (attachment, isDisplayDownload) => {
    this.setState((prevstate) => ({
      downloadAttachment: [...prevstate.downloadAttachment, ...attachment],
      isDisplayDownload
    }));
  };
  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand("copy");

    e.target.focus();
    this.setState({ copySuccess: "Copied to clipboard!" });
  };
  getTheme = () => {
    let childQuestionnaireId;
    let parentQuestionnaireId;
    let exportPlanId;
    if (this.props.allowed) {
      childQuestionnaireId = this.props.childQuestionnaireId;
      parentQuestionnaireId = this.props.parentQuestionnaireId;
      exportPlanId = this.props.exportPlanId;
    } else {
      childQuestionnaireId = this.state.obj?.childQuestionnaireId;
      parentQuestionnaireId = this.state.obj?.parentQuestionnaireId;
      exportPlanId = this.state.obj?.exportPlanId;
    }
    // this.setState({closeCalled:true})
    if (exportPlanId) {
      axios
        .get(
          `${baseUrl}api/export/${parentQuestionnaireId}/${childQuestionnaireId}/${exportPlanId}/${this.state.obj.eventId}`
        )
        .then((res) => {
          this.setState({
            // event:data,
            pricing: res.data?.pricing,
            themes: res.data?.themes,
            guestOptions: res.data?.guestOptions,
            loading:false
          });


        })
        .catch((err) => {
          // console.log(err);
        });
    }

  };

  handleMailPDF = () => {
    let planPreviewURL =
      window.location.origin +
      "/email/planpreview/" +
      `${this.state.obj?.parentQuestionnaireId}/${this.state.obj?.childQuestionnaireId}/${this.state.obj?.exportPlanId}/${this.state.obj?.eventId}`;

    HttpService.sendPlanPreviewEmail({
      URL: planPreviewURL,
      email: this.state.popOverEmail,
    }).then(
      this.setState({
        popoverOpen: !this.state.popoverOpen,
        popOverEmail: null,
        loading:false,
      
      }),

    );
  };
  displayTextarea = () => {
    this.setState({ display: "" });
  };
  handleBackClick = async () => {
    let parentQuestionnaireId;
    let exportPlanId;
    let eventId;
    if (this.props.allowed) {
      parentQuestionnaireId = this.props.parentQuestionnaireId;
      exportPlanId = this.props.exportPlanId;
      eventId = this.props.eventId;
    } else {
      parentQuestionnaireId = this.state.obj?.parentQuestionnaireId;
      exportPlanId = this.state.obj?.exportPlanId;
      eventId = this.state.obj.eventId;
    }
    if (this.state.obj?.planId && exportPlanId && this.state.themes?.length) {
      // console.log("in planID")

      const data = {
        questionnaireId: parentQuestionnaireId,
        themes: this.state.themes,
        planId: this.state.obj?.planId,
        guestOptions: this.state.guestOptions,
        priceObject: this.state.pricing,
      };
      // let res = await HttpService.updateExportPlan(
      //   this.context.exportPlanId,
      //   data
      // );
      await HttpService.updateExportPlanWithEvent(
        this.context.exportPlanId,
        eventId,
        data
      );
    }
    this.setState({
      showExportPopup: !this.state.showExportPopup,
      loading:false,
    });
    this.toggle();
  };
  closeExportPopup = (string) => {
    //     console.log("planPre",this.props.history.location.state)
    //     console.log("")
    //   if(string!="saveCalled"){
    // this.setState({closeCalled:true})
    //   }

    // window.location.reload()
    this.getTheme();
    this.setState({ showExportPopup: false });
  };
  handleOnFocus = (themeId, divId) => {
    const themes = this.state.themes;
    for (let i of themes) {
      if (i.themeId === themeId) {
        for (let j of i.divs) {
          if (j.divId === divId) {
            j["hideToolbar"] = false;
          }
        }
      }
    }

    this.setState({ themes });
  };
  pdfClick = (note) => {
    if (note) {
      this.setState({ display: "textareaDiv" });
    }
  };
  handleOnBlur = (themeId, divId) => {
    const themes = this.state.themes;
    for (let i of themes) {
      if (i.themeId === themeId) {
        for (let j of i.divs) {
          if (j.divId === divId) {
            j["hideToolbar"] = true;
          }
        }
      }
    }

    this.setState({ themes });
  };

  onEditorStateNoteChange = (themeId, divId, editorState) => {
    // console.log("editor",themeId, divId,editorState)
    const copy = Object.assign([], this.state.themes);
    for (let i of copy) {
      if (i.themeId === themeId) {
        for (let j of i.divs) {
          if (j.divId === divId) {
            j.editorStateNote = editorState;
          }
        }
      }
    }
    this.setState({
      theme: copy,
    });
  };
  setTextInputValue = async (themeId, id, e) => {
    //  e.target.value.replace(/\n\r?/g, '<br />');
    // let value=  e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />')
    // console.log("value",value)
    const copy = Object.assign([], this.state.themes);
    for (let i of copy) {
      if (i.themeId === themeId) {
        for (let j of i.divs) {
          if (j.divId === id) {
            j[e.target.name] = e.target.value;
          }
        }
      }
    }
    this.setState({ themes: copy });
    const data = {
      questionnaireId: this.state.obj?.parentQuestionnaireId,
      themes: this.state.themes,
      planId: this.state.obj?.planId,
      guestOptions: this.state.guestOptions,
      priceObject: this.state.pricing,
    };
    await HttpService.updateExportPlanWithEvent(
      this.context.exportPlanId,
      this.state.obj?.eventId,
      data
    );
  };

  renderOption = (option, divId, divData, theme, divData2) => {
    //  console.log("theme",theme)
    // console.log("closeCalled",this.state.closeCalled)

    if (theme?.display === false) {
      return null;
    }
   
    switch (option.optionType) {
      case "text":
        return (
          <>
            <TextOption
              key={divId}
              data={divData}
              option={option}
              updateChange={this.updateChange}
            />
          </>
        );
      case "datum":
        return (
          <>
            <DatumOption
              key={divId}
              data={divData}
              option={option}
              updateChange={this.updateChange}
             
            />
          </>
        );
      case "bild":
        return (
          <>
            <BildOption
              key={divId}
              data={divData}
              option={option}
              showExportPopup={this.state.showExportPopup}
              attachments={this.state.attachments}
              updateChange={this.updateChange}
              updateAttachments={this.updateAttachments}
            />
          </>
        );
      case "radio":
        return (
          <>
            {/* {console.log("divData2-radio",divData2)} */}
            <RadioOption
              key={divId}
              data={divData}
              data2={divData2}
              option={option}
              updateChange={this.updateChange}
            />
          </>
        );
      case "table":
        return (
          <>
            <TableOption
              key={divId}
              data={divData}
              option={option}
              updateChange={this.updateChange}
            />
          </>
        );

      case "upload":
        return (
          <>
            <UploadOption
              uploadAttachment={this.uploadAttachment}
              key={divId}
              data={divData}
              option={option}
              attachments={this.state.attachments}
              updateChange={this.updateChange}
              updateAttachments={this.updateAttachments}
            />
          </>
        );
      case "download":
        return (
          <>
            <DownloadOption
              downloadAttachment={this.downloadAttachment}
              key={divId}
              data={divData}
              option={option}
              attachments={this.state.attachments}
              updateChange={this.updateChange}
              updateAttachments={this.updateAttachments}
            />
          </>
        );
      case "image":
        if (divData?.imageInput?.multipleSelection === false) {
          return (
            <>
              <ImageOption
                key={divId}
                data={divData}
                data2={divData2}
                option={option}
                attachments={this.state.attachments}
                updateChange={this.updateChange}
                updateAttachments={this.updateAttachments}
              />
            </>
          );
        } else {
          return (
            <>
              <ImageMultipleOption
                key={divId}
                data={divData}
                data2={divData2}
                option={option}
                attachments={this.state.attachments}
                updateChange={this.updateChange}
                updateAttachments={this.updateAttachments}
              />
            </>
          );
        }

      case "checkbox":
        return (
          <>
            {/* {console.log("divData",divData)}
            // {  console.log("divdata",divData,"divData2",divData2)} */}
            {/* {console.log("divData2",divData2)} */}
            <RadioMultipleOption
              closeCalled={this.state.closeCalled}
              key={divId}
              data={divData}
              data2={divData2}
              option={option}
              attachments={this.state.attachments}
              updateChange={this.updateChange}
              updateAttachments={this.updateAttachments}
             />
          </>
        );
     
      default:

        break;
    }
   
  };


  updateAttachments = (image) => {
    if (att?.length) {
      const newDat = att.find((urls) => {
        return urls?.url === image?.url;
      });
      if (!newDat) {
        att.push(image);
      }
    } else {
      att.push(image);
    }
  };
  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
      copySuccess: "",
    });
  };

  handleRedirectToHome = async (e) => {
    e.preventDefault();
    // console.log("in home",this.context?.planId )
    if (
      this.state.obj?.planId &&
      this.state.obj?.exportPlanId &&
      this.state.themes?.length
    ) {
      // const data = {
      //   questionnaireId: this.state.obj?.parentQuestionnaireId,
      //   themes: this.state.themes,
      //   planId: this.state.obj?.planId,
      //   guestOptions: this.state.guestOptions,
      //   priceObject: this.state.pricing,
      // };
      // let res = await HttpService.updateExportPlan(
      //   this.context.exportPlanId,
      //   data
      // );

      const data = {
        questionnaireId: this.state.obj?.parentQuestionnaireId,
        themes: this.state.themes,
        planId: this.state.obj?.planId,
        guestOptions: this.state.guestOptions,
        priceObject: this.state.pricing,
      };

      await HttpService.updateExportPlanWithEvent(
        this.context.exportPlanId,
        this.state.obj?.eventId,
        data
      );
    }
    this.props.history.push("/admin/events");
  };

  render() {
    //  console.log("guestLabelWithPrice",this.state.guestLabelWithPrice)
    //  console.log("guestOptions",this.state.guestOptions)
    let adults = 0;
    let U6 = 0;
    let U12 = 0;
    let U18 = 0;
    let actualGuestCount = 0;
    // console.log("events",this.state.event)
    const { event } = this.state;

    if (this.state.guestOptions.length) {
      this.state.guestOptions[3].value.forEach((guest) => {
        for (let key in this.state.guestLabelWithPrice) {
          if (guest.checked === true && guest.label === key) {
            actualGuestCount =
              actualGuestCount + Number(this.state.guestLabelWithPrice[key]);
            return actualGuestCount;
          }
        }
      });
    }
    // console.log(actualGuestCount,"actual")
    for (let key in this.state.guestLabelWithPrice) {
      // console.log("key",key)
      if (key === "adults") {
        adults = this.state.guestLabelWithPrice[key];
      }
      if (key === "U6") {
        U6 = this.state.guestLabelWithPrice[key];
      }
      if (key === "U12") {
        U12 = this.state.guestLabelWithPrice[key];
      }
      if (key === "U18") {
        U18 = this.state.guestLabelWithPrice[key];
      }
    }
    // console.log(U6,U12,U18,adults)

    if (!event.adults) {
      event.adults = adults;
    }
    if (!event.U6) {
      event.U6 = U6;
    }
    if (!event.U12) {
      event.U12 = U12;
    }
    if (!event.U18) {
      event.U18 = U18;
    }
    if (!event.actualGuestCount) {
      event.actualGuestCount = actualGuestCount;
    }

    let childQuestionnaireId;
    let parentQuestionnaireId;
    let exportPlanId;
    if (this.props.allowed) {
      childQuestionnaireId = this.props.childQuestionnaireId;
      parentQuestionnaireId = this.props.parentQuestionnaireId;
      exportPlanId = this.props.exportPlanId;
    } else {
      childQuestionnaireId = this.state.obj?.childQuestionnaireId;
      parentQuestionnaireId = this.state.obj?.parentQuestionnaireId;
      exportPlanId = this.state.obj?.exportPlanId;
    }


    // console.log("y",y)
    // console.log("height",this.state.height)
    

    return (
      <div>
        {this.state.loading?
        <Loader/>:<>
        <div>
        {this.context.authenticated || this.props.allowed ? (
          <div className="plan_preview_nav">
            {/* {console.log("plans", this.state.themes)} */}
            <div className="row preview_nav">
              <div className="col-2" 
                >
                <img
                 style={{display:window.location.pathname !== "/planPreview" ? "none":""}}
                  src={home}
                  className="home_img"
                  alt="back"
                  onClick={(e) => {
                    this.handleRedirectToHome(e);
                  }}
                />
              </div>
              <div className="col-6">
              
              </div>
              <div className= "col-4">
                {/* <ReactToPdf 
                        targetRef={ref} 
                        onComplete={this.handleMailPDF} 
                        filename="plans.pdf"
                        options={options}
                        >
                        {({toPdf}) => (
                            <button className="download" onClick={toPdf}>
                              {console.log("pdf", pdf)}
                            
                              <img src={link2} alt=""  />
                            </button>
                        )}
                      </ReactToPdf> */}
                      <button className="download" id="Popover1">
                        <img src={link2} alt="" />
                        {/* <Button id="Popover1" type="button"> */}
                        {/* Launch Popover
        </Button> */}
                        <Popover
                          placement="bottom"
                          isOpen={this.state.popoverOpen}
                          target="Popover1"
                          style={{ left: "-90px" }}
                          toggle={this.toggle}
                        >
                          <PopoverHeader style={{ height: "30px" }}>
                            {/* Copy URL */}
                            <button
                              style={{
                                border: "none",
                                float: "right",
                                position: "absolute",
                                bottom: "165px",
                                right: "5px",
                              }}
                              onClick={this.toggle}
                            >
                              x
                            </button>
                          </PopoverHeader>
                          <PopoverBody>
                            {/* {console.log("href",window.location)} */}
                            <textarea
                              style={{
                                resize: "initial",
                                height: "120px",
                                width: "250px",
                              }}
                              // type="email"

                              ref={(textarea) => (this.textArea = textarea)}
                              value={`${window.location.origin}/email/planpreview/${parentQuestionnaireId}/${childQuestionnaireId}/${exportPlanId}/${this.state.obj?.eventId}`}
                            // onChange={(e) => {
                            //   this.setState({ popOverEmail: e.target.value });
                            // }}
                            />
                          </PopoverBody>
                          <div>
                            {this.state.copySuccess}
                            <button
                              className="download"
                              style={{ marginTop: "0px" }}
                              onClick={this.copyToClipboard}
                            // onClick={this.handleMailPDF}
                            >
                              {this.props.t('Kopieren')}
                            </button>
                          </div>
                        </Popover>
                      </button>
                      {/* {console.log("ref",ref)} */}
                      {/* <ReactToPdf
                  targetRef={ref}
                  filename="plan.pdf"
                  options={options}
                >
                  {({ toPdf }) => (
                    <button
                    id="pdf"
                      className="download pdf" onClick={toPdf}>
                      {
                        // console.log('toPdf', toPdf)
                      }
                      <img src={pdf1} alt="" />
                    </button>
                  )}
                </ReactToPdf> */}
  
                <ReactToPrint
                  trigger={() => (
                    <button className="download" disabled={this.state.show}>
                      <img src={print} alt="" disabled={this.state.show}/>
                    </button>
                  )}
                  content={() => ref.current}
                  pageStyle='@page { margin: 20mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px 20px 20px 20px !important;margin-top: -20px !important; } }'
                />
              </div>
            </div>
          </div>
        ) : null}


                      

              {/* {this.context.authenticated || this.props.allowed ? (
          <img
            src={back}
            className="back_to_plan"
            alt=""
            onClick={this.handleBackClick}
          />
        ) : null} */}
            </div>
            {this.state.showExportPopup ? (
              <ExportSectionPopup
                closeExportPopup={this.closeExportPopup.bind(this)}
                questionnaireId={parentQuestionnaireId}
                planId={this.state.obj?.planId}
                data={this.state?.themes}
              />
            ) : null}
            {/* <PreviewPrint ref={ref} />    ref={el => (this.componentRef = el) */}


            <div
              style={{ padding: "0px 40px 40px 40px", maxWidth: "1200px", margin: "0 auto" }}
              id="divToPrint"
              ref={ref}
            >
              <div className="plan_preview_info"
              // style={{display:window.location.pathname !== "/planPreview" ? "none":""}}
              >
                {/* {console.log("guestoptions",this.state.guestOptions)} */}
                {this.state.guestOptions?.map((guest) => {
                  //  console.log("guest",guest)

                  return (
                    <>
                      <span>
                        {(guest.name === "Event Name" ||
                          guest.name === "Eventname") &&
                          guest.checked ? (
                          <>
                            {this.state.event?.eventName} &nbsp; &nbsp;|&nbsp;&nbsp;
                          </>
                        ) : (
                          ""
                        )}
                        {(guest.name === "Date" || guest.name === "Eventdatum") &&
                          guest.checked ? (
                          <>
                            {" "}
                            <Moment format="DD.MM.YYYY">
                              {this.state.event.eventDate}
                            </Moment>
                            &nbsp; &nbsp;| &nbsp;
                          </>
                        ) : (
                          ""
                        )}

                        {(guest.name === "Venue" ||
                          guest.name === "Kontaktperson") &&
                          guest.checked ? (
                          <>
                            {this.state.event.eventCustomer} &nbsp;
                            &nbsp;|&nbsp;&nbsp;
                          </>
                        ) : (
                          ""
                        )}
                        {(guest.name === "Number Of Guest" ||
                          guest.name === "Anzahl Gäste") &&
                          guest.checked ? (
                          <>
                            {this.state.event?.actualGuestCount || 0} Gäste &nbsp;
                            &nbsp;| &nbsp;
                            {guest?.value.map((val) => {
                              return (
                                <>
                                  {val.checked ? (
                                    <>
                                      {this.state.event[val.label] || 0} {val.name}{" "}
                                      &nbsp; &nbsp;| &nbsp;{" "}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          ""
                        )}

                        {(guest.name === "VVO" || guest.name === "Eventmanager") &&
                          guest.checked ? (
                          <>VVO : {this.state.event?.vvoName || "--"}</>
                        ) : (
                          ""
                        )}
                      </span>
                    </>
                  );
                })}
              </div>

              {this.state.themes?.length
                ? this.state.themes.map((theme, j) => {
                  return (
                    <div
                      className="event_plan_details"
                      style={{
                        border: theme?.display === false && "none",
                        padding: theme?.display === false && "0px",
                      }}
                      key={j}
                    >
                      {theme?.display === true && theme.priceObject ? (
                        <div
                          style={{
                            display: "grid",
                            marginBottom: "10px",
                          }}
                        >
                          {theme.priceObject.map((price) => {
                            return this.state.guestOptions?.map((guest) => {
                              return guest.name === "Number Of Guest" ||
                                guest.name === "Anzahl Gäste" ? (
                                guest?.value.forEach((val) => {
                                  if (
                                    price.checked === true &&
                                    price.name === val.name
                                  ) {
                                    return (
                                      <span>
                                        {this.state.guestLabelWithPrice &&
                                          this.state.guestLabelWithPrice[
                                          val.label
                                          ] !== undefined
                                          ? ` ${this.state.guestLabelWithPrice[
                                          val.label
                                          ]
                                          } - ${price?.name}`
                                          : price.name}
                                      </span>
                                    );
                                  }
                                })
                              ) : (
                                <></>
                              );
                            });
                          })}
                        </div>
                      ) : (
                        <></>
                      )}

                      {theme?.divs?.map((div, i) => {
                        if (theme?.display === true) {
                          if (div?.textClicked) {
                            var x = draftToHtml(
                              convertToRaw(
                                EditorState.createWithContent(
                                  convertFromRaw(div?.editorStateText)
                                ).getCurrentContent()
                              )
                            );
                            return (
                              <div key={i} style={{ marginBottom: "10px" }}>
                                <span
                                  dangerouslySetInnerHTML={{ __html: x }}
                                ></span>
                              </div>
                            );
                          } else if (div.noteClicked) {
                            //        if(Object.keys(div.editorStateNote).includes("blocks")){
                            //         return <div style={{border:"1px solid black"}}>
                            //         <Editor key={div.divId}
                            //         placeholder="Note"
                            //         toolbarOnFocus={!div.hideToolbar}
                            //         toolbarHidden={div.hideToolbar}
                            //         editorState={EditorState.createWithContent(convertFromRaw(div.editorStateNote))}
                            //         toolbarClassName="toolbarClassName"
                            //         wrapperClassName="wrapperClassName"
                            //         editorClassName="editorClassName"
                            //         onFocus={() => {
                            //           this.handleOnFocus(theme.themeId, div.divId)
                            //         }}
                            //         onBlur={() => {
                            //           this.handleOnBlur(theme.themeId, div.divId)
                            //         }}
                            //         onEditorStateChange={(e)=>{this.onEditorStateNoteChange(theme.themeId, div.divId, e)}}
                            //       />
                            //       </div>
                            // }
                            // else{
                            //     return <div style={{border:"1px solid black"}}>
                            //     <Editor key={div.divId}
                            //     placeholder="Note"
                            //         toolbarOnFocus={!div.hideToolbar}
                            //         toolbarHidden={div.hideToolbar}
                            //         editorState={div.editorStateNote}
                            //         toolbarClassName="toolbarClassName"
                            //         wrapperClassName="wrapperClassName"
                            //         editorClassName="editorClassName"
                            //         onFocus={() => {
                            //           this.handleOnFocus(theme.themeId, div.divId)
                            //         }}
                            //         onBlur={() => {
                            //           this.handleOnBlur(theme.themeId, div.divId)
                            //         }}
                            //         onEditorStateChange={(e)=>{this.onEditorStateNoteChange(theme.themeId, div.divId, e)}}
                            //       />
                            //       </div>
                            // }
                            if (
                              this.context.userRole === "VA" ||
                              this.context.userRole === "VM"
                            )
                              return (
                                <>
                                  {/* {  console.log("text",div.note)} */}

                                  <TextareaAutosize
                                    // id="clickbox"
                                    key={div.divId}
                                    style={{
                                      width: "100%",
                                      marginBottom: "10px",
                                      whiteSpace: "pre-line",
                                      // display:
                                      //   this.state.display == "textareaDiv" &&
                                      //   "none",
                                    }}
                                    type="text"
                                    name="note"
                                    placeholder={this.props.t("Notizen")}
                                    className="input_field"
                                    value={div.note}
                                    onBlur={() => this.pdfClick(div.note)}
                                    onChange={(e) =>
                                      this.setTextInputValue(
                                        theme.themeId,
                                        div.divId,
                                        e
                                      )
                                    }
                                  />
                                  {/* <div
                                    key={div.divId}
                                      // id="clickbox2"
                                      style={{ background: "#e6e7e8" }}
                                      onClick={this.displayTextarea}
                                     
                                    >
                                      <span
                                        style={{
                                          whiteSpace: "pre-line",
                                          color: !div && "grey",
                                          height: "30px ",
                                          display:
                                            this.state.display == "" && "none",
                                        }}
                                      >
                                        {div.note ? div.note : "Note"} <br />
                                      </span>
                                    </div> */}
                                  {/* {div.note.split("<br/>").map((div) => {
                                  // console.log("div",div)

                                  return (
                                    <div
                                    key={div.divId}
                                      // id="clickbox2"
                                      style={{ background: "#e6e7e8" }}
                                      onClick={this.displayTextarea}
                                     
                                    >
                                      <span
                                        style={{
                                          whiteSpace: "pre-line",
                                          color: !div && "grey",
                                          height: "30px ",
                                          display:
                                            this.state.display == "" && "none",
                                        }}
                                      >
                                        {div ? div : "Note"} <br />
                                      </span>
                                    </div>
                                  );
                                })} */}
                                </>
                              );
                            else {
                              return (
                                <textarea
                                  key={div.divId}
                                  disabled
                                  style={{ height: "50px", marginBottom: "10px" }}
                                  type="text"
                                  name="note"
                                  placeholder={this.props.t("Notizen")}
                                  className="input_field"
                                  value={div.note}
                                  onChange={(e) =>
                                    this.setTextInputValue(
                                      theme.themeId,
                                      div.divId,
                                      e
                                    )
                                  }
                                />
                              );
                            }
                          } else if (div.infoClicked) {

                            // console.log("export",this.state.exportThemes[j].divs[i])
                            return this.renderOption(
                              div?.selectedDropdown,
                              div?.divId,
                              div?.data,
                              theme,
                              this.state.exportThemes[j]?.divs[i]?.data
                            );
                          } else {
                            // this.shouldDisplayEmpty(theme, j)
                            return null;
                          }
                        } else {
                          return null;
                        }
                        //  this.renderAttachedOption(
                        //   div.selectedDropdown,
                        //   div.divId,
                        //   div?.data,
                        //   theme
                        // )
                      })}
                    </div>
                  );
                })
                : null}
              <div className="attachment ">
                {this.state.isDisplay !== "false" ? <>
                  {this.state.uploadAttachment?.map((file) => {
                    return (
                      <div>
                        {file.type === "jpeg" ||
                          file.type === "jpg" ||
                          file.type === "png" ||
                          file.type === "image" ? (
                          <a
                              target="_blank"
                              rel={"noopener noreferrer"}
                            style={{ padding: "20px" }}
                            href={`${file.url}`}
                          >
                            {/* {console.log("input",input)} */}
                            <img
                              src={file.type === "pdf" ? docIcon : `${file.url}`}
                              alt=""
                              style={file.type === "pdf" ? styles.pdf : styles.image}
                            //  style={{ width: "100px" }}
                            />
                          </a>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
                </> : <></>}

                {this.state.isDisplayDownload !== "false" ? <>{this.state.downloadAttachment?.map((file) => {
                  return (
                    <div>
                      {file.type === "jpeg" ||
                        file.type === "jpg" ||
                        file.type === "png" ||
                        file.type === "image" ? (
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                          style={{ padding: "20px" }}
                          href={`${file.url}`}
                        >
                          {/* {console.log("input",input)} */}
                          <img
                            src={file.type === "pdf" ? docIcon : `${file.url}`}
                            alt=""
                            style={file.type === "pdf" ? styles.pdf : styles.image}
                          //  style={{ width: "100px" }}
                          />
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
                </> : <></>}
              </div>
              {/* <img src={camera} alt=""/> */}
            </div>

        <div></div>
      </>}
      </div>
    );
  }
  //  componentDidUpdate(prevProps, prevState) {
  //   console.log(prevProps,prevState)
  //  this.setState({show:false})
  // }
}


export default withTranslation()(PlanPreview);
const styles = {
  image: {
    // display: "flex",
    // width: "50%",
    // height: "auto",
    height: "800px",
    // margin: "auto",
    width: "900px",
    textAlign: "center",
    // top:"40px",
    // paddingBottom:"20px",
    paddingTop: "40px",
    position: "relative",
    right: "90px",
    // maxHeight: "360px",
    // objectFit: "contain",
    // paddingLeft: "10px",
    // width: "3508px",
    // height: " 2480px",
  },
  pdf: {
    display: "block",
    width: "100px",
    height: "100px",
    margin: "auto",
    objectFit: "contain",
    paddingLeft: "10px",
  },
};

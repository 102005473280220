import React, { Component } from "react";
import SideBar from "../partials/SideBar";
import { StateContext } from "../../context/state";
import Form from "../partials/Form";
import { v4 as uuidv4 } from "uuid";
import HttpService from "../../services/HttpService";
import Features from "../partials/Features";
export default class Site extends Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.state = {
      payload: {},
      featurePayload:{},
      dragData: "",
      response: {},
      plan: "clickedSunnyPlan",
      draggedItems:[],
      sunnyPlanUrl:"",
      rainyPlanUrl:"",
      room: {
        roomId: uuidv4(),
        childQuestionnaireId: "",
        name: "",
        dimensions: "",
        notes: "",
        roomType: "",
      },
      // rooms: [{roomId:uuidv4(), name: "dh", dimensions: "we", notes: "ew", roomType: "event", childQuestionnaireId: "60252b20e4c6840d78b58d5e" },
      // { roomId:uuidv4(),name: "367", dimensions: "36 * 67", notes: "ew", roomType: "event", childQuestionnaireId: "60252b20e4c6840d78b58d5e" },
      // { roomId:uuidv4(),name: "dh", dimensions: "we", notes: "ew", roomType: "event", childQuestionnaireId: "60252b20e4c6840d78b58d5e" },
      // { roomId:uuidv4(),name: "sbj", dimensions: "we", notes: "ew", roomType: "restricted", childQuestionnaireId: "60252b20e4c6840d78b58d5e" },
      // { roomId:uuidv4(),name: "dd", dimensions: "we", notes: "ew", roomType: "event", childQuestionnaireId: "60252b20e4c6840d78b58d5e" }],
      rooms: [],
      showRoom: false,
      feature:[]
    };
  }

  componentDidMount = async () => {
    const obj=JSON.parse(sessionStorage.getItem("state2"))
  
    
      if (window.location?.pathname === "/planner-screen") {
     let response = await HttpService.getRoomsEventLevel(obj?.eventId, true);
    //  console.log("response",response)
        this.setState({ rooms:response.rooms, response ,feature:response.sitePlan.feature,featurePayload:response});

      }
      else{

        let rooms = await HttpService.getRooms(this.context.venueId);
         const getPlan = await HttpService.getSitePlan(this.context.venueId, true);
         this.setState({ rooms:rooms, response:getPlan });

      }
    

    // console.log("response", getPlan);
  };
 
  saveFeatures=async(feature)=>{
    const obj=JSON.parse(sessionStorage.getItem("state2"))
    const {featurePayload,plan}=this.state
    featurePayload.sitePlan.feature=feature
    // console.log("feature",featurePayload.sitePlan.feature)
    if (plan === "clickedSunnyPlan") {
      // console.log("in this",featurePayload)
      featurePayload.isSunnyPlan=true
      featurePayload.eventId=obj?.eventId
     const result=await HttpService.putEventPlan(this.state.featurePayload, obj?.eventId, true);
     if(result){
      let response = await HttpService.getRoomsEventLevel(obj?.eventId, true);
      // console.log("feature",response.sitePlan.feature)
      this.setState({ rooms:response.rooms, response ,feature:response.sitePlan.feature,featurePayload:response});
     }
     
    } else {
      featurePayload.isSunnyPlan=false
      featurePayload.eventId=obj?.eventId
      const result =await HttpService.putEventPlan(
        this.state.featurePayload,
        obj?.eventId,
        false
      );
      if(result){
        let response = await HttpService.getRoomsEventLevel(obj?.eventId, false);
        this.setState({ rooms:response.rooms, response ,feature:response.sitePlan.feature,featurePayload:response});
      }
    
    }
  }
  
  savePayload = (payload,feature) => {
if(feature){
  // console.log("payload",payload)
  payload.sitePlan.feature=this.state.feature
  this.setState({ featurePayload:payload},()=>{
    // console.log("payload",this.state.featurePayload)

  });

}
else{
  // console.log("payload",payload)
  this.setState({ payload });

}
  };
  dragFeatureData=(e,index)=>{
    e.dataTransfer.setData("featureIndex", index);
  }
  onDrag = (e, index) => {
    e.dataTransfer.setData("index", index);
  };
  displayRoom = (val) => {
    this.setState({ showRoom: val });
  };
  clickedPlan = async (plan) => {
    const obj=JSON.parse(sessionStorage.getItem("state2"))

    if (plan === "clickedSunnyPlan") {
      if (window.location?.pathname === "/planner-screen") {
        let response = await HttpService.getRoomsEventLevel(obj?.eventId, true);
           this.setState({  plan: "clickedSunnyPlan", response ,feature:response.sitePlan.feature,featurePayload:response });
   
         }
         else{
          const getPlan = await HttpService.getSitePlan(this.context.venueId, true);
          this.setState({ plan: "clickedSunnyPlan", response: getPlan });
         }
    
    } else {
      if (window.location?.pathname === "/planner-screen") {
        let response = await HttpService.getRoomsEventLevel(obj?.eventId, false);
           this.setState({  plan: "clickedRainyPlan", response ,feature:response.sitePlan.feature,featurePayload:response   });
   
         }
         else{
          const getPlan = await HttpService.getSitePlan(
            this.context.venueId,
            false
          );
          this.setState({ plan: "clickedRainyPlan", response: getPlan });  
         }
     
    }
  };
  savePlan = async () => {
      const obj=JSON.parse(sessionStorage.getItem("state2"))
    const { plan, response,featurePayload } = this.state;
    if(window.location?.pathname  !== "/planner-screen"){
    if (Object.keys(response).length===0) {
      HttpService.postSitePlan(this.state.payload,this.context.venueId);
    } else {
      if (plan === "clickedSunnyPlan") {
        HttpService.putSitePlan(this.state.payload, this.context.venueId, true);
      } else {
        HttpService.putSitePlan(
          this.state.payload,
          this.context.venueId,
          false
        );
      }
    }
  }
  else{
    // console.log("plan",plan)
    if (plan === "clickedSunnyPlan") {
      // console.log("in this",this.state.featurePayload.sitePlan.feature)
      featurePayload.isSunnyPlan=true
      featurePayload.eventId=obj?.eventId
      // featurePayload.sitePlan.feature=[]
      // console.log(featurePayload,"featurePayload")
  const result=await HttpService.putEventPlan(this.state.featurePayload, obj?.eventId, true);
  if(result){
    // console.log("result",result)
    let response = await HttpService.getRoomsEventLevel(obj?.eventId, true);
    // console.log("response",response)
    this.setState({ rooms:response.rooms,sunnyPlanUrl: response.sitePlan.sunnyPlanUrl, response ,feature:response.sitePlan.feature,featurePayload:response});
   }
    } else {
      featurePayload.isSunnyPlan=false
      featurePayload.eventId=obj?.eventId
     const result=await HttpService.putEventPlan(
        this.state.featurePayload,
        obj?.eventId,
        false
      );
      if(result){
        let response = await HttpService.getRoomsEventLevel(obj?.eventId, false);
        // console.log("response",response)
        this.setState({ rooms:response.rooms,rainyPlanUrl: response.sitePlan.rainyPlanUrl, response ,feature:response.sitePlan.feature,featurePayload:response});
       }
    }
  }
  };
  addRooms = async (room) => {
    const { rooms } = this.state;
    // const obj=JSON.parse(sessionStorage.getItem("state2"))
    let result;
    const bool = rooms.filter((r) => r.roomId === room.roomId);
    var response;
    // console.log("bool",bool)
    // console.log("context", this.context)
    if (bool.length === 0) {
      response = await HttpService.postRoom(room);
      // console.log("response",response)
      if (response.insertedId) {
        result = await HttpService.getRooms(this.context.venueId);
      }
      // room.insertedId=response.insertedId
    } else {
      HttpService.putRoom(room);
      result = await HttpService.getRooms(this.context.venueId);
    }
    this.setState({ rooms: result });
  };
  sendOneRoom = (room) => {
    this.setState({ room });
  };

  render() {
    return (
      <div className="d-flex">
        <div className={`${window.location?.pathname  === "/planner-screen" ? `col-2 pl-0` :`col-3 pl-4 `} col-3 pl-4 pr-0 themesWrapper`}>
          <div
            className="main-company-logo d-flex align-items-center"
            style={{ top: "85px", position: "relative" }}
          >
            <SideBar
              displayRoom={this.displayRoom}
              rooms={this.state.rooms}
              sendOneRoom={this.sendOneRoom}
              dragData={this.onDrag}
            />
          </div>
        </div>

        <div
          className="content pl-0 pr-2
           align-items-center"
          style={{
            background: "#f0eff0",
            // position: "relative",
          }}
        >
          <div>
            <div className="pl-3 pr-3">
              <h5 style={{ display: "flex", justifyContent: "center" }}>
                Site Plan
              </h5>
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  width: "600px",
                  margin: "auto",
                }}
              >
                Manage your guest RSVPs. Switch to advanced mode to see more
                options for each guest.Also you can import guest list from CSV
              </span>
            </div>
          </div>
        </div>
{/* {console.log("this?.props?.location?.pathnam",this?.props?.location?.pathnam)} */}
     
          {window.location?.pathname  === "/planner-screen" ?
             <div
             className="col-3 pl-2 priceWrapperPricebar"
             style={styles.priceWrapper}
           >
        <Features
        savePlan={this.savePlan}
        saveFeatures={this.saveFeatures}
        dragFeatureData={this.dragFeatureData}
        features={this.state.feature}
        /> 
        </div>
        :
        <div
        className="col-2 pl-0 priceWrapperPricebar"
        style={styles.priceWrapper}
      >
 <Form
            savePlan={this.savePlan}
            showRoom={this.state.showRoom}
            addRooms={this.addRooms}
            room={this.state.room}
          />
            </div>
        }
         
      
      </div>
    );
  }
}

const styles = {
  outline: {
    color: "#297825",
    fontFamily: "Crete Round",
    fontSize: "13px",
    border: "1px solid #297825",
    // minWidth: "143px",
    padding: "9px 15px",
    textAlign: "center",
    display: "inline-block",
    marginRight: "15px",
    float: "right",
  },
  senderAddress: {
    height: "17.7px",
    marginRight: "10px",
    width: "80px",
    display: "contents",
    flexWrap: "wrap",
    // font: "Crete Round",
  },
  senderAddress2: {
    height: "17.7px",
    marginRight: "10px",
    width: "80px",
    display: "flex",
    flexWrap: "wrap",
    // marginTop: "20px",
    paddingTop: "44px",
    // fontStyle: "Crete Round",
    //  fontFamily: 'Roboto'
  },
  recipientAddress: {
    height: "27.3px",
    marginRight: "10px",
    width: "80px",
    display: "contents",
    flexWrap: "wrap",
  },
  solid: {
    background: "#297825",
    fontFamily: "Crete Round",
    border: "1px solid #297825",
    color: "#fff",
    fontSize: "13px",
    // minWidth: "143px",
    padding: "9px 15px",
    textAlign: "center",
    display: "inline-block",
    marginRight: "15px",
  },
  stepsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  headerImage: {
    width: "100%",
    height: "175px",
    objectFit: "fill",
  },
  input: {
    border: 0,
    padding: 0,
    margin: 0,
    background: "transparent",
    borderBottom: "1px solid #707070",
    // width: "200px",
  },
  inputLabel: {
    // fontFamily: "Crete Round",
    // fontStyle: "Italic",
    color: "#95969b",
    fontSize: "14px",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
  },

  flexRow: {
    flexDirection: "row",
    width: "70%",
  },
  priceWrapper: {
    // position: "absolute",
    right: "160px",
    // top: "0",
    // display: "block",
    // background: "#e6e7e8",
    background: "rgb(240, 239, 240) none repeat scroll 0% 0%",
    // width: "360px",
    minHeight: "100vh",
    transition: "0.3s",
    zIndex: "30",
    paddingTop: "80px",
  },

  loader: {
    color: "#888888",
    fontWeight: "bold",
    fontSize: "20px",
    padding: "20px",
    textAlign: "center",
  },
  logo: { width: "150px", height: "150px", objectFit: "contain" },
  addRow: {
    width: "100%",
    height: "50px",
    border: "none",
    background: "#E6E7E8 0% 0% no-repeat padding-box",
    marginTop: "20px",
    fontSize: "40px",
    color: "#939598",
  },
  price: {
    float: "right",
    marginTop: "30px",
  },
  divider: {
    borderTop: "1px solid #d0d3d4",
    margin: "30px 0 0px 0px",
    paddingTop: "10px",
  },
 
  textStyle: {
    font: "normal 49px / 62px Roboto",
    fontWeight: "bold",
    textAlign: "left",

    letterSpacing: "0px",
    color: " #00000",
  },
  smallText: {
    // textAlign: "right",
    font: "italic normal normal normal 20px/29px Roboto",
    letterSpacing: "0px",
    color: "#00000066",
    opacity: "1",
  },
};

import React, { Component } from "react";
import axios from "axios";
import { StateContext } from "../../context/state";
import { baseUrl } from "../../Constants.js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation} from 'react-i18next';

// const d={
//     textInput:{
//                 inputs:[{
//                     id: 1,
//                     interactive: false,
//                     numeric: true,
//                     stages: "11",
//                     maximum: "33",
//                     value:"hello"
//                     }],
//             length: 1
//         },
//             type: "text"
// }
class TextOption extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      newData: {},
    };
  }
  componentDidMount() {
    const option = this.props.option;
    // console.log("option",option);
    // console.log("text prop div data",this.props.divData);
    console.log('api', `${baseUrl}api/export/ServiceElement/${this.context.questionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`)
    axios
      .get(
        `${baseUrl}api/export/ServiceElement/${this.props.questionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`
        )
      .then((res) => {
        const d = res.data;
        // console.log("response-text",d);
        const dd = d?.textInput?.inputs?.map((input,index) => {
          // input.alternate = "";
          input.alternate = input.alternate? input.alternate:this.props.divData?.textInput?.inputs[index].alternate
          console.log("inut alternate",input.alternate);
          return { ...input };
        });
        if(d.textInput){
          d.textInput.inputs = dd;
        }
        
        this.setState({
          data: d,
          newData: { ...this.state.newData, ...d },
        });
        this.props.updateChange({...d },this.props.divId);
      })
      .catch((err) => {
        console.log(err);
      });

      // console.log("text-newdata", this.state.newData);
  }
  handleChangeOptionAlt = (id, e) => {
    const dummy = Object.assign({}, this.state.newData);
    dummy.textInput.inputs.map((input) => {
      if (input.id === id) {
        return (input.alternate = e.target.value);
      } else {
        return input;
      }
    });
    this.setState({
      newData: dummy,
    });
   
    this.props.updateChange(dummy,this.props.divId);
  };

  render() {
    return (
      <div>
        {this.state.newData.textInput?.inputs.map((input, i) => {
          
       
       
          return (
            <div key={i} style={{ display: "flex", width: "100%" }}>
              <div className="quest1">
                {input?.interactiveText}
                {/* {console.log("input",input)} */}
                {/* {input.value} */}

                {/* <div key={i}>
                  <span dangerouslySetInnerHTML={{ __html: x }}></span>
                </div> */}
              </div>
              <input
                type="text"
                className="inp1"
                placeholder={this.props.t("Ersatztext")}
                value={input.alternate}
                onChange={(e) => {
                  this.handleChangeOptionAlt(input.id, e);
                }}
              />
                {/* {this.props.t('Interaktiver Text')}  */}
                {/* {input?.interactiveText} */}
              
            </div>
          );
        })}
      </div>
    );
  }
}

export default  withTranslation() (TextOption);

import React, { Component } from "react";
import RadioOption from "./RadioOption";
import RadioMultipleOption from "./RadioMultipleOption";
import Text from "../../Text";
import { StateContext } from "../../../../context/state";
export default class RadioButton extends Component {
  static contextType = StateContext;
  onUpdate = (answer) => {
    const { option, onUpdate } = this.props;
    option.answer = answer;

    onUpdate(option);
  };
  onUpdateWithoutAPICall = (answer) => {
    const { option, onUpdateWithoutAPICall } = this.props;
    option.answer = answer;

    onUpdateWithoutAPICall(option);
    
  };
  render() {
    const headingFontFamily = this.context.stylingData?.headingFontFamily;
    const headingFontSize = this.context.stylingData?.headingFontSize;
    // const headingColor =this.context.stylingData?.["headingColor"] ? this.context.stylingData?.headingColor : "#939598";
    const headingColor = this.context.stylingData?.textColor ? this.context.stylingData?.textColor :"#939598";

    let {
      option: {
        radioInput: { question, multipleSelection, inputs },
        answer,
      },
    } = this.props;

    question = question || "Question?";
    inputs = inputs || [];

    return (
      <>
        <Text
          value={question}
          style={{
            fontFamily: `${headingFontFamily}`,
            fontSize: `${headingFontSize}px`,
            color :`${headingColor}`,
            padding:"0",
            paddingTop:"10px",
            paddingBottom:"10px"
          }}
        />

        {inputs.map((input, index) =>
          multipleSelection === "true" ? (
            <>
           {/* {console.log(this.props.option.radioInput.max)} */}   
            <RadioMultipleOption
            min={this.props.option.radioInput.min}
            max={this.props.option.radioInput.max}
            maxLimit={this.props.option.radioInput.max}
              key={index}
              selectedGuestCount={input.selectedGuestCount}
              {...input}
              answer={answer}
              onUpdate={this.onUpdate}
              onUpdateWithoutAPICall={this.onUpdateWithoutAPICall}
              guestCount={this.props.guestCount} 
              GuestInfoLabel={this.props.guestInfo}
            />
            </>
          ) : (
            <>
 
            <RadioOption
              key={index}
              selectedGuestCount={input.selectedGuestCount}
              {...input}
              answer={answer}
              onUpdate={this.onUpdate}
              onUpdateWithoutAPICall={this.onUpdateWithoutAPICall}
              guestCount={this.props.guestCount} 
              GuestInfoLabel={this.props.guestInfo}
              
            />
            </>
          )
        )}
      </>
    );
  }
}

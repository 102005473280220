import React, { Component } from 'react';
import LeftBar from '../partials/LeftBar';
import RightBar from '../partials/RightBar';
import PullBar from '../partials/PullBar';
import { StateContext } from '../../context/state';
// import closeIcon from "../../assets/cross.svg";
// import plusIcon from "../../assets/plus.svg";
// import DatePicker from "react-datepicker";
import axios from 'axios';
import { baseUrl } from '../../Constants';
import moment from 'moment';
import 'moment-timezone';
import Moment from 'react-moment';
import HttpService from '../../services/HttpService';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw, convertFromRaw } from 'draft-js';
import { withTranslation } from 'react-i18next';
class EventsWelcome extends Component {
	static contextType = StateContext;
	_isMounted = false;
	constructor(props, context) {
		super(props, context);
		this.state = {
			newEntry: true,
			collapsed: true,
			events: [
				// {
				//   name:"firstStep",
				//   text:"",
				//   date: new Date()

				// },
				{
					name: 'secondStep',
					text: '',
					date: this.context.eventCreationDate,
					topText: '',
				},
				{
					name: 'thirdStep',
					text: '',
					date: new Date(),
					topText: '',
				},
				{
					name: 'fourthStep',
					text: '',
					date: this.context.deadlineForEntries,
				},
				{
					name: 'fifthStep',
					text: '',
					date: this.context.eventDate,
				},
				// {
				//   name: "sixthStep",
				//   text: "",
				//   date: this.context.newDate,
				// },
			],
			para: '',
			paragraphs: [],
			editorState: { value: EditorState.createEmpty(), hideTextToolbar: true },
			paragraph: { value: EditorState.createEmpty(), hideTextToolbar: true },
			questionnaireData: {},
			paragraphsNewEntry: true,
			newPara: '',
			collapsed2: true,
			collapsed3: true,
			collapsed4: true,
			collapsed5: true,
			collapsed6: true,
			languageFormat: '',
		};
	}
	componentDidMount() {
		// console.log("events",this.state.events)
		if (window.innerWidth > 1206) this.setState({ collapsed: false });
		window.addEventListener('resize', () => {
			if (window.innerWidth > 1206) this.setState({ collapsed: false });
			else this.setState({ collapsed: true });
		});
		if (window.innerWidth > 948) this.setState({ collapsed2: false });
		window.addEventListener('resize', () => {
			if (window.innerWidth > 948) this.setState({ collapsed2: false });
			else this.setState({ collapsed2: true });
		});
		if (window.innerWidth > 828) this.setState({ collapsed3: false });
		window.addEventListener('resize', () => {
			if (window.innerWidth > 828) this.setState({ collapsed3: false });
			else this.setState({ collapsed3: true });
		});
		if (window.innerWidth < 674) this.setState({ collapsed4: false });
		window.addEventListener('resize', () => {
			if (window.innerWidth < 674) this.setState({ collapsed4: false });
			else this.setState({ collapsed4: true });
		});
		if (window.innerWidth > 1160) this.setState({ collapsed5: false });
		window.addEventListener('resize', () => {
			if (window.innerWidth > 1160) this.setState({ collapsed5: false });
			else this.setState({ collapsed5: true });
		});
		if (window.innerWidth > 496) this.setState({ collapsed6: false });
		window.addEventListener('resize', () => {
			if (window.innerWidth > 496) this.setState({ collapsed6: false });
			else this.setState({ collapsed6: true });
		});
		var sessionStorage_transfer = function (event) {
			if (!event) {
				event = window.event;
			} // ie suq
			if (!event.newValue) return; // do nothing if no value to work with
			if (event.key === 'getSessionStorage') {
				// another tab asked for the sessionStorage -> send it
				localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
				// the other tab should now have it, so we're done with it.
				localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
			} else if (event.key === 'sessionStorage' && !sessionStorage.length) {
				// another tab sent data <- get it
				var data = JSON.parse(event.newValue);
				for (var key in data) {
					sessionStorage.setItem(key, data[key]);
				}
			}
		};

		// listen for changes to localStorage
		if (window.addEventListener) {
			window.addEventListener('storage', sessionStorage_transfer, false);
		} else {
			window.attachEvent('onstorage', sessionStorage_transfer);
		}
		this._isMounted = true;
		const data = this.context;

		axios
			.get(`${baseUrl}api/event/Welcome/${data.parentQuestionnaireId}`)
			.then((res) => {
				// console.log('get res', res.data)

				if (res.data?.events) {
					let ev = Object.assign([], res.data?.events);
					// ev[4].date= new Date().toISOString();
					this.setState({
						events: res.data.events.length && ev,
						// paragraphs:res.data.paragraphs?res.data.paragraphs:[],
						newEntry: res.data.events.length ? false : true,
					});
				} else {
					axios
						.get(
							`${baseUrl}api/questionnaireSetting/${data.parentQuestionnaireId}`
						)
						.then((res) => {
							const ev = Object.assign([], this.state.events);
							ev[4].date = new Date().toISOString();
							if (res.data.vvoAssignmentDate) {
								ev[1].date = moment(this.context.eventDate).subtract(
									Number(res.data.vvoAssignmentDate),
									'days'
								);

								// console.log('date', moment(this.context.eventDate).subtract(Number(res.data.vvoAssignmentDate), "days"))
								this.setState({
									newEntry: true,
									events: ev,
								});
							} else {
								this.setState({
									newEntry: true,
								});
							}
						})
						.catch((err) => {
							this.setState({
								newEntry: true,
							});
						});
				}
			})
			.catch((err) => {
				console.log(err);
			});
		axios
			.get(`${baseUrl}api/questionnaireSetting/${data.parentQuestionnaireId}`)
			.then((res) => {
				// console.log("res",res)
				let dummyPara = {
					value: EditorState.createEmpty(),
					hideTextToolbar: true,
				};

				let string = '';
				if (res.data.Questionaireparagraphs.length) {
					// console.log("res.data.Questionaireparagraphs",res.data.Questionaireparagraphs[0])
					for (let i of res.data.Questionaireparagraphs) {
						string = string + i;
					}
				}
				if (res.data.Questionaireparagraphs.blocks) {
					// console.log("in if")
					// console.log("res.data.Questionaireparagraphs",res.data.Questionaireparagraphs)
					dummyPara.value = EditorState.createWithContent(
						convertFromRaw(res.data.Questionaireparagraphs)
					);
				} else {
					// console.log(string,"string")
					dummyPara.value = EditorState.createWithContent(
						ContentState.createFromText(string)
					);
				}
				// console.log("res.data.Questionaireparagraphs",res.data.Questionaireparagraphs)
				//     if(res.data.Questionaireparagraphs){
				//       // console.log("in if")
				//       // console.log("res.data.Questionaireparagraphs",res.data.Questionaireparagraphs)
				//       dummyPara.value = EditorState.createWithContent(
				//         convertFromRaw(res.data.Questionaireparagraphs)
				//       );
				//     }
				// else{
				//   // console.log("in else")
				//   dummyPara.value= EditorState.createWithContent(ContentState.createFromText())
				// }
				// console.log("dummypara",dummyPara)
				// console.log("res",res.data)
				this.setState({
					questionnaireData: res.data,
					newPara: res.data.Questionaireparagraphs,
					editorState: dummyPara,
					// paragraph:dummyPara,
					paragraphsNewEntry: res.data._id ? false : true,
				});
			})
			.catch((err) => {
				console.log(err);
			});

		axios
			.get(`${baseUrl}api/venue/${data.venueId}`)
			.then((resp) => {
				this.setState({
					languageFormat: resp.data?.languageFormat,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}
	onEditorStateChange = (e) => {
		// console.log(editorState)
		const editorState = Object.assign({}, this.state.editorState);
		editorState.value = e;
		this.setState({
			editorState,
		});
	};
	handleProceed = async () => {
		const data = this.context;

		const postData = {
			questionnaireId: data.parentQuestionnaireId,
			events: this.state.events,
			// paragraphs: this.state.paragraphs,
		};
		let Questionaireparagraphs;

		Questionaireparagraphs = convertToRaw(
			this.state.editorState.value.getCurrentContent()
		);
		// console.log("dummypara", Questionaireparagraphs.blocks[0].text);
		// console.log("questionnairedata", this.state.questionnaireData);

		// if (this.state.questionnaireData.Questionaireparagraphs) {
		//   // this.setState({
		//   //   editorState: Questionaireparagraphs.blocks[0].text,
		//   // });

		// }
		this.state.questionnaireData.Questionaireparagraphs =
			Questionaireparagraphs;
		const reqData = {
			questionnaireId: data.parentQuestionnaireId,
			...this.state.questionnaireData,
			// dummyPara
		};

		if (this.state.newEntry) {
			await HttpService.postEventsPageData(postData);
		} else {
			await HttpService.putEventsPageData(postData, data.parentQuestionnaireId);
		}
		if (this.state.paragraphsNewEntry) {
			await HttpService.postQtnnaireData(reqData);
		} else {
			await HttpService.putQtnnaireData(
				this.state.questionnaireData,
				// dummyPara,
				data.parentQuestionnaireId
			);
		}
		return this.props.history.push('/guests', this.props.location.state);
	};
	//  componentWillUnmount() {
	//   this._isMounted = false;
	// }
	handlePara = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	handleParagraph = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	handleChangingParagraph = (e) => {
		const paragraph = Object.assign({}, this.state.paragraph);
		paragraph.value = e;
		this.setState({
			paragraph,
		});
	};
	handleOnParagraphFocus = () => {
		const editorState = Object.assign({}, this.state.editorState);
		editorState['hideTextToolbar'] = false;
		this.setState({ editorState });
	};
	handleOnParagraphBlur = () => {
		const editorState = Object.assign({}, this.state.editorState);
		editorState['hideTextToolbar'] = true;
		this.setState({ editorState });
	};

	handleChangingPara = (e, i) => {
		const questionnaireData = Object.assign({}, this.state.questionnaireData);
		questionnaireData.Questionaireparagraphs[i] = e.target.value;
		this.setState({
			questionnaireData,
		});
	};
	addPara = (e) => {
		// e.preventDefault();
		const questionnaireData = Object.assign({}, this.state.questionnaireData);
		questionnaireData.Questionaireparagraphs =
			questionnaireData?.Questionaireparagraphs
				? [...questionnaireData?.Questionaireparagraphs, this.state.para]
				: [this.state.para];
		this.setState({
			paragraphs: this.state.paragraphs.concat(this.state.para),
			para: '',
			questionnaireData,
		});
	};

	findIfDatePassed = (name) => {
		const dummy = Object.assign([], this.state.events);
		const dummy2 = dummy.find((d) => {
			return d.name === name;
		});
		// console.log('dummy',moment(dummy2.date).isAfter(new Date()))
		return moment(dummy2.date).isAfter(new Date());
	};
	handleBack = () => {
		this.context.updateState({ eventId: '' });
		if (this.context.userRole === 'CU') {
			this.props.history.push(`/login-landing`);
		} else if (this.props.location?.state?.from === 'eventsPage') {
			this.props.history.push(`/admin/events`);
		} else {
			this.props.history.push(`/admin/questionnaire`);
		}
	};
	functionCapitalise = (initialTitle) => {
		let title = '';
		if (initialTitle) {
			title = initialTitle[0]
				.toUpperCase()
				.concat(initialTitle.slice(1, initialTitle.length));
		}
		return title;
	};

	handleTimelineText = (e, index) => {
		const events = Object.assign([], this.state.events);
		events[index].text = e.target.value;
		this.setState({
			events,
		});
	};
	handleTopText = (e, index) => {
		const events = Object.assign([], this.state.events);
		events[index].topText = e.target.value;
		this.setState({
			events,
		});
	};
	handleLanguageForEditor = () => {
		const lang = this.state.languageFormat;
		switch (lang) {
			case 'de':
				return 'de';
			case 'en':
				return 'en';
			case 'se':
				return 'es';
			default:
				return lang;
		}
	};
	render() {
		const { events } = this.state;
		const { cuDetails } = this.context;
		let date = new Date().toISOString();
		const editorLabels = {
			'components.controls.link.linkTitle': 'Link Text',
			'components.controls.link.linkTarget': 'Link',
			'components.controls.link.linkTargetOption': 'In neuem Fenster öffnen ',
		};

		return (
			<div className="login-event-landing" style={{ marginLeft: '240px' }}>
				<div className="col-2">
					<LeftBar />
				</div>
				<div className="col-10">
					<div
						className="steps-indicator"
						style={{
							width:
								this.state.collapsed2 && this.state.collapsed3
									? '170%'
									: this.state.collapsed2
									? '120%'
									: '100%',
							marginLeft:
								this.state.collapsed2 &&
								this.state.collapsed3 &&
								!this.state.collapsed4
									? '-100px'
									: this.state.collapsed2 && this.state.collapsed3
									? '-150px'
									: this.state.collapsed2
									? '-100px'
									: '0px',
							display: !this.state.collapsed4 ? 'grid' : 'flex',
						}}>
						<div className="second-step">
							&nbsp;&nbsp;
							{/* &nbsp;&nbsp; */}
							{events.map((event, i) => {
								if (event.name === 'secondStep') {
									return (
										<div key={i}>
											{/* {events.length && this.findIfDatePassed("secondStep") ? (
                        <div
                          className="empty-circle"
                          style={{ marginTop: "45px" }}
                        ></div>
                      ) : (
                        <div
                          className="circle"
                          style={{ marginTop: "45px" }}
                        ></div>
                      )} */}
											{events.length && (
												<>
													<input
														type="text"
														style={{
															width: !this.state.collapsed ? '150px' : '100px',
														}}
														value={events[0].topText}
														onChange={(e) => {
															this.handleTopText(e, 0);
														}}
													/>
													<div
														className="empty-circle"
														style={{ marginTop: '20px' }}></div>
												</>
											)}
											<br />
											<input
												type="text"
												style={{
													width: !this.state.collapsed ? '150px' : '100px',
													marginTop: '-1px',
												}}
												value={events[0].text}
												onChange={(e) => {
													this.handleTimelineText(e, 0);
												}}
											/>
										</div>
									);
								} else {
									return null;
								}
							})}
							<span className="line second"></span>
							<div></div>
						</div>
						<div className="third-step">
							<span className="line"></span>
							&nbsp;&nbsp;&nbsp;&nbsp;
							{events.map((event, i) => {
								if (event.name === 'thirdStep') {
									return (
										<div key={i}>
											{/* {events.length && this.findIfDatePassed("thirdStep") ? (
                        <div
                          className="empty-circle"
                          style={{ marginTop: "45px" }}
                        ></div>
                      ) : (
                        <div
                          className="circle"
                          style={{ marginTop: "45px" }}
                        ></div>
                      )} */}
											{events.length && (
												<>
													{' '}
													<input
														type="text"
														style={{
															width: !this.state.collapsed ? '150px' : '100px',
														}}
														value={events[1].topText}
														onChange={(e) => {
															this.handleTopText(e, 1);
														}}
													/>
													<div
														className="empty-circle"
														style={{ marginTop: '20px' }}></div>
												</>
											)}
											<br />
											<input
												type="text"
												style={{
													width: !this.state.collapsed ? '150px' : '100px',
													marginTop: '-1px',
												}}
												value={events[1].text}
												onChange={(e) => {
													this.handleTimelineText(e, 1);
												}}
											/>
										</div>
									);
								} else {
									return null;
								}
							})}
							<span className="line second"></span>
						</div>
						<div
							id="firstDiv"
							className="sixth-step"
							style={{ display: 'none' }}>
							<span className="line"></span>
							&nbsp;&nbsp;&nbsp;&nbsp;
							{/* {events.map((event, i) => {
                if (event.name === "sixthStep") {
                  return (
                    <div key={i}>
                      <span style={{ width: "150px" }}>
                        {this.props.location.state?.from === "eventsPage" ? (
                          <Moment format="DD-MM-YYYY">
                            {date}
                          </Moment>
                        ) : (
                          "--"
                        )}
                      </span>

                      {events.length && this.findIfDatePassed("sixthStep") ? (
                        <div className="empty-circle"></div>
                      ) : (
                        <div className="circle"></div>
                      )}
                      <br />
                      <input
                        type="text"
                        style={{ width: "150px" }}
                        value={events[4].text}
                        onChange={(e) => {
                          this.handleTimelineText(e, 4);
                        }}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })} */}
							<div>
								<span style={{ width: '150px' }}>
									{this.props.location.state?.from === 'eventsPage' ? (
										<Moment format="DD-MM-YYYY">{date}</Moment>
									) : (
										'--'
									)}
								</span>
								<div className="circle"></div>
								<br />
								<span>{this.props.t('Present_day')}</span>
								{/* {events.length ? (
                        <div className="empty-circle"></div>
                      ) : (
                        <div className="circle"></div>
                      )} */}
								{/* <br />
                      <input
                        type="text"
                        style={{ width: "150px" }}
                        value={events[4].text}
                        onChange={(e) => {
                          this.handleTimelineText(e, 4);
                        }}
                      /> */}
							</div>
							<span className="line second"></span>
						</div>
						<div className="fourth-step">
							<span className="line"></span>
							&nbsp;&nbsp;&nbsp;&nbsp;
							{events.map((event, i) => {
								if (event.name === 'fourthStep') {
									return (
										<div key={i}>
											<span style={{ width: '150px' }}>
												{this.props.location.state?.from === 'eventsPage' ? (
													<Moment format="DD-MM-YYYY">
														{this.context.deadlineForEntries}
													</Moment>
												) : (
													'--'
												)}
											</span>

											<br />
											{events.length && this.findIfDatePassed('fourthStep') ? (
												<div className="empty-circle"></div>
											) : (
												<div className="circle"></div>
											)}
											<br />

											<input
												type="text"
												style={{
													width: !this.state.collapsed ? '150px' : '100px',
												}}
												value={events[2].text}
												onChange={(e) => {
													this.handleTimelineText(e, 2);
												}}
											/>
										</div>
									);
								} else {
									return null;
								}
							})}
							<span className="line second"></span>
						</div>

						{/* {Date.parse(date) < Date.parse(this.context.deadlineForEntries) &&
           
  } */}

						<div
							id="secondDiv"
							className="sixth-step"
							style={{ display: 'none' }}>
							<span className="line"></span>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<div>
								<span style={{ width: '150px' }}>
									{this.props.location.state?.from === 'eventsPage' ? (
										<Moment format="DD-MM-YYYY">{date}</Moment>
									) : (
										'--'
									)}
								</span>
								<div className="circle"></div>
								<br />
								<span>{this.props.t('Present_day')}</span>
								{/* {events.length  ? (
                        <div className="empty-circle"></div>
                      ) : (
                        <div className="circle"></div>
                      )} */}
								{/* <br /> */}
								{/* <input
                        type="text"
                        style={{ width: "150px" }}
                        value={events[4].text}
                        onChange={(e) => {
                          this.handleTimelineText(e, 4);
                        }}
                      /> */}
							</div>
							{/* {events.map((event, i) => {
                console.log("event.name",event.name)
                if (event.name === "sixthStep") {
                 
                  return (
                    <div key={i}>
                      <span style={{ width: "150px" }}>
                        {this.props.location.state?.from === "eventsPage" ? (
                          <Moment format="DD-MM-YYYY">
                            {date}
                          </Moment>
                        ) : (
                          "--"
                        )}
                      </span>

                      {events.length && this.findIfDatePassed("sixthStep") ? (
                        <div className="empty-circle"></div>
                      ) : (
                        <div className="circle"></div>
                      )}
                      <br />
                      <input
                        type="text"
                        style={{ width: "150px" }}
                        value={events[4].text}
                        onChange={(e) => {
                          this.handleTimelineText(e, 4);
                        }}
                      />
                    </div>
                  );
                } else {
                  console.log("in else")
                  return null;
                }
              })} */}
							{/* <div>
                      <span style={{ width: "150px" }}>
                        {this.props.location.state?.from === "eventsPage" ? (
                          <Moment format="DD-MM-YYYY">
                            {date}
                          </Moment>
                        ) : (
                          "--"
                        )}
                      </span>

                     
                      
                        <div className="circle"></div>
              </div> */}
							<span className="line second"></span>
						</div>
						<div className="fifth-step">
							<span className="line"></span>
							&nbsp;&nbsp;&nbsp;&nbsp;
							{events.map((event, i) => {
								if (event.name === 'fifthStep') {
									return (
										<div key={i}>
											<span style={{ width: '150px' }}>
												{this.props.location.state?.from === 'eventsPage' ? (
													<Moment format="DD-MM-YYYY">
														{this.context.eventDate}
													</Moment>
												) : (
													'--'
												)}
											</span>

											{events.length && this.findIfDatePassed('fifthStep') ? (
												<div className="empty-circle"></div>
											) : (
												<div className="circle"></div>
											)}
											<br />
											<input
												type="text"
												style={{
													width: !this.state.collapsed ? '150px' : '100px',
												}}
												value={events[3].text}
												onChange={(e) => {
													this.handleTimelineText(e, 3);
												}}
											/>
										</div>
									);
								} else {
									return null;
								}
							})}
							{/* <span className="line second" style={{right:date >= this.context.eventDate && 0}}></span> */}
						</div>
						<div
							id="thirdDiv"
							className="sixth-step"
							style={{ display: 'none' }}>
							<span className="line"></span>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<div>
								<span style={{ width: '150px' }}>
									{this.props.location.state?.from === 'eventsPage' ? (
										<Moment format="DD-MM-YYYY">{date}</Moment>
									) : (
										'--'
									)}
								</span>

								<div className="circle"></div>

								{/* {events.length  ? (
                        <div className="empty-circle"></div>
                      ) : (
                        <div className="circle"></div>
                      )} */}

								<br />
								<span>{this.props.t('Present_day')}</span>
								{/* <input
                        type="text"
                        style={{ width: "150px" }}
                        value={events[4].text}
                        onChange={(e) => {
                          this.handleTimelineText(e, 4);
                        }}
                      /> */}
							</div>
							{/* {events.map((event, i) => {
                if (event.name === "sixthStep") {
                  return (
                    <div key={i}>
                      <span style={{ width: "150px" }}>
                        {this.props.location.state?.from === "eventsPage" ? (
                          <Moment format="DD-MM-YYYY">
                            {date}
                          </Moment>
                        ) : (
                          "--"
                        )}
                      </span>

                     
                      
                      {events.length && this.findIfDatePassed("sixthStep") ? (
                        <div className="empty-circle"></div>
                      ) : (
                        <div className="circle"></div>
                      )}
                      
                      <br />
                      <input
                        type="text"
                        style={{ width: "150px" }}
                        value={events[4].text}
                        onChange={(e) => {
                          this.handleTimelineText(e, 4);
                        }}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })} */}
							{/* <span className="line second"></span> */}
						</div>
					</div>

					<div
						style={{
							marginRight: this.state.collapsed5 ? '0px' : '200px',
							marginLeft: this.state.collapsed5 ? '-100px' : '100px',
						}}>
						<br />
						<h5>
							{cuDetails?.surname1
								? `${
										cuDetails?.title1 === 'frau'
											? `${this.props.t('Sehr geehrte')}`
											: `${this.props.t('Sehr geehrter')}`
								  } ${this.props.t(
										this.functionCapitalise(cuDetails?.title1)
								  )} ${cuDetails?.surname1}${cuDetails?.surname2 ? `,` : ','} `
								: ''}

							{cuDetails?.surname2
								? `${
										cuDetails?.title2 === 'frau'
											? `${this.props.t('Sehr geehrte')}`
											: `${this.props.t('Sehr geehrter')}`
								  } ${this.props.t(
										this.functionCapitalise(cuDetails?.title2)
								  )} ${cuDetails?.surname2},`
								: ''}
						</h5>

						{this.props.history?.location?.state?.from ===
						'questionnairePage' ? (
							<div
								style={{
									border: '1px solid black',
									width: '100%',
									marginBottom: '20px',
									lineHeight: '1.2',
									padding: '10px 0px',
								}}>
								<Editor
									localization={{
										locale: this.handleLanguageForEditor(),
										translations: editorLabels,
									}}
									editorState={this.state.editorState.value}
									onEditorStateChange={this.onEditorStateChange}
									onFocus={this.handleOnParagraphFocus}
									onBlur={this.handleOnParagraphBlur}
									toolbarClassName="toolbarClassName"
									wrapperClassName="wrapperClassName"
									editorClassName="editorClassName"
									toolbar={{
										fontFamily: {
											options: [
												'Arial',
												'Georgia',
												'Impact',
												'Tahoma',
												'Times New Roman',
												'Verdana',
												'Roboto',
											],
										},
									}}
								/>
							</div>
						) : (
							<></>
						)}
						{/* <p className="signature">Ihr Hofgut Lilienhof Team</p> */}

						<div
							className="links-container"
							style={{
								marginTop:
									!this.props.history?.location?.state?.from && '200px',
								display: this.state.collapsed6 ? 'grid' : 'flex',
								// marginLeft:!this.props.history?.location?.state?.from && "200px"
							}}>
							<button className="green-border-btn" onClick={this.handleBack}>
								{this.props.t('return')}
							</button>
							<button className="green-border-btn" onClick={this.handleProceed}>
								{this.props.t('weiter')}
							</button>
						</div>
						<br />
						<br />
					</div>
				</div>

				<RightBar />
				<PullBar />
			</div>
		);
	}
}

export default withTranslation()(EventsWelcome);

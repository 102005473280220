import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { StateContext } from "../context/state";
import CryptoJS from "crypto-js";
import jwt_decode from "jwt-decode";
import { baseUrl } from "../Constants";
import eye from "../assets/eye.svg";
import eyeslash from "../assets/eye-slash.svg";
import { useTranslation } from "react-i18next";

function Login(props) {
  const { t,i18n } = useTranslation();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loginJSON, setLoginJSON] = useState(null);
  const [loginErrorMsg, setLoginErrorMsg] = useState("");
  const [bool, setBool] = useState(false);
  const value = useContext(StateContext);
  const [urlName] = useState(
    !props?.match?.params?.name
      ? "adminScreen"
      : props.match.params.name.toLowerCase()
  );
  const [urlValidation, setUrlValidation] = useState("");

  const setBodyBackground = (backgroundUrl) => {
    let body = document.getElementsByTagName("body")[0];
    body.style.backgroundImage = `url(${backgroundUrl})`;
  };
  //componentDidMount
  const changePassword = () => {
    const password = document.querySelector("#password");

    const type =
      password.getAttribute("type") === "password" ? "text" : "password";

    password.setAttribute("type", type);

    if (password.type === "text") {
      setBool(true);
    } else if (password.type === "password") {
      setBool(false);
    }
  };
  useEffect(() => {
    localStorage.clear('state')
    axios.get(baseUrl + "api/venue/byName/" + urlName).then((res) => {
      const response = res.data;
      if (!Object.keys(response).length) {
        setUrlValidation(t("Bitte geben Sie den richtigen URL-Namen ein"));
      }
      setLoginJSON(response);
      setBodyBackground(response.backgroundImgSrc);
      // if(value.authenticated && value.urlName === urlName){

      // }
      // else{
      //     setBodyBackground(response.backgroundImgSrc);
      // }
      value.updateState({
        venueId: response._id,
        venueLogo: response.venueLogoSrc,
        currencyFormat: response.currencyFormat,
        languageFormat: response.languageFormat,
      });
    });
  }, [urlName]);

  useEffect(() => {
    return () => {
      let body = document.getElementsByTagName("body")[0];
      body.style.backgroundImage = "";
    };
  }, []);

  return (
    <StateContext.Consumer>
      {({ authenticated, venueId, updateState,from }) => {
        // console.log('venueid', venueId, props.match.params.name, urlName )
        if (authenticated && !from) {
          return;
          // return <Redirect to="/login-landing" />
        }

        const postLogin = (e) => {
          e.preventDefault();
          const key = CryptoJS.enc.Utf8.parse("7061737323313233");
          const iv = CryptoJS.enc.Utf8.parse("7061737323313233");
          const encryptedUserName = CryptoJS.AES.encrypt(
            CryptoJS.enc.Utf8.parse(userName),
            key,
            {
              keySize: 128 / 8,
              iv: iv,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }
          );
          const encryptedPassword = CryptoJS.AES.encrypt(
            CryptoJS.enc.Utf8.parse(password),
            key,
            {
              keySize: 128 / 8,
              iv: iv,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }
          );

          axios
            .post(baseUrl + "api/auth/login", {
              UserName: encryptedUserName.ciphertext.toString(
                CryptoJS.enc.Base64
              ),
              Password: encryptedPassword.ciphertext.toString(
                CryptoJS.enc.Base64
              ),
              VenueId: venueId,
            })
            .then((result) => {
              if (result.status === 200) {
                updateState({ authenticated: true, urlName });
                // console.log('token', result.data.token)
                var decodedObj = jwt_decode(result.data.token);
                var role = "";
                var sid = "";
                var lang= "" ;
                var userdata = "";
                var surname = "";
                Object.keys(decodedObj).forEach((key) => {
                  let res = key.split("/");
                  
                  if (res.length > 1) {
                    if (res[res.length - 1] === "role") {
                      role = decodedObj[key];
                      updateState({ userRole: role});
                    }
                    if (res[res.length - 1] === "sid") {
                      sid = decodedObj[key];

                      updateState({ loggedInUserId: sid});
                    }
                    if (res[res.length - 1] === "surname") {
                      surname = decodedObj[key];
                      updateState({ surname });
                    }
                    if (res[res.length - 1] === "userdata") {
                      userdata = decodedObj[key];

                      updateState({ userdata });
                    }
                    if (res[res.length - 1] === "country")
                     {
                      lang = decodedObj[key];
                      // console.log("lang",lang , key);
                      updateState({languageFormat:lang});
                      i18n.changeLanguage(lang);
                    }
                   // sessionStorage.setItem("USER", JSON.stringify({venueId, userRole: role, loggedInUserId: sid, authenticated:true}));
                  }
                });

                axios
                  .get(`${baseUrl}api/user/EventByUser/${sid && sid}`)

                  .then((resp) => {
                    // console.log('data', resp.data)

                    let obj = {};
                    if (resp.data.length === 1 && role === "CU") {
                      resp.data.map((res) => {
                        return Object.assign(obj, res);
                      });

                      updateState({
                        eventId: obj._id,
                        questionnaireId: obj.childQuestionnaireId,
                        eventDate: obj.eventDate,
                        deadlineForEntries: obj.deadlineForEntries,
                        parentQuestionnaireId: obj.parentQuestionnaireId,
                        eventType: obj.type,
                        eventName: obj.eventName,
                      });
                      props.history.push("/EventLanding",{
                        from: "login",
                      });
                    } else if (role === "CU" && resp.data.length !== 1) {
                      props.history.push("/login-landing");
                    } else {
                      props.history.push("/admin/events");
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
            .catch((e) => {
              console.log(e);
              setLoginErrorMsg(t("ungültiger Benutzername oder Passwort"));
            });
        };
        return (
					<div className="login-section-wrapper">
						<span
							style={{
								borderRadius: '3px',
								color: '#ff0000',
								fontSize: '15px',
								marginLeft: '160px',
							}}>
							{urlValidation}
						</span>
						<div className="row">
							<div className="col-md-6 col-sm-12">
								<div className="login-section-left">
									<p
										style={{
											color:
												loginJSON?.welcomeTextColor &&
												loginJSON.welcomeTextColor,
											fontFamily:
												loginJSON?.welcomeTextFont && loginJSON.welcomeTextFont,
											fontSize:
												loginJSON?.loginFontSize && loginJSON.loginFontSize,
											textAlign:
												(loginJSON?.lineFormat === 'text-align-right' &&
													'right') ||
												(loginJSON?.lineFormat === 'text-align-left' &&
													'left') ||
												(loginJSON?.lineFormat === 'text-align-center' &&
													'center'),
										}}>
										{loginJSON != null && loginJSON.welcomeText}
									</p>
									<img
										className="company-logo"
										src={loginJSON != null ? loginJSON.venueLogoSrc : 'false'}
										alt=""
									/>
								</div>
							</div>
							<div className="col-md-6 col-sm-12">
								<form
									className="login-section-right form-group"
									onSubmit={postLogin}>
									<input
										type="text"
										className="form-control"
										style={{ borderRadius: '3px' }}
										placeholder="Email"
										value={userName}
										onChange={(e) => setUserName(e.target.value)}
										autoComplete="off"
									/>

									<input
										id="password"
										type="password"
										className="form-control"
										style={{ borderRadius: '3px' }}
										placeholder={t('Password')}
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										autoComplete="off"
									/>
									{bool === true ? (
										<div onClick={changePassword}>
											<img
												alt={'Eye'}
												id="togglePassword"
												src={eye}
												style={{
													borderRadius: '3px',
													position: 'relative',
													width: '15px',
													height: '10px',
													marginTop: '-110px',
													marginRight: '5px',
												}}
											/>
										</div>
									) : (
										<div onClick={changePassword}>
											<img
												alt={'Eyelash'}
												id="togglePassword"
												// onClick={changePassword}
												src={eyeslash}
												style={{
													borderRadius: '3px',
													width: '15px',
													height: '10px',
													marginTop: '-110px',
													position: 'relative',
													marginRight: '5px',
												}}
											/>
										</div>
									)}

									<div className="error-msg">{loginErrorMsg}</div>
									<button
										className="login-button"
										style={{ borderRadius: '3px' }}
										type="submit">
										{t('log_in')}
									</button>
									{urlName === 'adminScreen' ? (
										<Link to={`/forgot-password`}> {t('Forgot_Password')}</Link>
									) : (
										<Link
											to={`/forgot-password/${urlName}`}
											style={{ pointerEvents: urlValidation.length && 'none' }}>
											{t('Forgot_Password')}
										</Link>
									)}
								</form>
							</div>
						</div>
					</div>
				);
      }}
    </StateContext.Consumer>
  );
}

export default Login;

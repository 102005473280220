import React, { Component } from "react";

const WithNavStateAsProps = (WrappedComponent) => {
  return class propsHOC extends Component {
    params;

    constructor(props) {
      super(props);

      const { location } = props;
      this.params = location?.state;
    }

    render = () => <WrappedComponent {...this.props} {...this.params} />;
  };
};

export default WithNavStateAsProps;

import React, { Component } from "react";
import arrowDown from "../../assets/arrow-small-down.svg";
import axios from "axios";
import { baseUrl } from "../../Constants.js";
import { StateContext } from "../../context/state";
import crossSmallIcon from "../../assets/cross-small.svg";
import plusIcon from "../../assets/plus.svg"
import { withTranslation } from 'react-i18next';

class ThemeLinking extends Component {
  static contextType = StateContext;

  constructor() {
    super();
    this.state = {
      themes: [],
      selectedDropdown: "info",
      data: [],
      putData: {},
    };
  }
  componentDidMount() {
    axios
      .get(`${baseUrl}api/export/elements/${this.props.questionnaireId}`)
      .then((res) => {
        this.setState({
          themes: res.data.themes ? res.data.themes : [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  static getDerivedStateFromProps = (nextProps, prevState) => {
    return {
      data: nextProps.data,
    };
  };
  //   handleLinkOption = (themeId, serviceId, element) => {
  //     document.getElementById("menu").checked = false;
  //     const option = {
  //       themeId,
  //       serviceId,
  //       ...element,
  //     };
  //     let dummyRadioInput = Object.assign({}, this.state.data);
  //     if (dummyRadioInput.planLink) {
  //       dummyRadioInput.planLink = { ...dummyRadioInput.planLink, ...option };
  //     } else {
  //       const planLink = {
  //         ...option,
  //       };
  //       dummyRadioInput = { ...dummyRadioInput, planLink };
  //     }
  //     this.setState({
  //       data: dummyRadioInput,
  //       selectedDropdown: element.optionType,
  //     });
  //     // console.log('linkoption', element)

  //     this.props.updateRadioLink(dummyRadioInput);
  //   };
  //   selectShowHide = (ans) => {
  //     document.getElementById("show").checked = false;
  //     let dummyRadioInput = Object.assign({}, this.state.data);
  //     if (!dummyRadioInput.inputs) {
  //       return;
  //     }
  //     if (dummyRadioInput.planLink) {
  //       console.log("dummyRadioInput", dummyRadioInput);
  //       dummyRadioInput.planLink.display = ans;
  //     } else {
  //       var planLink = {
  //         display: ans,
  //       };
  //       dummyRadioInput = { ...dummyRadioInput, planLink };
  //     }
  //     this.setState({
  //       data: dummyRadioInput,
  //     });
  //     this.props.updateRadioLink(dummyRadioInput);
  //   };
  removeLink = (index) => {
    // console.log('remove', itemIndex)
    let dummyData = Object.assign([], this.state.data);
    // console.log("dummydata",dummyData)
    dummyData[index] = {
      // ...dummyData[index],
      columnIndex:"",
      linkedOptionId: "",
      linkedOptionValue: "",
      optionType: "",
      questionIndex: "",
      rowIndex: "",
      serviceId: "",
      themeId: "",
      display:""
     };
    this.setState({
      data: dummyData,
    });
  
    this.props.updateThemeLink(dummyData);
    
  };
  handleLinkOption = (themeId, serviceId, element, opt, index) => {
    //  console.log("themeId",themeId,"serviceId",serviceId,"element",element,"opt",opt,"index",index)
    document.getElementById(`menu${index}`).checked = false;

    let dummyData = Object.assign([], this.state.data);
    
    dummyData[index] = {
      ...dummyData[index],
      columnIndex: Number(element.columnIndex),
      linkedOptionId: opt.optionId,
      linkedOptionValue: opt.optionValue,
      optionType: element.optionType,
      questionIndex: Number(element.questionIndex),
      rowIndex: Number(element.rowIndex),
      serviceId: serviceId,
      themeId: themeId,
    };
    this.setState({
      data: dummyData,
    });
    // console.log("dummyData",dummyData)
    // console.log('state data',this.state.data)
    this.props.updateThemeLink(dummyData);
  };

  selectShowHide = (ans, index) => {
   
    document.getElementById(`show${index}`).checked = false;
    let dummyData = Object.assign([], this.state.data);
    // console.log("dummyData[index]",dummyData[index])
    // console.log("ans",ans)
    dummyData[index].display = ans;
    this.setState({
      data: dummyData,
    });
    this.props.updateThemeLink(dummyData);
  };

  handleClose = () => {
    this.props.handleClose();
  };
    addingLink = () => {
      let dummyRadioInput = Object.assign([], this.state.data);
      dummyRadioInput.push({
        columnIndex: "",
        linkedOptionId: "",
        linkedOptionValue: "",
        optionType: "",
        questionIndex: "",
        rowIndex: "",
        serviceId: "",
        themeId: "",
        display: "",
      });

      this.setState({
        data: dummyRadioInput,
      });
      this.props.updateThemeLink(dummyRadioInput);
    };
  render() {
    return (
      <div>
        <div>
          
          {this.state.data?.length
            ? this.state.data.map((l, index) => {
                return (
                  <div
                    className="row"
                    key={index}
                    style={{ marginBottom: "5px"}}
                  >
                    <div className="col-6">
                      <div className="navi">
                        <input type="checkbox" id={`show${index}`} />
                        <label
                          className="checkboxLable"
                          name="showHide"
                          htmlFor={`show${index}`}
                          id="navi-icon2"
                        >
                          {l.display !== ""
                            ? l.display
                              ? this.props.t('Feld zeigen')
                              : this.props.t('Feld nicht zeigen')
                            : this.props.t('Aktion')}
                        </label>
                        <div className="multi-level">
                          <ul style={{ padding: "5px" }}>
                            <li
                              style={{ fontSize: "14px", textAlign: "left" }}
                              onClick={() => {
                                this.selectShowHide(true, index);
                              }}
                            >
                              {" "}
                              - {this.props.t('Feld zeigen')}
                            </li>
                            <li
                              style={{ fontSize: "14px", textAlign: "left" }}
                              onClick={() => {
                                this.selectShowHide(false, index);
                              }}
                            >
                              -  {this.props.t('Feld nicht zeigen')}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="navi">
                        <input type="checkbox" id={`menu${index}`} />
                        <label
                        style={{width:"180px"}}
                          className="checkboxLable"
                          htmlFor={`menu${index}`}
                          id="navi-icon2"
                        >
                          {l.linkedOptionValue?.length
                            ? l.linkedOptionValue
                            : this.props.t("Info von Antwort")}
                        </label>
                        <div className="multi-level">
                          {this.state.themes.map((theme, _i) => {
                            return (
                              <div key={theme._id} className="item">
                                <input
                                  type="checkbox"
                                  id={`${index}${theme.name}${_i}`}
                                />
                                <img
                                  src={arrowDown}
                                  className="dropdown_arrow"
                                  alt=""
                                />
                                <label
                                  className="checkboxLable"
                                  style={{
                                    fontSize: "14px",
                                    textAlign: "left",
                                  }}
                                  htmlFor={`${index}${theme.name}${_i}`}
                                >
                                  {theme.name}
                                </label>
                                <ul style={{ fontSize: "14px" }}>
                                  {theme.services.map((service, _s) => {
                                    if (service.elements) {
                                      return (
                                        <li
                                          style={{ fontSize: "14px" }}
                                          key={service._id}
                                        >
                                          <div className="sub-item">
                                            <input
                                              type="checkbox"
                                              id={`${index}${_i}${service.name}${_s}`}
                                            />
                                            <img
                                              src={arrowDown}
                                              className="dropdown_arrow"
                                              alt=""
                                            />
                                            <label
                                              className="checkboxLable"
                                              style={{
                                                fontSize: "14px",
                                                textAlign: "left",
                                              }}
                                              htmlFor={`${index}${_i}${service.name}${_s}`}
                                            >
                                              {/* {console.log("service", service)} */}
                                              {service.name}
                                            </label>
                                            <ul style={{ fontSize: "14px" }}>
                                              {service.elements.map(
                                                (element, i) => {
                                                  return (
                                                    <li
                                                      key={i}
                                                      style={{
                                                        fontSize: "14px",
                                                      }}
                                                    >
                                                      <div className="sub-item">
                                                        <input
                                                          type="checkbox"
                                                          id={`${index}${_i}${_s}${i}`}
                                                        />
                                                        <img
                                                          src={arrowDown}
                                                          className="dropdown_arrow"
                                                          alt=""
                                                        />
                                                        <label
                                                          className="checkboxLable"
                                                          style={{
                                                            fontSize: "14px",
                                                            textAlign: "left",
                                                          }}
                                                          htmlFor={`${index}${_i}${_s}${i}`}
                                                        >
                                                          {element.optionType}
                                                        </label>
                                                        <ul
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                        >
                                                          {element.options.map(
                                                            (option, o) => {
                                                              return (
                                                                <li
                                                                  key={o}
                                                                  onClick={() => {
                                                                    this.handleLinkOption(
                                                                      theme._id,
                                                                      service._id,
                                                                      element,
                                                                      option,
                                                                      index
                                                                    );
                                                                  }}
                                                                >
                                                                  -{" "}
                                                                  {
                                                                    option.optionValue
                                                                  }
                                                                </li>
                                                              );
                                                            }
                                                          )}
                                                        </ul>
                                                      </div>
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </li>
                                      );
                                    } else {
                                      return null;
                                    }
                                  })}
                                </ul>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <button
                  style={{
                    border: "none",
                    borderRadius: "50%",
                    margin: "-3px -10px",
                    height: "23px",
                    position:"absolute"
                    // margin: "8px -15px",
                    // height: "23px",
                  }}
                  onClick={()=>{this.removeLink(index)}}
                >
                  <img src={crossSmallIcon} alt="" />
                </button>
                  </div>
                );
              })
            : null}
          {/* <div className="row ">
            <div className="col-6">
              <div className="navi" style={{ width: "100%" }}>
                <input type="checkbox" id="show" />
                <label className="checkboxLable" htmlFor="show" id="navi-icon2">
                  {this.state.data.link
                    ? this.state.data?.link.display
                      ? "Feld zeige"
                      : "feld nicht zeigen"
                    : "Aktion auswahlen"}
                </label>
                <div className="multi-level">
                  <ul style={{ padding: "5px" }}>
                    <li
                      style={{ fontSize: "15px", textAlign: "left" }}
                      onClick={() => {
                        this.selectShowHide(true);
                      }}
                    >
                      {" "}
                      Feld zeige
                    </li>
                    <li
                      style={{ fontSize: "15px", textAlign: "left" }}
                      onClick={() => {
                        this.selectShowHide(false);
                      }}
                    >
                      feld nicht zeigen
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="navi">
                <input type="checkbox" id="menu" />
                <label className="checkboxLable" htmlFor="menu" id="navi-icon2">
                  {this.state.selectedDropdown}
                </label>
                <div className="multi-level">
                  {this.state.dropdownThemes.map((theme, _i) => {
                    return (
                      <div key={theme._id} className="item">
                        <input type="checkbox" id={`${theme.name}${_i}`} />
                        <img
                          src={arrowDown}
                          className="dropdown_arrow"
                          alt=""
                        />
                        <label
                          className="checkboxLable"
                          style={{ fontSize: "14px", textAlign: "left" }}
                          htmlFor={`${theme.name}${_i}`}
                        >
                          {theme.name}
                        </label>
                        <ul style={{ fontSize: "14px" }}>
                          {theme.services.map((service, _s) => {
                            if (service.elements) {
                              return (
                                <li
                                  style={{ fontSize: "14px" }}
                                  key={service._id}
                                >
                                  <div className="sub-item">
                                    <input
                                      type="checkbox"
                                      id={`${_i}${service.name}${_s}`}
                                    />
                                    <img
                                      src={arrowDown}
                                      className="dropdown_arrow"
                                      alt=""
                                    />
                                    <label
                                      className="checkboxLable"
                                      style={{
                                        fontSize: "14px",
                                        textAlign: "left",
                                      }}
                                      htmlFor={`${_i}${service.name}${_s}`}
                                    >
                                      {service.name}
                                    </label>
                                    <ul style={{ fontSize: "14px" }}>
                                      {service.elements.map((element, i) => {
                                        return (
                                          <li
                                            key={i}
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            <div className="sub-item">
                                              <input
                                                type="checkbox"
                                                id={`${_i}${_s}${i}`}
                                              />
                                              <img
                                                src={arrowDown}
                                                className="dropdown_arrow"
                                                alt=""
                                              />
                                              <label
                                                className="checkboxLable"
                                                style={{
                                                  fontSize: "14px",
                                                  textAlign: "left",
                                                }}
                                                htmlFor={`${_i}${_s}${i}`}
                                              >
                                                {element.optionType}
                                              </label>
                                              <ul
                                                style={{
                                                  fontSize: "14px",
                                                }}
                                              >
                                                {element.options.map(
                                                  (option, o) => {
                                                    return (
                                                      <li
                                                        key={o}
                                                        onClick={() => {
                                                          this.handleLinkOption(
                                                            theme._id,
                                                            service._id,
                                                            element,
                                                            option
                                                            // index
                                                          );
                                                        }}
                                                      >
                                                        - {option.optionValue}
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </li>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div> */}
           
            <button
              onClick={this.addingLink}
              style={{ borderRadius: "50%", border: "none" }}
            >
              <img src={plusIcon} alt="" />
            </button>
        
        </div>
      </div>
    );
  }
}

export default withTranslation() (ThemeLinking);
import React, {Component} from "react";
import {createRef} from "react/cjs/react.production.min";
import { withTranslation} from 'react-i18next';

class Spinner extends Component {

    ref = createRef();

    constructor(props) {
        super(props);

        this.state = {
            activeOption: -1,
            showOptions: false,
            below: true
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = e => {
        if (!this.ref?.current?.contains?.(e.target)) {
            this.setState({showOptions: false})
        }
    };

    onKeyDown = (e) => {

        const {activeOption} = this.state;
        const {options, onSelect} = this.props;

        const state = {};

        if (e.keyCode === 13) {
            onSelect(options?.[activeOption]);
            state.showOptions = false;
            state.activeOption = -1;
        } else if (e.keyCode === 38) {
            state.activeOption = (activeOption - 1 + options.length) % options.length;
        } else if (e.keyCode === 40) {
            state.activeOption = (activeOption + 1) % options.length;
        }

        e.preventDefault();
        this.setState(state);
    };

    render() {
        const {activeOption, showOptions, below} = this.state;
        const {value, placeholder, style, options, disabled} = this.props;

        return (
            <div style={styles.container} ref={this.ref}>
                <input
                    style={style}
                    value={this.props.t(value) ?? placeholder ?? ""}
                    onKeyDown={this.onKeyDown}
                    onClick={() => this.onClick(disabled)}
                    readOnly={true}
                    autoComplete="off"
                />

                {showOptions && options?.length > 0 &&
                <div style={{...styles.wrapper, [below ? "top" : "bottom"]: "36px"}}>
                    {options?.map((option, index) =>
                        <div
                            key={index}
                            style={{...styles.option, background: index === activeOption && "#d0d3d4"}}
                            onClick={() => this.onSelect(option)}
                        >
                            {this.props.t(option)}
                        </div>)
                    }
                </div>}
            </div>
        );
    }

    onClick = (disabled) => {
        return !disabled && this.setState({
            showOptions: true,
            below: window?.innerHeight - this.ref?.current?.getBoundingClientRect()?.top > 200
        });
    };

    onSelect = (option) => {
        this.props.onSelect(option);
        this.setState({
            showOptions: false,
            activeOption: -1
        });
    };
}

export default withTranslation() (Spinner);

const styles = {
    container: {
        position: "relative",
        width: "100%",
        display: "flex"
    },
    wrapper: {
        position: "absolute",
        maxHeight: "200px",
        background: "#e7e7e8",
        zIndex: "60",
        overflow: "auto",
        border: "1px solid #939599",
        left: "-5px",
        right: "-5px",
        fontSize: "12px"
    },
    option: {
        padding: "5px 10px",
        cursor: "pointer",
        color: "#939599",
        backgroundColor: "transparent"
    }
};

import React, { Component } from 'react';
import calendar from "../../assets/calendar.svg";
// import DatePicker from "react-datepicker";
import 'moment-timezone'
import Moment from 'react-moment'
import "react-datepicker/dist/react-datepicker.css";
import {StateContext} from "../../context/state";
import axios from 'axios'
import { withTranslation} from 'react-i18next';
import {baseUrl} from '../../Constants.js'

class DatumOption extends Component {
    
    static contextType = StateContext;
    showDate = true;
    showTime = true;
    currentDate = new Date().toLocaleDateString();

    constructor(props){
        super(props)
        // const {option} = this.props;

        // this.showDate = option.datumInput?.hasDatum ?? true;
        // this.showTime = option.datumInput?.hasTime ?? true;
        this.state={
            option:[],
            newData:{

            }
        }
    }
    componentDidMount(){
        const option= this.props.option
        // console.log('api', `${baseUrl}api/export/ServiceElement/${this.context.questionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`)
        axios.get(`${baseUrl}api/export/ServiceElement/${this.props.questionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`)
        .then((res)=>{
            const d=res.data
            this.setState({
                newData:d
            })
            this.props.updateChange(d,this.props.divId)
            })
        .catch((err)=>{
            console.log(err)
        })
     
    }
      

    onDateSelect = (date) => {
        // const {option, onUpdate} = this.props;

        // const time = option.answer?.time;
        // date = date?.toDateString();
        // option.answer = {time, date};

        // onUpdate(option);
    };

    onTimeSelect = (time) => {
        // const {option, onUpdate} = this.props;

        // const date = option.answer?.date;
        // time = time?.toTimeString();
        // option.answer = {time, date};

        // onUpdate(option);
    };
    render() {
        const {newData: {answer}} = this.state;

        const date = answer?.date ? new Date(answer?.date) : undefined;
        const time = answer?.time ? new Date(`${this.currentDate} ${answer?.time}`) : undefined;

        return (
            <React.Fragment>
                <div style={{display:"block"}}>
                {this.showDate && <div style={styles.container}>
                    <div style={styles.value}>
                        {/* <DatePicker
                            selected={date}
                            // onChange={this.onDateSelect}\
                            disabled={true}
                            placeholderText="TT.MM.JJJJ"
                            className="date-time-input"
                        /> */}
                        <Moment format="DD-MM-YYYY" className="date-time-input">{date}</Moment>
                    </div>
                    <span style={styles.label}>{this.props.t('Datum')}</span>
                </div>}

                {this.showTime && <div style={styles.container}>
                    <div style={{...styles.value, ...styles.time}}>
                    <Moment format="hh:mm" className="date-time-input">{time}</Moment>

                        {/* <DatePicker
                            selected={time}
                            // onChange={this.onTimeSelect}
                            disabled={true}
                            showTimeSelect
                            showTimeSelectOnly
                            placeholderText="00:00"
                            className="date-time-input"
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                        /> */}
                    </div>
                    <span style={styles.label}> {this.props.t('Uhrzeit')}</span>
                </div>}
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation() (DatumOption);
const styles = {
    container: {
        textAlign: "left",
        marginTop: "15px",
        marginLeft: "15px",
    },
    value: {
        // color: "#e6e7e8",
        width: "150px",
        borderBottom: "1px solid #707070",
        fontSize: "15px",
        background: `url("${calendar}") no-repeat right 0 top 3px`,
    },
    input: {
        border: 0,
        padding: 0,
        margin: 0,
        background: "transparent"
    },
    time: {
        background: "none",
    },
    label: {
        // fontFamily: "Crete Round",
        color: "#939598",
        // fontStyle: "italic",
        fontSize: "15px",
    }
};
import React, { Component } from 'react';
import magnifyingGlass from '../../assets/magnifying-glass.svg';
import arrowUp from '../../assets/arrow-small.svg';
import arrowDown from '../../assets/arrow-small-down.svg';
import DatePicker from 'react-datepicker';
import Spinner from './Spinner';
import AutoSpinner from './AutoSpinner';
import { StateContext } from '../../context/state';
import axios from 'axios';
import { baseUrl } from '../../Constants';
import { DateRange, DateRangePicker } from 'react-date-range';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { dateRangeService } from '../../services/DateRangeService';
import { withTranslation } from 'react-i18next';
import { registerLocale } from 'react-datepicker';
import { localeGerman } from '../../Constants';
import * as rdrLocales from 'react-date-range/dist/locale';

registerLocale('de', localeGerman);

export const Header = {
	TEXT: 'text',
	DATE: 'date1',
	SPINNER: 'spinner',
	AUTO_SPINNER: 'autoSpinner',
	LABEL: 'label',
	LABEL_DATE: 'label_date',
};

export const Sort = {
	ASC: 'ASC',
	DESC: 'DESC',
};

class TableSection extends Component {
	static contextType = StateContext;
	buttonPanelWidth = 0;

	constructor(props) {
		super(props);

		const rows = this.props.data ?? [];
		const filter = this.props.filter ?? {};

		this.state = {
			focusedInput: null,
			rows,
			filter,
			selfUpdate: false,
			performParentFilter: true,
			dateFormat: '',
			displayDateRangeForDeadline: undefined,
			displayDateRangeForEvent: false,
			displayDateRangeForErstelam: false,
			displayDateRangeForplanCreationDate: false,
			startDate1: new Date(),
			endDate1: new Date(),
			startDate2: new Date(),
			endDate2: new Date(),
			startDate3: new Date(),
			endDate3: new Date(),
			startDate4: new Date(),
			endDate4: new Date(),

			ranges1: [
				{
					startDate: null,
					endDate: null,

					key: 'range1',
				},
			],
			ranges2: [
				{
					startDate: null,
					endDate: null,
					key: 'range1',
				},
			],
			languageFormat: '',
		};

		const count = props.buttonCount ?? props.buttons?.length;
		this.buttonPanelWidth = count * 22 ?? 0;

		setTimeout(this.filterTable, 10);
	}

	onFocusChange(focusedInput) {
		this.setState({ focusedInput });
	}

	componentDidMount() {
		const data = this.context;

		axios
			.get(`${baseUrl}api/venue/${data?.venueId}`)
			.then((resp) => {
				// console.log("resp", resp);
				this.setState({
					dateFormat: resp.data?.dateFormat,
					languageFormat: resp.data?.languageFormat,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	static getDerivedStateFromProps = (nextProps, prevState) => {
		if (!prevState.selfUpdate) {
			return {
				rows: nextProps.data ?? [],
			};
		}

		return {
			selfUpdate: false,
		};
	};

	showDateRangeForDeadline = () => {
		this.setState({
			displayDateRangeForDeadline: !this.state.displayDateRangeForDeadline,
		});
	};

	showDateRangeForEvent = () => {
		this.setState({
			displayDateRangeForEvent: !this.state.displayDateRangeForEvent,
		});
	};

	showDateRangeForErsteltam = () => {
		this.setState({
			displayDateRangeForErstelam: !this.state.displayDateRangeForErstelam,
		});
	};

	showDateRangeForplanCreationDate = () => {
		this.setState({
			displayDateRangeForplanCreationDate:
				!this.state.displayDateRangeForplanCreationDate,
		});
	};

	sortTable = (header, sortType) => {
		let rows;

		rows =
			header.type === Header.DATE
				? this.state.rows.sort((a, b) => {
						return sortType === Sort.ASC
							? a[header.value] - b[header.value]
							: b[header.value] - a[header.value];
				  })
				: this.state.rows.sort((a, b) => {
						return sortType === Sort.ASC
							? a[header.value].toString().localeCompare(b[header.value])
							: b[header.value].toString().localeCompare(a[header.value]);
				  });

		this.updateState({ rows });
	};

	setRangeForDeadline = (item, e) => {
		let { data, allEvents } = this.props;
		const startDate1 = item?.range1?.startDate;
		const endDate1 = item?.range1?.endDate;

		if (item?.range1?.startDate !== item?.range1?.endDate) {
			data = allEvents?.forEach((row) => {
				if (
					Date.parse(row.deadlineForEntries) >= Date.parse(startDate1) &&
					Date.parse(row.deadlineForEntries) <= Date.parse(endDate1)
				) {
					return row;
				}
			});

			this.updateState({ rows: data });
			if (this.props.FilteredData) {
				this.props.FilteredData(data);
			}
			this.setState({
				displayDateRangeForDeadline: !this.state.displayDateRangeForDeadline,
				startDate1,
				endDate1,
				ranges1: [item.range1],
			});
		} else {
			this.setState({
				ranges1: [item.range1],
			});
		}
	};

	setRangeForEvent = (item) => {
		let { data, allEvents } = this.props;
		console.log('all', allEvents);
		const startDate2 = item?.range1?.startDate;
		const endDate2 = item?.range1?.endDate;
		if (item?.range1?.startDate !== item?.range1?.endDate) {
			data = allEvents?.forEach((row) => {
				if (
					Date.parse(row.eventDate) >= Date.parse(startDate2) &&
					Date.parse(row.eventDate) <= Date.parse(endDate2)
				) {
					// console.log("row",row)

					return row;
				}
			});
			data.sort(function (a, b) {
				return new Date(a.eventDate) - new Date(b.eventDate);
			});
			// console.log("data",data)
			this.updateState({ rows: data });
			if (this.props.FilteredData) {
				this.props.FilteredData(data);
			}

			dateRangeService.setDateRange({
				fromDate: moment(startDate2).format('YYYY/MM/DD'),
				toDate: moment(endDate2).format('YYYY/MM/DD'),
			});

			this.setState({
				displayDateRangeForEvent: !this.state.displayDateRangeForEvent,
				startDate2,
				endDate2,
				ranges2: [item.range1],
			});
		} else {
			this.setState({
				ranges2: [item.range1],
			});
		}
	};

	setRangeForErstalam = (item) => {
		let { data } = this.props;
		const startDate3 = item?.range1?.startDate;
		const endDate3 = item?.range1?.endDate;
		if (item?.range1?.startDate !== item?.range1?.endDate) {
			data = data?.forEach((row) => {
				if (
					Date.parse(row.creationDate) >= Date.parse(startDate3) &&
					Date.parse(row.creationDate) <= Date.parse(endDate3)
				) {
					return row;
				}
			});
			this.updateState({ rows: data });
			if (this.props.FilteredData) {
				this.props.FilteredData(data);
			}
			this.setState({
				displayDateRangeForErstelam: !this.state.displayDateRangeForErstelam,
				startDate3,
				endDate3,
				ranges2: [item.range1],
			});
		} else {
			this.setState({
				ranges2: [item.range1],
			});
		}
	};

	setRangeForPlanCreatiion = (item) => {
		let { data } = this.props;
		const startDate4 = item?.range1?.startDate;
		const endDate4 = item?.range1?.endDate;
		if (item?.range1?.startDate !== item?.range1?.endDate) {
			data = data?.forEach((row) => {
				if (
					Date.parse(row.planCreationDate) >= Date.parse(startDate4) &&
					Date.parse(row.planCreationDate) <= Date.parse(endDate4)
				) {
					return row;
				}
			});
			this.updateState({ rows: data });
			if (this.props.FilteredData) {
				this.props.FilteredData(data);
			}
			this.setState({
				displayDateRangeForplanCreationDate:
					!this.state.displayDateRangeForplanCreationDate,
				startDate4,
				endDate4,
				ranges2: [item.range1],
			});
		} else {
			this.setState({
				ranges2: [item.range1],
			});
		}
	};

	handleLanguageForEditor = () => {
		const lang = this.state.languageFormat;
		// console.log("lang", lang);
		switch (lang) {
			case 'de':
				return 'de';
			case 'en':
				return 'enUS';
			case 'se':
				return 'es';
			case 'no':
				return 'nb';
			default:
				return lang;
		}
	};

	render() {
		const { headers, buttons } = this.props;
		const { rows } = this.state;
		this.handleLanguageForEditor();

		return (
			<div>
				<div style={styles.headerRow}>
					<div
						style={{
							...styles.headerWrapper,
							marginRight: `${this.buttonPanelWidth}px`,
						}}>
						{headers?.map((header, index) => (
							<div key={index} style={styles.cell}>
								{header.type !== Header.LABEL &&
									header.type !== Header.LABEL_DATE && (
										<img
											src={magnifyingGlass}
											style={styles.icon}
											alt=""
											onClick={this.filterTable}
										/>
									)}
								{this.renderHeader(header)}
								{header.type !== Header.LABEL &&
									header.type !== Header.LABEL_DATE && (
										<>
											<img
												src={arrowUp}
												style={styles.smallIcon}
												alt=""
												onClick={() => this.sortTable(header, Sort.ASC)}
											/>
											<img
												src={arrowDown}
												style={styles.smallIcon}
												alt=""
												onClick={() => this.sortTable(header, Sort.DESC)}
											/>
										</>
									)}
							</div>
						))}
					</div>
				</div>

				{rows?.map((row, index) => (
					<div key={index} style={styles.row} className="table-row">
						{headers.map((header, _index) => (
							<div key={_index} style={styles.cell}>
								{this.renderCell({ row, header, iRow: index, iCol: _index })}
							</div>
						))}
						<div style={{ width: `${this.buttonPanelWidth}px`, paddingRight:"0px" }}>
							{buttons.length ? (
								buttons.map(
									(button, _index) =>
										(button?.isVisible?.(row) ?? true) && (
											<OverlayTrigger
												key={_index}
												placement="top"
												overlay={
													<Tooltip id={`tooltip-disabled`}>
														<span>{this.props.t(button.tooltipText)}</span>
													</Tooltip>
												}>
												{/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
												<a
													target="_blank"
													onClick={() => button?.onPress?.(row, index)}>
													<img
														src={button.icon}
														alt={button.name}
														style={{ ...styles.icon, ...button.style }}
													/>
												</a>
											</OverlayTrigger>
										)
								)
							) : (
								<></>
							)}
						</div>
					</div>
				))}
			</div>
		);
	}

	renderHeader = (header) => {
		const lang = this.handleLanguageForEditor();
		const { filter } = this.state;
		return header.type === Header.DATE ? (
			<>
				{header.value === 'deadlineForEntries' ? (
					<>
						{this.state.ranges1[0].startDate === null ? (
							<input
								placeholder={this.props.t(header.name)}
								maxLength="30"
								value=""
								onClick={this.showDateRangeForDeadline}
								style={{
									borderRadius: '3px',
									border: ' 0px',
									padding: ' 0px 5px',
									margin: ' 0px',
									background: 'transparent',
									width: '100%',
									flexGrow: '1',
									fontSize: ' 14px',
									overflow: 'auto',
								}}
								autoComplete="off"
							/>
						) : (
							<button
								style={{
									borderRadius: '3px',
									border: ' 0px',
									padding: ' 0px 5px',
									margin: ' 0px',
									background: 'transparent',
									width: '100%',
									flexGrow: '1',
									fontSize: ' 14px',
								}}
								onClick={this.showDateRangeForDeadline}>
								<Moment format={this.state.dateFormat}>
									{this.state.startDate1}
								</Moment>{' '}
								-
								<Moment format={this.state.dateFormat}>
									{this.state.endDate1}
								</Moment>
							</button>
						)}
					</>
				) : header.value === 'creationDate' ? (
					<>
						{this.state.ranges2[0].startDate === null ? (
							<input
								placeholder={this.props.t(header.name)}
								maxLength="30"
								value=""
								onClick={this.showDateRangeForErsteltam}
								style={{
									border: ' 0px',
									padding: ' 0px 5px',
									margin: ' 0px',
									background: 'transparent',
									width: '100%',
									flexGrow: '1',
									fontSize: ' 14px',
								}}
								autoComplete="off"
							/>
						) : (
							<button
								style={{
									border: ' 0px',
									padding: ' 0px 5px',
									margin: ' 0px',
									background: 'transparent',
									width: '100%',
									flexGrow: '1',
									fontSize: ' 14px',
								}}
								onClick={this.showDateRangeForErsteltam}>
								<Moment format={this.state.dateFormat}>
									{this.state.startDate3}
								</Moment>{' '}
								-
								<Moment format={this.state.dateFormat}>
									{this.state.endDate3}
								</Moment>
							</button>
						)}
					</>
				) : header.value === 'planCreationDate' ? (
					<>
						{this.state.ranges2[0].startDate === null ? (
							<input
								placeholder={this.props.t(header.name)}
								maxLength="30"
								value=""
								onClick={this.showDateRangeForplanCreationDate}
								style={{
									border: ' 0px',
									padding: ' 0px 5px',
									margin: ' 0px',
									background: 'transparent',
									width: '100%',
									flexGrow: '1',
									fontSize: ' 14px',
								}}
								autoComplete="off"
							/>
						) : (
							<button
								style={{
									border: ' 0px',
									padding: ' 0px 5px',
									margin: ' 0px',
									background: 'transparent',
									width: '100%',
									flexGrow: '1',
									fontSize: ' 14px',
								}}
								onClick={this.showDateRangeForplanCreationDate}>
								<Moment format={this.state.dateFormat}>
									{this.state.startDate4}
								</Moment>{' '}
								-
								<Moment format={this.state.dateFormat}>
									{this.state.endDate4}
								</Moment>
							</button>
						)}
					</>
				) : header.value === 'eventDate' ? (
					<>
						{this.state.ranges2[0].startDate === null ? (
							<input
								placeholder={this.props.t(header.name)}
								maxLength="30"
								value=""
								onClick={this.showDateRangeForEvent}
								style={{
									border: ' 0px',
									padding: ' 0px 5px',
									margin: ' 0px',
									background: 'transparent',
									width: '100%',
									flexGrow: '1',
									fontSize: ' 14px',
									overflow: 'auto',
								}}
								autoComplete="off"
							/>
						) : (
							<button
								style={{
									border: ' 0px',
									padding: ' 0px 5px',
									margin: ' 0px',
									background: 'transparent',
									width: '100%',
									flexGrow: '1',
									fontSize: ' 14px',
								}}
								onClick={this.showDateRangeForEvent}>
								<Moment format={this.state.dateFormat}>
									{this.state.startDate2}
								</Moment>
								-
								<br />
								<Moment format={this.state.dateFormat}>
									{this.state.endDate2}
								</Moment>
							</button>
						)}
					</>
				) : (
					<DatePicker
						locale="de"
						onChange={(date) => {
							this.setFilter(date, header);
							setTimeout(() => this.filterTable(), 100);
						}}
						selected={filter[header.value]}
						placeholderText={this.props.t(header.name)}
						onKeyDown={(e) => {
							const key = e.key;
							if (key !== 'Backspace') {
								e.preventDefault();
							}
						}}
						className="header date-time-input"
					/>
				)}

				{/* () => {
  const [startDate, setStartDate] = useState(new Date());
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));
  return (
    <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      customInput={<ExampleCustomInput />}
    />
  );
}; */}

				{this.state.displayDateRangeForDeadline ? (
					header.name === 'Eingabeschluss' && (
						<>
							<DateRange
								locale={rdrLocales[lang]}
                startDatePlaceholder={this.props.t("Früher")}
                endDatePlaceholder={this.props.t("Später")}
								className="date-range-picker"
								onChange={this.setRangeForDeadline}
								editableDateInputs={true}
								moveRangeOnFirstSelection={false}
								ranges={this.state.ranges1}
								onOutsideClick={() => {
									this.onFocusChange(null);
								}}
                // ref="date-range-picker"
                // inputProps={{ onBlur: () => this.refs.picker.closeCalendar() }}
							/>
						</>
					)
				) : this.state.displayDateRangeForErstelam ? (
					header.name === 'Erstellt am' && (
						<>
							<DateRange
								locale={rdrLocales[lang]}
                startDatePlaceholder={this.props.t("Früher")}
                endDatePlaceholder={this.props.t("Später")}
								className="date-range-picker"
								onChange={this.setRangeForErstalam}
								editableDateInputs={true}
								moveRangeOnFirstSelection={false}
								ranges={this.state.ranges2}
								placeholderText="Eventdatum"
								onKeyDown={(e) => {
									const key = e.key;
									if (key !== 'Backspace') {
										e.preventDefault();
									}
								}}
							/>
						</>
					)
				) : this.state.displayDateRangeForplanCreationDate ? (
					header.value === 'planCreationDate' &&
					header.name === 'Erstellt am' && (
						<>
							<DateRange
								locale={rdrLocales[lang]}
								startDatePlaceholder={this.props.t('Früher')}
								endDatePlaceholder={this.props.t('Später')}
								className="date-range-picker"
								onChange={this.setRangeForPlanCreatiion}
								editableDateInputs={true}
								moveRangeOnFirstSelection={false}
								ranges={this.state.ranges2}
								placeholderText="Eventdatum"
								onKeyDown={(e) => {
									const key = e.key;
									if (key !== 'Backspace') {
										e.preventDefault();
									}
								}}
							/>
						</>
					)
				) : this.state.displayDateRangeForEvent ? (
					header.name === 'Eventdatum' && (
						<>
              <DateRange
								locale={rdrLocales[lang]}
                startDatePlaceholder={this.props.t("Früher")}
                endDatePlaceholder={this.props.t("Später")}
								className="date-range-picker"
								onChange={this.setRangeForEvent}
								editableDateInputs={true}
								moveRangeOnFirstSelection={false}
								ranges={this.state.ranges2}
								placeholderText="Eventdatum"
								onKeyDown={(e) => {
									const key = e.key;
									if (key !== 'Backspace') {
										e.preventDefault();
									}
								}}
							/>
						</>
					)
				) : (
					<></>
				)}
			</>
		) : (
			<div>
				<input
					style={styles.headerInput}
					value={filter[header.value] ?? ''}
					placeholder={this.props.t(header.name)}
					maxLength={header.length ?? 30}
					onChange={(e) => this.setFilter(e.target.value, header)}
					onKeyDown={(e) => e?.keyCode === 13 && this.filterTable()}
					autoComplete="off"
					disabled={
						header.type === Header.LABEL || header.type === Header.LABEL_DATE
							? true
							: false
					}
				/>
			</div>
		);
	};

	renderCell = (params) => {
		let dateFormat = this.state?.dateFormat;

		if (dateFormat === 'DD/MM/YY') {
			dateFormat = 'dd/MM/yy';
		} else if (dateFormat === 'MM/DD/YY') {
			dateFormat = 'MM/dd/yy';
		} else if (dateFormat === 'YY/MM/DD') {
			dateFormat = 'yy/MM/dd';
		}

		const { row, header } = params;
		const lang = this.handleLanguageForEditor();

		switch (header.type) {
			case Header.TEXT:
				return (
					<>
						<input
							maxLength={header.length ?? 30}
							style={{ ...styles.input, ...styles.cellText }}
							value={row[header.value]}
							onChange={(e) => {
								const specialChar = /[!#$%^&*()+=[\]{};':"\\|,<>/?]+/;
								row.validation = specialChar.test(e.target.value);
								this.setValue(params, e?.target?.value ?? '');
							}}
							disabled={header.readOnly || !row.edit}
							autoComplete="off"
						/>
					</>
				);
			case Header.DATE:
				return (
					<DatePicker
						locale={lang}
						selected={row[header.value]}
						onChange={(date) => this.setDate(params, date)}
						className="cell date-time-input"
						disabled={header.readOnly || !row.edit}
						dateFormat={dateFormat ? dateFormat : 'dd/mm/yy'}
						onKeyDown={(e) => {
							const key = e.key;
							if (key !== 'Backspace') {
								e.preventDefault();
							}
						}}
						minDate={Date.now()}
					/>
				);
			case Header.SPINNER:
				return (
					<Spinner
						style={{ ...styles.input, ...styles.cellText }}
						options={header.values}
						value={row[header.value]}
						disabled={!row.edit}
						onSelect={(value) => this.setValue(params, value)}
					/>
				);
			case Header.AUTO_SPINNER:
				// console.log("header",header.value)
				return (
					<AutoSpinner
						style={{ ...styles.input, ...styles.cellText }}
						options={header.values}
						value={row[header.value]}
						disabled={header.readOnly || !row.edit}
						onSelect={(value) => this.setOption(params, value)}
						onChange={(value) => this.setOption(params, value, true)}
					/>
				);
			case Header.LABEL:
				return (
					<>
						<input
							maxLength={header.length ?? 30}
							style={{ ...styles.input, ...styles.cellText }}
							value={row[header.value]}
							onChange={(e) => this.setValue(params, e?.target?.value ?? '')}
							disabled={header.readOnly || !row.edit}
							autoComplete="off"
						/>
					</>
				);
			case Header.LABEL_DATE:
				return (
					<>
						<input
							maxLength={header.length ?? 30}
							style={{ ...styles.input, ...styles.cellText }}
							value={moment(row[header.value]).format(this.state?.dateFormat)}
							onChange={(e) => this.setValue(params, e?.target?.value ?? '')}
							disabled={header.readOnly || !row.edit}
							autoComplete="off"
						/>
					</>
				);
			default:
				return null;
		}
	};

	updateState = (state) => {
		this.setState({
			...state,
			selfUpdate: true,
		});
	};

	setDate = (params, date) => {
		const { rows } = this.state;
		const { row, header, iRow } = params;

		row[header.value] = date;
		rows[iRow] = row;

		this.updateState({ rows });
	};

	setValue = (params, value) => {
		const { rows } = this.state;
		const { row, header, iRow } = params;

		row[header.value] = value;
		rows[iRow] = row;

		this.updateState({ rows });
	};

	setOption = (params, value, change) => {
		const { rows } = this.state;
		const { row, header, iRow } = params;

		if (!change) {
			row[header.value] = value.name;
			row[header.field] = value.id;

			this.props.onOptionSelect &&
				this.props.onOptionSelect(iRow, header, value);
		} else {
			row[header.value] = value;
		}
		rows[iRow] = row;

		this.updateState({ rows });
	};

	setFilter = (value, header) => {
		const { filter } = this.state;
		filter[header.value] = value;
		setTimeout(() => this.filterTable(), 100);

		this.updateState({ filter });
	};

	filterTable = () => {
		let { headers, allEvents, data } = this.props;
		const { filter } = this.state;
		const array1 = Object.values(filter);
		const array = array1.filter(function (el) {
			return el;
		});

		if (window.location.pathname === '/admin/events') {
			if (array.length === 0) {
				this.props.checkIfHeaderEmpty(true);
			} else {
				array.forEach((a) => {
					this.props.checkIfHeaderEmpty(false);
				});
			}
		}
		if (
			window.location.pathname === '/admin/users' ||
			window.location.pathname === '/admin/stats'
		) {
			headers.forEach((header) => {
				// let array=[]
				let filterValue = filter[header?.value];

				if (filterValue)
					data =
						header?.type === Header.DATE || header?.type === Header.LABEL_DATE
							? data?.filter(
									(row) =>
										row[header?.value]?.toDateString() ===
										filterValue?.toDateString()
							  )
							: header?.type === Header.TEXT && header.name === 'Aktive Events'
							? data?.filter(
									(row) => Number(filter[header?.value]) === row.upcomingEvent
							  )
							: header?.type === Header.TEXT && header.name === 'Anzahl Fragen'
							? data?.filter(
									(row) => Number(filter[header?.value]) === row.questionCount
							  )
							: header?.type === Header.TEXT && header.name === ' No. Of Events'
							? data?.filter(
									(row) => Number(filter[header?.value]) === row.NumberOfEvents
							  )
							: header?.type === Header.TEXT && header.name === 'No. Of Adults'
							? data?.filter(
									(row) => Number(filter[header?.value]) === row.NumberOfAdults
							  )
							: header?.type === Header.TEXT && header.name === 'No. Of Teens'
							? data?.filter(
									(row) => Number(filter[header?.value]) === row.NumberOfTeens
							  )
							: header?.type === Header.TEXT && header.name === ' No. Of Kids'
							? data?.filter(
									(row) => Number(filter[header?.value]) === row.NumberOfKids
							  )
							: header?.type === Header.TEXT &&
							  header.name === 'No. Of Small child'
							? data?.filter(
									(row) => Number(filter[header?.value]) === row.NumberOfChild
							  )
							: header?.type === Header.TEXT &&
							  header.name === 'Total No. of Guest'
							? data?.filter(
									(row) => Number(filter[header?.value]) === row.TotalGuests
							  )
							: // (header?.type === Header.TEXT &&
							  // header.name !== "Aktive Events" && header.name !== "Anzahl Fragen") &&
							  data?.filter((row) =>
									row[header?.value]
										?.toLowerCase()
										.includes(filterValue?.toLowerCase())
							  );
			});

			this.updateState({ rows: data });
		} else if (window.location.pathname === '/admin/events') {
			// console.log("in else")
			let data2;
			headers.forEach((header) => {
				// let array=[]
				let filterValue = filter[header?.value];
				// console.log("this.props.originalData",this.props.originalData)
				if (filterValue)
					data2 =
						header?.type === Header.DATE
							? allEvents?.filter(
									(row) =>
										row[header?.value]?.toDateString() ===
										filterValue?.toDateString()
							  )
							: header?.type === Header.TEXT && header.name === 'Aktive Events'
							? allEvents?.filter(
									(row) => Number(filter[header?.value]) === row.upcomingEvent
							  )
							: header?.type === Header.TEXT && header.name === 'Anzahl Fragen'
							? allEvents?.filter(
									(row) => Number(filter[header?.value]) === row.questionCount
							  )
							: // (header?.type === Header.TEXT &&
							  // header.name !== "Aktive Events" && header.name !== "Anzahl Fragen") &&
							  allEvents?.filter((row) =>
									row[header?.value]
										?.toLowerCase()
										.includes(filterValue?.toLowerCase())
							  );
			});

			if (this.props.FilteredData) {
				this.props.FilteredData(data2 ? data2 : allEvents);
			}

			this.updateState({
				rows: data2 ? data2 : !allEvents ? this.props.data : allEvents,
			});
		} else {
			// console.log("in else")
			let data2;
			headers.forEach((header) => {
				// let array=[]
				let filterValue = filter[header?.value];
				// console.log("this.props.originalData",this.props.originalData)
				if (filterValue)
					data2 =
						header?.type === Header.DATE
							? this.props.originalData?.filter(
									(row) =>
										row[header?.value]?.toDateString() ===
										filterValue?.toDateString()
							  )
							: header?.type === Header.TEXT && header.name === 'Aktive Events'
							? this.props.originalData?.filter(
									(row) => Number(filter[header?.value]) === row.upcomingEvent
							  )
							: header?.type === Header.TEXT && header.name === 'Anzahl Fragen'
							? this.props.originalData?.filter(
									(row) => Number(filter[header?.value]) === row.questionCount
							  )
							: // (header?.type === Header.TEXT &&
							  // header.name !== "Aktive Events" && header.name !== "Anzahl Fragen") &&
							  this.props.originalData?.filter((row) =>
									row[header?.value]
										?.toLowerCase()
										.includes(filterValue?.toLowerCase())
							  );
			});

			if (this.props.FilteredData) {
				this.props.FilteredData(data2 ? data2 : this.props.originalData);
			}

			this.updateState({
				rows: data2
					? data2
					: !this.props.originalData
					? this.props.data
					: this.props.originalData,
			});
		}
	};
}

export default withTranslation()(TableSection);

const styles = {
	headerRow: {
		display: 'flex',
		padding: '0px',
    alignItems: 'center',
    marginRight: "10px"
	},
	headerWrapper: {
		background: '#d0d3d4',
		flexGrow: 1,
		justifyContent: 'space-between',
		padding: '10px',
		paddingRight: '0px',
		alignItems: 'center',
		display: 'flex',
		borderRadius: '3px',
		boxShadow: '0px 0px 10px #dadada',
		border: "0.1px solid #70707030"
	},
	row: {
		background: 'transparent',
		display: 'flex',
		justifyContent: 'space-between',
		padding: '10px 10px 10px 10px',
		alignItems: 'center',
	},
	cell: {
    borderRadius: '3px',
    border: "0.1px solid #70707025",
		background: '#e7e7e8',
		padding: '10px 5px',
		marginRight: '10px',
		flexBasis: 1,
		flexGrow: 1,
		alignItems: 'center',
		display: 'flex',
		color: '#939599',
	},
	cellText: {
		padding: '0px 10px',
		fontFamily: 'Roboto',
	},
	input: {
		border: 0,
		padding: '0 5px',
		margin: 0,
		background: 'transparent',
		width: '100%',
		flexGrow: 1,
	},
	headerInput: {
		border: 0,
		padding: '0 5px',
		margin: 0,
		background: 'transparent',
		width: '100%',
		flexGrow: 1,
		fontSize: '14px',
	},
	icon: {
		width: '18px',
		height: '18px',
		opacity: 0.9,
		margin: '2px',
		cursor: 'pointer',
	},
	smallIcon: {
		width: '8px',
		height: '8px',
		opacity: 0.5,
		margin: '2px',
		cursor: 'pointer',
	},
	btnContent: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		color: '#6d6e71',
	},
};

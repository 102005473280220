
import i18next from 'i18next';
export const baseUrl = process.env.REACT_APP_WEDDINGCONFIG_API;


export const PriceType = {
    FLAT_RATE: "flat-rate",
    NUMBER_OF_GUESTS: "number-guests",
    NUMERICALLY: "numerically",
    NONE: "none",
};

export const PricingFunction = {
    [PriceType.FLAT_RATE]: (price, quantity, stages, maximum, minimum) => {

        let amount = undefined;
        let error = undefined;

        if (!price && price!==0) error = i18next.t(`Preis ist ungültig`);
        else if (stages && quantity % stages !== 0) error =i18next.t(`quantity_multiple`,{qtystages:stages});
        else if (minimum && quantity < minimum) error =i18next.t(`amount_greater_equal`,{qtyminimum:minimum});
        else if (maximum && quantity > maximum) error = i18next.t(`amount_less_equal`,{qtymaximum:maximum});
        else amount = price;

        if(quantity === 0 || quantity === undefined){
            amount = 0;
        }

        return {amount, error}
    },
    [PriceType.NUMBER_OF_GUESTS]: (price, quantity, stages, maximum, minimum) => {

        let amount = undefined;
        let error = undefined;

        if (!price && price!==0) error = i18next.t(`Preis ist ungültig`);
        else if (stages && quantity % stages !== 0) error = i18next.t(`quantity_multiple`,{qtystages:stages});
        else if (minimum && quantity < minimum) error = i18next.t(`amount_greater_equal`,{qtyminimum:minimum}); 
        else if (maximum && quantity > maximum) error = i18next.t(`amount_less_equal`,{qtymaximum:maximum});
        // else if (minimum && maximum && minimum===maximum) error= `quantity should be less than ${minimum} and more than ${maximum}`
        else amount = price * quantity;

        return {amount, error}
    },
    [PriceType.NUMERICALLY]: (price, quantity, stages, maximum, minimum, numerically) => {
        let {
            minimumNumber,
            minimumNumberCalculate,
            maximumNumber,
            maximumNumberCalculate,
            stage,
            relay1,
            relay1Alle,
            relay1Price,
            relay2,
            relay2Alle,
            relay2Price,
            relay3,
            relay3Alle,
            relay3Price,
        } = numerically;

        stage = Number(stage || stages);
        minimumNumber = Number(minimumNumber || minimum);
        maximumNumber = Number(maximumNumber || maximum);

        let amount = undefined;
        let error = undefined;

        let alle = relay3Alle || relay2Alle || relay1Alle;
if(quantity!==0){


        if (!price && price!==0) {
            error = i18next.t(`Preis ist ungültig`)
        } else if (typeof quantity == "undefined" || quantity == null) {
            error = i18next.t(`Menge ist ungültig`)
        } else if (!minimumNumberCalculate && minimumNumber && quantity < minimumNumber) {
            error =  i18next.t(`amount_greater_equall`,{qtyminimumNumber:minimumNumber});
        } else if (!maximumNumberCalculate && maximumNumber && quantity > maximumNumber) {
            error =  i18next.t(`amount_less_equall`,{qtymaximumNumber:maximumNumber});
        } else if (stage && quantity % stage !== 0) {
            error =i18next.t(`quantity_multiple`,{qtystages:stages});
        } else if (minimumNumberCalculate && quantity < minimumNumber) {
            if(quantity){
                amount = price * minimumNumber;
            }
            
        }
         else {
            if (maximumNumber && quantity > maximumNumber) quantity = maximumNumber;
            if (alle) {
                if (relay3 && quantity >= Number(relay3))
                    amount = quantity * Number(relay3Price);
                else if (relay2 && quantity >= Number(relay2))
                    amount = quantity * Number(relay2Price);
                else if (relay1 && quantity >= Number(relay1))
                    amount = quantity * Number(relay1Price);
                else
                    amount = quantity * price;
            } else {
                amount = 0;
                if (Number(relay3) && quantity > Number(relay3)) {
                    amount += (quantity - Number(relay3)) * Number(relay3Price);
                    quantity = Number(relay3);
                }
                if (Number(relay2) && quantity > Number(relay2)) {
                    amount += (quantity - Number(relay2)) * Number(relay2Price);
                    quantity = Number(relay2);
                }
                if (Number(relay1) && quantity > Number(relay1)) {
                    amount += (quantity - Number(relay1)) * Number(relay1Price);
                    quantity = Number(relay1);
                }
                if (quantity !== 0) {
                    amount += quantity * price;
                }
            }
        }
    }
        return {amount, error};
    },
    [PriceType.NONE]: () => {
        return {
            amount: 0,
            error: undefined
        }
    },
};

const months = ['Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember']
const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

export const localeGerman = {
  localize: {
    month: n => months[n],
    day: n => days[n]
  },
  formatLong: {}
}
export const monthsDisplay = (month) => {
    switch (month) {
      case 'January':
        return 'Januar';
      case 'February':
        return 'Februar';
      case 'March':
        return 'März';
      case 'May':
        return 'Mai';
      case 'June':
        return 'Juni';
      case 'July':
        return 'Juli';
      case 'October':
        return 'Oktober';
      case 'December':
        return 'Dezember';
      default:
        return month;
    }
  };

  
import React, { Component } from "react";
import { StateContext } from "../../context/state";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
export default class Step extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bool: false,
    };
  }
  static contextType = StateContext;
  componentDidMount = async () => {
    if (window.innerWidth > 511) this.setState({ bool: false });
    window.addEventListener("resize", () => {
      if (window.innerWidth > 511) this.setState({ bool: false });
      else this.setState({ bool: true });
    });
  };
  render() {
    let { name, selected, type, handleDisplay } = this.props;

    //const selectedStyle = selected && styles.green;
    const lineColor =this.context.stylingData?.["lineColor"] ? this.context.stylingData?.lineColor : this.context.defaultSettingStyle.presAccentColor;
    const textFontSize = this.context.stylingData?.textFontSize;
    const isFirstStyle = (!type || type === "start") && styles.hide;
    const isLastStyle = (!type || type === "end") && styles.hide;

    return (
        <>
      <div style={styles.item}>
        <button
          className="step-circle"
          style={selected ? {background : lineColor,border : `1px solid ${lineColor}` }: {border : `1px solid ${lineColor}`}}
          onClick={handleDisplay}
        />
{this.state.bool === true ? (
          <div
            style={{
              fontSize: `${textFontSize}`,
              color: "black",padding: "0 3px",margin: "0px",textOverflow: "ellipsis",whiteSpace: "nowrap",
        overflow: "hidden",
            }}
          >
            <OverlayTrigger
              key="bottom"
              placement="bottom"
              overlay={
                <Tooltip id={`tooltip-disabled`}>
                <span>{name}</span> 
                </Tooltip>
              }
            >
              <span>{name}</span>
            </OverlayTrigger>
          </div>
        ) : (
          <p
            style={{
              fontSize: `${textFontSize}`,
              color: "black",padding: "0 3px",margin: "0px",textOverflow: "ellipsis",whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {name.length>12 ?
            <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={
              <Tooltip id={`tooltip-disabled`}>
              <span>{name}</span> 
              </Tooltip>
            }
          >
            <span>{name}</span>
          </OverlayTrigger>:
          <span>{name}</span>
          }
          </p>
        )}

        <span style={{ ...styles.line, ...isLastStyle }} />
        <span style={{ ...styles.line, ...styles.second, ...isFirstStyle }} />
      </div>
      </>
    );
  }
}

const styles = {
  item: {
    textAlign: "center",
    display: "inline-block",
    width: "15%",
    position: "relative",
    marginBottom: "10px",
  },
  // circle: {
  // //    width: window.innerWidth = "360px"? "18px":"48px",
  // //    height: window.innerWidth="360px"? "18px":"48px",
  //     width: "48px",
  //     height: "48px",
  //     display: "block",
  //     margin: "0 auto 4px",
  //     textAlign: "center",
  //     background: "#00000000",
  //     borderRadius: "50%",
  //     borderWidth: "1px",
  //     borderColor: "#297825",
  //     outline: "none",
  // },
  green: {
    background: "#297825",
  },
  name: {
    color: "black",
    // fontSize: "12px",
    padding: "0 3px",
    margin: "0px",
    wordBreak: "break-all",
  },
  line: {
    display: "block",
    height: "1px",
    width: "calc(50% - 39px)",
    position: "absolute",
    right: "0",
    top: "24px",
    background: "#d1d3d4",
  },
  second: {
    right: "auto",
    left: "0",
  },
  hide: {
    display: "none",
  },
};

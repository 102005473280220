import React, { Component } from "react";
import closeIcon from "../../assets/cross.svg";

import { StateContext } from "../../context/state";
import { baseUrl } from "../../Constants";
import axios from "axios";
import { withTranslation} from 'react-i18next';

class KeyFigDetails extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          name: "Kunden",
          description:
            "Anzahl der Kunden für einen bestimmten Veranstaltungsort.",
        },
        {
          name: "Mitarbeiter",
          description:
            "Anzahl der LM, EM für einen bestimmten Veranstaltungsort. ",
        },
        {
          name: "Administratoren",
          description: "Anzahl der VA für einen bestimmten Veranstaltungsort. ",
        },
        {
          name: "Veranstaltungen",
          description:
            "Anzahl der Veranstaltungen für einen bestimmten Veranstaltungsort. ",
        },
        {
          name: "Benutzer",
          description:
            "Anzahl der Benutzer für einen bestimmten Veranstaltungsort. ",
        },
        {
          name: "Gäste",
          description:
            "Anzahl der Gäste für einen bestimmten Veranstaltungsort. ",
        },
        {
          name: "Umsatz",
          description:
            "Gesamtkosten des Fragebogens für einen bestimmten Veranstaltungsort. ",
        },
        {
          name: "Hochzeiten",
          description:
            "Anzahl der Hochzeitsereignisse für einen bestimmten Veranstaltungsort. ",
        },
        {
          name: "Firmenevents",
          description:
            "Anzahl der Besprechungstypen für einen bestimmten Veranstaltungsort.",
        },
        {
          name: "Bevorstehende Hochzeiten",
          description:
            "Die Anzahl der Hochzeitsereignisse, deren Veranstaltungsdatum für einen bestimmten Veranstaltungsort nicht mehr als 30 Tage ab heute beträgt.",
        },
        {
          name: "Besuche",
          description:
            "Anzahl der angemeldeten Benutzer für einen bestimmten Veranstaltungsort. ",
        },
      ],
    };
  }

  componentDidMount() {
    axios
      .get(`${baseUrl}api/userKeyFig/1`)
      .then((res) => {
        // console.log(res.data);
        this.setState({
          data: res.data?.keyFig || this.state.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

   
    
    render() {
        return (
            <div className="popup-overlay " id="descriptionPopup" style={{top:"10%",
            width:"900px",
            right: "200px",
            zIndex:"1",
            marginLeft:"4%"
            }}>
                <div className="popup-container" 
                    style={{background: "#d1d3d4",  maxWidth: "1620px", width: "90%", height:"600px", display: "inline-block", padding:"10px", position: "relative", maxHeight: "100vh", overflow:"auto", zIndex:"111"}}>
                <button
                className="close-btn"
                style={{float:"right", border:"none", borderRadius:"50%", padding:"1px 4px" }}
                onClick={this.props.closePopup}
                >
                <img src={closeIcon} alt="" />
                </button>
            <div style={{padding:"50px"}}>
                <div style={{display:"flex"}}>
                    <div style={{width:"30%", border:"1px solid black"}}><b><u>{this.props.t('Name')}</u></b></div>
                    <div style={{width:"70%", border:"1px solid black"}}><b><u>{this.props.t('Beschreibung')}</u></b></div>
                </div>
                {
                    this.state.data.map((info, i)=>{
                        return (
                            <div key={i} style={{display:"flex"}}>
                                <div style={{width:"30%", border:"1px solid black"}}>{`${this.props.t(info.name)}`}</div>
                                <div style={{width:"70%", border:"1px solid black"}}>{`${this.props.t(info.name+"_desc")}`}</div>     
					  
                        </div>
                        )
                    })
                }
                       
                </div>
            </div>
            {this.state.data.map((info, i) => {
              return (
                <div key={i} style={{ display: "flex" }}>
                  <div style={{ width: "40%", border: "1px solid black" }}>
                    {info.name}
                  </div>
                  <div style={{ width: "60%", border: "1px solid black" }}>
                    {info.description}
                  </div>
                </div>
              );
            })}
          </div>
    );
  }
}

export default withTranslation() (KeyFigDetails);

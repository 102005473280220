import React, {Component} from "react";
import KeyFigure from "./KeyFigure";
import Slider from "./Slider";
import fileIcon from "../../assets/file.svg"
import fileCheckedIcon from "../../assets/file-checked.svg"
import HttpService from "../../services/HttpService";
import {StateContext} from "../../context/state";
import Text from "../preview/Text";
import {dateRangeService} from "../../services/DateRangeService";
import KeyFigDetails from "./KeyFigDetails";
import { withTranslation} from 'react-i18next';
 class KeyFigures extends Component {

    static contextType = StateContext;

    element = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            style: {},
            keyFigures: [],
            userKey: [],
            collapsed: true,
            loading: "Loading...",
            fromDate: "",
            toDate: "",
            showPopUp:false
        };
    }

    async componentDidMount() {

        const width = this.element.current.scrollWidth;
        const {style} = this.state;

        style.width = width / 4 * 0.95;
        // style.height = style.width * 3 / 4;
        style.height = "150px";

        this.setState({style});

        this.fetchKeys({fromDate: "", toDate: ""});
        this.subscription = dateRangeService.getDateRange().subscribe(range => this.fetchKeys(range));
    }
closePopup = () => {
       this.setState({showPopUp:false})
      };
handleShowPopUp=()=>{
    this.setState({showPopUp:true})
}
    async fetchKeys(range) {

        const {venueId, userRole, loggedInUserId} = this.context;

        try {
            const keyFigures = await HttpService.fetchKeyFigures();
            const userKey = await HttpService.fetchUserKeyFigures(loggedInUserId);
            const promises = [];

            const startDate = range?.fromDate ?? "";
            const endDate = range?.toDate ?? "";
           // console.log("keyfig",keyFigures);
            keyFigures.forEach((key, index) => {
                const selected = userKey?.keys ? userKey?.keys?.includes(key.id) : index < 4;
                if (selected) {
                    promises.push(
                        HttpService.fetchKeyFigure({
                            keyFigId: key.id,
                            venueId: venueId,
                            userRole: userRole,
                            fromDate: startDate,
                            toDate: endDate
                        })
                            .then(value => {

                                keyFigures[index] = {...key, selected, value}
                            }));
                } else {
                    keyFigures[index] = {...key, selected, value: 0}
                }
            });

            Promise.all(promises)
                .then(res => {
                    this.setState({
                        loading: undefined,
                        keyFigures,
                        userKey,
                        fromDate : startDate,
                        toDate : endDate
                    })
                })
                .catch(err => {
                    this.setState({loading: JSON.stringify(err)})
                })
        } catch (error) {
            this.setState({
                loading: JSON.stringify(error)
            })
        }
    }

    componentWillUnmount() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
    }

    toggleSlider = () => {
        this.setState((prevState) => {
            return {
                collapsed: !prevState.collapsed,
            };
        })
    };

    toggleFigure = (index) => {
        const {keyFigures, fromDate, toDate} = this.state;
        const keyFigure = keyFigures[index];
        const selected = !keyFigure.selected;

        const count = keyFigures.reduce((total, key) => {
            return total + (key.selected ? 1 : 0);
        }, 0);

        if ((selected && count >= 4) || (!selected && count === 1)) return;

        if (selected) {
            const {venueId, userRole} = this.context;
            HttpService.fetchKeyFigure({
                keyFigId: keyFigure.id,
                venueId: venueId,
                userRole: userRole,
                fromDate: fromDate ?? "",
                toDate: toDate ?? ""
            })
                .then(value => {
                    keyFigures[index] = {...keyFigure, selected, value};
                })
                .catch(err => {
                    keyFigures[index] = {...keyFigure, selected, value: 0};
                })
                .finally(() => {
                    this.setState({keyFigures});
                });
        }

        keyFigures[index] = {...keyFigure, selected};
        this.persistKeys(keyFigures);

        this.setState({keyFigures})
    };

    persistKeys = (keyFigures) => {
        const {userKey} = this.state;
        const {loggedInUserId} = this.context;
        const selectedKeys = keyFigures.filter(fig => fig.selected).map(fig => fig.id);

        if (userKey?.keys)
            HttpService.updateUserKeyFigures({...userKey, keys: selectedKeys}, loggedInUserId).then(res => {
                this.setState({userKey: {...userKey, keys: selectedKeys}})
            });
        else
            HttpService.saveUserKeyFigures({
                keys: selectedKeys,
                userId: loggedInUserId
            }).then(res => {
                this.setState({userKey: {_id: res.id, userId: loggedInUserId, keys: selectedKeys}})
            });
    };

    render() {

        const {collapsed, keyFigures} = this.state;

        return (
            <>
            <React.Fragment>
                <div ref={this.element} style={styles.container}>
                    {this.renderContent()}
                </div>

                <Slider collapsed={collapsed} onChange={(collapsed) => this.setState({collapsed, showPopUp:false})}>
                    <div style={styles.sliderWrapper}>
                        <h1>{this.props.t('Key_Figures')}</h1>
                        {keyFigures.map((key, index) =>
                            <label key={index} onClick={() => this.toggleFigure(index)}>
                                <img src={key.selected ? fileCheckedIcon : fileIcon} alt={`${key.name}`} />
                                <span style={{marginLeft: "10px"}}>{`${this.props.t(key.name)}`}</span>
                            </label>)}

                           <div style={{width:"100%"}}>
                           <button
                  className="button"
                  style={{ float:"right", background:"rgb(208, 211, 212)" }}
                  onClick={this.handleShowPopUp}
                >
                <span style={{
                    border: "4px solid gray",
                    borderRadius:"50%",
                    padding:"0px 12px",
                    fontSize: "26px",
                    color: "gray",

                }}>?</span>
                </button>
                 </div> 
                            
                    </div>
                </Slider>
            
                
            </React.Fragment>
            {this.state.showPopUp===true &&
                            <KeyFigDetails closePopup={this.closePopup} />
                        }
            </>
            
        );
    }

    renderContent = () => {

        const {loading, keyFigures, style} = this.state;

        if (loading) return <Text value={this.props.t('Loading')} />;

        return keyFigures.map((key, index) =>
    
            key.selected
                ? <KeyFigure
                    key={index}
                    style={style}
                    value={key}
                    onEditSelect={this.toggleSlider}
                />
                : null
        )
    };
}

export default withTranslation() (KeyFigures);

const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    sliderWrapper: {
        background: "#d0d3d4",
        padding: "40px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        height: "auto",
        minHeight: "100vh"
    }
};

import React, { Component } from "react";
import { StateContext } from "../../context/state";
import swal from "sweetalert";
import ConfiguratorTemplate12 from "../templates/ConfiguratorTemplate12";
import ConfiguratorTemplateSelection from "../partials/ConfiguratorTemplateSelection";
import { withTranslation} from 'react-i18next';
import styled from "styled-components";
// import {stateToHTML} from 'draft-js-export-html';
import {
  // ContentState,
  EditorState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import closeIcon from "../../assets/cross.svg";
import HttpService from "../../services/HttpService";
const MainContainer = styled.div`
  height: auto;
  width: 100%;
  .scroll-div {
    position: sticky;
    z-index: 500;
    top: -10px;
    width:10%;
    height: auto;
    padding-bottom: 10px;
   }
  `;
  const StickyDiv = styled.div`
  position:fixed;
  width:10%;
  height:36px;
  left:1325px;
		z-index: 1000;

 
	`;

class ConfiguratorTemplateEditPopup extends Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.state = {
      scroll:false,
      selectedTemplate: 12,
      layoutObject: [],
      canBeEdited: false,
      serviceLink: {},
    };
  }

  updateLayoutObject = (layoutObject, serviceLink) => {
    if (serviceLink) {
      this.setState({ layoutObject, serviceLink });
    } else {
      this.setState({ layoutObject });
    }
  };

  sendQuestionAndClosePopup = async () => {
   // console.log("save", this.state.layoutObject);

    var validated = true;
    const dummy = Object.assign([], this.state.layoutObject); 
    dummy.forEach((layout) => {
      layout.row.forEach((row) => {
        row.column.forEach((column) => {
       
          if(column.option.type==="radio"){
         
             if(column.option.radioInput.multipleSelection===false){
               column.option.answer={}
             }
             else if(column.option.radioInput.multipleSelection==="true"){
              column.option.answer=[]
             }
            delete column.option.textInput
            delete column.option.datumInput
            delete column.option.tableInput
            delete column.option.bildInput
            delete column.option.uploadInput
          
            const linkArray = column.option.radioInput.link;
            if (column.option.radioInput.link !== undefined) {
              column.option.radioInput.link = linkArray.filter(function (obj) {
                return obj.optionType !== '';
              });
              
            }
          
          }
          if(column.option.type==="image"){
         
            if(column.option.imageInput.multipleSelection===false){
              column.option.answer={}
            }
            else if(column.option.imageInput.multipleSelection==="true"){
             column.option.answer=[]
            }
            delete column.option.radioInput
           delete column.option.textInput
           delete column.option.datumInput
           delete column.option.tableInput
           delete column.option.bildInput
           delete column.option.uploadInput
           const linkArray = column.option.imageInput.link;
           if (column.option.imageInput.link !== undefined) {
             column.option.imageInput.link = linkArray.filter(function (obj) {
               return obj.optionType !== '';
             });       
           }
         }
          if(column.option.type==="text"){
            delete column.option.radioInput
            // delete column.option.textInput
            delete column.option.datumInput
            delete column.option.tableInput
            delete column.option.bildInput
            delete column.option.uploadInput  
            const linkArray = column.option.textInput.link;
            if (column.option.textInput.link !== undefined) {
              column.option.textInput.link = linkArray.filter(function (obj) {
                return obj.optionType !== '';
              });       
            }    
          }
          if(column.option.type==="datum"){
            delete column.option.radioInput
            delete column.option.textInput
            // delete column.option.datumInput
            delete column.option.tableInput
            delete column.option.bildInput
            delete column.option.uploadInput
            const linkArray = column.option.datumInput.link;
            if (column.option.datumInput.link !== undefined) {
              column.option.datumInput.link = linkArray.filter(function (obj) {
                return obj.optionType !== '';
              });       
            }    
          }
          if(column.option.type==="table"){
            delete column.option.radioInput
            delete column.option.textInput
            delete column.option.datumInput
            // delete column.option.tableInput
            delete column.option.bildInput
            delete column.option.uploadInput
            const linkArray = column.option.tableInput.link;
            if (column.option.tableInput.link !== undefined) {
              column.option.tableInput.link = linkArray.filter(function (obj) {
                return obj.optionType !== '';
              });       
            }    
          }
          if(column.option.type==="bild"){
            delete column.option.radioInput
            delete column.option.textInput
            delete column.option.datumInput
            delete column.option.tableInput
            // delete column.option.imageInput
            delete column.option.uploadInput
            const linkArray = column.option.bildInput.link;
            if (column.option.bildInput.link !== undefined) {
              column.option.bildInput.link = linkArray.filter(function (obj) {
                return obj.optionType !== '';
              });       
            }  
          }
          if(column.option.type==="upload"){
            delete column.option.radioInput
            delete column.option.textInput
            delete column.option.datumInput
            delete column.option.tableInput
            delete column.option.bildInput
            const linkArray = column.option.uploadInput.link;
            if (column.option.uploadInput.link !== undefined) {
              column.option.uploadInput.link = linkArray.filter(function (obj) {
                return obj.optionType !== '';
              });       
            }  
            // delete column.option.uploadInput
          }
          // console.log("column.option",column.option)
         
          if (column.option.type === "text") {
            if (column.option?.textInput) {
              column.option.textInput.inputs.forEach((input) => {
                const min = Number(input.numerically.minimumNumber);
                const max = Number(input.numerically.maximumNumber);
                const price = Number(input.price);

                if (min && max && min > max) {
                  validated = false;
                  return;
                } else if (min && max && !price) {
                  validated = false;
                  return;
                } else if (min && !price) {
                  validated = false;
                  return;
                } else if (max && !price) {
                  validated = false;
                  return;
                }
                if (!Object.keys(input.value).includes("blocks")) {
                  const c = convertToRaw(input.value.getCurrentContent());
                  input.value = c;
                }
              });
            }
          }
          if (column.option.type === "radio") {
            if (column.option?.radioInput) {
              column.option.radioInput.inputs.forEach((input) => {
                const min = Number(input.numerically.minimumNumber);
                const max = Number(input.numerically.maximumNumber);
                const price = Number(input.price);

                if (min && max && min > max) {
                  validated = false;
                  return;
                } else if (min && max && !price && price!==0) {
                  validated = false;
                  return;
                } else if (min && !price  && price!==0) {
                  validated = false;
                  return;
                } else if (max && !price  && price!==0) {
                  validated = false;
                  return;
                }
              });
            }
          }
          if (column.option.type === "image") {
            if (column.option?.imageInput) {
              column.option.imageInput.inputs.forEach((input) => {
                const min = Number(input.numerically.minimumNumber);
                const max = Number(input.numerically.maximumNumber);
                const price = Number(input.price);

                if (min && max && min > max) {
                  validated = false;
                  return;
                } else if (min && max && !price) {
                  validated = false;
                  return;
                } else if (min && !price) {
                  validated = false;
                  return;
                } else if (max && !price) {
                  validated = false;
                  return;
                }
              });
            }
          }
        });
      });
    });
    
    console.log("object",dummy);
    if (validated) {
      const data = {
        // eventId: "1",
        questionnaireId: this.context.questionnaireId,
        themeId: this.props.templateId.toString(),
        serviceId: this.props.stepId.toString(),
        question: dummy,
        isDeleted :false
      };
      const {loggedInUserId } = this.context;
  const result1= await HttpService.putBuilderQtn(
        this.context.questionnaireId,
        this.props.templateId,
        this.props.stepId,
        loggedInUserId,
        data
      );
      const getThemes = await HttpService.getBuilderThemes(
        this.context.questionnaireId
      );
      //console.log('layout',  this.props.templateObject, getThemes)
      const dummyLay=Object.assign([], this.props.templateObject)  

      dummyLay.forEach((theme) => {
        if (theme.id === this.props.templateId) {
          //console.log("theme.services",theme.services);
          theme.services.forEach((serv) => {
            if (serv.id === this.props.stepId) {
              serv.serviceLink = this.state.serviceLink;
            }
          
            if(serv.serviceLink.length!==undefined){
             const serviceLinkOptionArray=serv.serviceLink?.serviceInput?.link;
            //  console.log("serviceLinkOptionArray",serviceLinkOptionArray);
             if (serv?.serviceLink?.serviceInput?.link !== undefined) {
              serv.serviceLink.serviceInput.link= serviceLinkOptionArray.filter(function (obj) {
                return obj.optionType !== '';
              });       
            } 
          } 
          });
        }

      });
      getThemes.themeApiObject={themes:dummyLay}
      getThemes.questionnaireName=this.context.questionnaireName 
      delete getThemes._id
      // const putThemes = {
      //   themeApiObject: {themes:dummyLay},
      //   // questionnaireId: getThemes.questionnaireId,
      // };
      // console.log("put themes", putThemes);
  const result2= await HttpService.putThemes(getThemes.questionnaireId, getThemes);
      // this.props.closeEditPopup("yes", dummyLay);
      // console.log("result",result1,"result2",result2)
      if((result1.status===true)&&(result2.status===true)){
        swal(`${this.props.t('Gespeichert!')}`, "", "success");
      }
    }    
  };

  componentDidMount = async () => {
    const getQtn = await HttpService.ifIsSaved(
      this.context.questionnaireId,
      this.props.templateId,
      this.props.stepId
    );
    if (getQtn.question) {
      var dummy = Object.assign([], getQtn.question);
      dummy.forEach((layout) => {
        layout.row.forEach((row) => {
          row.column.forEach((column) => {
            if (column.option.type === "text") {
              if (column.option?.textInput) {
                column.option.textInput.inputs.forEach((input) => {
                  const c = EditorState.createWithContent(
                    convertFromRaw(input.value)
                  );
                  input.value = c;
                });
              }
            }
          });
        });
      });
      // console.log('dummy',dummy)

      // this.setState({ layoutObject: dummy, canBeEdited: true });
    } else {
      dummy = [
        {
          row: [
            {
              column: [
                {
                  option: {},
                  span: 1,
                  selected: false,
                },
              ],
              quantity: 1,
              span: 1,
              default: true,
            },
          ],
          default: true,
          quantity: 1,
        },
      ];
      // this.setState({ layoutObject: dummy, canBeEdited: true });
    }
    // console.log("du", dummy);

    const getThemes = await HttpService.getBuilderThemes(
      this.context.questionnaireId
    );
    // console.log("get res", getThemes.themeApiObject.themes, this.props.templateId, this.props.stepId);

    for (let x of getThemes?.themeApiObject?.themes) {
      if (x.id === this.props.templateId) {
        var service = x.services.find((serv) => {
          return serv.id === this.props.stepId;
        });
      }
    }
    // console.log("serv", service?.serviceLink);
    this.setState({
      layoutObject: dummy,
      canBeEdited: true,
      serviceLink: service?.serviceLink
        ? service?.serviceLink
        : {
            type: "service",
            serviceInput: {
              link: [
                {
                  optionId:"",
                  columnIndex: "",
                  linkedOptionId: "",
                  linkedOptionValue: "",
                  optionType: "",
                  questionIndex: "",
                  rowIndex: "",
                  serviceId: "",
                  themeId: "",
                  display: "",
                },
              ],
            },
          },
    });
  };

  renderTemplate = (sectionNumber) => {
    switch (sectionNumber) {
      case 12:
        return (
          <ConfiguratorTemplate12
          themeId={this.props.templateId}
          serviceId={this.props.serviceId}
            updateLayoutObject={this.updateLayoutObject}
            layoutObject={this.state.layoutObject}
            canBeEdited={this.state.canBeEdited}
            serviceLink={this.state.serviceLink}
          />
        );
      default:
        return (
          <ConfiguratorTemplateSelection
            setSelectedTemplate={this.setSelectedTemplate}
            canBeEdited={this.state.canBeEdited}
            serviceLink={this.state.serviceLink}
          />
        );
    }
  };
  componentDidMount(){
    window.addEventListener("scroll", () => {
      this.setState({scroll:window.scrollY > 0});
    });
  };

  render() {
    return (
			<>
				<MainContainer>
					<div className="popup-overlay">
						<div className="popup template-selection-popup">
							<div className="popup-container">
								<StickyDiv className={this.scroll ? 'scroll-div' : 'no-scroll'} style={{background:"#00000000"}}>
									<button
										className="saveTemplate"
										onClick={this.sendQuestionAndClosePopup}
										// style={{position:"sticky" ,top: "0px" }}
									>
										{this.props.t('save')}
									</button>
									<button
										className="closeTemplate"
										onClick={this.props.closeEditPopup}
										//  style={{position:"sticky" , top: "0px" , marginRight: "-90%"}}
									>
										<img src={closeIcon} alt="" />
									</button>
								</StickyDiv>
								<div className="scrollbar">{this.renderTemplate(this.state.selectedTemplate)}</div>
							</div>
						</div>
					</div>
				</MainContainer>
			</>
		);
  }
}

export default  withTranslation() (ConfiguratorTemplateEditPopup);

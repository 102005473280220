import React, { Component } from "react";
import axios from "axios";
import { Tab, Tabs, TabPanel, TabList } from "react-tabs";
import UploadPdfDropzone from "../partials/UploadPdfDropzone";
import { baseUrl } from "../../Constants";
import { StateContext } from "../../context/state";
import uploadIcon from "../../assets/upload.svg";
import crossLightIcon from "../../assets/cross-light.svg";
import crossSmallIcon from "../../assets/cross-small.svg";
import jpgIcon from "../../assets/jpg-file.svg";
import linkIcon from "../../assets/link.svg";
import deleteIcon from "../../assets/trash.svg"
import { Spinner } from 'reactstrap';
import { v4 as uuidv4 } from "uuid";
import Masonry from 'react-masonry-css';
import { withTranslation } from 'react-i18next';
import LightBox from "../lightBox/LightBox";

class UploadOptionComponent extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.messagesRef=React.createRef(null);
    this.state = {
      uploadInput: {
        question: "",
        inputs: [],
        id:uuidv4()
      },
      mediaLibrary: [],
      tabIndex: 0,
      uploadModalOpen: false,
      isMediaLibraryEmpty: true,
      searchValue: "",
      actualTotal: [],
      deleted:false,
      uploadSelected:false,
      photo:"",
      clientid:"Z4rIu13ZLRWsXO5kL70Gox1nvGssGRFupjwtJ2vhNEA",
      unsplashImages:[],
      TotalUnsplashImages:[],
      page:1,
      totalPages:1,
      isOpen: false,
      lightboxImages: [],
      image: [],
      photoIndex: 0
    };
  }

  componentDidMount = () => {
    // console.log('media gallery', this.context)
    // axios
    //   .get(baseUrl + `api/mediaGallery/${this.context.venueId}/all`)
    axios.get(`${baseUrl}api/mediaGallery/mediaGalleryForVenue/${this.context.venueId}/all`)
      .then((res) => {
        // console.log(res);
        if (res.data.hasOwnProperty("mediaLibrary")) {
          this.setState({ isMediaLibraryEmpty: false });
          const mediaLibrary = res.data.mediaLibrary;
          this.setState({
            mediaLibrary,
            actualTotal: res.data.mediaLibrary,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
   };

  acceptUploadToMediaLibrary = (params) => {
    // console.log('params', params)
    if (this.state.isMediaLibraryEmpty) {
      axios
        .post(baseUrl + "api/mediaGallery", {
          mediaLibrary: [
            {
              url: params.url,
              type: params.type,
              name: params.name,
            },
          ],
          venueId: this.context.venueId,
        })
        .then((res) => {
          // console.log(res);
          const mediaLibrary = this.state.mediaLibrary;
          mediaLibrary.unshift(params);
          this.setState({
            mediaLibrary,
            actualTotal: mediaLibrary,
            tabIndex: 1,
            isMediaLibraryEmpty: false
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const mediaLibrary = this.state.mediaLibrary;
      mediaLibrary.unshift(params);
      // console.log('gallary', mediaLibrary)
      axios
        .put(baseUrl + `api/mediaGallery/${this.context.venueId}`, {
          mediaLibrary,
          venueId: this.context.venueId,
        })
        .then((res) => {
          // console.log(res);
          this.setState({
            mediaLibrary,
            actualTotal: mediaLibrary,
            tabIndex: 1,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  setQuestion = (e) => {
    const uploadInput = this.state.uploadInput;
    uploadInput.question = e.target.value;
    this.setState({ uploadInput });
    this.props.updateUploadInput(uploadInput);
  };

  toggleUploadModal = (index) => {
    const mediaLibrary = this.state.mediaLibrary;
    mediaLibrary.forEach((item) => {
      item.checked = false;
    });
    this.setState((prevState) => ({
      mediaLibrary,
      uploadModalOpen: !prevState.uploadModalOpen,
      tabIndex: 0,
    }));
    this.props.setPopup();
  };

  openUploadModalMediaSection = (index) => {
    // axios
    //   .get(baseUrl + `api/mediaGallery/${this.context.venueId}/all`)
    axios.get(`${baseUrl}api/mediaGallery/mediaGalleryForVenue/${this.context.venueId}/all`)
      .then((res) => {
        if (res.data.hasOwnProperty("mediaLibrary")) {
          this.setState({ isMediaLibraryEmpty: false, tabIndex: 1 });
          const mediaLibrary = res.data.mediaLibrary;
          mediaLibrary.forEach((item) => {
            item.checked = false;
          });
          this.setState({
            mediaLibrary,
            actualTotal: res.data.mediaLibrary,
            tabIndex: 1,
            uploadModalOpen: true,
          });
        } else {
          const mediaLibrary = this.state.mediaLibrary;
          mediaLibrary.forEach((item) => {
            item.checked = false;
          });
          this.setState({ mediaLibrary });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // const mediaLibrary = this.state.mediaLibrary;
    // mediaLibrary.forEach(item => {
    //   item.checked = false;
    // });
    this.setState({
      // mediaLibrary,
      uploadModalOpen: true,
      tabIndex: 1,
    });
  };

  setMediaItemChecked = (index) => {
    const mediaLibrary = this.state.mediaLibrary;
    mediaLibrary[index].checked = !mediaLibrary[index].checked;
    this.setState({ mediaLibrary });
  };

  
  setUnsplashItemChecked=(e,url) => {
    var totalImages = this.state.TotalUnsplashImages;
    if(totalImages.indexOf(url)>=0)
    {
      totalImages.splice(totalImages.indexOf(url)-1,1);
    }
    else
    {
      totalImages.push(url);
    }
    this.setState({ TotalUnsplashImages: totalImages });
  };

  deleteMediaImage=(item,e)=>{
    this.setState({delete:true})

    const array=Object.assign([],this.state.mediaLibrary)
     
     const mediaLibrary=array?.filter(media=>media.url!==item.url)
    //  console.log(mediaLibrary,"medialibrary")
      this.setState({mediaLibrary:mediaLibrary})
    
      
               axios
              .put(baseUrl+ `api/mediaGallery/${this.context.venueId}`, {
                mediaLibrary,
                venueId: this.context.venueId
              })
              .then(res => {
                // console.log(res);
                this.setState({ 
                  mediaLibrary:mediaLibrary, 
                  actualTotal:mediaLibrary,
                  tabIndex: 1 ,
                  delete:false
                });
              })
              .catch(error => {
                console.log(error);
              });
      
        }
      
  insertSelectedMedia = () => {
    const uploadInput = this.state.uploadInput;
    !uploadInput.hasOwnProperty("inputs") && (uploadInput.inputs = []);
    this.state.mediaLibrary.forEach((item) => {
      item.checked &&
        uploadInput.inputs.push({
          type: item.type,
          url: item.url,
          name: item.name,
        });
    });
    this.setState({ uploadInput, uploadModalOpen: false });
    this.props.updateUploadInput(uploadInput);
    console.log(uploadInput);
    this.props.setPopup();
  };
  insertSelectedUnsplash = () => {
    const uploadInput = this.state.uploadInput;
    !uploadInput.hasOwnProperty("inputs") && (uploadInput.inputs = []);
    console.log("test",this.state.TotalUnsplashImages);
    this.state.TotalUnsplashImages.forEach((photo)=>{
      uploadInput.inputs.push({
        type: "image",
        url: photo,
        name: "",
      });
    });
    this.setState({ uploadInput, uploadModalOpen: false });
    this.props.updateUploadInput(uploadInput);
    this.props.setPopup();
  };

  setDisplayName = (index, e) => {
    const uploadInput = this.state.uploadInput;
    uploadInput.inputs[index].display = e.target.value;
    this.setState({ uploadInput });
    this.props.updateUploadInput(uploadInput);
  };

  removeUploadInputs = () => {
    const uploadInput = this.state.uploadInput;
    uploadInput.inputs = [];
    const mediaLibrary = this.state.mediaLibrary;
    mediaLibrary.forEach((item) => {
      item.checked = false;
    });
    // this.setState({mediaLibrary})
    this.setState({ uploadInput, mediaLibrary });
    this.props.updateUploadInput(uploadInput);
  };

  static getDerivedStateFromProps = (nextProps, prevState) => {
    // console.log('next', nextProps)
    if (nextProps.uploadInput === undefined) {
      return null;
    } else {
      return {
        uploadInput: nextProps.uploadInput,
      };
    }
  };
  handleClicked = () => {
    // console.log('clicked', this.state.uploadInput)
    // console.log("upload",document.getElementById("upload"))
    if (this.state.uploadInput.type === "download") {
      const d = {
        uploadSelect: "download",
        uploadMaximum: "",
        uploadMinimum: "",
      };
      this.props.setOptionSelected(this.props.index, "download", null, null, d);
    } else {
      const d = {
        uploadSelect: "upload",
        uploadMaximum: this.state.uploadInput.maximum
          ? this.state.uploadInput.maximum
          : "",
        uploadMinimum: this.state.uploadInput.minimum
          ? this.state.uploadInput.minimum
          : "",
      };
      this.props.setOptionSelected(this.props.index, "upload", null, null, d);
    }
  };
  handleTabShift = (tabIndex) => {
    // console.log('tab shift', tabIndex)
    if (tabIndex === "1") {
      // axios
      //   .get(baseUrl + `api/mediaGallery/${this.context.venueId}/all`)
      axios.get(`${baseUrl}api/mediaGallery/mediaGalleryForVenue/${this.context.venueId}/all`)
        .then((res) => {
          // console.log(res);
          if (res.data.hasOwnProperty("mediaLibrary")) {
            this.setState({ isMediaLibraryEmpty: false, tabIndex });
            const mediaLibrary = res.data.mediaLibrary;
            mediaLibrary.forEach((item) => {
              item.checked = false;
            });
            this.setState({
              mediaLibrary,
              actualTotal: res.data.mediaLibrary,
              tabIndex,
            });
          } else {
            this.setState({ tabIndex });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ tabIndex });
    }

    if(tabIndex==="2"){
      this.setState({
        unsplashImages:[] 
      });
    }
  };

  handleChange = (event) => {
   this.setState({  
     photo:event.target.value
   });
  
 }

//  handleSumbit = (page) => { 
//   console.log("photo in sumbit", this.state.photo);
//   const url = "https://api.unsplash.com/search/photos?page=1&query=" + this.state.photo + "&client_id=" + this.state.clientid;
//   console.log(url);
//   axios.get(url)
//     .then((response) => {
//       // setResult(response.data.results);
//       console.log(response);
//       this.setState({
//         result:response.data.results   
//       })
//     })
// }
handleSumbit = (page) => { 
  var totalpage=this.state.totalPages;
  if(page===1){
     totalpage=1
  }
  if(page<=totalpage)
  {
  const url = "https://api.unsplash.com/search/photos?page="+page+"&query=" + this.state.photo + "&client_id=" + this.state.clientid;
 
  axios.get(url)
    .then((response) => {    
    if (page<2)
     {
      this.setState(
        {
        unsplashImages:response.data.results ,
        totalPages :response.data.total_pages  
      });
     }
   else if(page<=response.data.total_pages){
      this.setState(
        {
          unsplashImages:[...this.state.unsplashImages,...response.data.results] ,  
      });
     console.log("total unsplash",this.state.TotalUnsplashImages);

    }  
    })
  }
}

handleKeypress = e => {
  //it triggers by pressing the enter key
  if (e.key === 'Enter' && this.state.tabIndex===2) 
   {
  this.handleSumbit(1);
}
};
handleScroll = (e) => { 
  if(this.state.tabIndex===2) 
  { 
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) 
    { 
      this.setState(
      {
        page:this.state.page+1},()=>{
        this.handleSumbit(this.state.page);
      }
     )
    }
  } 
}

  handleSearch = (e) => {
    const { mediaLibrary, actualTotal } = this.state;
    if (this.state.searchValue.length > e.target.value.length) {
      const x = actualTotal.filter((img) =>
        img.name?.toLowerCase().includes(e.target.value.toLowerCase())
      );
      this.setState({
        mediaLibrary: x,
        searchValue: e.target.value,
      });
    } else {
      const x = mediaLibrary.filter((img) =>
        img.name?.toLowerCase().includes(e.target.value.toLowerCase())
      );
      this.setState({
        mediaLibrary: x,
        searchValue: e.target.value,
      });
    }
  };

  handleDropdown = (e) => {
    // if(e.target.value==="images"){
    //   axios
    //   .get(baseUrl + `api/mediaGallery/${this.context.loggedInUserId}/image`)
    //   .then(res => {
    //     if (res.data.hasOwnProperty("mediaLibrary")) {
    //       this.setState({ isMediaLibraryEmpty: false });
    //       const mediaLibrary = res.data.mediaLibrary;
    //       this.setState({
    //         mediaLibrary,
    //         actualTotal:res.data.mediaLibrary
    //       });
    //     }
    //   })
    //     console.log(error);
    //   });
    // }
    // else{
    //   axios
    //   .get(baseUrl + `api/mediaGallery/${this.context.loggedInUserId}/all`)
    //   .then(res => {
    //     if (res.data.hasOwnProperty("mediaLibrary")) {
    //       this.setState({ isMediaLibraryEmpty: false });
    //       const mediaLibrary = res.data.mediaLibrary;
    //       this.setState({
    //         mediaLibrary,
    //         actualTotal:res.data.mediaLibrary
    //       });
    //     }
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
    // }
  };
  showLinkComponent = (e) => {
    e.stopPropagation()
    this.props.toggleLinkOption(null, null, this.props.index);
  };
  display=(value)=>{
    this.setState({uploadSelected:value})
  }
  openLightBox= (index) => {
    let lghtImges = this.state.mediaLibrary.map((obj) => {
      return obj.url;
    });
    this.setState({lightboxImages: lghtImges, isOpen : true, photoIndex: index});
  }
  render() {
    // console.log('state', this.state.uploadInput)
    // console.log('props', this.props.uploadInput)

    // console.log("uploadSE",this.state.uploadSelected)
    const breakpointColumnsObj = {
      default: 3,
      1100: 2,
      700: 1,
      500: 1
    };
    return (
      <div 
      id="upload"
        className="upload-option-component"
        onClick={this.handleClicked}
        style={{ border: "2px dashed #bcbec0", 
        marginTop:"-6px",
        padding:"15px",
        paddingBottom:"0px",
        marginBottom:"-5px",
        // padding: "33px 33px 10px 10px",
           background:this.props.layoutObject[this.props.index[0]]?.row[this.props.index[1]]?.column[this.props.index[2]]?.selected===true? "#aaacaf":"" 
          }}
  
        tabIndex="0"
        onFocus={()=>{this.display(true)}}
        onBlur={()=>{ this.display(false)}}
      >
          <button className="template-link-btn" onClick={(e)=>{this.showLinkComponent(e)}} >
            <img src={linkIcon} alt="" />
          </button>
       
        <div className="upload-container">
          {this.state.uploadInput.hasOwnProperty("type") &&
          this.state.uploadInput.type === "download" ? (
            <>
              {this.state.uploadInput.inputs.length === 0 ? (
                <div className="upload-section upload" >
                  <img src={uploadIcon} alt="" />
                  <button
                    className="choose-btn"
                    style={{fontSize:"11px",height:"30px",width:"100px"}}
                    onClick={this.toggleUploadModal}
                  >
                      {this.props.t("Datei wählen")}
                  </button>
                  <button
                    className="choose-btn"
                    style={{fontSize:"11px",height:"30px",width:"100px"}}
                    onClick={this.openUploadModalMediaSection}
                  >
                {this.props.t("Bildersammlung")}
                  </button>
                </div>
              ) : (
                <div className="images-container">
                  <div
                    className="images-overflow"
                    style={{
                      width:
                        this.state.uploadInput.inputs.length * 315 +
                        (this.state.uploadInput.inputs.length - 1) * 15,
                    }}
                  >
                    {this.state.uploadInput.inputs.map((item, index) => (
                      <div className="image-item" key={index} >
                        {/* {  console.log('i', item)} */}
                        <div
                          className="image"
                          style={{
                            backgroundImage:
                              item.type !== "image"
                                ? item.type === "picture"
                                  ? `url(${jpgIcon})`
                                  : `url(${require(`../../assets/${item.type}.svg`)})`
                                : `url(${jpgIcon})`,
                            backgroundSize: "60px",
                            height:"0"
                          }}
                        ></div>
                        <span className="display-name">{item.name}</span>
                        {/* <input
                          type="text"
                          className="display-name"
                          placeholder="Bildname"
                          value={item.display || ""}
                          onChange={e => this.setDisplayName(index, e)}
                        /> */}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="upload-section">
              <img src={uploadIcon} alt="" />
              <button className="choose-btn"  style={{fontSize:"11px",height:"30px",width:"100px"}}> {this.props.t("Datei wählen")}</button>
              <button className="choose-btn"  style={{fontSize:"11px",height:"30px",width:"100px"}}>  {this.props.t("Bildersammlung")}</button>
            </div>
          )}
          {this.state.uploadInput.inputs.length !== 0 && (
            <button className="remove-btn" onClick={this.removeUploadInputs}>
              <img src={crossSmallIcon} alt="" />
            </button>
          )}
        </div>
        <div
          className={`image-selector-modal ${
            this.state.uploadModalOpen && "open"
          }`}
        >
          <div className="selector-container">
            <Tabs
              selectedIndex={this.state.tabIndex}
              onSelect={(tabIndex) => this.handleTabShift(tabIndex)}
            >
              <TabList>
                 <Tab>{this.props.t("Bilder hochladen")}</Tab>
                <Tab> {this.props.t("Bildersammlung")}</Tab>
                <Tab> Unsplash</Tab>
              
              </TabList>
              <TabPanel>
                <UploadPdfDropzone
                  acceptUploadToMediaLibrary={this.acceptUploadToMediaLibrary}
                />
              </TabPanel>
              <TabPanel>
                <div className="media-library-header">
                  <div className="row">
                    <div className="col-6 text-left">
                      {/* <select className="type-select" onChange={this.handleDropdown}>
                        <option value="all-media">All Media</option>
                        <option value="images">Images</option>
                      </select> */}
                       <input
                        type="text"
                        className="search-input"
                        placeholder= {this.props.t("Suchen")}
                        value={this.state.searchValue}
                        onChange={this.handleSearch}
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-6 text-right">
                     
                    </div>
                  </div>
                </div>
                {this.state.delete===true ? 
                 <div style={{
                  color: "#888888",
                  fontWeight: "bold",
                  fontSize: "20px",
                  padding: "20px",
                  textAlign: "center",
                 }}><div><Spinner color="success" /></div></div>:
                 <></>
              }
                <div className="media-library-container">
                  {this.state.isOpen &&
                    <LightBox lightboxImages={this.state.lightboxImages} setIsOpen={(e) => this.setState({isOpen: e})} setPhotoIndex={(e) => this.setState({photoIndex: e})} photoIndex={this.state.photoIndex} />
                  }
                  {this.state.mediaLibrary.map((item, index) => (
                    <label
                      className={`media-item ${item.checked && "selected"}`}
                      key={index}
                    >
                       <img src={deleteIcon} alt={"Delete"}
                         style={{position:"absolute",top:"150px",left:"10px",borderRadius:"50%",background:"white",padding:"5px"}}
                          onClick={(e)=>this.deleteMediaImage(item,e)}/>
                      <input
                        type="checkbox"
                        checked={item.checked}
                        onChange={() => this.setMediaItemChecked(index)}
                        autoComplete="off"
                      />
                      <span />

                      <div
                        className="image"
                        onClick={() => this.openLightBox(index)}
                        style={{
                          backgroundImage:
                            item.type !== "image"
                              ? item.type === "picture"
                                ? `url(${item.url})`
                                : `url(${require(`../../assets/${item.type}.svg`)})`
                              : // `url(${require(`../../assets/${item.type}.svg`)})`
                                `url(${item.url})`,
                          backgroundSize:
                            item.type !== "image" ? "60px" : "cover",
                            height:"0"
                        }}
                      ></div>
                      <div className="file-name">{item.name}</div>
                    </label>
                  ))}
                </div>
                <div className="media-library-footer text-right">
                  <button
                    className="footer-btn"
                    onClick={this.insertSelectedMedia}
                  >
                      {this.props.t("Bild einfügen")}
                  </button>
                  <button
                    className="footer-btn"
                    onClick={this.toggleUploadModal}
                  >
                   {this.props.t("Abbrechen")}
                  </button>
                </div>
              </TabPanel>

              <TabPanel>
              <div>
                <div className="unsplash-library-header">
                  <div className="row">
                    <div className="col-6 text-left">
                      {/* <select className="type-select">
                        <option value="all-media">All Media</option>
                        <option value="images">Images</option>
                      </select> */}
                       <input  className="search-input"   
                     onChange={this.handleChange}
                      type="text" name="photo"
                       placeholder={this.props.t("Suchen")}
                       onKeyPress={this.handleKeypress}
                       autoComplete="off"/>
                       <button className="unsplash-sumbit" onClick={()=>this.handleSumbit(1)} type="sumbit"></button>
                    </div>
                    <div className="col-6 text-right">       
                    </div>
                  </div>
                </div>   
                {/* <div className="wrapper-unsplash">
                {this.state.result.map((photo) => {
                 return (<img src={photo.urls.small}/>);
                })}
                </div> */}
                <div className="wrapper-unsplash" onScroll={this.handleScroll}  ref={this.messagesRef}>
                <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">  
                {this.state.unsplashImages.map((photo) => {
                 return (
                     <div className={`unsplash-item ${this.state.TotalUnsplashImages.indexOf(photo.urls.small)>=0 && "selected"}`}  ref={this.messagesRef}>
                     <input type="checkbox" className="checkbox" onChange={e => this.setUnsplashItemChecked(e, photo.urls.small)} autoComplete="off" />
                     <img src={photo.urls.small} alt={"URL"} />
                     </div>); 
                })}
                </Masonry>
                </div>
                <div className="unsplash-footer text-right">
                  <button className="footer-btn" onClick={this.insertSelectedUnsplash}>
                   {this.props.t("Bild einfügen")}
                  </button>
                  <button className="footer-btn" onClick={this.toggleUploadModal}>
                  {this.props.t("Abbrechen")}
                  </button>
                </div>
                </div>
              </TabPanel>
            </Tabs>
            <button className="close-btn" onClick={this.toggleUploadModal}>
              <img src={crossLightIcon} alt="" />
            </button>
          </div>
        </div>
         <input
          type="text"
          className="question"
          placeholder={this.props.t("Text einfügen")}
          onChange={this.setQuestion}
          value={this.state.uploadInput.question}
          // style={{background:"#d1d3d4"}}
          style={{ background: "#e6e7e8" ,marginTop:"20px", borderRadius:"3px"}}
          autoComplete="off"
        />
      </div>
    );
  }
}

export default  withTranslation() (UploadOptionComponent);

// import React, { Component } from "react";
// import axios from "axios";
// import { Tab, Tabs, TabPanel, TabList } from "react-tabs";
// import UploadPdfDropzone from "../partials/UploadPdfDropzone";
// import { baseUrl } from "../../Constants";
// import { StateContext } from "../../context/state";
// import uploadIcon from "../../assets/upload.svg";
// import crossLightIcon from "../../assets/cross-light.svg";
// import crossSmallIcon from "../../assets/cross-small.svg";
// import jpgIcon from "../../assets/jpg-file.svg";
// class UploadOptionComponent extends Component {
//   static contextType = StateContext;
//   constructor(props) {
//     super(props);
//     this.state = {
//       uploadInput: {
//         question: "",
//         inputs: []
//       },
//       mediaLibrary: [],
//       tabIndex: 0,
//       uploadModalOpen: false,
//       isMediaLibraryEmpty: true
//     };
//   }

//   componentDidMount = () => {
//     // console.log('media gallery', this.context)
//     axios
//       .get(baseUrl + `api/mediaGallery/${this.context.loggedInUserId}/all`)
//       .then(res => {
//         // console.log(res);
//         if (res.data.hasOwnProperty("mediaLibrary")) {
//           this.setState({ isMediaLibraryEmpty: false });
//           const mediaLibrary = res.data.mediaLibrary;
//           this.setState({ mediaLibrary });
//         }
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   };

//   acceptUploadToMediaLibrary = params => {
//     // console.log('params', params)
//     if (this.state.isMediaLibraryEmpty) {
//       axios
//         .post(baseUrl + "api/mediaGallery", {
//           mediaLibrary: [
//             {
//               url: params.url,
//               type: params.type,
//               name: params.name
//             }
//           ],
//           userId: this.context.loggedInUserId
//         })
//         .then(res => {
//           // console.log(res);
//           const mediaLibrary = this.state.mediaLibrary;
//           mediaLibrary.push(params);
//           this.setState({ mediaLibrary, tabIndex: 1 });
//         })
//         .catch(error => {
//           console.log(error);
//         });
//     } else {
//       const mediaLibrary = this.state.mediaLibrary;
//       mediaLibrary.push(params);
//       axios
//         .put(baseUrl + `api/mediaGallery/${this.context.loggedInUserId}`, {
//           mediaLibrary,
//           userId: this.context.loggedInUserId
//         })
//         .then(res => {
//           console.log(res);
//           this.setState({ mediaLibrary, tabIndex: 1 });
//         })
//         .catch(error => {
//           console.log(error);
//         });
//     }
//   };

//   setQuestion = e => {
//     const uploadInput = this.state.uploadInput;
//     uploadInput.question = e.target.value;
//     this.setState({ uploadInput });
//     this.props.updateUploadInput(uploadInput);
//   };

//   toggleUploadModal = index => {
//     const mediaLibrary = this.state.mediaLibrary;
//     mediaLibrary.forEach(item => {
//       item.checked = false;
//     });
//     this.setState(prevState => ({
//       mediaLibrary,
//       uploadModalOpen: !prevState.uploadModalOpen,
//       tabIndex: 0
//     }));
//   };

//   openUploadModalMediaSection = index => {
//     const mediaLibrary = this.state.mediaLibrary;
//     mediaLibrary.forEach(item => {
//       item.checked = false;
//     });
//     this.setState({
//       mediaLibrary,
//       uploadModalOpen: true,
//       tabIndex: 1
//     });
//   };

//   setMediaItemChecked = index => {
//     const mediaLibrary = this.state.mediaLibrary;
//     mediaLibrary[index].checked = !mediaLibrary[index].checked;
//     this.setState({ mediaLibrary });
//   };

//   insertSelectedMedia = () => {
//     const uploadInput = this.state.uploadInput;
//     !uploadInput.hasOwnProperty("inputs") && (uploadInput.inputs = []);
//     this.state.mediaLibrary.forEach(item => {
//       item.checked &&
//         uploadInput.inputs.push({ type: item.type, url: item.url });
//     });
//     this.setState({ uploadInput, uploadModalOpen: false });
//     this.props.updateUploadInput(uploadInput);
//   };

//   setDisplayName = (index, e) => {
//     const uploadInput = this.state.uploadInput;
//     uploadInput.inputs[index].display = e.target.value;
//     this.setState({ uploadInput });
//     this.props.updateUploadInput(uploadInput);
//   };

//   removeUploadInputs = () => {
//     const uploadInput = this.state.uploadInput;
//     uploadInput.inputs = [];
//     this.setState({ uploadInput });
//     this.props.updateUploadInput(uploadInput);
//   };

//   static getDerivedStateFromProps = (nextProps, prevState) => {
//     // console.log('next', nextProps)
//     if (nextProps.uploadInput === undefined) {
//       return null;
//     } else {
//       return {
//         uploadInput: nextProps.uploadInput
//       };
//     }
//   };
//   handleClicked=()=>{
//     // console.log('clicked', this.state.uploadInput)
//     if(this.state.uploadInput.type==="download"){
//       const d={
//         uploadSelect:"download",
//         uploadMaximum:"",
//         uploadMinimum:""
//       }
//       this.props.setOptionSelected(this.props.index, "download",
//       null, null, d
//       );

//     }
//     else{

//       const d={
//         uploadSelect:"upload",
//         uploadMaximum:this.state.uploadInput.maximum?this.state.uploadInput.maximum:"",
//         uploadMinimum:this.state.uploadInput.minimum?this.state.uploadInput.minimum:""
//       }
//       this.props.setOptionSelected(this.props.index, "upload",
//       null, null, d
//       );
//     }

//   }
//   render() {
//     // console.log('ren', this.state.uploadInput)
//     return (
//       <div className="upload-option-component" onClick={this.handleClicked}>
//         <input
//           type="text"
//           className="question"
//           placeholder="Text einfügen"
//           onChange={this.setQuestion}
//           value={this.state.uploadInput.question}
//         />
//         <div className="upload-container">
//           {this.state.uploadInput.hasOwnProperty("type") &&
//           this.state.uploadInput.type === "download" ? (
//             <>
//               {this.state.uploadInput.inputs.length === 0 ? (
//                 <div className="upload-section upload">
//                   <img src={uploadIcon} alt="" />
//                   <button
//                     className="choose-btn"
//                     onClick={this.toggleUploadModal}
//                   >
//                     Choose file
//                   </button>
//                   <button
//                     className="choose-btn"
//                     onClick={this.openUploadModalMediaSection}
//                   >
//                     Media Library
//                   </button>
//                 </div>
//               ) : (
//                 <div className="images-container">
//                   <div
//                     className="images-overflow"
//                     style={{
//                       width:
//                         this.state.uploadInput.inputs.length * 315 +
//                         (this.state.uploadInput.inputs.length - 1) * 15
//                     }}
//                   >
//                     {this.state.uploadInput.inputs.map((item, index) => (
//                       <div className="image-item" key={index}>
//                         <div
//                           className="image"
//                           style={{
//                             backgroundImage:
//                               item.type !== "image"
//                                 ? (item.type==="picture"?`url(${jpgIcon})`:`url(${require(`../../assets/${item.type}.svg`)})`)
//                                 : `url(${jpgIcon})`,
//                             backgroundSize:"60px"
//                           }}
//                         ></div>
//                         <input
//                           type="text"
//                           className="display-name"
//                           placeholder="Bildname"
//                           value={item.display || ""}
//                           onChange={e => this.setDisplayName(index, e)}
//                         />
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               )}
//             </>
//           ) : (
//             <div className="upload-section">
//               <img src={uploadIcon} alt="" />
//               <button className="choose-btn">Choose file</button>
//               <button className="choose-btn">Media Library</button>
//             </div>
//           )}
//           {this.state.uploadInput.inputs.length !== 0 && (
//             <button className="remove-btn" onClick={this.removeUploadInputs}>
//               <img src={crossSmallIcon} alt="" />
//             </button>
//           )}
//         </div>
//         <div
//           className={`image-selector-modal ${this.state.uploadModalOpen &&
//             "open"}`}
//         >
//           <div className="selector-container">
//             <Tabs
//               selectedIndex={this.state.tabIndex}
//               onSelect={tabIndex => this.setState({ tabIndex })}
//             >
//               <TabList>
//                 <Tab>Upload Files</Tab>
//                 <Tab>Media Library</Tab>
//               </TabList>
//               <TabPanel>
//                 <UploadPdfDropzone
//                   acceptUploadToMediaLibrary={this.acceptUploadToMediaLibrary}
//                 />
//               </TabPanel>
//               <TabPanel>
//                 <div className="media-library-header">
//                   <div className="row">
//                     <div className="col-6 text-left">
//                       <select className="type-select">
//                         <option value="all-media">All Media</option>
//                         <option value="images">Images</option>
//                       </select>
//                     </div>
//                     <div className="col-6 text-right">
//                       <input
//                         type="text"
//                         className="search-input"
//                         placeholder="Search"
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="media-library-container">
//                   {this.state.mediaLibrary.map((item, index) => (
//                     <label
//                       className={`media-item ${item.checked && "selected"}`}
//                       key={index}
//                     >
//                       <input
//                         type="checkbox"
//                         checked={item.checked}
//                         onChange={() => this.setMediaItemChecked(index)}
//                       />
//                       <span />

//                        <div
//                         className="image"
//                         style={{
//                           backgroundImage:(item.type)!=="image"
//                           ? (item.type==="picture"?`url(${item.url})`:`url(${require(`../../assets/${item.type}.svg`)})`)
//                           // `url(${require(`../../assets/${item.type}.svg`)})`
//                           :
//                               `url(${item.url})`,
//                           backgroundSize:
//                             item.type !== "image" ? "60px" : "cover"
//                         }}
//                       ></div>
//                       <div className="file-name">{item.name}</div>
//                     </label>
//                   ))}
//                 </div>
//                 <div className="media-library-footer text-right">
//                   <button
//                     className="footer-btn"
//                     onClick={this.insertSelectedMedia}
//                   >
//                     Insert Selected Media
//                   </button>
//                   <button
//                     className="footer-btn"
//                     onClick={this.toggleUploadModal}
//                   >
//                     Cancel
//                   </button>
//                 </div>
//               </TabPanel>
//             </Tabs>
//             <button className="close-btn" onClick={this.toggleUploadModal}>
//               <img src={crossLightIcon} alt="" />
//             </button>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default UploadOptionComponent;

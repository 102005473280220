import React, { useState, useEffect, useContext, useCallback, useRef } from "react"
import axios from "axios";
import swal from "sweetalert";
import {
  Container,
  Label,
  Card,
  Button,
  InputGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupButtonDropdown,
  InputGroupAddon,
  InputGroupText,
  Dropdown,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import { 
  Image, Row, Col, FormCheck
} from 'react-bootstrap';

import {
  Typography,
} from "@material-ui/core";
import { ChromePicker } from "react-color";
import Dropzone from "react-dropzone";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup'
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ReactFlagsSelect from 'react-flags-select';
import useClickOutside from "../GlobalComponents/useClickOutside";
import { StateContext } from "../../context/state";
import HttpService from "../../services/HttpService";
import ReactSelectCustom from "../dashboard/ReactSelectCustom";
import AsyncSelect from 'react-select/async';
import NewPassword from "./NewPassword";
import ForgotPassword from "./ForgotPassword";
import { baseUrl } from "../../Constants";
import Loader from "../../components/loader/Loader";

import {
  checkMarkSaveIcon,
  trashIcon,
  duplicateIcon,
  textAlignCenterIcon,
  textAlignRightIcon,
  textAlignLeftIcon,
  uploadSMIcon
} from '../../assets'

const imageMaxSize = 10240;

const ContainerSection = styled(Container)`
  font-family: Segoe UI, Arial, sans-serif;
`

const styles = {
  cardui: {
    backgroundColor: '#f8f8f8',
    marginTop: 6,
    alignItem: 'center',
    border: '0px solid rgba(0,0,0,.125)'
  },
  dropdownui: {
    width: 190,
    fontSize: "13px",
    backgroundColor: '#fff',
    border: '1px solid #e3e2e2',
    padding: 0,
    height: 32
  },
  sectionRight: {
    backgroundColor: '#E7E7E7',
    minHeight: '1000px'
  },
  venueLogo: {
    flexShrink:0,
    height:"100%",
    width: "100%",
    objectFit:'contain'
  },
}

const StyleVenueLogo = styled.div`
width:100%;
height:160px;
display:flex;
justify-content:center;
align-items:center;
overflow:hidden;
border-radius: 5px;
  
`
const StyleLabel = styled(Label)`
  color: #8f8f8f;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  pointer-events: none !important;
`;

const StyleHr = styled.hr`
  margin-top: 0rem;
  // margin-bottom: .5rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`

const StyleTypography = styled(Typography)`
  color: #929598;
  span {
    font-weight: 500;
  }
`
const StyleField = styled(Field)`
  width: 200px;
  border-radius: 4px !important;
`

const StyleSearchField = styled.div`
border-radius: 4px !important;
padding-bottom: 10px;
`

const StyleCardSection = styled.div`
  p {
    color: #a2a2aa;
  }
  .react-select {
    width: 100%;
    /* margin-right: 5px; */
  }
  background-color: #F8F8F8;
  padding: 1px 10px 8px 10px;
  border-radius: 4px;
  .flag-select {
    width: 56%;
    min-width: 56%;
  }
  .ReactFlagsSelect-module_selectBtn__19wW7 {
    padding: 5px 2px;
    font-size: 15px !important;
  }
  .greeting-text {
    border-color: #ced4da !important;
  }

  .custom-border-radius {
    border-left-style: none;
    border-radius: 0px !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
  .input-group-append {
    margin-left: 0px !important;
  }
  .disable-dropzone {
    background-color: #ced4da;
  }
`

const StyleCardSectionRight = styled.div`
  p {
    color: #a2a2aa;
    line-height:10px;
  }
  .react-select {
    width: 100%;
    /* margin-right: 5px; */
  }
  /* background-color: #F8F8F8; */
  border-radius: 4px;
`

const StylePTag = styled.p`
  width: 50%;
  padding-right: 2px;
  word-break: break-word;
  line-height: 0px;
`

const StyleSmall = styled.small`
  font: normal normal normal 13px/16px Noto Sans;
  letter-spacing: 0px;
  color: #707070;
  /* opacity: 1; */
  text-align: left;
  /* padding-left: 1px; */
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyleSectionB = styled.div`
  padding-top: 0.5rem;
`

const StyleColorPicker = styled.div`
  width: 100%;
  
  .color-box {
    width: 40px;
    height: 38px;
    border: 1px solid #ced4da;
    border-left-style: none;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    /* margin-right: 10px; */
  }
  .color-picker-custom {
    left: 54px;
    top: 42px;
    z-index: 1;
    position: absolute;
  }
  .color-input-box {
    border-radius: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    /* width: 175px; */
    background-color: #fff;
    /* border-right-style: none; */
  }
  .form-control:focus {
    border-color: #ced4da;
  }
`

const StyleButtonUpload = styled(Button)`
  background-color: white;
  padding: 0;
  width: 50%;
  border-color: #ced4da !important;
  .p-upload {
    border-bottom: 1px solid #ced4da;
    padding: 5px 10px;
    color: #000;
  }
  .p-preview {
    padding: 45px;
    height: 0px;
  }
`

const StyleButtonInvoiceUpload = styled(Button)`
  background-color: white;
  padding: 0;
  width: 50%;
  border-color: #ced4da !important;
  .p-upload {
    border-bottom: 1px solid #ced4da;
    padding: 5px 10px;
    color: #000;
  }
  .p-preview {
    padding: 45px;
    height: 0px;
  }
`

const StyleButtonBlock = styled(Button)`
  border-color: #ced4da;
  background: #fff;
`

const StyleInputGroup = styled(InputGroup)`
  left: 1px;
  .add-on {
    width: 20%;
    /* border: 1px solid #ced4da; */
  }
  .addon-img {
    width: 100%;
    /* height: 37px; */
    border: 4px solid #ced4da;
    border-radius: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #C4C4C4;
    max-height: 38px !important;
  }
  .flag-select-box {
    width: 80%;
    .select__control {
      /* border-left-style: none; */
      border-radius: 0px !important;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }
`

const StyleInputGroupText = styled(InputGroupText)`
  padding: 0;
`

const StyleListDropdown = styled.div`
  .icons {
    float: right;
  }
  :hover {
    color: rgb(149 150 155);
  }
  .pointer {
    cursor: pointer;
  }
  .list-group-item {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .list-group-item:hover {
    background: #e9ecef;
  }
`

const StyleImageIcon = styled(Image)`
  padding: 3px;
  size: 10px;
  cursor: pointer;
`

const StyleReactSelectCustom = styled(ReactSelectCustom)`
  .css-1owu2yd-control:hover {
    border-color: #ced4da;
  }
`

const StyleInvoiceDiv = styled.span`
  width: 100%;
  .form-control.is-invalid, .was-validated .form-control:invalid {
    padding-right: calc(1.3em + 0.72rem);
  }
  .text {
    font-size: 10px;
    padding: 5px;
    white-space: nowrap;
  }
`

const StyleAsyncDropdown = styled.div`
  .icons {
    display: none;
  }
  .select__option:hover {
    cursor: pointer;
    .icons {
      display: block;
    }
  }
`

const StyleFormCheck = styled.div`
  .custom-switch {
    padding-left: 2.25rem;
    width: 50%;
  }
  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    float: right;
    width: 0;
  }
`

const StyleBtnDropDown = styled.div`
  .btn-secondary {
    background-color: #fff;
  }
  .btn {
    border: 0px solid transparent;
    padding: 0.275rem 0.50rem;
  }
`

const StyleSetInvoiceNoButton = styled(Col)`
  padding-left: 6px;
`

const StyleListGroup = styled(ListGroup)`
  border-radius: 4px;
  padding-top: 10px;
  .item {
    cursor: pointer;
    border: 0px;
  }
  .active {
    background: rgb(149, 150, 155);
  }
`

const flagCodeMappingLanguage = {
  "se":"ES",
  "en":"GB",
  "de":"DE",
  "no":"NO"
}

const flagCodeMappingCurrency = {
  "€":"EU",
  "kr.":"IS",
  "₹":"IN",
  "₪":"IL",
  "NOK":"NO",
  "Zł":"PL",
  "₽":"RU",
  "Skr":"SE",
  "CHF":"CH",
  "₺":"TR",
  "$":"US",
  "£":"GB"
}

const Settings = (props) => {
  const { t } = useTranslation();
  const refColorPicker = useRef();

  let contextDetails = useContext(StateContext);
  const venueId = contextDetails.venueId ? contextDetails.venueId : null;
  const userRole = contextDetails.userRole;
  const [settingDetails, setSettingDetails] = useState({});
  const [currSettingType, setCurrSettingType] = useState('general')
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [fontOptions, setFontOptions] = useState([])
  const [dateFormatOptions, setDateFormatOptions] = useState([]);
  const [timeFormatOptions, setTimeFormatOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [lineFormattingOptions, setLineFormattingOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([])
  const [fontSizeOptions, setFontSizeOptions] = useState([])
  const [shpaeOptions, setShapeOptions] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState('GB');
  const [processing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalENewUserOpen, setModalENewUserOpen] = useState(false);
  const [modalEForgotPasswordOpen, setModalEForgotPasswordOpen] = useState(false);
  const [backgroundImgSrc, setBackgroundImgSrc] = useState(null);
  const [venueLogoSrc, setVenueLogoSrc] = useState(null);
  const [invoiceLogoSrc, setInvoiceLogoSrc] = useState(null);
  const [validateBackgroundImg, setValidateBackgroundImg] = useState(true);
  const [validateVenueLogo, setValidateVenueLogo] = useState(true);
  const [langFlagCountryCode, setLangFlagCountryCode] = useState('GB')
  const [, setCurrencyFlagCode] = useState('GB')
  const [timer, setTimer] = useState(null)
  const [, setLocationList] = useState([])
  const [syntaxFormatOptions, setSyntaxFormatOptions] = useState([])
  const [subscribedLinks, setSubscribedLinks] = useState(false)
  const [sharedLinks, setSharedLinks] = useState(false)
  const [invoiceFormReqType, setInvoiceFormReqType] = useState('general')
  const [venueInvoiceDetails, setVenueInvoiceDetails] = useState({})
  const [venueInvoiceGeneralDetails, setVenueInvoiceGeneralDetails] = useState([])
  const [selectedSyntaxDetails, setSelectedSyntaxDetails] = useState({})
  const [invoiceSyntax, setInvoiceSyntax] = useState('[LETTER]-[YEAR]-[NUMBER]');
  const [allowGeneralUpdate, setAllowGeneralUpdate] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [invoiceTemplateDetail, setInvoiceTemplateDetail] = useState({});
  const [cloneTemplate, setCloneTemplate] = useState(false);

  const [openColorPicker, setOpenColorPicker] = useState({
    welcomeTextPicker: false,
    headlineColorPicker: false,
    textColorPicker: false,
    accentColorPicker: false,
    backgroundColorPicker: false,
  });

  const closeWelcomeTextPicker = useCallback(() => setOpenColorPicker({
    welcomeTextPicker: false,
    headlineColorPicker: false,
    textColorPicker: false,
    accentColorPicker: false,
    backgroundColorPicker: false,
  }), []);
  useClickOutside(refColorPicker, closeWelcomeTextPicker);

  let contextValue = useContext(StateContext);
  const { venueLogo } = contextValue;
  const value = useContext(StateContext);

  const settingOptions = [
    { value: "general", label: t("General") },
    { value: "locations", label: t("Locations") },
    { value: "invoices", label: t("Invoices") },
    // { value: "rechnungen", label: t("Rechnungen" }
  ];

  const dateFormatEn = [
    {
      value: 'DD/MM/YY',
      label: "DD-MM-YY"
    },
    {
      value: 'MM/DD/YY',
      label: "MM-DD-YY"
    },
    {
      value: 'YY/MM/DD',
      label: "YY/MM/DD"
    }
  ]

  const dateFormatDe = [
    {
      value: 'DD/MM/YY',
      label: "TT-MM-JJ"
    },
    {
      value: 'MM/DD/YY',
      label: "MM-TT-JJ"
    },
    {
      value: 'YY/MM/DD',
      label: "JJ/MM/TT"
    }
  ]

  useEffect(() => {
    
    setSyntaxFormatOptions([
      {
        value: '[LETTER]-[YEAR]-[NUMBER]',
        label: '[LETTER]-[YEAR]-[NUMBER]'
      },
      {
        value: '[LETTER]-[NUMBER]',
        label: '[LETTER]-[NUMBER]'
      },
      {
        value: '[YEAR]-[NUMBER]',
        label: '[YEAR]-[NUMBER]'
      },
      {
        value: '[NUMBER]',
        label: '[NUMBER]'
      }
    ]);
    setLineFormattingOptions(
      [
        {
          value: 'text-align-center',
          label: 'textAlignCenterIcon',
        },
        {
          value: 'text-align-right',
          label: 'textAlignRightIcon',
        },
        {
          value: 'text-align-left',
          label: 'textAlignLeftIcon',
        }
      ] 
    );
    setFontSizeOptions([
      {
        value: 8,
        label: 8,
      },
      {
        value: 10,
        label: 10,
      },
      {
        value: 12,
        label: 12,
      },
      {
        value: 14,
        label: 14,
      },
      {
        value: 16,
        label: 16,
      },
      {
        value: 18,
        label: 18,
      }
    ]);

    setShapeOptions([
      {
        value: 'square',
        label: 'Square',
      },
      {
        value: 'oval',
        label: 'Oval',
      },
    ]);
    if(venueId) {
      HttpService.getVenueSettingDetails(venueId).then(
        (response) => {
          setSettingDetails(response);
          setBackgroundImgSrc(response.backgroundImgSrc);
          setVenueLogoSrc(response.venueLogoSrc);
          setLangFlagCountryCode(response.languageFormat ? flagCodeMappingLanguage[response.languageFormat] : 'GB');
          setCurrencyFlagCode(response.currencyFormat ? flagCodeMappingCurrency[response.currencyFormat] : 'GB');
          setDateFormatOptions((response.languageFormat === 'GB' || response.languageFormat === 'en') ? dateFormatEn : dateFormatDe)
        })
    }
    HttpService.getLanguages().then(
      (response) => {
        let lang = response.length > 0 && response.map((val) => {
          return {
            value: val.code,
            label: val.name,
            country_code: val.country_code
          }
        })
        setLanguageOptions(lang)
      })
    HttpService.getCurrency().then(
      (response) => {
        let currency = response.length > 0 && response.map((val) => {
          return {
            value: val.symbol,
            label: val.country + ' ' + val.symbol
          }
        })
        setCurrencyOptions(currency)
      })
    HttpService.getVenueSettings().then(
      (response) => {
        // let dateFormats = response.dateFormats.length > 0 && response.dateFormats.map((val) => {
        //   return {
        //     value: val,
        //     label: formatDateObj[val] ? formatDateObj[val] : val
        //   }
        // })
        // setDateFormatOptions(dateFormats)
        let timeFormats = response.timeFormats.length > 0 && response.timeFormats.map((val) => {
          return {
            value: val,
            label: val + ' hours'
          }
        })
        setTimeFormatOptions(timeFormats)
        let fontFormats = response.fonts.length > 0 && response.fonts.map((val) => {
          return {
            value: val,
            label: val
          }
        })
        setFontOptions(fontFormats)
      })
  }, [dateFormatDe, dateFormatEn, venueId])

  useEffect(() => {
    if(venueId && currSettingType === 'invoices') {
      HttpService.getSettingsInvoiceNumber(venueId).then(
        (response) => {
          if(Object.keys(response).length > 0) {
            setVenueInvoiceDetails(response);
            HttpService.getGeneralSettings(venueId).then(
              (resp) => {
                if(resp.data.length > 0) {
                  setVenueInvoiceGeneralDetails(resp.data);
                  let activeSyntax = resp.data.find((item) => item.isActive === true && item);
                  setInvoiceSyntax(activeSyntax.syntax);
                  handleSyntaxChange(activeSyntax.syntax, resp.data);
                }
            })
          }
        })
      HttpService.getTemplates(venueId).then((response) => {
        if(response.data) {
          setTemplateData(response.data);
        }
      })
    }
  }, [currSettingType, venueId])

  const handleSyntaxChange = (syntax, details) => {
    setInvoiceSyntax(syntax);
    const selSyntax = details && details.find((item) => item.syntax === syntax && item);
    if(selSyntax) {
      setSelectedSyntaxDetails(selSyntax);
    } else {
      setSelectedSyntaxDetails({});
    }
  }

  const toggle = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const getDateFormatOptionValue = (value) => {
    let selectedOption = dateFormatOptions && dateFormatOptions.find(item => item.value === value)
    if(selectedOption){
      return {'value': selectedOption.value, 'label': selectedOption.label}
    }
  }

  const getTimeFormatOptionValue = (value) => {
    return timeFormatOptions && timeFormatOptions.find(item => item.value === parseInt(value))
  }

  const getLanguageFormatOptionValue = (value) => {
    return languageOptions && languageOptions.find(item => item.value === value)
  }

  const getCurrencyFormatOptionValue = (value) => {
    return currencyOptions && currencyOptions.find(item => item.value === value)
  }

  const getFontOptionValue = (value) => {
    return fontOptions && fontOptions.find(item => item.value === value)
  }

  const getFontSizeOptionValue = (value) => {
    return fontSizeOptions && fontSizeOptions.find(item => item.value === parseInt(value))
  }

  const getShapeOptionValue = (value) => {
    return shpaeOptions && shpaeOptions.find(item => item.value === value)
  }

  const getInvoiceSyntaxOptionValue = (value) => {
    return syntaxFormatOptions && syntaxFormatOptions.find(item => item.value === value)
  }

  const handleToggleColorPicker = (pickerType) => {
    setOpenColorPicker({
      welcomeTextPicker: pickerType === 'welcomeTextColor' ? !openColorPicker.welcomeTextPicker : false,
      headlineColorPicker: pickerType === 'headlineColor' ? !openColorPicker.headlineColorPicker : false,
      textColorPicker: pickerType === 'presTextColor' ? !openColorPicker.textColorPicker : false,
      accentColorPicker: pickerType === 'presAccentColor' ? !openColorPicker.accentColorPicker : false,
      backgroundColorPicker: pickerType === 'presBackgroundColor' ? !openColorPicker.backgroundColorPicker : false,
    })
  }



  const handleOnDropBackgroundImg = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyImageFile(rejectedFiles);
    }

    if (acceptedFiles && acceptedFiles.length > 0) {
      const isVerified = verifyImageFile(acceptedFiles);
      if (isVerified) {
        const currentFile = acceptedFiles[0];
        const data = new FormData();
        data.append("blob", currentFile);
        axios
          .post(baseUrl + `api/blob/${1}`, data, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            setBackgroundImgSrc(res.data.url);
            setValidateBackgroundImg(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const handleOnDropVenueLogo = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyImageFile(rejectedFiles);
    }

    if (acceptedFiles && acceptedFiles.length > 0) {
      const isVerified = verifyImageFile(acceptedFiles);
      if (isVerified) {
        const currentFile = acceptedFiles[0];
        const data = new FormData();
        data.append("blob", currentFile);
        axios
          .post(baseUrl + `api/blob/${3}`, data, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            setVenueLogoSrc(res.data.url);
            setValidateVenueLogo(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const handleOnDropInvoiceLogo = (acceptedFiles, rejectedFiles) => {
    if(cloneTemplate) {
      return;
    }
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyImageFile(rejectedFiles);
    }

    if (acceptedFiles && acceptedFiles.length > 0) {
      const isVerified = verifyImageFile(acceptedFiles);
      if (isVerified) {
        setIsLoading(true);
        const currentFile = acceptedFiles[0];
        const data = new FormData();
        data.append("blob", currentFile);
        axios
          .post(baseUrl + `api/blob/${3}`, data, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            setInvoiceLogoSrc(res.data.url);
            setValidateVenueLogo(true);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      }
    }
  };

  const verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileSize = Math.round(currentFile.size / 1024);
      if (currentFileSize > imageMaxSize) {

        // swal(`Diese Datei ist zu groß`, "", "info");
        swal(t('Bilder bis 15 MB sind erlaubt'), "", "info");

        return false;
      }
      return true;
    }
  };

  const handleSubmit = async (values, resetForm = null, showPopup = true) => {
    if(currSettingType === 'general') {
      setIsLoading(true);
      let venueData = {
        venueName: settingDetails.venueName ?? '',
        venueURL: settingDetails.venueURL ?? '',
        timeFormat: values.timeFormat.value,
        dateFormat: values.dateFormat.value,
        welcomeText: values.welcomeText,
        invoice:settingDetails.invoice ?? '',
        invoiceReceipt:settingDetails.invoiceReceipt ?? '',
        welcomeTextColor: values.welcomeTextColor,
        welcomeTextFont: values.welcomeTextFont.value,
        backgroundImgSrc: backgroundImgSrc,
        venueLogoSrc: venueLogoSrc,
        paragraphs: settingDetails.paragraphs ?? '',
        isDeleted: false,
        currencyFormat:values.currencyFormat.value,
        languageFormat:values.languageFormat.value,
        lineFormat: values.lineFormat,
        loginFontSize: values.loginFontSize.value,
        headlineFont: values.headlineFont ? values.headlineFont.value : '',
        headlineSize: values.headlineSize.value,
        headlineColor: values.headlineColor,
        presTextFont: values.presTextFont ? values.presTextFont.value : '',
        presTextSize: values.presTextSize.value,
        presTextColor: values.presTextColor,
        presAccentColor: values.presAccentColor,
        presBackgroundColor: values.presBackgroundColor,
        presColorShape: values.presColorShape ? values.presColorShape.value : '',
      };
      await HttpService.updateVenue(venueId, venueData).then(
        (response) => {
            value.updateState({
              venueLogo: venueLogoSrc,
              currencyFormat: values.currencyFormat.value,
              languageFormat: values.languageFormat.value,
            });
            i18next.changeLanguage(values.languageFormat.value);
            setDateFormatOptions((values.dateFormat.value === 'GB' || values.dateFormat.value === 'en') ? dateFormatEn : dateFormatDe)
            if(showPopup) {
              swal(t('Updated Successfully'), '', 'info');
            }
        }
      )
      setIsLoading(false);
    } else if(currSettingType === 'locations') {
      let locationData = {
        venueId,
        locationName: values.locationName
      };
      await HttpService.createLocation(locationData).then(
        (response) => {
            swal(t('Created Successfully!'), '', 'info');
        }
      )
    } else {
      if(invoiceFormReqType === 'general') {
        let proceedStatus = false;
        if(Object.keys(venueInvoiceDetails).length === 0) {
          let invoiceData = {
            venueId,
            invoiceNo: values.invoiceNo
          };
          await HttpService.createInvoiceNumber(invoiceData).then(
            (response) => {
              proceedStatus = true;
            }
          )
        } else {
          await HttpService.updateInvoiceNumber(venueInvoiceDetails._id, values.invoiceNo).then(
            (response) => {}
          )
          proceedStatus = true;
        }
        if(proceedStatus) {
          if(values._id) {
            let generalData = {
              venueId,
              syntax: values.syntax.value,
              letter: values.letter,
              year: values.year
            };
            await HttpService.updateInvoiceGeneralSetting(values._id, venueId, generalData).then(
              (response) => {
                  swal(t('Updated Successfully'), '', 'info');
              }
            )
          } else {
            let generalData = {
              venueId,
              syntax: values.syntax.value,
              letter: values.letter,
              year: values.year
            };
            await HttpService.createInvoiceGeneralSetting(generalData).then(
              (response) => {
                  swal(t('Created Successfully!'), '', 'info');
              }
            )
          }
          HttpService.getGeneralSettings(venueId).then(
            (resp) => {
              if(resp.data.length > 0) {
                // setVenueInvoiceGeneralDetails(resp.data);
                // handleSyntaxChange(invoiceSyntax, resp.data);
              }
          })
        }
      } else {
        let templateDetail = {
          venueId,
          templateName: values.templateName,
          font: values.appearnceFont.value,
          logo: invoiceLogoSrc,
          welcomeText: values.appearnceWelcomeText,
          closingText: values.appearnceClosingText,
          detail1: values.detail1,
          detail2: values.detail2,
          detail3: values.detail3,
          detail4: values.detail4,
        };
        if(values.invoiceTemplateId) {
          if(cloneTemplate) {
            await HttpService.cloneTemplate(values.invoiceTemplateId, values.templateName).then(
              (response) => {
                  templateDetail._id = response.insertedId;
                  let newTemplateData = [...templateData, templateDetail];
                  setTemplateData(newTemplateData);
                  swal(t('Updated Successfully!'), '', 'info');
              }
            )
          } else {
            await HttpService.updateTemplate(templateDetail, values.invoiceTemplateId).then(
              (response) => {
                  let objIndex = templateData.findIndex((obj => obj._id === values.invoiceTemplateId));
                  templateDetail._id = values.invoiceTemplateId;
                  templateData[objIndex] = templateDetail;
                  setTemplateData(templateData);
                  swal(t('Updated Successfully!'), '', 'info');
              }
            )
          }
        } else {
          await HttpService.createTemplate(templateDetail).then(
            (response) => {
              templateDetail._id = response.insertedId;
              let newTemplateData = [...templateData, templateDetail];
              setTemplateData(newTemplateData);
              if(resetForm) {
                resetForm();
                setInvoiceLogoSrc(null);
              }
              swal(t('Created Successfully!'), '', 'info');
            }
          )
        }
      }
    }
  }

  const handleLanguageChange = (values, language) => {
    values.languageFormat = language;
    handleSubmit(values, null, false);
  }

  const invoiceNumberMinNumber = () => {
    return Object.keys(venueInvoiceDetails).length > 0 ? parseInt(venueInvoiceDetails.invoiceReceipt) : 1
  }

  const formSchema = Yup.object().shape(
    currSettingType === 'general' ? {
      dateFormat: Yup.object().required(t("Date format is required")),
      timeFormat: Yup.object().required(t("Time format is required")),
      languageFormat: Yup.object().required(t("Language format is required")),
      currencyFormat: Yup.object().required(t("Cuurency format is required")),
      lineFormat: Yup.string().required(t("Line format is required")),
      welcomeText: Yup.string().required(t("Greeting text is required")),
      welcomeTextFont: Yup.object().required(t("Welcome text font is required")),
      loginFontSize: Yup.object().required(t("Login font size is required")),
      welcomeTextColor: Yup.string().required(t("Welcome text color is required")),
      headlineFont: Yup.object().required(t("Headline text color is required")),
      headlineSize: Yup.object().required(t("Headline size is required")),
      headlineColor: Yup.string().required(t("Headline color is required")),
      presTextFont: Yup.object().required(t("Text font is required")),
      presTextSize: Yup.object().required(t("Text size is required")),
      presTextColor: Yup.string().required(t("Text color is required")),
      presAccentColor: Yup.string().required(t("Accent color is required")),
      presBackgroundColor: Yup.string().required(t("Background color is required")),
      presColorShape: Yup.object().required(t("Color shape is required")),
    } : currSettingType === 'locations' ? {
      locationName: Yup.string().required(t("Location name is required")),
    } : (
      invoiceFormReqType === 'general' ? (
        invoiceSyntax === '[NUMBER]' ? (
          {
            syntax: Yup.object().required(t("Syntax is required")),
            invoiceNo: Yup.number().min(invoiceNumberMinNumber())
          }
        ) : (
          invoiceSyntax === '[LETTER]-[NUMBER]' ? {
            syntax: Yup.object().required(t("Syntax is required")),
            letter: Yup.string().required(t("Letter is required")).matches(/^[aA-zZ\s]+$/, t("Only alphabets are allowed for this field ")),
            invoiceNo: Yup.number().min(invoiceNumberMinNumber())
          } : (
            invoiceSyntax === '[YEAR]-[NUMBER]' ? {
              syntax: Yup.object().required(t("Syntax is required")),
              year: Yup.number()
                .test('len', 'Must be exactly 4 characters',
                val => val && val.toString().length === 4 ).min(new Date().getFullYear()),
              invoiceNo: Yup.number().min(invoiceNumberMinNumber())
            } : {
              syntax: Yup.object().required(t("Syntax is required")),
              letter: Yup.string().required(t("Letter is required")).matches(/^[aA-zZ\s]+$/, t("Only alphabets are allowed for this field ")),
              year: Yup.number()
                .test('len', 'Must be exactly 4 characters',
                val => val && val.toString().length === 4 ).min(new Date().getFullYear()),
              invoiceNo: Yup.number().min(invoiceNumberMinNumber())
            }
          )
        )) :
      {
        templateName: Yup.string().required(t("Template name is required")),
        appearnceFont: Yup.object().required(t("Appearence font is required")),
        appearnceWelcomeText: Yup.string().required(t("Welcome text is required")),
        appearnceClosingText: Yup.string().required(t("Closing text is required")),
        detail1: Yup.string().required(t("Footer is required")),
        detail2: Yup.string().required(t("Footer is required")),
        detail3: Yup.string().required(t("Footer is required")),
        detail4: Yup.string().required(t("Footer is required"))
      }
    )
  )

  const searchLocations = ( inputValue, callback ) => {
    // if( inputValue.length > 1) {
      if (timer) {
        clearTimeout(timer)
        setTimer(null)
      }
      setTimer(
          setTimeout(() => {
            HttpService.getLocations(venueId, inputValue).then((response) => {
              if(response.data) {
                setLocationList(response.data);
                callback(formatLocationOptions(response.data));
              } else {
                callback([]);
              }
            })
          }, 500)
      )
    // }
  }

  const formatLocationOptions = ( locations ) => {
    return locations.map( location => ({ value: location._id, label: <StyleListDropdown><span>{location.locationName}</span><span className="icons"><StyleImageIcon src={duplicateIcon} onClick={() => handleLocationDuplicate(location)}></StyleImageIcon><StyleImageIcon src={trashIcon} onClick={() => handleLocationDelete(location)}></StyleImageIcon></span></StyleListDropdown>, data: location }) );
  }

  const handleLocationDelete = (location) => {
    swal({
      title: `${t('Are you sure?')}`,
      text: `${t('Do you want to delete this location')}`,
      buttons: [`${t('Abbrechen')}`, t("OK")],
      dangerMode: true,
    })
    .then((isConfirmed) => {
        if (isConfirmed) {
          HttpService.deleteLocation(location._id).then(
              (response) => {
                swal(t('Deleted Successfully!'), '', 'info');
              }
          )
        }
    })
  }

  const handleLocationDuplicate = (location) => {
    
  }

  const searchTemplates = ( event ) => {
    event.preventDefault();
    let inputValue = event.target.value;
    // if( inputValue.length > 1) {
      if (timer) {
        clearTimeout(timer)
        setTimer(null)
      }
      setTimer(
          setTimeout(() => {
            HttpService.getTemplates(venueId, inputValue).then((response) => {
              if(response.data) {
                setTemplateData(response.data);
              }
            })
          }, 500)
      )
    // }
  }

  const handleTemplateDelete = (template) => {
    setInvoiceTemplateDetail({});
    swal({
      title: `${t('Are you sure?')}`,
      text: `${t('Do you want to delete this template')}`,
      buttons: [`${t('Abbrechen')}`, t("OK")],
      dangerMode: true,
    })
    .then((isConfirmed) => {
        if (isConfirmed) {
          HttpService.deleteTemplate(template._id).then(
              (response) => {
                let tempObj = templateData.filter((obj => obj._id !== template._id));
                setTemplateData(tempObj);
                swal(t('Deleted Successfully!'), '', 'info');
              }
          )
        }
    })
  }

  const handleSettingPermission = () => {
    if(userRole === 'VA') {
      swal({
        title: `${t('Warning')}`,
        text: `${t('Do you really want to change the invoice number')}`,
        buttons: [`${t('Cancel')}`, t("Proceed")],
        dangerMode: true,
      })
      .then((isConfirmed) => {
          if (isConfirmed) {
            setAllowGeneralUpdate(!allowGeneralUpdate);
          }
      })
    } else {
      swal({
        title: '',
        text: t('Only the Venue Admin can change the invoice Number'),
        type: "warning",
        buttons: [`${t('Abbrechen')}`, t("OK")],
        dangerMode: true,
      })
    }
  }

  return (
    <React.Fragment>
      <ContainerSection fluid>
        { isLoading &&
          <Loader />
        }
        { modalENewUserOpen &&
          <NewPassword 
            closePopup={() => setModalENewUserOpen(!modalENewUserOpen)}
          />
        }
        { modalEForgotPasswordOpen &&
          <ForgotPassword 
            closePopup={() => setModalEForgotPasswordOpen(!modalEForgotPasswordOpen)}
          />
        }
        <Formik
          enableReinitialize={true}
          initialValues={currSettingType === 'general' ? {
            settingType: 'general',
            dateFormat: getDateFormatOptionValue(settingDetails.dateFormat ? settingDetails.dateFormat : 'DD/MM/YY'),
            timeFormat: getTimeFormatOptionValue(settingDetails.timeFormat ? settingDetails.timeFormat : 24),
            languageFormat: getLanguageFormatOptionValue(settingDetails.languageFormat ? settingDetails.languageFormat : 'en'),
            currencyFormat: getCurrencyFormatOptionValue(settingDetails.currencyFormat ? settingDetails.currencyFormat : '€'),
            lineFormat: settingDetails.lineFormat ?? 'text-align-center',
            welcomeText: settingDetails.welcomeText ?? '',
            welcomeTextFont: getFontOptionValue(settingDetails.welcomeTextFont ? settingDetails.welcomeTextFont : ''),
            loginFontSize: getFontSizeOptionValue(settingDetails.loginFontSize ? settingDetails.loginFontSize : 8),
            welcomeTextColor: settingDetails.welcomeTextColor ?? '',
            headlineFont: getFontOptionValue(settingDetails.headlineFont ? settingDetails.headlineFont : ''),
            headlineSize: getFontSizeOptionValue(settingDetails.headlineSize ? settingDetails.headlineSize : 8),
            headlineColor: settingDetails.headlineColor ?? '',
            presTextFont: getFontOptionValue(settingDetails.presTextFont ? settingDetails.presTextFont : ''),
            presTextSize: getFontSizeOptionValue(settingDetails.presTextSize ? settingDetails.presTextSize : 8),
            presTextColor: settingDetails.presTextColor ?? '',
            presAccentColor: settingDetails.presAccentColor ?? '',
            presBackgroundColor: settingDetails.presBackgroundColor ?? '',
            presColorShape: getShapeOptionValue(settingDetails.presColorShape ? settingDetails.presColorShape : ''),
          } : currSettingType === 'locations' ?
            {
              locations: [],
              locationName: '',
            } : {
              templates: [],
              _id: selectedSyntaxDetails && selectedSyntaxDetails._id ? selectedSyntaxDetails._id : '',
              syntax: getInvoiceSyntaxOptionValue(invoiceSyntax),
              letter: selectedSyntaxDetails && selectedSyntaxDetails.letter ? selectedSyntaxDetails.letter : '',
              year: selectedSyntaxDetails && selectedSyntaxDetails.year ? selectedSyntaxDetails.year : '',
              invoiceNo: Object.keys(venueInvoiceDetails).length > 0 ? venueInvoiceDetails.invoiceReceipt : '',
              templateName: invoiceTemplateDetail.templateName ? invoiceTemplateDetail.templateName + (cloneTemplate ? ' copy' : '') : '',
              invoiceTemplateId: invoiceTemplateDetail._id ?? '',
              appearnceFont: getFontOptionValue(invoiceTemplateDetail.font ?? ''),
              appearnceWelcomeText: invoiceTemplateDetail.welcomeText ?? '',
              appearnceClosingText: invoiceTemplateDetail.closingText ?? '',
              detail1: invoiceTemplateDetail.detail1 ?? '',
              detail2: invoiceTemplateDetail.detail2 ?? '',
              detail3: invoiceTemplateDetail.detail3 ?? '',
              detail4: invoiceTemplateDetail.detail4 ?? ''
            }
          }
          onSubmit={(values, {resetForm}) => {
            handleSubmit(values, resetForm);
          }}
          validationSchema={formSchema}
        >
          {({ values,
            setFieldValue,
            touched,
            errors,
            isSubmitting
          }) => (
            <Form>
              <Row className='mt-2'>
                <Col md={2}>
                  <StyleVenueLogo>
                    <Image style={styles.venueLogo} src={venueLogo} />
                  </StyleVenueLogo>
                  {currSettingType === 'general' &&
                    <Row>
                        <Col md='12' sm='12' className="text-center mt-2">
                            <StyleButtonBlock outline size="sm" block disabled={processing} type='submit'>
                                {t('Save Changes')}
                            </StyleButtonBlock>
                        </Col>
                    </Row>
                  }
                  <StyleListGroup>
                    { settingOptions.length > 0 && (
                        settingOptions.map((option, k) => (
                          <ListGroupItem key={k} className={`item ${currSettingType === option.value && "active"}`} onClick={() => { setFieldValue('settingType', option.value); setCurrSettingType(option.value)}}>{option.label}</ListGroupItem>
                        ))
                      )
                    }
                  </StyleListGroup>
                </Col>
                {currSettingType === 'general' &&
                  <>
                    <Col>
                      <StyleTypography variant="h5" component="div" gutterBottom>
                        <span>{t('Einstellungen')}</span>
                      </StyleTypography>
                      <StyleSectionB>
                        <StyleLabel>{t('Regional Settings')}</StyleLabel>
                        <StyleHr></StyleHr>
                        <StyleCardSection>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('DEFAULT LANGUAGE')}</StyleSmall>
                              </StylePTag>
                              <StyleInputGroup>
                                <InputGroupAddon addonType="prepend" className="add-on">
                                  <img
                                    className="addon-img"
                                    alt="United States"
                                    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${langFlagCountryCode}.svg`}/>
                                </InputGroupAddon>
                                <StyleReactSelectCustom
                                  className={`react-select flag-select-box`}
                                  classNamePrefix='select'
                                  name='languageFormat'
                                  value={values.languageFormat}
                                  options={languageOptions}
                                  onChange={value => {
                                    //setDateFormatOptions(value.country_code === 'GB' ? dateFormatLangEn : dateFormatLangDe)
                                    //setTimeout(() => {
                                      setFieldValue('languageFormat', value || '');
                                      setLangFlagCountryCode(value.country_code);
                                      handleLanguageChange(values, value)
                                    //})
                                  }}
                                  placeholder={t('Language')}
                                  styles={{
                                    // Fixes the overlapping problem of the component
                                    menu: provided => ({ ...provided, zIndex: 9999}),
                                    control: (base, state) => ({
                                      ...base,
                                      boxShadow: 'none',
                                      border: errors.languageFormat && touched.languageFormat ? '1px solid #dc3545' : '',
                                    })
                                  }}
                                />
                              </StyleInputGroup>
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center ">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('DATE SYNTAX')}</StyleSmall>
                              </StylePTag>
                              <StyleReactSelectCustom
                                className={`react-select`}
                                classNamePrefix='select'
                                name='dateFormat'
                                value={values.dateFormat}
                                options={dateFormatOptions}
                                onChange={value => { setFieldValue('dateFormat', value || '') }}
                                placeholder={t('Date Format')}
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: provided => ({ ...provided, zIndex: 9999 }),
                                  control: (base, state) => ({
                                    ...base,
                                    boxShadow: 'none',
                                    border: errors.dateFormat && touched.dateFormat ? '1px solid #dc3545' : '',
                                  })
                                }}
                              />
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center ">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('TIME FORMAT')}</StyleSmall>
                              </StylePTag>
                              <StyleReactSelectCustom
                                className={`react-select`}
                                classNamePrefix='select'
                                name='timeFormat'
                                value={values.timeFormat}
                                options={timeFormatOptions}
                                onChange={value => { setFieldValue('timeFormat', value || '') }}
                                placeholder={t('Time Format')}
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: provided => ({ ...provided, zIndex: 9999 }),
                                  control: (base, state) => ({
                                    ...base,
                                    boxShadow: 'none',
                                    border: errors.timeFormat && touched.timeFormat ? '1px solid #dc3545' : '',
                                  })
                                }}
                              />
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center ">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('CURRENCY')}</StyleSmall>
                              </StylePTag>
                              <StyleInputGroup>
                                {/* <InputGroupAddon addonType="prepend" className="add-on">
                                  <img
                                    className="addon-img"
                                    alt="United States"
                                    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${currencyFlagCode}.svg`}/>
                                </InputGroupAddon> */}
                                <StyleReactSelectCustom
                                  className={`react-select`}
                                  classNamePrefix='select'
                                  name='currencyFormat'
                                  value={values.currencyFormat}
                                  options={currencyOptions}
                                  onChange={value => { setFieldValue('currencyFormat', value || ''); setCurrencyFlagCode(flagCodeMappingCurrency[value.value]); }}
                                  placeholder={t('Currency')}
                                  styles={{
                                    // Fixes the overlapping problem of the component
                                    menu: provided => ({ ...provided, zIndex: 9999}),
                                    control: (base, state) => ({
                                      ...base,
                                      boxShadow: 'none',
                                      border: errors.currencyFormat && touched.currencyFormat ? '1px solid #dc3545' : '',
                                    })
                                  }}
                                />
                              </StyleInputGroup>
                            </div>
                          </Card>
                        </StyleCardSection>
                      </StyleSectionB>
                    </Col>
                    <Col>
                      <div className="mt-5">
                        <StyleLabel>{t('Login')}</StyleLabel>
                        <StyleHr></StyleHr>
                        <StyleCardSection>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('GREETING TEXT')}</StyleSmall>
                              </StylePTag>
                                <InputGroup>
                                  <InputGroupButtonDropdown addonType="append" isOpen={dropdownOpen} toggle={toggle}>
                                    <DropdownToggle
                                      className='greeting-text'
                                      caret
                                      style={{
                                        width: "50px",
                                        background: "rgb(240, 239, 240)",
                                        borderRadius: '0px',
                                        borderTopLeftRadius: '4px',
                                        borderBottomLeftRadius: '4px',
                                        padding: 0,
                                      }}
                                    >
                                      <Image style={styles.iconimage} src={
                                        values.lineFormat === 'text-align-center' ? textAlignCenterIcon : (values.lineFormat === 'text-align-left' ? textAlignLeftIcon : textAlignRightIcon)
                                      } />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {lineFormattingOptions.length > 0 && lineFormattingOptions.map((format) => {
                                        return (
                                          <DropdownItem
                                            name="lineFormat"
                                            onClick={() => {
                                              setFieldValue('lineFormat', format.value || '')
                                            }}
                                            key={format.value}
                                            value={format.label}
                                          >
                                            <Image style={styles.iconimage} src={
                                              format.value === 'text-align-center' ? textAlignCenterIcon : (format.value === 'text-align-left' ? textAlignLeftIcon : textAlignRightIcon)
                                            } />
                                          </DropdownItem>
                                        );
                                      })}
                                    </DropdownMenu>
                                  </InputGroupButtonDropdown>
                                  <StyleField
                                    name='welcomeText'
                                    placeholder={t('Greeting Text')}
                                    className={`form-control custom-border-radius ${ errors.welcomeText && touched.welcomeText && 'is-invalid' }`}
                                  >
                                  </StyleField>
                                </InputGroup>
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center ">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('FONT')}</StyleSmall>
                              </StylePTag>
                              <StyleReactSelectCustom
                                className={`react-select`}
                                classNamePrefix='select'
                                name='welcomeTextFont'
                                value={values.welcomeTextFont}
                                options={fontOptions}
                                onChange={value => { setFieldValue('welcomeTextFont', value || '') }}
                                placeholder={t('Select Font')}
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: provided => ({ ...provided, zIndex: 9999}),
                                  control: (base, state) => ({
                                    ...base,
                                    boxShadow: 'none',
                                    border: errors.welcomeTextFont && touched.welcomeTextFont ? '1px solid #dc3545' : '',
                                  })
                                }}
                              />
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('SIZE')}</StyleSmall>
                              </StylePTag>
                              <StyleReactSelectCustom
                                className={`react-select`}
                                classNamePrefix='select'
                                name='loginFontSize'
                                value={values.loginFontSize}
                                options={fontSizeOptions}
                                onChange={value => { setFieldValue('loginFontSize', value || '') }}
                                placeholder={t('Select Font')}
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: provided => ({ ...provided, zIndex: 9999}),
                                  control: (base, state) => ({
                                    ...base,
                                    boxShadow: 'none',
                                    border: errors.loginFontSize && touched.loginFontSize ? '1px solid #dc3545' : '',
                                  })
                                }}
                              />
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('COLOUR')}</StyleSmall>
                              </StylePTag>
                              <StyleColorPicker className="d-flex align-items-center">
                                <Field
                                  name='welcomeTextColor'
                                  placeholder={t('Select Color')}
                                  value={values.welcomeTextColor}
                                  onClick={() => handleToggleColorPicker('welcomeTextColor')}
                                  className={`form-control input-box color-input-box ${ errors.welcomeTextColor && touched.welcomeTextColor && 'is-invalid' }`}
                                  readOnly
                                />
                                <div
                                  className="color-box"
                                  style={{
                                    backgroundColor: values.welcomeTextColor,
                                  }}
                                  onClick={() => handleToggleColorPicker('welcomeTextColor')}
                                ></div>
                                {openColorPicker.welcomeTextPicker && (
                                    <div ref={refColorPicker}>
                                      <ChromePicker
                                        className="color-picker-custom"
                                        color={values.welcomeTextColor}
                                        onChange={(color) => {
                                          setFieldValue('welcomeTextColor', color.hex || '');
                                        }}
                                      />
                                    </div>
                                )}
                              </StyleColorPicker>
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center ">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('BACKGROUND PICTURE')}</StyleSmall>
                              </StylePTag>
                              <StyleButtonUpload type='button'>
                                <p className="p-upload"><StyleImageIcon src={uploadSMIcon}></StyleImageIcon><br/>{t('Upload')}</p>
                                {/* <p className="p-preview">Preview</p> */}
                                <Dropzone
                                  onDrop={handleOnDropBackgroundImg}
                                  accept="image/*"
                                  className="p-preview"
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <>
                                      <div {...getRootProps()}>
                                      <input {...getInputProps()} autoComplete="off" />
                                      <div
                                        {...getRootProps()}
                                        style={{
                                          border: "0px dashed gray",
                                          maxWidth: "100%",
                                          maxHeight: "100%",
                                          alignSelf: "center",
                                          padding: "5px",
                                          minHeight: "100px"
                                        }}
                                      >
                                        {backgroundImgSrc !== null ? (
                                          <img
                                            src={backgroundImgSrc}
                                            style={{ maxWidth: "100%", maxHeight: "100%", alignSelf: "center",padding:"0px"}}
                                            alt="Background"
                                          />
                                        ) : (
                                          <p className="p-preview">{t('Preview')}</p>
                                        )}
                                      </div>
                                      {!validateBackgroundImg && (
                                        <small style={{ color: "red" }}>
                                          * {t('Choose_picture')}
                                        </small>
                                      )}
                                      </div>
                                    </>
                                  )}
                                </Dropzone>
                              </StyleButtonUpload>
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center ">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('VENUE LOGO')}</StyleSmall>
                              </StylePTag>
                              <StyleButtonUpload type='button'>
                                <p className="p-upload"><StyleImageIcon src={uploadSMIcon}></StyleImageIcon><br/>{t('Upload')}</p>
                                {/* <p className="p-preview">Preview</p> */}
                                <Dropzone
                                  onDrop={handleOnDropVenueLogo}
                                  accept="image/*"
                                  className="p-preview"
                                  // maxSize={imageMaxSize}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <>
                                      <div {...getRootProps()}>
                                        <input {...getInputProps()} autoComplete="off" />
                                        <div
                                          style={{
                                            border: "0px dashed gray",
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            alignSelf: "center",
                                            padding: "5px",
                                            minHeight: "100px"
                                          }}
                                        >
                                          {venueLogoSrc !== null ? (
                                            <img
                                              src={venueLogoSrc}
                                              style={{ maxWidth: "100%", maxHeight: "100%", alignSelf: "center",padding:"0px"}} 
                                              alt="Venue Logo"
                                            />
                                          ) : (
                                            <p className="p-preview">{t('Preview')}</p>
                                          )}
                                        </div>
                                        {/* <p> {t('Location Logo')}</p> */}
                                        {!validateVenueLogo && (
                                          <small style={{ color: "red" }}>
                                            * {t('Choose_picture')}
                                          </small>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </Dropzone>
                              </StyleButtonUpload>
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="">
                              <StyleButtonBlock outline size="sm" block onClick={() => setModalENewUserOpen(!modalENewUserOpen)}>{t('Edit Email for New Users')}</StyleButtonBlock>
                              <StyleButtonBlock outline size="sm" block onClick={() => setModalEForgotPasswordOpen(!modalEForgotPasswordOpen)}>{t('Edit email for forgotten password')}</StyleButtonBlock>
                            </div>
                          </Card>
                        </StyleCardSection>
                      </div>
                    </Col>
                    <Col>
                      <div className="mt-5">
                        <StyleLabel>{t('Display front end')}</StyleLabel>
                        <StyleHr></StyleHr>
                        <StyleCardSection>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center ">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('HEADLINE FONT')}</StyleSmall>
                              </StylePTag>
                              <StyleReactSelectCustom
                                className={`react-select`}
                                classNamePrefix='select'
                                name='headlineFont'
                                value={values.headlineFont}
                                options={fontOptions}
                                onChange={value => { setFieldValue('headlineFont', value || '') }}
                                placeholder={t('Select')}
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: provided => ({ ...provided, zIndex: 9999}),
                                  control: (base, state) => ({
                                    ...base,
                                    boxShadow: 'none',
                                    border: errors.headlineFont && touched.headlineFont ? '1px solid #dc3545' : '',
                                  })
                                }}
                              />
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center ">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('HEADLINE SIZE')}</StyleSmall>
                              </StylePTag>
                              <StyleReactSelectCustom
                                className={`react-select`}
                                classNamePrefix='select'
                                name='headlineSize'
                                value={values.headlineSize}
                                options={fontSizeOptions}
                                onChange={value => { setFieldValue('headlineSize', value || '') }}
                                placeholder={t('Select Size')}
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: provided => ({ ...provided, zIndex: 9999}),
                                  control: (base, state) => ({
                                    ...base,
                                    boxShadow: 'none',
                                    border: errors.headlineSize && touched.headlineSize ? '1px solid #dc3545' : '',
                                  })
                                }}
                              />
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2">
                            <div className="d-flex align-items-center">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('HEADLINE COLOUR')}</StyleSmall>
                              </StylePTag>
                              <StyleColorPicker className="d-flex align-items-center">
                                <Field
                                  name='headlineColor'
                                  placeholder={t('Select Color')}
                                  value={values.headlineColor}
                                  onClick={() => handleToggleColorPicker('headlineColor')}
                                  className={`form-control input-box color-input-box ${ errors.headlineColor && touched.headlineColor && 'is-invalid' }`}
                                  readOnly
                                />
                                <div
                                  className="color-box"
                                  style={{
                                    backgroundColor: values.headlineColor,
                                  }}
                                  onClick={() => handleToggleColorPicker('headlineColor')}
                                ></div>
                                {openColorPicker.headlineColorPicker && (
                                    <div ref={refColorPicker}>
                                      <ChromePicker
                                        className="color-picker-custom"
                                        color={values.headlineColor}
                                        onChange={(color) => {
                                          setFieldValue('headlineColor', color.hex || '');
                                        }}
                                      />
                                    </div>
                                )}
                              </StyleColorPicker>
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('TEXT FONT')}</StyleSmall>
                              </StylePTag>
                              <StyleReactSelectCustom
                                className={`react-select`}
                                classNamePrefix='select'
                                name='presTextFont'
                                options={fontOptions}
                                value={values.presTextFont}
                                onChange={value => { setFieldValue('presTextFont', value || '') }}
                                placeholder={t('Select')}
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: provided => ({ ...provided, zIndex: 9999}),
                                  control: (base, state) => ({
                                    ...base,
                                    boxShadow: 'none',
                                    border: errors.presTextFont && touched.presTextFont ? '1px solid #dc3545' : '',
                                  })
                                }}
                              />
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('TEXT SIZE')}</StyleSmall>
                              </StylePTag>
                              <StyleReactSelectCustom
                                className={`react-select`}
                                classNamePrefix='select'
                                name='presTextSize'
                                value={values.presTextSize}
                                options={fontSizeOptions}
                                onChange={value => { setFieldValue('presTextSize', value || '') }}
                                placeholder={t('Select')}
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: provided => ({ ...provided, zIndex: 9999}),
                                  control: (base, state) => ({
                                    ...base,
                                    boxShadow: 'none',
                                    border: errors.presTextSize && touched.presTextSize ? '1px solid #dc3545' : '',
                                  })
                                }}
                              />
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('TEXT COLOUR')}</StyleSmall>
                              </StylePTag>
                              <StyleColorPicker className="d-flex align-items-center">
                                <Field
                                  name='presTextColor'
                                  placeholder={t('#fff')}
                                  value={values.presTextColor}
                                  onClick={() => handleToggleColorPicker('presTextColor')}
                                  className={`form-control input-box color-input-box ${ errors.presTextColor && touched.presTextColor && 'is-invalid' }`}
                                  readOnly
                                />
                                <div
                                  className="color-box"
                                  style={{
                                    backgroundColor: values.presTextColor,
                                  }}
                                  onClick={() => handleToggleColorPicker('presTextColor')}
                                ></div>
                                {openColorPicker.textColorPicker && (
                                  <div ref={refColorPicker}>
                                    <ChromePicker
                                      className="color-picker-custom"
                                      color={values.presTextColor}
                                      onChange={(color) => {
                                        setFieldValue('presTextColor', color.hex || '');
                                      }}
                                    />
                                  </div>
                                )}
                              </StyleColorPicker>
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('ACCENT COLOUR')}</StyleSmall>
                              </StylePTag>
                              <StyleColorPicker className="d-flex align-items-center">
                                <Field
                                  name='presAccentColor'
                                  placeholder={t('#fff')}
                                  value={values.presAccentColor}
                                  onClick={() => handleToggleColorPicker('presAccentColor')}
                                  className={`form-control input-box color-input-box ${ errors.presAccentColor && touched.presAccentColor && 'is-invalid' }`}
                                  readOnly
                                />
                                <div
                                  className="color-box"
                                  style={{
                                    backgroundColor: values.presAccentColor,
                                  }}
                                  onClick={() => handleToggleColorPicker('presAccentColor')}
                                ></div>
                                {openColorPicker.accentColorPicker && (
                                  <div ref={refColorPicker}>
                                    <ChromePicker
                                      className="color-picker-custom"
                                      color={values.presAccentColor}
                                      onChange={(color) => {
                                        setFieldValue('presAccentColor', color.hex || '');
                                      }}
                                    />
                                  </div>
                                )}
                              </StyleColorPicker>
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center ">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('BACKGROUND COLOUR')}</StyleSmall>
                              </StylePTag>
                              <StyleColorPicker className="d-flex align-items-center ">
                                <Field
                                  name='presBackgroundColor'
                                  placeholder={t('#fff')}
                                  value={values.presBackgroundColor}
                                  onClick={() => handleToggleColorPicker('presBackgroundColor')}
                                  className={`form-control input-box color-input-box ${ errors.presBackgroundColor && touched.presBackgroundColor && 'is-invalid' }`}
                                  readOnly
                                />
                                <div
                                  className="color-box"
                                  style={{
                                    backgroundColor: values.presBackgroundColor,
                                  }}
                                  onClick={() => handleToggleColorPicker('presBackgroundColor')}
                                ></div>
                                {openColorPicker.backgroundColorPicker && (
                                  <div ref={refColorPicker}>
                                    <ChromePicker
                                      className="color-picker-custom"
                                      color={values.presBackgroundColor}
                                      onChange={(color) => {
                                        setFieldValue('presBackgroundColor', color.hex || '');
                                      }}
                                    />
                                  </div>
                                )}
                              </StyleColorPicker>
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center ">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('CORNER SHAPE')}</StyleSmall>
                              </StylePTag>
                              <StyleReactSelectCustom
                                className={`react-select`}
                                classNamePrefix='select'
                                name='presColorShape'
                                value={values.presColorShape}
                                options={shpaeOptions}
                                onChange={value => { setFieldValue('presColorShape', value || '') }}
                                placeholder={t('Select')}
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: provided => ({ ...provided, zIndex: 9999}),
                                  control: (base, state) => ({
                                    ...base,
                                    boxShadow: 'none',
                                    border: errors.presColorShape && touched.presColorShape ? '1px solid #dc3545' : '',
                                  })
                                }}
                              />
                            </div>
                          </Card>
                        </StyleCardSection>
                      </div>
                    </Col>
                  </>
                }
                {currSettingType === 'locations' &&
                  <>
                    <Col md={3}>
                      <StyleTypography variant="h5" component="div" gutterBottom>
                        <span>{t('Einstellungen')}</span>
                      </StyleTypography>
                      <StyleSectionB>
                        <StyleLabel>{t('Locations')}</StyleLabel>
                        <StyleHr></StyleHr>
                        <StyleCardSection>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center ">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('NEW LOCATION')}</StyleSmall>
                              </StylePTag>
                              <InputGroup>
                                <StyleField
                                  name='locationName'
                                  id='locationName'
                                  placeholder={t('Location Name')}
                                  value={values.locationName}
                                  className={`form-control input-box ${ errors.locationName && touched.locationName && 'is-invalid' }`}
                                >
                                </StyleField>
                                <InputGroupAddon addonType="append">
                                  <StyleInputGroupText>
                                    <Button type='submit' outline size="sm">
                                      <Image style={styles.iconimage} src={checkMarkSaveIcon} />
                                    </Button>
                                  </StyleInputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </div>
                          </Card>
                        </StyleCardSection>
                      </StyleSectionB>
                      <StyleSectionB>
                        <StyleCardSection>
                          <Card style={styles.cardui} className="pl-2" >
                            <StyleAsyncDropdown className="d-flex align-items-center">
                              <AsyncSelect
                                className='react-select mb-2'
                                classNamePrefix='select'
                                placeholder={t('Suchen')}
                                noOptionsMessage={()=> <span>No Record</span>}
                                cacheOptions
                                // isClearable
                                closeOnSelect={false}
                                closeMenuOnSelect={false}
                                // onSelectResetsInput={false}
                                // removeSelected={false}
                                // hideSelectedOptions={false}
                                value={''}
                                autoBlur={false}
                                loadOptions={searchLocations}
                                onChange={ value => {
                                    if( value && ! values.locations.find( item => item.value === value.value ) ) {
                                        setFieldValue('locations', [ value, ...values.locations ]);
                                    }
                                }}
                            />
                            </StyleAsyncDropdown>
                          </Card>
                        </StyleCardSection>
                      </StyleSectionB>
                    </Col>
                    <Col style={styles.sectionRight}>
                      <Row>
                        <Col>
                          <div className="mt-5">
                            <StyleLabel>{t('Details')}</StyleLabel>
                            <StyleHr></StyleHr>
                            <StyleCardSection>
                              <Card style={styles.cardui} className="pl-2">
                                <div className="">
                                  <Button outline size="sm" block>{t('Edit iCal-Link')}</Button>
                                </div>
                                <StyleBtnDropDown className="mt-2">
                                  <Dropdown direction="right" isOpen={subscribedLinks} toggle={() => { setSubscribedLinks(!subscribedLinks) }}>
                                    <DropdownToggle caret>
                                      Suscribed Links
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem>Belegungsplan Hofgut</DropdownItem>
                                      <DropdownItem>Andrea Outlook Planung</DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </StyleBtnDropDown>
                                <StyleBtnDropDown className="mt-2">
                                  <Dropdown direction="right" isOpen={sharedLinks} toggle={() => { setSharedLinks(!sharedLinks) }}>
                                    <DropdownToggle caret>
                                      Shared Links
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem>Belegungsplan Hofgut</DropdownItem>
                                      <DropdownItem>Andrea Outlook Planung</DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </StyleBtnDropDown>
                              </Card>
                            </StyleCardSection>
                          </div>
                          <div className="mt-2">
                            <StyleLabel>{t('Address')}</StyleLabel>
                            <StyleHr></StyleHr>
                            <StyleCardSection>
                              <Card style={styles.cardui} className="pl-2" >
                                <div className="d-flex align-items-center ">
                                  <StylePTag className="mb-0">
                                    <StyleSmall>{t('STREET + NO')}</StyleSmall>
                                  </StylePTag>
                                  <StyleField
                                    name='name'
                                    id='name'
                                    placeholder={t('STREET + NO')}
                                    value={''}
                                    className={`form-control input-box ${ errors.name && touched.name && 'is-invalid' }`}
                                  >
                                  </StyleField>
                                </div>
                              </Card>
                              <Card style={styles.cardui} className="pl-2" >
                                <div className="d-flex align-items-center ">
                                  <StylePTag className="mb-0">
                                    <StyleSmall>{t('ZIP CODE')}</StyleSmall>
                                  </StylePTag>
                                  <StyleField
                                    name='name'
                                    id='name'
                                    placeholder={t('ZIP CODE')}
                                    value={''}
                                    className={`form-control input-box ${ errors.name && touched.name && 'is-invalid' }`}
                                  >
                                  </StyleField>
                                </div>
                              </Card>
                              <Card style={styles.cardui} className="pl-2" >
                                <div className="d-flex align-items-center ">
                                  <StylePTag className="mb-0">
                                    <StyleSmall>{t('CITY')}</StyleSmall>
                                  </StylePTag>
                                  <StyleField
                                    name='name'
                                    id='name'
                                    placeholder={t('CITY')}
                                    value={''}
                                    className={`form-control input-box ${ errors.name && touched.name && 'is-invalid' }`}
                                  >
                                  </StyleField>
                                </div>
                              </Card>
                              <Card style={styles.cardui} className="pl-2" >
                                <div className="d-flex align-items-center ">
                                  <StylePTag className="mb-0">
                                    <StyleSmall>{t('COUNTRY')}</StyleSmall>
                                  </StylePTag>
                                  <ReactFlagsSelect
                                    className={`react-select flag-select`}
                                    classNamePrefix='select'
                                    name='languageFormat1'
                                    selected={selectedLanguage}
                                    onChange={value => { setFieldValue('languageFormat1', value || '') }}
                                    // options={languageOptions}
                                    // countries={languageOptions.map((lang) => lang.country_code)}
                                    // customLabels={{"US": "EN-US","GB": "EN-GB","FR": "FR","DE": "DE","IT": "IT"}}
                                    placeholder={t('Select Country')}
                                    onSelect={code => setSelectedLanguage(code)}
                                  />
                                </div>
                              </Card>
                            </StyleCardSection>
                          </div>
                          <div className="mt-2">
                            <StyleLabel>{t('Rooms')}</StyleLabel>
                            <StyleHr></StyleHr>
                            <StyleCardSection>
                              <Card style={styles.cardui} className="pl-2" >
                                <div className="d-flex align-items-center ">
                                  <StylePTag className="mb-0">
                                    <StyleSmall>{t('NEW ROOM')}</StyleSmall>
                                  </StylePTag>
                                  <StyleField
                                    name='name'
                                    id='name'
                                    placeholder={t('Name')}
                                    value={''}
                                    className={`form-control input-box ${ errors.name && touched.name && 'is-invalid' }`}
                                  >
                                  </StyleField>
                                </div>
                              </Card>
                            </StyleCardSection>
                            <StyleCardSection>
                              <Card style={styles.cardui} className="pl-2" >
                                <div className="d-flex align-items-center ">
                                  <Field
                                    name='name'
                                    id='name'
                                    placeholder={t('Suchen')}
                                    value={''}
                                    className={`form-control input-box ${ errors.name && touched.name && 'is-invalid' }`}
                                  />
                                </div>
                              </Card>
                            </StyleCardSection>
                          </div>
                        </Col>
                        <Col>
                          <div className="mt-5">
                            <StyleLabel>{t('Site plan')}</StyleLabel>
                            <StyleHr></StyleHr>
                            <StyleCardSection>
                              <Card style={styles.cardui} className="pl-2" >
                                <StyleFormCheck className="d-flex align-items-center ">
                                  <StylePTag className="mb-0">
                                    <StyleSmall>{t('HIDE IN BUILDER')}</StyleSmall>
                                  </StylePTag>
                                  <FormCheck 
                                    type="switch"
                                    id="custom-switch"
                                    label=""
                                  />
                                </StyleFormCheck>
                              </Card>
                              <Card style={styles.cardui} className="pl-2" >
                                <div className="">
                                  <Button outline size="sm" block>{t('Edit site plan')}</Button>
                                </div>
                              </Card>
                            </StyleCardSection>
                          </div>
                          <div className="mt-2">
                            <StyleLabel>{t('Table plan')}</StyleLabel>
                            <StyleHr></StyleHr>
                            <StyleCardSection>
                              <Card style={styles.cardui} className="pl-2" >
                                <StyleFormCheck className="d-flex align-items-center ">
                                  <StylePTag className="mb-0">
                                    <StyleSmall>{t('HIDE IN BUILDER')}</StyleSmall>
                                  </StylePTag>
                                  <FormCheck 
                                    type="switch"
                                    id="custom-switch"
                                    label=""
                                  />
                                </StyleFormCheck>
                              </Card>
                              <Card style={styles.cardui} className="pl-2" >
                                <div className="">
                                  <Button outline size="sm" block>{t('Draw room')}</Button>
                                  <Button outline size="sm" block>{t('Edit items')}</Button>
                                  <Button outline size="sm" block>{t('Edit Templates')}</Button>
                                </div>
                              </Card>
                            </StyleCardSection>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </>
                }
                {currSettingType === 'invoices' &&
                  <>
                    <Col md={4}>
                      <StyleTypography variant="h5" component="div" gutterBottom>
                        <span>{t('Einstellungen')}</span>
                      </StyleTypography>
                      <StyleSectionB>
                        <StyleLabel>{t('General')}</StyleLabel>
                        <StyleHr></StyleHr>
                        <StyleCardSection>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('SYNTAX')}</StyleSmall>
                              </StylePTag>
                              <Field
                                  type='hidden'
                                  name='_id'
                                  value={values._id}
                                  className={`form-control`}
                              />
                              <StyleReactSelectCustom
                                className={`react-select`}
                                classNamePrefix='select'
                                name='syntax'
                                id='syntax'
                                options={syntaxFormatOptions}
                                value={values.syntax}
                                onChange={value => {
                                  handleSyntaxChange(value.value, venueInvoiceGeneralDetails);
                                  setFieldValue('syntax', value || '');
                                }}
                                placeholder={t('Select')}
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: provided => ({ ...provided, zIndex: 9999}),
                                  control: (base, state) => ({
                                    ...base,
                                    boxShadow: 'none',
                                    border: errors.syntax && touched.syntax ? '1px solid #dc3545' : '',
                                  })
                                }}
                                isDisabled={!allowGeneralUpdate}
                              />
                            </div>
                          </Card>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('START NO')}</StyleSmall>
                              </StylePTag>
                              <StyleInvoiceDiv className="d-flex align-items-center">
                                { (invoiceSyntax === '[LETTER]-[YEAR]-[NUMBER]' || invoiceSyntax === '[LETTER]-[NUMBER]') &&
                                  <Field
                                    name='letter'
                                    placeholder={t('Letter')}
                                    value={values.letter}
                                    className={`form-control input-box ${ errors.letter && touched.letter && 'is-invalid' }`}
                                    disabled={!allowGeneralUpdate}
                                  /> 
                                }
                                { (invoiceSyntax === '[LETTER]-[YEAR]-[NUMBER]' || invoiceSyntax === '[YEAR]-[NUMBER]') &&
                                  <Field
                                    type='number'
                                    name='year'
                                    placeholder={t('YEAR')}
                                    value={values.year}
                                    className={`form-control input-box ${ errors.year && touched.year && 'is-invalid' }`}
                                    disabled={!allowGeneralUpdate}
                                  />
                                }
                                <Field
                                  type='number'
                                  name='invoiceNo'
                                  placeholder={t('00001')}
                                  value={values.invoiceNo}
                                  className={`form-control input-box ${ errors.invoiceNo && touched.invoiceNo && 'is-invalid' }`}
                                  // readOnly={Object.keys(venueInvoiceDetails).length > 0 ? true : false}
                                  disabled={!allowGeneralUpdate}
                                />
                              </StyleInvoiceDiv>
                            </div>
                            <div>
                              <Row className="mt-2">
                                <Col md='4'><StyleButtonBlock type="button" outline size="sm" block onClick={() => handleSettingPermission()}>{t('Change')}</StyleButtonBlock></Col>
                                <StyleSetInvoiceNoButton md='8'><StyleButtonBlock type="submit" outline size="sm" block onClick={() => setInvoiceFormReqType('general')} disabled={!allowGeneralUpdate}>{t('Set Invoice Number')}</StyleButtonBlock></StyleSetInvoiceNoButton>
                              </Row>
                              <Row>
                                <Col md='12' sm='12' className="text-right mt-2">
                                  <StyleButtonBlock outline size="sm" block onClick={() => setInvoiceFormReqType('Edit Email for invoices')}>{t('Edit email for invoices')}</StyleButtonBlock>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </StyleCardSection>
                      </StyleSectionB>
                      <StyleSectionB>
                        <StyleLabel>{t('Invoices')}</StyleLabel>
                        <StyleHr></StyleHr>
                        <StyleCardSection>
                          <Card style={styles.cardui} className="pl-2" >
                            <div className="d-flex align-items-center ">
                              <StylePTag className="mb-0">
                                <StyleSmall>{t('NEW TEMPLATE')}</StyleSmall>
                              </StylePTag>
                              <InputGroup>
                                <StyleField
                                  name='templateName'
                                  placeholder={t('Name')}
                                  value={values.templateName}
                                  className={`form-control input-box ${ errors.templateName && touched.templateName && 'is-invalid' }`}
                                >
                                </StyleField>
                                <Field
                                    type='hidden'
                                    name='invoiceTemplateId'
                                    value={values.invoiceTemplateId}
                                />
                                <InputGroupAddon addonType="append">
                                  <StyleInputGroupText>
                                    <Button type='submit' outline size="sm" onClick={() => setInvoiceFormReqType('template')}>
                                      <Image style={styles.iconimage} src={checkMarkSaveIcon} />
                                    </Button>
                                  </StyleInputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </div>
                          </Card>
                        </StyleCardSection>
                      </StyleSectionB>
                      <StyleSectionB>
                        <StyleCardSection>
                          <Card style={styles.cardui} className="pl-2">
                            <StyleSearchField>
                              <Field
                                name='searchTemplate'
                                placeholder={t('Search')}
                                value={values.searchTemplate}
                                className={`form-control input-box`}
                                onChange={(e) => searchTemplates(e)}
                              />
                            </StyleSearchField>
                            <ListGroup flush>
                              { templateData.length > 0 ? (
                                templateData.map((template, k) => (
                                  <StyleListDropdown key={k}><ListGroupItem action key={k}><span className="pointer" onClick={() => {setInvoiceTemplateDetail(template); setCloneTemplate(false); setInvoiceLogoSrc(template.logo ?? '')}}>{template.templateName}</span><span className="icons pointer"><StyleImageIcon src={duplicateIcon} onClick={() => {setInvoiceTemplateDetail(template); setCloneTemplate(true); setInvoiceLogoSrc(template.logo ?? '')}}></StyleImageIcon><StyleImageIcon src={trashIcon} onClick={() => {handleTemplateDelete(template); setCloneTemplate(false)}}></StyleImageIcon></span></ListGroupItem></StyleListDropdown>
                                ))
                              ) : <ListGroupItem className="text-centre">{t('No Template created yet')}</ListGroupItem>
                              }
                            </ListGroup>
                          </Card>
                        </StyleCardSection>
                      </StyleSectionB>
                    </Col>
                    <Col style={styles.sectionRight}>
                      <div className="mt-5">
                        <StyleLabel>{t('Appearance')}</StyleLabel>
                        <StyleHr></StyleHr>
                        <Row>
                          <Col md={12}>
                            <StyleCardSection>
                              <Row>
                                <Col md={8}>
                                  <Card style={styles.cardui}>
                                    <div className="d-flex align-items-center">
                                      <StylePTag className="mb-0">
                                        <StyleSmall>{t('FONT')}</StyleSmall>
                                      </StylePTag>
                                      <StyleReactSelectCustom
                                        className={`react-select`}
                                        classNamePrefix='select'
                                        name='appearnceFont'
                                        options={fontOptions}
                                        value={values.appearnceFont}
                                        onChange={value => { setFieldValue('appearnceFont', value || '') }}
                                        placeholder={t('Select')}
                                        styles={{
                                          // Fixes the overlapping problem of the component
                                          menu: provided => ({ ...provided, zIndex: 9999}),
                                          control: (base, state) => ({
                                            ...base,
                                            boxShadow: 'none',
                                            border: errors.appearnceFont && touched.appearnceFont ? '1px solid #dc3545' : '',
                                          })
                                        }}
                                        isDisabled={cloneTemplate}
                                      />
                                    </div>
                                  </Card>
                                </Col>
                                <Col md={8}>
                                  <Card style={styles.cardui}>
                                    <div className="d-flex align-items-center">
                                      <StylePTag className="mb-0">
                                        <StyleSmall>{t('INVOICE LOGO')}</StyleSmall>
                                      </StylePTag>
                                      <StyleButtonInvoiceUpload type='button' className={`${cloneTemplate ? 'disable-dropzone' : ''}`}>
                                        <p className="p-upload"><StyleImageIcon src={uploadSMIcon}></StyleImageIcon><br/>{t('Upload')}</p>
                                        <Dropzone
                                          onDrop={handleOnDropInvoiceLogo}
                                          accept="image/*"
                                          className={`p-preview`}
                                          // maxSize={imageMaxSize}
                                        >
                                          {({ getRootProps, getInputProps }) => (
                                            <>
                                              <div {...getRootProps()}>
                                                <input {...getInputProps()} autoComplete="off" />
                                                <div
                                                  style={{
                                                    border: "0px dashed gray",
                                                    maxWidth: "100%",
                                                    maxHeight: "100%",
                                                    alignSelf: "center",
                                                    padding: "5px",
                                                    minHeight: "100px"
                                                  }}
                                                >
                                                  {invoiceLogoSrc !== null ? (
                                                    <img
                                                      src={invoiceLogoSrc}
                                                      style={{ maxWidth: "100%", maxHeight: "100%", alignSelf: "center",padding:"0px" }} 
                                                      alt=""
                                                    />
                                                  ) : (
                                                    <p className="p-preview">{t('Preview')}</p>
                                                  )}
                                                </div>
                                                {/* <p> {t('Location Logo')}</p> */}
                                                {!validateVenueLogo && (
                                                  <small style={{ color: "red" }}>
                                                    * {t('Choose_picture')}
                                                  </small>
                                                )}
                                              </div>
                                            </>
                                          )}
                                        </Dropzone>
                                      </StyleButtonInvoiceUpload>
                                    </div>
                                  </Card>
                                </Col>
                              </Row>
                            </StyleCardSection>
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-2">
                        <StyleLabel>{t('Welcome text')}</StyleLabel>
                        <StyleHr></StyleHr>
                        <StyleCardSectionRight>
                          <div >
                            <div className="d-flex align-items-center ">
                              <Field
                                  as='textarea'
                                  rows="4"
                                  name='appearnceWelcomeText'
                                  value={values.appearnceWelcomeText}
                                  placeholder={t('Please add here your welcomes text')}
                                  className={`form-control input-box ${ errors.appearnceWelcomeText && touched.appearnceWelcomeText && 'is-invalid' }`}
                                  disabled={cloneTemplate}
                              />
                            </div>
                          </div>
                        </StyleCardSectionRight>
                      </div>
                      <div className="mt-2">
                        <StyleLabel>{t('Closing text')}</StyleLabel>
                        <StyleHr></StyleHr>
                        <StyleCardSectionRight>
                          <div >
                            <div className="d-flex align-items-center ">
                              <Field
                                as='textarea'
                                rows="4"
                                name='appearnceClosingText'
                                placeholder={t('Please add here your welcomes text...')}
                                value={values.appearnceClosingText}
                                className={`form-control input-box ${ errors.appearnceClosingText && touched.appearnceClosingText && 'is-invalid' }`}
                                disabled={cloneTemplate}
                              />
                            </div>
                          </div>
                        </StyleCardSectionRight>
                      </div>
                      <div className="mt-2">
                        <StyleLabel>{t('Footer')}</StyleLabel>
                        <StyleHr></StyleHr>
                        <StyleCardSectionRight>
                          <div>
                            <div className="d-flex align-items-center ">
                              <Field
                                as='textarea'
                                rows="4"
                                name='detail1'
                                placeholder={t('Add contact details here')}
                                value={values.detail1}
                                className={`form-control input-box mr-2 ${ errors.detail1 && touched.detail1 && 'is-invalid' }`}
                                disabled={cloneTemplate}
                              />
                              <Field
                                as='textarea'
                                rows="4"
                                name='detail2'
                                placeholder={t('Add contact details here')}
                                value={values.detail2}
                                className={`form-control input-box mr-2 ${ errors.detail2 && touched.detail2 && 'is-invalid' }`}
                                disabled={cloneTemplate}
                              />
                              <Field
                                as='textarea'
                                rows="4"
                                name='detail3'
                                placeholder={t('Add contact details here')}
                                value={values.detail3}
                                className={`form-control input-box mr-2 ${ errors.detail3 && touched.detail3 && 'is-invalid' }`}
                                disabled={cloneTemplate}
                              />
                              <Field
                                as='textarea'
                                rows="4"
                                name='detail4'
                                placeholder={t('Add contact details here')}
                                value={values.detail4}
                                className={`form-control input-box mr-2 ${ errors.detail4 && touched.detail4 && 'is-invalid' }`}
                                disabled={cloneTemplate}
                              />
                            </div>
                          </div>
                        </StyleCardSectionRight>
                      </div>
                    </Col>
                  </>
                }
                {currSettingType === 'rechnungen' &&
                  <>
                    
                  </>
                }
              </Row>
            </Form>
          )}
        </Formik>
      </ContainerSection>
    </React.Fragment>
  );
}

export default Settings
import React, {Component} from "react";
import {createRef} from "react/cjs/react.production.min";

export default class AutoSpinner extends Component {

    ref = createRef();

    constructor(props) {
        super(props);

        this.state = {
            activeOption: -1,
            showOptions: false,
            options: props.options ?? [],
            below: true
        }
    }

    onKeyDown = (e) => {

        const {activeOption, options} = this.state;
        const {onSelect} = this.props;

        const state = {};

        if (e.keyCode === 13) {
            onSelect(options[activeOption]);
            state.showOptions = false;
            state.activeOption = -1;
            state.options = this.props.options;
            e.preventDefault()
        } else if (e.keyCode === 38) {
            state.activeOption = (activeOption - 1 + options.length) % options.length;
            e.preventDefault()
        } else if (e.keyCode === 40) {
            state.activeOption = (activeOption + 1) % options.length;
            e.preventDefault()
        }

        this.setState(state)
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = e => {
        if (!this.ref?.current?.contains?.(e.target)) {
            this.setState({showOptions: false})
        }
    };

    onChange = (value) => {
        const {onChange} = this.props;
        const options = this.props.options?.filter(option => option.name.toLowerCase().includes(value.toLowerCase())) ?? [];

        onChange(value);

        this.setState({
            showOptions: true,
            activeOption: -1,
            options,
        });
    };

    setValue = (option) => {
        this.props.onSelect(option);

        this.setState({
            showOptions: false,
            activeOption: -1,
            options: this.props.options,
        });
    };

    render() {

        const {disabled, style, value, placeholder} = this.props;
        const {activeOption, showOptions, options, below} = this.state;

        return (
            <div style={styles.container} ref={this.ref}>
                <input
                    placeholder={placeholder ?? ""}
                    maxLength={30}
                    style={style}
                    value={value ?? ""}
                    onChange={(e) => this.onChange(e?.target?.value)}
                    disabled={disabled}
                    onKeyDown={this.onKeyDown}
                    onClick={this.onClick}
                    autoComplete="off"
                />

                {showOptions && options?.length > 0 &&
                <div style={{...styles.wrapper, [below ? "top" : "bottom"]: "36px"}}>
                    {options?.map((option, index) => (
                        <div
                            key={index}
                            style={{...styles.option, background: index === activeOption ? "#d0d3d4" : "transparent"}}
                            onClick={() => this.setValue(option)}
                        >
                            {option.name}
                        </div>))
                    }
                </div>}
            </div>
        );
    }

    onClick = () => this.setState({
        showOptions: true,
        below: window?.innerHeight - this.ref?.current?.getBoundingClientRect()?.top > 200
    });
}

const styles = {
    container: {
        position: "relative",
        width: "100%",
        display: "flex"
    },
    wrapper: {
        position: "absolute",
        maxHeight: "200px",
        background: "#e7e7e8",
        zIndex: "60",
        overflow: "auto",
        border: "1px solid #939599",
        left: "-5px",
        right: "-5px",
        fontSize: "12px"
    },
    option: {
        padding: "5px 10px",
        cursor: "pointer",
        color: "#939599",
        backgroundColor: "transparent"
    }
};

import React, { Component } from "react";
import { PriceType, PricingFunction } from "../../../../Constants";

import {StateContext} from "../../../../context/state";
import moment from "moment-timezone"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withTranslation} from 'react-i18next';
import Style from 'style-it';
import i18next from 'i18next';

 class ImageMultipleOption extends Component {
  static contextType = StateContext;
  pricingFunction;

  constructor(props) {
   
    super(props);
    this.state={
      error:"",
      quantity: "",
      quantity2: "",
      textDisplay:true,
      displayError: false,
      displayErrorWhileRemoving: false,
    }
    this.pricingFunction = PricingFunction[props.priceType || PriceType.NONE];
  }
  componentDidMount(){
    if (window.innerWidth > 1094) this.setState({ textDisplay: false });
    window.addEventListener("resize", () => {
      if (window.innerWidth > 1094) this.setState({ textDisplay: false });
      else this.setState({ textDisplay: true });
    });
  }
  onQuantityChange = (e) => {
    let key = Number(e.target.value);
    if (isNaN(key) || e.key === null || e.key === " ") return
    const {
      id,
      price,
      numerically,
      stages,
      maximum,
      minimum,
      onUpdateWithoutAPICall,
      answer
    } = this.props;
    const quantity = e.target.value;
    const { amount, error } = this.pricingFunction(
      Number(price) || 0,
      Number(quantity) || 0,
      Number(stages) || 0,
      Number(maximum) || 0,
      Number(minimum) || 0,
      numerically
    );
    let deadLineDate = new Date(this.context.deadlineForEntries);
   
    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ){
      return false
    }
    else{

    
    this.setState({error:error})
    const item= { id, quantity, amount, error }
    if (answer?.length) {
      const dummy = Object.assign([], answer);
      const findingElement = dummy.find((ans) => {
        return ans.id === id;
      });

      if (findingElement) {
        const dummy2 = dummy.filter((ans) => {
          return ans.id !== id;
        });
        dummy2.push(item);
        onUpdateWithoutAPICall(dummy2);
      } else {
        dummy.push(item);
        onUpdateWithoutAPICall(dummy);
      }
    } else {
      onUpdateWithoutAPICall([{ id, quantity, amount , error}]);
    }
  }
  };
  QuantityZero = (e) => {
    let deadLineDate = new Date(this.context.deadlineForEntries);

    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) {
      return false;
    } else {
      let key = Number(e.target.value);
      if (isNaN(key) || e.key === null || e.key === " ") return;
      const {
        id,
        onUpdateWithoutAPICall,
        answer,
      } = this.props;
      const quantity2 = e.target.value;
      this.setState({ quantity2: quantity2 });
      const { quantity } = answer?answer:0;

      const item = { id, quantity2, quantity };
      if (answer?.length) {
        const dummy = Object.assign([], answer);
        const findingElement = dummy.find((ans) => {
          return ans.id === id;
        });

        if (findingElement) {
          const dummy2 = dummy.filter((ans) => {
            return ans.id !== id;
          });
          dummy2.push(item);
          onUpdateWithoutAPICall(dummy2);
        } else {
          dummy.push(item);
          onUpdateWithoutAPICall(dummy);
        }
      } else {
        onUpdateWithoutAPICall([{ id, quantity, quantity2 }]);
      }
    }
  };

  onSelect = () => {
    const { id, price, priceType, answer, onUpdate, min,
      max, } = this.props;
    // const quantity = priceType==="number-guests"? this.props.guestCount :0;
    const quantity = priceType === "number-guests" ? this.props.selectedGuestCount : 0;
    const quantity2 = answer?.quantity2;
    let amount = undefined;
    // console.log("ans", answer, answer.length);
    let deadLineDate = new Date(this.context.deadlineForEntries);
   
    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ){
      return false
    }
    else{

    
    if (priceType === PriceType.FLAT_RATE) amount = Number(price);

    const item = { id, quantity, amount };
    if (answer?.length) {
      const dummy = Object.assign([], answer);
      const findingElement = dummy.find((ans) => {
        return ans.id === id;
      });

      if (findingElement) {
        const dummy2 = dummy.filter((ans) => {
          return ans.id !== id;
        });

        if(max && dummy2.length>=max)
        {
         
           toast.error(i18next.t(`max_selection`,{maxUnit:max}), {
             position: "top-center",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
           });
          return;
        }
        else{
          onUpdate(dummy2);
        }
        if (dummy2.length < min) {
          toast.error(`${this.props.t('Bitte wählen Sie mindestens')} ${min} ${this.props.t('Möglichkeiten')}.`, {
            position: "top-center",
            // autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // dummy2.push(opt)
          // onUpdate(dummy2);
          this.setState({
            displayErrorWhileRemoving: true,
            displayError: false,
            error: `${this.props.t('Bitte wählen Sie mindestens')} ${min} ${this.props.t('Möglichkeiten')}.`,
          });
        }
        
      } else {
        if(max && dummy.length>=max)
        {
         
          toast.error(i18next.t(`max_selection`,{maxUnit:max}) , {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }
        else
        {
          dummy.push(item);
          onUpdate(dummy);
        }
        
      }
    } else {
      onUpdate([{ id, quantity, amount,quantity2 }]);
    }
  }
  };

  renderPriceTypeComponent = () => {
    const { priceType, numerically ,price,alignment} = this.props;
    const val= this.findingFunction()
    const quantity = val ? val.quantity : "";
    const textColor =this.context.stylingData?.textColor? this.context.stylingData?.textColor : "#939598";
    // let quantity
    // if(val){
    //   if( priceType !== "number-guests"){
    //     quantity=val.quantity
    //   }
    //   else{
    //     if(this.props.selectedGuestCount){
    //       quantity=this.props.selectedGuestCount
    //     }
    //     else{
    //       quantity=""
    //     }
    //   }
    // }
    
    const quantity2 = val ? val.quantity2 : "";
    let placeholder;
    switch (priceType) {
      case PriceType.NUMBER_OF_GUESTS:
        placeholder = "x Gäste";
        break;
      case PriceType.NUMERICALLY:
        placeholder = `${
          numerically?.unit ? numerically?.unit : this.props.t("Geben Sie den Wert ein")
        }`;
        break;
      case PriceType.FLAT_RATE:
      default:
        placeholder = undefined;
        break;
    }

    if (placeholder) {
      return (
        <>
        { price===0 ? 
            <input
           style={{...styles.input,margin: alignment==="vertical"? "7px 0px 0 30px":"14px 0px 0 30px"} }
           value={quantity2}
           // placeholder={placeholder}
           placeholder={this.props.t('bitte_eintragen')}
           onKeyDown={(e) => {
           
             if (e.key === "Enter") this.onEnterHitWhenZero(quantity2)
            
             
           }}
           maxLength={10}
           onChange={this.QuantityZero}
           autoComplete="off"
        
         />:
         priceType !== "number-guests" &&  <Style>
         {`
   #placeHolder::placeholder {
   color: ${textColor};   
  }`}
 <div style={{marginTop:"-38px"}}>
        <input
          style={styles.input}
          // placeholder={placeholder}
          id="placeHolder"
          placeholder={this.props.t('bitte_eintragen')}
          value={quantity}
          maxLength={10}
          onChange={this.onQuantityChange}
          onKeyDown={(e) => {
            let deadLineDate = new Date(this.context.deadlineForEntries);
   
            if (
              deadLineDate.toDateString() !== new Date().toDateString() &&
              moment(this.context.deadlineForEntries).isBefore(new Date()) &&
              this.context.userRole === "CU"
            ){
             
          
              e.preventDefault()
            }
            else{
              if (e.key === "Enter") this.onEnterHit(quantity)
              // if (e.key === "Backspace") return;
              // let key = Number(e.key);
              // if (isNaN(key) || e.key === null || e.key === " ")
              //   e.preventDefault();
            }
           
          }}
          // disabled={this.props.selectedGuestCount ? true :false}
          autoComplete="off"
        />
       </div>
        </Style>
    }
        </>
      );
    } else return null;
  };
  onEnterHitWhenZero = (quantity2) => {
    const {
      id,
      onUpdate,
      answer,
    } = this.props;
    const { quantity } = answer;

    let deadLineDate = new Date(this.context.deadlineForEntries);

    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) {
      return false;
    } else {
      const item = { id, quantity2, quantity };
      if (answer?.length) {
      const dummy = Object.assign([], answer);

      const findingElement = dummy.find((ans) => {
        return ans.id === id;
      });
      if (findingElement) {
        const dummy2 = dummy.filter((ans) => {
          return ans.id !== id;
        });
        dummy2.push(item);
        onUpdate(dummy2);
      } else {
        dummy.push(item);
        onUpdate(dummy);
      }
    }
    else{
      onUpdate([{ id, quantity2, quantity }]);
    }
    
    }
  };
  onEnterHit=(quantity)=>{
    const {
      id,
      price,
      numerically,
      stages,
      maximum,
      minimum,
      onUpdate,
      onUpdateWithoutAPICall,
      answer,
    } = this.props;
    
    const { amount, error } = this.pricingFunction(
      Number(price) || 0,
      Number(quantity) || 0,
      Number(stages) || 0,
      Number(maximum) || 0,
      Number(minimum) || 0,
      numerically
    );
    let deadLineDate = new Date(this.context.deadlineForEntries);
   
    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ){
      return false
    }
    else{

    
    const item = { id, quantity, amount, error };
    if (answer?.length) {
      const dummy = Object.assign([], answer);
      const findingElement = dummy.find((ans) => {
        return ans.id === id;
      });

      if (findingElement) {
        const dummy2 = dummy.filter((ans) => {
          return ans.id !== id;
        });
        dummy2.push(item);
        !error? onUpdate(dummy2) : onUpdateWithoutAPICall(dummy2)
      } else {
        dummy.push(item);
        !error? onUpdate(dummy) : onUpdateWithoutAPICall(dummy)
      }
    } else {
      !error? onUpdate([{ id, quantity, amount , error}]):onUpdateWithoutAPICall([{ id, quantity, amount , error}])
    }
  }
  toast.error(this.state.error, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    }); 
  }
  findingFunction = () => {
    const { id, answer } = this.props;
    if (answer?.length) {
      const dummy = Object.assign([], answer);
      const findingElement = dummy.find((ans) => {
        return ans.id === id;
      });
      if (findingElement) {
        return findingElement
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  render() {
    let { image, alignment, price,priceType ,answer,id,numerically} = this.props;
    
    const textFontSize=this.context.stylingData?.textFontSize

    const lineColor= this.context.stylingData?.lineColor? this.context.stylingData?.lineColor : "rgb(41, 120, 37)";
    styles.selected.borderLeft=`3px solid ${lineColor}`;
    styles.selected.borderBottom=`3px solid ${lineColor}`;
    const selectedStyle = this.findingFunction() ? styles.selected : {};
    const circleStyle = { ...styles.circle, ...selectedStyle };
   
    const dummy = Object.assign([], answer);
    const findingElement = dummy.find((ans) => {
      return ans.id === id;
    });
    const val = this.findingFunction();
    const quantity2 = val ? val.quantity2 : "";
    return (
      <div className={`image-item image-item-${alignment}`} style={{fontSize:`${textFontSize}px`}}>
         {/* {(findingElement && this.state.textDisplay)?
      <span  style={{color:"red", margin: "0px 0px 10px", fontWeight: "600"}}>{this.state.error?this.state.error:""}</span>:
      <></>
    }   */}
    {(findingElement && this.state.textDisplay)?
     <ToastContainer
     position="bottom-center"
     autoClose={5000}
     hideProgressBar={false}
     newestOnTop={false}
     closeOnClick
     rtl={false}
     pauseOnFocusLoss
     draggable
     pauseOnHover
     />
      :
      <></>
    }  
        <div className="image" style={{ backgroundImage: `url(${image})`,backgroundRepeat:"no-repeat" , marginBottom:alignment==="horizontal"?"60px":{}}} />
        {/* <div  classname={"circle"} style={{...circleStyle,marginBottom:`${this.findingFunction()?`40px`:`20px`}`}} onClick={this.onSelect} /> */}
        <div  classname={"circle"} style={{...circleStyle,border: `1px solid ${lineColor}`}} onClick={this.onSelect}/>
        <div style={{...styles.checkBox,  border: `1px solid ${lineColor}`}}/>
        {/* <Style>
            {`
         #checkBox {
     display: inline-block;
     width: 16px;
     height: 16px;
     border-radius: 2px;
     border: 1px solid ${lineColor};
     position:relative;
        }
      [type=checkbox]:checked + #checkBox:before{
     position: absolute;
     top: 3px;
     left: 1px;
     content: "";
     display: block;
     width: 14px;
     height: 5px;
     border-left: 2px solid ${lineColor};
     border-bottom: 2px solid ${lineColor};
     transform: rotate(-45deg);
}
.select:before
{
  position: absolute;
  top: 3px;
  left: 1px;
  content: "";
  display: block;
  width: 14px;
  height: 5px;
  border-left: 2px solid ${lineColor};
  border-bottom: 2px solid ${lineColor};
  transform: rotate(-45deg);
}

`}           
         <div style={{marginTop:"15px", display:"inline-block"}}>
          <label>
         <input type='checkbox' style={{width: "1px",height: "1px", position: "absolute",opacity:"0"}}onClick={this.onSelect} />
         <span id="checkBox" className={this.findingFunction()?"select":""}></span>
         </label>
         </div>      
</Style>*/}
        {/* <div className="radioLabel" style={{color:`${headingColor}`}}>
            {value}</div> */}
    
        {price && <span  className="price-display-image-multiple"
           style={{bottom:alignment==="vertical" ?"-4px":"25px"}}>
          
        {priceType==="flat-rate" ?
         price===0 ? 
         <input
        style={{...styles.input,margin: "14px 0px 0 0px"}}
        value={quantity2}
        // placeholder={placeholder}
        placeholder={this.props.t('bitte_eintragen')}
        onKeyDown={(e) => {
        
          if (e.key === "Enter") this.onEnterHitWhenZero(quantity2)
         
          
        }}
        maxLength={10}
        onChange={this.QuantityZero}
        autoComplete="off"
      />:
        `(${
          numerically?.unit? ` ${numerically.unit}` : 
          `${this.props.t('flat_rate')}`
         } ${this.context.languageFormat==="en"? `${Number(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :  `${Number(price)
          .toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`} ${this.context.currencyFormat})`
      :
      priceType === PriceType.NUMERICALLY?
      price!==0 && `(${this.context.languageFormat==="en"? `${Number(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :  `${Number(price)
        .toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`} ${this.context.currencyFormat}
       ${
        numerically?.unit ? ` ${numerically.unit}` : ""
      })`
      :
      price!==0 && `(${this.context.languageFormat==="en"? `${Number(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :  `${Number(price)
        .toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`} ${this.context.currencyFormat}
      ${
        numerically?.unit ? ` ${numerically.unit}` :  `${this.props.t('Per')} ${this.props.t('Gast')}`
      })`
      }</span>}
        {this.renderPriceTypeComponent()}
      
      </div>
    );
  }
}

export default withTranslation() (ImageMultipleOption);

let styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },
  labelContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px",
  },
  typeContainer: {
    marginLeft: "54px",
  },
  circle: {
    width: "16px",
    height: "16px",
    display: "inline-block",
    textAlign: "center",
    background: "transparent",
    borderRadius: "0%",
    border: "1px solid #297825",
    outline: "none",
    zIndex:"20" ,
    position:"absolute",
  
    bottom:"4px"
  },

  checkBox: {
    // content: "", 
    width: "16px",
    height: "16px",
    display: "inline-block",
    textAlign: "center",
    background: "transparent",
    borderRadius: "0%",
    border: "1px solid #297825",
    outline: "none",
    zIndex:"10" ,
    position:"absolute",
    left:"0px" ,
   
    bottom:"4px"
  },  
  selected: {
    // background: `url(${checkIcon})`,
    // backgroundPosition: "center",
    // backgroundSize: "contain",
    // backgroundRepeat:"no-repeat",
    minWidth: "14px",
    minHeight: "5px",
    content: "", 
    display: "inline-block",
     top: "auto",  
    left: "1px",
    bottom:"10px",
    position:"absolute",
    width: "14px",
    height: "5px",
    borderLeft: `3px solid red`,
    borderBottom: "3px solid red",
    borderTop:"none",
    borderRight:"none",
    transform: "rotate(-45deg)", 
    zIndex:"25",
  },
  label: {
    margin: "0 0 0 20px",
    padding: "0",
    display: "inline-block",
    color: "#939598",
    fontSize: "16px",
    width: "50px",
    flexGrow: 1,
  },
  input: {
    border: 0,
    padding: 0,
    margin: "14px 0px 0 30px",
    background: "transparent",
    borderBottom: "1px solid #707070",
    width: "200px",
  },
};

import React, { useState, useEffect, useContext } from "react"
import { Modal, Row, Col, } from 'react-bootstrap';
import {
    Checkbox,
    FormControlLabel,
    FormGroup as FormGroupMUI} from "@material-ui/core";
import {
    Container,
    Card, FormGroup, Label,
    Button
} from 'reactstrap';
import { components } from "react-select";
import swal from "sweetalert";
import makeAnimated from "react-select/animated";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup'
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { StateContext } from "../../context/state";
import HttpService from "../../services/HttpService";
import ReactSelectCustom from "./ReactSelectCustom";

import styled from "styled-components"

import {
    plusIcon,
} from '../../assets'

import {
    crateWidgetStyle as styles,
    StyleMain,
    StylePlusIcon
} from './styles'
import { t } from "react-i18nify";


const ModalDiv = styled.div`
`
const StyleLabel = styled(Label)`
    color: #8f8f8f;
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    pointer-events: none !important;
`;

const StyleLabelFilter = styled(Label)`
    color: #8f8f8f;
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    padding-left: 15px;
    pointer-events: none !important;
`;

const StyleBtnSave = styled(Button)`
    background-color: #00a394;
    border-color: #00a394;
    padding: 1px 20px;
`

const StyleBtnCancel = styled(Button)`
    background-color: #f0eff0;
    border-color: #00a292;
    color: #00a292;
    margin-right: 10px;
    padding: 1px 20px;
`

const StyleBtnDelete = styled(Button)`
    background-color: #dc3545;
    border-color: #00a292;
    color: #fff;
    margin-right: 10px;
    padding: 1px 20px;
    float: left;
`

const StyleMainCard = styled(Card)`
    padding: 0.50rem;
`

const StyleCard = styled(Card)`
    background-color: rgb(239 239 239);
    border: 2px dotted #c6c6c6;
    height: 55px;
    cursor: pointer;
`

const StyleCard2Row = styled(Card)`
    background-color: rgb(239 239 239);
    border: 2px dotted #c6c6c6;
    height: 120px;
    cursor: pointer;
`

const StyleCardCol = styled(Col)`
    .activeWidgetItem {
        background-color: #fff;
        border: 1px solid #c6c6c6;
    }
`
const StyleLabelCard = styled(Label)`
    color: #8f8f8f;
    font-size: 13px;
    /* font-weight: bold; */
    margin-bottom: 3px;
    text-decoration: underline;
    pointer-events: none !important;
`;
const StyleHr = styled.hr`
    margin-top: 0rem;
    margin-bottom: .5rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
`

const StyleSelectAllSpan = styled.span`
    width: 100%;
`

const StyleFormControlLabel = styled(FormControlLabel)`
    pointer-events: none !important;
    height: 15px;
`

const StyleColWidgetSize = styled(Col)`
    .activeWidgetType {
        border-color: #707070;
    }
    .is-invalid {
        border: 1px solid #dc3545;
    }
`

const allOption = {
    label: "Select all",
    value: "*"
};

const ValueContainer = ({ children, ...props }) => {
    const currentValues = props.getValue();
    let toBeRendered = children;
    if (currentValues.some(val => val.value === allOption.value)) {
        toBeRendered = [[children[0][0]], children[1]];
    }

    return (
        <components.ValueContainer {...props}>
            {toBeRendered}
        </components.ValueContainer>
    );
};

const MultiValue = props => {
    let labelToBeDisplayed = `${props.data.label}, `;
    if (props.data.value === allOption.value) {
        labelToBeDisplayed = t("All is selected");
    }
    return (
        <components.MultiValue {...props}>
            <span>{labelToBeDisplayed}</span>
        </components.MultiValue>
    );
};

const Option = (props) => {
    return (
        <components.Option {...props}>
            <FormGroupMUI>
                <StyleFormControlLabel control={<Checkbox checked={props.isSelected} onChange={() => null} />} label={props.label} />
            </FormGroupMUI>
        </components.Option>
    );
};

const animatedComponents = makeAnimated();

const CreateWidget = (props) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [axiosProcess, setAxiosProcess] = useState(false);
    const [widgetTypeOptions, setWidgetTypeOptions] = useState([]);
    const [filterWidgetTypeOptions, setFilterWidgetTypeOptions] = useState([]);
    const [widgetFilterTypeOptions, setWidgetFilterTypeOptions] = useState([]);
    const [widgetDataTypeOptions, setWidgetDataTypeOptions] = useState([]);
    const [widgetTimeTypeOptions, setWidgetTimeTypeOptions] = useState([]);
    const [widgetTextTypeOptions, setWidgetTextTypeOptions] = useState([]);
    const [widgetTemplates, setWidgetTemplates] = useState([]);
    const [widgetLocations, setWidgetLocations] = useState([]);
    const [widgetQuestionnaires, setWidgetQuestionnaires] = useState([]);
    const [selWidgetType, setSelWidgetType] = useState(null);
    const [selWidgetFilterType, setSelWidgetFilterType] = useState('questionnaire');
    const [activeWidgetSize, setActiveWidgetSize] = useState(4);
    const [activeWidgetItem, setActiveWidgetItem] = useState(1);
    const [widgetObj, setWidgetObj] = useState([]);
    const [currWidgetObj, setCurrWidgetObj] = useState({});
    
    let contextValue = useContext(StateContext);
    
    useEffect(() => {
        const { venueId } = contextValue;
        const getWidgetTypePromise = HttpService.getWidgetType().then(
            (response) => {
                let types = response.types && response.types.length > 0 && response.types.map((val) => {
                    return {
                        value: val.id,
                        label: t(val.type)
                    }
                })
                handleFilterWidgetTypes(types, activeWidgetSize)
                setWidgetTypeOptions(types)
            }
        )

        const getLocationsPromise = HttpService.getLocations(venueId).then((response) => {
            if (response.data) {
                let locations = response.data && response.data.length > 0 && response.data.map((val) => {
                    return {
                        value: val._id,
                        label: t(val.locationName),
                        venueId: val.venueId,
                    }
                })
                if(locations) {
                    setWidgetLocations(locations);
                }
            }
        })

        const getTableTypesPromise = HttpService.getTableTypes(venueId).then((response) => {
            if (response.types) {
                let templates = response.types && response.types.length > 0 && response.types.map((val) => {
                    return {
                        value: val.id,
                        label: t(val.type)
                    }
                })
                if(templates) {
                    setWidgetTemplates(templates);
                }
            }
        })

        const getQuestionnairesPromise = HttpService.getQuestionnaires(venueId).then((response) => {
            if (response.data) {
                let questionnaires = response.data && response.data.length > 0 && response.data.map((val) => {
                    return {
                        value: val._id,
                        label: t(val.name),
                        venueId: val.venueId,
                    }
                })
                if(questionnaires) {
                    setWidgetQuestionnaires(questionnaires);
                }
            }
        })

        setWidgetFilterTypeOptions([
            { value: "questionnaire", label: t("Questionnaire") },
            { value: "location", label: t("Location") },
        ]);

        const getWidgetDataTypePromise = HttpService.getWidgetDataType().then(
            (response) => {
                let widgetTypes = response.types && response.types.length > 0 && response.types.map((data) => {
                    return {
                        value: data.id,
                        label: t(data.type)
                    }
                })
                if(widgetTypes) {
                    setWidgetDataTypeOptions(widgetTypes)
                }
            }
        )

        const getWidgetTimeTypePromise = HttpService.getWidgetTimeType().then(
            (response) => {
                let widgetTimeTypes = response.types && response.types.length > 0 && response.types.map((data) => {
                    return {
                        value: data.id,
                        label: t(data.type)
                    }
                })
                if(widgetTimeTypes) {
                    setWidgetTimeTypeOptions(widgetTimeTypes)
                }
            }
        )

        const getWidgetTextTypePromise = HttpService.getWidgetTextType().then(
            (response) => {
                let widgetTextTypes = response.types && response.types.length > 0 && response.types.map((data) => {
                    return {
                        value: data.id,
                        label: t(data.type)
                    }
                })
                if(widgetTextTypes) {
                    setWidgetTextTypeOptions(widgetTextTypes)
                }
            }
        )

        // setProcessing(true);

        Promise.all([
            getWidgetTypePromise,
            getLocationsPromise,
            getTableTypesPromise,
            getQuestionnairesPromise,
            getWidgetDataTypePromise,
            getWidgetTimeTypePromise,
            getWidgetTextTypePromise
          ]).then(() => {
            setProcessing(false);
          })
    }, [activeWidgetSize, contextValue, t])

    useEffect(() => {
        if (show === false) {
            props.setWidgetDetail({});
            setWidgetObj([]);
            setCurrWidgetObj({});
            setSelWidgetType(null);
            setSelWidgetFilterType('questionnaire');
            return;
        }
    }, [props, show])
   
    
    useEffect(() => {
        const handleWidget = () => {
					if (Object.keys(props.widgetDetail).length > 0) {
						handleFilterWidgetTypes(widgetTypeOptions, props.widgetDetail.size);
						setActiveWidgetSize(props.widgetDetail.size);
						setActiveWidgetItem(props.selectedWidgetItem);
						setWidgetObj(props.widgetDetail.widget);
						fillWidgetDetails(
							props.widgetDetail.widget,
							props.widgetDetail.size,
							props.selectedWidgetItem
						);
						setShow(true);
					}
				};
        handleWidget();
    }, [props.selectedWidgetItem, props.widgetDetail, widgetTypeOptions]);
    
 

    const fillWidgetDetails = (widgetInfo, size, rowItem) => {
        const tempWidget = widgetInfo[rowItem-1] ?? {};
        setCurrWidgetObj(tempWidget);
        setSelWidgetType(tempWidget.widgetType);
        setSelWidgetFilterType(tempWidget.filterType);
    }

    const getWidgetTypeOptionValue = (value) => {
        return value && widgetTypeOptions && widgetTypeOptions.find((item) => item.value === value && item);
    }

    const getWidgetFilterTypeOptionValue = (value) => {
        return value && widgetFilterTypeOptions && widgetFilterTypeOptions.find((item) => item.value === value && item);
    }

    const getDataTypeOptionValue = (value) => {
        return value && widgetDataTypeOptions && widgetDataTypeOptions.find((item) => item.value === value && item);
    }

    const getTimeTypeOptionValue = (value) => {
        return value && widgetTimeTypeOptions && widgetTimeTypeOptions.find((item) => item.value === value && item);
    }

    const getTableTypeOptionValue = (value) => {
        return value && widgetTemplates && widgetTemplates.find((item) => item.value === value && item);
    }

    const getTextTypeOptionValue = (value) => {
        return value && widgetTextTypeOptions && widgetTextTypeOptions.find((item) => item.value === value && item);
    }

    const getLocationOptionValue = (ids) => {
        return ids.length > 0 && ids.map(id => {
            const location = widgetLocations.find(item => item.value === id);
            if (location) {
                return { value: location.value, label: location.label }
            }
            return [];
        })
    }

    const getQuestionnaireOptionValue = (ids) => {
        return ids.length > 0 && ids.map(id => {
            const tmpQuestionare = widgetQuestionnaires.find(item => item.value === id);
            if (tmpQuestionare) {
                return { value: tmpQuestionare.value, label: tmpQuestionare.label }
            }
            return [];
        })
    }

    const handleWidgetTypeChange = (value, setFieldValue) => {
        resetFormFileds(setFieldValue, false);
        if (value) {
            setSelWidgetType(value)
        } else {
            setSelWidgetType(null)
        }
    }

    const handleFilterWidgetTypes = (options, size) => {
        let types = options && options.length > 0 && options.filter((val) => {
            if (size === 2) {
                return val.value !== 3 && val;
            } else {
                return val.value !== 2 && val;
            }
        })
        setFilterWidgetTypeOptions(types);
    }

    // const handleWidgetObj = (selected, type, isMulti = false) => {
    //     if(type === 'locationIds') {
    //         let tempObj = selected.map((val) => {
    //             return val.value;
    //         });
    //         currentWidget[type] = tempObj;
    //     }
    //     if(['widgetDataType', 'widgetTimeType', 'questionnaireId'].includes(type)) {
    //         currentWidget[type] = selected.value;
    //     }
    //     if(['locationIds', 'questionnaireId'].includes(type)) {
    //         if(type === 'locationIds') {
    //             currentWidget['questionnaireId'] = [];
    //         } else {
    //             currentWidget['locationIds'] = [];
    //         }
    //     }
    //     console.log(currentWidget, 'currentWidget');
    //     // setWidgetObj([...widgetObj, currentWidget]);
    // }

    const handleWidgetObj = (selected, valuesObj, type) => {
        let currentWidget = [];
        currentWidget['sizeRow'] = activeWidgetItem;
        let targetIndex = widgetObj.findIndex((obj => obj.sizeRow === activeWidgetItem));
        Object.keys(valuesObj).forEach((val) => {
            if (['locationIds', 'widgetDataType', 'widgetTimeType', 'questionnaireId', 'widgetType', 'filterType', 'widgetTableType', 'widgetTextType'].includes(val)) {
                if (type === val) {
                    if (type === 'locationIds' || type === 'questionnaireId') {
                        if (selected) {
                            let tempObj = selected.filter(element => element.value !== '*').map((val) => {
                                return val.value;
                            });
                            currentWidget[type] = tempObj;
                        } else {
                            currentWidget[type] = [];
                        }
                    } else {
                        currentWidget[type] = selected.value;
                    }
                } else {
                    if (val === 'locationIds' || val === 'questionnaireId') {
                        currentWidget[val] = valuesObj[val] && valuesObj[val].filter(element => element.value !== '*').forEach((v) => {
                            if(v.value !== '*') {
                                return v.value;
                            }
                        });
                        let checkAllSelected = selected && selected.filter(element => element.value === '*');
                        if(checkAllSelected && checkAllSelected.length > 0) {
                            currentWidget['allSelected'] = true;
                        }
                    } else {
                        currentWidget[val] = valuesObj[val] ? valuesObj[val].value : '';
                    }
                }
            }
        })

        if (['locationIds', 'questionnaireId'].includes(type)) {
            if (type === 'locationIds') {
                currentWidget['questionnaireId'] = [];
            } else {
                currentWidget['locationIds'] = [];
            }
        }
        let newObj = [];
        if (targetIndex !== -1) {
            newObj = widgetObj;
            newObj[targetIndex] = Object.assign({}, currentWidget);
            setWidgetObj([...newObj]);
        } else {
            newObj = [...widgetObj, Object.assign({}, currentWidget)];
            setWidgetObj(newObj);
        }
    }

    const handleSize = (size, item, setFieldValue) => {
        // if(activeWidgetItem !== item) {
            resetFormFileds(setFieldValue);
        // }
        fillWidgetDetails(widgetObj, activeWidgetSize, item);
        if (activeWidgetSize && size !== activeWidgetSize) {
            if (widgetObj.length > 0) {
                swal({
                    title: `${t('Bist du sicher')}`,
                    text: `${t('All Settings will reset if you change size for this widget!!')}`,
                    buttons: [`${t('Abbrechen')}`, t('Ja')],
                    // buttons: true,
                    dangerMode: true,
                })
                .then((isConfirmed) => {
                    if (isConfirmed) {
                        setActiveWidgetSize(size)
                        setActiveWidgetItem(1)
                        setFieldValue('size', size)
                        handleFilterWidgetTypes(widgetTypeOptions, size)
                        setWidgetObj([]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            } else {
                //Check if previous widget cols are set
                let validate = checkIfPreviousItemFilled(1);
                if(validate) {
                    setActiveWidgetSize(size)
                    setActiveWidgetItem(1)
                    setFieldValue('size', size)
                    handleFilterWidgetTypes(widgetTypeOptions, size)
                    setWidgetObj([]);
                }
            }
        } else {
            //Check if previous widget cols are set
            let validate = checkIfPreviousItemFilled(item);
            if(validate) {
                setActiveWidgetSize(size)
                setActiveWidgetItem(item)
                setFieldValue('size', size);
            }
        }
    }

    const checkIfPreviousItemFilled = (item) => {
        if((item > 1 && widgetObj.length === 0)) {
            swal({
                title: ``,
                text: `${t('Please fill all previous items first')}`,
                showCancelButton: false,
                buttons: [t("Close"), t('Ja')],
            });
            return false;
        } else {
            if(item === 1) {
                return true;
            }
            let itemCheck = true;
            for (let i = 0; i < item-1; i++) {
                if(!widgetObj[i]) {
                    itemCheck = false;
                    break;
                }
            }
            if(!itemCheck) {
                swal({
                    title: ``,
                    text: `${t('Please fill all previous items first')}`,
                    showCancelButton: false,
                    buttons: [t("Close"), t('Ja')],
                });
            }
            return itemCheck;
        }
        
    }

    const resetFormFileds = (setFieldValue, resetWidgetType = true) => {
        setSelWidgetType(null);
        if(resetWidgetType) {
            setFieldValue('widgetType', null);
        }
        setFieldValue('locationIds', []);
        setFieldValue('questionnaireId', []);
        setFieldValue('widgetDataType', null);
        setFieldValue('widgetTimeType', null);
        setFieldValue('widgetTableType', null);
        setFieldValue('widgetTextType', null);
        setFieldValue('filterType', getWidgetFilterTypeOptionValue('questionnaire'));
        setSelWidgetFilterType('questionnaire');
    }

    const handleSubmit = async (values, resetForm) => {
        let validate = validateWidgetObj(values);
        if (validate) {
            swal({
                    title: ``,
                    text: `${t(validate)}`,
                    buttons: [t("Close"), t('Ja')],
                    type: 'error'
                });
            return;
        }
        const { venueId, loggedInUserId } = contextValue;
        const data = {
            userId: loggedInUserId,
            venueId,
            widget: widgetObj.map((object) => {
                object.allSelected = object.allSelected ?? false;
                return object
            })
            // widget: widgetObj
        };
        Object.keys(values).forEach((field) => {
            if (values[field]) {
                if ('object' === typeof values[field] && !(values[field] instanceof File)) {
                    data[field] = values[field].value;
                } else if ('boolean' === typeof values[field]) {
                    data[field] = values[field] ? 1 : 0;
                } else {
                    data[field] = values[field];
                }
            }
        });
        delete data['locationIds'];
        delete data['filterType'];
        delete data['widgetType'];
        delete data['questionnaireId'];
        setAxiosProcess(true);
        Object.keys(props.widgetDetail).length > 0
            ? await HttpService.updateWidget(props.widgetDetail._id, data).then(
                (response) => {
                    swal({
                        title: ``,
                        text: `${t('Widget Updated Successfully!')}`,
                        buttons: [t("Close"), t('Ja')],
                        type: 'info'
                    });
                    resetForm();
                    props.setCallWidgetCnt(props.callWidgetCnt+1);
                    setShow(false);
                    setAxiosProcess(false);
                }
            )
            : await HttpService.createWidget(data).then(
                (response) => {
                    swal({
                        title: ``,
                        text: `${t('Widget Updated Successfully!')}`,
                        buttons: [t("Close"), t('Ja')],
                        type: 'info'
                    });
                    resetForm();
                    props.setCallWidgetCnt(props.callWidgetCnt+1);
                    setShow(false);
                    setAxiosProcess(false);
                }
            )
    }

    const handleDeleteWidget = (_id, setFieldValue) => {
        swal({
            title: `${t('Bist du sicher')}`,
            text: `${t('Do you want to delete this widget!!')}`,
            buttons: [`${t('Abbrechen')}`, t("YES")],
            dangerMode: true,
        })
            .then((isConfirmed) => {
                if (isConfirmed) {
                    setProcessing(true);
                    HttpService.deleteWidget(_id).then(
                        (response) => {
                            setProcessing(false);
                            setActiveWidgetSize(4);
                            setActiveWidgetItem(1);
                            props.setCallWidgetCnt(props.callWidgetCnt+1);
                            resetFormFileds(setFieldValue);
                            setShow(false);
                        }
                    )
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const validateWidgetObj = (values) => {
        if (widgetObj.length !== activeWidgetSize) {
            return t('Widget is not proper, please select all rows of widget!');
        }
        let isValid = true;
        widgetObj.forEach((obj) => {
            if(obj.widgetType === 1) {
                if (obj.filterType === 'questionnaire') {
                    if (obj.questionnaireId.length === 0 || obj.sizeRow === '' || obj.widgetDataType === '' || obj.widgetTimeType === '' || obj.widgetType === '') {
                        isValid = false;
                    }
                } else {
                    if (obj.locationIds.length === 0 || obj.sizeRow === '' || obj.widgetDataType === '' || obj.widgetTimeType === '' || obj.widgetType === '') {
                        isValid = false;
                    }
                }       
            } else if(obj.widgetType === 2) {
                if (obj.widgetTableType === '' || obj.sizeRow === '' || obj.widgetType === '') {
                    isValid = false;
                }
            } else {
                if (obj.filterType === 'questionnaire') {
                    if (obj.questionnaireId.length === 0 || obj.sizeRow === '' || obj.widgetType === '') {
                        isValid = false;
                    }
                } else {
                    if (obj.locationIds.length === 0 || obj.sizeRow === '' || obj.widgetTextType === '' || obj.widgetType === '') {
                        isValid = false;
                    }
                }
            }
        })
        if (isValid === false) {
            return t('Widget is not proper, please select all rows of widget!');
        }
    }

    const formSchema = Yup.object({
        // name: Yup.string().required('Widget name is required'),
        size: Yup.string().required('Size is required'),
        widgetType: Yup.object().required("Widget type is required"),
    }).required();

    return (
        <React.Fragment>
            { !processing &&
                <StyleMain className="text-center mt-4">
                    <ToastContainer />
                    <StylePlusIcon src={plusIcon} onClick={() => setShow(true)} />
                    <ModalDiv>
                        <Modal
                            style={styles.modal_width}
                            show={show}
                            size={activeWidgetSize === null ? 'md' : 'lg'}
                            onHide={() => setShow(false)}
                            dialogClassName="modal-50w"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                            <Modal.Header style={styles.header}>
                                <Modal.Title style={styles.headerTitle}>
                                    {t('New Widget')}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={styles.modal_ui}>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        name: props.widgetDetail.name ?? '',
                                        widgetType: getWidgetTypeOptionValue(currWidgetObj.widgetType ?? ''),
                                        size: activeWidgetSize,
                                        order: 1,
                                        locationIds: getLocationOptionValue(currWidgetObj.locationIds ?? []),
                                        questionnaireId: getQuestionnaireOptionValue(currWidgetObj.questionnaireId ?? []),
                                        widgetDataType: getDataTypeOptionValue(currWidgetObj.widgetDataType ?? ''),
                                        widgetTimeType: getTimeTypeOptionValue(currWidgetObj.widgetTimeType ?? ''),
                                        widgetTableType: getTableTypeOptionValue(currWidgetObj.widgetTableType ?? ''),
                                        widgetTextType: getTextTypeOptionValue(currWidgetObj.widgetTextType ?? ''),
                                        filterType: getWidgetFilterTypeOptionValue(currWidgetObj.filterType ?? 'questionnaire'),
                                    }}
                                    onSubmit={(values, {resetForm}) => {
                                        handleSubmit(values, resetForm);
                                    }}
                                    validationSchema={formSchema}
                                >
                                    {({ values,
                                        setFieldValue,
                                        isSubmitting,
                                        touched,
                                        errors,
                                        setFieldTouched,
                                        handleBlur,
                                        handleSubmit,
                                    }) => (
                                        <Form>
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <Row>
                                                            {/* <Col md='12' sm='12'> */}
                                                                {/* <FormGroup className='mb-3 form-label-group'> */}
                                                                    {/* <StyleLabel for='name'>{t('Name')}</StyleLabel> */}
                                                                    <Field
                                                                        type='hidden'
                                                                        name='name'
                                                                        value={values.name}
                                                                        placeholder={t('Widget Name')}
                                                                        className={`form-control ${errors.name && touched.name && 'is-invalid'}`}
                                                                    />
                                                                    <Field
                                                                        type='hidden'
                                                                        name='order'
                                                                        value={values.order}
                                                                        className={`form-control`}
                                                                    />
                                                                {/* </FormGroup> */}
                                                            {/* </Col> */}
                                                            <StyleColWidgetSize md='12' sm='12'>
                                                                <FormGroup className={`mb-3 form-label-group`}>
                                                                    <StyleLabel for='size'>{t('Size')}</StyleLabel>
                                                                    <Field
                                                                        type='hidden'
                                                                        name='size'
                                                                        value={values.size}
                                                                        className={`form-control`}
                                                                    />
                                                                    <StyleHr></StyleHr>
                                                                    <StyleMainCard body className={`mt-0 ${activeWidgetSize === 4 ? 'activeWidgetType' : ''} ${errors.size && touched.size && 'is-invalid'}`}>
                                                                        <StyleLabelCard>{t('4 in a row')}</StyleLabelCard>
                                                                        <Row xs={1} md={4} className="g-3 m-0">
                                                                            <StyleCardCol className="p-1" onClick={() => handleSize(4, 1, setFieldValue)}><StyleCard className={`${activeWidgetSize === 4 && activeWidgetItem === 1 ? 'activeWidgetItem' : ''}`}></StyleCard></StyleCardCol>
                                                                            <StyleCardCol className="p-1" onClick={() => handleSize(4, 2, setFieldValue)}><StyleCard className={`${activeWidgetSize === 4 && activeWidgetItem === 2 ? 'activeWidgetItem' : ''}`}></StyleCard></StyleCardCol>
                                                                            <StyleCardCol className="p-1" onClick={() => handleSize(4, 3, setFieldValue)}><StyleCard className={`${activeWidgetSize === 4 && activeWidgetItem === 3 ? 'activeWidgetItem' : ''}`}></StyleCard></StyleCardCol>
                                                                            <StyleCardCol className="p-1" onClick={() => handleSize(4, 4, setFieldValue)}><StyleCard className={`${activeWidgetSize === 4 && activeWidgetItem === 4 ? 'activeWidgetItem' : ''}`}></StyleCard></StyleCardCol>
                                                                        </Row>
                                                                    </StyleMainCard>
                                                                    <StyleMainCard body className={`mt-2 ${activeWidgetSize === 2 ? 'activeWidgetType' : ''} ${errors.size && touched.size && 'is-invalid'}`}>
                                                                        <StyleLabelCard>{t('2 in a row')}</StyleLabelCard>
                                                                        <Row xs={1} md={2} className="g-3 m-0">
                                                                            <StyleCardCol className="p-1" onClick={() => handleSize(2, 1, setFieldValue)}><StyleCard2Row className={`${activeWidgetSize === 2 && activeWidgetItem === 1 ? 'activeWidgetItem' : ''}`}></StyleCard2Row></StyleCardCol>
                                                                            <StyleCardCol className="p-1" onClick={() => handleSize(2, 2, setFieldValue)}><StyleCard2Row className={`${activeWidgetSize === 2 && activeWidgetItem === 2 ? 'activeWidgetItem' : ''}`}></StyleCard2Row></StyleCardCol>
                                                                        </Row>
                                                                    </StyleMainCard>
                                                                </FormGroup>
                                                            </StyleColWidgetSize>
                                                        </Row>
                                                    </Col>
                                                    {activeWidgetSize !== null &&
                                                        <Col>
                                                            <Row>
                                                                <Col md='12' sm='12'>
                                                                    <FormGroup className='mb-3 form-label-group'>
                                                                        <StyleLabel for='widgetType'>{t('Type')}</StyleLabel>
                                                                        <ReactSelectCustom
                                                                            name='widgetType'
                                                                            value={values.widgetType}
                                                                            className={`react-select ${errors.widgetType && touched.widgetType && 'is-invalid'}`}
                                                                            classNamePrefix='select'
                                                                            onChange={value => { setFieldValue('widgetType', value); handleWidgetTypeChange(value ? value.value : '', setFieldValue) }}
                                                                            options={filterWidgetTypeOptions}
                                                                            isSearchable={false}
                                                                            isClearable={false}
                                                                            isDisabled={false}
                                                                            placeholder={t('Select')}
                                                                            styles={{
                                                                                menu: provided => ({ ...provided, zIndex: 9999 }),
                                                                                control: (base, state) => ({
                                                                                    ...base,
                                                                                    boxShadow: 'none',
                                                                                    border: errors.widgetType && touched.widgetType ? '1px solid #dc3545' : '',
                                                                                    // '&:hover': {
                                                                                    //     border: '1px solid red',
                                                                                    // }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                {selWidgetType &&
                                                                    <>
                                                                        <StyleLabelFilter for='widgetName'>{t('Filter')}</StyleLabelFilter>
                                                                        { (selWidgetType === 1 || selWidgetType === 3) &&
                                                                        <>
                                                                        <Col md='12' sm='12'>
                                                                            <StyleHr></StyleHr>
                                                                            <FormGroup className='mb-3 form-label-group'>
                                                                                <StyleLabel for='filterType'>{t('Filter Type')}</StyleLabel>
                                                                                <ReactSelectCustom
                                                                                    name='filterType'
                                                                                    value={values.filterType}
                                                                                    className={`react-select`}
                                                                                    classNamePrefix='select form-control'
                                                                                    options={widgetFilterTypeOptions}
                                                                                    onChange={value => { setFieldValue('filterType', value || ''); setSelWidgetFilterType(value.value); }}
                                                                                    isSearchable={false}
                                                                                    isClearable={false}
                                                                                    isDisabled={false}
                                                                                    placeholder={t('Select')}
                                                                                    styles={{
                                                                                        // Fixes the overlapping problem of the component
                                                                                        menu: provided => ({ ...provided, zIndex: 9999 }),
                                                                                        control: (base, state) => ({
                                                                                            ...base,
                                                                                            boxShadow: 'none',
                                                                                            border: errors.filterType && touched.filterType ? '1px solid #dc3545' : '',
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md='12' sm='12'>
                                                                            <StyleHr></StyleHr>
                                                                            <FormGroup className='mb-3 form-label-group'>
                                                                                { selWidgetFilterType === 'location' ?
                                                                                    <>
                                                                                    <StyleLabel for='widgetName'>{t('Locations')}</StyleLabel>
                                                                                    <StyleSelectAllSpan
                                                                                        className="d-inline-block"
                                                                                        data-toggle="popover"
                                                                                        data-trigger="focus"
                                                                                        data-content="Please select"
                                                                                    >
                                                                                        <ReactSelectCustom
                                                                                            name='locationIds'
                                                                                            value={values.locationIds}
                                                                                            className={`react-select ${errors.locationIds && touched.locationIds && 'is-invalid'}`}
                                                                                            options={widgetLocations}
                                                                                            isMulti
                                                                                            closeMenuOnSelect={false}
                                                                                            hideSelectedOptions={false}
                                                                                            components={{
                                                                                                Option,
                                                                                                MultiValue,
                                                                                                ValueContainer,
                                                                                                animatedComponents
                                                                                            }}
                                                                                            onChange={value => { setFieldValue('locationIds', value || ''); handleWidgetObj(value, values, 'locationIds') }}
                                                                                            allowSelectAll={true}
                                                                                            styles={{
                                                                                                // Fixes the overlapping problem of the component
                                                                                                menu: provided => ({ ...provided, zIndex: 9999 }),
                                                                                                control: (base, state) => ({
                                                                                                    ...base,
                                                                                                    boxShadow: 'none',
                                                                                                    border: errors.locationIds && touched.locationIds ? '1px solid #dc3545' : '',
                                                                                                })
                                                                                            }}
                                                                                        />
                                                                                    </StyleSelectAllSpan>
                                                                                    </> :
                                                                                    <>
                                                                                    <StyleLabel for='widgetName'>{t('Fragebogen')}</StyleLabel>
                                                                                        <StyleSelectAllSpan
                                                                                            className="d-inline-block"
                                                                                            data-toggle="popover"
                                                                                            data-trigger="focus"
                                                                                            data-content="Please select"
                                                                                        >
                                                                                            <ReactSelectCustom
                                                                                                name='questionnaireId'
                                                                                                value={values.questionnaireId}
                                                                                                className={`react-select ${errors.questionnaireId && touched.questionnaireId && 'is-invalid'}`}
                                                                                                options={widgetQuestionnaires}
                                                                                                isMulti
                                                                                                closeMenuOnSelect={false}
                                                                                                hideSelectedOptions={false}
                                                                                                placeholder={t('Select')}
                                                                                                components={{
                                                                                                    Option,
                                                                                                    MultiValue,
                                                                                                    ValueContainer,
                                                                                                    animatedComponents
                                                                                                }}
                                                                                                onChange={value => { setFieldValue('questionnaireId', value || ''); handleWidgetObj(value, values, 'questionnaireId') }}
                                                                                                allowSelectAll={true}
                                                                                                styles={{
                                                                                                    // Fixes the overlapping problem of the component
                                                                                                    menu: provided => ({ ...provided, zIndex: 9999 }),
                                                                                                    control: (base, state) => ({
                                                                                                        ...base,
                                                                                                        boxShadow: 'none',
                                                                                                        border: errors.questionnaireId && touched.questionnaireId ? '1px solid #dc3545' : '',
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                        </StyleSelectAllSpan>
                                                                                    </>
                                                                                }
                                                                            </FormGroup>
                                                                        </Col>
                                                                        </>
                                                                        }
                                                                        { selWidgetType === 1 &&
                                                                        <>
                                                                        <Col md='6' sm='12'>
                                                                            <FormGroup className='mb-3 form-label-group'>
                                                                                <StyleLabel for='widgetDataType'>{t('Data Type')}</StyleLabel>
                                                                                <ReactSelectCustom
                                                                                    name='widgetDataType'
                                                                                    value={values.widgetDataType}
                                                                                    className={`react-select`}
                                                                                    classNamePrefix='select form-control'
                                                                                    options={widgetDataTypeOptions}
                                                                                    onChange={value => { setFieldValue('widgetDataType', value || ''); handleWidgetObj(value, values, 'widgetDataType') }}
                                                                                    isSearchable={false}
                                                                                    isClearable={false}
                                                                                    isDisabled={false}
                                                                                    placeholder={t('Select')}
                                                                                    styles={{
                                                                                        // Fixes the overlapping problem of the component
                                                                                        menu: provided => ({ ...provided, zIndex: 9999 }),
                                                                                        control: (base, state) => ({
                                                                                            ...base,
                                                                                            boxShadow: 'none',
                                                                                            border: errors.widgetDataType && touched.widgetDataType ? '1px solid #dc3545' : '',
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md='6' sm='12'>
                                                                            <FormGroup className='mb-3 form-label-group'>
                                                                                <StyleLabel for='widgetTimeType'>{t('Time')}</StyleLabel>
                                                                                <ReactSelectCustom
                                                                                    className={`react-select`}
                                                                                    classNamePrefix='select'
                                                                                    name='widgetTimeType'
                                                                                    value={values.widgetTimeType}
                                                                                    options={widgetTimeTypeOptions}
                                                                                    onChange={value => { setFieldValue('widgetTimeType', value || ''); handleWidgetObj(value, values, 'widgetTimeType') }}
                                                                                    isSearchable={false}
                                                                                    isClearable={false}
                                                                                    isDisabled={false}
                                                                                    placeholder={t('Select')}
                                                                                    styles={{
                                                                                        // Fixes the overlapping problem of the component
                                                                                        menu: provided => ({ ...provided, zIndex: 9999 })
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        </>
                                                                        }
                                                                        { selWidgetType === 2 &&
                                                                        <Col md='12' sm='12'>
                                                                            <StyleHr></StyleHr>
                                                                            <ReactSelectCustom
                                                                                name='widgetTableType'
                                                                                value={values.widgetTableType}
                                                                                className={`react-select ${errors.widgetTableType && touched.widgetTableType && 'is-invalid'}`}
                                                                                classNamePrefix='select'
                                                                                onChange={value => { setFieldValue('widgetTableType', value); handleWidgetObj(value, values, 'widgetTableType') }}
                                                                                options={widgetTemplates}
                                                                                isSearchable={true}
                                                                                isClearable={false}
                                                                                isDisabled={false}
                                                                                placeholder={t('Select')}
                                                                                styles={{
                                                                                    menu: provided => ({ ...provided, zIndex: 9999 }),
                                                                                    control: (base, state) => ({
                                                                                        ...base,
                                                                                        boxShadow: 'none',
                                                                                        border: errors.widgetTableType && touched.widgetTableType ? '1px solid #dc3545' : '',
                                                                                        // '&:hover': {
                                                                                        //     border: '1px solid red',
                                                                                        // }
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        }
                                                                        { selWidgetType === 3 &&
                                                                        <Col md='12' sm='12'>
                                                                            <FormGroup className='mb-3 form-label-group'>
                                                                                <StyleLabel for='widgetTextType'>{t('Text Type')}</StyleLabel>
                                                                                <ReactSelectCustom
                                                                                    className={`react-select`}
                                                                                    classNamePrefix='select'
                                                                                    name='widgetTextType'
                                                                                    value={values.widgetTextType}
                                                                                    options={widgetTextTypeOptions}
                                                                                    onChange={value => { setFieldValue('widgetTextType', value); handleWidgetObj(value, values, 'widgetTextType') }}
                                                                                    isSearchable={false}
                                                                                    isClearable={false}
                                                                                    isDisabled={false}
                                                                                    placeholder={t('Select')}
                                                                                    styles={{
                                                                                        // Fixes the overlapping problem of the component
                                                                                        menu: provided => ({ ...provided, zIndex: 9999 })
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        }
                                                                    </>
                                                                }
                                                            </Row>
                                                        </Col>
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col md='12' sm='12' className="text-right mt-3">
                                                        { Object.keys(props.widgetDetail).length > 0 &&
                                                            <StyleBtnDelete disabled={processing} type='button' onClick={() => handleDeleteWidget(props.widgetDetail._id, setFieldValue)} variant="outlined" size="small" color="danger">
                                                                {t('Delete Widget')}
                                                            </StyleBtnDelete>
                                                        }
                                                        <StyleBtnCancel disabled={processing} type='button' onClick={() => setShow(false)} variant="outlined" size="small">
                                                            {t('Cancel')}
                                                        </StyleBtnCancel>
                                                        <StyleBtnSave disabled={axiosProcess} type='submit' variant="outlined" size="small">
                                                            {t('Save')}
                                                        </StyleBtnSave>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Form>
                                    )}
                                </Formik>
                            </Modal.Body>
                        </Modal>
                    </ModalDiv>
                </StyleMain>
            }
        </React.Fragment>
    )
}

export default CreateWidget
import React, {Component} from "react";

export default class Slider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsed: true,
            selfUpdate: false
        }
    }


    static getDerivedStateFromProps = (nextProps, prevState) => {
        return {
            collapsed: prevState.selfUpdate ? prevState.collapsed : nextProps.collapsed,
            selfUpdate: false
        };
    };

    render() {
        const {collapsed} = this.state;

        const barStyle = {...styles.bar, visibility: collapsed ? "hidden" : "visible"};
        const containerStyle = {...styles.container, right: collapsed ? "-100vw" : "0"};

        return (
            <div style={barStyle} onClick={() => this.setCollapsed(true)}>
                <div style={containerStyle} onClick={e => e.stopPropagation()}>
                    {this.props.children}
                </div>
            </div>
        );
    }

    setCollapsed(collapsed) {
        this.setState({collapsed, selfUpdate: true});
        if (!this.props.onChange()) { return; } else {
        this.props.onChange(collapsed);}
    }
}

const styles = {
    container: {
        position: "fixed",
        top: "0",
        right: "-100vw",
        display: "block",
        bottom: "0",
        width: "fit-content",
        overflowY: "scroll",
        transition: "0.3s",
    },
    bar: {
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        background: "#00000088",
        height: "100vh",
        width: "100vw",
        zIndex: "1",
    }
};
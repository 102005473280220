import React, { Component } from 'react';
import TableSection, { Header } from '../partials/TableSection';
import { StateContext } from '../../context/state';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import logoutIcon from '../../assets/logout.svg';
import home from '../../assets/home.svg';

const headers = [
	{
		name: 'Wer',
		value: 'userName',
		type: Header.TEXT,
	},
	{
		name: 'Wann',
		value: 'timeStamp',
		type: Header.TEXT,
	},
	{
		name: 'Wo',
		value: 'logDetails',
		type: Header.TEXT,
	},
];

class ChangeLog extends Component {
	static contextType = StateContext;
	originals = [];

	currentDate = new Date();

	constructor(props) {
		super(props);

		this.state = {
			loading: 'Loading...',
			logs: [],
			allLogs: '',
			events: [],
			collapsed: true,
			plans: [],
			questionnaireID: '',
			dummyParentQuestionnaireId: '',
			dummyChildQuestionnaireId: '',
			filteredData: [],
			event: '',
			keyFigures: {},
			allEvents: [],
			headerEmpty: false,
		};
	}

	componentDidMount = async () => {
		// localStorage.clear("eventId")
		//const venue=JSON.parse(sessionStorage.getItem("USER"))?.urlName
		const obj = JSON.parse(sessionStorage.getItem('state2'));
		console.log('obj', obj);
	};
	FilteredData = (data) => {
		//  console.log("data",data)
		this.setState({
			filteredData: data,
		});
	};

	buttons = [];
	handleLogout = () => {
		const urlName = this.context.urlName;
		this.context.updateState({
			authenticated: false,
			from: 'logout',
		});
		localStorage.clear();
		sessionStorage.clear();
		if (urlName === 'adminScreen') {
			this.props.history.push(`/login`);
		} else {
			this.props.history.push(`/login/${urlName}`);
		}
	};
	render() {
		const { logs, allLogs } = this.state;

		return (
			<div
				className="container-fluid p-0"
				style={{ overflowX: 'hidden' }}
				// style={{ overflow: "scroll" }}
			>
				<div className="row">
					<div className="col-2" style={styles.themesWrapper}>
						<OverlayTrigger
							key="bottom"
							placement="bottom"
							overlay={
								<Tooltip id={`tooltip-disabled`}>
									<span>{this.props.t('logout')}</span>
								</Tooltip>
							}>
							<button
								className="logout-btn"
								style={{
									border: 'none',
									marginTop: '10px',
									marginLeft: '10px',
								}}
								onClick={this.handleLogout}>
								<img src={logoutIcon} alt="" />
							</button>
						</OverlayTrigger>
						<OverlayTrigger
							key="bottom1"
							placement="bottom"
							overlay={
								<Tooltip id={`tooltip-bottom`}>
									<span>{this.props.t('home')}</span>
								</Tooltip>
							}>
							<button
								className="home-btn border-0 m-2"
								style={{ border: 'none' }}
								onClick={(e) => {
									this.props.location.state?.from === 'questionnairePage'
										? this.props.history.push('/admin/questionnaire')
										: this.props.history.push('/admin/events');
									//             this.props.location.state?.from=="eventsPage"?this.props.history.push("/admin/events"):
									//  this.props.history.push("/admin/questionnaire")
								}}>
								<img
									style={{ width: '20px', height: '25px' }}
									src={home}
									alt=""
								/>
							</button>
						</OverlayTrigger>
						<div className="main-company-logo d-flex align-items-center justify-content-center">
							<img
								// style={{ width: "150px", height: "150px", marginTop: "15px" }}
								style={{
									width: '100%',
									height: 'auto',
									maxHeight: '80px',
									objectFit: 'contain',
									imageRendering: 'high-quality',
									marginTop: '15px',
								}}
								src={this.context.venueLogo}
								alt=""
							/>
						</div>
					</div>
					<div className="col-9 ">
						<React.Fragment>
							<div style={styles.row}>
								<div className="col-10">
									<div
										style={{
											// color: "rgb(131, 132, 135)",
											fontSize: '24px',
											// margin: "20px 105px 20px 105px",
											marginTop: '20px',
											color: '#524f4f',
										}}>
										{this.props.t('Change_Logs')}
									</div>
									<div
										style={{
											// color: "rgb(131, 132, 135)",
											fontSize: '18px',
											// margin: "20px 105px 20px 105px",
											marginTop: '20px',
											color: '#524f4f',
										}}>
										{console.log('allLogs', allLogs)}
										<span>{allLogs.customerName}</span> --{' '}
										{/* <span>{allLogs.eventDate}</span> */}
										<span>
											{moment(allLogs.eventDate)
												.utcOffset(0, true)
												.format('YY/M/D')}
										</span>
										-- <span>{allLogs.managerName}</span> --{' '}
										<span>{allLogs.questionnaireName}</span>
									</div>
									<div style={styles.divider} />
								</div>
							</div>

							<div style={styles.row}>
								<div className="col-1" />
								<div className="col-12">
									<>
										{console.log('logs', logs)}
										{/* {this.context.userRole !== "SA" && ( */}
										<TableSection
											headers={headers}
											data={logs}
											//allEvents={allEvents}
											buttons={this.buttons}
											buttonCount={4}
											//FilteredData={this.FilteredData}
											//originalData={this.originals}
											checkIfHeaderEmpty={this.checkIfHeaderEmpty}
										/>
										{/* )} */}
										{/* <TableSection headers={headers} data={events} buttons={this.buttons} buttonCount={4} /> */}
									</>
								</div>
							</div>
						</React.Fragment>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(ChangeLog);
const styles = {
	row: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	addWrapper: {
		marginLeft: '40px',
	},
	slider: {
		background: '#d0d3d4',
		padding: '40px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		height: '100%',
	},
	plan: {
		maxWidth: '400px',
		display: 'flex',
		alignItems: 'center',
	},
	text: {
		margin: '10px',
		wordWrap: 'break-word',
		fontSize: '16px',
		backgroundColor: 'rgba(230, 231, 232, 0.81)',
	},
	icon: {
		width: '20px',
		height: '20px',
		margin: '2px',
	},
	pointer: {
		cursor: 'pointer',
	},
	divider: {
		borderTop: '1px solid #d0d3d4',
		margin: '30px 0 0px 0px',
		paddingTop: '10px',
	},
};

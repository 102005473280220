import React, { Component } from "react";
import { PriceType, PricingFunction } from "../../../Constants";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Text from "../Text";
import {StateContext} from "../../../context/state";
import moment from 'moment-timezone'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withTranslation} from 'react-i18next';
import Style from 'style-it';
// import Radium, {Style} from 'radium';


 class TextOption extends Component {
  static contextType = StateContext;
  pricingFunction;

  constructor(props) {
    super(props);
    this.state={
      textDisplay:true,
      error:""
    }
    this.pricingFunction =
      PricingFunction[this.props.priceType || PriceType.NONE];
    this.state = {
      label: draftToHtml(
        convertToRaw(
          EditorState.createWithContent(
            convertFromRaw(props.value)
          ).getCurrentContent()
        )
      ),
    };
  }
  componentDidMount(){
    const textarea=document.getElementById("textarea")
  
    if(textarea!==null){
      
      textarea.style.height=`${textarea.scrollHeight}px`
      //  textarea.style.height=`${height*2}px`
      //  textarea.value.trim()
      //  if(textarea.value.trim()){
      //    const ht=textarea.style.height
      //   console.log( textarea.style.height)
      // }
    }
    if (window.innerWidth > 1094) this.setState({ textDisplay: false });
    window.addEventListener("resize", () => {
      if (window.innerWidth > 1094) this.setState({ textDisplay: false });
      else this.setState({ textDisplay: true });
    });
  
  }
  handleKeyDown = (e) => {
   
    
    let deadLineDate = new Date(this.context.deadlineForEntries);
   
    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) 
  {
        
      e.preventDefault()
    }
    else{
      e.target.style.height = `${e.target.scrollHeight}px`
    }
    
   
  };

  static getDerivedStateFromProps = (nextProps, prevState) => {
      return {
       label: draftToHtml(convertToRaw(EditorState.createWithContent(convertFromRaw(nextProps.value)).getCurrentContent()))
      };
    };
  onQuantityChange = (e) => {
   
    let deadLineDate = new Date(this.context.deadlineForEntries);
   
    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) 
  {
     return false
    }
    else{
      if(!this.props.selectedGuestCount){

      
      let key = Number(e.target.value);
      if (isNaN(key) || e.key === null || e.key === " ") return
    
    const {
      price,
      numerically,
      stages,
      maximum,
      minimum,
      onUpdateWithoutAPICall,
    } = this.props;
    const quantity = e.target.value;
    const { amount, error } = this.pricingFunction(
      Number(price) || 0,
      Number(quantity) || 0,
      Number(stages) || 0,
      Number(maximum) || 0,
      Number(minimum) || 0,
      numerically
    );
    this.setState({error:error})
    onUpdateWithoutAPICall({ quantity, amount, error });
  };
}
}
  onEnterHit=(quantity)=>{
    const {
      price,
      numerically,
      stages,
      maximum,
      minimum,
      onUpdate,
    } = this.props;
    const { amount, error } = this.pricingFunction(
      Number(price) || 0,
      Number(quantity) || 0,
      Number(stages) || 0,
      Number(maximum) || 0,
      Number(minimum) || 0,
      numerically
    );
    this.setState({error:error})
    toast.error(this.state.error, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
   !error && onUpdate({ quantity, amount, error });
   
  }
  renderPriceTypeComponent = () => {
    const textFontSize = this.context.stylingData?.textFontSize;
    const textFontFamily = this.context.stylingData?.textFontFamily;
    // const headingColor =this.context.stylingData?.["headingColor"] ? this.context.stylingData?.headingColor : "#939598";
    const headingColor = this.context.stylingData?.textColor ? this.context.stylingData?.textColor :"#939598";

    const { priceType, answer, numerically, price } = this.props;
    // const quantity = answer ? answer?.quantity: priceType==="number-guests"? this.props.selectedGuestCount :"";

    let quantity;
    if (answer) {
      if (priceType !== "number-guests") {
        quantity = answer?.quantity;
      } else {
        if (this.props.selectedGuestCount) {
          // quantity=this.props.selectedGuestCount;
          quantity = "";
        }
      }
    }
      

    

    return (
      <Style>
      {`
#placeHolder::placeholder {
color: ${headingColor};   
}`}
      <div style={styles.wrapper}>
    {  priceType !== "number-guests"  && (<input
          onKeyDown={(e) => {
            
            let deadLineDate = new Date(this.context.deadlineForEntries);
   
            if (
              deadLineDate.toDateString() !== new Date().toDateString() &&
              moment(this.context.deadlineForEntries).isBefore(new Date()) &&
              this.context.userRole === "CU"
            ) 
            {
    
              e.preventDefault()
            }
            else{
              if (e.key === "Enter") this.onEnterHit(quantity)
            // if (e.key === "Backspace") return;
            // let key = Number(e.key);
            // if (isNaN(key) || e.key === null || e.key === " ")
            //   e.preventDefault();
           
            }
            
          }}
          // placeholder={placeholder}
          id="placeHolder"
          placeholder={this.props.t('bitte_eintragen')}
          value={quantity}
          onChange={this.onQuantityChange}
          // style={{ ...styles.input, borderBottom: "0px" }}
          style={this.props.selectedGuestCount ? styles.inputHidden : { ...styles.input, borderBottom: "0px",color:headingColor}}
          autoComplete="off"
        />)}
        {priceType==="flat-rate" ?
       (<Text
        fontSize={textFontSize}
        fontFamily={textFontFamily}
        color = {headingColor}
        // style={styles.unit}
        value={`(${
          numerically?.unit? ` ${numerically.unit}` : 
          `${this.props.t('flat_rate')}`
         }
         ${this.context.languageFormat==="en"? `${Number(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :  `${Number(price)
          .toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}` } ${this.context.currencyFormat})`}
      />):
      (priceType===PriceType.NUMERICALLY ?
      <Text
      fontSize={textFontSize}
      fontFamily={textFontFamily}
      color = {headingColor}
          // style={styles.unit}
          value={`(${this.context.languageFormat==="en"? `${Number(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :  `${Number(price)
            .toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}` }  ${this.context.currencyFormat} ${
            numerically?.unit ? `${numerically.unit}` : " "
          })`}
        />:
        <Text
        fontSize={textFontSize}
        fontFamily={textFontFamily}
        color = {headingColor}
            // style={styles.unit}
            value={`(${this.context.languageFormat="en"? `${Number(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :  `${Number(price)
              .toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}` }  ${this.context.currencyFormat} ${
              numerically?.unit ? `${numerically.unit}` : `${this.props.t('Per')} ${this.props.t('Gast')}`
            })`}
          />
      )  }    
      </div>
      </Style>
    );
  };



  render() {

    const textFontSize = this.context.stylingData?.textFontSize;
    const textFontFamily = this.context.stylingData?.textFontFamily;
    const textColor =this.context.stylingData?.textColor? this.context.stylingData?.textColor : "#939598";
    console.log("txt",textColor);
  

    const {
      interactive,
      numeric,
      answer,
      onUpdateWithoutAPICall,
      priceType,
      interactiveText,
      stages,
      maximum,
      minimum,
    } = this.props;
    const { label } = this.state;
    // console.log("answer",answer)
    const val = answer?.value ? answer?.value : "";

    if (interactive) {
      if (numeric) {
        if (priceType) {
          return (
            <div style={styles.container}>
              {
             this.state.textDisplay ? 
           
              <ToastContainer
           position="bottom-center"
           autoClose={5000}
           hideProgressBar={false}
           newestOnTop={false}
           closeOnClick
           rtl={false}
           pauseOnFocusLoss
           draggable
           pauseOnHover
           />
             :<></> } 
              {this.renderPriceTypeComponent()}
         {/* {this.state.textDisplay ?  <span  style={{color:"red", margin: "0px 0px 10px", fontWeight: "600"}}>{answer?.error}</span>:<></> }     */}
              <Text fontSize={textFontSize}
                fontFamily={textFontFamily} value={interactiveText} style={styles.label} />
            </div>
          );
        }

        return (
          <div style={styles.container}>
             {/* {console.log("val",val)} */}
            <input
              // placeholder={"Geben Sie die Nummer ein"}
              placeholder={this.props.t('bitte_eintragen')}
              value={answer?.quantity}
              maxLength={2000}
              onKeyDown={(e) => {
                  
                  let deadLineDate = new Date(this.context.deadlineForEntries);
   
                  if (
                    deadLineDate.toDateString() !== new Date().toDateString() &&
                    moment(this.context.deadlineForEntries).isBefore(new Date()) &&
                    this.context.userRole === "CU"
                  ) 
               {
                  e.preventDefault()
                }
                else{
                  if (e.key === "Enter") this.onEnterHit(val)
                  // if (e.key === "Backspace") return;
                  // let key = Number(e.key);
                  // if (isNaN(key) || e.key === null || e.key === " ")
                  //   e.preventDefault();
                
                }
            }
          }
              style={{...styles.input,color:textColor}}
              onChange={(e) => {
                const number = Number(e.target.value);
                let error = undefined;
                if (stages && number % Number(stages) !== 0)
                  error = `Wert sollte ein Vielfaches von ${stages} sein`;
                if (minimum && number < Number(minimum))
                  error = `Wert sollte größer oder gleich ${minimum} sein`;
                if (maximum && number > Number(maximum))
                  error = `Wert sollte kleiner oder gleich ${maximum} sein`;
                  onUpdateWithoutAPICall({ value: e.target.value, error });
              }}
              autoComplete="off"
            />
            <Text  fontSize={textFontSize}
                fontFamily={textFontFamily}value={interactiveText} style={styles.label} />
          </div>
        );
      }

      return (

        <Style>
            {`
      #placeHolder::placeholder {
      color: ${textColor};   
     }`}
        <div style={styles.container}>
 
          <textarea
          id="placeHolder"
          className="texta"
            // placeholder={"Geben Sie den Wert ein"}
            placeholder={this.props?.value?.blocks[0]?.text? this.props.value?.blocks[0]?.text:this.props.t('bitte_eintragen')}
            value={val}
            rows={1}
            maxLength={2000}
            style={{ ...styles.textArea,fontFamily:textFontFamily,fontSize:`${textFontSize}px`,color:textColor}}
            onChange={(e) =>
              {
                let deadLineDate = new Date(this.context.deadlineForEntries);
   
                if (
                  deadLineDate.toDateString() !== new Date().toDateString() &&
                  moment(this.context.deadlineForEntries).isBefore(new Date()) &&
                  this.context.userRole === "CU"
                ) 
            {
                e.preventDefault()
              }
              else{
                onUpdateWithoutAPICall({ value: e.target.value })
              }
              }
            }
            onKeyDown={this.handleKeyDown}
            
          />
          
          <Text  fontSize={textFontSize}
                fontFamily={textFontFamily} value={interactiveText} style={styles.label} />
        </div>
        </Style>
      );
    }

    return (
      <div style={styles.container}>
        <span
          style={{fontSize:`${textFontSize}px`,textFontFamily:textFontFamily,
          // color:`${textColor}`
        }}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      </div>
    );
  }
}

export default withTranslation()(TextOption);
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    // padding: "10px 0",
  },
  label: {
    padding: "0",
    color: "#939598",
    // fontSize: "16px",
  },
  wrapper: {
    display: "flex",
    width: "100%",
    borderBottom: "2px solid #bcbec0",
  },
  input: {
    border: 0,
    padding: 0,
    margin: 0,
    background: "transparent",
    flex: "1 1 auto",
    borderBottom: "2px solid #bcbec0",
  },
  inputHidden : {
    display:"none",
  },
  textArea:{
    border: 0,
    padding: 0,
    margin: 0,
    background: "transparent",
    flex: "1 1 auto",
    borderBottom: "2px solid #bcbec0",
    outline: "none", 
    resize: "none" ,
    overflow:"hidden"
  },

  unit: {
    margin: "0 0 0 10px",
    padding: "0",
    color: "#939598",
    // fontSize: "16px",
    // fontFamily: "Crete Round",
  },
};

import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { StateContext } from "../context/state";
import swal from "sweetalert";
import { baseUrl } from "../Constants";
import eye from "../assets/eye.svg";
import eyeslash from "../assets/eye-slash.svg";
import { useTranslation } from "react-i18next";

function SetPassword(props) {
  const {t} = useTranslation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConFirmPassword] = useState("");
  const [urlName, ] = useState(
    !props?.match?.params?.name
      ? "adminScreen"
      : props.match.params.name.toLowerCase()
  );
  const [bool, setBool] = useState(false);
  const [confirmPasswordBool, setConfirmPasswordBool] = useState(false);
  const [loginJSON, setLoginJSON] = useState(null);
  const [, setReferer] = useState("/");
  const [ErrorMsg, setErrorMsg] = useState("");
  const [, setDisabled] = useState(true);
  const [, setBlock] = useState(false);
  const value = useContext(StateContext);

  const setBodyBackground = (backgroundUrl) => {
    let body = document.getElementsByTagName("body")[0];
    body.style.backgroundImage = `url(${backgroundUrl})`;
  };

  useEffect(() => {
    if (password.length === 0) {
      setDisabled(true);
    }

    axios.get(baseUrl + "api/venue/byName/" + urlName).then((res) => {
      const response = res.data;

      setLoginJSON(response);
      setReferer("/");
      setBodyBackground(response.backgroundImgSrc);

      value.updateState({
        venueId: response._id,
        venueLogo: response.venueLogoSrc,
        venueURL: response.venueURL,
        currencyFormat: response.currencyFormat,
        languageFormat: response.languageFormat,
      });
    });
  }, [password.length, urlName, value]);
  const changeConfirmPassword = () => {
    const confirmpassword = document.querySelector("#Confirmpassword");

    const type =
      confirmpassword.getAttribute("type") === "password" ? "text" : "password";

    confirmpassword.setAttribute("type", type);

    if (confirmpassword.type === "text") {
      setConfirmPasswordBool(true);
    } else if (confirmpassword.type === "password") {
      setConfirmPasswordBool(false);
    }
  };
  const changePassword = () => {
    const password = document.querySelector("#password");

    const type =
      password.getAttribute("type") === "password" ? "text" : "password";

    password.setAttribute("type", type);

    if (password.type === "text") {
      setBool(true);
    } else if (password.type === "password") {
      setBool(false);
    }
  };

  useEffect(() => {
    return () => {
      let body = document.getElementsByTagName("body")[0];
      body.style.backgroundImage = "";
    };
  }, []);
  const handleChange = (e) => {
    let passwordRegex =   /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z0-9!@#$%^&*()~¥=_+}{":;'?/>.<,`\-|[\]]{6,50}$/
    ///(?=.*\d)[0-9a-zA-Z]{5,}/;

    if (confirmPassword.length === 0) {
      setBlock(true);
    } else {
      setBlock(false);
    }
    if (password.length === 0) {
      setDisabled(true);
    } else if (!passwordRegex.test(e.target.value)) {
      setErrorMsg(
        // "Das Passwort sollte aus insgesamt 6 Zeichen bestehen und mindestens eine Zahl und ein Zeichen sowie kein Sonderzeichen enthalten."
      //   "Das Passwort sollte aus mindestens 6 Zeichen bestehen sowie mindestens eine Zahl und ein Buchstaben enthalten."
      t("Das Passwort sollte mindestens aus sechs Zeichen, einer Zahl und einem Buchstaben bestehen")
      );
      setDisabled(true);
    } else if (e.target.value.length < 6) {
      setErrorMsg(t("Das Passwort sollte mindestens 6 Zeichen enthalten"));
      setDisabled(true);
    } else {
      setErrorMsg("");
      setDisabled(false);
    }
    setPassword(e.target.value);
  };
  const handleButton = (e) => {
    e.preventDefault();
    let passwordRegex =  /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z0-9!@#$%^&*()~¥=_+}{":;'?/>.<,`\-|[\]]{6,50}$/
    ///(?=.*\d)[0-9a-zA-Z]{5,}/
    // /[0-9a-zA-Z]{5,}/;
    const payload = {
      venueURL: value.venueURL,
      emailId: props.match.params.email,
      accessCode: props.match.params.code,
      password: password,
    };
    // if (confirmPassword.length === 0) {
    //   setBlock(true);
    // } else {
    //   setBlock(false);
    // }
    if (!passwordRegex.test(password)) {
      setErrorMsg(
        // "Das Passwort sollte aus insgesamt 6 Zeichen bestehen und mindestens eine Zahl und ein Zeichen sowie kein Sonderzeichen enthalten."
        // "Das Passwort sollte aus mindestens 6 Zeichen bestehen sowie mindestens eine Zahl und ein Buchstaben enthalten."
       t("Das Passwort sollte mindestens aus sechs Zeichen, einer Zahl und einem Buchstaben bestehen")
        );
      setDisabled(true);
    } else if (password.length < 6) {
      setErrorMsg(t("Das Passwort sollte mindestens 6 Zeichen enthalten"));
      setDisabled(true);
    } else if (password !== confirmPassword) {
      setErrorMsg(
        t("Das neue Passwort stimmt nicht mit dem Bestätigungspasswort überein")
      );
      setDisabled(true);
    } else if (password.length === 0) {
      setErrorMsg(t("ungültiger Benutzername oder Passwort"));
      setDisabled(true);
    } else {
      setErrorMsg("");
      setDisabled(false);
    }
    // if (block === true || disabled === true) {
    //   swal("Passwort setzen fehlgeschlagen");
    // }
    if (
      password.length === 0 ||
      confirmPassword.length === 0 ||
      !passwordRegex.test(password) ||
      password !== confirmPassword
    ) {
      swal(t("Passwort setzen fehlgeschlagen"));
    } else {
      axios
        .post(`${baseUrl}api/user/SetPassword`, payload)
        .then((res) => {
          swal(res.data);
          if (res.data !== "Passwort setzen fehlgeschlagen.") {
            props.history.push(`/login/${value.venueURL}`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
   
  };

  return (
    <StateContext.Consumer>
      {({ authenticated }) => {
        if (authenticated) {
          return;
        }

        return (
					<div className="login-section-wrapper">
						<span
							style={{
								color: '#ff0000',
								fontSize: '15px',
								marginLeft: '160px',
							}}></span>
						<div className="row">
							<div className="col-md-6 col-sm-12">
								<div className="login-section-left">
									<p
										style={{
											color:
												loginJSON?.welcomeTextColor &&
												loginJSON.welcomeTextColor,
											fontFamily:
												loginJSON?.welcomeTextFont && loginJSON.welcomeTextFont,
										}}>
										{loginJSON != null && loginJSON.welcomeText}
									</p>
									<img
										className="company-logo"
										src={loginJSON != null ? loginJSON.venueLogoSrc : 'false'}
										alt=""
									/>
								</div>
							</div>
							<div className="col-md-6 col-sm-12">
								<form className="login-section-right form-group">
									<input
										id="password"
										type="password"
										className="form-control"
										placeholder={t('Password')}
										value={password}
										onChange={handleChange}
										autoComplete="off"
									/>
									{bool === true ? (
										<img
											alt={'Eye'}
											id="togglePassword"
											onClick={changePassword}
											src={eye}
											style={{
												position: 'relative',
												width: '15px',
												height: '10px',
												marginTop: '-110px',
												marginRight: '5px',
											}}
										/>
									) : (
										<img
											alt={'Eye'}
											id="togglePassword"
											onClick={changePassword}
											src={eyeslash}
											style={{
												position: 'relative',
												width: '15px',
												height: '10px',
												marginTop: '-110px',
												marginRight: '5px',
											}}
										/>
									)}
									<input
										id="Confirmpassword"
										type="password"
										className="form-control"
										placeholder={t('Passwort bestätigen')}
										value={confirmPassword}
										onChange={(e) => setConFirmPassword(e.target.value)}
										autoComplete="off"
										// disabled={disabled}
									/>
									{confirmPasswordBool === true ? (
										<img
											alt={'Eye'}
											id="toggleConfirmPassword"
											onClick={changeConfirmPassword}
											src={eye}
											style={{
												position: 'relative',
												width: '15px',
												height: '10px',
												marginTop: '-110px',
												marginRight: '5px',
											}}
										/>
									) : (
										<img
											alt={'Eye'}
											id="toggleConfirmPassword"
											onClick={changeConfirmPassword}
											src={eyeslash}
											style={{
												position: 'relative',
												width: '15px',
												height: '10px',
												marginTop: '-110px',
												marginRight: '5px',
											}}
										/>
									)}

									<div className="error-msg" style={{ color: 'black' }}>
										{ErrorMsg}
									</div>

									<button
										className="login-button"
										type="submit"
										onClick={handleButton}>
										{t('Passwort festlegen')}
									</button>
								</form>
							</div>
						</div>
					</div>
				);
      }}
    </StateContext.Consumer>
  );
}

export default SetPassword;

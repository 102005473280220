import React, { useState, useEffect, useContext } from "react"
import { useHistory } from "react-router-dom";
import { Image, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Highcharts from 'highcharts'
import moment from "moment"
import HighchartsReact from 'highcharts-react-official'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import HttpService from "../../services/HttpService";
import { StateContext } from "../../context/state"
import TableSection from "../partials/TableSection"
import axios from "axios";
import styled from "styled-components";
import { baseUrl } from "../../Constants";
import { circleMask } from "../../assets";

import {
  userIcon,
  offersIcon,
  eventIcon,
  occupancyPlanIcon,
  controllingIcon,
  squareCurrencyIcon,
  questionnaireIcon,
  organizationPlanIcon,
  partnersIcon,
  logoutIcon,
  settingIcon,
  dragDropIcon,
} from '../../assets'

import {
  EventTblSection,
  ControllingSection,
  StickyDiv,
  ContainerSection,
  CustomizeDashboard,
  CustomBtn,
  styles,
  StyleNoData,
  StyleNoDataSM,
  StyleProfileSection
} from '../../components/dashboard/styles'

import CreateWidget from "../dashboard/CreateWidget";
import WeatherWidget from "../dashboard/WeatherWidget";
// import { t,i18n } from "react-i18nify";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";

const translationMapping = {
  "events":"Events",
  "offers":"Offers",
  "Occupancy Plan":"Occupancy Plan",
  "Controlling":"Controlling",
  "invoice":"Invoices",
  "Revenue":"Revenue",
  "questionnaire":"Fragebogen"
}

const tblQuestionaireHeader = [
  {
    name: "Fragebogen",
    value: "name",
    type: 'label',
    length: 30,
    readOnly: true,
  },
  {
    name: "Erstellt am",
    value: "creationDate",
    type: 'label_date',
    readOnly: true,
  },
  {
    name: "Aktive Events",
    value: "activeEvents",
    type: 'label',
    readOnly: true,
  }
]

const tblEventHeader = [
  {
    name: "Event Name",
    value: "eventName",
    type: 'label',
  },
  {
    name: "Eventdatum",
    value: "eventDate",
    type: 'label_date',
  },
  {
    name: "Fragebogen",
    value: "parentQuestionnaireName",
    type: 'label',
    values: [],
    field: "parentQuestionnaireId",
  },
  {
    name: "Kontaktperson",
    value: "contactPersonName",
    type: 'label',
    values: [],
    field: "contactPersonId",
  },
  {
    name: "Eventmanager",
    value: "vvoName",
    type: 'label',
    values: [],
    field: "vvoId",
  },
]

const tblInvoiceHeader = [
  {
    name: "invoiceNo",
    value: "invoiceNo",
    type: "label"
  },
  {
    name: "location",
    value: "location",
    type: "label"
  },
  {
    name: "invoiceCreated",
    value: "invoiceCreated",
    type: "label_date"
  },
  {
    name: "status",
    value: "status",
    type: "label"
  },
  {
    name: "totalAmount",
    value: "totalAmount",
    type: "label"
  }
]

const StyleHighChart = styled.div`
  .highcharts-tooltip {
    pointer-events: auto !important  
  }
  .highChartTooltipDiv { 
    max-height:200px !important;
    overflow-y:scroll !important;
  }
  tspan {
    text-transform:capitalize;
  }
`

const StyleUl = styled.ul`
  
`

const DashboardPage = (props) => {
  const { t } = useTranslation();
  const history = useHistory()
  const [scrolldiv, setScrollDiv] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [customizeDashboard, setCustomizeDashboard] = useState(false);
  const [callWidgetCnt, setCallWidgetCnt] = useState(1);
  const [widgets, setWidgets] = useState([]);
  const [widgetDetail, setWidgetDetail] = useState({});
  const [selectedWidgetItem, setSelectedWidgetItem] = useState(1);

  let contextValue = useContext(StateContext);
  const { loggedInUserId, venueLogo, surname, languageFormat } = contextValue;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollDiv(window.scrollY > 0);
    });
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 280);
    });
  }, []);

  useEffect(() => {
    HttpService.getWidget(loggedInUserId).then(
      (response) => {
        setWidgets(response);
      });
  
  }, [callWidgetCnt])

  useEffect(() => {
    axios
      .get(`${baseUrl}api/venue/${contextValue.venueId}`)
      .then((res) => {  
        // contextValue.updateState({languageFormat:res.data?.languageFormat});
        i18next.changeLanguage(res.data?.languageFormat);
      
      })
      .catch((err) => {
        console.log(err)
      });
  })

  const getChartLgSize = (chart) => {
    let chartData = chart.data;
    return {
      chart: {
          type: 'column'
      },
      title: {
          text: '',
      },
      subtitle: {
          text: ''
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      xAxis: {
          categories: chartData.categoriesXAxis && chartData.categoriesXAxis.length > 0 ? chartData.categoriesXAxis.map((v) => {
            return t(v)
          }) : chartData.categories.length > 0 && chartData.categories.map((val) => {
            return moment(val).locale(languageFormat).format('MMMM YYYY')
          }),
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: t(chartData.timeFrame),
              enabled: false
          }
      },
      tooltip: {
          formatter: function() {
            let formatStr = `<div class="highChartTooltipDiv">`;
            for (let i = 0; i < this.points.length; i++) {
              let point = this.points[i];
              formatStr += `<tspan><b>${t(point.point.category)}</b></tspan> : <span style="color:' + point.color + '"><b>${point.y} ${chartData.textToDisplay.toLowerCase().includes("revenue") ? chartData.currency : ''}</span></b><br/>`;
              point.series.name.split(",");
              // Use the "undocumented" Highcharts.numberFormat function
              if(chart.allSelected) {
                formatStr += '<span style="color:' + point.color + '">●</span>' + t('all') + '<br/>';
              } 
            }
            formatStr += `</div>`;
            return formatStr;
          },
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: chartData.chartData && chartData.chartData.length > 0 && chartData.chartData.map((chart) => {
        return {
          name: chart.name,
          data: chart.widgetData && chart.widgetData.length > 0 && chart.widgetData.map((w) => {
            return w.total === 0 || w.total === '0' ? null : parseFloat(w.total)
          }),
        }
      })
    }
  }

  const getChartSmSize = (chart) => {
    let chartData = chart.data;
    return {
      chart: {
          type: 'column',
          height: '160px',
          zoomType: 'x',
          backgroundColor: 'transparent'
      },
      title: {
          text: '',
      },
      subtitle: {
          text: ''
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      xAxis: {
        labels: {
          enabled: false
        },
        categories: chartData.categoriesXAxis && chartData.categoriesXAxis.length > 0 ? chartData.categoriesXAxis.map((v) => {
          return t(v)
        }) : chartData.categories.length > 0 && chartData.categories.map((val) => {
          return moment(val).locale(languageFormat).format('MMMM YY')
        }),
        crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: t(chartData.timeFrame),
              enabled: false
          }
      },
      tooltip: {
        formatter: function() {
          let formatStr = `<div class="highChartTooltipDiv">`;
          for (let i = 0; i < this.points.length; i++) {
            let point = this.points[i];
            formatStr += `<tspan><b>${t(point.point.category)}</b></tspan> : <span style="color:' + point.color + '"><b>${point.y} ${chartData.textToDisplay.toLowerCase().includes("revenue") ? chartData.currency : ''}</span></b><br/>`;
            point.series.name.split(",");
            // Use the "undocumented" Highcharts.numberFormat function
            if(chart.allSelected) {
              formatStr += '<span style="color:' + point.color + '">●</span>' + t('all') + '<br/>';
            }
          }
          formatStr += `</div>`;
          return formatStr;
        },
        shared: true,
        useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: chartData.chartData && chartData.chartData.length > 0 && chartData.chartData.map((chart) => {
        return {
          name: chart.name,
          data: chart.widgetData && chart.widgetData.length > 0 && chart.widgetData.map((w) => {
            return w.total === 0 || w.total === '0' ? null : parseFloat(w.total)
          })
        }
      })
    }
    // return {
    //   chart: {
    //     type: 'column',
    //     height: '158px',
    //     zoomType: 'x',
    //     backgroundColor: 'transparent'
    //   },
    //   title: {
    //       text: ''
    //   },
    //   subtitle: {
    //       text: ''
    //   },
    //   credits: {
    //     enabled: false
    //   },
    //   accessibility: {
    //       announceNewData: {
    //           enabled: true
    //       }
    //   },
    //   xAxis: {
    //       type: 'category'
    //   },
    //   yAxis: {
    //       title: {
    //           text: ''
    //       }
    //   },
    //   legend: {
    //       enabled: false
    //   },
    //   plotOptions: {
    //       series: {
    //           borderWidth: 0,
    //           dataLabels: {
    //               enabled: true,
    //               format: `{point.y} ${chartData.textToDisplay === 'Revenue' ? chartData.currency : ''}`
    //           }
    //       }
    //   },
    //   tooltip: {
    //       headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    //       pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y} ${chartData.textToDisplay === 'Revenue' ? chartData.currency : ''}</b><br/> ${t(chartData.headlineText)}`
    //   },
    //   series: [
    //     {
    //         name: t(chartData.textToDisplay),
    //         colorByPoint: true,
    //         data: chartData.widgetData && chartData.widgetData.length > 0 && chartData.widgetData.map((chart) => {
    //           return {
    //             name: chart.name,
    //             y: chart.total === 0 || chart.total === '0' ? null : parseFloat(chart.total),
    //             drilldown: chart.name,
    //             visible: false,
    //           }
    //         })
    //     }
    //   ],
    //   drilldown: {
    //       breadcrumbs: {
    //           position: {
    //               align: 'right'
    //           }
    //       },
    //       series: []
    //   }
    // }
  }

  const handleLogout = () => {
    const urlName = contextValue.urlName;
    contextValue.updateState({
      authenticated: false,
      from: "logout"
    });
    localStorage.clear();
    sessionStorage.clear();
    if (urlName === "adminScreen") {
      props.history.push(`/login`);
    } else {
      props.history.push(`/login/${urlName}`);
    }
  };

  const getItemStyle = (isDragging, draggableStyle, width) => ({
    // some basic styles to make the items look a bit nicer

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : '',
    flex: width === '50' ? '0 0 55%' : '0 0 25%',
    maxWidth: width === '50' ? '50%' : '25%',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '',
  });

  const onDragEnd = (result, key) => {
    // dropped outside the list
    if (!result.destination || customizeDashboard === false) {
      return;
    }
    const selectWidget = widgets[key];
    const dndItems = reorder(
      selectWidget.widget,
      result.source.index,
      result.destination.index
    );
    widgets[key].widget = dndItems;
    HttpService.updateWidget(selectWidget._id, {
      order: selectWidget.order,
      size: selectWidget.size,
      userId: loggedInUserId,
      venueId: contextValue.venueId,
      widget: dndItems,
      widgetTableType: selectWidget.widgetTableType,
    })
    setWidgets(widgets);
  }

  const reorder = (list, startIndex, endIndex) => {
    let firstWidget = list[startIndex];
    const fSizeRowCol = firstWidget.sizeRow;
    let secondWidget = list[endIndex];
    firstWidget.sizeRow = secondWidget.sizeRow;
    secondWidget.sizeRow = fSizeRowCol;
    list[startIndex] = firstWidget;
    list[endIndex] = secondWidget;
    [list[startIndex], list[endIndex]] = [list[endIndex], list[startIndex]]
    return list;
  };

  const handleWidgetClick = (detail, activeItem) => {
    setWidgetDetail(detail);
    setSelectedWidgetItem(activeItem);
  }

  return (
    <React.Fragment>
      <ContainerSection fluid style={styles.container}>
        <Row style={styles.widgetRow} className='justify-content-md-center'>
          <Col md={10} xs={12}>
            <div style={styles.bgColor}>
              <StickyDiv className={scrolldiv ? "scroll-div" : "no-scroll"}>
                <Row>
                  <Col md={2} style={styles.venueLogoSection}>
                    <Image style={styles.venueLogo} src={venueLogo} />
                  </Col>
                  <Col md={10}>
                    <div style={styles.rightSection}>
                      <StyleProfileSection>
                        <div style={styles.imagetext}>
                          <div style={{width:"82px", height:"80px",position:"relative",marginRight:"10px",marginTop:"10px",marginBottom:"10px"}}>
                            <Image className={!contextValue.userdata ? 'profile-default' : ''} style={styles.avatar} src={contextValue.userdata ? contextValue.userdata : userIcon}  />
                            <img style={{ position: "absolute",top: 0, left: 0 ,width: "inherit",height: "inherit"}} src={circleMask} alt="" />
                          </div>
                          <div>
                            <p style={styles.navpName}>{t('Hello')} {surname.charAt(0).toUpperCase() + surname.slice(1)}</p>
                            <p style={styles.navp}>{moment().locale(languageFormat).format("dddd, D MMMM YYYY")}</p>
                          </div>
                          {/* <div style={styles.vl}></div> */}
                          {/* <h5 style={styles.notification}>
                              <Badge bg="success">
                                <Image src={bellIcon} /> <span style={styles.notifCount}>12</span>
                              </Badge>{' '}
                            </h5> */}
                        </div>
                      </StyleProfileSection>
                      <WeatherWidget />
                    </div>
                  </Col>
                </Row>
                <Row style={styles.bgColor} className="mt-2">
                  <Col md={12}>
                    <div style={styles.mainbox} className="d-flex p-1">
                      {/* <CarouselSection itemsToShow={7} itemsToScroll={2}> */}
                      <div style={styles.iconbox} onClick={() => { history.push(`/admin/coming-soon`) }}>
                        <Image style={styles.iconimage} src={offersIcon} />
                        <p style={styles.iconboxtext}>{t('Offers')}</p>
                      </div>
                      <div style={styles.iconbox} onClick={() => { history.push(`/admin/events`) }}>
                        <Image style={styles.iconimage} src={eventIcon} />
                        <p style={styles.iconboxtext}>{t('Events')}</p>
                      </div>
                      <div style={styles.iconbox} onClick={() => { history.push(`/admin/coming-soon`) }}>
                        <Image style={styles.iconimage} src={occupancyPlanIcon} />
                        <p style={styles.iconboxtext}>{t('Occupancy Plan')}</p>
                      </div>
                      <div style={styles.iconbox} onClick={() => { history.push(`/admin/stats`) }}>
                        <Image style={styles.iconimage} src={controllingIcon} />
                        <p style={styles.iconboxtext}>{t('Controlling')}</p>
                      </div>
                      <div style={styles.iconbox} onClick={() => { history.push(`/admin/invoices`) }}>
                        <Image style={styles.iconimage} src={squareCurrencyIcon} />
                        <p style={styles.iconboxtext}>{t('Invoices')}</p>
                      </div>
                      <div style={styles.iconbox} onClick={() => { history.push(`/admin/users`) }}>
                        <Image style={styles.iconimage} src={userIcon} />
                        <p style={styles.iconboxtext}>{t('Users')}</p>
                      </div>
                      <div style={styles.iconbox} onClick={() => { history.push(`/admin/questionnaire`) }}>
                        <Image style={styles.iconimage} src={questionnaireIcon} />
                        <p style={styles.iconboxtext}>{t('Fragebogen')}</p>
                      </div>
                      <div style={styles.iconbox} onClick={() => { history.push(`/admin/plans`) }}>
                        <Image style={styles.iconimage} src={organizationPlanIcon} />
                        <p style={styles.iconboxtext}>{t('Organization Plan')}</p>
                      </div>
                      <div style={styles.iconbox} onClick={() => { history.push(`/admin/coming-soon`) }}>
                        <Image style={styles.iconimage} src={partnersIcon} />
                        <p style={styles.iconboxtext}>{t('partners')}</p>
                      </div>
                      {/* </CarouselSection> */}
                    </div>
                  </Col>
                </Row>
              </StickyDiv>
              <div className="mb-2">
                <Row className="mt-2 mb-3">
                  <Col md={12}>
                    <CustomizeDashboard>
                      <CustomBtn button={Button} size="sm" onClick={() => setCustomizeDashboard(!customizeDashboard)}>
                        <small style={{ verticalAlign: 'text-bottom' }}>{t('Customize Dashboard')}</small>
                      </CustomBtn>
                    </CustomizeDashboard>
                  </Col>
                </Row>
                {widgets.length > 0 && widgets.map((detail, key) => (
                  <DragDropContext key={key} onDragEnd={(result) => onDragEnd(result, key)}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                        >
                          {detail.size === 4 ?
                            <Row key={key} className={key !== 0 ? 'mt-3' : ''}>
                              {detail.widget.length > 0 && detail.widget.map((widgetInfo, k) => (
                                <Draggable key={`${detail._id}_${k}`} draggableId={`${detail._id}_${k}`} index={k} isDragDisabled={customizeDashboard ? false : true}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style,
                                        '25'
                                      )}
                                    >
                                      {widgetInfo.widgetType === 3 ?
                                        <Col md={12} key={k}
                                        >
                                          <div style={styles.boxfour} key={k}>
                                            <p className="m-0" style={styles.boxIconCross}>
                                              {customizeDashboard &&
                                                <>
                                                  <Image style={styles.crossimage} src={settingIcon} onClick={() => handleWidgetClick(detail, widgetInfo.sizeRow)} />
                                                  {/* <Image style={styles.crossimage} src={crossSmallIcon} /> */}
                                                </>
                                              }
                                            </p>
                                            <OverlayTrigger
                                                key="bottom"
                                                placement="bottom"
                                                variant="light"
                                                overlay={
                                                  <Tooltip id={`tooltip-disabled`}>
                                                    <StyleUl>
                                                    { widgetInfo.allSelected ?
                                                      <li key={k}>{t('all')}</li>
                                                      :
                                                      widgetInfo.data && widgetInfo.data.headlineText.split(',').map((v, k) => (
                                                        <li key={k}>{v}</li>
                                                    ))}
                                                    </StyleUl>
                                                  </Tooltip>
                                                }
                                            >
                                              <h2 style={styles.numerictext}>{widgetInfo.data && widgetInfo.data.totalCount ? widgetInfo.data.totalCount + (widgetInfo.data.textToDisplay.toLowerCase().includes("revenue") ? ' ' + widgetInfo.data.currency : '') : 0}</h2>
                                            </OverlayTrigger>
                                            <p style={styles.boxxtext}>
                                              {widgetInfo.data && widgetInfo.data.textToDisplay ? t(widgetInfo.data.textToDisplay) : t('')}
                                              <br/>
                                              { widgetInfo.data && widgetInfo.data.timeFrame &&
                                                <small style={styles.boxpSmalltext}>{t(widgetInfo.data.timeFrame)}</small>
                                              }
                                            </p>
                                          </div>
                                          {customizeDashboard &&
                                            <p className={scroll ? "box_icon_drag_scroll" : "box_icon_drag"} >
                                              <Image src={dragDropIcon} />
                                            </p>
                                          }
                                        </Col>
                                        :
                                        <Col md={12} key={k}>
                                          <div style={styles.boxfour} key={k}>
                                            <p className="m-0" style={styles.boxIconCross}>
                                              {customizeDashboard &&
                                                <>
                                                  <Image style={styles.crossimage} src={settingIcon} onClick={() => handleWidgetClick(detail, widgetInfo.sizeRow)} />
                                                  {/* <Image style={styles.crossimage} src={crossSmallIcon} /> */}
                                                </>
                                              }
                                            </p>
                                            <p style={styles.box_p_text}>{widgetInfo.data && widgetInfo.data.textToDisplay ? t(widgetInfo.data.textToDisplay) : ''} {widgetInfo.data && widgetInfo.data.timeFrame && '('+t(widgetInfo.data.timeFrame)+')'}</p>
                                            {widgetInfo && widgetInfo.data && widgetInfo.data.chartData ?
                                              <StyleHighChart>
                                                <HighchartsReact
                                                  highcharts={Highcharts}
                                                  options={getChartSmSize(widgetInfo)}
                                                />
                                              </StyleHighChart>
                                              : <StyleNoDataSM>
                                                <p style={styles.boxxtext}>{t('No Data Found')}</p>
                                              </StyleNoDataSM>
                                            }
                                          </div>
                                          {customizeDashboard &&
                                            <p className={scroll ? "box_icon_drag_scroll" : "box_icon_drag"} >
                                              <Image src={dragDropIcon} />
                                            </p>
                                          }
                                        </Col>
                                      }
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            </Row>
                            :
                            <Row key={key} className={key !== 0 ? 'mt-3' : ''}>
                              {detail.widget.length > 0 && detail.widget.map((widgetInfo, k) => (
                                <Draggable key={`${detail._id}_${k}`} draggableId={`${detail._id}_${k}`} index={k} isDragDisabled={customizeDashboard ? false : true}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style,
                                        '50'
                                      )}
                                    >
                                      <>
                                      {widgetInfo.widgetType === 2 ?
                                        <Col md={12}>
                                          <div style={styles.mainbox} className="p-2" key={k}>
                                            <p className="m-0" style={styles.boxIconCross}>
                                              {customizeDashboard &&
                                                <>
                                                  <Image style={styles.crossimage} src={settingIcon} onClick={() => handleWidgetClick(detail, widgetInfo.sizeRow)} />
                                                  {/* <Image style={styles.crossimage} src={crossSmallIcon} /> */}
                                                </>
                                              }
                                            </p>
                                            <p style={styles.boxheading}><b>{t(widgetInfo.data && widgetInfo.data[widgetInfo.data.textToDisplay] ? t(translationMapping[widgetInfo.data[widgetInfo.data.textToDisplay].title]) : '')}</b></p>
                                            <EventTblSection key={k}>
                                              <TableSection style={styles.tablebox}
                                                headers={
                                                  widgetInfo.data &&widgetInfo.data[widgetInfo.data.textToDisplay] ? 
                                                    widgetInfo.data.textToDisplay === 'invoiceData' ? tblInvoiceHeader : (widgetInfo.data.textToDisplay === 'questionnaireData' ? tblQuestionaireHeader : tblEventHeader)
                                                  : []
                                                }
                                                data={widgetInfo.data && widgetInfo.data[widgetInfo.data.textToDisplay] ? widgetInfo.data[widgetInfo.data.textToDisplay].data : []}
                                                buttons={[]}
                                                buttonCount={0}
                                              />
                                            </EventTblSection>
                                            {customizeDashboard &&
                                              <p className={scroll ? "box_icon_drag_scroll" : "box_icon_drag"} >
                                                <Image src={dragDropIcon} />
                                              </p>
                                            }
                                          </div>
                                        </Col> :
                                        <Col md={12} key={k}>
                                          <div style={styles.mainbox} className="p-2" key={k}>
                                            <p className="m-0" style={styles.boxIconCross}>
                                              {customizeDashboard &&
                                                <>
                                                  <Image style={styles.crossimage} src={settingIcon} onClick={() => handleWidgetClick(detail, widgetInfo.sizeRow)} />
                                                  {/* <Image style={styles.crossimage} src={crossSmallIcon} /> */}
                                                </>
                                              }
                                            </p>
                                            <p style={styles.boxheading}><b>{widgetInfo.data && widgetInfo.data.textToDisplay && t(widgetInfo.data.textToDisplay)} {widgetInfo.data && widgetInfo.data.timeFrame && '('+t(widgetInfo.data.timeFrame)+')'}</b></p>
                                            <ControllingSection>
                                              {widgetInfo && widgetInfo.data && widgetInfo.data.chartData ?
                                                <StyleHighChart>
                                                  <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={getChartLgSize(widgetInfo)}
                                                  />
                                                </StyleHighChart>
                                                : <StyleNoData>
                                                  <p style={styles.boxxtext}>{t('No Data Found')}</p>
                                                </StyleNoData>
                                              }
                                            </ControllingSection>
                                            {customizeDashboard &&
                                              <p className={scroll ? "box_icon_drag_scroll" : "box_icon_drag"} >
                                                <Image src={dragDropIcon} />
                                              </p>
                                            }
                                          </div>
                                        </Col>
                                      }
                                    </>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            </Row>
                          }
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ))}
              </div>
              {customizeDashboard &&
                <div>
                  <CreateWidget widgetDetail={widgetDetail} setWidgetDetail={(e) => setWidgetDetail(e)} setCallWidgetCnt={(e) => setCallWidgetCnt(e)} callWidgetCnt={callWidgetCnt} selectedWidgetItem={selectedWidgetItem} setSelectedWidgetItem={(e) => setSelectedWidgetItem(e)} />
                </div>
              }
            </div>
          </Col>
        </Row>
        <div style={styles.bottomicon}>
          <p className="mb-2" style={styles.pointer} onClick={() => { history.push(`/admin/settings-new`) }}><Image src={settingIcon} /> </p>
          <p className="mb-2" style={styles.pointer} onClick={() => { handleLogout() }}><Image src={logoutIcon} /> </p>
        </div>
      </ContainerSection>
    </React.Fragment>
  )
}

export default DashboardPage
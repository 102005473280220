import React, { Component } from 'react';
import { StateContext } from '../../context/state';
import axios from 'axios';
import { Carousel } from 'react-bootstrap';
import { baseUrl } from '../../Constants.js';
// const d={
// type:"bild",
// bildInput:[
//     {
//         url:"url"
//     }
// ]
// }
class BildOption extends Component {
	static contextType = StateContext;
	constructor(props) {
		super(props);
		this.state = {
			newData: {},
		};
	}
	componentDidMount() {
		const option = this.props.option;

		// console.log('api', `${baseUrl}api/export/ServiceElement/${this.context.questionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`)
		axios
			.get(
				`${baseUrl}api/export/ServiceElement/${this.props.questionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`
			)
			.then((res) => {
				const d = res.data;
				// console.log('res.data',d)
				this.setState({
					newData: d,
				});
				this.props.updateChange(d, this.props.divId);
			})
			.catch((err) => {
				console.log(err);
			});
	}
	showLinkOptions = () => {
		this.props.handleLinking(this.state.newData);
	};
	render() {
		let image =
			this.state.newData?.bildInput?.image || 'http://placehold.jp/300x300.png';

		return (
			// this.state.newData?.bildInput.image.map((img)=>{
			// return <img src={image?.url} style={{...styles.image, backgroundImage: image}} alt="" />
			// })
			<div style={{ paddingTop: '30px' }}>
				{/* <img src={linkIcon} alt="" style={styles.link} onClick={this.showLinkOptions}/> */}
				<Carousel>
					{Array.isArray(image) &&
						image.map((i) => {
							return (
								<Carousel.Item>
									<img
										className="d-block w-100"
										// style={{...styles.image}}
										src={i?.url}
										alt="First slide"
									/>
									<Carousel.Caption>
										{i?.title?.length ? (
											<div
												style={{
													background: 'white',
													color: 'black',
													padding: '10px',
												}}>
												<h3>{i?.title}</h3>
												<p>{i?.caption}</p>
											</div>
										) : null}
									</Carousel.Caption>
								</Carousel.Item>
							);
						})}
				</Carousel>
			</div>
		);
	}
}

export default BildOption;

import React, { Component } from "react";
import crossLight from "../../assets/cross-light.svg";
import { StateContext } from "../../context/state";
import axios from "axios";
import { baseUrl } from "../../Constants.js";
import { withTranslation} from 'react-i18next';

class RadioOption extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      newData: {},
      selectedOptions:[],
      questionSelected:false,
    };
  }
  componentDidMount() {
    if(Object.keys(this.props.divData).length){
      if(!this.props.divData?.radioInput?.multipleSelection){
        this.setState({
            data:this.props.divData,
            newData:this.props.divData,
            selectedOption:[]
        })
      }
       else{
        this.apiCall()
       }
        // this.props.updateChange()
        // console.log("divdata",this.props.divData)
    }
    else{
      this.apiCall()
    }
   
  }
apiCall=()=>{
  const option = this.props.option;
  axios
  .get(
    `${baseUrl}api/export/ServiceElement/${this.props.questionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`
  )
  .then((res) => {
    const d = res.data;
    // d.selectedOptions=[]
   
    d.radioInput.questionSelected=this.props.divData?.radioInput?.questionSelected ? this.props.divData?.radioInput?.questionSelected:false
  
    const dd = d.radioInput.inputs.map((input,index) => {
      input.alternate = input.alternate? input.alternate:this.props.divData?.radioInput?.inputs[index].alternate
      input.marked = false;
      input.showMark = false;
      return { ...input };
    });
    d.radioInput.inputs = dd;
    d.radioInput.qtnAlternate = d.radioInput.qtnAlternate ?d.radioInput.qtnAlternate: this.props.divData?.radioInput?.qtnAlternate;
    this.setState({
      data: d,
      newData: { ...this.state.newData, ...d },
    });
    // console.log('d', {...d})
    this.props.updateChange({ ...d },this.props.divId);
  })
  .catch((err) => {
    console.log(err);
  });
 

}
  handleChangeQtnAlt = (e) => {
    const newData = Object.assign({}, this.state.newData);

    newData.radioInput.qtnAlternate = e.target.value;
    this.setState({
      newData,
    });
    this.props.updateChange(newData,this.props.divId);
  };
  handleChangeOptionAlt = (id, e) => {
    const dummy = Object.assign({}, this.state.newData);
    dummy.radioInput.inputs.map((input) => {
      if (input.id === id) {
        return (input.alternate = e.target.value);
      } else {
        return input;
      }
    });
    this.setState({
      newData: dummy,
    })
    this.props.updateChange(dummy,this.props.divId);
  };

  clickedOption = (option, i) => {
 
    const dummy = Object.assign({}, this.state.newData);
   
    let array = dummy.selectedOptions ? dummy.selectedOptions: []
    const found=dummy?.selectedOptions?.find((op)=>{
      return op.id===option.id
    })
    if(!found){
      array.push(option);
    }
    // array.push(option);
   
    dummy.selectedOptions=array
    dummy.radioInput.inputs[i].showMark=true
    // console.log("array",array)
    this.setState({
      newData: dummy,
      selectedOptions: array,
    });

    this.props.updateChange(dummy,this.props.divId);

  };
  markQuestion=(e,question)=>{
  
    // console.log("e",e.target.value,"question",question)
    const dummy = Object.assign({}, this.state.newData);
   if(question===dummy.radioInput.question){
    dummy.radioInput.questionSelected=true
   }
  //  console.log("dummy",dummy)
    this.setState({newData:dummy})
    // console.log("divid",this.props.divClicked)
    this.props.updateChange(dummy,this.props.divClicked);
  }
  removeMarkedQuestion=(question)=>{
    // console.log("divid",this.props.divClicked)
    const dummy = Object.assign({}, this.state.newData);
    if(question===dummy.radioInput.question){
      dummy.radioInput.questionSelected=false
     }
   
    this.setState({newData:dummy})
    this.props.updateChange(dummy,this.props.divClicked,this.props.themeId);
  }
  handleMark = (id) => {
    const dummy = Object.assign({}, this.state.newData);
    dummy.radioInput.inputs.map((input) => {
      if (input.id === id) {
        if (input.marked) {
          input.showMark = false;
          return (input.marked = !input.marked);
        } else {
          return (input.marked = !input.marked);
        }
      } else {
        return input;
      }
    });
    this.setState({
      newData: dummy,
    });
    this.props.updateChange(dummy,this.props.divId);
  };
  showLinkOptions = () => {
    this.props.handleLinking(this.state.data);
  };

  selectedOption = (option,e) => {};

  handleRemoveSelectedOption = (e, option,i) => {
     e.stopPropagation()
    
    const dummy = Object.assign({}, this.state.newData);
    dummy.radioInput.inputs[i].showMark=false
    // console.log("dummy",dummy.radioInput.inputs)
    // console.log("selectedoption",dummy.selectedOptions)
    let array = dummy.selectedOptions ? dummy.selectedOptions:[]
    const newArr=array?.filter((op)=>{
      return op.id!==option.id
    })
    // console.log("array",newArr)
    dummy.selectedOptions = newArr
    this.setState({
      newData: dummy,
      selectedOptions:newArr
    },()=>{
      // console.log("newData",this.state.newData)
    });
    // console.log("dummy",dummy)
    this.props.updateChange(dummy,this.props.divId,this.props.themeId);
  
  };
  render() {
  //  console.log("render",this.state.newData)
    return (
      <div>
        {/* <img src={linkIcon} alt="" style={styles.link} onClick={this.showLinkOptions}/> */}
        <div className="qtn_optns">
          {this.state.newData.radioInput?.inputs && (
            <>
            {/* {console.log("this.state.newData.radioInput?.inputs ",this.state.newData )} */}
        
              <div 
               style={{
                background:
                this.state.newData.radioInput.questionSelected  ? "#939598" : "#e6e7e8",
              }}
              className="quest1"
               onClick={(e)=>{this.markQuestion(e,this.state.newData.radioInput?.question)}}>
                <b>
                  <i>{this.state.newData.radioInput?.question}</i>
                </b>
                
              </div>
              <img
                      src={crossLight}
                      alt=""
                      style={{
                       float:"right",
                       width:"10px",
                       position:"relative",
                       right:"15px",
                      //  paddingTop:"5px",
                      bottom:"8px",
                       display:!this.state.newData.radioInput.questionSelected && "none"
                      }}
                      onClick={
                        ()=>this.removeMarkedQuestion(this.state.newData.radioInput.question)
                      }
                    />
                    {/* {console.log("input alt",this.state.newData?.radioInput)} */}
              <input
             style={{
              background:
              this.state.newData.radioInput.questionSelected  ? "#939598" : "#e6e7e8",
            }}
                type="text"
                className="inp1"
                placeholder={this.props.t("Ersatztext")}
                value={this.state.newData?.radioInput?.qtnAlternate}
                onChange={(e) => {
                  this.handleChangeQtnAlt(e);
                }}
              />
            </>
          )}
        </div>

        <div>
          {this.state.newData?.radioInput?.inputs &&
            this.state.newData?.radioInput?.inputs.map((option, i) => {
              return (
                <div className="qtn_optns" key={i}>
                  {option.id === this.state.newData?.answer?.id ? (
                    <span style={styles.filledCircle} />
                  ) : (
                    <span style={styles.circle} />
                  )}
               
                  <>
                    <div
                      className="optn"
                      onClick={() => {
                        this.clickedOption(option, i);
                      }}
                      style={{
                        background:
                          option.showMark
                            ? "#939598"
                            : "#e6e7e8",
                            width:"46.4%"
                      }}
                    >
                       <img
                      src={crossLight}
                      alt=""
                      style={{
                       float:"right",
                       width:"10px",
                       display:!option.showMark && "none"
                      }}
                      onClick={(e) => {
                        this.handleRemoveSelectedOption(e, option, i);
                      }}
                    />
                      {option.value}
                    </div>
                    {/* <div style={{backgroundColor:"#e6e7e8"}}>
                                    {
                                        option.showMark || option.marked? <img src={closeIcon} onClick={()=>{this.handleMark(option.id)}} className="close_icon" alt=""/> :null
                                    }
                                    </div> */}
                  </>
                  <div className="optn">
                    <input
                      style={{
                        background:
                         option.showMark ? "#939598" : "#e6e7e8",
                      }}
                      type="text"
                      className="inp"
                      placeholder={this.props.t("Ersatztext")}
                      value={option.alternate}
                      onChange={(e) => {
                        this.handleChangeOptionAlt(option.id, e);
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default withTranslation() (RadioOption);
const styles = {
  circle: {
    marginTop: "7px",
    width: "16px",
    height: "16px",
    display: "inline-block",
    textAlign: "center",
    background: "transparent",
    borderRadius: "50%",
    border: "1px solid #939598",
    outline: "none",
  },
  filledCircle: {
    marginTop: "7px",
    width: "16px",
    height: "16px",
    display: "inline-block",
    textAlign: "center",
    background: "transparent",
    borderRadius: "50%",
    backgroundColor: "#939598",
    border: "1px solid #939598",
    outline: "none",
  },
  link: {
    background: "#e6e7e8",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "90%",
  },
  selectedOptionColor: {
    backgroundColor: "#e6e7e8",
  },
};

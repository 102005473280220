import axios from "axios";
import { baseUrl } from "../Constants";
import moment from "moment";
import "moment-timezone";

const JSON_HEADER = { headers: { "Content-Type": "application/json" } };
const MULTI_PART_HEADER = { headers: { 'Content-Type': 'multipart/form-data' } };

const dataHandler = (promise) => promise.then(res => res?.data).catch(errorHandler);

const errorHandler = (err) => {
    const message = err?.message;
    return Promise.reject(message ? typeof message != 'string' ? JSON.stringify(message) : message : JSON.stringify(err))
};

class HttpService {

    baseURL = process.env.REACT_APP_WEDDINGCONFIG_API + "api/";

    weatherUrl = 'https://api.openweathermap.org/data/2.5/onecall';

    getThemes = (questionnaireId) => dataHandler(axios.get(this.baseURL + `themes/${questionnaireId}`));

    getGuests = (questionnaireId) => dataHandler(axios.get(this.baseURL + `guest/${questionnaireId}`));

    getHeaderImage = (questionnaireId) => dataHandler(axios.get(this.baseURL + `headerImage/${questionnaireId}`));

    putGuests = (questionnaireId, data) => dataHandler(axios.put(this.baseURL + `guest/${questionnaireId}`, { ...data, updatedDate: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"), updatedBy: JSON.parse(sessionStorage.getItem("USER"))?.loggedInUserId }, JSON_HEADER));

    postGuests = (data) => dataHandler(axios.post(this.baseURL + `guest`, { ...data, updatedDate: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"), updatedBy: JSON.parse(sessionStorage.getItem("USER"))?.loggedInUserId }, JSON_HEADER));

    getForms = (questionnaireId, themeId, serviceId) => dataHandler(axios.get(this.baseURL + `questions/${questionnaireId}/${themeId}/${serviceId}`));

    //  getFormsNotSaved = (questionnaireId, themeId, serviceId) => dataHandler(axios.get(this.baseURL + `questions/${questionnaireId}/${themeId}/${serviceId}/isNotSaved`));
    uploadFile = (imageFile, options) => {
        const data = new FormData();
        data.append("blob", imageFile);
        return dataHandler(axios.post(this.baseURL + `blob/${2}`, data, options, MULTI_PART_HEADER))
    };
   createPdf= (data,id,venueId) =>dataHandler(axios.post(this.baseURL + `invoice/generatePdf/${id}/${venueId}`, data, JSON_HEADER));
   createPricePdf=(venueId,questionnaireId) =>dataHandler(axios.post(this.baseURL + `invoice/price/${venueId}/${questionnaireId}`, JSON_HEADER));

    uploadAnswer = (questionnaireId, themeId, serviceId, userId, data) => dataHandler(axios.put(this.baseURL + `questions/${questionnaireId}/${themeId}/${serviceId}/${userId}`, {...data, answeringDate:moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")}, JSON_HEADER));
    getChangeLog = (eventId) => dataHandler(axios.get(this.baseURL + `log/${eventId}`));
    uploadChangeLog = (changeLog) => dataHandler(axios.post(this.baseURL + `log`, changeLog, JSON_HEADER));


    getSummary = (eventId, questionnaireId) => dataHandler(axios.get(this.baseURL + `pricing/${questionnaireId}`));
    //new api for updating price
    uploadPrice = (questionnaireId) => dataHandler(axios.put(this.baseURL + `pricing/${questionnaireId}`, {}, JSON_HEADER));
    themePriceUpdate = (questionnaireId) => dataHandler(axios.put(this.baseURL + `themes/update/${questionnaireId}`, {}, JSON_HEADER));
    invoiceTable = (eventId) => dataHandler(axios.get(this.baseURL + `invoice/${eventId}`));

    getVenueLogo = (venueId) => dataHandler(axios.get(this.baseURL + `venue/${venueId}`));

    fetchEventDetails = (eventId, userRole) => dataHandler(axios.get(this.baseURL + `user/ByEvent/${eventId}/${userRole}`));

    fetchTotalAmount = (questionnaireId) => dataHandler(axios.get(`${baseUrl}api/pricing/totalAmount/${questionnaireId}`));

    /* ADMIN EVENT MODULE */

    getEvent = () => dataHandler(axios.get(this.baseURL + `event`));

    createEvent = (event) => dataHandler(axios.post(this.baseURL + `event`, event, JSON_HEADER));

    updateEvent = (event) => dataHandler(axios.put(this.baseURL + `event/${event._id}`, event, JSON_HEADER));

    getEvents = (venueId, userId, userRole) => dataHandler(axios.get(this.baseURL + `event/GetEventByUserRole/${venueId}/${userId}/${userRole}?`));

    getContactPersons = (venueId) => dataHandler(axios.get(this.baseURL + `user/GetUserByUserRole/CU/${venueId}`));

    getVVOs = (venueId) => dataHandler(axios.get(this.baseURL + `user/GetUserByUserRole/VV/${venueId}`));

    getVVOsOnEventsPage = (venueId) => dataHandler(axios.get(this.baseURL + `user/GetUserByUserRole/all/${venueId}`));
    getQuestionnaires = (venueId) => dataHandler(axios.get(this.baseURL + `quest/GetQuestionnaireByVenue/${venueId}`));

    fetchEventPlans = (eventId) => dataHandler(axios.get(this.baseURL + `plan/byEvent/${eventId}`));
    // http://testapi.the-eventcloud.com/api/export/list/5f7ee0e29f81711704a495aa
    fetchQuestionnairePlans = (questionnaireId) => dataHandler(axios.get(this.baseURL + `export/list/${questionnaireId}`))
    fetchEventQuestionnaire = (eventId) => dataHandler(axios.get(this.baseURL + `plan/questionnaireByEvent/${eventId}`));

    /* ADMIN USER MODULE */

    getUserRoles = (venueId) => dataHandler(axios.get(this.baseURL + `lookup/roles/${venueId}`));

    getUser = (userId) => dataHandler(axios.get(this.baseURL + `user/${userId}`));

    getUserLanguages = () => dataHandler(axios.get(this.baseURL + `venue/languages/`));

    getVenue = (venueId) => dataHandler(axios.get(this.baseURL + `venue/${venueId}`));

    createUser = (user) => dataHandler(axios.post(this.baseURL + `user`, user, JSON_HEADER));

    updateUser = (user) => dataHandler(axios.put(this.baseURL + `user/${user._id}`, user, JSON_HEADER));

    getVenues = () => dataHandler(axios.get(this.baseURL + `venue/getAll`));

    getUsers = (venueId, userRole) => dataHandler(axios.get(this.baseURL + `user/UserListing/${venueId}/${userRole}?`));
    getUsersByUserRole = (venueId, userRole) => dataHandler(axios.get(this.baseURL + `user/GetUserByUserRole/${userRole}/${venueId}?`));

    /* ADMIN QUESTIONNAIRE MODULE */
    fetchQuestionnaireInSuperAdmin = () => dataHandler(axios.get(this.baseURL + `quest/GetQuestionnaireBySA`));
    fetchQuestionnaires = (venueId) => dataHandler(axios.get(this.baseURL + `quest/GetQuestionnaireByVenue/${venueId}?`));

    fetchParentQuestionnaires = (venueId) => dataHandler(axios.get(this.baseURL + `quest/parent/${venueId}?`));

    createQuestionnaire = (quest) => dataHandler(axios.post(this.baseURL + `quest`, quest, JSON_HEADER));

    updateQuestionnaire = (quest) => dataHandler(axios.put(this.baseURL + `quest/${quest._id}`, quest, JSON_HEADER));

    deleteQuestionnaire = (quest) => dataHandler(axios.delete(this.baseURL + `quest/${quest._id}`));
    deleteEvent = (event) => dataHandler(axios.delete(this.baseURL + `event/deleteEvent/${event._id}`));

    cloneQuestionnaire = (quest, creationDate, copyOrgPlan, name) => dataHandler(axios.get(this.baseURL + `quest/clone/${quest._id}/${creationDate}/${copyOrgPlan}/${name}`));

    /* ADMIN PLAN MODULE */

    fetchPlans = (venueId, userId, userRole) => dataHandler(axios.get(this.baseURL + `plan/byVenue/${venueId}/${userId}/${userRole}?`));

    createPlan = (plan) => dataHandler(axios.post(this.baseURL + `plan`, plan, JSON_HEADER));

    updatePlan = (plan) => dataHandler(axios.put(this.baseURL + `plan/${plan._id}`, plan, JSON_HEADER));

    deletePlan = (plan) => dataHandler(axios.delete(this.baseURL + `plan/${plan._id}`));
    deleteUser = (user) => dataHandler(axios.delete(this.baseURL + `user/${user.userId}`));
    clonePlan = (plan, creationDate) => dataHandler(axios.get(this.baseURL + `plan/clone/${plan._id}/${creationDate}`));

    /* ADMIN VENUE MODULE */

    fetchVenues = () => dataHandler(axios.get(this.baseURL + `venue/details`));

    deleteVenue = (venue) => dataHandler(axios.delete(this.baseURL + `venue/${venue._id}`));

    fetchVenue = (venueId) => dataHandler(axios.get(this.baseURL + `venue/${venueId}`));

    /* ADMIN KEY FIGURES */

    fetchKeyFigures = () => {
        return axios.get(this.baseURL + `keyFig/list?`)
            .then(res => {
                return Promise.resolve(res?.data?.keyFig ?? []);
            })
            .catch(errorHandler)
    };

    fetchUserKeyFigures = (userId) => dataHandler(axios.get(this.baseURL + `userKeyFig/${userId}/?`));

    saveUserKeyFigures = (body) => dataHandler(axios.post(this.baseURL + `userKeyFig/?`, body, JSON_HEADER));

    updateUserKeyFigures = (body, userId) => dataHandler(axios.put(this.baseURL + `userKeyFig/${userId}/?`, body, JSON_HEADER));

    fetchKeyFigure = (body) => dataHandler(axios.post(this.baseURL + `keyFig/getCountByDate`, body, JSON_HEADER));

    sendPlanPreviewEmail = (body) => dataHandler(axios.post(this.baseURL + `export/sendEmail`, body, JSON_HEADER));
    
    /*  EXPORT MODULE */

    getExportElements = (id) => {
        return axios.get(this.baseURL + `export/elements/${id}`)
            .then((res) => {
                return Promise.resolve(res?.data)
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
    getExportThemes = (parentQuestionnaireId, childQuestionnaireId, exportPlanId) => {
        return axios.get(this.baseURL + `export/${parentQuestionnaireId}/${childQuestionnaireId}/${exportPlanId}`)
            .then((res) => {
                return Promise.resolve(res?.data)
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
    getAllExportElements = (id) => {
        return axios.get(this.baseURL + `export/elementsall/${id}`)
            .then((res) => {
                return Promise.resolve(res?.data)
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }

    saveExportPlan = (data) => {
        return axios.post(this.baseURL + `export`, data)
            .then((res) => {
                return Promise.resolve(res?.data)
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
    updateExportPlan = (id, data) => {
        return axios.put(this.baseURL + `export/${id}`, data)
            .then((res) => {
                return Promise.resolve(res?.data)
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
    updateExportPlanWithEvent = (id, eventId, data) => {
        return axios.post(this.baseURL + `export/${id}/${eventId}`, data)
            .then((res) => {
                return Promise.resolve(res?.data)
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
    getPlanDetails = (id) => {
        return axios.get(this.baseURL + `plan/${id}`)
            .then((res) => {
                return Promise.resolve(res?.data)
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }

    getExportPlan = (questionnaireId, exportPlanId) => {
        return axios.get(this.baseURL + `export/${questionnaireId}/${exportPlanId}`)
            .then((res) => {
                return Promise.resolve(res?.data)
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
    getExportPlanWithChildQuestionnaire = (questionnaireId, childQuestionnaireId, exportPlanId, eventId) => {
        return axios.get(this.baseURL + `export/${questionnaireId}/${childQuestionnaireId}/${exportPlanId}/${eventId}`)
            .then((res) => {
                return Promise.resolve(res?.data)
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }

    getServiceElement = (questionnaireId, option) => {
        return axios.get(`${this.baseURL}export/ServiceElement/${questionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`)
            .then((res) => {
                return Promise.resolve(res?.data)
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }


    // --- builder---
    ifIsSaved = (questionnaireId, templateId, stepId) => {
        // console.log('api', `${this.baseURL}Questions/${questionnaireId}/${templateId}/${stepId}`)
        return axios.get(
            `${this.baseURL}Questions/${questionnaireId}/${templateId}/${stepId}`
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }
    postBuilderQtn = (postData) => {
        //   console.log('post qtn')
        // console.log('api', `${this.baseURL}Questions/${questionnaireId}/${templateId}/${stepId}`)
        return axios.post(
            `${this.baseURL}Questions`, postData
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }
    getBuilderThemes = (questionnaireId) => {
        return axios.get(this.baseURL + `themes/${questionnaireId}`)
            .then(res => {
                return Promise.resolve(res?.data);
            })
            .catch(error => {
                return Promise.resolve({ message: error });
            });
    };
    putThemes = (questionnaireId, data) => {
        // console.log('putThemes', data)

        return axios.put(this.baseURL + `themes/${questionnaireId}`, data)
            .then(res => {
                return Promise.resolve(res?.data);
            })
            .catch(error => {
                return Promise.resolve({ message: error });
            });
    };
    postThemes = (data) => {
        // console.log('postThemes')
        return axios.post(this.baseURL + `themes`, data)
            .then(res => {
                return Promise.resolve(res?.data);
            })
            .catch(error => {
                return Promise.resolve({ message: error });
            });
    };
    putBuilderQtn = (questionnaireId, templateId, stepId,userId, data) => {
        // console.log('putQtn', data)

        return axios.put(this.baseURL + `Questions/${questionnaireId}/${templateId}/${stepId}/${userId}`, data)
            .then(res => {
                return Promise.resolve(res?.data);
            })
            .catch(error => {
                return Promise.resolve({ message: error });
            });
    };

    //Delete Service
    deleteService = (questionnaireId, themeId, serviceId) => dataHandler(axios.delete(this.baseURL + `questions/${questionnaireId}/${themeId}/${serviceId}`));

    //Get Events
    getEventWelcomePage = (questionnaireId) => dataHandler(axios.get(this.baseURL + `event/Welcome/${questionnaireId}`));
    //Get Questionnaire setting
    getQuestionnaireSetting = (parentQuestionnaireId) => dataHandler(axios.get(this.baseURL + `questionnaireSetting/${parentQuestionnaireId}`));
    //post Events Data

    postEventsPageData = (postData) => {
        return axios.post(
            `${this.baseURL}event/Welcome`, postData
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }
    postInvoiceData= (data)=>{
        return axios.post(this.baseURL + `invoice/data`, data)
        .then(res => {
            return Promise.resolve(res?.data);
        })
        .catch(error => {
            return Promise.resolve({ message: error });
        });
    }
     updateInvoiceData=(data,id)=>{
        return axios.put(this.baseURL + `invoice/data/${id}`, data)
        .then(res => {
            return Promise.resolve(res?.data);
        })
        .catch(error => {
            return Promise.resolve({ message: error });
        });

     }


    //put Events Data
    putEventsPageData = (postData, questionnaireId) => {
        return axios.put(
            `${this.baseURL}event/Welcome/${questionnaireId}`, postData
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }

    getPriceBarLatestData = (questionnaireId) => {
        return axios.get(
            `${this.baseURL}pricing/latest/${questionnaireId}`
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }


    //post qtnnaire settings Data

    postQtnnaireData = (postData) => {
        return axios.post(
            `${this.baseURL}questionnaireSetting`, postData
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }
    //put qtnnaire settings Data
    putQtnnaireData = (postData, questionnaireId) => {
        return axios.put(
            `${this.baseURL}questionnaireSetting/${questionnaireId}`, postData
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }

    //events
    createEventDummy = (event) => {
        return axios.post(
            `${this.baseURL}event`, event
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }

    updateEventDummy = (event) => {
        return axios.put(
            `${this.baseURL}event/${event._id}`, event
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }

    postNewPassword = (data) => {
        return axios.post(
            `${this.baseURL}email/newUserEmail`, data
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }
    putNewPassword = (venueId, data) => {
        return axios.put(
            `${this.baseURL}email/newUserEmail/${venueId}`, data
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }

    postResetPassword = (data) => {
        return axios.post(
            `${this.baseURL}email/resetPasswordEmail`, data
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }
    putResetPassword = (venueId, data) => {
        return axios.put(
            `${this.baseURL}email/resetPasswordEmail/${venueId}`, data
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }
    getqtnnqireListing = (id) => {
        return axios.get(`${this.baseURL}quest/parent/${id}`)
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }
    //site and table

    postSitePlan = (data, venueId) => {
        return axios.post(
            `${this.baseURL}tableSitePlan/plan/venue/${venueId}`, data
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }
    putSitePlan = (data, venueId, sunnyPlan) => {
        return axios.put(
            `${this.baseURL}tableSitePlan/plan/venue/${venueId}/${sunnyPlan}`, data
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }
    getSitePlan = (venueId, sunnyPlan) => dataHandler(axios.get(this.baseURL + `tableSitePlan/plan/venue/${venueId}/${sunnyPlan}`));
    postRoom = (data) => {
        return axios.post(
            `${this.baseURL}tableSitePlan/room`, data
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }
    putRoom = (data) => {
        return axios.put(
            `${this.baseURL}tableSitePlan/room`, data
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }
    putEventPlan = (data, eventId, isSunnyPlan) => {
        return axios.put(
            `${this.baseURL}tableSitePlan/plan/event/${eventId}/${isSunnyPlan}`, data
        )
            .then(res => {
                return Promise.resolve(res?.data)

            })
            .catch(err => {
                return Promise.reject(err)
            });

    }
    getRooms = (venueId) => dataHandler(axios.get(this.baseURL + `tableSitePlan/room/${venueId}`));

    getRoomsEventLevel = (eventId, isSunnyPlan) => dataHandler(axios.get(this.baseURL + `tableSitePlan/plan/event/${eventId}/${isSunnyPlan}`))





    //STATISTICS

    fetchUserTables = (userId) => dataHandler(axios.get(this.baseURL + `statistics/table/${userId}/?`));

    fetchTableDetails = (tableId) => dataHandler(axios.get(this.baseURL + `statistics/${tableId}/?`));

    saveTable = (body) => dataHandler(axios.post(this.baseURL + `statistics/?`, body, JSON_HEADER));

    updateTable = (tableId, body) => dataHandler(axios.put(this.baseURL + `statistics/${tableId}/?`, body, JSON_HEADER));

    deleteTable = (tableId) => dataHandler(axios.delete(this.baseURL + `statistics/${tableId}/?`));

    fetchTableHeaders = () => dataHandler(axios.get(this.baseURL + `lookup/headers/?`));

    fetchTags = (venueId) => dataHandler(axios.get(this.baseURL + `questions/Tag/${venueId}/?`));

    cloneTable = (tableId, tableName) => dataHandler(axios.get(this.baseURL + `statistics/clone/${tableId}/${tableName}/?`));

    fetchHeaderDetails = (body) => dataHandler(axios.post(this.baseURL + `statistics/headerDetails/?`, body, JSON_HEADER));

    //Dashboard Api
    getWeather = (lat, long) => dataHandler(axios.get(this.baseURL + `weather/${lat}/${long}`));
    getWeatherLive = (lat, long) => dataHandler(axios.get(this.weatherUrl + `?lat=${lat}&lon=${long}&exclude=hourly,current,minutely,alerts&appid=c7bf4692d71d0bbcdac8e5fd9484cab6&units=metric&lang=en`));
    updateWeather = (body) => dataHandler(axios.post(this.baseURL + `weather`, body, JSON_HEADER));
    getWidgetType = () => dataHandler(axios.get(this.baseURL + `lookup/widgetType`));
    getVenueSettingDetails = (venueId) => dataHandler(axios.get(this.baseURL + `venue/${venueId}`));
    getWidgetDataType = () => dataHandler(axios.get(this.baseURL + `lookup/widgetDataType`));
    getWidgetTextType = () => dataHandler(axios.get(this.baseURL + `lookup/widgetTextType`));
    getWidgetTimeType = () => dataHandler(axios.get(this.baseURL + `lookup/widgetTimeType`));
    getLanguages = () => dataHandler(axios.get(this.baseURL + `venue/languages`));
    getVenueSettings = () => dataHandler(axios.get(this.baseURL + `lookup/VenueSettingScreen`));
    getCurrency = () => dataHandler(axios.get(this.baseURL + `venue/currencies`));
    createLocation = (body) => dataHandler(axios.post(this.baseURL + `location`, body, JSON_HEADER));
    getLocations = (venueId,  searchString = '', page = 0, countPerPage = 0) => dataHandler(axios.get(this.baseURL + `location?page=${page}&countPerPage=${countPerPage}&searchString=${searchString}&venueId=${venueId}`));
    deleteLocation = (id) => dataHandler(axios.delete(this.baseURL + `location/${id}`));
    getTableTypes = () => dataHandler(axios.get(this.baseURL + `lookup/widgetTableType`));
    //getQuestionnaires = (venueId,  searchString = '', page = 0, countPerPage = 0) => dataHandler(axios.get(this.baseURL + `quest/getQuestionnaireByVenuePagination/${venueId}?page=${page}&countPerPage=${countPerPage}&searchString=${searchString}`));
    createWidget = (body) => dataHandler(axios.post(this.baseURL + `dashboard`, body, JSON_HEADER));
    updateWidget = (id, body) => dataHandler(axios.put(this.baseURL + `dashboard/${id}`, body, JSON_HEADER));
    deleteWidget = (id) => dataHandler(axios.delete(this.baseURL + `dashboard/${id}`));
    getWidget = (userId) => dataHandler(axios.get(this.baseURL + `dashboard/${userId}`));
    getInvoiceList = (venueId, topTen = 1) => dataHandler(axios.get(this.baseURL + `invoice/invoiceList/${venueId}/${topTen}`));
    updateVenue = (venueId, body) => dataHandler(axios.put(this.baseURL + `venue/${venueId}`, body, JSON_HEADER));
    getTemplates = (venueId,  searchString = '', page = 0, countPerPage = 0) => dataHandler(axios.get(this.baseURL + `invoice/template/${venueId}?page=${page}&countPerPage=${countPerPage}&searchString=${searchString}&venueId=${venueId}`));
    createTemplate = (body) => dataHandler(axios.post(this.baseURL + `invoice/template`, body, JSON_HEADER));
    updateTemplate = (body, id) => dataHandler(axios.put(this.baseURL + `invoice/template/${id}`, body, JSON_HEADER));
    cloneTemplate = (id, templateName) => dataHandler(axios.get(this.baseURL + `invoice/template/clone/${id}/${templateName}`));
    deleteTemplate = (id) => dataHandler(axios.delete(this.baseURL + `invoice/template/${id}`));
    getSettingsInvoiceNumber = (venueId) => dataHandler(axios.get(this.baseURL + `venueSetting/invoice/number/${venueId}`));
    createInvoiceNumber = (body) => dataHandler(axios.post(this.baseURL + `venueSetting/invoice/number`, body, JSON_HEADER));
    updateInvoiceNumber = (id, invoiceNo) => dataHandler(axios.put(this.baseURL + `venueSetting/invoice/number/${id}/${invoiceNo}`, {}, JSON_HEADER));
    getGeneralSettings = (venueId, page = 0, countPerPage = 0) => dataHandler(axios.get(this.baseURL + `venueSetting/invoice/general/${venueId}?page=${page}&countPerPage=${countPerPage}`));
    createInvoiceGeneralSetting = (body) => dataHandler(axios.post(this.baseURL + `venueSetting/invoice/general`, body));
    updateInvoiceGeneralSetting = (id, venueId, body) => dataHandler(axios.put(this.baseURL + `venueSetting/invoice/general/${id}/${venueId}`, body));
    deleteInvoice = (invoice) => dataHandler(axios.delete(this.baseURL + `invoice/data/${invoice._id}`));
    getInvoice = (invoice) => dataHandler(axios.get(this.baseURL + `invoice/data/${invoice._id}`));
}



export default new HttpService();

import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Home from "./components/Home";
import Login from "./components/Login";
import SetPassword from './components/newPassword';
import ResetPassword from "./components/resetPassword"
import ForgotPassword from "./components/ForgotPassword";
import EventAdminLanding from "./components/event-admin/Landing";
import EventAdminEventLanding from "./components/event-admin/EventLanding";
import ConfiguratorLanding from "./components/configurator/ConfiguratorLanding";
import EventLanding from "./components/event-admin/EventLanding";
import ChangeLog from "./components/event-admin/ChangeLog";
import { StateContext } from "./context/state";
import QuestionPreview from "./components/screens/QuestionPreview";
import Planner from "./components/screens/PlannerScreen";
import Site from "./components/screens/Site"
import AdminDashboard from "./components/screens/AdminDashboard";
import SettingsMain from './components/settings/settingsMainPage'
import StatisticsMain from "./components/statistics/statisticsMainPage"
import PlanPreview from "./components/plans/planPreview"
import PlanPreviewEmail from "./components/plans/planPreviewEmail"
import GuestsPg from "./components/configurator/guestsPg"
import EventsWelcome from "./components/configurator/eventsWelcomePg"
import PlanParent from "./components/plans/planParent"
import PlanChild from "./components/plans/planChild"
import UserInfoPage from "./components/screens/UserInfoPage";
import Authorization from "./authorization";
import EventsParagraphs from './components/settings/EventsParagraphs';


class App extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = JSON.parse(sessionStorage.getItem("USER")) ?? {
      authToken: "",
      authenticated: false,
      venueId:"",
      venueName:"",
      venueLogo:"",
      loggedInUserId:"",
      userId:"",
      userRole: "couple",
      layoutObject: {},
    };
    this.state.updateState = this.setAppState;
  }
  componentDidMount=()=>{
  
   if(window.location.pathname==="/login"){
    document.title="The-Eventcloud"
   }
   else{
    
     const venue=JSON.parse(sessionStorage.getItem("USER"))?.urlName
   let array=window.location.pathname.split("/")
  
    
    if(venue){
      document.title=venue
    }
    else{
      document.title=array[2]
    }
   }
  }
  setAppState = params => {

    this.setState({...params});
    let item = JSON.parse(sessionStorage.getItem("USER"));
    item ={...item, ...params};

    sessionStorage.setItem("USER", JSON.stringify(item));
  };
  // setAppState = params => this.setState({ ...params });

  setTokens = data => {
    // console.log('token', data)
    localStorage.setItem("token", JSON.stringify(data));
    this.state.updateState({ authToken: data });
  };

  render() {
    return (
      <StateContext.Provider value={{ ...this.state }}>
        <Router>
          {
            <div className="main" style={{ backgroundColor:( window.location.pathname === "/venueSettings")? 'rgba(230, 231, 232, 0.81)' : "", minHeight: "100vh", 
            minWidth:  window.location.pathname === "/admin/userInfo" ?"140vh":""
            }}>

            <PrivateRoute exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/login/:name" component={Login} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/forgot-password/:name" component={ForgotPassword} />
            <Route exact path="/email/planpreview/:parentQuestionnaireId/:childQuestionnaireId/:exportPlanId/:eventId" component={PlanPreviewEmail} />

            {/* <Route exact path="/reset-password/:name" component={ResetPassword} />
            <Route exact path="/set-password/:name" component={SetPassword} /> */}
            
            <Route exact path="/set-password/:name/:email/:code" component={SetPassword}/>
            <Route exact path="/reset-password/:name/:email/:code" component={ResetPassword}/>
            <PrivateRoute exact path="/login-landing" component={EventAdminLanding} />
            <PrivateRoute exact path="/config-elements" component={this.ConfigEle} />
            
            <PrivateRoute
              exact
              path="/event-landing"
              component={EventAdminEventLanding}
            />
            <PrivateRoute
              exact
              path="/configurator-landing"
              component={ConfiguratorLanding}
            />
            <PrivateRoute exact path="/EventLanding" component={EventLanding} />
            <PrivateRoute exact path="/questionnaire-preview" component={QuestionPreview} />
            <PrivateRoute exact path="/planner-screen" component={Planner} /> 
            {/* <PrivateRoute exact path="/export" component={ExportSectionPopup} /> */}


           
            <PrivateRoute exact path="/site" component={Site} />

            <PrivateRoute exact path="/userInfo" component={UserInfoPage} />
            <PrivateRoute path="/admin" component={Authorization(AdminDashboard, ["VA", "SA", "VM", "VV"])} />
             <PrivateRoute exact path="/venueSettings" component={SettingsMain} />
             <PrivateRoute exact path="/statistics" component={StatisticsMain} />
             <PrivateRoute exact path="/planPreview" component={PlanPreview} />
             <PrivateRoute exact path="/guests" component={GuestsPg} />
             <PrivateRoute exact path="/eventsWelcome" component={EventsWelcome} />
             <PrivateRoute exact path="/changeLog" component={ChangeLog} />
             <PrivateRoute exact path="/planParent" component={PlanParent} />
             <PrivateRoute exact path="/planChild" component={PlanChild} />
             <PrivateRoute exact path="/eventsParagraphs" component={EventsParagraphs} />
          </div>
          }
        </Router>
      </StateContext.Provider>
    );
  }
}

export default App;

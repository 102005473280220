import React, { Component } from 'react';
import crossLight from "../../assets/cross-light.svg";
import {StateContext} from "../../context/state";
import axios from 'axios'
import {baseUrl} from '../../Constants.js'
import { withTranslation} from 'react-i18next';

class RadioMultipleOption extends Component {
    static contextType = StateContext;
    constructor(props){
        super(props)
        this.state={
            data:{},
            newData:{
              
            },
            selectedOptions:[],
            checkboxQuestionSelected:false,

        }
    }
    componentDidMount(){       
        if(Object.keys(this.props.divData).length){
            if(this.props.divData?.radioInput?.multipleSelection){
                this.setState({
                    data:this.props.divData,
                    newData:this.props.divData,
                    selectedOption:this.props.divData?.selectedOptions?this.props.divData?.selectedOptions:[]
                })
                // this.props.updateChange()
        
            }
            else{
                this.apiCall()
            }
           
        }
        else{
          this.apiCall()
    }

}
apiCall=()=>{
    const option= this.props.option
       
    axios.get(`${baseUrl}api/export/ServiceElement/${this.props.questionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`)
    .then((res)=>{
        const d=res.data

        const dd=d.radioInput.inputs.map((input,index)=>{
            input.alternate=input.alternate? input.alternate:this.props.divData?.radioInput?.inputs[index]?.alternate
            input.marked=false
            input.showMark=false
            d.radioInput.checkboxQuestionSelected=
            this.props.divData?.radioInput?.checkboxQuestionSelected? 
            this.props.divData?.radioInput?.checkboxQuestionSelected:false
            return {...input}
        })
        d.radioInput.inputs=dd
        d.radioInput.qtnAlternate=d.radioInput?.qtnAlternate ?d.radioInput?.qtnAlternate: this.props.divData?.radioInput?.qtnAlternate;
        this.setState({
            data:d,
            newData:{...this.state.newData, ...d}
        })
        // console.log('d', {...d})
        this.props.updateChange({...d},this.props.divId)

    })
    .catch((err)=>{
        console.log(err)
    })
}

    handleChangeQtnAlt=(e)=>{
        const newData= Object.assign({},this.state.newData)
        // console.log("newData",newData)
        newData.radioInput.qtnAlternate=e.target.value
        this.setState({
           newData
        })
        this.props.updateChange(newData,this.props.divId)
     }
     handleChangeOptionAlt=(id, e)=>{
         const dummy= Object.assign({}, this.state.newData)
         dummy.radioInput.inputs.map((input)=>{
             if(input.id===id){
                return input.alternate=e.target.value
             }
             else{
                return input
             }
         })
         this.setState({
             newData:dummy
         })
         this.props.updateChange(dummy,this.props.divId)
     }
    
     clickedOption=(id)=>{
        const dummy= Object.assign({}, this.state.newData)
        dummy.radioInput.inputs.map((input)=>{
            if(input.id===id){
               return input.showMark=!input.showMark
            }
            else{
               return input
            }
        })
        this.setState({
            newData:dummy
        })         
        this.props.updateChange(dummy,this.props.divId)

         // const copy=Object.assign({}, this.props.question)
         // for(let i of copy.options){
         //     for(let j in i){
         //         // console.log('j', j)
         //         if(j==="option" && i[j]===option){
         //             i["showMark"]=!i["showMark"]
         //         }
         //     }
         // }
         // // this.setState({
         // //     question:copy
         // // })
     }
     markQuestion=(e,question)=>{
  
        // console.log("e",e.target.value,"question",question)
        const dummy = Object.assign({}, this.state.newData);
       if(question===dummy.radioInput.question){
        dummy.radioInput.checkboxQuestionSelected=true
       }
      //  console.log("dummy",dummy)
        this.setState({newData:dummy})
        // console.log("divid",this.props.divClicked)
        this.props.updateChange(dummy,this.props.divClicked);
      }
      removeMarkedQuestion=(question)=>{
        const dummy = Object.assign({}, this.state.newData);
        if(question===dummy.radioInput.question){
          dummy.radioInput.checkboxQuestionSelected=false
         }
       
        this.setState({newData:dummy})
        // console.log("divid",this.props.divClicked)
        this.props.updateChange(dummy,this.props.divClicked,this.props.themeId);
      }
     handleMark=(id)=>{
        //  console.log('mark', id)
         const dummy= Object.assign({}, this.state.newData)
         dummy.radioInput.inputs.map((input)=>{
             if(input.id===id){
                 if(input.marked){
                    input.showMark=false
                    return input.marked=!input.marked
                 }
                 else{
                    return input.marked=!input.marked
                 }
               
             }
             else{
                return input
             }
         })
         this.setState({
             newData:dummy
         })         
         this.props.updateChange(dummy,this.props.divId)
     }
     handleRemoveSelectedOption = (e, option,i) => {
        e.stopPropagation()
       
       const dummy = Object.assign({}, this.state.newData);
       dummy.radioInput.inputs[i].showMark=false
       let array = dummy?.selectedOptions
       const newArr=array?.filter((op)=>{
         return op.id!==option.id
       })
       dummy.selectedOptions = newArr
       this.setState({
         newData: dummy,
         selectedOptions:newArr
       });
       this.props.updateChange(dummy,this.props.divId,this.props.themeId);
     
     };
     showLinkOptions=()=>{
    
        this.props.handleLinking(this.state.data)
     }
    //  findingFunction = () => {
         
    //     const { id, answer } = this.props;
    //     console.log("id",id,"answer",answer)
    //     if (answer?.length) {
    //       const dummy = Object.assign([], answer);
    //       const findingElement = dummy.find((ans) => {
    //         return ans.id === id;
    //       });
    //       if (findingElement) {

    //         return findingElement
    //       } else {
    //         return false;
    //       }
    //     } else {
    //       return false;
    //     }
    //   };
    render() {
        // const selectedStyle = this.findingFunction() ? styles.selected : {};
       
        return (
            <div>
                {/* <img src={linkIcon} alt="" style={styles.link} onClick={this.showLinkOptions}/> */}
               <div className="qtn_optns">
                  { this.state.newData.radioInput?.inputs && 
                  <><div 
                  style={{
                    background:
                    this.state.newData.radioInput.checkboxQuestionSelected  ? "#939598" : "#e6e7e8",
                  }}
                  className="quest1"  
                   onClick={(e)=>{this.markQuestion(e,this.state.newData.radioInput?.question)}}>                     
                    <b><i>{this.state.newData.radioInput?.question}</i></b>
                    </div>
                    <img
                      src={crossLight}
                      alt=""
                      style={{
                       float:"right",
                       width:"10px",
                       position:"relative",
                       right:"15px",
                      //  paddingTop:"5px",
                      bottom:"8px",
                       display:!this.state.newData.radioInput.checkboxQuestionSelected && "none"
                      }}
                      onClick={
                        ()=>this.removeMarkedQuestion(this.state.newData.radioInput.question)
                      }
                    />
                    <input 
                     style={{
                        background:
                        this.state.newData.radioInput.checkboxQuestionSelected  ? "#939598" : "#e6e7e8",
                      }}
                        type="text" 
                        className="inp1" 
                        placeholder={this.props.t("Ersatztext")}
                        value= {this.state.newData.radioInput.qtnAlternate}
                        onChange={(e)=>{this.handleChangeQtnAlt(e)}}
                        autoComplete="off"
                        />
                        </>
                        }
                </div>

                <div>
                    {this.state.newData.radioInput?.inputs &&
                        this.state.newData.radioInput.inputs.map((option,i)=>{
                            return <div className="qtn_optns" key={i}>
                                {
                                        (option.id===this.state.newData?.answer?.id)?
                                        <span style={styles.filledCircle} />
                                        :
                                        <span style={styles.circle} />

                                    }
                                {/* <div className="radio_clicked"></div> */}
                                <>
                                <div className="optn" 
                                style={{
                                    background:
                                      option.showMark
                                        ? "#939598"
                                        : "#e6e7e8",
                                        width:"46.4%"
                                  }}
                                onClick={()=>{this.clickedOption(option.id)}} >
                                     <img
                      src={crossLight}
                      alt=""
                      style={{
                       float:"right",
                       width:"10px",
                       display:!option.showMark && "none"
                      }}
                      onClick={(e) => {
                        this.handleRemoveSelectedOption(e, option, i);
                      }}
                    />
                                    {option.value}
                                </div>
                                    {/* <div style={{backgroundColor:"#e6e7e8"}}>
                                    {
                                        option.showMark || option.marked? <img src={closeIcon} onClick={()=>{this.handleMark(option.id)}} className="close_icon" alt=""/> :null
                                    }
                                    </div> */}
                                </>
                                <div  className="optn">
                                    <input 
                                    style={{
                                        background:
                                         option.showMark ? "#939598" : "#e6e7e8",
                                      }}
                                        type="text" 
                                        className="inp" 
                                        placeholder={this.props.t("Ersatztext")}
                                        value= {option.alternate}
                                        onChange={(e)=>{this.handleChangeOptionAlt(option.id, e)}}
                                        autoComplete="off"
                                        />
                                       
                                </div>
                              
                            </div>
                        })
                    }
                </div>
            </div>
        );
    }
}

export default withTranslation() (RadioMultipleOption);
const styles={
    circle: {
        marginTop:"7px",
        width: "16px",
        height: "16px",
        display: "inline-block",
        textAlign: "center",
        background: "transparent",
        // borderRadius: "12px",
        border: "1px solid #297825",
        outline: "none",
        cursor: "pointer",
      },
    filledCircle: {
        marginTop:"7px",
        width: "16px",
        height: "16px",
        display: "inline-block",
        textAlign: "center",
        background: "transparent",
        backgroundColor:"#939598",
        border: "1px solid #939598",
        outline: "none",
        cursor: "pointer",
    },
    link:{
        background: "#e6e7e8",
        borderRadius: "50%",
        padding: "2px",
        marginLeft: "90%"
    }
}

import React, { Component } from "react";
import { StateContext } from "../../context/state";
import Accordion from "../Accordion";
import "moment-timezone";
import logoutIcon from "../../assets/logout.svg";
import home from "../../assets/home.svg";
import collapseToggleIcon from "../../assets/collapse.svg";

import { withRouter } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { withTranslation} from 'react-i18next';



class RightBarPreview extends Component {
  static contextType = StateContext;
  
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      dateFormat: "",
      vvoDetails: [],
      cuDetails: [],
      venueLogoSrc: "",
      companyLogoUrl: "",
      plannerImageUrl: "",
      plannerEmail: "nadine@hofgut-lilienhof.de",
      plannerPhone: "07668 9965280",
      eventDate: "30/09/2019",
      coupleImageUrl: "",
      coupleName: "Hanna und Gilbert Reichhausen",
      eventLogo: "",
      cachedSomeProp: null
    };
  }
  collapseToggle = () => {
   
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };
  static getDerivedStateFromProps(nextProps, prevState) {
  
  //  console.log("nextProps",nextProps.bool3,prevState,"prevState")
  return {
    cachedSomeProp: nextProps.bool3,
  
  };

  }
  
  componentDidMount = () => {
  
  if (window.innerWidth > 1306) this.setState({ collapsed: false });
    window.addEventListener("resize", () => {
      if (window.innerWidth > 1306) this.setState({ collapsed: false });
      // else  if (this.props.bool3===false) this.setState({ collapsed: false });
      else this.setState({ collapsed: true });
    });

    
  };
  handleLogout = () => {
    const urlName = this.context.urlName;
    this.context.updateState({
      authenticated: false,
      from:"logout"

    });
    localStorage.clear();
    sessionStorage.clear();
    if (urlName === "adminScreen") {
      this.props.history.push(`/login`);
    } else {
      this.props.history.push(`/login/${urlName}`);
    }
  };

  serviceSelect = (service) =>{
    this.props.serviceSelect(service);
    //console.log(service)
    this.setState({ collapsed: true });
  }

  render() {
    // console.log("activeservice",this.props.activeService)
    // console.log("serviceselect",this.props.serviceSelect(this.props?.activeService))
    // if(this.state.cachedSomeProp===false){
    //  return this.state.collapsed=(!this.state.collapsed)
    //   // this.setState({collapsed:false})
    // } 

    let collapseButtonStyle =  {...styles.collapseButton}

    if(this.state.collapsed){
      collapseButtonStyle.transform = 'rotateZ(180deg)'
    }else{
      collapseButtonStyle.transform ='scale(1.05) rotateZ(0)';
      collapseButtonStyle.left ='77px';
      collapseButtonStyle.top = '13px';
    }

   return (
            <div
            style={{background:"rgb(240, 239, 240)",overflow:"auto"}}
              className={`left-bar ${this.state.collapsed ? "collapsed" : ""}`}

            >
              {/* {console.log("bool3",this.state.cachedSomeProp)} */}
              <>
                <OverlayTrigger
                  // key="bottom"
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id={`tooltip-bottom`}
                    >
                      <span>{this.props.t("logout")}</span>
                    </Tooltip>
                  }
                >

                  <button className="logout-btn" onClick={this.handleLogout}>
                    <img src={logoutIcon} alt="" />
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  // key="bottom"
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id={`tooltip-bottom`}
                    >
                      <span>{this.props.t("home")}</span>
                    </Tooltip>
                  }
                >
                  <button className="home-btn border-0 m-2"  style={{border:"none",left:"40px",top:"5px"}}onClick={(e)=>{this.props.history.push("/admin/events")}}>
                    <img style={{width:"20px",height:"25px"}}src={home} alt="" />
                  </button>
                </OverlayTrigger>
              </>
            
              <button style={collapseButtonStyle}
                className={`collapse-button ${
                  this.state.collapsed ? "collapsed" : ""
                }`}
                onClick={this.collapseToggle}
              >
                <img src={collapseToggleIcon} alt="" />
              </button>
                {/* <button
            className={`pullLeft-bar-toggle ${
              this.state.collapsed ? "collapsed" : ""
            }`}
            onClick={this.collapseToggle}
          >
            <span />
            <span />
            <span />
          </button> */}
              <div className="main-company-logo d-flex align-items-center justify-content-center">
              <img
                  src={`${this.context.stylingData?.questionnaireLogoSrc?this.context.stylingData?.questionnaireLogoSrc:""}`}
                  alt=""
                  style={{
                  
                     width: "100%",
                    height: "auto",
                    maxHeight: "100px",
                    objectFit: "contain",
                    imageRendering: "high-quality",
                  }}
                />
             {/* <img style={styles.logo} src={this.context.venueLogo} alt="" /> */}
         </div>
              <div className="pl-2 pt-5">
            <Accordion
              bool3={this.props.bool3}
              allowMultipleOpen={this.props.open}
              data={this.props.themes}
              activeTheme={this.props.activeTheme}
              onThemeSelect={this.props.themeSelect}
              // onServiceSelect={this.props.serviceSelect}
              onServiceSelect={this.serviceSelect}
            />
          </div>
            </div>

   
    );
  }
}


export default withTranslation() (withRouter(RightBarPreview));


const styles={
  logo: { width: "150px", height: "150px", objectFit: "contain" },
  collapseButton: {    
    position: 'fixed',
    top: '15px',
    left: '23px',
    background: 'transparent',
    width: '30px',
    height: '30px',
    bordeRradius: '50%',
    border: '0',
    zIndex: '90',
    transition: 'all 0.3s ease 0s'
  }
}
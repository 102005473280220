import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { StateContext } from "../../../context/state";
import axios from "axios";
import plusIcon from "../../../assets/plus.svg";
import arrowIcon from "../../../assets/arrow-small-down.svg";
import * as _ from "lodash";

import TextareaAutosize from "react-textarea-autosize";

import { Portal } from "react-overlays";
import { baseUrl } from "../../../Constants";
import moment from "moment-timezone";
import { registerLocale } from "react-datepicker";
import { localeGerman } from "../../../Constants";
import deleteIcon from "../../../assets/trash.svg";

registerLocale("de", localeGerman);

export default class Table extends Component {
  static contextType = StateContext;
  cellWidth = 0;

  constructor(props) {
    super(props);
    this.state = {
      height: "",
      dateFormat: "",
      timeFormat: "",
      resp: {},
    };
    const { rows } = this.props.option.tableInput;
    this.cellWidth = `calc(100% / ${rows?.[0]?.columns?.length})`;
  }
  componentDidMount() {
    const data = this.context;
    axios
      .get(`${baseUrl}api/venue/${data.venueId}`)
      .then((resp) => {
        // console.log("resp.data",resp.data)
        // console.log("dateFormat",resp.data?.timeFormat)
        this.setState({
          timeFormat: resp.data?.timeFormat,
          dateFormat: resp.data?.dateFormat,
          // resp:resp?.data
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(this.state.resp,"resp")
    let { rows } = this.props.option.tableInput;
    rows = rows || [];
    // console.log("row",rows)
    if (rows[0].columns.length) {
      rows[0].columns.forEach((_item, _index) => {
        // console.log("item",_item[0])
        // console.log(_index)
        this.sortAscRow(_item, 0, 0);
      });
    }

    document.getElementById("divCell");

    document.getElementById("textId");
    document.getElementById("rowDiv");
  }
  CalendarContainer = ({ children }) => {
    const el = document.getElementById("calendar-portal");

    return <Portal container={el}>{children}</Portal>;
  };
  onChange = (index, _index, e) => {
    
    let deadLineDate = new Date(this.context.deadlineForEntries);

    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) {
      return false;
    } else {
      const { option, onUpdate } = this.props;

      option.tableInput.rows[index].columns[_index].value =
        e?.target?.value ?? e;
      //e.target.style.height = e.target.scrollHeight + "px";
      onUpdate(option);
    }
  };
  handleKeyDown = (e) => {
   
    let deadLineDate = new Date(this.context.deadlineForEntries);

    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) {
      // if (
      //   this.context.userRole == "CU" &&
      //   Date.parse(this.context.deadlineForEntries) >
      //     Date.parse(today.toISOString())
      // )
      e.preventDefault();
    } else {
      e.target.style.height = "inherit";
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
  };

  showText = (e) => {
    e.target.style.height = e.target.scrollHeight + "px";
  };

  onEdit = (e) => {
  
    // console.log("editing");

    if (window.innerWidth < 1024) {
      e.target.style.whiteSpace = "normal";
      e.target.parentNode.style.width = "200%";
    }
    // e.target.parentNode.style.border = "1px solid #000";
    //option.tableInput.rows[1].columns[1].style.background = 'pink';
    //console.log(option.tableInput.rows[1].columns[1]);
  };

  onLeave = (e) => {
    // console.log("left");

    if (window.innerWidth < 1024) {
      e.target.style.whiteSpace = "nowrap";
      e.target.style.height = styles.textarea.height;
      e.target.parentNode.style.width = this.cellWidth;
    }

    // setTimeout(function(){
    //   console.log(e.target.style);
    //   //e.target.style.height = 'auto !important';
    // }, 300, e);
  };

  handleAddRow = () => {
    const { option, onUpdate } = this.props;
    
    let deadLineDate = new Date(this.context.deadlineForEntries);

    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) {
      return false;
    } else {
      const x = JSON.parse(
        JSON.stringify(
          option.tableInput.rows[option.tableInput.rows.length - 1].columns
        )
      );
      // console.log("x", x)
      const dummyX = JSON.parse(JSON.stringify(x));
      const dummyXX = dummyX.map((item) => {
        item.value = "";
        return item;
      });
      // console.log('dummy x', dummyXX)
      option.tableInput.rows.push({ columns: dummyXX });
      onUpdate(option);
    }
  };

  deleteTableRow = (index) => {
    const { option, onUpdate } = this.props;
  
    //  {console.log("index",index)}
    if (option.tableInput.rows.length > 1) {
      option.tableInput.rows.splice(index, 1);
    }
    //  {console.log("newTable",newTable)}
    onUpdate(option);
  };
 
  sortAscRow = (data, index, _index) => {
    // console.log("data",data)
    // console.log(_index, "sort")
    const { option, onUpdate } = this.props;

    let deadLineDate = new Date(this.context.deadlineForEntries);

    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) {
      return false;
    } else {
      let deep = _.cloneDeep(option);
      const firstRow = deep.tableInput.rows[0];
      const remainingRows = deep.tableInput.rows.slice(1);
      const b = this.handleAscManipulation(remainingRows, _index);
      const x = [];
      x.push(firstRow);
      x.push(...b);

      option.tableInput.rows = x;
      onUpdate(option);
    }
  };
  handleAscManipulation = (rows, _index) => {
    // console.log("rows",rows)
    let time = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
    const textData = rows.filter((a) => {
      // console.log("type",a["columns"][_index].value)
      if (
        a["columns"][_index].type === "text" &&
        !Number(a["columns"][_index].value)
      ) {
        return a;
      } else { return undefined; }
    });
    textData.sort((a, b) => {
      return a["columns"][_index].value > b["columns"][_index].value ? 1 : -1;
    });
    //  console.log('text', textData)
    const numericData = rows.filter((a) => {
      if (
        a["columns"][_index].type === "text" &&
        Number(a["columns"][_index].value)
      ) {
        return a;
      } 
      if (a['columns'][_index].type === 'numeric') {
				return a;
			} else {
				return undefined;
			}
    });
    numericData.sort((a, b) => {
      return (
        Number(a["columns"][_index].value) - Number(b["columns"][_index].value)
      );
    });
    const dateData = rows.filter((a) => {
      if (a['columns'][_index].type === 'date') {
				return a;
			} else {
				return undefined;
			}
    });
    dateData.sort((a, b) => {
      return (
        Date.parse(a["columns"][_index].value) -
        Date.parse(b["columns"][_index].value)
      );
    });
    const timeData = rows.filter((a) => {
      if (
				a['columns'][_index].type === 'time' ||
				(a['columns'][_index].type === 'string' &&
					time.test(a['columns'][_index].value) === true)
			) {
				return a;
			} else {
				return undefined;
			}
    });
    timeData.sort((a, c) => {
      //moment('17:00', ['h:m a', 'H:m']);

      return (
        Date.parse(a["columns"][_index].value) -
        Date.parse(c["columns"][_index].value)
      );
    });

    const a = numericData.concat(textData);

    const b = a.concat(dateData);
    const c = b.concat(timeData);
    return c;
  };
  sortDscRow = (data, index, _index) => {
    let deadLineDate = new Date(this.context.deadlineForEntries);

    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) {
      return false;
    } else {
      const { option, onUpdate } = this.props;
      let deep = _.cloneDeep(option);
      const firstRow = deep.tableInput.rows[0];
      const remainingRows = deep.tableInput.rows.slice(1);
      const b = this.handleDscManipulation(remainingRows, _index);
      const x = [];
      x.push(firstRow);
      x.push(...b);
      option.tableInput.rows = x;
      onUpdate(option);
    }
  };
  handleDscManipulation = (rows, _index) => {
    let time = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
    const textData = rows.filter((a) => {
      if (
				a['columns'][_index].type === 'text' &&
				!Number(a['columns'][_index].value)
			) {
				return a;
			} else {
				return undefined;
			}
    });
    textData.sort((a, b) => {
      return a["columns"][_index].value < b["columns"][_index].value ? 1 : -1;
    });
    const numericData = rows.filter((a) => {
      if (
        a["columns"][_index].type === "text" &&
        Number(a["columns"][_index].value)
      ) {
        return a;
      } if (a['columns'][_index].type === 'numeric') {
				return a;
			} else {
				return undefined;
			}
    });
    numericData.sort((a, b) => {
      return (
        Number(b["columns"][_index].value) - Number(a["columns"][_index].value)
      );
    });
    const dateData = rows.filter((a) => {
      if (a['columns'][_index].type === 'date') {
				return a;
			} else {
				return undefined;
			}
    });
    dateData.sort((a, b) => {
      return (
        Date.parse(b["columns"][_index].value) -
        Date.parse(a["columns"][_index].value)
      );
    });
    const timeData = rows.filter((a) => {
      if (
				a['columns'][_index].type === 'time' ||
				(a['columns'][_index].type === 'string' &&
					time.test(a['columns'][_index].value) === true)
			) {
				// console.log("in this")
				return a;
			} else {
				return undefined;
			}
    });
    timeData.sort((a, c) => {
      return (
        Date.parse(c["columns"][_index].value) -
        Date.parse(a["columns"][_index].value)
      );
    });

    const a = numericData.concat(textData);

    const b = a.concat(dateData);
    const c = b.concat(timeData);
    return c;
  };
  renderCell = (data, index, _index) => {
    const textColor =this.context.stylingData?.textColor? this.context.stylingData?.textColor : "#3f4041";
    // console.log("textColor",textColor)
    const dummyPlaceholder = this.context?.dateFormat
      ? this.context?.dateFormat.replace("dd", "TT").replace("yy", "JJ")
      : "JJJJ-MM-TT";
  
    let isMobile = false;

    if (window.innerWidth < 1024) {
      isMobile = true;
    }

    let textareaStyles = { ...styles.textarea };

    if (isMobile) {
      textareaStyles.width = `80%`;
      textareaStyles.whiteSpace = `nowrap`;
    } else {
      textareaStyles.width = `95%`;
      textareaStyles.whiteSpace = `normal`;
    }

    // console.log(textareaStyles);

    const cell = data.interactive ? (
      data.type === "numeric" ? (
        <input
          style={styles.input}
          onKeyDown={(e) => {
            if (e.key === "Backspace") return;
            let key = Number(e.key);
            if (isNaN(key) || e.key === null || e.key === " ")
              e.preventDefault();
          }}
          value={data.value}
          onChange={(e) => this.onChange(index, _index, e)}
          autoComplete="off"
        />
      ) : data.type === "time" ? (
        <>
          <DatePicker
            popperContainer={this.CalendarContainer}
            selected={data?.value ? new Date(data?.value) : undefined}
            onChange={(time) =>
              this.onChange(index, _index, time?.toISOString())
            }
            showTimeSelect
            showTimeSelectOnly
            placeholderText="00:00"
            className="table date-time-input"
            // timeIntervals={15}
            timeCaption="Zeit"
            timeFormat={
              this.state?.timeFormat === 24 ? "HH:mm" : "hh:mm aa" ?? "hh:mm aa"
            }
            dateFormat={
              this.state?.timeFormat === 24 ? "HH:mm" : "hh:mm aa" ?? "hh:mm aa"
            }
            // onKeyDown={(e) => {
            //   const key = e.key;
            //   if (key !== "Backspace") {
            //     e.preventDefault();
            //   }
            // }}
          />
        </>
      ) : data.type === "date" ? (
        <DatePicker
          popperContainer={this.CalendarContainer}
          selected={data?.value ? new Date(data?.value) : undefined}
          onChange={(date) =>
            this.onChange(index, _index, date?.toDateString())
          }
          locale="de"
          dateFormat={this.context?.dateFormat ?? "yyyy-MM-dd"}
          placeholderText={dummyPlaceholder}
          className="table date-time-input"
          // onKeyDown={(e) => {
          //   const key = e.key;
          //   if (key !== "Backspace") {
          //     e.preventDefault();
          //   }
          // }}
        />
      ) : isMobile ? (
        <textarea
          // className="text-div"
          id="textId"
          style={[textareaStyles, {color:textColor}]}
          type="text"
          value={data.value}
          onChange={(e) => this.onChange(index, _index, e)}
          //onKeyDownCapture={this.handleKeyDown}
          onBlur={(e) => this.onLeave(e)}
          onFocus={(e) => this.onEdit(e)}
          onClick={(e) => this.showText(e)}
        />
      ) : (
        <TextareaAutosize
          className="text-div"
          // id="textId"
          style={{...styles.textarea,color:textColor}}
          type="text"
          value={data.value}
          onChange={(e) => this.onChange(index, _index, e)}
          //onKeyDownCapture={this.handleKeyDowngit
        />
      )
    ) : (
      <span style={{...styles.label,color:textColor}}>
        {data.value}
        {index === 0 && _index !== 0 && (
          <>
            <img
              src={arrowIcon}
              alt=""
              style={{ margin: "12px 2px", padding: "2px" }}
              onClick={() => this.sortAscRow(data, index, _index)}
            />
            <img
              src={arrowIcon}
              alt=""
              style={{
                margin: "12px 0px",
                padding: "2px",
                transform: "rotate(180deg)",
              }}
              onClick={() => this.sortDscRow(data, index, _index)}
            />
          </>
        )}
        {index === 0 &&
          _index === 0 &&
          !this.props?.option?.tableInput?.rows[0]?.columns[1]?.interactive && (
            <>
              <img
                src={arrowIcon}
                alt=""
                style={{ margin: "12px 2px", padding: "2px" }}
                onClick={() => this.sortAscRow(data, index, _index)}
              />
              <img
                src={arrowIcon}
                alt=""
                style={{
                  margin: "12px 0px",
                  padding: "2px",
                  transform: "rotate(180deg)",
                }}
                onClick={() => this.sortDscRow(data, index, _index)}
              />
            </>
          )}
      </span>
    );

    const cellStyle = {
      ...styles.cell,
      ...(!data.interactive && styles.gray),
      width: this.cellWidth,

      // whiteSpace:"pre-wrap",
      //  wordWrap:"break-word"
      resize: "both",
      // overflow: "auto"
    };

    return (
      <div key={_index} style={cellStyle} id="divCell">
        {cell}
      </div>
    );
  };

  render() {
    let { rows } = this.props.option.tableInput;
    rows = rows || [];
    let textFontSize = this.context.stylingData?.textFontSize;
    return (
      <>
        <div
          className="table-width"
          // style={{
          //   fontSize: `${textFontSize}px`,
          //   borderLeft: "1px solid #939598",
          //   // borderTop: "1px solid #939598",
          // },styles.tableBorder}
          style={{
            ...{
              fontSize: `${textFontSize}px`,
              borderLeft: "1px solid #939598",
              borderBottom: "1px solid #939598",
            },
          }}
          id="rowDiv"
        >
          {rows.map((item, index) => (
            <div
              style={{ ...styles.row, ...styles.tableBorder }}
              key={index}>
              {item.columns.map((_item, _index) =>
                this.renderCell(_item, index, _index)
              )}
              <button
                className="logout-btn border-0 m-2"
                onClick={() => this.deleteTableRow(index)}
              >
                <img src={deleteIcon} alt="" />
              </button>
            </div>
          ))}
        </div>
        <img
          src={plusIcon}
          alt=""
          style={{ margin: "12px 20px" }}
          onClick={this.handleAddRow}
        />
      </>
    );
  }
}

const styles = {
  container: {
    width: "100%",
    borderLeft: "1px solid #939598",
    borderTop: "1px solid #939598",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    // overflow:"clip"
  },
  cell: {
    borderRight: "1px solid #939598",
    // borderBottom: "1px solid #939598",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
  },
  gray: {
    background: "#e6e8ea",
  },
  label: {
    // color: "#3f4041",
    // fontFamily: "Crete Round",
    wordBreak: "break-word",
    textAlign: "center",
    minHeight: "24px",
  },
  input: {
    width: "100%",
    background: "transparent",
    border: "0",
    textAlign: "center",
    color: "#3f4041",
    // fontFamily: "Crete Round",
  },
  textarea: {
    width: "100%",
    background: "transparent",
    border: "0",
    outline: "none",
    textAlign: "center",
    // color: "#3f4041",
    height: "30px",
    resize: "none",
    // overflow:"hidden"
    overflow: "hidden",
    textOverflow: "ellipsis",
    // fontSize: "14px",
    float: "left",
  },
  tableBorder: {
    borderTop: "1px solid #939598",
    borderRight: "1px solid #939598",
  },
};

import React, {Component} from "react";
import ArrowDown from "../assets/arrow-small-down.svg";
import ArrowRight from "../assets/arrow-small-right.svg";
import { withTranslation} from 'react-i18next';
import { StateContext } from "../context/state";

 class AccordionSection extends Component {
    static contextType = StateContext;

    _element = React.createRef();

    render() {
        const {props: {isOpen, theme, onThemeSelect, onServiceSelect}} = this;
        const iconStyle = {visibility: theme.services.length === 0 ? "hidden" : "visible"};
        const textColor = this.context.stylingData?.textColor ? this.context.stylingData?.textColor : this.context.defaultSettingStyle?.presTextColor; 


        
        const icon =
            <img
                src={isOpen ? ArrowDown : ArrowRight}
                alt=""
                style={{...styles.icon, ...iconStyle}}
            />;

        const headerStyle = {...styles.header, color: isOpen && `${textColor}`};

        const maxHeightStyle = {...styles.content, maxHeight: isOpen ? this._element?.current?.scrollHeight ?? "0px" : "0px"};

        return (
            <div style={styles.section}>
                <div style={headerStyle} onClick={() => onThemeSelect(theme,"circleClick")}>
                    {icon}
                    {theme.name}
                </div>

                <div ref={this._element} style={maxHeightStyle}>
                    {theme.services.map(service =>
                        <div key={service.id} style={{cursor: "pointer",display: 'flex',padding: "5px 10px",fontSize: "12px",color:`${textColor}`}} onClick={() => onServiceSelect(service,"circleClick")}>
                            <span>-&nbsp;</span>
                            <span>{service.name}</span>
                        </div>)}
                </div>
            </div>
        );
    }
}

export default withTranslation() (AccordionSection);
const styles = {
 
    content: {
        padding: "0 18px",
        overflow: "hidden",
        transition: "max-height 0.2s ease-out",
    },
    section: {
        padding: "5px 10px"
    },
    header: {
        cursor: "pointer",
        fontFamily: "Crete Round",
        // color: "#95969b",
        fontSize: "18px",
    },
    icon: {
        width: "10px",
        height: "10px",
        marginRight: "5px"
    },
    item: {
        cursor: "pointer",
        display: 'flex',
        // color: `${textColor}`,
        fontSize: "12px",
        padding: "5px 10px",
    }
};

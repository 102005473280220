import React, { Component } from "react";
import CreatableSelect from "react-select/creatable";
import {StateContext} from "../../context/state";
import minusIcon from "../../assets/minus.svg";
import plusIcon from "../../assets/plus.svg";
import tagIcon from "../../assets/tag.svg";
import tagInverseIcon from "../../assets/tag-inverse.svg";
// import deleteIcon from "../../assets/delete.svg";
import crossSmallIcon from "../../assets/cross-small.svg";
import axios from "axios";
import {baseUrl} from "../../Constants"
import { v4 as uuidv4 } from "uuid";
import { withTranslation} from 'react-i18next';
import addIcon from "../../assets/add.svg";

class RadioOptionConfiguration extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.messagesRef=React.createRef(null);
    this.state = {
      priceOptionSelected: false,
      tagOptions: [],
      tagOptionSelected: false,
      imageInput: {},
      bruttoChange:undefined,

      selectedImageIndex: null,
      tags:[],
      newEntry:true,
      guestInfo: {
        adults: false,
        U6: false,
        U12: false,
        U18: false,
      },
      guestOptions: [
        { value: "adults", checked: false },
        { value: "U6", checked: false },
        { value: "U12", checked: false },
        { value: "U18", checked: false },
      ],

      initialGuestOptions: [
        { value: "adults", checked: false },
        { value: "U6", checked: false },
        { value: "U12", checked: false },
        { value: "U18", checked: false },
      ],
      display: false,
  
      VATPercent:[{id:uuidv4(), checked:false,value:"19"},{id:uuidv4(), checked:false,value:"7"}]
    };
  }
  componentDidMount(){
    axios.get(`${baseUrl}api/Questions/Tag/${this.context.venueId}`)
    .then((res)=>{
      // console.log('res', res.data)
      if(res.data.hasOwnProperty("tags")){
        this.setState({
          tagOptions:res.data.tags,
          tags:res.data.tags,
          newEntry:false
        })
      }
   })
    .catch((err)=>{
      console.log(err)
    })
  }
  static getDerivedStateFromProps = (nextProps, prevState) => {
    return {
      imageInput: nextProps.option.imageInput,
      selectedImageIndex: nextProps.selectedImageIndex
    };
  };
  setVAT=(e,per)=>{
    const imageInput = this.state.imageInput;
    // console.log("per",per)
    imageInput.inputs[
      this.state.selectedImageIndex
    ].VATPercent.forEach((perc)=>{
      if(perc.id===per.id){
        perc.value=e.target.value
        // console.log("perc",perc)
      }
    })
   
    this.setState({ imageInput });
    this.props.updateImageOptionConfiguration(imageInput);

  }
  onNettoChange = (e) => {
   
      const imageInput = this.state.imageInput;
      let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
      imageInput.inputs[this.state.selectedImageIndex].netto = textValue;
      imageInput.inputs[this.state.selectedImageIndex].VATPercent.forEach((vat)=>{
        if(vat.checked===true){
          let percent=`${vat.value}%`
          // console.log("percent",percent)
          var result = parseFloat(percent) / 100.0;
          // var value=e.target.value *( result+1)
          var value =(Number(textValue * ( result+1))).toFixed(2)
  
          // console.log("result",value)
          imageInput.inputs[this.state.selectedImageIndex].brutto = value;
          if (this.context.priceType === "Netto") {
            imageInput.inputs[this.state.selectedImageIndex].price = textValue;
          }
          else{
            imageInput.inputs[this.state.selectedImageIndex].price = value;
          }
          this.setState({ imageInput });
          this.props.updateImageOptionConfiguration(imageInput);
        }
        else{
          this.setState({ imageInput });
          this.props.updateImageOptionConfiguration(imageInput);
        }
      })
    
    
  };
  convertedNettoValues = () => {
    const imageInput = this.state.imageInput;
    if(this.context.languageFormat!=="en" && imageInput.inputs[this.state.selectedImageIndex].netto)
    {
      let inputValue = imageInput.inputs[this.state.selectedImageIndex].netto
    return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && imageInput.inputs[this.state.selectedImageIndex].netto)
    {
      return imageInput.inputs[this.state.selectedImageIndex].netto
    }
    else{
      return ""
    }

  }
  convertedBruttoValues = () => {
    const imageInput = this.state.imageInput;
    if(this.context.languageFormat!=="en" && imageInput.inputs[this.state.selectedImageIndex].brutto)
    {
      let inputValue = imageInput.inputs[this.state.selectedImageIndex].brutto
 
      return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && imageInput.inputs[this.state.selectedImageIndex].brutto)
    {
      return imageInput.inputs[this.state.selectedImageIndex].brutto
    }
    else{
      return ""
    }

  }
  onBruttoChange = (e) => {
    const imageInput = this.state.imageInput;
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    imageInput.inputs[this.state.selectedImageIndex].brutto = textValue;
    imageInput.inputs[this.state.selectedImageIndex].VATPercent.forEach((vat)=>{
      if(vat.checked===true){
        let percent=`${vat.value}%`
        // console.log("percent",percent)
        var result = parseFloat(percent) / 100.0;
        var value=Number(textValue /( result+1)).toFixed(2)
        // console.log("result",value)
        imageInput.inputs[this.state.selectedImageIndex].netto = value;
        if (this.context.priceType === "Brutto") {
          imageInput.inputs[this.state.selectedImageIndex].price = textValue;
        }
        else{
          imageInput.inputs[this.state.selectedImageIndex].price = value;
        }
        this.setState({ imageInput });
        this.props.updateImageOptionConfiguration(imageInput);
      }
      else{
        this.setState({ imageInput });
        this.props.updateImageOptionConfiguration(imageInput);
      }
    })
  };
  onEnterHitAtNetto=(e)=>{
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].VATPercent.forEach((vat)=>{
      if(vat.checked===true){
        let percent=`${vat.value}%`
        // console.log("percent",percent)
        var result = parseFloat(percent) / 100.0;
        // var value=e.target.value *( result+1)
        var value =(Number(e.target.value * ( result+1))).toFixed(2)

        // console.log("result",value)
        imageInput.inputs[this.state.selectedImageIndex].brutto = value;
        if (this.context.priceType === "Netto") {
          imageInput.inputs[this.state.selectedImageIndex].price = e.target.value;
        }
        else{
          imageInput.inputs[this.state.selectedImageIndex].price = value;
        }
        this.setState({ imageInput });
        this.props.updateImageOptionConfiguration(imageInput);
      }
    })
  }
  setCheckedPercentage=(per)=>{
    const imageInput = this.state.imageInput;
    // console.log("per",per)
    imageInput.inputs[
      this.state.selectedImageIndex
    ].VATPercent.forEach((perc)=>{
      if(perc.id===per.id){
        perc.checked = true
        // perc.checked=!perc.checked
        // console.log("perc",perc)
        if(perc.checked===true){
          if(this.context.priceType === "Netto"){
            if(this.state.bruttoChange===true){
              let percent = `${perc.value}%`;
            
                          var result = parseFloat(percent) / 100.0;
            
                          var value = Number(
                            imageInput.inputs[this.state.selectedImageIndex].brutto /
                              (result + 1)
                          ).toFixed(2);
                          // console.log("value",value)
                          imageInput.inputs[this.state.selectedImageIndex].netto = value;
                          imageInput.inputs[this.state.selectedImageIndex].price = value;
            }
            else{
            if(imageInput.inputs[this.state.selectedImageIndex].netto){
              let percent = `${perc.value}%`;
         
              result = parseFloat(percent) / 100.0;
              
              value = (Number( imageInput.inputs[this.state.selectedImageIndex].netto * (result + 1))).toFixed(2);
              imageInput.inputs[this.state.selectedImageIndex].brutto = value;
              imageInput.inputs[this.state.selectedImageIndex].price = imageInput.inputs[this.state.selectedImageIndex].netto;
            }
          }
          // else{
          //   imageInput.inputs[this.state.selectedImageIndex].brutto = 0;
          //   imageInput.inputs[this.state.selectedImageIndex].price = 0;
          // }
          
          }
          else if(this.context.priceType === "Brutto"){
            console.log("bruttoChange",this.state.bruttoChange)
            if(this.state.bruttoChange===false){
              console.log("price",imageInput.inputs[this.state.selectedImageIndex].netto 
              )
              let percent = `${perc.value}%`;
            
                          result = parseFloat(percent) / 100.0;
            
                          value = Number(
                            imageInput.inputs[this.state.selectedImageIndex].netto *
                              (result + 1)
                          ).toFixed(2);
                          // console.log("value",value)
                          imageInput.inputs[this.state.selectedImageIndex].brutto = value;
                          imageInput.inputs[this.state.selectedImageIndex].price = value;
            }
            else{
            if(imageInput.inputs[this.state.selectedImageIndex].brutto){
              let percent = `${perc.value}%`;
         
              result = parseFloat(percent) / 100.0;
              
              value = (Number(imageInput.inputs[this.state.selectedImageIndex].brutto / (result + 1))).toFixed(2);
              imageInput.inputs[this.state.selectedImageIndex].netto = value;
              imageInput.inputs[this.state.selectedImageIndex].price = imageInput.inputs[this.state.selectedImageIndex].brutto;
            }
          }
            // else{
            //   imageInput.inputs[this.state.selectedImageIndex].netto = 0;
            //   imageInput.inputs[this.state.selectedImageIndex].price = 0;
            // }
          
          
          }
        }
        else{
          imageInput.inputs[this.state.selectedImageIndex].price=0
          
        } 
      }
    
        if(perc.id!==per.id){
        perc.checked=false
      }
    })
   
    this.setState({ imageInput });
    this.props.updateImageOptionConfiguration(imageInput);

  }
  onEnterHitAtBrutto=(e)=>{
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].VATPercent.forEach((vat)=>{
      if(vat.checked===true){
        let percent=`${vat.value}%`
        // console.log("percent",percent)
        var result = parseFloat(percent) / 100.0;
        var value=Number(e.target.value /( result+1)).toFixed(2)
        // console.log("result",value)
        imageInput.inputs[this.state.selectedImageIndex].netto = value;
        if (this.context.priceType === "Brutto") {
          imageInput.inputs[this.state.selectedImageIndex].price = e.target.value;
        }
        else{
          imageInput.inputs[this.state.selectedImageIndex].price = value;
        }
        this.setState({ imageInput });
        this.props.updateImageOptionConfiguration(imageInput);
      }
    })
  }
  addVATPercentage=(e)=>{
    const imageInput = this.state.imageInput;
let dummy
if(imageInput.inputs[this.state.selectedImageIndex].VATPercent){
   dummy=Object.assign([],imageInput.inputs[this.state.selectedImageIndex].VATPercent)
}
   else{
     dummy=Object.assign([],this.state.VATPercent)
   }
    // console.log("VA",this.state.VATPercent)
    dummy.push({id:uuidv4(), checked:false,value:'0'})
    // console.log("dummy",dummy)
    imageInput.inputs[this.state.selectedImageIndex].VATPercent =dummy;
    this.setState({ imageInput});
    this.props.updateImageOptionConfiguration(this.state.imageInput);

  }
  removeVATPercentage=(index)=>{
    const imageInput = this.state.imageInput;
    if (imageInput.inputs[this.state.selectedImageIndex].VATPercent[index].checked) {
      if(index>1){
        imageInput.inputs[this.state.selectedImageIndex].VATPercent.splice(index,1);
        this.setCheckedPercentage(imageInput.inputs[this.state.selectedImageIndex].VATPercent[0]);
        this.setState({ imageInput});
      }
    }
    else {
      imageInput.inputs[this.state.selectedImageIndex].VATPercent.splice(index,1);
      this.setState({imageInput});
    }
    }
  setInvoiceText = (e) => {
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].description=
      e.target.value;
    this.setState({ imageInput });
    this.props.updateImageOptionConfiguration(this.state.imageInput);
  };
  functionValidation=()=>{
    const {imageInput}= this.state
    const min=Number(imageInput.inputs[this.state.selectedImageIndex].numerically.minimumNumber)
    const max=Number(imageInput.inputs[this.state.selectedImageIndex].numerically.maximumNumber)
    //  console.log('min', min, max)
    if(min && max && min>max){
      const msg="Der Maximalwert sollte größer als der Minimalwert sein"
        // valid=false
        return msg
    }
    else{
        // valid=true
        return null
    }
    
  }
  functionPriceValidation=()=>{
    const { imageInput}= this.state
    const min=Number(imageInput.inputs[this.state.selectedImageIndex].numerically.minimumNumber)
    const max=Number(imageInput.inputs[this.state.selectedImageIndex].numerically.maximumNumber)
    const price=Number(imageInput.inputs[this.state.selectedImageIndex].price)
    const brutto = Number(
      imageInput.inputs[this.state.selectedImageIndex].brutto
    );
    const netto = Number(
      imageInput.inputs[this.state.selectedImageIndex].netto
    );
    const  grossNetPrice=this.context.priceType==="Netto" ? netto :brutto

    //  console.log('min', min, max)
    if(min && max && !price && !grossNetPrice){
      const msg="Preis eingeben"
        // valid=false
        return msg
    }
     else if(min && !price &&  !grossNetPrice){
      const msg="Preis eingeben"
        // valid=false
        return msg
    }
    else if(max && !price &&  !grossNetPrice){
      const msg="Preis eingeben"
        // valid=false
        return msg
    }
    else{
        // valid=true
        return null
    }
    
  }

  setFlatRate=(e)=>{
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].numerically.unit =
    e.target.value;
  this.setState({ imageInput });
  }
  setGuest=(e)=>{
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].numerically.unit =
    e.target.value;
  this.setState({ imageInput });
  }
  setUnit=(e)=>{
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].numerically.unit =
    e.target.value;
  this.setState({ imageInput });
  }

  setPriceOptionSelected = () =>
    this.setState({ priceOptionSelected: true, tagOptionSelected: false });

  setTagOptionSelected = () =>
    this.setState({ priceOptionSelected: false, tagOptionSelected: true });

  renderPriceOption = selectedPriceOption => {
    
    Object.keys(this.context.guestLabel).forEach((guest) => {
      this.state.initialGuestOptions.forEach((label) => {
        if (label.value === guest) {
          (label.value = this.context.guestLabel[guest]);
        }
      });
    });
    //  console.log("initialguest",this.state.initialGuestOptions)
    const array = this.state.imageInput.inputs[this.state.selectedImageIndex]
      .guestOptions
      ? this.state.imageInput.inputs[this.state.selectedImageIndex].guestOptions
      : this.state.initialGuestOptions;

    switch (selectedPriceOption) {
      case "flat-rate":
        return (
          <div className="price-option-btn-container">
            <div className="price-option-btn">{this.props.t('flat_rate')}</div>
            <button className="clear-option" onClick={this.resetPriceOption}>
              <img src={minusIcon} alt="" />
            </button>
            <div className="row" style={{ display: "block", margin: "auto" }}>
              <div style={{}}>
                <h6 style={{paddingLeft:"66px",textAlign:"left"}}>{this.props.t('Unit')}</h6>
                <input
                  maxLength="15"
                  className="invoice-text"
                  type="text"
                  placeholder={`${this.props.t('flat_rate')}`}
                  value={
                    this.state.imageInput.inputs[this.state.selectedImageIndex]
                      .numerically.unit
                  } 
                  onChange={this.setFlatRate}        
                />
              </div>
            </div>
          </div>
        );
      case "number-guests":
        return (
          <div className="price-option-btn-container">
            <div className="price-option-btn">{this.props.t('Anzahl')}</div>
            <button className="clear-option" onClick={this.resetPriceOption}>
              <img src={minusIcon} alt="" />
            </button>
            <div
              // onClick={(e)=>this.HeaderObject(e)}
              style={{
                marginLeft: "50px",
                width: "190px",
              }}
            >
              {array.map((guest) => {
                return (
                  <div
                    //  display: "flex"
                    style={{
                      paddingBottom: "20px",
                      marginTop: "-10px",
                      // color: "#939598",
                      background: "rgb(209, 211, 212) none repeat scroll 0% 0%",
                      display: "flex",
                      // paddingLeft: "50px",
                      // paddingRight: "90px",
                      marginRight: "200px",
                      // height: "200px",
                      // overflow: "auto",
                      // marginLeft: "50px",
                      width: "190px",
                    }}
                  >
                    <input
                      onChange={(e) => {
                        this.selectedHeader(e, guest.value);
                      }}
                      style={{
                        // width: "100%",
                        position: "absolute",
                        right: "250px",
                        left: "90px",
                        // top:"40px"
                      }}
                      checked={guest.checked}
                      type="checkbox"
                      id={guest.value}
                      name={guest.value}
                      value={guest.value}
                    />

                    <label
                      htmlFor={guest.value}
                      style={{
                        clear: "both",
                        left: "60px",
                        display: "inline-block",

                        whiteSpace: "nowrap",
                      }}
                    >
                    {this.props.t(guest.value)}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="row" style={{ display: "block", margin: "auto" }}>
              <div style={{}}>
                <h6 style={{paddingLeft:"66px",textAlign:"left"}}>{this.props.t('Unit')}</h6>
                <input
                  maxLength="15"
                  className="invoice-text"
                  type="text"
                  placeholder={`${this.props.t('Per')} ${this.props.t('Gast')}`}
                  value={
                    this.state.imageInput.inputs[this.state.selectedImageIndex]
                    .numerically.unit
                  } 
                  onChange={this.setGuest}        
                />
              </div>
            </div>
        
          </div>
        );
      case "numerically":
        return (
          <>
            <div className="price-option-btn-container">
              <div className="price-option-btn">{this.props.t('Numerically')}</div>
              <button className="clear-option" onClick={this.resetPriceOption}>
                <img src={minusIcon} alt="" />
              </button>
            </div>
             {
            (this.functionPriceValidation())?
            <div className="validationMsg">{this.functionPriceValidation()}
            </div>
            : null
          }
             {
            (this.functionValidation())?
            <div className="validationMsg">{this.functionValidation()}
            </div>
            : null
          }
            <div className="numerically-options">
              <div className="left-text">
              {this.props.t('Minimum_number')}
                <br />
                {this.props.t('Max_number')} 
                <br />
                {this.props.t('stages')}
                <br />
                {`${this.props.t('Season')} 1:`}
                <br />
                {`${this.props.t('Season')} 2:`}
                <br />
                {`${this.props.t('Season')} 3:`}
                <br />
                {/* Einheit: */}
              </div>
              <div className="right-options">
              <div style={{display:"flex",  height:"30px", marginTop:"5px"}}>
                <input
                  type="number"
                  className="small-input"
                  placeholder="000"
                  style={(this.functionValidation()) && {border:"1px solid red"}}
                  value={
                    this.state.imageInput.inputs[this.state.selectedImageIndex]
                      .numerically.minimumNumber
                  }
                  onChange={this.setMinimumNumber}
                  autoComplete="off"
                />
                <label className="calculate">
                  <input
                    type="checkbox"
                    checked={
                      this.state.imageInput.inputs[
                        this.state.selectedImageIndex
                      ].numerically.minimumNumberCalculate
                    }
                    onChange={this.setMinimumNumberCalculate}
                    autoComplete="off"
                  />
                  <span>{this.props.t('Just_calculate')}</span>
                </label>
                </div>
                {/* <br /> */}
                <div style={{display:"flex", height:"30px", marginTop:"2px"}}>
                <input
                  type="number"
                  className="small-input"
                  placeholder="000"
                  style={(this.functionValidation()) && {border:"1px solid red"}}
                  value={
                    this.state.imageInput.inputs[this.state.selectedImageIndex]
                      .numerically.maximumNumber
                  }
                  onChange={this.setMaximumNumber}
                  autoComplete="off"
                />
                <label className="calculate">
                  <input
                    type="checkbox"
                    checked={
                      this.state.imageInput.inputs[
                        this.state.selectedImageIndex
                      ].numerically.maximumNumberCalculate
                    }
                    onChange={this.setMaximumNumberCalculate}
                    autoComplete="off"
                  />
                  <span>{this.props.t('Just_calculate')}</span>
                </label>
                </div>
                {/* <br /> */}
                <div style={{height:"30px",marginTop:"2px"}}>
                <input
                  type="number"
                  className="small-input"
                  placeholder="000"
                  value={
                    this.state.imageInput.inputs[this.state.selectedImageIndex]
                      .numerically.stage
                  }
                  onKeyDown={e => {
                    if (e.key === "Backspace") return;
                    let key = Number(e.key);
                    if (isNaN(key) || e.key === null || e.key === ' ') e.preventDefault();
                }}
                  onChange={this.setStage}
                  autoComplete="off"
                />
                </div>
                {/* <br /> */}
                <div style={{display:"flex", height: "30px", marginTop:"2px"}}>
                <input
                  type="number"
                  className="small-input"
                  placeholder="000"
                  value={
                    this.state.imageInput.inputs[this.state.selectedImageIndex]
                      .numerically.relay1
                  }
                  onKeyDown={e => {
                    if (e.key === "Backspace") return;
                    let key = Number(e.key);
                    if (isNaN(key) || e.key === null || e.key === ' ') e.preventDefault();
                }}
                  onChange={this.setRelay1}
                  autoComplete="off"
                />
                <div>
                <label className="more">
                  <input
                    type="radio"
                    name="staffel1"
                    checked={
                      this.state.imageInput.inputs[
                        this.state.selectedImageIndex
                      ].numerically.relay1Alle === false
                    }
                    onChange={this.handleRelay1}
                    value="false"
                    autoComplete="off"
                  />
                  <span>{this.props.t('Each_further')}</span>
                </label>
                </div>
                <div>
                <label className="alle">
                  <input
                    type="radio"
                    name="staffel1"
                    checked={
                      this.state.imageInput.inputs[
                        this.state.selectedImageIndex
                      ].numerically.relay1Alle === true
                    }
                    onChange={this.handleRelay1}
                    value="true"
                    autoComplete="off"
                  />
                  <span>{this.props.t('all')}</span>
                </label>
               </div>
                <input
                  type="text"
                  className="last-input"
                  value={
                    // this.state.imageInput.inputs[this.state.selectedImageIndex]
                    //   .numerically.relay1Price
                    this.convertedRelay1PriceValues()

                  }
                  onChange={this.setRelay1Price}
                  autoComplete="off"
                  onKeyPress={(evt) => {
                    var charCode = evt.which ? evt.which : evt.keyCode;

                    let inputFormat= "";
                    inputFormat = (this.context.languageFormat==="en") ? charCode !== 46 : charCode !== 44 
                    if (inputFormat &&
                      charCode > 31 &&
                      (charCode < 48 || charCode > 57)  
                    ) {
                      //console.log("in alse")
                      // return false;
                      evt.preventDefault();
                    }
                    
                    return true;
                  }}
                />
                </div>
                {/* <br /> */}
                <div style={{display:"flex", height: "30px", marginTop:"2px"}}>
                <input
                  type="number"
                  className="small-input"
                  placeholder="000"
                  value={
                    this.state.imageInput.inputs[this.state.selectedImageIndex]
                      .numerically.relay2
                  }
                  onChange={this.setRelay2}
                  onKeyDown={e => {
                    if (e.key === "Backspace") return;
                    let key = Number(e.key);
                    if (isNaN(key) || e.key === null || e.key === ' ') e.preventDefault();
                }}
                autoComplete="off"
                />
                <div>
                <label className="more">
                  <input
                    type="radio"
                    name="staffel2"
                    value="false"
                    checked={
                      this.state.imageInput.inputs[
                        this.state.selectedImageIndex
                      ].numerically.relay2Alle === false
                    }
                    onChange={this.handleRelay1}
                    autoComplete="off"
                  />
                  <span>{this.props.t('Each_further')}</span>
                </label>
                </div>
                <div>
                <label className="alle">
                  <input
                    type="radio"
                    name="staffel2"
                    value="true"
                    checked={
                      this.state.imageInput.inputs[
                        this.state.selectedImageIndex
                      ].numerically.relay2Alle === true
                    }
                    onChange={this.handleRelay1}
                    autoComplete="off"
                  />
                  <span>{this.props.t('all')}</span>
                </label>
                </div>
                <div>
                <input
                  type="text"
                  className="last-input"
                  value={
                    // this.state.imageInput.inputs[this.state.selectedImageIndex]
                    //   .numerically.relay2Price
                    this.convertedRelay2PriceValues()

                  }
                  onChange={this.setRelay2Price}
                  autoComplete="off"
                  onKeyPress={(evt) => {
                    var charCode = evt.which ? evt.which : evt.keyCode;

                    let inputFormat= "";
                    inputFormat = (this.context.languageFormat==="en") ? charCode !== 46 : charCode !== 44 
                    if (inputFormat &&
                      charCode > 31 &&
                      (charCode < 48 || charCode > 57)  
                    ) {
                      //console.log("in alse")
                      // return false;
                      evt.preventDefault();
                    }
                    
                    return true;
                  }}
                />
                </div>
                </div>
                {/* <br /> */}
                <div style={{display:"flex", height: "30px", marginTop:"2px"}}>
                <input
                  type="number"
                  className="small-input"
                  placeholder="000"
                  value={
                    this.state.imageInput.inputs[this.state.selectedImageIndex]
                      .numerically.relay3
                  }
                  onKeyDown={e => {
                    if (e.key === "Backspace") return;
                    let key = Number(e.key);
                    if (isNaN(key) || e.key === null || e.key === ' ') e.preventDefault();
                }}
                  onChange={this.setRelay3}
                  autoComplete="off"
                />
                <div>
                <label className="more">
                  <input
                    type="radio"
                    name="staffel3"
                    value="false"
                    checked={
                      this.state.imageInput.inputs[
                        this.state.selectedImageIndex
                      ].numerically.relay3Alle === false
                    }
                    onChange={this.handleRelay1}
                    autoComplete="off"
                  />
                  <span>{this.props.t('Each_further')}</span>
                </label>
                  </div>
                  <div>
                <label className="alle">
                  <input
                    type="radio"
                    name="staffel3"
                    value="true"
                    checked={
                      this.state.imageInput.inputs[
                        this.state.selectedImageIndex
                      ].numerically.relay3Alle === true
                    }
                    onChange={this.handleRelay1}
                    autoComplete="off"
                  />
                  <span>{this.props.t('all')}</span>
                </label>
                </div>
                <div>
                <input
                  type="number"
                  className="last-input"
                  value={
                    // this.state.imageInput.inputs[this.state.selectedImageIndex]
                    //   .numerically.relay3Price
                    this.convertedRelay3PriceValues()
                  }
                  onChange={this.setRelay3Price}
                  autoComplete="off"
                  onKeyPress={(evt) => {
                    var charCode = evt.which ? evt.which : evt.keyCode;

                    let inputFormat= "";
                    inputFormat = (this.context.languageFormat==="en") ? charCode !== 46 : charCode !== 44 
                    if (inputFormat &&
                      charCode > 31 &&
                      (charCode < 48 || charCode > 57)  
                    ) {
                      //console.log("in alse")
                      // return false;
                      evt.preventDefault();
                    }
                    
                    return true;
                  }}
                />
                </div>
                </div>
                {/* <br /> */}
                {/* <input
                  className="unit"
                  type="text"
                  placeholder="/Flasche/Person/Stück"
                  value={
                    this.state.imageInput.inputs[this.state.selectedImageIndex]
                      .numerically.unit
                  }
                  onChange={this.setUnit}
                /> */}
              </div>
            </div>
            <div className="row" style={{ display: "block", margin: "auto" }}> 
                <h6 style={{paddingLeft:"66px",textAlign:"left"}}>{this.props.t('Unit')}</h6>
                <input
                  maxLength="15"
                  className="unit1"
                  type="text"
                  placeholder={this.props.t('Add your Unit here..')}
                  value={
                    this.state.imageInput.inputs[this.state.selectedImageIndex]
                    .numerically.unit
                  }
                  onChange={this.setUnit}
                />
             
            </div>
          </>
        );
      default:
        return null;
    }
  };

  str2bool = value => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  selectPriceOption = priceType => {
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].priceType = priceType;
    if (priceType === "flat-rate")
      imageInput.inputs[this.state.selectedImageIndex].priceTypeId = 1;
    else if (priceType === "number-guests")
      imageInput.inputs[this.state.selectedImageIndex].priceTypeId = 2;
    else if (priceType === "numerically")
      imageInput.inputs[this.state.selectedImageIndex].priceTypeId = 3;
    else imageInput.inputs[this.state.selectedImageIndex].priceTypeId = null;
    this.setState({ imageInput });
    this.props.updateImageOptionConfiguration(this.state.imageInput);
  };
  selectedHeader = (e, label) => {
    // constimageInput = this.stateimageInput;
    const imageInput = Object.assign({}, this.state.imageInput);
    let guestOptions;
    if (imageInput.inputs[this.state.selectedImageIndex].guestOptions) {
      // console.log("in if",imageInput.inputs[this.state.selectedImageIndex].guestOptions)
      guestOptions =
      imageInput.inputs[this.state.selectedImageIndex].guestOptions;
    } else {
      // console.log("in else")

      let dummy = [
        { value: "adults", checked: false },
        { value: "U6", checked: false },
        { value: "U12", checked: false },
        { value: "U18", checked: false },
      ];
      Object.keys(this.context.guestLabel).forEach((guest) => {
        dummy.forEach((label) => {
          if (label.value === guest) {
            (label.value = this.context.guestLabel[guest]);
          }
        });
      });
      // console.log("dummy",dummy,"dummy2",dummy2)
      guestOptions = dummy;
    }
    // console.log(guestOptions,"guestOptions")
    guestOptions.forEach((guest) => {
      if (guest.value === label) {
        // e.target.checked === true
        guest.checked = e.target.checked;
        // ? (guest.checked = true)
        // : (guest.checked = false);
      }
    });
    let array = [];
    guestOptions.forEach((guest) => {
      if (guest.checked === true) {
        Object.keys(this.context.guestLabel).forEach((label) => {
          // console.log(this.context.guestLabel[label])
          if (this.context.guestLabel[label] === guest.value) {
            array.push(label);
          }
        });
      }
    });

    imageInput.inputs[
      this.state.selectedImageIndex
    ].guestOptions = guestOptions;
    imageInput.inputs[this.state.selectedImageIndex].guestInfo = array;
    this.setState({ imageInput });
    this.props.updateImageOptionConfiguration(this.state.imageInput);
  };
  resetPriceOption = () => {
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].priceType = "";
    this.setState({ imageInput });
    this.props.updateImageOptionConfiguration(this.state.imageInput);
  };

  setimageInputPrice = e => {
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].price = e.target.value;
    this.setState({ imageInput });
    this.props.updateImageOptionConfiguration(this.state.imageInput);
  };

  clearPrice = () => {
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].price = "";
    imageInput.inputs[this.state.selectedImageIndex].netto = "";
    imageInput.inputs[this.state.selectedImageIndex].brutto = "";
    imageInput.inputs[this.state.selectedImageIndex].VATPercent.forEach((v)=>{
      // console.log("v",v)
      v.checked=false
    })
    this.setState({ imageInput });
    this.props.updateImageOptionConfiguration(this.state.imageInput);
  };

  setMinimumNumber = e => {
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].numerically.minimumNumber =
      e.target.value;
    this.setState({ imageInput });
    this.props.updateImageOptionConfiguration(this.state.imageInput);

  };

  setMinimumNumberCalculate = e => {
    const imageInput = this.state.imageInput;
    imageInput.inputs[
      this.state.selectedImageIndex
    ].numerically.minimumNumberCalculate = !imageInput.inputs[
      this.state.selectedImageIndex
    ].numerically.minimumNumberCalculate;
    this.setState({ imageInput });
  };

  setMaximumNumber = e => {
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].numerically.maximumNumber =
      e.target.value;
    this.setState({ imageInput });
    this.props.updateImageOptionConfiguration(this.state.imageInput);

  };

  setMaximumNumberCalculate = () => {
    const imageInput = this.state.imageInput;
    imageInput.inputs[
      this.state.selectedImageIndex
    ].numerically.maximumNumberCalculate = !imageInput.inputs[
      this.state.selectedImageIndex
    ].numerically.maximumNumberCalculate;
    this.setState({ imageInput });
  };

  setStage = e => {
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].numerically.stage =
      e.target.value;
    this.setState({ imageInput });
  };

  setRelay1 = e => {
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].numerically.relay1 =
      e.target.value;
    this.setState({ imageInput });
   
  };

  handleRelay1 = e => {
    const imageInput = this.state.imageInput;
    imageInput.inputs[
      this.state.selectedImageIndex
    ].numerically.relay1Alle = this.str2bool(e.target.value);
    imageInput.inputs[
      this.state.selectedImageIndex
        ].numerically.relay2Alle = this.str2bool(e.target.value);
        imageInput.inputs[
      this.state.selectedImageIndex
            ].numerically.relay3Alle = this.str2bool(e.target.value);
    this.setState({ imageInput });
  };

  setRelay1Price = e => {
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].numerically.relay1Price =
      textValue;
    this.setState({ imageInput });
  };
  convertedRelay1PriceValues = () => {

    if(this.context.languageFormat!=="en" && this.state.imageInput.inputs[this.state.selectedImageIndex].numerically.relay1Price)
    {
      let inputValue = this.state.imageInput.inputs[this.state.selectedImageIndex].numerically.relay1Price

      //inputValue.replace('.',",")
    return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && this.state.imageInput.inputs[this.state.selectedImageIndex].numerically.relay1Price)
    {
      return this.state.imageInput.inputs[this.state.selectedImageIndex].numerically.relay1Price
    }
    else{
      return ""
    }

  }

  setRelay2 = e => {
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].numerically.relay2 =
      e.target.value;
    this.setState({ imageInput });
  };

  // handleRelay2 = e => {
  //   const imageInput = this.state.imageInput;
  //   imageInput.inputs[
  //     this.state.selectedImageIndex
  //   ].numerically.relay2Alle = this.str2bool(e.target.value);
  //   this.setState({ imageInput });
  // };

  setRelay2Price = e => {
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].numerically.relay2Price =
      textValue;
    this.setState({ imageInput });
  };
  convertedRelay2PriceValues = () => {

    if(this.context.languageFormat!=="en" && this.state.imageInput.inputs[this.state.selectedImageIndex].numerically.relay2Price)
    {
      let inputValue = this.state.imageInput.inputs[this.state.selectedImageIndex].numerically.relay2Price

      //inputValue.replace('.',",")
    return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && this.state.imageInput.inputs[this.state.selectedImageIndex].numerically.relay2Price)
    {
      return this.state.imageInput.inputs[this.state.selectedImageIndex].numerically.relay2Price
    }
    else{
      return ""
    }

  }

  setRelay3 = e => {
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].numerically.relay3 =
      e.target.value;
    this.setState({ imageInput });
  };

  // handleRelay3 = e => {
  //   const imageInput = this.state.imageInput;
  //   imageInput.inputs[
  //     this.state.selectedImageIndex
  //   ].numerically.relay3Alle = this.str2bool(e.target.value);
  //   this.setState({ imageInput });
  // };

  setRelay3Price = e => {
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].numerically.relay3Price =
      textValue;
    this.setState({ imageInput });
  };
  convertedRelay3PriceValues = () => {

    if(this.context.languageFormat!=="en" && this.state.imageInput.inputs[this.state.selectedImageIndex].numerically.relay3Price)
    {
      let inputValue = this.state.imageInput.inputs[this.state.selectedImageIndex].numerically.relay3Price

      //inputValue.replace('.',",")
    return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && this.state.imageInput.inputs[this.state.selectedImageIndex].numerically.relay3Price)
    {
      return this.state.imageInput.inputs[this.state.selectedImageIndex].numerically.relay3Price
    }
    else{
      return ""
    }

  }

  setUnit = e => {
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].numerically.unit =
      e.target.value;
    this.setState({ imageInput });
  };

  // handleTagChange = (index, newValue) => {
  //   if (newValue && newValue.__isNew__) {
  //     const tagOptions = this.state.tagOptions;
  //     tagOptions.push({ label: newValue.label });
  //     this.setState({ tagOptions });
  //   }
  //   const imageInput = this.state.imageInput;
  //   imageInput.inputs[this.state.selectedImageIndex].tags[index] =
  //     newValue.label;
  //   this.setState({ imageInput });
  // };
  handleTagChange = (newValue, x) => {
    const imageInput = this.state.imageInput;
    imageInput.inputs[this.state.selectedImageIndex].tags=newValue;
    this.setState({ imageInput });
    if(x.action==="create-option"){
      let tags=Object.assign([],this.state.tags)
      const tag={
        value:newValue[newValue.length-1].value,
        label:newValue[newValue.length-1].label,
      }
      tags.push(tag)
      const tagOptions=Object.assign([], this.state.tagOptions)
      tagOptions.push(tag)
      this.setState({ tags,
      tagOptions
    });
      const postData={
        tags,
        venueId:this.context.venueId
      }
      if(this.state.newEntry && !this.state.tagOptions.length){
        axios.post(`${baseUrl}api/Questions/Tag`, postData)
        .then((res)=>{
          // console.log(res.data)
        })
        .catch((err)=>{
          console.log(err)
        })
      }
      else{
        axios.put(`${baseUrl}api/Questions/Tag/${this.context.venueId}`, postData)
        .then((res)=>{
          // console.log(res.data)
        })
        .catch((err)=>{
          console.log(err)
        })
      }
    }
  }
  // addTagSelector = () => {
  //   const imageInput = this.state.imageInput;
  //   imageInput.inputs[this.state.selectedImageIndex].tags.push("");
  //   this.setState({ imageInput });
  // };

  // removeTag = index => {
  //   const imageInput = this.state.imageInput;
  //   imageInput.inputs[this.state.selectedImageIndex].tags.splice(index, 1);
  //   this.setState({ imageInput });
  // };
  handleClose=()=>{
    const ans=this.functionValidation()
    if(!ans){
      this.props.handleClose()
    }
  }
  render() {
    const VATPercent= [
      { id: uuidv4(), checked: true, value: "19" },
      { id: uuidv4(), checked: false, value: "7" },
    ]
    const {imageInput}=this.state
    imageInput.inputs[this.state.selectedImageIndex].description =imageInput
    .inputs[this.state.selectedImageIndex].description
    ?imageInput.inputs[this.state.selectedImageIndex].description
    : "";
 
    if(!imageInput.inputs[this.state.selectedImageIndex].VATPercent){
      imageInput.inputs[this.state.selectedImageIndex].VATPercent=VATPercent

    }
    return (
      <div className="component-container option-component"> 
        <button
          className={`price-selection ${
            this.state.priceOptionSelected ? "selected" : ""
          }`}
          onClick={this.setPriceOptionSelected}
        >
          {this.context.currencyFormat}
        </button>
        <button
          className={`tag-selection ${
            this.state.tagOptionSelected ? "selected" : ""
          }`}
          onClick={this.setTagOptionSelected}
        >
          <img
            src={this.state.tagOptionSelected ? tagInverseIcon : tagIcon}
            alt=""
          />
        </button>
        <img
            src={crossSmallIcon}
            onClick={this.handleClose}
            alt=""
            style={{
              float:"right",
              marginRight:"2px",
              marginTop:"-28px",
              width:"13px",
              height:"13px"
            }}
          />
        {this.state.priceOptionSelected && (
          <div className="price-options">
             {this.state.imageInput.inputs[this.state.selectedImageIndex]
                .price !== "" && (
                <button className="clear-price" style={{marginBottom:"20px"}} onClick={this.clearPrice}>
                  <img src={minusIcon} alt="" />
                </button>
              )}
            <div className="price-input">
              {/* <input
                type="number"
                value={
                  this.state.imageInput.inputs[this.state.selectedImageIndex]
                    .price
                }
                onChange={this.setimageInputPrice}
                placeholder="00.00"
                 style={(this.functionPriceValidation()) && {border:"1px solid red"}}
              /> */}
              <div style={{ justifyContent: "center", display: "flex" }} >
              <div
                    style={{ display: "flex", justifyContent: "flex-start",
                    width: "min-content",
                    height: "min-content",
                    marginLeft: "103px",
                    color:"rgb(149, 150, 155)",
                    fontSize:"15px",
                    fontWeight:"300"           
                  }}
                  >
                <h6>{this.props.t('Netto')}</h6> 
                </div>
                <div style={{background:this.context.priceType==="Netto" ? "rgb(170, 172, 175)":"#e6e7e8",
                // marginRight:"20px",
                marginLeft:"-40px",
                marginTop:"20px",
                 content:this.context.currencyFormat}} className="netto-brutto">
                 
                  <div style={{display:"flex"}}>
                 <span style={{marginTop:"5px",fontSize:"20px" ,color:"#939598"}}>{this.context.currencyFormat}</span>
                  <input
                  id="euro"
                  type="tel"
                    onChange={this.onNettoChange}
                    // value={this.state.imageInput.inputs[this.state.selectedImageIndex].netto ?
                    //   this.state.imageInput.inputs[this.state.selectedImageIndex].netto:""
                    // }
                    value={this.convertedNettoValues()}
                    onFocus={()=>{
                      this.setState({bruttoChange:false})
                    }}
                    placeholder={this.context.languageFormat==="en" ? "00.00" : "00,00"}
                    style={
                      this.functionPriceValidation() ? {
                        border: "1px solid red",
                        background:this.context.priceType==="Netto" ? "rgb(170, 172, 175)":"#e6e7e8",
                        color:this.context.priceType==="Brutto" ? "#939598":"black",
                        fontSize:"25px"
                      }:{
                        background:this.context.priceType==="Netto" ? "rgb(170, 172, 175)":"#e6e7e8",
                        color:this.context.priceType==="Brutto" ? "#939598":"black",
                        fontSize:"25px"
                      }
                    }
                    onKeyPress={(evt) => {
                      var charCode = evt.which ? evt.which : evt.keyCode;
                      let inputFormat= "";
                      inputFormat = (this.context.languageFormat==="en") ? charCode !== 46 : charCode !== 44 
                      if (inputFormat &&
                        charCode > 31 &&
                        (charCode < 48 || charCode > 57)  
                      ) {
                        console.log("in alse")
                        // return false;
                        evt.preventDefault();
                      }
                      

                      return true;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") this.onEnterHitAtNetto(e);
                    }}
                    // disabled={this.context.priceType=="Brutto" && true}
                    autoComplete="off"
                  />
                  </div>
                </div>
                <div
                    style={{ display: "flex", justifyContent: "flex-start",
                    width: "min-content",
                    height: "min-content",
                    marginLeft: "25px",
                    color:"#939598",
                    fontSize:"15px",
                    fontWeight:"300" 
                  
                  }}
                  >
                    <h6>{this.props.t('Brutto')}   </h6> 
                  </div>
                <div style={{background:this.context.priceType==="Brutto" ? "rgb(170, 172, 175)":"#e6e7e8", content:this.context.currencyFormat,
                marginRight:"88px",
                marginLeft:"-40px",
                marginTop:"20px",}}  className="netto-brutto">
                
                  <div style={{display:"flex"}}>
                 <span style={{marginTop:"5px",fontSize:"20px",color:"#939598"}}>{this.context.currencyFormat}</span>
                  <input
                   className="euro"
                   type="tel"
                    // value={this.state.imageInput.inputs[this.state.selectedImageIndex].brutto?
                    //   this.state.imageInput.inputs[this.state.selectedImageIndex].brutto:""
                    // }
                    value={this.convertedBruttoValues()}
                    onChange={this.onBruttoChange}
                    onFocus={()=>{
                      this.setState({bruttoChange:true})
                    }}
                    placeholder={this.context.languageFormat==="en" ? "00.00" : "00,00"}
                    style={
                      this.functionPriceValidation() ? {
                        border: "1px solid red",
                        background:this.context.priceType==="Brutto" ? "rgb(170, 172, 175)":"#e6e7e8",
                        color:this.context.priceType==="Netto" ? "#939598":"black",
                        fontSize:"25px"
                      }:{
                        background:this.context.priceType==="Brutto" ? "rgb(170, 172, 175)":"#e6e7e8",
                        color:this.context.priceType==="Netto" ? "#939598":"black",
                        fontSize:"25px"
                      }
                    }
                    onKeyPress={(evt) => {
                      var charCode = evt.which ? evt.which : evt.keyCode;
                      let inputFormat= "";
                      inputFormat = (this.context.languageFormat==="en") ? charCode !== 46 : charCode !== 44 
                      if (inputFormat &&
                        charCode > 31 &&
                        (charCode < 48 || charCode > 57)  
                      ) {
                        console.log("in alse")
                        // return false;
                        evt.preventDefault();
                      }
                      

                      return true;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") this.onEnterHitAtBrutto(e);
                    }}
                    // disabled={this.context.priceType=="Netto" && true}
                    autoComplete="off"
                  />
                </div>
                </div>
              </div>
              <br />

              <div className="row" style={{marginLeft:"0px",marginRight:"66px"}}>
            
              { this.state.imageInput.inputs[this.state.selectedImageIndex].VATPercent?.map((per,index)=>{
              return <div className="col" style={{ marginLeft: "56px" ,marginRight: "-34px"}}>
                <label style={{display:"flex"}} className="calculate">
              
                 <div style={per.checked ? styles.selected:styles.circle}  onClick={()=>this.setCheckedPercentage(per)}/>
                 <div style={{display:"flex"}}>
              <input
              style={{background:"#d0d2d4",width: "36px",
              color: "#939598",paddingLeft:"10px"}}
                type="text"
                value={per.value}
                onChange={(e)=>{this.setVAT(e,per)}}
                placeholder={this.context.languageFormat==="en" ? "00.00" : "00,00"}
                onKeyPress={(evt) => {
                  var charCode = evt.which ? evt.which : evt.keyCode;
                  // console.log("charcode",charCode)
                  if (
                    charCode !== 46 &&
                    charCode > 31 &&
                    (charCode < 48 || charCode > 57)
                  ) {
                    // console.log("in alse")
                    // return false;
                    evt.preventDefault();
                  }

                  return true;
                }}
                autoComplete="off"
              />
              <span style={{fontSize:"20px" ,color:"#939598" }}>%</span> 
              {index>1 && (
                          <img
                          src={crossSmallIcon}
                          onClick={()=>this.removeVATPercentage(index)}
                          alt=""
                          style={{
                            float: "right",
                            width: "10px",
                            height: "10px"
                          }}
                        />         
                         )   
                       }               
              </div>
              </label>
              </div>
              })}
        <div className="col">
        <img src={addIcon} alt="" onClick={this.addVATPercentage}/>
               
         </div>

              </div>
             
            </div>
            <div className="row" style={{display:"block",margin:"auto"}}>
            <div style={{}} >
                      <h6 style={{color:"#939598"}}>{this.props.t('Invoice Text')}</h6>
                      <input
                      maxLength="50"
                      className="invoice-text"
                        type="text"
                        placeholder={this.props.t('Invoice Text')}
                        value={
                          this.state.imageInput.inputs[this.state.selectedImageIndex].description 
                        }
                        onChange={this.setInvoiceText}
                        autoComplete="off"
                      />
                    </div>
                    </div>
            <div className="plus-icon">
              <img src={plusIcon} alt="" />
            </div>
            {this.renderPriceOption(
              this.state.imageInput.inputs[this.state.selectedImageIndex]
                .priceType
            )}
            {this.state.imageInput.inputs[this.state.selectedImageIndex]
              .priceType === "" && (
              <>
                <button
                  className="price-option-btn"
                  onClick={() => this.selectPriceOption("flat-rate")}
                >
               {this.props.t('flat_rate')}  
                </button>
                <button
                  className="price-option-btn"
                  onClick={() => this.selectPriceOption("number-guests")}
                >
                 {this.props.t('Anzahl')}  
                </button>
                <button
                  className="price-option-btn"
                  onClick={() => this.selectPriceOption("numerically")}
                >
                  {this.props.t('Numerically')}  
                </button>
              </>
            )}
          </div>
        )}
        {this.state.tagOptionSelected && (
          <div className="tag-options">
            <div className="select-container">
                  <CreatableSelect
                    className="tag-select"
                    classNamePrefix="tag"
                    // isClearable
                    isMulti
                    value={this.state.imageInput.inputs[
                      this.state.selectedImageIndex
                    ].tags}
                    onChange={this.handleTagChange}
                    options={this.state.tagOptions}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeperator: () => null
                    }}
                    // value={{ label: tag }}
                  />
                  {/* {index !== 0 && (
                    <button
                      className="remove-tag"
                      onClick={() => this.removeTag(index)}
                    >
                      <img src={deleteIcon} alt="" />
                    </button>
                  )}
                  {tag !== "" &&
                    index ===
                      this.state.imageInput.inputs[
                        this.state.selectedImageIndex
                      ].tags.length -
                        1 && (
                      <button className="add-tag" onClick={this.addTagSelector}>
                        <img src={plusIcon} alt="" />
                      </button>
                    )} */}
                </div>
             
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation() (RadioOptionConfiguration);
const styles={
  selected: {
    background: `url(${crossSmallIcon}),rgb(230, 231, 232) none repeat scroll 0% 0%`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    // width: "24px",
    // height: "24px",
    minWidth: "18px",
    minHeight: "20px",
    height:"20px",
    display: "inline-block",
    textAlign: "center",
    // background: "transparent",
    // borderRadius: "12px",
    // border: "1px solid black",
    outline: "none",
    cursor: "pointer",
  },
  circle: {
    minWidth: "18px",
    minHeight: "20px",
    height:"20px",
    display: "inline-block",
    textAlign: "center",
    background: "rgb(230, 231, 232) none repeat scroll 0% 0%",
    // borderRadius: "12px",
    // border: "1px solid black",
    outline: "none",
    cursor: "pointer",
  },
}
import React, {Component} from 'react';
import {StateContext} from "../../context/state";
import DropDown from "./DropDown";
import crossIcon from "../../assets/cross.svg";
import { withTranslation} from 'react-i18next';

 class Header extends Component {
    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.messagesRef=React.createRef(null);
        this.state = {
            hovered: false,
            
        };
    }
    componentDidMount() {
        if (this.messagesRef.current) {
            this.messagesRef.current.scrollIntoView({
                behavior: 'smooth',
               block: 'end',
               inline: 'nearest'
             });
        }
      }
  render() {
        const {header, name, headers, onSelect, onRemove, disabled,columnIndex,data,original} = this.props;
        const {hovered} = this.state;
     
        // console.log("dtaa",data)
        return (
            
            <div
                ref={this.messagesRef}
                style={styles.header}
                onMouseEnter={() => this.setState({hovered: true})}
                onMouseLeave={() => this.setState({hovered: false})}
            >
             
                {hovered && !disabled && columnIndex!==0 && <img style={styles.closeIcon} src={crossIcon} onClick={() => onRemove(header)} alt="" />}
                <DropDown
                    ref={this.messagesRef}
                    placeholder={this.props.t("Column Name")}
                    setHeaderData={this.props.setHeaderData}
                    options={headers}
                    value={header?._id}
                    name={name}
                    onSelect={(header, index) => {
                        this.setState({hovered: false});
                        onSelect(header, index)
                    }}
                    containerStyle={{
                        width: "100%",
                        marginRight: "5px"
                    }}
                    style={{
                        width: "100%"
                    }}
                    data={data}
                    original={original}
                    disabled={disabled}
                    showIndicator={false}
                    showDropdownOptions={this.props.showDropdownOptions ?? false}
                    onSortTable={this.props.onSortTable ?? false}
                    hideSort={this.props.hideSort ?? false}
                />
            </div>
        );
    }
}

export default withTranslation() (Header);

const styles = {
    header: {
        border: 0,
        margin: 0,
        minWidth: "150px",
        fontSize: "14px",
        background: "#d0d3d4",
        padding: "10px 5px",
        alignItems: "center",
        display: "flex",
        position: "relative"
    },
    headerText: {
        padding: "10px 5px",
        width: '100%',
        background: "#e7e7e8",
    },
    closeIcon: {
        position: 'absolute',
        top: 2,
        right: 2,
        width: '16px',
        height: '16px',
        cursor: 'pointer',
    }
};


import React, { useCallback, useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import swal from "sweetalert";
import { baseUrl } from "../../Constants";
import { useTranslation } from "react-i18next";
import uploadIcon from "../../assets/upload.svg";
const imageMaxSize = 10240;

function UploadConfiguratorLandingDropzone(props) {
  const { t } = useTranslation();
  const [style, setStyle] = useState({ backgroundImage: "" });
  const [showControls, setShowControls] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
 
  // if(props.isLoading !== undefined){
  //   setIsLoading(props.isLoading);
  // } 

  const onDrop = useCallback((acceptedFiles, rejected) => {
    setIsLoading(true);
    let isValidSize = true;
    acceptedFiles.forEach(file => {
      const currentFileSize = Math.round(file.size / 1024);
      if (currentFileSize > imageMaxSize) {
        isValidSize = false;
      }
    });

    if(isValidSize) {
      // console.log("onDrop", acceptedFiles, rejected )
      acceptedFiles.forEach(file => {
        //const preview = URL.createObjectURL(file);
        //setStyle({ backgroundImage: `url(${preview})` });
        //setShowControls(false);
        const data = new FormData();
        data.append("blob", file);
        return axios
          .post(baseUrl + `api/blob/${3}`, data, {
            headers: { "Content-Type": "multipart/form-data" }
          })
          .then(res => {
            // console.log(res);
            setStyle({ backgroundImage: `url(${res.data.url})` });
            setShowControls(false);
            const params = {
              url: res.data.url,
              name: file.name,
              type: "image",
              checked:false
            };
            props.acceptUploadToMediaLibrary(params);
          })
          .catch(error => {
            console.log(error);
          });
      });
    } else {
      swal(t('Bilder bis 15 MB sind erlaubt'), "", "info");
      setIsLoading(false);
      return;
    }
  }, [props, t]);

  const { getRootProps, getInputProps } = useDropzone({
     accept:"image/*, application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf",
    // accept: "image/jpg, image/jpeg, image/png",
    // multiple: false,
    onDrop
  });

  return (
    <div {...getRootProps({ className: "dropzone" })} style={style}>
      <input {...getInputProps()} autoComplete="off" />
      {showControls && (
        <>
          {isLoading ? (
            <div className="loader loader-text">Loading ...</div>
          ) : (
            <>
              <img src={uploadIcon} alt="" />
              <p>
                Drag and drop a file here <br />
                or
              </p>
              <button>Choose file</button>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default UploadConfiguratorLandingDropzone;

import React, {Component} from "react";
import {
    editIcon,
    editInverseIcon,
    fileCheckedIcon,
    fileCheckedInverseIcon,
    fileIcon,
    fileInverseIcon,
    reportInversePage,
    reportPage,
    userIcon,
    userInverseIcon,
    eventIcon,
    eventInverseIcon,
    settingIcon,
    logoutIcon,
    settingsWhite
} from "../../assets"
import venueDarkIcon from "../../assets/venue-dark.svg"
import venueWhiteIcon from "../../assets/venue-white.svg"
import {StateContext} from "../../context/state";
import {withRouter} from 'react-router-dom';
import {allowedRoles} from "../screens/AdminDashboard";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { withTranslation} from 'react-i18next';

class TabBar extends Component {
    static contextType = StateContext;
    base = "/admin";

    tabs = [
        {
            id: "events",
            name: `${this.base}/events`,
            icon: eventIcon,
            selectedIcon: eventInverseIcon,
            tooltipText:"Event"
        }, {
            id: "statistics",
            name: `${this.base}/stats`,
            icon: reportPage,
            selectedIcon: reportInversePage,
            tooltipText:"Controlling"
        }, {
            id: "users",
            name: `${this.base}/users`,
            icon: userIcon,
            selectedIcon: userInverseIcon,
            tooltipText:"Benutzer"
        }, {
            id: "settings",
            name: `${this.base}/settings`,
            icon: editIcon,
            selectedIcon: editInverseIcon,
            tooltipText:"Einstellungen"
        }, {
            id: "questionnaires",
            name: `${this.base}/questionnaire`,
            icon: fileIcon,
            selectedIcon: fileInverseIcon,
            tooltipText:"Fragebogen"
        }, {
            id: "plans",
            name: `${this.base}/plans`,
            icon: fileCheckedIcon,
            selectedIcon: fileCheckedInverseIcon,
            tooltipText:"Organisationsplan"
        }, {
            id: "venues",
            name: `${this.base}/venues`,
            icon: venueDarkIcon,
            selectedIcon: venueWhiteIcon,
            tooltipText:"Veranstaltungsort"
        },
        {
            id: "settingsmain",
            name: `/admin/settings-new`,
            icon: settingIcon,
            selectedIcon: settingsWhite,
            tooltipText:"settings"
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: this.tabs.filter((tab) => tab.name === (props.history?.location?.pathname ?? "").toLowerCase())?.[0]?.id ?? "events",
            allowedTabs: [],
            isOverflowing:false,
            viewArrowUp:false,
            width:0,
            height:0
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        const {userRole} = this.context;

        this.setState({
            allowedTabs: this.tabs.filter(tab => allowedRoles[tab.id]?.includes(userRole) ?? true)
        });

        this.props.history.listen((location) => {
            this.setState({
                selectedTab: this.tabs.filter((tab) => tab.name === (location?.pathname ?? "").toLowerCase())?.[0]?.id
            })
            this.context.updateState({authenticated:true})
        })
        
        this.checkOverflow()
         document.addEventListener('wheel',this.checkOverflow)
         this.updateWindowDimensions();
         window.addEventListener('resize', this.updateWindowDimensions);
         
            
    }
   
    componentDidUpdate() {
        
            let element = document.getElementById('isSelected')
            element.scrollIntoView()
    
    }
   
    componentWillUnmount(){
        window.removeEventListener('resize', this.updateWindowDimensions);
         return(
             document.removeEventListener('wheel',this.checkOverflow)
             
         )  
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    checkOverflow =()=>{
        let isOverflowing = this.state.height < 852;
        this.setState({
            isOverflowing:isOverflowing
        })
    }

    handleArrowPress=(event,direction)=>{
        event.preventDefault()
        const ele = document.getElementById('topIcon')
        if(direction==='down'){
            ele.scroll({
                top:700,
                behavior:"smooth"
            })
        } else{
            ele.scroll({
                top:0,
                behavior:"smooth"
            })
        }
        
    }

    onTabSelect = (tab) => {
        this.setState({selectedTab: tab.id});
        this.props.history.push(`${tab.name}`);
    };
    

    handleLogout = () => {
        const urlName = this.context.urlName;
        this.context.updateState({
          authenticated: false,
          from:"logout"
        });
        localStorage.clear();
        sessionStorage.clear();
        if (urlName === "adminScreen") {
          this.props.history.push(`/login`);
        } else {
          this.props.history.push(`/login/${urlName}`);
        }
    };

    // eslint-disable-next-line array-callback-return
    renderTabs = (which) => this.state.allowedTabs.map((tab, index) =>
        {
        if(which==='top'){
        if(index<=this.state.allowedTabs.length-2){
        return (
					<div
						key={index}
						id={this.state.selectedTab === tab.id ? 'isSelected' : ''}
						className="navItem"
						style={{
							...styles.item,
							background: `${
								this.state.selectedTab === tab.id ? '#95969b' : 'transparent'
							}`,
							boxShadow: `${
								this.state.selectedTab === tab.id ? '0 0 10px #95969b75' : 'transparent'
							}`,
						}}
						onClick={() => this.onTabSelect(tab)}>
						<OverlayTrigger
                    key="right"
                    placement="right"
                    overlay={
                        <Tooltip id={`tooltip-disabled`}>
                            <span>{this.props.t(tab.tooltipText)}</span>
                        </Tooltip>
                    }>
							<img
								className="navImage"
								style={styles.image}
								src={
									this.state.selectedTab === tab.id
										? tab.selectedIcon
										: tab.icon
								}
								alt={tab.name}
							/>
						</OverlayTrigger>
					</div>
				);}}
        if(which==='bottom'){
            if(this.state.allowedTabs.length-2<index){
            return(
            <div
                key={index}
                id={this.state.selectedTab === tab.id? 'isSelected':''}
                className="navItem"
                style={{
                    ...styles.item,
                    background: `${this.state.selectedTab === tab.id ? "#95969b" : "transparent"}`

                }}
                onClick={() => this.onTabSelect(tab)}>
                    <OverlayTrigger
                        key="right"
                        placement="right"
                        overlay={
                          <Tooltip id={`tooltip-disabled`}>
                           <span>{this.props.t(tab.tooltipText)}</span>
                          </Tooltip>
                        }
                      >
                <img
                    style={styles.image}
                    className="navImage"
                    src={this.state.selectedTab === tab.id ? tab.selectedIcon : tab.icon}
                    alt={tab.name} />
                    </OverlayTrigger>
            </div>)}}    
    });

    render() {
        return (
            <div style={styles.container} >
                {/* {this.state.isOverflowing  && <div style={styles.arrowWrapper} className="arrowWrapper" onClick={(e)=> this.handleArrowPress(e,'up')}><img style={styles.arrowIconUp} className="navArrow" src={arrowdown}></img></div>} */}
                <div style={styles.topIcon}  className="topIcon" id="topIcon" >
                    {this.renderTabs('top')}
                </div>
                {/* {this.state.isOverflowing && <div style={styles.arrowWrapper} className="arrowWrapper" onClick={(e)=> this.handleArrowPress(e,'down')}><img style={styles.arrowIcon} className="navArrow" src={arrowdown}></img></div>} */}
                <div style={styles.bottomIcon}>
                    {/* <p className="mb-2" style={styles.pointerIcon} onClick={() => { this.props.history.push(`/admin/settings-new`) }}><img src={settingIcon} /></p> */}
                    <p className="mb-2" style={styles.pointerIcon} onClick={() => { this.handleLogout() }}><img alt={"logout"} src={logoutIcon} /></p>
                </div>
            </div>
        );
    }
}

export default  withTranslation() (withRouter(TabBar));

const styles = {
	container: {
		zIndex: 2,
		height: '100vh',
		width: 'auto',
		background: '#d0d3d4',
		display: 'flex',
		flexDirection: 'column',
		paddingTop: '30px',
		position: 'fixed',
        boxShadow: '0px 0px 5px #dadada',
        borderRight: "0.05px solid #70707015"
		// top:0,
	},
	item: {
		display: 'block',
		padding: '12px',
		cursor: 'pointer',
	},
	image: {
		width: '40px',
		height: '40px',
	},
	topIcon: {
		display: 'flex',
		flexDirection: 'column',
		height: 'auto',
		overflow: 'scroll',
        scrollbarWidth: 'none',
        paddingTop: "10px",
        paddingBottom: "10px"
	},
	bottomIcon: {
		position: 'fixed',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		bottom: '0',
		background: 'rgb(211, 212, 214)',
	},
	pointerIcon: {
		padding: '17px',
		cursor: 'pointer',
	},
	arrowIcon: {
		width: '40px',
		height: '40px',
		cursor: 'pointer',
	},
	arrowIconUp: {
		width: '40px',
		height: '40px',
		cursor: 'pointer',
		transform: 'rotate(180deg)',
		paddingBottom: '5px',
	},
	arrowWrapper: {
		display: 'flex',
		flexDirection: 'column',
		padding: '12px',
		position: 'relative',
	},
};

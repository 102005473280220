import React, { Component } from "react";
import plusIcon from "../../assets/plus.svg";
import deleteIcon from "../../assets/trash.svg";
import TableSection, { Header } from "../partials/TableSection";
import userInfo from "../../assets/profile-card.svg";
import fileIcon from "../../assets/file.svg";
import editIcon from "../../assets/edit.svg";
import { StateContext } from "../../context/state";
import HttpService from "../../services/HttpService";
import swal from "sweetalert";
import Text from "../preview/Text";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {withTranslation} from 'react-i18next';
const headers = [
  {
    name: "Nachname",
    value: "surname1",
    type: Header.TEXT,
  },
  {
    name: "Vorname",
    value: "firstName1",
    type: Header.TEXT,
  },
  {
    name: "Tagungsort",
    value: "venueName",
    type: Header.TEXT,
    // visible: "SA",
  },
  {
    name: "Fragebogen",
    value: "questionnaire",
    type: Header.TEXT,
    // visible: "w/o SA",
  },
  {
    name: "Rolle",
    value: "role",
    type: Header.TEXT,
  },
  {
    name: "Events",
    value: "events",
    type: Header.TEXT,
  },
];

 class UsersPage extends Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: "Loading...",
      users: [],
      // filteredData:[]
    };
  }
  // FilteredData=(data)=>{
  //     //  console.log("data",data)
  //       this.setState({
  //         filteredData:data
  //       })
  //       }
  componentDidMount() {
    const { venueId, userRole } = this.context;

    HttpService.getUsers(venueId, userRole)
      .then((response) => {
        //    console.log("response",response)
        const { venueUsers } = response?.[0];
        // console.log("venueUserss",venueUserss)
        //  const venueUsers=Object.assign([],response)
        const users = Object.assign([], venueUsers);

        // eslint-disable-next-line array-callback-return
        let newUsers = users.filter((user) => {
          if (user.role === "VV") {
            return (user.role = "EM"); //Event Manager
          } else if (user.role === "CU") {
            return (user.role = "KU"); //Customer
          } else if (user.role === "VM") {
            return (user.role = "LM"); //Location manager
          } else if (user.role === "VA") {
            return (user.role = "VA");
          }
        });
        //    console.log("users",newUsers)
        this.setState({
          loading: undefined,
          users: newUsers,
        });
      })
      .catch((error) => {
        // console.log("error",error)
        this.setState({
          loading: JSON.stringify(error),
        });
      });
  }

  async fetchKeys(range) {
    const { venueId, userRole, loggedInUserId } = this.context;

    try {
      const keyFigures = await HttpService.fetchKeyFigures();
      const userKey = await HttpService.fetchUserKeyFigures(loggedInUserId);
      const promises = [];

      const startDate = range?.fromDate ?? "";
      const endDate = range?.toDate ?? "";

      keyFigures.forEach((key, index) => {
        const selected = userKey?.keys
          ? userKey?.keys?.includes(key.id)
          : index < 4;
        if (selected) {
          promises.push(
            HttpService.fetchKeyFigure({
              keyFigId: key.id,
              venueId: venueId,
              userRole: userRole,
              fromDate: startDate,
              toDate: endDate,
            }).then((value) => {
              keyFigures[index] = { ...key, selected, value };
            })
          );
        } else {
          keyFigures[index] = { ...key, selected, value: 0 };
        }
      });

      Promise.all(promises)
        .then((res) => {
          localStorage.setItem("KeyFig", JSON.stringify(keyFigures));
          this.setState({
            keyFigures,
            // userKey,
            // fromDate : startDate,
            // toDate : endDate
          });
          // window.location.reload()
          this.context.updateState({ keyFig: keyFigures });
        })
        .catch((err) => {
          this.setState({ loading: JSON.stringify(err) });
        });
    } catch (error) {
      this.setState({
        loading: JSON.stringify(error),
      });
    }
  }
  deleteQuestionnaire = (user) => {
    HttpService.deleteUser(user)
      .then((res) => {
        let { users } = this.state;
        users = users.filter((u) => u.userId !== user.userId);


        this.setState({ users }, () => {});
        this.context.updateState({ keyFig: "" });
      })
      .catch((err) => JSON.stringify(err));
  };
  render() {
    const header2 = headers.filter((header) => header.name !== "Tagungsort");
const header3=headers.filter((header) => header.name !== "Fragebogen")
    let newHeaders = [];
    if (this.context.userRole !== "SA") {
      newHeaders = header2;
    } else {
      newHeaders = header3;
    }

    const { loading, users } = this.state;

    if (loading) {
      return (
        <div style={styles.row}>
          <div className="col-1" />
          <div className="col-10">
            <Text value={this.props.t("Loading")} />
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div style={styles.row}>
          <div className="col-1"></div>
          <div className="col-10">
            <div
              style={{ color: "#524f4f", fontSize: "24px", marginTop: "20px" }}
            >
              {this.props.t('Benutzer')}{" "}
            </div>
            <div style={styles.divider} />
          </div>
        </div>
        <div style={styles.row}>
          <div className="col-11" style={styles.addWrapper}>
          <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-disabled`}>
                    <span> {this.props.t('Create_user')}</span>
                  </Tooltip>
                }
              >
            <img
              style={styles.pointer}
              src={plusIcon}
              alt=""
              onClick={() => {
                this.props.history.push("/admin/userInfo");
              }}
            />
               </OverlayTrigger>
          </div>
        </div>

        <div style={styles.row}>
          <div className={"col-1"} />
          <div className={"col-10"}>
            <TableSection
              headers={newHeaders}
              data={users}
              buttons={this.buttons}
              buttonCount={4}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  toUserDetailsPage = (state) => {
    this.props.history.push("/admin/userInfo", state);
  };

  toEventsPage = (user) => {
    this.context.updateState({ userId: user.userId });
    this.props.history.push("/login-landing");
  };

  buttons = [
    {
      id: 1,
      name: "userInfo",
      icon: userInfo,
      // tooltipText:"Benutzerinformation",
      tooltipText: "Anzeige",
      style: { width: "30px" },
      onPress: (user) => this.toUserDetailsPage({ user, disabled: true }),
    },
    {
      id: 2,
      name: "edit",
      icon: editIcon,
      tooltipText: "Bearbeiten",
      onPress: (user) => this.toUserDetailsPage({ user, disabled: false }),
    },
    {
      id: 3,
      name: "events",
      icon: fileIcon,
      tooltipText: "Kundenbereich",
      onPress: this.toEventsPage,
      isVisible: (user) => user.role === "CU",
    },
    {
      id: 4,
      name: "dummy",
      icon: fileIcon,
      tooltipText: "Kopieren",
      onPress: () => true,
      isVisible: () => false,
    },
    {
      id: 5,
      name: "delete",
      icon: deleteIcon,
      tooltipText: "Löschen",
      onPress: (user, index) => {
        swal({
          title:`${this.props.t('Bist du sicher?')}`,
          text:
          `${this.props.t('Einmal gelöscht, können Sie diesen Benutzer nicht wiederherstellen!!')}`,
          buttons: [`${this.props.t('Abbrechen')}`, "OK"],
          // buttons: true,
          dangerMode: true,
        })
          .then((isConfirmed) => {
            if (isConfirmed) {
              this.deleteQuestionnaire(user);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  ];
}

export default withTranslation() (UsersPage); 

const styles = {
  row: {
    display: "flex",
    flexWrap: "wrap",
  },
  addWrapper: {
    marginLeft: "40px",
  },
  pointer: {
    cursor: "pointer",
  },
  divider: {
    borderTop: "1px solid #d0d3d4",
    margin: "30px 0 0px 0px",
    paddingTop: "10px",
  },
};

import React, { Component } from "react";
import { StateContext } from "../../context/state";
import PlanPreview from "./planPreview";


class PlanPreviewEmail extends Component {
    static contextType = StateContext;

    componentWillMount(){
        // console.log(" this.props.match.params.exportPlanId", this.props.match.params.exportPlanId)
        this.context.updateState({
            exportPlanId: this.props.match.params.exportPlanId,
            parentQuestionnaireId:this.props.match.params.parentQuestionnaireId,
            childQuestionnaireId:this.props.match.params.childQuestionnaireId,
            eventId:this.props.match.params.eventId
        });
    }

    render(){
        return(
            <PlanPreview allowed={true}
            exportPlanId={ this.props.match.params.exportPlanId}
            parentQuestionnaireId={this.props.match.params.parentQuestionnaireId}
            childQuestionnaireId={this.props.match.params.childQuestionnaireId}
            eventId={this.props.match.params.eventId}
            />
        )
    }
}

export default PlanPreviewEmail;
import React, { Component } from "react";
import { StateContext } from "../../context/state";
import download from "../../assets/download.png";
import { Formik, Form, Field } from "formik";
export default class OptionsForm extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    // console.log("room",this.props.room)
    this.state = {
      rooms: [],
      room: this.props.room,
    };
  }

  static getDerivedStateFromProps(nextProps) {}
  componentDidMount() {}

  render() {
    const { room } = this.props;
    // console.log("room",room)
    return (
      <React.Fragment>
        <div style={styles.container}>
          <span>Options</span>
          {/* {console.log("room",room)} */}
          {this.props.showRoom ? (
            <Formik
              enableReinitialize
              initialValues={{
                roomId: room.roomId,
                name: room.name,
                dimensions: room.dimensions,
                notes: room.notes,
                roomType: room.roomType,
              }}
              validate={(values) => {
                const errors = {};

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                // const obj=JSON.parse(sessionStorage.getItem("state2"))
                // values.childQuestionnaireId=obj.questionnaireId
                values.venueId = this.context.venueId;
                this.setState({ rooms: values });
                // console.log("values", values);

                this.props.addRooms(values);

                setSubmitting(false);
              }}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  <Field
                    type="name"
                    name="name"
                    // value={room.name}
                    placeholder="Room Name"
                    style={styles.input}
                  />
                  <br />
                  <Field
                    type="dimensions"
                    // onChange={this.handleChange}
                    name="dimensions"
                    // value={room.dimensions}
                    placeholder="Room Width X Length"
                    style={styles.input}
                  />
                  {/* <ErrorMessage name="email" component="div" /> */}
                  <br />
                  <Field
                    type="notes"
                    // onChange={this.handleChange}

                    name="notes"
                    placeholder="Note"
                    // value={room.notes}
                    component="textarea"
                    style={styles.input}
                  />
                  <br />
                  <div role="group" aria-labelledby="my-radio-group">
                    <label>
                      {/* {console.log('restricted',values)} */}
                      <Field
                        type="radio"
                        name="roomType"
                        // value={room.roomType}
                        value="restricted"
                      />
                      Restricted Area
                    </label>
                    <label>
                      <Field
                        type="radio"
                        name="roomType"
                        value="event"
                        // value={room.roomType}
                      />
                      Event Room
                    </label>
                  </div>

                  {/* <ErrorMessage name="password" component="div" /> */}

                  <button
                    type="submit"
                    className="outline-button speichern"
                    style={styles.outline}
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          ) : (
            <></>
          )}
          <img
            alt={"Download"}
            onClick={this.props.savePlan}
            style={{ width: "20px" }}
            src={download}
          />
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  title: {
    fontFamily: "Crete Round",
    fontSize: "19px",
    color: "#000",
    margin: "0px",
  },
  bold: {
    fontSize: "16px",
    margin: "0px",
    color: "#000",
    fontWeight: "600",
  },
  text: {
    fontSize: "16px",
    fontWeight: "300",
    margin: "0px",
    color: "#000",
  },
  outline: {
    color: "#297825",
    fontFamily: "Crete Round",
    fontSize: "13px",
    border: "1px solid #297825",
    // minWidth: "143px",
    padding: "9px 15px",
    textAlign: "center",
    display: "inline-block",
    marginRight: "15px",
    // float: "right",
  },
  cost: {
    fontSize: "40px",
    fontWeight: "600",
    color: "#000",
  },

  container: {
    // textAlign: "right",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    padding: "60px 10px 10px 20px",
    // width: "400px",
    background: "rgb(208, 211, 212) none repeat scroll 0% 0%",
  },
  pointer: {
    cursor: "pointer",
  },
  loader: {
    color: "#888888",
    fontWeight: "bold",
    fontSize: "20px",
    padding: "20px",
    textAlign: "center",
  },
  input: {
    marginBottom: "20px",
    border: "none",
    background: " #DEDEDE 0% 0% no-repeat padding-box",
  },
};

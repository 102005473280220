import React, { Component } from "react";
import HttpService from "../../services/HttpService";
import Text from "../preview/Text";
import { StateContext } from "../../context/state";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { withTranslation} from 'react-i18next';
import exp from "../../assets/link2.svg"
import axios from 'axios'
import { baseUrl } from '../../Constants'

class PullBar extends Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      error: undefined,
      loading: false,
      summary: {},
      guestInfo: {},
    };
  }
  componentDidMount() {
    const { questionnaireId } = this.context;

    HttpService.getGuests(questionnaireId).then(
      (response) => {
        // console.log("resp",response)
        this.setState({
          guestInfo: response.guestLabel,
        });
      }
    );
  }
  collapseToggle = () => {
    this.setState((prevState) => {
      if (prevState.collapsed) {
        this.getSummary();
      }

      return {
        collapsed: !prevState.collapsed,
      };
    });
  };

  async getSummary() {
    const { eventId, questionnaireId } = this.context;
    const { guestPriceObj } = this.props;
    const { guestInfo } = this.state;
    this.setState({
      loading: true,
    });

    let response = await HttpService.getSummary(eventId, questionnaireId);

    let newthemes = {};
    newthemes["name"] = "Gästepauschale";
    newthemes.services = [];
let name=""
    for (let key in guestPriceObj) {
      if (key === "adults") {
         name= guestInfo?.adults;
      } else if (key === "U12") {
         name=guestInfo?.U12;
      } else if (key === "U18") {
         name=guestInfo?.U18;
      } else if (key === "U6") {
         name=guestInfo?.U6;
      } 
      newthemes.services.push({ name: name, cost: guestPriceObj[key] });
    }

   

    // console.log("response",response)
    if (response.message) {
      this.setState({
        loading: false,
        error: JSON.stringify(response.message),
      });
      return;
    }

    this.setState({
      loading: false,
      summary: response,
    });
  }
  findLabel = (name) => {
    const { guestInfo } = this.state;

    if (name === "adults") {
      return guestInfo.adults;
    } else if (name === "U12") {
      return guestInfo.U12;
    } else if (name === "U18") {
      return guestInfo.U18;
    } else if (name === "U6") {
      return guestInfo.U6;
    } else {
      return null;
    }
  };
  calculateGuestTotal = (model) => {
    let sum = 0;

    Object.getOwnPropertyNames(model).forEach((key) => {
      if (Number(model[key])) sum += model[key];
    });

    return   Number(sum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };
  priceExportFunction = () => {
    //function to export prices
    const { questionnaireId, venueId } = this.context;
    //  const response = HttpService.createPricePdf(venueId,questionnaireId);
    //   const url = window.URL.createObjectURL(new Blob([response.pdfUrl])); 
    axios.post(`${baseUrl}api/invoice/price/${venueId}/${questionnaireId}`)
      .then((response) => {
        const link = document.createElement("a");
        link.href = response.data.pdfUrl;
        link.setAttribute("download", `file`);
        document.body.appendChild(link);
        window.open(link, "_blank");
      })
      .catch((err) => {
        console.log(err)
      }) 
  };

  renderGuestPriceContent = () => {
    const { guestPriceObj } = this.props;

    // eslint-disable-next-line array-callback-return
    return Object.keys(guestPriceObj).map((key, index) => {
      if (guestPriceObj[key])
        return (
          <React.Fragment key={index}>
            {Number(guestPriceObj[key]) ? (
              <>
                <br />
                <div style={{ ...styles.text }}>
                  {this.findLabel(key, index)}
                </div>
                <div>{`
                ${this.context.languageFormat==="en" ? Number(guestPriceObj[key]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") :Number(guestPriceObj[key]).toFixed(2)
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")??0}
                ${this.context.currencyFormat}`}</div>
              </>
            ) : (
              <>
                <br />
                <div style={{ ...styles.text }}>
                  {this.findLabel(key, index)}
                </div>
                <div
                  style={{ ...styles.bold, color: "red", marginBottom: "5px" }}
                >
                  {guestPriceObj[key]}
                </div>
              </>
            )}
          </React.Fragment>
        );
    });
  };
  priceRefesh=async()=>{
    const {questionnaireId,loggedInUserId}=this.context
    const{activeTheme,activeService}=this.props
    
    await HttpService.uploadAnswer(
     questionnaireId,
     activeTheme.id,
     activeService.id,
     loggedInUserId,
     this.props.questionnaire
   );
   let questionnaire = await HttpService.getForms(
    questionnaireId,
    activeTheme.id,
    activeService.id
  );
  let themes = (await HttpService.getThemes(this.context.questionnaireId))
    ?.themeApiObject;
  
  themes = themes?.themes ?? [];
  for (let theme of themes)
    theme.services = theme?.services?.filter((service) => service?.display);
  themes = themes.filter((theme) => theme?.services?.length > 0);
  themes = [{ id: -1, name: "Anzahl Gäste", services: [] }, ...themes];
  
     let latestPrice = await HttpService.getPriceBarLatestData(questionnaireId);
     if(this.props.updatePriceState){
      this.props.updatePriceState(latestPrice,themes,questionnaire)
     }
    
   
  }
  renderThemes = () => {
   
    const themes = this.state.summary?.themes;

    return themes?.map((theme, index) => (
      <div key={index} className="col-md-3 list-item" style={styles.section}>
       
        {theme.name === "Gästepauschale" && this.props.guestPriceObj ? (
          <h5 style={{ fontFamily: "Crete Round",
          fontSize: "24px",
          fontStyle: "italic",
          marginBottom: "24px"}}>
           {this.props.t('Gästepauschale')} 
            {/* {this.calculateGuestTotal(this.props.guestPriceObj)} */}
          </h5>
        ) : (
          <h4>{this.props.t(theme?.name)}</h4>
        )}
        
        {theme?.services.map((service, _index) => (
           
          <>
          {/* {console.log("service?.cost",service)} */}
            {(Number(service?.cost)||(service?.cost!==undefined))&& (
              <React.Fragment key={_index}>
                <p className="item-name">{this.props.t(service?.name)}</p>
                <p className="item-price">{` 
                ${this.context.languageFormat==="en" ? Number(service?.cost).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") :Number(service?.cost).toFixed(2)
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                ${this.context.currencyFormat}`}</p>
            </React.Fragment>
            )}
          </>
        ))}
      </div>
    ));
  };

  render() {
    const collapsedClass = this.state.collapsed ? "collapsed" : "";

    return (
      <div>
        <OverlayTrigger
          key="left"
          placement="left"
          overlay={
            <Tooltip id={`tooltip-disabled`}>
              <span>
                {this.state.collapsed ?  this.props.t('Buchungsübersicht') : this.props.t('schließen')}
              </span>
            </Tooltip>
          }
        >
          <button
            className={`pull-bar-toggle ${collapsedClass}`}
            onClick={this.collapseToggle}
          >
            <span />
            <span />
            <span />
          </button>
        </OverlayTrigger>
        <div className={`pull-bar ${collapsedClass}`}>
          {this.renderContent()}
        </div>
      </div>
    );
  }

  renderContent = () => {
    if (this.state.loading) {
      return <Text value={this.props.t('Loading')} />;
    }

    if (this.state.error) {
      return <Text value={this.state.error} />;
    }

    return (
			<div className="container">
				<div className="row">
					<div className="col-lg-4 col-md-5 total-price">
						<h5>{this.props.t('event_price')} </h5>
						<h1>{`${
							this.context.languageFormat === 'en'
								? Number(this.state.summary?.totalAmount)
										.toFixed(2)
										.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
								: Number(this.state.summary?.totalAmount)
										.toFixed(2)
										.replace('.', ',')
										.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
						}
            ${this.context.currencyFormat}`}</h1>
						<div
							className="seperator"
							style={{
								background: this.context.stylingData?.lineColor
									? `${this.context.stylingData.lineColor}`
									: `${this.context.defaultSettingStyle?.presAccentColor}`,
							}}
						/>
						{this.props.guestPriceObj && (
            <>
              <h5>
                Gästepauschale:{" "}
                {this.calculateGuestTotal(this.props.guestPriceObj)}
              </h5>
              {this.renderGuestPriceContent()}
            </>
          )}
						<br />
					</div>
					<div className="col-lg-6 col-md-8" style={{ marginTop: '50px' }}>
						<OverlayTrigger
							// key="bottom"
							placement="top"
							overlay={
								<Tooltip id={`tooltip-disabled`}>
									<span>{this.props.t('export')}</span>
								</Tooltip>
							}>
							<button
								style={{
									border: 'none',
									marginTop: '10px',
									background: 'transparent',
								}}
								onClick={this.priceExportFunction}>
								{' '}
								<img
									src={exp}
									alt="export"
									style={{ width: '20px', height: '20px' }}
								/>
							</button>
						</OverlayTrigger>
					</div>
				</div>

				<div className="row">{this.renderThemes()}</div>

				{this.context?.stylingData !== undefined &&
					this.context?.stylingData?.hasOwnProperty('note') &&
					Object.keys(this.context?.stylingData?.note).length !== 0 && (
						<div style={{ display: 'flex' }}>
							{/* <span className="item-name" style={{fontSize:"20px",fontWeight:"bold",fontStyle:"italic"}}>Notizen:</span> */}
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<span className="item-name" style={{ marginTop: '4px' }}>
								{' '}
								{this.props.t(this.context.stylingData?.note.value)}
							</span>
						</div>
					)}
			</div>
		);
  };
}

export default withTranslation() (PullBar);
const styles = {
  section: {
    marginBottom: "60px",
  },
};

import React, { Component } from "react";
import { StateContext } from "../context/state";
import axios from 'axios'
import {baseUrl} from '../Constants.js'
import swal from 'sweetalert';
import { withTranslation } from 'react-i18next';
class ForgotPassword extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      urlName:!props?.match?.params?.name
              ? "adminScreen"
              : props.match.params.name.toLowerCase(),
      backgroundImg:""
    };
  }

  setBodyBackground = backgroundUrl => {
    let body = document.getElementsByTagName("body")[0];
    body.style.backgroundImage = `url(${backgroundUrl})`;
  };
  componentDidMount(){
    // let body = document.getElementsByTagName("body")[0];

    const name=this.state.urlName==="adminscreen" ? "adminScreen" : this.state.urlName
    axios.get(baseUrl + "api/venue/byName/" + name)
    .then(res => {
      const response=res.data
      // console.log("res", response)
      this.setBodyBackground(response.backgroundImgSrc);
      // setLoginJSON(response);
      // setReferer(/*props.location.state.referer ||*/ "/")
      this.setState({backgroundImg:response.backgroundImgSrc});
      // value.updateState({venueId:response._id})
    });
  }
  componentWillUnmount(){
    let body = document.getElementsByTagName("body")[0];
    body.style.backgroundImage = "";
  }
  setUserName = e => this.setState({ userName: e.target.value});
handleButton=(e)=>{
  e.preventDefault();
  const data={
    "venueId": this.context.venueId ,
    "emailAddress": this.state.userName,
    }
    // console.log('hi', data)
  axios.post(`${baseUrl}api/email/forgotPassword`, data)
  .then((res)=>{
    swal(res.data.status,"", "");
  })
  .catch((err)=>{
    console.log(err)
  })
}
  render() {
    return (
      <StateContext.Consumer>
        {({ authenticated, updateState }) => {
          return (
            <div className="container forget-password-section">
              <div className="row">
                <div className="col-sm-12">
                  <form className="form-group">
                    <h1> {this.props.t('Forgot_Password')}</h1>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={this.state.userName}
                      onChange={this.setUserName}
                      autoComplete="off"
                    />
                    <button className="reset-password-button" onClick={this.handleButton}>
                     {this.props.t('Reset_password')} 
                    </button>
                    <p className="reset-password-text">
                   {this.props.t('Reset_password_text')} 
                    
                    </p>
                  </form>
                </div>
              </div>
            </div>
          );
        }}
      </StateContext.Consumer>
    );
  }
}

export default withTranslation() (ForgotPassword);

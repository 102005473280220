import React, { Component } from 'react';
import {StateContext} from "../../context/state";
import axios from 'axios'
import { baseUrl } from '../../Constants.js'

class RadioOption2 extends Component {
    static contextType = StateContext;
    constructor(){
        super()
        this.state={
            data:{},
        }
    }
    componentDidMount(){
        const option= this.props.option
        axios.get(`${baseUrl}api/export/ServiceElement/${this.context.childQuestionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`)
        .then((res)=>{
            const d=res.data
            this.setState({
                data:d
            })
        })
        .catch((err)=>{
            console.log(err)
        })
    }
    
    render() {
        return (
            <div>
               <div className="qtn_optns">
                  { this.state.data.radioInput?.inputs && <>
                  <div 
                    // className="quest1"
                    >                     
                   {
                       this.state.data.radioInput.qtnAlternate?
                       <div>
                           {this.state.data.radioInput.qtnAlternate}
                        </div>
                        :
                        <div>
                            {this.state.data.radioInput.question}
                        </div>
                   }
                    </div>
                        </>
                        }
                </div>

                <div>
                    {this.state.data.radioInput?.inputs &&
                        this.state.data.radioInput.inputs.map((option,i)=>{
                            return <div className="qtn_optns" key={i}>
                                {
                                        (option.id===this.state.data?.answer.id)?
                                        <span style={styles.filledCircle} />
                                        :
                                        <span style={styles.circle} />

                                    }
                                <>
                                {
                                    (option.alternate)? 
                                    <div 
                                    // className="optn" 
                                    style={{padding: "2px 4px"}}
                                    >
                                    {option.alternate}
                                    </div>
                                :
                                    <div 
                                    // className="optn" 
                                    style={{padding: "2px 4px"}}
                                    >
                                    {option.value}
                                    </div>
                                }
                            
                                </>
                              
                            </div>
                        })
                    }
                </div>
              
            </div>
        );
    }
}

export default RadioOption2;
const styles={
    circle: {
        marginTop:"7px",
        width: "16px",
        height: "16px",
        display: "inline-block",
        textAlign: "center",
        background: "transparent",
        borderRadius: "50%",
        border: "1px solid #939598",
        outline: "none"
    },
    filledCircle: {
        marginTop:"7px",
        width: "16px",
        height: "16px",
        display: "inline-block",
        textAlign: "center",
        background: "transparent",
        borderRadius: "50%",
        backgroundColor:"#939598",
        border: "1px solid #939598",
        outline: "none"
    },
}
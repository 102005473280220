import React, { Component } from "react";
import Accordion from "../Accordion";
import HttpService from "../../services/HttpService";
// import PriceBar from "../partials/PriceBar";

import { StateContext } from "../../context/state";
// import { Link } from "react-router-dom";
import logoutIcon from "../../assets/logout.svg";
import home from "../../assets/home.svg";

import axios from "axios";
import { baseUrl } from "../../Constants";
import ImageComposer from "./Preview"
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";

import "moment-timezone";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, convertFromRaw } from "draft-js";
import optionIcon from "../../assets/option.svg";
import optionFilledIcon from "../../assets/option-filled.svg";

import GuestPriceConfigurations from "./GuestPriceConfiguration";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { withTranslation} from 'react-i18next';

 class GuestsPg extends Component {
  static contextType = StateContext;
  images = {};

  constructor(props, context) {
    super(props, context);
    this.state = {
      newEntry: true,
      guests: {},
      image: "",
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      loading: true,
      activeTheme: { id: -1, name:"Anzahl Gäste", services: [] },
      activeService: undefined,
      questionnaire: {},
      questFetchStatus: undefined,
      event: {},
      title: { value: EditorState.createEmpty(), hideTextToolbar: true },
      para: { id: 1, value: EditorState.createEmpty(), hideTextToolbar: true },
      paragraphs: [],
      guestLabel: {
         adults: "Anzahl Erwachsene",
        U6: "Anzahl Kleinkinder",
        U12: " Anzahl Kinder",
        U18: "Anzahl Jugendliche",
      },
      guestInfo: {
        adults: true,
        U6: true,
        U12: true,
        U18: true,
      },
      pricing: [
        {
          uniqueId:uuidv4(),
          id: 1,
          value: "adults",
          price: "",
          priceType: "", //flat-rate, number-guests, numerically
          priceTypeId: null,
          tags: [],
          numerically: {
            minimumNumber: "",
            minimumNumberCalculate: false,
            maximumNumber: "",
            maximumNumberCalculate: false,
            stage: "",
            relay1: "",
            relay1Alle: false,
            relay1Price: "",
            relay2: "",
            relay2Alle: false,
            relay2Price: "",
            relay3: "",
            relay3Alle: false,
            relay3Price: "",
            unit: "",
          },
        },
        {
          uniqueId:uuidv4(),
          id: 2,
          value: "U12",
          price: "",
          priceType: "", //flat-rate, number-guests, numerically
          priceTypeId: null,
          tags: [],
          numerically: {
            minimumNumber: "",
            minimumNumberCalculate: false,
            maximumNumber: "",
            maximumNumberCalculate: false,
            stage: "",
            relay1: "",
            relay1Alle: false,
            relay1Price: "",
            relay2: "",
            relay2Alle: false,
            relay2Price: "",
            relay3: "",
            relay3Alle: false,
            relay3Price: "",
            unit: "",
          },
        },
        {
          uniqueId:uuidv4(),
          id: 3,
          value: "U18",
          price: "",
          priceType: "", //flat-rate, number-guests, numerically
          priceTypeId: null,
          tags: [],
          numerically: {
            minimumNumber: "",
            minimumNumberCalculate: false,
            maximumNumber: "",
            maximumNumberCalculate: false,
            stage: "",
            relay1: "",
            relay1Alle: false,
            relay1Price: "",
            relay2: "",
            relay2Alle: false,
            relay2Price: "",
            relay3: "",
            relay3Alle: false,
            relay3Price: "",
            unit: "",
          },
        },
        {
          uniqueId:uuidv4(),
          id: 4,
          value: "U6",
          price: "",
          priceType: "", //flat-rate, number-guests, numerically
          priceTypeId: null,
          tags: [],
          numerically: {
            minimumNumber: "",
            minimumNumberCalculate: false,
            maximumNumber: "",
            maximumNumberCalculate: false,
            stage: "",
            relay1: "",
            relay1Alle: false,
            relay1Price: "",
            relay2: "",
            relay2Alle: false,
            relay2Price: "",
            relay3: "",
            relay3Alle: false,
            relay3Price: "",
            unit: "",
          },
        },
      ],
      events: [
        { name: "secondStep", text: "", date: this.context.eventCreationDate },
        {
          name: "thirdStep",
          text: "",
          date: new Date(),
        },
        {
          name: "fourthStep",
          text: "",
          date: this.context.deadlineForEntries,
        },
        {
          name: "fifthStep",
          text: "",
          date: this.context.eventDate,
        },
      ],
      themes: [{ id: -1, name: "Anzahl Gäste", services: [] }],
      headerImage: "",
      cuDetails: {},
      price: 0,
      showOptions: null,
      selectedPricing: {},
      selectedOption: "",
      selectedOptionIndex: null,
      display1: false,
      guestOptions:[],
      obj:{}
    };
  }

  componentDidMount = async () => {
    const data = this.context;
    console.log("context", this.context);
    const obj=JSON.parse(sessionStorage.getItem("state2"))
    this.setState({obj:obj})
  let questionnaireId=this.props.history.location?.state?.from==="questionnairePage" ? data.questionnaireId:obj.questionnaireId
   
    this._isMounted = true;
// if(Object.keys(obj).length!=0){
//    this.context.updateState({
//       authenticated: true,
//     });
// }
    let guestInfo = await HttpService.getGuests(questionnaireId);
    var dummyfields = Object.assign({}, this.state.guestInfo);
    let dummyPricing = Object.assign([], this.state.pricing);
    let dummyLabels = Object.assign({}, this.state.guestLabel);
    if (Object.keys(guestInfo).length) {
      let dummyTitle = {
        value: EditorState.createEmpty(),
        hideTextToolbar: true,
      };
      if (guestInfo.content?.title?.value) {
        dummyTitle.value = EditorState.createWithContent(
          convertFromRaw(guestInfo?.content?.title?.value)
        );
      }
      // console.log("guestinfo",guestInfo)
      this.setState({
        loading: false,
        newEntry: false,
        guests: guestInfo?.guests || {},
        guestInfo:
          guestInfo?.fields && Object.keys(guestInfo?.fields).includes("adults")
            ? guestInfo?.fields
            : dummyfields,
        image: guestInfo?.image || "",
        scale:guestInfo.scale ? guestInfo.scale:1,
        position:guestInfo.position ? guestInfo.position : { x: 0.5, y: 0.5 },
        title: dummyTitle,
        cuDetails: this.context.cuDetails,
        pricing: guestInfo.pricing ? guestInfo.pricing : dummyPricing,
        guestLabel: guestInfo.guestLabel ? guestInfo.guestLabel : dummyLabels,
        guestOptions:guestInfo.guestOptions
      });
    } else {
      this.setState({
        loading: false,
        newEntry: true,
        cuDetails: this.context.cuDetails,
      });
    }
    this.context.updateState({
     guestLabel:this.state.guestLabel
    });
    // console.log("obj",obj)
    if(this.props.history.location?.state?.from!=="questionnairePage"){
      this.context.updateState({
        eventId:obj?.eventId ,
        eventName: obj?.eventName,
        eventDate: obj?.eventDate,
        eventType: obj?.eventType,
        deadlineForEntries: obj?.deadlineForEntries,
        newDate: obj?.newDate,
        questionnaireId: obj?.questionnaireId,
        parentQuestionnaireId: obj?.parentQuestionnaireId,
        priceType:obj?.priceType
      });
    }
    axios
      .get(`${baseUrl}api/pricing/totalAmount/${questionnaireId}`)
      .then((res) => {
        const price = res.data;
        this.setState({
          price,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // events welcome page
    if (this.props.location.state === "from") {
      // const eventsWelcome = await HttpService.getEventWelcomePage(
      //   data.questionnaireId
      // );
      // if (eventsWelcome?.events?.length) {
      //   bool = false;
      // } else {
      //   bool = true;
      // }
      // const questionnaire = await HttpService.getQuestionnaireSetting(
      //   data?.parentQuestionnaireId
      // );
      // if (questionnaire?.vvoAssignmentDate) {
      //   let x = moment(this.context.eventDate).subtract(
      //     Number(questionnaire.vvoAssignmentDate),
      //     "days"
      //   );
      //   ev[1].date = x._d;
      // }
      // const postData = {
      //   questionnaireId: data.questionnaireId,
      //   events: ev,
      // };
      // if (bool) {
      //   await HttpService.postEventsPageData(postData);
      // } else {
      //   await HttpService.putEventsPageData(postData, data.questionnaireId);
      // }
    }
    // console.log("guest test");
    axios
    .get(`${baseUrl}api/venue/${data.venueId}`)
    .then((res) => {
      console.log(res);
      console.log("guest-context-lang",res.data?.languageFormat);
      this.context.updateState({
        currencyFormat: res.data?.currencyFormat,
        languageFormat: res.data?.languageFormat

      });
        console.log("lang1",res.data.languageFormat);
        // i18next.changeLanguage(res.data.languageFormat); 
    })
    .catch((error) => {
      console.log(error);
    });
  };

  checkIfChildrenExist = (item) => {
    let childrenExist = false;
    item.row.forEach((_item) => {
      _item.column.forEach((__item) => {
        __item.option.hasOwnProperty("type") && (childrenExist = true);
      });
    });
    return childrenExist;
  };
hanldePositionChange=(position)=>{
this.setState({position:position})
}
handleScaleChange=(scale)=>{
  this.setState({scale:scale})
}
  onGuestChange = (e) => {
    const guestInfo = Object.assign({}, this.state.guestInfo);
    for (let i in guestInfo) {
      if (i === e.target.name) {
        guestInfo[i] = !guestInfo[i];
      }
    }
    this.setState({
      guestInfo,
    });
  };
  onGuestCountChange = (guestCount) => {
    let { guests } = this.state;

    guests = {
      ...guests,
      ...guestCount,
    };

    this.setState({
      guests,
    });
  };
  handleTitle = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // handleChangingPara = (e, i) => {
  //   const paragraphs = Object.assign([], this.state.paragraphs);
  //   paragraphs[i].value = e;
  //   this.setState({
  //     paragraphs,
  //   });
  // };
  // handleChangingSinglePara = (e) => {
  //   const para = Object.assign({}, this.state.para);
  //   para.value = e;
  //   this.setState({
  //     para,
  //   });
  // };
  // addPara = (e) => {
  //   e.preventDefault();
  //   this.setState({
  //     paragraphs: this.state.paragraphs.concat(this.state.para),
  //     para: {
  //       id: this.state.paragraphs.length + 1,
  //       value: EditorState.createEmpty(),
  //       hideTextToolbar: true,
  //     },
  //   });
  // };
  // handleOnFocus = (e, id, index) => {
  //   const paragraphs = Object.assign([], this.state.paragraphs);
  //   paragraphs[index]["hideTextToolbar"] = false;
  //   this.setState({ paragraphs });
  // };
  // handleOnBlur = (e, id, index) => {
  //   const paragraphs = Object.assign([], this.state.paragraphs);
  //   paragraphs[index]["hideTextToolbar"] = true;
  //   this.setState({ paragraphs });
  // };
  // handleOnSingleFocus = () => {
  //   const para = Object.assign({}, this.state.para);
  //   para["hideTextToolbar"] = false;
  //   this.setState({ para });
  // };
  // handleOnSingleBlur = () => {
  //   const para = Object.assign({}, this.state.para);
  //   para["hideTextToolbar"] = true;
  //   this.setState({ para });
  // };
  handleChangingTitlePara = (e) => {
    const title = Object.assign({}, this.state.title);
    title.value = e;
    this.setState({
      title,
    });
  };
  handleOnTitleFocus = () => {
    const title = Object.assign({}, this.state.title);
    title["hideTextToolbar"] = false;
    this.setState({ title });
  };
  handleOnTitleBlur = () => {
    const title = Object.assign({}, this.state.title);
    title["hideTextToolbar"] = true;
    this.setState({ title });
  };

  showOptionComponent = (category, index) => {
    // const {pricing}= this.state
    // const selectedPricing=pricing.find((item)=>{
    //   return item.value===category
    // })
    this.setState({
      showOptions: true,
      selectedOption: category,
      selectedOptionIndex: index,
    });
  };
  selectedHeader = (e, label) => {
   let pricing=this.state.pricing
  // console.log("e",e.target.value,label,"label")

 this.setState({pricing})
 
  };
  handleLabelChange = (e, labelFor) => {
    const { guestLabel } = this.state;
    guestLabel[labelFor] = e.target.value;
    this.setState({
      guestLabel,
    });
  };
  handleLanguageForEditor=()=>{ 
    const lang= this.context.languageFormat;
     switch (lang) {
       case 'de':
         return 'de';
       case 'en':
         return 'en';
       case 'se':
         return 'es';
       default:
         return lang;
     }
   }
  renderContent = () => {
    const editorLabels = {
      "components.controls.link.linkTitle": "Anzuzeigender Text",
      "components.controls.link.linkTarget": "Link",
      "components.controls.link.linkTargetOption": "In neuem Fenster öffnen ",
    };
    const { activeTheme } = this.state;
    if (activeTheme.id === -1) {
      const { guestInfo, guestLabel } = this.state;
      return (
        <React.Fragment>
          <form style={{ padding: "0px 50px" }}>
            <div style={{ marginLeft: "20px" }}>
              {/* <h5 style={{ fontFamily: "Crete Round" }}>Überschrift</h5> */}
              {/* <input
                type="text"
                name="title"
                value={this.state.title}
                onChange={this.handleTitle}
              /> */}
              <div
                style={{
                  border: "1px solid black",
                  width: "80%",
                  padding: "0px 10px",
                  lineHeight: "1.2",
                }}
              >
                <Editor
                  localization={{ locale: this.handleLanguageForEditor(), translations: editorLabels }}
                  defaultEditorState={this.state.title.value}
                  toolbarOnFocus={!this.state.title.hideTextToolbar}
                  toolbarHidden={this.state.title.hideTextToolbar}
                  wrapperClassName="wrapperClassName"
                  onFocus={this.handleOnTitleFocus}
                  onBlur={this.handleOnTitleBlur}
                  onEditorStateChange={this.handleChangingTitlePara}
                  toolbar={{fontFamily:{options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana','Roboto'],
                }}}
                />
              </div>
              <br />
              {/* <br /> */}
              {/* <h5 style={{ fontFamily: "Crete Round" }}>Text</h5>
              {this.state.paragraphs.map((para, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      border: "1px solid black",
                      width: "70%",
                      marginBottom: "10px",
                      padding: "0px 10px",
                      lineHeight: "1.2",
                    }}
                  >
                    <Editor
                      editorState={para.value}
                      toolbarOnFocus={!para.hideTextToolbar}
                      toolbarHidden={para.hideTextToolbar}
                      onFocus={(e) => {
                        this.handleOnFocus(e, para.id, index);
                      }}
                      onBlur={(e) => {
                        this.handleOnBlur(e, para.id, index);
                      }}
                      onEditorStateChange={(e) =>
                        this.handleChangingPara(e, index)
                      }
                    />
                  </div>
                );
              })} */}
              {/* <div
                style={{
                  display: "flex",
                }}
              >
                <div
                  style={{
                    border: "1px solid black",
                    width: "70%",
                    marginBottom: "10px",
                    padding: "0px 10px",
                    lineHeight: "1.2",
                  }}
                >
                  <Editor
                    editorState={this.state.para.value}
                    toolbarOnFocus={!this.state.para.hideTextToolbar}
                    toolbarHidden={this.state.para.hideTextToolbar}
                    wrapperClassName="wrapperClassName"
                    onFocus={this.handleOnSingleFocus}
                    onBlur={this.handleOnSingleBlur}
                    onEditorStateChange={this.handleChangingSinglePara}
                  />
                  &nbsp;
                </div>
                <button onClick={this.addPara} style={styles.addPara}>
                  <img src={plusIcon} alt="" />
                </button>
              </div> */}
            </div>
            <br />
            <div style={(styles.flexRow, { marginLeft: "20px" })}>
              <div style={styles.inputWrapper}>
                {/* <input
                  maxLength={5}
                  min="2"
                  max="5"
                  type="text"
                  // placeholder="Anzahl U18"
                  style={styles.input}
                  value={this.state.guests?.adults}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") return;
                    let key = Number(e.key);
                    if (isNaN(key) || e.key === null || e.key === " ")
                      e.preventDefault();
                  }}
                  onChange={(e) =>
                    this.onGuestCountChange({ adults: e.target.value })
                  }
                /> */}
                <div style={{ display: "flex" }}>
                  <input
                    maxLength={5}
                    min="2"
                    max="5"
                    type="text"
                    placeholder={`${this.props.t('bitte_eintragen')}`} 
                    style={styles.input}
                    className="input-width"
                    disabled={true}
                  />
                
                  <div
                    className="option"
                    onClick={() => this.showOptionComponent("adults", 0)}
                    style={{
                      border: this.functionValidation(this.state.pricing[0])
                        ? "2px solid red"
                        : "none",
                      borderRadius: "50%",
                      height: "34px",
                      width: "34px",
                      margin: "0px 5px",
                    }}
                  >
                    <img
                      src={
                        this.state.selectedOption === "adults"
                          ? optionFilledIcon
                          : optionIcon
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div style={{ display: "flex", margin: "10px 0px" }}>
                  <input
                    type="text"
                    style={styles.inputLabel}
                    value={this.props.t(guestLabel.adults)}
                    onChange={(e) => {
                      this.handleLabelChange(e, "adults");
                    }}
                  />
                  {/* &nbsp;&nbsp; */}
                  {this.state.pricing[0]?.price ? (
                    <span
                      style={{
                        fontFamily: "Crete Round",
                        fontStyle: "italic",
                        color: "rgb(149, 150, 155)",
                        fontSize: "15px",
                        marginTop: "2px",
                        border: "medium none",
                      }}
                    >
                      { this.context.languageFormat==="en"? `(${Number(this.state.pricing[0]?.price)
                      .toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${this.context.currencyFormat})` : 
                      `(${Number(this.state.pricing[0]?.price)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} ${this.context.currencyFormat})
                   
                   `}
                    </span>
                  ) : (
                    ""
                  )}
                  {/* <label style={styles.inputLabel}>
                    Anzahl Erwachsene&nbsp;&nbsp;
                  </label> */}
                  <div style={{ display: "flex" }}>
                    <input
                      type="checkbox"
                      style={{ margin: "7px" }}
                      name="adults"
                      value="adults"
                      checked={guestInfo.adults}
                    onChange={this.onGuestChange}
                      // checked
                      // disabled
                    />
                  </div>
                </div>
              </div>
              <div style={styles.inputWrapper}>
                {/* <input
                  maxLength={5}
                  type="text"
                  style={styles.input}
                  // placeholder="Anzahl U12"
                  value={this.state.guests?.U12}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") return;
                    let key = Number(e.key);
                    if (isNaN(key) || e.key === null || e.key === " ")
                      e.preventDefault();
                  }}
                  onChange={(e) =>
                    this.onGuestCountChange({ U12: e.target.value })
                  }
                /> */}
                <div style={{ display: "flex" }}>
                  <input
                    maxLength={5}
                    min="2"
                    max="5"
                    type="text"
                    placeholder={`${this.props.t('bitte_eintragen')}`} 
                    style={styles.input}
                    className="input-width"
                    disabled={true}
                  />
                  <div
                    className="option"
                    onClick={() => this.showOptionComponent("U12", 1)}
                    style={{
                      border: this.functionValidation(this.state.pricing[1])
                        ? "2px solid red"
                        : "none",
                      borderRadius: "50%",
                      height: "34px",
                      width: "34px",
                      margin: "0px 5px",
                    }}
                  >
                    <img
                      src={
                        this.state.selectedOption === "U12"
                          ? optionFilledIcon
                          : optionIcon
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div style={{ display: "flex", margin: "10px 0px" }}>
                  <input
                    type="text"
                    style={styles.inputLabel}
                    value={`${this.props.t(guestLabel.U12)}`}
                    onChange={(e) => {
                      this.handleLabelChange(e, "U12");
                    }}
                  />
             
                  {this.state.pricing[1]?.price ? (
                    <span
                      style={{
                        fontFamily: "Crete Round",
                        fontStyle: "italic",
                        color: "rgb(149, 150, 155)",
                        fontSize: "15px",
                        marginTop: "2px",
                        border: "medium none",
                      }}
                    >{ this.context.languageFormat==="en"? `(${Number(this.state.pricing[1]?.price)
                      .toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${this.context.currencyFormat})`:
                      `(${Number(this.state.pricing[1]?.price)
                      .toFixed(2)
                      .replace(".", ",")
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}${this.context.currencyFormat})`}</span>
                  ) : (
                    ""
                  )}
                  {/* <label style={styles.inputLabel}>
                    Anzahl Kinder&nbsp;&nbsp;
                  </label> */}
                  <input
                    type="checkbox"
                    style={{ margin: "7px" }}
                    name="U12"
                    value="U12"
                    checked={guestInfo.U12}
                    onChange={this.onGuestChange}
                  />
                </div>
              </div>
            </div>

            <div style={(styles.flexRow, { marginLeft: "20px" })}>
              <div style={styles.inputWrapper}>
                {/* <input
                  maxLength={5}
                  type="text"
                  // placeholder="Anzahl U18"
                  style={styles.input}
                  value={this.state.guests?.U18}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") return;
                    let key = Number(e.key);
                    if (isNaN(key) || e.key === null || e.key === " ")
                      e.preventDefault();
                  }}
                  onChange={(e) =>
                    this.onGuestCountChange({ U18: e.target.value })
                  }
                /> */}
                <div style={{ display: "flex" }}>
                  <input
                    maxLength={5}
                    min="2"
                    max="5"
                    type="text"
                    placeholder={`${this.props.t('bitte_eintragen')}`} 
                    style={styles.input}
                    className="input-width"
                    disabled={true}
                  />
                  <div
                    className="option"
                    onClick={() => this.showOptionComponent("U18", 2)}
                    style={{
                      border: this.functionValidation(this.state.pricing[2])
                        ? "2px solid red"
                        : "none",
                      borderRadius: "50%",
                      height: "34px",
                      width: "34px",
                      margin: "0px 5px",
                    }}
                  >
                    <img
                      src={
                        this.state.selectedOption === "U18"
                          ? optionFilledIcon
                          : optionIcon
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div style={{ display: "flex", margin: "10px 0px" }}>
                  <input
                    type="text"
                    style={styles.inputLabel}
                    value={this.props.t(guestLabel.U18)}
                    onChange={(e) => {
                      this.handleLabelChange(e, "U18");
                    }}
                  />
                  {this.state.pricing[2]?.price ? (
                    <span
                      style={{
                        fontFamily: "Crete Round",
                        fontStyle: "italic",
                        color: "rgb(149, 150, 155)",
                        fontSize: "15px",
                        marginTop: "2px",
                        border: "medium none",
                      }}
                    >{this.context.languageFormat==="en"? `(${Number(this.state.pricing[2]?.price)
                      .toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${this.context.currencyFormat})`:
                      `(${Number(this.state.pricing[2]?.price)
                      .toFixed(2)
                      .replace(".", ",")
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}${this.context.currencyFormat})`}</span>
                  ) : (
                    ""
                  )}
                  {/* <label style={styles.inputLabel}>
                    Anzahl Jugendliche&nbsp;&nbsp;
                  </label> */}
                  <input
                    type="checkbox"
                    style={{ margin: "7px" }}
                    name="U18"
                    checked={guestInfo.U18}
                    onChange={this.onGuestChange}
                  />
                </div>
              </div>

              <div style={styles.inputWrapper}>
                {/* <input
                  maxLength={5}
                  type="text"
                  // placeholder="Anzahl U6"
                  style={styles.input}
                  value={this.state.guests?.U6}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") return;
                    let key = Number(e.key);
                    if (isNaN(key) || e.key === null || e.key === " ")
                      e.preventDefault();
                  }}
                  onChange={(e) =>
                    this.onGuestCountChange({ U6: e.target.value })
                  }
                /> */}
                <div style={{ display: "flex" }}>
                  <input
                    maxLength={5}
                    min="2"
                    max="5"
                    type="text"
                    placeholder={`${this.props.t('bitte_eintragen')}`} 
                    style={styles.input}
                    className="input-width"
                    disabled={true}
                  />
                  <div
                    className="option"
                    onClick={() => this.showOptionComponent("U6", 3)}
                    style={{
                      border: this.functionValidation(this.state.pricing[3])
                        ? "2px solid red"
                        : "none",
                      borderRadius: "50%",
                      height: "34px",
                      width: "34px",
                      margin: "0px 5px",
                    }}
                  >
                    <img
                      src={
                        this.state.selectedOption === "U6"
                          ? optionFilledIcon
                          : optionIcon
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div style={{ display: "flex", margin: "10px 0px" }}>
                  <input
                    type="text"
                    style={styles.inputLabel}
                    value={this.props.t(guestLabel.U6)}
                    onChange={(e) => {
                      this.handleLabelChange(e, "U6");
                    }}
                  />
                  {/* <label style={styles.inputLabel}>
                    Anzahl Kleinkinder&nbsp;&nbsp;
                  </label> */}
                  {this.state.pricing[3]?.price ? (
                    <span
                      style={{
                        fontFamily: "Crete Round",
                        fontStyle: "italic",
                        color: "rgb(149, 150, 155)",
                        fontSize: "15px",
                        marginTop: "2px",
                        border: "medium none",
                      }}
                    >{this.context.languageFormat==="en"? `(${Number(this.state.pricing[3]?.price)
                      .toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${this.context.currencyFormat})`:
                      `(${Number(this.state.pricing[3]?.price)
                      .toFixed(2)
                      .replace(".", ",")
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}${this.context.currencyFormat})`}</span>
                  ) : (
                    ""
                  )}
                  <input
                    type="checkbox"
                    name="U6"
                    style={{ margin: "7px" }}
                    checked={guestInfo.U6}
                    onChange={this.onGuestChange}
                  />
                </div>
              </div>
            </div>
          </form>
        </React.Fragment>
      );
    }
  };
  guestDivClicked = () => {
    this.setState({ display1: !this.state.display1 });
  };

  saveQuestionnaire = async () => {
    const { activeTheme, newEntry, title, pricing, guestLabel} = this.state;
    let questionnaireId=this.props.history.location?.state?.from==="questionnairePage" ? this.context.questionnaireId
    :this.state.obj.questionnaireId
    let validated = true;
    pricing.forEach((input) => {
      const min = Number(input.numerically.minimumNumber);
      const max = Number(input.numerically.maximumNumber);
      const price = Number(input.price);

      if (min && max && min > max) {
        validated = false;
        return;
      } else if (min && max && !price && price!==0) {
        validated = false;
        return;
      } else if (min && !price && price!==0) {
        validated = false;
        return;
      } else if (max && !price && price!==0) {
        validated = false;
        return;
      }
    });
    if (validated) {
      let dummyTitle = Object.assign({}, title);
      dummyTitle.value = convertToRaw(dummyTitle.value.getCurrentContent());
      if (newEntry) {
        var guestsData = {
          questionnaireId:questionnaireId,
          content: {
            title: dummyTitle,
          },
          fields: this.state.guestInfo,
          pricing,
          guestLabel,
          guestOptions:this.state.guestOptions
        };
      } else {
        guestsData = {
          questionnaireId: questionnaireId,
          content: {
            title: dummyTitle,
          },
          fields: this.state.guestInfo,
          guests: this.state.guests,
          image: this.state.image,
          position:this.state.position,
          scale:this.state.scale,
          pricing,
          guestLabel,
          guestOptions:this.state.guestOptions
        };
      }
      if (activeTheme.id === -1 && newEntry) {
        // console.log('post', guestsData)
        await HttpService.postGuests(guestsData);
        return;
      }
      if (activeTheme.id === -1 && !newEntry) {
        // console.log('put', guestsData)
        await HttpService.putGuests(questionnaireId, guestsData);
        return;
      }
    }
  };

  saveQuestionnaireAndProceed = async () => {
    this.saveQuestionnaire();
    let {obj}=this.state
    // console.log("loc",this.props.history.location?.state?.from)
    if(this.props.history.location?.state?.from!=="questionnairePage"){
      this.context.updateState({
        eventId:obj?.eventId ,
        eventName: obj?.eventName,
        eventDate: obj?.eventDate,
        eventType: obj?.eventType,
        deadlineForEntries: obj?.deadlineForEntries,
        newDate: obj?.newDate,
        questionnaireId: obj?.questionnaireId,
        parentQuestionnaireId: obj?.parentQuestionnaireId,
        priceType:obj?.priceType

      });
    }
    let validated = true;
    this.state.pricing.forEach((input) => {
      const min = Number(input.numerically.minimumNumber);
      const max = Number(input.numerically.maximumNumber);
      const price = Number(input.price);

      if (min && max && min > max) {
        validated = false;
        return;
      } else if (min && max && !price && price!==0) {
        validated = false;
        return;
      } else if (min && !price && price!==0) {
        validated = false;
        return;
      } else if (max && !price && price!==0) {
        validated = false;
        return;
      }
    });
    if (validated) {
     
     
    
      return this.props.history.push(
        "/configurator-landing",
        this.props.location.state
      );
    }
  };

  selectTheme = async (theme) => {};

  selectService = async (service) => {};
  handleLogout = () => {
    const urlName = this.context.urlName;
    this.context.updateState({
      authenticated: false,
      from:"logout"

    });
    localStorage.clear();
    sessionStorage.clear();
    if (urlName === "adminScreen") {
      this.props.history.push(`/login`);
    } else {
      this.props.history.push(`/login/${urlName}`);
    }
  };
  handleBack = () => {
    // this.context.updateState({ eventId: "" });
    // this.props.history.push(`/admin/events`);
    // this.props.history.goBack();
   
   ( this.props.history.location?.state?.from && this.props.history.location?.state?.from==="questionnairePage")?
    this.props.history.push(`/eventsWelcome`, this.props.location.state):
    this.props.history.push(`/admin/events`, this.props.location.state)
  };

  updatePriceOptionConfiguration = (options) => {
    this.setState({
      pricing: options,
    });
  };
  handleClose = () => {
    this.setState({
      showOptions: false,
      selectedOptionIndex: null,
      selectedOption: "",
    });
  };
  functionValidation = (input) => {
    const min = Number(input.numerically.minimumNumber);
    const max = Number(input.numerically.maximumNumber);
    const price = Number(input.price);

    if (min && max && min > max) {
      const msg = "Der Maximalwert sollte größer als der Minimalwert sein";
      return msg;
    }
    if (min && max && !price && price!==0) {
      const msg = "Preis eingeben";
      return msg;
    } else if (min && !price && price!==0) {
      const msg = "Enter Price";
      return msg;
    } else if (max && !price && price!==0) {
      const msg = "Enter Price";
      return msg;
    } else {
      return null;
    }
  };
  functionCapitalise = (initialTitle) => {
    let title = "";
    if (initialTitle) {
      title = initialTitle[0]
        .toUpperCase()
        .concat(initialTitle.slice(1, initialTitle.length));
    }

    return title;
  };
 
  render() {
    const { activeTheme, loading, themes } = this.state;

    if (loading) {
      return <h1>{this.props.t('Loading')}</h1>;
    }
    let questionnaireId=this.props.history.location?.state?.from==="questionnairePage" ?
     this.context.questionnaireId:this.state.obj?.questionnaireId

    return (
			<div
				className="container-fluid p-0"
				style={{ overflowX: 'hidden' }}
				// style={{ overflow: "scroll" }}
			>
				<div
					className="row"
					style={{
						height: '100vh',
					}}>
					<div className="col-2" style={styles.themesWrapper}>
						<OverlayTrigger
							key="bottom"
							placement="bottom"
							overlay={
								<Tooltip id={`tooltip-disabled`}>
									<span>{this.props.t('logout')}</span>
								</Tooltip>
							}>
							<button
								className="logout-btn"
								style={{
									border: 'none',
									marginTop: '10px',
									marginLeft: '10px',
								}}
								onClick={this.handleLogout}>
								<img src={logoutIcon} alt="" />
							</button>
						</OverlayTrigger>
						<OverlayTrigger
							key="bottom1"
							placement="bottom"
							overlay={
								<Tooltip id={`tooltip-bottom`}>
									<span>{this.props.t('home')}</span>
								</Tooltip>
							}>
							<button
								className="home-btn border-0 m-2"
								style={{ border: 'none' }}
								onClick={(e) => {
									this.props.location.state?.from === 'questionnairePage'
										? this.props.history.push('/admin/questionnaire')
										: this.props.history.push('/admin/events');
									//             this.props.location.state?.from=="eventsPage"?this.props.history.push("/admin/events"):
									//  this.props.history.push("/admin/questionnaire")
								}}>
								<img
									style={{ width: '20px', height: '25px' }}
									src={home}
									alt=""
								/>
							</button>
						</OverlayTrigger>
						<div className="main-company-logo d-flex align-items-center justify-content-center">
							<img
								// style={{ width: "150px", height: "150px", marginTop: "15px" }}
								style={{
									width: '100%',
									height: 'auto',
									maxHeight: '80px',
									objectFit: 'contain',
									imageRendering: 'high-quality',
									marginTop: '15px',
								}}
								src={this.context.venueLogo}
								alt=""
							/>
						</div>
						<div className="pl-2 pt-5">
							<Accordion
								allowMultipleOpen={false}
								data={themes}
								activeTheme={activeTheme}
								onThemeSelect={this.selectTheme}
								onServiceSelect={this.selectService}
							/>
						</div>
					</div>

					<div
						className="col-7 configurator-landing"
						style={{
              paddingLeft: '0px',
              paddingRight: "0",
							maxWidth: '58%',
							flex: '0 0 58%',
						}}>
						{/* <ConfiguratorLandingDropzone 
            handleScaleChange={this.handleScaleChange}
            hanldePositionChange={this.hanldePositionChange}
            scale={this.state.scale}
            position={this.state.position}
            obj={this.props.history.location?.state?.from!="questionnairePage" ?
            this.state.obj:{}}/> */}
						<ImageComposer
							questionnaireId={questionnaireId}
							obj={
								this.props.history.location?.state?.from !== 'questionnairePage'
									? this.state.obj
									: {}
							}
						/>
						<div className="container pl-0 pr-0">{this.renderContent()}</div>
						<br />
						<div className="d-flex justify-content-center">
							<button style={styles.outline} onClick={this.handleBack}>
								{this.props.t('return')}
							</button>
							<button
								style={styles.outline}
								onClick={this.saveQuestionnaireAndProceed}>
								{this.props.t('weiter')}
							</button>
						</div>
						<br />
						<br />
						<br />
					</div>
					<div
						className="col-3 config-right-bar"
						style={{
							paddingLeft: '5px',
							height: '100vh',
							marginLeft: '-15px',
						}}>
						<div className="pricing-section" style={{ paddingRight: '20px' }}>
							<h6 style={{ fontSize: '19px' }}>
								{' '}
								{this.props.t('Preis_Ihrer_Veranstaltung')}
							</h6>
							{/* {console.log(this.state.price)} */}
							<h1>
								{this.context.languageFormat === 'en'
									? `${this.state.price
											.toFixed(2)
											.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${
											this.context.currencyFormat
									  }`
									: `${this.state.price
											.toFixed(2)
											.replace('.', ',')
											.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  ${this.context.currencyFormat}`}
							</h1>
							<div className="seperator"></div>
						</div>
						{/* <div
              style={{
                // background: "#e6e7e8",
                // color: "#939598",
                padding: "1px 130px 1px 1px",
                marginTop: "4px",
                background:"#939598",
                width:"250px",
                height:"40px",
                display:"block ruby",
                marginLeft:"50px"

              }}
            >
              <label style={{marginRight:"-30px",clear: "both",
                      
    display: "inline-block",
   
    whiteSpace: "nowrap"}} onClick={this.guestDivClicked}>Berechung "Anzahl Gäste"</label>
            </div> */}
						{/* {this.state.display1 ? (
              <>
                <div
                  // onClick={(e)=>this.HeaderObject(e)}
                  style={{
                    // paddingBottom: "20px",
                    marginTop: "-10px",
                    // color: "#939598",
                    background: "rgb(209, 211, 212) none repeat scroll 0% 0%",
                    display: "block",
                    // paddingLeft: "50px",
                    paddingRight: "90px",
                    marginRight: "200px",
                    // height: "200px",
                    overflow: "auto",
                    marginLeft: "50px",
                    width: "250px",
                  }}
                >
                  {this.state.pricing.map((price)=>{
                   return  <div style={{
                     
                    paddingBottom: "20px",
                  
                    background: "rgb(209, 211, 212) none repeat scroll 0% 0%",
                    display: "flex",
                   
                    overflow: "auto",
                  
                    width: "190px",
                    right:"50px",
                    left:"20px",
                    position:"relative"
                     }}>
                  
                    <input
                    onChange={(e) => {
                      this.selectedHeader(e, price.value);
                    }}
                  style={{right: "10px",
                  position: "absolute",
                  left: "20px"}}
                    checked={price.checked}
                    type="checkbox"
                   id={price.value}
                    name={price.value}
                    value={price.value}
                  />

                  <label htmlFor={price.value} style={{clear: "both",
                      
                      display: "inline-block",
                     
                      whiteSpace: "nowrap"}}>
                
                    {Object.keys(this.state.guestLabel).map((label)=>{
                                      return price?.value==label && this.state.guestLabel[label]
                                    })}
                    </label>
                  </div>
                  })}
                  
                </div>
              </>
            ) : (
              <></>
            )} */}

						{this.state.showOptions ? (
							<>
								<GuestPriceConfigurations
									options={this.state.pricing}
									// selectedOption={this.state.option}
									selectedOptionIndex={this.state.selectedOptionIndex}
									handleClose={this.handleClose}
									updatePriceOptionConfiguration={
										this.updatePriceOptionConfiguration
									}
								/>
								{/* <button style={styles.outline} onClick={this.saveQuestionnaire}>
                speichern
                </button> */}
							</>
						) : null}
						<div
							className="bottom-section"
							style={{
								position:
									this.state.pricing[this.state.selectedOptionIndex]
										?.priceType === 'numerically'
										? 'relative'
										: 'absolute',
							}}>
							{this.context.eventId?.length ? (
								<div style={{ paddingRight: '10px' }}>
									<p style={styles.title}>{this.props.t('Billing_address')}</p>
									<p
										style={{ ...styles.text, ...styles.pointer }}
										onClick={() => this.toUserDetailsPage()}>
										{this.props.t('Change')}
									</p>
									<br />
									<>
										{/* <p style={styles.bold}>
                      {cuDetails?.firstName1} {cuDetails?.surname1}
                    </p> */}
										<p style={styles.text}>Maxi Mustermann</p>
										<p style={styles.text}>Musterstraße 10</p>
										<p style={styles.text}>12345 Musterstetten</p>
										<br />
										<p style={styles.text}>Tel. 01234567891</p>
										<p style={styles.text}>maxi@muster.de</p>
										{/* <p style={styles.bold}>
                      {cuDetails?.firstName1} {cuDetails?.surname1}
                    </p>
                   
                    <p style={styles.text}>{cuDetails?.companyName}</p>
                    <p style={styles.text}>{cuDetails?.streetNumber}</p>
                    <p style={styles.text}>
                      {cuDetails.postCode} {cuDetails.state}
                    </p>
                    <br />
                    <p style={styles.text}>{cuDetails?.telNumber1}</p>
                    <p style={styles.text}>{cuDetails?.email1}</p> */}
									</>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		);
  }
}

const styles = {
  addPara: {
    border: "none",
    boxShadow: "none",
    margin: "10px",
    // width:"27px",
    height: "27px",
    borderRadius: "50%",
  },
  outline: {
    color: "#297825",
    fontFamily: "Crete Round",
    fontSize: "13px",
    border: "1px solid #297825",
    minWidth: "143px",
    padding: "9px 15px",
    textAlign: "center",
    display: "inline-block",
    marginRight: "15px",
  },
  solid: {
    background: "#297825",
    fontFamily: "Crete Round",
    border: "1px solid #297825",
    color: "#fff",
    fontSize: "13px",
    minWidth: "143px",
    padding: "9px 15px",
    textAlign: "center",
    display: "inline-block",
    marginRight: "15px",
  },
  stepsContainer: {
    textAlign: "center",
  },
  headerImage: {
    width: "100%",
    height: "140px",
    objectFit: "cover",
  },
  input: {
    border: 0,
    padding: 0,
    margin: 0,
    background: "transparent",
    borderBottom: "1px solid #707070",
    width: "200px",
  },
  inputLabel: {
    fontFamily: "Crete Round",
    fontStyle: "Italic",
    color: "#95969b",
    fontSize: "15px",
    border: "none",
    // marginRight:"-10px"
  },
  inputWrapper: {
    display: "inline-block",
    marginRight: "40px",
  },
  flexRow: {
    flexDirection: "row",
  },
  priceWrapper: {
    background: "#e6e7e8",
    right: "0",
    top: "0",
    display: "block",
    width: "360px",
    height: "100vh",
    transition: "0.3s",
    zIndex: "30",
  },
  themesWrapper: {
    background: "#f0eff0",
    // height: "100vh",
  },
  // contentWrapper: {
  //     padding: "32px 0px"
  // },
  loader: {
    color: "#888888",
    fontWeight: "bold",
    fontSize: "20px",
    padding: "20px",
    textAlign: "center",
  },

  title: {
    fontFamily: "Crete Round",
    fontSize: "19px",
    color: "#000",
    margin: "0px",
  },
  bold: {
    fontSize: "16px",
    margin: "0px",
    color: "#000",
    fontWeight: "600",
  },
  text: {
    fontSize: "16px",
    fontWeight: "300",
    margin: "0px",
    color: "#000",
  },

  pointer: {
    cursor: "pointer",
  },
};

export default withTranslation() (GuestsPg);
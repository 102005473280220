import React, { Component } from "react";
import { PriceType, PricingFunction } from "../../../../Constants";
import Text from "../../Text";
import { StateContext } from "../../../../context/state";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation} from 'react-i18next';
import Style from 'style-it';

class RadioOption extends Component {
  static contextType = StateContext;
  pricingFunction;

  constructor(props) {
    super(props);
    this.state = {
      bool: false,
      quantity: "",
      quantity2: "",
      error: "",
      selectedId: "",
      textDisplay: true,
    };
    this.pricingFunction = PricingFunction[props.priceType || PriceType.NONE];
  }
  componentDidMount() {
    if (window.innerWidth > 1094) this.setState({ textDisplay: false });
    window.addEventListener("resize", () => {
      if (window.innerWidth > 1094) this.setState({ textDisplay: false });
      else this.setState({ textDisplay: true });
    });
  }
  onQuantityChange = (e) => {
    // console.log('e', e.target.value)

    let deadLineDate = new Date(this.context.deadlineForEntries);

    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) {
      return false;
    } else {
      let key = Number(e.target.value);
      if (isNaN(key) || e.key === null || e.key === " ") return;
      const {
        id,
        price,
        numerically,
        stages,
        maximum,
        minimum,
        onUpdateWithoutAPICall,
        answer,
      } = this.props;
      const quantity = e.target.value;
      this.setState({ quantity: quantity });
      const { quantity2 } = answer ? answer : {};
      this.pricingFunction = PricingFunction[this.props.priceType || PriceType.NONE];
      const { amount, error } = this.pricingFunction(
        Number(price) || 0,
        Number(quantity) || 0,
        Number(stages) || 0,
        Number(maximum) || 0,
        Number(minimum) || 0,
        numerically
      );
      const item = { id, quantity, amount, error, quantity2 };
      // console.log('props', this.props)
      // if (Object.keys(answer)?.length) {

      const findingElement = answer?.id === id;
      if (findingElement) {
        let dummy2 = {};
        onUpdateWithoutAPICall(dummy2);
      } else {
        onUpdateWithoutAPICall(item);
      }
      // }
      // console.log("answer",answer)

      this.setState({ error: error, selectedId: id });
      onUpdateWithoutAPICall({ id, quantity, amount, error, quantity2 });
    }
  };
  QuantityZero = (e) => {
    let deadLineDate = new Date(this.context.deadlineForEntries);

    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) {
      return false;
    } else {
      let key = Number(e.target.value);
      if (isNaN(key) || e.key === null || e.key === " ") return;
      const {
        id,
        onUpdateWithoutAPICall,
        answer,
      } = this.props;
      const quantity2 = e.target.value;
      this.setState({ quantity2: quantity2 });
      const { quantity } = answer ? answer : 0;
      // const { amount, error } = this.pricingFunction(
      //   Number(price) || 0,
      //   Number(quantity) || 0,
      //   Number(stages) || 0,
      //   Number(maximum) || 0,
      //   Number(minimum) || 0,
      //   numerically
      // );
      const item = { id, quantity2, quantity };
      // console.log('props', this.props)
      // if (Object.keys(answer)?.length) {

      const findingElement = answer?.id === id;
      if (findingElement) {
        let dummy2 = {};
        onUpdateWithoutAPICall(dummy2);
      } else {
        onUpdateWithoutAPICall(item);
      }

      onUpdateWithoutAPICall({ id, quantity2, quantity });
    }
  };
  onSelect = () => {
    const {
      id,
      price,
      priceType,
      onUpdate,
      answer,
      onUpdateWithoutAPICall,
    } = this.props;
    const quantity =
      priceType === "number-guests" ? this.props.selectedGuestCount : 0;
      
    const { quantity2 } = this.state;
    let amount = undefined;
    // if (priceType === PriceType.FLAT_RATE) amount = Number(price);
    // onUpdate({ id, quantity, amount });
    const item = { id, quantity, amount, quantity2 };
    let deadLineDate = new Date(this.context.deadlineForEntries);

    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) {
      return false;
    } else {
      const findingElement = answer?.id === id;

      if (findingElement) {
        const dummy2 = {};
        // onUpdateWithoutAPICall(dummy2);
        onUpdate(dummy2);
      } else {
        onUpdateWithoutAPICall(item);
        if (priceType === PriceType.FLAT_RATE) amount = Number(price);
        
          onUpdate({ id, quantity, amount, quantity2 });
        
      }
    }
  };

  renderPriceTypeComponent = () => {
    const { id, answer, priceType, price } = this.props;
    const textFontSize = this.context.stylingData?.textFontSize;
    const textFontFamily = this.context.stylingData?.textFontFamily;
    const textColor =this.context.stylingData?.textColor? this.context.stylingData?.textColor : "black";
    const val = this.findingFunction();
    // const quantity = val ? val.quantity : "";
    const quantity2 = answer?.id === id ? answer?.quantity2 : "";
    let quantity
      if( answer?.id === id){
        if( priceType !== "number-guests"){
          quantity=answer?.quantity
        }
        else{
          if(this.props.selectedGuestCount){
            // quantity=this.props.selectedGuestCount;
            quantity="";
          }
          else{
            quantity=""
          }
        }
      }
    // const quantity =
    //   answer?.id === id
    //     ? answer?.quantity
    //     : priceType === "number-guests"
    //     ? this.props.selectedGuestCount
    //     : "";

    let placeholder;
    switch (priceType) {
      case PriceType.NUMBER_OF_GUESTS:
        placeholder = ` ${this.context.currencyFormat} ${price} x Gäste`;
        break;
      case PriceType.NUMERICALLY:
        placeholder = this.props.t("Geben Sie den Wert ein");
        break;
      case PriceType.FLAT_RATE:
      default:
        placeholder = undefined;
        break;
    }

    if (placeholder) {
      return (
        <div
          style={{
            fontSize: `${textFontSize}px`,
            fontFamily: `${textFontFamily}`,
          }}
        >
          {/* {console.log("price",priceType)} */}
          {(price === 0 &&( priceType==="" || !priceType )) ? (
            <input
              //  style={styles.input}
              style={{
                border: 0,
                padding: 0,

                // margin: 0,
                marginBottom: "20px",
                marginTop: "0px",
                background: "transparent",
                borderBottom: "1px solid #707070",
                width: "200px",
                color:textColor
              }}
              value={val ? quantity2 : ""}
              // placeholder={placeholder}
              placeholder={this.props.t('bitte_eintragen')}
              onKeyDown={(e) => {
                if (e.key === "Enter") this.onEnterHitWhenZero(quantity2);
              }}
              maxLength={10}
              onChange={this.QuantityZero}

              // disable={this.props.selectedGuestCount ? true :false}
              autoComplete="off"
            />
          ) : (
            priceType !== "number-guests" &&  <Style>
            {`
      #placeHolder::placeholder {
      color: ${textColor};   
     }`}
    <div>
            <input
              style={this.props.selectedGuestCount ? styles.inputHidden : {...styles.input,color:textColor}}
              // placeholder={placeholder}
              id="placeHolder"
              placeholder={this.props.t('bitte_eintragen')}
              // value={
              //   this.props.selectedGuestCount
              //     ? this.props.selectedGuestCount
              //     : val ? quantity : ""
              // }
              value={
                this.props.selectedGuestCount
                  ? quantity
                  : val ? quantity : ""
              }
              maxLength={10}
              onChange={this.onQuantityChange}
              onKeyDown={(e) => {
                // if (e.key === "Backspace") return;
                // let key = Number(e.key);

                if (e.key === "Enter") this.onEnterHit(quantity);

                // if (isNaN(key) || e.key === null || e.key === " ")
                //   e.preventDefault();
              }}
              // disabled={this.props.selectedGuestCount ? true : false}
              // onBlur={()=>{
              //   console.log('blur')
              //   this.onEnterHit(quantity)
              // }}
              autoComplete="off"
            />
            </div>
            </Style>
          )}
        </div>
      );
    } else return null;
  };
  onEnterHit = (quantity) => {
    const {
      id,
      price,
      numerically,
      stages,
      maximum,
      minimum,
      onUpdate,
      onUpdateWithoutAPICall,
      answer,
    } = this.props;
    const { quantity2 } = answer;
    const { amount, error } = this.pricingFunction(
      Number(price) || 0,
      Number(quantity) || 0,
      Number(stages) || 0,
      Number(maximum) || 0,
      Number(minimum) || 0,
      numerically
    );
    let deadLineDate = new Date(this.context.deadlineForEntries);

    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) {
      return false;
    } else {
      const item = { id, quantity, amount, error, quantity2 };
      const findingElement = answer?.id === id;
      if (findingElement) {
        let dummy2 = {};
        onUpdateWithoutAPICall(dummy2);
      } else {
        onUpdateWithoutAPICall(item);
      }
      this.setState({ error: error, selectedId: id });
      // console.log('err', error)
      if (!error) {
        onUpdate({ id, quantity, amount, error, quantity2 });
      } else {
        onUpdateWithoutAPICall({ id, quantity, amount, error, quantity2 });
      }
    }
    if(this.state.error!==""){
      toast.error(this.state.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  
  };
  onEnterHitWhenZero = (quantity2) => {
    const {
      id,
      onUpdate,
      onUpdateWithoutAPICall,
      answer,
    } = this.props;
    const { quantity } = answer;

    let deadLineDate = new Date(this.context.deadlineForEntries);

    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ) {
      return false;
    } else {
      const item = { id, quantity2, quantity };
      const findingElement = answer?.id === id;
      if (findingElement) {
        let dummy2 = {};
        //  onUpdate({ id, quantity,quantity2 });
        onUpdateWithoutAPICall(dummy2);
      } else {
        onUpdateWithoutAPICall(item);
      }

      onUpdate({ id, quantity2, quantity });
    }
  };
  findingFunction = () => {
    const { id, answer } = this.props;
    const findingElement = answer?.id === id;
    if (findingElement) {
      return findingElement;
    } else {
      return false;
    }
  };

  findMatching = (guestInfo) => {  
    const {GuestInfoLabel} = this.props; 
    const result = [];
    for (var i = 0; i < guestInfo.length; i++) {
      var info = guestInfo[i];
      switch (info) {
        case "adults":
          var priceAdult;
          if(GuestInfoLabel.pricing?.[0]?.price ){
            priceAdult= ` ${Number(GuestInfoLabel.pricing?.[0]?.price).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} ${this.context.currencyFormat}`;
          }
          else
          {
            priceAdult="";
          } 
          result.push(` ${this.props.t('Per')} `+ `${this.props.t('Gast')}`+ priceAdult);
          break;
        case "U6":
          var priceU6;
          if(GuestInfoLabel.pricing?.[3]?.price ){
            priceU6= ` ${Number(GuestInfoLabel.pricing?.[3]?.price).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} ${this.context.currencyFormat}`;
          }
          else
          {
            priceU6="";
          }
          result.push(` ${this.props.t('Per')} `+ `${this.props.t('Gast')}`+ priceU6);
          break;
        case "U12":
          var priceU12;
          if(GuestInfoLabel.pricing?.[1]?.price ){
            priceU12= ` ${Number(GuestInfoLabel.pricing?.[1]?.price).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} ${this.context.currencyFormat}`;
          }
          else
          {
            priceU12="";
          }
          result.push(` ${this.props.t('Per')} `+ `${this.props.t('Gast')}`+ priceU12);
          break;
        case "U18":
          var priceU18;
          if(GuestInfoLabel.pricing?.[2]?.price ){
            priceU18= ` ${Number(GuestInfoLabel.pricing?.[2]?.price).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} ${this.context.currencyFormat}`;
          }
          else
          {
            priceU18="";
          }
          result.push(` ${this.props.t('Per')} `+ `${this.props.t('Gast')}`+ priceU18);
          break;
        default:
          break;
      }        
    } 
    return result;
  }
    
  render() {
    let { id, value, answer, priceType, numerically, price} = this.props;
    const textFontSize = this.context.stylingData?.textFontSize ? this.context.stylingData.textFontSize : this.context.defaultSettingStyle?.presTextSize;
    const textFontFamily = this.context.stylingData?.textFontFamily ? this.context.stylingData.textFontFamily : this.context.defaultSettingStyle?.presTextFont;
    // const headingColor = this.context.stylingData?.textColor;
    const headingColor = this.context.stylingData?.textColor ? this.context.stylingData?.textColor : this.context.defaultSettingStyle?.presTextColor;
    const textColor = this.context.stylingData?.textColor ? this.context.stylingData?.textColor : this.context.defaultSettingStyle?.presTextColor;

    const quantity2 = answer?.id === id ? answer?.quantity2 : "";
    // const result= this.findMatching();
    
    
    
    const themeColorStyle = this.findingFunction() ? {
      background: this.context.stylingData?.lineColor ?
        `${this.context.stylingData?.lineColor}` :
        `${this.context.defaultSettingStyle.presAccentColor}`,
      border: this.context.stylingData?.lineColor ?
        `1px solid ${this.context.stylingData?.lineColor}` :
        `1px solid ${this.context.defaultSettingStyle.presAccentColor}`
    } : {};
    const themeColorUsed =this.context.stylingData?.["lineColor"] ? themeColorStyle : themeColorStyle;
    return (
      <div
        style={{
          fontSize: `${textFontSize}px`,
          fontFamily: `${textFontFamily}`,
          // display: "flex",
          flexDirection: "column",
          padding: "0px",
        }}
      >
        {this.state.textDisplay === true &&
        this.state.selectedId === answer?.id ? (
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        ) : (
          <></>
        )}
        <div style={styles.labelContainer}>
          <div className="radioCircle" style={themeColorUsed} onClick={this.onSelect} />
          <div className="radioLabel" style={{color:`${headingColor}`}}>
            {value}</div>

          {priceType === "flat-rate" ? (
            price === 0 ? (
              <input
                style={{...styles.input2, color:textColor}}
                value={quantity2}
                // placeholder={placeholder}
                placeholder={this.props.t('bitte_eintragen')}
                onKeyDown={(e) => {
                  if (e.key === "Enter") this.onEnterHitWhenZero(quantity2);
                }}
                maxLength={10}
                onChange={this.QuantityZero}
                autoComplete="off"
              />
            ) : (
              <>
                {priceType === PriceType.NUMERICALLY ? (
                  <Text
                    fontSize={textFontSize}
                    fontFamily={textFontFamily}
                    style={styles.unit}
                    value={`(${this.props.t('flat_rate')}  ${this.context.languageFormat==="en"? `${Number(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :  `${Number(price)
                      .toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`} ${this.context.currencyFormat} ${
                      numerically?.unit ? ` ${numerically.unit}` : ""
                    })`}
                  />
                ) : (
                  price !== 0 && (
                    <Text
                      fontSize={textFontSize}
                      fontFamily={textFontFamily}
                      style={styles.unit}
                      value={
                        price &&
                        `( ${
                          numerically?.unit? ` ${numerically.unit}` : 
                          `${this.props.t('flat_rate')}`
                         }  ${this.context.languageFormat==="en"? `${Number(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :  `${Number(price)
                          .toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`}  ${this.context.currencyFormat})`
                      }
                    />
                  )
                )}
              </>
            )
          ) : (
<>            
              {priceType === PriceType.NUMERICALLY
                ? price !== 0 && (
                    <Text
                      fontSize={textFontSize}
                      fontFamily={textFontFamily}
                      style={styles.unit}
                      value={`( ${this.context.languageFormat==="en"? `${Number(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :  `${Number(price)
                        .toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`}  ${this.context.currencyFormat}
                        ${
                        numerically?.unit ? ` ${numerically.unit}` : ""
                      })`}
                    />
                  )
                : price !== 0 && (
                    <Text
                      fontSize={textFontSize}
                      fontFamily={textFontFamily}
                      style={styles.unit}
                      value={
                        price &&
                        `(
                          ${this.context.languageFormat==="en"? `${Number(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :  `${Number(price)
                            .toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`}
                           ${this.context.currencyFormat}
                           ${
                            numerically?.unit? ` ${numerically.unit}` : 
                            `${this.props.t('Per')} ${this.props.t('Gast')}`
                           })`
                      }
                    />
                  )}
            </>
          )}
        </div>
        {this.renderPriceTypeComponent()}
        <br />
        {/* {(this.state.textDisplay==true && this.state.selectedId===answer?.id) ?
               <span  style={{color:"red",fontSize: "16px", margin: "0px 0px 10px", fontWeight: "600"}}>{answer?.error}</span>:
      <></>
              }  */}
      </div>
    );
  }
}
export default withTranslation() (RadioOption);

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },
  labelContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    top: "-3px",
    position: "relative",
    // padding: "10px 0",
  },
  circle: {
    minWidth: "24px",
    minHeight: "24px",
    display: "inline-block",
    textAlign: "center",
    background: "transparent",
    borderRadius: "12px",
    border: "1px solid #297825",
    outline: "none",
    cursor: "pointer",
  },
  selected: {
    background: "#297825",
  },
  label: {
    margin: "0 0 0 20px",
    padding: "0",
    color: "#939598",
    // fontSize: "16px",
    // fontFamily: "Crete Round"
  },
  unit: {
    margin: "0 0 0 10px",
    padding: "0",
    color: "#939598",
    // fontSize: "16px",
    // fontFamily: "Crete Round"
  },
  input: {
    border: 0,
    padding: 0,
    margin: "0px 44px 20px",
    background: "transparent",
    borderBottom: "1px solid #707070",
    width: "200px",
    // bottom: "10px",
    position: "relative",
    // marginTop:"10px"
  },
  inputHidden : {
    display:"none",
  },

  input2: {
    border: 0,
    padding: 0,
    // margin: 0,
    background: "transparent",
    borderBottom: "1px solid #707070",
    width: "200px",
    margin: "60px 0px 0px -48px",
    bottom: "10px",
    position: "relative",
  },
};

import styled from "styled-components"
import Carousel from 'react-elastic-carousel'
import { Container } from "reactstrap";
import { Image } from 'react-bootstrap';


export const StyleMain = styled.div`
  
`;

export const EventTblSection = styled.div`
  background-color: #f0eff0;
  overflow-y: scroll;
  height: 400px;
`
export const CarouselSection = styled(Carousel)`
  /* square buttons */
  .rec.rec-arrow {
    border-radius: 0;
  }
  /* round buttons on hover */
  .rec.rec-arrow:hover {
    border-radius: 50%;
    background-color: rgb(146 149 153);
  }
  .rec-dot_active {
    background-color: rgb(146 149 153);
  }
  .rec-dot {
    box-shadow: 0 0 1px 3px rgb(146 149 153);
  }
  /* hide disabled buttons */
  .rec.rec-arrow:disabled {
    /* visibility: hidden; */
  }
  /* disable default outline on focused items */
  /* add custom outline on focused items */
  .rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 1px lightgrey;
  }
`

export const ControllingSection = styled.div`
  background-color: #f0eff0;
`

export const StickyDiv = styled.div`
  position: relative;
 
`;
export const ContainerSection = styled(Container)`
  .scroll-div {
    position: sticky;
    top:0px;
    z-index:100;
    width:100%;
    height: auto;
    padding-bottom: 10px;
    background-color: #f0eff0; 
    padding: 10px 0px;
  }
  .box_icon_drag {
    textAlign: right;
    color: #929598;
    position: absolute;
    top: -11px;
    left: 2px
  }
  .box_icon_drag_scroll {
    textAlign: right;
    color: #929598;
    position: absolute;
    top: 0px;
    left: 20px
  }
`;

export const CustomizeDashboard = styled.div`
  text-align: right;
  font-size: 13px;
  text-decoration: underline;
  padding-top: 10px;
  padding-bottom: 0px;
`;

export const CustomBtn = styled.button.attrs()`
  background-color: #f0eff0;
  padding: 0.10rem 0.5rem;
  color: #707070;
  border-radius: 3px;
  border-color: #707070;
  &:hover {
    background-color: #707070;
    color: #fff;
    border-color: #707070;
  }
`;

export const styles = {
    container: {
      fontFamily: "Segoe UI, Arial, sans-serif",
      minHeight: "100vh",
      backgroundColor: "#f0eff0",
    },
    bgColor: {
      backgroundColor: "#f0eff0",
    },
    navBar: {
      padding: '0px',
      borderRadius: '5px'
    },
    avatar: {    
      width: 'inherit',
      height: 'inherit',
      objectFit: 'contain',
      verticalAlign: 'middle',
    },
    navpName: {
      color: '#949598',
      margin: '0px',
      fontWeight: '500',
      fontSize: '20px',
    },
    navBrand: {
      paddingTop: 0,
      paddingBottom: 0
    },
    navp: {
      fontSize: '13px',
      color: '#939598',
      margin: 0
    },
    venueLogoSection: {
      alignContent: 'center',
      display: 'flex',
      //background: 'white',
      justifyContent: 'center',
      borderRadius: '5px',
      left: '15px'
    },
    venueLogo: {
      maxWidth: '100%',
      // maxHeight: '100%',
      alignSelf: 'center',
      // padding: '0px 8px',
      // marginRight: 'auto',
      // margin: '-12px',
      // marginLeft: '15px',
      // height: 'auto',
      maxHeight: '100px',
      borderRadius: '5px',
    },
    vl: {
      borderLeft: '1px solid #ccc',
      height: '40px',
      padding: '5px 5px',
      marginLeft: '10px'
    },
    notification: {
      borderRadius: '5px',
      backgroundColor: '#F0EFF0',
      marginTop: '5px',
    },
    notifCount: {
      fontSize: '15px',
      color: '#FEBF54'
    },
    mainbox: {
      justifyContent: 'space-between',
      backgroundColor: '#f8f8f8',
      borderRadius: '5px',
    },
    iconbox: {
      backgroundColor: '#f0eff0',
      margin: '5px 5px 5px',
      borderRadius: '5px',
      textAlign: 'center',
      padding: '5px 0px',
      width: '11%',
      height: '120px',
      cursor: 'pointer',
    },
    iconboxtext: {
      marginTop: '15px',
      fontSize: '14px',
      color: '#929598',
    },
    rightSection: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#fff',
      paddingLeft: '14px',
      borderRadius: '5px'
    },
    profileSection: {
      // paddingTop: '5px',
      alignSelf: 'center',
    },
    imagetext: {
      display: 'flex',
      alignItems: 'center'
    },
    bottomicon: {
      position: 'fixed',
      bottom: '0'
    },
    pointer: {
      cursor: 'pointer'
    },
    tablebox: {
      background: '#000'
    },
    iconimage: {
      width: '50px',
      height: '50px',
      marginTop: '10px',
    },
    boxheading: {
      color: '#8b8b8b',
      fontSize: '18px',
    },
    boxfour: {
      backgroundColor: '#f8f8f8',
      padding: '5px 15px',
      textAlign: 'center',
      height: '230px',
      borderRadius: '5px',
      wordBreak: 'break-all',
    },
    numerictext: {
      color: '#929598',
      fontSize: 40,
      fontWeight: 'bold',
      paddingTop: '20px'
    },
    boxxtext: {
      color: '#929598',
      fontSize: 18,
      fontWeight: 'bold'
    },
    box_p_text: {
      color: '#929598',
      fontSize: 12,
      fontWeight: 'bold',
      textAlign: 'left',
    },
    boxpSmalltext: {
      color: '#929598',
      fontSize: 12,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    boxIconCross: {
      textAlign: 'right',
      color: '#929598',
      height:0
    },
    
    crossimage: {
      width: '15px',
      height: '15px',
      marginLeft: 5,
      cursor: 'pointer',
    },
    widgetRow: {
      
    }
};

export const crateWidgetStyle = {
    header: {
        padding: "20px 10px 15px 10px",
        backgroundColor: '#efefef',
    },
    headerTitle: {
        fontSize: '16px',
        color: '#8f8f8f',
        paddingLeft: '1.3rem',
    },
    label_color: {
        color: '#8f8f8f',
        fontSize: '12px',
        fontWeight: 'bold',
        margin: 0,

    },
    inner_heading: {
        color: '#8f8f8f',
        fontSize: '12px',
        fontWeight: 'bold',
        marginBottom: 10,
        borderBottom: '1px solid #d3d0d0',
    },
    inputgroupheight: {
        height: 28
    },
    card_hight: {
        height: 28,
        backgroundColor: '#f8f8f8',
    },
    dropdownui: {
        fontSize: "13px",
        backgroundColor: '#fff',
        border: '1px solid #e3e2e2',
        padding: 0,
        height: 28

    },
    cardui_small: {
        height: 70,
        backgroundColor: '#fff',
        border: '1px dotted #e3e2e2',
    },
    modal_ui: {
        backgroundColor: '#efefef',
        borderRadius: '5px'
    },
    card_color: {
        backgroundColor: '#f8f8f8',
    },
    box_inner_heading: {
        color: '#8f8f8f',
        fontSize: '10px',
        fontWeight: 'bold',
        marginBottom: 5,
        textDecoration: 'underline'

    },
    btn_secondary: {
        backgroundColor: '#00a394',
        borderColor: '#00a394',
        padding: '1px 20px',
    },
    btn_primary: {
        borderColor: '#00a394',
        color: '#00a394',
        marginRight: '10px',
        padding: '1px 20px',

    },
}

export const ErrorMsg = styled.p`
    color: #ff0000;
    font-size: 15px; 
`

export const StylePlusIcon = styled(Image)`
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50px;
    padding: 10px;
    cursor: pointer;
`

export const StyleComingSoon = styled.div`
  .index_fullpage__2JorV {
    z-index: 0000;
  }
`

export const StyleNoData = styled.div`
  background-color: #fff;
  padding: 5px 15px;
  text-align: center;
  height: 400px;
  border-radius: 5px;
  p {
    align-self: center;
  }
`

export const StyleNoDataSM = styled.div`
  background-color: #fff;
  padding: 5px 15px;
  text-align: center;
  height: 125px;
  border-radius: 5px;
  p {
    align-self: center;
  }
`

export const StyleProfileSection = styled.div`
  align-self: center;
  .profile-default {
    padding: 10px;
  }
`
import React from  "react";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
const override = css`
   font-weight: 500;
        width: 6.5rem;
        height: 6.5rem;
        display: block;
        left: 50px;
        right: 0;
        position: absolute;
        top: 50px;
        bottom: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
        z-index: 50000;
        font-style:Robot;
`;
export default function Loader({...props}){
     return (
         <>
      
            
      <ClipLoader  size={60} css={override}/>
     
         </>
     )
}

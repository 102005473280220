import React, { Component } from "react";
import { StateContext } from "../../context/state";
import cancelIcon from "../../assets/cancel.svg";
import saveIcon from "../../assets/save.svg";
import crossIcon from "../../assets/cross.svg";
import duplicateIcon from "../../assets/duplicate.svg";
import trashIcon from "../../assets/trash.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import HttpService from "../../services/HttpService";
import plusIcon from "../../assets/plus.svg";
import exportCsvIcon from "../../assets/csv_export.svg";
import Header from "./Header";
import DropDown from "./DropDown";
import swal from "sweetalert";
import { CSVLink } from "react-csv";
import TagHeader from "./TagHeader";
import { withTranslation } from 'react-i18next';
import { DateRange } from "react-date-range";
import Moment from 'react-moment';
import moment from 'moment'
import CurrencyFormat from 'react-currency-format';
import { registerLocale } from "react-datepicker";
import { localeGerman } from '../../Constants'
import * as rdrLocales from 'react-date-range/dist/locale';
registerLocale('de', localeGerman)

const startDateInt = moment(new Date()).subtract(14, 'days').format("YYYY-MM-DD") + 'T00:00:00Z';
const endDateInt = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD") + 'T24:00:00Z';

class StatisticTable extends Component {
  static contextType = StateContext;
  original = {};
  constructor(props) {
    super(props);
    this.csvLinkEl = React.createRef();
    this.scrollRef = React.createRef();
    this.state = {
      tables: [],
      headers: [],
      table: {},
      data: {},
      original: {},
      tags: [],
      scroll:false,
      tagView: { show: false },
      aggregates: [
        { _id: 1, name: "€" },
        { _id: 2, name: "Anzahl gebuchte" },
        // {_id: 3, name: "Kalkulieren"},
        { _id: 4, name: "Summe" },
        { _id: 5, name: "Text"}
      ],
      tableLoad: true,
      type: "event",
      clickLoad: true,
      showDropdownOptions: false,
      focusedInput: null,
      startDate: startDateInt,//new Date(+new Date + 12096e5),
      endDate: endDateInt,//new Date(),
      ranges: [{
        startDate: null,
        endDate: null,
        key: "range",
      }],
      selfUpdate: false,
      hovered: false,
      showPlus: true,
      isClickedPlus: false,
      csvHeaders: [],
      csvData: []
    };
  }

  componentDidMount() {
    let tables, headers, tags;
    const { type, startDate, endDate } = this.state;
    const { loggedInUserId, venueId } = this.context;
    const fetchTablesPromise = HttpService.fetchUserTables(loggedInUserId).then(
      (res) => {
       
        tables = res;
        tables.unshift({
          // name: this.props.t("create new table"),
          name: "create new table",
          headers: [{ _id: "1", name: "Fragebogen" }],
        });
       
      
      }
    );

    //Fetching Header details
    const fetchHeadersPromise = HttpService.fetchTableHeaders().then((res) => {
      res.headers = res.headers.map((header) => ({
        _id: header.headerId,
        name: header.name,
      }));
     
      res.headers.push({ _id: "-1", name: "Tag hinzufügen" });
     
      headers = [...res.headers];
      this.original.headers = [...res.headers];
     
    });

    const fetchTagsPromise = HttpService.fetchTags(venueId).then(
      (res) =>
      (tags = res?.tags?.map((tag) => ({
        name: tag.label,
        value: tag.value,
      })))
    );

    Promise.all([
      fetchTablesPromise,
      fetchHeadersPromise,
      fetchTagsPromise,
    ]).then(() => {
      const selectedTable = tables[0];//tables[1] ?? tables[0];
      const _ids = selectedTable?.headers?.map(({ _id }) => _id);
      HttpService.fetchHeaderDetails({ venueId, headers: _ids, type, fromDate: startDate, toDate: endDate }).then(
        (data) => {
          headers = headers.filter(({ _id }) => !_ids.includes(_id));
          this.setState({
            tables,
            headers,
            tags,
            table: selectedTable,
            data,
            original: data,
            tableLoad: false,
            clickLoad: false,
          });
         
        }
      );
    });
    
  }
 

  onTableSelect = (table) => {
    const { venueId } = this.context;
    const { type, startDate, endDate } = this.state;
    this.setState({
      table,
      tableLoad: true,
      showDropdownOptions: false,
    });
    if (!table._id) {
      table.headers = [{ _id: "-1", name: "Tag hinzufügen" }];
      const _ids = [...new Set(table?.headers?.map(({ _id }) => _id))];
      HttpService.fetchHeaderDetails({ venueId, headers: _ids, type, fromDate: startDate, toDate: endDate }).then(
        (data) => {
          console.log(data)
          this.setState({
            data,
            headers: this.original.headers
              .filter((header) => header._id !== "1")
              .map((header) => ({ ...header })),
            tableLoad: false,
            showPlus: true,
            type: 'event',
          });
          this.scrollRef.current.scrollIntoView(
            {
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest'
            })
         
        }
      );
    } else {
      HttpService.fetchTableDetails(table._id).then((res) => {
      res = { ...res, _id: res._id };
        const _ids = [...new Set(res?.headers?.map(({ _id }) => _id))];
        _ids.push('1');
        const headers = [...this.original.headers].filter(
          (header) => !_ids.includes(header._id) || header._id === '-1');
        let newType = type;
        if (table.type) {
          newType = table.type;
          this.setState({
            type: table.type,
          })
        
        }
        
        HttpService.fetchHeaderDetails({ venueId, headers: _ids, type: newType, fromDate: startDate, toDate: endDate }).then(
          (data) => {
          this.setState({
              table: res,
              data,
              headers,
              tableLoad: false,
              showPlus: true,
            });
            this.scrollRef.current.scrollIntoView(
              {
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest'
              })
          }
        );
      });
    }
  };
  

  onHeaderSelect = (header, index, _index) => {
    console.log(header)
    const { data, table, headers, type } = this.state;
    
    if (index === 0) {
      table.headers = [{ _id: "-1", name: "Tag hinzufügen" }];
      this.setState({ table, data: {} });
    }

    if (headers.length === 1 || header?._id === "-1") {
        this.setState({tagView: {show: true, index}});
       
        
    }
    if ((headers.length === 1 && index !== 0) || header?._id === "-1") {
      this.setState({ tagView: { show: true, index } });
     
    }
     else {
      if (index === -1) {
        this.setState({
          isClickedPlus: true,
          showPlus: false,
         
        })
        table.headers.push({ _id: "", name: "" });
       
        if (header === null) {
          this.setState({
            showDropdownOptions: true,
          });
        }
      }
      else {
        if (index !== 0) {
          table.headers[index] = header;
          // delete headers[_index];
          headers.splice(_index, 1);
          // headers.unshift(prev);
        }
      }
     
      const state = {
        table,
        headers,
        type,
      };

      const newData = {};
      if (index !== 0) {
        Object.keys(data).forEach((key, i) => {
          if (index !== i) newData[key] = data[key];
        });
      }

      state.data = newData;
      if (index === 0) {
        if (_index === 1 && header?.name === "Questionnaire") {
          state.type = "questionnaire";
        } else {
          state.type = "event";
        }
        state.headers = this.original.headers
          .filter((header) => header._id !== "1")
          .map((header) => ({ ...header }));
      }
      this.setState(state);
      if (index !== -1) {
        this.handleFetchHeaderDetails([header._id], newData, state.type);
        if (this.scrollRef.current) {
          this.scrollRef.current.scrollIntoView(
            {
              behavior: 'smooth',
              block: 'end',
              inline: 'end'
            })
        }
      }
     
    }
  
   
  };

  onTagSelect = (tagId) => {
    const { tagView, table, headers, data, type } = this.state;
    const { index } = tagView;
    const header = { _id: tagId, name: "Tag hinzufügen" };
    const state = {
      table,
      headers,
      tagView: { show: false },
    };
    const newData = {};
    Object.keys(data).forEach((key, i) => {
      if (index !== i) newData[key] = data[key];
    });
    state.data = newData;
    if (index === -1) {
      state.table.headers.push(header);
    } else {
      const prev = state.table.headers[index];
      state.table.headers[index] = header;
      state.headers.unshift(prev);
    }
    this.handleFetchHeaderDetails([tagId], newData, type);
    this.setState(state);
  };

  handleFetchHeaderDetails = (filterHeader, newData, type) => {
    const { venueId } = this.context;
    const { table, startDate, endDate } = this.state;
    this.setState({
      clickLoad: true,
      showPlus: false
    });
    HttpService.fetchHeaderDetails({ venueId, headers: filterHeader, type, fromDate: startDate, toDate: endDate }).then(
      (respData) => {
        const _ids = table?.headers?.map((header) => header?._id) ?? [];
        const newHeaders = [...this.original.headers].filter(
          (header) => (!_ids.includes(header._id) && header._id !== 1) || header._id === '-1'
        );
        let newObj = Object.assign({}, newData, respData);
        this.setState({
          data: newObj,
          clickLoad: false,
          showPlus: true,
          isClickedPlus: false,
          headers: newHeaders,
        });
        
        this.scrollRef.current.scrollIntoView({ block: "end", behavior: 'smooth', inline: "end" })
      }
    );
  }

  fetchFilterDetails = (startDate, endDate) => {
    const { venueId } = this.context;
    const { type, table } = this.state;
    if (!table._id) {
      // table.headers = [{ _id: "-1", name: "Tag hinzufügen" }];
      // const _ids = [...new Set(table?.headers?.map(({ _id }) => _id))];
      const _ids = table?.headers?.map((header) => header?._id) ?? [];
      HttpService.fetchHeaderDetails({ venueId, headers: _ids, type, fromDate: startDate, toDate: endDate }).then(
        (data) => {
          this.setState({
            data,
            headers: this.original.headers
              .filter((header) => (!_ids.includes(header._id) && header._id !== 1) || header._id === '-1')
              .map((header) => ({ ...header })),
            tableLoad: false,
            showPlus: true,
          });
          this.scrollRef.current.scrollIntoView(
            {
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest'
            })
        }
      );
    } else {
      HttpService.fetchTableDetails(table._id).then((res) => {
        res = { ...res, _id: res._id };
        const _ids = [...new Set(res?.headers?.map(({ _id }) => _id))];
        _ids.push('1');
        const headers = [...this.original.headers].filter(
          (header) => !_ids.includes(header._id) || header._id === '-1'
        );
        HttpService.fetchHeaderDetails({ venueId, headers: _ids, type, fromDate: startDate, toDate: endDate }).then(
          (data) => {
            this.setState({
              table: res,
              data,
              headers,
              tableLoad: false,
              showPlus: true,
              isClickedPlus: false
            });
          }
        );
      });
    }
  }

  onHeaderRemove = (index) => {
    const { table, headers, data } = this.state;
    delete data[Object.keys(data)?.[index]];
    let header = table.headers.splice(index, 1)?.[0];

    if ((header._id !== "-1" && !isNaN(Number(header._id))))
      headers.unshift(header);

    var newHeaders = headers.filter(function (el) { return el._id !== ""; });

    this.setState({ table, headers: newHeaders, data, showPlus: true });
  };

  setHeaders = (headers, index) => {
    const { type } = this.state;
    if (index === 0) {
      const defaultHeader = [
        { _id: 0, name: "Event-name" },
        { _id: 0, name: "Questionnaire" },
      ];
      return defaultHeader;
    } else {
      if (type === 'questionnaire') {
        const _ids = ['2', '3']
        return _ids && headers.filter(({ _id }) => !_ids.includes(_id));
      }
      return headers;
    }
   
  };

  setHeaderData = (data) => {
    this.setState({ data })
  //  this.scrollRef.current.scrollIntoView(
  //     {
  //       behavior: 'smooth',
  //       block: 'end',
  //       inline: 'nearest'
  //     })
  }

  updateState = (state) => {
    this.setState({
      ...state,
      selfUpdate: true,
    });
  };

  setRange = (item, e) => {
    const startDate = moment(item?.range?.startDate).subtract(1, 'days').format("YYYY-MM-DD") + 'T00:00:00Z';
    const endDate = moment(item?.range?.endDate).subtract(1, 'days').format("YYYY-MM-DD") + 'T24:00:00Z';
    this.fetchFilterDetails(startDate, endDate);
    if (item?.range?.startDate !== item?.range?.endDate) {
      // data = allEvents?.filter((row) => {
      //   if (
      //     Date.parse(row.deadlineForEntries) >= Date.parse(startDate) &&
      //     Date.parse(row.deadlineForEntries) <= Date.parse(endDate)
      //   ) {
      //     return row;
      //   }
      // });
      // this.updateState({ rows: data });
      // if (this.props.FilteredData) {
      //   this.props.FilteredData(data);
      // }
      this.setState({
        focusedInput: null,
        startDate,
        endDate,
        ranges: [item.range],
      });
    } else {
      this.setState({
        ranges: [item.range],
      });
    }
  };

  onCancelDateFilter = (e) => {
    e.preventDefault();
    this.setState({
      startDate: null,
      endDate: null,
      focusedInput: null,
    });
    this.fetchFilterDetails(null, null);
  }

  showDateRange = () => {
    this.setState({
      focusedInput: !this.state.focusedInput,
    })
  }

  sortTable = (header, sortType) => {
    let { data } = this.state;
    const swapped = Object.fromEntries(Object.entries(data[header]).map(a => a.reverse()));
    let cloneObj = JSON.parse(JSON.stringify(data));
    if (header === "Gaste" || header === "Umsatz") {
      if (sortType === "asc") {
        cloneObj[header].sort(function (a, b) {
          return a - b;
        });
      } else {
        cloneObj[header].sort(function (a, b) {
          return b - a;
        });
      }
    } else if (header === "Datum") {
      cloneObj[header].sort((a, b) => {
        return sortType === "asc"
          ? a.toString().localeCompare(b)
          : b.toString().localeCompare(a);
      });
    } else {
      if (sortType === "asc") {
        cloneObj[header].sort();
      } else {
        cloneObj[header].sort();
        cloneObj[header].reverse();
      }
    }
    //Assign another column values
    Object.keys(cloneObj).forEach((name) => {
      if (header !== name) {
        let newObj = cloneObj[header].map((val, key) => {
          return cloneObj[name][swapped[val]];
        });
        cloneObj[name] = newObj;
      }
    });
    this.setState({ data: cloneObj });
  };

  buildExportData = () => {
    const { data } = this.state;

    const result = [];
    result.push(Object.keys(data));

    Object.values(data).forEach(value => {
      const row = [];
      value.forEach(item => {
        row.push(item);
      });
      result.push(row)
    });

    return result;
  };

  buildExportCsv = async () => {
    const { data } = this.state;
    let tempObjHeaders = Object.keys(data).map((d) => {
      return this.props.t(d);
    });

    const objFirst = Object.values(data).find(e => true);

    let tempObjData = objFirst.map((val, key) => {
      return Object.keys(data).map((dt) => {
        if (dt === 'Datum') {
          return new Date(data[dt][key]).toLocaleDateString()
        }
        return data[dt][key];
      })
    })
    this.setState({ csvData: tempObjData, csvHeaders: tempObjHeaders }, () => {
      setTimeout(() => {
        this.csvLinkEl.current.link.click();
      });
    });
  }

  buttons = [
    {
      id: 1,
      name: "undo",
      icon: cancelIcon,
      tooltipText: "return",
      onPress: (table, index) => {
        this.onTableSelect(table);
      },
      isVisible: (table) => !!table?._id,
    },
    {
      id: 2,
      name: "save",
      icon: saveIcon,
      tooltipText: "save",
      onPress: (table, index) => {
        table = { ...table };
        const { loggedInUserId } = this.context;

        if (table?._id) {
          HttpService.updateTable(table?._id, {
            ...table,
            userId: loggedInUserId,
          })
            .then((res) => {
              swal(`${this.props.t('Aktualisierung erfolgreich!')}`);
            })
            .catch((err) => {
              if (err) {
                swal(`${this.props.t('Update fehlgeschlagen!')}`, "error");
              } else {
                swal.stopLoading();
                swal.close();
              }
            });
        } else {
          swal({
            text: `${this.props.t('Bitte benennen Sie diese Tabelle')}`,
            content: "input",
            button: {
              text: `${this.props.t('save')}`,
              closeModal: false,
            },
          })
            .then((name) => {
              if (!name) throw null;
              table.name = name;
              return HttpService.saveTable({
                ...table,
                userId: loggedInUserId,
                type: this.state.type
              });
            })
            .then((res) => {
              if (res?.status === "Success") {
                swal(`${this.props.t('Speichern erfolgreich')}`);
                const { tables } = this.state;
                tables.push({ _id: res?.id, name: table.name });
                table._id = res?.id;
                this.setState({
                  tables,
                  table,
                });
              } else {
                swal(`${this.props.t('Speichern fehlgeschlagen!')}`, "error");
              }
            })
            .catch((err) => {
              if (err) {
                swal(`${this.props.t('Speichern fehlgeschlagen!')}`, "error");
              } else {
                swal.stopLoading();
                swal.close();
              }
            });
        }
      },
      isVisible: (table) => true,
    },
    {
      id: 3,
      name: "duplicate",
      icon: duplicateIcon,
      tooltipText: "Kopieren",
      onPress: (table, index) => {
        table = { ...table };
        swal({
          text: `${this.props.t('Bitte nennen Sie diese geklonte Tabelle')}`,
          content: "input",
          button: {
            text: "Save",
            closeModal: false,
          },
        })
          .then((name) => {
            if (!name) throw null;
            table.name = name;
            return HttpService.cloneTable(table._id, name);
          })
          .then((res) => {
            if (res?.status === "Success") {
              swal(`${this.props.t('Klon erfolgreich')}`);
              const { tables } = this.state;
              tables.push({ _id: res?.id, name: table.name });
              table._id = res?.id;
              this.setState({
                tables,
                table,
              });
            } else {
              swal(`${this.props.t('Klonen fehlgeschlagen!')}`, "error");
            }
          })
          .catch((err) => {
            if (err) {
              swal(`${this.props.t('Klonen fehlgeschlagen!')}`, "error");
            } else {
              swal.stopLoading();
              swal.close();
            }
          });
      },
      isVisible: (table) => !!table?._id,
    },
    {
      id: 4,
      name: "delete",
      icon: trashIcon,
      tooltipText: "Löschen",
      onPress: (table) => {
        swal({
          title: `${this.props.t('Bist du sicher')}`,
          text: "",
          icon: "warning",
          buttons: [
            'Nein!',
            'Ja!'
          ],
          dangerMode: true,
        }).then(function (isConfirm) {
          if (isConfirm) {
            return HttpService.deleteTable(table._id);
          } else {

          }
        }).then((res) => {
          if (res?.status === "Success") {
            swal(`${this.props.t('Erfolgreich löschen')}`);
            const tables = this.state.tables.filter((t) => t._id !== table._id);
            this.setState({
              tables,
            });
            this.onTableSelect(tables[0]);
          }
        }).catch((err) => {
          if (err) {
            swal(`${this.props.t('Löschen fehlgeschlagen!')}`, "error");
          }
        });
      },
      isVisible: (table) => !!table?._id,
    },
    {
      id: 5,
      name: "export",
      icon: exportCsvIcon,
      tooltipText: "export",
      onPress: (event) => { },
      isVisible: (table) => true,
      renderer: (table) => {
        return (
          <>
            <img
              src={exportCsvIcon}
              alt={"export"}
              style={{ ...styles.icon }}
              onClick={() => this.buildExportCsv()}
            />
            <CSVLink
              headers={this.state.csvHeaders}
              filename={`${table?.name}.csv`}
              data={this.state.csvData}
              ref={this.csvLinkEl}
            />
          </>
        );
      },
    },
  ];

  render() {
    const {
      tables,
      table,
      tableLoad,
      data,
      headers,
      tagView,
      tags,
      aggregates,
      clickLoad,
      showDropdownOptions,
      hovered,
      showPlus,
      type,
      isClickedPlus
    } = this.state;
    const { currencyFormat } = this.context;
    const names = Object.keys(data);
    const values = Object.values(data);
    return (
      <>
      <div style={styles.container}>
        <div style={styles.topSection}>
            <DropDown
              style={{borderRadius:"3px", border:"0.1px solid #90909025"}}
            placeholder={this.props.t('Tabelle auswählen')}
            options={tables}
            value={table?._id}
            onSelect={(table) => this.onTableSelect(table)}
            disabled={false}
            hideSort={true}
          />
          <div style={styles.dateFilter} onMouseEnter={() => this.setState({ hovered: true })}
            onMouseLeave={() => this.setState({ hovered: false })}>
            {hovered &&
              <img
                src={crossIcon}
                style={styles.closeIcon} alt=""
                onClick={(e) => this.onCancelDateFilter(e)}
              />
            }
            <button
              className="buttons dummy_button"
              style={styles.btnDateRange}
              onClick={this.showDateRange}
            >
              {this.state.startDate ? <><Moment format='DD-MM-YYYY'>{moment(this.state.startDate).add(1, 'days').toDate()}</Moment> - <Moment format='DD-MM-YYYY'>{moment(this.state.endDate).toDate()}</Moment></> : this.props.t('Select Date')}
            </button>
          </div>
          {this.state.focusedInput &&
            <>
              <DateRange
                locale={rdrLocales.de}
                startDatePlaceholder={this.props.t("Früher")}
                endDatePlaceholder={this.props.t("Später")}
                className="date-range-picker"
                onChange={this.setRange}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                ranges={this.state.ranges}
                onFocusChange={focusedInput => {
                  // Do not apply if it is null
                  if (focusedInput) {
                    this.setState({ focusedInput: !this.state.focusedInput });
                  }
                }}
                shouldCloseOnSelect={false}
                staticRanges={[]}
                inputRanges={[]}
              />
            </>
          }
          <div style={{ flexGrow: 1 }} />
          {this.buttons.map(
            (button, _index) =>
              button.isVisible(table) && (
                <OverlayTrigger
                  key={_index}
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-disabled`}>
                      {/* <span>{this.props.t(button.tooltipText)}</span> */}
                      <span>{this.props.t(button.tooltipText)}</span>
                    </Tooltip>
                  }
                >
                  {button.renderer?.(table) ?? (
                    <img
                      ref={this.scrollRef}
                      src={button.icon}
                      alt={button.name}
                      style={styles.icon}
                      onClick={() => button?.onPress?.(table)}
                    />
                  )}
                </OverlayTrigger>
              )
          )}
        </div>
        <div style={table.headers && table.headers.length > 0 ? styles.events : styles.eventsBorderNone} ref={this.scrollRef}>
          <div style={styles.content} ref={this.scrollRef}>
            <div style={styles.row}>
              {!tableLoad ? (
                table?.headers?.map((header, index) => {
                  return (
                    <div style={styles.column} key={index}  ref={this.scrollRef} >
                      <div style={styles.sticky} ref={this.scrollRef}>
                        <Header 
                          
                          setHeaderData={this.setHeaderData}
                          header={header}
                          name={names[index] === 'Name' ? type : names[index]}
                          headers={this.setHeaders(headers, index)}
                          onSelect={(header, _index) =>
                            this.onHeaderSelect(header, index, _index)
                          }
                          onRemove={() => this.onHeaderRemove(index)}
                          //disabled={!showPlus}
                          columnIndex={index}
                          data={this.state.data}
                          original={this.state.original}
                          showDropdownOptions={showDropdownOptions}
                          onSortTable={this.sortTable}
                          hideSort={true}
                        />
                      </div>
                      <div >
                        {values[index]?.map((value, _index) => (
                          <div style={styles.value} key={_index}   >
                            {names[index] === "Datum"
                              ? new Date(value).toLocaleDateString()
                              : (!['Name', 'Datum', 'Kunde', 'Gaste'].includes(names[index]) && !names[index].includes('Text: ') ?
                                    <CurrencyFormat value={value} displayType={'text'} fixedDecimalScale={true} decimalScale={2} thousandSeparator={true} prefix={currencyFormat} /> : value)}
                          </div>
                        )) ?? (clickLoad ? <div style={styles.loading}>{this.props.t('Loading')}.</div> : '')}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div style={styles.loading}>{this.props.t('Loading')}.</div>
              )}
             </div>
            
            <div style={styles.content} ref={this.scrollRef}>
            {!tableLoad && table.headers && table.headers.length > 0 && showPlus && (
              <div style={styles.sticky} ref={this.scrollRef}>
                <img
                  // ref={this.scrollRef}
                  src={plusIcon}
                  style={styles.addIcon}
                  alt=""
                  onClick={() => this.onHeaderSelect(null, -1)}
                />
                </div>
             
            )}
           </div>
         
            </div>
          </div>
        </div>
        <TagHeader
        
          collapsed={!tagView?.show}
          onChange={(collapsed) =>
            this.setState({ tagView: { show: !collapsed } })
          }
          options={tags}
          aggregates={aggregates}
          headers={table.headers}
          onSelect={this.onTagSelect}
          showPlus={(e) => this.setState({ showPlus: e })}
          isClickedPlus={isClickedPlus}
        />
      </>
 
    );
  }
}

export default withTranslation()(StatisticTable);

const styles = {
	divstyle: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		width: 'max-content',
		borderRadius: '3px',
	},
	scrolldiv: {
		overflow: 'auto',
		maxHeight: '900px',
		border: '2px solid #0000000d',
		borderRadius: '4px',
		marginTop: '4px',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		overflowX: 'hidden',
		borderRadius: '3px',
	},
	loading: {
		margin: '10px 10px 0 5px',
		padding: '0 10px',
		borderRadius: '3px',
		border: '0.1px solid #90909020',
	},
	topSection: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		borderRadius: '3px',
	},
	icon: {
		width: '18px',
		height: '18px',
		opacity: 0.9,
		margin: '2px',
		cursor: 'pointer',
	},
	header: {
		border: 0,
		margin: 0,
		minWidth: '150px',
		fontSize: '14px',
		background: '#d0d3d4',
		padding: '10px 5px',
		alignItems: 'center',
		display: 'flex',
		borderRadius: '3px',
	},
	headerText: {
		padding: '10px 5px',
		width: '100%',
		background: '#e7e7e8',
		borderRadius: '3px',
	},
	content: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		width: 'max-content',
		background: '#f0eff0',
		paddingBottom: '20px',
		minHeight: '250px',
		borderRadius: '3px',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		borderRadius: '3px',
	},
	column: {
		marginTop: '10px',
		display: 'flex',
		flexDirection: 'column',
		flexShrink: '0',
		borderRadius: '3px',
	},
	value: {
		margin: '10px 10px 0 5px',
		padding: '0 10px',
		background: '#e7e7e8',
		display: 'flex',
		alignItems: 'center',
		minHeight: '60px',
		width: '180px',
		borderRadius: '3px',
		border: '0.1px solid #90909020',
	},
	addIcon: {
		margin: '32px 20px 0 20px',
		cursor: 'pointer',
	},
	events: {
		overflow: 'auto',
		maxHeight: '900px',
		border: '2px solid #0000000d',
		borderRadius: '3px',
		marginTop: '4px',
	},
	eventsBorderNone: {
		overflow: 'auto',
		maxHeight: '900px',
		borderRadius: '4px',
		marginTop: '4px',
	},
	sticky: {
		position: '-webkit-sticky',
		top: 0,
		padding: '0px',
		paddingBottom: '5px',
		maxHeight: '900px',
		borderRadius: '3px',
	},
	btnDateRange: {
		width: '200px',
		borderRadius: '3px',
	},
	dateFilter: {
		alignItems: 'center',
		display: 'flex',
		position: 'relative',
		borderRadius: '3px',
	},
	closeIcon: {
		position: 'absolute',
		top: 5,
		left: 200,
		width: '16px',
		height: '16px',
		cursor: 'pointer',
		zIndex: '1',
		borderRadius: '3px',
	},
};

import React, { Component } from "react";
import PullBar from "./PullBar";
import HttpService from "../../services/HttpService";
import { StateContext } from "../../context/state";
import euroSmall from "../../assets/euro-light.svg"
import { Spinner } from "reactstrap";
import { withTranslation} from 'react-i18next';

 class PriceBar extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);

    this.state = {
      total: "0.00",
      current: "0.00",
      priceModel: {},
      questionnaire: {},
      activeTheme: undefined,
      activeService: undefined,
      couple: props?.couple,
      latestPrice: [],
      guestInfo:{},
      collapsed: false,
    };
  }
  collapseToggle = () => {
    this.setState(prevState => ({ collapsed: !prevState.collapsed }));
  };
  static getDerivedStateFromProps(nextProps) {
    if (!nextProps.loading) {
      const {
        activeTheme,
        activeService,
        guestPriceObj,
        latestPrice,
      } = nextProps;
      const { model, valid } = PriceBar.buildPriceModel(nextProps);
      if (nextProps.onValidate) nextProps.onValidate(valid);

      const total = Number(nextProps?.totalAmount)?.toFixed(2) ?? "0.00";
      const current = PriceBar.calculateTotalPrice(model)?.toFixed(2) ?? "0.00";

      return {
        model,
        total,
        current,
        activeTheme,
        activeService,
        guestPriceObj,
        latestPrice,
      };
    }
  }
componentDidMount(){
  const {  questionnaireId } = this.context;
  if (window.innerWidth <1024) this.setState({ collapsed: true });
  window.addEventListener("resize", () => {
    if (window.innerWidth < 1024) this.setState({ collapsed: true });
    else this.setState({ collapsed: false });
  });
  HttpService.getGuests(questionnaireId).then(
    (response) => {
      // console.log("resp",response)
      this.setState({
        guestInfo:response.guestLabel
      })
    }
  );
}
  static calculateTotalPrice = (model) => {
    let sum = 0;
    Object.getOwnPropertyNames(model).forEach((key) => {
      if (Number(model[key])) sum += model[key];
    });

    return Number(sum);
  };
  priceRefesh=async()=>{
    const {questionnaireId,loggedInUserId}=this.context
    const{activeTheme,activeService}=this.props
    
    await HttpService.uploadAnswer(
     questionnaireId,
     activeTheme.id,
     activeService.id,
     loggedInUserId,
     this.props.questionnaire
   );
   let questionnaire = await HttpService.getForms(
    questionnaireId,
    activeTheme.id,
    activeService.id
  );
  let themes = (await HttpService.getThemes(this.context.questionnaireId))
    ?.themeApiObject;
  
  themes = themes?.themes ?? [];
  for (let theme of themes)
    theme.services = theme?.services?.filter((service) => service?.display);
  themes = themes.filter((theme) => theme?.services?.length > 0);
  themes = [{ id: -1, name: "Anzahl Gäste", services: [] }, ...themes];
  
     let latestPrice = await HttpService.getPriceBarLatestData(questionnaireId);
     if(this.props.updatePriceState){
      this.props.updatePriceState(latestPrice,themes,questionnaire)
     }
    
   
  }
  static buildPriceModel = (props) => {
    const { activeService, questionnaire } = props;
    let valid = true;
    const model = {};

    if (!activeService) return { model, valid };
    if (questionnaire?.question )  
      questionnaire.question.forEach((item) => {
        if (item.row)
          item.row.forEach((_item) => {
            if (_item.column)
              _item.column.forEach((__item) => {
                if (__item.option?.type === "text") {
                  const {
                    textInput: { inputs },
                  } = __item.option;
                  if (inputs) 
                    inputs.forEach((input) => {
                      const {
                        interactive,
                        numeric,
                        answer,
                        price,
                        priceType,
                        interactiveText,
                      } = input;
                      if (interactive && numeric) {
                        if (price && priceType) {
                          model[interactiveText] =
                            answer?.amount ?? answer?.error ?? "";
                          if (!answer?.amount) valid = false;
                        } else if (answer?.error) {
                          model[interactiveText] = answer?.error;
                          valid = false;
                        }
                      }
                    });
                } else if (__item.option.type === "image") {
                  const {
                    imageInput: { question, multipleSelection, inputs },
                    answer,
                  } = __item.option;
                  if (multipleSelection === "true") {
                    if (answer?.length) {
                      let dummyAmt = 0;
                      let dummyErr = "";
                      for (let ans of answer) {
                        //   console.log("ans", ans);
                        const { id, amount, error } = ans;
                        let findIndex = inputs.findIndex((input) => input.id === id);
                        const input = inputs[findIndex];
                        //   console.log("input", input, amount);
                        if (input?.price && input?.priceType) {
                          if (amount) dummyAmt += Number(amount);
                          if (error) dummyErr = error;
                        }
                      }
                      // console.log("dummy", dummyAmt);
                      model[question] = dummyErr.length ? dummyErr : dummyAmt;
                      // if (!dummyAmt) valid = false;
                    } else valid = false;
                  } else {
                    if (answer?.id || answer?.id === 0) {
                      const { id, amount, error } = answer;
                      let findIndex = inputs.findIndex((input) => input.id === id);
                      const input = inputs[findIndex];

                      if (input?.price && input?.priceType) {
                        model[question] = amount ?? error;
                        if (!amount) valid = false;
                      }
                    } else valid = false;
                  }
                } else if (__item.option.type === "radio") {
                  const {
                    radioInput: { question, multipleSelection, inputs },
                    answer,
                  } = __item.option;
                  if (multipleSelection === "true") {
                    if (answer?.length) {
                      let dummyAmt = 0;
                      let dummyErr = "";
                      for (let ans of answer) {
                        //   console.log("ans", ans);
                        const { id, amount, error } = ans;
                        let findIndex = inputs.findIndex((input) => input.id === id);
                        const input = inputs[findIndex];

                        if (input?.price && input?.priceType) {
                          if (amount) dummyAmt += Number(amount);
                          if (error) dummyErr = error;
                        }
                      }
                      model[`${question}`] = dummyErr.length ? dummyErr : dummyAmt;
                      // if (!dummyAmt) valid = false;
                    } else valid = false;
                  } else {
                    if (answer?.id || answer?.id === 0) {
                      const { id, amount, error } = answer;
                      let findIndex = inputs.findIndex((input) => input.id === id);
                      const input = inputs[findIndex];

                      if (input?.price && input?.priceType) {
                        model[`${question}: ${input?.value}`] = amount ?? error;
                        if (!amount) valid = false;
                      }
                    } else valid = false;
                  }
                }
              })
            ;
          })
        ;
      })
    ;
    return { model, valid };
  };
  calculateGuestTotal = (model) => {
    let sum = 0;
    Object.getOwnPropertyNames(model).forEach((key) => {
      if (Number(model[key])) sum += model[key];
    });

    return Number(sum).toFixed(2);

  };
  findLabel=(name)=>{
    const {guestInfo}=this.state
  
    if(name==="adults"){
      
      return guestInfo.adults
    }
    else if(name==="U12"){
      return guestInfo.U12
    }
    else if(name==="U18"){
      return guestInfo.U18
    }
    else if(name==="U6"){
      return guestInfo.U6
    }
    else {
      return null
    }
  }
  renderGuestPriceContent = () => {
    const { guestPriceObj } = this.props;
  
    // eslint-disable-next-line array-callback-return
    return Object.keys(guestPriceObj).map((key, index) => {
      if (guestPriceObj[key])
        return (
          <React.Fragment key={index}>
            
            {Number(guestPriceObj[key]) ? 
              // null
              // <div style={{ ...styles.bold, marginBottom: "5px" }}>{`${
              //   Number(guestPriceObj[key]).toFixed(2) ?? 0
              // } €`}</div>
              <>
              <br/>
              <div style={{ ...styles.text }}>{this.findLabel(key, index)}</div>
             <div
             
             >
            
            {`
              ${this.context.languageFormat==="en" ? Number(guestPriceObj[key]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") :Number(guestPriceObj[key]).toFixed(2)
              .replace(".", ",")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".") ?? 0}
               ${this.context.currencyFormat}`}
             </div>
             </>
             : (
               <>
               <br/>
               <div style={{ ...styles.text }}>{this.findLabel(key, index)}</div>
              <div
                style={{ ...styles.bold, color: "red", marginBottom: "5px" }}
              >
             
                {guestPriceObj[key]}
              </div>
              </>
            )}
          </React.Fragment>
        );
    });
  };

  renderContent = () => {
    const { activeTheme, activeService, model } = this.state;
    if (!activeTheme || !activeService) return;
    // eslint-disable-next-line array-callback-return
    return Object.keys(model).map((key, index) => {
      // console.log("model", model);
      if (model[key])
        return (
          <React.Fragment key={index}>
            
            {Number(model[key]) ? (
              null
              
            ) : (
              <>
              <div style={{ ...styles.text }}>{key}</div>
              <div
                style={{ ...styles.bold, color: "red", marginBottom: "10px" }}
              >
                {model[key]}
              </div>
              </>
            )}
          </React.Fragment>
        );
    });
  };
  renderLatest = () => {
    const { activeTheme, activeService, latestPrice } = this.state;
    if (!activeTheme || !activeService) return;
    return latestPrice?.map((price, index) => {
      return (
        <div key={index} style={{margin:"5px 0px 15px 0px", fontSize: "18px", lineHeight: "25px", fontWeight:"300"}}>
          <div style={{color:"gray"}}>{`${price.serviceName}`} </div>
          <div style={styles.bold}>{`${Number(price.cost).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".")} ${this.context.currencyFormat}`}</div>
        </div>
      );
    });
  };

  functionCapitalise = () => {
    let title = "";
    if (this.context?.cuDetails?.title1) {
      title = this.context?.cuDetails?.title1[0]
        .toUpperCase()
        .concat(
          this.context?.cuDetails?.title1.slice(
            1,
            this.context?.cuDetails?.title1.length
          )
        );
    }
    // console.log('title', title)
    return title;
  };
  functionCapitaliseTitle2 = () => {
    let title = "";
    if (this.context?.cuDetails?.title2) {
      title = this.context?.cuDetails?.title2[0]
        .toUpperCase()
        .concat(
          this.context?.cuDetails?.title2.slice(
            1,
            this.context?.cuDetails?.title2.length
          )
        );
    }
    // console.log('title', title)
    return title;
  };

  render() {
    const { couple, activeService, activeTheme } = this.props;

    return (
      <React.Fragment>
         {((this.props.allowPricingApi===true)
          ||this.props.uploadPriceStatus===true ) && (
              <div style={styles.loader}>
                <div>
                  <Spinner color="success" />
                </div>
              </div>
            )}
        <div className={`right-bar ${this.state.collapsed ? "collapsed" : ""}`}>
        <button

          className={`euro-right-bar-collapse ${
            this.state.collapsed ? "collapsed" : ""
          } `}
          onClick={this.collapseToggle.bind(this)}
          >
          <img src={euroSmall} alt="" style={{
          borderRadius:" 5px",
          background: "#939598",
          padding: "5px",
          height: "32px",
          width: "32px"

          }} />
</button>

    {this.props.history.location.pathname!=="/invoice" &&
        <PullBar {...this.state} guestPriceObj={this.props.guestPriceObj}  updatePriceState={this.props.updatePriceState} />
        }
        <div style={styles.container}>
          <h6 style={styles.title}> {this.props.t('Estimated price')}</h6>
          <h1 style={styles.cost}>{`
          ${this.context.languageFormat==="en" ? Number(this.state.total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") :Number(this.state.total).toFixed(2)
          .replace(".", ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
           ${this.context.currencyFormat}`}</h1>
          <div style={styles.separator} />
          {activeTheme?.id === -1 && (
            <>
              <h5 style={{
                fontFamily: "Crete Round",
                fontSize: "24px",
                fontStyle: "italic",
                marginBottom: "24px"
              }}>
              {this.props.t('Gästepauschale')}:{" "}
                {this.calculateGuestTotal(this.props.guestPriceObj)}
              </h5>
              {this.renderGuestPriceContent()}
            </>
          )}
                 
          <div style={{ flex: "1 1 auto", overflowY: "auto" }}>
            {activeTheme?.id !== -1 && activeService && this.renderLatest()}
          </div>
          <div style={{ flex: "1 1 auto", overflowY: "auto" }}>
            {activeTheme?.id !== -1 && activeService && this.renderContent()}
          </div>
          <div>
            <p style={styles.title}>{this.props.t('Billing_address')}</p>
            <p
              style={{ ...styles.text, ...styles.pointer }}
              onClick={this.toUserDetailsPage}
            >
              {this.props.t('Change')}
            </p>
            <p />
            {couple?.companyName ? (
                        <p style={styles.text}>{couple?.companyName}</p>
                      ) : (
                        <>
                          <p style={styles.bold}>
                            {couple?.firstName1} {couple?.surname1}
                          </p>
                          <p style={styles.bold}>
                            {couple?.firstName2} {couple?.surname2}
                          </p>
                        </>
                      )}
            <p style={styles.text}>{`${couple?.streetNumber || "undefiniert"}`}</p>
            <p style={styles.text}>{`${couple?.postCode || "undefiniert"} ${couple?.state || "undefiniert"}`}</p>
            <br/>
            <p style={styles.text}>{`Tel. ${couple?.telNumber1 || "undefiniert"}`}</p>
            <p style={styles.text}>{couple?.email1 || "undefiniert"}</p>
          </div>
        </div>
        </div>
      </React.Fragment>
    );
  }

  toUserDetailsPage = () => {
    const { history, couple } = this.props;
    if (!history) { return; }
    history.push("/userInfo", {
      user: { ...couple, userId: couple._id },
      disabled: false,
    });
  };
}

export default withTranslation() (PriceBar);
const styles = {
  title: {
    fontFamily: "Crete Round",
    fontSize: "19px",
    color: "#000",
    margin: "0px",
  },
  bold: {
    fontSize: "16px",
    margin: "0px",
    color: "#000",
    fontWeight: "600",
  },
  text: {
    fontSize: "16px",
    fontWeight: "300",
    margin: "0px",
    color: "#000",
  },
  cost: {
    fontSize: "40px",
    fontWeight: "600",
    color: "#000",
  },
  separator: {
    display: "block",
    width: "100%",
    height: "2px",
    background: "#297825",
    marginBottom: "20px",
  },
  container: {
    textAlign: "right",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    padding: "60px 0 10px 20px",
  },
  pointer: {
    cursor: "pointer",
  },
  loader: {
    color: "#888888",
    fontWeight: "bold",
    fontSize: "20px",
    padding: "20px",
    textAlign: "center",
  },
};

import React, { useCallback, useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { baseUrl } from "../../Constants";

import uploadIcon from "../../assets/upload.svg";
const imageMaxSize = 10240;

function UploadPdfDropzone(props) {
  const { t } = useTranslation();
  const [style, setStyle] = useState({ backgroundImage: "" });
  const [showControls, setShowControls] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const onDrop = useCallback((acceptedFiles, rejected) => {
    // console.log("onDrop", acceptedFiles, rejected )
    let isValidSize = true;
    setIsLoading(true);
    acceptedFiles.forEach(file => {
      const currentFileSize = Math.round(file.size / 1024);
      if (currentFileSize > imageMaxSize) {
        isValidSize = false;
      }
      if(isValidSize) {
        //const preview = URL.createObjectURL(file);
        //setStyle({ backgroundImage: `url(${preview})` });
        //setShowControls(false);
        const data = new FormData();
        data.append("blob", file);
        return axios
          .post(baseUrl + `api/blob/${1}`, data, {
            headers: { "Content-Type": "multipart/form-data" }
          })
          .then(res => {
            const extension=(file.name.split(".")[file.name.split(".").length-1])
            var type=""
            console.log('in here',file, extension);
            if(extension==="jpg" || extension==="jpeg" ||extension==="png"){
              type="image"
            }
            else if(extension==="pdf"){
              type="pdf"
            }
            else{
              type="doc"
            }
            setStyle({ backgroundImage: `url(${res.data.url})` });
            setShowControls(false);

            const params = {
              url: res.data.url,
              name: file.name,
              type: type,
              checked:false
            };
            props.acceptUploadToMediaLibrary(params);
            type=""
          })
          .catch(error => {
            console.log(error);
          });
        /*const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          console.log(reader);
        };
        reader.readAsArrayBuffer(file);*/
      } else {
        swal(t('Bilder bis 15 MB sind erlaubt'), "", "info");
        setIsLoading(false);
        return;
      }
    });
  }, [props, t]);

  const { getRootProps, getInputProps } = useDropzone({
    accept:"image/*, application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf",
    // "image/jpg, image/jpeg, image/png, pdf",
    // multiple: false,
    onDrop
  });

  return (
    <div {...getRootProps({ className: "dropzone" })} style={style}>
      <input {...getInputProps()} autoComplete="off" />
      {showControls && (
        <>
          {isLoading ? (
            <div className="loader loader-text">Loading ...</div>
          ) : (
          <>
            <img src={uploadIcon} alt="" />
            <p>
              Drag and drop a file here <br />
              or
            </p>
            <button>Choose file</button>
          </>
          )}
        </>
      )}
    </div>
  );
}

export default UploadPdfDropzone;

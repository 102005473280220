import React, { Component } from "react";
import { StateContext } from "../../context/state";
import axios from "axios";
import { baseUrl } from "../../Constants.js";
import TextOption from "./text";
import DatumOption from "./datum";
import RadioOption2 from "./radio2";
import ImageOption2 from "./image";
import BildOption from "./bild";
import TableOption from "./table";
import UploadOption from "./upload";
import DownloadOption from "./download";
import { withTranslation} from 'react-i18next';
class ImageOption extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      newData: {},
      childData: {},
    };
  }
  componentDidMount() {
    this.setState({
        newData: this.props.data,
      });
    

    const option = this.props.option;
    axios
      .get(
        `${baseUrl}api/export/ServiceElement/${this.context.childQuestionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`
      )
      .then((res) => {
        const d = res.data;
   
    const dd = d?.imageInput?.inputs?.map((input,index) => {
      // input.alternate = "";
      input.alternate = input.alternate? input.alternate:this.props.data?.imageInput?.inputs[index].alternate
      return { ...input };
    });

        if (d?.imageInput?.inputs) {
          d.imageInput.inputs.forEach((item) => {
            if (item.image) {
              const image = {
                url: item.image,
              };
              this.props.updateAttachments(image);
            }
          });
        }
        if(d.imageInput){
          d.imageInput.inputs = dd;
        }
        // this.setState({
        //   childData: { ...d },
        // });
        this.setState({
          data: d,
          childData: { ...this.state.newData, ...d },
        });
      })
      .catch((err) => {
        console.log(err);
      });

  }
  display = (input) => {
    let boolean =
      Object.keys(this.state.childData).length &&
      this.state.childData?.answer?.id === input?.id;
    // console.log("boolean",boolean)
    if (boolean === true) {
      return true;
    } else {
      return false;
    }
  };
  handleChangeQtnAlt = (e) => {};
  renderOption = (option) => {
    if (option?.display === false) {
      return null;
    }
    switch (option?.optionType) {
      case "text":
        return <TextOption option={option} />;
      case "datum":
        return <DatumOption option={option} />;
      case "bild":
        return <BildOption option={option} />;
      case "radio":
        return <RadioOption2 option={option} />;
      case "table":
        return <TableOption option={option} />;
      case "upload":
        return <UploadOption option={option} />;
      case "download":
        return <DownloadOption option={option} />;
      case "image":
        return <ImageOption2 option={option} />;
      default:
        break;
    }
  };
  render() {
    // let {newData: {imageInput: {question, alignment, inputs}, answer, id}} = this.state.newData;
// console.log("childData",this.state.childData);
// console.log("propdata",this.props.data);
//console.log("data2-1",this.props.data);

    // question = question || "Question?";
    let ans;
    const alignment = this.props.data?.imageInput?.alignment || "";
    if(this.state.childData.imageInput){
      this.setState.childData.imageInput.qtnAlternate=this.state.childData?.imageInput?.qtnAlternate ? 
      this.state.childData?.imageInput?.qtnAlternate:
       this.state.newData?.imageInput?.qtnAlternate    
    }

    let show=false;
    if(this.props.data2?.imageInput?.questionSelected || this.props.data2?.imageInput?.checkboxQuestionSelected ){
      show=true
    }
    else if(this.state.data?.imageInput?.questionSelected || this.state.data?.imageInput?.checkboxQuestionSelected){
      show=true
    }
    if (this.state.childData?.answer) {
      ans = this.state.childData?.answer;
    }

   
   
    // const selectedStyle = answer?.id === id ? styles.selected : {};
    // const circleStyle = {...styles.circle, ...selectedStyle};

    
    return (
      <div style={{marginBottom:"10px" }}>
        { ans !== undefined &&  Object.keys(ans).length !== 0 && this.state.childData.isDisplay!=="false" ? <>
        <div  style={{overflow:"auto"}}>
          {this.state.newData.imageInput?.inputs && (
            <>
              <div className="qtn_optns">
              {/* {console.log("this.state.childData?.imageInput",this.state.childData?.imageInput)}
              {console.log("newData",this.state.newData.imageInput)} */}
              {(Object.keys(this.state.childData).length!==0 && this.state.childData!==undefined) && (!show) &&<>
                {!this.state.childData?.imageInput?.qtnAlternate ? (
                  <div
                  //    className="quest1"
                  >
                    <b>
                      <i>{this.state.childData.imageInput?.question}</i>
                    </b>
                  </div>
                ) : (

                  <div
                  //    className="quest1"
                  >
                    <b>
                      <i>{this.state.childData.imageInput?.qtnAlternate}</i>
                    </b>
                  </div>
                  // <input
                  //   type="text"
                  //   className="inp1"
                  //   style={{background:"white"}}
                  //   placeholder={this.props.t("Ersatztext")}
                  //   value={this.state.childData.imageInput.qtnAlternate}
                  //   onChange={(e) => {
                  //     this.handleChangeQtnAlt(e);
                  //   }}
                  //   autoComplete="off"
                  // />
                  
                )}
                </>}
              </div>
              <div className="secondHalfPreview" style={{width:"auto"}}>
                {/* {console.log("newData",this.state.childData)} */}
                {this.state.childData?.imageInput?.inputs.map((input, i) => {
                  return (
                    <div key={i}>
                      {/* {this.display(input)} */}
                      {/* {console.log("boolean",this.display(input))} */}
                   

                      {this.state.childData?.answer!==undefined && 
                      Object.keys(this.state.childData?.answer).length ? (
                        <>
                            {input?.alternate ?
                              <div style={{ display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              top: "-3px",
                              position: "relative",}}>  
                              
                                {input?.id === this.state.childData?.answer?.id &&
                                <>
                                 <span style={styles.filledCircleAlternate} />
                                  <div style={{width:"200px",marginLeft:"10px",marginRight:"10px"}} >
                                    {input?.alternate}
                                  </div>
                               {this.state.childData?.answer?.quantity!=="0" && this.state.childData?.answer?.quantity}
                              {this.state.childData?.answer?.quantity2!=="0" && this.state.childData?.answer?.quantity2}
                                  </>
                                }
                             
                              </div>
                              :
                              <div
                                className={`image-item image-item-${alignment}`}
                              >
                                {input?.id === this.state.childData?.answer?.id ?
                                <>
                                  <img
                                    src={`${input.image}`}
                                    style={{ width: "300px", height: "250px" }}
                                    // style={widthStyle}
                                    alt=""
                                  />
                              {/* <span style={styles.filledCircle} /> */}
                              {this.state.childData?.answer?.quantity!=="0" && this.state.childData?.answer?.quantity}
                              {this.state.childData?.answer?.quantity2!=="0" && this.state.childData?.answer?.quantity2}
                                  </>:
                                   <span style={styles.Circle}/>
                                }

                              </div>
                            }                       
                        </>
                      ) : (
                        <>
                          {input?.alternate ?
                              <div>   
                                              
                                  {/* <div style={{width:"200px"}} >
                                    {input?.alternate}
                                  </div>   */}
                                  <span style={styles.circle} />
                                  <div style={{width:"200px",marginLeft:"22px",marginTop:"-27px"}} >
                                    {input?.alternate}
                                  </div>                        
                              </div>
                              :
                              <>
                          <div className={`image-item image-item-${alignment}`}>
                             <img
                              src={`${input.image}`}
                              style={{width:"300px",height:"250px"}}
                              // style={widthStyle}
                              alt=""
                            />
                          </div>
                             <span style={styles.circle} />
                             </>
                          }
                       
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
        {this.state.newData.imageInput?.link?.display &&
          this.state.childData?.answer?.id ===
            this.state.newData.imageInput.link.linkedOptionId &&
          this.renderOption(this.state.childData.imageInput.link)}
        {/* {this.state.childData?.imageInput?.link[0].display &&
          (this.state.childData?.imageInput?.link?.linkedOptionId==
            this.state.newData?.imageInput?.link?.linkedOptionId) &&
          this.renderOption(this.state.childData?.imageInput?.link[0])} */}
     </>:<></>}
      </div>
    );
  }
}

export default  withTranslation() (ImageOption);

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    marginBottom:"10px" 
  },
  labelContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px",
  },
  typeContainer: {
    marginLeft: "54px",
  },
  circle: {
    marginTop: "7px",
    width: "16px",
    height: "16px",
    display: "inline-block",
    textAlign: "center",
    background: "transparent",
    borderRadius: "50%",
    border: "1px solid #939598",
    outline: "none",
  },
  filledCircle: {
    marginTop: "7px",
    width: "16px",
    height: "16px",
    display: "inline-block",
    textAlign: "center",
    background: "transparent",
    borderRadius: "50%",
    backgroundColor: "#939598",
    border: "1px solid #939598",
    outline: "none",
  },
  filledCircleAlternate: {
    width: "16px",
    height: "16px",
    display: "inline-block",
    textAlign: "center",
    background: "transparent",
    borderRadius: "50%",
    backgroundColor: "#939598",
    border: "1px solid #939598",
    outline: "none",
  },
  selected: {
    background: "#297825",
  },
  label: {
    margin: "0 0 0 20px",
    padding: "0",
    display: "inline-block",
    color: "#939598",
    fontSize: "16px",
  },
  input: {
    border: 0,
    padding: 0,
    margin: 0,
    background: "transparent",
    borderBottom: "1px solid #707070",
    width: "200px",
  },
};

import React, {Component} from "react";
import DownloadComponent from "./DownloadComponent";
import UploadComponent from "./UploadComponent";
import Text from "../Text";
import {StateContext} from "../../../context/state";
export default class UploadDownload extends Component {
    static contextType = StateContext;
    render() {
        const textFontSize=this.context.stylingData?.textFontSize
        const textFontFamily=this.context.stylingData?.textFontFamily
        // const headingColor =this.context.stylingData?.["headingColor"] ? this.context.stylingData?.headingColor : "#939598";
        const headingColor = this.context.stylingData?.textColor ? this.context.stylingData?.textColor :"#939598";

        let {option: {uploadInput, files}} = this.props;
        uploadInput = uploadInput ?? {};

        let {question, inputs, type, maximum, minimum} = uploadInput;
        question = question ?? "";
        type = type ?? "";

        return type === "download"
            ? <div>
               
                <DownloadComponent inputs={inputs} />
                <Text value={question} 
                fontSize={textFontSize}
                fontFamily={textFontFamily}
                style={{padding: "0 !important",fontSize:`${textFontSize}px`,fontFamily:`${textFontFamily}`, color:`${headingColor}`}}  />
            </div>
            : <div>
                <UploadComponent max={maximum} min={minimum} files={files} onSelect={this.props.onSelect} onDelete={this.props.onDelete}/>
                <Text
                fontSize={textFontSize}
                fontFamily={textFontFamily}
                value={question} style={{fontSize:`${textFontSize}px`,color:`${headingColor}`, fontFamily:`${textFontFamily}`, marginTop: "10px",
        borderTop: "2px solid #bcbec0",
        padding: "0 !important"}}  />
            </div>;
    }
}


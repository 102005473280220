import React, { Component } from "react";
import OptionsComponent from "../options/OptionsComponent";
import TextOptionComponent from "../options/TextOptionComponent";
import TextOptionConfiguration from "../configurations/TextOptionConfiguration";
import DatumOptionComponent from "../options/DatumOptionComponent";
import RadioOptionComponent from "../options/RadioOptionComponent";
import RadioOptionConfiguration from "../configurations/RadioOptionConfiguration";
import ImageOptionComponent from "../options/ImageOptionComponent";
import ImageOptionConfiguration from "../options/ImageOptionConfiguration";
import BildOptionComponent from "../options/BildOptionComponent";
import TableOptionComponent from "../options/TableOptionComponent";
import UploadOptionComponent from "../options/UploadOptionComponent";
import RadioLinkOption from "../configurations/RadioLink.js";
import ImageCaption from "../configurations/ImageCaption.js";
import { withTranslation} from 'react-i18next';
// import linkIcon from "../../assets/link.svg";
import swal from "sweetalert";
import { v4 as uuidv4 } from "uuid";
import plusIcon from "../../assets/plus.svg";
import plusLightIcon from "../../assets/plus-light.svg";
import crossSmallIcon from "../../assets/cross-small.svg";
import minusIcon from "../../assets/minus.svg";
import linkIcon from "../../assets/link.svg";
import { StateContext } from "../../context/state";
import styled from "styled-components"
import { EditorState } from "draft-js";
const HeadingDiv = styled.div`
	position: sticky;
	top: 2px;
	z-index: 100;
	height: 61px;
	margin-bottom: 25px;
`;
const  MainContainer=styled.div`

`;
const HeadingStyle=styled.h3` 
position:relative;
top:15px;
`;
class ConfiguratorTemplate12 extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.messagesRef=React.createRef(null);
    this.state = {
      scroll:false,
      layoutObject: [
        {
          row: [
            {
              column: [
                {
                  option: {},
                  span: 1,
                  selected: false,
                  changeBackgroundColor:false,
                },
              ],
              quantity: 1,
              span: 1,
              default: true,
            },
          ],
          default: true,
          quantity: 1,
        },
      ],

      serviceLink: {
        type: "service",
        serviceInput: {
          link: [
            {optionId:"",
              columnIndex: "",
              linkedOptionId: "",
              linkedOptionValue: "",
              optionType: "",
              questionIndex: "",
              rowIndex: "",
              serviceId: "",
              themeId: "",
              display: "",
            },
          ],
        },
      },
      optionType: null,
      userNotify:false,
      optionId:"",
      selectedOption: null,
      selectedTextIndex: null,
      selectedTextIndex2: 0,
      selectedRadioIndex: null,
      componentSelectorVisible: false,
      textOptionConfigurationVisible: false,
      radioOptionConfigurationVisible: false,
      radioLinkVisible: false,
      imageCaptionVisible:false,
      imageOptionAlignment: "horizontal",
      tableOptionTypeVisible: false,
      textInput: [],
      dateInfo: {
        hasDatum: true,
        hasTime: true,
        question: "",
      },
      uploadInfo: {
        uploadSelect: "upload",
        uploadMinimum: "",
        uploadMaximum: "",
      },
      tableInfo: {
        rows: 5,
        columns: 6,
        type: "text",
        isTableInteractive: true,
        tableOptionTypeVisible: false,
      },
      radioInfo: {
        radioChoice: "radio",
        imageAlignment: "",
        multipleSelection:false,
        min: "",
        max: "", },
      canBeEdited: false,
      bildCaptionData:{},
      selectedBildImgIndex:0,
      dateQuestion:"",
      optionContainerClicked:false,
      indexedOnSelect:[],
      showLink:"",
      toggleElementIndexes:[],
      selectedIndex:[],
      showPopup:false
    };
  }

  componentDidMount() {
    // console.log("this.props.serviceLink", this.props);
    this.setState({
      userNotify:true,
      layoutObject: this.props.layoutObject,
      serviceLink: this.props?.serviceLink
        ? this.props.serviceLink
        : {
          type: "service",
          serviceInput: {
            link: [
              {
                optionId:"",
                columnIndex: "",
                linkedOptionId: "",
                linkedOptionValue: "",
                optionType: "",
                questionIndex: "",
                rowIndex: "",
                serviceId: "",
                themeId: "",
                display: "",
              },
            ],
          },
        },
    });
    this.props.updateLayoutObject(this.props.layoutObject);

    window.addEventListener("scroll", () => {
      this.setState({scroll:window.scrollY > 0});
    });
    if (this.messagesRef.current) {
      this.messagesRef.current.scrollIntoView({
          behavior: 'smooth',
         block: 'end',
         inline: 'nearest'
       });
  }
}
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.layoutObject !== this.state.layoutObject) {
  //     console.log('layout state has changed.')
  //   }
  // }
 



  toggleComponentSelector = (index, _index, __index) => {
    const layoutObject = this.state.layoutObject;
    layoutObject.forEach((item, ___index) => {
      item.row.forEach((_item, ____index) => {
        _item.column.forEach((__item, _____index) => {
          if (
            ___index === index &&
            ____index === _index &&
            _____index === __index
          ) {
            __item.selected = !__item.selected;
            // this.setState({ optionType: null });
          } else __item.selected = false;
        });
      });
    });
    if (this.messagesRef.current) {
      this.messagesRef.current.scrollIntoView({
          behavior: 'smooth',
         block: 'end',
         inline: 'nearest'
       });
  }

    let componentSelectorVisible = false;
    layoutObject[index].row[_index].column[__index].selected === true &&
      (componentSelectorVisible = true);
    // console.log("layour",layoutObject[index].row[_index])
    this.setState({
      layoutObject,
      componentSelectorVisible,
      radioLinkVisible: false,
      imageCaptionVisible:false,
      textOptionConfigurationVisible: false,
      radioOptionConfigurationVisible: false,
      imageOptionConfigurationVisible: false,
      selectedTextIndex: null,
      selectedRadioIndex: null,
      selectedImageIndex: null,
      selectedTextIndex2: 0,
      optionType:null,
      indexedOnSelect:[index, _index, __index],
      selectedOption: null,
    });
    this.props.updateLayoutObject(layoutObject);
  };

  clearSelection()
  {
    const layoutObject = this.state.layoutObject;
    layoutObject.forEach((layout_item) => {
      layout_item.row.forEach((row_item) => {
      row_item.column.forEach((col_item) => {
          col_item.selected = false;
        });
      });
    });
    this.setState({layoutObject});
  }

  // onClickButtonToggleComponentSelector = (index, _index, __index) => {
  //   const layoutObject = this.state.layoutObject;
  //   layoutObject.forEach((layout_item, layout_index) => {
  //     layout_item.row.forEach((row_item, row_index) => {
  //     row_item.column.forEach((col_item, col_index) => {
  //         if (layout_index === index &&
  //           row_index === _index &&
  //           col_index === __index
  //         ) {
  //           col_item.selected = true;
  //         } else col_item.selected = false;
  //       });
  //     });
  //   });

  //   let componentSelectorVisible = false;

  //   layoutObject[index].row[_index].column[__index].selected === true &&
  //     (componentSelectorVisible = true);
  //    console.log("layour",layoutObject[index].row[_index])
  //   this.setState({
  //     layoutObject,
  //     componentSelectorVisible,
  //     radioLinkVisible: false,
  //     imageCaptionVisible:false,
  //     textOptionConfigurationVisible: false,
  //     radioOptionConfigurationVisible: false,
  //     imageOptionConfigurationVisible: false,
  //     selectedTextIndex: null,
  //     selectedRadioIndex: null,
  //     selectedImageIndex: null,
  //     selectedTextIndex2: 0,
  //     optionType:null,
  //     indexedOnSelect:[index, _index, __index],
  //     selectedOption: null,
  //   });
  //   this.props.updateLayoutObject(layoutObject);
  // };

  toggleTextOptionConfiguration = (index, textInput, selectedIndex) => {
    // console.log('tggleText', index)
    let selectedOption;
    const layoutObject = this.state.layoutObject;
    layoutObject.forEach((item, index) => {
      item.row.forEach((_item, _index) => {
        _item.column.forEach((__item, __index) => {
          index === selectedIndex[0] &&
            _index === selectedIndex[1] &&
            __index === selectedIndex[2]
            ? (__item.selected = true)
            : (__item.selected = false);
        });
      });
    });
    selectedOption =
      layoutObject[selectedIndex[0]].row[selectedIndex[1]].column[
        selectedIndex[2]
      ].option;
    selectedOption.textInput = textInput;
    this.setState({
      layoutObject,
      selectedTextIndex: index,
      selectedOption,
      componentSelectorVisible: false,
      radioLinkVisible: false,
      imageCaptionVisible:false,
      textOptionConfigurationVisible: true,
      radioOptionConfigurationVisible: false,
      imageOptionConfigurationVisible: false,
    });
    // console.log('selected', selectedOption)

    this.props.updateLayoutObject(layoutObject);
  };

  toggleRadioOptionConfiguration = (index, radioInput, selectedIndex) => {
    let selectedOption;
    const layoutObject = this.state.layoutObject;
    layoutObject.forEach((item, index) => {
      item.row.forEach((_item, _index) => {
        _item.column.forEach((__item, __index) => {
          index === selectedIndex[0] &&
            _index === selectedIndex[1] &&
            __index === selectedIndex[2]
            ? (__item.selected = true)
            : (__item.selected = false);
        });
      });
    });
    selectedOption =
      layoutObject[selectedIndex[0]].row[selectedIndex[1]].column[
        selectedIndex[2]
      ].option;
    selectedOption.radioInput = radioInput;
    this.setState({
      layoutObject,
      selectedRadioIndex: index,
      selectedOption,
      componentSelectorVisible: false,
      radioLinkVisible: false,
      imageCaptionVisible:false,
      textOptionConfigurationVisible: false,
      radioOptionConfigurationVisible: true,
      imageOptionConfigurationVisible: false,
    });
    this.props.updateLayoutObject(layoutObject);
  };

  toggleRadioOptionType = (radioMultiSelect) => {
    const layoutObject = this.state.layoutObject;
    layoutObject.forEach((item) => {
      item.row.forEach((_item) => {
        _item.column.forEach((__item) => {
          if (__item.selected) {
            if (__item.option.hasOwnProperty("radioInput")) {
              __item.option.radioInput.multipleSelection = radioMultiSelect;
            }

          }
        });
      });
    });
    const { radioInfo } = this.state;
    radioInfo.multipleSelection = radioMultiSelect;
    // console.log("ra", radioInfo);
    this.setState({ layoutObject, radioInfo });
    this.props.updateLayoutObject(layoutObject);
  };


  updateImageOptionInput = (imageInput) => {
    const layoutObject = this.state.layoutObject;
    layoutObject.forEach((item) => {
      item.row.forEach((_item) => {
        _item.column.forEach((__item) => {
          __item.selected && (__item.option.imageInput = imageInput);
        });
      });
    });
    this.setState({ layoutObject });
    this.props.updateLayoutObject(layoutObject);
  };

  setMinMaxValue = (value) => {
    const layoutObject = this.state.layoutObject;
    layoutObject.forEach((item) => {
      item.row.forEach((_item) => {
        _item.column.forEach((__item) => {
          if (__item.selected) {
            if (!__item.option.hasOwnProperty("radioInput")) {
              __item.option.radioInput = {};
              __item.option.radioInput.question = "";
              __item.option.radioInput.inputs = [];
              __item.option.radioInput.multipleSelection = false;
            }
            if (!__item.option.hasOwnProperty("imageInput")) {
              __item.option.imageInput = {};
              __item.option.imageInput.question = "";
              __item.option.imageInput.inputs = [];
              __item.option.imageInput.multipleSelection = false;
            }
            __item.option.radioInput.min = value.min;
            __item.option.radioInput.max = value.max;
            __item.option.imageInput.min = value.min;
            __item.option.imageInput.max = value.max;
          }
        });
      });
    });

    const { radioInfo } = this.state;
    radioInfo.min = value.min;
    radioInfo.max = value.max;

    // console.log("ra", radioInfo);
    this.setState({ layoutObject, radioInfo }, () => {
      // console.log("radioInfo", radioInfo);
    });
    this.props.updateLayoutObject(layoutObject);
  };
  toggleImageOptionAlignment = (imageOptionAlignment) => {
    const layoutObject = this.state.layoutObject;
    layoutObject.forEach((item) => {
      item.row.forEach((_item) => {
        _item.column.forEach((__item) => {
          if (__item.selected) {
            if (!__item.option.hasOwnProperty("imageInput")) {
              __item.option.imageInput = {};
              __item.option.imageInput.question = "";
              __item.option.imageInput.inputs = [];
              __item.option.imageInput.multipleSelection=false
            }
            __item.option.imageInput.alignment = imageOptionAlignment;
          }
        });
      });
    });
    const { radioInfo } = this.state;
    radioInfo.imageAlignment = imageOptionAlignment;
    // console.log("ra", radioInfo);
    this.setState({ layoutObject, radioInfo });
    this.props.updateLayoutObject(layoutObject);
  };
  toggleImageOptionType = (multiSelect) => {
    const layoutObject = this.state.layoutObject;
    layoutObject.forEach((item) => {
      item.row.forEach((_item) => {
        _item.column.forEach((__item) => {
          if (__item.selected) {
            if (!__item.option.hasOwnProperty("imageInput")) {
              __item.option.imageInput = {};
              __item.option.imageInput.question = "";
              __item.option.imageInput.inputs = [];
              __item.option.imageInput.alignment = "horizontal";
            }
            __item.option.imageInput.multipleSelection = multiSelect;
          }
        });
      });
    });
    const { radioInfo } = this.state;
    radioInfo.multipleSelection = multiSelect;
    // console.log("ra", radioInfo);
    this.setState({ layoutObject, radioInfo });
    this.props.updateLayoutObject(layoutObject);
  };
  toggleImageOptionConfiguration = (index, imageInput, selectedIndex) => {
    // console.log('hi', this.state.selectedOption)
    let selectedOption;
    const layoutObject = this.state.layoutObject;
    layoutObject.forEach((item, index) => {
      item.row.forEach((_item, _index) => {
        _item.column.forEach((__item, __index) => {
          index === selectedIndex[0] &&
            _index === selectedIndex[1] &&
            __index === selectedIndex[2]
            ? (__item.selected = true)
            : (__item.selected = false);
        });
      });
    });
    selectedOption =
      layoutObject[selectedIndex[0]].row[selectedIndex[1]].column[
        selectedIndex[2]
      ].option;
    selectedOption.imageInput = imageInput;
    this.setState({
      layoutObject,
      selectedImageIndex: index,
      selectedOption,
      componentSelectorVisible: false,
      radioLinkVisible: false,
      imageCaptionVisible:false,
      textOptionConfigurationVisible: false,
      radioOptionConfigurationVisible: false,
      imageOptionConfigurationVisible: true,
    });
    this.props.updateLayoutObject(layoutObject);
  };

  updateImageOptionConfiguration = (imageInput) => {
    const selectedOption = this.state.selectedOption;
    selectedOption.imageInput = imageInput;
    this.setState({ selectedOption });
  };

  updateTextOptionConfiguration = (textInput) => {
    const selectedOption = this.state.selectedOption;
    selectedOption.textInput = textInput;
    this.setState({ selectedOption });
  };

  updateSeletedTextIndex = (selectedTextIndex2) => {
    this.setState({ selectedTextIndex2 });
  };

  updateSelectedOptionForText = (selectedOption) => {
    // console.log('hi', selectedOption)
    // this.setState({ selectedOption });
    const layoutObject = this.state.layoutObject;
    layoutObject.forEach((item) => {
      item.row.forEach((_item) => {
        _item.column.forEach((__item) => {
          if (__item.selected) {
            !__item.option.hasOwnProperty("textInput") &&
              (__item.option.textInput = {
                inputs: [],
              });
            __item.option.textInput = selectedOption.textInput;
          }
        });
      });
    });
    this.setState({ layoutObject, selectedOption });
    this.props.updateLayoutObject(layoutObject);
  };

  updateTextOptionParams = (selectedOption) => {
    // console.log('update', selectedOption)

    const layoutObject = this.state.layoutObject;
    layoutObject.forEach((item) => {
      item.row.forEach((_item) => {
        _item.column.forEach((__item) => {
          if (__item.selected) {
            !__item.option.hasOwnProperty("textInput") &&
              (__item.option.textInput = {
                inputs: [],
              });
            __item.option.textInput = selectedOption.textInput;
          }
        });
      });
    });
    this.setState({ layoutObject, selectedOption });
    this.props.updateLayoutObject(layoutObject);
  };

  updateDatumOptionConfiguration = (datumOptionConfiguration) => {
    // console.log('ii',datumOptionConfiguration)
    const layoutObject = this.state.layoutObject;
    layoutObject.forEach((item) => {
      item.row.forEach((_item) => {
        _item.column.forEach((__item) => {
          __item.selected &&
            (__item.option.datumInput = datumOptionConfiguration);
        });
      });
    });
    this.setState({ layoutObject, dateInfo: datumOptionConfiguration });
    this.props.updateLayoutObject(layoutObject);
  };

  updateRadioOptionConfiguration = (radioInput) => {
    const selectedOption = this.state.selectedOption;
    selectedOption.radioInput = radioInput;
    this.setState({ selectedOption });
  };
  updateRadioLinkConfiguration = (generalInput, element,linkedOptionArray,serviceLinkOptionArray) => {
    // console.log("update", generalInput, this.state.selectedOption);
    // console.log('element', element)
    if (
      element &&
      element.themeId === this.context.themeId &&
      element.serviceId === this.context.serviceId
    ) {
      const { layoutObject } = this.state;
      if (
        layoutObject[element?.questionIndex]?.row[element?.rowIndex]?.column[
          element?.columnIndex
        ].option.type === "radio"
      ) {
        layoutObject[element.questionIndex].row[element.rowIndex].column[
          element.columnIndex
        ].option.radioInput.shouldRefresh = true;
        if(linkedOptionArray)
          layoutObject[element.questionIndex].row[element.rowIndex].column[
            element.columnIndex
          ].option.linkedOptionArray=linkedOptionArray
      }
      if(serviceLinkOptionArray){
        layoutObject[element.questionIndex].row[element.rowIndex].column[
          element.columnIndex
        ].option.serviceLinkOptionArray=serviceLinkOptionArray


      }
      // console.log('obj', layoutObject )
    }

    const selectedOption = this.state.selectedOption;
    if (selectedOption.type === "image") {
      selectedOption.imageInput = generalInput;
      this.setState({ selectedOption });
    } else if (selectedOption.type === "datum") {
      selectedOption.datumInput = generalInput;
      this.setState({ selectedOption });
    } else if (selectedOption.type === "bild") {
      selectedOption.bildInput = generalInput;
      this.setState({ selectedOption });
    } else if (selectedOption.type === "radio") {
      selectedOption.radioInput = generalInput;
      this.setState({ selectedOption });
    } else if (selectedOption.type === "table") {
      selectedOption.tableInput = generalInput;
      this.setState({ selectedOption });
    } else if (selectedOption.type === "upload") {
      selectedOption.uploadInput = generalInput;
      this.setState({ selectedOption });
    } else if (selectedOption.type === "text") {
      selectedOption.textInput = generalInput;
      this.setState({ selectedOption });
    } else if (selectedOption.type === "service") {
      selectedOption.serviceInput = generalInput;
      this.setState({ selectedOption, serviceLink: selectedOption });

      // this.props.updateLink(selectedOption)
      this.props.updateLayoutObject(this.state.layoutObject, selectedOption);
    } else {
      return;
    }
  };
  optionContainerClicked=(val)=>{
    // console.log("value",val)
    this.setState({optionContainerClicked:val})
  }
  setBildImage = (url) => {
    const layoutObject = this.state.layoutObject;
    layoutObject.forEach((item) => {
      item.row.forEach((_item) => {
        _item.column.forEach((__item) => {
          if (__item.selected) {
            !__item.option.hasOwnProperty("bildInput") &&
              (__item.option.bildInput = {});
            __item.option.bildInput.image = url;
          }
        });
      });
    });
    this.setState({ layoutObject });
    this.props.updateLayoutObject(layoutObject);
  };
  setPopup=()=>{
     if(this.state.showPopup){
       this.setState({showPopup:false},()=>{
        console.log(this.state.showPopup)
       })
     }
     else
     this.setState({showPopup:true},()=>{
      console.log(this.state.showPopup)
     })
     
  }

  toggleUploadDownload = (type) => {
    const layoutObject = this.state.layoutObject;
    layoutObject.forEach((item) => {
      item.row.forEach((_item) => {
        _item.column.forEach((__item) => {
          if (__item.selected) {
            !__item.option.hasOwnProperty("uploadInput") &&
              (__item.option.uploadInput = {});
            !__item.option.uploadInput.hasOwnProperty("question") &&
              (__item.option.uploadInput.question = "");
            !__item.option.uploadInput.hasOwnProperty("inputs") &&
              (__item.option.uploadInput.inputs = []);
            __item.option.uploadInput.type = type;
          }
        });
      });
    });
    //  if(type==="upload"){
    //   const uploadInfo= Object.assign({}, this.state.uploadInfo)
    //   console.log('uploadInfo')
    //   this.setState({ layoutObject});
    //  }
    //  else if(type==="download"){
    //   console.log('downloadInfo')

    //   this.setState({ layoutObject});
    //  }
    const up = { ...this.state.uploadInfo, uploadSelect: type };
    // console.log('up', up)
    this.setState({ layoutObject, uploadInfo: up });
    this.props.updateLayoutObject(layoutObject);
  };

  updateUploadInput = (uploadInput) => {
    const layoutObject = this.state.layoutObject;
    let selectedOption;
    layoutObject.forEach((item) => {
      item.row.forEach((_item) => {
        _item.column.forEach((__item) => {
          if (__item.selected) {
            __item.option.uploadInput = uploadInput;
            selectedOption = __item.option;
          }
        });
      });
    });
    this.setState({ layoutObject, selectedOption });
    this.props.updateLayoutObject(layoutObject);
  };

  updateDate = (question) => {
    // console.log("dateInput",question)
    const layoutObject = this.state.layoutObject;
    let selectedOption;
    layoutObject.forEach((item) => {
      item.row.forEach((_item) => {
        _item.column.forEach((__item) => {
          if (__item.selected) {
            __item.option.datumInput.question = question;
            selectedOption = __item.option;
          }
        });
      });
    });

    // console.log("dateInfo",this.state.dateInfo)
    // console.log("dateinput",dateInput)
    // this.updateDatumOptionConfiguration()
    this.setState({ layoutObject, selectedOption });
    this.props.updateLayoutObject(layoutObject);
  };
  updateUploadInputMaxMin = (params) => {
    // console.log('in updateUpload', params)
    const layoutObject = this.state.layoutObject;
    let selectedOption;
    layoutObject.forEach((item) => {
      item.row.forEach((_item) => {
        _item.column.forEach((__item) => {
          if (__item.selected) {
            !__item.option.hasOwnProperty("uploadInput") &&
              (__item.option.uploadInput = { question: "", inputs: [] });
            __item.option.uploadInput.maximum = params.maximum;
            __item.option.uploadInput.minimum = params.minimum;
            selectedOption = __item.option;
          }
        });
      });
    });
    const data = {
      uploadSelect: "upload",
      uploadMaximum: params.maximum,
      uploadMinimum: params.minimum,
    };
    // console.log('update', data)
    this.setState({ layoutObject, selectedOption, uploadInfo: data });
    this.props.updateLayoutObject(layoutObject);
    // console.log(layoutObject);
  };

  updateTableInput = (tableInput, data) => {
    // console.log('update', tableInput)
    const layoutObject = this.state.layoutObject;
    let selectedOption;
    layoutObject.forEach((item) => {
      item.row.forEach((_item) => {
        _item.column.forEach((__item) => {
          if (__item.selected) {
            __item.option.tableInput = tableInput;
            selectedOption = __item.option;
          }
        });
      });
    });
    // console.log('tableInput', tableInput)
    if (data) {
      if (data.hasOwnProperty("isTableInteractive")) {
        var t = {
          ...this.state.tableInfo,
          rows: tableInput.rows.length,
          columns: tableInput.rows[0].columns.length,
          isTableInteractive: data.isTableInteractive,
        };
      } else if (data.hasOwnProperty("type")) {
        t = {
          ...this.state.tableInfo,
          rows: tableInput.rows.length,
          columns: tableInput.rows[0].columns.length,
          type: data.type,
        };
      }
    } else {
      t = {
        ...this.state.tableInfo,
        rows: tableInput.rows.length,
        columns: tableInput.rows[0].columns.length,
      };
    }

    this.setState({ layoutObject, selectedOption, tableInfo: t });
    this.props.updateLayoutObject(layoutObject);
  };

  setOptionSelected = (
    selectedIndex,
    type,
    linkClicked,
    selectedOption,
    info,
    ind,
    multiSelect,
    d
  ) => {
    const layoutObject = this.state.layoutObject;
    // console.log('index', selectedIndex)
    // console.log("set optnselected", type, selectedOption);
    layoutObject.forEach((item, index) => {
      item.row.forEach((_item, _index) => {
        _item.column.forEach((__item, __index) => {
          // console.log('itm', __item)
          if (
            selectedIndex[0] === index &&
            selectedIndex[1] === _index &&
            selectedIndex[2] === __index
          ) {
            __item.selected = true;
            if (type === "text") {
              !__item.option.hasOwnProperty("textInput") &&
                (__item.option.textInput = {
                  inputs: [],
                });
              __item.option.textInput = selectedOption.textInput;
            }
          } else {
            __item.selected = false;
          }
        });
      });
    });
    if (type) {
      switch (type) {
        case "text":
          return this.setState({
            layoutObject,
            optionType: type,
            selectedOption: selectedOption ? selectedOption : null,
            componentSelectorVisible: true,
            radioLinkVisible: false,
            imageCaptionVisible:false,
            textOptionConfigurationVisible: false,
            radioOptionConfigurationVisible: false,
            imageOptionConfigurationVisible: false,
          });
        case "datum":
          return this.setState({
            layoutObject,
            optionType: type,
            selectedOption: selectedOption ? selectedOption : null,
            componentSelectorVisible: true,
            radioLinkVisible: false,
            imageCaptionVisible:false,
            textOptionConfigurationVisible: false,
            radioOptionConfigurationVisible: false,
            imageOptionConfigurationVisible: false,
            dateInfo: info,
          });
        case "radio":
          if (linkClicked) {
            return this.setState({
              layoutObject,
              optionType: type,
              selectedOption: selectedOption ? selectedOption : null,
              componentSelectorVisible: false,
              radioLinkVisible: true,
              imageCaptionVisible:false,
              textOptionConfigurationVisible: false,
              radioOptionConfigurationVisible: false,
              imageOptionConfigurationVisible: false,
            });
          } else {
            let dummyradioInfo = Object.assign({}, this.state.radioInfo);
            dummyradioInfo.radioChoice = type;
            dummyradioInfo.multipleSelection = multiSelect;
            dummyradioInfo.min = d.min;
            dummyradioInfo.max = d.max;
          
            return this.setState({
              layoutObject,
              optionType: type,
              radioInfo: dummyradioInfo,
              selectedOption: selectedOption ? selectedOption : null,
              componentSelectorVisible: true,
              radioLinkVisible: false,
              imageCaptionVisible:false,
              textOptionConfigurationVisible: false,
              radioOptionConfigurationVisible: false,
              imageOptionConfigurationVisible: false,
            });
          }

        case "image":
          if (selectedOption) {
            let dummyradioInfo = Object.assign({}, this.state.radioInfo);
            dummyradioInfo.radioChoice = type;
            dummyradioInfo.imageAlignment = selectedOption.alignment;
            dummyradioInfo.multipleSelection = selectedOption.multipleSelection;
            console.log("clicked", dummyradioInfo);
            dummyradioInfo.min = d.min;
            dummyradioInfo.max = d.max;
            return this.setState({
              layoutObject,
              optionType: type,
              radioInfo: dummyradioInfo,
              selectedOption: selectedOption ? selectedOption : null,
              componentSelectorVisible: true,
              radioLinkVisible: false,
              imageCaptionVisible:false,
              textOptionConfigurationVisible: false,
              radioOptionConfigurationVisible: false,
              imageOptionConfigurationVisible: false,
            });
          } else {
            return this.setState({
              layoutObject,
              optionType: type,
              selectedOption: selectedOption ? selectedOption : null,
              componentSelectorVisible: true,
              radioLinkVisible: false,
              imageCaptionVisible:false,
              textOptionConfigurationVisible: false,
              radioOptionConfigurationVisible: false,
              imageOptionConfigurationVisible: false,
            });
          }
        case "bild":
          return this.setState({
            layoutObject,
            optionType: type,
            selectedOption: selectedOption ? selectedOption : null,
            componentSelectorVisible: true,
            radioLinkVisible: false,
            imageCaptionVisible:false,
            textOptionConfigurationVisible: false,
            radioOptionConfigurationVisible: false,
            imageOptionConfigurationVisible: false,
          });
        case "table":
          // console.log('table', selectedOption )
          let x;
          let t = {};
          if (x) {
            // console.log('record', record)
            // console.log('record', x)
            t = {
              ...this.state.tableInfo,
              rows: selectedOption.tableInput.rows.length,
              columns: selectedOption.tableInput.rows[0].columns.length,
              type: x.type,
              isTableInteractive: x.interactive,
            };
          } else {
            // console.log('record', x)
            t = {
              ...this.state.tableInfo,
              rows: selectedOption.tableInput.rows.length,
              columns: selectedOption.tableInput.rows[0].columns.length,
            };
            //  console.log('t', t)
          }
          return this.setState({
            layoutObject,
            optionType: type,
            selectedOption: selectedOption ? selectedOption : null,
            componentSelectorVisible: true,
            radioLinkVisible: false,
            imageCaptionVisible:false,
            textOptionConfigurationVisible: false,
            radioOptionConfigurationVisible: false,
            imageOptionConfigurationVisible: false,
            tableInfo: t,
          });
        case "upload":
          if (info) {
            return this.setState({
              layoutObject,
              optionType: type,
              uploadInfo: info,
              selectedOption: selectedOption ? selectedOption : null,
              componentSelectorVisible: true,
              radioLinkVisible: false,
              imageCaptionVisible:false,
              textOptionConfigurationVisible: false,
              radioOptionConfigurationVisible: false,
              imageOptionConfigurationVisible: false,
            });
          } else {
            return this.setState({
              layoutObject,
              optionType: type,
              selectedOption: selectedOption ? selectedOption : null,
              componentSelectorVisible: true,
              radioLinkVisible: false,
              imageCaptionVisible:false,
              textOptionConfigurationVisible: false,
              radioOptionConfigurationVisible: false,
              imageOptionConfigurationVisible: false,
            });
          }
        case "download":
          if (info) {
            // console.log('here', info)
            return this.setState({
              layoutObject,
              optionType: "upload",
              uploadInfo: info,
              selectedOption: selectedOption ? selectedOption : null,
              componentSelectorVisible: true,
              radioLinkVisible: false,
              imageCaptionVisible:false,
              textOptionConfigurationVisible: false,
              radioOptionConfigurationVisible: false,
              imageOptionConfigurationVisible: false,
            });
          } else {
            return this.setState({
              layoutObject,
              optionType: type,
              selectedOption: selectedOption ? selectedOption : null,
              componentSelectorVisible: true,
              radioLinkVisible: false,
              imageCaptionVisible:false,
              textOptionConfigurationVisible: false,
              radioOptionConfigurationVisible: false,
              imageOptionConfigurationVisible: false,
            });
          }
        default:
          return this.setState({
            layoutObject,
            selectedOption: selectedOption ? selectedOption : null,
            componentSelectorVisible: true,
            radioLinkVisible: false,
            imageCaptionVisible:false,
            textOptionConfigurationVisible: false,
            radioOptionConfigurationVisible: false,
            imageOptionConfigurationVisible: false,
          });
      }
    }
    //   this.setState({ layoutObject,
    //     optionType:type,
    //     selectedOption:selectedOption? selectedOption :null,
    //     componentSelectorVisible:true,
    //     radioLinkVisible:false,
    //   textOptionConfigurationVisible: false,
    //     radioOptionConfigurationVisible: false,
    //     imageOptionConfigurationVisible: false
    //   });
    // }
    // else if(type && linkClicked==="optionsClicked"){
    //   this.setState({ layoutObject, optionType:type,
    //     selectedOption:null,
    //     componentSelectorVisible:false,
    //     textOptionConfigurationVisible: true,
    //     radioOptionConfigurationVisible: false,
    //     imageOptionConfigurationVisible: false,
    //     radioLinkVisible:false,
    //   });
    // }
    // else if(type && linkClicked==="clicked"){
    //   this.setState({ layoutObject,
    //     optionType:type,
    //     selectedOption:selectedOption? selectedOption :null,
    //     radioLinkVisible:true,
    //     componentSelectorVisible:false,
    //     textOptionConfigurationVisible: false,
    //     radioOptionConfigurationVisible: false,
    //     imageOptionConfigurationVisible: false
    //   });
    // }
    else {
      this.setState({
        layoutObject,
        selectedOption: selectedOption ? selectedOption : null,
        componentSelectorVisible: true,
        radioLinkVisible: false,
        imageCaptionVisible:false,
        textOptionConfigurationVisible: false,
        radioOptionConfigurationVisible: false,
        imageOptionConfigurationVisible: false,
      });
    }
    this.props.updateLayoutObject(layoutObject);

    // ---
    // const layoutObject = this.state.layoutObject;
    // layoutObject.forEach((item, index) => {
    //   item.row.forEach((_item, _index) => {
    //     _item.column.forEach((__item, __index) => {
    //       selectedIndex[0] === index &&
    //       selectedIndex[1] === _index &&
    //       selectedIndex[2] === __index
    //         ? (__item.selected = true)
    //         : (__item.selected = false);
    //     });
    //   });
    // });
    // if(type && !linkClicked){
    //   // console.log('if')

    //   this.setState({ layoutObject,
    //     optionType:type,
    //     selectedOption:selectedOption? selectedOption :null,
    //     componentSelectorVisible:true,
    //     radioLinkVisible:false,
    //   textOptionConfigurationVisible: false,
    //     radioOptionConfigurationVisible: false,
    //     imageOptionConfigurationVisible: false
    //   });
    // }
    // else if(type && linkClicked==="clicked"){
    //   this.setState({ layoutObject,
    //     optionType:type,
    //     selectedOption:selectedOption? selectedOption :null,
    //     radioLinkVisible:true,
    //     componentSelectorVisible:false,
    //     textOptionConfigurationVisible: false,
    //     radioOptionConfigurationVisible: false,
    //     imageOptionConfigurationVisible: false
    //   });
    // }
    // else{
    //   this.setState({ layoutObject,
    //     selectedOption:selectedOption? selectedOption :null,
    //      componentSelectorVisible:true,
    //      radioLinkVisible:false,
    //     textOptionConfigurationVisible: false,
    //     radioOptionConfigurationVisible: false,
    //     imageOptionConfigurationVisible: false });

    // }
    // this.props.updateLayoutObject(layoutObject);
  };

  toggleTableOptionType = (tableOptionTypeVisible, tableInput) => {
    // console.log('hi', this.state.selectedOption, tableInput)
    let xx;
    tableInput.rows.find((row) => {
      return row.columns.forEach((col) => {
        if (col.hasOwnProperty("selected") && col.selected) {
          xx = col;
          return col;
        }
      });
    });
    var tt;
    if (xx) {
      tt = {
        ...this.state.tableInfo,
        rows: tableInput.rows.length,
        columns: tableInput.rows[0].columns.length,
        type: xx.type,
        isTableInteractive: xx.interactive,
        tableOptionTypeVisible: tableOptionTypeVisible,
      };
    } else {
      tt = {
        ...this.state.tableInfo,
        rows: tableInput.rows.length,
        columns: tableInput.rows[0].columns.length,
        tableOptionTypeVisible: tableOptionTypeVisible,
      };
    }
    this.setState({
      tableOptionTypeVisible,
      tableInfo: tt,
    });
  };

  checkIfChildrenExist = (item) => {
    let childrenExist = false;
    item.row.forEach((_item) => {
      _item.column.forEach((__item) => {
        __item.option.hasOwnProperty("type") && (childrenExist = true);
      });
    });
    // console.log(childrenExist)

    return childrenExist;
  };
  expandHorizontalInMiddle = (index, _index) => {
    this.clearSelection();
    const layoutObject = this.state.layoutObject;
    layoutObject[index].row.splice(_index + 1, 0, {
      column: [{ option: {}, span: 1, selected: false }],
      span: 1,
      quantity: 1,
    });
    layoutObject[index].default &&
      (layoutObject[index].row[
        layoutObject[index].row.length - 1
      ].default = true);
    layoutObject[index].quantity++;
    this.setState({ layoutObject,optionType:"" },()=>{this.toggleComponentSelector(index, _index + 1, 0);});
    //this.props.updateLayoutObject(layoutObject);
  };
  // expandHorizontalRight = (index) => {
  //   const layoutObject = this.state.layoutObject;
  //   layoutObject[index].row.unshift({
  //     column: [{ option: {}, span: 1, selected: false }],
  //     span: 1,
  //     quantity: 1,
  //   });
  //   layoutObject[index].default &&
  //     (layoutObject[index].row[
  //       layoutObject[index].row.length - 1
  //     ].default = true);
  //   layoutObject[index].quantity++;
  //   console.log("lauout",layoutObject[index].row.length - 1)
  //   this.toggleComponentSelector(index, 0, 0);
  //   this.setState({ layoutObject,optionType:"" });
  //   this.props.updateLayoutObject(layoutObject);
    
  // };
  expandMiddleVerticalBottom = (index) => {
    this.clearSelection();
    if (this.messagesRef.current) {
      this.messagesRef.current.scrollIntoView({
          behavior: 'smooth',
         block: 'end',
         inline: 'nearest'
       });
  }
    // console.log("expand vertical")
    const layoutObject = this.state.layoutObject;
    // console.log("layout",layoutObject[index])
    layoutObject.splice(index + 1, 0, {
      row: [
        {
          column: [{ option: {}, span: 1, selected: false }],
          span: 1,
          quantity: 1,
        },
      ],
      orientation: "top",
      quantity: 1,
    });
   
    this.setState({ layoutObject,optionType:"" }, () => {this.toggleComponentSelector(index + 1, 0, 0);});
    //this.props.updateLayoutObject(layoutObject);
  };

  expandHorizontalRight = (index) => {
    this.clearSelection();
    const layoutObject = this.state.layoutObject;
    layoutObject[index].row.unshift({
      column: [{ option: {}, span: 1, selected: false }],
      span: 1,
      quantity: 1,
    });
    layoutObject[index].default &&
      (layoutObject[index].row[
        layoutObject[index].row.length - 1
      ].default = true);
    layoutObject[index].quantity++;    
    this.setState({ layoutObject,optionType:"" },()=>{this.toggleComponentSelector(index,0, 0);});
    //this.props.updateLayoutObject(layoutObject);
  };

  expandHorizontal = (index) => {
    this.clearSelection();
    const layoutObject = this.state.layoutObject;
    layoutObject[index].row.push({
      column: [{ option: {}, span: 1, selected: false }],
      span: 1,
      quantity: 1,
    });
    layoutObject[index].default &&
      (layoutObject[index].row[
        layoutObject[index].row.length - 1
      ].default = true);
    layoutObject[index].quantity++;
   
    this.setState({ layoutObject,optionType:"" },()=>{this.toggleComponentSelector(index, layoutObject[index].row.length - 1, 0);});
    //this.props.updateLayoutObject(layoutObject);
  };

  removeLayout = (index, _index) => {
    // console.log('remove layout', this.state.layoutObject)
    const layoutObject = this.state.layoutObject;
    layoutObject[index].row.splice(_index, 1);
    layoutObject[index].quantity--;
    if (layoutObject[index].row.length === 1) {
      layoutObject[index].quantity = 1;
      layoutObject[index].row[0].span = 1;
    }
    layoutObject.forEach((item, ___index) => {
      item.row.forEach((_item, ____index) => {
        _item.column.forEach((__item, _____index) => {
          __item.selected = false;
        });
      });
    });
    this.setState({
      layoutObject,
      componentSelectorVisible: false,
      radioLinkVisible: false,
      imageCaptionVisible:false,
      textOptionConfigurationVisible: false,
      radioOptionConfigurationVisible: false,
      imageOptionConfigurationVisible: false,
    });
    this.props.updateLayoutObject(layoutObject);
  };

  removeLayoutFull = (index) => {
    // console.log('remove full', this.state.layoutObject)
    const layoutObject = this.state.layoutObject;
    layoutObject.splice(index, 1);
    layoutObject.forEach((item, ___index) => {
      item.row.forEach((_item, ____index) => {
        _item.column.forEach((__item, _____index) => {
          __item.selected = false;
        });
      });
    });
    this.setState({
      layoutObject,
      componentSelectorVisible: false,
      radioLinkVisible: false,
      imageCaptionVisible:false,
      textOptionConfigurationVisible: false,
      radioOptionConfigurationVisible: false,
      imageOptionConfigurationVisible: false,
    });
    this.props.updateLayoutObject(layoutObject);
  };

  removeLayoutDefault = (index, _index, __index) => {
    // console.log('remove default', this.state.layoutObject)
    const layoutObject = this.state.layoutObject;
    layoutObject[index].row[_index].column.splice(__index, 1);
    layoutObject[index].row[_index].quantity--;
    if (layoutObject[index].row[_index].column.length === 1) {
      layoutObject[index].row[_index].quantity = 1;
      __index === 0
        ? (layoutObject[index].row[_index].column[0].span = 1)
        : (layoutObject[index].row[_index].column[__index - 1].span = 1);
    }
    layoutObject.forEach((item, ___index) => {
      item.row.forEach((_item, ____index) => {
        _item.column.forEach((__item, _____index) => {
          __item.selected = false;
        });
      });
    });
    this.setState({
      layoutObject,
      componentSelectorVisible: false,
      radioLinkVisible: false,
      imageCaptionVisible:false,
      textOptionConfigurationVisible: false,
      radioOptionConfigurationVisible: false,
      imageOptionConfigurationVisible: false,
    });
    this.props.updateLayoutObject(layoutObject);
  };

  removeOption = (index, _index, __index) => {
    const layoutObject = this.state.layoutObject;
    layoutObject[index].row[_index].column[__index].option = {};
    // console.log('remove optn', layoutObject )
    layoutObject.forEach((item, ___index) => {
      item.row.forEach((_item, ____index) => {
        _item.column.forEach((__item, _____index) => {
          if (
            ___index === index &&
            ____index === _index &&
            _____index === __index
          ) {
            __item.selected = true;
          } else __item.selected = false;
        });
      });
    });
    this.setState({
      layoutObject,
      optionType: null,
      componentSelectorVisible: true,
      radioLinkVisible: false,
      imageCaptionVisible:false,
      textOptionConfigurationVisible: false,
      radioOptionConfigurationVisible: false,
      imageOptionConfigurationVisible: false,
    });
    this.props.updateLayoutObject(layoutObject);
  };

  expandVerticalTop = () => {
    this.clearSelection();
    // console.log("vertical top")
    const layoutObject = this.state.layoutObject;
    layoutObject.unshift({
      row: [
        {
          column: [{ option: {}, span: 1, selected: false }],
          span: 1,
          quantity: 1,
        },
      ],
      orientation: "top",
      quantity: 1,
    });
    this.setState({ layoutObject,optionType:"" },()=>{this.toggleComponentSelector(0,0,0);});
    //this.props.updateLayoutObject(layoutObject);
  };

  expandVerticalBottom = () => {
    const layoutObject = this.state.layoutObject;
    layoutObject.push({
      row: [
        {
          column: [{ option: {}, span: 1, selected: false }],
          span: 1,
          quantity: 1,
        },
      ],
      orientation: "bottom",
      quantity: 1,
    });
    this.setState({ layoutObject,optionType:"" });
    this.props.updateLayoutObject(layoutObject);
  };

  mergeLayout = (index, _index, __index) => {
    const layoutObject = this.state.layoutObject;

    //console.log("mergeLayout",layoutObject, index,_index, __index);

    if(layoutObject[index].row[_index].column.length <= 1){

      //new code
      layoutObject[index].row[_index].span +=layoutObject[index].row[_index + 1].span;
      layoutObject[index].row.splice(_index + 1, 1);
      if (layoutObject[index].row.length === 1) {
        layoutObject[index].quantity = 1;
      layoutObject[index].row[_index].span = 1;
    }
      //end code
      // layoutObject[index].quantity = layoutObject[index].row.length-1;
      // layoutObject[index].row.splice(_index+1,1);

      // console.log("Deleting row")
    }else{

      layoutObject[index].row[_index+1].quantity = layoutObject[index].row[_index+1].quantity-1;
      layoutObject[index].row[_index+1].column.splice(__index, 1);

            // console.log("Deleting column")
    }


    // if (layoutObject[index].row.length === 1) {
    //   layoutObject[index].quantity = 1;
    //   layoutObject[index].row[_index].span = 1;
    // }

    this.setState({ layoutObject });
    this.props.updateLayoutObject(layoutObject);

    // console.log("mergeLayout after change",layoutObject);
  };

  mergeLayoutDefault = (index, _index, __index) => {
    const layoutObject = this.state.layoutObject;

    // console.log("mergeLayoutDefault",index,_index, __index);

    layoutObject[index].row[_index].column[__index].span +=
      layoutObject[index].row[_index].column[__index + 1].span;
    layoutObject[index].row[_index].column.splice(__index + 1, 1);
    if (layoutObject[index].row[_index].column.length === 1) {
      layoutObject[index].row[_index].quantity = 1;
      layoutObject[index].row[_index].column[__index].span = 1;
    }

    // console.log("mergeLayoutDefault after change",layoutObject);

    this.setState({ layoutObject });
    this.props.updateLayoutObject(layoutObject);
  };

  expandLayoutDefault = (index, _index, __index) => {
    const layoutObject = this.state.layoutObject;
    layoutObject[index].row[_index].column.push({
      option: {},
      span: 1,
      selected: false,
    });
    layoutObject[index].row[_index].quantity++;
    this.setState({ layoutObject ,optionType:""});
    this.props.updateLayoutObject(layoutObject);

  };
  toggleLinkOption = (index, radioInput, selectedIndex) => {
    let selectedOption;
    const layoutObject = this.state.layoutObject;
    layoutObject.forEach((item, index) => {
      item.row.forEach((_item, _index) => {
        _item.column.forEach((__item, __index) => {
          index === selectedIndex[0] &&
            _index === selectedIndex[1] &&
            __index === selectedIndex[2]
            ? (__item.selected = true)
            : (__item.selected = false);
        });
      });
    });
    selectedOption =
      layoutObject[selectedIndex[0]].row[selectedIndex[1]].column[
        selectedIndex[2]
      ].option;
    // console.log("selectedzOption", selectedOption);
    // selectedOption.radioInput = radioInput;
    this.setState({
      layoutObject,
      selectedRadioIndex: index,
      selectedOption,
      componentSelectorVisible: false,
      radioLinkVisible: true,
      imageCaptionVisible:false,
      textOptionConfigurationVisible: false,
      radioOptionConfigurationVisible: false,
      imageOptionConfigurationVisible: false,
      showLink:"element",
      selectedIndex
    });
    this.props.updateLayoutObject(layoutObject);
  };
  toggleImageCaption=(bildInput, index)=>{
    this.setState({
      componentSelectorVisible: false,
      textOptionConfigurationVisible: false,
      radioOptionConfigurationVisible: false,
      imageOptionConfigurationVisible: false,
      radioLinkVisible: false,
      imageCaptionVisible:true,
      bildCaptionData:bildInput,
      selectedBildImgIndex:index
    });
  }
  updateImageCaptions=(bildInput)=>{
    // console.log('bild', this.state.layoutObject)
    const layoutObject = this.state.layoutObject;
    layoutObject.forEach((item) => {
      item.row.forEach((_item) => {
        _item.column.forEach((__item) => {
          if (__item.selected) {
            !__item.option.hasOwnProperty("bildInput") &&
              (__item.option.bildInput = {});
            __item.option.bildInput = bildInput;
          }
        });
      });
    });
    this.setState({ layoutObject });
    this.props.updateLayoutObject(layoutObject);
  }
  handleClose = () => {
    this.setState({
      componentSelectorVisible: true,
      textOptionConfigurationVisible: false,
      radioOptionConfigurationVisible: false,
      imageOptionConfigurationVisible: false,
      radioLinkVisible: false,
      imageCaptionVisible:false,
    });
  };

  renderOption = (option, selected, index, _index, __index) => {
    // console.log('temp12', option)
    //  console.log("optionContainerClicked",this.state.optionContainerClicked)
    // console.log("componentSelectorVisible",this.state.componentSelectorVisible)
    switch (option.type) {
      case "text":
        return (
          <div>
            <TextOptionComponent
              selectedTextIndex={selected ? this.state.selectedTextIndex : null}
              toggleTextOptionConfiguration={this.toggleTextOptionConfiguration}
              index={[index, _index, __index]}
              textInput={option.textInput}
              layoutObject={this.state.layoutObject}
              // updateTextQuestion={this.updateTextQuestion}
              indexedOnSelect={this.state.indexedOnSelect}
              updateSelectedOptionForText={this.updateSelectedOptionForText}
              updateSeletedTextIndex={this.updateSeletedTextIndex}
              setOptionSelected={this.setOptionSelected}
              toggleLinkOption={this.toggleLinkOption}
              optionType={this.state.optionType}
              optionContainerClicked={this.state.optionContainerClicked}
            />
          </div>
        );
      case "datum":
        return (
          <div>
            <DatumOptionComponent
              datumOptionConfiguration={option.datumInput}
              updateDate={this.updateDate}
              layoutObject={this.state.layoutObject}
              index={[index, _index, __index]}
              indexedOnSelect={this.state.indexedOnSelect}
              setOptionSelected={this.setOptionSelected}
              toggleLinkOption={this.toggleLinkOption}
              optionType={this.state.optionType}
              optionContainerClicked={this.state.optionContainerClicked}
            />
          </div>
        );
      case "bild":
        return (

          <div >
            <BildOptionComponent
              setBildImage={this.setBildImage}
              bildInput={
                option.hasOwnProperty("bildInput") ? option.bildInput : {}
              }
              setPopup={this.setPopup}
              layoutObject={this.state.layoutObject}
              index={[index, _index, __index]}
              indexedOnSelect={this.state.indexedOnSelect}
              setOptionSelected={this.setOptionSelected}
              toggleLinkOption={this.toggleLinkOption}
              toggleImageCaption={this.toggleImageCaption}
              optionType={this.state.optionType}
              optionContainerClicked={this.state.optionContainerClicked}
            />
          </div>
        );
      case "radio":
        return (
          <div >
            <RadioOptionComponent
              selectedRadioIndex={selected ? this.state.selectedRadioIndex : null}
              toggleRadioOptionConfiguration={this.toggleRadioOptionConfiguration}
              layoutObject={this.state.layoutObject}
              index={[index, _index, __index]}
              indexedOnSelect={this.state.indexedOnSelect}
              radioInput={option.radioInput}
              toggleLinkOption={this.toggleLinkOption}
              canBeEdited={this.state.canBeEdited}
              setOptionSelected={this.setOptionSelected}
              optionType={this.state.optionType}
              optionContainerClicked={this.state.optionContainerClicked}
            />
          </div>
        );
      case "table":
        return (
          <div >
            <TableOptionComponent
              tableInput={option.tableInput}
              updateTableInput={this.updateTableInput}
              toggleTableOptionType={this.toggleTableOptionType}
              layoutObject={this.state.layoutObject}
              index={[index, _index, __index]}
              indexedOnSelect={this.state.indexedOnSelect}
              setOptionSelected={this.setOptionSelected}
              tableInfo={this.state.tableInfo}
              toggleLinkOption={this.toggleLinkOption}
              optionType={this.state.optionType}
              optionContainerClicked={this.state.optionContainerClicked}
            />
          </div>
        );
      case "upload":
        return (
          <div>
            <UploadOptionComponent
             setPopup={this.setPopup}
              uploadInput={option.uploadInput}
              layoutObject={this.state.layoutObject}
              updateUploadInput={this.updateUploadInput}
              index={[index, _index, __index]}
              indexedOnSelect={this.state.indexedOnSelect}
              setOptionSelected={this.setOptionSelected}
              toggleLinkOption={this.toggleLinkOption}
              optionType={this.state.optionType}
              optionContainerClicked={this.state.optionContainerClicked}
            />
          </div>
        );
      case "image":
        return (
          <div>
            <ImageOptionComponent
              selectedImageIndex={selected ? this.state.selectedImageIndex : null}
              toggleImageOptionConfiguration={this.toggleImageOptionConfiguration}
              index={[index, _index, __index]}
              layoutObject={this.state.layoutObject}
              indexedOnSelect={this.state.indexedOnSelect}
              imageInput={option.imageInput}
              updateImageOptionInput={this.updateImageOptionInput}
              setOptionSelected={this.setOptionSelected}
              canBeEdited={this.state.canBeEdited}
              toggleLinkOption={this.toggleLinkOption}
              optionType={this.state.optionType}
              optionContainerClicked={this.state.optionContainerClicked}
              setPopup={this.setPopup}
            />
          </div>
        );
      default:
        break;
    }
  };


  populateOption = (type) => {
    // const layoutObject = this.state.layoutObject;
    // layoutObject.forEach(item => {
    //   item.row.forEach(_item => {
    //     _item.column.forEach(__item => {
    //       __item.selected === true && (__item.option.type = type);
    //     });
    //   });
    // });
    // this.setState({ layoutObject, optionType: type });
    // this.props.updateLayoutObject(layoutObject);

    const layoutObject = this.state.layoutObject;
    switch (type) {
      case "text":
        layoutObject.forEach((item) => {
          item?.row &&   item.row.forEach((_item) => {
            _item.column.forEach((__item) => {
              if (__item.selected === true) {
                __item.option.type = type;
                __item.option._id=uuidv4()
                __item.option.textInput = {
                  inputs: [
                    {
                      id: 1,
                      interactive: false,
                      numeric: false,
                      stages: "",
                      maximum: "",
                      minimum: "",
                      value: EditorState.createEmpty(),
                      interactiveText: "",
                      hideTextToolbar: true,
                      price: "",
                      priceType: "",
                      priceTypeId: null,
                      tags: [],
                      numerically: {
                        minimumNumber: "",
                        minimumNumberCalculate: false,
                        maximumNumber: "",
                        maximumNumberCalculate: false,
                        stage: "",
                        relay1: "",
                        relay1Alle: false,
                        relay1Price: "",
                        relay2: "",
                        relay2Alle: false,
                        relay2Price: "",
                        relay3: "",
                        relay3Alle: false,
                        relay3Price: "",
                        unit: "",
                      },
                    },
                  ],
                  boxColor: "",
                };
              }
            });
          });
        });
        this.props.updateLayoutObject(layoutObject);
        return this.setState({ layoutObject, optionType: type });
      case "datum":
        layoutObject.forEach((item) => {
          item?.row &&   item.row.forEach((_item) => {
            _item.column.forEach((__item) => {
              if (__item.selected === true) {
                __item.option.type = type;
                __item.option._id=uuidv4()
                __item.option.datumInput = {
                  question: "",
                  hasDatum: true,
                  hasTime: true,
                };
              }
            });
          });
        });
        this.props.updateLayoutObject(layoutObject);
        return this.setState({
          layoutObject,
          optionType: type,
          dateInfo: { hasDatum: true, hasTime: true, question: "" },
        });
      case "radio":
        layoutObject.forEach((item) => {
          item?.row &&  item.row.forEach((_item) => {
            _item.column.forEach((__item) => {
              if (__item.selected === true) {
                __item.option.type = type;
                __item.option._id=uuidv4()
                __item.option.radioInput = {
                  question: "",
                  multipleSelection: false,
                  inputs: [
                    {
                      id: 1,
                      value: "",
                      price: "",
                      priceType: "", //flat-rate, number-guests, numerically
                      priceTypeId: null,
                      tags: [],
                      numerically: {
                        minimumNumber: "",
                        minimumNumberCalculate: false,
                        maximumNumber: "",
                        maximumNumberCalculate: false,
                        stage: "",
                        relay1: "",
                        relay1Alle: false,
                        relay1Price: "",
                        relay2: "",
                        relay2Alle: false,
                        relay2Price: "",
                        relay3: "",
                        relay3Alle: false,
                        relay3Price: "",
                        unit: "",
                      },
                    },
                    {
                      id: 2,
                      value: "",
                      price: "",
                      priceType: "",
                      priceTypeId: null,
                      tags: [],
                      numerically: {
                        minimumNumber: "",
                        minimumNumberCalculate: false,
                        maximumNumber: "",
                        maximumNumberCalculate: false,
                        stage: "",
                        relay1: "",
                        relay1Alle: false,
                        relay1Price: "",
                        relay2: "",
                        relay2Alle: false,
                        relay2Price: "",
                        relay3: "",
                        relay3Alle: false,
                        relay3Price: "",
                        unit: "",
                      },
                    },
                  ],
                };
              }
            });
          });
        });
        let dummyradioInfo = Object.assign({}, this.state.radioInfo);
        dummyradioInfo.radioChoice = "radio";
        dummyradioInfo.multipleSelection = false;
        this.props.updateLayoutObject(layoutObject);
        return this.setState({
          layoutObject,
          optionType: type,
          radioInfo: dummyradioInfo,
        });
      case "table":
        layoutObject.forEach((item) => {
          item?.row &&    item.row.forEach((_item) => {
            _item.column.forEach((__item) => {
              if( __item.selected === true ){
                __item.option.type = type
                __item.option._id=uuidv4()
              }
              // __item.selected === true && 
              //  ( __item.option.type = type)


            });
          });
        });
        this.props.updateLayoutObject(layoutObject);
        const t = {
          rows: 5,
          columns: 6,
          type: "text",
          isTableInteractive: true,
          tableOptionTypeVisible: false,
        };
        return this.setState({ layoutObject, optionType: type, tableInfo: t });
      case "image":
        layoutObject.forEach((item) => {
          item?.row &&  item.row.forEach((_item) => {
            _item.column.forEach((__item) => {
              if (__item.selected === true) {
                __item.option.type = type;
                __item.option._id=uuidv4()
                __item.option.imageInput = {
                  question: "",
                  multipleSelection: false,
                  alignment: "horizontal",
                  inputs: [],
                };
              }
            });
          });
        });

        this.props.updateLayoutObject(layoutObject);
        return this.setState({
          layoutObject,
          optionType: type,
          dateInfo: { hasDatum: true, hasTime: true, question: "" },
        });

      // case "upload":
      //   layoutObject.forEach(item => {
      //     item.row.forEach(_item => {
      //       _item.column.forEach(__item => {
      //         if(__item.selected === true){
      //          // (__item.option.type = type);
      //           // (__item.option.uploadnput = {

      //           // })
      //         }
      //       });
      //     });
      //   })
      //   this.props.updateLayoutObject(layoutObject);
      //   return (
      //     this.setState({ layoutObject, optionType: type, dateInfo:{hasDatum:true, hasTime:true} })
      //   )

      default:
        layoutObject.forEach((item) => {
          item?.row &&   item.row.forEach((_item) => {
            _item.column.forEach((__item) => {
              if( __item.selected === true ){
                __item.option.type = type
                __item.option._id=uuidv4()
              }
              // __item.selected === true && 
              //   (__item.option.type = type)
              //  ( __item.option._id=uuidv4())

            });
          });
        });

        // console.log('populate', layoutObject)
        this.props.updateLayoutObject(layoutObject);
        return this.setState({ layoutObject, optionType: type });
    }
  };

  static getDerivedStateFromProps = (nextProps) => {
    // console.log('next', nextProps)

    if (nextProps.canBeEdited) {
      return {
        layoutObject: nextProps.layoutObject,
        canBeEdited: nextProps.canBeEdited ? nextProps.canBeEdited : false,
        serviceLink: nextProps.serviceLink,
        // componentSelectorVisible:true,
        // optionType:type
      };
    } else {
      return {
        // layoutObject: nextProps.layoutObject,
        // canBeEdited:nextProps.canBeEdited?nextProps.canBeEdited:false
      };
    }
  };
  showServiceLink = () => {
    // console.log("test");
    this.setState({
      selectedOption: this.state.serviceLink,
      componentSelectorVisible: false,
      radioLinkVisible: true,
      imageCaptionVisible:false,
      textOptionConfigurationVisible: false,
      radioOptionConfigurationVisible: false,
      imageOptionConfigurationVisible: false,
      showLink:"service"
    });
  };

  onDragOverTemplateItem = (e) => {
    e.preventDefault();
    
    var target= e.target.closest('.layout-box-vertical');
    if(target)
    {
      var end_pos_left=target.getBoundingClientRect().x+target.getBoundingClientRect().width/4;
      var start_pos_right=target.getBoundingClientRect().x+ target.getBoundingClientRect().width/4*3;
      if(e.clientX>end_pos_left && e.clientX<start_pos_right)
      { 
        //middle
        var elems = document.querySelectorAll(".layout-box-vertical");
        [].forEach.call(elems, function(el) {
             el.classList.remove("drop_border");
         });
        target.classList.add('drop_border');
      }     
      
    }
    else
    {
      // var minusBtns = document.querySelectorAll(".layout-merge");  
      // [].forEach.call(minusBtns, function(el) {     
      //   el.classList.remove("btn-highlight");
      // });
      elems = document.querySelectorAll(".layout-box-vertical");
      [].forEach.call(elems, function(el) {
           el.classList.remove("drop_border");
       });
    }  
  };

  onTemplateItemDragStart = (e, row, col) => {   
      console.log(this.state.showPopup); 
      console.log(e);
    e.dataTransfer.setData("rowIndex", row);
    e.dataTransfer.setData("colIndex", col);
   //}
  };

  onTemplateItemDrop = (e) => {
    const row = e.dataTransfer.getData("rowIndex");
    const col = e.dataTransfer.getData("colIndex");
    var target = e.target.closest('.layout-box-vertical');
   
    if(target)
    {
      var start_pos_left = target.getBoundingClientRect().x;
      var end_pos_left=target.getBoundingClientRect().x+target.getBoundingClientRect().width/4;
      var start_pos_right=target.getBoundingClientRect().x+ target.getBoundingClientRect().width/4*3;
      var end_pos_right= target.getBoundingClientRect().x+ target.getBoundingClientRect().width;  
      var left=false;
      var right=false;
      var middle=false;
      if(e.clientX> start_pos_left && e.clientX<end_pos_left)
      {
        left=true;
      }
     else if(e.clientX>start_pos_right && e.clientX<end_pos_right)
      {
        right=true;
      }
    else if(e.clientX>end_pos_left && e.clientX<start_pos_right)
      {
        middle=true;
      }
      target= target.id;
      var targetRow = target.split('_')[1];
      var targetCol = target.split('_')[2];

      if (targetRow?.length && targetCol?.length && row?.length && col?.length) {
       if(right||left)
        this.insertTemplateItemFunction(targetRow, targetCol, row, col, left,right);
        else 
        this.swapTemplateItemFunction(targetRow, targetCol, row, col,middle);
      }    
    }
    var elems = document.querySelectorAll(".layout-box-vertical");
    [].forEach.call(elems, function(el) {
        el.classList.remove("drop_border");
    });

    // var minusBtns = document.querySelectorAll(".layout-merge");
    // [].forEach.call(minusBtns, function(el) {
    //   el.classList.remove("btn-highlight");
    // });
  };
  
  swapTemplateItemFunction=(targetRow, targetCol, row, col)=>{
    this.clearSelection();
    // console.log("swap function");
    const templateItem = Object.assign([], this.state.layoutObject);
    // console.log("templateItem",templateItem);
    var source = templateItem[row].row[col];
    var target = templateItem[targetRow].row[targetCol];
   
    if(source.span !== target.span){
        source.span=source.span+target.span;
        target.span=source.span-target.span;
        source.span= source.span-target.span;
    }
    templateItem[row].row.splice(col, 1);
    templateItem[row].row.splice(col, 0, target);
    templateItem[targetRow].row.splice(targetCol, 1);
    templateItem[targetRow].row.splice(targetCol, 0, source);
    this.setState({
      layoutObject: templateItem,
    }
    // ,()=>{this.toggleComponentSelector(targetRow,targetCol,0);}
    );
    this.props.updateLayoutObject(templateItem);
  }

  insertTemplateItemFunction = (targetRow, targetCol, row, col,left,right) => {
    this.clearSelection();
    const templateItem = Object.assign([], this.state.layoutObject);
    var source = templateItem[row].row[col];
    var target = templateItem[targetRow].row[targetCol];
   
    if(source.span !== target.span){
        source.span=source.span+target.span;
        target.span=source.span-target.span;
        source.span= source.span-target.span;
    }
    templateItem[row].row.splice(col, 1);
    // templateItem[row].row.splice(col, 0, target);
    // templateItem[targetRow].row.splice(targetCol, 1);
    if(right)
    {
       targetCol++;
    }
    if(row===targetRow && left && targetCol>0)
    {
      targetCol--;
      
    }
   
    templateItem[targetRow].row.splice(targetCol, 0, source);
    templateItem[row].quantity--;
    templateItem[targetRow].quantity++;

    // console.log("templateItem[row].quantity",templateItem[row].quantity);
    if(templateItem[row].quantity===0){
      // console.log(" templateItem[row].row", templateItem[row].row);
      templateItem.splice(row,1);
    } 


    this.setState({
      layoutObject: templateItem,
    });
    this.props.updateLayoutObject(templateItem);
    // swal("Die Position des Vorlagenelements wurde geändert. Bitte verknüpfen Sie das Element erneut. !");
  };

  render() {

    let layboxHeight = '200px';

    if(this.state.layoutObject.length > 1){
      layboxHeight = '100px'
    }

    return (
      <>
      <MainContainer>
      <div className="config-template template-12"   style={{ marginBottom: "0px",padding:"-10px"}}>
        <HeadingDiv  className={this.scroll ? "scroll-div" : "no-scroll"}>
        <HeadingStyle>{this.props.t('Gestalten Sie Ihre eigene Seite mit Boxen und Inhalten')}</HeadingStyle>
        </HeadingDiv>
        <div className="template-container" 
        style={{ marginBottom: "0px"
                // padding:"0px 30px"
             }}
         onDragOver={(e) => this.onDragOverTemplateItem(e)}
         onDrop={(e) => this.onTemplateItemDrop(e)}>
          <button className="template-link-btn" onClick={this.showServiceLink} >
            <img src={linkIcon} alt="" />
          </button>
          {/* {console.log(
            "layout",
            this.state.layoutObject,
            this.state.radioInfo
          )} */}
          <div className="layout-row-container"  style={{padding:"0px",marginBottom:"0px"}}>
            <button
              className="layout-expand-top"
              onClick={this.expandVerticalTop}
            >
              <img src={plusIcon} alt="" />
            </button>

            {this.state.layoutObject.map((item, index) => (
              <div
              // ref={this.messagesRef}
              className={`layout-box-container ${!item.default && "slim"} ${
                item.orientation === "bottom" && "space-top"
              } ${item.orientation === "top" && "space-bottom"}`}
              key={index}
              style={{
                minHeight: "100px",
                height:"auto"
                  // height:
                  //   item.default && !this.checkIfChildrenExist(item)
                  //     ? `calc(520px - ${
                  //         (this.state.layoutObject.length - 1) * 81
                  //       }px)`
                  //     : this.checkIfChildrenExist(item)
                  //     ? "auto"
                  //     : "51px",
                  // margin: "10px 0px",
                }}               
              >
                <button
                  className="layout-expand-right"
                  style={{left:"-23px"}}
                  onClick={() => this.expandHorizontalRight(index)}
                >
                  <img src={plusIcon} alt="" />
                </button>
                {/* { console.log('item', item)} */}
                {item.row.map((_item, _index) => (
                  <div
                  // ref={this.messagesRef}
                    className="layout-box-vertical"
                    key={_index}
                  id={'vertid_'+ index+ '_'+_index}
                  style={{
                    width: `calc(${
                      (100 / item.quantity) * item.row[_index].span
                    }% - ${
                      ((item.quantity - item.row[_index].span) * 30) /
                      item.quantity
                    }px)`,
                  }}
                    draggable
                    onDragStart={(e) => this.onTemplateItemDragStart(e, index, _index)}
                  >

                    {_item.column.map((__item, __index) => (
                      <div
                        className="layout-box"
                        key={__index}
                        style={{
                          height: `calc(${
                            (100 / _item.quantity) * _item.column[__index].span
                          }% - ${
                            ((_item.quantity - _item.column[__index].span) *
                              30) /
                            _item.quantity
                            }px)`,
                        }}
                      >
                        {/* {console.log("obj",Object.entries(__item.option))}
                        {console.log("type", __item.option.constructor)} */}
                        {Object.entries(__item.option).length === 0 &&
                          __item.option.constructor === Object
                          ? (
                            <button
                            className={`layout-box-btn ${
                              __item.selected ? "selected" : ""
                            }`}
                            onClick={() =>
                              this.toggleComponentSelector(
                                index,
                                _index,
                                __index
                              )
                            }
                            style={{'minHeight':layboxHeight}}
                            >
                              <img
                                src={__item.selected ? plusLightIcon : plusIcon}
                                alt=""
                              />
                              {/* <p>{`${index}, ${_index}, ${__index} `}</p> */}
                            </button>
                          ) : (
                            <div
                              ref={this.messagesRef}
                              className="option-container"
                              style={{ marginBottom: "10px"}}
                            >
                              {/* {console.log("render", __item.option, __item, __index )} */}
                              {this.renderOption(
                                __item.option,
                                __item.selected,
                                index,
                                _index,
                                __index
                              )}
                            </div>
                          )}
                        {Object.entries(__item.option).length === 0 &&
                          __item.option.constructor === Object &&
                          this.state.layoutObject[index].row.length !== 1 &&
                          this.state.layoutObject[index].row[_index].column
                            .length < 2 && (
                            <button
                              className="layout-remove"
                              onClick={() => this.removeLayout(index, _index)}
                            >
                              <img src={crossSmallIcon} alt="" />
                            </button>
                          )}
                        {Object.entries(__item.option).length === 0 &&
                          __item.option.constructor === Object &&
                          !item.default &&
                          this.state.layoutObject[index].row.length === 1 &&
                          this.state.layoutObject.length !== 1 && (
                            <button
                              className="layout-remove"
                              onClick={() => this.removeLayoutFull(index)}
                            >
                              <img src={crossSmallIcon} alt="" />
                            </button>
                          )}
                        {Object.entries(__item.option).length === 0 &&
                          __item.option.constructor === Object &&
                          this.state.layoutObject[index].row[_index].column
                            .length > 1 && (
                            <button
                              className="layout-remove"
                              onClick={() =>
                                this.removeLayoutDefault(index, _index, __index)
                              }
                            >
                              <img src={crossSmallIcon} alt="" />
                            </button>
                          )}
                        {__item.option.type && (
                          <button
                            className="layout-remove"
                            onClick={() =>
                              // this.removeOption(index, _index, __index)
                              swal({
                                title:
                                `${this.props.t('Sind Sie sicher, dass Sie löschen möchten?')}`,
                                // text:
                                //   "Nach dem Löschen können Sie diese Frage nicht mehr wiederherstellen!",
                                buttons: [`${this.props.t('Abbrechen')}`, "OK"],
                                // buttons: true,
                                dangerMode: true,
                              })
                                .then((isConfirmed) => {
                                  if (isConfirmed) {
                                    this.removeOption(index, _index, __index);
                                  }
                                })
                                .catch((err) => {
                                  console.log(err);
                                })
                            }
                          >
                            <img src={crossSmallIcon} alt="" />
                            {/* <p>{`${index}, ${_index}, ${__index} `}</p> */}
                          </button>
                        )}
                        {_index + 1 !==
                          this.state.layoutObject[index].row.length && this.state.layoutObject[index].row.length > 0 && (
                            // <div style={{ display: "flex", padding: "20px" }}>
                            <div style={{ display: "flex"}}>
                              <button
                                className="layout-expand-right"
                                style={{ marginRight: "20px" }}
                                onClick={() =>
                                  this.expandHorizontalInMiddle(index, _index)
                                }
                              >
                                <img src={plusIcon} alt="" />
                              </button>
                              <button
                                className="layout-merge"
                                onClick={() => this.mergeLayout(index, _index, __index)}
                              >
                                <img src={minusIcon} alt="" />
                              </button>
                            </div>
                          )}
                        {__index + 1 !==
                          this.state.layoutObject[index].row[_index].column
                            .length && (
                            <button
                              className="layout-merge-default"
                              onClick={() =>
                                this.mergeLayoutDefault(index, _index, __index)
                              }
                            >
                              <img src={minusIcon} alt="" />
                            </button>
                          )}
                      </div>
                    ))}
                  </div>
                ))}
                <button
                  ref={this.messagesRef}
                  className="layout-expand-right"
                  onClick={() => this.expandHorizontal(index)}
                >
                  <img src={plusIcon} alt="" />
                </button>
                <button
                  className="layout-expand-bottom"
                  // style={{position:"relative",bottom:"-10px",left:"50%",top:"-20px"}}
                  onClick={() => this.expandMiddleVerticalBottom(index)}
                >
                  <img src={plusIcon} alt="" ref={this.messagesRef}/>
                </button>
              </div>
            ))}
            {/* <button
              className="layout-expand-bottom"
              onClick={this.expandVerticalBottom}
            >
              <img src={plusIcon} alt="" />
            </button> */}
          </div>
        </div>
        {/* { console.log('optns cmp', this.state.uploadInfo )} */}
        {/* {console.log("optiontype",this.state.optionType,"selectedOption",this.state.selectedOption)} */}
        {this.state.componentSelectorVisible && (

          <OptionsComponent
            clickOption={this.optionContainerClicked}
            type={this.state.optionType}
            selectedOption={this.state.selectedOption}
            dateInfo={this.state.dateInfo}
            uploadInfo={this.state.uploadInfo}
            tableInfo={this.state.tableInfo}
            radioInfo={this.state.radioInfo}
            populateOption={this.populateOption}
            updateDatumOptionConfiguration={this.updateDatumOptionConfiguration}
            toggleImageOptionAlignment={this.toggleImageOptionAlignment}
            setMinMaxValue={this.setMinMaxValue}
            toggleUploadDownload={this.toggleUploadDownload}
            updateTableInput={this.updateTableInput}
            tableOptionTypeVisible={this.state.tableOptionTypeVisible}
            updateTextOptionParams={this.updateTextOptionParams}
            selectedTextIndex2={this.state.selectedTextIndex2}
            updateUploadInputMaxMin={this.updateUploadInputMaxMin}
            toggleRadioOptionType={this.toggleRadioOptionType}
            toggleImageOptionType={this.toggleImageOptionType}
          />
        )}
        {this.state.radioOptionConfigurationVisible && (
          <RadioOptionConfiguration
            option={this.state.selectedOption}
            selectedRadioIndex={this.state.selectedRadioIndex}
            handleClose={this.handleClose}
            updateRadioOptionConfiguration={this.updateRadioOptionConfiguration}
          />
        )}
        {/* {console.log('calling config',this.state.selectedOption )} */}
        {this.state.textOptionConfigurationVisible && (
          <TextOptionConfiguration
            option={this.state.selectedOption}
            selectedTextIndex={this.state.selectedTextIndex}
            handleClose={this.handleClose}
            updateTextOptionConfiguration={this.updateTextOptionConfiguration}
          />
        )}
        {this.state.imageOptionConfigurationVisible && (
          <ImageOptionConfiguration
            option={this.state.selectedOption}
            selectedImageIndex={this.state.selectedImageIndex}
            handleClose={this.handleClose}
            updateImageOptionConfiguration={this.updateImageOptionConfiguration}
          />
        )}
        {this.state.radioLinkVisible && (
          <RadioLinkOption
            option={this.state.selectedOption}
            selectedIndex={this.state.selectedIndex}
            selectedRadioIndex={this.state.selectedRadioIndex}
            handleClose={this.handleClose}
            layoutObject={this.state.layoutObject}
            updateRadioLinkConfiguration={this.updateRadioLinkConfiguration}
            showLink={this.state.showLink}
          />
        )}
        {this.state.imageCaptionVisible && (
          <ImageCaption
            bildCaptionData={this.state.bildCaptionData}
            selectedBildImgIndex={this.state.selectedBildImgIndex}
            // selectedRadioIndex={this.state.selectedRadioIndex}
            handleClose={this.handleClose}
            updateImageCaptions={this.updateImageCaptions}
          // updateRadioLinkConfiguration={this.updateRadioLinkConfiguration}
          />
        )}
      </div>
      </MainContainer>
    </>
    );
  }
}

export default  withTranslation() (ConfiguratorTemplate12);

import React, { Component } from "react";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/trash.svg";
import fileCheckedIcon from "../../assets/file-checked.svg";
import plusIcon from "../../assets/plus.svg";
import TableSection, { Header } from "../partials/TableSection";
import HttpService from "../../services/HttpService";
import { StateContext } from "../../context/state";
import saveIcon from "../../assets/upload.svg";
import cancelIcon from "../../assets/cross.svg";
import cloneIcon from "../../assets/duplicate.svg";
import Text from "../preview/Text";
import linkIcon from "../../assets/arrows.svg";
import swal from "sweetalert";
import Swal from "sweetalert2";
import moment from "moment"
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { withTranslation} from 'react-i18next';

class QuestionnairesPage extends Component {
  static contextType = StateContext;
  originals = [];

  constructor(props) {
    super(props);

    this.state = {
      loading: "Loading...",
      quests: [],
      filteredData: [],
       filter: undefined,
       keyFigures:{},
       languages:[],
       language:"en",
       headers :[
        {
          name: "Fragebogen",
          value: "name",
          type: Header.TEXT,
          length: 30,
        },
        {
          name: "Erstellt am",
          value: "creationDate",
          type: Header.DATE,
          readOnly: true,
        },
        {
          name: "Aktive Events",
          value: "upcomingEvent",
          type: Header.TEXT,
          readOnly: true,
        },
        {
          name: "Notizen",
          value: "type",
          type: Header.SPINNER,
          values: ["Netto", "Brutto"],
        },
        {
          name: "venue",
          value: "venueName",
          type: Header.AUTO_SPINNER,
          values: [],
          field: "venueId",
          // readOnly: true
        }
      // {
      //   name: "language",
      //     value: "languageFormat",
      //     type: Header.SPINNER,
      //     values: [],
      // }
      ]
    };   

  }

  addRow = () => {
    const quests = [
      {
        venueId: this.context.venueId,
        // venueName:"",
        name: "",
        creationDate: new Date(),
        upcomingEvent: 0,
        questionCount: 0,
        isParent: true,
        isDeleted: false,
        edit: true,
        type:"",
        // languageFormat:""
      },
      ...this.state.quests,
    ];

    this.setState({ quests });
  };
  FilteredData = (data) => {
    //  console.log("data",data)
    this.setState({
      filteredData: data,
    });
  };
  saveQuest = (quest, index) => {
    const { edit, ...body } = quest; 
    const { _id, venueId, creationDate, name,type} = body;
    // const { _id, venueId, creationDate, name,venueName ,type,languageFormat} = body;

    if (!venueId) return swal(`${this.props.t('Wählen Sie einen Veranstaltungsort')}`, "", "info");
    if (!new Date(creationDate))
      return swal(
        `${this.props.t('Wählen Sie ein gültiges Erstellungsdatum des Fragebogens aus')}`,
        "",
        "info"
      );
    if (quest.validation === true) return swal(`${this.props.t('Invalid name validation message')}`, "", "warning");
    if (!name)
      return swal(`${this.props.t('Geben Sie den Namen des Fragebogens ein')}`, "", "info");
    if (!type) return swal(`${this.props.t('Geben Sie die Art des Fragebogens ein')}`, "", "info");
    // if(!languageFormat) return swal(`${this.props.t('select language for questionaire')}`, "", "info");
    if (this.context.userRole === "SA") {
      body.userRole = "SA";
    }
    body.creationDate = moment(body.creationDate).format('YYYY-MM-DDTHH:mm:ss[Z]');
    let promise = _id
      ? HttpService.updateQuestionnaire({ ...body, isParent: true })
      : HttpService.createQuestionnaire(body);
    promise
      .then((res) => {
        console.log('qtn', res)

        const { quests, filteredData } = this.state;
        if (quests.length > filteredData.length) {
          const q = {
            ...quests[index],
            edit: false,
            _id: _id ?? res?.id,
          };

          quests[index] = q;
          this.originals[index] = { ...q };

          this.setState({ quests },()=>{
            window.location.reload();
          });
        } else {
          const q = {
            ...filteredData[index],
            edit: false,
            _id: _id ?? res?.id,
          };
          let newIndex;

          this.originals.forEach((quest) => {
            // console.log("events",event)
            if (quest._id === filteredData[index]._id) {
              newIndex = this.originals.indexOf(quest);
            }
          });
          filteredData[index] = q;
          this.originals[newIndex] = { ...q };

          this.setState({ quests: filteredData },()=>{
            window.location.reload();
          });
        }
      })
      .catch((err) => swal(JSON.stringify(err), "", "error"));
      
  };
  cloneQuestionnaire = (quest, index) => {
    const currentDate = new Date();
    HttpService.cloneQuestionnaire(
      quest,
      currentDate.toISOString(),
      false,
      quest.name
    )
      .then((res) => {
        let { quests, filteredData } = this.state;

        if (quests.length === filteredData.length) {
          //  console.log("res",res)
          const q = {
            ...quests[index],
            _id: res.id,
            creationDate: currentDate,
            name: `${quests[index].name} (copy)`,
            edit: false,
            type: quests[index].type,
            upcomingEvent: 0,
          };

          quests.splice(index + 1, 0, q);

          this.originals.splice(index + 1, 0, q);

          this.setState({ quests });
        } else {
          let newIndex;

          this.originals.forEach((quest) => {
            // console.log("events",event)
            if (quest._id === filteredData[index]._id) {
              newIndex = this.originals.indexOf(quest);
            }
          });
          // console.log("res",res)
          const q = {
            ...filteredData[newIndex],
            _id: res.id,
            creationDate: currentDate,
            name: `${filteredData[newIndex].name} (copy)`,
            edit: false,
            type: filteredData[newIndex].type,
            upcomingEvent: 0,
          };

          filteredData.splice(newIndex + 1, 0, q);

          this.originals.splice(newIndex + 1, 0, q);

          this.setState({ quests: filteredData });
        }
      })
      .catch((err) => swal(JSON.stringify(err), "", "error"));
    
  };

  

  cloneQuestionnaireWithChangePlan = (quest, index) => {
    const currentDate = new Date();
    swal({
      title: `${this.props.t('Möchten Sie auch den beigefügten Organisationsplan kopieren')}`, text: "", icon: "info",
      buttons: {
        cancel: `${this.props.t('Abbrechen')}`, no:  {
          text:`${this.props.t('Nein')}`,
          value:"no"
        }, ok: {
          text:`${this.props.t('Ja')}`,
          value:"ok"
        }
      }
    })
    .then(value => {
        console.log(value);
        switch (value) {
          case "ok":
           Swal.fire({
                inputPlaceholder: `${this.props.t('Fragebogenname')}`,
                title: `${this.props.t('Bitte ändern Sie den Fragebogennamen!')}`,
                input: "text",
                showCancelButton: true,
                cancelButtonText: `${this.props.t('Abbrechen')}`,
                inputValidator: (value) => {
                  const specialChar = /[!#$%^&*()+=[\]{};':"\\|,<>/?]+/;
                  if (!value) {
                    return `${this.props.t('You need to write something!')}`
                  }
                  else if (specialChar.test(value)) {
                    return `${this.props.t('Invalid name validation message')}`
                   }
                }
              })
              .then((inputValue) => {
                console.log("inputValue",inputValue);
                if(inputValue.isConfirmed){
                  HttpService.cloneQuestionnaire(
                    quest,
                    currentDate.toISOString(),
                    true,
                    inputValue.value
                  )
                    .then((res) => {
                      let { quests, filteredData } = this.state;
    
                      if (quests.length === filteredData.length) {
                        //  console.log("res",res)
                        const q = {
                          ...quests[index],
                          _id: res.id,
                          creationDate: currentDate,
                          name: `${inputValue.value}`,
                          edit: false,
                          type: quests[index].type,
                          upcomingEvent: 0,
                        };
    
                        quests.splice(index + 1, 0, q);
    
                        this.originals.splice(index + 1, 0, q);
    
                        this.setState({ quests }, () => {
                          swal(
                            `${this.props.t('Schön!')}`,
                            `${this.props.t('Sie schrieben')}: ` + inputValue.value,
                            "success"
                          );
                        });
                      } else {
                        let newIndex;
    
                        this.originals.forEach((quest) => {
                          // console.log("events",event)
                          if (quest._id === filteredData[index]._id) {
                            newIndex = this.originals.indexOf(quest);
                          }
                        });
                        // console.log("res",res)
                        const q = {
                          ...filteredData[newIndex],
                          _id: res.id,
                          creationDate: currentDate,
                          name: `${inputValue.value}`,
                          edit: false,
                          type: filteredData[newIndex].type,
                          upcomingEvent: 0,
                        };
    
                        filteredData.splice(newIndex + 1, 0, q);
    
                        this.originals.splice(newIndex + 1, 0, q);
    
                        this.setState({ quests: filteredData }, () => {
                          swal(
                            `${this.props.t('Schön!')}`,
                            `${this.props.t('Sie schrieben')}: ` + inputValue.value,
                            "success"
                          );
                        });
                      }
                    })
                    .catch((err) => swal(JSON.stringify(err), "", "error"));
                  }
                 })
                .catch((err) => {}); 
            break;
          case "no":
            this.cloneQuestionnaire(quest, index);
            break;
          default:
            break;
        }
      }
      )
  };

  onCancel = (quest, index) => {
    const { filteredData } = this.state;
    let newIndex;

    this.originals.forEach((quest) => {
      // console.log("events",event)
      if (quest._id === filteredData[index]._id) {
        newIndex = this.originals.indexOf(quest);
      }
    });
    if (quest._id) {
      filteredData[index] = { ...this.originals[newIndex] };
    } else {
      filteredData.shift();
    }
    // if (quest._id) filteredData[index] = { ...this.originals[newIndex] };
    // else filteredData.shift();

    this.setState({ quest: filteredData });
  };
  async fetchKeys(range) {
    const { venueId, userRole, loggedInUserId } = this.context;

    try {
      const keyFigures = await HttpService.fetchKeyFigures();
      const userKey = await HttpService.fetchUserKeyFigures(loggedInUserId);
      const promises = [];

      const startDate = range?.fromDate ?? "";
      const endDate = range?.toDate ?? "";

      keyFigures.forEach((key, index) => {
        const selected = userKey?.keys
          ? userKey?.keys?.includes(key.id)
          : index < 4;
        if (selected) {
          promises.push(
            HttpService.fetchKeyFigure({
              keyFigId: key.id,
              venueId: venueId,
              userRole: userRole,
              fromDate: startDate,
              toDate: endDate,
            }).then((value) => {
              keyFigures[index] = { ...key, selected, value };
            })
          );
        } else {
          keyFigures[index] = { ...key, selected, value: 0 };
        }
      });

      Promise.all(promises)
        .then((res) => {
          localStorage.setItem("KeyFig", JSON.stringify(keyFigures));
          this.setState({
            keyFigures,
            // userKey,
            // fromDate : startDate,
            // toDate : endDate
          });
          // window.location.reload()
          this.context.updateState({ keyFig: keyFigures });
        })
        .catch((err) => {
          this.setState({ loading: JSON.stringify(err) });
        });
    } catch (error) {
      this.setState({
        loading: JSON.stringify(error),
      });
    }
  }
  deleteQuestionnaire = (quest, index) => {
    HttpService.deleteQuestionnaire(quest)
      .then((res) => {
        let { quests, filteredData } = this.state;
        // this.fetchKeys({ fromDate: "", toDate: "" });
        // this.subscription = dateRangeService
        //   .getDateRange()
        //   .subscribe((range) => this.fetchKeys(range));
        if (quests.length === filteredData.length) {
          quest.isDeleted = true;
          quests[index] = { ...quest };

          // quests = quests.filter((q) => q._id !== quest._id);
          this.setState({ quests });
          this.context.updateState({ keyFig: "" });
        } else {
          let newIndex;

          this.originals.forEach((quest) => {
            // console.log("events",event)
            if (quest._id === filteredData[index]._id) {
              newIndex = this.originals.indexOf(quest);
            }
          });
          quest.isDeleted = true;
          filteredData[newIndex] = { ...quest };
          // filteredData = filteredData.filter((q) => q._id !== quest._id);
          this.setState({ quests: filteredData });
          this.context.updateState({ keyFig: "" });
        }
      })
      .catch((err) => JSON.stringify(err));
  };

  buttons = [
    {
      id: 1,
      name: "edit",
      icon: editIcon,
      tooltipText: `${this.props.t('Bearbeiten')}`,
      onPress: (quest, index) => {
        const { filteredData } = this.state;

        // let newIndex;

        // for (let i = 0; i < quests.length; i++) {
        //   if (quests[i] == quest) {
        //     newIndex = i;
        //   }
        // }

        filteredData[index].edit = true;
        this.setState({ quests: filteredData });
      },
      isVisible: (quest) => (!quest.isDeleted && !quest.edit) ?? false,
    },
    {
      id: 2,
      name: "save",
      icon: saveIcon,
      tooltipText: `${this.props.t('save')}`,
      onPress: (quest, index) => this.saveQuest(quest, index),
      isVisible: (quest) => (!quest.isDeleted && quest.edit) ?? false,
    },
    {
      id: 3,
      name: "edit-questionnaire",
      icon: linkIcon,
      tooltipText: `${this.props.t('questionnaire')}`,
      onPress: (quest) => {
        // console.log("quest",quest)
        this.context.updateState({
          questionnaireId: quest._id,
          parentQuestionnaireId: quest._id,
          priceType: quest.type,
        });
        this.props.history.push("/eventsWelcome", {
          from: "questionnairePage",
        });
      },
      isVisible: (event) => (event._id && event.edit) ?? false,
    },
    {
      id: 4,
      name: "duplicate",
      icon: cloneIcon,
      tooltipText: `${this.props.t('Kopieren')}`,
      onPress: (quest, index) =>
        this.cloneQuestionnaireWithChangePlan(quest, index),
      isVisible: (quest) => (!quest.isDeleted && !quest.edit) ?? false,
    },

    {
      id: 5,
      name: "plans",
      icon: fileCheckedIcon,
      tooltipText: `${this.props.t('Organisations_plan')}`,
      onPress: (quest) => {
        // localStorage.setItem("questionnaireId", JSON.stringify(quest._id));
        //  const newWindow=  window.open(
        //       `${window.location.origin}/admin/plans`,

        //       "_blank"
        //     );

        //     newWindow.my_special_setting = "fromQuestionnairePage";
        // this.context.updateState({
        //   questionnaireName: quest.name,
        //  });
        this.props.history.push("/admin/plans", { questionnaireId: quest._id });
      },
      isVisible: (quest) =>
        (!quest.isDeleted && !quest.edit && this.context.userRole !== "SA") ??
        false,
    },
    {
      id: 6,
      name: "delete",
      icon: deleteIcon,
      tooltipText: `${this.props.t('Löschen')}`,
      onPress: (quest, index) => {
        swal({
          title: `${this.props.t('Bist du sicher')}`,
          text:
          `${this.props.t('Nach dem Löschen können Sie diese Frage nicht mehr wiederherstellen!')}`,
          buttons: [`${this.props.t('Abbrechen')}`, "OK"],
          // buttons: true,
          dangerMode: true,
        })
          .then((isConfirmed) => {
            if (isConfirmed) {
              this.deleteQuestionnaire(quest, index);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      isVisible: (quest) => (!quest.isDeleted && !quest.edit) ?? false,
    },
    {
      id: 7,
      name: "cancel",
      icon: cancelIcon,
      tooltipText: `${this.props.t('Abbrechen')}`,
      onPress: (quest, index) => {
        const { quests, filteredData } = this.state;
        let newIndex;
        
        
        this.originals.forEach((q) => {
          // console.log("events",event)
          if (q._id === filteredData[index]._id) {
            newIndex = this.originals.indexOf(q);
          }
        });

        if (quest._id) {
          filteredData[index].edit = false;
          filteredData[index] = { ...this.originals[newIndex] };
        } else {
          quests.shift();
        }

        this.setState({ quests: filteredData });
        window.location.reload();
      },
      // onPress: (quest, index) => this.onCancel(quest, index),
      isVisible: (quest) => (!quest.isDeleted && quest.edit) ?? false,
    },
  ];

  componentDidMount() {
    const { venueId, venueName } = this.context;
    sessionStorage.removeItem("state2");
    let quests;
    let questionnairePromise;
    if (this.context.userRole !== "SA") {
      questionnairePromise = HttpService.fetchQuestionnaires(venueId).then(
        (res) => {
          // console.log("res",res)

          quests = res?.map((quest) => {
            const q = {
              ...quest,
              creationDate: isNaN(Date.parse(quest?.creationDate))
                ? new Date()
                : new Date(quest?.creationDate),
              edit: false,
              type: quest.type ? quest.type : "Netto",
            };
            this.originals.push({ ...q });
            return q;
          });

        }
      );
    } else {
      questionnairePromise = HttpService.fetchQuestionnaireInSuperAdmin().then(
        (res) => {
          // console.log("res",res)
          quests = res?.map((quest) => {
            const q = {
              ...quest,
              creationDate: isNaN(Date.parse(quest?.creationDate))
                ? new Date()
                : new Date(quest?.creationDate),
              edit: false,
              type: quest.type ? quest.type : "Netto",
            };
            this.originals.push({ ...q });
            return q;
          });
        }
      );
    }

    const venuePromise = HttpService.getVenues().then((res) => {
      // console.log(res,"res")
      const venues = res?.map((v) => {
        return {
          id: v?._id,
          name: v?.venueName,
        };
      });
      // console.log("venues",venues)
      let index = this.state.headers?.findIndex((header) => header.value === "venueName");
      this.state.headers[index].values = venues;
    });
    Promise.all([questionnairePromise, venuePromise])
      .then(() => {
        let filter = undefined;
        if (venueName) {
          this.context.updateState({ venueName: undefined });
          filter = {
            venueName: venueName,
          };
        }

        this.setState({
          loading: undefined,
          quests,
          filter,
        });
      })

      .catch((err) => {
        this.setState({
          loading: JSON.stringify(err),
        });
      });

  }

  render() {

  const { loading, quests} = this.state;
  const header2=  this.state.headers.filter(header=>header.name!=="venue")

  let newHeaders=[]
  if(this.context.userRole!=="SA"){
    newHeaders=header2
  }
  else{
    newHeaders=this.state.headers
  }
// console.log("quests",quests)

    if (loading) {
      return (
        <div style={styles.row}>
          <div className="col-1" />
          <div className="col-10">
            <Text value={this.props.t('Loading')} />
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div style={styles.row}>
          <div className="col-1"></div>
          <div className="col-10">
            <div
              style={{ color: "#524f4f", fontSize: "24px", marginTop: "20px" }}
            >
             {this.props.t('questionnaire')}{" "}
            </div>
            <div style={styles.divider} />
          </div>
        </div>
        <div style={styles.row}>
          <div className={"col-11"} style={styles.addWrapper}>
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-disabled`}>
                  <span>{this.props.t('Create_questionnaire')}</span>
                </Tooltip>
              }
            >
              <img
                style={styles.pointer}
                src={plusIcon}
                alt=""
                onClick={this.addRow}
              />
            </OverlayTrigger>
          </div>
        </div>

        <div style={styles.row}>
          <div className={"col-1"} />
          <div className={"col-10"}>
            <TableSection
              headers={newHeaders}
              data={quests}
              buttons={this.buttons}
              buttonCount={4}
              FilteredData={this.FilteredData}
              originalData={this.originals}
              // onOptionSelect={(row, header, value) => {
              //   if (header.value === "venueName") {
              //     HttpService.getVenues(

              //     ).then((res) => {
              //       // console.log("response",res)
              //       const { quests } = this.state;
              //       quests[row].venueId = res?._id;
              //       quests[row].venueName = res?.venueName;
              //       this.setState({ quests });
              //     });
              //   }
              // }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation() (QuestionnairesPage);
const styles = {
  row: {
    display: "flex",
    flexWrap: "wrap",
  },
  addWrapper: {
    marginLeft: "40px",
  },
  pointer: {
    cursor: "pointer",
  },
  divider: {
    borderTop: "1px solid #d0d3d4",
    margin: "30px 0 0px 0px",
    paddingTop: "10px",
  },
};

import React, { Component } from "react";
import plusIcon from "../../assets/plus.svg";
import TableSection, { Header } from "../partials/TableSection";
import fileIcon from "../../assets/file.svg";
import fileCheckedIcon from "../../assets/file-checked.svg";
import editIcon from "../../assets/edit.svg";
import saveIcon from "../../assets/upload.svg";
import cancelIcon from "../../assets/cross.svg";
import deleteIcon from "../../assets/trash.svg";
import linkIcon from "../../assets/arrows.svg";
import Text from "../preview/Text";
import HttpService from "../../services/HttpService";
import { StateContext } from "../../context/state";
import Slider from "../partials/Slider";
import swal from "sweetalert";
import { Roles } from "./UserInfoPage";
import moment from "moment-timezone";
import { baseUrl } from "../../Constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import axios from "axios";


const headers = [
  {
    name: "Event Name",
    value: "eventName",
    type: Header.TEXT,
  },
  {
    name: "Veranstaltungsart",
    value: "type",
    type: Header.SPINNER,
    values: ["meeting", "wedding"],
  },
  {
    name: "Eventdatum",
    value: "eventDate",
    type: Header.DATE,
  },
  {
    name: "Fragebogen",
    value: "parentQuestionnaireName",
    type: Header.AUTO_SPINNER,
    values: [],
    field: "parentQuestionnaireId",
  },
  {
    name: "Kontaktperson",
    value: "contactPersonName",
    type: Header.AUTO_SPINNER,
    values: [],
    field: "contactPersonId",
  },
  {
    name: "Eingabeschluss",
    value: "deadlineForEntries",
    type: Header.DATE,
  },
  {
    name: "Eventmanager",
    value: "vvoName",
    type: Header.AUTO_SPINNER,
    values: [],
    field: "vvoId",
  },
];

class EventsPage extends Component {

  static contextType = StateContext;
  originals = [];


  currentDate = new Date();

  constructor(props) {
    super(props);

    this.state = {
      loading: "Loading...",
      events: [],
      collapsed: true,
      templateCollapsed:true,
      plans: [],
      questionnaireID: "",
      dummyParentQuestionnaireId: "",
      dummyChildQuestionnaireId: "",
      filteredData: [],
      event: "",
      keyFigures: {},
      allEvents: [],
      headerEmpty: false,
      templates:[],
      templateName:""
    };
  }

  componentDidMount() {
    // localStorage.clear("eventId")
    // i18next.changeLanguage(this.context.languageFormat);													   
    const venue = JSON.parse(sessionStorage.getItem("USER"))?.urlName
    // console.log("user",venue)
    if (venue) {
      document.title = venue
    }
    const { venueId, loggedInUserId, userRole } = this.context;
    let events;
    let allEvents;
    const eventsPromise = HttpService.getEvents(
      venueId,
      loggedInUserId,
      userRole
    ).then((res) => {
      const allEvents2 = Object.assign([], res);
      allEvents2.sort(function (a, b) {
        return new Date(a.eventDate) - new Date(b.eventDate);
      });

      allEvents = allEvents2?.map((event) => {
        const e = {
          ...event,
          eventDate: isNaN(Date.parse(event?.eventDate))
            ? this.currentDate
            : new Date(event?.eventDate),
          deadlineForEntries: isNaN(Date.parse(event?.deadlineForEntries))
            ? this.currentDate
            : new Date(event?.deadlineForEntries),
          edit: false,
        };
        // this.originals.push({ ...e });

        return e;
      });
      // console.log("all",allEvents)
      let finalDate = moment(new Date()).subtract(7, "days")._d;
      // console.log("finaldate",new Date(finalDate).toISOString())
      let finalDate1 = new Date(finalDate).toISOString();
      const dummy = res.filter((r) =>
        moment(r.eventDate).isSameOrAfter(finalDate1)
      );
      // console.log("res",dummy)
      const dummyEvents = Object.assign([], dummy);

      dummyEvents.sort(function (a, b) {
        return new Date(a.eventDate) - new Date(b.eventDate);
      });
      // this.sortEvents(res)
      events = dummyEvents?.map((event) => {
        const e = {
          ...event,
          eventDate: isNaN(Date.parse(event?.eventDate))
            ? this.currentDate
            : new Date(event?.eventDate),
          deadlineForEntries: isNaN(Date.parse(event?.deadlineForEntries))
            ? this.currentDate
            : new Date(event?.deadlineForEntries),
          edit: false,
        };
        this.originals.push({ ...e });

        return e;
      });

      // console.log("events",allEvents)
    });
    let string = "";
    const vvosPromise = HttpService.getVVOsOnEventsPage(venueId, string).then(
      (res) => {
        const vvos = res?.map((vvo) => {
          return {
            id: vvo?._id,
            name:
              vvo.companyName ||
              `${vvo.firstName1 ?? ""} ${vvo.surname1 ?? ""}${vvo?.surname2 || vvo?.firstName2 ? "/" : ""
              }${vvo?.firstName2 ?? ""} ${vvo?.surname2 ?? ""}`,
          };
        });

        let index = headers.findIndex((header) => header.value === "vvoName");
        headers[index].values = vvos;
      }
    );

    const contactPersonsPromise = HttpService.getContactPersons(venueId).then(
      (res) => {
        const persons = res?.map((couple) => {
          return {
            id: couple?._id,
            name:
              couple?.companyName ||
              `${couple?.firstName1 ?? ""} ${couple?.surname1 ?? ""}${couple?.surname2 || couple?.firstName2 ? "/" : ""
              }${couple?.firstName2 ?? ""} ${couple?.surname2 ?? ""}`,
            // `${couple?.firstName1 ?? ""} ${couple?.firstName2 ?? ""}`,
          };
        });

        let index = headers.findIndex(
          (header) => header.value === "contactPersonName"
        );
        headers[index].values = persons;
      }
    );

    const questionnairesPromise = HttpService.fetchParentQuestionnaires(
      venueId
    ).then((res) => {
      const quests = res?.map((q) => {
        return {
          id: q?._id,
          name: q?.name,
        };
      });

      let index = headers.findIndex(
        (header) => header.value === "parentQuestionnaireName"
      );
      headers[index].values = quests;
    });

    axios.get(`${baseUrl}api/invoice/template/${venueId}`)
    .then((res) => {
      this.setState({ templates: res.data.data});
    })
    .catch((err) => {
      console.log(err)
    })

    Promise.all([
      eventsPromise,
      vvosPromise,
      contactPersonsPromise,
      questionnairesPromise,
    ])

      .then(() => {
        // events.sort(function (a, b) {
        //   return new Date(a.eventDate) - new Date(b.eventDate);
        // });
        this.setState({
          loading: undefined,
          events,
          allEvents,
        });
      })
      .catch((err) => {
        this.setState({
          loading: JSON.stringify(err),
        });
      });
  }
  checkIfHeaderEmpty = (val) => {
    console.log("checkheaderstatus", val)
    if (val === true) {
      this.refreshEvents();
      console.log("header is empty", val)
    } else {
    }
    // console.log("evets",events)
    // console.log("alll",this.state.allEvents)
  };
  sortEvents = (events) => {
    let finalDate = moment(new Date()).subtract(7, "days")._d;
    let finalDate1 = new Date(finalDate).toISOString();
    const dummy = events.filter((r) =>
      moment(r.eventDate).isSameOrAfter(finalDate1)
    );

    const dummyEvents = Object.assign([], dummy);

    dummyEvents.sort(function (a, b) {
      return new Date(a.eventDate) - new Date(b.eventDate);
    });
    this.setState({ events: dummyEvents })
  }

  refreshEvents = async () => {
    const { venueId, loggedInUserId, userRole } = this.context;

    let events;
    let allEvents;
    const eventsPromise = await HttpService.getEvents(
      venueId,
      loggedInUserId,
      userRole
    ).then((res) => {
      // console.log("rows",this.context.rows)
      const allEvents2 = Object.assign([], res);
      allEvents2.sort(function (a, b) {
        return new Date(a.eventDate) - new Date(b.eventDate);
      });
      allEvents = allEvents2?.map((event) => {
        const e = {
          ...event,
          eventDate: isNaN(Date.parse(event?.eventDate))
            ? this.currentDate
            : new Date(event?.eventDate),
          deadlineForEntries: isNaN(Date.parse(event?.deadlineForEntries))
            ? this.currentDate
            : new Date(event?.deadlineForEntries),
          edit: false,
        };
        // this.originals.push({ ...e });

        return e;
      });
      this.setState({ allEvents })

      let finalDate = moment(new Date()).subtract(7, "days")._d;
      // console.log("finaldate",new Date(finalDate).toISOString())
      let finalDate1 = new Date(finalDate).toISOString();
      const dummy = res.filter((r) =>
        moment(r.eventDate).isSameOrAfter(finalDate1)
      );
      //  console.log("res",dummy)
      const dummyEvents = Object.assign([], dummy);

      dummyEvents.sort(function (a, b) {
        return new Date(a.eventDate) - new Date(b.eventDate);
      });
      this.originals = [];
      events = dummyEvents?.map((event) => {
        const e = {
          ...event,
          eventDate: isNaN(Date.parse(event?.eventDate))
            ? this.currentDate
            : new Date(event?.eventDate),
          deadlineForEntries: isNaN(Date.parse(event?.deadlineForEntries))
            ? this.currentDate
            : new Date(event?.deadlineForEntries),
          edit: false,
        };
        this.originals.push({ ...e });
        return e;
      });
    });

    Promise.all([eventsPromise])
      .then(() => {
        // events.sort(function(a,b){
        //   return new Date(a.eventDate) - new Date(b.eventDate);
        // });
        this.setState({
          loading: undefined,
          events,
        });
      })
      .catch((err) => {
        this.setState({
          loading: JSON.stringify(err),
        });
      });
  };
  deleteEvent = (event) => {
    HttpService.deleteEvent(event)
      .then((res) => {
        // this.fetchKeys({ fromDate: "", toDate: "" });
        // this.subscription = dateRangeService
        //   .getDateRange()
        //   .subscribe((range) => this.fetchKeys(range));
        let { events, filteredData } = this.state;
        events = events.filter((e) => e._id !== event._id);
        filteredData = filteredData.filter((e) => e._id !== event._id);
        this.originals.splice(0, this.originals.length, ...events);
        events.sort(function (a, b) {
          return new Date(a.eventDate) - new Date(b.eventDate);
        });
        filteredData.sort(function (a, b) {
          return new Date(a.eventDate) - new Date(b.eventDate);
        });
        this.setState({
          events: events.length === filteredData.length ? events : filteredData,
        });
        this.context.updateState({ keyFig: "" });
      })
      .catch((err) => JSON.stringify(err));
  };
  async fetchKeys(range) {
    const { venueId, userRole, loggedInUserId } = this.context;

    try {
      const keyFigures = await HttpService.fetchKeyFigures();
      const userKey = await HttpService.fetchUserKeyFigures(loggedInUserId);
      const promises = [];

      const startDate = range?.fromDate ?? "";
      const endDate = range?.toDate ?? "";

      keyFigures.forEach((key, index) => {
        const selected = userKey?.keys
          ? userKey?.keys?.includes(key.id)
          : index < 4;
        if (selected) {
          promises.push(
            HttpService.fetchKeyFigure({
              keyFigId: key.id,
              venueId: venueId,
              userRole: userRole,
              fromDate: startDate,
              toDate: endDate,
            }).then((value) => {
              keyFigures[index] = { ...key, selected, value };
            })
          );
        } else {
          keyFigures[index] = { ...key, selected, value: 0 };
        }
      });

      // Promise.all(promises)
      //   .then((res) => {
      //     localStorage.setItem("KeyFig", JSON.stringify(keyFigures));
      //     this.setState({
      //       keyFigures,
      //       // userKey,
      //       // fromDate : startDate,
      //       // toDate : endDate
      //     });
      //     // window.location.reload()
      //     this.context.updateState({keyFig:keyFigures})
      //   })
      //   .catch((err) => {
      //     this.setState({ loading: JSON.stringify(err) });
      //   });
    } catch (error) {
      this.setState({
        loading: JSON.stringify(error),
      });
    }
  }
  FilteredData = (data) => {
    //  console.log("data",data)
    this.setState({
      filteredData: data,
    });
  };
  saveEvent = (event, index) => {
    // console.log("in save",event,index)
    const { edit, ...body } = event;

    // const { allEvents } = this.state;
    // this.setState({ allEvents: [...allEvents,event] });

    const {
      _id,
      venueId,
      eventName,
      eventDate,
      parentQuestionnaireId,
      contactPersonId,
      deadlineForEntries,
      vvoId,
      type,
    } = body;

    if (!venueId) return swal(`${this.props.t('Wählen Sie einen Veranstaltungsort')}`, "", "info");
    if (!eventName)
      return swal(`${this.props.t('Geben Sie den Namen des Events ein')}`, "", "info");
    if (!parentQuestionnaireId)
      return swal(`${this.props.t('Wählen Sie ein Fragebogen aus')}`, "", "info");
    if (!contactPersonId)
      return swal(`${this.props.t('Wählen Sie eine Kontaktperson')}`, "", "info");

    const deadlineDate = new Date(deadlineForEntries);
    const eDate = new Date(eventDate);
    if (!eDate) return swal(`${this.props.t('Wählen Sie ein gültiges Eventdatum')}`, "", "info");
    if (!deadlineDate)
      return swal(
        `${this.props.t('Wählen Sie ein gültiges Datum als Eingabeschluss')}`,
        "",
        "info"
      );
    // if (moment.duration(moment(eDate).diff(moment(deadlineDate))).asDays() < 14)
    //   return swal(
    //     `Der Eingabeschluss sollte zwei Wochen vor dem Event liegen`,
    //     "",
    //     "info"
    //   );
    // console.log("edate",eDate,"deadlne",deadlineDate)
    const date1 = new Date(eDate).toISOString();
    const date2 = new Date(deadlineDate).toISOString();
    // console.log("date before",date1,"after",Date.parse(date1))
    const convertedEventDate = moment(eventDate).utcOffset(0, true).format();
    body.convertedEventDate = convertedEventDate
    const convertedDeadlineForEntries = moment(deadlineForEntries).utcOffset(0, true).format();
    body.convertedDeadlineForEntries = convertedDeadlineForEntries
    if (Date.parse(date1) < Date.parse(date2))
      return swal(
        `${this.props.t('Stichtag kann nicht nach dem Veranstaltungsdatum festgelegt werden')}`,
        "",
        "info"
      );
    if (!vvoId) return swal(`${this.props.t('Wählen Sie einen Ansprechspartner')}`, "", "info");
    if (!type) return swal(`${this.props.t('Wählen Sie die Veranstaltungsart')}`, "", "info");

    let newIndex;

    this.originals.forEach((event) => {
      // console.log("events",event)
      if (event._id === this.state.filteredData[index]._id) {
        newIndex = this.originals.indexOf(event);
      }
    });

    let bool1;
    let bool2;
    if (this.state.filteredData.length === this.state.allEvents.length) {
      // console.log("event", this.state.events[index]);
      // console.log("original", this.originals[index]);
      if (
        this.state.events[index]?.parentQuestionnaireName !==
        this.originals[index]?.parentQuestionnaireName
      ) {
        bool1 = true;
      } else {
        bool1 = false;
      }
    } else {
      // console.log("event", this.state.events[index]);
      // console.log("original", this.originals[index]);
      // console.log("original on new index", this.originals[newIndex]);
      if (
        this.state.events[index]?.parentQuestionnaireName !==
        this.originals[newIndex]?.parentQuestionnaireName
      ) {
        bool2 = true;
      } else {
        bool2 = false;
      }
    }

    if (_id && (bool1 === true || bool2 === true)) {
      swal({
        title: `${this.props.t('Bist du sicher')}`,
        text: `${this.props.t('Sie möchten den zugewiesenen Fragebogen ändern?')}`,
        buttons: [`${this.props.t('Abbrechen')}`, "OK"],
        // buttons: true,
        dangerMode: true,
      })
        .then((isConfirm) => {
          if (!isConfirm) {
            return;
          } else {
            body.deadlineForEntries = moment(body.deadlineForEntries).format('YYYY-MM-DDTHH:mm:ss[Z]');
            let promise = _id
              ? HttpService.updateEvent(body)
              : HttpService.createEvent(body);
            promise
              .then((res) => {
                //this.refreshEvents();
                const { events, filteredData } = this.state;
                // console.log("events",events,"filtered".filteredData)
                if (filteredData.length === this.state.allEvents.length) {
                  let e = {
                    ...events[index],
                    edit: false,
                    _id: _id ?? res?.eventId,
                    childQuestionnaireId: res?.childQuestionnaireId,
                  };
                  // this.originalData.forEach((data)=>{
                  //   if(data._id==filteredData[index]._id){
                  //     console.log(data)
                  //   }
                  // })
                  events[index] = e;
                  this.originals[index] = { ...e };

                  this.setState({ events });
                } else {
                  let newIndex;

                  this.originals.forEach((event) => {
                    //console.log("events",event)
                    if (event._id === filteredData[index]._id) {
                      newIndex = this.originals.indexOf(event);
                    }
                  });

                  let e = {
                    ...filteredData[index],
                    edit: false,
                    _id: _id ?? res?.eventId,
                    childQuestionnaireId: res?.childQuestionnaireId,
                  };
                  // this.originalData.forEach((data)=>{
                  //   if(data._id==filteredData[index]._id){
                  //     console.log(data)
                  //   }
                  // })
                  filteredData[index] = e;
                  this.originals[newIndex] = { ...e };
                  filteredData.sort(function (a, b) {
                    return new Date(a.eventDate) - new Date(b.eventDate);
                  });
                  this.setState({ events: filteredData });
                }
              })
              .catch((err) => {
                swal(JSON.stringify(err), "", "error");
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      body.deadlineForEntries = moment(body.deadlineForEntries).format('YYYY-MM-DDTHH:mm:ss[Z]');
      let promise = _id
        ? HttpService.updateEvent(body)
        : HttpService.createEvent(body);
      promise
        .then((res) => {
          // console.log("in else")
          const { events, filteredData } = this.state;
          this.refreshEvents();
          // console.log("event",events,"filtereddata",filteredData,"allEvents",this.state.allEvents)
          if (filteredData.length < this.state.allEvents.length) {
            this.refreshEvents();
            // console.log("in if")
            let e = {
              ...events[index],
              edit: false,
              _id: _id ?? res?.eventId,
              childQuestionnaireId: res?.childQuestionnaireId,
            };
            // let e1 = {
            //   ...allEvents[index],
            //   edit: false,
            //   _id: _id ?? res?.eventId,
            //   childQuestionnaireId: res?.childQuestionnaireId,
            // };
            // allEvents[index] = e1;
            events[index] = e;

            this.originals[index] = { ...e };
            events.sort(function (a, b) {
              return new Date(a.eventDate) - new Date(b.eventDate);
            });
            //this.setState({ events});
            //this.setState({ events,allEvents },()=>{console.log("all events state",this.state.allEvents)});
          } else {
            let newIndex;

            this.originals.forEach((event) => {
              //console.log("events",event)
              if (event._id === filteredData[index]._id) {
                newIndex = this.originals.indexOf(event);
              }
            });
            let e = {
              ...filteredData[index],
              edit: false,
              _id: _id ?? res?.eventId,
              childQuestionnaireId: res?.childQuestionnaireId,
            };
            // let e1 = {
            //   ...this.state.allEvents[index],
            //   edit: false,
            //   _id: _id ?? res?.eventId,
            //   childQuestionnaireId: res?.childQuestionnaireId,
            // };
            // this.state.allEvents[index] = e1;

            filteredData[index] = e;


            this.originals[newIndex] = { ...e };
            filteredData.sort(function (a, b) {
              return new Date(a.eventDate) - new Date(b.eventDate);
            });
            this.setState({ events: filteredData });
            //  console.log("events last",events)
          }
        })
        .catch((err) => {
          swal(JSON.stringify(err), "", "error");
        });
    }
  };

  buttons = [
    {
      id: 1,
      name: "edit",
      icon: editIcon,
      tooltipText: "Bearbeiten",
      onPress: (event, index) => {
        //         let newIndex;
        const { events, filteredData } = this.state;
        // console.log("filtered",filteredData,"allevents",this.state.allEvents)
        if (filteredData.length === this.state.allEvents.length) {
          events[index].edit = true;
          events.sort(function (a, b) {
            return new Date(a.eventDate) - new Date(b.eventDate);
          });
          this.setState({ events });
        } else {
          // console.log("in else",filteredData)
          
          // filteredData.sort(function (a, b) {
          //   return new Date(a.eventDate) - new Date(b.eventDate);
          // });
          this.sortEvents(filteredData)
          this.originals.forEach((event) => {
            // console.log("events",event)
            if (event._id === this.state.filteredData[index]?._id) {
              this.originals.indexOf(event);
            }
          });

          filteredData[index].edit = true;
          // this.originals[newIndex] = { ...filteredData[index] };

          this.setState({ events: filteredData });
        }
      },
      isVisible: (event) => {
        new Date(event?.eventDate);
        new Date(event?.deadlineForEntries);
        return (
          (!event.edit &&
            // (eventDate?.getTime() > this.currentDate.getTime() ||
            //   eventDate.toDateString() === this.currentDate.toDateString()) &&
            [Roles.VenueAdmin, Roles.VenueManager].includes(
              this.context.userRole
            )) ??
          false
        );
      },
    },
    {
      id: 2,
      name: "event-plan",
      icon: fileCheckedIcon,
      tooltipText: "Organisationsplan",
      onPress: (event) => {
        // console.log("fileteed",this.state.filteredData)

        sessionStorage.setItem("eventId", JSON.stringify(event._id));
        // this.refreshEvents()
        HttpService.fetchQuestionnairePlans(event.parentQuestionnaireId)

          .then((plans) => {
            if (this.state.filteredData.length !== this.state.allEvents.length) {
              this.sortEvents(this.state.filteredData)
            }
            else {
              this.sortEvents(this.state.events)
            }
            this.setState({
              plans,
              collapsed: false,
              dummyParentQuestionnaireId: event.parentQuestionnaireId,
              dummyChildQuestionnaireId: event.childQuestionnaireId,
              // event,
              events:
                this.state.filteredData.length !== this.state.allEvents.length
                  ? this.state.filteredData
                  : this.state.events,
            });
          })
          .catch((err) => {
            swal(JSON.stringify(err), "", "error");
          });
      },

      isVisible: (event) => !event.edit ?? false,
    },
    {
      id: 3,
      name: "save",
      icon: saveIcon,
      tooltipText:"Speichern",
      onPress: (event, index) => {
        this.saveEvent(event, index);
      },
      isVisible: (event) => event.edit ?? false,
    },
    {
      id: 4,
      name: "event",
      icon: fileIcon,
      tooltipText: "Kundenbereich",
      onPress: (event) => {
        const itemObject = {
          eventId: event._id,
          eventName: event.eventName,
          eventDate: event.eventDate,
          eventType: event.type,
          deadlineForEntries: event.deadlineForEntries,
          newDate: new Date().toISOString(),
          questionnaireId: event.childQuestionnaireId,
          parentQuestionnaireId: event.parentQuestionnaireId,
          eventsRoute: "eventPage"
        };
        sessionStorage.setItem("state2", JSON.stringify(itemObject));
        // const obj=JSON.parse(localStorage.getItem("state"))
        // console.log("obj",obj)
        // this.context.updateState({
        //   eventId: event._id,
        //   eventName: event.eventName,
        //   eventDate: event.eventDate,
        //   eventType: event.type,
        //   deadlineForEntries: event.deadlineForEntries,
        //   newDate: new Date().toISOString(),
        //   questionnaireId: event.childQuestionnaireId,
        //   parentQuestionnaireId: event.parentQuestionnaireId,
        // });

        // avoids refeshing of the page
        // this.forceUpdate(false);

        // return null;

        window.open(
          `${window.location.origin}/EventLanding`,

          "_blank"
        );

        // this.props.history.push("/EventLanding",{ from: "eventsPage" });
      },
      isVisible: (event) => {
        let deadLineDate = new Date(event?.deadlineForEntries);

        return (
          ([Roles.VVO, Roles.VenueManager, Roles.VenueAdmin].includes(
            this.context.userRole
          )
            ? !event?.edit &&
            [Roles.VenueManager, Roles.VVO, Roles.VenueAdmin].includes(
              this.context.userRole
            )
            : !event?.edit &&
            deadLineDate?.getTime() < this.currentDate?.getTime() &&
            [Roles.VenueAdmin].includes(this.context.userRole)) ?? false
        );
      },
    },
    // {
    //   id: 5,
    //   name: "invoice",
    //   icon: invoice,
    //   tooltipText: "Rechnung",
    //   onPress: (event) => {
    //     console.log("events", event)
    //     const itemObject = {
    //       eventId: event._id,
    //       priceType: event.priceType ? event.priceType : "Netto",
    //       eventName: event.eventName,
    //       eventDate: event.eventDate,
    //       eventType: event.type,
    //       deadlineForEntries: event.deadlineForEntries,
    //       newDate: new Date().toISOString(),
    //       questionnaireId: event.childQuestionnaireId,
    //       parentQuestionnaireId: event.parentQuestionnaireId,
    //     };
    //     sessionStorage.setItem("state2", JSON.stringify(itemObject));


    //     window.open(
    //       `${window.location.origin}/invoice`,

    //       "_blank"
    //     );

    //   },
    //   isVisible: (event) => {
    //     let deadLineDate = new Date(event?.deadlineForEntries);

    //     return (
    //       ([Roles.VenueManager, Roles.VenueAdmin].includes(
    //         this.context.userRole
    //       )
    //         ? !event?.edit &&
    //         [Roles.VenueManager, Roles.VenueAdmin].includes(
    //           this.context.userRole
    //         )
    //         : !event?.edit &&
    //         deadLineDate?.getTime() < this.currentDate?.getTime() &&
    //         [Roles.VenueAdmin].includes(this.context.userRole)) ?? false
    //     );
    //   },
      
    // },
    // {
    //   id: 5,
    //   name: "site",
    //   icon: couple,
    //   tooltipText: "Site",
    //   onPress: (event) => {
    //     // console.log("events",event)
    //     const itemObject = {
    //       eventId: event._id,
    //       eventName: event.eventName,
    //       eventDate: event.eventDate,
    //       eventType: event.type,
    //       deadlineForEntries: event.deadlineForEntries,
    //       newDate: new Date().toISOString(),
    //       questionnaireId: event.childQuestionnaireId,
    //       parentQuestionnaireId: event.parentQuestionnaireId,
    //     };
    //     sessionStorage.setItem("state2", JSON.stringify(itemObject));


    //     window.open(
    //       `${window.location.origin}/planner-screen`,

    //       "_blank"
    //     );

    //   },
    //   isVisible: (event) => {
    //     let deadLineDate = new Date(event?.deadlineForEntries);

    //     return (
    //       ([ Roles.VenueManager, Roles.VenueAdmin].includes(
    //         this.context.userRole
    //       )
    //         ? !event?.edit &&
    //           [Roles.VenueManager,  Roles.VenueAdmin].includes(
    //             this.context.userRole
    //           )
    //         : !event?.edit &&
    //           deadLineDate?.getTime() < this.currentDate?.getTime() &&
    //           [Roles.VenueAdmin].includes(this.context.userRole)) ?? false
    //     );
    //   },
    // },
    {
      id: 5,
      name: "cancel",
      icon: cancelIcon,
      tooltipText: "Abbrechen",
      onPress: (event, index) => {
        const { events, filteredData, allEvents } = this.state;
        if (event._id) {
          if (filteredData.length === this.state.allEvents.length) {
            let finalDate = moment(new Date()).subtract(7, "days")._d;
            let finalDate1 = new Date(finalDate).toISOString();
            const dummy = events.filter((r) =>
              moment(r.eventDate).isSameOrAfter(finalDate1)
            );

            const dummyEvents = Object.assign([], dummy);
            dummyEvents.sort(function (a, b) {
              return new Date(a.eventDate) - new Date(b.eventDate);
            });
            // dummyEvents[index].edit = false;

            // allEvents[index].edit=false
            // console.log("dummyEvents[index].edit",dummyEvents[index].edit)

            dummyEvents.forEach((dummy) => {
              if (dummy._id === event._id) {
                dummy.edit = false;
                this.originals.forEach((o) => {
                  if (o._id === dummy._id) {
                    o.edit = false;
                    dummy = { ...o };
                  }
                });
              }
            });
            allEvents.forEach((eve) => {
              if (eve._id === event._id) {
                eve.edit = false;
              }
            });
            this.setState({ events: dummyEvents });
          } else {
            // console.log("in else")
        

            this.originals.forEach((event) => {
              // console.log("events",event)
              if (event._id === filteredData[index]._id) {
                this.originals.indexOf(event);
              }
            });
            let finalDate = moment(new Date()).subtract(7, "days")._d;
            let finalDate1 = new Date(finalDate).toISOString();
            const dummy = filteredData.filter((r) =>
              moment(r.eventDate).isSameOrAfter(finalDate1)
            );

            const dummyEvents = Object.assign([], dummy);
            dummyEvents.sort(function (a, b) {
              return new Date(a.eventDate) - new Date(b.eventDate);
            });
            dummyEvents.forEach((dummy) => {
              if (dummy._id === event._id) {
                dummy.edit = false;
                // console.log("dummy",dummy)
                this.originals.forEach((o) => {
                  o.edit = false;
                  if (o._id === dummy._id) {
                    dummy = { ...o };
                    // console.log("dummy",dummy)
                  }
                });
              }
            });
            allEvents.forEach((eve) => {
              if (eve._id === event._id) {
                eve.edit = false;
              }
            });
          }
        } else {
          events.shift();
        }
        
        this.setState({ events });
        window.location.reload();
      },
      isVisible: (event) => event.edit ?? false,
    },
    {
      id: 6,
      name: "link",
      icon: linkIcon,
      tooltipText: "Event bearbeiten",
      onPress: (event) => {
        
        const itemObject = {
          questionnaireId: event.childQuestionnaireId,
          newDate: new Date().toISOString(),
          userId: event.contactPersonId,
          eventId: event._id,
          eventName: event.eventName,
          eventDate: event.eventDate,
          eventType: event.type,
          deadlineForEntries: event.deadlineForEntries,
          eventCreationDate: event.eventCreationDate,
          parentQuestionnaireId: event.parentQuestionnaireId,
          questionnaireName: event.parentQuestionnaireName,
          priceType: event.priceType ? event.priceType : "Netto",
          eventsRoute: "eventPage"
        };
        sessionStorage.setItem("state2", JSON.stringify(itemObject));
        // console.log("loc",window.location.origin)
        // const obj=JSON.parse(sessionStorage.getItem("state"))
        // console.log("obj",obj)
        window.open(
          `${window.location.origin}/guests`,

          "_blank"
        );

        // this.props.history.push("/eventsWelcome", { from: "eventsPage" });
      },
      isVisible: (event) => {
        new Date(event?.eventDate);
        new Date(event?.deadlineForEntries);
        return (
          (!event.edit &&
            // (eventDate?.getTime() > this.currentDate?.getTime() ||
            //   eventDate.toDateString() === this.currentDate.toDateString()) &&
            ![Roles.VVO].includes(this.context.userRole)) ??
          false
        );
      },
    },
    // {
    //   id: 8,
    //   name: "chagelog",
    //   icon: changelogIcon,
    //   tooltipText: "Änderungsprotokoll",
    //   onPress: (event) => {
    //     const itemObject = {
    //       questionnaireId: event.childQuestionnaireId,
    //       newDate: new Date().toISOString(),
    //       userId: event.contactPersonId,
    //       eventId: event._id,
    //       eventName: event.eventName,
    //       eventDate: event.eventDate,
    //       eventType: event.type,
    //       deadlineForEntries: event.deadlineForEntries,
    //       eventCreationDate: event.eventCreationDate,
    //       parentQuestionnaireId: event.parentQuestionnaireId,
    //       questionnaireName: event.parentQuestionnaireName,
    //     };
    //     sessionStorage.setItem("state2", JSON.stringify(itemObject));
    //     // console.log("loc",window.location.origin)
    //     // const obj=JSON.parse(sessionStorage.getItem("state"))
    //     // console.log("obj",obj)
    //     window.open(
    //       `${window.location.origin}/changeLog`,

    //       "_blank"
    //     );

    //     // this.props.history.push("/eventsWelcome", { from: "eventsPage" });
    //   },
    //   isVisible: (event) => {
    //     let eventDate = new Date(event?.eventDate);
    //     let deadLineDate = new Date(event?.deadlineForEntries);
    //     return (
    //       (!event.edit &&
    //         // (eventDate?.getTime() > this.currentDate?.getTime() ||
    //         //   eventDate.toDateString() === this.currentDate.toDateString()) &&
    //         ![Roles.VVO].includes(this.context.userRole)) ??
    //       false
    //     );
    //   },
    // },
    {
      id: 7,
      name: "delete",
      icon: deleteIcon,
      tooltipText: "Löschen",
      onPress: (event) => {
        swal({
          title: `${this.props.t('Bist du sicher')}`,
          text:
            `${this.props.t('Nach dem Löschen können Sie dieses Ereignis nicht wiederherstellen!')}`,
          buttons: [`${this.props.t('Abbrechen')}`, "OK"],
          // buttons: true,
          dangerMode: true,
        })
          .then((isConfirmed) => {
            if (isConfirmed) {
              this.deleteEvent(event);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      isVisible: (event) =>
        (!event.edit && this.context.userRole !== "VV") ?? false,
    },
  ];

  addRow = () => {
    const events = [
      {
        venueId: this.context.venueId,
        eventName: "",
        eventDate: null,
        parentQuestionnaireId: "",
        parentQuestionnaireName: "",
        childQuestionnaireId: "",
        contactPersonId: "",
        contactPersonName: "",
        deadlineForEntries: null,
        vvoId: "",
        vvoName: "",
        type: "",
        edit: true,
        eventCreationDate: new Date(),
        convertedEventDate: new Date(),
        convertedDeadlineForEntries: new Date(),
      },
      ...this.state.events,
    ];
    // events.sort(function(a,b){
    //   return new Date(b.eventDate) - new Date(a.eventDate);
    // });

    this.setState({ events });
  };

  handleRedirectToPlanPreview = (plan) => {
    // console.log("plan",plan)
    const eventId = JSON.parse(sessionStorage.getItem("eventId"));
    // console.log("eventid",eventId)
    if (plan?.exportPlanId) {
      const itemObject1 = {
        eventName: this.state.event?.eventName,
        exportPlanId: plan.exportPlanId,
        parentQuestionnaireId: this.state.dummyParentQuestionnaireId,
        childQuestionnaireId: this.state.dummyChildQuestionnaireId,
        planId: plan.planId,
        eventId: eventId,
      };
      sessionStorage.setItem("state1", JSON.stringify(itemObject1));

      // this.context.updateState({
      //   eventName: this.state.event?.eventName,
      //   exportPlanId: plan.exportPlanId,
      //   parentQuestionnaireId: this.state.dummyParentQuestionnaireId,
      //   childQuestionnaireId: this.state.dummyChildQuestionnaireId,
      // });
    } else if (plan?.questionnaireId) {
      const itemObject2 = {
        exportPlanId: null,
        parentQuestionnaireId: this.state.dummyParentQuestionnaireId,
        childQuestionnaireId: this.state.dummyChildQuestionnaireId,
        planId: plan.planId,
        eventId: eventId,
      };
      sessionStorage.setItem("state1", JSON.stringify(itemObject2));
      // this.context.updateState({
      //   exportPlanId: null,
      //   parentQuestionnaireId: this.state.dummyParentQuestionnaireId,
      //   childQuestionnaireId: this.state.dummyChildQuestionnaireId,
      // });
    } else {
      const itemObject3 = {
        exportPlanId: null,
        questionnaireId: null,
        planId: null,
        eventId: null,
      };
      sessionStorage.setItem("state1", JSON.stringify(itemObject3));
      // this.context.updateState({
      //   exportPlanId: null,
      //   questionnaireId: null,
      // });
    }
    if (plan.planName) {
      // this.props.history.push("/planPreview",{ from: "eventsPage" });
      window.open(
        `${window.location.origin}/planPreview`,

        "_blank"
      );
    } else {
      swal(`${this.props.t('Vorschau der Planseite')}`, "", "info");
    }
  };

  handleRedirectToInvoice = (_id) => {
    console.log("id", _id);
    const itemObject = {
      templateId: _id,
    };
    sessionStorage.setItem("state3", JSON.stringify(itemObject));
    window.open(
      `${window.location.origin}/invoice`,
      "_blank"
    );
  }

  render() {
    const { loading, events, collapsed,templateCollapsed, plans, allEvents ,templates} = this.state;
    const canCreateUser = [Roles.VenueAdmin, Roles.VenueManager].includes(
      this.context.userRole
    );

    // events.sort(function(a,b){
    //   return new Date(b.eventDate) - new Date(a.eventDate);
    // });
    if (loading) {
      return (
        <div style={styles.row}>
          <div className="col-1" />
          <div className="col-10">
            <Text value={this.props.t('Loading')} />
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div style={styles.row}>
          <div className="col-1"></div>
          <div className="col-10">
            <div
              style={{
                // color: "rgb(131, 132, 135)",
                fontSize: "24px",
                // margin: "20px 105px 20px 105px",
                marginTop: "20px",
                color: "#524f4f",
              }}
            >
              {this.props.t('Event')}
            </div>
            <div style={styles.divider} />
          </div>
        </div>
        <div style={styles.row}>
          <div className="col-11" style={styles.addWrapper}>
            {canCreateUser && (
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-disabled`}>
                    <span> {this.props.t('Create_event')}</span>
                  </Tooltip>
                }
              >
                <img
                  style={styles.pointer}
                  src={plusIcon}
                  alt=""
                  onClick={this.addRow}
                />
              </OverlayTrigger>
            )}
          </div>
        </div>

        <div style={styles.row}>
          <div className="col-1" />
          <div className="col-10">
            <>
              {/* {console.log("allEvents",allEvents)} */}
              {this.context.userRole !== "SA" && (
                <TableSection
                  headers={headers}
                  data={events}
                  allEvents={allEvents}
                  buttons={this.buttons}
                  buttonCount={4}
                  FilteredData={this.FilteredData}
                  originalData={this.originals}
                  checkIfHeaderEmpty={this.checkIfHeaderEmpty}
                />
              )}
              {/* <TableSection headers={headers} data={events} buttons={this.buttons} buttonCount={4} /> */}
            </>
          </div>
        </div>

        <Slider
          collapsed={collapsed}
          onChange={(collapsed) => this.setState({ collapsed })}
        >
          <div style={styles.slider}>
            {(!plans || plans.length === 0) && <Text value={this.props.t("Keine_Pläne")} />}
            {plans.map((plan, index) => (
              <div key={index} style={styles.plan}>
                <Text style={styles.text} value={plan?.planName} />

                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => this.handleRedirectToPlanPreview(plan)}
                >
                  <img
                    src={linkIcon}
                    alt={"Icon"}
                    style={styles.icon}
                  />
                </a>
              </div>
            ))}
          </div>
        </Slider>
        <Slider
          collapsed={templateCollapsed}
          onChange={(templateCollapsed) => this.setState({ templateCollapsed })}
        >
          <div style={styles.slider}>
            <h1>{this.props.t('Template')}</h1>
            {(!templates || templates.length === 0) && <Text value={this.props.t("No templates")} />}
            {templates?.map((template, index) =>
            (
              <div key={index} style={styles.plan}>
                <Text style={styles.text} value={template?.templateName} />

                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={() => this.handleRedirectToInvoice(template?._id)}
                >
                  <img
                    src={linkIcon}
                    alt={"template?.templateName"}
                    style={styles.icon}
                  />
                </a>
              </div>
            )
            )}
          </div>
        </Slider>
      </React.Fragment>
    );
  }
}

export default withTranslation()(EventsPage);

const styles = {
  row: {
    display: "flex",
    flexWrap: "wrap",
  },
  addWrapper: {
    marginLeft: "40px",
  },
  slider: {
    background: "#d0d3d4",
    padding: "40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "100%",
    borderLeft: "0.1px solid #90909030",
    boxShadow: "0 0 15px #90909060"
  },
  plan: {
    maxWidth: "400px",
    display: "flex",
    alignItems: "center",
  },
  text: {
    margin: "10px",
    wordWrap: "break-word",
    fontSize: "16px",
    backgroundColor: "rgba(230, 231, 232, 0.81)",
  },
  icon: {
    width: "20px",
    height: "20px",
    margin: "2px",
  },
  pointer: {
    cursor: "pointer",
  },
  divider: {
    borderTop: "1px solid #d0d3d4",
    margin: "30px 0 0px 0px",
    paddingTop: "10px",
  },
};


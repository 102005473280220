import React, { Component } from "react";
import Site from "./Site";
import { StateContext } from "../../context/state";
import Step from "../partials/Step";

export default class PlannerScreen extends Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.state = {
      dragData: "",
      rooms: [],
      showRoom: false,
      display: "List of Guests",
      steps: [
        { id: 1, name: "List of Guests" },
        { id: 2, name: "configure Plan" },
        { id: 3, name: "Table Planning" },
        { id: 4, name: "Overview" },
      ],
    };
  }

  componentDidMount = async () => {};

  display = (id) => {
    // console.log("id", id);
    if (id === 1) {
      this.setState({ display: "List of Guests" });
    } else if (id === 2) {
    //   console.log("in here");
      this.setState({ display: "configure Plan" });
    } else if (id === 3) {
      this.setState({ display: "Table Planning" });
    } else if (id === 4) {
      this.setState({ display: "Overview" });
    }
  };
  renderContent = () => {
    // console.log(this.state.display);
    switch (this.state.display) {
      case "configure Plan":
        return <Site />;
        // case "List of Guests":
        //   return <GuestSite />;
      default:
        break;
    }
  };
  render() {
    const { steps,display } = this.state;
    return (
     
          <div style={{background: "#f0eff0"}}>
        <div className="stepsContainer">
          {steps?.length ? (
            steps.map((service, index) => {
              return (
                <Step
                  key={index}
                  selected={service.name === display}
                  name={service.name}
                  type={
                    steps.length === 1
                      ? undefined
                      : index === 0
                      ? "start"
                      : index === steps.length - 1
                      ? "end"
                      : "intermediate"
                  }
                  handleDisplay={() => {
                    this.display(service.id);
                  }}
                />
              );
            })
          ) : (
            <></>
          )}
          <div
           className=" align-items-center"
          style={{
            background: "#f0eff0",
            // position: "relative",
          }}>{this.renderContent()}</div>
        </div>
   
      </div>
    );
  }
}
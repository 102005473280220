import React, { Component } from "react";
import { StateContext } from "../../context/state";
import axios from "axios";
import { baseUrl } from "../../Constants.js";
import Moment from "react-moment";
import "moment-timezone";
import Loader from "./Loader";
export default class TableOption extends Component {
  static contextType = StateContext;
  cellWidth = 0;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      rows: [],
      isDisplay:null
    };
    this.cellWidth = `calc(100% / ${this.state.rows?.[0]?.columns?.length})`;
  }

  onChange = (index, _index, e) => {
    //     const {option, onUpdate} = this.props;
    //     option.tableInput.rows[index].columns[_index].value = e.target.value;
    //     onUpdate(option);
  };

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        rows: this.props.data?.tableInput?.rows,
      });
      this.cellWidth = `calc(100% / ${this.props?.data?.tableInput?.rows?.[0]?.columns?.length})`;
    }
    this.setState({loading:true})
    const option = this.props.option;
    axios
      .get(
        `${baseUrl}api/export/ServiceElement/${this.context.childQuestionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`
      )
      .then((res) => {
        const data = res.data;
        this.cellWidth = `calc(100% / ${data.tableInput?.rows?.[0]?.columns?.length})`;
        // console.log("data",data)
        this.setState({
          rows: data?.tableInput?.rows,
          isDisplay:data.isDisplay,
          loading:false
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  renderCell = (data, index, _index) => {
    let time;
    if (data.type === "time" && data.value) {
      // console.log("time",data.value)
      var d = new Date(data.value);

      time = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
    }
    const cell = data.interactive ? (
      <>
        {data.type === "text" ||
        data.type === "numeric" ||
        data.type === "time" ? (
          <div
            style={{ display: "block",wordWrap: "anywhere" }}
            // style={styles.input}
            // type={data.type === "numeric" ? "number" : data.type === "date" ? "date" : "text"}

            onChange={this.onChange}
          >
            {data.type === "time" ? time : data.value}
          </div>
        ) : (
          data.value ? <Moment format="DD-MM-YYYY">{data.value}</Moment>:<div
          style={{ display: "block" ,wordWrap: "anywhere"}}
          onChange={this.onChange}
        >
          {data.value}
        </div>
        )}
      </>
    ) : data.value ? (
      <>
        <div style={styles.label}>{data.value}</div>
      </>
    ) : (
      <div style={styles.label}>{data.type}</div>
    );

    const cellStyle = {
      ...styles.cell,
      ...(index === 0 && styles.header),
      width: this.cellWidth,
    };
    return (
      <div key={_index} style={cellStyle}>
        {cell}
      </div>
    );
  };

  render() {
    let { rows } = this.state;
    return (
      <>
         {this.state.loading?
        <Loader/>:<>
      {this.state.isDisplay!=="false" ? 
      <div style={styles.container}>
        {rows &&
          rows.map((item, index) => (
            <div style={styles.row} key={index}>
              {/* {console.log('hi', item)} */}
              {item.columns.map((_item, _index) =>
                this.renderCell(_item, index, _index)
              )}
            </div>
          ))}
      </div>:<></>}
    </>}
      </>
    );
  }
}

const styles = {
  container: {
    width: "100%",
    borderLeft: "1px solid #939598",
    borderTop: "1px solid #939598",
    marginBottom: "15px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    borderRight: "1px solid #939598",
    borderBottom: "1px solid #939598",
    display: "flex",
    flexDirection: "row",
    textAlign:"center",
    justifyContent: "center",
    alignItems: "center",
    // height: "30px",
    background: "transparent",
  },
  header: {
    // height: "50px",
    background: "#e6e8ea",
  },
  label: {
    color: "#b1b1b1",
    fontFamily: "Crete Round",
    display: "block",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    wordWrap: "anywhere"
  },
  input: {
    width: "100%",
    background: "transparent",
    border: "0",
    textAlign: "center",
    color: "#3f4041",
    fontFamily: "Crete Round",
  },
};

import React, { Component } from 'react';
import axios from 'axios'
import {baseUrl} from '../../Constants.js'
import {StateContext} from "../../context/state";
import Loader from "./Loader";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class TextOption extends Component {
    static contextType = StateContext;
    constructor(props){
        super(props)
        this.state={
            loading:false,
            newData:{},
            childData:{}
        }
    }
    componentDidMount(){
        if(this.props.data){
            this.setState({
            newData:this.props.data
        })
    }
            this.setState({loading:true})
            const option= this.props.option
            axios.get(`${baseUrl}api/export/ServiceElement/${this.context.childQuestionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`)
           .then((res)=>{
//             if(option.serviceId=="806568e6-b6f7-44a4-acc4-9d6f2304abfe"){
// console.log("res",res.data)
//             }
               const d=res?.data;
               const dd = d?.textInput?.inputs?.map((input,index) => {
                // input.alternate = "";
                input.alternate = input.alternate? input.alternate:this.props.data?.textInput?.inputs[index].alternate
             
                return { ...input };
              });
              if(d.textInput){
                d.textInput.inputs = dd;
              }

            //    this.setState({
            //        childData:d
            //    })

               this.setState({
                data: d,
                childData: { ...this.state.newData, ...d },
                loading:false
              });
           })
           .catch((err)=>{
               console.log(err)
           })
       
    }
 
    render() {
 
        return (
            <>
              {this.state.loading?
        <Loader/>:<>
            <div  >  
                {this.state.childData.isDisplay!=="false"  ?  <>
                       
            {this.state.childData?.textInput?.inputs?.map((input, i)=>{
              
                // var x= draftToHtml(convertToRaw(EditorState.createWithContent(convertFromRaw(
                //              input?.value
                //                )).getCurrentContent()))
                return <div className="qtn_optns" key={i} >
                    {/* {console.log("input",input)}                   */}
                <div 
                // className="quest1"
                >

                    {  this.state.childData?.textInput?.inputs?.map((inp, i)=>{
                        //    if(this.props.option.serviceId=="806568e6-b6f7-44a4-acc4-9d6f2304abfe"){
                        //     console.log("res",inp)
                        //     <>            }
                         return  (input?.answer?.value ||input?.answer?.quantity) &&
               <div style={{marginBottom:(input?.answer?.value ||input?.answer?.quantity)?"10px":"",display:"flex"}}>
                    
                         { inp?.alternate ? 
                            <div>                     
                                <b>{inp?.alternate}</b> 
                             </div>
                             :
                             <div><b>{inp?.interactiveText}</b></div>
                             }
                             :
                             <div style={{paddingLeft:"5px"}} >   
                                 {input?.answer?.quantity}
                                 {input?.answer?.value}
                                 {/* <div key={i}>
                                         <span dangerouslySetInnerHTML={{__html:x}}></span>
                                       </div> */}
                             </div>
                    
                           </div>  
                    })
                }
                      
                        </div>
                        </div>
            })}
</>:<></>}
            </div>
            </>}
            </>
        );
    }
}

export default TextOption;
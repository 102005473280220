import React, {Component} from "react";
import {StateContext} from "../../context/state";
import axios from "axios";
import {baseUrl} from '../../Constants.js'

export default class TableOption extends Component {

    static contextType = StateContext;
    cellWidth = 0;

    constructor(props) {
        super(props);

        this.state={
            rows:[]
        };
        this.cellWidth = `calc(100% / ${this.state.rows?.[0]?.columns?.length})`;
    }

    onChange = (index, _index, e) => {
    //     const {option, onUpdate} = this.props;

    //     option.tableInput.rows[index].columns[_index].value = e.target.value;
    //     onUpdate(option);
    };

    componentDidMount(){
        const option= this.props.option
        // console.log('api', `${baseUrl}api/export/ServiceElement/${this.context.questionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`)
        axios.get(`${baseUrl}api/export/ServiceElement/${this.props.questionnaireId}/${option.questionIndex}/${option.rowIndex}/${option.columnIndex}/${option.themeId}/${option.serviceId}`)
        .then((res)=>{
            const data=res.data
            this.setState({
                rows:data.tableInput.rows
            })
            this.cellWidth = `calc(100% / ${data.tableInput?.rows?.[0]?.columns?.length})`;
            this.props.updateChange(data,this.props.divId)
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    renderCell = (data, index, _index) => {
        const cell = data.interactive ?
            <input
                style={styles.input}
                type={data.type === "numeric" ? "number" : data.type === "date" ? "date" : "text"}
                value={data.value}
                onChange={this.onChange}
                autoComplete="off"
            />
            : <span style={styles.label}>{data.type}</span>;

        const cellStyle = {
            ...styles.cell,
            ...(index === 0 && styles.header),
            width: this.cellWidth,
        };
        // console.log('cellwidth', this.cellWidth)
        return <div key={_index} style={cellStyle}>{cell}</div>;
    };

    render() {

        let {rows} = this.state;
        rows = rows || [];

        return (
            <div style={styles.container}>
                {Object.keys(rows).length ? rows.map((item, index) => (
                    
                    <div style={styles.row} key={index}>
                        {/* {console.log('hi', item)} */}
                        {item.columns.map((_item, _index) => (
                            
                            this.renderCell(_item, index, _index)
                        ))}
                    </div>
                ))
            : null}
            </div>
        );
    }
}

const styles = {
    container: {
        width: "100%",
        borderLeft: "1px solid #939598",
        borderTop: "1px solid #939598",
        marginTop:"30px"
    },
    row: {
        display: "flex",
        flexDirection: "row",
    },
    cell: {
        borderRight: "1px solid #939598",
        borderBottom: "1px solid #939598",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "30px",
        background: "transparent",
    },
    header: {
        height: "50px",
        background: "#e6e8ea",
    },
    label: {
        color: "#b1b1b1",
        // fontFamily: "Crete Round",
    },
    input: {
        width: "100%",
        background: "transparent",
        border: "0",
        textAlign: "center",
        color: "#3f4041",
        // fontFamily: "Crete Round",
    }
};
import React, {Component} from "react";
import {StateContext} from "./context/state";
import Login from "./components/Login";

const Authorization = (WrappedComponent, allowedRoles) => {
    return class WithAuthorization extends Component {
       

        render() {
            return (
                <StateContext.Consumer>
                    {({userRole, authenticated}) => {
                        if (!authenticated) {
                            return (
                                <div id="wrapper">
                                    <Login {...this.props} />
                                </div>

                            );
                        } else if (allowedRoles.includes(userRole)) {
                            return <WrappedComponent {...this.props} />;
                        }
                    }}
                </StateContext.Consumer>
            );
        }
    };
};

export default Authorization;

import React, { Component } from "react";
import CreatableSelect from "react-select/creatable";
import { StateContext } from "../../context/state";
import minusIcon from "../../assets/minus.svg";
import plusIcon from "../../assets/plus.svg";
import tagIcon from "../../assets/tag.svg";
import tagInverseIcon from "../../assets/tag-inverse.svg";
// import deleteIcon from "../../assets/delete.svg";
import crossSmallIcon from "../../assets/cross-small.svg";
import axios from "axios";
import { baseUrl } from "../../Constants";
import { v4 as uuidv4 } from "uuid";
import { withTranslation} from 'react-i18next';
import addIcon from "../../assets/add.svg";

class RadioOptionConfiguration extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      priceOptionSelected: false,
      tagOptions: [],
      tagOptionSelected: false,
      TextInput: {},
      selectedTextIndex: null,
      tags: [],
      newEntry: true,
      bruttoChange:undefined,
      guestInfo: {
        adults: false,
        U6: false,
        U12: false,
        U18: false,
      },
      guestOptions: [
        { value: "adults",checked:false },
        { value: "U6" ,checked:false },
        { value: "U12" ,checked:false },
        { value: "U18" ,checked:false },
      ],
      initialGuestOptions: [
        { value: "adults" ,checked:false},
        { value: "U6",checked:false },
        { value: "U12",checked:false },
        { value: "U18",checked:false },
      ],
      VATPercent:[{id:uuidv4(), checked:false,value:"19"},{id:uuidv4(), checked:false,value:"7"}],
      type:""

    };
    
  }
  componentDidMount() {
    axios
      .get(`${baseUrl}api/Questions/Tag/${this.context.venueId}`)
      .then((res) => {
        if (res.data.hasOwnProperty("tags")) {
          this.setState({
            tagOptions: res.data.tags,
            tags: res.data.tags,
            newEntry: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
      // axios
      // .get(
      //   `${baseUrl}api/questionnaireSetting/${this.context.parentQuestionnaireId}`
      // )
      // .then((res) => {
      //   if (Object.keys(res.data).length) {
      //     // console.log(res.data,"res")
      //     this.setState({ type: res?.data?.note?.name });
      //   }
      // });
    
    Object.keys(this.context.guestLabel).forEach((guest) => {
      this.state.guestOptions.forEach((label) => {
        if (label.value === guest) {
          label.value = this.context.guestLabel[guest];
        }
      });
    });
  }
  static getDerivedStateFromProps = (nextProps, prevState) => {
    // console.log('next', nextProps)
    return {
      textInput: nextProps?.option?.textInput,
      selectedTextIndex: nextProps.selectedTextIndex,
    };
  };
  functionValidation = () => {
    const { textInput } = this.state;
    const min = Number(
      textInput.inputs[this.state.selectedTextIndex].numerically.minimumNumber
    );
    const max = Number(
      textInput.inputs[this.state.selectedTextIndex].numerically.maximumNumber
    );

    if (min && max && min > max) {
      const msg = "Der Maximalwert sollte größer als der Minimalwert sein";
      return msg;
    } else {
      return null;
    }
  };
  functionPriceValidation = () => {
    const { textInput } = this.state;

    const min = Number(
      textInput.inputs[this.state.selectedTextIndex].numerically.minimumNumber
    );
    const max = Number(
      textInput.inputs[this.state.selectedTextIndex].numerically.maximumNumber
    );
    const price = Number(textInput.inputs[this.state.selectedTextIndex].price);
    const brutto =Number(textInput.inputs[this.state.selectedTextIndex].brutto)
    const netto =Number(textInput.inputs[this.state.selectedTextIndex].netto)
    //  console.log('min', min, max)
    
    const  grossNetPrice=this.context.priceType==="Netto" ? netto :brutto
    if (min && max && !price && !grossNetPrice) {
      const msg = "Preis eingeben";
      // valid=false
      return msg;
    } else if (min && !price &&  !grossNetPrice) {
      const msg = "Preis eingeben";
      // valid=false
      return msg;
    } else if (max && !price &&  !grossNetPrice) {
      const msg = "Preis eingeben";
      // valid=false
      return msg;
    } else {
      // valid=true
      return null;
    }
  };
  setVAT=(e,per)=>{
    const textInput = this.state.textInput;
    // console.log("per",per)
    textInput.inputs[
      this.state.selectedTextIndex
    ].VATPercent.forEach((perc)=>{
      if(perc.id===per.id){
        perc.value=e.target.value
        // console.log("perc",perc)
      }
    })
   
    this.setState({ textInput });
    this.props.updateTextOptionConfiguration(textInput);

  }
  onNettoChange = (e) => {
    const textInput = this.state.textInput;
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    textInput.inputs[this.state.selectedTextIndex].netto = textValue;
    textInput.inputs[this.state.selectedTextIndex].VATPercent.forEach((vat)=>{
      if(vat.checked===true){
        let percent=`${vat.value}%`
        // console.log("percent",percent)
        var result = parseFloat(percent) / 100.0;
        // var value=e.target.value *( result+1)
        var value =(Number(textValue * ( result+1))).toFixed(2)

        // console.log("result",value)
        textInput.inputs[this.state.selectedTextIndex].brutto = value;
        if (this.context.priceType === "Netto") {
        textInput.inputs[this.state.selectedTextIndex].price = textValue;
        }
        else{
          textInput.inputs[this.state.selectedTextIndex].price = value;
        }  
        this.setState({ textInput });
        this.props.updateTextOptionConfiguration(textInput);
      }
      else{
        this.setState({ textInput });
        this.props.updateTextOptionConfiguration(textInput);
      }
    })
  };
 
  convertedNettoValues = () => {
    const textInput = this.state.textInput;
    if(this.context.languageFormat!=="en" && textInput.inputs[this.state.selectedTextIndex].netto)
    {
      let inputValue = textInput.inputs[this.state.selectedTextIndex].netto
    return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && textInput.inputs[this.state.selectedTextIndex].netto)
    {
      return textInput.inputs[this.state.selectedTextIndex].netto
    }
    else{
      return ""
    }

  }
  convertedBruttoValues = () => {
    const textInput = this.state.textInput;
    if(this.context.languageFormat!=="en" && textInput.inputs[this.state.selectedTextIndex].brutto)
    {
      let inputValue = textInput.inputs[this.state.selectedTextIndex].brutto
 
      return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && textInput.inputs[this.state.selectedTextIndex].brutto)
    {
      return textInput.inputs[this.state.selectedTextIndex].brutto
    }
    else{
      return ""
    }

  }
  onBruttoChange = (e) => {
    const textInput = this.state.textInput;
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    textInput.inputs[this.state.selectedTextIndex].brutto = textValue;
    textInput.inputs[this.state.selectedTextIndex].VATPercent.forEach((vat)=>{
      if(vat.checked===true){
        let percent=`${vat.value}%`
        // console.log("percent",percent)
        var result = parseFloat(percent) / 100.0;
        var value=Number(textValue /( result+1)).toFixed(2)
        // console.log("result",value)
        textInput.inputs[this.state.selectedTextIndex].netto = value;
        if (this.context.priceType === "Brutto") {
          textInput.inputs[this.state.selectedTextIndex].price = textValue;
          }
          else{
            textInput.inputs[this.state.selectedTextIndex].price = value;
          }  
        this.setState({ textInput });
        this.props.updateTextOptionConfiguration(textInput);
      }
      else{
        this.setState({ textInput });
        this.props.updateTextOptionConfiguration(textInput);
      }
    })
    // this.setState({ textInput });
    // this.props.updateTextOptionConfiguration(textInput);
  };
  onEnterHitAtNetto=(e)=>{
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].VATPercent.forEach((vat)=>{
      if(vat.checked===true){
        let percent=`${vat.value}%`
        // console.log("percent",percent)
        var result = parseFloat(percent) / 100.0;
        // var value=e.target.value *( result+1)
        var value =(Number(e.target.value * ( result+1))).toFixed(2)

        // console.log("result",value)
        textInput.inputs[this.state.selectedTextIndex].brutto = value;
        if (this.context.priceType === "Netto") {
          textInput.inputs[this.state.selectedTextIndex].price = e.target.value;
          }
          else{
            textInput.inputs[this.state.selectedTextIndex].price = value;
          }  
        this.setState({ textInput });
        this.props.updateTextOptionConfiguration(textInput);
      }
    })
  }
  setCheckedPercentage=(per)=>{
    const textInput = this.state.textInput;
    // console.log("per",per)
   textInput.inputs[
      this.state.selectedTextIndex
    ].VATPercent.forEach((perc)=>{
      if(perc.id===per.id){
        perc.checked = true
        // perc.checked=!perc.checked
        // console.log("perc",perc)
        if(perc.checked===true){
          // console.log(this.context.priceType,"perc")
          if(this.context.priceType === "Netto"){
           
              // console.log("brutto selected",this.state.bruttoChange)
  if(this.state.bruttoChange===true){
    let percent = `${perc.value}%`;
  
                var result = parseFloat(percent) / 100.0;
  
                var value = Number(
                  textInput.inputs[this.state.selectedTextIndex].brutto /
                    (result + 1)
                ).toFixed(2);
                // console.log("value",value)
                textInput.inputs[this.state.selectedTextIndex].netto = value;
                textInput.inputs[this.state.selectedTextIndex].price = value;
  }
  else{
            if(textInput.inputs[this.state.selectedTextIndex].netto){
              
         
        
              
               textInput.inputs[this.state.selectedTextIndex].brutto = value;
               textInput.inputs[this.state.selectedTextIndex].price = textInput.inputs[this.state.selectedTextIndex].netto;
            }
        //  else{
        //   textInput.inputs[this.state.selectedTextIndex].brutto = 0;
        //   textInput.inputs[this.state.selectedTextIndex].price = 0;
        //  }
          
          }
        }
          else if(this.context.priceType === "Brutto"){
            if(this.state.bruttoChange===false){
            
                          textInput.inputs[this.state.selectedTextIndex].brutto = value;
                          textInput.inputs[this.state.selectedTextIndex].price = value;
            }
            else{
            if(textInput.inputs[this.state.selectedTextIndex].brutto){
             
              textInput.inputs[this.state.selectedTextIndex].netto = value;
              textInput.inputs[this.state.selectedTextIndex].price = textInput.inputs[this.state.selectedTextIndex].brutto;
            
            }
          }
            // else{
            //   textInput.inputs[this.state.selectedTextIndex].netto = 0;
            //   textInput.inputs[this.state.selectedTextIndex].price = 0;
            // }
           
          }
        }
        else{
          textInput.inputs[this.state.selectedTextIndex].price=0
          
        }
      }
    
        if(perc.id!==per.id){
        perc.checked=false
      }
    })
   
    this.setState({ textInput });
    this.props.updateTextOptionConfiguration(textInput);

  }
  onEnterHitAtBrutto=(e)=>{
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].VATPercent.forEach((vat)=>{
      if(vat.checked===true){
        let percent=`${vat.value}%`
        // console.log("percent",percent)
        var result = parseFloat(percent) / 100.0;
        var value=Number(e.target.value /( result+1)).toFixed(2)
        // console.log("result",value)
        textInput.inputs[this.state.selectedTextIndex].netto = value;
        if (this.context.priceType === "Brutto") {
          textInput.inputs[this.state.selectedTextIndex].price = e.target.value;
          }
          else{
            textInput.inputs[this.state.selectedTextIndex].price = value;
          }  
        this.setState({ textInput });
        this.props.updateTextOptionConfiguration(textInput);
      }
    })
  }
  addVATPercentage=(e)=>{
    const textInput = this.state.textInput;
let dummy
if(textInput.inputs[this.state.selectedTextIndex].VATPercent){
   dummy=Object.assign([],textInput.inputs[this.state.selectedTextIndex].VATPercent)
}
   else{
     dummy=Object.assign([],this.state.VATPercent)
   }
    // console.log("VA",this.state.VATPercent)
    dummy.push({id:uuidv4(), checked:false,value:'0'})
    // console.log("dummy",dummy)
    textInput.inputs[this.state.selectedTextIndex].VATPercent =dummy;
    this.setState({ textInput});
    this.props.updateTextOptionConfiguration(this.state.textInput);

  }
  removeVATPercentage=(index)=>{
    const textInput = this.state.textInput;
    if (textInput.inputs[this.state.selectedTextIndex].VATPercent[index].checked) {
      if(index>1){
        textInput.inputs[this.state.selectedTextIndex].VATPercent.splice(index,1);
        this.setCheckedPercentage(textInput.inputs[this.state.selectedTextIndex].VATPercent[0]);
        this.setState({ textInput});
      }
    }
    else {
      textInput.inputs[this.state.selectedTextIndex].VATPercent.splice(index,1);
      this.setState({textInput});
    }
    }

  setInvoiceText = (e) => {
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].description =
      e.target.value;
    this.setState({ textInput });
    this.props.updateTextOptionConfiguration(this.state.textInput);
  };
  setGuest=(e)=>{
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].numerically.unit =
    e.target.value;
  this.setState({ textInput });
  }
  setFlatRate=(e)=>{
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].numerically.unit =
    e.target.value;
  this.setState({ textInput });
  }
  setUnit=(e)=>{
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].numerically.unit =
    e.target.value;
  this.setState({ textInput });
  }
  setPriceOptionSelected = () =>
    this.setState({ priceOptionSelected: true, tagOptionSelected: false });

  setTagOptionSelected = () =>
    this.setState({ priceOptionSelected: false, tagOptionSelected: true });

  renderPriceOption = (selectedPriceOption) => {
   
    Object.keys(this.context.guestLabel).forEach((guest) => {
      this.state.initialGuestOptions.forEach((label) => {
        if (label.value === guest) {
          return (label.value = this.context.guestLabel[guest]);
        }
      });
    });
    const array = this.state.textInput.inputs[this.state.selectedTextIndex]
      .guestOptions
      ? this.state.textInput.inputs[this.state.selectedTextIndex].guestOptions
      : this.state.guestOptions;
    // console.log("array", array);
    switch (selectedPriceOption) {
      case "flat-rate":
        return (
          <div className="price-option-btn-container">
            <div className="price-option-btn">{this.props.t('flat_rate')}</div>
            <button className="clear-option" onClick={this.resetPriceOption}>
              <img src={minusIcon} alt="" />
            </button>
            <div className="row" style={{ display: "block", margin: "auto" }}>
              <div style={{}}>
                <h6 style={{paddingLeft:"66px",textAlign:"left"}}>{this.props.t('Unit')}</h6>
                <input
                  maxLength="15"
                  className="invoice-text"
                  type="text"
                  placeholder={`${this.props.t('flat_rate')}`}
                  value={
                    this.state.textInput.inputs[this.state.selectedTextIndex].numerically.unit
                  } 
                  onChange={this.setFlatRate}        
                />
              </div>
            </div>
          </div>
        );
      case "number-guests":
        return (
          <div className="price-option-btn-container">
            <div className="price-option-btn">{this.props.t('Anzahl')}</div>
            <button className="clear-option" onClick={this.resetPriceOption}>
              <img src={minusIcon} alt="" />
            </button>
            <div
              // onClick={(e)=>this.HeaderObject(e)}
              style={{
                marginLeft: "50px",
                width: "190px",
              }}
            >
              {console.log("array",array)}
              {array.map((guest) => {
                return (
                  <div
                    //  display: "flex"
                    style={{
                      paddingBottom: "20px",
                      marginTop: "-10px",
                      // color: "#939598",
                      background: "rgb(209, 211, 212) none repeat scroll 0% 0%",
                      display: "flex",
                      // paddingLeft: "50px",
                      // paddingRight: "90px",
                      marginRight: "200px",
                      // height: "200px",
                      // overflow: "auto",
                      // marginLeft: "50px",
                      width: "190px",
                    }}
                  >
                    <input
                      onChange={(e) => {
                        this.selectedHeader(e, guest.value);
                      }}
                      style={{ 
                        // width: "100%",
                        position: "absolute",
                      right: "250px", 
                      left:"90px"
               }}
                      checked={guest.checked}
                      type="checkbox"
                      id={guest.value}
                      name={guest.value}
                      value={guest.value}
                    />

                    <label
                      htmlFor={guest.value}
                      style={{
                        clear: "both",
                        left: "60px",
                        display: "inline-block",

                        whiteSpace: "nowrap",
                      }}
                    >
                     {this.props.t(guest.value)}
                    </label>
                  </div>
                );
              })}
            </div>
             <div className="row" style={{ display: "block", margin: "auto" }}>
              <div style={{}}>
                <h6 style={{paddingLeft:"66px",textAlign:"left"}}>{this.props.t('Unit')}</h6>
                <input
                  maxLength="15"
                  className="invoice-text"
                  type="text"
                  placeholder={`${this.props.t('Per')} ${this.props.t('Gast')}`}
                  value={
                    this.state.textInput.inputs[this.state.selectedTextIndex].numerically.unit
                  } 
                  onChange={this.setGuest}  
                />
              </div>
            </div>
          </div>
        );
      case "numerically":
        return (
          <>
            <div className="price-option-btn-container">
              <div className="price-option-btn">{this.props.t('Numerically')}</div>
              <button className="clear-option" onClick={this.resetPriceOption}>
                <img src={minusIcon} alt="" />
              </button>
            </div>
            {this.functionPriceValidation() ? (
              <div className="validationMsg">
                {this.functionPriceValidation()}
              </div>
            ) : null}
            {this.functionValidation() ? (
              <div className="validationMsg">{this.functionValidation()}</div>
            ) : null}
            <div className="numerically-options">
              <div className="left-text">
              {this.props.t('Minimum_number')}
                <br />
                {this.props.t('Max_number')} 
                <br />
                {this.props.t('stages')}
                <br />
                {`${this.props.t('Season')} 1:`}
                <br />
                {`${this.props.t('Season')} 2:`}
                <br />
                {`${this.props.t('Season')} 3:`}
                <br />
                {/* Einheit: */}
              </div>
              <div className="right-options">
                <div
                  style={{ display: "flex", height: "30px", marginTop: "5px" }}
                >
                  <input
                    type="number"
                    className="small-input"
                    placeholder="000"
                    style={
                      this.functionValidation() && { border: "1px solid red" }
                    }
                    value={
                      this.state.textInput.inputs[this.state.selectedTextIndex]
                        .numerically.minimumNumber
                    }
                    onChange={this.setMinimumNumber}
                  />
                  <label className="calculate">
                    <input
                      type="checkbox"
                      checked={
                        this.state.textInput.inputs[
                          this.state.selectedTextIndex
                        ].numerically.minimumNumberCalculate
                      }
                      onChange={this.setMinimumNumberCalculate}
                    />
                    <span>{this.props.t('Just_calculate')}</span>
                  </label>
                </div>
                {/* <br /> */}
                <div
                  style={{ display: "flex", height: "30px", marginTop: "2px" }}
                >
                  <input
                    type="number"
                    className="small-input"
                    placeholder="000"
                    style={
                      this.functionValidation() && { border: "1px solid red" }
                    }
                    value={
                      this.state.textInput.inputs[this.state.selectedTextIndex]
                        .numerically.maximumNumber
                    }
                    onChange={this.setMaximumNumber}
                  />
                  <label className="calculate">
                    <input
                      type="checkbox"
                      checked={
                        this.state.textInput.inputs[
                          this.state.selectedTextIndex
                        ].numerically.maximumNumberCalculate
                      }
                      onChange={this.setMaximumNumberCalculate}
                    />
                    <span>{this.props.t('Just_calculate')}</span>
                  </label>
                </div>
                {/* <br /> */}
                <div style={{ height: "30px", marginTop: "2px" }}>
                  <input
                    type="number"
                    className="small-input"
                    placeholder="000"
                    value={
                      this.state.textInput.inputs[this.state.selectedTextIndex]
                        .numerically.stage
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Backspace") return;
                      let key = Number(e.key);
                      if (isNaN(key) || e.key === null || e.key === " ")
                        e.preventDefault();
                    }}
                    onChange={this.setStage}
                  />
                </div>
                {/* <br /> */}
                <div
                  style={{ display: "flex", height: "30px", marginTop: "2px" }}
                >
                  <input
                    type="number"
                    className="small-input"
                    placeholder="000"
                    value={
                      this.state.textInput.inputs[this.state.selectedTextIndex]
                        .numerically.relay1
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Backspace") return;
                      let key = Number(e.key);
                      if (isNaN(key) || e.key === null || e.key === " ")
                        e.preventDefault();
                    }}
                    onChange={this.setRelay1}
                  />
                  <div>
                    <label className="more">
                      <input
                        type="radio"
                        name="staffel1"
                        checked={
                          this.state.textInput.inputs[
                            this.state.selectedTextIndex
                          ].numerically.relay1Alle === false
                        }
                        onChange={this.handleRelay1}
                        value="false"
                      />
                      <span>{this.props.t('Each_further')}</span>
                    </label>
                  </div>
                  <div>
                    <label className="alle">
                      <input
                        type="radio"
                        name="staffel1"
                        checked={
                          this.state.textInput.inputs[
                            this.state.selectedTextIndex
                          ].numerically.relay1Alle === true
                        }
                        onChange={this.handleRelay1}
                        value="true"
                      />
                      <span>{this.props.t('all')}</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="last-input"
                      value={
                        // this.state.textInput.inputs[
                        //   this.state.selectedTextIndex
                        // ].numerically.relay1Price
                        this.convertedRelay1PriceValues()
                      }
                      onChange={this.setRelay1Price}
                      onKeyPress={(evt) => {
                        var charCode = evt.which ? evt.which : evt.keyCode;
    
                        let inputFormat= "";
                        inputFormat = (this.context.languageFormat==="en") ? charCode !== 46 : charCode !== 44 
                        if (inputFormat &&
                          charCode > 31 &&
                          (charCode < 48 || charCode > 57)  
                        ) {
                          //console.log("in alse")
                          // return false;
                          evt.preventDefault();
                        }
                        
                        return true;
                      }}
                    />
                  </div>
                </div>
                {/* <br /> */}
                <div
                  style={{ display: "flex", height: "30px", marginTop: "2px" }}
                >
                  <input
                    type="number"
                    className="small-input"
                    placeholder="000"
                    value={
                      this.state.textInput.inputs[this.state.selectedTextIndex]
                        .numerically.relay2
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Backspace") return;
                      let key = Number(e.key);
                      if (isNaN(key) || e.key === null || e.key === " ")
                        e.preventDefault();
                    }}
                    onChange={this.setRelay2}
                  />
                  <div>
                    <label className="more">
                      <input
                        type="radio"
                        name="staffel2"
                        value="false"
                        checked={
                          this.state.textInput.inputs[
                            this.state.selectedTextIndex
                          ].numerically.relay2Alle === false
                        }
                        onChange={this.handleRelay1}
                      />
                      <span>{this.props.t('Each_further')}</span>
                      {/* <span title="Je weitere">Je weit</span> */}
                    </label>
                  </div>
                  <div>
                    <label className="alle">
                      <input
                        type="radio"
                        name="staffel2"
                        value="true"
                        checked={
                          this.state.textInput.inputs[
                            this.state.selectedTextIndex
                          ].numerically.relay2Alle === true
                        }
                        onChange={this.handleRelay1}
                      />
                      <span>{this.props.t('all')}</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="last-input"
                      value={
                        // this.state.textInput.inputs[
                        //   this.state.selectedTextIndex
                        // ].numerically.relay2Price
                        this.convertedRelay2PriceValues()
                      }
                      onChange={this.setRelay2Price}
                      onKeyPress={(evt) => {
                        var charCode = evt.which ? evt.which : evt.keyCode;
    
                        let inputFormat= "";
                        inputFormat = (this.context.languageFormat==="en") ? charCode !== 46 : charCode !== 44 
                        if (inputFormat &&
                          charCode > 31 &&
                          (charCode < 48 || charCode > 57)  
                        ) {
                          //console.log("in alse")
                          // return false;
                          evt.preventDefault();
                        }
                        
                        return true;
                      }}
                    />
                  </div>
                </div>
                {/* <br /> */}
                <div
                  style={{ display: "flex", height: "30px", marginTop: "2px" }}
                >
                  <input
                    type="number"
                    className="small-input"
                    placeholder="000"
                    min="1"
                    value={
                      this.state.textInput.inputs[this.state.selectedTextIndex]
                        .numerically.relay3
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Backspace") return;
                      let key = Number(e.key);
                      if (isNaN(key) || e.key === null || e.key === " ")
                        e.preventDefault();
                    }}
                    onChange={this.setRelay3}
                  />
                  <div>
                    <label className="more">
                      <input
                        type="radio"
                        name="staffel3"
                        value="false"
                        checked={
                          this.state.textInput.inputs[
                            this.state.selectedTextIndex
                          ].numerically.relay3Alle === false
                        }
                        onChange={this.handleRelay1}
                      />
                      <span>{this.props.t('Each_further')}</span>
                    </label>
                  </div>
                  <div>
                    <label className="alle">
                      <input
                        type="radio"
                        name="staffel3"
                        value="true"
                        checked={
                          this.state.textInput.inputs[
                            this.state.selectedTextIndex
                          ].numerically.relay3Alle === true
                        }
                        onChange={this.handleRelay1}
                      />
                      <span>{this.props.t('all')}</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="last-input"
                      value={
                        // this.state.textInput.inputs[
                        //   this.state.selectedTextIndex
                        // ].numerically.relay3Price
                        this.convertedRelay3PriceValues()
                      }
                      onChange={this.setRelay3Price}
                      onKeyPress={(evt) => {
                        var charCode = evt.which ? evt.which : evt.keyCode;
    
                        let inputFormat= "";
                        inputFormat = (this.context.languageFormat==="en") ? charCode !== 46 : charCode !== 44 
                        if (inputFormat &&
                          charCode > 31 &&
                          (charCode < 48 || charCode > 57)  
                        ) {
                          //console.log("in alse")
                          // return false;
                          evt.preventDefault();
                        }
                        
                        return true;
                      }}
                    />
                  </div>
                </div>
                {/* <br /> */}
                {/* <input
                  className="unit"
                  type="text"
                  placeholder="/Flasche/Person/Stück"
                  value={
                    this.state.textInput.inputs[this.state.selectedTextIndex]
                      .numerically.unit
                  }
                  onChange={this.setUnit}
                /> */}
              </div>
            </div>
            <div className="row" style={{ display: "block", margin: "auto" }}> 
                <h6 style={{paddingLeft:"66px",textAlign:"left"}}>{this.props.t('Unit')}</h6>
                <input
                  maxLength="15"
                  className="unit1"
                  type="text"
                  placeholder={this.props.t('Add your Unit here..')}
                  value={
                    this.state.textInput.inputs[this.state.selectedTextIndex].numerically.unit
                  }
                  onChange={this.setUnit}
                />
             
            </div>
          </>
        );
      default:
        return null;
    }
  };

  str2bool = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  selectPriceOption = (priceType) => {
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].priceType = priceType;
    if (priceType === "flat-rate")
      textInput.inputs[this.state.selectedTextIndex].priceTypeId = 1;
    else if (priceType === "number-guests")
      textInput.inputs[this.state.selectedTextIndex].priceTypeId = 2;
    else if (priceType === "numerically")
      textInput.inputs[this.state.selectedTextIndex].priceTypeId = 3;
    else textInput.inputs[this.state.selectedTextIndex].priceTypeId = null;
    this.setState({ textInput });
    this.props.updateTextOptionConfiguration(this.state.textInput);
  };

  resetPriceOption = () => {
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].priceType = "";
    this.setState({ textInput });
    this.props.updateTextOptionConfiguration(this.state.textInput);
  };
  selectedHeader = (e, label) => {
    const textInput = Object.assign({},this.state.textInput);
    
    let guestOptions;
    if (textInput.inputs[this.state.selectedTextIndex].guestOptions) {
      guestOptions =
        textInput.inputs[this.state.selectedTextIndex].guestOptions;
    
     
    } else {
      let dummy=   [
        { value: "adults" ,checked:false},
        { value: "U6" ,checked:false},
        { value: "U12",checked:false },
        { value: "U18" ,checked:false},
      ]
      
      Object.keys(this.context.guestLabel).forEach((guest) => {
        dummy.forEach((label) => {
          if (label.value === guest) {
            return (label.value = this.context.guestLabel[guest]);
          }
        });
      });
      guestOptions = dummy;
   
    }
    
  
    let array = [];
    guestOptions.forEach((guest) => {
      if (guest.checked === true) {
        Object.keys(this.context.guestLabel).forEach((label) => {
          // console.log(this.context.guestLabel[label])
          if (this.context.guestLabel[label] === guest.value) {
            array.push(label);
          }
        });
      }
    });
   

    // console.log("guestOptions",guestOptions,"guestInfo",guestInfo)
    textInput.inputs[this.state.selectedTextIndex].guestOptions = guestOptions;
    textInput.inputs[this.state.selectedTextIndex].guestInfo = array;
    this.setState({ textInput });
    this.props.updateTextOptionConfiguration(this.state.textInput);
  };
  settextInputPrice = (e) => {
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].price = e.target.value;
    this.setState({ textInput });
    this.props.updateTextOptionConfiguration(this.state.textInput);
  };

  clearPrice = () => {
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].price = "";
    textInput.inputs[this.state.selectedTextIndex].netto = "";
    textInput.inputs[this.state.selectedTextIndex].brutto = "";
    textInput.inputs[this.state.selectedTextIndex].VATPercent.forEach((v)=>{
      // console.log("v",v)
      v.checked=false
    })
    this.setState({ textInput });
    this.props.updateTextOptionConfiguration(this.state.textInput);
  };

  setMinimumNumber = (e) => {
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].numerically.minimumNumber =
      e.target.value;
    this.setState({ textInput });
    this.props.updateTextOptionConfiguration(this.state.textInput);
  };

  setMinimumNumberCalculate = (e) => {
    const textInput = this.state.textInput;
    textInput.inputs[
      this.state.selectedTextIndex
    ].numerically.minimumNumberCalculate = !textInput.inputs[
      this.state.selectedTextIndex
    ].numerically.minimumNumberCalculate;
    this.setState({ textInput });
  };

  setMaximumNumber = (e) => {
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].numerically.maximumNumber =
      e.target.value;
    this.setState({ textInput });
    this.props.updateTextOptionConfiguration(this.state.textInput);
  };

  setMaximumNumberCalculate = () => {
    const textInput = this.state.textInput;
    textInput.inputs[
      this.state.selectedTextIndex
    ].numerically.maximumNumberCalculate = !textInput.inputs[
      this.state.selectedTextIndex
    ].numerically.maximumNumberCalculate;
    this.setState({ textInput });
  };

  setStage = (e) => {
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].numerically.stage =
      e.target.value;
    this.setState({ textInput });
  };

  setRelay1 = (e) => {
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].numerically.relay1 =
      e.target.value;
    this.setState({ textInput });
  };

  handleRelay1 = (e) => {
    const textInput = this.state.textInput;
    textInput.inputs[
      this.state.selectedTextIndex
    ].numerically.relay1Alle = this.str2bool(e.target.value);
    textInput.inputs[
      this.state.selectedTextIndex
    ].numerically.relay2Alle = this.str2bool(e.target.value);
    textInput.inputs[
      this.state.selectedTextIndex
    ].numerically.relay3Alle = this.str2bool(e.target.value);
    this.setState({ textInput });
  };

  setRelay1Price = (e) => {
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].numerically.relay1Price =
      textValue;
    this.setState({ textInput });
  };

  convertedRelay1PriceValues = () => {

    if(this.context.languageFormat!=="en" && this.state.textInput.inputs[this.state.selectedTextIndex].numerically.relay1Price)
    {
      let inputValue = this.state.textInput.inputs[this.state.selectedTextIndex].numerically.relay1Price

      //inputValue.replace('.',",")
    return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && this.state.textInput.inputs[this.state.selectedTextIndex].numerically.relay1Price)
    {
      return this.state.textInput.inputs[this.state.selectedTextIndex].numerically.relay1Price
    }
    else{
      return ""
    }

  }

  setRelay2 = (e) => {
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].numerically.relay2 =
      e.target.value;
    this.setState({ textInput });
  };

  // handleRelay2 = e => {
  //   const textInput = this.state.textInput;
  //   textInput.inputs[
  //     this.state.selectedTextIndex
  //   ].numerically.relay2Alle = this.str2bool(e.target.value);
  //   this.setState({ textInput });
  // };

  setRelay2Price = (e) => {
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].numerically.relay2Price =
      textValue;
    this.setState({ textInput });
  };
  convertedRelay2PriceValues = () => {

    if(this.context.languageFormat!=="en" && this.state.textInput.inputs[this.state.selectedTextIndex].numerically.relay2Price)
    {
      let inputValue = this.state.textInput.inputs[this.state.selectedTextIndex].numerically.relay2Price

      //inputValue.replace('.',",")
    return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && this.state.textInput.inputs[this.state.selectedTextIndex].numerically.relay2Price)
    {
      return this.state.textInput.inputs[this.state.selectedTextIndex].numerically.relay2Price
    }
    else{
      return ""
    }

  }
  setRelay3 = (e) => {
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].numerically.relay3 =
      e.target.value;
    this.setState({ textInput });
  };

  // handleRelay3 = e => {
  //   const textInput = this.state.textInput;
  //   textInput.inputs[
  //     this.state.selectedTextIndex
  //   ].numerically.relay3Alle = this.str2bool(e.target.value);
  //   this.setState({ textInput });
  // };

  setRelay3Price = (e) => {
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].numerically.relay3Price =
      textValue;
    this.setState({ textInput });
  };
  convertedRelay3PriceValues = () => {

    if(this.context.languageFormat!=="en" && this.state.textInput.inputs[this.state.selectedTextIndex].numerically.relay3Price)
    {
      let inputValue = this.state.textInput.inputs[this.state.selectedTextIndex].numerically.relay3Price

      //inputValue.replace('.',",")
    return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && this.state.textInput.inputs[this.state.selectedTextIndex].numerically.relay3Price)
    {
      return this.state.textInput.inputs[this.state.selectedTextIndex].numerically.relay3Price
    }
    else{
      return ""
    }

  }

  setUnit = (e) => {
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].numerically.unit =
      e.target.value;
    this.setState({ textInput });
  };

  // handleTagChange = (index, newValue) => {
  //   if (newValue && newValue.__isNew__) {
  //     const tagOptions = this.state.tagOptions;
  //     tagOptions.push({ label: newValue.label });
  //     this.setState({ tagOptions });
  //   }
  //   const textInput = this.state.textInput;
  //   textInput.inputs[this.state.selectedTextIndex].tags[index] = newValue.label;
  //   this.setState({ textInput });
  // };

  handleTagChange = (newValue, x) => {
    const textInput = this.state.textInput;
    textInput.inputs[this.state.selectedTextIndex].tags = newValue;
    this.setState({ textInput });
    if (x.action === "create-option") {
      let tags = Object.assign([], this.state.tags);
      const tag = {
        value: newValue[newValue.length - 1].value,
        label: newValue[newValue.length - 1].label,
      };
      tags.push(tag);
      const tagOptions = Object.assign([], this.state.tagOptions);
      tagOptions.push(tag);
      this.setState({ tags, tagOptions });
      const postData = {
        tags,
        venueId: this.context.venueId,
      };
      if (this.state.newEntry && !this.state.tagOptions.length) {
        axios
          .post(`${baseUrl}api/Questions/Tag`, postData)
          .then((res) => {
            // console.log(res.data)
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .put(`${baseUrl}api/Questions/Tag/${this.context.venueId}`, postData)
          .then((res) => {
            // console.log(res.data)
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  // addTagSelector = () => {
  //   const textInput = this.state.textInput;
  //   textInput.inputs[this.state.selectedTextIndex].tags.push("");
  //   this.setState({ textInput });
  // };

  // removeTag = index => {
  //   const textInput = this.state.textInput;
  //   textInput.inputs[this.state.selectedTextIndex].tags.splice(index, 1);
  //   this.setState({ textInput });
  // };
  handleClose = () => {
    const ans = this.functionValidation();
    if (!ans) {
      this.props.handleClose();
    }
  };
  render() {
    // console.log('textInput',this.state.textInput)
    const VATPercent= [
      { id: uuidv4(), checked: true, value: "19" },
      { id: uuidv4(), checked: false, value: "7" },
    ]
    const {textInput}=this.state
    if(!textInput.inputs[this.state.selectedTextIndex].VATPercent){
      textInput.inputs[this.state.selectedTextIndex].VATPercent=VATPercent

    }
    textInput.inputs[this.state.selectedTextIndex].netto = textInput
    .inputs[this.state.selectedTextIndex].netto
    ? textInput.inputs[this.state.selectedTextIndex].netto
    : "";
    textInput.inputs[this.state.selectedTextIndex].brutto = textInput
    .inputs[this.state.selectedTextIndex].brutto
    ? textInput.inputs[this.state.selectedTextIndex].brutto
    : "";
    textInput.inputs[this.state.selectedTextIndex].description = textInput
    .inputs[this.state.selectedTextIndex].description
    ? textInput.inputs[this.state.selectedTextIndex].description
    : "";
    return (
      <div className="component-container option-component" style={{top: "65px"}}>
        {textInput.inputs[this.state.selectedTextIndex]?.numeric && (<button
          className={`price-selection ${
            this.state.priceOptionSelected ? "selected" : ""
          }`}
          onClick={this.setPriceOptionSelected}
        >
          {this.context.currencyFormat}
        </button>)}
        <button
          className={`tag-selection ${
            this.state.tagOptionSelected ? "selected" : ""
          }`}
          onClick={this.setTagOptionSelected}
        >
          <img
            src={this.state.tagOptionSelected ? tagInverseIcon : tagIcon}
            alt=""
          />
        </button>
        <img
          src={crossSmallIcon}
          onClick={this.handleClose}
          alt=""
          style={{
            float: "right",
            marginRight: "2px",
            marginTop: "-28px",
            width: "13px",
            height: "13px",
          }}
        />

        {this.state.priceOptionSelected && this.state.textInput?.inputs && (
          <div className="price-options">
              {this.state.textInput.inputs[this.state.selectedTextIndex]
                .price !== "" && (
                <button className="clear-price"  style={{marginBottom:"20px"}} onClick={this.clearPrice}>
                  <img src={minusIcon} alt="" />
                </button>
              )}
            <div className="price-input">

            <div style={{ justifyContent: "center", display: "flex" }} >
            <div
                    style={{ display: "flex", justifyContent: "flex-start",
                    width: "min-content",
                    height: "min-content",
                    marginLeft: "103px",
                    color:"rgb(149, 150, 155)",
                    fontSize:"15px",
                    fontWeight:"300"           
                  }}
                  >
                <h6>{this.props.t('Netto')}</h6> 
                  </div>
                <div style={{ background:this.context.priceType==="Netto" ? "rgb(170, 172, 175)":"#e6e7e8",
                //  marginRight:"20px", 
                 marginLeft:"-40px",
                    marginTop:"20px",
                content:this.context.currencyFormat}} className="netto-brutto">
                 
                  <div style={{display:"flex"}}>
                 <span style={{marginTop:"5px",fontSize:"20px" ,color:"#939598"}}>{this.context.currencyFormat}</span>
                  <input
                  className="euro"
                  type="text"
                    onChange={this.onNettoChange}
                    onFocus={()=>{
                      this.setState({bruttoChange:false})
                    }}
                    value={this.convertedNettoValues()}
                    //textInput.inputs[this.state.selectedTextIndex].netto
                    onKeyPress={(evt) => {
                      var charCode = evt.which ? evt.which : evt.keyCode;
                      let inputFormat= "";
                      inputFormat = (this.context.languageFormat==="en") ? charCode !== 46 : charCode !== 44 
                      if (inputFormat &&
                        charCode > 31 &&
                        (charCode < 48 || charCode > 57)  
                      ) {
                        //console.log("in alse")
                        // return false;
                        evt.preventDefault();
                      }
                      

                      return true;
                    }}
                    // placeholder="00.00"
                    placeholder={this.context.languageFormat==="en" ? "00.00" : "00,00"} 
                    style={
                      this.functionPriceValidation() ? {
                        border: "1px solid red",
                        width: "130px",
                        background:this.context.priceType==="Netto" ? "rgb(170, 172, 175)":"#e6e7e8",
                        color:this.context.priceType==="Netto" ? "#939598":"black",
                        fontSize:"25px"
                      }:
                      {
                        background:this.context.priceType==="Netto" ? "rgb(170, 172, 175)":"#e6e7e8",
                        color:this.context.priceType==="Netto" ? "#939598":"black",
                        fontSize:"25px"
                      }
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") this.onEnterHitAtNetto(e);
                    }}
                    // disabled={this.context.priceType=="Brutto" && true}

                  />
                  </div>
                </div>
                <div
                    style={{ display: "flex", justifyContent: "flex-start",
                    width: "min-content",
                    height: "min-content",
                    marginLeft: "25px",
                    color:"#939598",
                    fontSize:"15px",
                    fontWeight:"300" 
                  
                  }}
                  >
                    <h6>{this.props.t('Brutto')}   </h6> 
                  </div>
                <div style={{background:this.context.priceType==="Brutto" ? "rgb(170, 172, 175)":"#e6e7e8",  content:this.context.currencyFormat,
                  marginRight:"88px",
                  marginLeft:"-40px",
                  marginTop:"20px",}}
                  className="netto-brutto">
                
                  <div style={{display:"flex"}}>
                 <span style={{marginTop:"5px",fontSize:"20px",color:"#939598"}}>{this.context.currencyFormat}</span>
                  <input
                   className="euro"
                   type="text"
                   value={this.convertedBruttoValues()}
                    //textInput.inputs[this.state.selectedTextIndex].brutto
                    onChange={this.onBruttoChange}
                    onFocus={()=>{
                      this.setState({bruttoChange:true})
                    }}
                    // placeholder="00.00"
                    placeholder={this.context.languageFormat==="en" ? "00.00" : "00,00"} 
                    style={
                      this.functionPriceValidation() ? {
                        border: "1px solid red",
                        width: "130px",
                        background:this.context.priceType==="Brutto" ? "rgb(170, 172, 175)":"#e6e7e8",
                         fontSize:"25px",
                        color:this.context.priceType==="Netto" ? "#939598":"black",
                      }:{
                        background:this.context.priceType==="Brutto" ? "rgb(170, 172, 175)":"#e6e7e8",
                        color:this.context.priceType==="Netto" ? "#939598":"black",
                        fontSize:"25px"
                      }
                    }
                    onKeyPress={(evt) => {
                      var charCode = evt.which ? evt.which : evt.keyCode;

                      let inputFormat= "";
                      inputFormat = (this.context.languageFormat==="en") ? charCode !== 46 : charCode !== 44 
                      if (inputFormat &&
                        charCode > 31 &&
                        (charCode < 48 || charCode > 57)  
                      ) {
                        console.log("in alse")
                        // return false;
                        evt.preventDefault();
                      }
                      

                      return true;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") this.onEnterHitAtBrutto(e);
                    }}
                    // disabled={this.context.priceType=="Netto" && true}

                  />
                  </div>
                </div>
              </div>
              <br />
              <div className="row" style={{marginLeft:"0px",marginRight:"66px"}}>
            
              {textInput.inputs[this.state.selectedTextIndex].VATPercent?.map((per,index)=>{
              return <div className="col" style={{ marginLeft: "56px" ,marginRight: "-34px" ,}}>
                <label style={{display:"flex"}} className="calculate">
                {/* <input
                  type="checkbox"
                  checked={
                    per.checked
                  }
                  style={{width:"20px"}}
                  id={per.id}
                  name={per.value}
                  value={per.value}
                  onChange={()=>this.setCheckedPercentage(per)}
                /> */}
                 <div style={per.checked ? styles.selected:styles.circle}
                   onClick={()=>this.setCheckedPercentage(per)}/>
                     <div style={{display:"flex"}}>
              <input
              style={{background:"#d0d2d4", width: "36px",
              color: "#939598",paddingLeft:"10px"}}
                type="number"
                value={per.value}
                onChange={(e)=>{this.setVAT(e,per)}}
                // placeholder="00.00"
                placeholder={this.context.languageFormat==="en" ? "00.00" : "00,00"} 
                onKeyPress={(evt) => {
                  var charCode = evt.which ? evt.which : evt.keyCode;
                  // console.log("charcode",charCode)
                  if (
                    charCode !== 46 &&
                    charCode > 31 &&
                    (charCode < 48 || charCode > 57)
                  ) {
                    // console.log("in alse")
                    // return false;
                    evt.preventDefault();
                  }

                  return true;
                }}
               
              /> 
                <span style={{fontSize:"20px",color:"#939598"}}>%</span>
                {index>1 && (
                          <img
                          src={crossSmallIcon}
                          onClick={()=>this.removeVATPercentage(index)}
                          alt=""
                          style={{
                            float: "right",
                            width: "10px",
                            height: "10px"
                          }}
                        />         
                         )   
                       }            
                </div>
              </label>
            
              </div>

              })}
        
         <div className="col">
                 
                  <img src={addIcon} alt="" onClick={this.addVATPercentage}/>
               
         </div>

              </div>
              {/* {  console.log('pricing-Inputs, index',this.state.textInput, this.state.selectedTextIndex)} */}
              {/* <input
                type="number"
                value={
                  this.state.textInput.inputs[this.state.selectedTextIndex]
                    .price
                }
                onChange={this.settextInputPrice}
                style={
                  this.functionPriceValidation() && { border: "1px solid red" }
                }
                placeholder="00.00"
              /> */}
            
            </div>
         
                    <div className="row" style={{display:"block",margin:"auto"}}>
            <div style={{color:"#939598" }}>
                      <h6  style={{margin:"auto"}}>{this.props.t('Invoice Text')}</h6>
                      <input
                      className="invoice-text"
                      maxLength="50"
                        type="text"
                        placeholder={this.props.t('Invoice Text')}
                        value={
                          textInput.inputs[this.state.selectedTextIndex].description
                        }
                        onChange={this.setInvoiceText}
                      />
                    </div>
                    </div>
            <div className="plus-icon">
              <img src={plusIcon} alt="" />
            </div>
            {this.renderPriceOption(
              this.state.textInput.inputs[this.state.selectedTextIndex]
                .priceType
            )}
            {this.state.textInput.inputs[this.state.selectedTextIndex]
              .priceType === "" && (
              <>
                <button
                  className="price-option-btn"
                  onClick={() => this.selectPriceOption("flat-rate")}
                >
                 {this.props.t('flat_rate')}  
                </button>
                <button
                  className="price-option-btn"
                  onClick={() => this.selectPriceOption("number-guests")}
                >
                   {this.props.t('Anzahl')}
                </button>
                <button
                  className="price-option-btn"
                  onClick={() => this.selectPriceOption("numerically")}
                >
                 {this.props.t('Numerically')}
                </button>
              </>
            )}
          </div>
        )}
        {this.state.tagOptionSelected && (
          <div className="tag-options">
            <div className="select-container">
              <CreatableSelect
                isMulti
                className="tag-select"
                classNamePrefix="tag"
                // isClearable
                value={
                  this.state.textInput.inputs[this.state.selectedTextIndex].tags
                }
                onChange={this.handleTagChange}
                options={this.state.tagOptions}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeperator: () => null,
                }}
                // value={{ label: tag }}
              />
              {/* {index !== 0 && (
                      <button
                        className="remove-tag"
                        onClick={() => this.removeTag(index)}
                      >
                        <img src={deleteIcon} alt="" />
                      </button>
                    )}
                    {tag !== "" &&
                      index ===
                        this.state.textInput.inputs[
                          this.state.selectedTextIndex
                        ].tags.length -
                          1 && (
                        <button
                          className="add-tag"
                          onClick={this.addTagSelector}
                        >
                          <img src={plusIcon} alt="" />
                        </button>
                      )} */}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation() (RadioOptionConfiguration);
const styles={
  selected: {
    background: `url(${crossSmallIcon}),rgb(230, 231, 232) none repeat scroll 0% 0%`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    minWidth: "18px",
    minHeight: "20px",
    // minWidth: "24px",
    // minHeight: "24px",
    height:"20px",
    display: "inline-block",
    textAlign: "center",
    // background: "transparent",
    // borderRadius: "12px",
    // border: "1px solid black",
    outline: "none",
    cursor: "pointer",
  },
  circle: {
    minWidth: "18px",
    minHeight: "20px",
    height:"20px",
    display: "inline-block",
    textAlign: "center",
    background: "rgb(230, 231, 232) none repeat scroll 0% 0%",
    // borderRadius: "12px",
    // border: "1px solid black",
    outline: "none",
    cursor: "pointer",
  },
}
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import uploadIcon from "../../../assets/upload.svg";
import docIcon from "../../../assets/doc.svg";
import Text from "../Text";
import jpgIcon from "../../../assets/jpg-file.svg";
import pdfIcon from "../../../assets/pdf.svg";
import trash from "../../../assets/trash.svg";
import HttpService from "../../../services/HttpService";
import {StateContext} from "../../../context/state";
import {ProgressBar} from "react-bootstrap"
import moment from 'moment-timezone'
import swal from 'sweetalert';
import { withTranslation} from 'react-i18next';
const imageMaxSize = 10240;

 class UploadComponent extends Component {
  iconMap = {
    jpg: jpgIcon,
    png: jpgIcon,
    jpeg: jpgIcon,
    pdf: pdfIcon,
  };
  static contextType = StateContext;
  constructor(props) {
    super(props);

    this.state = {
      files: props.files ? props.files : [],
      loading:false,
      progress:0,
      uploadPercentage:0

    };
  }

  onFileSelect = async(selectedFiles) => {
    
    let deadLineDate = new Date(this.context.deadlineForEntries);
    const currentFile = selectedFiles[0];
    const currentFileSize = Math.round(currentFile.size / 1024);
    if (currentFileSize > imageMaxSize) {
        swal(`${this.props.t('Bilder bis 15 MB sind erlaubt')}`, "", "info");
        return;
    }
    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ){     
     return false
    }
    else 
    {
    this.setState({loading:true})
    this.setState({loading:true,progress:60})
    let files = (this.state.files)?.length ? this.state.files :[]
    for(let i of selectedFiles){
      
      // console.log(i, selectedFiles);
      const data = new FormData();
     
      // console.log("selectedfile",selectedFiles[0])
        data.append('file',selectedFiles[0])
        const options={
          onUploadProgress:(progressEvent)=>{
            // console.log("progressevent",progressEvent)
            const {loaded,total}=progressEvent
  
            let percent=Math.floor((loaded * 100)/total)
            // console.log(`${loaded}kb 0f ${total}kb |${percent}%`)
            if(percent<100){
              this.setState({uploadPercentage:percent})
            }
          }
        }
  
      // data.append("blob", file);
      // console.log("options",i)
      const response = await HttpService.uploadFile(i,options);
      if(response){
        // console.log("response",response)
        this.setState({uploadPercentage:100,loading:false},()=>{
          setTimeout(()=>{
            this.setState({uploadPercentage:0});
          },1000)
        })

      }
      const params = {
        url: response.url,
        name: i?.name,
        type: i?.type?.split("/")?.[1],
        
      };
      // console.log(params,files);

      files.push(params)
    }
    
    // selectedFiles.forEach(file => {
    //   const data = new FormData();
    //   // data.append("blob", file);
    //   const response = await HttpService.uploadFile(file);
    //   const params = {
    //     url: response.url,
    //     name: file?.name,
    //     type: file?.type?.split("/")?.[1],
        
    //   };
    //   console.log(params);
      // return axios
      //   .post(baseUrl + "api/blob", data, {
      //     headers: { "Content-Type": "multipart/form-data" }
      //   })
      //   .then(res => {
      //     console.log(res);
         
         
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });
 // })
    
    //---------- selectedFiles = selectedFiles?.map((file) => {
    //   return {
    //     name: file?.name,
    //     type: file?.type?.split("/")?.[1],
    //     url: file,
    //   };
    // });

    // let files = this.state.files?.concat(selectedFiles);

    // this.setState({
    //   files: files,
    // });--------------
    // selectedFiles = selectedFiles.slice(min, max);
    //         array.push(selectedFiles)
    // console.log("se;ectedFiles",array)
    //         const files = selectedFiles.map(file => {
    //             return {
    //                 url: file,
    //                 name: file?.name,
    //                 type: file?.type?.split('/')?.[1]
    //             }
    //         });

    this.setState({files});

    this.props.onSelect(files);
  }
  };
  handleDelete = (file) => {
    let deadLineDate = new Date(this.context.deadlineForEntries);
   
    if (
      deadLineDate.toDateString() !== new Date().toDateString() &&
      moment(this.context.deadlineForEntries).isBefore(new Date()) &&
      this.context.userRole === "CU"
    ){        
      return false
     }
     else{

     
    let files = this.state.files.filter((f) => f?.url !== file.url);
    this.setState({ files: files });
    // console.log("dummy",files)
    this.props.onSelect(files);
     }
  };
  render() {
    let containerStyle = { ...styles.container };
    let {uploadPercentage}=this.state
    return (
      <>
        <Dropzone onDrop={this.onFileSelect}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()} style={containerStyle} id="upload">
                <input {...getInputProps()} autoComplete="off" />
                <img style={styles.icon} src={uploadIcon} alt="" />
                {/* {this.state.file===undefined ?
                        <img style={styles.icon} src={uploadIcon} alt="" />:
                        <div style={{height:"150px"}}></div> }    */}
              </div>
            </section>
          )}
        </Dropzone>
        
        <div style={{marginTop:"10px"}}>
        
       {this.state.loading || uploadPercentage!==0? <><ProgressBar now={uploadPercentage}  label={`${uploadPercentage}%`}/>
      <span style={{color:"#707070",fontSize:"14px"}}>File upload in progress</span></>:<></>} 
        </div>

        <div style={{}}>{this.renderContent()}</div>

      </>
    );
  }

  renderContent() {
    const { files } = this.state;
    const textFontSize = this.context.stylingData?.textFontSize;
    const textFontFamily = this.context.stylingData?.textFontFamily;
    if (files?.length > 0) {
      return (
        <div style={{ overflow: "auto" }}>
          <div style={{ display: "flex" }}>
            {files?.map((file, i) => {
              return (
                <div style={styles.imageWrapper} key={i}>
                  {/* <img src={this.iconMap[file.type] ?? docIcon} alt="" style={styles.image} /> */}
                  {/* {console.log('file.url', file)} */}
                  <a target="_blank" rel="noopener noreferrer" href={`${file?.url}`}>
                    <img
                      src={
                        file.type === "jpeg" ||
                        file.type === "jpg" ||
                        file.type === "png"
                          ? file?.url
                          : this.iconMap[file.type] ?? docIcon
                      }
                      alt={file.type}
                      style={styles.image}
                    />
                  </a>
                  
                  <img
                    src={trash}
                    onClick={() => {
                      this.handleDelete(file);
                    }}
                    style={{
                      marginTop: "60px",
                      height: "25px",
                      width: "25px",
                      borderRadius: "50%",
                      background: "#e5dbdb",
                      marginLeft: "-40px",
                      position: "relative",
                    }}
                    alt=""
                  />
                  <Text fontSize={textFontSize}
                fontFamily={textFontFamily}style={styles.label} value={file?.name ?? ""} />
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    // return <img style={styles.icon} src={uploadIcon} alt="" />;
  }
}
export default withTranslation() (UploadComponent);
const styles = {
  icon: {
    margin: "30px 0",
  },
  image: {
    width: "100px",
    height: "100px",
    // padding: "10px",
    objectFit: "contain",
  },
  text: {
    // fontSize: "10px",
    // fontFamily: "Crete Round",
    // fontStyle: "italic",
    color: "#707070",
  },
  button: {
    display: "block",
    background: "#939598",
    // fontSize: "10px",
    color: "#e6e7e8",
    margin: "6px auto 30px",
    padding: "3px 6px",
    border: "0",
  },
  container: {
    border: "2px dashed #bcbec0",
    width: "100%",
    cursor: "pointer",
    textAlign: "center",
    overflow: "auto",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    marginTop: "20px",
  },
  imageWrapper: {
    // marginRight: "15px",
    display: "inline-block",
    // width: "200px",
    // position:"absolute",
    marginTop: "-10px",
    marginRight: "20px"
  },
  label: {
    // fontSize: "14px"
    width: "40px",
    overflow: "auto",
    padding: "30px",
    margin: "10px 0px 0px"
  },
};

import React, { Component } from "react";

import { StateContext } from "../../context/state";
import download from "../../assets/download.png";
import { Formik, Form, Field } from "formik";
import arrowLeft from "../../assets/arrow-left.png";
import { v4 as uuidv4 } from "uuid";

export default class Features extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    // console.log("room",this.props.room)
    this.state = {
      showForm: false,
      icons: ["bar.svg", "music.svg", "dancefloor.svg", "food.svg", "dj.svg"],
      selectedIcon: "",
      additionalFeatures: this.props.features,
      values: { featureId: uuidv4(), featureName: "", notes: "", icon: "" },
    };
  }

  static getDerivedStateFromProps(nextProps) {}
  componentDidMount() {
    console.log("props",this.props.feature)
    // this.props.saveFeatures(this.state.feature)
  }
  handleEditFeature=(values)=>{
    const {features}=this.props
    let {additionalFeatures}=this.state
 additionalFeatures=Object.assign([],features)
additionalFeatures.map((feature)=>{
  return feature = { ...values }
})
// console.log("additional features",additionalFeatures)
    this.setState({values,showForm:true,additionalFeatures,selectedIcon:values.icon},()=>{   
      //  console.log("values",values)
  })
  }
  selectIcon = (e, icon, values) => {
 
    values.icon = icon;
    this.setState({ selectedIcon: icon, values });
  };
  onDragStart = (e, index) => {
    e.dataTransfer.setData("featureIndex", index);
   
  };
  render() {
    const { features } = this.props;
    let {additionalFeatures}=this.state
 
additionalFeatures=this.props.features
    return (
      <React.Fragment>
        <div style={styles.container}>
          {this.state.showForm === false && (
            <>
              <span>Additional Features</span>
              <button
                type="submit"
                className="outline-button speichern"
                style={styles.outline}
                onClick={() => {
                  this.setState({ showForm: true, values: {} });
                }}
              >
                Add Features
              </button>
              <br />
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {features ? (
                  <>
                    {features.map((feature, index) => {
                      return (
                        <div style={styles.icon} key={index} onClick={()=>this.handleEditFeature(feature)}>
                          <img alt={"Features"}
                            id={index}
                            draggable
                            onDragStart={(e) => this.onDragStart(e, index)}
                            style={styles.iconImage}
                            src={require(`../../assets/${feature.icon}`)}
                          />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
          <br />
          {this.state.showForm ? (
            <Formik
              enableReinitialize
              initialValues={this.state.values}
              validate={(values) => {
                // values.icon=this.state.selectedIcon
                const errors = {};

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                // console.log("values",values)
                if (
                  values.name === "" ||
                  values.icon === "" ||
                  values.notes === ""
                ) {
                  setSubmitting(false);
                } else {
                  values.icon = this.state.selectedIcon;
                  // console.log("values",values)
                  if(!values.featureId){
                    values.featureId=uuidv4()
                  }
                 
              const result=additionalFeatures.filter(feature=>feature.featureId!==values.featureId)
              // console.log(additionalFeatures,result)
               let newArray= [
                    
                  ...result,
                  values
                ]

            // console.log(newArray,"newArray")
//             const uniqueAddresses = Array.from(new Set(newArray.map(a => a.id)))
//  .map(id => {
//    return newArray.find(a => a.id === id)
//  })
//  console.log(uniqueAddresses)
              // const dummy=  newArray.filter(ar=>ar.featureId==values.featureId)
// console.log("dummy",dummy)
                // console.log("additionalFeatures",dummy)
                this.setState({showForm:false})
                this.props.saveFeatures(newArray);

                    // this.setState(
                    //   (prevState) => ({
                    //     additionalFeatures: [
                    
                    //       ...additionalFeatures,
                    //       values,
                    //     ],

                    //     showForm: false,
                    //   }),
                    //   () => {
                    //     this.props.saveFeatures(additionalFeatures);
                    //   }
                    // );

                  setSubmitting(false);
                }
              }}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  <div style={{ display: "flex" }}>
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      style={{
                        outline: "none",
                        border: "none",
                        background:
                          "rgb(208, 211, 212) none repeat scroll 0% 0%",
                      }}
                    >
                      <img style={{ width: "20px" }} src={arrowLeft} alt={"Arrow"} />
                    </button>

                    <span> Additional Features</span>
                  </div>

                  <br />
                  <Field
                    type="name"
                    name="featureName"
                    // value={room.name}
                    placeholder="Feature Name"
                    style={styles.input}
                  />
                  <br />

                  <Field
                    type="notes"
                    // onChange={this.handleChange}

                    name="notes"
                    placeholder="Note"
                    // value={room.notes}
                    component="textarea"
                    style={styles.input}
                  />
                  <br />
                  <span>Icons</span>
                  <div style={{ display: "flex", padding: "10px" }}>
                    {this.state.icons.map((icon) => {
                      return (
                        <div
                          onClick={(e) => this.selectIcon(e, icon, values)}
                          style={{
                            borderRadius: "20%",
                            background: "white",
                            marginRight: "10px",
                            outline:
                              icon === this.state.selectedIcon
                                ? "2px solid black"
                                : "",
                          }}
                        >
                          <img src={require(`../../assets/${icon}`)} alt={"icon"} />
                        </div>
                      );
                    })}
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <></>
          )}

          <img
            alt={"Download"}
            onClick={this.props.savePlan}
            style={{ width: "20px" }}
            src={download}
          />
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  title: {
    fontFamily: "Crete Round",
    fontSize: "19px",
    color: "#000",
    margin: "0px",
  },
  icon: {
    outline: "1px solid black",
    width: "90px",
    height: "90px",
    padding: "20px",
    background: "#e2e0e0",
    marginRight: "20px",
    marginTop: "10px",
  },
  iconImage: {
    width: "30px",
  },
  bold: {
    fontSize: "16px",
    margin: "0px",
    color: "#000",
    fontWeight: "600",
  },
  text: {
    fontSize: "16px",
    fontWeight: "300",
    margin: "0px",
    color: "#000",
  },
  outline: {
    color: "#297825",
    fontFamily: "Crete Round",
    fontSize: "13px",
    border: "1px solid #297825",
    // minWidth: "143px",
    padding: "9px 15px",
    textAlign: "center",
    display: "inline-block",
    marginRight: "15px",
    // float: "right",
  },
  cost: {
    fontSize: "40px",
    fontWeight: "600",
    color: "#000",
  },

  container: {
    // textAlign: "right",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    padding: "60px 10px 10px 20px",
    // width: "400px",
    background: "rgb(208, 211, 212) none repeat scroll 0% 0%",
  },
  pointer: {
    cursor: "pointer",
  },
  loader: {
    color: "#888888",
    fontWeight: "bold",
    fontSize: "20px",
    padding: "20px",
    textAlign: "center",
  },
  input: {
    marginBottom: "20px",
    border: "none",
    background: " #DEDEDE 0% 0% no-repeat padding-box",
  },
};

import React, { Component } from "react";
import plusIcon from "../../assets/plus.svg";
import TableSection, { Header } from "../partials/TableSection";
import editIcon from "../../assets/edit.svg";
import cloneIcon from "../../assets/duplicate.svg";
import deleteIcon from "../../assets/trash.svg";
import HttpService from "../../services/HttpService";
import { StateContext } from "../../context/state";
import Text from "../preview/Text";
import cancelIcon from "../../assets/cross.svg";
import saveIcon from "../../assets/upload.svg";
import linkIcon from "../../assets/arrows.svg";
import swal from "sweetalert";
import ExportSectionPopup from "../partials/x";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { withTranslation} from 'react-i18next';

const headers = [
  {
    name: "Organisationsplan",
    value: "planName",
    type: Header.TEXT,
  },
  {
    name: "Erstellt am",
    value: "planCreationDate",
    type: Header.DATE,
    readOnly: true,
  },
  // {
  //     name: "Event",
  //     value: "eventName",
  //     type: Header.AUTO_SPINNER,
  //     values: [],
  //     field: "eventId"
  // },
  {
    name: "Fragebogen",
    value: "questionnaireName",
    type: Header.AUTO_SPINNER,
    values: [],
    field: "questionnaireId",
    // readOnly: true
  },
];


 
class PlansPage extends Component {
  static contextType = StateContext;
  originals = [];

  constructor(props) {
    super(props);

    this.state = {
      loading: "Loading...",
      plans: [],
      filter: undefined,
      filteredData: [],
      keyFigures: {},
    };


  }
  FilteredData = (data) => {
    //  console.log("data",data)
    this.setState({
      filteredData: data,
    });
  };
  savePlan = (plan, index) => {
    const { edit, ...planBody } = plan;
    const {
      _id,
      venueId,
      planName,
      planCreationDate,
      questionnaireId,
    } = planBody;

     if (!venueId) return swal(`${this.props.t('Wählen Sie einen Veranstaltungsort')}`, "", "info");
    // if (!eventId) return swal(`Please select an event`, "", "info");
    if (plan.validation === true) return swal(`${this.props.t('Invalid name validation message')}`, "", "warning");
    if (!planName) return swal(`${this.props.t('Geben Sie den Namen des  Plans ein')}`, "", "info");
    if (!new Date(planCreationDate))
      return swal(`${this.props.t('Wählen Sie ein gültiges Planerstellungsdatum aus')}`, "", "info");
    if (!questionnaireId)
      return swal(`${this.props.t('Bitte wählen Sie noch einen Fragebogen')}`, "", "info");

    let promise = _id
      ? HttpService.updatePlan(planBody)
      : HttpService.createPlan(planBody);
    promise
      .then((res) => {
        const { plans, filteredData } = this.state;
        if (plans.length > filteredData.length) {
          const p = {
            ...plans[index],
            edit: false,
            _id: _id ?? res?.id,
          };

          plans[index] = p;
          this.originals[index] = { ...p };

          this.setState({ plans },()=>{
            window.location.reload();
          });
        } else {
          const p = {
            ...filteredData[index],
            edit: false,
            _id: _id ?? res?.id,
          };
          let newIndex;

          this.originals.forEach((plan) => {
            // console.log("events",event)
            if (plan._id === filteredData[index]._id) {
              newIndex = this.originals.indexOf(plan);
            }
          });
          filteredData[index] = p;
          this.originals[newIndex] = { ...p };

          this.setState({ plans: filteredData },()=>{
            window.location.reload();
          });
        }
      })
      .catch((err) => swal(JSON.stringify(err), "", "error"));
  };


  componentDidMount() {
    const {
      venueId,
      loggedInUserId,
      userRole,
      questionnaireName,
    } = this.context;
    let plans;
    // const message=window.my_special_setting
    // const passedQuestionnaireId=JSON.parse(localStorage.getItem("questionnaireId"))
    // console.log("passedQuestionnaireId",passedQuestionnaireId)
    // console.log("message",message)
    const plansPromise = HttpService.fetchPlans(
      venueId,
      loggedInUserId,
      userRole
    ).then((res) => {
      const currentDate = new Date();
      let dummy
      if(this.props.location?.state?.questionnaireId){
        dummy=  res.filter((plan)=>{
          // console.log("passedQuestionnaireId",passedQuestionnaireId,"plan.questionnaireId",plan.questionnaireId)
      return plan.questionnaireId===this.props.location?.state?.questionnaireId
          })
      }
      else{
        dummy=res
      }
      // console.log("dummy",dummy)
      plans = dummy?.map((plan) => {
        const p = {
          ...plan,
          planCreationDate: isNaN(Date.parse(plan?.planCreationDate))
            ? currentDate
            : new Date(plan?.planCreationDate),
          edit: false,
        };
        this.originals.push({ ...p });
        return p;
      });
    });

    const questionnairesPromise = HttpService.fetchParentQuestionnaires(
      venueId
    ).then((res) => {
      const quests = res?.map((q) => {
        return {
          id: q?._id,
          name: q?.name,
        };
      });

      let index = headers.findIndex(
        (header) => header.value === "questionnaireName"
      );
      headers[index].values = quests;
    });

    // const eventsPromise = HttpService.getEvents(venueId, loggedInUserId, userRole)
    //     .then(res => {
    //         const events = res?.map(e => {
    //             return {
    //                 id: e?._id,
    //                 name: e?.eventName
    //             }
    //         });

    //         let index = headers.findIndex(header => header.value === "eventName");
    //         headers[index].values = events;
    //     });

    Promise.all([plansPromise, questionnairesPromise])
      .then(() => {
        let filter = undefined;
        if (questionnaireName) {
          this.context.updateState({ questionnaireName: undefined });
          filter = {
            questionnaireName: questionnaireName,
          };
        }

        this.setState({
          loading: undefined,
          plans,
          filter,
        });
      })
      .catch((err) => {
        this.setState({
          loading: JSON.stringify(err),
        });
      });
   
  }

  addRow = () => {
    const plans = [
      {
        venueId: this.context.venueId,
        planName: "",
        planCreationDate: new Date(),
        eventId: "",
        eventName: "",
        questionnaireId: "",
        questionnaireName: "",
        edit: true,
        isDeleted: false,
      },
      ...this.state.plans,
    ];

    this.setState({ plans });
  };
  closeExportPopup = () => this.setState({ showExportPopup: false });
  clonePlan = (plan, index) => {
  
    const currentDate = new Date();
    HttpService.clonePlan(plan, currentDate.toISOString())
      .then((res) => {
        let { plans, filteredData } = this.state;

        if (plans.length === filteredData.length) {
          const p = {
            ...plans[index],
            _id: res.id,
            planCreationDate: currentDate,
            planName: `${plans[index].planName} (copy)`,
            edit: false,
          };

          plans.splice(index + 1, 0, p);

          this.originals.splice(index + 1, 0, p);

          this.setState({ plans });
        } else {
          let newIndex;

          this.originals.forEach((plan) => {
            // console.log("events",event)
            if (plan._id === filteredData[index]._id) {
              newIndex = this.originals.indexOf(plan);
            }
          });
          const p = {
            ...filteredData[newIndex],
            _id: res.id,
            planCreationDate: currentDate,
            planName: `${filteredData[newIndex].planName} (copy)`,
            edit: false,
          };

          filteredData.splice(newIndex + 1, 0, p);
         
          this.originals.splice(newIndex + 1, 0, p);

          this.setState({ plans: filteredData });
        }
      })
      .catch((err) => swal(JSON.stringify(err), "", "error"));
  };
  deletePlan = (plan) => {
  
    HttpService.deletePlan(plan)
      .then(() => {
        let { plans,filteredData } = this.state;
        // console.log("plans",plans,"filteredData",filteredData)
        // this.fetchKeys({ fromDate: "", toDate: "" });
        // this.subscription = dateRangeService
        //   .getDateRange()
        //   .subscribe((range) => this.fetchKeys(range));
        if (plans.length === filteredData.length) {
        
        

          plans = plans.filter((p) => p._id !== plan._id);
         

          filteredData = filteredData.filter((p) => p._id !== plan._id);
          this.originals = this.originals.filter((p) => p._id !== plan._id);
          
          this.setState({ plans,filteredData}); 
          this.context.updateState({keyFig:""})
        }
        // else{
        //   console.log("in else")
        //   let newIndex;

        //   this.originals.map((plan) => {
        //     // console.log("events",event)
        //     if (plan._id === filteredData[index]._id) {
        //       newIndex = this.originals.indexOf(plan);
        //     }
        //   })
        //   filteredData[newIndex] = { ...plan };

        //   filteredData = filteredData.filter((p) => p._id !== plan._id);
        //   this.setState({ plans:filteredData }); 
        // }
         
         
        
       
      })
      .catch((err) => JSON.stringify(err));
  };
  async fetchKeys(range) {
    const { venueId, userRole, loggedInUserId } = this.context;

    try {
      const keyFigures = await HttpService.fetchKeyFigures();
      const userKey = await HttpService.fetchUserKeyFigures(loggedInUserId);
      const promises = [];

      const startDate = range?.fromDate ?? "";
      const endDate = range?.toDate ?? "";

      keyFigures.forEach((key, index) => {
        const selected = userKey?.keys
          ? userKey?.keys?.includes(key.id)
          : index < 4;
        if (selected) {
          promises.push(
            HttpService.fetchKeyFigure({
              keyFigId: key.id,
              venueId: venueId,
              userRole: userRole,
              fromDate: startDate,
              toDate: endDate,
            }).then((value) => {
              keyFigures[index] = { ...key, selected, value };
            })
          );
        } else {
          keyFigures[index] = { ...key, selected, value: 0 };
        }
      });

      Promise.all(promises)
        .then(() => {
          localStorage.setItem("KeyFig", JSON.stringify(keyFigures));
          this.setState({
            keyFigures,
            // userKey,
            // fromDate : startDate,
            // toDate : endDate
          });
          // window.location.reload()
          this.context.updateState({keyFig:keyFigures})
        })
        .catch((err) => {
          this.setState({ loading: JSON.stringify(err) });
        });
    } catch (error) {
      this.setState({
        loading: JSON.stringify(error),
      });
    }
  }
  render() {
    const { loading, plans, filter, questionnaireId, planId } = this.state;
   
    // console.log("window.my_special_setting",window.my_special_setting)
    // const message=window.my_special_setting
//     const passedQuestionnaireId=JSON.parse(localStorage.getItem("questionnaireId"))
  
  
    // console.log("dummy",dummy)
    // console.log("props",this.props.location?.state )
    if (loading) {
      return (
        <div style={styles.row}>
          <div className="col-1" />
          <div className="col-10">
           <Text value={this.props.t('Loading')} />
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div style={styles.row}>
          <div className="col-1"></div>
          <div className="col-10">
            <div
              style={{ color: "#524f4f", fontSize: "24px", marginTop: "20px" }}
            >
               {this.props.t('Organisationsplan')}
            </div>
            <div style={styles.divider} />
          </div>
        </div>
        <div style={styles.row}>
          <div className={"col-11"} style={styles.addWrapper}>
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-disabled`}>
                     <span> {this.props.t('Organisationsplan_erstellen')}</span>
                </Tooltip>
              }
            >
              <img
                style={styles.pointer}
                src={plusIcon}
                alt=""
                onClick={this.addRow}
              />
            </OverlayTrigger>
          </div>
        </div>

        <div
          style={
            this.state.showExportPopup ? { filter: "blur(10px)" } : styles.row
          }
        >
          <div className={"col-1"} />
          <div className={"col-10"}>
            <TableSection
              headers={headers}
              data={plans}
              buttons={this.buttons}
              filter={filter}
              FilteredData={this.FilteredData}
              originalData={this.originals}
              onOptionSelect={(row, header) => {
                if (header.value === "Fragebogen") {
                  HttpService.fetchParentQuestionnaires(
                    this.context.venueId
                  ).then((res) => {
                    const { plans } = this.state;
                    plans[row].questionnaireId = res?.questionnaireId;
                    plans[row].questionnaireName = res?.questionnaireName;
                    this.setState({ plans });
                  });
                }
              }}
              buttonCount={4}
            />
          </div>
        </div>
        {this.state.showExportPopup ? (
          <ExportSectionPopup
            questionnaireId={questionnaireId}
            planId={planId}
            closeExportPopup={this.closeExportPopup.bind(this)}
          />
        ) : null}
      </React.Fragment>
    );
  }

  buttons = [
    {
      id: 1,
      name: "edit",
      icon: editIcon,
      tooltipText: "Bearbeiten",
      onPress: (plan, index) => {
        const { filteredData } = this.state;
        // let newIndex;

        // for (let i = 0; i < plans.length; i++) {
        //   if (plans[i] == plan) {
        //     newIndex = i;
        //   }
        // }
        filteredData[index].edit = true;
        this.setState({ plans: filteredData });
      },
      isVisible: (plan) => (plan._id && !plan.edit) ?? false,
    },
    {
      id: 2,
      name: "save",
      icon: saveIcon,
      tooltipText: "Speichern",
      onPress: this.savePlan,
      isVisible: (plan) => plan.edit ?? false,
    },
    {
      id: 3,
      name: "duplicate",
      icon: cloneIcon,
      tooltipText: "Kopieren",
      onPress: this.clonePlan,
      isVisible: (plan) => (plan._id && !plan.edit) ?? false,
    },
    {
      id: 4,
      name: "delete",
      icon: deleteIcon,
      tooltipText: "Löschen",
      onPress: (plan, index) => {
        swal({
            title:`${this.props.t('Bist du sicher')}`,
          text:
          `${this.props.t('Einmal gelöscht, können Sie diesen Plan nicht wiederherstellen!')}`,
            buttons: [`${this.props.t('Abbrechen')}`, "OK"],
          // buttons: true,
          dangerMode: true,
        })
          .then((isConfirmed) => {
            if (isConfirmed) {
              this.deletePlan(plan, index);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      isVisible: (plan) => (plan._id && !plan.edit) ?? false,
    },
    {
      id: 5,
      name: "cancel",
      icon: cancelIcon,
      tooltipText: "Abbrechen",
      onPress: (plan, index) => {
        const { plans, filteredData } = this.state;
      
        let newIndex;
        this.originals.forEach((p) => {
          // console.log("events",event)
          if (p._id === filteredData[index]._id) {
            newIndex = this.originals.indexOf(p);
       
          }
        });

        if (plan._id) {
          filteredData[index].edit = false;
          filteredData[index] = { ...this.originals[newIndex] };
        
        } 
       else {
          plans.shift();
        }

        this.setState({ plans: filteredData });
        window.location.reload();
      },
      isVisible: (plan) => plan.edit ?? false,
    },
    {
      id: 6,
      name: "edit-plan",
      icon: linkIcon,
      tooltipText: "Organisationsplan",
      onPress: (plan) => {
        this.setState({
          showExportPopup: true,
          questionnaireId: plan.questionnaireId,
          planId: plan._id,
        });
      },
      isVisible: (plan) => (plan._id && !plan.edit) ?? false,
    },
  ];
}

export default withTranslation() (PlansPage);										 

const styles = {
  row: {
    display: "flex",
    flexWrap: "wrap",
  },
  addWrapper: {
    marginLeft: "40px",
  },
  pointer: {
    cursor: "pointer",
  },
  divider: {
    borderTop: "1px solid #d0d3d4",
    margin: "30px 0 0px 0px",
    paddingTop: "10px",
  },
};

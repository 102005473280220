import React, { Component } from 'react'
// import CreatableSelect from "react-select/creatable";
import {StateContext} from "../../context/state";
import minusIcon from "../../assets/minus.svg";
import plusIcon from "../../assets/plus.svg";
import addIcon from "../../assets/add.svg";
import crossSmallIcon from "../../assets/cross-small.svg";
import { withTranslation} from 'react-i18next';
import { v4 as uuidv4 } from "uuid";

// import axios from "axios";
// import {baseUrl} from "../../Constants"
class GuestPriceConfigurations extends Component {
    static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      bruttoChange:undefined,
      priceOptionSelected: true,
      tagOptions: [],
      tagOptionSelected: false,
      options: [],
      selectedOptionIndex: null,
      tags:[],
      newEntry:true,
      validationMessage:"validation msg",
      VATPercent: [
        { id: uuidv4(), checked: false, value: "19" },
        { id: uuidv4(), checked: false, value: "7" },
      ],
    };
  }
  componentDidMount(){
    // axios.get(`${baseUrl}api/Questions/Tag/${this.context.venueId}`)
    // .then((res)=>{
    //   // console.log('res', res.data)
    //   if(res.data.hasOwnProperty("tags")){
    //     this.setState({
    //       tagOptions:res.data.tags,
    //       tags:res.data.tags,
    //       newEntry:false
    //     })
    //   }
    // })
    // .catch((err)=>{
    //   console.log(err)
    // })
  }
  static getDerivedStateFromProps = (nextProps, prevState) => {
    return {
     options: nextProps.options,
      selectedOptionIndex: nextProps.selectedOptionIndex,
    };
  };

  functionValidation=()=>{
    const { options}= this.state
    const min=Number(options[this.state.selectedOptionIndex].numerically.minimumNumber)
    const max=Number(options[this.state.selectedOptionIndex].numerically.maximumNumber)
    //  console.log('min', min, max)
    if(min && max && min>max){
      const msg="Der Maximalwert sollte größer als der Minimalwert sein"
        // valid=false
        return msg
    }
    else{
        // valid=true
        return null
    }
    
  }
  setVAT = (e, per) => {
    const options = this.state.options;
    // console.log("per",per)
    options[this.state.selectedOptionIndex].VATPercent.forEach((perc) => {
      if (perc.id === per.id) {
        perc.value = e.target.value;
        // console.log("perc",perc)
      }
    });

    this.setState({ options });
    this.props.updatePriceOptionConfiguration(this.state.options);

  };
  onNettoChange = (e) => {
    // console.log("e.key",e.key)
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    const options = this.state.options;
    options[this.state.selectedOptionIndex].netto = textValue;
    options[this.state.selectedOptionIndex].VATPercent.forEach(
      (vat) => {
        if (vat.checked === true) {
          let percent = `${vat.value}%`;
          // console.log("percent",percent)
          var result = parseFloat(percent) / 100.0;
          // var value=e.target.value *( result+1)
          var value = Number(textValue * (result + 1)).toFixed(2);
          // console.log("result",value)
          options[this.state.selectedOptionIndex].brutto = value;

          if (this.context.priceType === "Netto") {
            options[this.state.selectedOptionIndex].price = textValue;
          }
          else {
            options[this.state.selectedOptionIndex].price = value;
          }
          this.setState({ options });

        } else {
          this.setState({ options });
      
        }
      }
    );
    this.props.updatePriceOptionConfiguration(this.state.options);

    // this.setState({ radioInput });
    // this.props.updateRadioOptionConfiguration(this.state.radioInput);
  };

  convertedNettoValues = () => {

    if(this.context.languageFormat!=="en" && this.state.options[this.state.selectedOptionIndex].netto)
    {
      let inputValue = this.state.options[this.state.selectedOptionIndex].netto

      //inputValue.replace('.',",")
    return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && this.state.options[this.state.selectedOptionIndex].netto)
    {
      return this.state.options[this.state.selectedOptionIndex].netto
    }
    else{
      return ""
    }

  }
  convertedBruttoValues = () => {
    if(this.context.languageFormat!=="en" && this.state.options[this.state.selectedOptionIndex].brutto)
    {
      let inputValue = this.state.options[this.state.selectedOptionIndex].brutto

      
      return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && this.state.options[this.state.selectedOptionIndex].brutto)
    {
      return this.state.options[this.state.selectedOptionIndex].brutto
    }
    else{
      return ""
    }

  }
  onBruttoChange = (e) => {

    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;

    const options = this.state.options;
    options[this.state.selectedOptionIndex].brutto = textValue;
    options[this.state.selectedOptionIndex].VATPercent.forEach(
      (vat) => {
        if (vat.checked === true) {
          let percent = `${vat.value}%`;
          // console.log("percent",percent)
          var result = parseFloat(percent) / 100.0;
          var value = Number(textValue / (result + 1)).toFixed(2);
          // console.log("result",value)

          options[this.state.selectedOptionIndex].netto = value;
          if (this.context.priceType === "Brutto") {
            options[this.state.selectedOptionIndex].price = textValue;
          }
          else {
            options[this.state.selectedOptionIndex].price = value;
          }
          this.setState({ options });
         
        } else {
          this.setState({ options });
       
        }
      }
    );
    this.props.updatePriceOptionConfiguration(this.state.options);

  };
  onEnterHitAtNetto = (e) => {
    const options = this.state.options;
    options[this.state.selectedOptionIndex].VATPercent.forEach(
      (vat) => {
        if (vat.checked === true) {
          let percent = `${vat.value}%`;
          // console.log("percent",percent)
          var result = parseFloat(percent) / 100.0;
          // var value=e.target.value *( result+1)
          var value = Number(e.target.value * (result + 1)).toFixed(2);
          // console.log("result",value)
          options[this.state.selectedOptionIndex].brutto = value;
          if (this.context.priceType === "Netto") {
            options[this.state.selectedOptionIndex].price = e.target.value;
          }
          else {
            options[this.state.selectedOptionIndex].price = value;
          }
          this.setState({ options });
        
        }
      }
    );
    this.props.updatePriceOptionConfiguration(this.state.options);

  };
  onEnterHitAtBrutto = (e) => {
    const options = this.state.options;
    options[this.state.selectedOptionIndex].VATPercent.forEach(
      (vat) => {
        if (vat.checked === true) {
          let percent = `${vat.value}%`;
          // console.log("percent",percent)
          var result = parseFloat(percent) / 100.0;
          var value = Number(e.target.value / (result + 1)).toFixed(2);
          // console.log("result",value)
          options[this.state.selectedOptionIndex].netto = value;
          if (this.context.priceType === "Brutto") {
            options[this.state.selectedOptionIndex].price = e.target.value;
          }
          else {
            options[this.state.selectedOptionIndex].price = value;
          }
          this.setState({ options });
        
        }
      }
    );
    this.props.updatePriceOptionConfiguration(this.state.options);

  };
  setInvoiceText = (e) => {
    const options = this.state.options;
    options[this.state.selectedOptionIndex].description = e.target.value;
    this.setState({ options });
    this.props.updatePriceOptionConfiguration(this.state.options);
  };
  addVATPercentage = (e) => {
    const options = this.state.options;
    console.log(options,"options");
    let dummy;
    if ( options[this.state.selectedOptionIndex].VATPercent) {
      dummy = Object.assign(
        [],
        options[this.state.selectedOptionIndex].VATPercent
      );
    } else {
      dummy = Object.assign([], this.state.VATPercent);
    }
    // console.log("VA",this.state.VATPercent)
    dummy.push({ id: uuidv4(), checked: false, value: '0' });
    // console.log("dummy",dummy)
    options[this.state.selectedOptionIndex].VATPercent = dummy;
    this.setState({ options });
    this.props.updatePriceOptionConfiguration(this.state.options);
  };
  removeVATPercentage = (index) => { 
    const options = this.state.options;
    if (options[this.state.selectedOptionIndex].VATPercent[index].checked) {
      if(index>1){
        options[this.state.selectedOptionIndex].VATPercent.splice(index,1);
        this.setCheckedPercentage(options[this.state.selectedOptionIndex].VATPercent[0]);
        this.setState({ options});
      }
    }
    else {
      options[this.state.selectedOptionIndex].VATPercent.splice(index,1);
      this.setState({options});
    }
  }
  setCheckedPercentage = (per) => {
    const options = this.state.options;
    // console.log("per",per)
    options[this.state.selectedOptionIndex].VATPercent.forEach((perc) => {
      if (perc.id === per.id) {
        perc.checked = true
        if (perc.checked === true) {
          if (this.context.priceType === "Netto") {
            if(this.state.bruttoChange===true){
              let percent = `${perc.value}%`;
            
                          var result = parseFloat(percent) / 100.0;
            
                          var value = Number(
                            options[this.state.selectedOptionIndex].brutto /
                              (result + 1)
                          ).toFixed(2);
                          // console.log("value",value)
                          options[this.state.selectedOptionIndex].netto = value;
                          options[this.state.selectedOptionIndex].price = value;
            }
            else{
            if( options[this.state.selectedOptionIndex].netto){
              // console.log(perc.value)
              

              
              
              // console.log("value",value)
             options[this.state.selectedOptionIndex].brutto = value;
            options[this.state.selectedOptionIndex].price = options[this.state.selectedOptionIndex].netto;
            }
          }
            // else{
            
            //  options[this.state.selectedOptionIndex].brutto = 0;
            //  options[this.state.selectedOptionIndex].price = 0;
            // }
          
          } else if (this.context.priceType === "Brutto") {
            if(this.state.bruttoChange===false){
              
                          options[this.state.selectedOptionIndex].brutto = value;
                         options[this.state.selectedOptionIndex].price = value;
            }
            else{
            if (options[this.state.selectedOptionIndex].brutto){
             
             options[this.state.selectedOptionIndex].netto = value;
            options[this.state.selectedOptionIndex].price = options[this.state.selectedOptionIndex].brutto;
            }
          }
          //  else{
          //  options[this.state.selectedOptionIndex].netto = 0;
          //  options[this.state.selectedOptionIndex].price = 0;
          //  }
          
          }
        }
        else{
         options[this.state.selectedOptionIndex].price=0
        }
        // console.log("perc",perc)
      }
      // else{
      //   options[this.state.selectedOptionIndex].price=0
        
      // }
      if (perc.id !== per.id) {

        perc.checked = false;
      }
    });

    this.setState({ options });
    this.props.updatePriceOptionConfiguration(this.state.options);

  };
  functionPriceValidation=()=>{
    const { options}= this.state
    const min=Number(options[this.state.selectedOptionIndex].numerically.minimumNumber)
    const max=Number(options[this.state.selectedOptionIndex].numerically.maximumNumber)
    const price=Number(options[this.state.selectedOptionIndex].price)
    //  console.log('min', min, max)
    if(min && max && !price && price!==0){
      const msg="Preis eingeben"
        // valid=false
        return msg
    }
    else if(min && !price && price!==0){
      const msg="Preis eingeben"
        // valid=false
        return msg
    }
    else if(max && !price && price!==0){
      const msg="Preis eingeben"
        // valid=false
        return msg
    }
    else{
        // valid=true
        return null
    }
    
  }
  setPriceOptionSelected = () =>
    this.setState({ priceOptionSelected: true, tagOptionSelected: false });

  setTagOptionSelected = () =>
    this.setState({ priceOptionSelected: false, tagOptionSelected: true });

  renderPriceOption = selectedPriceOption => {
    switch (selectedPriceOption) {
      case "flat-rate":
        return (
           <>
          <div className="price-option-btn-container">
            <div className="price-option-btn">{this.props.t('flat_rate')}</div>
            <button className="clear-option" onClick={this.resetPriceOption}>
              <img src={minusIcon} alt="" />
            </button>
            </div>
            <div className="numerically-options">
              <div className="left-text" style={{paddingLeft:"86px"}}>
              {`${this.props.t('Unit')} :`}
              </div>
              <div className="right-options">
                <input
                  maxLength="15"
                  className="unit"
                  style={{marginLeft:"-24px"}}
                  type="text"
                  placeholder={this.props.t('Add your Unit here')}
                  value={
                    this.state.options[this.state.selectedOptionIndex]
                      .numerically.unit
                  }
                  onChange={this.setUnit}
                />
              </div>
            </div>
          </>
        );
      case "number-guests":
        return (
          <div className="price-option-btn-container">
            <div className="price-option-btn">{this.props.t('Anzahl')}</div>
            <button className="clear-option" onClick={this.resetPriceOption}>
              <img src={minusIcon} alt="" />
            </button>
          </div>
        );
      case "numerically":
        return (
          <>
            <div className="price-option-btn-container">
              <div className="price-option-btn"> {this.props.t('Numerically')}</div>
              <button className="clear-option" onClick={this.resetPriceOption}>
                <img src={minusIcon} alt="" />
              </button>
            </div>
            {
            (this.functionPriceValidation())?
            <div className="validationMsg" style={{margin:"2px 10px 0px"}}>{this.functionPriceValidation()}
            </div>
            : null
          }
             {
            (this.functionValidation())?
            <div className="validationMsg" style={{margin:"2px 10px 0px"}}>{this.functionValidation()}
            </div>
            : null
          }

            <div className="numerically-options">
              <div className="left-text">
              {this.props.t('Minimum_number')}
                <br />
                {this.props.t('Max_number')} 
                <br />
                 {this.props.t('stages')}
                <br />
               {`${this.props.t('Season')} 1:`}
                <br />
                {`${this.props.t('Season')} 2:`}
                <br />
                {`${this.props.t('Season')} 3:`}
                <br />
                {`${this.props.t('Unit')} :`}
              </div>
              <div className="right-options">
                <div style={{display:"flex", height:"30px", marginTop:"5px"}}>
                <input
                  type="number"
                  className="small-input"
                  placeholder="000"
                  style={(this.functionValidation()) && {border:"1px solid red"}}
                  value={
                    this.state.options[this.state.selectedOptionIndex]
                      .numerically.minimumNumber
                  }
                  onChange={this.setMinimumNumber}
                />
                <label className="calculate">
                  <input
                    type="checkbox"
                    checked={
                      this.state.options[
                        this.state.selectedOptionIndex
                      ].numerically.minimumNumberCalculate
                    }
                    onChange={this.setMinimumNumberCalculate}
                  />
                  <span>{this.props.t('Just_calculate')}</span>
                </label>
                </div>
                {/* <br /> */}
                {/* {
                  console.log('valid', valid)
                } */}
                <div style={{display:"flex", height:"30px", marginTop:"2px"}}>
                <input
                  type="number"
                  className="small-input"
                  placeholder="000"
                  style={(this.functionValidation()) && {border:"1px solid red"}}
                  value={
                    this.state.options[this.state.selectedOptionIndex]
                      .numerically.maximumNumber
                  }
                  onChange={this.setMaximumNumber}
                />
                <label className="calculate">
                  <input
                    type="checkbox"
                    checked={
                      this.state.options[
                        this.state.selectedOptionIndex
                      ].numerically.maximumNumberCalculate
                    }
                    onChange={this.setMaximumNumberCalculate}
                  />
                  <span>{this.props.t('Just_calculate')}</span>
                </label>
                </div>
                {/* <br /> */}
                <div style={{height:"30px",marginTop:"2px"}}>
                <input
                  type="number"
                  className="small-input"
                  placeholder="000"
                  // min="1"
                  style={{height:"20px"}}
                  value={
                    this.state.options[this.state.selectedOptionIndex]
                      .numerically.stage
                  }
                  onKeyDown={e => {
                    if (e.key === "Backspace") return;
                    let key = Number(e.key);
                    if (isNaN(key) || e.key === null || e.key === ' ') e.preventDefault();
                }}
                  onChange={this.setStage}
                />
                </div>
                {/* <br /> */}
                <div style={{display:"flex", height: "30px", marginTop:"2px"}}>
                <input
                  type="number"
                  className="small-input"
                  placeholder="000"
                  value={
                    this.state.options[this.state.selectedOptionIndex]
                      .numerically.relay1
                  }
                  onKeyDown={e => {
                    if (e.key === "Backspace") return;
                    let key = Number(e.key);
                    if (isNaN(key) || e.key === null || e.key === ' ') e.preventDefault();
                }}
                  onChange={this.setRelay1}
                />
              <div>
                <label className="more">
                  <input
                    type="radio"
                    name="staffel1"
                    checked={
                      this.state.options[
                        this.state.selectedOptionIndex
                      ].numerically.relay1Alle === false
                    }
                    onChange={this.handleRelay1}
                    value="false"
                  />
                  <span>{this.props.t('Each_further')}</span>
                </label>
                </div>
                <div>
                <label className="alle">
                  <input
                    type="radio"
                    name="staffel1"
                    checked={
                      this.state.options[
                        this.state.selectedOptionIndex
                      ].numerically.relay1Alle === true
                    }
                    onChange={this.handleRelay1}
                    value="true"
                  />
                  <span>{this.props.t('all')}</span>
                </label>
                </div>
                <div className={`last-input1 currency-${this.context.currencyFormat.replace('.',"").replace('$',"dollor")}`}>
                <input
                  type="text"
                  className="last-input"
                  // value={
                  //   this.state.options[this.state.selectedOptionIndex]
                  //     .numerically.relay1Price
                  // }
                  value={this.convertedRelay1PriceValues()}
                  onChange={this.setRelay1Price}
                  onKeyPress={(evt) => {
                    var charCode = evt.which ? evt.which : evt.keyCode;
                    let inputFormat= "";
                    inputFormat = (this.context.languageFormat==="en") ? charCode !== 46 : charCode !== 44
                    if (inputFormat &&
                      charCode > 31 &&
                      (charCode < 48 || charCode > 57)
                    ) {
                      //console.log("in alse")
                      // return false;
                      evt.preventDefault();
                    }
                    return true;
                  }}
                />
                </div>
                </div>
                {/* <br /> */}
                <div style={{display:"flex", height: "30px", marginTop:"2px"}}>
                <input
                  type="number"
                  className="small-input"
                  placeholder="000"
                  value={
                    this.state.options[this.state.selectedOptionIndex]
                      .numerically.relay2
                  }
                  onChange={this.setRelay2}
                  onKeyDown={e => {
                    if (e.key === "Backspace") return;
                    let key = Number(e.key);
                    if (isNaN(key) || e.key === null || e.key === ' ') e.preventDefault();
                }}
                />
                <div>
                <label className="more">
                  <input
                    type="radio"
                    name="staffel2"
                    value="false"
                    checked={
                      this.state.options[
                        this.state.selectedOptionIndex
                      ].numerically.relay2Alle === false
                    }
                    onChange={this.handleRelay1}
                  />
                  <span>{this.props.t('Each_further')}</span>
                </label>
                </div>
                <div>
                <label className="alle">
                  <input
                    type="radio"
                    name="staffel2"
                    value="true"
                    checked={
                      this.state.options[
                        this.state.selectedOptionIndex
                      ].numerically.relay2Alle === true
                    }
                    onChange={this.handleRelay1}
                  />
                  <span>{this.props.t('all')}</span>
                </label>
                </div>
                <div className={`last-input1 currency-${this.context.currencyFormat.replace('.',"").replace('$',"dollor")}`}>
                <input
                  type="text"
                  min="0"
                  className="last-input"
                  // value={
                  //   this.state.options[this.state.selectedOptionIndex]
                  //     .numerically.relay2Price
                  // }
                  value={this.convertedRelay2PriceValues()}
                  onChange={this.setRelay2Price}
                  onKeyPress={(evt) => {
                    var charCode = evt.which ? evt.which : evt.keyCode;
                    let inputFormat= "";
                    inputFormat = (this.context.languageFormat==="en") ? charCode !== 46 : charCode !== 44
                    if (inputFormat &&
                      charCode > 31 &&
                      (charCode < 48 || charCode > 57)
                    ) {
                      //console.log("in alse")
                      // return false;
                      evt.preventDefault();
                    }
                    return true;
                  }}

                />
                </div>
                </div>
                {/* <br /> */}
                <div style={{display:"flex", height: "30px", marginTop:"2px"}}>
                <input
                  type="number"
                  className="small-input"
                  placeholder="000"
                  // min="1"
                  value={
                    this.state.options[this.state.selectedOptionIndex]
                      .numerically.relay3
                  }
                  onChange={this.setRelay3}
                  onKeyDown={e => {
                    if (e.key === "Backspace") return;
                    let key = Number(e.key);
                    if (isNaN(key) || e.key === null || e.key === ' ') e.preventDefault();
                }}
                />
                <div>
                <label className="more">
                  <input
                    type="radio"
                    name="staffel3"
                    value="false"
                    checked={
                      this.state.options[
                        this.state.selectedOptionIndex
                      ].numerically.relay3Alle === false
                    }
                    onChange={this.handleRelay1}
                  />
                  <span>{this.props.t('Each_further')}</span>
                </label>
                </div>
                <div>
                <label className="alle">
                  <input
                    type="radio"
                    name="staffel3"
                    value="true"
                    checked={
                      this.state.options[
                        this.state.selectedOptionIndex
                      ].numerically.relay3Alle === true
                    }
                    onChange={this.handleRelay1}
                  />
                  <span>{this.props.t('all')}</span>
                </label>
                </div>
                <div className={`last-input1 currency-${this.context.currencyFormat.replace('.',"").replace('$',"dollor")}`}>
                <input
                  type="text"
                  className="last-input"
                  // value={
                  //   this.state.options[this.state.selectedOptionIndex]
                  //     .numerically.relay3Price
                  // }
                  value={ this.convertedRelay3PriceValues()}
                  onChange={this.setRelay3Price}
                  onKeyPress={(evt) => {
                    var charCode = evt.which ? evt.which : evt.keyCode;
                    let inputFormat= "";
                    inputFormat = (this.context.languageFormat==="en") ? charCode !== 46 : charCode !== 44
                    if (inputFormat &&
                      charCode > 31 &&
                      (charCode < 48 || charCode > 57)
                    ) {
                      //console.log("in alse")
                      // return false;
                      evt.preventDefault();
                    }
                    return true;
                  }}
                />
                </div>
                </div>
                <input
                  maxLength="15"
                  className="unit"
                  type="text"
                  placeholder={this.props.t('Add your Unit here')}
                  value={
                    this.state.options[this.state.selectedOptionIndex]
                      .numerically.unit
                  }
                  onChange={this.setUnit}
                />
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  str2bool = value => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  selectPriceOption = priceType => {
    const options = this.state.options;
    options[this.state.selectedOptionIndex].priceType = priceType;
    if (priceType === "flat-rate")
      options[this.state.selectedOptionIndex].priceTypeId = 1;
    else if (priceType === "number-guests")
      options[this.state.selectedOptionIndex].priceTypeId = 2;
    else if (priceType === "numerically")
      options[this.state.selectedOptionIndex].priceTypeId = 3;
    else options[this.state.selectedOptionIndex].priceTypeId = null;
    this.setState({ options });
    this.props.updatePriceOptionConfiguration(this.state.options);
  };

  resetPriceOption = () => {
    const options = this.state.options;
    options[this.state.selectedOptionIndex].priceType = "";
    this.setState({ options });
    this.props.updatePriceOptionConfiguration(this.state.options);
  };

  setRadioInputPrice = e => {
    const options = this.state.options;
    options[this.state.selectedOptionIndex].price = e.target.value;
    this.setState({ options });
    this.props.updatePriceOptionConfiguration(this.state.options);
  };

  clearPrice = () => {
    const options = this.state.options;
    options[this.state.selectedOptionIndex].price = "";
    this.setState({ options });
    this.props.updatePriceOptionConfiguration(this.state.options);
  };

  setMinimumNumber = e => {
    const options = this.state.options;
    options[this.state.selectedOptionIndex].numerically.minimumNumber =
      e.target.value;
    this.setState({ options });
    this.props.updatePriceOptionConfiguration(this.state.options);

  };

  setMinimumNumberCalculate = e => {
    const options = this.state.options;
    options[
      this.state.selectedOptionIndex
    ].numerically.minimumNumberCalculate = !options[
      this.state.selectedOptionIndex
    ].numerically.minimumNumberCalculate;
    this.setState({ options });
  };

  setMaximumNumber = e => {
    const options = this.state.options;
    options[this.state.selectedOptionIndex].numerically.maximumNumber =
      e.target.value;
    this.setState({ options });
    this.props.updatePriceOptionConfiguration(this.state.options);

  };

  setMaximumNumberCalculate = () => {
    const options = this.state.options;
    options[
      this.state.selectedOptionIndex
    ].numerically.maximumNumberCalculate = !options[
      this.state.selectedOptionIndex
    ].numerically.maximumNumberCalculate;
    this.setState({ options });
  };

  setStage = e => {
    const options = this.state.options;
    options[this.state.selectedOptionIndex].numerically.stage =
      e.target.value;
    this.setState({ options });
    
  };
 
  setRelay1 = e => {
    const options = this.state.options;
      options[this.state.selectedOptionIndex].numerically.relay1 =
        e.target.value;
      this.setState({ options });
   
  };

  handleRelay1 = e => {
    // console.log('hi handleRelay', e.target.value)
    const options = this.state.options;
    options[
      this.state.selectedOptionIndex
    ].numerically.relay1Alle = this.str2bool(e.target.value);
    options[
          this.state.selectedOptionIndex
        ].numerically.relay2Alle = this.str2bool(e.target.value);
    options[
          this.state.selectedOptionIndex
        ].numerically.relay3Alle = this.str2bool(e.target.value);
    this.setState({ options });
  };

  setRelay1Price = e => {
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    const options = this.state.options;
    options[this.state.selectedOptionIndex].numerically.relay1Price =
      textValue;
    this.setState({ options });
  };

  convertedRelay1PriceValues = () => {
    if(this.context.languageFormat!=="en" && this.state.options[this.state.selectedOptionIndex].numerically.relay1Price)
    {
      let inputValue = this.state.options[this.state.selectedOptionIndex].numerically.relay1Price
      //inputValue.replace('.',",")
    return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && this.state.options[this.state.selectedOptionIndex].numerically.relay1Price)
    {
      return this.state.options[this.state.selectedOptionIndex].numerically.relay1Price
    }
    else{
      return ""
    }
  }

  setRelay2 = e => {
    const options = this.state.options;
    options[this.state.selectedOptionIndex].numerically.relay2 =
      e.target.value;
    this.setState({ options });
  };

  // handleRelay2 = e => {
  //   const options = this.state.options;
  //   options[
  //     this.state.selectedOptionIndex
  //   ].numerically.relay2Alle = this.str2bool(e.target.value);
  //   this.setState({ options });
  // };

 
  setRelay2Price = e => {
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    const options = this.state.options;
    options[this.state.selectedOptionIndex].numerically.relay2Price =
      textValue;
    this.setState({ options });
  };
  convertedRelay2PriceValues = () => {
    if(this.context.languageFormat!=="en" && this.state.options[this.state.selectedOptionIndex].numerically.relay2Price)
    {
      let inputValue = this.state.options[this.state.selectedOptionIndex].numerically.relay2Price
      //inputValue.replace('.',",")
    return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && this.state.options[this.state.selectedOptionIndex].numerically.relay2Price)
    {
      return this.state.options[this.state.selectedOptionIndex].numerically.relay2Price
    }
    else{
      return ""
    }
  }

  setRelay3 = e => {
    const options = this.state.options;
      options[this.state.selectedOptionIndex].numerically.relay3 =
        e.target.value;
      this.setState({ options });
  };

  // handleRelay3 = e => {
  //   const options = this.state.options;
  //   options[
  //     this.state.selectedOptionIndex
  //   ].numerically.relay3Alle = this.str2bool(e.target.value);
  //   this.setState({ options });
  // };

  setRelay3Price = e => {
    let textValue = e.target.value;
    textValue = this.context.languageFormat!=="en" ? textValue.replace(',',".") : textValue ;
    const options = this.state.options;
    options[this.state.selectedOptionIndex].numerically.relay3Price =
      textValue;
    this.setState({ options });
  };
  
  convertedRelay3PriceValues = () => {
    if(this.context.languageFormat!=="en" && this.state.options[this.state.selectedOptionIndex].numerically.relay3Price)
    {
      let inputValue = this.state.options[this.state.selectedOptionIndex].numerically.relay3Price
      //inputValue.replace('.',",")
    return inputValue.replace('.',",")
    }
    else if (this.context.languageFormat==="en"  && this.state.options[this.state.selectedOptionIndex].numerically.relay3Price)
    {
      return this.state.options[this.state.selectedOptionIndex].numerically.relay3Price
    }
    else{
      return ""
    }
  }

  setUnit = e => {
    const options = this.state.options;
    options[this.state.selectedOptionIndex].numerically.unit =
      e.target.value;
    this.setState({ options });
  };

//   handleTagChange = (newValue, x) => {
//       const options = this.state.options;
//       options[this.state.selectedOptionIndex].tags=newValue;
//       this.setState({ options });
//       if(x.action==="create-option"){
//         let tags=Object.assign([],this.state.tags)
//         const tag={
//           value:newValue[newValue.length-1].value,
//           label:newValue[newValue.length-1].label,
//         }
//         tags.push(tag)
//         const tagOptions=Object.assign([], this.state.tagOptions)
//         tagOptions.push(tag)
//         this.setState({ tags,
//         tagOptions
//       });
//         const postData={
//           tags,
//           venueId:this.context.venueId
//         }
//         if(this.state.newEntry && !this.state.tagOptions.length){
//           axios.post(`${baseUrl}api/Questions/Tag`, postData)
//           .then((res)=>{
//             // console.log(res.data)
//           })
//           .catch((err)=>{
//             console.log(err)
//           })
//         }
//         else{
//           axios.put(`${baseUrl}api/Questions/Tag/${this.context.venueId}`, postData)
//           .then((res)=>{
//             // console.log(res.data)
//           })
//           .catch((err)=>{
//             console.log(err)
//           })
//         }
//       }

//   };
 
 
  render() {
    const {options}=this.state
    const VATPercent= [
      { id: uuidv4(), checked: true, value: "19" },
      { id: uuidv4(), checked: false, value: "7" },
    ]
    options[this.state.selectedOptionIndex].description =
    options[this.state.selectedOptionIndex].description
    ?options[this.state.selectedOptionIndex].description
    : "";
    if (!options[this.state.selectedOptionIndex].VATPercent) {
      options[this.state.selectedOptionIndex].VATPercent = VATPercent;
    }
    return (
			<div
				className="guests-price"
				style={{
					background: '#d1d3d4',
					position: 'relative',
					zIndex: '1',
					margin: '10px 5px',
					padding: '10px 0px',
					textAlign: 'center',
					height: this.props.height,
					width: this.props.width,
					border: '2px dashed #bcbec0',
				}}
				//   "component-container option-component"
			>
				{/* {console.log('radio configuration', this.state.options)} */}
				<button
					className={`price-selection ${
						this.state.priceOptionSelected ? 'selected' : ''
					}`}
					onClick={this.setPriceOptionSelected}>
					{this.context.currencyFormat}
				</button>
				{/* <button
          className={`tag-selection ${
            this.state.tagOptionSelected ? "selected" : ""
          }`}
          onClick={this.setTagOptionSelected}
        >
          <img
            src={this.state.tagOptionSelected ? tagInverseIcon : tagIcon}
            alt=""
          />
        </button> */}

				<img
					src={crossSmallIcon}
					onClick={this.props.handleClose}
					alt=""
					style={{
						float: 'right',
						marginRight: '0.5rem',
						//   marginTop:"-28px",
						width: '1rem',
						height: '1rem',
					}}
				/>
				{this.state.priceOptionSelected && (
					<div className="price-options" style={{ marginTop: '-10px' }}>
						<div
							className="price-input"
							// className={`price-input
							// currency-${this.context.currencyFormat.replace('.',"").replace('$',"dollor")}
							// `}
						>
							{/* <input
                type="number"
                value={
                  this.state.options[this.state.selectedOptionIndex]
                    .price
                }
                onChange={this.setRadioInputPrice}
                placeholder="00.00"
                style={(this.functionPriceValidation()) && {border:"1px solid red"}}  
              /> */}
							{this.state.options[this.state.selectedOptionIndex].price !==
								'' && (
								<button
									// className="clear-price"
									style={{
										top: '20px',
										position: 'relative',
									}}
									onClick={this.clearPrice}>
									<img src={minusIcon} alt="" />
								</button>
							)}
							<div
								style={{
									justifyContent: 'center',
									display: 'flex',
									paddingTop: '30px',
								}}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										width: 'min-content',
										height: 'min-content',
										marginLeft: '103px',
										color: 'rgb(149, 150, 155)',
										fontSize: '15px',
										fontWeight: '300',
									}}>
									<h6>{this.props.t('Netto')}</h6>
								</div>
								<div
									style={{
										background:
											this.context.priceType === 'Netto'
												? 'rgb(170, 172, 175)'
												: '#e6e7e8',
										// marginRight: "20px",
										marginLeft: '-40px',
										marginTop: '20px',
										content: this.context.currencyFormat,
										width: '60%',
										borderRadius: '3px',
									}}
									className="netto-brutto">
									{/* here was span */}
									{/* <br /> */}
									<div style={{ display: 'flex' }}>
										<span
											style={{
												marginTop: '5px',
												fontSize: '25px',
												color: '#939598',
											}}>
											{this.context.currencyFormat}
										</span>
										<input
											id="euro"
											type="text"
											onChange={this.onNettoChange}
											onFocus={() => {
												this.setState({ bruttoChange: false });
											}}
											style={{
												background:
													this.context.priceType === 'Netto'
														? 'rgb(170, 172, 175)'
														: '#e6e7e8',
												marginRight: '20px',
												width: '60%',
												color: 'black',
												content: this.context.currencyFormat,
												paddingLeft: '4px',
												borderRadius: '3px',
											}}
											value={this.convertedNettoValues()}
											// this.state.options[this.state.selectedOptionIndex].netto
											// ? this.state.options[this.state.selectedOptionIndex].netto
											// : ""
											placeholder={
												this.context.languageFormat === 'en' ? '00.00' : '00,00'
											}
											onKeyPress={(evt) => {
												var charCode = evt.which ? evt.which : evt.keyCode;

												let inputFormat = '';
												inputFormat =
													this.context.languageFormat === 'en'
														? charCode !== 46
														: charCode !== 44;
												if (
													inputFormat &&
													charCode > 31 &&
													(charCode < 48 || charCode > 57)
												) {
													//console.log("in alse")
													// return false;
													evt.preventDefault();
												}

												return true;
											}}
											onKeyDown={(e) => {
												if (e.key === 'Enter') this.onEnterHitAtNetto(e);
											}}
											// disabled={this.context.priceType == "Brutto" && true}
										/>
									</div>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										width: 'min-content',
										height: 'min-content',
										marginLeft: '25px',
										color: '#939598',
										fontSize: '15px',
										fontWeight: '300',
										borderRadius: '3px'
									}}>
									<h6>{this.props.t('Brutto')} </h6>
								</div>
								<div
									style={{
										background:
											this.context.priceType === 'Brutto'
												? 'rgb(170, 172, 175)'
												: '#e6e7e8',
										content: this.context.currencyFormat,
										width: '60%',
										marginRight: '88px',
										marginLeft: '-40px',
										marginTop: '20px',
										borderRadius: '3px',
									}}
									className="netto-brutto">
									{/* here was span */}
									{/* <br /> */}
									<div style={{ display: 'flex' }}>
										<span
											style={{
												marginTop: '5px',
												fontSize: '25px',
												color: '#939598',
											}}>
											{this.context.currencyFormat}
										</span>
										<input
											className="euro"
											type="text"
											style={{
												width: '60%',
												paddingLeft: '4px',
												color: 'black',
												borderRadius: '3px',
												background:
													this.context.priceType === 'Brutto'
														? 'rgb(170, 172, 175)'
														: '#e6e7e8',
												content: this.context.currencyFormat,
											}}
											// value={
											//   this.state.options[this.state.selectedOptionIndex].brutto
											//     ? this.state.options[this.state.selectedOptionIndex].brutto
											//     : ""
											// }
											value={this.convertedBruttoValues()}
											onChange={this.onBruttoChange}
											onFocus={() => {
												this.setState({ bruttoChange: true });
											}}
											placeholder={
												this.context.languageFormat === 'en' ? '00.00' : '00,00'
											}
											onKeyPress={(evt) => {
												var charCode = evt.which ? evt.which : evt.keyCode;
												let inputFormat = '';
												inputFormat =
													this.context.languageFormat === 'en'
														? charCode !== 46
														: charCode !== 44;
												if (
													inputFormat &&
													charCode > 31 &&
													(charCode < 48 || charCode > 57)
												) {
													console.log('in alse');
													// return false;
													evt.preventDefault();
												}

												return true;
											}}
											onKeyDown={(e) => {
												if (e.key === 'Enter') this.onEnterHitAtBrutto(e);
											}}
											// disabled={this.context.priceType == "Netto" && true}
										/>
									</div>
								</div>
							</div>
							<br />
						</div>
						<br />
						<div
							className="row"
							style={{ marginLeft: '0px', marginRight: '58px' }}>
							{this.state.options[
								this.state.selectedOptionIndex
							]?.VATPercent?.map((per, index) => {
								return (
									<div
										className="col"
										style={{ marginLeft: '74px', marginRight: '-22px' }}>
										<label style={{ display: 'flex' }} className="calculate">
											<div
												style={per.checked ? styles.selected : styles.circle}
												onClick={() => this.setCheckedPercentage(per)}
											/>
											<div style={{ display: 'flex' }}>
												<input
													style={{
														background: '#d0d2d4',
														width: '36px',
														color: '#939598',
														paddingLeft: '10px',
														border: 'none',
													}}
													type="number"
													value={per.value}
													onChange={(e) => {
														this.setVAT(e, per);
													}}
													placeholder={
														this.context.languageFormat === 'en'
															? '00.00'
															: '00,00'
													}
													onKeyPress={(evt) => {
														var charCode = evt.which ? evt.which : evt.keyCode;
														// console.log("charcode",charCode)
														if (
															charCode !== 46 &&
															charCode > 31 &&
															(charCode < 48 || charCode > 57)
														) {
															// console.log("in alse")
															// return false;
															evt.preventDefault();
														}

														return true;
													}}
												/>
												<span style={{ fontSize: '20px', color: '#939598' }}>
													%
												</span>
												{index > 1 && (
													<img
														src={crossSmallIcon}
														onClick={() => this.removeVATPercentage(index)}
														alt=""
														style={{
															float: 'right',
															width: '10px',
															height: '10px',
														}}
													/>
												)}
											</div>
										</label>
									</div>
								);
							})}
							<div className="col">
								<img src={addIcon} alt="" onClick={this.addVATPercentage} />
							</div>
						</div>
						<div style={{}}>
							<h6 style={{ color: '#939598' }}>
								{this.props.t('Invoice Text')}
							</h6>
							<input
								maxLength="50"
								className="invoice-text"
								type="text"
								placeholder={this.props.t('Invoice Text')}
								value={options[this.state.selectedOptionIndex].description}
								onChange={this.setInvoiceText}
							/>
						</div>
						<div className="plus-icon">
							<img src={plusIcon} alt="" />
						</div>
						{this.renderPriceOption(
							this.state.options[this.state.selectedOptionIndex].priceType
						)}
						{this.state.options[this.state.selectedOptionIndex].priceType ===
							'' && (
							<>
								<button
									className="price-option-btn"
									onClick={() => this.selectPriceOption('flat-rate')}>
									{this.props.t('flat_rate')}
								</button>
								<button
									className="price-option-btn"
									onClick={() => this.selectPriceOption('number-guests')}>
									{this.props.t('Anzahl')}
								</button>
								<button
									className="price-option-btn"
									onClick={() => this.selectPriceOption('numerically')}>
									{this.props.t('Numerically')}
								</button>
							</>
						)}
					</div>
				)}
				{/* {this.state.tagOptionSelected && (
          <div className="tag-options">
                <div className="select-container">
                  <CreatableSelect
                    isMulti
                    className="tag-select"
                    classNamePrefix="tag"
                    // isClearable
                    value={this.state.options[
                      this.state.selectedOptionIndex
                    ].tags}
                    onChange={this.handleTagChange}
                    options={this.state.tagOptions}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeperator: () => null
                    }}
                    // value={{ label: tag }}
                  />
                
                </div>
          </div>
        )} */}
			</div>
		);
  }
}

export default withTranslation() (GuestPriceConfigurations);

const styles = {
  selected: {
    background: `url(${crossSmallIcon}) ,rgb(230, 231, 232) none repeat scroll 0% 0%`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    // width: "24px",
    // height: "24px",
    minWidth: "18px",
    minHeight: "20px",
    height: "20px",
    display: "inline-block",
    textAlign: "center",
    // background: "transparent",
    // borderRadius: "12px",
    // border: "1px solid black",
    outline: "none",
    cursor: "pointer",
  },
  circle: {
    minWidth: "18px",
    minHeight: "20px",
    height: "20px",
    display: "inline-block",
    textAlign: "center",
    background: "rgb(230, 231, 232) none repeat scroll 0% 0%",
    // borderRadius: "12px",
    // border: "1px solid black",
    outline: "none",
    cursor: "pointer",
  },
};


import React, { Component } from "react";
import linkIcon from "../../assets/link.svg";
class TableOptionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableInput: {
        rows: [
          {
            columns: [
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
            ],
          },
          {
            columns: [
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
            ],
          },
          {
            columns: [
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
            ],
          },
          {
            columns: [
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
            ],
          },
          {
            columns: [
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
            ],
          },
        ],
        checkboxes: {
          rows: [0, 0, 0, 0, 0],
          columns: [0, 0, 0, 0, 0, 0],
        },
      },
      rowSelectionDisabled: false,
      columnSelectionDisabled: false,
      tableSelected:false
      // isTableInteractive:false,
      // type:"text"
    };
  }

  componentDidMount = () => {
    let tableInput;
    if (this.props.tableInput) {
      tableInput = this.props.tableInput;
      tableInput.rows.forEach((item) => {
        item.columns.forEach((_item) => {
          _item.selected = false;
        });
      });
    } else {
      tableInput = this.state.tableInput;
      tableInput.rows.forEach((item) => {
        item.columns.forEach((_item) => {
          _item.interactive = true;
          _item.type = "text";
        });
      });
    }
    this.props.updateTableInput(tableInput);
    this.setState({ tableInput });
    };

  handleRowSelection = (index, e) => {
    // console.log('row', index)
    const tableInput = this.state.tableInput;
    tableInput.rows[index].columns.forEach((item) => {
      item.selected = e.target.checked ? true : false;
      // this.props.toggleTableOptionType(item.selected);
    });
    if (e.target.checked) {
      tableInput.checkboxes.rows[index] = 1;
    } else {
      tableInput.checkboxes.rows[index] = 0;
    }
    this.setState({ tableInput });
    let isColumnCheckboxSelected = false;
    // document.querySelectorAll(".row-checkbox").forEach(item => {
    //   item.checked && (isColumnCheckboxSelected = true);
    // });
    tableInput.checkboxes.rows.forEach((row) => {
      row && (isColumnCheckboxSelected = true);
    });
    isColumnCheckboxSelected
      ? this.setState({ columnSelectionDisabled: true })
      : this.setState({ columnSelectionDisabled: false });

    // var z=false
    // document.getElementsByName("tblCheckbox").forEach((i)=>{
    //   // console.log('i', i.checked)
    //   if(i.checked){
    //     z=true
    //   }
    // })
    // this.props.toggleTableOptionType(z);
    var z = false;
    tableInput.rows.forEach((row) => {
      row.columns.forEach((col) => {
        // console.log('cols', col?.selected)
        if (col?.selected) {
          z = true;
        }
      });
    });
    this.props.toggleTableOptionType(z, tableInput);
  };

  handleColumnSelection = (index, e) => {
    // const tableInput = this.state.tableInput;
    // console.log('tableinput', tableInput)
    // tableInput.rows.forEach(item => {
    //   console.log('item', tableInput)
    //   item.columns[index].selected = e.target.checked ? true : false;
    //   this.props.toggleTableOptionType(item.columns[index].selected);
    // });
    // console.log('colmn', index, e.target.checked)
    const tableInput = this.state.tableInput;
    tableInput.rows.forEach((item) => {
      // console.log('col item', item)
      item.columns[index].selected = e.target.checked ? true : false;
    });

    if (e.target.checked) {
      tableInput.checkboxes.columns[index] = 1;
    } else {
      tableInput.checkboxes.columns[index] = 0;
    }
    this.setState({ tableInput });
    let isRowCheckboxSelected = false;
    // document.querySelectorAll(".column-checkbox").forEach(item => {
    //   item.checked && (isRowCheckboxSelected = true);
    // });
    tableInput.checkboxes.columns.forEach((col) => {
      col && (isRowCheckboxSelected = true);
    });

    isRowCheckboxSelected
      ? this.setState({ rowSelectionDisabled: true })
      : this.setState({ rowSelectionDisabled: false });

    //   var z=false
    // document.getElementsByName("tblCheckbox").forEach((i)=>{
    //   if(i.checked){
    //     z=true
    //   }
    // })

    // this.props.toggleTableOptionType(z);
    var z = false;
    tableInput.rows.forEach((row) => {
      row.columns.forEach((col) => {
        // console.log('cols', col?.selected)
        if (col?.selected) {
          z = true;
        }
      });
    });
    this.props.toggleTableOptionType(z, tableInput);
  };

  handleTableCellValue = (index, _index, e) => {
    const tableInput = this.state.tableInput;
    tableInput.rows[index].columns[_index].value = e.target.value;
    this.props.updateTableInput(tableInput);
    this.setState({ tableInput });
  };

  handleClicked = () => {
    // console.log('clicked', this.state.tableInput)
    // console.log('optn', this.state.tableInput.rows.length,this.state.tableInput.rows[0].columns.length)
    this.props.setOptionSelected(this.props.index, "table", null, {
      tableInput: this.state.tableInput,
    });
  };
  // static getDerivedStateFromProps = (nextProps, prevState) => {
  //   console.log('nextProps', nextProps.tableInfo)
  //   return {
  //     isTableInteractive:nextProps.tableInfo?nextProps.tableInfo.isTableInteractive:prevState.isTableInteractive,
  //     type:nextProps.tableInfo?nextProps.tableInfo.type:prevState.type

  //   }
  // }
  showLinkComponent = (e) => {
    e.stopPropagation();
    this.props.toggleLinkOption(null, null, this.props.index);
  };
  display=(value)=>{
    this.setState({tableSelected:value})
  }
  render() {
    return (
      <div
        className="table-option-component"
        onClick={this.handleClicked}
        style={{ padding: "33px 37px 10px 10px", border: "2px dashed #bcbec0",
        background:this.props.layoutObject[this.props.index[0]]?.row[this.props.index[1]]?.column[this.props.index[2]]?.selected===true? "#aaacaf":"" 
      }}
  
      tabIndex="0"
      onFocus={()=>{this.display(true)}}
      onBlur={()=>{this.display(false)}}
      >
        <button
          className="template-link-btn"
          onClick={(e) => {
            this.showLinkComponent(e);
          }}
        >
          <img src={linkIcon} alt="" />
        </button>
        <div className="table-container">
          {/* {  console.log('table', this.state.tableInput)} */}

          {/* { console.log('table', this.state.isTableInteractive, this.state.type)} */}
          {this.state.tableInput.rows.map((item, index) => (
            <React.Fragment key={index}>
              {index === 0 && (
                <div className="checkbox-row">
                  <div className="checkbox-column"></div>
                  {item.columns.map((_item, _index) => (
                    <div
                      className="column"
                      key={_index}
                      style={{
                        width: `calc(100% / ${item.columns.length} - 30px / ${item.columns.length})`,
                      }}
                    >
                      <input
                        type="checkbox"
                        className="column-checkbox"
                        name="tblCheckbox"
                        disabled={this.state.columnSelectionDisabled}
                        onChange={(e) => this.handleColumnSelection(_index, e)}
                        autoComplete="off"
                      />
                    </div>
                  ))}
                </div>
              )}
              <div className="column-row">
                <div className="checkbox-column">
                  <input
                    type="checkbox"
                    className="row-checkbox"
                    name="tblCheckbox"
                    disabled={this.state.rowSelectionDisabled}
                    onChange={(e) => this.handleRowSelection(index, e)}
                    autoComplete="off"
                  />
                </div>
                {item.columns.map((_item, _index) => (
                  <div
                    className={`column ${_item.selected ? "selected" : ""}`}
                    key={_index}
                    style={{
                      width: `calc(100% / ${item.columns.length} - 30px / ${item.columns.length})`,
                    }}
                  >
                    {_item.interactive ? (
                      <span className="placeholder-type">
                         {_item.type==="numeric"? "Nummer"
                      : _item.type==="date"?"Datum"
                      : _item.type==="time"?"Zeit"
                      : _item.type==="text"?"Text"
                      : _item.type}
                      </span>
                    ) : (
                      <>
                        {/* {_item.type === "date" ? (
                          <input
                            type="date"
                            value={_item.value}
                            onChange={e =>
                              this.handleTableCellValue(index, _index, e)
                            }
                          />
                          ) : ( */}
                        <input
                          type="text"
                          value={_item.value}
                          onChange={(e) =>
                            this.handleTableCellValue(index, _index, e)
                          }
                          autoComplete="off"
                        />
                        {/* ) */}
                        {/* // } */}
                      </>
                    )}
                  </div>
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }
}

export default TableOptionComponent;

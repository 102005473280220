import React, { Component } from "react";
import arrowRight from "../../assets/arrow-small-right.svg";
import arrowDown from "../../assets/arrow-small-down.svg";
import { createRef } from "react/cjs/react.production.min";
import arrowUp from "../../assets/arrow-small.svg";
import { withTranslation} from 'react-i18next';
class DropDown extends Component {
  ref = createRef();

  constructor(props) {
    super(props);
    this.state = {
      activeOption: -1,
      showOptions: props.showDropdownOptions ?? false,
      header:""
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  handleClick = (e) => {
    if (!this.ref?.current?.contains?.(e.target)) {
      this.setState({ showOptions: false });
    }
  };
  handleChange=(e,name)=>{
// console.log("e",e.target.value)
let data=Object.assign({},this.props.data)
let original=Object.assign({},this.props.original)
if (name === "Gaste" || name === "Umsatz") {
    let dummy = data[name]?.filter((d) => d.toString().includes(e.target.value));
    this.setState({header:e.target.value},()=>{
      
    })
  
    data[name]=e.target.value? dummy :original[name]
    this.props.setHeaderData(data);

} 

else {
    let dummy = data[name]?.filter((d) => d.includes(e.target.value));
    this.setState({header:e.target.value},()=>{
      
    })
//  console.log("this.props.data[name]",this.props.data[name])
    data[name]=e.target.value ? dummy :original[name]
    this.props.setHeaderData(data);
}

  }
  sortTable = (name, type) => {
    let { data } = this.props;
    if (name === "Gaste" || name === "Umsatz") {
      if (type === "asc") {
        data[name].sort(function (a, b) {
          return a - b;
        });
      } else {
        data[name].sort(function (a, b) {
          return b - a;
        });
      }
    } else if (name === "Datum") {
      data[name].sort((a, b) => {
        return type === "asc"
          ? a.toString().localeCompare(b)
          : b.toString().localeCompare(a);
      });
    } else {
      if (type === "asc") {
        data[name].sort();
      } else {
        data[name].sort();
        data[name].reverse();
      }
    }

    this.props.setHeaderData(data);
  };

  onKeyDown = (e) => {
    const { activeOption } = this.state;
    const { options, onSelect } = this.props;

    const state = {};

    if (e.keyCode === 13) {
      onSelect && onSelect(options[activeOption]?._id);
      state.showOptions = false;
      state.activeOption = -1;
    } else if (e.keyCode === 38) {
      state.activeOption = (activeOption - 1 + options.length) % options.length;
    } else if (e.keyCode === 40) {
      state.activeOption = (activeOption + 1) % options.length;
    }

    // e.preventDefault();
    this.setState(state);
  };

  render() {
    const { activeOption, showOptions } = this.state;
    const {
      name,
      value,
      placeholder,
      style,
      options,
      disabled,
      labelStyle,
      containerStyle,
      hideSort,
    } = this.props;
    const dropDownStyle = { ...styles.dropdown, ...style };
    const wrapperStyle = { ...styles.container, ...containerStyle };
    const textStyle = { ...styles.input, ...labelStyle };

    const opt = options?.filter((option) => option?._id === value);
    const showIndicator = this.props?.showIndicator ?? true;
    // console.log("data",data)
    return (
      <div style={wrapperStyle} ref={this.ref}>
        <div style={dropDownStyle}>
          <input
            onChange={(e)=>{this.handleChange(e,name)}}
            maxLength={30}
            placeholder={name ? this.props.t(name) : (opt?.[0]?.name ? this.props.t(opt?.[0]?.name) : this.props.t(placeholder))}
            style={textStyle}
            // value={name ?? opt?.[0]?.name ?? placeholder ?? ""}
            value={this.props.t(this.state.header)}
            onKeyDown={this.onKeyDown}
            onClick={() => !disabled && this.setState({ showOptions: true })}
            // readOnly={true}
            autoComplete="off"
          />
          { !hideSort &&
            <>
            <img
              src={arrowUp}
              style={styles.smallIcon}
              alt=""
              onClick={() => this.props.onSortTable ? this.props.onSortTable(name, "asc") : this.sortTable(name, "asc")}
            />
            <img
              src={arrowDown}
              style={styles.smallIcon}
              alt=""
              onClick={() => this.props.onSortTable ? this.props.onSortTable(name, "desc") : this.sortTable(name, "desc")}
            />
            </>
          }
          {showIndicator && (
            <img
              alt="dropdown"
              style={styles.icon}
              src={showOptions ? arrowDown : arrowRight}
            />
          )}
        </div>

        <div style={styles.relative}>
          {showOptions && options?.length > 0 && (
            <div style={styles.wrapper}>
              {options?.map((option, index) => (
                <div
                  key={index}
                  style={{
                    ...styles.option,
                    background: index === activeOption && "#d0d3d4",
                  }}
                  onClick={() => this.onSelect(option, index)}
                >
                  {this.props.t(option?.name)}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }

  onSelect = (option, index) => {
    this.props.onSelect(option, index);
    this.setState({
      showOptions: false,
      activeOption: -1,
    });
  };
}

export default withTranslation() (DropDown);

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
  },
  relative: {
    position: "relative",
  },
  dropdown: {
    background: "#e7e7e8",
    color: "#939599",
    fontSize: "14px",
    width: "min-content",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  smallIcon: {
    width: "8px",
    height: "8px",
    opacity: 0.5,
    margin: "2px",
    cursor: "pointer",
  },
  wrapper: {
    position: "absolute",
    maxHeight: "200px",
    background: "#e7e7e8",
    border: "1px solid #939599",
    zIndex: "60",
    left: "0",
    right: "0",
    fontSize: "12px",
    overflow: "auto",
  },
  option: {
    padding: "5px 10px",
    cursor: "pointer",
    color: "#939599",
    backgroundColor: "transparent",
  },
  icon: {
    marginRight: "15px",
    height: "8px",
    width: "8px",
  },
  input: {
    border: 0,
    margin: 0,
    maxWidth: "150px",
    cursor: "pointer",
    background: "transparent",
    padding: "10px 15px",
  },
};
